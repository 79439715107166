import { Route, Routes, Outlet } from 'react-router-dom';
import { ShowPcsMission } from './ShowMission/ShowPcsMission';
import { Exception } from '../../../components';
import { ShowPlansResearchMission } from './ShowMission/ShowPlansResearchMission';
import { ShowAuditOneMission } from './ShowMission/ShowAuditOneMission';
import { ShowAuditOneTwoMission } from './ShowMission/ShowAuditOneTwoMission';
import { ShowRcpMission } from './ShowMission/ShowRcpMission';
import { MissionContextProvider } from '../../../contexts/MissionContext';
import { Missions } from './Missions';

// This component applies the context to the "show" routes
const MissionContextWrapper = () => (
  <MissionContextProvider>
    <Outlet />
  </MissionContextProvider>
);

/**
 * Renders the mission router component.
 * @returns {JSX.Element} The mission router component.
 */
export const MissionViewRouter = () => (
  <Routes>
    <Route path="/" element={<Missions />} />

    <Route element={<MissionContextWrapper />}>
      <Route path="/show/pcs/:id/:type" element={<ShowPcsMission />} />
      <Route
        path="/show/plans_research/:id/:type"
        element={<ShowPlansResearchMission />}
      />
      <Route path="/show/audit_1/:id/:type" element={<ShowAuditOneMission />} />
      <Route
        path="/show/audit_1_2/:id/:type"
        element={<ShowAuditOneTwoMission />}
      />
      <Route path="/show/rcp_research/:id/:type" element={<ShowRcpMission />} />
    </Route>

    <Route path="*" element={<Exception />} />
  </Routes>
);
