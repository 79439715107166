import { createContext, useContext, useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { useErrorMessage } from '../utils/errorMessage';
import { useAuthContext } from './AuthContext';

export const GlobalContext = createContext();

/**
 * Provides a global context for the application.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The global context provider component.
 */
export const GlobalContextProvider = ({ children }) => {
  const { dispatchAPI, user, isValid, token } = useAuthContext();
  const { message } = useErrorMessage();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isCreateMessageCardOpen, setIsCreateMessageCardOpen] = useState(false);
  const [selectedRecipientsValues, setSelectedRecipientsValues] = useState([]);
  const [messageDateWhoReceiveResponse, setMessageDateWhoReceiveResponse] =
    useState(null);
  const [syndics, setSyndics] = useState([]);
  const [managers, setManagers] = useState([]);
  const [activeKey, setActiveKey] = useState('1');
  const [currentSyndic, setCurrentSyndic] = useState(null);
  const [refreshSyndic, setRefreshSyndic] = useState(false);

  /**
   * Retrieves the current syndic for the user.
   * @function
   * @async
   * @returns {Promise<void>} A promise that resolves when the current syndic is set.
   */
  const getCurrentSyndic = async () => {
    try {
      if (token) {
        const strings = {
          'users:SYNDIC_MANAGER': 'managers',
          'users:SYNDIC_ASSISTANT': 'assistants',
          'users:SYNDIC_COUNCIL': 'councils'
        };
        const { data } = await dispatchAPI('GET', {
          url: `/syndic?${strings[user.role]}=${user._id}&fields=_id`
        });
        if (data && data.length > 0) {
          setCurrentSyndic(data[0]._id);
        } else {
          setCurrentSyndic(false);
        }
      }
    } catch (e) {
      if (e.response) message(e.response.status);
      setCurrentSyndic(false);
    }
  };

  /**
   * Fetches the syndics from the API.
   * @function
   * @async
   * @returns {Promise<void>} A promise that resolves when the syndics are fetched.
   */
  const getSyndics = async () => {
    try {
      if (token) {
        const { data } = await dispatchAPI('GET', {
          url: '/syndics?populate=assistants,managers,lawyer'
        });
        setSyndics(data);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  /**
   * Fetches managers from the server.
   * @function
   * @async
   * @returns {Promise<void>} A promise that resolves when the managers are fetched.
   */
  const getManagers = async () => {
    try {
      if (token) {
        const { data } = await dispatchAPI('GET', {
          url: '/users?role=users:SYNDIC_MANAGER'
        });
        setManagers(data);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  /**
   * Fetches the collective ownerships from the API.
   * @function
   * @async
   * @returns {Promise<void>} A promise that resolves when the collective ownerships are fetched.
   */
  const getCollectiveOwnerships = async () => {
    let link = '/collective-ownerships';

    try {
      if (token) {
        if (
          [
            'users:NOTARY',
            'users:LAWYER',
            'users:SURVEYOR',
            'users:OTHERS'
          ].includes(user.role)
        ) {
          const role = user.role.split(':')[1].toLowerCase();
          link = `/collective-ownerships?permissions.${pluralize(role)}=${
            user._id
          }`;
        }
        const { data } = await dispatchAPI('GET', { url: link });
        setCollectiveOwnerships(data);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        getSyndics(),
        getCollectiveOwnerships(),
        getManagers()
      ]);
      setIsDataLoading(false);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (
      user &&
      [
        'users:SYNDIC_MANAGER',
        'users:SYNDIC_ASSISTANT',
        'users:SYNDIC_COUNCIL'
      ].includes(user.role)
    ) {
      if (isValid) {
        getCurrentSyndic();
      } else {
        window.location.reload();
      }
    }
  }, [refreshSyndic, user, isValid]);

  return (
    <GlobalContext.Provider
      value={{
        syndics,
        setSyndics,
        managers,
        setManagers,
        collectiveOwnerships,
        setCollectiveOwnerships,
        setForceRefresh,
        forceRefresh,
        isCreateMessageCardOpen,
        setIsCreateMessageCardOpen,
        selectedRecipientsValues,
        setSelectedRecipientsValues,
        messageDateWhoReceiveResponse,
        setMessageDateWhoReceiveResponse,
        isDataLoading,
        setIsDataLoading,
        activeKey,
        setActiveKey,
        currentSyndic,
        setCurrentSyndic,
        refreshSyndic,
        setRefreshSyndic
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
