import { useTranslation } from 'react-i18next';
import { Popconfirm, Row, message as antdMessage } from 'antd';
import dayjs from 'dayjs';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { deleteResource } from '../../../utils/deleteResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Custom hook that returns an array of columns configuration for a table.
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setEditingKey - The function to set the editing key.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The force refresh flag.
 * @returns {Array} - An array of column configuration objects.
 */
export const useColumns = ({
  setEditingKey,
  form,
  setForceRefresh,
  forceRefresh,
  setSelectType
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { editCell } = EditableTableMethods();

  return [
    {
      title: t('reminders.form.application_period'),
      key: 'application_period',
      dataIndex: 'application_period',
      editable: true,
      inputType: 'range-picker',
      render: (_, record) =>
        record.default ? (
          t('reminders.form.default')
        ) : (
          <Row
            onClick={() =>
              editCell({
                record,
                key: 'application_period',
                setEditingKey,
                form
              })
            }
          >
            {record?.application_period
              ? `${dayjs(record?.application_period?.start).format(
                  'DD-MM-YYYY'
                )} - ${dayjs(record?.application_period?.end).format(
                  'DD-MM-YYYY'
                )}`
              : '-'}
          </Row>
        ),
      sorter: (a, b) => {
        if (a?.application_period?.start) {
          return (
            new Date(a?.application_period?.start) -
            new Date(b?.application_period?.start)
          );
        }
        return true;
      }
    },
    {
      title: t('reminders.form.first_reminder'),
      key: 'first_reminder',
      dataIndex: 'first_reminder',
      editable: true,
      inputType: 'number-unit',
      render: (_, record) => (
        <Row
          onClick={() => {
            if (record?.default) return;
            setSelectType('first_reminder');
            editCell({ record, key: 'first_reminder', setEditingKey, form });
          }}
        >
          {`${record?.first_reminder?.number || '-'} ${
            record?.first_reminder?.unit
              ? t(`units.${record?.first_reminder?.unit}`)
              : ''
          } `}
        </Row>
      )
    },
    {
      title: t('reminders.form.first_reminder_if_no_response'),
      key: 'first_reminder_if_no_response',
      dataIndex: 'first_reminder_if_no_response',
      editable: true,
      inputType: 'number-unit',
      render: (_, record) => (
        <Row
          onClick={() => {
            if (record?.default) return;
            setSelectType('first_reminder_if_no_response');
            editCell({
              record,
              key: 'first_reminder_if_no_response',
              setEditingKey,
              form
            });
          }}
        >
          {`${record?.first_reminder_if_no_response?.number || '-'} ${
            record?.first_reminder_if_no_response?.unit
              ? t(`units.${record?.first_reminder_if_no_response?.unit}`)
              : ''
          } `}
        </Row>
      )
    },
    {
      title: t('reminders.form.second_reminder'),
      key: 'second_reminder',
      dataIndex: 'second_reminder',
      editable: true,
      inputType: 'number-unit',
      render: (_, record) => (
        <Row
          onClick={() => {
            if (record?.default) return;
            setSelectType('second_reminder');
            editCell({ record, key: 'second_reminder', setEditingKey, form });
          }}
        >
          {`${record?.second_reminder?.number || '-'} ${
            record?.second_reminder?.unit
              ? t(`units.${record?.second_reminder?.unit}`)
              : ''
          } `}
        </Row>
      )
    },
    {
      title: t('reminders.form.second_reminder_if_no_response'),
      key: 'second_reminder_if_no_response',
      dataIndex: 'second_reminder_if_no_response',
      editable: true,
      inputType: 'number-unit',
      render: (_, record) => (
        <Row
          onClick={() => {
            if (record?.default) return;
            setSelectType('second_reminder_if_no_response');
            editCell({
              record,
              key: 'second_reminder_if_no_response',
              setEditingKey,
              form
            });
          }}
        >
          {`${record?.second_reminder_if_no_response?.number || '-'} ${
            record?.second_reminder_if_no_response?.unit
              ? t(`units.${record?.second_reminder_if_no_response?.unit}`)
              : ''
          } `}
        </Row>
      )
    },
    {
      title: t('reminders.form.third_reminder'),
      key: 'third_reminder',
      dataIndex: 'third_reminder',
      editable: true,
      inputType: 'number-unit',
      render: (_, record) => (
        <Row
          onClick={() => {
            if (record?.default) return;
            setSelectType('third_reminder');
            editCell({ record, key: 'third_reminder', setEditingKey, form });
          }}
        >
          {`${record?.third_reminder?.number || '-'} ${
            record?.third_reminder?.unit
              ? t(`units.${record?.third_reminder?.unit}`)
              : ''
          } `}
        </Row>
      )
    },
    {
      title: t('reminders.form.third_reminder_if_no_response'),
      key: 'third_reminder_if_no_response',
      dataIndex: 'third_reminder_if_no_response',
      editable: true,
      inputType: 'number-unit',
      render: (_, record) => (
        <Row
          onClick={() => {
            if (record?.default) return;
            setSelectType('third_reminder_if_no_response');
            editCell({
              record,
              key: 'third_reminder_if_no_response',
              setEditingKey,
              form
            });
          }}
        >
          {`${record?.third_reminder_if_no_response?.number || '-'} ${
            record?.third_reminder_if_no_response?.unit
              ? t(`units.${record?.third_reminder_if_no_response?.unit}`)
              : ''
          } `}
        </Row>
      )
    },
    {
      key: 'action',
      fixed: 'right',
      width: 60,
      render: (record) =>
        record.default ? null : (
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => {
              deleteResource({
                dispatchAPI,
                resourceName: 'reminder',
                id: record._id,
                setForceRefresh,
                forceRefresh,
                message
              });
              antdMessage.success({
                content: t('datatable.message.success.deleteline'),
                icon: <DeleteOutlined style={{ color: 'red' }} />
              });
              return true;
            }}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'var(--errorColor)', fontSize: 16 }}
              type="delete"
            />
          </Popconfirm>
        )
    }
  ];
};
