import { DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { generatePDF } from '../../../../utils/generatePDF';

/**
 * @component
 * DownloadAwnsersButton component.
 *
 * This component renders a button that, when clicked, generates and downloads a PDF document
 * containing the answers of a collective ownership form.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.collectiveOwnershipForm - The collective ownership form data.
 * @param {string} props.collectiveOwnershipForm._id - The ID of the collective ownership form.
 *
 * @returns {JSX.Element} The rendered button component.
 *
 * @example
 * <DownloadAwnsersButton collectiveOwnershipForm={form} />
 *
 */
const DownloadAwnsersButton = ({ collectiveOwnershipForm }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const translationObject = {
    true: t('buttons.yes'),
    GARDEN: t('missions.tags.GARDEN'),
    BALCONY: t('missions.tags.BALCONY'),
    TERRACE: t('missions.tags.TERRACE'),
    ATTIC: t('missions.tags.ATTIC'),
    COURTYARD: t('missions.tags.COURTYARD'),
    PARKING: t('missions.tags.PARKING'),
    WC: t('missions.tags.WC'),
    CORRIDOR: t('missions.tags.CORRIDOR'),
    LOGGIA: t('missions.tagsLOGGIA')
  };

  const downloadDocuments = async () => {
    await generatePDF({
      message,
      dispatchAPI,
      parameters: [
        {
          collection: 'Collective-ownership-form',
          documentId: collectiveOwnershipForm._id
        }
      ],
      use_case: 'COLLECTIVE_OWNERSHIP_FORM_ANSWERS',
      template_type: 'TEMPLATE_DOCS',
      setIsLoading: () => {},
      downloadName: t(`missions.form.collective_ownership_form`),
      translationObject
    });
  };

  return <DownloadOutlined onClick={downloadDocuments} />;
};

export default DownloadAwnsersButton;

DownloadAwnsersButton.propTypes = {
  collectiveOwnershipForm: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
