import PropTypes from 'prop-types';
import { Layout, Spin } from 'antd';

const { Content } = Layout;

export const ContentCustom = ({
  children,
  style,
  isLoading = false,
  className
}) => (
  <Content
    className={className}
    style={{
      margin: '8px 24px 8px',
      padding: 16,
      background: 'var(--clientColor)',
      minHeight: 'fit-content',
      borderRadius: 2,
      ...style
    }}
  >
    <Spin spinning={isLoading}>{children}</Spin>
  </Content>
);

ContentCustom.propTypes = {
  style: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  className: PropTypes.string
};

ContentCustom.defaultProps = {
  style: undefined,
  isLoading: false,
  className: ''
};
