import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Returns an array of principal items for the Audit One Two steps.
 * @hook
 * @returns {Object[]} principalItems - The array of principal items.
 * @property {string} key - The key of the item.
 * @property {string} title - The title of the item.
 * @property {boolean} disabled - Indicates if the item is disabled.
 */
export const auditOneTwoSteps = ({ step }) => {
  const { t } = useTranslation();
  const principalSteps = [
    {
      title: t('missions.steps.INVOICE_DEPOSIT'),
      icon: <CheckCircleOutlined />,
      key: 'INVOICE_DEPOSIT',
      disabled: true
    },
    {
      title: t('missions.steps.BUILDING_FORM_ORDER'),
      icon: <CheckCircleOutlined />,
      key: 'BUILDING_FORM_ORDER',
      disabled: true
    },
    {
      title: t('missions.steps.BUILDING_FORM_DELIVERY'),
      icon: <CheckCircleOutlined />,
      key: 'BUILDING_FORM_DELIVERY',
      disabled: true
    },
    {
      title: t('missions.steps.DOCUMENTS_ORDER'),
      icon: <CheckCircleOutlined />,
      key: 'DOCUMENTS_ORDER',
      disabled: true
    },
    {
      title: t('missions.steps.DOCUMENTS_DELIVERY'),
      icon: <CheckCircleOutlined />,
      key: 'DOCUMENTS_DELIVERY',
      disabled: true
    },
    {
      title: t('missions.steps.TRANSFORMATION_WORD'),
      icon: <CheckCircleOutlined />,
      key: 'TRANSFORMATION_WORD',
      disabled: !(step >= 3)
    }
  ];

  const principalItems = principalSteps.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled
  }));

  return { principalItems };
};
