import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListTemplateDocs from '../utils/templateComponents/ListTemplateDocs';
import { DocEditor } from '../utils/templateComponents/DocEditor/DocEditor';
import { Exception } from '../../../components';
import { ShowTemplate } from '../utils/templateComponents/ShowTemplate';

/**
 * `TemplateDocRouter` is a component that defines a set of routes for handling
 * document templates within a React application. It leverages the `Routes` and
 * `Route` components from `react-router-dom` to define various paths and their
 * corresponding components.
 *
 * @component
 * @returns {React.Element} A set of routes to handle document template-related operations such as creation, editing, viewing, and listing.
 * @example
 *
 * return (
 *   <TemplateDocRouter />
 * )
 */
export const TemplateDocRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<DocEditor purpose="create" type="TEMPLATE_DOCS" />}
    />
    <Route
      path="/edit/:id"
      element={<DocEditor purpose="edit" type="TEMPLATE_DOCS" />}
    />
    <Route path="/show/:id" element={<ShowTemplate type="TEMPLATE_DOCS" />} />
    {/* ---------------------------------------------------------- */}
    <Route index element={<ListTemplateDocs type="TEMPLATE_DOCS" />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
