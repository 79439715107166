import { Button, Flex, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Quotation } from '../../../models/Quotation';
import { useQuotationContext } from '../../../contexts/QuotationContext';

/**
 * CustomActionButtons component.
 * Renders custom action buttons for a quotation form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the quotation form.
 * @returns {JSX.Element} The rendered component.
 */
export const CustomActionButtons = ({ purpose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { state } = useLocation();
  const { current, setForceRefresh, formData, form, setIsLoading, filesList } =
    useQuotationContext();

  return (
    <Flex justify="flex-end" gap="small" style={{ marginTop: '15px' }}>
      <Button type="link" danger onClick={() => navigate(-1)}>
        {t('buttons.cancel')}
      </Button>
      <Button
        type="primary"
        onClick={() =>
          Quotation.onSubmitForm({
            values: formData,
            form,
            purpose,
            id,
            current,
            navigate,
            setForceRefresh,
            message,
            t,
            type,
            setIsLoading,
            filesList,
            state
          })
        }
      >
        <CheckOutlined />
        {t(
          `buttons.${
            state?.launch_pcs3 && current.sub === 5
              ? 'valid_quotation_and_launch_mission_pcs_3'
              : 'save'
          }`
        )}
      </Button>
    </Flex>
  );
};

CustomActionButtons.propTypes = {
  purpose: PropTypes.string.isRequired
};
