import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

/**
 * Generates an array of column definitions for the treasury publications table.
 *
 * @function useColumns
 * @param {Object} params - The parameters for generating the columns.
 * @param {Function} params.t - Translation function for localizing column titles.
 * @returns {Array} Array of column definitions for a table.
 * @example
 * const columns = useColumns({ t });
 * // This will return an array of columns with title, key, render, and sorter properties for a table.
 */
export const useColumns = ({ t }) => [
  {
    title: t('treasury_publications.columns.syndic'),
    key: 'syndic',
    dataIndex: 'syndic',
    render: (syndic) =>
      `${syndic?.society_name || '-'} ${
        syndic?.code ? `(${syndic.code})` : ''
      }`,
    sorter: true
  },
  {
    title: t('treasury_publications.columns.collective_ownership'),
    key: 'collective_ownership',
    render: (record) =>
      `${record?.code ? `${record?.code} -` : ''} ${
        record?.first_address?.number || ''
      } ${record?.first_address?.street || ''} ${
        record?.first_address?.postal_code || ''
      } ${record?.first_address?.city || ''} ${
        record?.cadastral_reference || ''
      }`,
    sorter: true
  },
  {
    title: t('treasury_publications.columns.nomade'),
    key: 'nomade',
    render: (record) => {
      const invoices = record?.invoices || [];
      let total = 0;

      invoices.forEach((invoice) => {
        if (invoice?.total_ttc) {
          total += invoice.total_ttc;
        }
      });
      return total ? `${parseFloat(total).toFixed(2)} €` : '0 €';
    },
    sorter: true
  },
  {
    title: t('treasury_publications.columns.notary'),
    key: 'notary',
    sorter: true
  },
  {
    title: t('treasury_publications.columns.paid'),
    key: 'paid',
    sorter: true
  },
  {
    title: t('treasury_publications.columns.refunded'),
    key: 'refunded',
    sorter: true
  },
  {
    key: 'action',
    align: 'right',
    fixed: 'right',
    render: (record) => (
      <Link to={`/collective-ownerships/show/${record._id}`}>
        <EyeOutlined
          style={{ fontSize: 18, color: 'black', cursor: 'pointer' }}
        />
      </Link>
    )
  }
];
