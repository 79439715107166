import { useState } from 'react';
import {
  Drawer,
  Form,
  Row,
  Col,
  Divider,
  Input,
  Checkbox,
  Button,
  notification
} from 'antd';
import dayjs from 'dayjs';
import { CloseOutlined, PrinterOutlined } from '@ant-design/icons';
import isBetween from 'dayjs/plugin/isBetween';
import { useTranslation } from 'react-i18next';
import { useHandleResize } from '../../../../../utils/handleResize';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { Order } from '../../../../../models/Order';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { generateCerfa } from '../../../../../utils/generateCerfa';
import { useAuthContext } from '../../../../../contexts/AuthContext';

dayjs.extend(isBetween);

/**
 * Represents a drawer component for displaying Cerfa orders.
 * @component
 * @returns {JSX.Element} The CerfaOrderDrawer component.
 */
export const CerfaOrderDrawer = () => {
  const { orders, isDrawerOpen, setIsDrawerOpen, setForceRefresh } =
    useMissionContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { user } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cheques] = useState(generateCerfa(orders));

  const colOwns = [];
  const colOwnsPerCerfaNumber = {
    3233: new Set(),
    3231: new Set(),
    3236: new Set()
  };
  const concernedSPFPerColOwn = {};

  cheques.forEach((cheque) => {
    cheque.cerfas.forEach((cerfa) => {
      cerfa.lines.forEach((line) => {
        colOwns.push(line.mission.collective_ownership);
        colOwnsPerCerfaNumber[line.cerfa_number].add(
          JSON.stringify({
            _id: line.mission.collective_ownership._id,
            concerned_SPF: line.concerned_SPF
          })
        );
        concernedSPFPerColOwn[line.mission.collective_ownership._id] =
          line.concerned_SPF;
      });
    });
  });

  let chequeNumber = 0;
  // Nombre unique de copropriétés au total
  const uniqueCollectiveOwnershipIds = new Set(
    colOwns.map((colOwn) => colOwn._id)
  );

  const fillChequeNumbers = (values) => {
    const newCheques = cheques.map((cheque, index) => {
      // eslint-disable-next-line no-param-reassign
      cheque.chequeNumber = values[`cheque_number_${index + 1}`];
      return cheque;
    });
    return newCheques;
  };

  const transformCheques = (values) => fillChequeNumbers(values);

  const handleFinish = async (formValues) => {
    const transformedCheques = transformCheques(formValues);

    try {
      const formattedData = await Order.formatTakeOrdersData({
        tabCheques: transformedCheques,
        urgent: formValues.urgent
      });
      if (formattedData.spfNameWithMissingCerfa.length > 0) {
        notification.warning({
          message: t('orders.notifications.cerfa_not_found_title'),
          description: t('orders.notifications.cerfa_not_found_description', {
            spf_name: formattedData.spfNameWithMissingCerfa.join(', ')
          })
        });
        return;
      }

      await Order.takeOrders({
        values: {
          ...formValues,
          user: user._id
        },
        cerfaNumber: formattedData,
        missionsAndSteps: [],
        setIsDrawerOpen,
        isDrawerOpen: {
          cerfa_order: { missionId: null, step: null, open: false }
        },
        setForceRefresh,
        setIsSubmitting,
        cheques,
        extraQuery: isDrawerOpen?.cerfa_order?.missionId
          ? `missionId=${isDrawerOpen?.cerfa_order?.missionId}&step=${isDrawerOpen?.cerfa_order?.step}`
          : '',
        message
      });
      notification.success({
        message: t('orders.notifications.ordered_success_title'),
        description: t('orders.notifications.ordered_success_description')
      });
    } catch (e) {
      notification.error({
        message: t('orders.notifications.ordered_error_title'),
        description: t('orders.notifications.ordered_error_description')
      });
    }
  };

  const { width } = useHandleResize();

  return (
    <Drawer
      title={t('missions.drawers.titles.cerfa_orders', {
        count: uniqueCollectiveOwnershipIds.size
      })}
      onClose={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          cerfa_order: { open: false, missionId: null, step: null }
        }))
      }
      open={isDrawerOpen?.cerfa_order?.open}
      closeIcon={false}
      width={width < 800 ? '80%' : '40%'}
    >
      <Form onFinish={handleFinish}>
        {cheques.map((cheque) => {
          const chequeOrders = cheque.cerfas.map((cerfa) => (
            <Row justify="space-between" align="middle">
              <Col>
                {t('missions.drawers.titles.cerfa_number', {
                  number: cerfa.lines[0].cerfa_number
                })}
              </Col>
              <Col span={8}>
                <Divider type="horizontal" />
              </Col>
              <Col>
                {t('missions.drawers.titles.collective_ownership_number', {
                  count: cerfa.lines.length
                })}
              </Col>
            </Row>
          ));

          chequeNumber += 1;

          return (
            <>
              {chequeOrders}
              <Form.Item
                label={t(`orders.form.cheque_number`, {
                  number: chequeNumber,
                  spf: cheque.cerfas[0].lines[0].concerned_SPF.name
                })}
                name={[`cheque_number_${chequeNumber}`]}
                labelAlign="left"
                labelCol={{ span: 24 }}
              >
                <Input maxLength={7} />
              </Form.Item>
            </>
          );
        })}
        <Form.Item
          label={t(`orders.form.urgent`)}
          name={['urgent']}
          valuePropName="checked"
          tooltip={t('missions.tooltips.urgent')}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 8px' }}
              type="link"
              danger
              onClick={() =>
                setIsDrawerOpen((prev) => ({
                  ...prev,
                  cerfa_order: { open: false, missionId: null, step: null }
                }))
              }
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit" loading={isSubmitting}>
              <PrinterOutlined />
              {`${t('buttons.print')} `}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
