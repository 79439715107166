import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * Custom hook that provides drag and drop functionality for files.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {string} options.resource - The resource URL.
 * @returns {Object} - An object containing the deleteFile function.
 */
export const useDraggerFunctions = ({ resource }) => {
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();

  const deleteFile = async (fileID) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${resource}/${id}/${fileID}`
      });
    } catch (e) {
      message(e);
    }
  };

  return { deleteFile };
};
