import { Row, Col, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Mission } from '../../../../../models/Mission';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Renders the block mission credit note buttons.
 * @component
 * @returns {JSX.Element} The block mission credit note buttons component.
 */
export const BlockMissionCreditNoteButtons = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message, setIsDrawerOpen } = useMissionContext();

  return (
    <Row gutter={[16, 16]} justify="end">
      <Col>
        <Button
          type="link"
          onClick={() =>
            setIsDrawerOpen((prev) => ({
              ...prev,
              block_mission_credit_note: false
            }))
          }
        >
          {t('missions.buttons.do_nothing')}
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() =>
            Mission.patchResource({
              id,
              values: { status: 'BLOCKED', access: 'BLOCKED' },
              message,
              messageContent: t('missions.messages.mission_blocked'),
              setIsDrawerOpen,
              drawerKey: 'block_mission_credit_note'
            })
          }
        >
          {t('missions.buttons.block_mission')}
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() =>
            Mission.patchResource({
              id,
              values: { document_access: 'BLOCKED' },
              message,
              messageContent: t('missions.messages.mission_locked'),
              setIsDrawerOpen,
              drawerKey: 'block_mission_credit_note'
            })
          }
        >
          {t('missions.buttons.lock_access')}
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() =>
            Mission.patchResource({
              id,
              values: {
                status: 'BLOCKED',
                document_access: 'BLOCKED',
                access: 'BLOCKED'
              },
              message,
              messageContent: t('missions.messages.mission_blocked_and_locked'),
              setIsDrawerOpen,
              drawerKey: 'block_mission_credit_note'
            })
          }
        >
          {t('missions.buttons.lock_and_block')}
        </Button>
      </Col>
    </Row>
  );
};
