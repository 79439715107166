import { useTranslation } from 'react-i18next';
import Phase_1 from '../../assets/images/phases_step_1.svg';
import Phase_2 from '../../assets/images/phases_step_2.svg';
import Phase_3 from '../../assets/images/phases_step_3.svg';
import { formatAddress } from '../syndic-view/utils/formatingFunctions';

/**
 * @hook
 * Custom hook that returns an array of columns configuration for a table.
 *
 * @param {Array} MISSIONS_PCS An array of mission types.
 *
 * @returns {Array} An array of column objects.
 */
export const useColumns = (MISSIONS_PCS) => {
  const { t } = useTranslation();

  return [
    {
      title: t('collective-ownerships.form.manager'),
      key: 'name',
      dataIndex: ['collective_ownership', 'manager'],
      sorter: true,
      render: (r) => `${r?.first_name || ''} ${r?.last_name || ''}`
    },
    {
      title: t('collective-ownerships.form.collective_ownership_address'),
      key: ['first_address'],
      dataIndex: ['collective_ownership'],
      render: ({ name, first_address, first_city }) =>
        formatAddress(name, first_address, first_city)
    },
    {
      title: t('collective-ownerships.form.mission_type'),
      key: ['type'],
      dataIndex: ['type'],
      render: (e) =>
        e ? (
          <>
            {t(`missions.tags.${MISSIONS_PCS.includes(e) ? 'PCS' : e}`)}
            <br />
          </>
        ) : (
          ''
        )
    },
    {
      title: t('collective-ownerships.form.phases_steps'),
      key: ['type'],
      render: (record) => {
        if (record.type === 'PCS_1') {
          return <img src={Phase_1} alt="Phase 1" />;
        }
        if (record.type === 'PCS_2') {
          return <img src={Phase_2} alt="Phase 1" />;
        }
        if (record.type === 'PCS_3') {
          return <img src={Phase_3} alt="Phase 1" />;
        }
        return '';
      }
    }
  ];
};
