import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useColumns } from './useColumns';
import { ExtraSearchButton } from './ExtraSearchButton';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { dashboardTitle } from '../../ComplianceElanRouter/dashboardTitles';
import { ListResource } from '../../../../../components';
import { syndicContentCustomStyle } from '../../../utils/styleConstants';
import { ServicesCustomContentWithDivider } from '../../ServicesCustomContentWithDivider';

/**
 * Renders a list of services actions to do.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.missions - The list of missions.
 * @param {Array} props.customColumns - The custom columns for the list.
 * @param {string} props.tradKey - The translation key for the title.
 * @returns {JSX.Element} The rendered component.
 */
export const ServicesActionsToDoList = ({
  missions,
  customColumns,
  tradKey
}) => {
  const { currentSyndic } = useGlobalContext();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [filter, setFilter] = useState([]);
  const [todoActions, setTodoActions] = useState({});
  const columns = useColumns();
  const { actionsToDoTitle, button } = dashboardTitle();
  const fieldsToFetch = ['type', '_id', 'name'];
  const buildStringFields = (fields) => fields.join(',');
  const fieldsString = buildStringFields(fieldsToFetch);
  const missionsString = buildStringFields(missions);
  const navigate = useNavigate();
  const location = useLocation();

  const onDoubleClickAction = (e) => {
    const currentPath = location.pathname;
    const actionsToDoIndex = currentPath.indexOf('actions-to-do');

    if (actionsToDoIndex !== -1) {
      const newPath = `${currentPath.slice(
        0,
        actionsToDoIndex
      )}collective-ownerships/show/${e._id}`;
      navigate(newPath);
    } else {
      navigate(`collective-ownerships/show/${e._id}`);
    }
  };

  const getTagsData = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `syndic-collective-ownerships/todoActions?syndic=${currentSyndic}&type=${missionsString}`
    });
    setTodoActions(data);
  };

  useEffect(() => {
    getTagsData();
  }, []);

  return (
    <ServicesCustomContentWithDivider
      title={actionsToDoTitle(tradKey || missionsString)}
      buttonOnRight={button}
      cardStyle={{ backgroundColor: '#222222' }}
    >
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        {Object.entries(todoActions).map(([ttl, number], index) => (
          <Card
            key={ttl}
            style={{
              padding: '0px',
              marginBottom: '8px',
              fontSize: '14px',
              ...(index === 0
                ? { backgroundColor: '#222222', color: '#F4F4F4' }
                : { backgroundColor: '#E9ECEF', color: '#495057' })
            }}
          >
            {t(`services-actions-to-do.actions.${ttl}`)}
            {': '}
            <span
              style={index === 0 ? { color: '#FFD53F' } : { color: '#222222' }}
            >
              {number}
            </span>
          </Card>
        ))}
      </div>
      <div style={{ width: '100%' }}>
        <ListResource
          ContentCustomStyle={syndicContentCustomStyle}
          withPageHeader={false}
          resourceName="syndic-collective-ownerships"
          extraUrlPath="/forMissions"
          extraQuery={`syndic=${currentSyndic}&type=${
            filter.length > 0 ? filter : missions
          }&fields=${fieldsString}`}
          searchBarExtraButtons={
            <ExtraSearchButton setFilter={setFilter} missions={missions} />
          }
          columns={customColumns || columns}
          resourceModelName="Collective-ownership"
          customActionColumn
          onDoubleClickAction={{
            action: (e) => {
              onDoubleClickAction(e);
            }
          }}
          withCreateButton={false}
        />
      </div>
    </ServicesCustomContentWithDivider>
  );
};

ServicesActionsToDoList.propTypes = {
  missions: PropTypes.arrayOf(PropTypes.string),
  customColumns: PropTypes.shape([]),
  tradKey: PropTypes.string
};

ServicesActionsToDoList.defaultProps = {
  missions: [],
  customColumns: null,
  tradKey: null
};
