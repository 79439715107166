import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { AddIcon } from '../../../../utils/constants/customIcons';
import { ListResource } from '../../../../components';
import { useColumns } from './columns/collectiveOwnerhipColumns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { CustomSearchResourcesBar } from '../../../../components/ExtraSearchsBar/CustomSearchResourcesBar';
import { transformExtraQuery } from '../../../../utils/filtersUtils';

/**
 * The `CollectiveOwnerships` component is designed to display a list of collective ownership entities associated with a specific syndic.
 * It utilizes the `ListResource` component to render the list, providing functionalities such as sorting, filtering, and custom actions for each collective ownership.
 *
 * Props:
 * @prop {Object} [enums={}] - An object containing enumeration values for dropdown fields and other selectable options within the table, used to populate filters and tag colors.
 * @prop {function} t - A translation function provided by `react-i18next` for internationalization, used to translate labels, buttons, and other UI text.
 *
 * The component uses React's `useState` hook to manage the `forceRefresh` state, which controls the re-fetching of collective ownership data.
 * The `useColumns` hook is utilized to generate the column definitions for the table based on the `enums`, API dispatch function, and other parameters.
 *
 * Additionally, the component integrates with the `useAuthContext` for authorization context and `useErrorMessage` for handling error messages.
 * The `useParams` hook from 'react-router-dom' is used to extract the syndic's ID from the URL parameters, which is then used to filter the list of collective ownerships by the associated syndic.
 *
 * Extra functionality includes a custom action button for creating new collective ownership entities, linked to the collective ownership creation route.
 *
 * Usage:
 * This component is used within the administrative interface of an application that manages syndics and their associated collective ownerships.
 * It provides a comprehensive view of all collective ownerships linked to a specific syndic, with the ability to navigate to detailed views or perform actions such as creation of new entities.
 */

export const CollectiveOwnerships = ({ enums, t, editPermissions }) => {
  const { dispatchAPI } = useAuthContext();
  const { setActiveKey } = useGlobalContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [filters, setFilters] = useState({
    'syndic._id': id
  });
  const [extraQuery, setExtraQuery] = useState(`syndic._id=${id}`);
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns({
    enums,
    dispatchAPI,
    message,
    setForceRefresh,
    forceRefresh,
    setActiveKey,
    editPermissions
  });

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
  }, [filters]);

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between">
        <Col>
          <CustomSearchResourcesBar setFilters={setFilters} />
        </Col>
        <Col>
          {editPermissions && (
            <Link to="/collective-ownerships/create">
              <Button type="add">
                {t('buttons.create')}
                <AddIcon />
              </Button>
            </Link>
          )}
        </Col>
      </Row>
      <ListResource
        resourceName="collective-ownerships"
        extraQuery={extraQuery}
        columns={columns}
        populate="manager,mission"
        forceRefresh={forceRefresh}
        customActionColumn
        resourceModelName="Collective-ownership"
        withPageHeader={false}
        withCreateButton={false}
        withSearchBar={false}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
};

CollectiveOwnerships.propTypes = {
  enums: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  editPermissions: PropTypes.bool
};

CollectiveOwnerships.defaultProps = {
  enums: {},
  editPermissions: true
};
