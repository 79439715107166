import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabPane from 'antd/es/tabs/TabPane';
import { Flex, Tabs, Input } from 'antd';
import { facturesTitle } from '../factures-syndic/utils/facturesTitle';
import SyndicViewContentCustom from '../SyndicViewContentCustom';
import MissionConfondues from '../../../assets/images/missions_confondues.svg';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ContentCustom } from '../../../components';
import { useErrorMessage } from '../../../utils/errorMessage';
import { QuestionCollapse } from './QuestionsCollapse';

const { Search } = Input;

/**
 * Renders the FAQ section component.
 * @component
 * @returns {JSX.Element} The rendered FAQ section component.
 */
export const FaqSection = () => {
  const { title, subtitle } = facturesTitle();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [useCases, setUsesCases] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const getUseCases = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/template-documents/enums'
      });
      setUsesCases(
        data.configurations.FAQ_TEMPLATES.map((config) => ({
          label: config.use_case,
          key: config.use_case,
          children: (
            <QuestionCollapse
              searchValue={searchValue}
              useCase={config.use_case}
            />
          )
        }))
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getUseCases();
    })();
  }, [searchValue]);

  const onSearch = (value) => {
    if (value === '') {
      return setSearchValue(null);
    }
    return setSearchValue(value);
  };

  return (
    <SyndicViewContentCustom
      title={title}
      subtitle={subtitle}
      imageOnRight={MissionConfondues}
      cardStyle={{ backgroundColor: '#222222' }}
    >
      <ContentCustom>
        <Flex justify="flex-end" style={{ marginBottom: 16 }}>
          <Search
            placeholder={t('placeholder.search')}
            onSearch={onSearch}
            style={{ width: 200 }}
          />
        </Flex>
        <Tabs tabPosition="left" style={{ maxHeight: '100vh' }}>
          {useCases.map(({ label, key, children }) => (
            <TabPane
              key={key}
              tab={t(`template-documents.form.${label}`)}
              style={{ paddingLeft: 0 }}
            >
              {children}
            </TabPane>
          ))}
        </Tabs>
      </ContentCustom>
    </SyndicViewContentCustom>
  );
};
