/**
 * Custom hook that returns an array of manager information objects.
 *
 * @hook
 * @param {Object} data - The data object containing manager information.
 * @returns {Array} An array of manager information objects.
 */
export const useManagerInformationsListContent = (data = {}) => {
  const manager = data?.manager || {};

  return [
    {
      label: 'pcs-pre-productions.form.consultant',
      span: 1,
      content: manager
        ? `${manager?.first_name || ''} ${
            manager?.last_name ? manager.last_name.toUpperCase() : ''
          }`
        : '-'
    },
    {
      label: 'pcs-pre-productions.form.email',
      span: 1,
      content: manager?.email || ''
    },
    {
      label: 'pcs-pre-productions.form.phone_number_full',
      span: 1,
      content: manager?.phone_number?.number || '-'
    }
  ];
};
