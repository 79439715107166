import { Input, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

/**
 * Custom hook that returns an array of fields for lawyer consultation.
 *
 * @hook
 * @returns {Object} An object containing the fields array.
 */
export const useFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      key: 'type',
      name: ['type'],
      input: (
        <Radio.Group>
          <Radio.Button value="PCS">{t('notes.form.PCS')}</Radio.Button>
          <Radio.Button value="ADAPTATION">
            {t('notes.form.ADAPTATION')}
          </Radio.Button>
          <Radio.Button value="PUBLICATION">
            {t('notes.form.PUBLICATION')}
          </Radio.Button>
        </Radio.Group>
      )
    },
    {
      name: ['documents'],
      key: 'documents',
      maxFilesCount: 100,
      input: 'File'
    },
    {
      key: 'title',
      name: ['title'],
      rules: [{ required: true }]
    },
    {
      key: 'comment',
      name: ['comment'],
      input: <TextArea />
    }
  ];

  return { fields };
};
