import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Select } from 'antd';
import { useServicesContext } from '../contexts/ServicesContext';

/**
 * ServiceCustomSelector component.
 *
 * This component is responsible for rendering a custom selector for services.
 * It allows the user to select a service and navigate to the corresponding page.
 *
 * @returns {JSX.Element} The rendered ServiceCustomSelector component.
 */
const ServiceCustomSelector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { setCurrentService } = useServicesContext();

  const handleServiceChange = (value) => {
    setCurrentService(value);
    navigate(`/services/${value}`);
  };

  const selectOptions = [
    'COMPLIANCE_LOI_ELAN',
    // 'PUBLICATION_LOI_ELAN',
    'RCP_RESEARCH',
    'PLANS_RESEARCH'
  ];

  const stringFormatter = (str) => str.toLowerCase().replace(/_/g, '-');

  const createdLabel_value = selectOptions.map((option) => ({
    label: t(`missions.tags.${option}`),
    value: `${stringFormatter(option)}/dashboard`
  }));

  const pathname = location.pathname.split('/')[2];
  const revertStringFormatter = (str) => str.toUpperCase().replace(/-/g, '_');

  return (
    <Row
      style={{
        width: '90%',
        margin: '5%',
        flexDirection: 'column'
      }}
      align="middle"
    >
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: '5px'
        }}
      >
        <p style={{ margin: 0 }}>Service sélectionné</p>
        <InfoCircleOutlined />
      </div>
      <Select
        defaultValue={t(`missions.tags.${revertStringFormatter(pathname)}`)}
        style={{
          width: '100%'
        }}
        onChange={handleServiceChange}
        options={createdLabel_value}
      />
    </Row>
  );
};

export default ServiceCustomSelector;
