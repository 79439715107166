import { useTranslation } from 'react-i18next';

/**
 * The `useContactListContent` hook is designed to structure and prepare contact-related data for presentation in a list or detailed view format. It processes information about a contact, including civility, name, function, email, phone numbers, and address, formatting them for display and using conditional rendering to handle missing data.
 *
 * @param {Object} [data={}] - An object containing contact information. The `contact` property within this object should include details such as civility, name, function, email, phone numbers, and address.
 *
 * Returns:
 * An array of objects where each object represents a piece of information to be displayed in the list. Each object includes:
 * - `label`: A string key for internationalization purposes, used to retrieve the localized text for the label of the information.
 * - `span`: A numerical value indicating the span or width of the item in the list layout, used for alignment and formatting in grid or table-like displays.
 * - `content`: The content to be displayed for the item. This can be a simple string, a formatted string combining multiple pieces of data, or a conditional rendering to display a placeholder '-' for missing or undefined data.
 *
 * The hook utilizes the `useTranslation` hook from `react-i18next` for label internationalization, ensuring that the list content is accessible and understandable in different languages.
 *
 * Usage:
 * This hook is intended for use in components that display detailed information about a contact, such as a profile page, detail view, or information panel.
 * It simplifies the process of organizing and displaying complex data sets by structuring the data into a consistent format suitable for list or table-like presentations.
 */

export const useContactListContent = (data = {}) => {
  const { t } = useTranslation();
  const { contact } = data;

  return [
    {
      label: 'syndics.form.contact.civility',
      span: 1,
      content: contact?.civility ? t(`syndics.tags.${contact.civility}`) : '-'
    },
    {
      label: 'syndics.form.contact.name',
      span: 1,
      content: contact
        ? `${contact?.last_name || ''} ${contact?.first_name || ''}`
        : '-'
    },
    {
      label: 'syndics.form.contact.function',
      span: 1,
      content: contact?.function ? t(`syndics.tags.${contact.function}`) : '-'
    },
    {
      label: 'syndics.form.contact.email',
      span: 1,
      content: contact?.email || '-'
    },
    {
      label: 'syndics.form.contact.phone_number',
      span: 1,
      content: contact?.phone_number ? contact.phone_number?.number : '-'
    },
    {
      label: 'syndics.form.contact.cell_phone_number',
      span: 1,
      content: contact?.cell_phone_number
        ? contact?.cell_phone_number.number
        : '-'
    },
    {
      label: 'syndics.form.contact.address.title',
      span: 1,
      content: contact?.address
        ? `${contact.address?.number || ''} ${contact.address?.street || ''} ${
            contact.address?.postal_code || ''
          } ${contact.address?.city || ''}`
        : '-'
    }
  ];
};
