import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, DatePicker, Input, Form } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Mission } from '../../../../../models/Mission';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Returns the content for the parameters section of the mission view.
 * @hook
 * @param {Object} props - The props object.
 * @param {Function} props.setUpdateField - The function to set the update field state.
 * @param {string} props.updateField - The update field state.
 * @param {Object} props.form - The form object.
 * @returns {Object} - An object containing the useIcon, AGDateContent, and osCodeContent functions.
 */
export const parametersContent = ({ setUpdateField, updateField, form }) => {
  const { id } = useParams();
  const inputRef = useRef(null);
  const { mission, forceRefresh, setForceRefresh } = useMissionContext();

  /**
   * Returns the icon button based on the priority status.
   * @function
   * @param {boolean} isPriority - The priority status.
   * @returns {JSX.Element} - The icon button element.
   */
  const useIcon = (isPriority) => {
    if (isPriority) {
      return (
        <Button
          type="link"
          onClick={() =>
            Mission.updateParametersContent({
              value: { priority: !isPriority },
              id,
              setForceRefresh,
              forceRefresh,
              resource: 'missions',
              setUpdateField
            })
          }
        >
          <CheckCircleFilled style={{ width: 24, color: 'yellow' }} />
        </Button>
      );
    }
    return (
      <Button
        type="link"
        onClick={() =>
          Mission.updateParametersContent({
            value: { priority: !isPriority },
            id,
            setForceRefresh,
            forceRefresh,
            resource: 'missions',
            setUpdateField
          })
        }
      >
        <CloseCircleFilled style={{ width: 24, color: 'grey' }} />
      </Button>
    );
  };

  /**
   * Returns the content for the AG date field.
   * @function
   * @returns {JSX.Element|string} - The AG date content.
   */
  const AGDateContent = () => {
    if (updateField === 'AG_code') {
      setTimeout(() => {
        form.setFieldsValue({
          AG_date: dayjs(mission.collective_ownership.AG_date)
        });
      }, 100);

      return (
        <Form form={form}>
          <Form.Item name="AG_date">
            <DatePicker
              autoFocus
              format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
              onChange={(value) =>
                Mission.updateParametersContent({
                  value: { AG_date: value },
                  id: mission.collective_ownership._id,
                  setForceRefresh,
                  forceRefresh,
                  resource: 'collective-ownerships',
                  setUpdateField
                })
              }
            />
          </Form.Item>
        </Form>
      );
    }
    if (mission?.collective_ownership?.AG_date) {
      return dayjs(mission.collective_ownership.AG_date).format('DD-MM-YYYY');
    }
    return '-';
  };

  /**
   * Returns the content for the OS code field.
   * @function
   * @returns {JSX.Element|string} - The OS code content.
   */
  const osCodeContent = () => {
    if (updateField === 'OS_code') {
      setTimeout(() => {
        form.setFieldsValue({
          OS_code: mission.OS_code
        });
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);

      return (
        <Form form={form}>
          <Form.Item name="OS_code">
            <Input
              ref={inputRef}
              onBlur={(e) =>
                Mission.updateParametersContent({
                  value: { OS_code: e.target.value },
                  id,
                  setForceRefresh,
                  forceRefresh,
                  resource: 'missions',
                  setUpdateField
                })
              }
              onPressEnter={(e) =>
                Mission.updateParametersContent({
                  value: { OS_code: e.target.value },
                  id,
                  setForceRefresh,
                  forceRefresh,
                  resource: 'missions',
                  setUpdateField
                })
              }
            />
          </Form.Item>
        </Form>
      );
    }
    if (mission?.OS_code) {
      return mission.OS_code;
    }
    return '-';
  };

  return { useIcon, AGDateContent, osCodeContent };
};
