import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

/**
 * Retrieves the enums for a given resource.
 * @hook
 * @description You have to call this function with the name of the route to get the enums. (ex: 'users')
 * @returns {Object} An object containing the `getEnums` function.
 */
export const GetEnums = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Retrieves the enums for a given resource.
   * @function
   * @async
   * @param {Object} options - The options for retrieving the enums.
   * @param {string} options.resource - The resource to retrieve the enums for.
   * @returns {Promise<Object|null>} - A promise that resolves to the retrieved enums or null if the resource is not provided.
   */
  const getEnums = async ({ resource }) => {
    if (!resource) return null;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/enums`
      });

      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      return message(error);
    }
  };

  /**
   * Retrieves the enums for document repositories.
   * @function
   * @async
   * @returns {Promise<{roles: Array<string>, missions: Array<string>}>} The enums for roles and missions.
   */
  const getDocumentRepositoriesEnums = async () => {
    try {
      const { data: usersEnums } = await dispatchAPI('GET', {
        url: '/users/enums?selected_enums=create_update_user'
      });

      const { data: missionsEnums } = await dispatchAPI('GET', {
        url: '/document-repositories/enums'
      });

      if (usersEnums && missionsEnums) {
        return {
          roles: usersEnums.roles,
          missions: missionsEnums.missions_display
        };
      }

      return null;
    } catch (error) {
      return message(error);
    }
  };

  return { getEnums, getDocumentRepositoriesEnums };
};
