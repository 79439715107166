import dayjs from 'dayjs';

/**
 * The `usePublicationListContent` hook is designed to structure and prepare data related to a syndic's publication for presentation in a list or detailed view format. It processes various pieces of information about the syndic's publication, including office usual name, society form, capital, SIRET number, RCS city, representative details, professional card information, and more.
 *
 * @param {Object} [data={}] - An object containing the publication information of a syndic entity. The properties include office_usual_name, society_form, capital, siret_number, rcs_city, representative_civility, representative_name, representative_function, representative_address, procuration_representative, city_of_birth, professional_card_mention, professional_card_number, professional_card_acquisition_date, and card_deliver_by.
 *
 * Returns:
 * An array of objects where each object represents a piece of information to be displayed in the list. Each object includes:
 * - `label`: A string key for internationalization purposes, used to retrieve the localized text for the label of the information.
 * - `span`: A numerical value indicating the span or width of the item in the list layout, used for alignment and formatting in grid or table-like displays.
 * - `content`: The content to be displayed for the item. This can be a simple string, a formatted string combining multiple pieces of data, or a conditional rendering to display a placeholder '-' for missing or undefined data. Dates are formatted using the `dayjs` library to ensure consistency and readability.
 *
 * Usage:
 * This hook is intended for use in components that display detailed information about a syndic's publication, such as a profile page, detail view, or information panel. It simplifies the process of organizing and displaying complex data sets by structuring the data into a consistent format suitable for list or table-like presentations.
 */

export const usePublicationListContent = (data = {}) => {
  const {
    office_usual_name,
    society_form,
    capital,
    siret_number,
    rcs_city,
    representative_civility,
    representative_name,
    representative_function,
    representative_address,
    procuration_representative,
    city_of_birth,
    professional_card_mention,
    professional_card_number,
    professional_card_acquisition_date,
    card_deliver_by
  } = data;

  return [
    {
      label: 'syndics.form.office_usual_name',
      span: 1,
      content: office_usual_name || '-'
    },
    {
      label: 'syndics.form.society_form',
      span: 1,
      content: society_form || '-'
    },
    {
      label: 'syndics.form.capital',
      span: 1,
      content: capital ? `${capital} €` : '-'
    },
    {
      label: 'syndics.form.siret_number',
      span: 1,
      content: siret_number || '-'
    },
    {
      label: 'syndics.form.rcs_city',
      span: 1,
      content: rcs_city || '-'
    },
    {
      label: 'syndics.form.representative',
      span: 1,
      content: `${representative_civility || ''} ${representative_name || ''}`
    },
    {
      label: 'syndics.form.representative_function',
      span: 1,
      content: representative_function || '-'
    },
    {
      label: 'syndics.form.representative_address.title',
      span: 1,
      content: representative_address
        ? `${representative_address?.number || ''} ${
            representative_address?.street || ''
          } ${representative_address?.postal_code || ''} ${
            representative_address?.city || ''
          }`
        : '-'
    },
    {
      label: 'syndics.form.procuration_representative',
      span: 1,
      content: procuration_representative || '-'
    },
    {
      label: 'syndics.form.city_of_birth',
      span: 1,
      content: city_of_birth || '-'
    },
    {
      label: 'syndics.form.professional_card_mention',
      span: 1,
      content: professional_card_mention || '-'
    },
    {
      label: 'syndics.form.professional_card_number',
      span: 1,
      content: professional_card_number || '-'
    },
    {
      label: 'syndics.form.professional_card_acquisition_date',
      span: 1,
      content: professional_card_acquisition_date
        ? dayjs(professional_card_acquisition_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: 'syndics.form.card_deliver_by',
      span: 1,
      content: card_deliver_by || '-'
    }
  ];
};
