/* eslint-disable react/no-danger */
import { Row, Card, Col, Typography, Tag, Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAuthContext } from '../../contexts/AuthContext';
import { missionTag } from '../../utils/constants/tagColors';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { Message } from '../../models/Message';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { CreateMessage } from './CreateMessage';
import { useMessageContext } from '../../contexts/MessageContext';
import { setDocumentIcon } from '../../routes/configuration/spf-departmental-archive/MergeComponents/utils/switchIcon';

const { Text, Title } = Typography;

/**
 * Renders a card component for displaying a message.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.message - The message object.
 * @param {Object} props.form - The form object.
 * @returns {JSX.Element} The rendered card component.
 */
export const MessageCard = ({ message, cardId }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { downloadDocument } = useDownloadDocument();
  const {
    setIsCreateMessageCardOpen,
    setSelectedRecipientsValues,
    setMessageDateWhoReceiveResponse,
    isCreateMessageCardOpen
  } = useGlobalContext();
  const { form, setActiveCard, activeCard, showAllMessages } =
    useMessageContext();

  const setUsersName = (key) =>
    message.intern_message
      ? t('messages.form.nomade_intern_note')
      : message[key].map(
          (recipient) =>
            ` ${t(`messages.roles.${recipient.role.split(':')[1]}`)} - ${
              recipient?.first_name || ''
            } ${recipient?.last_name || ''}`
        );

  return (
    <>
      <Card>
        <Row gutters={[24, 24]}>
          <Col span={message.document ? 22 : 24}>
            <Text>
              {t(`messages.labels.from`, {
                user: `${t(
                  `messages.roles.${message?.author?.role.split(':')[1]}`
                )} - ${message?.author?.first_name || ''} ${
                  message?.author?.last_name || ''
                }`
              })}
            </Text>
          </Col>
          <Col span={24}>
            <Text>
              {t('messages.labels.at', {
                users: setUsersName('recipients')
              })}
            </Text>
          </Col>
          {message?.copy?.length ? (
            <Col span={24}>
              <Text>
                {t(`messages.labels.copy`, {
                  users: setUsersName('copy')
                })}
              </Text>
            </Col>
          ) : null}
          {message?.mission ? (
            <Col span={8}>
              <Tag color={missionTag[message.mission]} key={message.mission}>
                {t(`missions.tags.${message.mission}`)}
              </Tag>
            </Col>
          ) : null}
          <Col span={8}>
            <Row justify="center">
              <Title level={5}>{message?.subject || ''}</Title>
            </Row>
          </Col>
          <Col span={8}>
            <Text>
              {t('messages.labels.date', {
                date: dayjs(message.created_at).format('DD-MM-YYYY [à] HH[h]mm')
              })}
            </Text>
          </Col>
          {message?.message_date_who_receive_response ? (
            <Col span={24}>
              <Text>
                {t('messages.labels.message_date_who_receive_response', {
                  date: dayjs(message.message_date_who_receive_response).format(
                    'DD-MM-YYYY [à] HH[h]mm'
                  )
                })}
              </Text>
            </Col>
          ) : null}
          <Col span={24}>
            <Text>
              <div dangerouslySetInnerHTML={{ __html: message.content }} />
            </Text>
          </Col>
          {message.document
            ? (message?.document?.files || []).map((file) => (
                <Col span={2}>
                  <Button
                    type="link"
                    onClick={() =>
                      downloadDocument({
                        _id: file._id,
                        metadata: file.metadata,
                        contentType: file.contentType
                      })
                    }
                  >
                    {setDocumentIcon(file.contentType)}
                    <Text>{file.metadata.originalName}</Text>
                  </Button>
                </Col>
              ))
            : null}
          <Col span={24}>
            <Row
              align="center"
              justify={message.require_response ? 'space-between' : 'end'}
            >
              {message.require_response ? (
                <Col>
                  <Tag color="yellow">
                    {t('messages.show.require_response')}
                  </Tag>
                </Col>
              ) : null}
              {message.author &&
              message.author._id !== user._id &&
              !showAllMessages ? (
                <Col>
                  <Button
                    onClick={() => {
                      setActiveCard(message._id);
                      Message.setFieldsValues({
                        form,
                        message,
                        setMessageDateWhoReceiveResponse,
                        setSelectedRecipientsValues,
                        setIsCreateMessageCardOpen
                      });
                    }}
                  >
                    {t('messages.buttons.reply')}
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Card>
      {isCreateMessageCardOpen && activeCard === cardId ? (
        <CreateMessage />
      ) : null}
    </>
  );
};

MessageCard.propTypes = {
  message: PropTypes.shape({
    author: PropTypes.shape({
      role: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      _id: PropTypes.string
    }),
    _id: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.shape({})),
    copy: PropTypes.arrayOf(PropTypes.shape({})),
    mission: PropTypes.string,
    subject: PropTypes.string,
    created_at: PropTypes.string,
    content: PropTypes.string,
    message_date_who_receive_response: PropTypes.string,
    intern_message: PropTypes.bool,
    require_response: PropTypes.bool,
    document: PropTypes.shape({
      files: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          metadata: PropTypes.shape({
            originalName: PropTypes.string
          }),
          contentType: PropTypes.string
        })
      )
    })
  }).isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  cardId: PropTypes.string.isRequired
};
