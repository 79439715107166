import { useNavigate } from 'react-router-dom';
import StepsSVGWrapper from '../../../../../assets/images/step-wrapper.svg';
import { ServicesGenericCollectiveOwnershipList } from '../../components/ServicesCollectiveOwnerships/ServicesGenericCollectiveOwnershipList';
import { useColumns } from './useColumns';

/**
 * @component
 * Renders a list of collective ownerships for a syndic.
 * @returns {JSX.Element} The rendered component.
 */
export const ComplianceElanCollectiveOwnershipList = () => {
  const columns = useColumns();
  const navigate = useNavigate();

  return (
    <ServicesGenericCollectiveOwnershipList
      missions={['PCS_1', 'PCS_2', 'PCS_3']}
      tradKey="COMPLIANCE_LOI_ELAN"
      additionalHeader={
        <img src={StepsSVGWrapper} alt="steps" style={{ width: '97.5%' }} />
      }
      customColumns={columns}
      onDoubleClickAction={{
        action: (e) => {
          navigate(`show/${e._id}`);
        }
      }}
    />
  );
};
