import dayjs from 'dayjs';
import { BaseModel } from './BaseModel';

export class Task extends BaseModel {
  static resourceName = 'tasks';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    try {
      const { data } = await this.dispatchAPI('GET', {
        url: `${Task.resourceName}/${id}?populate=${populate}&${extraQuery}`
      });
      return data;
    } catch (e) {
      return e;
    }
  }

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    try {
      const { data } = await this.dispatchAPI('GET', {
        url: `${Task.resourceName}?populate=${populate}&${extraQuery}`
      });
      return data;
    } catch (e) {
      return e;
    }
  }

  /**
   * Creates a new resource with the given values.
   * @function
   * @async
   * @param {Object} options - The options for creating the resource.
   * @param {Object} options.values - The values for the new resource.
   * @returns {Promise<Object>} A promise that resolves to the created resource data.
   */
  static async createResource({ values }) {
    try {
      const formData = new FormData();
      formData.append('values', JSON.stringify(values));

      const { data } = await this.dispatchAPI('POST', {
        url: `${Task.resourceName}/`,
        body: formData
      });

      return data;
    } catch (e) {
      return e;
    }
  }

  /**
   * Updates the resource with the given values.
   * @function
   * @async
   * @param {Object} options - The options for updating the resource.
   * @param {string} options.id - The ID of the resource to update.
   * @param {Object} options.values - The values to update the resource with.
   * @returns {Promise<Object>} A promise that resolves to the updated resource data.
   */
  static async updateResource({ id, values }) {
    try {
      let newValues;
      // if date in values, add index to values depending on the day of the week (monday = 0, sunday = 6...)
      if (values.date) {
        const dayOfWeek = dayjs(values.date).day() - 1;
        newValues = { ...values, day_index: dayOfWeek };
      } else {
        newValues = values;
      }

      const formData = new FormData();
      formData.append('values', JSON.stringify(newValues));

      const { data } = await this.dispatchAPI('PATCH', {
        url: `${Task.resourceName}/${id}`,
        body: formData
      });

      return data;
    } catch (e) {
      return e;
    }
  }

  /**
   * Deletes a resource by its ID.
   * @function
   * @async
   * @param {string} id - The ID of the resource to delete.
   * @returns {Promise<void>} A promise that resolves when the resource is deleted.
   */
  static async deleteResource(id) {
    try {
      await this.dispatchAPI('DELETE', { url: `${Task.resourceName}/${id}` });
      return true;
    } catch (e) {
      return e;
    }
  }
}
