/**
 * Processes the wordings of an invoice.
 * @function
 * @param {Object} params - The parameters object.
 * @param {Object} params.invoice - The invoice object.
 * @returns {Object} The processed wordings object.
 */
export const processWordings = ({ invoice }) => {
  const updatedWordings = invoice.wordings?.length
    ? invoice.wordings.map((wording) => ({
        ...wording,
        fee: `${parseFloat(wording.fee).toFixed(2)} €`,
        total: `${parseFloat(wording.total).toFixed(2)} €`,
        disbursement: `${parseFloat(wording.disbursement).toFixed(2)} €`,
        tva: wording.tva
          ? `${parseFloat(wording.tva).toFixed(2)} €`
          : `${parseFloat(wording.total * (wording.tva_percentage / 100))
              .toFixed(2)
              .toString()} €`,
        total_ttc: wording.tva_percentage
          ? `${parseFloat(
              (wording.total * wording.tva_percentage) / 100 +
                wording.disbursement
            )
              .toFixed(2)
              .toString()} €`
          : `${parseFloat(wording.total + wording.tva + wording.disbursement)
              .toFixed(2)
              .toString()} €`
      }))
    : [];
  return updatedWordings;
};
