import { PcajpArray } from '../../../../../models/PcajpArray';

/**
 * Handles the submission of PcajpArray.
 * @function
 * @async
 * @param {Object} options - The options object.
 * @param {Object} options.values - The values to be submitted.
 * @param {Object} options.currentPcajpArray - The current PcajpArray object.
 * @param {Function} options.setIsSubmitting - The function to set the submitting state.
 * @param {Function} options.setForceRefresh - The function to set the refresh state.
 * @param {string} options.id - The ID of the mission.
 * @returns {Promise<void>} - A promise that resolves when the submission is complete.
 */
export const handleSubmitPcajpArray = async ({
  values,
  currentPcajpArray,
  setIsSubmitting,
  setForceRefresh,
  id,
  extraDatas,
  message,
  t
}) => {
  let messageContent = '';

  if (extraDatas.is_validated) {
    messageContent = t('missions.messages.pcajp_array_validated');
  } else if (extraDatas.is_draft) {
    messageContent = t('missions.messages.pcajp_array_draft');
  } else {
    messageContent = t('missions.messages.pcajp_array_published');
  }

  const updatedValues = {
    version: currentPcajpArray.version,
    role: currentPcajpArray.role,
    visible_to_role: extraDatas.visible_to_role,
    is_draft: extraDatas.is_draft,
    is_validated: extraDatas.is_validated,
    data: values
  };
  await PcajpArray.patchResource({
    id: currentPcajpArray._id,
    values: updatedValues,
    setIsSubmitting,
    setForceRefresh,
    extraQuery: `missionId=${id}`,
    message,
    messageContent
  });
};
