import { createFileFromJSON } from './createFileFromJSON';

/**
 * Handles file actions on data fetch.
 *
 * @async
 * @function
 * @param {Object} data - The data from the fetch operation.
 * @param {Function} setFieldsFileList - The setter function for fields file list state.
 * @param {Function} setDraggerFilesList - The setter function for dragger files list state.
 * @param {Function} setFilesConfiguration - The setter function for files configuration state.
 * @param {Function} dispatchAPI - The dispatch function to make API requests.
 * @param {Function} message - The function to display messages.
 * @returns {Promise<void>} A promise that resolves when all file actions have been handled.
 * @throws {Error} If there is an error during file creation.
 */
export const handleFileActionsOnFetch = async (
  data,
  setFieldsFileList,
  setDraggerFilesList,
  setFilesConfiguration,
  dispatchAPI,
  message
) => {
  const fieldsDocuments = data.documents.filter(
    (document) => document.source === 'field'
  );

  const filesListObject = {};

  const normalizedFieldsDocuments = fieldsDocuments.map(async (document) => {
    await Promise.all(
      document.files.map(async (file) => {
        const documentObject = {
          ...document,
          rawFile: file,
          file: await createFileFromJSON(file, dispatchAPI, message)
        };
        if (filesListObject[document.fileKey]) {
          filesListObject[document.fileKey].push(documentObject);
        } else {
          filesListObject[document.fileKey] = [documentObject];
        }
        return true;
      })
    );
  });
  await Promise.all(normalizedFieldsDocuments);

  setFieldsFileList((prev) => ({ ...prev, ...filesListObject }));

  const draggerDocuments = data.documents.filter(
    (document) => document.source === 'dragger'
  );
  const draggerNormalizedDocuments = draggerDocuments.flatMap((document) =>
    document.files.map((file) => ({
      ...document,
      name: file.metadata.originalName
    }))
  );
  setDraggerFilesList(draggerNormalizedDocuments);

  setFilesConfiguration([
    ...draggerDocuments.flatMap((document) =>
      document.files.map((file) => ({
        fileKey: document.fileKey,
        name: file.metadata.originalName,
        source: document.source
      }))
    ),
    ...fieldsDocuments.flatMap((document) =>
      document.files.map((file) => ({
        fileKey: document.fileKey,
        name: file.metadata.originalName,
        source: document.source,
        id: document._id,
        type: file.contentType
      }))
    )
  ]);
};
