import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Steps, message as Message, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components';
import { MainInformationsForm } from './forms/MainInformations';
import { PlansForm } from './forms/Plans';
import { NotesForm } from './forms/Notes';
import { Permissions as PermissionsForm } from '../components/Permissions/Permissions';
import { changeForm } from '../utils/changeForm';
import { onFinishForm } from '../utils/onFinishForm';
import { onSubmitForm } from '../utils/onSubmitForm';
import { getEnums } from '../utils/getEnums';
import { getProfessionals } from '../utils/getProfessionals';
import { setDynamicFields } from '../utils/setDynamicFields';
import { useConfig } from '../utils/createUpdateConfig';

/**
 * `CreateUpdateCollectiveOwnership` component.
 *
 * This component provides a multi-step form interface for creating or updating
 * collective ownership details. It renders different forms based on the current
 * step, including main information, plans, notes, and permissions.
 *
 * Props:
 * @prop {string} purpose - Required. Determines the purpose of the form, which
 *                          can be either 'create' or 'update'. This affects the
 *                          form's title and the API endpoint used upon submission.
 *
 * Usage:
 * <CreateUpdateCollectiveOwnership purpose="create" />
 * or
 * <CreateUpdateCollectiveOwnership purpose="edit" />
 *
 * The component uses Ant Design's `Steps` for navigation between different form
 * sections. Each section is represented by a sub-component: `MainInformationsForm`,
 * `PlansForm`, `NotesForm`, and `PermissionsForm`. The forms are controlled components
 * using Ant Design's `Form` component with a custom hook for managing form state and
 * submission logic. Additionally, the component handles loading of enumeration values
 * and professional details required for select dropdowns in the form.
 *
 * The component also uses custom hooks from the application's context for authentication
 * and global state management, as well as utility hooks for handling API calls and error
 * messages.
 */

export const CreateUpdateCollectiveOwnership = ({ purpose }) => {
  const [form] = Form.useForm();
  const queryParameter = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const { id } = useParams();
  const currentPage = queryParameter.get('current');
  const missionId = queryParameter.get('missionId');
  const navigate = useNavigate();
  const { user, dispatchAPI } = useAuthContext();
  const { syndics } = useGlobalContext();
  const { message } = useErrorMessage();
  const [current, setCurrent] = useState(parseInt(currentPage, 10) || 0);
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [professionals, setProfessionals] = useState({});
  const [formData, setFormData] = useState({});
  const [syndic, setSyndic] = useState(null);
  const [manager, setManager] = useState(null);
  const [syndicOptions, setSyndicOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [permissions, setPermissions] = useState({
    lawyers: [],
    notaries: [],
    surveyors: [],
    others: []
  });
  const editPermissions = !['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(
    user.role
  );

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums({ dispatchAPI, setEnums, message });
    await getProfessionals({
      dispatchAPI,
      message,
      setProfessionals
    });
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    if (!editPermissions) {
      navigate('/collective-ownerships');
    }
  }, [editPermissions, user]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  useEffect(() => {
    setSyndicOptions(syndics);
  }, [syndics]);

  useEffect(() => {
    if (professionals.managers) {
      setManagerOptions(professionals.managers);
    }
  }, [professionals]);

  useEffect(() => {
    setDynamicFields({
      manager,
      syndics,
      setSyndicOptions,
      syndic,
      professionals,
      setManagerOptions
    });
  }, [manager, syndic]);

  const forms = [
    <MainInformationsForm
      onFinish={() =>
        onFinishForm({
          form,
          setFormData,
          setCurrent,
          current,
          Message,
          t
        })
      }
      onSubmit={() =>
        onSubmitForm({
          values: formData,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          navigate,
          form,
          permissions,
          missionId,
          user
        })
      }
      initialValues={formData}
      form={form}
      enums={enums}
      isFieldsLoading={isFieldsLoading}
      setCurrent={setCurrent}
      current={current}
      setSyndic={setSyndic}
      setManager={setManager}
      managerOptions={managerOptions}
      syndicOptions={syndicOptions}
      t={t}
      key="main-informations"
      setPermissions={setPermissions}
    />,
    <PlansForm
      onFinish={() =>
        onFinishForm({
          form,
          setFormData,
          setCurrent,
          current,
          Message,
          t
        })
      }
      onSubmit={() =>
        onSubmitForm({
          values: formData,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          navigate,
          form,
          permissions,
          missionId,
          user
        })
      }
      initialValues={formData}
      form={form}
      enums={enums}
      isFieldsLoading={isFieldsLoading}
      setCurrent={setCurrent}
      current={current}
      professionals={professionals}
      key="plans"
    />,
    <NotesForm
      onFinish={() =>
        onFinishForm({
          form,
          setFormData,
          setCurrent,
          current,
          Message,
          t
        })
      }
      onSubmit={() =>
        onSubmitForm({
          values: formData,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          navigate,
          form,
          permissions,
          missionId,
          user
        })
      }
      initialValues={formData}
      form={form}
      enums={enums}
      isFieldsLoading={isFieldsLoading}
      setCurrent={setCurrent}
      current={current}
      key="notes"
    />,
    <PermissionsForm
      onFinish={() =>
        onFinishForm({
          form,
          setFormData,
          setCurrent,
          current,
          Message,
          t
        })
      }
      onSubmit={() =>
        onSubmitForm({
          values: formData,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          navigate,
          form,
          permissions,
          missionId
        })
      }
      initialValues={formData}
      form={form}
      enums={enums}
      isFieldsLoading={isFieldsLoading}
      setCurrent={setCurrent}
      current={current}
      permissions={permissions}
      setPermissions={setPermissions}
      professionals={professionals}
      t={t}
      purpose="edit"
      key="permissions"
    />
  ];

  const steps = [
    {
      title: t('collective-ownerships.form.titles.main_informations'),
      icon: <CheckCircleOutlined />,
      key: 'main-informations'
    },
    {
      title: t('collective-ownerships.form.titles.plans'),
      icon: <CheckCircleOutlined />,
      key: 'plans'
    },
    {
      title: t('collective-ownerships.form.titles.notes'),
      icon: <CheckCircleOutlined />,
      key: 'notes'
    },
    {
      title: t('collective-ownerships.form.titles.permissions'),
      icon: <CheckCircleOutlined />,
      key: 'permissions'
    }
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title
  }));

  const config = useConfig({ setPermissions });

  const customErrorMessageHandler = (e) =>
    message(t(`errors.message.${e.message}`));

  return (
    <>
      <PageHeaderCustom
        title={t(`collective-ownerships.form.title.${purpose}`)}
      />
      <ContentCustom>
        <Steps
          current={current}
          items={items}
          onChange={(e) =>
            changeForm({
              nextStep: e,
              form,
              setCurrent,
              Message,
              t,
              setFormData
            })
          }
          style={{ marginBottom: 24 }}
        />
        <CreateUpdateContainer
          customFormInstance={form}
          config={config}
          formExtra={forms[current]}
          isFieldsLoading={false}
          purpose={purpose}
          baseUrl="collective-ownerships"
          resource="collective-ownerships"
          withFilesManager={false}
          withEnums={false}
          withCustomButtons
          withCustomPageHeader
          customErrorMessage={customErrorMessageHandler}
        />
      </ContentCustom>
    </>
  );
};

CreateUpdateCollectiveOwnership.propTypes = {
  purpose: PropTypes.string.isRequired
};
