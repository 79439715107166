import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row, Spin, Button, Space, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { PageHeaderCustom } from '../../../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../../../components';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../../utils/constants/formLayout';
import {
  routes,
  subRoutes
} from '../../../../../utils/constants/routes/adminRoutes';
import { useSteps } from './JoditEditor/useSteps';
import { handleActiveKey } from './JoditEditor/utils/handleActiveKey';
import { handleDisplaySaveButton } from './JoditEditor/utils/displaySaveButton';
import { useFaqCustomError } from './utils/useFaqCustomError';

/**
 * The `DocEditor` component serves as a document editor, allowing users to create or edit template documents.
 * It provides functionalities such as loading the current template document details and configurations,
 * changing steps between form info and editor view, and handling document submission.
 *
 * @component
 * @param {Object} props - Properties passed down from parent component.
 * @param {string} props.purpose - Purpose of the document editor which can either be "create" or "edit".
 * @param {string} props.type - The type of template document.
 *
 * @example
 *
 * return <DocEditor purpose="create" />;
 * @returns {JSX.Element} A React component which contains the form and the editor for creating/editing template documents.
 */
export const DocEditor = ({ purpose, type }) => {
  const [form] = Form.useForm();
  const { dispatchAPI, daycare } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { verifyUsedOrderErrorFAQ } = useFaqCustomError();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [template, setTemplate] = useState();
  const [pickedConfiguration, setPickedConfiguration] = useState();
  const [step, setStep] = useState('config');
  const [tags, setTags] = useState([]);

  const [formValues, setFormValues] = useState({
    title: '',
    status: '',
    default_template: '',
    header: false,
    footer: false
  });
  const [content, setContent] = useState({
    header: '',
    body: '',
    footer: ''
  });

  const steps = useSteps(
    status,
    configurations,
    pickedConfiguration,
    setPickedConfiguration,
    template,
    content,
    setContent,
    purpose,
    isSubmitting,
    formValues,
    tags,
    t,
    type
  );

  const getResource = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `template-documents/${id}`
      });
      setTemplate(data);
      setContent({
        header: data.header || '',
        body: data.body || '',
        footer: data.footer || ''
      });
      setFormValues({
        ...data,
        header: !!data?.header,
        footer: !!data?.footer
      });
      setPickedConfiguration(data.use_case);
      form.setFieldsValue({
        ...data,
        configuration: data.use_case
      });
    } catch (e) {
      message(e);
    }
  }, []);

  const fetchTags = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `template-documents/tags/${pickedConfiguration}?type=${type}`
      });
      setTags(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (pickedConfiguration) {
      (async () => {
        await fetchTags();
      })();
    }
  }, [pickedConfiguration]);

  const getEnums = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/template-documents/enums'
      });
      setConfigurations(data.configurations[type]);
      setStatus(data.status);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (purpose === 'edit') {
      setIsLoading(true);
      if (!isLoading && configurations)
        (async () => {
          await getResource();
        })();
    }
  }, [getResource]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getEnums();
    })();
  }, []);

  const createTemplateDocument = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: 'template-documents',
        body: {
          ...formValues,
          type,
          use_case: pickedConfiguration,
          body: content.body,
          header: content.header,
          footer: content.footer,
          daycare
        }
      });
      navigate(
        `${routes.CONFIGURATIONS}${subRoutes.CONFIGURATIONS[type]}/show/${data?._id}`
      );
    } catch (e) {
      verifyUsedOrderErrorFAQ(e);
      setIsSubmitting(false);

      message(e);
    }
  };

  const updateTemplateDocument = async () => {
    setIsSubmitting(true);

    try {
      await dispatchAPI('PATCH', {
        url: `template-documents/${id}`,
        body: {
          values: JSON.stringify({
            ...formValues,
            type,
            use_case: pickedConfiguration,
            body: content.body,
            header: content.header,
            footer: content.footer,
            daycare
          })
        }
      });
      navigate(
        `${routes.CONFIGURATIONS}${subRoutes.CONFIGURATIONS[type]}/show/${id}`
      );
    } catch (e) {
      verifyUsedOrderErrorFAQ(e);
      setIsSubmitting(false);
      message(e);
    }
  };

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateTemplateDocument(values);
    if (purpose === 'create') await createTemplateDocument(values);
  };

  const displaySaveButton = handleDisplaySaveButton(step, steps);

  return (
    <>
      <PageHeaderCustom
        title={t(`template-documents.form.${purpose}`, {
          title: t(`template-documents.form.${template?.use_case}`)
        })}
      />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form
            {...formItemLayout}
            layout="horizontal"
            form={form}
            onFinish={handleSubmit}
            onValuesChange={(_, allValues) => setFormValues(allValues)}
          >
            <Tabs
              style={{ margin: '10px 0px' }}
              items={steps}
              defaultActiveKey="config"
              activeKey={step}
              onChange={(key) => setStep(key)}
            />

            {displaySaveButton ? (
              <Form.Item {...tailFormItemLayout}>
                <Row justify="center">
                  <Button
                    style={{ margin: '0 10px' }}
                    type="link"
                    danger
                    onClick={() => handleActiveKey(steps, setStep, 'backward')}
                  >
                    <ArrowLeftOutlined />
                    {`${t('buttons.previous_step')} `}
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                  >
                    <CheckOutlined />
                    {`${t('buttons.save')} `}
                  </Button>
                </Row>
              </Form.Item>
            ) : (
              <Row justify="center">
                <Col
                  xs={{
                    span: 24,
                    offset: 0
                  }}
                  sm={{
                    span: 14,
                    offset: 8
                  }}
                  md={{ span: 12, offset: 8 }}
                  lg={{ span: 10, offset: 6 }}
                >
                  <Button
                    style={{ margin: '0 10px' }}
                    type="link"
                    danger
                    onClick={() => {
                      if (step === 'config') {
                        return navigate(-1);
                      }

                      return handleActiveKey(steps, setStep, 'backward');
                    }}
                  >
                    <Space>
                      {step === 'config' ? (
                        <>
                          <CloseOutlined /> {`${t('buttons.cancel')} `}
                        </>
                      ) : (
                        <>
                          <ArrowLeftOutlined />{' '}
                          {`${t('buttons.previous_step')}`}
                        </>
                      )}
                    </Space>
                  </Button>
                  <Button
                    style={{ margin: '0 10px' }}
                    type="primary"
                    onClick={() => handleActiveKey(steps, setStep, 'forward')}
                    disabled={!pickedConfiguration}
                  >
                    <ArrowRightOutlined />
                    {t('buttons.next_step')}
                  </Button>
                  <Button
                    type="secondary"
                    htmlType="submit"
                    loading={isSubmitting}
                  >
                    <CheckOutlined />
                    {`${t('buttons.save')} `}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
};

DocEditor.propTypes = {
  purpose: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
