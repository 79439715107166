/* eslint-disable react/forbid-prop-types */
import { Card, Col, Row, Typography, Alert } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../../components';
import { useConfig } from './utils/createUpdateConfig';
import { GetEnums } from '../../../../utils/getEnums';

const { Text } = Typography;

/**
 * Component that renders the form to create or update a collective ownership form.
 *
 * @component
 * @param {Object} props - The props of the component.
 * @param {string} props.purpose - The purpose of the form.
 * @param {boolean} props.isEditable - Indicates if the form is editable.
 * @returns {React.Component} The component
 */
export const CreateUpdateCollectiveOwnershipForm = ({
  purpose = '',
  isEditable = false
}) => {
  const { t } = useTranslation();
  const [enums, setEnums] = useState([]);
  const { getEnums } = GetEnums();
  const { state } = useLocation();

  const getSelectOptions = useCallback(async () => {
    const enumsDatas = await getEnums({
      resource: 'collective-ownership-forms'
    });
    setEnums(enumsDatas);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const config = useConfig({
    syndic: state.syndic,
    collectiveOwnership: state.collectiveOwnership,
    mission: state.mission
  });
  const { fields } = useFields({ enums, isEditable, mission: state.mission });

  return (
    <Card title={t('collective-ownership-forms.form.title')}>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text>
              {`${t('collective-ownership-forms.form.syndicName')} : ${
                state.syndic?.society_name || '-'
              }`}
            </Text>
          </Col>
          <Col span={24}>
            <Text>
              {`${t(
                'collective-ownership-forms.form.collectiveOwnershipAdress'
              )} : ${state.collectiveOwnership?.name || '-'}`}
            </Text>
          </Col>
          {state.mission.co_owner_form_status === 'CLOSED' ? (
            <Col>
              <Alert
                showIcon
                message={t('collective-ownership-forms.messages.closed_form')}
                type="warning"
              />
            </Col>
          ) : null}
        </Row>
      </Card>
      <CreateUpdateContainer
        config={config}
        fields={fields}
        purpose={purpose}
        baseUrl="collective-ownership-forms"
        resource="collective-ownership-forms"
        layout="vertical"
        withEnums={false}
        withFilesManager={false}
        withCustomPageHeader
        disabledSubmit={
          isEditable || state.mission.co_owner_form_status === 'CLOSED'
        }
      />
    </Card>
  );
};

CreateUpdateCollectiveOwnershipForm.propTypes = {
  purpose: Proptypes.string,
  isEditable: Proptypes.bool
};

CreateUpdateCollectiveOwnershipForm.defaultProps = {
  purpose: '',
  isEditable: false
};
