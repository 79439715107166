export const getUser = async ({
  setIsLoading,
  dispatchAPI,
  id,
  setUser,
  message
}) => {
  setIsLoading(true);
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/users/${id}`
    });
    setUser(data);
  } catch (e) {
    message(e);
  }
  setIsLoading(false);
};
