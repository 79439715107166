import {
  Card,
  Skeleton,
  List,
  Row,
  Col,
  Typography,
  Popconfirm,
  Button,
  Collapse
} from 'antd';
import dayjs from 'dayjs';
import {
  DeleteOutlined,
  WarningOutlined,
  TableOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PcsPreProduction } from '../../../../models/PcsPreProduction';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import ChargingGrid from '../../../charging-grid/ChargingGrid';

const { Text } = Typography;

/**
 * Renders a list of documents for a PCS pre-production.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.pcsPreProduction - The PCS pre-production object.
 * @param {Function} props.setPcsPreProduction - Function to set the PCS pre-production object.
 * @param {boolean} props.isLoading - Indicates if the data is loading.
 * @param {Function} props.setForceRefresh - Function to force refresh the data.
 * @param {boolean} props.forceRefresh - Indicates if the data should be forcefully refreshed.
 * @returns {JSX.Element} The rendered component.
 */
export const Documents = ({
  pcsPreProduction,
  setPcsPreProduction,
  isLoading,
  setForceRefresh,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { viewDocument, downloadDocument } = useDownloadDocument();

  const data = [
    {
      title: t('pcs-pre-productions.show.cards.titles.contracts'),
      fileKey: 'contracts'
    },
    {
      title: t('pcs-pre-productions.show.cards.titles.documents'),
      fileKey: 'documents'
    },
    {
      title: t('pcs-pre-productions.show.cards.titles.rcp'),
      fileKey: 'rcp'
    },
    {
      title: t('pcs-pre-productions.show.cards.titles.charging_grids'),
      fileKey: 'charging_grids'
    }
  ];

  return (
    <Card title={t('pcs-pre-productions.show.cards.titles.documents')}>
      <Skeleton loading={isLoading} active>
        <Row>
          <Col span={24}>
            <List
              grid={{ gutter: 16, column: 2 }}
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <Card title={item.title} bordered={false}>
                    {pcsPreProduction?.documents?.length
                      ? pcsPreProduction.documents
                          .filter((el) => el.fileKey === item.fileKey)
                          .map((doc) => (
                            <Row key={doc._id} justify="space-between">
                              {doc.files.map((file) => (
                                <Row>
                                  <Col>
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        file.type === 'application/pdf'
                                          ? viewDocument(file)
                                          : downloadDocument({
                                              _id: file._id,
                                              metadata: file.metadata,
                                              contentType: file.contentType
                                            })
                                      }
                                    >
                                      <Text>
                                        {file?.metadata?.originalName}
                                      </Text>
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Popconfirm
                                      title={t('popconfirm.titles.file')}
                                      okText={t('popconfirm.onOk.file')}
                                      okButtonProps={{ type: 'danger' }}
                                      cancelText={t('popconfirm.onCancel.file')}
                                      onConfirm={() =>
                                        PcsPreProduction.deleteFileById({
                                          resourceId: id,
                                          setForceRefresh,
                                          forceRefresh,
                                          fileId: file._id
                                        })
                                      }
                                      icon={<WarningOutlined />}
                                    >
                                      <DeleteOutlined type="delete" />
                                    </Popconfirm>
                                  </Col>
                                </Row>
                              ))}
                            </Row>
                          ))
                      : null}
                  </Card>
                </List.Item>
              )}
            />
          </Col>
          <Col span={24}>
            <Collapse
              items={[
                {
                  key: '1',
                  label: (
                    <Row gutter={[12, 12]}>
                      <Col>
                        <TableOutlined />
                      </Col>
                      <Col>
                        <Text>
                          {t('pcs-pre-productions.show.tabs.charging_grid', {
                            date: dayjs(pcsPreProduction?.created_at).format(
                              'YYYY'
                            )
                          })}
                        </Text>
                      </Col>
                    </Row>
                  ),
                  children: (
                    <ChargingGrid
                      resourceDatas={pcsPreProduction}
                      setResourceDatas={setPcsPreProduction}
                      isLoading={isLoading}
                    />
                  )
                }
              ]}
            />
          </Col>
        </Row>
      </Skeleton>
    </Card>
  );
};

Documents.propTypes = {
  pcsPreProduction: PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.shape({})),
    created_at: PropTypes.string
  }),
  setPcsPreProduction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool
};

Documents.defaultProps = {
  pcsPreProduction: {},
  isLoading: false,
  forceRefresh: false
};
