import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Space, Switch, Tabs, Typography } from 'antd';
import { useState } from 'react';
import { ProjectOutlined, TableOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { useItems } from './useItems';
import { PcsPreProductionsKanbanView } from './ShowKanban/PcsPreProductionsKanbanView';

const { Text } = Typography;

/**
 * Renders the PcsPreProductions component.
 *
 * @component
 * @returns {JSX.Element} The rendered PcsPreProductions component.
 */
export const PcsPreProductions = () => {
  const { t } = useTranslation();
  const { items } = useItems({});
  const [showView, setShowView] = useState('table');
  const location = useLocation();
  return (
    <>
      <PageHeaderCustom title={t('pcs-pre-productions.title')} />

      <ContentCustom>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            background: 'none',
            border: 'none'
          }}
        >
          <Row justify="center" gutter={[16, 16]}>
            <Col>
              <Space>
                <TableOutlined />
                <Text>{t('pcs-pre-productions.view.table')}</Text>
              </Space>
            </Col>
            <Col>
              <Switch
                onChange={(e) => {
                  if (e) {
                    setShowView('kanban');
                  } else {
                    setShowView('table');
                  }
                }}
              />
            </Col>
            <Col>
              <Space>
                <ProjectOutlined />
                <Text>{t('pcs-pre-productions.view.kanban')}</Text>
              </Space>
            </Col>
          </Row>
        </Card>
        {showView === 'table' ? (
          <Tabs
            items={items}
            defaultActiveKey={
              location.state && location.state.key ? location.state.key : '2'
            }
          />
        ) : (
          <PcsPreProductionsKanbanView />
        )}
      </ContentCustom>
    </>
  );
};
