/**
 * Fetches the enums for the price system configuration.
 *
 * @param {Object} options - The options for fetching the enums.
 * @param {Function} options.dispatchAPI - The dispatchAPI function for making API requests.
 * @param {Function} options.message - The function for displaying error messages.
 * @param {Function} options.setItemsTitle - The function for setting the title of items.
 * @param {Function} options.setEnums - The function for setting the enums.
 * @returns {Promise<void>} - A promise that resolves when the enums are fetched.
 */
export const getEnums = async ({
  dispatchAPI,
  message,
  setItemsTitle,
  setEnums
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/price-systems/enums'
    });

    const updatedData = data.missions.filter(
      (mission) => !['PCS_2'].includes(mission)
    );

    if (setItemsTitle) {
      setItemsTitle(updatedData);
    }
    setEnums(updatedData);
  } catch (error) {
    message(error);
  }
};
