import { Checkbox, Form, Input, Col, Button, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';

export const useFields = () => {
  const { t } = useTranslation();
  const { requestedDocuments } = useMissionContext();

  const fields = [
    {
      noLabel: true,
      name: ['desired_documents'],
      input: (requestedDocuments || []).map((obj) => (
        <Form.Item
          key={obj._id}
          name={['desired_documents', obj._id]}
          valuePropName="checked"
        >
          <Checkbox>{obj.wording}</Checkbox>
        </Form.Item>
      ))
    },
    {
      noLabel: true,
      name: ['desired_documents'],
      input: (
        <Form.List name={['desired_documents', 'other_documents']}>
          {(listFields, { add, remove }) => (
            <>
              {listFields.map(({ key, name, ...restField }) => (
                <Row>
                  <Col span={22}>
                    <Form.Item {...restField} key={key} name={[name]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Button type="link" onClick={() => remove(name)}>
                      <DeleteOutlined />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Col span={24}>
                <Button type="primary" onClick={() => add()}>
                  {t('missions.buttons.add_field')}
                </Button>
              </Col>
            </>
          )}
        </Form.List>
      )
    }
  ];

  return { fields };
};
