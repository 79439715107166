import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ListResource, PageHeaderCustom } from '../../../../../components';
import { useColumns } from './useColumns';
import { Order } from '../../../../../models/Order';
import { CollectiveOwnership } from '../../../../../models/CollectiveOwnership';
import { Mission } from '../../../../../models/Mission';

/**
 * LisPLanRequestsReview component manages the review of plan requests for missions.
 * It handles the process of updating the plan status and creating orders based on the plan.
 *
 * @component
 * @returns {JSX.Element} The rendered LisPLanRequestsReview component.
 */
export const LisPLanRequestsReview = () => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState({});
  const [expeditor, setExpeditor] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [mission, setMission] = useState({});
  const columns = useColumns({ setPlan, setExpeditor, setMission });

  const handlePlanChange = async () => {
    if (plan._id) {
      await Mission.patchPlanRequest({
        missionId: mission._id,
        values: plan,
        planRequestId: plan._id,
        lastStep: true
      });

      if (plan.status === 'PLANS_AVAILABLE') {
        const concerned_plan =
          await CollectiveOwnership.getSpecificPlanFromCollectiveOwnership(
            plan.collective_ownership_plan,
            mission
          );
        const values = {
          name: 'Plan',
          type: 'TO_BE_ORDERED',
          mission: mission._id,
          expeditor,
          status: 'TO_BE_ORDERED',
          added_date: plan.date,
          ref_doc: `${concerned_plan.plan_volume}-${concerned_plan.plan_number}`,
          costs: 0,
          history: [
            {
              type: 'POST',
              date: new Date(),
              description: 'ORDER_CREATED'
            }
          ]
        };

        await Order.createOrder({ values });
      }
      setForceRefresh(!forceRefresh);
    }
  };

  useEffect(() => {
    (async () => {
      await handlePlanChange();
    })();
  }, [plan]);

  return (
    <>
      <PageHeaderCustom
        title={t('plan_requests_review.titles.plan_requests_review')}
      />
      <ListResource
        resourceName="missions/plan-requests-review"
        resourceModelName="Mission"
        populate="syndic collective_ownership"
        withPageHeader={false}
        columns={columns}
        withCreateButton={false}
        extraQuery=""
        deleteAction={false}
        editAction={false}
        scroll={{ x: 'max-content' }}
        customActionColumn
        forceRefresh={forceRefresh}
      />
    </>
  );
};
