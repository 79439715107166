import { Form, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * SearchBar component for activity monitoring.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - Function to set the filters.
 * @param {Array} props.usersList - List of users.
 * @param {Function} props.setSelectedRole - Function to set the selected role.
 * @param {boolean} props.isDataLoading - Flag indicating if data is loading.
 * @param {Object} props.enums - Object containing enums.
 * @param {Function} props.setForceRefresh - Function to force refresh.
 * @returns {JSX.Element} - The rendered SearchBar component.
 */
export const SearchBar = ({
  setFilters,
  usersList,
  setSelectedRole,
  isDataLoading,
  enums,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Form layout="inline" form={form}>
      <Form.Item name={['role']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              role: value || ''
            }));
            setSelectedRole(value);
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              role: ''
            }));
            setSelectedRole('');
            setForceRefresh((prev) => !prev);
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.role')}
        >
          {(enums || []).map((role) => (
            <Option key={role} value={role}>
              {t(`users.tags.${role.split(':')[1]}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['users']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              user: value || '' //! refacto later
            }));
            setForceRefresh((prev) => !prev);
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              user: ''
            }));
            setForceRefresh((prev) => !prev);
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.user')}
        >
          {(usersList || []).map((user) => (
            <Option key={user._id} value={user._id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['date']} label={t('activity-monitorings.labels.date')}>
        <RangePicker
          onChange={(dates) => {
            if (!dates) {
              setFilters((prev) => ({
                ...prev,
                start_date: '',
                end_date: ''
              }));
              setForceRefresh((prev) => !prev);
              return;
            }
            setFilters((prev) => ({
              ...prev,
              start_date: dates[0]?.format('YYYY-MM-DD') || '',
              end_date: dates[1]?.format('YYYY-MM-DD') || ''
            }));
            setForceRefresh((prev) => !prev);
          }}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          allowClear
          style={{ minWidth: 200 }}
        />
      </Form.Item>
    </Form>
  );
};

SearchBar.propTypes = {
  setFilters: PropTypes.func.isRequired,
  usersList: PropTypes.arrayOf(PropTypes.shape({})),
  isDataLoading: PropTypes.bool.isRequired,
  enums: PropTypes.arrayOf(PropTypes.string),
  setForceRefresh: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired
};

SearchBar.defaultProps = {
  usersList: [],
  enums: []
};
