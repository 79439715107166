/**
 * Asynchronously handles the submission of a delete form for a syndic. It attempts to delete the specified syndic via an API call.
 * On successful deletion, it closes the delete modal and triggers a refresh in the UI.
 * In case of an error during the deletion process, it displays an error message using the provided message function.
 *
 * @param {Object} options - The options for handling the delete form submission.
 * @param {Object} options.value - The form values, which may include a new syndic to reassign resources to.
 * @param {Function} options.dispatchAPI - The function used to dispatch API requests. It accepts a request method ('DELETE' in this case) and options.
 * @param {string} options.selectedSyndic - The ID of the syndic to be deleted.
 * @param {Function} options.message - Function to display error messages, typically used for error handling. It accepts an error message or status code.
 * @param {Function} options.setForceRefresh - Function to toggle a state variable that can be used to force a refresh in the UI.
 * @param {boolean} options.forceRefresh - The current state of the refresh toggle, used to determine the new state after toggling.
 * @param {Function} options.setIsDeleteModalOpen - Function to set the visibility of the delete modal. It accepts a boolean value.
 * @async
 * @returns {Promise<void>} A promise that resolves when the delete operation has been successfully completed and the UI has been updated, or when an error has been caught and handled.
 */

export const handleDeleteFormSubmit = async ({
  value,
  dispatchAPI,
  selectedSyndic,
  message,
  setForceRefresh,
  forceRefresh,
  setIsDeleteModalOpen
}) => {
  try {
    await dispatchAPI('DELETE', {
      url: `/syndics/${selectedSyndic._id}${
        value?.new_manager ? `?new_manager=${value.new_manager}` : ''
      }`
    });

    setIsDeleteModalOpen(false);
    setForceRefresh(!forceRefresh);
  } catch (e) {
    message(e);
  }
};
