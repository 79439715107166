/**
 * Determines the disabled steps based on the current mission and step type.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {Object} options.mission - The mission object.
 * @param {string} options.step_type - The step type.
 * @returns {Object|boolean} - The disabled steps object or false if no matching step is found.
 */
export const handleStepDisabled = ({ mission, step_type }) => {
  const step =
    step_type === 'principal' ? mission?.steps?.principal : mission?.steps?.sub;

  switch (step) {
    case 'ADVANCE_PAYMENT':
      return {
        ADVANCE_PAYMENT: false,
        BUILDING_FORM_ORDER: true,
        BUILDING_FORM_DELIVERY: true,
        ORDER_VERIFICATION: true,
        DOCUMENTS_DELIVERY: true,
        LAWYER_CONSULTATION: true
      };
    case 'BUILDING_FORM_ORDER':
      return {
        ADVANCE_PAYMENT: true,
        BUILDING_FORM_ORDER: true,
        BUILDING_FORM_DELIVERY: false,
        ORDER_VERIFICATION: true,
        DOCUMENTS_DELIVERY: true,
        LAWYER_CONSULTATION: true,
        MATRIX_FINALIZATION: false
      };
    case 'BUILDING_FORM_DELIVERY':
      return {
        ADVANCE_PAYMENT: false,
        BUILDING_FORM_ORDER: false,
        BUILDING_FORM_DELIVERY: false,
        ORDER_VERIFICATION: true,
        DOCUMENTS_DELIVERY: true,
        LAWYER_CONSULTATION: false
      };
    case 'ORDER_VERIFICATION':
      return {
        ADVANCE_PAYMENT: false,
        BUILDING_FORM_ORDER: false,
        BUILDING_FORM_DELIVERY: false,
        ORDER_VERIFICATION: false,
        DOCUMENTS_DELIVERY: false,
        LAWYER_CONSULTATION: false
      };
    case 'DOCUMENTS_DELIVERY':
      return {
        ADVANCE_PAYMENT: false,
        BUILDING_FORM_ORDER: false,
        BUILDING_FORM_DELIVERY: false,
        ORDER_VERIFICATION: false,
        DOCUMENTS_DELIVERY: false,
        LAWYER_CONSULTATION: false
      };
    case 'LAWYER_CONSULTATION':
      return {
        ADVANCE_PAYMENT: true,
        BUILDING_FORM_ORDER: true,
        BUILDING_FORM_DELIVERY: true,
        ORDER_VERIFICATION: true,
        DOCUMENTS_DELIVERY: true,
        LAWYER_CONSULTATION: true
      };
    case 'MATRIX_FINALIZATION':
      return {
        ADVANCE_PAYMENT: true,
        BUILDING_FORM_ORDER: true,
        BUILDING_FORM_DELIVERY: true,
        ORDER_VERIFICATION: true,
        DOCUMENTS_DELIVERY: true,
        LAWYER_CONSULTATION: true
      };
    default:
      return false;
  }
};
