/**
 * Retrieves managers from other syndics for reassignment.
 * @function
 *
 * @param {Object} options - The options object.
 * @param {Array} options.syndics - The array of syndics.
 * @param {Object} options.selectedSyndic - The selected syndic.
 * @param {Function} options.setIsFieldLoading - The function to set the field loading state.
 * @param {Function} options.setManagers - The function to set the managers.
 * @returns {void}
 */
export const getManagersForReassign = ({
  syndics,
  selectedSyndic,
  setIsFieldLoading,
  setManagers
}) => {
  setIsFieldLoading(true);
  const otherSyndics = syndics.filter(
    (item) => item._id !== selectedSyndic._id
  );

  const managersInfo = otherSyndics.flatMap((syndic) =>
    syndic.managers.map((manager) => ({
      ...manager,
      name: `${manager.last_name} ${manager.first_name} - ${syndic.society_name}`
    }))
  );

  setManagers(managersInfo);
  setIsFieldLoading(false);
};
