import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Returns an array of principal items for the Plans Research steps.
 * @hook
 * @returns {Object[]} principalItems - The array of principal items.
 * @property {string} key - The key of the item.
 * @property {string} title - The title of the item.
 * @property {boolean} disabled - Indicates if the item is disabled.
 */
export const plansResearchSteps = () => {
  const { t } = useTranslation();
  const principalSteps = [
    {
      title: t('missions.steps.FILLED_RCP_INFORMATIONS'),
      icon: <CheckCircleOutlined />,
      key: 'FILLED_RCP_INFORMATIONS',
      disabled: true
    },
    {
      title: t('missions.steps.NOTARY_OFFICE_REQUEST'),
      icon: <CheckCircleOutlined />,
      key: 'NOTARY_OFFICE_REQUEST',
      disabled: true
    },
    {
      title: t('missions.steps.PLANS_ORDERS'),
      icon: <CheckCircleOutlined />,
      key: 'PLANS_ORDERS',
      disabled: true
    },
    {
      title: t('missions.steps.PLANS_DELIVERY'),
      icon: <CheckCircleOutlined />,
      key: 'PLANS_DELIVERY',
      disabled: true
    }
  ];

  const principalItems = principalSteps.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled
  }));

  return { principalItems };
};
