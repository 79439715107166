import { Select, Input, DatePicker } from 'antd';
import { useMissionContext } from '../../../../../contexts/MissionContext';

const { Option } = Select;
const { TextArea } = Input;

/**
 * @hook
 * Custom hook that returns the fields for the notary transmission form.
 * @returns {Object} The fields object.
 */
export const useFields = () => {
  const { isLoading, professionals } = useMissionContext();

  /**
   * Function to filter options in the Select component.
   * @param {string} input - The input value to filter.
   * @param {Object} option - The option object to filter.
   * @returns {boolean} Whether the option matches the filter or not.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['notary'],
      label: 'notary_in_charge_of_case',
      key: 'notary',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isLoading}
        >
          {(professionals?.notaries || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item?.last_name || ''} ${item?.first_name || ''}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['desired_publication_date'],
      key: 'desired_publication_date',
      input: <DatePicker />
    },
    {
      name: ['comment_for_notary'],
      key: 'comment_for_notary',
      input: <TextArea />
    }
  ];

  return { fields };
};
