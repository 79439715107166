export const informationsProvidedBySyndicInputs = [
  {
    label: 'contract_completed_and_signed',
    type: 'checkbox'
  },
  {
    label: 'manager_contact_details',
    type: 'checkbox'
  },
  {
    label: 'invoice_code',
    type: 'checkbox'
  },
  {
    label: 'rcp_document_in_their_possession',
    type: 'checkbox'
  },
  {
    label: 'charging_grid_used_by_the_accountant',
    type: 'checkbox'
  },
  {
    label: 'other_information',
    type: 'input'
  }
];
