import { updateHistory } from '../../../utils/updateHistory';

export const onSubmitForm = async ({
  values,
  purpose,
  dispatchAPI,
  id,
  Message,
  t,
  setManagerModal,
  form,
  navigate,
  isDirectorManager,
  user
}) => {
  try {
    const formData = new FormData();
    const actualFormValues = await form.validateFields();
    let historic;
    const createHistoric = (type) => ({
      modelName: 'Syndic',
      type,
      author: user._id,
      pin: false
    });

    const commonValues = {
      ...values,
      ...actualFormValues
    };
    let newValues = commonValues;

    switch (purpose) {
      case 'create':
        newValues = {
          ...commonValues,
          historic: [createHistoric('POST')],
          isDirectorManager
        };
        break;
      case 'edit':
        historic = createHistoric('PATCH');
        await updateHistory({
          dispatchAPI,
          id,
          resourceName: 'syndics',
          values: historic
        });
        break;
      default:
        newValues = commonValues;
        break;
    }

    // Check if required fields in Contact step are filled
    const requiredKey = [
      'last_name',
      'first_name',
      'function',
      'email',
      'phone_number',
      'cell_phone_number'
    ];

    const isFieldMissing = requiredKey.some((key) => {
      if (!newValues?.contact?.[key]) {
        Message.error(
          t('syndics.messages.contact_required_fields', {
            field: t(`syndics.form.contact.${key}`)
          })
        );
        return true;
      }
      return false;
    });

    if (isFieldMissing) {
      return false;
    }

    formData.append('values', JSON.stringify(newValues));

    const setApiCall = () => {
      switch (purpose) {
        case 'create':
          return { url: '/syndics', action: 'POST' };
        case 'edit':
          return { url: `/syndics/${id}`, action: 'PATCH' };
        default:
          return { url: '/syndics', action: 'POST' };
      }
    };

    await dispatchAPI(setApiCall().action, {
      url: setApiCall().url,
      body: formData
    });

    Message.success(t(`syndics.messages.success.${purpose}`));
    if (purpose === 'create' && !isDirectorManager) {
      return setManagerModal(true);
    }
    return navigate(-1);
  } catch (e) {
    if (e.errorFields && e.errorFields.length) {
      return Message.error(t('syndics.messages.required_fields'));
    }
    return Message.error(t(`syndics.messages.error.${purpose}`, { error: e }));
  }
};
