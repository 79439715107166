import { SendOutlined } from '@ant-design/icons';
import { Button, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

/**
 * Renders a switcher component for displaying different buttons based on the type of mission step.
 * @param {Object} props - The component props.
 * @param {Object} props.mission - The mission object.
 * @param {Function} props.setVisible - The function to set the visibility of a component.
 * @returns {JSX.Element|null} The rendered switcher component.
 */
export const PlansButtonsSwitcher = ({ mission, setVisible }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [type, setType] = useState(null);

  useEffect(() => {
    setType(mission?.steps?.principal);
  }, [mission]);

  switch (type) {
    case 'FILLED_RCP_INFORMATIONS':
      return (
        <Col>
          <Button
            type="primary"
            onClick={() =>
              navigate(
                `/collective-ownerships/edit/${mission?.collective_ownership?._id}?current=1&missionId=${mission._id}`
              )
            }
          >
            {t('missions.buttons.fill_rcp_informations')}
          </Button>
        </Col>
      );
    case 'NOTARY_OFFICE_REQUEST':
      return (
        <>
          {mission?.send_archivist_notary === true && (
            <Col>
              <Button
                type="primary"
                icon={<SendOutlined />}
                onClick={() => setVisible(true)}
              >
                {t('missions.buttons.send_request')}
              </Button>
            </Col>
          )}
          <Col>
            <Button
              type="primary"
              onClick={() => {
                navigate(
                  `/collective-ownerships/edit/${mission?.collective_ownership?._id}?current=1`
                );
              }}
            >
              {t('missions.buttons.show_rcp_infos')}
            </Button>
          </Col>
        </>
      );
    default:
      return (
        <Col>
          <Button
            type="primary"
            onClick={() => {
              navigate(
                `/collective-ownerships/edit/${mission?.collective_ownership?._id}?current=1`
              );
            }}
          >
            {t('missions.buttons.show_rcp_infos')}
          </Button>
        </Col>
      );
  }
};

PlansButtonsSwitcher.propTypes = {
  mission: PropTypes.shape({
    _id: PropTypes.string,
    collective_ownership: PropTypes.shape({
      _id: PropTypes.string
    }),
    steps: PropTypes.shape({
      principal: PropTypes.string
    }),
    send_archivist_notary: PropTypes.bool
  }).isRequired,
  setVisible: PropTypes.func.isRequired
};
