import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { GetEnums } from '../../../utils/getEnums';
import { addColumns } from '../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { EditableTable } from '../../../components/editableTableComponents/EditableTable';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';

/**
 * Renders the CustomerServiceManagerMeetings component.
 *
 * @component
 * @returns {JSX.Element} The rendered CustomerServiceManagerMeetings component.
 */
export const CustomerServiceManagerMeetings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectOptions, setSelectOptions] = useState({ psc_phases: [] });
  const [selectType, setSelectType] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const { getEnums } = GetEnums();
  const { getResourceData } = EditableTableMethods();
  const { searchValue, setPagination, pagination, currentSorter } =
    useConfigurationContext();
  const columns = useColumns({
    setEditingKey,
    form,
    setSelectType,
    setForceRefresh,
    forceRefresh
  });
  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  useEffect(() => {
    (async () => {
      const [
        CustomerServiceManagerMeetingsDatas,
        CustomerServiceManagerMeettingsEnums
      ] = await Promise.all([
        getResourceData({
          resource: 'customer-service-manager-meetings',
          searchValue,
          setPagination,
          pagination,
          currentSorter
        }),
        getEnums({ resource: 'customer-service-manager-meetings' })
      ]);

      setDataSource(
        CustomerServiceManagerMeetingsDatas.map((el, index) => ({
          ...el,
          key: index
        }))
      );
      setSelectOptions({ ...CustomerServiceManagerMeettingsEnums });
    })();
  }, [forceRefresh, searchValue, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: columns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      selectOptions,
      selectType,
      resource: 'customer-service-manager-meetings'
    });
  }, [editingKey, dataSource]);

  return (
    <>
      <PageHeaderCustom title={t('customer-service-manager-meetings.title')} />
      <ContentCustom>
        <EditableTable
          resource="customer-service-manager-meetings"
          dataSource={dataSource}
          mergedColumns={mergedColumns}
          setSelectedRowKey={setSelectedRowKey}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          form={form}
          searchValue={searchValue}
          pagination={pagination}
          setPagination={setPagination}
          setDataSource={setDataSource}
        />
      </ContentCustom>
    </>
  );
};
