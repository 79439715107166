// Main nav menuItems
export const routes = {
  DASHBOARD: '/dashboard',
  EVENTS: '/events',
  MISSIONS: '/missions',
  MONITORING: '/monitoring',
  BILLING_AND_ACCOUNTING: '/billing-and-accountings',
  SYNDICS: '/syndics',
  COLLECTIVE_OWNERSHIPS: '/collective-ownerships'
};

// Main nav subMenuItems
export const subRoutes = {
  MISSIONS: {
    PCS_PRE_PRODUCTIONS: '/pcs-pre-productions',
    MISSIONS_VIEW: '/missions-view',
    COLLECTIONS: '/collections'
  },
  BILLING_AND_ACCOUNTING: {
    INVOICES: '/invoices',
    INVOICES_REMINDERS: '/invoices-reminders'
  },
  MONITORING: {
    PCS_STOCK: '/pcs-stock',
    COLLECTIVEOWNERSHIPS_FORMS: '/collective-ownership-forms'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PASSWORD_CREATION: '/post-pwd',
  PASSWORD_RESET: '/reset-pwd',
  // TODO : REMOVE THIS ROUTE LATER
  PCSFORM: '/pcs-form'
};

// url search parameters
export const pathSearches = {};
