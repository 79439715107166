import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  PrinterOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Order } from '../../../models/Order';

/**
 * Hook for generating extra buttons for the ShowOrder component.
 * @hook
 * @returns {object[]} Array of objects representing extra buttons.
 */
export const useExtraButtons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return [
    {
      type: 'primary',
      label: t('orders.show.buttons.download_form'),
      icon: <DownloadOutlined />,
      onClick: () => {}
    },
    {
      type: 'primary',
      label: t('buttons.print'),
      icon: <PrinterOutlined />,
      onClick: () => {}
    },
    {
      type: 'link',
      label: t('buttons.archive_order'),
      icon: <DeleteOutlined />,
      danger: true,
      popConfirm: {
        title: t('datatable.column.action.archive.title'),
        okText: t('datatable.column.action.archive.ok'),
        okButtonProps: { type: 'danger' },
        cancelText: t('datatable.column.action.archive.cancel'),
        onConfirm: async (id) => {
          try {
            await Order.archiveResource(id);
            navigate(-1);
          } catch (e) {
            message(e);
          }
        },
        icon: <WarningOutlined />
      }
    }
  ];
};
