import { BaseModel } from './BaseModel';
import { generateDocumentsOld } from '../utils/generateDocumentOld';

/**
 * Represents an order.
 * @class
 */
export class Order extends BaseModel {
  static resourceName = 'orders';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Order.resourceName}/${id}`
    });
    return data;
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Order.resourceName}/mission?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Counts the number of documents in the order collection based on the provided query.
   * @function
   * @static
   * @async
   * @param {Object} [params={}] - Parameters to include in the query.
   * @param {string} [params.extraQuery=''] - Additional query parameters to be appended to the request URL.
   * @returns {Promise<Object>} The data returned from the API, containing the count of documents.
   */
  static async countDocuments({ extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Order.resourceName}/count?&${extraQuery}`
    });
    return data;
  }

  /**
   * Generates CERFA documents by type based on the provided cheques and message.
   *
   * This asynchronous method collects all `cerfas` from the provided `cheques` and
   * calls the `generateDocumentsOld` function to generate the required documents.
   * @async
   * @function
   * @param {Object} options - An object containing the options.
   * @param {Array<Object>} options.cheques - An array of `cheque` objects from which `cerfas` will be extracted.
   * @param {string} options.message - A message to be included in the document generation process.
   * @returns {Promise<void>} A promise that resolves when the document generation is complete.
   */
  static async generateCerfaByType({ cheques, message }) {
    await generateDocumentsOld({
      dispatchAPI: this.dispatchAPI,
      message,
      cerfas: this.getAllCerfasFromCheques(cheques)
    });
  }

  /**
   * Retrieves all `cerfas` objects from a list of cheques.
   *
   * This method extracts the `cerfas` array from each `cheque` object in the provided list,
   * then concatenates them into a single array.
   *
   * @param {Array<Object>} cheques - An array of `cheque` objects, each containing a `cerfas` property.
   * @returns {Array<Object>} An array containing all concatenated `cerfas` objects.
   */
  static getAllCerfasFromCheques(cheques) {
    return cheques.map((cheque) => cheque.cerfas).reduce((a, b) => a.concat(b));
  }

  /**
   * Takes orders with the provided values and cerfa number.
   *
   * @function
   * @async
   * @param {Object} options - The options for taking orders.
   * @param {Object} options.values - The values for the orders.
   * @param {string} options.cerfaNumber - The cerfa number for the orders.
   * @param {function} options.setIsDrawerOpen - The function to set the state of the drawer.
   * @param {function} options.setForceRefresh - The function to set the state for force refresh.
   * @param {function} options.setIsSubmitting - The function to set the state for submitting.
   * @param {string} [options.populate=''] - The populate query parameter.
   * @param {string} [options.extraQuery=''] - The extra query parameter.
   * @param {Array} [options.missionsAndSteps=''] - The array containing objects referring mission and step related to each order.
   * @param {function} options.message - The function to display a message.
   * @returns {Promise<Object>} - The data returned from the API.
   */
  static async takeOrders({
    values,
    cerfaNumber,
    setIsDrawerOpen,
    isDrawerOpen,
    setForceRefresh,
    setIsSubmitting,
    populate = '',
    extraQuery = '',
    missionsAndSteps = [],
    cheques,
    message
  }) {
    try {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append('values', JSON.stringify(values));
      formData.append('cerfaNumber', JSON.stringify(cerfaNumber));
      if (missionsAndSteps.length)
        formData.append('missionsAndSteps', JSON.stringify(missionsAndSteps));

      const { data } = await this.dispatchAPI('POST', {
        url: `${Order.resourceName}/take-orders?populate=${populate}&${extraQuery}`,
        body: formData
      });

      await this.generateCerfaByType({ cheques, message });
      setIsSubmitting(false);
      setForceRefresh((prev) => !prev);
      setIsDrawerOpen((prev) => ({ ...prev, ...isDrawerOpen }));
      return data;
    } catch (error) {
      setIsSubmitting(false);
      return message(error);
    }
  }

  static async patchResource({
    id,
    values,
    setIsSubmitting,
    populate = '',
    extraQuery = ''
  }) {
    if (setIsSubmitting) setIsSubmitting(true);
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('PATCH', {
      url: `${Order.resourceName}/${id}?populate=${populate}&${extraQuery}`,
      body: formData
    });
    if (setIsSubmitting) setIsSubmitting(false);
    return data;
  }

  static async archiveResource(orderId) {
    await this.dispatchAPI('PATCH', {
      url: `/${Order.resourceName}/archive/${orderId}`
    });
  }

  /**
   * Creates a new order.
   *
   * @param {Object} options - The options for creating the order.
   * @param {Object} options.values - The values for the order.
   * @param {string} [options.extraQuery=''] - The extra query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the created order data.
   */
  static async createOrder({ values, extraQuery = '' }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('POST', {
      url: `${Order.resourceName}/create-order?${extraQuery}`,
      body: formData
    });

    return data;
  }

  /**
   * Takes a plan order by the reference document and mission ID.
   *
   * @param {Object} options - The options for taking the plan order.
   * @param {string} options.refDoc - The reference document.
   * @param {string} options.missionId - The mission ID.
   * @param {Object} options.values - The values for the plan order.
   * @returns {Promise<Object>} - A promise that resolves to the data of the taken plan order, or rejects with an error.
   */
  static async takePlanOrder({ refDoc, missionId, values }) {
    try {
      const formData = new FormData();
      formData.append('values', JSON.stringify(values));
      const { data } = await this.dispatchAPI('PATCH', {
        url: `${Order.resourceName}/update-order-by-doc-reference?refdoc=${refDoc}&mission=${missionId}`,
        body: formData
      });

      return data;
    } catch (error) {
      return error;
    }
  }

  /**
   * Retrieves order statistics.
   *
   * This method sends a GET request to the API to fetch statistics
   * associated with orders. It uses the `dispatchAPI` method to send the request.
   *
   * @returns {Promise<Object>} A promise that resolves with the order statistics data.
   */
  static async getStats() {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Order.resourceName}/stats`
    });
    return data;
  }

  /**
   * Updates the status of urgent late orders.
   *
   * This method sends a PATCH request to the API to update the status of orders
   * that are marked as urgent and late. It uses the `dispatchAPI` method to send the request.
   *
   * @returns {Promise<Object>} A promise that resolves with the response data.
   */
  static async updateUrgentLate() {
    const { data } = await this.dispatchAPI('PATCH', {
      url: `${Order.resourceName}/update-urgent-late`
    });
    return data;
  }

  /**
   * Formats the data for taking orders and sends it to the API.
   *
   * This method takes an object containing order values, converts it to
   * a FormData object, and sends it to the server via a POST request.
   * The response from the server is then returned.
   * @async
   * @function
   * @param {Object} values - The values of the orders to be formatted and sent.
   * @returns {Promise<Object>} A promise that resolves to the response data from the server.
   */
  static async formatTakeOrdersData(values) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('POST', {
      url: `${Order.resourceName}/format-take-orders-data`,
      body: formData
    });
    return data;
  }
}
