import { Route, Routes } from 'react-router-dom';
import { QuotationContextProvider } from '../../contexts/QuotationContext';
import { ListQuotations } from './ListQuotations';
import { CreateUpdateQuotations } from './CreateUpdateQuotations';
import { Exception } from '../../components';

export const QuotationRouter = () => (
  <QuotationContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateQuotations purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateQuotations purpose="edit" />}
      />
      {/* <Route
        path="/show/:id"
        element={<ShowCollectiveOwnership key={location.key} />}
      /> */}
      <Route index element={<ListQuotations />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </QuotationContextProvider>
);
