import {
  DownloadOutlined,
  EyeOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { Space, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { invoiceTag } from '../../../utils/constants/tagColors';

/**
 * Returns an array of columns configuration for the invoices phase one list.
 * Each column object contains a title, key, render function, and sorter property.
 * The render function is responsible for rendering the content of each cell in the column.
 * The sorter property enables sorting functionality for the column.
 *
 * @hook
 * @returns {Array} An array of column configuration objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const mainColumns = [
    {
      title: t('invoices.columns.syndic'),
      key: 'mission.syndic',
      render: (record) => {
        const syndic = record?.mission?.syndic;
        return syndic
          ? `${syndic.code || ''} - ${syndic.society_name || ''}`
          : '-';
      },
      sorter: true
    },
    {
      title: t('invoices.columns.collective-ownership'),
      key: 'collective_ownership',
      render: (record) => {
        const collective_ownership = record?.mission?.collective_ownership;
        return collective_ownership
          ? `${collective_ownership.code || ''} - ${
              collective_ownership.first_address.street || ''
            } ${collective_ownership.first_address.additional || ''} ${
              collective_ownership.first_address.postal_code || ''
            } ${collective_ownership.first_city.name || ''}`
          : '-';
      },
      sorter: true
    },
    {
      title: t('invoices.columns.status'),
      key: 'status',
      render: (record) => {
        const status = record?.status;
        return status ? (
          <Tag color={invoiceTag[status]}>
            {t(`invoices.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          '-'
        );
      }
    },
    {
      title: t('invoices.columns.syndic_sending_date'),
      key: 'syndic_sending_date',
      render: (record) => {
        const syndic_sending_date = record?.sending_date;
        return syndic_sending_date
          ? dayjs(syndic_sending_date).format('DD-MM-YYYY')
          : '-';
      }
    },
    {
      title: t('invoices.columns.syndic_payment'),
      key: 'paid',
      render: (record) =>
        record.syndic_payment
          ? dayjs(record.syndic_payment).format('DD-MM-YYYY')
          : '-'
    },
    {
      title: t('invoices.columns.pcs_mission_start_date'),
      key: 'pcs_mission_start_date',
      render: (record) => {
        const pcs_mission_start_date = record?.mission?.created_at;
        return pcs_mission_start_date
          ? dayjs(pcs_mission_start_date).format('DD-MM-YYYY')
          : '-';
      }
    },
    {
      title: t('invoices.columns.pcs_mission_end_date'),
      key: 'pcs_mission_end_date',
      render: () => dayjs().format('DD-MM-YYYY') // TODO: Replace with actual end date
    },
    {
      title: t('invoices.columns.nomade_payment_date'),
      key: 'nomade_payment_date',
      render: (record) => {
        const nomade_payment_date = record?.nomade_payment_date;
        return nomade_payment_date
          ? dayjs(nomade_payment_date).format('DD-MM-YYYY')
          : '-';
      }
    },
    {
      title: t('invoices.columns.reference'),
      key: 'invoice_number',
      render: (record) => {
        const reference = record?.reference;
        return reference || '-';
      }
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => (
        <Space size="middle">
          <Link
            to={{
              pathname: `/billing-and-accountings/invoices/show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18, color: 'black' }} />
          </Link>
          <Link to={`/invoices/edit/${record._id}`}>
            <Tooltip title={t('invoices.tooltips.download')}>
              <DownloadOutlined style={{ fontSize: 18, color: 'black' }} />
            </Tooltip>
          </Link>
          <Link to={`/invoices/edit/${record._id}`}>
            <Tooltip title={t('invoices.tooltips.print')}>
              <PrinterOutlined style={{ fontSize: 18, color: 'black' }} />
            </Tooltip>
          </Link>
        </Space>
      )
    }
  ];

  const syndicColumns = [
    {
      title: t('invoices.columns.syndic'),
      key: 'syndic',
      render: (record) => {
        const syndic = record?.syndic;
        return syndic
          ? `${syndic?.code || ''} - ${syndic?.society_name || ''}`
          : '-';
      }
    },
    {
      title: t('invoices.columns.managers'),
      key: 'managers',
      render: (record) => {
        const managers = record?.syndic?.managers;
        if (managers && managers.length > 1) {
          return managers
            .map((manager) => `${manager.first_name} ${manager.last_name}`)
            .join(', ');
        }
        return managers?.first_name && managers?.last_name
          ? `${managers?.first_name} ${managers?.last_name}`
          : '-';
      }
    },
    {
      title: t('invoices.columns.phone_number'),
      key: 'phone_number',
      render: (record) => {
        const managers = record?.syndic?.managers;
        if (managers && managers.length > 1) {
          return managers
            .map(
              (manager) =>
                `${manager.phone_number.code || ''} ${
                  manager.phone_number.number
                }`
            )
            .join(' - ');
        }
        return managers?.phone_number?.number
          ? `${managers?.phone_number?.code || ''} ${
              managers?.phone_number?.number || ''
            }`
          : '-';
      }
    },
    {
      title: t('invoices.columns.email'),
      key: 'email',
      render: (record) => {
        const managers = record?.syndic?.managers;
        if (managers && managers.length > 1) {
          return managers.map((manager) => manager.email).join(' - ');
        }
        return managers?.email ? managers?.email : '-';
      }
    },
    {
      title: t('invoices.columns.unpaid_invoices'),
      key: 'unpaid_invoices',
      render: (record) => {
        const invoices = record?.invoices || [];
        const unpaid_invoices = invoices.filter(
          (invoice) => invoice.status === 'UNPAID'
        );
        const total_unpaid = unpaid_invoices.reduce(
          (acc, curr) => acc + curr.total_ttc,
          0
        );
        return total_unpaid ? `${parseFloat(total_unpaid).toFixed(2)} €` : '-';
      }
    },
    {
      title: t('invoices.columns.total_paid'),
      key: 'total_paid',
      render: (record) => {
        const invoices = record?.invoices || [];
        const paid_invoices = invoices.filter(
          (invoice) => invoice.status === 'PAID'
        );
        const total_paid = paid_invoices.reduce(
          (acc, curr) => acc + curr.total_ttc,
          0
        );
        return total_paid ? `${parseFloat(total_paid).toFixed(2)} €` : '0.00 €';
      }
    },
    {
      title: t('invoices.columns.total'),
      key: 'total',
      render: (record) => {
        const invoices = record?.invoices || [];
        const total = invoices.reduce((acc, curr) => acc + curr.total_ttc, 0);
        return total ? `${parseFloat(total).toFixed(2)} €` : '0.00 €';
      }
    }
  ];

  return { mainColumns, syndicColumns };
};
