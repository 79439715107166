import { Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/noteFields';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * NoteDrawer component.
 *
 * @component
 * @returns {JSX.Element} The NoteDrawer component
 */
export const NoteDrawer = () => {
  const {
    forceRefresh,
    setForceRefresh,
    isDrawerOpen,
    setIsDrawerOpen,
    isLoading,
    mission
  } = useMissionContext();
  const [form] = Form.useForm();
  const { fields } = useFields({ form });
  const { t } = useTranslation();

  const useConfig = {
    onCreateResource: {
      setBody: (values) => ({
        ...values,
        documents: null,
        mission: mission._id
      })
    }
  };

  return (
    <Drawer
      title={t('missions.drawers.titles.note')}
      onClose={() => setIsDrawerOpen((prev) => ({ ...prev, note: false }))}
      open={isDrawerOpen.note}
    >
      <CreateUpdateContainer
        successMessage={t('missions.messages.note_created')}
        fields={fields}
        isFieldsLoading={isLoading}
        config={useConfig}
        purpose="create"
        baseUrl="notes"
        resource="notes"
        withFilesManager={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{ ...isDrawerOpen, note: false }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
