import { message as antdMessage } from 'antd';
import dayjs from 'dayjs';

/**
 * Checks if the provided resource body can be created based on the given prices, data, and translation function.
 * If the resource body cannot be created due to a conflicting period, a warning message is displayed.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {Array} options.prices - The array of prices to check against.
 * @param {Object} options.data - The data object representing the resource body to be created.
 * @param {Function} options.t - The translation function.
 * @returns {Object|null} - The data object if it can be created, or null if a conflicting period is found.
 */
export const onCreateResourceBody = ({ prices, data, t }) => {
  let isPeriodTaken = false;

  prices
    .filter(
      (el) => el.mission_type === data.mission_type && el.type === data.type
    )
    .forEach((rate) => {
      if (data.end_date && rate.end_date) {
        if (
          dayjs(data.start_date).startOf('day') <= dayjs(rate.start_date) &&
          dayjs(data.end_date).endOf('day') >= dayjs(rate.end_date)
        ) {
          if (isPeriodTaken) return;
          antdMessage.warning(t('price-systems.messages.period_taken'));
          isPeriodTaken = true;
        }
      } else if (
        dayjs(data.start_date).startOf('day') <=
        dayjs(rate.start_date).startOf('day')
      ) {
        if (isPeriodTaken) return;
        antdMessage.warning(t('price-systems.messages.period_taken'));
        isPeriodTaken = true;
      }
    });

  if (isPeriodTaken) return null;

  return data;
};
