import dayjs from 'dayjs';

/**
 * Custom hook that returns an array of objects containing label, span, and content for collective ownership information.
 * @hook
 * @param {Object} data - The data object containing collective ownership information.
 * @returns {Array} - An array of objects containing label, span, and content.
 */
export const useCollectiveOwnershipInformationsListContent = (data = {}) => {
  const collective_ownership = data?.collective_ownership || {};

  return [
    {
      label: 'pcs-pre-productions.form.collective_ownership_code',
      span: 1,
      content: collective_ownership?.code || ''
    },
    {
      label: 'pcs-pre-productions.form.collective_ownership_invoice_code',
      span: 1,
      content: collective_ownership?.invoice_code || ''
    },
    {
      label: 'pcs-pre-productions.form.collective_ownership_name',
      span: 1,
      content: collective_ownership?.name || ''
    },
    {
      label: 'pcs-pre-productions.form.collective_ownership_address.street',
      span: 1,
      content: collective_ownership?.first_address?.street || ''
    },
    {
      label:
        'pcs-pre-productions.form.collective_ownership_address.postal_code',
      span: 1,
      content: collective_ownership?.first_address?.postal_code || ''
    },
    {
      label: 'pcs-pre-productions.form.collective_ownership_address.city',
      span: 1,
      content: collective_ownership?.first_city?.name || ''
    },
    {
      label:
        'pcs-pre-productions.form.collective_ownership_cadastral_reference',
      span: 1,
      content: collective_ownership?.cadastral_reference || ''
    },
    {
      label: 'pcs-pre-productions.form.collective_ownership_AG_date',
      span: 1,
      content: collective_ownership?.AG_date
        ? dayjs(collective_ownership?.AG_date).format('DD-MM-YYYY')
        : ''
    }
  ];
};
