import { Link } from 'react-router-dom';
import { Popconfirm, Divider } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { deleteResource } from '../../../../../utils/deleteResource';

/**
 * useColumns hook for Assistants component
 * @param {Object} dispatchAPI - dispatchAPI
 * @param {Object} message - message
 * @param {Function} setForceRefresh - setForceRefresh
 * @param {Boolean} forceRefresh - forceRefresh
 * @returns {Array} columns
 */
export const useColumns = ({
  dispatchAPI,
  message,
  setForceRefresh,
  forceRefresh,
  editUserPermissions
}) => {
  const { t } = useTranslation();

  return [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      render: (record) => record?.assistant?.last_name || '-',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      render: (record) => record?.assistant?.first_name || '-',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      render: (record) => record?.assistant?.email || '-',
      sorter: true
    },
    {
      title: t('users.form.phone_number'),
      key: 'phone_number',
      render: (record) =>
        record?.assistant?.phone_number.number
          ? `0${record?.assistant?.phone_number.number}`
          : '-',
      sorter: true
    },
    {
      title: t('users.form.access'),
      key: 'access',
      render: (record) => record?.assistant?.access || 'IN_PROGRESS'
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/users/show/${record?.assistant?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {editUserPermissions && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/users/edit/${record?.assistant?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() =>
                  deleteResource({
                    dispatchAPI,
                    resourceName: 'relation-manager-assistant',
                    id: record?._id,
                    setForceRefresh,
                    forceRefresh,
                    message
                  })
                }
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];
};
