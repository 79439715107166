import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useItems } from './Items/useItems';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { getSyndic } from '../utils/getSyndic';
import { getEnums } from '../utils/getEnums';
import { ShowButtons } from '../utils/ShowButtons';
import { useGlobalContext } from '../../../contexts/GlobalContext';

/**
 * The `ShowSyndic` component is designed to display detailed information about a specific syndic entity in a tabbed interface. It retrieves syndic data and enumerations required for the tabs and renders the information organized into several sections such as general information, managers and assistants, collective ownerships, documents, and publications.
 *
 * @component
 * @return {JSX.Element} The syndic detailed view component.
 * The component utilizes React Router's `useParams` to extract the `id` of the syndic from the URL, which is then used to fetch the syndic's detailed information using the `getSyndic` utility function. It also fetches enumeration values using the `getEnums` utility function, which are required for filtering and display purposes in child components.
 *
 * State management is handled through React's `useState` hook for storing the syndic data, loading state, force refresh trigger, and enumeration values. The `useEffect` hook is used to initiate data fetching on component mount and when a force refresh is triggered.
 *
 * The `useItems` hook aggregates the components to be displayed in each tab, passing necessary props such as the syndic data, loading state, translation function, enumerations, and force refresh controls.
 *
 * The Ant Design `Tabs` component is used to render the tabbed interface, with tab configurations and content provided by the `useItems` hook. The `PageHeaderCustom` component renders the page header, including the syndic's code and society name, and additional action buttons provided by the `ShowButtons` utility.
 *
 * Props: None
 *
 * Usage:
 * This component is intended to be used in the context of a syndic management system within an administrative interface, where detailed views of individual syndic entities are required. It offers a comprehensive and organized display of all relevant information pertaining to a syndic, enhancing usability and access to syndic data.
 */
export const ShowSyndic = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { activeKey, setActiveKey } = useGlobalContext();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [syndic, setSyndic] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [enums, setEnums] = useState({});
  const editPermissions = ![
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER',
    'users:LAWYER'
  ].includes(user.role);
  const { items } = useItems({
    syndic,
    isLoading,
    t,
    enums,
    setForceRefresh,
    forceRefresh,
    editPermissions
  });

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getSyndic({
        dispatchAPI,
        id,
        setSyndic,
        message
      });
      await getEnums({ dispatchAPI, setEnums, message });
      setForceRefresh((prev) => !prev);
    })();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getSyndic({
        dispatchAPI,
        id,
        setSyndic,
        message
      });
    })();
    setIsLoading(false);
  }, [forceRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={
          syndic &&
          t('syndics.show.title', {
            code: syndic.code,
            name: syndic.society_name
          })
        }
        extra={
          <ShowButtons
            permissions={{
              editButton: editPermissions,
              deleteButton: editPermissions
            }}
          />
        }
      />
      <ContentCustom>
        <Tabs
          tabPosition="left"
          items={items}
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key);
            setForceRefresh(!forceRefresh);
          }}
        />
      </ContentCustom>
    </>
  );
};
