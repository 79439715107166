import { useGlobalContext } from '../../../../contexts/GlobalContext';

/**
 * @hook
 * Returns a configuration object for creating a resource.
 * @returns {Object} The configuration object.
 */
export const useConfig = () => {
  const { currentSyndic } = useGlobalContext();

  return {
    onCreateResource: {
      setBody: (body) => {
        const finalData = {
          ...body,
          syndic: currentSyndic,
          requested_mission_type: 'COLLECTIVE_OWNERSHIP_TRANSFER',
          status: 'PENDING_VALIDATION'
        };
        return finalData;
      }
    }
  };
};
