export const routes = {
  HOME: '/',
  MISSIONS: '/missions',
  CONTACT: '/contact',
  FAQ: '/faq'
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PASSWORD_CREATION: '/post-pwd',
  PASSWORD_RESET: '/reset-pwd',
  SHOW_SYNDIC: '/syndics/show',
  SHOW_COLLECTIVE_OWNERSHIP: '/collective-ownerships/show',
  PCS_FORM: '/pcs-form'
};

// url search parameters
export const pathSearches = {};
