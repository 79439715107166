import { Tabs, Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { informationProvidedBySyndicItems } from './items/informationsProvidedBySyndicItems';

/**
 * Component for displaying information provided by syndic.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.pcsPreProduction - The PCS pre-production object.
 * @param {boolean} props.isLoading - Flag indicating if data is loading.
 * @param {Function} props.setPcsPreProduction - Function to set pcsPreProduction
 * @returns {JSX.Element} - The rendered component.
 */
export const InformationsProvidedBySyndic = ({
  pcsPreProduction,
  isLoading,
  setPcsPreProduction
}) => {
  const { t } = useTranslation();
  const items = informationProvidedBySyndicItems({
    pcsPreProduction,
    isLoading,
    setPcsPreProduction
  });

  return (
    <Card
      title={t(
        'pcs-pre-productions.show.cards.titles.informations_provided_by_syndic'
      )}
    >
      <Tabs items={items} />
    </Card>
  );
};

InformationsProvidedBySyndic.propTypes = {
  pcsPreProduction: PropTypes.shape({
    collective_ownership: PropTypes.shape({
      _id: PropTypes.string
    }),
    personalized_message: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  setPcsPreProduction: PropTypes.func
};

InformationsProvidedBySyndic.defaultProps = {
  pcsPreProduction: {},
  isLoading: false,
  setPcsPreProduction: null
};
