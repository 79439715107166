import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Table, Row, Button, Col, Spin, Tooltip, message } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  SendOutlined
} from '@ant-design/icons';
import { useColumns } from '../columns/pcajpArrayColumns';
import { handleSubmitPcajpArray } from '../utils/handleSubmitPcajpArray';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { setPcajpFieldsValues } from '../utils/setPcajpFieldsValues';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { PcajpArray } from '../../../../../models/PcajpArray';

/**
 * Represents a form for handling disbursement information in the mission view.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.currentPcajpArray - The current PCAJP array.
 * @param {Array} props.dataSource - The data source.
 * @param {Function} props.setCurrentPcajpArray - The function to set the current PCAJP array.
 * @param {Array} props.pcajpArrays - The array of PCAJP arrays.
 * @param {String} props.isSubmitting - The submitting state.
 * @param {Function} props.setIsSubmitting - The function to set the submitting state.
 * @returns {JSX.Element} PcajpArraysForm component
 */
export const PcajpArraysForm = ({
  currentPcajpArray,
  dataSource,
  setCurrentPcajpArray,
  pcajpArrays,
  isSubmitting,
  setIsSubmitting
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const [showPrincipalLotField, setShowPrincipalLotField] = useState({});
  const [extraDatas, setExtraDatas] = useState({});
  const columns = useColumns({
    showPrincipalLotField,
    setShowPrincipalLotField,
    setIsSubmitting
  });
  const { id } = useParams();
  const { setIsDrawerOpen, setForceRefresh } = useMissionContext();

  const isValidatedPcajpArray = pcajpArrays.find(
    (pcajpArray) => pcajpArray.is_validated
  );

  useEffect(() => {
    if (!pcajpArrays.length) return;
    setPcajpFieldsValues({
      setShowPrincipalLotField,
      setCurrentPcajpArray,
      pcajpArrays,
      form
    });
  }, [dataSource]);

  return (
    <Form
      form={form}
      onFinish={(values) =>
        handleSubmitPcajpArray({
          values,
          currentPcajpArray,
          setIsSubmitting,
          setForceRefresh,
          id,
          extraDatas,
          message,
          t
        })
      }
    >
      <Spin spinning={isSubmitting}>
        <Table dataSource={dataSource} columns={columns} scroll={{ y: 500 }} />
      </Spin>
      <Form.Item>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col>
            <Row justify="start">
              <Col>
                <Button
                  onClick={() =>
                    PcajpArray.addLot({
                      id: currentPcajpArray._id,
                      setForceRefresh,
                      setIsSubmitting
                    })
                  }
                >
                  {t('missions.buttons.add_lot')}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row justify="end" gutter={[16, 16]}>
              <Col>
                <Button
                  style={{ margin: '0 8px' }}
                  type="link"
                  danger
                  onClick={() => {
                    setIsDrawerOpen((prev) => ({
                      ...prev,
                      pcajp_array: false
                    }));
                  }}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
              </Col>
              <Col>
                <Tooltip title="En développement">
                  <Button
                    type="primary"
                    loading={isSubmitting === 'EXPORT'}
                    disabled
                  >
                    <DownloadOutlined />
                    {`${t('buttons.export')} `}
                  </Button>
                </Tooltip>
              </Col>
              {!isValidatedPcajpArray && pcajpArrays[0].data.length ? (
                <>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting === 'DRAFT'}
                      // Update when extranet is ready
                      onClick={() =>
                        setExtraDatas({
                          visible_to_role: [
                            'SUPER-ADMIN',
                            'ADMIN',
                            'NOMADE_PRODUCTION',
                            user.role.split(':')[1]
                          ],
                          is_draft: true
                        })
                      }
                    >
                      {`${t('buttons.register')} `}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting === 'PUBLISHED'}
                      onClick={() =>
                        setExtraDatas({
                          visible_to_role: [
                            'NOMADE_PRODUCTION',
                            'LAWYER',
                            'SYNDIC',
                            'ADMIN',
                            'SUPER-ADMIN'
                          ],
                          is_draft: false
                        })
                      }
                    >
                      {`${t(
                        `buttons.${
                          user.role === 'users:SYNDIC'
                            ? 'register_and_send_to_lawyer_for_validation'
                            : 'register_and_send_to_syndic_for_validation'
                        }`
                      )} `}
                      <SendOutlined />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting === 'VALIDATED'}
                      onClick={() =>
                        setExtraDatas({
                          visible_to_role: [
                            'NOMADE_PRODUCTION',
                            'LAWYER',
                            'SYNDIC',
                            'ADMIN',
                            'SUPER-ADMIN'
                          ],
                          is_validated: true
                        })
                      }
                    >
                      <CheckOutlined />
                      {`${t('missions.buttons.valid_pcajp_array')} `}
                    </Button>
                  </Col>
                </>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

PcajpArraysForm.propTypes = {
  currentPcajpArray: PropTypes.shape({
    _id: PropTypes.string
  }),
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  setCurrentPcajpArray: PropTypes.func.isRequired,
  pcajpArrays: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({}))
    })
  ),
  isSubmitting: PropTypes.string,
  setIsSubmitting: PropTypes.func
};

PcajpArraysForm.defaultProps = {
  currentPcajpArray: {},
  dataSource: [],
  pcajpArrays: [],
  isSubmitting: false,
  setIsSubmitting: () => {}
};
