import { BaseModel } from './BaseModel';

/**
 * Represents an quotation configuration.
 * @class
 */
export class QuoteConfiguration extends BaseModel {
  static resourceName = 'quote-configurations';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${QuoteConfiguration.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @param {string} [type=''] - The type of the resources to retrieve.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '', type = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${
        QuoteConfiguration.resourceName
      }?populate=${populate}&${extraQuery}&type=${type}&deleted=${false}`
    });
    return data;
  }
}
