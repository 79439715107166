/**
 * @function
 * @async
 * Blocks the collective ownership with the given parameters.
 *
 * @param {Object} options - The options object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {Function} options.message - The message function.
 * @param {string} options.id - The ID of the collective ownership.
 * @param {Function} options.setForceRefresh - The setForceRefresh function.
 * @param {boolean} options.forceRefresh - The current value of forceRefresh.
 * @param {any} options.value - The value to be sent in the request body.
 * @returns {Promise<void>} - A promise that resolves when the collective ownership is successfully blocked.
 */
export const blockCollectiveOwnership = async ({
  dispatchAPI,
  message,
  id,
  setForceRefresh,
  forceRefresh,
  value
}) => {
  try {
    const formData = new FormData();

    formData.append('values', JSON.stringify(value));

    await dispatchAPI('PATCH', {
      url: `/collective-ownerships/${id}`,
      body: formData
    });

    setForceRefresh(!forceRefresh);
  } catch (e) {
    message(e);
  }
};
