import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Search from 'antd/es/input/Search';
import { useTranslation } from 'react-i18next';
import { ExtraSearchBars } from '../ExtraSearchBars';
import { User } from '../../../../models/User';

/**
 * Renders the extra header for the kanban view.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {string} props.keyword - The keyword to search for.
 * @param {Function} props.setSearchValue - The function to set the search value.
 * @param {string} props.searchValue - The search value.
 * @param {string} props.pathname - The current pathname.
 * @returns {JSX.Element} The rendered ExtraHeader component.
 */
export const ExtraHeader = ({
  setFilters,
  keyword,
  setSearchValue,
  searchValue
}) => {
  const [isFiedlLoading, setIsFieldLoading] = useState(true);
  const [consultants, setConsultants] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (keyword) {
      setFilters(`keywords=${keyword}`);
    } else {
      setSearchValue(null);
    }
  }, [keyword]);

  useEffect(() => {
    setIsFieldLoading(true);
    (async () => {
      const consultantsData = await User.getResources({
        query: 'role=users:CONSULTANT'
      });
      setConsultants(consultantsData);
      setIsFieldLoading(false);
    })();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <Search
          placeholder={t('placeholder.search')}
          onSearch={(value) => {
            setSearchValue(value);
            setFilters(`keywords=${value}`);
          }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          style={{ width: 200 }}
        />
      </Col>
      <Col>
        <ExtraSearchBars
          setFilters={setFilters}
          consultants={consultants}
          isFiedlLoading={isFiedlLoading}
        />
      </Col>
    </Row>
  );
};

ExtraHeader.propTypes = {
  setFilters: PropTypes.func.isRequired,
  keyword: PropTypes.string,
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string
};

ExtraHeader.defaultProps = {
  keyword: '',
  searchValue: ''
};
