import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('pcs_stocks.columns.syndic'),
      key: 'syndic',
      render: (record) => {
        const syndic = record?.mission?.collective_ownership?.syndic;
        return syndic ? `${syndic.society_name} (${syndic.code})` : '-';
      },
      sorter: true
    },
    {
      title: t('pcs_stocks.columns.collective-ownership'),
      key: 'collective_ownership',
      render: (record) => {
        const collective_ownership = record?.mission?.collective_ownership;
        return collective_ownership
          ? `${collective_ownership.code} - ${collective_ownership.first_address.street} ${collective_ownership.first_address.postal_code} ${collective_ownership.first_city.name}`
          : '-';
      },
      sorter: true
    },
    {
      title: t('pcs_stocks.columns.payment_received'),
      key: 'paiement_received',
      dataIndex: 'paiement_received',
      render: (paiement_received) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            color: 'white',
            backgroundColor: paiement_received ? 'green' : 'red'
          }}
        >
          {paiement_received
            ? `Oui - ${dayjs(paiement_received).format('DD-MM-YYYY')}`
            : 'Non'}
        </div>
      ),
      sorter: true
    },
    {
      title: t('pcs_stocks.columns.grid_received'),
      key: 'grid_received',
      dataIndex: 'grid_received',
      render: (grid_received) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            color: 'white',
            backgroundColor: grid_received ? 'green' : 'red'
          }}
        >
          {grid_received
            ? `Oui - ${dayjs(grid_received).format('DD-MM-YYYY')}`
            : 'Non'}
        </div>
      ),
      sorter: true
    },
    {
      title: t('pcs_stocks.columns.rcp_received'),
      key: 'rcp_received',
      dataIndex: 'rcp_received',
      render: (rcp_received) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            color: 'white',
            backgroundColor: rcp_received ? 'green' : 'red'
          }}
        >
          {rcp_received
            ? `Oui - ${dayjs(rcp_received).format('DD-MM-YYYY')}`
            : 'Non'}
        </div>
      ),
      sorter: true
    },
    {
      title: t('pcs_stocks.columns.stock_date'),
      key: 'stock_date',
      dataIndex: 'stock_date',
      render: (stock_date) => dayjs(stock_date).format('DD-MM-YYYY'),
      sorter: true
    }
  ];
};
