export const deleteDocument = async ({
  dispatchAPI,
  syndic,
  setForceRefresh,
  forceRefresh,
  message,
  file_id,
  document_id
}) => {
  try {
    await dispatchAPI('DELETE', {
      url: `/syndics/documents/${syndic._id}?file_id=${file_id}&document_id=${document_id}`
    });
    setForceRefresh(!forceRefresh);
  } catch (e) {
    message(e);
  }
};
