import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useCollectiveOwnershipFormListContent } from '../listContent/collectiveOwnershipForm';

/**
 * Represents a drawer component for the collective ownership form.
 * @component
 * This component displays a drawer with a title and a description list.
 * @returns {JSX.Element} The collective ownership form drawer component.
 */
export const CollectiveOwnershipFormDrawer = () => {
  const { t } = useTranslation();
  const { isDrawerOpen, setIsDrawerOpen, collectiveOwnershipForm } =
    useMissionContext();
  const collectiveOwnershipFormListContent =
    useCollectiveOwnershipFormListContent(collectiveOwnershipForm);

  return (
    <Drawer
      title={t('missions.drawers.titles.collective_ownership_form')}
      onClose={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          collective_ownership_form: false
        }))
      }
      open={isDrawerOpen.collective_ownership_form}
    >
      <DescriptionList
        data={collectiveOwnershipFormListContent}
        translate
        vertical
      />
    </Drawer>
  );
};
