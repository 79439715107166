import { Card, Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../../../components';
import { CollapseHistory } from '../../../../components/History/CollapseHistory';
import { Invoice } from '../../../../models/Invoice';
import { useInvoiceInformationsListContent } from './invoiceInformationsListContent';
import { useColumns } from './colmuns';
import { HeaderButtons } from './HeaderButtons';
import { processWordings } from './utils/processWordings';

/**
 * Renders the component for displaying invoices.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ShowInvoice = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [invoice, setInvoice] = useState({});
  const [wordingsDetails, setWordingsDetails] = useState([]);
  const [paymentsDetails, setPaymentsDetails] = useState([]);
  const invoiceInformations = useInvoiceInformationsListContent({ invoice });
  const { wordingsColumns, paymentsColumns } = useColumns();

  useEffect(() => {
    (async () => {
      const invoiceData = await Invoice.getResource({
        id,
        populate:
          'mission,mission.syndic collective_ownership,history.by,payments' // ! Double population not working
      });
      const updatedWordings = processWordings({ invoice: invoiceData });
      setInvoice(invoiceData);
      setWordingsDetails(updatedWordings);
      setPaymentsDetails(invoiceData.payments);
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={t('invoices.titles.invoice', {
          reference: invoice?.reference,
          defaultValue: 'XXXX'
        })}
      />
      <HeaderButtons invoice={invoice} />
      <ContentCustom>
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 16
          }}
        >
          <Col span={15}>
            <Card>
              <DescriptionList
                title={t('invoices.labels.invoice_informations')}
                translate
                data={invoiceInformations}
              />
            </Card>
          </Col>
          <Col span={9}>
            <CollapseHistory
              isLoading={false}
              historic={invoice?.history}
              title="invoices.labels.invoice_history"
            />
          </Col>
        </Row>
        {wordingsDetails.length > 0 && (
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col span={24}>
              <Card title={t('invoices.labels.invoice_detail')}>
                <Table
                  dataSource={wordingsDetails}
                  columns={wordingsColumns}
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                />
              </Card>
            </Col>
          </Row>
        )}
        {paymentsDetails.length > 0 && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card title={t('invoices.labels.payments')}>
                <Table
                  dataSource={paymentsDetails}
                  columns={paymentsColumns}
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                />
              </Card>
            </Col>
          </Row>
        )}
      </ContentCustom>
    </>
  );
};
