import { Col, Row, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { missionStatus } from '../../../utils/constants/tagColors';

const { Title } = Typography;

export const ExtraTitleHeader = ({ missionsStatus }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]} align="center">
      <Title level={4} style={{ marginLeft: 10 }} strong>
        {t('missions.title')}
      </Title>
      <Col>
        <Tag color={missionStatus.IN_PROGRESS}>
          {t('missions.tags.IN_PROGRESS')} ({missionsStatus.IN_PROGRESS})
        </Tag>
        <Tag color={missionStatus.BLOCKED}>
          {t('missions.tags.BLOCKED')} ({missionsStatus.BLOCKED})
        </Tag>
        <Tag color={missionStatus.PENDING}>
          {t('missions.tags.PENDING')} ({missionsStatus.PENDING})
        </Tag>
      </Col>
    </Row>
  );
};

ExtraTitleHeader.propTypes = {
  missionsStatus: PropTypes.objectOf({}).isRequired
};
