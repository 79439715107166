import { Link } from 'react-router-dom';
import { Popconfirm, Divider } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  VerticalAlignMiddleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { deleteResource } from '../../../utils/deleteResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Custom hook that returns an array of columns for the municipality configuration table.
 *
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The flag indicating whether to force refresh the data.
 * @param {boolean} options.list_duplicates - The flag indicating whether to list duplicates.
 * @returns {Array} An array of columns for the municipality configuration table.
 */
export const useColumns = ({
  setForceRefresh,
  forceRefresh,
  list_duplicates,
  spfDepartmentalArchives
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  return [
    {
      title: t('municipalities.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('municipalities.form.postal_code'),
      key: 'postal_code',
      dataIndex: 'postal_code',
      sorter: false
    },
    {
      title: t('municipalities.form.cutting'),
      key: 'cutting',
      dataIndex: 'cutting',
      sorter: true
    },
    {
      title: t('municipalities.form.SPF_3233'),
      key: 'SPF_3233',
      dataIndex: 'SPF_3233',
      render: (SPF_3233) => SPF_3233?.name || '',
      filters: (spfDepartmentalArchives || []).map((r) => ({
        text: r.name,
        value: r._id
      })),
      sorter: (a, b) =>
        a.SPF_3233 ? a.SPF_3233.name.localeCompare(b.SPF_3233.name) : true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) =>
        list_duplicates ? (
          <Link
            to={{
              pathname: `/configurations/merge/${record._id}/${record.duplicate_id}/municipalities`,
              search: 'duplicate'
            }}
          >
            <VerticalAlignMiddleOutlined style={{ fontSize: 18 }} />
          </Link>
        ) : (
          <>
            <Link
              to={{
                pathname: `/configurations/municipalities/edit/${record?._id}`
              }}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </Link>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() =>
                deleteResource({
                  dispatchAPI,
                  resourceName: 'municipalities',
                  id: record._id,
                  setForceRefresh,
                  forceRefresh,
                  message
                })
              }
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: 18 }}
                type="delete"
              />
            </Popconfirm>
          </>
        )
    }
  ];
};
