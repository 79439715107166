import {
  FilePdfFilled,
  FileExcelFilled,
  FileWordFilled
} from '@ant-design/icons';

/**
 * Returns the corresponding icon component based on the document type.
 *
 * @function
 * @param {string} type - The MIME type of the document.
 * @returns {JSX.Element} - The icon component.
 */
export const setDocumentIcon = (type) => {
  const iconSize = 20;
  switch (type) {
    case 'application/pdf':
      return <FilePdfFilled style={{ fontSize: iconSize, color: 'red' }} />;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <FileWordFilled style={{ fontSize: iconSize, color: 'blue' }} />;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FileExcelFilled style={{ fontSize: iconSize, color: 'green' }} />;
    default:
      return <FilePdfFilled style={{ fontSize: iconSize, color: 'red' }} />;
  }
};
