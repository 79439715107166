import { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { outOfNavRoutes } from '../utils/constants/routes/adminRoutes';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { DeveloperLayout } from '../components/layouts/DeveloperLayout';
import { LawyerLayout } from '../components/layouts/Lawyer/LawyerLayout';
import { PrestationWordLayout } from '../components/layouts/PrestationWord/PrestationWordLayout';
import { CustomerServiceManagerLayout } from '../components/layouts/CustomerServiceManager/CustomerServiceManagerLayout';
import { AccountManagerLayout } from '../components/layouts/AccountManager/AccountManagerLayout';
import { AssistantLayout } from '../components/layouts/Assistant/AssistantLayout';
import { BillingLayout } from '../components/layouts/Billing/BillingLayout';
import { SyndicAssistantLayout } from '../components/layouts/SyndicAssistant/SyndicAssistantLayout';
import { SyndicManagerLayout } from '../components/layouts/SyndicManager/SyndicManagerLayout';
import { ProductionLayout } from '../components/layouts/Production/ProductionLayout';
import { SuperAdminLayout } from '../components/layouts/SuperAdmin/SuperAdminLayout';
import { SyndicCouncilLayout } from '../components/layouts/SyndicCounsil/SyndicCouncilLayout';

/**
 * Higher-order component that handles authentication and user role-based rendering of layouts.
 * @component
 * @returns {JSX.Element} Rendered layout component based on user role or redirects to login if not authenticated.
 */
export const RequireAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isValid, user, onLogin, setOnLogin } = useAuthContext();
  const { '*': urlToken } = useParams();

  const isPostRoute =
    urlToken?.includes('post-pwd') || urlToken?.includes('reset-pwd');

  useEffect(() => {
    if (onLogin) {
      setOnLogin(false);
      navigate('/');
    }
  }, [onLogin]);

  if (!isValid && !isPostRoute) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  const renderLayout = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
        return <AdminLayout />;
      case 'admins:SUPER-ADMIN':
        return <SuperAdminLayout />;
      case 'users:NOMADE_PRODUCTION':
        return <ProductionLayout />;
      case 'developers:DEVELOPER':
        return <DeveloperLayout />;
      case 'users:LAWYER':
        return <LawyerLayout />;
      case 'users:PRESTATION_WORD':
        return <PrestationWordLayout />;
      case 'users:NOMADE_CUSTOMER_SERVICE_MANAGER':
        return <CustomerServiceManagerLayout />;
      case 'users:NOMADE_ACCOUNT_MANAGER':
        return <AccountManagerLayout />;
      case 'users:NOMADE_ASSISTANT':
        return <AssistantLayout />;
      case 'users:NOMADE_BILLING':
        return <BillingLayout />;
      case 'users:SYNDIC_COUNCIL':
        return <SyndicCouncilLayout />;
      case 'users:SYNDIC_ASSISTANT':
        return <SyndicAssistantLayout />;
      case 'users:SYNDIC_MANAGER':
        return <SyndicManagerLayout />;
      default:
        return null;
    }
  };

  return renderLayout();
};
