import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns for the process delay statistics list
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useProcessDelayColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('statistics.columns.consultant'),
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: true
    },
    {
      title: t('statistics.columns.process_delay.sales_canal'),
      dataIndex: 'sales_canal',
      key: 'sales_canal',
      sorter: true
    },
    {
      title: t('statistics.columns.process_delay.total_syndic'),
      dataIndex: 'total_syndic',
      key: 'total_syndic',
      sorter: true
    },
    {
      title: t('statistics.columns.process_delay.total_collective_ownership'),
      dataIndex: 'total_collective_ownership',
      key: 'total_collective_ownership',
      sorter: true
    },
    {
      title: t('statistics.columns.process_delay.account_manager_delay'),
      dataIndex: 'account_manager_delay',
      key: 'account_manager_delay',
      sorter: true
    },
    {
      title: t('statistics.columns.process_delay.production_delay'),
      dataIndex: 'production_delay',
      key: 'production_delay',
      sorter: true
    },
    {
      title: t('statistics.columns.process_delay.invoice_delay'),
      dataIndex: 'invoice_delay',
      key: 'invoice_delay',
      sorter: true
    },
    {
      title: t('statistics.columns.process_delay.total_delay'),
      dataIndex: 'total_delay',
      key: 'total_delay',
      sorter: true
    }
  ];
};
