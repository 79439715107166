import { Input } from 'antd';

/**
 * Hook to generate form fields for notes.
 *
 * This hook creates a simple field configuration for a form, intended to capture
 * notes or textual information from the user. It utilizes Ant Design's `TextArea`
 * component for rendering a text area input field.
 *
 * @returns {Object} Returns an object containing an array of form fields for notes.
 *                   The array includes configuration for a single `TextArea` field.
 *
 * Usage:
 * const { notesFields } = useNotesFields();
 *
 * This can then be used in a form configuration or rendered directly in a form component.
 */

const { TextArea } = Input;

export const useNotesFields = () => {
  const notesFields = [
    {
      name: ['notes'],
      input: <TextArea />
    }
  ];

  return { notesFields };
};
