import { Checkbox, Input } from 'antd';

const { TextArea } = Input;

const recipients = [
  {
    label: 'Nomade Process',
    value: 'nomade'
  },
  {
    label: 'Notaire',
    value: 'notary'
  },
  {
    label: 'Avocat',
    value: 'lawyer'
  }
];

/**
 * Custom hook that returns an array of fields for the ComplianceElanRouter publication step 4.
 * @hook
 * @returns {Object} An object containing the fields array.
 */
export const useFields = () => {
  const fields = [
    {
      name: ['note_recipient'],
      key: 'note_recipient',
      valuePropName: 'checked',
      input: (
        <Checkbox.Group
          defaultValue={['Apple']}
          options={recipients}
          style={{ display: 'flex', flexDirection: 'column' }}
        />
      )
    },
    { name: ['note'], noLabel: true, input: <TextArea /> }
  ];

  return { fields };
};
