import { Alert, Col, Button } from 'antd';
import { useParams } from 'react-router-dom';
import {
  AlertOutlined,
  CloseCircleOutlined,
  HourglassOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { ExistingCapacityAlertMessage } from '../utils/ExistingCapacityAlertMessage';
import { Mission } from '../../../../../models/Mission';
import { Alert as AlertModel } from '../../../../../models/Alert';

/**
 * Renders an AlertCard component that displays a message about unpaid invoices.
 * If there are unpaid invoices, it shows the number of unpaid invoices.
 * If there are paid invoices, it shows a message about paid invoices.
 * Otherwise, it returns null.
 *
 * @component
 * @returns {JSX.Element|null} The rendered AlertCard component or null if there are no unpaid or paid invoices.
 */
export const AlertCard = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { invoices, mission, setCurrent, setMission, setForceRefresh, alerts } =
    useMissionContext();

  const existingDocumentToBeReWritten = (mission?.documents || []).find(
    (doc) => doc?.config_document?.wording === 'Documents à retaper'
  );

  const pcs3BalanceInvoice = invoices.find(
    (invoice) => invoice.type === 'BALANCE' && invoice.pcs_step === 'PCS_3'
  );

  const existingCapacity =
    mission?.collective_ownership?.capacity || mission?.ignore_capacity_alert;

  const unpaidInvoicesCount = invoices.filter(
    (invoice) => invoice.status === 'UNPAID'
  ).length;

  const columnSpan = () => {
    if (
      !existingCapacity &&
      unpaidInvoicesCount > 0 &&
      mission.type === 'PCS_2'
    ) {
      return 12;
    }
    return 24;
  };

  return (
    <>
      {!existingCapacity && mission.type === 'PCS_2' && (
        <Col span={columnSpan()}>
          <Alert
            showIcon
            message={<ExistingCapacityAlertMessage />}
            type="warning"
          />
        </Col>
      )}
      {unpaidInvoicesCount > 0 && (
        <Col span={columnSpan()}>
          <Alert
            showIcon
            message={t('missions.messages.unpaid_invoices', {
              number: unpaidInvoicesCount
            })}
            type="info"
          />
        </Col>
      )}
      {mission.type === 'PLANS_RESEARCH' &&
        mission.steps.principal === 'FILLED_RCP_INFORMATIONS' && (
          <Col span={columnSpan()}>
            <Alert
              showIcon
              message={t('missions.messages.rcp_infos_not_filled')}
              type="warning"
            />
          </Col>
        )}
      {mission?.type === 'PCS_2' &&
        mission?.steps?.sub === 'PROJECT_IMPLEMENTATION' && (
          <Col span={columnSpan()}>
            {existingDocumentToBeReWritten ? (
              <Alert
                showIcon
                message={t('missions.messages.documents_exchange')}
                type="info"
              />
            ) : (
              <Alert
                showIcon
                message={t('missions.messages.waiting_for_lawyer_analysis')}
                type="info"
              />
            )}
          </Col>
        )}
      {mission?.type === 'PCS_2' &&
      mission?.steps?.sub === 'SYNDIC_VALIDATION' ? (
        <Col span={columnSpan()}>
          <Button
            type="link"
            onClick={() =>
              Mission.updatePcsStatus({
                element: 4,
                id,
                setCurrent,
                setMission,
                setForceRefresh,
                stepType: 'sub',
                mission
              })
            }
          >
            <Alert
              showIcon
              message={t('missions.messages.waiting_for_syndic_validation')}
              type="info"
            />
          </Button>
        </Col>
      ) : null}
      {mission?.type === 'PCS_3' && !pcs3BalanceInvoice ? (
        <Col span={columnSpan()}>
          <Alert
            showIcon
            message={t('missions.messages.balance_invoice_missing')}
            type="error"
            icon={<AlertOutlined fontSize={24} />}
          />
        </Col>
      ) : null}
      {mission?.type === 'PCS_3' &&
      mission.steps.sub === 'PUBLICATION' &&
      mission.steps.principal === 'PCS_3' ? (
        <Col span={columnSpan()}>
          <Button type="link">
            <Alert
              showIcon
              message={t('missions.messages.waiting_act_publication')}
              type="info"
              icon={<HourglassOutlined fontSize={24} />}
            />
          </Button>
        </Col>
      ) : null}
      {(alerts || []).map((alert) => (
        <Col span={24}>
          <Alert
            showIcon
            message={alert.content}
            type="error"
            icon={<AlertOutlined fontSize={24} />}
            action={
              <Button
                size="small"
                type="link"
                onClick={() =>
                  AlertModel.deleteResource({ id: alert._id, setForceRefresh })
                }
              >
                <CloseCircleOutlined />
              </Button>
            }
          />
        </Col>
      ))}
    </>
  );
};
