/**
 * Retrieves the collective ownerships associated with a selected syndic.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function for making API requests.
 * @param {Function} options.message - The message function for displaying error messages.
 * @param {Object} options.selectedSyndic - The selected syndic object.
 * @param {Function} options.setCollectiveOwnerships - The function for setting the collective ownerships.
 * @returns {Promise<void>} - A promise that resolves when the collective ownerships are retrieved and set.
 */
export const getSelectedSyndicCollectiveOwnerships = async ({
  dispatchAPI,
  message,
  selectedSyndic,
  setCollectiveOwnerships
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/collective-ownerships?syndic._id=${selectedSyndic._id}`
    });

    setCollectiveOwnerships(data);
  } catch (error) {
    message(error);
  }
};
