import { Input } from 'antd';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Search } = Input;

/**
 * Renders a search input component for searching resources.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.searchValue - The initial value of the search input.
 * @returns {JSX.Element} The rendered SearchResources component.
 */
export const SearchResources = ({ searchValue }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const navigate = useNavigate();

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  return (
    <Search
      allowClear
      placeholder={t('placeholder.search')}
      defaultValue={searchValue}
      onSearch={(value) => searchResource(value)}
    />
  );
};

SearchResources.propTypes = {
  searchValue: PropTypes.string
};

SearchResources.defaultProps = {
  searchValue: ''
};
