import {
  DeleteOutlined,
  WarningOutlined,
  BankOutlined,
  CarOutlined,
  DesktopOutlined
} from '@ant-design/icons';

export const IconsArray = [
  {
    name: 'Supprimer',
    icon: <DeleteOutlined />
  },
  {
    name: 'Alerte',
    icon: <WarningOutlined />
  },
  {
    name: 'Banque',
    icon: <BankOutlined />
  },
  {
    name: 'Voiture',
    icon: <CarOutlined />
  },
  {
    name: 'Ordinateur',
    icon: <DesktopOutlined />
  }
];
