import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns configuration for the accountant exports expandable list.
 * @hook
 * @returns {Array} The array of columns configuration.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('accountant-exports.form.cerfa_number'),
      dataIndex: 'cerfa_number',
      key: 'cerfa_number',
      render: (cerfa_number) => cerfa_number || '-'
    },
    {
      title: t('missions.form.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: 'collective_ownership',
      render: (collective_ownership) =>
        `${collective_ownership?.code || ''} - ${
          collective_ownership?.first_address?.number || ''
        } ${collective_ownership?.first_address?.street || ''} ${
          collective_ownership?.first_address?.postal_code || ''
        } ${collective_ownership?.first_address?.city || ''} ${
          collective_ownership?.cadastral_reference
            ? `(${collective_ownership.cadastral_reference})`
            : ''
        }`
    },
    {
      title: t('accountant-exports.form.amount'),
      dataIndex: 'to_bill',
      key: 'to_bill',
      render: (to_bill) => (to_bill ? `${to_bill} €` : '-')
    }
  ];
};
