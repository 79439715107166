import { Select, Input, InputNumber, Radio } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

/**
 * The `useMainInfoFields` hook generates configurations for form fields related to the main information and address details of an entity.
 * It provides fields for basic information, status, preferences, billing mode, notes, and address details. The hook uses Ant Design's `Select`,
 * `Input`, `InputNumber`, `Radio`, and `TextArea` components for various input types.
 *
 * Parameters:
 * @param {Object} params - The parameters object.
 * @param {Object} params.enums - An object containing arrays of enumeration values for dropdown fields like status and billing mode, used to populate dropdown options.
 * @param {boolean} params.isFieldsLoading - A boolean indicating whether the enumeration data is being loaded, which controls the display of a loading indicator within the dropdown fields.
 * @param {function} params.t - A translation function provided by `react-i18next` for internationalization, used to translate labels and dropdown options.
 *
 * Returns:
 * @returns {Object} An object containing two arrays of field configurations: `informationsFields` and `addressFields`. Each field configuration may include:
 * - `name`: An array representing the path to the value in the form state, used as the name for the form field.
 * - `input`: A React component configured as the input for the form field, which can be a `Select` component for dropdown fields, `Input` or `TextArea` for text input fields, `InputNumber` for numeric inputs, or `Radio.Group` for binary choices.
 * - `rules`: An array of validation rules for the field, indicating if a field is required or has other validation criteria.
 *
 * The `Select` components are configured with `showSearch` to enable filtering through the options, and a custom `filterOption` function to control the search behavior. The `Radio.Group` components are used for binary choices with predefined options, and the `InputNumber` component includes an addon to specify units.
 *
 * Usage:
 * This hook is intended for use in form components where main information and address details for an entity need to be collected or edited. It abstracts the repetitive aspects of configuring form fields, simplifying form creation and maintenance.
 */

export const useMainInfoFields = ({ enums, isFieldsLoading, t }) => {
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const optionRadio = [
    { key: 'YES', value: true, label: t('radio-options.yes') },
    { key: 'NO', value: false, label: t('radio-options.no') }
  ];

  const informationsFields = [
    {
      name: ['society_name'],
      key: 'society_name',
      rules: [{ required: true }]
    },
    {
      name: ['code'],
      key: 'code',
      rules: [{ required: true }]
    },
    {
      name: ['name_for_accounting_export'],
      key: 'name_for_accounting_export'
    },
    {
      name: ['status'],
      key: 'status',
      input: (
        <Select
          showSearch
          loading={isFieldsLoading}
          optionFilterProp="children"
          filterOption={filterOption}
        >
          {(enums.syndic_status || []).map((item) => (
            <Option value={item} key={item}>
              {t(`syndics.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['force_code_OS'],
      key: 'force_code_OS',
      input: <Radio.Group options={optionRadio} />
    },
    {
      name: ['send_AG_convocation_by_mail'],
      key: 'send_AG_convocation_by_mail',
      input: <Radio.Group options={optionRadio} />
    },
    {
      name: ['wait_payment_for_send_PCS_to_lawyer'],
      key: 'wait_payment_for_send_PCS_to_lawyer',
      input: <Radio.Group options={optionRadio} />
    },
    {
      name: ['wait_payment_for_send_MEC_to_lawyer'],
      key: 'wait_payment_for_send_MEC_to_lawyer',
      input: <Radio.Group options={optionRadio} />
    },
    {
      name: ['enable_grid_reminders'],
      key: 'enable_grid_reminders',
      input: <Radio.Group options={optionRadio} />
    },
    {
      name: ['block_doc_if_invoice_is_pending'],
      key: 'block_doc_if_invoice_is_pending',
      input: <Radio.Group options={optionRadio} />
    },
    {
      name: ['billing_mode'],
      key: 'billing_mode',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          loading={isFieldsLoading}
          optionFilterProp="children"
          filterOption={filterOption}
        >
          {(enums.billing_modes || []).map((item) => (
            <Option value={item} key={item}>
              {t(`syndics.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['billing_email'],
      key: 'billing_email'
    },
    {
      name: ['waiting_period_before_reminder'],
      key: 'waiting_period_before_reminder',
      input: <InputNumber addonAfter="jours" />
    },
    {
      name: ['note_for_syndic'],
      key: 'note_for_syndic',
      input: <TextArea />
    }
  ];

  const addressFields = [
    {
      name: ['billing_address', 'number'],
      key: 'number'
    },
    {
      name: ['billing_address', 'street'],
      key: 'street'
    },
    {
      name: ['billing_address', 'additional'],
      key: 'additional'
    },
    {
      name: ['billing_address', 'postal_code'],
      key: 'postal_code'
    },
    {
      name: ['billing_address', 'city'],
      key: 'city'
    }
  ];

  return { informationsFields, addressFields };
};
