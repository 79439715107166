import { Form, Select, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { Mission } from '../../../../models/Mission';

const { Option } = Select;

/**
 * ReassignModalForm component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.syndics - The list of syndics.
 * @param {Array} props.selectedMissions - The list of selected collective ownerships.
 * @param {Function} props.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - The flag to force refresh.
 * @param {Function} props.setIsReassignModalOpen - The function to set the modal open flag.
 * @param {Function} props.setSelectedRowKeys - The function to set the selected row keys.
 * @param {Function} props.setSelectedMissions - The function to set the selected collective ownerships.
 * @returns {JSX.Element} The ReassignModalForm component.
 */
export const ReassignModalForm = ({
  selectedMissions,
  setForceRefresh,
  forceRefresh,
  setIsReassignModalOpen,
  setSelectedRowKeys,
  setSelectedMissions,
  isFieldLoading
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { collectiveOwnerships } = useGlobalContext();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form
      form={form}
      onFinish={(value) =>
        Mission.handleReassignFormSubmit({
          value,
          dispatchAPI,
          message,
          selectedMissions,
          setForceRefresh,
          forceRefresh,
          setIsReassignModalOpen,
          setSelectedRowKeys,
          setSelectedMissions
        })
      }
    >
      <Form.Item name="new_collective_ownership">
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldLoading}
        >
          {(collectiveOwnerships || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item?.code || ''} - ${item?.first_address?.number || ''} ${
                item?.first_address?.street || ''
              } ${item?.first_address?.postal_code || ''} ${
                item?.first_address?.city || ''
              } ${
                item?.cadastral_reference
                  ? `(${item?.cadastral_reference})`
                  : ''
              }`}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <Button
              type="primary"
              onClick={() => setIsReassignModalOpen(false)}
            >
              {t('buttons.cancel')}
            </Button>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

ReassignModalForm.propTypes = {
  setIsReassignModalOpen: PropTypes.func.isRequired,
  selectedMissions: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  setSelectedRowKeys: PropTypes.func.isRequired,
  setSelectedMissions: PropTypes.func.isRequired,
  isFieldLoading: PropTypes.bool
};

ReassignModalForm.defaultProps = {
  forceRefresh: false,
  isFieldLoading: false
};
