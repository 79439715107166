import { Row, Tag, Col } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { syndicStatus } from '../../../../../utils/constants/tagColors';

/**
 * The `useInformationsListContent` hook is designed to structure and prepare data related to a syndic entity for presentation in a list or detailed view format. It processes various pieces of information about the syndic, such as society name, code, accounting name, status, billing mode, billing email, billing address, waiting period before reminders, and several boolean parameters indicating specific syndic settings.
 *
 * @param {Object} [data={}] - An object containing information about a syndic entity. The object's properties include society_name, code, name_for_accounting_export, status, billing_mode, billing_email, billing_address, waiting_period_before_reminder, force_code_OS, send_AG_convocation_by_mail, wait_payment_for_send_PCS_to_lawyer, wait_payment_for_send_MEC_to_lawyer, enable_grid_reminders, and block_doc_if_invoice_is_pending.
 *
 * Returns:
 * An array of objects where each object represents a piece of information to be displayed in the list. Each object includes:
 * - `label`: A string key for internationalization purposes, used to retrieve the localized text for the label of the information.
 * - `span`: A numerical value indicating the span or width of the item in the list layout, used for alignment and formatting in grid or table-like displays.
 * - `content`: The content to be displayed for the item. This can be a simple string, a formatted string combining multiple pieces of data, a `Tag` component for status, or a React component for more complex data such as boolean parameters represented with icons.
 *
 * The hook utilizes the `useTranslation` hook from `react-i18next` for label internationalization, ensuring that the list content is accessible and understandable in different languages. It also uses Ant Design's `Row`, `Col`, `Tag`, and icon components (`CheckCircleFilled`, `CloseCircleFilled`) to visually represent boolean parameters and status.
 *
 * Usage:
 * This hook is intended for use in components that display detailed information about a syndic entity, particularly focusing on the syndic's main details and parameters. It provides a structured and visual way to present complex data sets in a list or table-like format.
 */

export const useInformationsListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    society_name,
    code,
    name_for_accounting_export,
    status,
    billing_mode,
    billing_email,
    billing_address,
    waiting_period_before_reminder,
    force_code_OS,
    send_AG_convocation_by_mail,
    wait_payment_for_send_PCS_to_lawyer,
    wait_payment_for_send_MEC_to_lawyer,
    enable_grid_reminders,
    block_doc_if_invoice_is_pending
  } = data;

  const useIcon = (isValidate) => {
    if (isValidate) {
      return <CheckCircleFilled style={{ width: 24, color: 'yellow' }} />;
    }
    return <CloseCircleFilled style={{ width: 24, color: 'grey' }} />;
  };

  return [
    {
      label: 'syndics.form.society_name',
      span: 1,
      content: society_name || '-'
    },
    {
      label: 'syndics.form.code',
      span: 1,
      content: code || '-'
    },
    {
      label: 'syndics.form.name_for_accounting_export',
      span: 1,
      content: name_for_accounting_export || '-'
    },
    {
      label: 'syndics.form.status',
      span: 1,
      content:
        (status && (
          <Tag color={syndicStatus[status]}>{t(`syndics.tags.${status}`)}</Tag>
        )) ||
        '-'
    },
    {
      label: 'syndics.form.billing_mode',
      span: 1,
      content: billing_mode ? t(`syndics.tags.${billing_mode}`) : '-'
    },
    {
      label: 'syndics.form.billing_email',
      span: 1,
      content: billing_email || '-'
    },
    {
      label: 'syndics.form.sub_titles.invoicing_address',
      span: 1,
      content: billing_address
        ? `${billing_address?.number || ''} ${billing_address?.street || ''} ${
            billing_address?.postal_code || ''
          } ${billing_address?.city || ''}`
        : '-'
    },
    {
      label: 'syndics.form.waiting_period_before_reminder',
      span: 2,
      content: waiting_period_before_reminder
        ? t('syndics.form.days', { number: waiting_period_before_reminder })
        : '-'
    },
    {
      label: 'syndics.form.parameters',
      span: 3,
      content: (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {useIcon(force_code_OS)} {t('syndics.form.force_code_OS')}
          </Col>
          <Col span={24}>
            {useIcon(send_AG_convocation_by_mail)}
            {t('syndics.form.send_AG_convocation_by_mail')}
          </Col>
          <Col span={24}>
            {useIcon(wait_payment_for_send_PCS_to_lawyer)}
            {t('syndics.form.wait_payment_for_send_PCS_to_lawyer')}
          </Col>
          <Col span={24}>
            {useIcon(wait_payment_for_send_MEC_to_lawyer)}
            {t('syndics.form.wait_payment_for_send_MEC_to_lawyer')}
          </Col>
          <Col span={24}>
            {useIcon(enable_grid_reminders)}
            {t('syndics.form.enable_grid_reminders')}
          </Col>
          <Col span={24}>
            {useIcon(block_doc_if_invoice_is_pending)}
            {t('syndics.form.block_doc_if_invoice_is_pending')}
          </Col>
        </Row>
      )
    }
  ];
};
