import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { userRoles } from '../../../../../utils/constants/tagColors';

/**
 * useInformationsListContent hook for ShowUser component
 * @param {*} data - data
 * @returns {Array} informationsListContent
 */
export const useInformationsListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    first_name,
    last_name,
    role,
    email,
    syndic,
    phone_number,
    last_connection
  } = data;

  return [
    {
      label: 'users.form.full_name',
      span: 1,
      content:
        last_name || first_name ? `${last_name || ''} ${first_name || ''}` : '-'
    },
    {
      label: 'users.form.role',
      span: 1,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.syndic',
      span: 1,
      content: syndic ? syndic.society_name : '-'
    },
    {
      label: 'users.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'users.form.phone_number',
      span: 1,
      content: phone_number?.number ? `0${phone_number.number}` : '-'
    },
    {
      label: t('collective-ownerships.form.last_manager_connection'),
      key: 'last_manager_connection',
      content: dayjs(last_connection).format('DD/MM/YYYY HH:mm') || '-'
    }
  ];
};
