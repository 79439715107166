import { Route, Routes } from 'react-router-dom';
import { PriceSystem } from './PriceSystem';
import { CreateUpdatePriceSystem } from './CreateUpdatePriceSystem';

export const PriceSystemRouter = () => (
  <Routes>
    <Route path="/" element={<PriceSystem />} />
    <Route
      path="/create/:missionType"
      element={<CreateUpdatePriceSystem purpose="create" />}
    />
    <Route
      path="/edit/:id/:missionType"
      element={<CreateUpdatePriceSystem purpose="edit" />}
    />
  </Routes>
);
