import { EditOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Space, Typography, Flex } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useDrag } from 'react-dnd';
import { Link } from 'react-router-dom';

const { Text } = Typography;

/**
 * Renders a card for the PCS pre-production kanban view.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data to render.
 * @returns {JSX.Element} The rendered KanBanCardPcsPreProduction component.
 */
export const KanBanCardPcsPreProduction = ({ data }) => {
  const { _id, syndic, history = [] } = data;
  const last_validation = history?.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  )[0]?.date;
  const date = last_validation
    ? dayjs(last_validation).format('DD/MM/YYYY')
    : '-';
  const [, drag] = useDrag({
    type: 'card',
    item: {
      type: 'card',
      id: _id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <Card ref={drag} hoverable className="kanban-pcs-card">
      <Row gutter={[8, 8]}>
        <Row>
          <Space style={{ color: 'var(--addColor)' }}>
            <UserOutlined />
            <Text style={{ color: 'var(--addColor)' }}>
              {syndic?.consultant?.first_name || '-'}{' '}
              {syndic?.consultant?.last_name || '-'}
            </Text>
          </Space>
        </Row>
        <Row>
          <Row>
            <Text style={{ fontWeight: 'bold' }}>
              {syndic?.society_name || '-'}
            </Text>
          </Row>
          <Row>{`${syndic?.billing_address?.number} 
        ${syndic?.billing_address?.street} 
        ${syndic?.billing_address?.postal_code} 
        ${syndic?.billing_address?.city}`}</Row>
        </Row>
        <Flex justify="space-between">
          <Col
            style={{ color: 'var(--gray600)' }}
          >{`Dernière val. ${date} `}</Col>
          <Flex>
            <Link
              to={{
                pathname: `/missions/pcs-pre-productions/show/${_id}`
              }}
            >
              <EyeOutlined
                style={{
                  color: 'var(--gray600)'
                }}
              />
            </Link>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/missions/pcs-pre-productions/edit/${_id}`
              }}
            >
              <EditOutlined
                style={{
                  color: 'var(--gray600)'
                }}
              />
            </Link>
          </Flex>
        </Flex>
      </Row>
    </Card>
  );
};

KanBanCardPcsPreProduction.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    syndic: PropTypes.shape({
      society_name: PropTypes.string,
      billing_address: PropTypes.shape({
        number: PropTypes.string,
        street: PropTypes.string,
        postal_code: PropTypes.string,
        city: PropTypes.string
      }),
      consultant: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string
      })
    }),
    consultant: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    history: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string
      })
    )
  }).isRequired
};

KanBanCardPcsPreProduction.defaultProps = {};
