import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Flex,
  Form,
  Image,
  Input,
  notification,
  Row,
  Typography
} from 'antd';
import {
  ArrowRightOutlined,
  ClockCircleFilled,
  EnvironmentFilled
} from '@ant-design/icons';
import { ContentCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import nous_contacter_syndic from '../../../assets/images/nous_contacter_syndic.png';

const { Title, Text } = Typography;
const { TextArea } = Input;
const tradKey = 'syndic-view.contact-section';
const descriptionStyle = { textAlign: 'justify' };
const contactTextStyle = { color: '#495057' };

/**
 * Renders the contact section for the syndic view.
 * @component
 * @returns {JSX.Element} The rendered contact section component.
 */
export const ContactSectionSyndic = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: 'mails/contact_us_syndic',
        body: values
      });
      if (data?.status_code === 201) {
        notification.success({ message: t('messages.form.message_sent') });
      }
    } catch (error) {
      message(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentCustom style={{ borderRadius: '15px' }}>
      <Row>
        <Title level={2}>{t(`${tradKey}.title`)}</Title>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={18}>
          <Row gutter={[32, 32]}>
            <Col span={12}>
              <Title level={4} className="yellow-underline">
                {t(`${tradKey}.form.title`)}
              </Title>
              <Form form={form} onFinish={onFinish}>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: t(`${tradKey}.form.required.subject`)
                    }
                  ]}
                >
                  <Input
                    placeholder={t(`${tradKey}.form.placeholders.subject`)}
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: t(`${tradKey}.form.required.message`)
                    }
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder={t(`${tradKey}.form.placeholders.message`)}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ borderRadius: '15px' }}
                  >
                    {t('buttons.send')} <ArrowRightOutlined />
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={6}>
              <Row>
                <Flex vertical>
                  <Flex align="center">
                    <EnvironmentFilled
                      style={{
                        fontSize: '24px',
                        marginRight: '1rem',
                        color: '#FFD53F'
                      }}
                    />
                    <Title level={4} className="yellow-underline">
                      {t(`${tradKey}.address.title`)}
                    </Title>
                  </Flex>
                  <Flex vertical>
                    <Text strong>{t(`${tradKey}.address.subtitle`)}</Text>
                    <Text>{t(`${tradKey}.address.street`)}</Text>
                    <Text>{t(`${tradKey}.address.city_and_code`)}</Text>
                  </Flex>
                </Flex>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Flex vertical>
                  <Flex>
                    <ClockCircleFilled
                      style={{
                        fontSize: '24px',
                        marginRight: '1rem',
                        color: '#FFD53F'
                      }}
                    />
                    <Title level={4} className="yellow-underline">
                      {t(`${tradKey}.schedule.title`)}
                    </Title>
                  </Flex>
                  <Flex vertical>
                    <Text strong> {t(`${tradKey}.schedule.subtitle_1`)} </Text>
                    <Text>{t(`${tradKey}.schedule.description_1`)}</Text>
                  </Flex>
                  <Flex vertical>
                    <Text strong> {t(`${tradKey}.schedule.subtitle_2`)} </Text>
                    <Text>{t(`${tradKey}.schedule.description_2`)}</Text>
                  </Flex>
                </Flex>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Image src={nous_contacter_syndic} preview={false} />
        </Col>
      </Row>
      <Row>
        <Title level={4} className="yellow-underline">
          {t(`${tradKey}.contact_services`)}
        </Title>
      </Row>
      <Row gutter={[32, 32]}>
        <Col span={5}>
          <Flex vertical>
            <Title level={5}>{t(`${tradKey}.commercial_service.title`)}</Title>
            <Text style={descriptionStyle}>
              {t(`${tradKey}.commercial_service.description`)}
            </Text>
            <a href={`mailto:${t(`${tradKey}.commercial_service.email`)}`}>
              <Text style={contactTextStyle}>
                {t(`${tradKey}.commercial_service.email`)}
              </Text>
            </a>
            <Text style={contactTextStyle}>
              {t(`${tradKey}.commercial_service.phone_number`)}
            </Text>
          </Flex>
        </Col>
        <Col span={5}>
          <Flex vertical>
            <Title level={5}>{t(`${tradKey}.production_service.title`)}</Title>
            <Text style={descriptionStyle}>
              {t(`${tradKey}.production_service.description`)}
            </Text>
            <a href={`mailto:${t(`${tradKey}.production_service.email`)}`}>
              <Text style={contactTextStyle}>
                {t(`${tradKey}.production_service.email`)}
              </Text>
            </a>
          </Flex>
        </Col>
        <Col span={5}>
          <Flex vertical>
            <Title level={5}>{t(`${tradKey}.collection_service.title`)}</Title>
            <Text style={descriptionStyle}>
              {t(`${tradKey}.collection_service.description`)}
            </Text>
            <a href={`mailto:${t(`${tradKey}.collection_service.email`)}`}>
              <Text style={contactTextStyle}>
                {t(`${tradKey}.collection_service.email`)}
              </Text>
            </a>
            <Text style={contactTextStyle}>
              {t(`${tradKey}.collection_service.phone_number`)}
            </Text>
          </Flex>
        </Col>
        <Col span={5}>
          <Flex vertical>
            <Title level={5}>{t(`${tradKey}.client_service.title`)}</Title>
            <Text style={descriptionStyle}>
              {t(`${tradKey}.client_service.description`)}
            </Text>
            <a href={`mailto:${t(`${tradKey}.client_service.email`)}`}>
              <Text style={contactTextStyle}>
                {t(`${tradKey}.client_service.email`)}
              </Text>
            </a>
            <Text style={contactTextStyle}>
              {t(`${tradKey}.client_service.phone_number`)}
            </Text>
          </Flex>
        </Col>
      </Row>
    </ContentCustom>
  );
};
