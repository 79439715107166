import { useEffect, useState } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { ExtraSearchBars } from './ExtraSearchBars';

/**
 * Component for displaying the list of collective ownerships forms.
 * @component
 * @returns {JSX.Element} The JSX element representing the component.
 */
export const ListCollectiveOwnershipsForms = () => {
  const { syndics, collectiveOwnerships, isDataLoading } = useGlobalContext();
  const [filters, setFilters] = useState('');
  const [extraQuery, setExtraQuery] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns({ forceRefresh, setForceRefresh });

  useEffect(() => {
    setExtraQuery(filters);
    setForceRefresh(!forceRefresh);
  }, [filters]);

  return (
    <ListResource
      resourceName="collective-ownership-form"
      populate="collective_ownership,syndic,mission"
      columns={columns}
      extraQuery={extraQuery}
      forceRefresh={forceRefresh}
      resourceModelName="Collective-ownership-form"
      customActionColumn
      withCreateButton={false}
      withUploadButton
      searchBarExtraButtons={
        <ExtraSearchBars
          setFilters={setFilters}
          syndics={syndics}
          collectiveOwnerships={collectiveOwnerships}
          isFiedlLoading={isDataLoading}
        />
      }
    />
  );
};
