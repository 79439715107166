import { updateChargingGrids } from './updateCollectiveOwnership';

export const handleSaveAllTable = async ({
  form,
  dataSource,
  dispatchAPI,
  chargingGrid,
  resourceDatas,
  setResourceDatas,
  setChargingGrid,
  setEditTable,
  message
}) => {
  try {
    const values = await form.validateFields();

    const newData = dataSource.map((element) => {
      const updatedElement = { ...element };

      Object.keys(element).forEach((key) => {
        if (element.key !== 1 && !updatedElement.delete_row) {
          if (values[`key${element.key}`][key]) {
            updatedElement[key].value = values[`key${element.key}`][key];
          }
        }
      });

      return updatedElement;
    });

    const formData = new FormData();

    formData.append('values', JSON.stringify(newData));

    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${chargingGrid._id}?type=edit_data`,
      body: formData
    });

    updateChargingGrids({
      resourceDatas,
      chargingGrid: data,
      setResourceDatas
    });
    setChargingGrid(data);
    setEditTable(false);
  } catch (e) {
    message(e);
  }
};
