import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Button, Layout, Menu, message } from 'antd';
import {
  GlobalOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/images/logo_prestation_word.svg';
import { useAuthContext } from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import useLanguageContext from '../../../contexts/LanguageContext';
import navMenuLogos from '../../../utils/constants/navMenuLogos';
import { secondaryRenderMenuItems } from './secondaryRenderMenuItems';

const { Header } = Layout;

const StyledHeader = styled.div`
  background: var(--menuDropdownBackground);
  height: 60px;
  padding: 12px;
  z-index: 8;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 992px) {
    justify-content: space-between;
  }
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledDropdownContainer = styled.div`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  transition: all 0.3s;

  :hover {
    background: var(--contrastBackground);
  }

  .ant-btn-link {
    height: fit-content;
  }
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-weight: bold;
`;

const StyledRoleP = styled.p`
  font-size: 10px;
  color: var(--textColor);
  margin-bottom: 0;
`;

const TriggerCollapse = styled.div`
  color: var(--textColor);
  font-size: 20px;
  padding: 0 24px;
  cursor: pointer;
  z-index: 10000;
  :hover {
    color: var(--primaryColor);
  }
`;

export const HeaderLayoutSecondary = ({
  collapseSider,
  collapsed,
  collapseWidth,
  themeLogo,
  pathSearches,
  routes,
  withoutConfigItem
}) => {
  const navigate = useNavigate();
  const { user, dispatchAPI } = useAuthContext();
  const { dispatch: dispatchLocale } = useLanguageContext();
  const { t, i18n } = useTranslation();
  const [widthTrigger, setWidthTrigger] = useState('100vw');

  const location = useLocation();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  const menuItems = secondaryRenderMenuItems(
    routes,
    [],
    t,
    navMenuLogos,
    NavLink,
    pathSearches
  );

  const LogoDiv = styled.div`
    position: relative;
    height: auto;
    margin-right: 20px;
    overflow: hidden;
    transition: all 0.3s;
    z-index: 900;
    display: flex;
    justify-content: begin;

    img {
      display: inline-block;
      height: 35px;
      vertical-align: middle;
    }
  `;

  const languages = {
    en_GB: {
      label: 'English',
      icon: '🇬🇧'
    },
    fr_FR: {
      label: 'Français',
      icon: '🇫🇷'
    },
    es_ES: {
      label: 'Español',
      icon: '🇪🇸'
    }
  };

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const onLanguageMenuClick = ({ key }) => {
    dispatchLocale({ type: 'SET_LOCALE', locale: key });
  };

  const localizationMenu = {
    onClick: onLanguageMenuClick,
    items: Object.keys(languages).map((locale) =>
      i18n.language === locale
        ? {
            key: locale,
            label: (
              <>
                <span>{languages[locale].icon}</span>
                {` ${languages[locale].label}`}
              </>
            )
          }
        : {
            key: locale,
            label: (
              <>
                <span>{languages[locale].icon}</span>
                {` ${languages[locale].label}`}
              </>
            )
          }
    )
  };

  const profileMenu = {
    onClick: onProfileMenuClick,
    items: [
      ...(window.innerWidth <= 992 && user
        ? [
            {
              key: 'user',
              label: (
                <Button
                  style={{
                    width: '100%',
                    height: 50
                  }}
                  type="link"
                >
                  <StyledFullNameSpan>
                    {`${user.first_name} ${user.last_name}`}
                  </StyledFullNameSpan>
                  <StyledRoleP>
                    {t(`users.tags.${user.role.split(':')[1]}`)}
                  </StyledRoleP>
                </Button>
              )
            }
          ]
        : []),
      {
        key: 'profile',
        onClick: () => navigate('/profile'),
        label: (
          <>
            {' '}
            <UserOutlined
              style={{
                fontSize: 16,
                marginRight: 4
              }}
            />
            {` ${t('header.menu.user.profile')}`}
          </>
        )
      },
      ...(withoutConfigItem
        ? []
        : [
            {
              key: 'settings',
              onClick: () => navigate('/settings'),
              label: (
                <>
                  <SettingOutlined
                    style={{
                      fontSize: 16,
                      marginRight: 4
                    }}
                  />
                  {` ${t('header.menu.user.settings')}`}
                </>
              )
            }
          ]),
      {
        key: 'logout',
        label: (
          <>
            <LogoutOutlined
              style={{
                fontSize: 16,
                marginRight: 4
              }}
            />
            {` ${t('header.menu.user.logout')}`}
          </>
        )
      }
    ]
  };

  useEffect(() => {
    if (collapsed) {
      if (collapseWidth === 0) {
        setWidthTrigger('100vw');
      } else {
        setWidthTrigger('calc(100vw - 80px)');
      }
    } else {
      setWidthTrigger('calc(100vw - 256px)');
    }
  }, [collapsed, collapseWidth]);

  return (
    <StyledHeader as={Header} width={widthTrigger}>
      {window.innerWidth < 992 && (
        <TriggerCollapse
          as={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
          onClick={() => collapseSider(!collapsed)}
        />
      )}
      {themeLogo && (
        <img
          style={{
            width: 100,
            maxHeight: 50,
            position: 'absolute',
            top: 10,
            left: '50%',
            marginLeft: '-50px'
          }}
          src={themeLogo}
          alt="Company logo"
        />
      )}
      <StyledContainer>
        <LogoDiv>
          <Link to="/">
            <img alt="Logo" src={collapsed ? null : Logo} />
          </Link>
        </LogoDiv>
        <Menu
          selectedKeys={activeKeys}
          defaultOpenKeys={activeKeys}
          mode="horizontal"
          items={menuItems}
        />
      </StyledContainer>
      <StyledContainer>
        <HeaderDropdown overlayMenu={profileMenu}>
          {window.innerWidth <= 992 ? (
            <StyledDropdownContainer>
              <UserOutlined />
            </StyledDropdownContainer>
          ) : (
            <StyledDropdownContainer>
              <Avatar
                size="medium"
                icon={user?.photo ? '' : <UserOutlined />}
                src={user?.photo ? user.photo : ''}
              >
                {`${user?.first_name[0]}${user?.last_name[0]}`}
              </Avatar>
              <Button type="link">
                <StyledFullNameSpan>
                  {`${user?.first_name} ${user?.last_name}`}
                </StyledFullNameSpan>
                <StyledRoleP>
                  {t(`users.tags.${user?.role.split(':')[1]}`)}
                </StyledRoleP>
              </Button>
            </StyledDropdownContainer>
          )}
        </HeaderDropdown>
        <HeaderDropdown overlayMenu={localizationMenu}>
          <StyledDropdownContainer>
            <GlobalOutlined
              style={{
                fontSize: 16
              }}
            />
          </StyledDropdownContainer>
        </HeaderDropdown>
      </StyledContainer>
    </StyledHeader>
  );
};

HeaderLayoutSecondary.propTypes = {
  collapseSider: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseWidth: PropTypes.number.isRequired,
  themeLogo: PropTypes.string,
  pathSearches: PropTypes.shape({}),
  routes: PropTypes.shape({}),
  withoutConfigItem: PropTypes.bool
};

HeaderLayoutSecondary.defaultProps = {
  pathSearches: null,
  routes: null,
  themeLogo: null,
  withoutConfigItem: false
};
