/**
 * Fetches the template for the charging grid.
 *
 * @function
 * @async
 * @param {Object} options - The options for fetching the template.
 * @param {Function} options.dispatchAPI - The dispatchAPI function for making API requests.
 * @param {Function} options.setTemplate - The function for setting the template data.
 * @param {Function} options.message - The function for displaying error messages.
 * @returns {Promise<void>} - A promise that resolves when the template is fetched and set.
 */
export const getTemplate = async ({ dispatchAPI, setTemplate, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/template?type=charging_grid`
    });
    setTemplate(data);
  } catch (e) {
    if (e.response) message(e.response);
  }
};
