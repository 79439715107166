import { Button, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  syndicViewSubtitle,
  syndicViewTitle
} from '../../utils/styleConstants';

/**
 * @hook
 * Generates the title and subtitle for the collective ownership component.
 * @returns {Object} An object containing the title and subtitle JSX elements.
 */
export const collectiveOwnershipTitle = () => {
  const { t } = useTranslation();

  const title = (
    <Row>
      <h2 style={syndicViewTitle}>
        {t('syndic-collective-ownerships.titlteCard.title')}
      </h2>
      <Button
        type="primary"
        style={{
          backgroundColor: '#ffd53f',
          borderRadius: '15px',
          color: '#222222',
          margin: '0px 10px'
        }}
      >
        <EditOutlined />
        {t('syndic-collective-ownerships.titlteCard.buttonText')}
      </Button>
    </Row>
  );

  const subtitle = (
    <p style={syndicViewSubtitle}>
      {t('syndic-collective-ownerships.titlteCard.subtitle')}
    </p>
  );

  return { title, subtitle };
};
