import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { AddIcon } from '../../utils/constants/customIcons';
import { SearchResources } from '../SearchResources/SearchResources';
import { ExportXlsxTemplateButton } from '../ExportXlsxTemplateButton/ExportXlsxTemplateButton';

export const ListResource = ({
  tableLayout,
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  withPageHeader,
  withSearchBar,
  forceRefresh,
  resourceModelName,
  editAction,
  showAction,
  duplicateAction,
  printAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  formatter,
  customParams,
  setResourceData,
  rowSelection,
  searchBarExtraButtons,
  extraUrlPath,
  style,
  exportTemplateUrl,
  sheetName,
  tableStyle
}) => {
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) {
      setSearchValue(keyword);
    } else {
      setSearchValue(null);
    }
  }, [keyword]);

  const menu = {
    items: [
      ...(headers
        ? [
            {
              key: 'export',
              label: (
                <ExportButton
                  dataName={resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                />
              )
            }
          ]
        : []),
      {
        key: 'import',
        label: <ImportButton resourceName={resourceModelName} />
      },
      ...(exportTemplateUrl
        ? [
            {
              key: 'exportTemplate',
              label: (
                <ExportXlsxTemplateButton
                  dataName={resourceName}
                  url={`/${exportTemplateUrl || resourceName}`}
                  sheetName={sheetName}
                />
              )
            }
          ]
        : [])
    ]
  };

  return (
    <>
      {withPageHeader && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
        />
      )}
      <ContentCustom style={style}>
        <Row justify={withSearchBar ? 'space-between' : 'end'} gutter={[8, 16]}>
          {withSearchBar && (
            <Row gutter={[16, 16]}>
              <Col>
                <SearchResources searchValue={searchValue} />
              </Col>
              {searchBarExtraButtons && <Col>{searchBarExtraButtons}</Col>}
            </Row>
          )}
          {!withCreateButton && headers && (
            <Col>
              <Dropdown menu={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            </Col>
          )}
          {withCreateButton && (
            <Col>
              <Row align="middle">
                {extraButtons}
                <Link
                  to={
                    withCreateButton?.path ||
                    `${pathname}/create${customParams || ''}`
                  }
                >
                  <Button type="add">
                    {withCreateButton?.buttonText || `${t('buttons.create')}`}
                    &nbsp;
                    {withCreateButton?.buttonIcon || <AddIcon />}
                  </Button>
                </Link>
                {withUploadButton && (
                  <Dropdown menu={menu}>
                    <Button type="link">
                      <MenuOutlined
                        style={{ fontSize: 16, color: 'var(--textColor)' }}
                      />
                    </Button>
                  </Dropdown>
                )}
              </Row>
            </Col>
          )}
          {!withCreateButton && <Col>{extraButtons}</Col>}
        </Row>
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          tableLayout={tableLayout}
          style={{ marginTop: 16, ...tableStyle }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          duplicateAction={duplicateAction}
          printAction={printAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 'fit-container' }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          setResourceData={setResourceData}
          rowSelection={rowSelection}
          extraUrlPath={extraUrlPath}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  tableLayout: PropTypes.string,
  style: PropTypes.shape({}),
  tableStyle: PropTypes.shape({}),
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  customParams: PropTypes.string,
  setResourceData: PropTypes.func,
  rowSelection: PropTypes.shape({}),
  searchBarExtraButtons: PropTypes.shape({}),
  extraUrlPath: PropTypes.string,
  exportTemplateUrl: PropTypes.string,
  sheetName: PropTypes.string
};

ListResource.defaultProps = {
  tableLayout: 'auto',
  style: undefined,
  tableStyle: undefined,
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withSearchBar: true,
  withPageHeader: true,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  formatter: undefined,
  customParams: null,
  setResourceData: null,
  rowSelection: null,
  searchBarExtraButtons: false,
  extraUrlPath: '',
  exportTemplateUrl: null,
  sheetName: null
};
