/**
 * Returns the action fields map.
 * @returns {Object} The action fields map.
 */
export const useRightsCalculationFields = () => {
  const actionFieldsMap = {
    LOT_CREATION: [
      {
        name: 'fixed_mortgage_tax',
        label: 'quotation-act-amounts.form.fixed_mortgage_tax',
        key: 'fixed_mortgage_tax'
      },
      {
        name: 'publication_contribution',
        label: 'quotation-act-amounts.form.publication_contribution',
        key: 'publication_contribution'
      },
      {
        name: 'formality_status',
        label: 'quotation-act-amounts.form.formality_status',
        key: 'formality_status'
      },
      {
        name: 'total_notary_ttc',
        label: 'quotation-act-amounts.form.total_notary_ttc',
        key: 'total_notary_ttc'
      },
      {
        name: 'nomade_honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_ttc'
      },
      {
        name: 'nomade_honorary_extra',
        label: 'quotation-act-amounts.form.nomade_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_extra'
      },
      {
        name: 'provision',
        label: 'quotation-act-amounts.form.provision',
        input: 'number',
        unit: '%',
        key: 'provision'
      },
      {
        name: 'provision_nomade_ht',
        label: 'quotation-act-amounts.form.provision_nomade_ht',
        key: 'provision_nomade_ht'
      }
    ],
    LOT_MERGE: [
      {
        name: 'fixed_mortgage_tax',
        label: 'quotation-act-amounts.form.fixed_mortgage_tax',
        key: 'fixed_mortgage_tax'
      },
      {
        name: 'publication_contribution',
        label: 'quotation-act-amounts.form.publication_contribution',
        key: 'publication_contribution'
      },
      {
        name: 'formality_status',
        label: 'quotation-act-amounts.form.formality_status',
        key: 'formality_status'
      },
      {
        name: 'total_notary_ttc',
        label: 'quotation-act-amounts.form.total_notary_ttc',
        key: 'total_notary_ttc'
      },
      {
        name: 'nomade_honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_ttc'
      },
      {
        name: 'nomade_honorary_extra',
        label: 'quotation-act-amounts.form.nomade_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_extra'
      },
      {
        name: 'provision',
        label: 'quotation-act-amounts.form.provision',
        input: 'number',
        unit: '%',
        key: 'provision'
      },
      {
        name: 'provision_nomade_ht',
        label: 'quotation-act-amounts.form.provision_nomade_ht',
        key: 'provision_nomade_ht'
      }
    ],
    LOT_DIVISION: [
      {
        name: 'fixed_mortgage_tax',
        label: 'quotation-act-amounts.form.fixed_mortgage_tax',
        key: 'fixed_mortgage_tax'
      },
      {
        name: 'publication_contribution',
        label: 'quotation-act-amounts.form.publication_contribution',
        key: 'publication_contribution'
      },
      {
        name: 'formality_status',
        label: 'quotation-act-amounts.form.formality_status',
        key: 'formality_status'
      },
      {
        name: 'total_notary_ttc',
        label: 'quotation-act-amounts.form.total_notary_ttc',
        key: 'total_notary_ttc'
      },
      {
        name: 'nomade_honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_ttc'
      },
      {
        name: 'nomade_honorary_extra',
        label: 'quotation-act-amounts.form.nomade_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_extra'
      },
      {
        name: 'provision',
        label: 'quotation-act-amounts.form.provision',
        input: 'number',
        unit: '%',
        key: 'provision'
      },
      {
        name: 'provision_nomade_ht',
        label: 'quotation-act-amounts.form.provision_nomade_ht',
        key: 'provision_nomade_ht'
      }
    ],
    LOT_REMOVAL: [
      {
        name: 'fixed_mortgage_tax',
        label: 'quotation-act-amounts.form.fixed_mortgage_tax',
        key: 'fixed_mortgage_tax'
      },
      {
        name: 'publication_contribution',
        label: 'quotation-act-amounts.form.publication_contribution',
        key: 'publication_contribution'
      },
      {
        name: 'formality_status',
        label: 'quotation-act-amounts.form.formality_status',
        key: 'formality_status'
      },
      {
        name: 'total_notary_ttc',
        label: 'quotation-act-amounts.form.total_notary_ttc',
        key: 'total_notary_ttc'
      },
      {
        name: 'nomade_honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_ttc'
      },
      {
        name: 'nomade_honorary_extra',
        label: 'quotation-act-amounts.form.nomade_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_extra'
      },
      {
        name: 'provision',
        label: 'quotation-act-amounts.form.provision',
        input: 'number',
        unit: '%',
        key: 'provision'
      },
      {
        name: 'provision_nomade_ht',
        label: 'quotation-act-amounts.form.provision_nomade_ht',
        key: 'provision_nomade_ht'
      }
    ],
    CONSISTENCY_DESTINATION_CHANGE: [
      {
        name: 'fixed_mortgage_tax',
        label: 'quotation-act-amounts.form.fixed_mortgage_tax',
        key: 'fixed_mortgage_tax'
      },
      {
        name: 'publication_contribution',
        label: 'quotation-act-amounts.form.publication_contribution',
        key: 'publication_contribution'
      },
      {
        name: 'formality_status',
        label: 'quotation-act-amounts.form.formality_status',
        key: 'formality_status'
      },
      {
        name: 'total_notary_ttc',
        label: 'quotation-act-amounts.form.total_notary_ttc',
        key: 'total_notary_ttc'
      },
      {
        name: 'nomade_honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_ttc'
      },
      {
        name: 'nomade_honorary_extra',
        label: 'quotation-act-amounts.form.nomade_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'nomade_honorary_extra'
      },
      {
        name: 'provision',
        label: 'quotation-act-amounts.form.provision',
        input: 'number',
        unit: '%',
        key: 'provision'
      },
      {
        name: 'provision_nomade_ht',
        label: 'quotation-act-amounts.form.provision_nomade_ht',
        key: 'provision_nomade_ht'
      }
    ]
  };

  return { actionFieldsMap };
};
