import PropTypes from 'prop-types';
import { FixInheritances } from './FixInheritances';
import { NotaryHonorary } from './NotaryHonorary';
import { NomadeHonorary } from './NomadeHonorary';
import { Total } from './Total';
import { RightsCalculation } from './RightsCalculation';
import { Actions } from './Actions';

/**
 * Configuration mapping actions to their respective components.
 * @constant
 * @type {Object}
 * @property {Array} PUBLICATION_PCS - The components to render for the PUBLICATION_PCS action.
 * @property {Array} ENJOYMENT_RIGHT_TO_VALIDATED - The components to render for the ENJOYMENT_RIGHT_TO_VALIDATED action.
 * @property {Array} CHARGING_GRID - The components to render for the CHARGING_GRID action.
 * @property {Array} LOT_ENJOYMENT_RIGHT - The components to render for the LOT_ENJOYMENT_RIGHT action.
 * @property {Array} LOT_CREATION_PCS - The components to render for the LOT_CREATION_PCS action.
 * @property {Array} SURVEYOR_QUOTATION - The components to render for the SURVEYOR_QUOTATION action.
 * @property {Array} LOT_CREATION - The components to render for the LOT_CREATION action.
 * @property {Array} LOT_MERGE - The components to render for the LOT_MERGE action.
 * @property {Array} LOT_DIVISION - The components to render for the LOT_DIVISION action.
 * @property {Array} CONSISTENCY_DESTINATION_CHANGE - The components to render for the CONSISTENCY_DESTINATION_CHANGE action.
 * @property {Array} LOT_REMOVAL_PCS - The components to render for the LOT_REMOVAL_PCS action.
 * @property {Array} OTHER - The components to render for the OTHER action.
 */
const actionComponentMap = {
  PUBLICATION_PCS: [Actions, NotaryHonorary, NomadeHonorary],
  ENJOYMENT_RIGHT_TO_VALIDATED: [Actions, NotaryHonorary, NomadeHonorary],
  CHARGING_GRID: [Actions, FixInheritances, NotaryHonorary, NomadeHonorary],
  LOT_ENJOYMENT_RIGHT: [Actions, NotaryHonorary, NomadeHonorary],
  LOT_CREATION_PCS: [Actions, FixInheritances, NotaryHonorary, NomadeHonorary],
  SURVEYOR_QUOTATION: [NomadeHonorary],
  LOT_CREATION: [Actions, FixInheritances, RightsCalculation],
  LOT_MERGE: [Actions, FixInheritances, RightsCalculation],
  LOT_DIVISION: [Actions, FixInheritances, RightsCalculation],
  LOT_REMOVAL: [Actions, FixInheritances, RightsCalculation],
  CONSISTENCY_DESTINATION_CHANGE: [Actions, NotaryHonorary, NomadeHonorary],
  LOT_REMOVAL_PCS: [Actions, FixInheritances, NotaryHonorary, NomadeHonorary],
  OTHER: [NomadeHonorary],
  SALE: [Actions, NomadeHonorary],
  EXCHANGE: [Actions, NomadeHonorary]
};

/**
 * Renders the Act Data Card based on the provided actAmount.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.actAmount - The actAmount object containing quotation action details.
 * @returns {JSX.Element|null} The rendered Act Data Card component.
 */
export const RenderActAmountDataCard = ({ actAmount }) => {
  const actions = [
    ...new Set(
      (actAmount?.quotation_acts || []).map(
        (act) => act.quotation_action.action
      )
    )
  ];

  const componentsToRender = [
    ...new Set(actions.flatMap((action) => actionComponentMap[action] || []))
  ];

  return (
    <>
      {componentsToRender.map((Component) => (
        <Component actAmount={actAmount} />
      ))}
      <Total actAmount={actAmount} />
    </>
  );
};

RenderActAmountDataCard.propTypes = {
  actAmount: PropTypes.shape({
    quotation_acts: PropTypes.arrayOf(
      PropTypes.shape({
        quotation_action: PropTypes.shape({
          action: PropTypes.string
        })
      })
    )
  })
};

RenderActAmountDataCard.defaultProps = {
  actAmount: {
    quotation_action: {
      action: ''
    }
  }
};
