import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for rendering an extra search button.
 * @component
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setFilter - The function to set the filter value.
 * @returns {JSX.Element} - The JSX element representing the extra search button.
 */
export const ExtraSearchButton = ({ setFilter }) => {
  const { t } = useTranslation();

  const missionTypeValues = [
    'PCS_1',
    'PCS_2',
    'PCS_3',
    'RCP_RESEARCH',
    'AUDIT_1_2',
    'PLANS_RESEARCH',
    'OUTLAWED_PUBLICATION_ELAN',
    'OUTLAWED_GEOMETER_STUDY_ELAN',
    'AUDIT_1'
  ];

  const missionTypes = missionTypeValues.map((value) => ({
    value,
    label: t(`missions.tags.${value}`)
  }));

  const onChange = (value) => {
    if (!value) {
      return setFilter('');
    }
    return setFilter(value);
  };

  return (
    <Select
      placeholder={t('syndic-collective-ownerships.list.select_mission_type')}
      onChange={(v) => onChange(v)}
      allowClear
      options={missionTypes}
    />
  );
};

ExtraSearchButton.propTypes = {
  setFilter: PropTypes.func.isRequired
};
