import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useColumns } from './useColumns';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';
import { ListResource } from '../../../../../components';
import { syndicContentCustomStyle } from '../../../utils/styleConstants';
import mes_brouillons_1 from '../../../../../assets/images/mes_brouillons_1.svg';
import { ServicesCustomContent } from '../../ServicesCustomContent';
import { dashboardTitle } from '../../ComplianceElanRouter/dashboardTitles';

/**
 * Renders a list of services drafts.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.requested_mission_type - The requested mission types.
 * @param {boolean} props.with_PCS_PRE_PRODUCTION - Indicates if PCS Pre production is enabled.
 * @param {string} props.missionsButtonTradKey - The translation key for the missions button.
 * @returns {JSX.Element} The rendered component.
 */
export const ServicesDraftsList = ({ requested_mission_type }) => {
  const missionsString = requested_mission_type.join(',');
  const { currentSyndic } = useGlobalContext();
  const navigate = useNavigate();
  const columns = useColumns();
  const { myDraftsTitle, myDraftsSubtitle } = dashboardTitle();
  const fieldsToFetch = [
    'type',
    '_id',
    'name',
    'updated_at',
    'draft_step',
    'collective_ownership.first_address',
    'collective_ownership.first_city.name',
    'collective_ownership.name',
    'requested_mission_type',
    'new_collective_ownership',
    'pcs_pre_production'
  ];
  const buildStringFields = (fields) => fields.join(',');
  const fieldsString = buildStringFields(fieldsToFetch);

  const getNavigateUrl = (record) => {
    let basePath = '/services/compliance-loi-elan';

    switch (record?.requested_mission_type || missionsString) {
      case 'PCS_PRE_PRODUCTION':
      case 'PCS_2':
      case 'PCS_3':
        basePath = '/services/compliance-loi-elan';
        break;
      case 'PLANS_RESEARCH':
        basePath = '/services/plans-research';
        break;
      case 'RCP_RESEARCH':
        basePath = '/services/rcp-research';
        break;
      default:
        break;
    }

    const paths = {
      PCS_PRE_PRODUCTION: 'audit/edit',
      PCS_2: 'compliance/edit',
      PCS_3: 'publication/edit',
      PLANS_RESEARCH: 'devis/edit',
      RCP_RESEARCH: 'devis/edit'
    };

    const missionTypePath =
      paths[record?.requested_mission_type] || paths[missionsString];

    return missionTypePath
      ? `${basePath}/${missionTypePath}/${record._id}`
      : null;
  };

  const onDoubleClickAction = {
    action: (record) => {
      const url = getNavigateUrl(record);
      if (url) navigate(url);
    }
  };

  const MISSIONS_POPULATE =
    'mission,collective_ownership,collective_ownership.first_city,new_collective_ownership.first_city,pcs_pre_production,pcs_pre_production.collective_ownership';
  const MISSIONS_QUERY = `syndic=${currentSyndic}&status=DRAFT&requested_mission_type=${missionsString}&fields=${fieldsString}&sort=-updated_at`;

  return (
    <ServicesCustomContent
      title={myDraftsTitle}
      subtitle={myDraftsSubtitle}
      imageOnRight={mes_brouillons_1}
      cardStyle={{ backgroundColor: '#222222' }}
    >
      <div style={{ width: '100%' }}>
        <ListResource
          ContentCustomStyle={syndicContentCustomStyle}
          withPageHeader={false}
          resourceName="syndic-requests"
          extraQuery={MISSIONS_QUERY}
          populate={MISSIONS_POPULATE}
          onDoubleClickAction={onDoubleClickAction}
          columns={columns}
          customActionColumn
          withCreateButton={false}
        />
      </div>
    </ServicesCustomContent>
  );
};

ServicesDraftsList.propTypes = {
  requested_mission_type: PropTypes.arrayOf(PropTypes.string)
};

ServicesDraftsList.defaultProps = {
  requested_mission_type: []
};
