import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../../components';
import { ConfigurationsList } from './ConfigurationList';
import { ConfigurationProvider } from '../../../contexts/ConfigurationContext';

export const ConfigurationRouter = () => (
  <ConfigurationProvider>
    <Routes>
      <Route index element={<ConfigurationsList />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ConfigurationProvider>
);
