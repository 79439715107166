/**
 * Returns a configuration object for resource retrieval.
 * @hook
 * @param {Object} mission The mission object.
 * @param {number} checkedCheckboxCount The number of checked checkboxes.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({
  mission,
  checkedCheckboxCount,
  setCheckedCheckboxCount,
  notification,
  t
}) => ({
  onUpdateResource: {
    setBody: (data) => {
      if (
        checkedCheckboxCount.count === 0 &&
        checkedCheckboxCount.show_message
      ) {
        setCheckedCheckboxCount({
          count: 0,
          show_message: false
        });

        return notification.warning({
          message: t('missions.messages.lawyer_consultation_nothing_checked')
        });
      }

      const updatedData = {
        ...data,
        lawyer_step: 'AG_DECISION',
        lawyer_consultation_received: true,
        show_messages: { ...mission?.show_messages, AG_decision: true }
      };

      return updatedData;
    }
  }
});
