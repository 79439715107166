import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Checkbox, Flex, Tag, Tooltip, notification, Spin } from 'antd';
import {
  VerticalAlignBottomOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { documentTag } from '../../../utils/constants/tagColors';
import { useAuthContext } from '../../../contexts/AuthContext';
import { Document } from '../../../models/Document';
import { inputDisplaySmall } from '../../../utils/generateFormItem/utils/inputDisplaySmall';

/**
 * Custom hook to generate columns configuration for displaying orders in a table.
 *
 * @hook
 * @param {object} options - Options object.
 * @param {Function} options.toggleUrgent - Function to toggle the urgent status of an order.
 * @returns {Array} - An array of column configuration objects for Ant Design Table.
 */
export const useColumns = ({ toggleUrgent, setForceRefresh }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [uploading, setUploading] = useState(false);
  const [uploadedRowId, setUploadedRowId] = useState('');

  const draggerProps = ({ id, mission }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      setUploading(true);
      try {
        setUploadedRowId(id);
        await Document.uploadDocument({
          id,
          file: objectFile.file,
          extraQuery: `?resource=orders&missionId=${mission._id}&step=${
            mission?.steps.sub || mission?.steps.principal
          }&user=${user._id}`,
          setForceRefresh
        });
        notification.success({
          message: t('orders.notifications.upload_document_success_title'),
          description: t(
            'orders.notifications.upload_document_success_description'
          )
        });
      } catch (e) {
        notification.error({
          message: t('orders.notifications.upload_document_error_title'),
          description: t(
            'orders.notifications.upload_document_error_description'
          )
        });
      }
      setUploading(false);
    },
    fileList: []
  });

  return [
    {
      title: t('orders.form.cerfa_document_type'),
      key: 'cerfa_document_type',
      dataIndex: 'cerfa_document_type',
      render: (cerfa_document_type) =>
        cerfa_document_type ? cerfa_document_type.wording : '-',
      sorter: true
    },
    {
      title: t('orders.form.urgent'),
      key: 'urgent',
      dataIndex: 'urgent',
      render: (urgent, order) => (
        <Flex vertical justify="center" align="center">
          <Checkbox
            defaultChecked={urgent}
            onChange={(e) => toggleUrgent(e.target.checked, order._id)}
          />
        </Flex>
      )
    },
    {
      title: t('orders.form.ref_doc'),
      key: 'ref_doc',
      dataIndex: 'ref_doc',
      render: (ref_doc) => ref_doc || '-'
    },
    {
      title: t('orders.form.file'),
      key: 'file',
      render: (record) =>
        uploading && uploadedRowId === record.document._id ? (
          <Flex justify="center">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24
                  }}
                  spin
                />
              }
              size="small"
            />
          </Flex>
        ) : (
          inputDisplaySmall(
            true,
            false,
            {
              ...draggerProps({
                id: record.document._id,
                mission: record.mission
              })
            },
            false,
            t,
            true
          )
        )
    },
    {
      title: t('orders.form.status'),
      key: 'status',
      dataIndex: ['document', 'status'],
      render: (status) => (
        <Tag color={documentTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      ),
      sorter: true
    },
    {
      title: t('orders.form.syndic'),
      key: 'syndic',
      dataIndex: ['mission', 'syndic'],
      render: (syndic) =>
        syndic ? `${syndic.society_name} (${syndic.code})` : '-',
      sorter: true
    },
    {
      title: t('orders.form.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: ['mission', 'collective_ownership'],
      render: (collective_ownership) =>
        collective_ownership
          ? [
              collective_ownership.code || '-',
              collective_ownership.name || '-',
              `${collective_ownership.first_address.street || '-'} ${
                collective_ownership.first_address.postal_code || '-'
              } ${collective_ownership.first_address.city || '-'}`
            ].join(' - ')
          : '-',
      sorter: true
    },
    {
      title: t('orders.form.request_date'),
      key: 'request_date',
      dataIndex: 'request_date',
      render: (request_date) =>
        request_date ? dayjs(request_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('orders.form.reception_date'),
      key: 'reception_date',
      dataIndex: 'reception_date',
      render: (reception_date) =>
        reception_date ? dayjs(reception_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('orders.form.concerned_SPF'),
      key: 'concerned_SPF',
      dataIndex: 'concerned_SPF',
      render: (concerned_SPF) => (concerned_SPF ? concerned_SPF.name : '-'),
      sorter: true
    },
    {
      title: t('orders.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (costs) => (costs ? `${costs} €` : '-'),
      sorter: true
    },
    {
      title: t('orders.form.cheque'),
      key: 'cheque_number',
      dataIndex: 'cheque_number',
      render: (cheque_number) => cheque_number || '-',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Link
          to={{
            pathname: `export/${record?._id}`
          }}
        >
          <Tooltip title={t('orders.form.tooltips.export')}>
            <VerticalAlignBottomOutlined style={{ fontSize: 18 }} />
          </Tooltip>
        </Link>
      )
    }
  ];
};
