import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns for the cash statistics list
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useMissingDocummentsColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('statistics.columns.consultant'),
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: true
    },
    {
      title: t('statistics.columns.missing_documents.total_syndics'),
      dataIndex: 'total_syndics',
      key: 'total_syndics',
      sorter: true
    },
    {
      title: t(
        'statistics.columns.missing_documents.total_collective_ownerships'
      ),
      dataIndex: 'total_collective_ownerships',
      key: 'total_collective_ownerships',
      sorter: true
    },
    {
      title: t('statistics.columns.missing_documents.total_delays'),
      dataIndex: 'total_delays',
      key: 'total_delays',
      sorter: true
    },
    {
      title: t('statistics.columns.missing_documents.percentage'),
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: true
    }
  ];
};
