import { Row, Col } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating the consultation list content.
 *
 * @hook
 * @param {Object} data - The data object containing consultation information.
 * @returns {Array} - An array containing the consultation list content.
 */
export const useConsultationListContent = (data = {}) => {
  const { t } = useTranslation();
  const { lawyer_consultation = {} } = data;

  const useIcon = (isValidate) => {
    if (isValidate) {
      return <CheckCircleFilled style={{ width: 24, color: 'yellow' }} />;
    }
    return <CloseCircleFilled style={{ width: 24, color: 'grey' }} />;
  };

  return [
    {
      noLabel: true,
      span: 3,
      content: (
        <Row gutter={[16, 16]}>
          {Object.keys(lawyer_consultation)
            .filter((key) => !Array.isArray(lawyer_consultation[key]))
            .map((key) => (
              <Col span={24} key={key}>
                {useIcon(lawyer_consultation[key])}
                {t(`missions.form.lawyer_consultation.${key}`)}
              </Col>
            ))}
        </Row>
      )
    }
  ];
};
