import { useState, useEffect } from 'react';
import { Form, Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columns/collectiveOwnershipColumns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { EditableTableMethods } from '../../../../components/editableTableComponents/methods';
import { addColumns } from '../../../../components/editableTableComponents/addColumns';
import { EditableTable } from '../../../../components/editableTableComponents/EditableTable';
import { useMissionContext } from '../../../../contexts/MissionContext';
import { CollectiveOwnershipsButtons } from './extraButtons/CollectiveOwnershipsButtons';
import { ExtraButtons } from './extraButtons/ListCollectiveOwnershipsExtraButtons';
import { GetEnums } from '../../../../utils/getEnums';

/**
 * Renders the ListCollectiveOwnerships component.
 *
 * @component
 * @returns {JSX.Element} The ListCollectiveOwnerships component.
 */
export const ListCollectiveOwnerships = ({
  pcsPreProduction,
  isLoading,
  setForceRefresh,
  forceRefresh
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectType, setSelectType] = useState('');
  const { getResourceData } = EditableTableMethods();
  const { searchValue, setPagination, pagination, currentSorter } =
    useMissionContext();
  const [filters, setFilters] = useState('');
  const [isFiedlLoading, setIsFieldLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const columns = useColumns({
    setEditingKey,
    form,
    setSelectType,
    setForceRefresh,
    forceRefresh
  });
  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;
  const importDoc = pcsPreProduction.documents.find(
    (doc) => doc.fileKey === 'collective_ownerships_import'
  );

  const { getEnums } = GetEnums();

  const handleDataSource = async () => {
    const ids = pcsPreProduction.collective_ownerships.map(
      (collectiveOwnership) => ({ _id: String(collectiveOwnership) })
    );

    const collectiveOwnershipsDatas = await getResourceData({
      resource: 'collective-ownerships',
      searchValue,
      setPagination,
      pagination,
      query: `&filter={"$or":${JSON.stringify(ids)}}&${filters}`,
      currentSorter
    });

    const updatedCollectiveOwnerships = (collectiveOwnershipsDatas || []).map(
      (collectiveOwnership, index) => ({
        ...collectiveOwnership,
        key: index
      })
    );
    setDataSource(updatedCollectiveOwnerships);
  };

  useEffect(() => {
    (async () => {
      const collectiveOwnerShipStatus = await getEnums({
        resource: 'collective-ownerships'
      });
      setEnums(
        (collectiveOwnerShipStatus.status || []).filter(
          (el) => !['DELETED', 'INACTIVE'].includes(el)
        )
      );

      setIsFieldLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await handleDataSource();
    })();
  }, [forceRefresh, searchValue, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: mergedColumns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      selectType,
      resource: 'collective-ownerships'
    });
  }, [editingKey, dataSource]);

  return (
    <Card
      title={t(
        'pcs-pre-productions.show.cards.titles.collective_ownerships_informations'
      )}
    >
      <Skeleton loading={isLoading} active>
        <EditableTable
          resource="collective-ownerships"
          dataSource={dataSource}
          mergedColumns={mergedColumns}
          setSelectedRowKey={setSelectedRowKey}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          extraQuery={filters}
          form={form}
          pagination={pagination}
          setPagination={setPagination}
          withoutAddLineButton
          isCustomRowClassName
          customRowValue="ACTIVE"
          withoutSearchButton
          extraHeader={
            <>
              <ExtraButtons
                setFilters={setFilters}
                isFiedlLoading={isFiedlLoading}
                enums={enums}
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
              />
              <CollectiveOwnershipsButtons
                importDoc={importDoc}
                dataSource={dataSource}
              />
            </>
          }
        />
      </Skeleton>
    </Card>
  );
};

ListCollectiveOwnerships.propTypes = {
  pcsPreProduction: PropTypes.shape({
    collective_ownerships: PropTypes.arrayOf(PropTypes.shape({})),
    documents: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired
};
