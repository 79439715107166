import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * @description SelectorItems component
 * @param {Object} props - The props object.
 * @param {Function} props.filterOption - The filterOption function.
 * @param {boolean} props.loading - The loading flag.
 * @param {string} props.placeholder - The placeholder text.
 * @param {Array} props.data - The data array.
 * @param {string} props.navigation - The navigation string.
 * @param {boolean} props.isNavigable - The isNavigable flag.
 * @param {Function} props.customAction - The customAction function.
 * @param {boolean} props.isTag - The isTag flag.
 * @param {number} props.width - The width number.
 * @param {string} props.customTranslate - The customTranslate string.
 * @param {boolean} props.forceRefresh - The forceRefresh flag.
 * @param {Function} props.setForceRefresh - The setForceRefresh function.
 * @returns {JSX.Element} - The JSX element.
 */
const SelectorItems = ({
  filterOption,
  loading,
  placeholder,
  data,
  navigation,
  isNavigable = true,
  customAction,
  isTag = true,
  width = 250,
  customTranslate = 'users.tags.',
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Select
      style={{
        width,
        marginRight: 10
      }}
      showSearch
      allowClear
      onClear={() => {
        customAction('');
        setForceRefresh(!forceRefresh);
      }}
      optionFilterProp="children"
      filterOption={filterOption}
      onSelect={(value) => {
        if (isNavigable) {
          navigate(navigation + value);
          customAction(value);
        } else {
          customAction(value);
        }
      }}
      loading={loading}
      placeholder={placeholder}
      onChange={() => {
        setForceRefresh(!forceRefresh);
      }}
    >
      {(data || []).map((item) => {
        const label = isTag
          ? t(`${customTranslate}${item.split(':')[1]}`)
          : t(`${customTranslate}${item}`);
        return (
          <Option value={item} key={item} label={label}>
            {label}
          </Option>
        );
      })}
    </Select>
  );
};

SelectorItems.defaultProps = {
  filterOption: true,
  loading: false,
  placeholder: '',
  data: [],
  navigation: '',
  isNavigable: true,
  customAction: () => {},
  isTag: true,
  width: 250,
  customTranslate: 'users.tags.',
  forceRefresh: false,
  setForceRefresh: () => {}
};

SelectorItems.propTypes = {
  filterOption: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
  navigation: PropTypes.string,
  isNavigable: PropTypes.bool,
  customAction: PropTypes.func,
  isTag: PropTypes.bool,
  customTranslate: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

export default SelectorItems;
