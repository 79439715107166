import dayjs from 'dayjs';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { invoiceTag, missionTag } from '../../../../utils/constants/tagColors';

/**
 * Custom hook that returns an array of objects containing invoice information.
 *
 * @hook
 * @param {object} data - The data object containing invoice information.
 * @returns {Array} - An array of objects containing invoice information.
 */
export const useInvoiceInformationsListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    reference,
    type,
    status,
    sending_date,
    mission,
    cancellation_fee,
    tva,
    total_ttc,
    paid,
    left_to_pay,
    total_ht,
    disbursement,
    lawyer_fee_ht
  } = data;

  return [
    {
      label: 'invoices.form.reference',
      span: 1,
      content: reference || ''
    },
    {
      label: 'invoices.form.type',
      span: 1,
      content: type ? t(`invoices.tags.${type}`) : ''
    },
    {
      label: 'invoices.form.status',
      span: 1,
      content: status ? (
        <Tag color={invoiceTag[status]}>
          {t(`invoices.tags.${status}`, {
            defaultValue: status
          })}
        </Tag>
      ) : (
        ''
      )
    },
    {
      label: 'invoices.form.sending_date',
      span: 1,
      content: sending_date ? dayjs(sending_date).format('DD-MM-YYYY') : ''
    },
    {
      label: 'invoices.form.syndic',
      span: 1,
      content: mission?.syndic
        ? `${mission?.syndic?.society_name || ''} ${
            mission?.syndic?.code ? `(${mission?.syndic?.code})` : ''
          }`
        : ''
    },
    {
      label: 'invoices.form.collective_ownership',
      span: 1,
      content: `${mission?.collective_ownership?.code || ''} - ${
        mission?.collective_ownership?.first_address?.number || ''
      } ${mission?.collective_ownership?.first_address?.street || ''} ${
        mission?.collective_ownership?.first_address?.postal_code || ''
      } ${mission?.collective_ownership?.first_address?.city || ''} ${
        mission?.collective_ownership?.cadastral_reference
          ? `(${mission?.collective_ownership?.cadastral_reference})`
          : ''
      }`
    },
    {
      label: 'invoices.form.type',
      span: 1,
      content: mission?.type ? (
        <Tag color={missionTag[mission?.type]}>
          {t(`missions.tags.${mission?.type}`, {
            defaultValue: mission?.type
          })}
        </Tag>
      ) : (
        ''
      )
    },
    {
      label: 'invoices.form.billing_address',
      span: 1,
      content: mission?.syndic?.billing_address
        ? `${mission?.syndic?.billing_address?.number || ''} ${
            mission?.syndic?.billing_address?.street || ''
          } ${mission?.syndic?.billing_address?.postal_code || ''} ${
            mission?.syndic?.billing_address?.city || ''
          }`
        : '-'
    },
    {
      label: 'invoices.form.cancellation_fee',
      span: 1,
      content: cancellation_fee
        ? `${parseFloat(cancellation_fee).toFixed(2)} €`
        : '0.00 €'
    },
    {
      label: 'invoices.form.tva',
      span: 1,
      content: tva ? `${Math.round((tva / total_ttc) * 100)} %` : ''
    },
    {
      label: 'invoices.form.total_ttc',
      span: 1,
      content: total_ttc ? `${parseFloat(total_ttc).toFixed(2)} €` : '0.00 €'
    },
    {
      label: 'invoices.form.paid',
      span: 1,
      content: paid ? `${parseFloat(paid).toFixed(2)} €` : ''
    },
    {
      label: 'invoices.form.left_to_pay',
      span: 1,
      content: left_to_pay ? `${parseFloat(left_to_pay).toFixed(2)} €` : ''
    },
    {
      label: 'invoices.form.total_ht',
      span: 1,
      content: total_ht ? `${parseFloat(total_ht).toFixed(2)} €` : ''
    },
    {
      label: 'invoices.form.disbursement',
      span: 1,
      content: disbursement ? `${parseFloat(disbursement).toFixed(2)} €` : ''
    },
    {
      label: 'invoices.form.lawyer_fee_ht',
      span: 1,
      content: lawyer_fee_ht ? `${parseFloat(lawyer_fee_ht).toFixed(2)} €` : ''
    }
  ];
};
