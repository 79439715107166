import pluralize from 'pluralize';

/**
 * Fetches the syndics from the API.
 * @function
 * @async
 * @returns {Promise<void>} A promise that resolves when the syndics are fetched.
 */
export const getSyndics = async ({
  dispatchAPI,
  user,
  setSyndics,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/collective-ownerships?populate=syndic'
    });

    const syndicsData = [];
    data.forEach((collective_ownership) => {
      const { syndic, permissions } = collective_ownership;
      const userRole = user.role;
      const userPermissions =
        permissions[pluralize(userRole.toLowerCase().split(':')[1])];
      if (userPermissions?.includes(user._id)) {
        if (!syndicsData.find((el) => el._id === syndic._id)) {
          syndicsData.push(syndic);
        }
      }
    });
    setSyndics(syndicsData);
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
