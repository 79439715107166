import { useGlobalContext } from '../../../../../../contexts/GlobalContext';
import { useDraggerConfig } from '../../../utils/DraggerField/useDraggerConfig';

/**
 * Custom hook for managing configuration data in the ComplianceElanRouter Audit component.
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setInitialValues - The function to set the initial values.
 * @param {Object} options.customFormData - The custom form data object.
 * @param {Function} options.setFilesList - The function to set the files list.
 * @param {Function} options.setFilesConfiguration - The function to set the files configuration.
 * @param {Object} options.filesList - The files list object.
 * @param {Object} options.filesConfiguration - The files configuration object.
 * @param {Object} options.initialValues - The initial values object.
 * @param {Array} options.stepTitles - The step titles array.
 * @param {Function} options.setCurrent - The function to set the current step.
 * @returns {Object} - The object containing the functions and data for managing configuration.
 */
export const useConfig = ({
  setInitialValues,
  customFormData,
  setFilesList,
  setFilesConfiguration,
  filesList,
  filesConfiguration,
  initialValues,
  setCurrent
}) => {
  const { currentSyndic } = useGlobalContext();
  const { draggerOnGetResource, draggerOnCreateAndUpdateCustomFormData } =
    useDraggerConfig();

  return {
    onGetResource: {
      setFields: async (data) => {
        setCurrent(data.draft_step);

        await draggerOnGetResource({
          data,
          setFilesList,
          setFilesConfiguration
        });

        const newData = {
          ...data,
          syndic: currentSyndic
        };

        setInitialValues(newData);
        return newData;
      }
    },
    onCreateResource: {
      setBody: (data) => {
        draggerOnCreateAndUpdateCustomFormData({
          filesList,
          filesConfiguration,
          customFormData
        });
        return {
          ...data,
          ...initialValues,
          syndic: currentSyndic,
          requested_mission_type: 'PCS_2'
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        draggerOnCreateAndUpdateCustomFormData({
          filesList,
          filesConfiguration,
          customFormData
        });

        return {
          ...data,
          ...initialValues,
          syndic: currentSyndic,
          requested_mission_type: 'PCS_2'
        };
      }
    }
  };
};
