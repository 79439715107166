import { Drawer, Button, Form } from 'antd';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem/generateFormItem';
import { onPermissionFormSubmit } from '../../utils/onPermissionFormSubmit';
import { useHandleResize } from '../../../../utils/handleResize';

/**
 * `AddPermissionsModal` is a component that renders a modal using Ant Design's `Drawer` component.
 * It is primarily used for adding or modifying permissions in the context of collective ownerships.
 * The modal includes a form that dynamically generates form items based on the fields provided by the `useFields` custom hook.
 * Upon form submission, the `onPermissionFormSubmit` function is called to handle the submission logic,
 * which may involve API calls and updating local state depending on the purpose of the form.
 *
 * Props:
 * @prop {boolean} openModal - Controls the visibility of the modal.
 * @prop {function} setOpenModal - Function to update the visibility state of the modal.
 * @prop {boolean} isFieldsLoading - Indicates if the fields data is currently loading.
 * @prop {Object} professionals - Object containing arrays of different professional entities involved, such as lawyers and notaries.
 * @prop {function} setPermissions - Function to update the permissions state.
 * @prop {Object} permissions - The current permissions settings.
 * @prop {function} t - Translation function used for i18n.
 * @prop {function} dispatchAPI - Function to dispatch API calls.
 * @prop {function} message - Function to display messages, typically used for showing success or error notifications.
 * @prop {string} id - Identifier for the current context or item for which permissions are being set.
 * @prop {string} purpose - Indicates the purpose of the form, which can affect the behavior on form submission (e.g., whether to update the database).
 *
 * Default Props:
 * @prop {Object} professionals - Defaults to an empty object with default structures for lawyers, notaries, surveyors, and others.
 * @prop {Object} permissions - Defaults to an object with empty arrays for lawyers, notaries, surveyors, and others.
 */

export const AddPermissionsModal = ({
  openModal,
  setOpenModal,
  isFieldsLoading,
  professionals,
  setPermissions,
  permissions,
  t,
  dispatchAPI,
  message,
  id,
  purpose
}) => {
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { fields } = useFields({ isFieldsLoading, professionals, t });
  const { width } = useHandleResize();

  return (
    <Drawer
      title={t('collective-ownerships.form.collapse.modal.title')}
      width={width < 800 ? '80%' : '40%'}
      placement="right"
      onClose={() => setOpenModal(false)}
      open={openModal}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) =>
          onPermissionFormSubmit({
            values,
            permissions,
            professionals,
            setPermissions,
            setOpenModal,
            updateDataBase: purpose === 'show',
            dispatchAPI,
            message,
            id
          })
        }
      >
        {fields.map((field) => generateFields('collective-ownerships', field))}
        <Button type="primary" htmlType="submit">
          {t('buttons.save')}
        </Button>
      </Form>
    </Drawer>
  );
};

AddPermissionsModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  setPermissions: PropTypes.func.isRequired,
  isFieldsLoading: PropTypes.bool.isRequired,
  professionals: PropTypes.shape({
    lawyers: PropTypes.arrayOf(PropTypes.shape({})),
    notaries: PropTypes.arrayOf(PropTypes.shape({})),
    surveyors: PropTypes.arrayOf(PropTypes.shape({})),
    others: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  permissions: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired
};

AddPermissionsModal.defaultProps = {
  professionals: {},
  permissions: { lawyers: [], notaries: [], surveyors: [], others: [] }
};
