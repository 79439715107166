/**
 * Custom hook for creating and updating configuration.
 *
 * @hook
 * @param {Object} options - The configuration options.
 * @param {string} options.type - The type of configuration.
 * @returns {Object} - The configuration object.
 */
export const useConfig = ({ type }) => ({
  onCreateResource: {
    setBody: (data) => {
      const updatedData = {
        ...data,
        type
      };

      return updatedData;
    }
  }
});
