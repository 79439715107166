import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  ContentCustom,
  ListResource,
  PageHeaderCustom
} from '../../../components';
import { useColumns } from './columns';
import { GetEnums } from '../../../utils/getEnums';
import { User } from '../../../models/User';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import { SearchBar } from './SearchsBar';

/**
 * Renders the Activity Monitoring List component.
 * @component
 * @returns {JSX.Element} The rendered Activity Monitoring List component.
 */
export const ActivityMonitoringList = () => {
  const { t } = useTranslation();
  const { getEnums } = GetEnums();
  // eslint-disable-next-line no-unused-vars
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [enums, setEnums] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [filters, setFilters] = useState({});
  const [extraQuery, setExtraQuery] = useState('');
  const columns = useColumns();

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const { roles } = await getEnums({ resource: 'users' });
      const usersData = await User.getResources({
        populate: '',
        extraQuery: selectedRole ? `role=${selectedRole}` : ''
      });
      setEnums(roles);
      setUsersList(usersData);
      setIsDataLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const users = await User.getResources({
        populate: '',
        extraQuery: selectedRole ? `role=${selectedRole}` : ''
      });
      setUsersList(users);
    })();
  }, [selectedRole]);

  useEffect(() => {
    const updatedExtraQuery = transformExtraQuery(filters);
    setExtraQuery(updatedExtraQuery);
  }, [filters]);

  return (
    <>
      <PageHeaderCustom title={t('activity-monitorings.title')} />
      <ContentCustom>
        <ListResource
          resourceName="activity-monitorings"
          columns={columns}
          populate="user,syndic,manager"
          resourceModelName="ActivityMonitoring"
          extraQuery={extraQuery}
          withCreateButton={false}
          withUploadButton={false}
          withPageHeader={false}
          deleteAction={false}
          editAction={false}
          withSearchBar
          scroll={{ x: 'max-content' }}
          searchBarExtraButtons={
            <SearchBar
              setFilters={setFilters}
              usersList={usersList}
              setSelectedRole={setSelectedRole}
              isDataLoading={isDataLoading}
              enums={enums}
              setForceRefresh={setForceRefresh}
            />
          }
        />
      </ContentCustom>
    </>
  );
};
