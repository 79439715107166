import { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Flex, Form, Row } from 'antd';
import { CreateUpdateContainer } from '../../../../../components';
import SyndicViewContentCustom from '../../../SyndicViewContentCustom';
import { dashboardTitle } from '../../ComplianceElanRouter/dashboardTitles';
import { useFields } from './useFields';
import Launch_mission from '../../../../../assets/images/launch_mission.png';
import { SubmitAndSaveButtons } from './DevisExtraButtons';
import { useConfig } from './useConfig';

/**
 * Create or update a devis.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the devis.
 * @param {string} props.requested_mission_type - The requested mission type for the devis.
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateDevis = ({ purpose, requested_mission_type }) => {
  const [form] = Form.useForm();
  const { fields } = useFields({ requested_mission_type, form });
  const { devisTitle, devisSubtitle } = dashboardTitle();
  const [status, setStatus] = useState('DRAFT');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const config = useConfig(status, requested_mission_type);

  return (
    <SyndicViewContentCustom
      title={devisTitle}
      subtitle={devisSubtitle}
      imageOnRight={Launch_mission}
      cardStyle={{ backgroundColor: '#222222' }}
      childrenStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Row style={{ width: '100%' }}>
        <Col span={16} offset={4}>
          <CreateUpdateContainer
            customFormInstance={form}
            fields={fields}
            config={config}
            customErrorMessage={() => {
              setIsSubmitting(false);
            }}
            purpose={purpose}
            baseUrl="syndic-requests"
            resource="syndic-requests"
            layout="vertical"
            customLabelCol={{}}
            withEnums={false}
            customWrapperCol={{}}
            withCustomPageHeader
            withFilesManager={false}
            withCustomButtons
          />
          <Flex justify="flex-end" gap="small">
            <SubmitAndSaveButtons
              form={form}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              onSaveAction={() => {
                setStatus('DRAFT');
              }}
              onSubmitAction={() => {
                setStatus('PENDING_VALIDATION');
              }}
              buttonTradKey="send_devis_request"
            />
          </Flex>
        </Col>
      </Row>
    </SyndicViewContentCustom>
  );
};

CreateUpdateDevis.propTypes = {
  requested_mission_type: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired
};
