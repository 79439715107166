import PropTypes from 'prop-types';
import { useState } from 'react';
import { Flex, Form, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import SyndicViewContentCustom from '../../../SyndicViewContentCustom';
import { dashboardTitle } from '../dashboardTitles';
import { CreateUpdateContainer } from '../../../../../components';
import { CollectivesOwnershipStep } from './Step1/CollectivesOwnershipStep';
import { SignPublicationDevis } from './Step2/SignPublicationDevis';
import { SubmitPublicationDocuments } from './Step3/SubmitPublicationDocuments';
import { useDraggerFunctions } from '../../utils/DraggerField/draggerFunctions';
import { StepsButtons } from '../../utils/useStepsButtons';
import { useConfig } from './utils/useConfig';
import { PublicationNoteStep } from './Step4/PublicationNoteStep';
import Launch_mission from '../../../../../assets/images/launch_mission.png';

const tradKeyForm = 'missions.form';
const resource = 'syndic-requests';

export const stepTitlesPublication = [
  'step_collective_ownership',
  'step_sign_devis',
  'step_submit_other_elements',
  'step_note'
];
/**
 * Component for creating or updating compliance.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the compliance.
 * @returns {JSX.Element} The CreateUpdateCompliance component.
 */
export const CreateUpdatePublication = ({ purpose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const customFormData = new FormData();
  const [current, setCurrent] = useState(0);
  const [initialValues, setInitialValues] = useState({
    requested_mission_type: 'PCS_3'
  });
  const [draggerFilesList, setDraggerFilesList] = useState([]);
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const { deleteFile } = useDraggerFunctions({ resource });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [buttonsActive, setButtonsActive] = useState({
    next: true,
    back: true,
    saveDraft: true,
    submit: true
  });

  const config = useConfig({
    setInitialValues,
    customFormData,
    draggerFilesList,
    setDraggerFilesList,
    setFilesConfiguration,
    filesConfiguration,
    initialValues,
    setCurrent
  });

  const { publicationSubtitle, publicationTitle, servicesCardStyle } =
    dashboardTitle();

  const steps = stepTitlesPublication.map((titleKey) => ({
    title: t(`${tradKeyForm}.${titleKey}`)
  }));

  const forms = [
    <CollectivesOwnershipStep
      initialValues={initialValues}
      form={form}
      setButtonsActive={setButtonsActive}
    />,
    <SignPublicationDevis
      form={form}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      setButtonsActive={setButtonsActive}
    />,
    <SubmitPublicationDocuments
      initialValues={initialValues}
      form={form}
      draggerFilesList={draggerFilesList}
      setDraggerFilesList={setDraggerFilesList}
      setFilesConfiguration={setFilesConfiguration}
      deleteFile={deleteFile}
      filesConfiguration={filesConfiguration}
      purpose={purpose}
      setButtonsActive={setButtonsActive}
    />,
    <PublicationNoteStep
      form={form}
      initialValues={initialValues}
      filesList={draggerFilesList}
      setFilesList={setDraggerFilesList}
      setFilesConfiguration={setFilesConfiguration}
      filesConfiguration={filesConfiguration}
      purpose={purpose}
      deleteFile={deleteFile}
      setButtonsActive={setButtonsActive}
    />
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title
  }));

  return (
    <SyndicViewContentCustom
      title={publicationTitle}
      subtitle={publicationSubtitle}
      imageOnRight={Launch_mission}
      cardStyle={servicesCardStyle}
      childrenStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Steps
        style={{
          width: '97.5%'
        }}
        current={current}
        items={items}
      />
      <div style={{ width: '100%' }}>
        <CreateUpdateContainer
          customFormInstance={form}
          fields={[]}
          populate="syndic,syndic.assistants managers,documents,documents.files"
          formExtra={forms[current]}
          layout="vertical"
          isFieldsLoading={false}
          purpose={purpose}
          baseUrl={resource}
          resource={resource}
          config={config}
          customFormData={customFormData}
          customErrorMessage={() => {
            setIsSubmitting(false);
          }}
          customUpdateFormData={customFormData}
          withEnums={false}
          withFilesManager={false}
          withCustomPageHeader
          withCustomButtons
        />
      </div>
      <Flex justify="flex-end" gap="small">
        <StepsButtons
          steps={steps}
          current={current}
          setCurrent={setCurrent}
          form={form}
          setInitialValues={setInitialValues}
          buttonsActive={buttonsActive}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          extraSubmitValues={{
            status: 'PENDING_VALIDATION'
          }}
          extraSaveDraftValues={{
            status: 'DRAFT',
            draft_step: current
          }}
        />
      </Flex>
    </SyndicViewContentCustom>
  );
};

CreateUpdatePublication.propTypes = {
  purpose: PropTypes.string
};

CreateUpdatePublication.defaultProps = {
  purpose: 'create'
};
