import { Route, Routes } from 'react-router-dom';
import { PcsPreProductions } from './PcsPreProductions';
import { CreateUpdatePcsPreProduction } from './CreateUpdatePcsPreProduction';
import { ShowPcsPreProduction } from './ShowPcsPreProduction/ShowPcsPreProduction';
import { Exception } from '../../../components';

/**
 * Renders the dynamic configuration router component.
 * @router
 * @returns {JSX.Element} The dynamic configuration router component.
 */
export const PcsPreProductionRouter = () => (
  <Routes>
    <Route path="/" element={<PcsPreProductions />} />
    <Route
      path="/create"
      element={<CreateUpdatePcsPreProduction purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdatePcsPreProduction purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowPcsPreProduction />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
