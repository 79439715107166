import { Select, Tag, Checkbox, Input, Row, Form, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { missionTag } from '../../utils/constants/tagColors';
import { Message } from '../../models/Message';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { useAuthContext } from '../../contexts/AuthContext';

Quill.register('modules/imageResize', ImageResize);

const { Option } = Select;

/**
 * Custom hook that returns an array of fields for the Messages component.
 * @hook
 * @param {Object} options - The options object.
 * @param {boolean} options.isFieldsLoading - Flag indicating if the fields are loading.
 * @param {Object} options.enums - The enums object.
 * @param {Array} options.recipients - The recipients array.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setEditorState - The function to set the editor state.
 * @param {Object} options.editorState - The editor state.
 * @param {Array} options.predefinedMessages - The predefined messages array.
 * @returns {Object} - The fields object.
 */
export const useFields = ({
  isFieldsLoading,
  enums,
  recipients,
  form,
  setEditorState,
  editorState,
  predefinedMessages,
  missionType
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { selectedRecipientsValues, setSelectedRecipientsValues } =
    useGlobalContext();

  const filterOption = (input, option) => {
    const selectChildren = String(option?.searchValue || option?.value);
    return selectChildren.toLowerCase().includes(input.toLowerCase());
  };

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image'],
      ['clean']
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };

  const fields = [
    ...(missionType
      ? []
      : [
          {
            noLabel: true,
            name: ['mission'],
            key: 'mission',
            input: (
              <Select
                loading={isFieldsLoading}
                placeholder={t('messages.form.mission')}
              >
                {(enums?.types || []).map((r) => (
                  <Option key={r} value={r}>
                    <Tag color={missionTag[r]}>{t(`missions.tags.${r}`)}</Tag>
                  </Option>
                ))}
              </Select>
            )
          }
        ]),

    {
      noLabel: true,
      key: 'recipients',
      rules: [
        {
          required: true,
          message: t('messages.form.error_handlers.required_recipients')
        }
      ],
      input: (
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="recipients"
              label={t('messages.form.recipients')}
              rules={[
                {
                  required: true,
                  message: t('messages.form.error_handlers.required_recipients')
                }
              ]}
            >
              <Checkbox.Group
                options={Message.recipientOptions({
                  recipients,
                  t,
                  intern_note: true,
                  user
                })}
                loading={isFieldsLoading}
                filterOption={filterOption}
                placeholder={t('messages.form.recipients')}
                onChange={(value) =>
                  Message.handleSelect({
                    value,
                    selectedValues: selectedRecipientsValues,
                    setSelectedValues: setSelectedRecipientsValues,
                    form
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="copy" label={t('messages.form.copy')}>
              <Checkbox.Group
                options={Message.recipientOptions({
                  recipients,
                  t,
                  intern_note: false,
                  user
                })}
                loading={isFieldsLoading}
                filterOption={filterOption}
                placeholder={t('messages.form.copy')}
                onChange={(value) =>
                  Message.handleSelect({
                    value,
                    selectedValues: selectedRecipientsValues,
                    setSelectedValues: setSelectedRecipientsValues,
                    form
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )
    },
    {
      noLabel: true,
      name: ['predefined-messages'],
      key: 'predefined-messages',
      input: (
        <Select
          allowClear
          showSearch
          loading={isFieldsLoading}
          filterOption={filterOption}
          placeholder={t('messages.form.predefined-messages')}
          onChange={(value) => setEditorState(value)}
        >
          {(predefinedMessages || []).map((r) => (
            <Option key={r._id} value={r.content} searchValue={r.label}>
              {r.label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      noLabel: true,
      name: ['subject'],
      key: 'subject',
      input: <Input placeholder={t('messages.form.subject')} />
    },
    {
      name: ['require_response'],
      key: 'require_response',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      name: ['document'],
      noLabel: true,
      input: 'File'
    },
    {
      noLabel: true,
      input: (
        <ReactQuill
          theme="snow"
          modules={modules}
          value={editorState}
          onChange={(value) => setEditorState(value)}
        />
      )
    }
  ];

  return { fields };
};
