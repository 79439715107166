import { useEffect } from 'react';
import { Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/definitiveProjectFields';
import { useConfig } from '../utils/definitiveProjectCreateUpdateConfig';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * DefinitiveProjectDrawer component.
 *
 * @component
 * @returns {JSX.Element} The DefinitiveProjectDrawer component
 */
export const DefinitiveProjectDrawer = () => {
  const {
    mission,
    forceRefresh,
    setForceRefresh,
    isDrawerOpen,
    setIsDrawerOpen,
    isLoading
  } = useMissionContext();
  const [form] = Form.useForm();
  const { fields } = useFields({ form });
  const { t } = useTranslation();

  const config = useConfig();

  useEffect(() => {
    form.setFieldsValue({
      realized_modifications: {
        compliance: mission?.phase_two_type === 'COMPLIANCE',
        adaptation: mission?.phase_two_type === 'ADAPTATION'
      }
    });
  }, []);

  return (
    <Drawer
      title={t('missions.drawers.titles.definitive_project')}
      onClose={() =>
        setIsDrawerOpen((prev) => ({ ...prev, definitive_project: false }))
      }
      open={isDrawerOpen.definitive_project}
    >
      <CreateUpdateContainer
        customFormInstance={form}
        fields={fields}
        config={config}
        isFieldsLoading={isLoading}
        purpose="edit"
        baseUrl="missions"
        resource="missions"
        withFilesManager={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{ ...isDrawerOpen, definitive_project: false }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
