import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { GetEnums } from '../../../utils/getEnums';
import { addColumns } from '../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { IconsArray } from '../../../components/editableTableComponents/IconsArray';
import { EditableTable } from '../../../components/editableTableComponents/EditableTable';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';

/**
 * Component for managing document repository.
 *
 * @component
 * @example
 * return (
 *   <DocumentRepository />
 * )
 */

export const DocumentRepository = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectOptions, setSelectOptions] = useState({
    roles: [],
    missions: [],
    icons: IconsArray
  });
  const [selectType, setSelectType] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const { getDocumentRepositoriesEnums } = GetEnums();
  const { getResourceData } = EditableTableMethods();
  const { searchValue, setPagination, pagination, currentSorter } =
    useConfigurationContext();
  const columns = useColumns({
    setEditingKey,
    form,
    setSelectType,
    setForceRefresh,
    forceRefresh
  });
  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  useEffect(() => {
    (async () => {
      const [documentRepositoriesDatas, documentRepositoriesEnums] =
        await Promise.all([
          getResourceData({
            resource: 'document-repositories',
            searchValue,
            setPagination,
            pagination,
            currentSorter
          }),
          getDocumentRepositoriesEnums()
        ]);

      setDataSource(
        documentRepositoriesDatas.map((el, index) => ({ ...el, key: index }))
      );
      setSelectOptions((prev) => ({ ...prev, ...documentRepositoriesEnums }));
    })();
  }, [forceRefresh, searchValue, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: mergedColumns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      selectOptions,
      selectType,
      resource: 'document-repositories'
    });
  }, [editingKey, dataSource]);

  return (
    <>
      <PageHeaderCustom title={t('document-repositories.title')} />
      <ContentCustom>
        <EditableTable
          resource="document-repositories"
          dataSource={dataSource}
          mergedColumns={mergedColumns}
          setSelectedRowKey={setSelectedRowKey}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          form={form}
          searchValue={searchValue}
          pagination={pagination}
          setPagination={setPagination}
          scroll={{ x: 'max-content' }}
        />
      </ContentCustom>
    </>
  );
};
