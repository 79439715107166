import { useQuotationContext } from '../../../contexts/QuotationContext';
import { QuotationAction } from '../../../models/QuotationAction';

/**
 * A collection of action methods for handling dynamic fields in quotations.
 * @returns {Object} An object containing the action methods.
 */
export const ActionMethods = () => {
  const { form, quotation } = useQuotationContext();

  /**
   * Sets the value of the 'actions' field in the form with the provided record ID.
   * The value is set with the 'impacted_lot_number' property, which is derived from the 'created_modified_lot_number' property of the quotation's mission.
   *
   * @function
   * @param {Object} params - The parameters for charging the grid.
   * @param {string} params.recordId - The ID of the record.
   * @returns {void}
   */
  const chargingGrid = ({ recordId }) => {
    const createdModifiedLotNumber =
      quotation?.mission?.created_modified_lot_number || 0;

    form.setFieldsValue({
      actions: {
        [recordId]: {
          data: {
            impacted_lot_number: createdModifiedLotNumber
          }
        }
      }
    });
  };

  /**
   * Handles the "ENJOYMENT_RIGHT_TO_VALIDATED" action.
   * @function
   * @param {Object} params - The parameters for the action.
   * @param {string} params.recordId - The record ID.
   * @returns {void}
   */
  const enjoymentRightToValidated = ({ recordId }) => {
    const pcajp = quotation?.mission?.pcajp_arrays?.slice(-1)[0] || {};
    const modifiedLotRef = {};
    let modifiedLotNumber = 0;

    pcajp.data?.forEach((el) => {
      el.private_usages?.forEach((usage) => {
        if (usage.to_validate.value === 'TO_VALIDATED_IN_AG') {
          const lots = (el.batch_numbers?.value || '').split(',');

          lots.forEach((lot, lotIndex) => {
            modifiedLotRef[lotIndex] = lot;
          });

          modifiedLotNumber += 1;
        }
      });
    });

    const updatedData = {
      modified_lot_number: modifiedLotNumber,
      modified_lot_ref: modifiedLotRef
    };

    QuotationAction.handleInputNumberChange({
      value: modifiedLotNumber,
      path: ['actions', recordId, 'data', 'modified_lot_ref'],
      form
    });

    form.setFieldsValue({
      actions: {
        [recordId]: {
          data: updatedData
        }
      }
    });
  };

  /**
   * Calculates the merge lot number and merge lot reference for the given quotation record.
   *
   * @function
   * @param {Object} params - The parameters for the function.
   * @param {string} params.recordId - The ID of the quotation record.
   * @returns {void}
   */
  const lotEnjoymentRight = ({ recordId }) => {
    const pcajp = quotation?.mission?.pcajp_arrays?.slice(-1)[0] || {};
    const mergeLotNumber = {};
    let createdLotNumber = 0;

    pcajp.data?.forEach((el) => {
      el.private_usages?.forEach((usage) => {
        if (usage.to_validate.value === 'LOT_ENJOYMENT_RIGHT') {
          if (mergeLotNumber[usage?.principal_lot_enjoyment_right?.value]) {
            mergeLotNumber[
              usage?.principal_lot_enjoyment_right?.value
            ].sub_merge_lot_merged += 1;
            mergeLotNumber[
              usage?.principal_lot_enjoyment_right?.value
            ].sub_merge_lot_removed.push(el?.batch_numbers?.value);
          } else {
            mergeLotNumber[usage?.principal_lot_enjoyment_right?.value] = {
              sub_merge_lot_merged: 2,
              sub_merge_lot_removed: [
                el?.batch_numbers?.value || null,
                usage?.principal_lot_enjoyment_right?.value || null
              ]
            };
            createdLotNumber += 1;
          }
        }
      });
    });

    const subMergeLotNumber = Object.keys(mergeLotNumber).reduce(
      (acc, key, index) => {
        acc[index] = {
          sub_merge_lot_merged: mergeLotNumber[key].sub_merge_lot_merged,
          sub_merge_lot_removed: mergeLotNumber[
            key
          ].sub_merge_lot_removed.reduce(
            (removedAcc, curr, removedIndex) => ({
              ...removedAcc,
              [removedIndex]: curr
            }),
            {}
          )
        };
        return acc;
      },
      {}
    );

    const updatedData = {
      merge_lot_number: createdLotNumber,
      merge_lot_ref: subMergeLotNumber
    };

    QuotationAction.handleInputNumberChange({
      value: createdLotNumber,
      path: ['actions', recordId, 'data', 'merge_lot_ref'],
      form
    });

    for (let i = 0; i < createdLotNumber; i += 1) {
      QuotationAction.handleInputNumberChange({
        value: subMergeLotNumber[i].sub_merge_lot_merged,
        path: [
          'actions',
          recordId,
          'data',
          'merge_lot_ref',
          i,
          'sub_merge_lot_removed'
        ],
        form
      });
    }

    form.setFieldsValue({
      actions: {
        [recordId]: {
          data: updatedData
        }
      }
    });
  };

  /**
   * Sets the dynamic fields for the given action.
   * @function
   * @param {Object} params - The parameters for setting the dynamic fields.
   * @param {string} params.action - The action to set the dynamic fields for.
   * @param {string} params.recordId - The record ID.
   * @returns {void}
   */
  const setActionDynamicFields = ({ action, recordId }) => {
    switch (action) {
      case 'CHARGING_GRID':
        return chargingGrid({ recordId });
      case 'ENJOYMENT_RIGHT_TO_VALIDATED':
        return enjoymentRightToValidated({ recordId });
      case 'LOT_ENJOYMENT_RIGHT':
        return lotEnjoymentRight({ recordId });
      default:
        return null;
    }
  };

  return {
    setActionDynamicFields
  };
};
