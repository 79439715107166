import { Select, Input, InputNumber, Form } from 'antd';

const { Option } = Select;

/**
 * Custom hook that returns the fields configuration for the notary form.
 *
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {boolean} options.isFieldsLoading - Flag indicating whether the fields are loading.
 * @param {Array} options.archivistNotaries - The list of archivist notaries.
 * @returns {Object} - The fields configuration.
 */
export const useFields = ({ isFieldsLoading, archivistNotaries }) => {
  const filterOption = (input, option) => {
    const selectChildren = option.children;
    return selectChildren.toLowerCase().includes(input.toLowerCase());
  };

  const editorNotaryFields = [
    {
      name: ['last_name'],
      key: 'last_name',
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      key: 'first_name',
      rules: [{ required: true }]
    },
    {
      name: ['city'],
      key: 'city',
      rules: [{ required: true }]
    },
    {
      name: ['archivist_notary'],
      key: 'archivist_notary',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(archivistNotaries || []).map((notary) => (
            <Option value={notary._id} key={notary._id}>
              {notary.office_name}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const archivistNotaryFields = [
    {
      name: ['office_name'],
      key: 'office_name',
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      key: 'email',
      rules: [{ required: true }]
    },
    {
      name: ['phone_number'],
      key: 'phone_number',
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <InputNumber style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['address', 'street'],
      key: 'street',
      rules: [{ required: true }]
    },
    {
      name: ['address', 'additional'],
      key: 'additional'
    },
    {
      name: ['address', 'postal_code'],
      key: 'postal_code',
      rules: [{ required: true }]
    },
    {
      name: ['address', 'city'],
      key: 'city',
      rules: [{ required: true }]
    }
  ];

  return {
    'editor-notaries': editorNotaryFields,
    'archivist-notaries': archivistNotaryFields
  };
};
