import { BaseModel } from './BaseModel';

/**
 * Represents a pcajp array.
 * @class
 */
export class PcajpArray extends BaseModel {
  static resourceName = 'pcajp-arrays';

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extraQuery string.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${PcajpArray.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Patch a resource by ID with new values.
   * @function
   * @async
   * @param {Object} options - The options for patching the resource.
   * @param {string} options.id - The ID of the resource to patch.
   * @param {Object} options.values - The new values to patch the resource with.
   * @param {function} [options.setIsSubmitting] - The function to set the submitting state.
   * @param {string} [options.populate=''] - The fields to populate in the patched resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the patch request.
   * @returns {Promise<Object>} A promise that resolves to the patched resource data.
   */
  static async patchResource({
    id,
    values,
    setIsSubmitting,
    message,
    messageContent,
    populate = '',
    extraQuery = '',
    setForceRefresh
  }) {
    const handleSubmitting = () => {
      if (values.is_validated) return 'VALIDATED';
      if (values.is_draft) return 'DRAFT';
      return 'PUBLISHED';
    };

    if (setIsSubmitting) setIsSubmitting(handleSubmitting());
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('PATCH', {
      url: `${PcajpArray.resourceName}/${id}?populate=${populate}&${extraQuery}`,
      body: formData
    });
    if (setIsSubmitting) setIsSubmitting(false);
    if (message) message.success(messageContent);
    if (setForceRefresh) setForceRefresh((prev) => !prev);
    return data;
  }

  /**
   * Adds private usage for a specific item.
   *
   * @function
   * @async
   * @param {Object} options - The options for adding private usage.
   * @param {string} options.id - The ID of the item.
   * @param {function} options.setForceRefresh - The function to set the force refresh flag.
   * @returns {Promise<Object>} A promise that resolves to the response data.
   */
  static async addPrivateUsage({ id, setForceRefresh, setIsSubmitting }) {
    if (setIsSubmitting) setIsSubmitting('UPDATE_DATA');

    const { data } = await this.dispatchAPI('PATCH', {
      url: `${PcajpArray.resourceName}/add-private-usages/${id}`
    });

    if (setForceRefresh) setForceRefresh((prev) => !prev);

    return data;
  }

  /**
   * Adds a lot to the PcajpArray.
   * @function
   * @async
   * @param {Object} options - The options for adding a lot.
   * @param {string} options.id - The ID of the lot to add.
   * @param {function} options.setForceRefresh - The function to set the force refresh flag.
   * @returns {Promise<Object>} A promise that resolves to the added lot data.
   */
  static async addLot({ id, setForceRefresh, setIsSubmitting }) {
    if (setIsSubmitting) setIsSubmitting('UPDATE_DATA');

    const { data } = await this.dispatchAPI('PATCH', {
      url: `${PcajpArray.resourceName}/add-lots/${id}`
    });

    if (setForceRefresh) setForceRefresh((prev) => !prev);
    return data;
  }
}
