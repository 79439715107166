import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../components';
import { MessageContextProvider } from '../../../contexts/MessageContext';
import { ListMessages } from './ListMessages';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Text } = Typography;

/**
 * Home component
 * @returns {JSX.Element} Home component
 * @constructor Home
 * @component Home
 * @example <Home />
 */
export const Home = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  return (
    <MessageContextProvider id={user._id} model="User">
      <Card
        style={{
          border: 'none'
        }}
      >
        <PageHeaderCustom title={t('lawyers.home.title')} />
        <Text
          style={{
            marginLeft: '10px'
          }}
        >
          {t('lawyers.home.description')}
        </Text>
        <ListMessages />
      </Card>
    </MessageContextProvider>
  );
};
