import { Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { missionTag } from '../../../../../utils/constants/tagColors';

/**
 * useFields Hook
 *
 * This hook generates a set of form fields for the creation and update of collective ownerships.
 * @hook
 * @param {Object} params - The hook parameters.
 * @param {boolean} params.isFieldsLoading - Indicates if the fields are loading.
 * @param {function} params.setSelectedSyndic - Function to set the selected syndic.
 * @param {Object} params.form - The form instance used for managing form state.
 * @param {Array<Object>} params.syndicOptions - The available syndic options.
 * @param {Array<Object>} params.managers - The available managers for the selected syndic.
 * @param {Object} params.enums - The available enums for the form fields.
 *
 * @returns {Object} The form fields configuration.
 */
export const useFields = ({
  isFieldsLoading,
  setSelectedSyndic,
  form,
  syndicOptions,
  managers,
  enums
}) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['syndic'],
      key: 'syndic',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(e, option) => {
            setSelectedSyndic(option?.syndic);
            form.setFieldsValue({
              manager: undefined
            });
          }}
        >
          {syndicOptions.map((item) => (
            <Option value={item._id} key={item._id} syndic={item}>
              {item.society_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['manager'],
      key: 'manager',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {managers?.map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.last_name} ${item.first_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['collective_ownerships_import'],
      key: 'collective_ownerships_import',
      rules: [{ required: true }],
      maxFilesCount: 1,
      input: 'File',
      requiredFileType: ['xlsx']
    },
    {
      name: ['launch-mission'],
      key: 'launch-mission',
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          loading={isFieldsLoading}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {(enums.types || []).map((missionType) => (
            <Option key={missionType} value={missionType}>
              <Tag color={missionTag[missionType]}>
                {t(`missions.tags.${missionType}`, {
                  defaultValue: missionType
                })}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return { fields };
};
