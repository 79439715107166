import { useTranslation } from 'react-i18next';
import { ListRequestedDocuments } from './ListRequestedDocuments';

/**
 * Custom hook that returns an array of items for requested documents configuration.
 * Each item contains a label, key, and children component.
 * @hook
 * @returns {Object} The items array.
 */
export const useItems = () => {
  const { t } = useTranslation();
  const items = [
    {
      label: t('requested-documents.tabs.general'),
      key: '1',
      children: <ListRequestedDocuments type="GENERAL" />
    },
    {
      label: t('requested-documents.tabs.modifying'),
      key: '2',
      children: <ListRequestedDocuments type="MODIFYING" />
    },
    {
      label: t('requested-documents.tabs.pcs_publication'),
      key: '3',
      children: <ListRequestedDocuments type="PCS_PUBLICATION" />
    }
  ];

  return { items };
};
