import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LeftOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Layout } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useGlobalContext } from '../../../contexts/GlobalContext';

/**
 * Renders a custom content component with a divider.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the component.
 * @param {Object} props.cardDesign - The design styles for the card component.
 * @param {boolean} props.buttonOnRight - Determines if the button should be on the right side.
 * @param {Object} props.containerStyle - The styles for the container element.
 * @param {boolean} props.withDivider - Determines if the component should have a divider.
 * @param {string} props.dashboardResource - The resource for the dashboard.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The rendered component.
 */
const { Content } = Layout;
export const ServicesCustomContentWithDivider = ({
  title,
  cardDesign,
  buttonOnRight,
  containerStyle,
  withDivider,
  dashboardResource,
  noHeaderButtons,
  children
}) => {
  const { dispatchAPI } = useAuthContext();
  const location = useLocation();
  const baseUrl = location.pathname.split('/').slice(0, -1).join('/');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentSyndic } = useGlobalContext();
  const [dashBoardInfo, setDashBoardInfo] = useState({});

  const getDashBoardInfo = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `syndic-collective-ownerships/dashBoardCards?syndic=${currentSyndic}&type=${dashboardResource}`
    });
    setDashBoardInfo(data);
  };
  useEffect(() => {
    if (dashboardResource) {
      getDashBoardInfo();
    }
  }, []);

  const iconStyle = { fontSize: '24px' };
  return (
    <Content
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        ...containerStyle
      }}
    >
      <Card
        style={{
          backgroundColor: '#222222',
          borderRadius: '15px',
          color: '#F4F4F4',
          width: '97.5%',
          padding: '0rem 1rem',
          ...cardDesign
        }}
      >
        <Row gutter={16} align="middle" justify="center">
          {!noHeaderButtons && (
            <Col span={1}>
              <LeftOutlined onClick={() => navigate(-1)} style={iconStyle} />
            </Col>
          )}

          <Col span={noHeaderButtons ? 24 : 18}>
            <Row>{title}</Row>
          </Col>
          {!noHeaderButtons && <Col span={4}>{buttonOnRight}</Col>}
        </Row>
        {withDivider && (
          <>
            <Divider style={{ backgroundColor: '#F4F4F4' }} />
            <Row justify="center" gutter={16}>
              {Object.entries(dashBoardInfo).map(([ttl, ttlObject]) => (
                <Link to={`${baseUrl}${ttlObject.route}`}>
                  <Card
                    key={ttl}
                    style={{
                      borderRadius: '15px',
                      backgroundColor: '#343A40',
                      marginBottom: '8px',
                      margin: '0rem 0.5rem',
                      border: 'none'
                    }}
                  >
                    <Row align="middle">
                      <Col xs={9} style={{ textAlign: 'center' }}>
                        <p
                          style={{
                            color: '#FFD53F',
                            fontSize: '40px',
                            margin: 0
                          }}
                        >
                          {ttlObject.total}
                        </p>
                      </Col>
                      <Col xs={15}>
                        <p
                          style={{
                            color: '#f4f4f4',
                            margin: 0,
                            paddingLeft: '10px'
                          }}
                        >
                          {t(`services-actions-to-do.actions.${ttl}`)}
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </Link>
              ))}
            </Row>
          </>
        )}
      </Card>
      <Content
        style={{
          width: '97.5%',
          backgroundColor: '#fff',
          padding: '2rem',
          borderRadius: '15px'
        }}
      >
        {children}
      </Content>
    </Content>
  );
};

ServicesCustomContentWithDivider.propTypes = {
  title: PropTypes.string,
  cardDesign: PropTypes.shape({}),
  buttonOnRight: PropTypes.element,
  containerStyle: PropTypes.shape({}),
  withDivider: PropTypes.bool,
  noHeaderButtons: PropTypes.bool,
  dashboardResource: PropTypes.string
};

ServicesCustomContentWithDivider.defaultProps = {
  title: '',
  cardDesign: {},
  buttonOnRight: null,
  containerStyle: {},
  withDivider: false,
  noHeaderButtons: false,
  dashboardResource: false
};
