import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CollectiveOwnership } from '../models/CollectiveOwnership';

export const CollectiveOwnershipContext = createContext();

/**
 * Provides the context for managing collective ownership data.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The context provider component.
 */
export const CollectiveOwnershipContextProvider = ({ children }) => {
  const { pathname } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });
  const [current, setCurrent] = useState({ principal: 0, sub: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [configDocuments, setConfigDocuments] = useState({
    document_repositories: []
  });
  const [documents, setDocuments] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [itemType, setItemType] = useState({
    type: 'missionDisplay',
    value: 'PCS_1'
  });
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  /**
   * Retrieves the document repository data for a collective ownership.
   * @function
   * @async
   * @param {Object} params - The parameters for retrieving the data.
   * @param {string} params.id - The ID of the collective ownership.
   * @returns {Promise<void>} A promise that resolves when the data is retrieved.
   */
  const getRepositoryDocumentDatas = async ({ id }) => {
    const [documentRepositoriesDatas] = await Promise.all([
      CollectiveOwnership.getDocumentRepositoriesForCollectiveOwnership({
        id,
        extraQuery: `item_type=${itemType?.type}&value=${itemType?.value}`
      })
    ]);

    setConfigDocuments({
      document_repositories: documentRepositoriesDatas
    });

    setIsLoading(false);
  };

  return (
    <CollectiveOwnershipContext.Provider
      value={{
        searchValue,
        setPagination,
        pagination,
        getRepositoryDocumentDatas,
        current,
        setCurrent,
        isLoading,
        setIsLoading,
        configDocuments,
        setConfigDocuments,
        documents,
        setDocuments,
        forceRefresh,
        setForceRefresh,
        setItemType,
        itemType,
        selectedDocuments,
        setSelectedDocuments
      }}
    >
      {children}
    </CollectiveOwnershipContext.Provider>
  );
};

export const useCollectiveOwnershipContext = () => {
  const context = useContext(CollectiveOwnershipContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};

CollectiveOwnershipContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
