import { Row, Col, Typography, Button, Alert } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { ExportExcel } from '../../../components/ExportExcelButton/ExportExcelButton';
import { generateFile } from '../utils/generateFile';
import { useChargingGridPermissions } from '../utils/usePermissions';

const { Text } = Typography;

/**
 * Renders the export buttons for the charging grid.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.chargingGrid - The charging grid object.
 * @param {Array} props.columns - The columns for the grid.
 * @param {boolean} props.isFinalVersion - Indicates if it's the final version of the grid.
 * @param {Function} props.dispatchAPI - The dispatch function for API calls.
 * @param {string} props.message - The message for API calls.
 * @param {Array} props.resourceDatas - The resource data for the grid.
 * @param {Function} props.setChargingGrid - The function to set the charging grid.
 * @param {Function} props.setIsVersionButton - The function to set the version button.
 * @param {Array} props.dataSource - The data source for the grid.
 * @param {Object} props.template - The template object.
 * @param {Function} props.t - The translation function.
 * @param {Function} props.setIsLabelModalOpen - The function to set the label modal open state.
 * @param {Function} props.setIsEditLabel - The function to set the edit label state.
 * @param {boolean} props.isLabelAndDate - Indicates if the label and date are shown.
 * @returns {JSX.Element} The export buttons component.
 */
export const ExportButtons = ({
  chargingGrid,
  columns,
  isFinalVersion,
  dispatchAPI,
  message,
  resourceDatas,
  setChargingGrid,
  setIsVersionButton,
  dataSource,
  template,
  t,
  setIsLabelModalOpen,
  setIsEditLabel,
  isLabelAndDate
}) => {
  const { downloadDocument } = useDownloadDocument();
  const { exportButtonsPermissions } = useChargingGridPermissions();
  const { isDisabled, noExportButton } = exportButtonsPermissions;

  return (
    <Col xs={24} xxl={24}>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="middle"
        className="grid-header"
      >
        <Col>
          <Row gutter={[16, 16]} align="middle">
            {isLabelAndDate ? (
              <>
                <Col className="grid-title">
                  <Text>{chargingGrid.label}</Text>
                </Col>
                <Col className="grid-date">
                  <Row align="middle">
                    <Col>
                      <Text>
                        {dayjs(chargingGrid.document_date).format('DD/MM/YYYY')}
                      </Text>
                    </Col>
                    <Col>
                      {!isDisabled && (
                        <Button
                          type="link"
                          onClick={() => {
                            setIsLabelModalOpen(true);
                            setIsEditLabel(true);
                          }}
                        >
                          <EditOutlined />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <>
                <Col>
                  {!isDisabled && (
                    <Button onClick={() => setIsLabelModalOpen(true)}>
                      {t('charging-grids.show.buttons.add_label_and_date')}
                    </Button>
                  )}
                </Col>
                <Col>
                  <Alert
                    message={t(
                      'charging-grids.show.messages.label_and_date_required'
                    )}
                    type="warning"
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col>
          {columns.length && dataSource.length && !noExportButton ? (
            <Row gutter={[6, 6]}>
              <Col>
                <ExportExcel
                  columns={columns}
                  exportDatas={dataSource}
                  chargingGrid={chargingGrid}
                />
              </Col>
              <Col>
                <Button
                  disabled
                  onClick={() =>
                    generateFile({
                      isFinalVersion,
                      chargingGrid,
                      dispatchAPI,
                      message,
                      resourceDatas,
                      setChargingGrid,
                      setIsVersionButton,
                      template,
                      downloadDocument
                    })
                  }
                >
                  {t('charging-grids.show.buttons.export_pdf')}
                </Button>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

ExportButtons.propTypes = {
  chargingGrid: PropTypes.shape({
    label: PropTypes.string,
    document_date: PropTypes.string
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  isFinalVersion: PropTypes.bool,
  dispatchAPI: PropTypes.func,
  message: PropTypes.func,
  resourceDatas: PropTypes.shape({}),
  setChargingGrid: PropTypes.func,
  setIsVersionButton: PropTypes.func,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  template: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  setIsLabelModalOpen: PropTypes.func,
  setIsEditLabel: PropTypes.func,
  isLabelAndDate: PropTypes.bool
};

ExportButtons.defaultProps = {
  chargingGrid: {
    label: '',
    document_date: ''
  },
  columns: [],
  isFinalVersion: false,
  dispatchAPI: () => {},
  message: () => {},
  resourceDatas: {},
  setChargingGrid: () => {},
  setIsVersionButton: () => {},
  dataSource: [],
  template: '',
  t: () => {},
  setIsLabelModalOpen: () => {},
  setIsEditLabel: () => {},
  isLabelAndDate: false
};
