import { Row, Col, Form, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuotationContext } from '../../../contexts/QuotationContext';

/**
 * Custom hook that provides fields for quotation validation.
 * @hook
 * @returns {Object} An object containing the fields.
 */
export const useFields = () => {
  const { t } = useTranslation();
  const { quotationActAmounts, quotation } = useQuotationContext();

  const fields = [
    {
      translate: false,
      label: t('quotations.form.validation', {
        name: quotation?.syndic?.society_name,
        code: quotation?.syndic?.code
      }),
      key: 'validation',
      input: (
        <Row gutter={[16, 16]}>
          <Col>
            {quotationActAmounts.map((actAmount) => (
              <Form.Item
                key={actAmount._id}
                name={['validation', actAmount._id]}
                valuePropName="checked"
              >
                <Checkbox>
                  {t('quotation-act-amounts.form.validation', {
                    act_number: actAmount.number,
                    title: actAmount.title,
                    sub_title: actAmount.sub_title
                  })}
                </Checkbox>
              </Form.Item>
            ))}
          </Col>
        </Row>
      )
    }
  ];

  return { fields };
};
