/**
 * Sets the mission steps based on the mission type.
 *
 * @param {string} missionType - The type of the mission.
 * @returns {object} - An object containing the principal and sub steps of the mission.
 */
export const setMissionSteps = (missionType) => {
  // TODO : Add other mission types
  switch (missionType) {
    case 'PCS_1':
      return { principal: 'PCS_1', sub_steps: 'ADVANCE_PAYMENT' };
    case 'PLANS_RESEARCH':
      return {
        principal: 'FILLED_RCP_INFORMATIONS'
      };
    case 'AUDIT_1':
      return {
        principal: 'BUILDING_FORM_ORDER'
      };
    case 'AUDIT_1_2':
      return {
        principal: 'INVOICE_DEPOSIT'
      };
    case 'RCP_RESEARCH':
      return {
        principal: 'BUILDING_FORM_ORDER'
      };
    default:
  }
  return {};
};
