import dayjs from 'dayjs';

/**
 * Returns a configuration object for the useConfig hook.
 * @hook
 * @returns {Object} The configuration object.
 */
export const useConfig = ({ setFormData }) => ({
  onGetResource: {
    setFields: (data) => {
      setFormData(data);
      return {
        ...data,
        birth_date: dayjs(data.birth_date),
        professional_card_acquisition_date: dayjs(
          data.professional_card_acquisition_date
        )
      };
    }
  }
});
