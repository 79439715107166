export const adminTheme = {
  primaryColor: '#222222',
  secondaryColor: '#ffd53f',
  white: '#ffffff'
};

export const iconColors = {
  folderOpen: '#6C757D'
};

export const buttonColors = {
  dangerColor: '#CB2529',
  primaryColor: '#222222',
  headerSecondary: '#ffd53f'
};

export const chartsColors = {
  MISSIONS: {
    IN_PROGRESS: '#FFD53F',
    FINISHED: '#10B9A8',
    CANCELLED: '#ADB5BD'
  }
};
