import { Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useRemarksListContent } from './listContent/remarks';

/**
 * Renders the Remarks component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.pcsPreProduction - The PCS pre-production data.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @returns {JSX.Element} The rendered Remarks component.
 */
export const Remarks = ({ pcsPreProduction, isLoading }) => {
  const { t } = useTranslation();
  const remarksListContent = useRemarksListContent(pcsPreProduction);

  return (
    <Card title={t('pcs-pre-productions.show.cards.titles.remarks')}>
      <Skeleton loading={isLoading} active>
        <DescriptionList data={remarksListContent} translate vertical />
      </Skeleton>
    </Card>
  );
};

Remarks.propTypes = {
  pcsPreProduction: PropTypes.shape({}),
  isLoading: PropTypes.bool
};

Remarks.defaultProps = {
  pcsPreProduction: {},
  isLoading: false
};
