/**
 * Array of invoice headers.
 * @type {Array<Object>}
 */
export const invoiceSendListHeaders = [
  {
    label: 'sending_date',
    key: 'sending_date'
  },
  {
    label: 'syndic_name',
    key: 'syndic_name'
  },
  {
    label: 'collective_ownership_address',
    key: 'collective_ownership_address'
  },
  {
    label: 'invoice_type',
    key: 'invoice_type'
  },
  {
    label: 'reference',
    key: 'reference'
  },
  {
    label: 'mission_type',
    key: 'mission_type'
  },
  {
    label: 'payment',
    key: 'payment'
  },
  {
    label: 'total_ht',
    key: 'total_ht'
  },
  {
    label: 'tva',
    key: 'tva'
  },
  {
    label: 'total_ttc',
    key: 'total_ttc'
  },
  {
    label: 'disbursement',
    key: 'disbursement'
  },
  {
    label: 'total',
    key: 'total'
  }
];

/**
 * Array of invoice paid headers.
 * @type {Array<Object>}
 */
export const invoicePaidListHeaders = [
  {
    label: 'payment_date',
    key: 'payment_date'
  },
  {
    label: 'syndic_name',
    key: 'syndic_name'
  },
  {
    label: 'collective_ownership_address',
    key: 'collective_ownership_address'
  },
  {
    label: 'invoice_type',
    key: 'invoice_type'
  },
  {
    label: 'reference',
    key: 'reference'
  },
  {
    label: 'mission_type',
    key: 'mission_type'
  },
  {
    label: 'payment',
    key: 'payment'
  },
  {
    label: 'total_ht',
    key: 'total_ht'
  },
  {
    label: 'tva',
    key: 'tva'
  },
  {
    label: 'total_ttc',
    key: 'total_ttc'
  },
  {
    label: 'disbursement',
    key: 'disbursement'
  },
  {
    label: 'total',
    key: 'total'
  }
];

/**
 * Array of invoice send accountant export headers.
 * @type {Array<{label: string, key: string}>}
 */
export const invoiceSendAccountantExportHeaders = [
  {
    label: 'sending_date',
    key: 'sending_date'
  },
  {
    label: 'bank_journal',
    key: 'bank_journal'
  },
  {
    label: 'bank_account',
    key: 'bank_account'
  },
  {
    label: 'wording',
    key: 'wording'
  },
  {
    label: 'piece',
    key: 'piece'
  },
  {
    label: 'debit',
    key: 'debit'
  },
  {
    label: 'credit',
    key: 'credit'
  },
  {
    label: 'pointing',
    key: 'pointing'
  }
];

/**
 * Array of invoice paid accountant export headers.
 * @type {Array<Object>}
 */
export const invoicePaidAccountantExportHeaders = [
  {
    label: 'payment_date',
    key: 'payment_date'
  },
  {
    label: 'bank_journal',
    key: 'bank_journal'
  },
  {
    label: 'bank_account',
    key: 'bank_account'
  },
  {
    label: 'wording',
    key: 'wording'
  },
  {
    label: 'piece',
    key: 'piece'
  },
  {
    label: 'debit',
    key: 'debit'
  },
  {
    label: 'credit',
    key: 'credit'
  },
  {
    label: 'pointing',
    key: 'pointing'
  }
];
