export const onFinishForm = async ({
  form,
  setFormData,
  setCurrent,
  current,
  Message,
  t
}) => {
  try {
    const values = await form.validateFields();
    setFormData((prev) => ({ ...prev, ...values }));
    setCurrent(current + 1);
    return null;
  } catch (errorInfo) {
    return Message.error(t('syndics.messages.required_fields'));
  }
};
