import React from 'react';
import { Typography, Card, Row } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const { Text } = Typography;

/**
 * DashboardActionButton component displays a styled button with an icon and text.
 * It wraps the button content inside a Link component for navigation.
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.icon - The icon to display inside the button.
 * @param {string} props.link - The link to navigate to when the button is clicked.
 * @param {string} props.text - The key used to get the translated text for the button.
 * @param {function} props.t - The translation function used to retrieve the translated text.
 *
 * @returns {JSX.Element} The rendered DashboardActionButton component.
 */
const DashboardActionButton = ({ icon, link, text, t }) => (
  <Card>
    <Link to={link}>
      <Row
        style={{
          background: '#343A40',
          width: '100%',
          display: 'flex',
          gap: '1rem',
          padding: '1rem 1.25rem',
          borderRadius: '8px'
        }}
      >
        {icon}
        <Text
          style={{
            fontWeight: 'bold',
            color: 'white',
            fontSize: '1.25rem'
          }}
        >
          {t(`dashboard.assistant.cards.${text}`)}
        </Text>
      </Row>
    </Link>
  </Card>
);

DashboardActionButton.propTypes = {
  icon: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default DashboardActionButton;
