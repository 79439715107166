export const constants = {
  INTERN: 'INTERN',
  PCS_APPOINTMENT: 'PCS_APPOINTMENT',
  UNAVAILABILITY: 'UNAVAILABILITY',
  COLORS: {
    GREEN: '#39b88e',
    BLUE: '#111E99',
    GRAY: '#6B6B6B',
    ORANGE: '#f9a825',
    PURPLE: '#9B11AB'
  },
  TRANSLATION: {
    INTERN: 'Interne',
    PCS_APPOINTMENT: 'RDV PCS',
    UNAVAILABILITY: 'Indisponible'
  }
};
