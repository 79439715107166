import { Select, Form, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGlobalContext } from '../../../contexts/GlobalContext';

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Renders a form with two Select components for filtering syndics, collective ownerships and enums.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.enums - The array of enums.
 * @param {boolean} props.isFieldsLoading - Indicates if the field is loading.
 * @returns {JSX.Element} - The rendered form.
 */
export const ListResourceExtraButtons = ({
  setFilters,
  enums,
  isFieldsLoading
}) => {
  const [form] = Form.useForm();
  const { syndics, collectiveOwnerships } = useGlobalContext();
  const { t } = useTranslation();

  /**
   * Filters the options based on the input value.
   * @function
   * @param {string} input - The input value.
   * @param {Object} option - The option object.
   * @returns {boolean} - Whether the option should be displayed or not.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} layout="inline">
      <Form.Item name={['syndics']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ collective_ownerships: null, status: null });
            setFilters(values ? `syndic=${values}` : '');
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFieldsLoading}
          placeholder={t('placeholder.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {syndic.society_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['collective_ownerships']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ syndics: null, status: null });
            setFilters(values ? `collective_ownership=${values}` : '');
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFieldsLoading}
          placeholder={t('placeholder.search_collective_ownership')}
        >
          {(collectiveOwnerships || []).map((collective_ownership) => (
            <Option
              key={collective_ownership._id}
              value={collective_ownership._id}
            >
              {collective_ownership.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['status']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ syndics: null, collective_ownerships: null });
            setFilters(values ? `status=${values}` : '');
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFieldsLoading}
          placeholder={t('placeholder.search_status')}
        >
          {(enums.status || []).map((status) => (
            <Option key={status} value={status}>
              {t(`quotations.steps.${status}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['time_slot']}>
        <RangePicker
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          onChange={(values) => {
            setFilters(
              values
                ? `&date>=${values[0].format(
                    'YYYY-MM-DD'
                  )}&date<=${values[1].format('YYYY-MM-DD')}`
                : ''
            );
          }}
        />
      </Form.Item>
    </Form>
  );
};

ListResourceExtraButtons.propTypes = {
  setFilters: PropTypes.func,
  enums: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.arrayOf(PropTypes.string)
  }),
  isFieldsLoading: PropTypes.bool
};

ListResourceExtraButtons.defaultProps = {
  setFilters: () => {},
  enums: {},
  isFieldsLoading: true
};
