import { Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/alertFields';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * AlertDrawer component.
 *
 * @component
 * @returns {JSX.Element} The AlertDrawer component
 */
export const AlertDrawer = () => {
  const {
    forceRefresh,
    setForceRefresh,
    isDrawerOpen,
    setIsDrawerOpen,
    isLoading,
    mission
  } = useMissionContext();
  const [form] = Form.useForm();
  const { fields } = useFields({ form });
  const { t } = useTranslation();

  const useConfig = {
    onCreateResource: {
      setBody: (values) => ({
        ...values,
        mission: mission._id
      })
    }
  };

  return (
    <Drawer
      title={t('missions.drawers.titles.alert')}
      onClose={() => setIsDrawerOpen((prev) => ({ ...prev, alert: false }))}
      open={isDrawerOpen.alert}
    >
      <CreateUpdateContainer
        successMessage={t('missions.messages.alert_created')}
        fields={fields}
        isFieldsLoading={isLoading}
        config={useConfig}
        purpose="create"
        baseUrl="alerts"
        resource="alerts"
        withFilesManager={false}
        withCustomPageHeader
        withEnums={false}
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{ ...isDrawerOpen, alert: false }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
