import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Row, Col, Typography } from 'antd';
import { RocketOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { routesCollectiveOwnership } from '../../../../utils/constants/routes/servicesRoutes';
import { ServicesCustomContentWithDivider } from '../ServicesCustomContentWithDivider';
import { dashboardTitle } from './dashboardTitles';

const { Title } = Typography;
const iconStyle = {
  fontSize: '15px'
};

const buttonStyle = {
  borderRadius: '15px',
  border: '2px solid #ffd53f',
  color: '#222222',
  margin: '10px 10px',
  backgroundColor: 'white',
  fontWeight: 'bold'
};

export const DashBoard = () => {
  const { title, button } = dashboardTitle();
  const { t } = useTranslation();

  return (
    <ServicesCustomContentWithDivider
      title={title('COMPLIANCE_LOI_ELAN')}
      buttonOnRight={button}
      withDivider
      dashboardResource="PCS_1,PCS_2,PCS_3"
      cardStyle={{ backgroundColor: '#222222' }}
    >
      <Card style={{ backgroundColor: 'white', borderRadius: '24px' }}>
        <Title level={3}>{t('syndic-view.dashboard.steps_title')}</Title>

        <Row className="compliance-elan-steps step-snake">
          <Col className="step c0">
            <Col className="line-start" />
            <Col className="content">
              <Col className="dot" />

              <Link
                to={`/services/compliance-loi-elan/${routesCollectiveOwnership.AUDIT}`}
              >
                <Button style={buttonStyle} className="btn-step">
                  <RocketOutlined
                    style={{ transform: 'rotate(45deg)', ...iconStyle }}
                  />
                  {t('syndic-view.dashboard.steps.step_1')}
                </Button>
              </Link>
            </Col>
            <Col className="line-end" />
          </Col>

          <Col className="step c1">
            <Col className="line-start" />
            <Col className="content">
              <Col className="dot" />

              <Button style={buttonStyle}>
                <AppstoreAddOutlined style={iconStyle} />
                {t('syndic-view.dashboard.steps.step_2')}
              </Button>
            </Col>
            <Col className="line-end" />
          </Col>
          <Col className="step c2">
            <Col className="line-start" />
            <Col className="content">
              <Col className="dot" />

              <Link
                to={`/services/compliance-loi-elan/${routesCollectiveOwnership.COMPLIANCE}`}
              >
                <Button style={buttonStyle}>
                  <RocketOutlined
                    style={{ transform: 'rotate(45deg)', ...iconStyle }}
                  />
                  {t('syndic-view.dashboard.steps.step_3')}
                </Button>
              </Link>
            </Col>
            <Col className="line-end" />
          </Col>
          <Col className="step c3">
            <Col className="line-start" />
            <Col className="content">
              <Col className="dot" />

              <Button style={buttonStyle}>
                <AppstoreAddOutlined style={iconStyle} />
                {t('syndic-view.dashboard.steps.step_4')}
              </Button>
            </Col>
            <Col className="line-end" />
          </Col>
          <Col className="step c4">
            <Col className="line-start" />
            <Col className="content">
              <Col className="dot" />

              <Link
                to={`/services/compliance-loi-elan/${routesCollectiveOwnership.PUBLICATION}`}
              >
                <Button style={buttonStyle}>
                  <RocketOutlined
                    style={{ transform: 'rotate(45deg)', ...iconStyle }}
                  />
                  {t('syndic-view.dashboard.steps.step_5')}
                </Button>
              </Link>
            </Col>
            <Col className="line-end" />
          </Col>
          <Col className="step c5">
            <Col className="line-start" />
            <Col className="content">
              <Col className="dot" />
              <span>{t('syndic-view.dashboard.steps.step_6')}</span>
            </Col>
            <Col className="line-end" />
          </Col>
        </Row>
      </Card>
    </ServicesCustomContentWithDivider>
  );
};
