import dayjs from 'dayjs';

/**
 * Returns a configuration object for resource retrieval.
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setSyndicInformation - The function to set the syndic information.
 * @param {Function} options.setBillingOptionsColors - The function to set the billing options colors.
 * @param {Function} options.setBillingOptionsAmounts - The function to set the billing options amounts.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({
  setSyndicInformation,
  setBillingOptionsColors,
  setBillingOptionsAmounts
}) => ({
  onGetResource: {
    setFields: (data) => {
      setSyndicInformation(data?.use_syndic_information_for_billing);

      const updatedData = {
        ...data,
        AG_date: data?.collective_ownership?.AG_date
          ? dayjs(data.collective_ownership.AG_date)
          : null
      };

      if (data?.type === 'PCS_2') {
        const billingOptions = {
          compliance: {
            status: data?.accepted_measures?.compliance,
            amount: data?.PCS_2.price_category.amount_ht
          },
          adaptation: {
            status: data?.accepted_measures?.adaptation,
            amount: data?.PCS_2.price_category.amount_ht
          },
          rcp_creation: {
            status: data?.lawyer_consultation?.creation_of_rcp,
            amount: data?.PCS_2.price_category.amount_ht
          },
          charge_grid_creation: {
            status: data?.lawyer_consultation?.charging_grid_not_published,
            amount: 0
          },
          pcs_creation: {
            status: data?.lawyer_consultation?.pcs_and_charges_to_create,
            amount: 0
          }
        };

        setBillingOptionsColors({
          compliance: billingOptions.compliance.status ? 'yellow' : 'gray',
          adaptation: billingOptions.adaptation.status ? 'yellow' : 'gray',
          rcp_creation: billingOptions.rcp_creation.status ? 'yellow' : 'gray',
          charge_grid_creation: billingOptions.charge_grid_creation.status
            ? 'yellow'
            : 'gray',
          pcs_creation: billingOptions.pcs_creation.status ? 'yellow' : 'gray'
        });
        setBillingOptionsAmounts({
          total_ht: Object.keys(billingOptions || {})
            .filter((key) => billingOptions[key].status)
            .map((key) => billingOptions[key].amount)
            .reduce((acc, curr) => acc + curr, 0)
        });

        updatedData.billing_options = Object.keys(billingOptions || {}).reduce(
          (acc, key) => {
            acc[key] = billingOptions[key].status;
            return acc;
          },
          {}
        );
      }
      return updatedData;
    }
  },
  onUpdateResource: {
    setBody: (body) => {
      const updatedBody = body;
      updatedBody.transformation_word_ok = true;
      return updatedBody;
    }
  }
});
