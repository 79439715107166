import React from 'react';
import { Form, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * A component that renders a form with various search filters for invoices.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.setFilters - Function to update the filters.
 * @param {Array} props.syndics - List of syndic objects for the syndic filter.
 * @param {Object} props.invoiceTypes - Invoice types data for the invoice type filter.
 * @param {Object} props.missions - Missions data for the mission type filter.
 * @param {boolean} props.isDataLoading - Flag to indicate if data is loading.
 * @param {Array} [props.consultants] - List of consultant objects for the consultant filter.
 *
 * @returns {JSX.Element} The rendered SearchBarsOptions form component.
 */
const SearchBarsOptions = ({
  setFilters,
  syndics,
  invoiceTypes,
  missions,
  isDataLoading,
  consultants = []
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Form layout="inline" form={form}>
      <Form.Item name={['syndic']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              'syndic._id': value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              'syndic._id': ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('invoices_reminders.placeholders.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {`${syndic?.society_name} (${syndic?.code})`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['consultant']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              'consultant._id': value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              'consultant._id': ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('invoices_reminders.placeholders.consultant')}
        >
          {(consultants || []).map((consultant) => (
            <Option key={consultant._id} value={consultant._id}>
              {`${consultant?.first_name} ${consultant?.last_name}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['invoice-type']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              invoiceType: value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              invoiceType: ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('invoices_reminders.placeholders.invoice_type')}
        >
          {(invoiceTypes.types || []).map((invoice_type) => (
            <Option key={invoice_type} value={invoice_type}>
              {t(`invoices_reminders.tags.${invoice_type}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['mission-type']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              missionType: value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              missionType: ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('invoices_reminders.placeholders.mission')}
        >
          {(missions.types || []).map((mission) => (
            <Option key={mission} value={mission}>
              {t(`missions.tags.${mission}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={['sending-date']}
        label={t('invoices_reminders.filters.filter_by_sending_date')}
      >
        <RangePicker
          onChange={(dates) => {
            if (dates) {
              setFilters((prev) => ({
                ...prev,
                'sending_date.start_date': new Date(dates[0].$d),
                'sending_date.end_date': new Date(dates[1].$d)
              }));
            } else {
              setFilters((prev) => ({
                ...prev,
                'sending_date.start_date': '',
                'sending_date.end_date': ''
              }));
            }
          }}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          allowClear
          style={{ minWidth: 200 }}
        />
      </Form.Item>
      <Form.Item
        name={['last-reminder-date']}
        label={t('invoices_reminders.filters.filter_by_last_reminder')}
      >
        <RangePicker
          onChange={(dates) => {
            if (dates) {
              setFilters((prev) => ({
                ...prev,
                'last_reminder.start_date': new Date(dates[0].$d),
                'last_reminder.end_date': new Date(dates[1].$d)
              }));
            } else {
              setFilters((prev) => ({
                ...prev,
                'last_reminder.start_date': '',
                'last_reminder.end_date': ''
              }));
            }
          }}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          allowClear
          style={{ minWidth: 200 }}
        />
      </Form.Item>
      <Form.Item
        name={['next-reminder-date']}
        label={t('invoices_reminders.filters.filter_by_next_reminder')}
      >
        <RangePicker
          onChange={(dates) => {
            if (dates) {
              setFilters((prev) => ({
                ...prev,
                'next_reminder.start_date': new Date(dates[0].$d),
                'next_reminder.end_date': new Date(dates[1].$d)
              }));
            } else {
              setFilters((prev) => ({
                ...prev,
                'next_reminder.start_date': '',
                'next_reminder.end_date': ''
              }));
            }
          }}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          allowClear
          style={{ minWidth: 200 }}
        />
      </Form.Item>
    </Form>
  );
};

SearchBarsOptions.propTypes = {
  setFilters: PropTypes.func.isRequired,
  syndics: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      society_name: PropTypes.string,
      code: PropTypes.string
    })
  ).isRequired,
  invoiceTypes: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  missions: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  consultants: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  )
};

SearchBarsOptions.defaultProps = {
  consultants: []
};

export default SearchBarsOptions;
