import { useState } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './columns';

/**
 * Renders a list of predefined messages.
 * @component
 * @returns {JSX.Element} The list of predefined messages.
 */
export const ListPredefinedMessages = () => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns({
    setForceRefresh,
    forceRefresh
  });

  return (
    <ListResource
      resourceName="predefined-messages"
      columns={columns}
      forceRefresh={forceRefresh}
      resourceModelName="Predifined-message"
    />
  );
};
