import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { Button, Popconfirm, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { routes } from '../../../utils/constants/routes/adminRoutes';
import { getCollectiveOwnership } from '../utils/getCollectiveOwnership';
import { deleteResource } from '../../../utils/deleteResource';
import { getProfessionals } from '../utils/getProfessionals';
import { useItems } from './Items/useItems';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useCollectiveOwnershipContext } from '../../../contexts/CollectiveOwnershipContext';
import { getEnums } from '../utils/getEnums';

// BIG REFACTORING NEEDED => SET ALL FUNCTION IN CONTEXT

/**
 * Renders the ShowCollectiveOwnership component.
 * This component displays the details of a collective ownership.
 *
 * @component
 * @returns {JSX.Element} The rendered ShowCollectiveOwnership component.
 */
export const ShowCollectiveOwnership = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { activeKey, setActiveKey } = useGlobalContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const [collectiveOwnership, setCollectiveOwnership] = useState({});
  const [chargingGrid, setChargingGrid] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [professionals, setProfessionals] = useState({});
  const [permissions, setPermissions] = useState({
    lawyers: [],
    notaries: [],
    surveyors: [],
    others: []
  });
  const { getRepositoryDocumentDatas, itemType } =
    useCollectiveOwnershipContext();
  const { items } = useItems({
    collectiveOwnership,
    isLoading,
    t,
    setCollectiveOwnership,
    columns,
    setColumns,
    chargingGrid,
    setChargingGrid,
    dataSource,
    setDataSource,
    permissions,
    setPermissions,
    professionals,
    id,
    enums,
    forceRefresh,
    setForceRefresh,
    user
  });

  const buttonsPermissions = {
    disableEdit: [
      'users:NOMADE_CUSTOMER_SERVICE_MANAGER',
      'users:LAWYER'
    ].includes(user.role)
  };

  const getCollectiveOwnershipDatas = async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        getCollectiveOwnership({
          dispatchAPI,
          id,
          setCollectiveOwnership,
          setPermissions,
          message
        }),
        getProfessionals({
          dispatchAPI,
          message,
          setProfessionals
        })
      ]);
      setIsLoading(false);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getRepositoryDocumentDatas({ id });
    })();
  }, [itemType]);

  useEffect(() => {
    (async () => {
      await getCollectiveOwnershipDatas();
      await getEnums({ dispatchAPI, setEnums, message });
    })();
  }, []);

  useEffect(() => {
    if (state?.showMissionTab) {
      setActiveKey('2');
    }
  }, [state]);

  return (
    <>
      <PageHeaderCustom
        title={t('collective-ownerships.show.title', {
          name:
            collectiveOwnership?.name ||
            collectiveOwnership?.syndic?.society_name
        })}
        extra={
          <>
            {!buttonsPermissions.disableEdit && (
              <Link
                to={{ pathname: `${routes.COLLECTIVE_OWNERSHIPS}/edit/${id}` }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
            {!buttonsPermissions.disableEdit && (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() =>
                  deleteResource({
                    dispatchAPI,
                    resourceName: 'collective-ownerships',
                    id,
                    message,
                    navigate
                  })
                }
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <ContentCustom>
        <Tabs
          tabPosition="left"
          items={items}
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key);
            setForceRefresh(!forceRefresh);
          }}
        />
      </ContentCustom>
    </>
  );
};
