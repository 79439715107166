import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Table, Button, Row } from 'antd';
import PropTypes from 'prop-types';
import { AddIcon } from '../../../../utils/constants/customIcons';
import { useColumns } from './columns';
import { ContentCustom } from '../../../../components/ContentCustom/ContentCustom';
import { AddPermissionsModal } from './AddPermissionsModal';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * The `ProfessionalsTable` component is designed to display a table of professionals and manage permissions associated with them.
 * It leverages Ant Design's `Table` component for the table display and a custom `AddPermissionsModal` for adding or editing permissions.
 * The component uses context and hooks to manage state and handle actions such as opening the modal, setting permissions, and displaying error messages.
 *
 * Props:
 * @prop {Array} dataSource - The data source for the table, expected to be an array of objects where each object represents a row in the table.
 * @prop {function} setPermissions - Function to update the permissions state, typically used within the `AddPermissionsModal`.
 * @prop {Object} professionals - An object containing arrays of professional entities, such as lawyers and notaries, which can be assigned permissions.
 * @prop {Object} permissions - The current permissions settings, used for pre-populating the modal with existing permissions.
 * @prop {boolean} isFieldsLoading - Indicates whether the data for the professionals is currently loading, used to show a loading state in the modal.
 * @prop {function} t - Translation function used for internationalization, allowing for dynamic translation of labels and buttons.
 * @prop {string} purpose - A string indicating the purpose of the table, which can influence the actions and behaviors within the component.
 *
 * The component initializes with a state `openModal` to control the visibility of the `AddPermissionsModal`.
 * It uses the `useColumns` hook to generate column definitions for the table, which include actions for editing and deleting permissions.
 * The `useAuthContext` hook provides `dispatchAPI` for making API calls, and the `useErrorMessage` hook offers a `message` function for error handling.
 * The `useParams` hook from `react-router-dom` is used to extract the `id` parameter from the URL, which can be used for operations that require an identifier, such as API requests.
 *
 * Usage:
 * This component is intended for use in interfaces where a list of professionals needs to be managed along with their permissions.
 * It is suitable for scenarios where permissions are dynamically assigned and require a user-friendly interface for addition and modification.
 */

export const ProfessionalsTable = ({
  dataSource,
  setPermissions,
  professionals,
  permissions,
  isFieldsLoading,
  t,
  purpose
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const columns = useColumns({
    setPermissions,
    permissions,
    dispatchAPI,
    message,
    id,
    purpose
  });
  const [openModal, setOpenModal] = useState(false);

  return (
    <ContentCustom>
      <Row justify="end">
        <Button type="add" onClick={() => setOpenModal(true)}>
          {t('buttons.create')}
          <AddIcon />
        </Button>
      </Row>
      <Table
        rowClassName="rowStyle"
        dataSource={[...dataSource]}
        columns={columns}
      />
      {openModal ? (
        <AddPermissionsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          setPermissions={setPermissions}
          isFieldsLoading={isFieldsLoading}
          professionals={professionals}
          permissions={permissions}
          t={t}
          dispatchAPI={dispatchAPI}
          message={message}
          id={id}
          purpose={purpose}
        />
      ) : null}
    </ContentCustom>
  );
};

ProfessionalsTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  setPermissions: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  professionals: PropTypes.shape({}),
  permissions: PropTypes.shape({}),
  isFieldsLoading: PropTypes.bool.isRequired,
  purpose: PropTypes.string.isRequired
};

ProfessionalsTable.defaultProps = {
  dataSource: [],
  professionals: {},
  permissions: {
    lawyers: [],
    notaries: [],
    surveyors: [],
    others: []
  }
};
