import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { actions_to_do } from '../../../../../utils/constants/tagColors';
import { formatAddress } from '../../../utils/formatingFunctions';
/**
 * @hook
 * Custom hook that returns an array of columns configuration for a table.
 *
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const missionStatusTags = (action, types) => {
    const filteredMissions = action.filter((mission) =>
      types.some((type) => mission === type)
    );
    return filteredMissions.map((mission) => (
      <Tag
        color={actions_to_do[mission]}
        style={{
          display: 'block',
          marginBottom: '8px',
          textAlign: 'center'
        }}
      >
        {mission ? (
          <>
            {t(`missions.tags.${mission}`)}
            <br />
          </>
        ) : (
          ''
        )}
      </Tag>
    ));
  };

  return [
    {
      title: t('collective-ownerships.form.manager'),
      key: 'name',
      dataIndex: ['collective_ownership', 'manager'],
      sorter: true,
      render: (r) => `${r?.first_name || ''} ${r?.last_name || ''}`
    },
    {
      title: t('collective-ownerships.form.collective_ownership_address'),
      key: ['first_address'],
      dataIndex: ['collective_ownership'],
      render: ({ name, first_address, first_city }) =>
        formatAddress(name, first_address, first_city)
    },
    {
      title: t('missions.tags.UNPAID_INVOICE'),
      key: ['actions_to_do'],
      dataIndex: ['actions_to_do'],
      render: (record) => missionStatusTags(record, ['UNPAID_INVOICE'])
    },
    {
      title: t('missions.tags.UNVALIDATED_QUOTE'),
      key: ['actions_to_do'],
      dataIndex: ['actions_to_do'],
      render: (record) => missionStatusTags(record, ['UNVALIDATED_QUOTE'])
    },
    {
      title: t('missions.tags.UNVALIDATED_DOCUMENT'),
      key: ['actions_to_do'],
      dataIndex: ['actions_to_do'],
      render: (record) => missionStatusTags(record, ['UNVALIDATED_DOCUMENT'])
    },
    {
      title: t('missions.tags.PENDING_DOCUMENT'),
      key: ['actions_to_do'],
      dataIndex: ['actions_to_do'],
      render: (record) => missionStatusTags(record, ['PENDING_DOCUMENT'])
    },
    {
      title: t('missions.tags.PENDING_MESSAGE'),
      key: ['actions_to_do'],
      dataIndex: ['actions_to_do'],
      render: (record) => missionStatusTags(record, ['PENDING_MESSAGE'])
    }
  ];
};
