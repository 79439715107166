import { useCallback, useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';
import { MunicipalitiesMethods } from './utils/methods';
import { GetEnums } from '../../../utils/getEnums';
import { useConfig } from './utils/createUpdateConfig';

/**
 * Component for creating or updating a municipality.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component.
 * @returns {JSX.Element} The CreateUpdateMunicipality component.
 */
export const CreateUpdateMunicipality = ({ purpose }) => {
  const [form] = Form.useForm();
  const { getSpfDepartmentalArchives } = MunicipalitiesMethods();
  const { getEnums } = GetEnums();
  const [spfDepartmentalArchives, setSpfDepartmentalArchives] = useState([]);
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { forceRefresh, setForceRefresh } = useConfigurationContext();
  const { fields } = useFields({
    enums,
    isFieldsLoading,
    spfDepartmentalArchives
  });

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);

    const [spfArchives, municipalitiesData] = await Promise.all([
      getSpfDepartmentalArchives(),
      getEnums({ resource: 'municipalities' })
    ]);

    setSpfDepartmentalArchives(spfArchives);
    setEnums(municipalitiesData);

    if (purpose === 'create') {
      form.setFieldsValue({
        services: [{ cerfa_type: '3231' }, { cerfa_type: '3236' }]
      });
    }

    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const config = useConfig();

  return (
    <CreateUpdateContainer
      customFormInstance={form}
      fields={fields}
      config={config}
      purpose={purpose}
      baseUrl="municipalities"
      resource="municipalities"
      withFilesManager={false}
      withEnums={false}
      setForceRefresh={setForceRefresh}
      forceRefresh={forceRefresh}
    />
  );
};

CreateUpdateMunicipality.propTypes = {
  purpose: PropTypes.string.isRequired
};
