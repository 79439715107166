import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, notification, Row, Spin, Steps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useMissionContext } from '../../../../contexts/MissionContext';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { showTitle } from './utils/showTitle';
import { AlertCard } from './components/AlertCard';
import { DocumentCard } from './components/DocumentCard';
import { MessageContextProvider } from '../../../../contexts/MessageContext';
import { Messages } from '../../../../components/Messages/Messages';
import { InformationsCard } from './components/InformationsCard';
import { InvoiceCard } from './components/InvoiceCard';
import { AddDocumentCard } from './components/AddDocumentCard';
import { plansResearchSteps } from './steps/plansResearchsSteps';
import { useMenu } from './utils/plansMenu';
import { PlansButtonsSwitcher } from './components/plansRequestComponents/PlansButtonsSwitcher';
import { SendEmailModal } from './components/plansRequestComponents/SendEmailModal';
import { CollectiveOwnership } from '../../../../models/CollectiveOwnership';
import { PlanRequestsCard } from './components/plansRequestComponents/PlanRequestsCard';
import { MissionMonitoringCard } from './components/MissionMonitoringCard';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { PlansOrderDrawer } from './drawers/PlansOrderDrawer';
import { PlansOrdersCard } from './components/plansRequestComponents/PlansOrdersCard';
import { DisbursementModal } from './modals/DisbursementModal';
import { AlertDrawer } from './drawers/AlertDrawer';
import { NoteDrawer } from './drawers/NoteDrawer';
import { BlockMissionCreditNote } from './modals/BlockMissionCreditNoteModal';
import { CancelMissionModal } from './modals/CancelMissionModal';
import { confirmRefundsButtons } from './utils/confirmRefundsButtons';
import { ConfirmRefundsModal } from './modals/ConfirmRefundsModal';

/**
 * Renders the ShowPlansResearchMission component.
 *
 * @component
 * @returns {JSX.Element} The rendered ShowPlansResearchMission component.
 */
export const ShowPlansResearchMission = () => {
  const { id, type } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { principalItems } = plansResearchSteps();
  const [isLoading, setIsLoading] = useState(true);
  const [archivistNotaries, setArchivistNotaries] = useState([]);
  const [step, setStep] = useState('');
  const [open, setOpen] = useState(false);
  const {
    getMissionDatas,
    current,
    mission,
    forceRefresh,
    setForceRefresh,
    addDocumentCardDatas,
    isOrderCardUp,
    isShowToBeOrderedCard,
    isDrawerOpen,
    setIsDrawerOpen
  } = useMissionContext();

  const [visible, setVisible] = useState(false);

  const getData = async (collective_ownership) => {
    const data =
      await CollectiveOwnership.getArchivistNotariesByCollectiveOwnershipId(
        collective_ownership
      );
    setArchivistNotaries(data);
  };

  const items = {
    PLANS_RESEARCH: { principal: principalItems }
  };

  const menu = {
    items: useMenu({ mission })
  };

  useEffect(() => {
    (async () => {
      await getMissionDatas({
        id,
        type,
        message: notification,
        t
      });
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (mission.type) {
      setIsLoading(false);
      setStep(mission.steps.principal);
    }
    if (mission?.send_archivist_notary === true) {
      setVisible(true);
    }
    if (mission?.collective_ownership?._id) {
      getData(mission?.collective_ownership?._id);
    }
  }, [mission]);

  return (
    <>
      <PageHeaderCustom title={showTitle({ record: mission })} />
      <Row justify="end" gutter={[5, 5]} style={{ marginRight: 15 }}>
        {confirmRefundsButtons({ mission, setIsDrawerOpen })}
        <PlansButtonsSwitcher
          mission={mission}
          setVisible={setVisible}
          forceRefresh={forceRefresh}
        />
        <Col>
          <Dropdown menu={menu}>
            <Button type="link">{t('missions.buttons.more_actions')}</Button>
          </Dropdown>
        </Col>
        <Col>
          <Button type="link" icon={<MenuOutlined />} />
        </Col>
      </Row>
      <ContentCustom>
        <Steps
          current={current.principal}
          items={items[type].principal}
          style={{ marginBottom: 24 }}
        />
        <Row gutter={[16, 16]}>
          <AlertCard />
          <Col span={addDocumentCardDatas.open ? 14 : 24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                {step === 'PLANS_DELIVERY' && isOrderCardUp ? (
                  <Col span={24} style={{ marginBottom: 16 }}>
                    <PlansOrdersCard status="ORDERED" />
                  </Col>
                ) : null}
                {current.principal >= 2 && isShowToBeOrderedCard ? (
                  <Col span={24}>
                    <PlansOrdersCard status="TO_BE_ORDERED" setOpen={setOpen} />
                  </Col>
                ) : null}
              </Col>
              {mission.plan_requests &&
              mission?.send_archivist_notary === false &&
              step === 'NOTARY_OFFICE_REQUEST' ? (
                <Col span={24}>
                  <PlanRequestsCard
                    mission={mission}
                    forceRefresh={forceRefresh}
                    setForceRefresh={setForceRefresh}
                  />
                </Col>
              ) : null}
              <Col span={24}>
                <MissionMonitoringCard
                  mission={mission}
                  isLoading={isLoading}
                />
              </Col>

              <Col span={24}>
                <DocumentCard />
              </Col>

              <Col md={24} lg={12}>
                <MessageContextProvider id={id} model="Mission">
                  <Messages missionType="PLANS_RESEARCH" withCreateButton />
                </MessageContextProvider>
              </Col>
              {mission?.pcs_pre_production ? (
                <Col md={24} lg={12}>
                  <MessageContextProvider
                    id={mission?.pcs_pre_production}
                    model="Pcs-pre-production"
                  >
                    <Messages
                      cardTitle={t(
                        'missions.cards.titles.pcs_pre_production_messages'
                      )}
                      withoutDefaultActiveKey
                      withCreateButton={false}
                    />
                  </MessageContextProvider>
                </Col>
              ) : null}
              <Col md={24} lg={12}>
                <InformationsCard />
              </Col>
              {Object.keys(mission).length ? (
                <Col span={24}>
                  <InvoiceCard type={mission.type} />
                </Col>
              ) : null}
              {current.principal > 2 && !isOrderCardUp ? (
                <Col span={24}>
                  <PlansOrdersCard status="ORDERED" />
                </Col>
              ) : null}
              {mission.plan_requests && current.principal >= 2 ? (
                <Col span={24}>
                  <PlanRequestsCard
                    mission={mission}
                    forceRefresh={forceRefresh}
                    setForceRefresh={setForceRefresh}
                    lastStep
                  />
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col
            className="add-cerfa-docs-card"
            span={10}
            style={{ display: addDocumentCardDatas.open ? 'block' : 'none' }}
          >
            <AddDocumentCard />
          </Col>
        </Row>
      </ContentCustom>
      <Spin
        spinning={isLoading}
        fullscreen
        tip={t('missions.messages.loading')}
      />
      <SendEmailModal
        mission={mission}
        archivistNotaries={archivistNotaries}
        setVisible={setVisible}
        setIsLoading={setIsLoading}
        visible={visible}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
      />
      <PlansOrderDrawer
        open={open}
        setOpen={setOpen}
        dispatchAPI={dispatchAPI}
        mission={mission}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        setIsLoading={setIsLoading}
      />
      {isDrawerOpen.disbursement ? <DisbursementModal /> : null}
      {isDrawerOpen.alert ? <AlertDrawer /> : null}
      {isDrawerOpen.note ? <NoteDrawer /> : null}
      {isDrawerOpen.block_mission_credit_note ? (
        <BlockMissionCreditNote />
      ) : null}
      {isDrawerOpen.cancel_mission ? <CancelMissionModal /> : null}
      {isDrawerOpen.confirm_refunds ? <ConfirmRefundsModal /> : null}
    </>
  );
};
