import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 *  Custom error handler for FAQ component.
 *  @component
 *
 * @param {Error} e - The error object.
 * @returns {void} - Custom error notification.
 */
export const useFaqCustomError = (e) => {
  const { t } = useTranslation();
  const verifyUsedOrderErrorFAQ = () => {
    if (e?.response?.data?.description === 'READING_ORDER_ALREADY_USED') {
      notification.error({
        message: t('status?.response?.data?.description', {
          action: e?.response?.data?.errors
        }),
        key: 'network_feedback',
        duration: 4.5
      });
    }
  };

  return { verifyUsedOrderErrorFAQ };
};
