import { useState, useEffect } from 'react';
import { Form, Table, Row, Button, Divider, Typography, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDisbursementAndDocumentsCostsColumns } from '../columns/disbursementAndDocumentsCostsColumns';
import { useDisbursementAndVariedCostsColumns } from '../columns/disbursementAndVariedCostsColumns';
import { useDisbursementAndNotaryCostsColumns } from '../columns/disbursementAndNotaryCostsColumns';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { DisbursementMethods } from '../utils/disbursementMethods';
import { Mission } from '../../../../../models/Mission';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const { Text } = Typography;

/**
 * Represents a form for handling disbursement information in the mission view.
 * @component
 * @returns {JSX.Element} DisbursementForm component
 */
export const DisbursementForm = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { addNewLineForDisbursementTable, setDefaultValues } =
    DisbursementMethods();
  const {
    documents,
    setIsDrawerOpen,
    mission,
    isDrawerOpen,
    notaryDisbursements,
    setNotaryDisbursements,
    setForceRefresh,
    disbursementAndVariedCosts,
    setDisbursementAndVariedCosts
  } = useMissionContext();

  const [finalAmounts, setFinalAmounts] = useState({
    disbursement: 0,
    difference: 0
  });

  const disbursementAndDocumentsCostsColumns =
    useDisbursementAndDocumentsCostsColumns();
  const disbursementAndVariedCostsColumns =
    useDisbursementAndVariedCostsColumns({
      form,
      setDisbursementAndVariedCosts
    });
  const disbursementAndNotaryCostsColumns =
    useDisbursementAndNotaryCostsColumns({
      setNotaryDisbursements,
      setFinalAmounts,
      form
    });

  useEffect(() => {
    setDefaultValues({
      documents,
      form,
      notaryDisbursements,
      disbursementAndVariedCosts,
      setFinalAmounts
    });
  }, [notaryDisbursements]);

  return (
    <Form
      form={form}
      onFinish={(values) =>
        Mission.handleDisbursements({
          values,
          id: mission._id,
          finalAmounts,
          setForceRefresh,
          message,
          setIsDrawerOpen,
          successMessage: t('missions.messages.disbursement_success'),
          isDrawerOpen
        })
      }
    >
      <Divider>
        {t('missions.dividers.disbursement_and_documents_costs')}
      </Divider>
      <Table
        bordered={false}
        dataSource={documents.filter(
          (doc) =>
            doc.main_type === 'CERFA' &&
            doc.mission_type === isDrawerOpen?.disbursement?.type
        )}
        columns={disbursementAndDocumentsCostsColumns}
        pagination={false}
        style={{ marginBottom: '16px' }}
      />
      {isDrawerOpen.disbursement.type === 'PCS_3' ? (
        <>
          <Divider>
            {t('missions.dividers.disbursement_and_notary_costs')}
          </Divider>
          <Table
            bordered={false}
            dataSource={notaryDisbursements}
            columns={disbursementAndNotaryCostsColumns}
            pagination={false}
            style={{ marginBottom: '16px' }}
          />
          <Button
            type="dashed"
            style={{
              width: '100%',
              borderColor: 'gray',
              borderStyle: 'dashed',
              backgroundColor: 'transparent',
              marginBottom: '16px'
            }}
            onClick={() =>
              addNewLineForDisbursementTable({
                setNotaryDisbursements,
                type: 'notary',
                mission,
                form,
                isDrawerOpen
              })
            }
          >
            {t('buttons.add_line')}
          </Button>
          <Row justify="start">
            <Col span={24}>
              <Text>
                {t('missions.form.notary_disbursement', {
                  amount: finalAmounts.disbursement
                })}
              </Text>
            </Col>
            <Col spane={24}>
              <Text>
                {' '}
                {t('missions.form.difference', {
                  amount: finalAmounts.difference
                })}
              </Text>
            </Col>
          </Row>
        </>
      ) : null}
      <Divider>{t('missions.dividers.disbursement_and_varied_costs')}</Divider>
      <Table
        bordered={false}
        dataSource={disbursementAndVariedCosts.filter(
          (disbursement) =>
            disbursement.mission_type === isDrawerOpen?.disbursement?.type
        )}
        columns={disbursementAndVariedCostsColumns}
        pagination={false}
        style={{ marginBottom: '16px' }}
      />
      <Button
        type="dashed"
        style={{
          width: '100%',
          borderColor: 'gray',
          borderStyle: 'dashed',
          backgroundColor: 'transparent',
          marginBottom: '16px'
        }}
        onClick={() =>
          addNewLineForDisbursementTable({
            setDisbursementAndVariedCosts,
            type: 'varied',
            mission,
            form,
            isDrawerOpen
          })
        }
      >
        {t('buttons.add_line')}
      </Button>
      <Form.Item>
        <Row justify="end">
          <Button
            style={{ margin: '0 8px' }}
            type="link"
            danger
            onClick={() =>
              setIsDrawerOpen((prev) => ({ ...prev, disbursement: false }))
            }
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
