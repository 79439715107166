import { useTranslation } from 'react-i18next';
import { Alert, Button, Row } from 'antd';
import { handleDeleteFormSubmit } from './handleDeleteFormSubmit';
import { DeleteForm } from '../modals/DeleteForm';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Renders the content for the delete syndic modal.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.collectiveOwnerships - The collective ownerships.
 * @param {boolean} props.forceRefresh - The force refresh flag.
 * @param {boolean} props.isFieldLoading - The field loading flag.
 * @param {Array} props.managers - The managers.
 * @param {Object} props.selectedSyndic - The selected syndic.
 * @param {Function} props.setForceRefresh - The function to set the force refresh flag.
 * @param {Function} props.setIsDeleteModalOpen - The function to set the delete modal open flag.
 * @returns {Array} The content for the delete syndic modal.
 */
export const HandleDeleteSyndicModalContent = ({
  collectiveOwnerships,
  forceRefresh,
  isFieldLoading,
  managers,
  selectedSyndic,
  setForceRefresh,
  setIsDeleteModalOpen
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const content = [];

  if (collectiveOwnerships.length) {
    content.push(
      <DeleteForm
        selectedSyndic={selectedSyndic}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        isFieldLoading={isFieldLoading}
        managers={managers}
      />
    );

    if (selectedSyndic?.managers?.length && collectiveOwnerships.length) {
      content.push(
        <Alert
          description={t(
            'syndics.modals.messages.managers_exists_with_collective_ownerships'
          )}
          type="warning"
        />
      );
    }
  }

  if (selectedSyndic?.managers?.length && !collectiveOwnerships.length) {
    content.push(
      <Row gutter={[16, 16]} justify="end">
        <Alert
          description={t(
            'syndics.modals.messages.managers_exists_without_collective_ownerships'
          )}
          type="warning"
        />
      </Row>
    );
  }

  if (!selectedSyndic?.managers?.length && !collectiveOwnerships.length) {
    content.push(
      <Row gutter={[16, 16]} justify="end">
        <Button
          onClick={() =>
            handleDeleteFormSubmit({
              dispatchAPI,
              message,
              selectedSyndic,
              setForceRefresh,
              forceRefresh,
              setIsDeleteModalOpen
            })
          }
        >
          {t('buttons.delete')}
        </Button>
      </Row>
    );
  }

  return content;
};
