import { useAuthContext } from '../../contexts/AuthContext';
import { AdminDashboard } from './AdminDashboard/AdminDashboard';
import { ProductionDashboard } from './ProductionDashboard/ProductionDashboard';
import { AssistantDashboard } from './AssistantDashboard/AssistantDashboard';
import { AccountManagerDashboard } from './AccountManagerDashboard/AccountManagerDashboard';
import { BillingDashboard } from './BillingDashboard/BillingDashboard';
import { SuperAdminDashboard } from './SuperAdminDashboard/SuperAdminDashboard';
/**
 * DashboardDispatch Component
 *
 * This component dynamically renders different dashboards based on the user's role:
 * - AdminDashboard for admins:ADMIN
 * - SuperAdminDashboard for admins:SUPER-ADMIN
 * - ProductionDashboard for users:NOMADE_PRODUCTION
 * - AssistantDashboard for users:NOMADE_ASSISTANT
 * - AccountManagerDashboard for users:NOMADE_ACCOUNT_MANAGER
 * - BillingDashboard for users:NOMADE_BILLING
 *
 * If the user's role doesn't match any of the above, it renders null.
 * @component
 * @returns {JSX.Element | null} The rendered dashboard component based on the user's role.
 */
export const DashboardDispatch = () => {
  const { user } = useAuthContext();

  const dispatchDashboard = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
        return <AdminDashboard />;
      case 'admins:SUPER-ADMIN':
        return <SuperAdminDashboard />;
      case 'users:NOMADE_PRODUCTION':
        return <ProductionDashboard />;
      case 'users:NOMADE_ASSISTANT':
        return <AssistantDashboard />;
      case 'users:NOMADE_ACCOUNT_MANAGER':
        return <AccountManagerDashboard />;
      case 'users:NOMADE_BILLING':
        return <BillingDashboard />;
      default:
        return null;
    }
  };

  return dispatchDashboard();
};
