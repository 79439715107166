import dayjs from 'dayjs';

/**
 * Custom hook that returns a configuration object with a callback function for setting fields.
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setAssistants - The function for setting assistants.
 * @param {Function} options.setManagers - The function for setting managers.
 * @param {Function} options.setIsMultipleCollectiveOwnerships - The function for setting the multiple collective ownerships flag.
 * @param {Function} options.setStatus - The function for setting the status.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({
  setAssistants,
  setManagers,
  setIsMultipleCollectiveOwnerships,
  setStatus,
  user
}) => ({
  onGetResource: {
    /**
     * Callback function for setting fields based on the provided data.
     * @param {Object} data - The data object.
     * @returns {Object} The updated data object.
     */
    setFields: (data) => {
      if (data?.syndic && data?.syndic?.assistants) {
        setAssistants(data?.syndic?.assistants);
      }

      if (data?.syndic && data?.syndic?.managers) {
        setManagers(data?.syndic?.managers);
      }

      setStatus(data?.status);
      setIsMultipleCollectiveOwnerships(
        data?.is_multiple_collective_ownerships
      );

      const newData = {
        ...data,
        syndic: data.syndic?._id,
        AG_date: dayjs(data.AG_date)
      };
      return newData;
    }
  },
  onCreateResource: {
    setBody: (data) => {
      const history = [
        {
          date: new Date(),
          type: 'mission_launch',
          by: user._id
        }
      ];
      const body = {
        ...data,
        history
      };
      return body;
    }
  }
});
