import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UsePermissionsItems } from './usePermissionsItems';
import { CustomActionButtons } from '../../../../components/CreateUpdateContainer/CustomActionButtons';

/**
 * The `Permissions` component is designed to manage and display permissions-related configurations within a form.
 * It uses the `UsePermissionsItems` component to render permissions items based on the given `professionals` and `permissions` data,
 * and conditionally renders `CustomActionButtons` for navigation and actions within a form when the purpose is set to 'edit'.
 * This component is typically used within a larger form structure where permissions need to be managed or edited.
 *
 * Props:
 * @prop {function} [onFinish] - Function to be called when the finish action is triggered.
 * @prop {function} [onSubmit] - Function to be called when the submit action is triggered.
 * @prop {Object} [initialValues] - Initial values for the form fields, used when the component is used for editing existing permissions.
 * @prop {Object} form - Instance of the form provided by Ant Design's Form component. Must include a `setFieldsValue` function for setting form field values.
 * @prop {boolean} [isFieldsLoading=false] - Indicates whether the fields' data is currently loading.
 * @prop {function} [setCurrent] - Function to set the current step in a multi-step form process.
 * @prop {number} [current=0] - The current step in a multi-step form process.
 * @prop {Object} permissions - The current permissions settings.
 * @prop {function} setPermissions - Function to update the permissions state.
 * @prop {Object} professionals - Object containing arrays of professional entities, such as lawyers and notaries, involved in the permissions.
 * @prop {function} t - Translation function used for i18n to translate labels and messages.
 * @prop {string} purpose - A string indicating the purpose of the component, affecting its behavior and rendering. For example, 'edit' triggers form field pre-population and the display of action buttons.
 *
 * The component utilizes a `useEffect` hook to pre-populate form fields with `initialValues` when the `purpose` is 'edit', ensuring that existing data can be modified.
 *
 * Usage:
 * This component is intended for use within forms that require a permissions management section, particularly in scenarios where permissions need to be assigned or edited. It provides a flexible interface for handling permissions with support for loading states and dynamic form field population based on the editing context.
 */

export const Permissions = ({
  onFinish,
  onSubmit,
  initialValues,
  form,
  isFieldsLoading,
  setCurrent,
  current,
  permissions,
  setPermissions,
  professionals,
  t,
  purpose
}) => {
  useEffect(() => {
    if (purpose === 'edit') {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  return (
    <>
      <UsePermissionsItems
        t={t}
        permissions={permissions}
        setPermissions={setPermissions}
        professionals={professionals}
        isFieldsLoading={isFieldsLoading}
        purpose={purpose}
      />
      {purpose === 'edit' ? (
        <CustomActionButtons
          onFinish={onFinish}
          onSubmit={onSubmit}
          setCurrent={setCurrent}
          current={current}
          previousButton
        />
      ) : null}
    </>
  );
};

Permissions.propTypes = {
  isFieldsLoading: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  enums: PropTypes.shape({}),
  permissions: PropTypes.shape({}),
  setPermissions: PropTypes.func.isRequired,
  professionals: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  onFinish: PropTypes.func,
  onSubmit: PropTypes.func,
  setCurrent: PropTypes.func,
  current: PropTypes.number
};

Permissions.defaultProps = {
  initialValues: {},
  enums: {},
  permissions: {},
  professionals: {},
  isFieldsLoading: true,
  onFinish: () => {},
  onSubmit: () => {},
  setCurrent: () => {},
  current: 0
};
