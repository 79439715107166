import { useTranslation } from 'react-i18next';
import { Checkbox, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { disableHours } from './utils/disableHours';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * @description useAppointmentFields form
 * @param {Object} props - The props object.
 * @param {Array} props.syndics - The syndics array.
 * @param {Function} props.setSyndic - The setSyndic function.
 * @param {Array} props.managers - The managers array.
 * @param {Array} props.missions - The missions array.
 * @param {Object} props.user - The user object.
 * @returns {Object} - The fields object.
 */
export const usePCSAppointmentFields = ({
  syndics,
  setSyndic,
  managers,
  preprods,
  syndic,
  manager,
  pcs_preproduction
}) => {
  const { t } = useTranslation();
  const [showReplan, setShowReplan] = useState(false);
  const { disabledTime } = disableHours();

  const fields = [
    {
      name: ['syndic'],
      rules: [{ required: true }, { type: 'string' }],
      input: (
        <Select
          style={{
            width: 300,
            marginRight: 10
          }}
          showSearch
          allowClear
          optionFilterProp="children"
          onSelect={(value, option) => {
            setSyndic(option.syndic);
          }}
          {...(syndic && { value: syndic._id })}
          placeholder={
            syndic
              ? `${syndic.code} - ${syndic.society_name}`
              : t('placeholder.select_syndic')
          }
        >
          {(syndics || []).map((item) => (
            <Option value={item._id} key={item._id} syndic={item}>
              {`${item.code} ${item.society_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['manager'],
      rules: [{ required: true }, { type: 'string' }],
      input: (
        <Select
          style={{
            width: 300,
            marginRight: 10
          }}
          showSearch
          allowClear
          optionFilterProp="children"
          {...(manager && { value: manager._id })}
          placeholder={
            manager
              ? `${manager.first_name} ${manager.last_name}`
              : t('placeholder.select_manager')
          }
        >
          {(managers || []).map((item) => (
            <Option value={item._id} key={item._id} manager={item}>
              {`${item.first_name} ${item.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['pcs_preproduction'],
      rules: [{ required: true }, { type: 'string' }],
      input: (
        <Select
          style={{
            width: 300,
            marginRight: 10
          }}
          showSearch
          allowClear
          optionFilterProp="children"
          {...(pcs_preproduction && { value: pcs_preproduction._id })}
          placeholder={
            pcs_preproduction
              ? `${pcs_preproduction.syndic.society_name} `
              : t('placeholder.select_syndic')
          }
        >
          {(preprods || []).map((item) => (
            <Option value={item._id} key={item._id} syndic={item}>
              {`${item?.syndic?.society_name || ''} ${
                item?.code ? `(${item.code})` : ''
              }`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['title'],
      input: (
        <Input style={{ width: 300 }} placeholder={t('events.form.title')} />
      )
    },
    {
      name: ['date'],
      key: 'date',
      rules: [{ required: true }, { type: Date }],
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      name: ['time_slots'],
      key: 'time_slots',
      rules: [{ required: true }, { type: Object }],
      input: (
        <RangePicker
          showTime={{
            format: 'HH:mm'
          }}
          picker="time"
          onOk={(value) => value}
          // disabledDate={disabledDateTime}
          // disabledTime={disabledRangeTime}
          disabled={false}
          disabledTime={disabledTime}
        />
      )
    },
    {
      name: ['contacts'],
      key: 'contacts',
      rules: [{ required: true }, { type: 'string' }],
      input: (
        <Input style={{ width: 300 }} placeholder={t('events.form.contacts')} />
      )
    },
    {
      name: ['remote_connection_support'],
      key: 'remote_connection_support',
      input: (
        <Input
          style={{ width: 300 }}
          placeholder={t('events.form.remote_connection_support')}
        />
      )
    },
    {
      name: ['informations'],
      key: 'informations',
      input: (
        <Input.TextArea
          style={{ width: 300, height: 100 }}
          placeholder={t('events.form.informations')}
        />
      )
    },
    {
      name: ['to_replan'],
      key: 'to_replan',
      valuePropName: 'checked',
      noLabel: true,
      input: (
        <Checkbox
          onChange={(value) => {
            setShowReplan(value.target.checked);
            return value.target.checked;
          }}
        >
          {t('events.form.to_replan')}
        </Checkbox>
      )
    },
    ...(showReplan
      ? [
          {
            name: ['new_date'],
            key: 'new_date',
            rules: [{ required: true }, { type: Date }],
            input: (
              <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
            )
          },
          {
            name: ['new_time_slots'],
            key: 'new_time_slots',
            rules: [{ required: true }, { type: Object }],
            input: (
              <RangePicker
                showTime={{
                  format: 'HH:mm',
                  defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                }}
                picker="time"
                onOk={(value) => value}
                // disabledDate={disabledDateTime}
                // disabledTime={disabledRangeTime}
                disabled={false}
              />
            )
          }
        ]
      : [])
  ];

  return { fields };
};
