import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Statistic } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import ModalPCSProgressTable from './ModalPCSProgressTable';

/**
 * CardStatistics component displays card statistics for a syndic.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.syndicId - The ID of the syndic.
 * @returns {JSX.Element} The CardStatistics component.
 */
export const CardStatistics = ({ syndicId }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [statistics, setStatistics] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getStatistics = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/syndics/statistics/${syndicId}`
    });
    setStatistics(data);
  };

  useEffect(() => {
    if (!syndicId) return;
    getStatistics();
  }, [syndicId]);

  const openModal = (card) => {
    if (card === 'PCS_missions_progress') setIsModalOpen(true);
  };

  return (
    <Row justify="space-around">
      {Object.keys(statistics).map((card) => (
        <Card
          style={{ backgroundColor: 'white', textAlign: 'center' }}
          onClick={() => openModal(card)}
        >
          <Statistic
            title={t(`syndics.show.cards.statistics.${card}`)}
            value={statistics[card].value}
            precision={statistics[card].precision}
            valueStyle={{
              textAlign: 'center',
              maxWidth: card === 'appointments' ? '10rem' : undefined,
              fontSize: card === 'appointments' ? '1rem' : undefined,
              fontWeight: card === 'appointments' ? '700' : undefined
            }}
            groupSeparator=",aaa"
            suffix={statistics[card].suffix}
          />
        </Card>
      ))}
      <ModalPCSProgressTable
        isOpen={isModalOpen}
        syndicId={syndicId}
        setIsOpen={setIsModalOpen}
      />
    </Row>
  );
};

CardStatistics.propTypes = {
  syndicId: PropTypes.string.isRequired
};
