import PropTypes from 'prop-types';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ListUsers } from './ListUsers';
import { CreateUpdateUser } from './CreateUpdateUser';
import { ShowUser } from './ShowUser/ShowUser';
import { Exception } from '../../components';

export const UserRouter = ({ restricAccess }) => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/create" element={<CreateUpdateUser purpose="create" />} />
      <Route path="/edit/:id" element={<CreateUpdateUser purpose="edit" />} />
      <Route path="/show/:id" element={<ShowUser key={location.key} />} />
      {restricAccess?.userList ? null : <Route index element={<ListUsers />} />}
      <Route path="*" element={<Exception />} />
    </Routes>
  );
};

UserRouter.defaultProps = {
  restricAccess: {}
};

UserRouter.propTypes = {
  restricAccess: PropTypes.shape({
    userList: PropTypes.bool
  })
};
