/**
 * Handles the form submission and validation.
 *
 * @async
 * @function
 *
 * @param {Object} options - The options object.
 * @param {Object} options.form - The form instance.
 * @param {Function} options.setFormData - The function to set the form data.
 * @param {Function} options.setCurrent - The function to set the current step.
 * @param {number} options.current - The current step.
 * @param {Object} options.Message - The message component.
 * @param {Function} options.t - The translation function.
 * @returns {Promise<null|string>} - A promise that resolves to null or an error message.
 */
export const onFinishForm = async ({
  form,
  setFormData,
  setCurrent,
  current,
  Message,
  t
}) => {
  try {
    const values = await form.validateFields();
    setFormData((prev) => ({ ...prev, ...values }));
    setCurrent(current + 1);
    return null;
  } catch (errorInfo) {
    return Message.error(t('collective-ownerships.messages.required_fields'));
  }
};
