import { Route, Routes } from 'react-router-dom';
import { ListMunicipalities } from './ListMunicipalities';
import { CreateUpdateMunicipality } from './CreateUpdateMunicipality';
import { Exception } from '../../../components';

export const MunicipalityRouter = () => (
  <Routes>
    <Route index element={<ListMunicipalities />} />
    <Route
      path="/create"
      element={<CreateUpdateMunicipality purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateMunicipality purpose="edit" />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
