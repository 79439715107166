// Main nav menuItems
export const routes = {
  DASHBOARD: '/dashboard',
  USERS: '/users',
  MISSIONS: '/missions',
  BILLING_AND_ACCOUNTING: '/billing-and-accountings',
  MONITORING: '/monitoring',
  ORDERS: '/orders',
  QUOTATIONS: '/quotations',
  SYNDICS: '/syndics',
  EVENTS: '/events',
  COLLECTIVE_OWNERSHIPS: '/collective-ownerships',
  CONFIGURATIONS: '/configurations'
};

// Main nav subMenuItems
export const subRoutes = {
  CONFIGURATIONS: {
    PRICE_SYSTEMS: '/price-systems',
    MUNICIPALITIES: '/municipalities',
    SPF_DEPARTMENTAL_ARCHIVES: '/spf-departmental-archives',
    DOCUMENT_REPOSITORIES: '/document-repositories',
    CERFA_DOCUMENTS: '/cerfa-documents',
    VARIOUS_DOCUMENTS: '/various-documents',
    REQUESTED_DOCUMENTS: '/requested-documents',
    QUOTE_CONFIGURATIONS: '/quote-configurations',
    REMINDERS: '/reminders',
    ACCOUNTINGS: '/accountings',
    NOTARY_CONFIGURATIONS: '/notary-configurations',
    CUSTOMER_SERVICE_MANAGER_MEETINGS: '/customer-service-manager-meetings',
    TEMPLATE_DOCS: '/template-documents',
    TEMPLATE_EMAILS: '/template-emails',
    FAQ_TEMPLATES: '/faq-templates',
    FAQ_TEMPLATES_PUBLIC: '/faq-templates-public',
    PREDEFINED_MESSAGES: '/predefined-messages'
  },
  MISSIONS: {
    PCS_PRE_PRODUCTIONS: '/pcs-pre-productions',
    MISSIONS_VIEW: '/missions-view',
    COLLECTIONS: '/collections',
    PCS_APPOINTMENTS_MONITORING: '/pcs-appointments-monitoring'
  },
  BILLING_AND_ACCOUNTING: {
    INVOICES: '/invoices',
    INVOICES_REMINDERS: '/invoices-reminders',
    ACCOUNTANT_EXPORTS: '/accountant-exports'
  },
  MONITORING: {
    PCS_STOCK: '/pcs-stock',
    SYNDIC_REQUESTS: '/syndic-requests',
    COLLECTIVEOWNERSHIPS_FORMS: '/collective-ownership-forms',
    COLLECTIVE_OWNERSHIPS_TRANSFER: '/collective-ownerships-transfer',
    STATISTICS: '/statistics',
    ACTIVITY_MONITORING: '/activity-monitoring'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PASSWORD_CREATION: '/post-pwd',
  PASSWORD_RESET: '/reset-pwd',
  // TODO : REMOVE THIS ROUTE LATER
  PCSFORM: '/pcs-form'
};

// url search parameters
export const pathSearches = {};
