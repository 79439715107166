import { BaseModel } from './BaseModel';
import { Order } from './Order';

/**
 * Represents an collective ownership.
 * @class
 */
export class CollectiveOwnership extends BaseModel {
  static resourceName = 'collective-ownerships';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${CollectiveOwnership.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${CollectiveOwnership.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Retrieves plans by collective ownership ID.
   *
   * @param {number} id - The ID of the plan.
   * @returns {Promise<Object>} - A promise that resolves to the plan data.
   */
  static async getPlansByRef({ collective_ownership, missionId, status }) {
    const { data: plans } = await this.dispatchAPI('GET', {
      url: `${CollectiveOwnership.resourceName}/plans/${collective_ownership}`
    });

    const { data: orders } = await this.dispatchAPI('GET', {
      url: `${Order.resourceName}/mission?mission=${missionId}&status=${status}`
    });

    // Récupérer uniquement les plans qui sont dans les commandes via la ref
    const plansInOrders = plans.filter((plan) => {
      const planRef = `${plan.plan_volume}-${plan.plan_number}`;
      return orders.some((order) => order.ref_doc.includes(planRef));
    });
    return plansInOrders;
  }

  /**
   * Retrieves a specific plan from the collective ownership based on the plan ID and mission.
   * @param {string} planId - The ID of the plan to retrieve.
   * @param {object} mission - The mission object containing the collective ownership information.
   * @returns {Promise<object>} - A promise that resolves to the retrieved plan data.
   */
  static async getSpecificPlanFromCollectiveOwnership(planId, mission) {
    const { data } = await this.dispatchAPI('GET', {
      url: `collective-ownerships/collective-ownership-plan/${mission.collective_ownership._id}?planId=${planId}`
    });
    return data;
  }

  /**
   * Retrieves the archivist notaries by collective ownership ID.
   *
   * @param {number} id - The ID of the collective ownership.
   * @returns {Promise<any>} - A promise that resolves to the retrieved data.
   */
  static async getArchivistNotariesByCollectiveOwnershipId(id) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${CollectiveOwnership.resourceName}/archivist-notaries/${id}`
    });
    return data;
  }

  /**
   * Uploads multiple collective ownership records with associated files.
   *
   * This method creates a FormData object, appends the given values as a JSON string,
   * and includes each file in the provided files array. It then dispatches a POST request
   * to the appropriate API endpoint with the constructed FormData as the request body.
   *
   * @param {Object} values - The values to be included in the request. This object is stringified and added to the FormData.
   * @param {Array<{ fileKey: string, file: File }>} files - An array of file objects, each containing a fileKey and a File.
   *        Each file is appended to the FormData with its corresponding fileKey.
   *
   * @returns {Promise<void>} - A promise that resolves when the POST request is completed.
   */
  static async postMultipleCollectiveOwnerships(values, files) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    if (files.length) {
      files.forEach((file) => {
        formData.append(file.fileKey, file.file);
      });
    }

    await this.dispatchAPI('POST', {
      url: `${CollectiveOwnership.resourceName}/`,
      body: formData
    });
  }

  /**
   * Retrieves the document repositories for a collective ownership.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the document repositories.
   * @param {string} options.id - The ID of the collective ownership.
   * @param {string} [options.populate=''] - The fields to populate in the document repositories.
   * @param {string} [options.extraQuery=''] - Additional query parameters.
   * @returns {Promise<Object>} A promise that resolves to the retrieved data.
   */
  static async getDocumentRepositoriesForCollectiveOwnership({
    id,
    populate = '',
    extraQuery = ''
  }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${CollectiveOwnership.resourceName}/document-repositories/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Deletes a document associated with a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for deleting the document.
   * @param {string} options.collective_ownership_id - The ID of the mission.
   * @param {function} options.setForceRefresh - The function to set the force refresh state.
   * @param {boolean} options.forceRefresh - The current force refresh state.
   * @param {string} options.document_id - The ID of the document.
   * @returns {Promise<void>} - A promise that resolves when the document is deleted.
   */
  static async deleteDocument({
    collective_ownership_id,
    setForceRefresh,
    forceRefresh,
    document_id
  }) {
    await this.dispatchAPI('DELETE', {
      url: `collective-ownerships/documents/${collective_ownership_id}?document_id=${document_id}`
    });
    setForceRefresh(!forceRefresh);
  }

  /**
   * Deletes multiple documents.
   *
   * @function
   * @async
   * @param {Object} options - The options for deleting multiple documents.
   * @param {Array} options.values - The values to be deleted.
   * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
   * @param {boolean} options.forceRefresh - The current value of the force refresh flag.
   * @param {string} options.id - The ID of the document.
   * @returns {Promise<void>} A promise that resolves when the documents are deleted.
   */
  static async deleteManyDocument({
    values,
    setForceRefresh,
    forceRefresh,
    id
  }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    await this.dispatchAPI('PATCH', {
      url: `missions/documents/delete-many/${id}`,
      body: formData
    });
    setForceRefresh(!forceRefresh);
  }

  /**
   * Fetches the list of forbidden roles from the API.
   *
   * This function makes an asynchronous GET request to retrieve forbidden roles
   * associated with the CollectiveOwnership resource. It uses the `dispatchAPI`
   * method to make the request and expects the response to contain data in a
   * specific format.
   *
   * @returns {Promise<Object>} A promise that resolves to the data containing the forbidden roles.
   */
  static async getForbiddenRoles() {
    const { data } = await this.dispatchAPI('GET', {
      url: `${CollectiveOwnership.resourceName}/forbidden-roles`
    });
    return data;
  }
}
