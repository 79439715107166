import dayjs from 'dayjs';

/**
 * Determines and returns the most recent reminder date from a given record.
 * The function compares the `documents_date`, `building_sheet_date`, and `nomade_payment_date`
 * fields of the record and returns a formatted string corresponding to the latest date.
 * If none of these dates are present, it returns a hyphen ("-").
 *
 * @param {Object} record - The record object containing reminder dates.
 * @param {Date|string|null} record.documents_date - The date when the document was received.
 * @param {Date|string|null} record.building_sheet_date - The date of the lawyer's response.
 * @param {Date|string|null} record.nomade_payment_date - The date of the Nomade payment.
 *
 * @returns {string} A string indicating the most recent date and the associated action
 *                   (e.g., "DD-MM-YYYY - Document reçu", "DD-MM-YYYY - Réponse avocat",
 *                   "DD-MM-YYYY - Paiement Nomade"). If no dates are available, returns "-".
 *
 * @example
 * const record = {
 *   documents_date: "2024-08-01T00:00:00Z",
 *   building_sheet_date: "2024-07-15T00:00:00Z",
 *   nomade_payment_date: null,
 * };
 *
 * getLastReminder(record);
 * // Returns "01-08-2024 - Document reçu"
 */
export const getLastReminder = (record) => {
  const documents_date = new Date(record.documents_date);
  const sheet_date = new Date(record.building_sheet_date);
  const nomade_payment = new Date(record.nomade_payment_date);
  const display1 = record.documents_date
    ? `${dayjs(documents_date).format('DD-MM-YYYY')} - Document reçu`
    : '-';
  const display2 = record.building_sheet_date
    ? `${dayjs(sheet_date).format('DD-MM-YYYY')} - Réponse avocat`
    : '-';
  const display3 = record.nomade_payment_date
    ? `${dayjs(nomade_payment).format('DD-MM-YYYY')} - Paiement Nomade`
    : '-';
  const dateArr = [];
  let defaultDate = '';

  if (record.documents_date) {
    dateArr.push(documents_date);
  }
  if (record.building_sheet_date) {
    dateArr.push(sheet_date);
  }
  if (record.nomade_payment_date) {
    dateArr.push(nomade_payment);
  }
  if (!dateArr.length) return '-';
  defaultDate = dateArr[0];
  dateArr.forEach((date) => {
    if (date > defaultDate) {
      defaultDate = date;
    }
  });
  switch (defaultDate) {
    case documents_date:
      return display1;
    case sheet_date:
      return display2;
    case nomade_payment:
      return display3;
    default:
      return '-';
  }
};
