import { Link } from 'react-router-dom';
import { Tag, Divider, Button } from 'antd';
import { EyeOutlined, EditOutlined, ForkOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { missionTag } from '../../../../../utils/constants/tagColors';

export const useColumns = ({
  enums,
  setIsReassignModalOpen,
  setSelectedCollectiveOwnerships,
  editUserPermissions
}) => {
  const { t } = useTranslation();

  return [
    {
      title: t('collective-ownerships.form.syndic'),
      key: 'syndic',
      render: (record) =>
        `${record.syndic.society_name || ''} ${
          record.syndic.code ? `(${record.code})` : ''
        }`,
      sorter: true
    },
    {
      title: t('collective-ownerships.form.collective_ownership'),
      key: 'collective_ownership',
      render: (record) =>
        `${record.code || ''} - ${record?.first_address?.number || ''} ${
          record?.first_address?.street || ''
        } ${record?.first_address?.postal_code || ''} ${
          record?.first_address?.city || ''
        } ${
          record.cadastral_reference ? `(${record.cadastral_reference})` : ''
        }`,
      sorter: true
    },
    {
      title: t('collective-ownerships.form.missions'),
      key: 'missions',
      dataIndex: 'missions',
      render: (missions) =>
        missions?.length
          ? missions.map((mission) => (
              <Tag color={missionTag[mission.type]}>
                {t(`missions.tags.${mission.type}`, {
                  defaultValue: mission.type
                })}
              </Tag>
            ))
          : '',
      sorter: true,
      filters: enums?.type?.map((r) => ({
        text: t(`missions.tags.${r}`),
        value: r
      }))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/collective-ownerships/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {editUserPermissions && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/collective-ownerships/edit/${record?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
              <Divider type="vertical" />
              <Button
                type="link"
                onClick={() => {
                  setIsReassignModalOpen(true);
                  setSelectedCollectiveOwnerships([record]);
                }}
              >
                <ForkOutlined />
              </Button>
            </>
          )}
        </>
      )
    }
  ];
};
