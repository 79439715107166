/**
 * Deletes a permission from the permissions object and updates the database if specified.
 * @function
 * @param {Object} options - The options object.
 * @param {Object} options.record - The record to be deleted.
 * @param {Function} options.setPermissions - The function to set the updated permissions.
 * @param {Object} options.permissions - The current permissions object.
 * @param {boolean} options.updateDataBase - Specifies whether to update the database.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {Function} options.message - The function to display error messages.
 * @param {string} options.id - The ID of the collective ownership.
 * @returns {void} - The function does not return anything.
 */
export const deletePermission = ({
  record,
  setPermissions,
  permissions,
  updateDataBase,
  dispatchAPI,
  message,
  id
}) => {
  const newPermissions = { ...permissions };

  Object.keys(newPermissions).forEach((key) => {
    newPermissions[key] = newPermissions[key].filter(
      (item) => item._id !== record._id
    );
  });

  if (updateDataBase) {
    const formData = new FormData();

    formData.append('values', JSON.stringify({ permissions: newPermissions }));

    try {
      dispatchAPI('PATCH', {
        url: `/collective-ownerships/${id}`,
        body: formData
      });
      setPermissions(newPermissions);
    } catch (e) {
      message(e);
    }
  } else {
    setPermissions(newPermissions);
  }
};
