import { EyeOutlined, FileSyncOutlined } from '@ant-design/icons';
import { Space, Tag, Tooltip, Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { missionTag } from '../../../utils/constants/tagColors';

/**
 * Returns an array of columns configuration for the invoices table.
 * Each column object contains a title, key, render function, and sorter property.
 * The render function is responsible for rendering the content of each cell in the column.
 * The sorter property enables sorting functionality for the column.
 *
 * @hook
 * @returns {Array} An array of column configuration objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return [
    {
      title: t('invoices.columns.syndic'),
      key: 'syndic',
      render: (record) => {
        const syndic = record?.mission_data?.syndic;
        const manager = record?.mission_data?.manager;
        const managerName = manager
          ? `${manager.first_name} ${manager.last_name}`
          : '-';
        const syndicName = syndic
          ? `${syndic.society_name} (${syndic.code})`
          : '-';
        return (
          <Row>
            <Col span={24}>
              <Text>{syndicName}</Text>
              <br />
              <Text>{managerName}</Text>
            </Col>
          </Row>
        );
      },
      sorter: true
    },
    {
      hidden: true,
      title: t('invoices.columns.manager'),
      key: 'manager',
      render: (record) => {
        const manager = record?.mission_data?.manager;
        return manager ? `${manager.first_name} ${manager.last_name}` : '-';
      },
      sorter: true
    },
    {
      title: t('invoices.columns.collective-ownership'),
      key: 'collective_ownership',
      render: (record) => {
        const collective_ownership = record?.mission_data?.collective_ownership;
        return collective_ownership ? collective_ownership.name : '-';
      },
      sorter: true
    },
    {
      title: t('invoices.columns.mission'),
      key: 'mission',
      render: (record) => {
        const mission_type = record?.mission_data.type;
        return mission_type ? (
          <Tag color={missionTag[mission_type]}>
            {t(`missions.tags.${mission_type}`, {
              defaultValue: mission_type
            })}
          </Tag>
        ) : (
          '-'
        );
      },
      sorter: true
    },
    {
      title: t('invoices.columns.last-reminder'),
      key: 'last_reminder',
      render: (record) => {
        const last_reminder = record?.mission_data.last_reminder;
        return last_reminder
          ? dayjs(last_reminder).format('DD-MM-YYYY')
          : 'Jamais';
      },
      sorter: true
    },
    {
      title: t('invoices.columns.remain_to_be_billed_ttc'),
      key: 'remain_to_be_billed_ttc',
      render: (record) => {
        const pcs_step = record?.mission_data.steps?.principal || '';
        let remain_to_be_billed_ttc =
          record?.mission_data.remain_to_be_billed_ttc;
        if (pcs_step.includes('PCS')) {
          remain_to_be_billed_ttc =
            record?.mission_data[pcs_step]?.remain_to_be_billed_ttc;
        }
        return remain_to_be_billed_ttc
          ? `${parseFloat(remain_to_be_billed_ttc).toFixed(2)} €`
          : '0.00 €';
      },
      sorter: true
    },
    {
      title: t('invoices.columns.amount_committed_ttc'),
      key: 'amount_committed_ttc',
      render: (record) => {
        const pcs_step = record?.mission_data.steps?.principal || '';
        let amount_committed_ttc = record?.mission_data.amount_committed_ttc;
        if (pcs_step.includes('PCS')) {
          amount_committed_ttc =
            record?.mission_data[pcs_step]?.amount_committed_ttc;
        }
        return amount_committed_ttc
          ? `${parseFloat(amount_committed_ttc).toFixed(2)} €`
          : '0.00 €';
      },
      sorter: true
    },
    {
      title: t('invoices.columns.paid'),
      key: 'paid',
      render: (record) => {
        const pcs_step = record?.mission_data.steps?.principal || '';
        let paid = record?.mission_data.paid;
        if (pcs_step.includes('PCS')) {
          paid = record?.mission_data[pcs_step]?.paid;
        }
        return paid ? `${parseFloat(paid).toFixed(2)} €` : '0.00 €';
      },
      sorter: true
    },
    {
      title: t('invoices.columns.left_to_pay'),
      key: 'left_to_pay',
      render: (record) => {
        const pcs_step = record?.mission_data.steps?.principal || '';
        let left_to_pay = record?.mission_data.left_to_pay;
        if (pcs_step.includes('PCS')) {
          left_to_pay = record?.mission_data[pcs_step]?.left_to_pay;
        }
        return left_to_pay
          ? `${parseFloat(left_to_pay).toFixed(2)} €`
          : '0.00 €';
      },
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => {
        const type = ['PCS_1', 'PCS_2', 'PCS_3'].includes(
          record?.mission_data?.type
        )
          ? 'PCS'
          : record?.mission_data?.type?.toLowerCase();
        return (
          <Space size="middle">
            <Link
              to={`/missions/missions-view/show/${type}/${record.mission_data._id}/${record.mission_data.type}`}
              state={[record]}
            >
              <EyeOutlined style={{ fontSize: 18, color: 'black' }} />
            </Link>
            <Link to={`/invoices/edit/${record._id.mission}`}>
              <Tooltip title={t('invoices.tooltips.assets')}>
                <FileSyncOutlined style={{ fontSize: 18, color: 'black' }} />
              </Tooltip>
            </Link>
          </Space>
        );
      }
    }
  ];
};
