import Search from 'antd/es/input/Search';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Flex } from 'antd';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * @component
 *  Renders extra buttons for the Mission Kanban view.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setMissionType - The function to set the mission type.
 * @param {Function} props.setSyndic - The function to set the syndic.
 * @param {Function} props.setSearchValue - The function to set the search value.
 * @returns {JSX.Element} The rendered MissionKanbanExtraButtons component.
 */
export const MissionKanbanExtraButtons = ({
  setMissionType,
  setSyndic,
  setSearchValue
}) => {
  const { t } = useTranslation();
  const [syndicOptions, setSyndicOptions] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const [missionTypes, setMissionTypes] = useState([]);

  const getMissionTypes = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/missions/enums?select=types'
    });
    setMissionTypes(data);
  };

  const getSyndics = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/syndic?fields=society_name'
    });
    const options = data.map(({ _id, society_name }) => ({
      label: society_name,
      value: _id
    }));
    setSyndicOptions(options);
  };

  useEffect(() => {
    getMissionTypes();
    getSyndics();
  }, []);

  const missionOptions = missionTypes.map((type) => ({
    label: t(`missions.tags.${type}`),
    value: type
  }));

  const onSearch = (value) => {
    if (value === '') {
      return setSearchValue(null);
    }
    return setSearchValue(value);
  };
  return (
    <Flex gap={8}>
      <Select
        options={missionOptions}
        style={{ minWidth: '200px' }}
        defaultValue="PCS_1"
        placeholder={t('placeholder.mission')}
        onChange={setMissionType}
      />
      <Select
        options={syndicOptions}
        allowClear
        style={{ minWidth: '200px' }}
        onChange={setSyndic}
        placeholder={t('placeholder.syndic')}
        showSearch
      />
      <Search
        placeholder={t('placeholder.search')}
        onSearch={onSearch}
        allowClear
        style={{ width: 200 }}
      />
    </Flex>
  );
};

MissionKanbanExtraButtons.propTypes = {
  setMissionType: PropTypes.func.isRequired,
  setSyndic: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired
};
