import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { DisbursementForm } from '../forms/DisbursementForm';

/**
 * DisbursementModal component.
 *
 * @component
 * @returns {JSX.Element} The DisbursementModal component
 */
export const DisbursementModal = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useMissionContext();
  const { t } = useTranslation();

  return (
    <Modal
      title={t('missions.modals.titles.disbursement')}
      onCancel={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          disbursement: {
            open: false,
            type: ''
          }
        }))
      }
      open={isDrawerOpen.disbursement.open}
      footer={null}
      width={1200}
      bodyStyle={{ padding: '24px', maxHeight: '80vh', overflowY: 'auto' }}
    >
      <DisbursementForm />
    </Modal>
  );
};
