import { Row, Typography, Col, Skeleton, Flex } from 'antd';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Mission } from '../../../../models/Mission';
import { User } from '../../../../models/User';
import StatisticLawyerDetail from './components/StatisticLawyerDetail';

const { Title } = Typography;

/**
 * StatisticLawyerDetails Component
 *
 * This component fetches and displays the statistics related to a lawyer's missions.
 * It displays these statistics in a series of rows, each containing a title and the corresponding
 * detailed statistics.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <StatisticLawyerDetails />
 */
const StatisticLawyerDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [missions, setMissions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const documentsData = User.formatLawyerStatisticsMissions(missions);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await Mission.getResources({
        extraQuery: `lawyer=${id}`
      });
      setMissions(data);
      setIsLoading(false);
    })();
  }, [id]);

  return (
    <Skeleton loading={isLoading} style={{ margin: '0px' }} active>
      <Flex gap={16} vertical>
        {documentsData &&
          documentsData.map((documents) => (
            <Row gutter={16} key={documents.title}>
              <Col lg={3}>
                <Title level={3}>
                  {t(`users.show.cards.titles.${documents?.title}`)}
                </Title>
              </Col>
              {documents.documentData.map((item) => (
                <StatisticLawyerDetail item={item} key={item.title} t={t} />
              ))}
            </Row>
          ))}
      </Flex>
    </Skeleton>
  );
};

export default StatisticLawyerDetails;
