import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReassignModalForm } from './ReassignModalForm';

/**
 * ReassignModal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isReassignModalOpen - Flag indicating if the reassign modal is open.
 * @param {function} props.setIsReassignModalOpen - Function to set the state of the reassign modal.
 * @param {Array} props.selectedMissions - Array of selected collective ownerships.
 * @param {Array} props.syndics - Array of syndics.
 * @param {function} props.setForceRefresh - Function to set the state of force refresh.
 * @param {boolean} props.forceRefresh - Flag indicating if a force refresh is needed.
 * @param {function} props.setSelectedRowKeys - Function to set the state of selected row keys.
 * @param {function} props.setSelectedMissions - Function to set the state of selected collective ownerships.
 * @returns {JSX.Element} The ReassignModal component.
 */

export const ReassignModal = ({
  isReassignModalOpen,
  setIsReassignModalOpen,
  selectedMissions,
  setForceRefresh,
  forceRefresh,
  setSelectedRowKeys,
  setSelectedMissions
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t(
        `missions.modals.titles.reassign_mission${
          selectedMissions.length > 1 ? 's' : ''
        }`
      )}
      open={isReassignModalOpen}
      onOk={() => setIsReassignModalOpen(false)}
      onCancel={() => setIsReassignModalOpen(false)}
      footer={null}
    >
      <ReassignModalForm
        selectedMissions={selectedMissions}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        setIsReassignModalOpen={setIsReassignModalOpen}
        setSelectedRowKeys={setSelectedRowKeys}
        setSelectedMissions={setSelectedMissions}
      />
    </Modal>
  );
};

ReassignModal.propTypes = {
  isReassignModalOpen: PropTypes.bool.isRequired,
  setIsReassignModalOpen: PropTypes.func.isRequired,
  selectedMissions: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  setSelectedRowKeys: PropTypes.func.isRequired,
  setSelectedMissions: PropTypes.func.isRequired
};

ReassignModal.defaultProps = {
  forceRefresh: false
};
