import { useState, useEffect } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';
import { SpfDepartmentalArchive } from '../../../models/SpfDepartmentalArchive';
import { ExtraButtons } from '../utils/ExtraButtons';

/**
 * Renders a list of municipalities with custom actions and search functionality.
 * @returns {JSX.Element} The rendered ListMunicipalities component.
 */
export const ListMunicipalities = () => {
  const { duplicateMunicipalities } = useConfigurationContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [spfDepartmentalArchives, setSpfDepartmentalArchives] = useState([]);
  const columns = useColumns({
    forceRefresh,
    setForceRefresh,
    spfDepartmentalArchives
  });

  useEffect(() => {
    (async () => {
      const spfDepartmentalArchivesDatas =
        await SpfDepartmentalArchive.getResources();
      setSpfDepartmentalArchives(spfDepartmentalArchivesDatas);
    })();
  }, [forceRefresh]);

  return (
    <ListResource
      resourceName="municipalities"
      columns={columns}
      forceRefresh={forceRefresh}
      populate="SPF_3233"
      resourceModelName="Municipality"
      customActionColumn
      searchBarExtraButtons={
        <ExtraButtons
          duplicatesArray={duplicateMunicipalities}
          resource="municipalities"
        />
      }
    />
  );
};
