import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns an array of columns for the payment table.
 * Each column object contains the title, key, dataIndex, and render function.
 *
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('payments.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => (date ? dayjs(date).format('DD-MM-YYYY') : '-')
    },
    {
      title: t('payments.form.bank_account'),
      key: 'bank_account',
      dataIndex: 'bank_account',
      render: (bank_account) => bank_account || '-'
    },
    {
      title: t('payments.form.total_ht'),
      key: 'total_ht',
      dataIndex: 'total_ht',
      render: (total_ht) =>
        total_ht ? `${parseFloat(total_ht).toFixed(2)} €` : '0.00 €'
    },
    {
      title: t('payments.form.tva'),
      key: 'tva',
      dataIndex: 'tva',
      render: (tva) => (tva ? `${parseFloat(tva).toFixed(2)} €` : '0.00 €')
    },
    {
      title: t('payments.form.disbursement'),
      key: 'disbursement',
      dataIndex: 'disbursement',
      render: (disbursement) =>
        disbursement ? `${parseFloat(disbursement).toFixed(2)} €` : '0.00 €'
    },
    {
      title: t('payments.form.lawyer_fee_ht'),
      key: 'lawyer_fee_ht',
      dataIndex: 'lawyer_fee_ht',
      render: (lawyer_fee_ht) =>
        lawyer_fee_ht ? `${parseFloat(lawyer_fee_ht).toFixed(2)} €` : '0.00 €'
    }
  ];
};
