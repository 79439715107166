import dayjs from 'dayjs';
import { BaseModel } from './BaseModel';

/**
 * Represents an invoice.
 * @class
 */
export class Invoice extends BaseModel {
  static resourceName = 'invoices';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Invoice.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Pay all invoices.
   *
   * @param {Object} options - The options for paying all invoices.
   * @param {Array} options.invoices - The list of invoices to be paid.
   * @returns {Promise<void>} - A promise that resolves when all invoices are paid.
   */
  static async payAllInvoices({ invoices }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(invoices));
    await this.dispatchAPI('PATCH', {
      url: `${Invoice.resourceName}/pay-all`,
      body: formData
    });
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Invoice.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Retrieves resources grouped by consultant.
   * @function
   * @static
   * @async
   * @param {Object} params - The parameters for the request.
   * @param {string} [params.populate=''] - Comma-separated list of fields to populate.
   * @param {string} [params.extraQuery=''] - Additional query parameters to append to the URL.
   * @returns {Promise<Object>} The data returned from the API.
   */
  static async getResourcesByConsultant({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Invoice.resourceName}/by-consultant?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Generates payment follow-ups based on the given parameters.
   * @function
   * @static
   * @async
   * @param {Object} params - The parameters for the request.
   * @param {string} [params.populate=''] - Comma-separated list of fields to populate.
   * @param {string} [params.extraQuery=''] - Additional query parameters to append to the URL.
   * @returns {Promise<Object>} The data returned from the API.
   */
  static async getPaymentFollowUps({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Invoice.resourceName}/generate-payment-follow-ups?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Sets the default values for creating or updating an invoice.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {Object} options.state - The state object.
   * @param {Function} options.setSelectedSyndic - The function to set the selected syndic.
   * @param {Function} options.setSelectedCollectiveOwnership - The function to set the selected collective ownership.
   * @param {Function} options.setSelectedMission - The function to set the selected mission.
   * @param {Object} options.form - The form object.
   * @returns {void}
   */
  static setCreateUpdateDefaultValues({
    state,
    setSelectedSyndic,
    setSelectedCollectiveOwnership,
    setSelectedMission,
    form,
    type,
    setTotalTTC
  }) {
    setSelectedSyndic(state?.syndic_id);
    setSelectedCollectiveOwnership(state?.collective_ownership_id);
    setSelectedMission(state?.mission);
    form.setFieldsValue({
      mission: state?.mission?._id,
      syndic: state?.syndic_id,
      collective_ownership: state?.collective_ownership_id,
      sending_date: dayjs(),
      total_ht: state?.total_ht,
      disbursement: state?.disbursement,
      tva_percentage: state?.tva_percentage,
      lawyer_fee_ht: state?.lawyer_fee_ht,
      notary_fee_ttc: state?.notary_fee_ttc,
      orders: (state?.orders || []).reduce((acc, current) => {
        acc[current._id] = current;
        return acc;
      }, {})
    });
    this.handleCreateUpdateTotalTTC({ form, type, setTotalTTC });
  }

  /**
   * Calculates and updates the total TTC (Toutes Taxes Comprises) values based on the provided form data.
   * @function
   * @param {Object} options - The options object.
   * @param {FormInstance} options.form - The form instance containing the field values.
   * @param {string} options.type - The type of invoice.
   * @param {Function} options.setTotalTTC - The function to set the total TTC values.
   * @returns {void}
   */
  static handleCreateUpdateTotalTTC({ form, type, setTotalTTC, invoiceId }) {
    const tva =
      form.getFieldValue(
        invoiceId
          ? ['credit_notes', invoiceId, 'tva_percentage']
          : 'tva_percentage'
      ) || 0;

    const values = invoiceId
      ? form.getFieldValue('credit_notes')
      : form.getFieldsValue();

    const totalDisbursement = Object.values(values.orders || {}).reduce(
      (acc, current) => acc + (current.to_bill || 0),
      0
    );

    const calculateTTC = (amount) => (amount ? amount * (1 + tva / 100) : 0);

    const udpatedValues = {
      cancellation_fee: calculateTTC(
        invoiceId ? values[invoiceId].cancellation_fee : values.cancellation_fee
      ),
      total_ht: calculateTTC(
        invoiceId ? values[invoiceId].total_ht : values.total_ht
      ),
      lawyer_fee_ht: calculateTTC(
        invoiceId ? values[invoiceId].lawyer_fee_ht : values.lawyer_fee_ht
      ),
      notary_fee_ttc: invoiceId
        ? values[invoiceId].notary_fee_ttc
        : values.notary_fee_ttc,
      disbursement: calculateTTC(
        invoiceId ? values[invoiceId].disbursement : values.disbursement
      )
    };

    const keyToAggregate = [
      'cancellation_fee',
      'total_ht',
      'lawyer_fee_ht',
      'disbursement',
      'notary_fee_ttc'
    ];

    const totalBilled = keyToAggregate.reduce((acc, key) => {
      if (udpatedValues[key]) {
        if (key === 'cancellation_fee') {
          return acc - udpatedValues[key];
        }
        return acc + udpatedValues[key];
      }
      return acc;
    }, 0);

    udpatedValues.total_billed_show =
      type === 'CREDIT_NOTE' ? -totalBilled.toFixed(2) : totalBilled.toFixed(2);
    udpatedValues.total_billed =
      type === 'CREDIT_NOTE' ? -totalBilled : totalBilled;

    if (invoiceId) {
      setTotalTTC({
        [invoiceId]: {
          cancellation_fee: udpatedValues.cancellation_fee.toFixed(2),
          total_ht: udpatedValues.total_ht.toFixed(2),
          lawyer_fee_ht: udpatedValues.lawyer_fee_ht.toFixed(2),
          disbursement: udpatedValues.disbursement.toFixed(2),
          total_billed: udpatedValues.total_billed + totalDisbursement,
          total_billed_show: (
            Number(udpatedValues.total_billed_show) + totalDisbursement
          ).toFixed(2)
        }
      });
    } else {
      setTotalTTC({
        cancellation_fee: udpatedValues.cancellation_fee.toFixed(2),
        total_ht: udpatedValues.total_ht.toFixed(2),
        lawyer_fee_ht: udpatedValues.lawyer_fee_ht.toFixed(2),
        total_billed: udpatedValues.total_billed + totalDisbursement,
        total_billed_show: (
          Number(udpatedValues.total_billed_show) + totalDisbursement
        ).toFixed(2)
      });
    }
  }
}
