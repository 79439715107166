import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../../utils/generateFormItem/generateFormItem';
import { useNotesFields } from '../fields/notes';
import { CustomActionButtons } from '../../../../components/CreateUpdateContainer/CustomActionButtons';

/**
 * The `NotesForm` component is designed for collecting and managing notes or comments within a larger form structure.
 * It utilizes dynamic field generation to create a form section dedicated to notes, leveraging custom hooks for field definitions and utilities for form item generation.
 *
 * Props:
 * @prop {function} onFinish - Callback function to be called upon completion of the form, typically used to handle form data processing or navigation.
 * @prop {function} onSubmit - Callback function to be called upon submission of the form, which may involve data validation or API calls.
 * @prop {Object} [initialValues={}] - Initial values for the form fields, used to pre-populate the form when editing existing data.
 * @prop {Object} form - Instance of the form provided by Ant Design's Form component, required for form control and value setting.
 * @prop {function} setCurrent - Function to set the current step in a multi-step form process, used for navigating between different sections of a larger form.
 * @prop {number} [current=0] - The current step in a multi-step form process, indicating the position of the `NotesForm` within the overall form structure.
 *
 * The component initializes by using the `useGenerateFormItem` hook to dynamically generate form fields based on the configurations provided by the `useNotesFields` hook.
 * It employs a `useEffect` hook to listen for changes to `initialValues` and updates the form values accordingly, ensuring that the form is correctly pre-populated when editing existing notes.
 * The `CustomActionButtons` component is included at the bottom of the form to provide navigation and submission controls, with properties indicating the presence of previous and next buttons for step navigation.
 *
 * Usage:
 * This component is intended for use within forms that require a dedicated section for notes or comments, such as in applications for managing collective ownerships, projects, or any context where notes need to be collected and managed.
 * It offers a structured approach to including a notes section in forms, with support for dynamic field generation and multi-step form navigation.
 */

export const NotesForm = ({
  onFinish,
  onSubmit,
  initialValues,
  form,
  setCurrent,
  current
}) => {
  const generateFields = useGenerateFormItem();
  const { notesFields } = useNotesFields();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <>
      {notesFields.map((field) =>
        generateFields('collective-ownerships', field)
      )}
      <CustomActionButtons
        onFinish={onFinish}
        onSubmit={onSubmit}
        setCurrent={setCurrent}
        current={current}
        previousButton
        nextButton
      />
    </>
  );
};

NotesForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  current: PropTypes.number,
  initialValues: PropTypes.shape({}),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  enums: PropTypes.shape({})
};

NotesForm.defaultProps = {
  initialValues: {},
  enums: {},
  current: 0
};
