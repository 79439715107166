import { useGlobalContext } from '../../../../../../contexts/GlobalContext';

/**
 * Custom hook for managing configuration data in the ComplianceElanRouter Publication component.
 *
 * @param {Object} options - The options object.
 * @param {Function} options.setInitialValues - The function to set the initial values.
 * @param {Object} options.customFormData - The custom form data object.
 * @param {Array} options.draggerFilesList - The list of dragger files.
 * @param {Function} options.setDraggerFilesList - The function to set the dragger files list.
 * @param {Function} options.setFilesConfiguration - The function to set the files configuration.
 * @param {Array} options.filesConfiguration - The files configuration array.
 * @param {Object} options.initialValues - The initial values object.
 * @param {Function} options.setCurrent - The function to set the current value.
 * @returns {Object} - The object containing the resource functions.
 */
export const useConfig = ({
  setInitialValues,
  customFormData,
  draggerFilesList,
  setDraggerFilesList,
  setFilesConfiguration,
  filesConfiguration,
  initialValues,
  setCurrent
}) => {
  const { currentSyndic } = useGlobalContext();

  return {
    onGetResource: {
      setFields: (data) => {
        setCurrent(data.draft_step);

        const draggerDocuments = data.documents.filter(
          (document) => document.source === 'dragger'
        );
        const updatedUpdate = [];
        draggerDocuments.forEach((document) => {
          document.files.forEach((file) =>
            updatedUpdate.push({
              ...file,
              fileKey: document.fileKey,
              name: file.metadata.originalName
            })
          );
        });

        setDraggerFilesList(updatedUpdate);

        const updatedConfiguration = [];
        draggerDocuments.forEach((document) => {
          document.files.forEach((file) =>
            updatedConfiguration.push({
              fileKey: document.fileKey,
              name: file.metadata.originalName,
              source: document.source
            })
          );
        });
        setFilesConfiguration(updatedConfiguration);
        const newData = {
          ...data,
          syndic: currentSyndic
        };

        setInitialValues(newData);
        return newData;
      }
    },
    onCreateResource: {
      setBody: (data) => {
        draggerFilesList.forEach((file) => {
          customFormData.append(`documents`, file);
        });
        customFormData.append(
          'filesConfiguration',
          JSON.stringify(filesConfiguration)
        );
        return {
          ...data,
          ...initialValues,
          syndic: currentSyndic,
          requested_mission_type: 'PCS_3'
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        const filteredDraggerFilesList = draggerFilesList.filter(
          (file) => !file.fileKey
        );

        const filteredByConfiguration = filesConfiguration.filter(
          (fileConfig) =>
            filteredDraggerFilesList.some(
              (file) => fileConfig.name === file.name
            ) || fileConfig.source === 'field'
        );
        draggerFilesList.forEach((file) => {
          customFormData.append(`documents`, file);
        });
        customFormData.append(
          'filesConfiguration',
          JSON.stringify(filteredByConfiguration)
        );

        return {
          ...data,
          ...initialValues,
          syndic: currentSyndic,
          requested_mission_type: 'PCS_3'
        };
      }
    }
  };
};
