/**
 * Formats the address based on the provided parameters.
 *
 * @param {string} name - The name associated with the address.
 * @param {object} first_address - The first address object containing the street information.
 * @param {object} first_city - The first city object containing the city name.
 * @returns {string} The formatted address.
 */
export const formatAddress = (name, first_address, first_city) => {
  let formattedAddress = '';
  if (name) {
    formattedAddress += `${name} - `;
  }
  if (first_address?.street) {
    formattedAddress += first_address.street;
  }
  if (first_address?.postal_code) {
    formattedAddress += ` ${first_address.postal_code}`;
  }
  if (first_city?.name) {
    formattedAddress += ` ${first_city.name}`;
  }
  return formattedAddress;
};
