import { useCallback, useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { ArchivistNotary } from '../../../models/ArchivistNotary';

/**
 * Component for creating or updating a notary configuration.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the configuration.
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateNotaryConfiguration = ({ purpose }) => {
  const [form] = Form.useForm();
  const { resource } = useParams();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [archivistNotaries, setArchivistNotaries] = useState([]);
  const fields = useFields({ isFieldsLoading, purpose, archivistNotaries });

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    const archivistNotariesDatas = await ArchivistNotary.getResources(
      '',
      'fields=office_name'
    );
    setArchivistNotaries(archivistNotariesDatas);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <CreateUpdateContainer
      customFormInstance={form}
      fields={fields[resource]}
      purpose={purpose}
      baseUrl={resource}
      resource={resource}
      withFilesManager={false}
      withEnums={false}
    />
  );
};

CreateUpdateNotaryConfiguration.propTypes = {
  purpose: PropTypes.string.isRequired
};
