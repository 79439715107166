import { useRef, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setEditInput } from './setEditInput';

/**
 * EditableCell component for adding columns to the document repository.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.editing - Flag indicating if the cell is in editing mode.
 * @param {string} props.dataIndex - The data index of the cell.
 * @param {string} props.title - The title of the cell.
 * @param {string} props.inputType - The input type of the cell.
 * @param {Object} props.record - The record object.
 * @param {Function} props.setEditingKey - Function to set the editing key.
 * @param {string} props.editingKey - The editing key.
 * @param {Function} props.setForceRefresh - Function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - Flag indicating if the component should force refresh.
 * @param {Object} props.form - The form object.
 * @param {number} props.index - The index of the cell.
 * @param {Object} props.selectOptions - The select options object.
 * @param {string} props.selectType - The select type.
 * @param {string} props.resource - The resource name.
 * @param {ReactNode} props.children - The children of the component.
 * @param {string} props.customUrl - The custom URL.
 * @returns {JSX.Element} The rendered EditableCell component.
 */

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  setEditingKey,
  editingKey,
  setForceRefresh,
  forceRefresh,
  form,
  index,
  selectOptions,
  selectType,
  resource,
  children,
  customUrl,
  ...restProps
}) => {
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (editing) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      if (selectRef.current) {
        selectRef.current.focus();
      }
    }
  }, [editing]);

  const inputNode = setEditInput({
    inputType,
    selectRef,
    selectType,
    setEditingKey,
    setForceRefresh,
    forceRefresh,
    record,
    form,
    selectOptions,
    inputRef,
    resource,
    t,
    customUrl
  });

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  inputType: PropTypes.string,
  record: PropTypes.shape({}),
  setEditingKey: PropTypes.func,
  editingKey: PropTypes.string,
  form: PropTypes.shape({}),
  index: PropTypes.number,
  children: PropTypes.node,
  setForceRefresh: PropTypes.func,
  forceRefresh: PropTypes.bool,
  selectOptions: PropTypes.shape({}),
  selectType: PropTypes.string,
  resource: PropTypes.string,
  customUrl: PropTypes.string
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  title: '',
  inputType: '',
  record: {},
  setEditingKey: () => {},
  editingKey: '',
  form: {},
  index: 0,
  children: null,
  setForceRefresh: () => {},
  forceRefresh: false,
  selectOptions: {},
  selectType: '',
  resource: '',
  customUrl: null
};
