import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../../components';
import { ListPhaseOneInvoices } from './ListPhaseOneInvoices';

/**
 * EventRouter component
 * @returns {object} - The UI of the BillsPhaseOneRouter component
 */
export const PhaseOneInvoicesRouter = () => (
  <Routes>
    <Route path="/create" element={<Exception />} />
    <Route path="/edit/:id" element={<Exception />} />
    <Route path="/show/:id" element={<Exception />} />
    <Route index element={<ListPhaseOneInvoices />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
