import React from 'react';
import styled from 'styled-components';
import { Badge, Flex, Card } from 'antd';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { updateHistory } from '../../utils/updateHistory';

const StyledCol = styled.div`
  height: 600px;
  border-radius: 2px;
  min-width: 300px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-right: 1px solid white;
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--primaryColor);
  box-shadow: 0px 9px 16px -6px var(--opacity25);
  border-radius: 2px;
  width: 100%;
  text-align: left;
  padding: 12px 10px;
  min-height: 40px;
  z-index: 1;
`;
/**
 * Renders a kanban column.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The resource name.
 * @param {Object} props.column - The column to render.
 * @param {Object} props.data - The data to render.
 * @param {boolean} props.display - The display status.
 * @param {boolean} props.refresh - The refresh status.
 * @param {Function} props.setRefresh - The setRefresh function.
 * @param {Node} props.children - The children to render.
 * @param {string} props.extraRoute - The extra route.
 * @returns {JSX.Element} The rendered KanbanColumn component.
 */
export const KanbanColumn = ({
  resourceName,
  column,
  data,
  display,
  setRefresh,
  refresh,
  children,
  extraRoute,
  isDraggable = false
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const updateCard = async (id, status) => {
    try {
      if (!isDraggable) return;
      const formData = new FormData();
      formData.append('values', JSON.stringify({ status }));
      await dispatchAPI('PATCH', {
        url: `${resourceName}${extraRoute ? `/${extraRoute}` : ''}/${id}`,
        body: formData
      });
      updateHistory({
        dispatchAPI,
        id,
        resourceName,
        values: {
          date: new Date(),
          type: 'mission_validation',
          by: user._id,
          step: status
        }
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const [, drop] = useDrop({
    accept: 'card',
    type: 'card',
    drop: async (item) => {
      try {
        await updateCard(item.id, column.status);
      } catch (e) {
        message(e);
      }
      return {};
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    display && (
      <StyledCol ref={drop}>
        <Title>
          <Badge
            dot={false}
            color="transparent"
            text={column.title}
            style={{ color: '#fff' }}
          />
        </Title>
        <Flex style={{ width: '100%', overflowY: 'auto' }} vertical gap={8}>
          {data ? (
            data
              .filter(({ status }) => status === column.status)
              .map((item) => (
                <>
                  {React.Children.map(children, (child) =>
                    React.cloneElement(child, { data: item, column })
                  )}
                </>
              ))
          ) : (
            <Card loading />
          )}
        </Flex>
      </StyledCol>
    )
  );
};

KanbanColumn.propTypes = {
  resourceName: PropTypes.string.isRequired,
  column: PropTypes.shape({
    _id: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  data: PropTypes.shape([]).isRequired,
  display: PropTypes.bool,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  extraRoute: PropTypes.string,
  isDraggable: PropTypes.bool
};

KanbanColumn.defaultProps = {
  display: true,
  extraRoute: undefined,
  isDraggable: false
};
