/**
 * Handles the document type based on the given input.
 * @function
 * @param {string} el - The input value representing the document type.
 * @returns {string|null} - The corresponding document type or null if no match found.
 */
export const handleDocumentType = (el) => {
  switch (el) {
    case '1':
      return 'Cerfa-document';
    case '3':
      return 'Various-document';
    default:
      return null;
  }
};
