import { Select, Form, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGlobalContext } from '../../contexts/GlobalContext';

const { Option } = Select;

/**
 * Renders a form with three Select components for filtering syndics, cerfa documents and concerned SPFs.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.cerfaDocuments- The array of cerfa documents.
 * @param {Array} props.concernedSPFs - The array of concerned SPFs.
 * @returns {JSX.Element} - The rendered form.
 */
export const ExtraSearchBars = ({
  setFilters,
  cerfaDocuments,
  concernedSPFs,
  enums,
  showOrdersOrdered,
  location
}) => {
  const [form] = Form.useForm();
  const { syndics, isDataLoading } = useGlobalContext();
  const { t } = useTranslation();

  /**
   * Filters the options based on the input value.
   * @function
   * @param {string} input - The input value.
   * @param {Object} option - The option object.
   * @returns {boolean} - Whether the option should be displayed or not.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  if (!showOrdersOrdered) {
    form.setFieldValue('status', 'EXPECTED');
  }

  return (
    <Form form={form} layout="inline">
      <Form.Item name={['syndics']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(syndic) => {
            setFilters((filters) => ({
              ...filters,
              options: {
                ...filters.options,
                'mission.syndic._id': syndic || ''
              }
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.select_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {syndic.society_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['cerfa_documents']}>
        <Select
          style={{ minWidth: 200 }}
          defaultValue={
            location?.state?.wording ? location.state.wording : null
          }
          onChange={(cerfa_document) => {
            setFilters((filters) => ({
              ...filters,
              options: {
                ...filters.options,
                'cerfa_document_type.wording': cerfa_document || ''
              }
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.select_document_type')}
        >
          {(cerfaDocuments || []).map((cerfa_document) => (
            <Option key={cerfa_document._id} value={cerfa_document.wording}>
              {cerfa_document.wording}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['concerned_SPFs']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(concerned_SPF) => {
            setFilters((filters) => ({
              ...filters,
              options: {
                ...filters.options,
                'concerned_SPF.name': concerned_SPF || ''
              }
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.select_concerned_SPF')}
        >
          {(concernedSPFs || []).map((concerned_SPF) => (
            <Option key={concerned_SPF._id} value={concerned_SPF.name}>
              {concerned_SPF.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {showOrdersOrdered && (
        <>
          <Form.Item name={['status']}>
            <Select
              style={{ minWidth: 200 }}
              defaultValue={
                location?.state?.['document.status']
                  ? t(`missions.tags.${location?.state?.['document.status']}`)
                  : null
              }
              onChange={(status) => {
                setFilters((filters) => ({
                  ...filters,
                  options: {
                    ...filters.options,
                    'document.status': status || ''
                  }
                }));
              }}
              showSearch
              allowClear
              loading={isDataLoading}
              filterOption={filterOption}
              placeholder={t('placeholder.select_document_status')}
            >
              {(enums?.docEnums?.status || []).map((status) => (
                <Option key={status} value={status}>
                  {t(`missions.tags.${status}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['request_date']}>
            <DatePicker
              onChange={(request_date) =>
                setFilters((filters) => ({
                  ...filters,
                  options: {
                    ...filters.options,
                    request_date: request_date?.toDate() || ''
                  }
                }))
              }
              format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
              placeholder={t('orders.form.request_date')}
              needConfirm
            />
          </Form.Item>
        </>
      )}
    </Form>
  );
};

ExtraSearchBars.propTypes = {
  setFilters: PropTypes.func,
  cerfaDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  concernedSPFs: PropTypes.arrayOf(PropTypes.shape({})),
  enums: PropTypes.shape({
    docEnums: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.string)
    })
  }),
  showOrdersOrdered: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      wording: PropTypes.string,
      'document.status': PropTypes.string
    })
  })
};

ExtraSearchBars.defaultProps = {
  setFilters: null,
  cerfaDocuments: [],
  concernedSPFs: [],
  enums: null,
  showOrdersOrdered: false,
  location: {}
};
