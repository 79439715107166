import { DatePicker, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom hook that returns an array of field objects for activity monitoring form.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Array} options.users - The array of user objects.
 * @param {Array} options.syndics - The array of syndic objects.
 * @param {Array} options.managers - The array of manager objects.
 * @param {Array} options.actionTypes - The array of action types.
 * @param {Function} options.setSelectedSyndic - The function to set the selected syndic.
 * @param {boolean} options.isLoading - The loading state.
 * @returns {Array} An array of field objects.
 */
export const useFields = ({
  users,
  syndics,
  managers,
  actionTypes,
  setSelectedSyndic,
  isLoading
}) => {
  const { t } = useTranslation();

  return [
    {
      name: ['date'],
      key: 'date',
      rules: [{ required: true }],
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      name: ['user'],
      key: 'user',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          loading={isLoading}
          allowClear
        >
          {(users || []).map((user) => (
            <Option key={user._id} value={user._id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['syndic'],
      key: 'syndic',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          onChange={(value, option) => setSelectedSyndic(option.syndic)}
          loading={isLoading}
          allowClear
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id} syndic={syndic}>
              {`${syndic.society_name} - (${syndic.code})`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['manager'],
      key: 'manager',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          loading={isLoading}
          allowClear
        >
          {(managers || []).map((manager) => (
            <Option key={manager._id} value={manager._id}>
              {`${manager.first_name} ${manager.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['action_type'],
      key: 'action_type',
      rules: [{ required: true }],
      input: (
        <Select showSearch optionFilterProp="children" loading={isLoading}>
          {(actionTypes || []).map((action_type) => (
            <Option key={action_type} value={action_type}>
              {t(`activity-monitorings.actions.${action_type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['details'],
      key: 'details',
      rules: [{ required: true }],
      input: <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
    }
  ];
};
