/**
 * Array of header configurations for exporting data.
 * Each object represents a column with a label and a corresponding key in the data.
 *
 * @type {Array<Object>}
 * @property {string} label - The label for the column header.
 * @property {string} key - The key used to access the corresponding value in the data.
 */
export const headers = [
  {
    label: 'cerfa_document_type',
    key: 'cerfa_document_type.wording'
  },
  {
    label: 'ref_doc',
    key: 'ref_doc'
  },
  {
    label: 'syndic',
    key: 'mission.syndic.society_name'
  },
  {
    label: 'collective_ownership',
    key: 'mission.collective_ownership.name'
  },
  {
    label: 'mission',
    key: 'mission.type'
  },
  {
    label: 'added_date',
    key: 'added_date'
  },
  {
    label: 'concerned_SPF',
    key: 'concerned_SPF.name'
  },
  {
    label: 'costs',
    key: 'costs'
  }
];
