import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography } from 'antd';
import JoditEditor from 'jodit-react';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Text } = Typography;

/**
 * Renders a collapsible section of questions with associated documents.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.searchValue - The search value for filtering documents.
 * @param {string} props.useCase - The use case for filtering documents.
 * @returns {JSX.Element} The rendered QuestionCollapse component.
 */
export const QuestionCollapse = ({ searchValue, useCase }) => {
  const { dispatchAPI } = useAuthContext();
  const [documents, setDocuments] = useState([]);
  const keywords = searchValue ? `/search/${searchValue}` : '';

  const getDocumentTemplates = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/template-document${keywords}?type=FAQ_TEMPLATES&use_case=${useCase}&status=VALIDATED&fields=body,title`
    });
    setDocuments(data);
  };

  useEffect(() => {
    (async () => {
      await getDocumentTemplates();
    })();
  }, [searchValue]);

  const removeEditorBar = () => {
    setTimeout(() => {
      const statusBar = document.getElementsByClassName('jodit-status-bar');
      for (let i = 0; i < statusBar.length; i += 1) {
        statusBar[i].style.display = 'none';
      }
    }, 200);
  };

  const config = {
    readonly: true,
    toolbar: false,
    showPlaceholder: false,
    toolbarSticky: false,
    toolbarAdaptive: false,
    iframe: false
  };

  const itemsArray = documents.map((document) => ({
    key: document._id,
    label: <Text strong>{document.title}</Text>,
    children: <JoditEditor value={document.body} config={config} />
  }));

  return (
    <Collapse
      onChange={() => {
        removeEditorBar();
      }}
      items={itemsArray}
      accordion
      style={{ marginBottom: 16 }}
    />
  );
};

QuestionCollapse.propTypes = {
  useCase: PropTypes.string.isRequired,
  searchValue: PropTypes.string
};

QuestionCollapse.defaultProps = {
  searchValue: ''
};
