import { Row, Col, Typography, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { missionTag } from '../../../../../utils/constants/tagColors';
import { useContent } from '../../../../../utils/useContent';
import { useAuthContext } from '../../../../../contexts/AuthContext';

const { Text } = Typography;

/**
 * Renders the title for a mission.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.record - The mission record.
 * @returns {JSX.Element} The rendered title component.
 */
export const showTitle = ({ record }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { syndicName, managerName, collectiveOwnership } = useContent({
    record
  });
  const editTPermissions = ![
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER',
    'users:LAWYER'
  ].includes(user.role);

  return Object.keys(record).length ? (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col>
            <Text>{syndicName}</Text>
          </Col>
          <Col>
            <Text>{managerName}</Text>
          </Col>
          <Col>
            {editTPermissions && (
              <Link
                to={{
                  pathname: `/syndics/edit/${record?.syndic?._id}`
                }}
              >
                <EditOutlined />
              </Link>
            )}
          </Col>
        </Row>
      </Col>
      <Col>
        <Row gutter={[16, 16]}>
          <Col>
            <Text>{collectiveOwnership}</Text>
          </Col>
          <Col>
            {editTPermissions && (
              <Link
                to={{
                  pathname: `/collective-ownerships/edit/${record?.collective_ownership?._id}`
                }}
              >
                <EditOutlined />
              </Link>
            )}
          </Col>
          <Col>
            <Tag color={missionTag[record.type]}>
              {t(`missions.tags.${record.type}`, {
                defaultValue: record.type
              })}
            </Tag>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : null;
};
