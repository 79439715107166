/**
 * Generates a PDF document of a specified type for a pensioner and initiates its download.
 *
 * @async
 * @param {function} dispatchAPI - The function used for making API requests.
 * @param {function} message - A function to handle error messages or notifications.
 * @param {string} documentType - The type of document to generate.
 * @param {string} recordID - The ID of the supervision for which the document is generated.
 * @param {string} filterID - ID allowing to filter arrays in API process.
 * @param {function} t - Translation function.
 * @param {function} setRefreshData - Function to refresh the data after sending the mail.
 * @param {function} setModalVisible - Function to set the visibility of the modal.
 * @param {boolean} [printPdf=true] - Whether to print the PDF or return the Blob object.
 * @returns {Promise<void>} A Promise that resolves once the document is generated and downloaded.
 * @throws {Error} If an error occurs during the generation or download process.
 *
 * @example
 * // Usage example:
 * generateDocumentsOld(dispatchAPI, showMessage, 'someDocumentType', '12345')
 *   .catch((error) => {
 *     console.error('Error:', error);
 *   });
 */
export const generateDocumentsOld = async ({
  dispatchAPI,
  message,
  documentType,
  recordID,
  filterID,
  // tabRecordIDs,
  cerfas,
  setRefreshData,
  values,
  setModalVisible,
  printPdf = true
}) => {
  try {
    if (setModalVisible) setModalVisible(true);

    const formData = new FormData();
    if (cerfas?.length) {
      formData.append('cerfas', JSON.stringify(cerfas));
    }

    if (values) {
      formData.append('values', JSON.stringify(values));
    }

    const { headers, data } = await dispatchAPI('POST', {
      url: `template-documents/generate/${documentType}/${recordID}/${filterID}`,
      responseType: 'arraybuffer',
      body: formData
    });

    // Create a Blob object from the ArrayBuffer
    const blob = new Blob([data], { type: 'application/pdf' });

    if (printPdf) {
      // Create a URL from the Blob object
      const url = URL.createObjectURL(blob);

      // Use the URL to create a download link or display the PDF in the browser
      const link = document.createElement('a');
      link.href = url;
      link.download = headers['content-disposition'];
      link.click();

      if (setModalVisible) setModalVisible(false);
      // Clean up the URL object
      return URL.revokeObjectURL(url);
    }
    if (setRefreshData) setRefreshData((prev) => !prev);

    return blob;
  } catch (e) {
    return message(e);
  }
};
