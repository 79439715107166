import { Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListQuoteConfigurations } from './ListQuoteConfigurations';
import { CreateUpdateQuoteConfiguration } from './CreateUpdateQuoteConfiguration';

/**
 * Renders the PublicationPcsItem component.
 * @component
 * This component displays a row with two columns, each containing a Card component.
 * The first column displays a ListQuoteConfigurations component with type "PUBLICATION_PCS".
 * The second column displays a CreateUpdateQuoteConfiguration component with type "AMOUNTS".
 * @returns {JSX.Element} The rendered PublicationPcsItem component.
 */
export const PublicationPcsItem = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title={t('quote-configurations.cards.titles.publication-pcs')}>
          <ListQuoteConfigurations type="PUBLICATION_PCS" />
        </Card>
      </Col>
      <Col span={24}>
        <Card title={t('quote-configurations.cards.titles.amounts')}>
          <CreateUpdateQuoteConfiguration type="AMOUNTS" />
        </Card>
      </Col>
    </Row>
  );
};
