import { Row, Col, Typography, Button, Popconfirm } from 'antd';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { handleDeleteColumn } from './handleDeleteColumn';

const { Text } = Typography;

/**
 * Generates the title component for a column in the charging grid.
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.columnObject - The column object containing column information
 * @param {Object} params.charging_grid - The charging grid object
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Function} params.message - Function for displaying messages
 * @param {Function} params.t - Translation function
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setIsColumnModalOpen - Function for setting the column modal open state
 * @param {Function} params.setDuplicateColumn - Function for setting the duplicated column name
 * @param {Function} params.setIsFinalVersion - Function for setting the final version state
 * @returns {JSX.Element} - The generated title component
 */

export const generateColumnTitle = ({
  columnObject,
  charging_grid,
  dispatchAPI,
  message,
  t,
  setChargingGrid,
  setIsColumnModalOpen,
  setDuplicateColumn,
  setIsFinalVersion,
  resourceDatas,
  setResourceDatas,
  titleButtonsPermissions
}) => {
  const { disableDeleteButton, disableDuplicateButton } =
    titleButtonsPermissions;

  return (
    <Row gutter={[8, 8]} align="middle" justify="space-between">
      <Col>
        <Text>{columnObject.column.name}</Text>
      </Col>
      {!['Lot', 'Remarques'].includes(columnObject.column.name) &&
      !columnObject.delete_column ? (
        <Col>
          {!disableDeleteButton && (
            <Popconfirm
              title={t('charging-grids.show.messages.confirm_column_deletion')}
              onConfirm={() =>
                handleDeleteColumn({
                  columnId: columnObject.column._id,
                  charging_grid,
                  dispatchAPI,
                  message,
                  setChargingGrid,
                  setIsFinalVersion,
                  resourceDatas,
                  setResourceDatas
                })
              }
            >
              <Button type="link" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
          {!disableDuplicateButton && (
            <Button
              type="link"
              onClick={() => {
                setDuplicateColumn(columnObject.column.name);
                setIsColumnModalOpen(true);
              }}
            >
              <CopyOutlined />
            </Button>
          )}
        </Col>
      ) : null}
    </Row>
  );
};
