import { Collapse, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useItems } from './items';

/**
 * MissionMonitoring component
 * @param {Object} props Component props
 * @param {string} - pcsPreProduction
 * @constructor
 * @component MissionMonitoring
 * @return {JSX.Element} The rendered MissionMonitoring component.
 */
const MissionMonitoring = ({ ressource, forceRefresh }) => {
  ressource?.history?.sort((a, b) => new Date(b.date) - new Date(a.date));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [forceRefresh]);

  const items = useItems({ history: ressource?.history || [] });

  return (
    <Skeleton loading={isLoading}>
      <Collapse items={items} />
    </Skeleton>
  );
};

MissionMonitoring.propTypes = {
  ressource: PropTypes.shape({
    history: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  forceRefresh: PropTypes.bool.isRequired
};

export default MissionMonitoring;
