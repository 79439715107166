import { Button, Table, Form, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EditableCell } from './EditableCell';
import { EditableTableMethods } from './methods';
import { SearchResources } from '../SearchResources/SearchResources';
import { handlePageChange } from '../DataTable/Datatable';
import { DraggingRow } from './DraggingRow';
import { setRowsColors } from '../../routes/missions/pcs-pre-productions/utils/setRowsColors';

/**
 * EditableTable component.
 *
 * @component EditableTable
 * @param {Object} props - The component props.
 * @param {string} props.resource - The resource name.
 * @param {Array} props.dataSource - The data source for the table.
 * @param {Array} props.mergedColumns - The merged columns for the table.
 * @param {function} props.setSelectedRowKey - The function to set the selected row key.
 * @param {function} props.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - The flag to force refresh the table.
 * @param {string} props.type - The type of the table.
 * @param {Object} props.form - The form object.
 * @param {string} props.searchValue - The search value.
 * @param {Object} props.pagination - The pagination object.
 * @param {function} props.setPagination - The function to set the pagination object.
 * @param {function} props.setDataSource - The function to set the data source.
 * @param {boolean} props.withoutAddLineButton - The flag to show the add line button.
 * @returns {JSX.Element} The rendered EditableTable component.
 */
export const EditableTable = ({
  resource,
  dataSource,
  mergedColumns,
  setSelectedRowKey,
  setForceRefresh,
  forceRefresh,
  type,
  form,
  searchValue,
  pagination,
  setPagination,
  setDataSource,
  withoutAddLineButton,
  extraHeader,
  headerJustify,
  scroll,
  rowSelection,
  withoutHeader,
  isCustomRowClassName = false,
  withoutSearchButton
}) => {
  const { t } = useTranslation();
  const { handleAddLine, onDragEnd } = EditableTableMethods();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);
  const navigate = useNavigate();
  const { pathname } = location;

  return (
    <>
      {!withoutHeader ? (
        <Row justify={headerJustify} style={{ marginBottom: '15px' }}>
          {!withoutSearchButton ? (
            <Col span={4}>
              <SearchResources searchValue={searchValue} />
            </Col>
          ) : null}
          {extraHeader || null}
        </Row>
      ) : null}
      <Form form={form} component={false}>
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={({ active, over }) =>
            onDragEnd({ active, over, dataSource, setDataSource, resource })
          }
        >
          <SortableContext
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              tableLayout="auto"
              components={{
                body: {
                  cell: EditableCell,
                  row: DraggingRow
                }
              }}
              rowSelection={rowSelection}
              scroll={scroll}
              dataSource={dataSource}
              columns={mergedColumns}
              pagination={{ ...pagination, current: currentPage, pageSize }}
              rowClassName={(record) => {
                if (isCustomRowClassName) {
                  return setRowsColors({ record });
                }
                return null;
              }}
              onChange={(page, filters, sorters) =>
                handlePageChange({
                  page,
                  filters,
                  sorters,
                  searchValue,
                  pathname,
                  setPagination,
                  navigate
                })
              }
              onRow={(_, key) => ({
                onClick: () => {
                  setSelectedRowKey(key + (currentPage - 1) * pageSize);
                }
              })}
            />
          </SortableContext>
        </DndContext>
      </Form>
      {!withoutAddLineButton ? (
        <Button
          style={{ marginTop: '10px' }}
          type="primary"
          onClick={() =>
            handleAddLine({
              dataSource,
              setForceRefresh,
              forceRefresh,
              resource,
              type
            })
          }
        >
          {t('buttons.add_line')}
          <PlusOutlined />
        </Button>
      ) : null}
    </>
  );
};

EditableTable.propTypes = {
  resource: PropTypes.string.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  mergedColumns: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedRowKey: PropTypes.func.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  type: PropTypes.string,
  form: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  pagination: PropTypes.shape({}),
  setPagination: PropTypes.func.isRequired,
  setDataSource: PropTypes.func,
  withoutAddLineButton: PropTypes.bool,
  extraHeader: PropTypes.node,
  headerJustify: PropTypes.string,
  scroll: PropTypes.shape({}),
  rowSelection: PropTypes.shape({}),
  withoutHeader: PropTypes.bool,
  isCustomRowClassName: PropTypes.bool,
  withoutSearchButton: PropTypes.bool
};

EditableTable.defaultProps = {
  dataSource: [],
  mergedColumns: [],
  type: '',
  searchValue: '',
  pagination: {},
  setDataSource: () => {},
  withoutAddLineButton: false,
  extraHeader: null,
  headerJustify: 'space-between',
  scroll: {},
  withoutHeader: false,
  rowSelection: {},
  isCustomRowClassName: false,
  withoutSearchButton: false
};
