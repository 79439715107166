import { Col, Divider, Row, Switch, Typography } from 'antd';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Renders extra buttons for a mission.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isChecked - Indicates whether the switch is checked.
 * @param {function} props.setExtraQuery - The function to set the extra query.
 * @param {function} props.setIsChecked - The function to set the isChecked state.
 * @param {function} props.setForceRefresh - The function to set the force refresh state.
 * @returns {JSX.Element} - The rendered component.
 */
export const ExtraButtons = ({
  isChecked,
  setExtraQuery,
  setIsChecked,
  setForceRefresh
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col>
        <EditOutlined
          style={{
            color: isChecked ? 'gray' : 'black'
          }}
        />
        <Divider type="vertical" />
        <Text
          style={{
            color: isChecked ? 'gray' : 'black'
          }}
        >
          {t('prestation_word.missions.form.in_progress')}
        </Text>
      </Col>
      <Col>
        <Switch
          key="switch"
          onChange={(checked) => {
            if (checked) {
              setExtraQuery('re_written_document_status=DONE');
              setIsChecked(true);
            } else {
              setExtraQuery('re_written_document_status=IN_PROGRESS');
              setIsChecked(false);
            }
            setForceRefresh((prev) => !prev);
          }}
          defaultChecked={isChecked}
        />
      </Col>
      <Col>
        <CheckCircleOutlined
          style={{
            color: isChecked ? 'black' : 'gray'
          }}
        />
        <Divider type="vertical" />
        <Text
          style={{
            color: isChecked ? 'black' : 'gray'
          }}
        >
          {t('prestation_word.missions.form.completed')}
        </Text>
      </Col>
    </Row>
  );
};

ExtraButtons.propTypes = {
  isChecked: PropTypes.bool,
  setExtraQuery: PropTypes.func,
  setIsChecked: PropTypes.func,
  setForceRefresh: PropTypes.func
};

ExtraButtons.defaultProps = {
  isChecked: false,
  setExtraQuery: () => {},
  setIsChecked: () => {},
  setForceRefresh: () => {}
};
