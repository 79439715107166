import { Select, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * The `useAttachedProfessionalsFields` hook is designed to generate configurations for form fields related to attaching professionals, such as lawyers and consultants, to a specific entity or task. It provides options for selecting professionals from a list and for making decisions on task reassignments.
 *
 * Parameters:
 * @param {boolean} isFieldsLoading - Indicates whether the professionals' data is currently being loaded, which controls the display of a loading indicator within the select fields.
 * @param {Object} professionals - An object containing arrays of professional entities categorized by their roles (e.g., lawyers, consultants). Each professional is expected to have an `_id`, `last_name`, and `first_name`.
 *
 * Returns:
 * @returns {Object} An object containing an array of field configurations, where each configuration includes:
 * - `name`: An array representing the path to the value in the form state, used as the name for the form field.
 * - `input`: A React component configured as the input for the form field. This can be a `Select` component for choosing professionals from a provided list or a `Radio.Group` for binary choices such as task reassignment options.
 *
 * The `Select` components are configured with `showSearch` to allow users to search through the list of options, and a custom `filterOption` function to control the search behavior. The `Radio.Group` components are used for binary choices with predefined options.
 *
 * Usage:
 * This hook is intended for use in form components where professionals need to be attached to a specific entity or task, and where there may be options to reassign tasks as part of the attachment process. It simplifies the creation of form fields by abstracting the repetitive aspects of configuring select and radio inputs.
 */

export const useAttachedProfessionalsFields = ({
  isFieldsLoading,
  professionals
}) => {
  const { t } = useTranslation();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const optionRadio = [
    { key: 'YES', value: true, label: t('radio-options.yes') },
    { key: 'NO', value: false, label: t('radio-options.no') }
  ];

  const attachedProfessionalsFields = [
    {
      name: ['lawyer'],
      key: 'lawyer',
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(professionals.lawyers || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.last_name || ''} ${item.first_name || ''}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['reassign_PCS_tasks_from_stock_to_this_lawyer'],
      key: 'reassign_PCS_tasks_from_stock_to_this_lawyer',
      input: <Radio.Group options={optionRadio} />
    },
    {
      name: ['reassign_PCS_tasks_for_other_lawyer'],
      key: 'reassign_PCS_tasks_for_other_lawyer',
      input: <Radio.Group options={optionRadio} />
    },
    {
      name: ['consultant'],
      key: 'consultant',
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(professionals.consultants || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.last_name || ''} ${item.first_name || ''}`}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return { attachedProfessionalsFields };
};
