/**
 * Sets dynamic fields based on the provided parameters.
 *
 * @function
 *
 * @param {Object} options - The options object.
 * @param {Object} options.manager - The selected manager.
 * @param {Array} options.syndics - The array of syndics.
 * @param {Function} options.setSyndicOptions - The function to set syndic options.
 * @param {Object} options.syndic - The selected syndic.
 * @param {Object} options.professionals - The professionals object.
 * @param {Function} options.setManagerOptions - The function to set manager options.
 * @returns {void} - The return is void.
 */
export const setDynamicFields = ({
  manager,
  syndics,
  setSyndicOptions,
  syndic,
  professionals,
  setManagerOptions
}) => {
  if (manager) {
    const selectedSyndic = syndics.find((el) =>
      el.managers.some((obj) => obj._id === manager._id)
    );

    setSyndicOptions([selectedSyndic]);
  } else if (syndics.length) {
    setSyndicOptions(syndics);
  }

  if (syndic) {
    const selectedManagers = [];
    professionals?.managers?.forEach((item) => {
      if (syndic.managers.some((el) => el._id === item._id)) {
        selectedManagers.push(item);
      }
    });

    setManagerOptions(selectedManagers);
  } else if (professionals.managers) {
    setManagerOptions(professionals.managers);
  }
};
