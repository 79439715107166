import { BaseModel } from './BaseModel';

/**
 * Represents a predefined message.
 * @class
 */
export class PredefinedMessage {
  static resourceName = 'predefined-messages';

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources(populate = '', extraQuery = '') {
    const { data } = await BaseModel.dispatchAPI('GET', {
      url: `${PredefinedMessage.resourceName}?populate=${populate}${extraQuery}`
    });
    return data;
  }

  /**
   * Get the resource.
   * @function
   * @async
   * @param {string} id - The ID of the resource.
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResource(id, populate = '', extraQuery = '') {
    const { data } = await BaseModel.dispatchAPI('GET', {
      url: `${PredefinedMessage.resourceName}/${id}?populate=${populate}${extraQuery}`
    });
    return data;
  }
}
