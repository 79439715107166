import { createFileFromJSON } from '../../../../../components/CreateUpdateContainer/utils/createFileFromJSON';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * Custom hook for configuring the dragger functionality.
 * @returns {Object} An object containing the dragger configuration functions.
 */
export const useDraggerConfig = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const draggerOnGetResource = async ({
    data,
    setFilesList,
    setFilesConfiguration
  }) => {
    const fieldsDocuments = data.documents.filter(
      (document) => document.source === 'field'
    );
    const filesListObject = {};
    const normalizedFieldsDocuments = fieldsDocuments.map(async (document) => {
      await Promise.all(
        document.files.map(async (file) => {
          const documentObject = {
            ...document,
            rawFile: file,
            file: await createFileFromJSON(file, dispatchAPI, message)
          };
          if (filesListObject[document.fileKey]) {
            filesListObject[document.fileKey].push(documentObject);
          } else {
            filesListObject[document.fileKey] = [documentObject];
          }
          return true;
        })
      );
    });
    await Promise.all(normalizedFieldsDocuments);
    setFilesList((prev) => ({ ...prev, ...filesListObject }));

    setFilesConfiguration(
      data.documents.flatMap((document) =>
        document.files.map((file) => ({
          fileKey: document.fileKey,
          name: file.metadata.originalName,
          source: document.source,
          id: document._id,
          type: file.contentType
        }))
      )
    );
  };

  const draggerOnCreateAndUpdateCustomFormData = ({
    filesList,
    filesConfiguration,
    customFormData
  }) => {
    if (Object.keys(filesList).length) {
      Object.keys(filesList).forEach((fileKey) => {
        filesList[fileKey].forEach((file) => {
          customFormData.append(fileKey, file.file);
        });
      });
    }
    customFormData.append(
      'filesConfiguration',
      JSON.stringify(filesConfiguration)
    );
  };

  return { draggerOnGetResource, draggerOnCreateAndUpdateCustomFormData };
};
