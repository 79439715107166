/**
 * Returns the action fields map.
 * @returns {Object} The action fields map.
 */
export const useNomadeHonoraryFields = () => {
  const actionFieldsMap = {
    PUBLICATION_PCS: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    ENJOYMENT_RIGHT_TO_VALIDATED: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'right_of_use_to_be_ratified_ttc',
        label:
          'quotation-act-amounts.form.nomade_honorary.right_of_use_to_be_ratified_ttc',
        key: 'right_of_use_to_be_ratified_ttc',
        input: true
      },
      {
        name: 'enjoyment_rigth_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.enjoyment_rigth_ttc',
        key: 'enjoyment_rigth_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    CHARGING_GRID: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    LOT_ENJOYMENT_RIGHT: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'right_of_use_to_be_ratified_ttc',
        label:
          'quotation-act-amounts.form.nomade_honorary.right_of_use_to_be_ratified_ttc',
        key: 'right_of_use_to_be_ratified_ttc',
        input: true
      },
      {
        name: 'enjoyment_rigth_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.enjoyment_rigth_ttc',
        key: 'enjoyment_rigth_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    LOT_CREATION_PCS: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    SURVEYOR_QUOTATION: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    LOT_REMOVAL_PCS: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    SALE: [
      {
        name: 'provision',
        label: 'quotation-act-amounts.form.nomade_honorary.provision',
        key: 'provision',
        input: true
      },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    EXCHANGE: [
      {
        name: 'provision',
        label: 'quotation-act-amounts.form.nomade_honorary.provision',
        key: 'provision',
        input: true
      },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ],
    OTHER: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.nomade_honorary.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.nomade_honorary.extra',
        key: 'extra',
        input: true
      }
    ]
  };

  return { actionFieldsMap };
};
