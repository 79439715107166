import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { QuoteConfigurationsMethods } from '../utils/methods';
import { useConfig } from '../utils/createUpdateConfig';

/**
 * Component for creating or updating a quote configuration.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of quote configuration.
 * @returns {JSX.Element} The CreateUpdateQuoteConfiguration component.
 */
export const CreateUpdateQuoteConfiguration = ({ type }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { getLatestQuoteAmounts } = QuoteConfigurationsMethods();
  const { traditionalFields, amountFields } = useFields({});

  const fields = {
    TRADITIONAL_PUBLICATION: traditionalFields,
    AMOUNTS: amountFields
  };

  useEffect(() => {
    (async () => {
      const amounts = await getLatestQuoteAmounts({ type });
      form.setFieldsValue(amounts);
    })();
  });

  const config = useConfig({ type });

  return (
    <CreateUpdateContainer
      config={config}
      customFormInstance={form}
      fields={fields[type]}
      purpose="create"
      baseUrl="quote-configurations"
      resource="quote-configurations"
      withFilesManager={false}
      withCustomPageHeader
      withEnums={false}
      successMessage={t(
        'quote-configurations.messages.updated_amounts_success'
      )}
    />
  );
};

CreateUpdateQuoteConfiguration.propTypes = {
  type: PropTypes.string.isRequired
};
