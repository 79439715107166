import { Select, InputNumber } from 'antd';

/**
 * Hook to generate form fields for main information and address details.
 *
 * This hook creates fields for a form, including selects for syndic and manager,
 * and various input fields for other data. It uses Ant Design's `Select` and
 * `InputNumber` components for rendering select boxes and numerical inputs, respectively.
 *
 * @param {Object} params - The parameters for generating form fields.
 * @param {Object} params.enums - Enumeration values for form fields.
 * @param {boolean} params.isFieldsLoading - Indicates if the form fields are currently loading.
 * @param {function} params.setSyndic - Function to set the selected syndic.
 * @param {function} params.setManager - Function to set the selected manager.
 * @param {Array} params.managerOptions - Options for the manager select field.
 * @param {Array} params.syndicOptions - Options for the syndic select field.
 * @param {function} params.t - Translation function from react-i18next.
 *
 * @returns {Object} Returns an object containing arrays of form fields for
 *                   main information, first address, and second address.
 *
 * Usage:
 * const { informationsFields, firstAddressFields, secondAddressFields } = useMainInfoFields({
 *   enums, isFieldsLoading, setSyndic, setManager, managerOptions, syndicOptions, t
 * });
 */

const { Option } = Select;

export const useMainInfoFields = ({
  enums,
  isFieldsLoading,
  setSyndic,
  setManager,
  managerOptions,
  syndicOptions,
  form,
  t,
  setPermissions
}) => {
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const informationsFields = [
    {
      name: ['syndic'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(e, option) => {
            setSyndic(option?.syndic);
            setPermissions((prevPermissions) => ({
              ...prevPermissions,
              lawyers: [...prevPermissions.lawyers, option.syndic.lawyer]
            }));
          }}
          onClear={() => {
            form.setFieldsValue({ manager: undefined });
            setManager(null);
          }}
        >
          {(syndicOptions || []).map((item) => (
            <Option value={item._id} key={item._id} syndic={item}>
              {item.society_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['manager'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(e, option) => setManager(option?.manager)}
        >
          {(managerOptions || []).map((item) => (
            <Option value={item._id} key={item._id} manager={item}>
              {`${item.last_name || ''} ${item.first_name || ''}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['code'],
      rules: [{ required: true }]
    },
    {
      name: ['cadastral_reference'],
      rules: [{ required: true }]
    },
    {
      name: ['cadastral_reference_two']
    },
    {
      name: ['volumetric_lot']
    },
    {
      name: ['manager_code']
    },
    {
      name: ['name']
    },
    {
      name: ['registration_number']
    },
    {
      name: ['cadastral_place_or_route']
    },
    {
      name: ['capacity'],
      input: <InputNumber addonAfter="m²" />
    },
    {
      name: ['head_office']
    },
    {
      name: ['siren_number']
    },
    {
      name: ['status'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(enums.collectivestatus || []).map((item) => (
            <Option value={item} key={item}>
              {t(`collective-ownerships.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const firstAddressFields = [
    {
      name: ['first_address', 'street'],
      rules: [{ required: true }]
    },
    {
      name: ['first_address', 'additional']
    },
    {
      name: ['first_address', 'postal_code'],
      rules: [{ required: true }]
    },
    {
      name: ['first_city'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(enums.cities || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  const secondAddressFields = [
    {
      name: ['second_address', 'street']
    },
    {
      name: ['second_address', 'additional']
    },
    {
      name: ['second_address', 'postal_code']
    },
    {
      name: ['second_address', 'city']
    }
  ];

  return { informationsFields, firstAddressFields, secondAddressFields };
};
