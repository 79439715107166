import { useTranslation } from 'react-i18next';
import { DatePicker, Input, Select } from 'antd';
import { disableHours } from './utils/disableHours';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * @description useUnavailabilityFields form
 * @param {Object} props - The props object.
 * @param {Array} props.enums - The enums array.
 * @returns {Object} - The fields object.
 */
export const useUnavailabilityFields = ({ enums }) => {
  const { t } = useTranslation();
  const { disabledTime } = disableHours();
  const fields = [
    {
      name: ['unvailability_type'],
      key: 'unvailability_type',
      rules: [{ required: true }, { type: String }],
      input: (
        <Select
          style={{
            width: 200
          }}
          showSearch
          allowClear
          optionFilterProp="children"
          onSelect={(value) => value}
          placeholder={t('events.form.unvailability_type')}
        >
          {(enums || []).map((item) => (
            <Option value={item} key={item}>
              {t(`events.event_tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['title'],
      key: 'title',
      input: (
        <Input style={{ width: 200 }} placeholder={t('events.form.title')} />
      )
    },
    {
      name: ['time_slots'],
      key: 'time_slots',
      rules: [{ required: true }, { type: Object }],
      input: (
        <RangePicker
          showTime={{ format: 'DD-MM-YYYY HH:mm' }}
          onOk={(value) => value}
          disabledTime={disabledTime}
        />
      )
    }
  ];

  return { fields };
};
