import { Col, Row, Space, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Text } = Typography;

/**
 * @component
 * @description Component that renders the extra buttons for the syndic form.
 * The extra buttons are used to filter the syndics by their status.
 * @param {Function} setExtraQuery - The function to set the extra query.
 * @returns {JSX.Element} A Row component with two buttons and a switch.
 */
export const ExtraButtons = ({ setExtraQuery }) => {
  const { t } = useTranslation();

  return (
    <Row style={{ marginRight: 16 }} gutter={[16, 16]}>
      <Col>
        <Space>
          <Text>{t('syndics.form.not-archived')}</Text>
        </Space>
      </Col>
      <Col>
        <Switch
          onChange={(e) => {
            if (e.valueOf() === true) {
              setExtraQuery(`deleted=${true}`);
            } else {
              setExtraQuery('');
            }
          }}
        />
      </Col>
      <Col>
        <Space>
          <Text>{t('syndics.form.archived')}</Text>
        </Space>
      </Col>
    </Row>
  );
};

ExtraButtons.propTypes = {
  setExtraQuery: PropTypes.func.isRequired
};
