import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Form } from 'antd';
import {
  CreateUpdateContainer,
  PageHeaderCustom
} from '../../../../components';
import { useFields } from './fields';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { CollectiveOwnership } from '../../../../models/CollectiveOwnership';
import { Mission } from '../../../../models/Mission';
import { Invoice } from '../../../../models/Invoice';
import { useConfig } from '../utils/createUpdateConfig';

/**
 * Component for creating an invoice.
 * @component
 * @returns {JSX.Element} The CreateInvoice component.
 */
export const CreateInvoice = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { syndics } = useGlobalContext();
  const [selectedSyndic, setSelectedSyndic] = useState('');
  const [missions, setMissions] = useState([]);
  const [collective_ownerships, setCollectiveOwnerships] = useState([]);
  const [selectedCollectiveOwnership, setSelectedCollectiveOwnership] =
    useState('');
  const [selectedMission, setSelectedMission] = useState('');
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState('');
  const [totalTTC, setTotalTTC] = useState({
    cancellation_fee: 0,
    total_ht: 0,
    lawyer_fee_ht: 0,
    notary_fee_ttc: 0,
    total_billed: 0
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const config = useConfig({ state, totalTTC, orders: state?.orders });
  const { fields } = useFields({
    form,
    type: state?.type,
    isFieldsLoading,
    syndics,
    collective_ownerships,
    missions,
    setSelectedSyndic,
    setSelectedCollectiveOwnership,
    setSelectedMission,
    selectedSyndic,
    selectedCollectiveOwnership,
    selectedMission,
    setTotalTTC,
    totalTTC,
    orders: state?.orders,
    is_mission_cancellation_form: state?.is_mission_cancellation_form,
    invoices: state?.invoices,
    setSelectedInvoices,
    selectedInvoices,
    invoice: state?.invoice
  });

  useEffect(() => {
    Invoice.setCreateUpdateDefaultValues({
      state,
      setSelectedSyndic,
      setSelectedCollectiveOwnership,
      setSelectedMission,
      form,
      type: state?.type,
      setTotalTTC
    });
  }, [state]);

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      const dataCollectiveOwnership = await CollectiveOwnership.getResources({
        populate: 'first_city',
        extraQuery: ''
      });
      const dataMissions = await Mission.getResources({
        populate: '',
        extraQuery: ''
      });
      setCollectiveOwnerships(dataCollectiveOwnership);
      setMissions(dataMissions);
      setIsFieldsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedSyndic) {
        const data = await CollectiveOwnership.getResources({
          populate: 'first_city',
          extraQuery: `syndic._id=${selectedSyndic}`
        });
        setCollectiveOwnerships(data);
      }
    })();
  }, [selectedSyndic]);

  useEffect(() => {
    (async () => {
      if (selectedCollectiveOwnership) {
        const data = await Mission.getResources({
          populate: '',
          extraQuery: `collective_ownership=${selectedCollectiveOwnership}`
        });
        setMissions(data);
      }
    })();
  }, [selectedCollectiveOwnership]);

  useEffect(() => {
    if (selectedInvoices) {
      selectedInvoices.forEach((el) => {
        form.setFieldsValue({
          credit_notes: {
            [el._id]: {
              total_ht: el.total_ht,
              disbursement: el.disbursement,
              lawyer_fee_ht: el.lawyer_fee_ht,
              notary_fee_ttc: el.notary_fee_ttc,
              tva_percentage: el.tva_percentage
            }
          }
        });
        Invoice.handleCreateUpdateTotalTTC({
          form,
          type: state?.type,
          setTotalTTC,
          invoiceId: el._id
        });
      });
    }
  }, [selectedInvoices]);

  let title;
  switch (state?.type) {
    case 'BALANCE':
      title = t('invoices.titles.create_balance_invoice');
      break;
    case 'ADVANCE_PAYMENT':
      title = t('invoices.titles.create_advance_payment_invoice');
      break;
    case 'ASSETS':
      title = t('invoices.titles.create_assets_invoice');
      break;
    case 'REGULE':
      title = t('invoices.titles.create_regule_invoice');
      break;
    case 'CREDIT_NOTE':
      title = t('invoices.titles.create_credit_note_invoice');
      break;
    default:
      title = t('invoices.titles.create_invoice');
      break;
  }

  return (
    <>
      <PageHeaderCustom title={title} />
      <CreateUpdateContainer
        customFormInstance={form}
        config={config}
        purpose="create"
        resource="invoices"
        baseUrl={
          state.is_mission_cancellation_form
            ? 'invoices/create-cancellation-credit-notes'
            : 'invoices'
        }
        withEnums={false}
        fields={fields}
        withCustomPageHeader
        withFilesManager={false}
        customNavigate={() =>
          state.is_mission_cancellation_form
            ? navigate('/billing-and-accountings/invoices', {
                state: { missionId: state?.mission?._id }
              })
            : navigate(-1)
        }
      />
    </>
  );
};
