import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { getActualPriceColor } from './utils/getActualPriceColor';

/**
 * Renders a list of prices for a specific mission.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.missionType - The mission identifier.
 * @param {function} props.dispatchAPI - The dispatch function for API requests.
 * @param {function} props.message - The function for displaying messages.
 * @param {Object} props.enums - The object containing enums for the mission.
 * @param {function} props.t - The translation function.
 * @returns {JSX.Element} The rendered ListPrices component.
 */

export const ListPrices = ({ missionType, enums }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [actualPriceColor, setActualPriceColor] = useState({});
  const columns = useColumns({
    dispatchAPI,
    message,
    forceRefresh,
    setForceRefresh,
    enums,
    missionType,
    actualPriceColor,
    t
  });

  useEffect(() => {
    getActualPriceColor({ resourceData, setActualPriceColor });
  }, [resourceData]);

  return (
    <ListResource
      setResourceData={setResourceData}
      resourceName="price-systems"
      extraQuery={`mission_type=${missionType}`}
      columns={columns}
      resourceModelName="Price-system"
      customParams={`/${missionType}`}
      withPageHeader={false}
      customActionColumn
      forceRefresh={forceRefresh}
    />
  );
};

ListPrices.propTypes = {
  missionType: PropTypes.string.isRequired,
  enums: PropTypes.shape({})
};

ListPrices.defaultProps = {
  enums: {}
};
