import { useEffect } from 'react';
import { Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/parametersFields';
import { useConfig } from '../utils/parametersCreateUpdateConfig';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useHandleResize } from '../../../../../utils/handleResize';

/**
 * Renders the Parameters Drawer component.
 *
 * @component
 * @returns {JSX.Element} The Parameters Drawer component.
 */
export const ParametersDrawer = () => {
  const {
    mission,
    forceRefresh,
    setForceRefresh,
    isDrawerOpen,
    setIsDrawerOpen,
    isLoading,
    setSyndicInformation,
    setBillingOptionsColors,
    billingOptionsAmounts,
    setBillingOptionsAmounts
  } = useMissionContext();
  const [form] = Form.useForm();
  const { fields } = useFields({ form });
  const { t } = useTranslation();

  const config = useConfig({
    setSyndicInformation,
    setBillingOptionsColors,
    setBillingOptionsAmounts
  });
  const { width } = useHandleResize();

  useEffect(() => {
    form.setFieldsValue(billingOptionsAmounts);
  }, [billingOptionsAmounts]);

  return (
    <Drawer
      title={t('missions.drawers.titles.parameters')}
      onClose={() => {
        setIsDrawerOpen((prev) => ({
          ...prev,
          parameters: false,
          transformation_word_ok: true
        }));
      }}
      open={isDrawerOpen.parameters}
      width={width < 800 ? '80%' : '40%'}
    >
      <CreateUpdateContainer
        customFormInstance={form}
        fields={fields}
        config={config}
        isFieldsLoading={isLoading}
        purpose="edit"
        baseUrl="missions/parameters"
        resource="missions"
        extraQuery={`?collective_ownership_id=${
          mission?.collective_ownership._id
        }${mission?.type === 'PCS_2' ? '&mission_type=PCS_2' : ''}`}
        withFilesManager={false}
        withEnums={false}
        withCustomPageHeader
        layout="vertical"
        populate="collective_ownership,PCS_2.price_category"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{
          ...isDrawerOpen,
          parameters: false,
          transformation_word_ok: true
        }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
