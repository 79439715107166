import { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { UserRouter } from './UserRouter';
import { AdminRouter } from './AdminRouter';
import { DeveloperRouter } from './DeveloperRouter';
import { useThemeContext } from '../contexts/ThemeContext';
import { LawyerRouter } from './LawyerRouter';
import { PrestationWordRouter } from './PrestationWordRouter';
import { CustomerServiceManagerRouter } from './CustomerServiceManagerRouter';
import { AccountManagerRouter } from './AccountManagerRouter';
import { AssistantRouter } from './AssistantRouter';
import { BillingRouter } from './BillingRouter';
import { SyndicAssistantRouter } from './SyndicAssistantRouter';
import { SyndicManagerRouter } from './SyndicManagerRouter';
import { ProductionRouter } from './ProductionRouter';
import { SuperAdminRouter } from './SuperAdminRouter';
import { SyndicCouncilRouter } from './SyndicCouncilRouter';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
        return <AdminRouter />;
      case 'admins:SUPER-ADMIN':
        return <SuperAdminRouter />;
      case 'users:USER':
      case 'users:SUPER-USER':
      case 'users:NOMADE_PRODUCTION': // Temporary for testing
        return <ProductionRouter />;
      case 'developers:DEVELOPER':
        return <DeveloperRouter />;
      case 'users:LAWYER':
        return <LawyerRouter />;
      case 'users:PRESTATION_WORD':
        return <PrestationWordRouter />;
      case 'users:NOMADE_CUSTOMER_SERVICE_MANAGER':
        return <CustomerServiceManagerRouter />;
      case 'users:NOMADE_ACCOUNT_MANAGER':
        return <AccountManagerRouter />;
      case 'users:NOMADE_ASSISTANT':
        return <AssistantRouter />;
      case 'users:NOMADE_BILLING':
        return <BillingRouter />;
      case 'users:SYNDIC_COUNCIL':
        return <SyndicCouncilRouter />;
      case 'users:SYNDIC_ASSISTANT':
        return <SyndicAssistantRouter />;
      case 'users:SYNDIC_MANAGER':
        return <SyndicManagerRouter />;
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
