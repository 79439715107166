import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userRoles } from '../../../../utils/constants/tagColors';
import { deleteResource } from '../../../../utils/deleteResource';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Text } = Typography;
const iconSize = '18px';

/**
 * @hook
 * Custom hook that returns the columns configuration for a table.
 *
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The flag indicating whether to force refresh the table.
 * @returns {Object} - The columns configuration object.
 */
export const useColumns = ({ setForceRefresh, forceRefresh }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: ['role'],
      render: (role) =>
        role ? (
          <Tag
            style={{
              color: userRoles[role.split(':')[1]],
              borderColor: userRoles[role.split(':')[1]]
            }}
          >
            {t(`users.tags.${role.split(':')[1]}`, {
              defaultValue: role.split(':')[1]
            })}
          </Tag>
        ) : (
          'N/R'
        )
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: (email) => (email ? <Text italic>{email}</Text> : '-')
    },
    {
      title: t('users.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) =>
        phone_number?.number
          ? `${phone_number.country_code} ${phone_number?.number}`
          : '-'
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/users/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/users/edit/${record?._id}`
              }}
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Link>
          </>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() =>
              deleteResource({
                dispatchAPI,
                resourceName: 'users',
                id: record._id,
                setForceRefresh,
                forceRefresh,
                message
              })
            }
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'var(--errorColor)', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        </>
      )
    }
  ];

  return { columns };
};
