/**
 * Sets the values of PCAJP fields.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {Function} options.setShowPrincipalLotField - The function to set the show principal lot field.
 * @param {Function} options.setCurrentPcajpArray - The function to set the current PCAJP array.
 * @param {Array} options.pcajpArrays - The array of PCAJP arrays.
 * @param {Object} options.form - The form object.
 * @returns {void}
 */
export const setPcajpFieldsValues = ({
  setShowPrincipalLotField,
  setCurrentPcajpArray,
  pcajpArrays,
  form
}) => {
  const showPrincipalLotField = {};

  pcajpArrays.forEach((pcajpArray) => {
    (pcajpArray?.data || []).forEach((data) => {
      data.private_usages.forEach((privateUsage) => {
        if (privateUsage.to_validate.value === 'LOT_ENJOYMENT_RIGHT') {
          showPrincipalLotField[privateUsage._id] = true;
        }
      });
    });
  });
  setShowPrincipalLotField(showPrincipalLotField);

  setCurrentPcajpArray(pcajpArrays[pcajpArrays.length - 1]);
  pcajpArrays.forEach((pcajpArray) => {
    pcajpArray.data.forEach((data) => {
      data.private_usages.forEach((privateUsage) => {
        form.setFieldsValue({
          [data._id]: {
            private_usages: {
              [privateUsage._id]: {
                private_usage: privateUsage.private_usage.value,
                enjoyment_right: privateUsage.enjoyment_right.value,
                to_validate: privateUsage.to_validate.value,
                principal_lot_enjoyment_right:
                  privateUsage?.principal_lot_enjoyment_right?.value || null
              }
            }
          }
        });
      });
      form.setFieldsValue({
        [data._id]: {
          batch_numbers: data?.batch_numbers?.value || null,
          validation: data?.validation?.value || null,
          remarks: data?.remarks?.value || null
        }
      });
    });
  });
};
