import PropTypes from 'prop-types';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useConfig } from '../../spf-departmental-archive/MergeComponents/utils/createUpdateConfig';
import { useFields as useSpfDepartmentalArchiveFields } from '../../spf-departmental-archive/fields';
import { useFields as useMunicipalityFields } from '../../municipality/fields';
import { MergeDuplicatesMethods } from './methods';

/**
 * Custom hook for creating and updating data.
 *
 * @hook
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form instance.
 * @param {string} props.id - The ID of the data.
 * @param {boolean} props.isFieldsLoading - Indicates if the fields are loading.
 * @param {Object} props.enums - The enums object.
 * @param {boolean} props.isDuplicateForm - Indicates if the form is a duplicate.
 * @param {string} props.resource - The resource type.
 * @param {Array} props.spfDepartmentalArchives - The array of departmental archives.
 * @returns {JSX.Element} The JSX element.
 */
export const UseCreateUpdate = ({
  form,
  id,
  isFieldsLoading,
  enums,
  isDuplicateForm,
  resource,
  spfDepartmentalArchives
}) => {
  const { useFields } = MergeDuplicatesMethods();
  const { fields: spfDepartmentalArchiveFields } =
    useSpfDepartmentalArchiveFields({
      enums,
      isFieldsLoading,
      id,
      isDuplicateForm
    });
  const { fields: municipalityFields } = useMunicipalityFields({
    enums,
    isFieldsLoading,
    id,
    isDuplicateForm,
    spfDepartmentalArchives
  });

  const config = useConfig({ id });

  return (
    <CreateUpdateContainer
      customWrapperCol="10"
      customFormInstance={form}
      fields={useFields({
        resource,
        spfDepartmentalArchiveFields,
        municipalityFields
      })}
      config={config}
      customId={id}
      purpose="edit"
      baseUrl={resource}
      resource={resource}
      withFilesManager={false}
      withEnums={false}
      withCustomPageHeader
      withCustomButtons
    />
  );
};

UseCreateUpdate.propTypes = {
  form: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  isFieldsLoading: PropTypes.bool.isRequired,
  enums: PropTypes.shape({}).isRequired,
  isDuplicateForm: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  spfDepartmentalArchives: PropTypes.arrayOf(PropTypes.shape({}))
};

UseCreateUpdate.defaultProps = {
  isDuplicateForm: false,
  spfDepartmentalArchives: []
};
