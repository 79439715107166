/**
 * Custom hook that returns an object containing the configuration for a form.
 *
 * @hook
 * @param {Object} param - The parameters object.
 * @param {Object} param.syndic - The syndic object.
 * @param {Object} param.collectiveOwnership - The collective ownership object.
 * @param {Object} param.mission - The mission object.
 * @returns {Object} - The configuration object.
 */
export const useConfig = ({
  syndic,
  collectiveOwnership,
  mission,
  setMissionId
}) => ({
  onGetResource: {
    setFields: (data) => {
      setMissionId(data.mission);
      const newData = {
        ...data
      };
      return newData;
    }
  },
  onCreateResource: {
    setBody: (data) => {
      const newData = {
        ...data,
        send_date: new Date(),
        syndic: syndic._id,
        collective_ownership: collectiveOwnership._id,
        mission: mission._id
      };
      return newData;
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const newData = {
        ...data,
        send_date: new Date(),
        syndic: syndic._id,
        collective_ownership: collectiveOwnership._id,
        mission: mission._id
      };
      return newData;
    }
  }
});
