import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListResource, PageHeaderCustom } from '../../../components';
import { usePcsPhase3Columns } from './useColumns';

/**
 * React component that renders a list view for PCS Phase 3 missions.
 * It utilizes the `usePcsPhase3Columns` hook to define the table columns and displays
 * a list of missions filtered by PCS Phase 3 type using the `ListResource` component.
 * The component also includes a custom page header.
 *
 * @returns {JSX.Element} The JSX code to render the list of PCS Phase 3 missions.
 */
export const ListPcsPhase3 = () => {
  const { t } = useTranslation();
  const columns = usePcsPhase3Columns();

  return (
    <>
      <PageHeaderCustom
        title={`${t('pcs_phase_3.titles.phase_3_follow_up')}-${t(
          'pcs_phase_3.titles.PCS_publication'
        )}`}
      />
      <ListResource
        resourceName="mission"
        resourceModelName="Mission"
        populate="syndic collective_ownership"
        withPageHeader={false}
        columns={columns}
        withCreateButton={false}
        extraQuery="type=PCS_3&desired_publication_date=null"
        deleteAction={false}
        editAction={false}
        scroll={{ x: 'max-content' }}
        customActionColumn
      />
    </>
  );
};
