import { BaseModel } from './BaseModel';

export class SyndicRequest extends BaseModel {
  static resourceName = 'syndic-requests';

  /**
   * Counts the number of documents in the syndics collection based on the provided query.
   * @function
   * @static
   * @async
   * @param {Object} [params={}] - Parameters to include in the query.
   * @param {string} [params.extraQuery=''] - Additional query parameters to be appended to the request URL.
   * @returns {Promise<Object>} The data returned from the API, containing the count of documents.
   */
  static async getDocumentsCount({ extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${SyndicRequest.resourceName}/count?${extraQuery}`
    });
    return data;
  }
}
