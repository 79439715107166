import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Select, Tag, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ListResource } from '../../../../components';
import { useColumns } from './columns/misssionsColumns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { createMission } from '../../utils/createMissionFromCollectiveOwnership';
import { missionTag } from '../../../../utils/constants/tagColors';

/**
 * The `Missions` component is designed to display a list of missions in a tabular format, leveraging the `ListResource` component for rendering the list.
 * This component integrates custom column definitions provided by the `useColumns` hook, which are tailored to the specific data fields and actions associated with missions.
 *
 * Props:
 * @prop {Object} [enums={}] - An object containing enumeration values that might be used within the table columns to display status, types, or other categorical data related to missions.
 * @prop {Object} [collectiveOwnership={}] - An object representing the collective ownership associated with the missions, including details such as the ID, cadastral reference, manager, and syndic.
 * @returns {JSX.Element} A table displaying a list of missions associated with the provided collective ownership.
 *
 * The component initializes with a `forceRefresh` state, used to trigger a re-fetching of the missions data from the server. This can be particularly useful for ensuring that the data displayed is up to date after certain actions, such as adding, editing, or deleting a mission.
 *
 * The `useColumns` hook is utilized to generate the column definitions for the missions table, with dependencies on `enums`, `dispatchAPI` from `useAuthContext` for making API calls, and `message` from `useErrorMessage` for handling error messages. The hook also receives `setForceRefresh` to allow column actions to trigger a data refresh.
 *
 * The `ListResource` component is then used to render the missions list, with properties including:
 * - `resourceName`: Specifies the type of resource, in this case, 'missions'.
 * - `columns`: The columns configuration generated by the `useColumns` hook.
 * - `forceRefresh`: A boolean state that, when toggled, triggers the `ListResource` component to refresh its data.
 * - `customActionColumn`: Indicates that the table includes a custom column for actions such as edit or delete.
 * - `resourceModelName`: A string that represents the model name of the resource, used for accessibility labels or headers.
 * - `withPageHeader`: A boolean indicating whether a page header should be rendered by the `ListResource` component.
 * - `withCreateButton`: A boolean indicating whether a button for creating new missions should be included.
 *
 * Usage:
 * This component is intended for use in administrative or management interfaces where a list of missions needs to be displayed and interacted with. It provides a flexible and extensible way to list missions, with support for custom actions and dynamic data refreshing.
 */
export const Missions = ({ enums, collectiveOwnership }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns({
    enums,
    dispatchAPI,
    message,
    setForceRefresh,
    forceRefresh
  });
  const createMissionPermission = ![
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER'
  ].includes(user.role);

  const handleCreateMission = async (value) => {
    await createMission({
      missisonType: value,
      collectiveOwnership,
      navigate,
      notification,
      t,
      message
    });
  };

  return (
    <>
      <Row justify="start" style={{ marginLeft: 35 }}>
        {createMissionPermission && (
          <Form.Item
            name="mission"
            label={t('missions.form.launch-mission')}
            style={{ marginBottom: 0 }}
          >
            <Select
              showSearch
              style={{ width: 300 }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={async (value) => {
                await handleCreateMission(value);
              }}
            >
              {(
                enums?.types?.filter(
                  (missionType) =>
                    !['PCS_1', 'PCS_2', 'PCS_3'].includes(missionType)
                ) || []
              ).map((missionType) => (
                <Select.Option key={missionType} value={missionType}>
                  <Tag color={missionTag[missionType]}>
                    {t(`missions.tags.${missionType}`, {
                      defaultValue: missionType
                    })}
                  </Tag>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Row>
      {collectiveOwnership._id && (
        <ListResource
          resourceName="missions"
          withSearchBar={false}
          columns={columns}
          forceRefresh={forceRefresh}
          extraQuery={`collective_ownership=${collectiveOwnership._id}`}
          customActionColumn
          resourceModelName="Mission"
          withPageHeader={false}
          withCreateButton={false}
        />
      )}
    </>
  );
};

Missions.propTypes = {
  enums: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.string)
  }),
  collectiveOwnership: PropTypes.shape({
    _id: PropTypes.string,
    cadastral_reference: PropTypes.string,
    manager: PropTypes.shape({
      _id: PropTypes.string
    }),
    syndic: PropTypes.shape({
      _id: PropTypes.string,
      assistant: PropTypes.shape({
        _id: PropTypes.string
      })
    })
  })
};

Missions.defaultProps = {
  enums: {},
  collectiveOwnership: {}
};
