import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

/**
 * Faq component
 * @returns {JSX.Element} Faq component
 * @constructor Faq
 * @component Faq
 * @example <Faq />
 */
export const Faq = () => {
  const { t } = useTranslation();
  return (
    <>
      <Card
        style={{
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '20px',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      >
        <Typography>
          <Title
            level={2}
            style={{
              color: 'white'
            }}
          >
            {t('prestation_word.faq.title')}
          </Title>
          <Text
            style={{
              color: 'white'
            }}
          >
            {t('prestation_word.faq.description')}
          </Text>
        </Typography>
      </Card>
      <Card>
        <p>FAQ</p>
      </Card>
    </>
  );
};
