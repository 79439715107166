import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { missionTag } from '../../../utils/constants/tagColors';

/**
 * Custom hook that returns an array of columns for the missions view table.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setIsReassignModalOpen - The function to set the state of the reassign modal.
 * @param {Function} options.setSelectedCollectiveOwnerships - The function to set the selected collective ownerships.
 * @param {Function} options.setForceRefresh - The function to set the force refresh state.
 * @param {boolean} options.forceRefresh - The force refresh state.
 * @param {Object} options.enums - The enums object.
 * @returns {Array} - An array of columns for the missions view table.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('missions.form.syndic'),
      key: 'syndic',
      dataIndex: 'syndic',
      render: (record) =>
        `${record?.society_name || ''} ${
          record?.code ? `(${record.code})` : ''
        }`,
      sorter: (a, b) =>
        a.syndic?.society_name
          ?.toLowerCase()
          .localeCompare(b.syndic?.society_name.toLowerCase())
    },
    {
      title: t('missions.form.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: 'collective_ownership',
      render: (collective_ownership) =>
        `${collective_ownership?.code || ''} - ${
          collective_ownership?.first_address?.number || ''
        } ${collective_ownership?.first_address?.street || ''} ${
          collective_ownership?.first_address?.postal_code || ''
        } ${collective_ownership?.first_address?.city || ''} ${
          collective_ownership?.cadastral_reference
            ? `(${collective_ownership.cadastral_reference})`
            : ''
        }`,
      sorter: true
    },
    {
      title: t('missions.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (
        <Tag color={missionTag[type]}>
          {t(`missions.tags.${type}`, {
            defaultValue: type
          })}
        </Tag>
      )
    },
    {
      title: t('missions.form.step'),
      key: 'steps.sub',
      render: (record) => {
        const step = record?.steps?.sub;
        return t(`missions.steps.${step}`, {
          defaultValue: 'N/A'
        });
      },
      sorter: true
    },
    {
      title: t('missions.form.attribution_date'),
      key: 'attribution_date',
      render: (record) =>
        record?.lawyer_consultation?.attribution_date
          ? dayjs(record?.lawyer_consultation?.attribution_date).format(
              'DD-MM-YYYY à HH[h]mm'
            )
          : '-',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Link
          to={{
            pathname: `/missions/missions-view/show/${
              record.type.includes('PCS') ? 'PCS' : record.type.toLowerCase()
            }/${record?._id}/${record.type}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];
};
