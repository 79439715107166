import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { useItems } from './Items/useItems';

export const QuoteConfigurations = () => {
  const { t } = useTranslation();
  const { items } = useItems({});

  return (
    <>
      <PageHeaderCustom title={t('quote-configurations.title')} />
      <ContentCustom>
        <Tabs items={items} />
      </ContentCustom>
    </>
  );
};
