import { Divider, Row, Col, Typography, Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CalculMethods } from '../utils/calculMethods';
import { useFixInheritancesFields } from './fields/fixInheritances';

const { Text } = Typography;

/**
 * Renders the fix inheritances section of a quotation action.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.actAmount - The quotation action object.
 * @returns {JSX.Element|null} The rendered fix inheritances section.
 */
export const FixInheritances = ({ actAmount }) => {
  const { t } = useTranslation();
  const { handleAmountsUpdate } = CalculMethods();
  const { actionFieldsMap } = useFixInheritancesFields();

  const actions = [
    ...new Set(
      actAmount.quotation_acts.map((act) => act.quotation_action.action)
    )
  ];

  const fieldsToRender = [
    ...new Map(
      actions
        .flatMap((action) => actionFieldsMap[action] || [])
        .map((field) => [field.name, field])
    ).values()
  ];

  const totalFields = fieldsToRender.filter((field) =>
    ['total_ht', 'tva', 'total_ttc'].includes(field.name)
  );

  const otherFields = fieldsToRender.filter(
    (field) => !['total_ht', 'tva', 'total_ttc'].includes(field.name)
  );

  return (
    <>
      <Divider>{t('quotations.dividers.fix_inheritances')}</Divider>
      <Row gutter={[16, 16]} justify="center">
        {[...otherFields, ...totalFields].map((field) => (
          <Col span={24} key={field.key}>
            {field.input ? (
              <Form.Item
                label={t(field.label)}
                name={[
                  'quotation_act_amounts',
                  actAmount._id,
                  'fix_inheritances',
                  field.name
                ]}
              >
                <InputNumber
                  addonAfter={field.unit}
                  onChange={() => handleAmountsUpdate({ actAmount })}
                />
              </Form.Item>
            ) : (
              <Text>
                {t(field.label, {
                  amount: actAmount?.fix_inheritances?.[field.name] || 0
                })}
              </Text>
            )}
          </Col>
        ))}
      </Row>
    </>
  );
};

FixInheritances.propTypes = {
  actAmount: PropTypes.shape({
    quotation_acts: PropTypes.arrayOf(
      PropTypes.shape({
        quotation_action: PropTypes.shape({
          action: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired,
    fix_inheritances: PropTypes.shape({
      charging_grid: PropTypes.number,
      total_ht: PropTypes.number,
      tva: PropTypes.number,
      total_ttc: PropTypes.number
    }),
    _id: PropTypes.string
  }).isRequired
};
