import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/routes/lawyerRoutes';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { RequireAuth } from './RequireAuth';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { MissionsRouter } from '../routes/lawyer-view/missions/MissionsRouter';
import { Home } from '../routes/lawyer-view/home/Home';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { PhaseOneInvoicesRouter } from '../routes/lawyer-view/PhaseOneInvoices/PhaseOneInvoicesRouter';
import { ShowSyndic } from '../routes/syndic/ShowSyndic/ShowSyndic';
import { ShowCollectiveOwnership } from '../routes/collective-ownership/ShowCollectiveOwnership/ShowCollectiveOwnership';
import { BillingAndAccountingRouter } from '../routes/billing-and-accountings/BillingAndAccountingRouter';
import { CreateUpdateCollectiveOwnershipFormNoConnection } from '../routes/monitoring/collective-ownerships-forms/pcs-informations/CreateUpdateCollectiveOwnershipFormNoConnection';

export const LawyerRouter = () => {
  const { isValid } = useAuthContext();
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={outOfNavRoutes.PCSFORM}
          element={
            <CreateUpdateCollectiveOwnershipFormNoConnection purpose="create" />
          }
        />

        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.MISSIONS}/*`} element={<MissionsRouter />} />
          <Route
            path={`${routes.BILLS_PHASE_ONE}/*`}
            element={<PhaseOneInvoicesRouter />}
          />
          <Route
            path={`${outOfNavRoutes.SHOW_SYNDIC}/:id`}
            element={<ShowSyndic />}
          />
          <Route
            path={`${outOfNavRoutes.BILLING_AND_ACCOUNTING}/*`}
            element={<BillingAndAccountingRouter />}
          />
          <Route
            path={`${outOfNavRoutes.SHOW_COLLECTIVE_OWNERSHIP}/:id`}
            element={<ShowCollectiveOwnership />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
