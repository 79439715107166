/**
 * The `useNotesListContent` hook is designed to structure and prepare note-related data for presentation, specifically focusing on notes associated with a syndic entity. It simplifies the process of displaying notes information in a consistent format, suitable for list or table-like presentations.
 *
 * @param {Object} [data={}] - An object containing note information for a syndic entity. Expected to include a `note_for_syndic` property that holds the note's content.
 *
 * Returns:
 * An array containing a single object, which represents the note information to be displayed. The object includes:
 * - `label`: A string identifier used to label the note content, typically used for displaying the name of the field in a list or table.
 * - `span`: A numerical value indicating the span or width of the item in the list layout, used for alignment and formatting purposes.
 * - `content`: The actual content of the note to be displayed. If `note_for_syndic` is not provided or is empty, a placeholder '-' is displayed instead.
 *
 * Usage:
 * This hook is intended for use in components that display detailed information about a syndic entity, where displaying notes or remarks associated with the entity is required. It provides a straightforward way to include note information within a larger set of data presented to the user.
 */

export const useNotesListContent = (data = {}) => {
  const { note_for_syndic } = data;

  return [
    {
      label: 'syndics.form.note_for_syndic',
      span: 1,
      content: note_for_syndic || '-'
    }
  ];
};
