import { useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useColumns } from '../columns/documentColumns';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { EditableTableMethods } from '../../../../../components/editableTableComponents/methods';
import { addColumns } from '../../../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../../../components/ContentCustom/ContentCustom';
import { EditableTable } from '../../../../../components/editableTableComponents/EditableTable';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Renders a list of documents for a mission.
 * @component
 * @param {Object} props - The component props.
 * @param {Boolean} props.search_by_document_ref - The document reference to search by.
 * @param {string} props.columns_type - The type of columns to display.
 * @param {string} props.document_repository_key - The key of the document repository.
 * @returns {JSX.Element} The rendered ListDocuments component.
 */
export const ListDocuments = ({
  search_by_document_ref,
  columns_type,
  document_repository_key
}) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const { getResourceData } = EditableTableMethods();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    setPagination,
    pagination,
    forceRefresh,
    setForceRefresh,
    documentType,
    setSelectedDocuments,
    missionTypeForDocuments
  } = useMissionContext();
  const {
    actCopyColumns,
    otherColumns,
    generalAssemblyColumns,
    exchangeDocumentsColumns,
    intermediateProjectColumns,
    definitiveProjectColumns,
    requestedDocumentSyndicColumns,
    auditColumns
  } = useColumns({ setEditingKey, form });
  const columnsData = {
    actCopyColumns,
    otherColumns,
    generalAssemblyColumns,
    exchangeDocumentsColumns,
    intermediateProjectColumns,
    definitiveProjectColumns,
    requestedDocumentSyndicColumns,
    auditColumns
  };

  const [mergedColumns, setMergedColumns] = useState(columnsData[columns_type]);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  const handleDataSource = async () => {
    const documentsDatas = await getResourceData({
      resource: `documents/mission/${id}`,
      setPagination,
      pagination,
      // IF missionTypeForDocuments is not null, then we get all documents types, not only cerfa or various document
      query: `&${
        search_by_document_ref
          ? 'config_document.document_type.document_key='
          : 'document_key='
      }${document_repository_key}${
        missionTypeForDocuments ? '' : `&config_document_ref=${documentType}`
      }`
    });

    const updatedDocuments = documentsDatas.map(
      (collectiveOwnership, index) => ({
        ...collectiveOwnership,
        key: index
      })
    );
    setDataSource(updatedDocuments);
  };

  useEffect(() => {
    (async () => {
      await handleDataSource();
    })();
  }, [forceRefresh]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: columnsData[columns_type],
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      resource: 'documents'
    });
  }, [editingKey, dataSource]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedDocuments(rowRecords);
    }
  };

  return (
    <ContentCustom style={{ margin: '0', padding: 8 }}>
      <EditableTable
        scroll={{ x: 'fit-container' }}
        resource="documents"
        dataSource={dataSource}
        mergedColumns={mergedColumns}
        setSelectedRowKey={setSelectedRowKey}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        form={form}
        pagination={pagination}
        setPagination={setPagination}
        withoutAddLineButton
        withoutHeader
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
      />
    </ContentCustom>
  );
};

ListDocuments.propTypes = {
  search_by_document_ref: PropTypes.bool,
  columns_type: PropTypes.string.isRequired,
  document_repository_key: PropTypes.string.isRequired
};

ListDocuments.defaultProps = {
  search_by_document_ref: false
};
