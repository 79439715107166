import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Renders a modal component for creating a manager.
 *
 * @component
 * @param {Object} managerModal - The state of the manager modal.
 * @returns {JSX.Element} The rendered ManagerModal component.
 */
export const ManagerModal = ({ managerModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal
      title={t('syndics.modals.titles.create_manager')}
      open={managerModal}
      footer={null}
      onCancel={() => navigate('/syndics')}
    >
      <Link to="/users/create" state={{ manager_role: 'users:SYNDIC_MANAGER' }}>
        <Button>{t('radio-options.yes')}</Button>
      </Link>
      <Link to="/syndics">
        <Button> {t('radio-options.no')}</Button>
      </Link>
    </Modal>
  );
};

ManagerModal.propTypes = {
  managerModal: PropTypes.bool.isRequired
};
