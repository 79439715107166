import { useState, useEffect } from 'react';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { getEnums } from './utils/getEnums';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ReassignModal } from './modals/ReassignModal';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { CollectiveOwnership } from '../../models/CollectiveOwnership';

/**
 * The `ListCollectiveOwnerships` component is designed to display a list of collective ownership entities,
 * allowing users to toggle between active and archived ownerships. It utilizes Ant Design's `Row` and `Switch`
 * components for layout and toggle functionality, respectively. The component fetches enumeration values
 * needed for column configuration and employs the `ListResource` component for rendering the list.
 *
 * The component leverages the `useTranslation` hook for internationalization, ensuring that UI elements
 * such as labels and messages are localized. It also integrates with the `useAuthContext` for authorization
 * context and `useErrorMessage` for handling error messages.
 *
 * Functionality:
 * - Fetches enumeration values used for column configuration on component mount.
 * - Provides a toggle switch for users to view either active or archived collective ownership entities.
 * - Utilizes the `ListResource` component to render the list of collective ownerships, passing in custom columns,
 *   resource name, and additional query parameters based on the toggle state.
 *
 * Usage:
 * This component is used within the administrative interface of an application that manages collective ownership entities.
 * It allows administrators to easily view and manage these entities, with the ability to filter the list based on archival status.
 */

export const ListCollectiveOwnerships = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { syndics, setActiveKey } = useGlobalContext();
  const [enums, setEnums] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCollectiveOwnerships, setSelectedCollectiveOwnerships] =
    useState([]);
  const [forbiddenRoles, setForbiddenRoles] = useState([]);
  const columns = useColumns({
    enums,
    setIsReassignModalOpen,
    setSelectedCollectiveOwnerships,
    dispatchAPI,
    setForceRefresh,
    forceRefresh,
    message
  });
  const location = useLocation();
  const { user } = useAuthContext();

  useEffect(() => {
    window.history.replaceState({}, '');
    (async () => {
      const fbRoles = await CollectiveOwnership.getForbiddenRoles();
      setForbiddenRoles(fbRoles.forbiddenRoles);
      await getEnums({ dispatchAPI, setEnums, message });
    })();
    setActiveKey('1');
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedCollectiveOwnerships(rowRecords);
    }
  };

  return (
    <>
      <ListResource
        resourceName="collective-ownerships"
        populate="syndic,manager,documents"
        extraQuery={`status=ACTIVE&deleted=${false}${
          location?.state
            ? `&documents.volume=${location.state.volume}&documents.number=${
                location.state.number
              }&deleted=${false}`
            : ''
        }`}
        columns={columns}
        forceRefresh={forceRefresh}
        resourceModelName="Collective-ownership"
        customActionColumn
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        withCreateButton={!forbiddenRoles?.includes(user?.role)}
      />
      {isReassignModalOpen ? (
        <ReassignModal
          syndics={syndics}
          setIsReassignModalOpen={setIsReassignModalOpen}
          isReassignModalOpen={isReassignModalOpen}
          selectedCollectiveOwnerships={selectedCollectiveOwnerships}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectedCollectiveOwnerships={setSelectedCollectiveOwnerships}
        />
      ) : null}
      {selectedRowKeys?.length > 0 ? (
        <Row style={{ marginLeft: 20 }}>
          <Button type="primary" onClick={() => setIsReassignModalOpen(true)}>
            {t('buttons.reassign')}
          </Button>
        </Row>
      ) : null}
    </>
  );
};
