import { CheckOutlined } from '@ant-design/icons';
import { Card, Input, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../utils/constants/routes/productionRoutes';

/**
 * ActCopyResearchCard Component
 *
 * This component renders a card with a form that allows users to input volume and number.
 * Upon form submission, it navigates to the COLLECTIVE_OWNERSHIPS route with the form values in the state.
 * @component
 * @returns {JSX.Element} The rendered card component.
 */
export const ActCopyResearchCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    navigate(routes.COLLECTIVE_OWNERSHIPS, { state: { ...values } });
  };
  return (
    <Card title={t('dashboard.production.cards.act_copy_research.title')}>
      <Form
        style={{ display: 'flex', justifyContent: 'space-around' }}
        onFinish={handleFinish}
      >
        <Form.Item
          label={t(
            'dashboard.production.cards.act_copy_research.labels.volume'
          )}
          name="volume"
          rules={[
            {
              required: true,
              message: t(
                'dashboard.production.cards.act_copy_research.required_volume'
              )
            }
          ]}
        >
          <Input
            placeholder={t(
              'dashboard.production.cards.act_copy_research.placeholders.volume'
            )}
          />
        </Form.Item>
        <Form.Item
          label={t(
            'dashboard.production.cards.act_copy_research.labels.number'
          )}
          name="number"
          rules={[
            {
              required: true,
              message: t(
                'dashboard.production.cards.act_copy_research.required_number'
              )
            }
          ]}
        >
          <Input
            placeholder={t(
              'dashboard.production.cards.act_copy_research.placeholders.number'
            )}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            <CheckOutlined />
            {t('dashboard.production.cards.act_copy_research.start_research')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
