import { Popconfirm, Row, message as antdMessage } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { EditableTableMethods } from '../../../../components/editableTableComponents/methods';
import { deleteResource } from '../../../../utils/deleteResource';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Custom hook that returns the columns configuration for a table.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setEditingKey - The function to set the editing key.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The force refresh flag.
 * @returns {Object} - The columns configuration for the table.
 */
export const useColumns = ({
  setEditingKey,
  form,
  setForceRefresh,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { editCell } = EditableTableMethods();

  const actionButtons = [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => {
            deleteResource({
              dispatchAPI,
              resourceName: 'quote-configurations',
              id: record._id,
              setForceRefresh,
              forceRefresh,
              message
            });
            antdMessage.success({
              content: t('datatable.message.success.deleteline'),
              icon: <DeleteOutlined style={{ color: 'red' }} />
            });
            return true;
          }}
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const publicationPcs = [
    {
      title: t('quote-configurations.form.number_of_lots'),
      key: 'number_of_lots',
      dataIndex: 'number_of_lots',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'number_of_lots', setEditingKey, form })
          }
        >
          {record?.number_of_lots || '-'}
        </Row>
      ),
      sorter: (a, b) => {
        const getMinValue = (range) => {
          if (range.includes('à')) {
            const match = range.match(/(\d+)\s*à\s*(\d+)/);
            return match ? parseInt(match[1], 10) : 0;
          }
          if (range.includes('et plus')) {
            const match = range.match(/(\d+)\s*et\s*plus/);
            return match ? parseInt(match[1], 10) : 0;
          }
          return 0; // ou une autre valeur par défaut en cas de format inattendu
        };

        return getMinValue(a.number_of_lots) - getMinValue(b.number_of_lots);
      }
    },
    {
      title: t('quote-configurations.form.notary_ttc'),
      key: 'notary_ttc',
      dataIndex: 'notary_ttc',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'notary_ttc', setEditingKey, form })
          }
        >
          {record?.notary_ttc ? `${record.notary_ttc} €` : '-'}
        </Row>
      )
    },
    {
      title: t('quote-configurations.form.nomade_ttc'),
      key: 'nomade_ttc',
      dataIndex: 'nomade_ttc',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'nomade_ttc', setEditingKey, form })
          }
        >
          {record?.nomade_ttc ? `${record.nomade_ttc} €` : '-'}
        </Row>
      )
    },
    {
      title: t('quote-configurations.form.total_ttc'),
      key: 'total_ttc',
      dataIndex: 'total_ttc',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'total_ttc', setEditingKey, form })
          }
        >
          {record?.total_ttc ? `${record.total_ttc} €` : '-'}
        </Row>
      )
    },
    ...actionButtons
  ];

  const missions = [
    {
      title: t('quote-configurations.form.mission_wording'),
      key: 'mission_wording',
      dataIndex: 'mission_wording',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'mission_wording', setEditingKey, form })
          }
        >
          {record?.mission_wording || '-'}
        </Row>
      ),
      sorter: (a, b) =>
        a.mission_wording
          ? a.mission_wording.localeCompare(b.mission_wording)
          : true
    },
    ...actionButtons
  ];

  const phrases = [
    {
      title: t('quote-configurations.form.concerned_action'),
      key: 'concerned_action',
      dataIndex: 'concerned_action',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'concerned_action', setEditingKey, form })
          }
        >
          {record?.concerned_action || '-'}
        </Row>
      ),
      sorter: true
    },
    {
      title: t('quote-configurations.form.phrase_to_insert_in_quote'),
      key: 'phrase_to_insert_in_quote',
      dataIndex: 'phrase_to_insert_in_quote',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({
              record,
              key: 'phrase_to_insert_in_quote',
              setEditingKey,
              form
            })
          }
        >
          {record?.phrase_to_insert_in_quote || '-'}
        </Row>
      ),
      sorter: true
    },
    ...actionButtons
  ];

  return {
    PUBLICATION_PCS: publicationPcs,
    MISSIONS: missions,
    PHRASES: phrases
  };
};
