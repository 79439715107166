import { Link } from 'react-router-dom';
import { Button, Card, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import MissionConfondues from '../../../assets/images/missions_confondues.svg';
import Mission_recherche_plan from '../../../assets/images/illustrationMission_recherche_plan.svg';
import Mission_recherche_rcp from '../../../assets/images/recherche_rcp.svg';
import Mission_services_complementaires from '../../../assets/images/services_complementaires.svg';
import Missions_RCP_ELAN from '../../../assets/images/Missions_RCP_ELAN.svg';

const defaultCards = [
  {
    title: 'Recherche de document RCP',
    image: Mission_recherche_rcp,
    route: '/rcp-research',
    index: 4
  },
  {
    title: 'Services complémentaires',
    image: Mission_services_complementaires,
    route: '',
    index: 5
  }
];

/**
 * Renders a set of cards displaying information related to the user's home.
 * Fetches data from the server and populates the cards dynamically.
 *
 * @component
 * @returns {JSX.Element} The rendered HomeCards component.
 */
const HomeCards = () => {
  const { user, dispatchAPI } = useAuthContext();
  const [cards, setCards] = useState(defaultCards);
  const { message } = useErrorMessage();

  const getCardsData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `syndic-collective-ownerships/homecards?syndic_manager=${user._id}`
      });

      const arrayToSet = [];
      if (data.collectiveOwnershipCount > 0) {
        arrayToSet[0] = {
          title: `Toutes missions confondues`,
          amount: data.collectiveOwnershipCount,
          image: MissionConfondues,
          route: '/collective-ownerships',
          index: 1
        };
      }
      if (data.loiElonRCPCount > 0) {
        arrayToSet[1] = {
          title: 'Mise en conformité des RCP - Loi ELAN',
          amount: data.loiElonRCPCount,
          image: Missions_RCP_ELAN,
          route: 'services/compliance-loi-elan/dashboard',
          index: 2
        };
      }
      if (data.planResearchCount > 0) {
        arrayToSet[2] = {
          title: 'Recherche de plan',
          amount: data.planResearchCount,
          image: Mission_recherche_plan,
          index: 3,
          route: 'services/plans-research/dashboard'
        };
      }
      setCards([...arrayToSet, ...defaultCards]);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getCardsData();
  }, []);

  return (
    <div className="subscription-tab">
      <Row justify="space-around">
        {(cards || []).map((item) => (
          <Col key={item.index} className="subscription-wrapper">
            <Card
              title={
                <div
                  style={{
                    overflow: 'visible',
                    whiteSpace: 'normal'
                  }}
                >
                  {item.title}
                </div>
              }
              key={item.index}
              style={{
                background: item.color,
                border: `1px solid ${item.color}`,
                width: '15rem',
                height: '20rem',
                borderRadius: '15px'
              }}
            >
              <img src={item.image} alt="logo" />
              <Link to={item.route}>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: '#222222',
                    borderRadius: '15px',
                    color: '#ffffff',
                    width: '100%'
                  }}
                >
                  {item?.amount ? (
                    <>
                      Voir mes{' '}
                      <span style={{ color: 'yellow' }}>{item.amount}</span>{' '}
                      copropriétés
                    </>
                  ) : (
                    'Decouvrir'
                  )}
                </Button>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default HomeCards;
