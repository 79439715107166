import {
  Checkbox,
  Select,
  Form,
  Input,
  Typography,
  Row,
  Col,
  DatePicker,
  Upload,
  Divider
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';

const { Option } = Select;
const { Text, Title } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

/**
 * Custom hook that defines the fields for the addDocumentCard component.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setFileList - The function to set the file list.
 * @param {Object} options.fileList - The current file list.
 * @returns {Object} - The fields object.
 */
export const useFields = ({ setFileList, fileList }) => {
  const { t } = useTranslation();
  const { isLoading, configDocuments, addDocumentCardDatas } =
    useMissionContext();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const draggerProps = ({ name }) => ({
    multiple: true,
    onRemove: () => {
      const updatedFileList = { ...fileList };
      delete updatedFileList[name];
      setFileList(updatedFileList);
    },
    beforeUpload: (file) => {
      setFileList((prev) => {
        const updatedList = { ...prev };
        if (updatedList[name]) {
          updatedList[name] = [...updatedList[name], file];
        } else {
          updatedList[name] = [file];
        }
        return updatedList;
      });

      return false;
    },
    fileList: fileList[name] || []
  });

  const fields = [
    {
      noLabel: true,
      name: ['documents'],
      input: (
        <Form.List name="documents" wrapperCol={{ span: 24 }}>
          {(listFields, { add }) => {
            if (listFields.length < 15) add();
            return (
              <>
                {listFields.map(({ key, name, ...restField }, index) => (
                  <Col span={24}>
                    <Divider orientation="left">
                      <Title style={{ marginTop: 0 }} level={5} key={key}>
                        {t('missions.form.document')} {index + 1}
                      </Title>
                    </Divider>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <Dragger {...draggerProps({ name })}>
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined
                              style={{ color: 'var(--textColor)' }}
                            />
                          </p>
                          <p className="ant-upload-text">
                            {t('files.create.action')}
                          </p>
                        </Dragger>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              name={[name, 'config_document']}
                              wrapperCol={{ span: 24 }}
                            >
                              <Select
                                allowClear
                                showSearch
                                loading={isLoading}
                                filterOption={filterOption}
                                placeholder={t(
                                  'missions.form.document_type_placeholder'
                                )}
                                popupMatchSelectWidth
                              >
                                {(addDocumentCardDatas.type === 'Cerfa-document'
                                  ? configDocuments.cerfa_documents
                                  : configDocuments.various_documents || []
                                ).map((document) => (
                                  <Option
                                    key={document._id}
                                    value={document._id}
                                  >
                                    {document.wording}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          {addDocumentCardDatas.type === 'Cerfa-document' ? (
                            <>
                              <Col span={24}>
                                <Form.Item {...restField} name={[name, 'date']}>
                                  <DatePicker
                                    format={[
                                      'DD-MM-YYYY',
                                      'DD/MM/YYYY',
                                      'DDMMYYYY'
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Row gutter={[16, 16]}>
                                  <Col>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'volume']}
                                    >
                                      <Input
                                        placeholder={t('missions.form.volume')}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'number']}
                                    >
                                      <Input
                                        placeholder={t('missions.form.number')}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'SAGE_code']}
                                    >
                                      <Input
                                        placeholder={t(
                                          'missions.form.SAGE_code'
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col span={24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'title']}
                                >
                                  <Input
                                    placeholder={t(
                                      'missions.form.personalized_title'
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item {...restField} name={[name, 'date']}>
                                  <DatePicker format="DD-MM-YYYY" />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                      <Col>
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <Form.Item
                              {...restField}
                              valuePropName="checked"
                              name={[name, 'supply_by_syndic']}
                            >
                              <Row gutter={[16, 16]}>
                                <Col>
                                  <Checkbox />
                                </Col>
                                <Col>
                                  <Text>
                                    {t('missions.form.supply_by_syndic')}
                                  </Text>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item {...restField} name={[name, 'comment']}>
                              <TextArea
                                placeholder={t('missions.form.comment')}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </>
            );
          }}
        </Form.List>
      )
    }
  ];

  return { fields };
};
