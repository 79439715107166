import { Popconfirm, Row, Tag } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { EditableTableMethods } from '../../../../components/editableTableComponents/methods';
import { deleteResource } from '../../../../utils/deleteResource';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { missionTag } from '../../../../utils/constants/tagColors';

/**
 * Custom hook that returns the columns configuration for the accounting table.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setEditingKey - The function to set the editing key.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The force refresh flag.
 * @param {Function} options.setSelectType - The function to set the select type.
 * @returns {Object} - The columns configuration for the table.
 */
export const useColumns = ({
  setEditingKey,
  form,
  setForceRefresh,
  forceRefresh,
  setSelectType
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { editCell } = EditableTableMethods();

  const actionButtons = [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            deleteResource({
              dispatchAPI,
              resourceName: 'accountings',
              id: record._id,
              setForceRefresh,
              forceRefresh,
              message
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const bankAccounts = [
    {
      title: t('accountings.form.bank_account'),
      key: 'bank_account',
      dataIndex: 'bank_account',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'bank_account', setEditingKey, form })
          }
        >
          {record?.bank_account || '-'}
        </Row>
      ),
      sorter: (a, b) =>
        a.bank_account ? a.bank_account.localeCompare(b.bank_account) : true
    },
    {
      title: t('accountings.form.account'),
      key: 'account',
      dataIndex: 'account',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'account', setEditingKey, form })
          }
        >
          {record?.account || '-'}
        </Row>
      ),
      sorter: (a, b) => (a.account ? a.account.localeCompare(b.account) : true)
    },
    ...actionButtons
  ];

  const missions = [
    {
      title: t('accountings.form.mission'),
      key: 'mission',
      dataIndex: 'mission',
      editable: true,
      inputType: 'select',
      render: (_, record) => (
        <Row
          onClick={() => {
            setSelectType('accounting_missions');
            editCell({
              record,
              key: 'mission',
              setEditingKey,
              form
            });
          }}
        >
          {record?.mission ? (
            <Tag color={missionTag[record?.mission]} key={record?.mission}>
              {record?.mission ? t(`missions.tags.${record?.mission}`) : ''}
            </Tag>
          ) : (
            '-'
          )}
        </Row>
      )
    },
    {
      title: t('accountings.form.account'),
      key: 'account',
      dataIndex: 'account',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'account', setEditingKey, form })
          }
        >
          {record?.account || '-'}
        </Row>
      ),
      sorter: (a, b) => (a.account ? a.account.localeCompare(b.account) : true)
    },
    ...actionButtons
  ];

  return {
    BANK_ACCOUNTS: bankAccounts,
    MISSIONS: missions
  };
};
