import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/requestedDocumentsToSyndicFields';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useConfig } from '../utils/requestedDocumentToSyndicCreateUpdateConfig';

/**
 * Renders a drawer component for displaying requested documents to syndic.
 * @component
 * @returns {JSX.Element} The requested documents to syndic drawer component.
 */
export const RequestedDocumentsToSyndicDrawer = () => {
  const {
    forceRefresh,
    setForceRefresh,
    setIsDrawerOpen,
    isDrawerOpen,
    isLoading
  } = useMissionContext();

  const { fields } = useFields();
  const { t } = useTranslation();
  const config = useConfig({});

  return (
    <Drawer
      title={t('missions.drawers.titles.requested_documents_to_syndic')}
      onClose={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          requested_documents_to_syndic: false
        }))
      }
      open={isDrawerOpen.requested_documents_to_syndic}
    >
      <CreateUpdateContainer
        config={config}
        fields={fields}
        isFieldsLoading={isLoading}
        purpose="edit"
        baseUrl="missions/update-requested-documents-to-syndic"
        resource="missions"
        populate="documents"
        withFilesManager={false}
        withEnums={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{
          ...isDrawerOpen,
          requested_documents_to_syndic: false
        }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        successMessage={t(
          'missions.messages.requested_documents_to_syndic_updated'
        )}
        customLabelCol={{}}
        customWrapperCol={{}}
        withoutHandleFileActionsOnFetch
      />
    </Drawer>
  );
};
