import { useTranslation } from 'react-i18next';
import { Row, Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PcsPreProduction } from '../../../../../models/PcsPreProduction';
import { Order } from '../../../../../models/Order';
import { Mission } from '../../../../../models/Mission';
import {
  routes,
  subRoutes,
  outOfNavRoutes
} from '../../../../../utils/constants/routes/productionRoutes';
import { adminTheme } from '../../../../../utils/constants/colors';
import { SyndicRequest } from '../../../../../models/SyndicRequest';

/**
 * useCollapseItems Hook
 *
 * This hook fetches the statistics for PCS and Orders, and generates a list of collapsible items
 * for the ExpectedActionsCard component.
 *
 * @returns {Array<Object>} An array of collapsible items with their respective actions and statistics.
 */
// revoir le code pour le count des plans à relancer + | raccourci anomalies détecter, commandes à relancer et Géomètre à mandater
export const useCollapseItems = () => {
  const { t } = useTranslation();
  const [stats, setStats] = useState({});
  const navigate = useNavigate();

  const expectedActions =
    'dashboard.production.cards.expected_actions.collapses';

  const mapItem = (item) => (
    <Row justify="center">
      <Button
        onClick={() =>
          item.path &&
          navigate(item.path, {
            state: {
              key: item.key,
              wording: item.wording,
              'document.status': item['document.status'],
              status: item.status
            }
          })
        }
        type="primary"
        style={{ width: '100%', marginBottom: 8 }}
      >
        <Space direction="horizontal">
          <span>{item.label}</span>
          {item && item.number && (
            <span style={{ color: adminTheme.secondaryColor }}>
              {item.number}
            </span>
          )}
        </Space>
      </Button>
    </Row>
  );

  useEffect(() => {
    const fetchData = async () => {
      const pcsStats = await PcsPreProduction.getStats();
      const ordersStats = await Order.getStats();
      const pcsStocksStats = await PcsPreProduction.getPcsStocksStats();
      const cerfaStockCount = await Order.countDocuments({
        extraQuery: 'status=TO_BE_ORDERED&mission.type=PCS_1'
      });
      const pcs2LateRewrite = await Mission.getDocumentsCount({
        extraQuery: 'type=PCS_2&re_written_document_status=LATE'
      });
      const toFinishCount = await Mission.getDocumentsCount({
        extraQuery: 'type=PCS_2&steps.sub=FINALIZATION'
      });
      const pcs3DocumentsCount = await Mission.getDocumentsCount({
        extraQuery: 'type=PCS_3'
      });
      const collectiveOwnershipTransfer = await SyndicRequest.getDocumentsCount(
        {
          extraQuery:
            'status=PENDING_VALIDATION&requested_mission_type=COLLECTIVE_OWNERSHIP_TRANSFER&sort=-updated_at'
        }
      );
      const planRequests = await Mission.getPlanRequestsByReview();
      setStats({
        ...pcsStats,
        ...ordersStats,
        ...pcsStocksStats,
        cerfaStockNb: cerfaStockCount?.documentCount,
        pcs2LateRewrite: pcs2LateRewrite?.count,
        toFinishCount: toFinishCount?.count,
        pcs3DocumentsCount: pcs3DocumentsCount?.count,
        collectiveOwnershipTransferCount: collectiveOwnershipTransfer?.count,
        planRequestsCount: planRequests?.length
      });
    };

    fetchData();
  }, []);

  return [
    {
      key: t(`${expectedActions}.PCS_1.label`),
      label: t(`${expectedActions}.PCS_1.label`),
      children: [
        {
          key: '4',
          label: t(`${expectedActions}.global_actions.to_start`),
          number: stats.pcs_1_to_start ? stats.pcs_1_to_start : 0,
          path: routes.MISSIONS + subRoutes.MISSIONS.PCS_PRE_PRODUCTIONS
        },
        {
          key: '3',
          label: t(`${expectedActions}.PCS_1.actions.pending`),
          number: stats.pcs_1_pending ? stats.pcs_1_pending : 0,
          path: routes.MISSIONS + subRoutes.MISSIONS.PCS_PRE_PRODUCTIONS
        },
        {
          key: 'cerfa_stock',
          label: t(`${expectedActions}.PCS_1.actions.cerfa_stock`),
          number: stats?.cerfaStockNb || 0,
          path: outOfNavRoutes.STOCK_CERFA
        },
        {
          key: 'folder_stock',
          label: t(`${expectedActions}.PCS_1.actions.folder_stock`),
          number: stats.pcs_stocks ? stats.pcs_stocks : 0,
          path: routes.MONITORING + subRoutes.MONITORING.PCS_STOCK
        }
      ].map(mapItem)
    },
    {
      key: t(`${expectedActions}.PCS_2.label`),
      label: t(`${expectedActions}.PCS_2.label`),
      children: [
        {
          key: 'to_start',
          label: t(`${expectedActions}.global_actions.to_start`),
          number: stats.pcs_2_to_start ? stats.pcs_2_to_start : 0,
          path: `${routes.MONITORING}${subRoutes.MONITORING.SYNDIC_REQUESTS}?requested_mission_type=PCS_2`
        },
        {
          key: 'surveyor',
          label: t(`${expectedActions}.global_actions.surveyor`)
        },
        {
          key: 'late_rewrite',
          label: t(`${expectedActions}.PCS_2.actions.late_rewrite`),
          path: `${routes.MISSIONS}${subRoutes.MISSIONS.MISSIONS_VIEW}?type=PCS_2&re_written_document_status=LATE`,
          number: stats?.pcs2LateRewrite || 0
        },
        {
          key: 'to_finish',
          label: t(`${expectedActions}.PCS_2.actions.to_finish`),
          path: `${routes.MISSIONS}${subRoutes.MISSIONS.MISSIONS_VIEW}?type=PCS_2&steps.sub=FINALIZATION`,
          number: stats?.toFinishCount || 0
        }
      ].map(mapItem)
    },
    {
      key: t(`${expectedActions}.PCS_3.label`),
      label: t(`${expectedActions}.PCS_3.label`),
      children: [
        {
          key: 'monitoring',
          label: t(`${expectedActions}.PCS_3.actions.monitoring`),
          path: `${routes.MONITORING}${subRoutes.MONITORING.PCS_PHASE_3}`,
          number: stats?.pcs3DocumentsCount || 0
        },
        {
          key: 'to_start',
          label: t(`${expectedActions}.global_actions.to_start`),
          number: stats.pcs_3_to_start ? stats.pcs_3_to_start : 0,
          path: `${routes.MONITORING}${subRoutes.MONITORING.SYNDIC_REQUESTS}?requested_mission_type=PCS_3`
        },
        {
          key: 'document_review',
          label: t(`${expectedActions}.PCS_3.actions.document_review`),
          path: outOfNavRoutes.DOCUMENT_REVIEW,
          number: stats?.pcs3DocumentsCount || 0
        }
      ].map(mapItem)
    },
    {
      key: t(`${expectedActions}.ALL.label`),
      label: t(`${expectedActions}.ALL.label`),
      children: [
        {
          key: 'Fiche immeuble',
          wording: 'Fiche immeuble',
          status: 'TO_BE_ORDERED',
          label: t(
            `${expectedActions}.ALL.actions.building_forms_to_be_ordered`
          ),
          number: stats.building_forms_to_be_ordered
            ? stats.building_forms_to_be_ordered
            : 0,
          path: routes.ORDERS
        },
        {
          key: "Copie d'acte",
          wording: "Copie d'acte",
          status: 'TO_BE_ORDERED',
          label: t(`${expectedActions}.ALL.actions.act_copies_to_be_ordered`),
          number: stats.act_copies_to_be_ordered
            ? stats.act_copies_to_be_ordered
            : 0,
          path: routes.ORDERS
        },
        {
          key: 'orders_to_reminder',
          label: t(`${expectedActions}.ALL.actions.orders_to_reminder`)
        },
        {
          key: 'orders_urgent_late',
          status: 'ORDERED',
          'document.status': 'LATE',
          label: t(`${expectedActions}.ALL.actions.orders_urgent_late`),
          number: stats.urgent_late_orders ? stats.urgent_late_orders : 0,
          path: routes.ORDERS
        },
        {
          key: 'detected_problems',
          label: t(`${expectedActions}.ALL.actions.detected_problems`)
        },
        {
          key: 'collective_ownership_transfer',
          label: t(
            `${expectedActions}.ALL.actions.collective_ownership_transfer`
          ),
          path: `${routes.MONITORING}${subRoutes.MONITORING.COLLECTIVE_OWNERSHIPS_TRANSFER}`,
          number: stats?.collectiveOwnershipTransferCount || 0
        }
      ].map(mapItem)
    },
    {
      key: t(`${expectedActions}.PCS_OUT.label`),
      label: t(`${expectedActions}.PCS_OUT.label`),
      children: [
        {
          key: 'to_start',
          label: t(`${expectedActions}.global_actions.to_start`),
          number: stats.outside_pcs_to_start ? stats.outside_pcs_to_start : 0,
          path: `${routes.MONITORING}${subRoutes.MONITORING.SYNDIC_REQUESTS}?requested_mission_type=OUTSIDE_PCS`
        },
        {
          key: 'orders_to_reminder',
          label: t(`${expectedActions}.PCS_OUT.actions.plans_reminder`),
          path: outOfNavRoutes.PLAN_REQUEST_REVIEW,
          number: stats?.planRequestsCount || 0
        }
      ].map(mapItem)
    }
  ];
};
