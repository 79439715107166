import { message } from 'antd';
import { BaseModel } from './BaseModel';

/**
 * Represents a user.
 * @class
 */
export class User extends BaseModel {
  static resourceName = 'users';

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extraQuery string.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${User.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Get an user by its id.
   * @function getUser
   * @async
   * @param {Object} props - The component props.
   * @param {string} props.id - The user id.
   * @returns {Promise<Object>} The user.
   * @static
   */
  static async getUser({ id }) {
    try {
      const { data } = await this.dispatchAPI('GET', {
        url: `${User.resourceName}/${id}`
      });
      return data;
    } catch (error) {
      message.error(error.message);
    }
    return null;
  }

  /**
   * Retrieves the most recently created syndic.
   * @function
   * @async
   * @param {Object} props - The component props.
   * @param {Object} props.form - The form object.
   * @param {Function} props.setSyndic - The function to set the syndic.
   * @param {string} props.manager_role - The manager role.
   * @param {Function} props.setRole - The function to set the role.
   * @returns {Promise<Object>} A promise that resolves to the data of the most recently created syndic.
   */
  static async populateFields({ form, setSyndic, manager_role, setRole }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `syndics?sort=-created_at&limit=1`
    });

    setTimeout(() => {
      form.setFieldsValue({ role: manager_role, syndic: data[0]._id });
      setSyndic(data[0]._id);
      setRole(manager_role);
    }, 300);
  }

  /**
   * Handles the data of professionals and categorizes them based on their role.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {Array} options.data - The array of user data.
   * @returns {Object} - An object containing categorized professionals.
   */
  static handleProfessionalsDatas({ data }) {
    const professionals = {
      managers: [],
      notaries: [],
      lawyers: [],
      surveyors: [],
      prestation_word: [],
      others: []
    };

    data.forEach((user) => {
      switch (user.role) {
        case 'users:SYNDIC_MANAGER':
          professionals.managers.push(user);
          break;
        case 'users:NOTARY':
          professionals.notaries.push(user);
          break;
        case 'users:LAWYER':
          professionals.lawyers.push(user);
          break;
        case 'users:SURVEYOR':
          professionals.surveyors.push(user);
          break;
        case 'users:PRESTATION_WORD':
          professionals.prestation_word.push(user);
          break;
        default:
          professionals.others.push(user);
      }
    });

    return professionals;
  }

  /**
   * Formats the lawyer's mission statistics into a structured array of objects,
   * each containing a title and a list of document data for various mission categories.
   *
   * @param {Array} missions - An array of mission objects. Each mission represents a lawyer's mission data.
   * @returns {Array|null} A formatted array of objects, where each object represents a category (e.g., 'all', 'PCS_1', 'PCS_2')
   * and contains a `title` and `documentData` array with statistics. Returns `null` if no missions are provided.
   *
   * @example
   * const missions = [/* Array of mission objects * /];
   * const formattedStatistics = User.formatLawyerStatisticsMissions(missions);
   * console.log(formattedStatistics);
   */
  static formatLawyerStatisticsMissions(missions) {
    if (!missions) return null;
    const {
      missionsValidatedPCS1,
      missionsValidatedPCS2,
      missionsInProgressPCS1,
      missionsInProgressPCS2,
      totalDocumentsValidated,
      totalDocumentsInProgress
    } = this.getLawyerStatisticMissions(missions);

    return [
      {
        title: 'all',
        documentData: [
          {
            title: 'processed_misssions',
            value: totalDocumentsValidated
          },
          {
            title: 'current_missions',
            value: totalDocumentsInProgress
          },
          {
            title: 'unpaid_missions',
            value: 2
          }
        ]
      },
      {
        title: 'PCS_1',
        documentData: [
          {
            title: 'processed_misssions',
            value: missionsValidatedPCS1
          },
          {
            title: 'current_missions',
            value: missionsInProgressPCS1
          },
          {
            title: 'unpaid_missions',
            value: 0
          }
        ]
      },
      {
        title: 'PCS_2',
        documentData: [
          {
            title: 'processed_misssions',
            value: missionsValidatedPCS2
          },
          {
            title: 'current_missions',
            value: missionsInProgressPCS2
          },
          {
            title: 'unpaid_missions',
            value: 0
          }
        ]
      }
    ];
  }

  /**
   * Analyzes and summarizes the missions data for a lawyer by categorizing the missions
   * into different types and stages. It returns a set of statistics that include the number
   * of validated and in-progress missions for two types: PCS_1 and PCS_2.
   *
   * @param {Array} missions - An array of mission objects. Each mission object should contain properties
   * such as `type`, `lawyer_step`, and `steps.sub` that are used to filter and categorize the missions.
   * @returns {Object} An object containing the summarized statistics for the lawyer's missions.
   *
   * @example
   * const missions = [/* Array of mission objects * /];
   * const statistics = User.getLawyerStatisticMissions(missions);
   * console.log(statistics);
   */
  static getLawyerStatisticMissions(missions) {
    const sub_steps = [
      'PROJECT_IMPLEMENTATION',
      'INTERMEDIATE_PROJECT_SUBMISSION',
      'SYNDIC_VALIDATION',
      'FINAL_PROJECT_SUBMISSION'
    ];
    const missionsValidatedPCS1 = missions.filter(
      (mission) =>
        mission.type === 'PCS_1' && mission.lawyer_step === 'AG_DECISION'
    ).length;
    const missionsInProgressPCS1 = missions.filter(
      (mission) =>
        mission.type === 'PCS_1' && mission.lawyer_step === 'LAWYER_SEIZURE'
    ).length;
    const missionsInProgressPCS2 = missions.filter(
      (mission) =>
        mission.type === 'PCS_2' && mission.steps.sub === 'FINALIZATION'
    ).length;
    const missionsValidatedPCS2 = missions.filter(
      (mission) =>
        mission.type === 'PCS_2' && sub_steps.includes(mission.steps.sub)
    ).length;
    const totalDocumentsValidated =
      missionsValidatedPCS1 + missionsValidatedPCS2;
    const totalDocumentsInProgress =
      missionsInProgressPCS2 + missionsInProgressPCS1;

    return {
      missionsValidatedPCS1,
      missionsValidatedPCS2,
      missionsInProgressPCS1,
      missionsInProgressPCS2,
      totalDocumentsValidated,
      totalDocumentsInProgress
    };
  }
}
