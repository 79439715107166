/* eslint-disable react/forbid-prop-types */
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';

const { Option } = Select;

/**
 * @description SelectorSearch component
 * @param {Object} props - The props object.
 * @param {Function} props.filterOption - The filterOption function.
 * @param {boolean} props.loading - The loading flag.
 * @param {string} props.placeholder - The placeholder text.
 * @param {Array} props.data - The data array.
 * @param {Array} props.indexes - The indexes array.
 * @param {string} props.navigation - The navigation string.
 * @returns {JSX.Element} - The JSX element.
 */

const SelectorSearch = ({
  filterOption,
  loading,
  placeholder,
  data,
  indexes,
  navigation,
  isNavigable = true,
  customAction,
  width = 250,
  forceRefresh,
  setForceRefresh,
  onClear,
  extraAction
}) => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('');

  return (
    <Select
      style={{
        width,
        marginRight: 10
      }}
      value={selectedValue ? '' : null}
      showSearch
      allowClear
      onClear={() => {
        onClear();
        setSelectedValue('');
      }}
      optionFilterProp="children"
      filterOption={filterOption}
      onSelect={(value) => {
        setSelectedValue(value);
        if (isNavigable) {
          navigate(navigation + value);
        }
        customAction(value);
        extraAction();
        setForceRefresh(!forceRefresh);
        setSelectedValue('');
      }}
      loading={loading}
      placeholder={placeholder}
    >
      {(data || []).map((item) => (
        <Option value={item._id} key={item._id} element={item}>
          {(() => {
            if (indexes.length > 1) {
              return indexes.map((index) => `${item[index] || ''} `).join(' ');
            }
            return `${item[indexes[0]] || ''} `;
          })()}
        </Option>
      ))}
    </Select>
  );
};

SelectorSearch.defaultProps = {
  filterOption: true,
  loading: false,
  placeholder: '',
  data: [],
  indexes: [],
  navigation: '',
  isNavigable: true,
  customAction: () => {},
  width: 250,
  forceRefresh: false,
  setForceRefresh: () => {},
  extraAction: () => {},
  onClear: () => {}
};

SelectorSearch.propTypes = {
  filterOption: PropTypes.any,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  indexes: PropTypes.arrayOf(PropTypes.string),
  navigation: PropTypes.string,
  isNavigable: PropTypes.bool,
  customAction: PropTypes.func,
  width: PropTypes.any,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  extraAction: PropTypes.func,
  onClear: PropTypes.func
};

export default SelectorSearch;
