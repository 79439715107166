import { createContext, useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { GetEnums } from '../utils/getEnums';
import { Message } from '../models/Message';
import { PredefinedMessage } from '../models/PredefinedMessage';
import { useSocketContext } from './SocketContext';
import { useErrorMessage } from '../utils/errorMessage';

export const MessageContext = createContext();

/**
 * Provides a context for managing messages.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @param {string} props.id - The document ID.
 * @param {string} props.model - The model.
 * @param {boolean} props.showAllMessages - Flag indicating if all messages should be shown.
 * @returns {JSX.Element} The message context provider component.
 */
export const MessageContextProvider = ({
  children,
  id,
  model,
  showAllMessages
}) => {
  const { message } = useErrorMessage();
  const { forceRefresh } = useSocketContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [extraQuery, setExtraQuery] = useState('');
  const [form] = Form.useForm();
  const [enums, setEnums] = useState({});
  const { getEnums } = GetEnums();
  const [recipients, setRecipients] = useState([]);
  const [messages, setMessages] = useState([]);
  const [predefinedMessages, setPredefinedMessages] = useState([]);
  const [activeCard, setActiveCard] = useState(null);

  const handleExtraQuery = () => {
    if (showAllMessages && !extraQuery) {
      return `&showAllMessages=${showAllMessages}`;
    }
    if (showAllMessages && extraQuery) {
      return `&showAllMessages=${showAllMessages}${extraQuery}`;
    }
    return extraQuery;
  };

  const getResources = async () => {
    try {
      const [
        enumsDatas,
        recipientsDatas,
        messagesDatas,
        predefinedMessagesDatas
      ] = await Promise.all([
        getEnums({ resource: 'missions' }),
        Message.getRecipients(id, model),
        Message.getResources({
          id,
          model,
          extraQuery: handleExtraQuery()
        }),
        PredefinedMessage.getResources()
      ]);
      setEnums(enumsDatas);
      setRecipients(recipientsDatas);
      setMessages(messagesDatas);
      setPredefinedMessages(predefinedMessagesDatas);
      setIsFieldsLoading(false);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getResources();
    })();
  }, [extraQuery, forceRefresh]);

  return (
    <MessageContext.Provider
      value={{
        isFieldsLoading,
        form,
        enums,
        recipients,
        messages,
        predefinedMessages,
        setExtraQuery,
        setActiveCard,
        activeCard,
        id,
        model,
        showAllMessages
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageContext = () => {
  const context = useContext(MessageContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};

MessageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  showAllMessages: PropTypes.bool
};

MessageContextProvider.defaultProps = {
  showAllMessages: false
};
