/**
 * Generates an array of collective ownerships based on the provided status.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {string} options.status - The status of the collective ownerships.
 * @param {string} options.purpose - The purpose of the collective ownerships.
 * @returns {Array} - An array of collective ownerships.
 */
export const collectiveOwnershipsDragger = ({ status, purpose }) => {
  if (['DRAFT', 'ASSISTANT'].includes(status) || purpose === 'create') {
    return [
      {
        name: ['collective_ownerships_import'],
        key: 'collective_ownerships-import',
        input: 'File'
      }
    ];
  }
  return [];
};
