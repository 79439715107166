import { Row, Col, Badge } from 'antd';
import { ListDocuments } from '../ShowCollectiveOwnership/Items/subComponents/ListDocuments';
import { useCollectiveOwnershipContext } from '../../../contexts/CollectiveOwnershipContext';

/**
 * Sets the collapse items for the mission view.
 *
 * @hook
 * @returns {Array} The array of collapse items.
 */
export const setCollapseItems = () => {
  const { configDocuments, documents, documentType, missionTypeForDocuments } =
    useCollectiveOwnershipContext();

  const documentRepositoriesDatas = configDocuments.document_repositories;

  const filterDocuments = (document_repository) =>
    documents.filter((document) => {
      const matchesKey = document?.config_document
        ? document?.config_document?.document_type?.document_key ===
          document_repository?.document_key
        : document?.document_key === document_repository?.document_key;

      const matchesRef =
        missionTypeForDocuments ||
        document?.config_document_ref === documentType;

      return matchesKey && matchesRef;
    });

  const documentRepositoriesDatasArray = Array.isArray(
    documentRepositoriesDatas
  )
    ? documentRepositoriesDatas
    : [];

  const collapseItems = (documentRepositoriesDatasArray || []).map(
    (document_repository, index) => {
      const filteredDocuments = filterDocuments(document_repository);
      const documentNumber = filteredDocuments.length;

      const searchByConfigDocumentRef = () => {
        switch (document_repository.wording) {
          case 'Projet de mise en conformité intermédiaire':
            return false;
          default:
            return true;
        }
      };

      return {
        key: String(index),
        label: (
          <Row gutter={[16, 16]}>
            <Col>{document_repository.wording}</Col>
            <Col>
              <Badge count={documentNumber} />
            </Col>
          </Row>
        ),
        children: (
          <ListDocuments
            search_by_document_ref={searchByConfigDocumentRef()}
            columns_type={document_repository.columns_type}
            document_repository_key={document_repository.document_key}
          />
        )
      };
    }
  );

  return { collapseItems };
};
