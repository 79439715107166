import { message as antdMessage } from 'antd';
import dayjs from 'dayjs';

/**
 * Checks if the provided resource body can be updated without conflicting with existing prices.
 * @function
 * @param {Object} options - The options object.
 * @param {Array} options.prices - The array of existing prices.
 * @param {Object} options.data - The data object representing the resource body to be updated.
 * @param {Object} options.priceToEdit - The price object to be edited.
 * @param {Function} options.t - The translation function.
 * @returns {Object|null} - The updated resource body if it can be updated, or null if there is a conflict.
 */
export const onUpdateResourceBody = ({ prices, data, priceToEdit, t }) => {
  let isPeriodTaken = false;

  prices
    .filter(
      (el) =>
        el.mission === data.mission &&
        el.type === data.type &&
        el._id !== priceToEdit._id
    )
    .forEach((rate) => {
      if (data.end_date && rate.end_date) {
        if (
          dayjs(data.start_date).startOf('day') <= dayjs(rate.start_date) &&
          dayjs(data.end_date).endOf('day') >= dayjs(rate.end_date)
        ) {
          if (isPeriodTaken) return;
          antdMessage.warning(t('price-systems.messages.period_taken'));
          isPeriodTaken = true;
        }
      } else if (
        dayjs(data.start_date).startOf('day') <=
        dayjs(rate.start_date).startOf('day')
      ) {
        if (isPeriodTaken) return;
        antdMessage.warning(t('price-systems.messages.period_taken'));
        isPeriodTaken = true;
      }
    });

  if (isPeriodTaken) return null;

  return data;
};
