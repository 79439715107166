import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListResource, PageHeaderCustom } from '../../../components';
import { useColumns } from './columns';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import ExtraSearchBarOptions from './ExtraSearchBarOptions';
import { CollectiveOwnership } from '../../../models/CollectiveOwnership';

/**
 * @component
 * @returns {JSX.Element} The rendered ListPaymentFollowUps component.
 *
 * @example
 * return (
 *   <ListPaymentFollowUps />
 * )
 */
export const ListTreasuryPublications = () => {
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [filters, setFilters] = useState({});
  const [extraQuery, setExtraQuery] = useState('');
  const columns = useColumns({ t });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { syndics } = useGlobalContext();
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const data = await CollectiveOwnership.getResources({
        populate: '',
        extraQuery: ''
      });
      setCollectiveOwnerships(data);
      setIsDataLoading(false);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <>
      <PageHeaderCustom
        title={t('treasury_publications.titles.treasury_publication_follow_up')}
      />
      <ListResource
        style={{ margin: '0' }}
        resourceName="collective-ownerships/treasury-publications"
        resourceModelName="collective-ownership"
        columns={columns}
        withCreateButton={false}
        withPageHeader={false}
        withSearchBar
        extraQuery={extraQuery}
        scroll={{ x: 'max-content' }}
        customActionColumn
        forceRefresh={forceRefresh}
        searchBarExtraButtons={
          <ExtraSearchBarOptions
            setFilters={setFilters}
            syndics={syndics}
            isDataLoading={isDataLoading}
            setForceRefresh={setForceRefresh}
            collective_ownerships={collectiveOwnerships}
          />
        }
      />
    </>
  );
};
