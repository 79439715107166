import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer, Radio } from 'antd';
import { useFields } from './useFields';
import { CreateUpdateContainer } from '../../../../../components';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';

/**
 * CreateManagerDrawer component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isDrawerOpen - Flag indicating whether the drawer is open or not.
 * @param {Function} props.setIsDrawerOpen - Function to set the state of the drawer.
 * @param {Function} props.setRefresh - Function to set the refresh state.
 * @param {boolean} props.refresh - Flag indicating whether to refresh the component.
 * @returns {JSX.Element|null} The CreateManagerDrawer component.
 */
export const CreateManagerDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  setRefresh,
  refresh
}) => {
  if (!isDrawerOpen) return null;
  const [isAssitant, setIsAssitant] = useState(false);
  const { currentSyndic } = useGlobalContext();
  const { t } = useTranslation();
  const options = [
    { label: t('pcs-pre-productions.form.manager'), value: false },
    { label: t('pcs-pre-productions.form.assistant'), value: true }
  ];
  const { fields } = useFields({ isAssitant });

  const role = isAssitant ? 'users:SYNDIC_ASSISTANT' : 'users:SYNDIC_MANAGER';
  const useConfig = () => ({
    onCreateResource: {
      setBody: (body) => {
        const updatedBody = {
          ...body,
          managers: [body?.managers],
          syndic: currentSyndic,
          role
        };
        return updatedBody;
      }
    }
  });

  const config = useConfig();

  return (
    <Drawer
      title={t('missions.drawers.titles.collective_ownership_form')}
      onClose={() => setIsDrawerOpen(false)}
      open={isDrawerOpen}
      width={500}
    >
      <Radio.Group
        value={isAssitant}
        onChange={(e) => setIsAssitant(e.target.value)}
        options={options}
        optionType="button"
        buttonStyle="solid"
      />
      <CreateUpdateContainer
        fields={fields}
        config={config}
        purpose="create"
        baseUrl="users"
        resource="users"
        setForceRefresh={() => {
          setIsDrawerOpen(false);
          setRefresh(!refresh);
        }}
        forceRefresh={refresh}
        cancelCustomAction={() => setIsDrawerOpen(false)}
        withFilesManager={false}
        withEnums={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{ ...isDrawerOpen, intermediate_project: false }}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};

CreateManagerDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired
};
