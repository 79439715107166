/**
 * Custom hook for handling configuration updates.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {string} options.editorState - The current editor state.
 * @param {Function} options.setEditorState - The function to set the editor state.
 * @returns {Object} - The configuration object.
 */
export const useConfig = ({ editorState, setEditorState }) => ({
  onCreateResource: {
    setBody: (data) => {
      const newData = { ...data, content: editorState };
      return newData;
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const newData = { ...data, content: editorState };
      return newData;
    }
  },
  onGetResource: {
    setFields: (data) => {
      setEditorState(data.content);
      return data;
    }
  }
});
