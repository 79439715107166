import { getChargingGrid } from './getChargingGrid';

/**
 * Deletes a charging grid.
 * @async
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.chargingGrid - The charging grid object to be deleted
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Object} params.resourceDatas - The collective ownership object associated with the charging grid
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setResourceDatas - Function for setting collective ownership data
 * @param {Function} params.setIsVersionButton - Function for setting version button state
 * @throws {Error} Throws an error if the API request fails
 * @returns {Promise<void>} - A promise that resolves after the charging grid is deleted
 */

export const deleteChargingGrid = async ({
  chargingGrid,
  dispatchAPI,
  resourceDatas,
  setChargingGrid,
  setResourceDatas,
  setIsVersionButton
}) => {
  const { data } = await dispatchAPI('DELETE', {
    url: `/charging-grids/${chargingGrid._id}?collective_ownership=${resourceDatas._id}`
  });

  setResourceDatas(data);
  getChargingGrid({
    resourceDatas: data,
    setChargingGrid,
    setIsVersionButton
  });
};
