import {
  Checkbox,
  Divider,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Tooltip,
  Button,
  Table,
  InputNumber,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { setDocumentIcon } from '../../../../../components/setDocumentIcon';
import { useColumns } from '../columns/lotEnjoymentRightColumns';
import { Mission } from '../../../../../models/Mission';

const { Title } = Typography;
export const useFields = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { finalizedInformations, mission, quotations } = useMissionContext();
  const { viewDocument, downloadDocument } = useDownloadDocument();
  const columns = useColumns({});

  const fields = [
    {
      startWithDivider: {
        orientation: 'left',
        title: (
          <Title level={4} style={{ marginTop: 0 }}>
            {t('missions.dividers.finalization', {
              type: t(`missions.tags.${mission?.phase_two_type}`)
            })}
          </Title>
        )
      },
      label: 'created_modified_lot_number',
      key: 'created_modified_lot_number',
      dataIndex: 'created_modified_lot_number',
      input: (
        <Form.Item name={['created_modified_lot_number']}>
          <InputNumber />
        </Form.Item>
      )
    },
    {
      noLabel: true,
      key: 'rcp_documents',
      input: (finalizedInformations?.rcp_documents || []).map((doc) => (
        <>
          <Divider orientation="left">{doc.config_document.wording}</Divider>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name={['rcp_documents', doc._id, 'editor_notary']}>
                    <Input placeholder={t('placeholder.editor_notary')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={['rcp_documents', doc._id, 'writting_date']}>
                    <DatePicker
                      format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                      placeholder={t('placeholder.writting_date')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item
                    name={['rcp_documents', doc._id, 'spf_number']}
                    label="SPF"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['rcp_documents', doc._id, 'ref_doc']}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['rcp_documents', doc._id, 'date']}>
                    <DatePicker
                      format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col>
                  {(doc.files || []).map((file) => (
                    <Tooltip title={file.metadata.originalName}>
                      <Button
                        type="link"
                        onClick={() =>
                          file.type === 'application/pdf'
                            ? viewDocument(file)
                            : downloadDocument({
                                _id: file._id,
                                metadata: file.metadata,
                                contentType: file.contentType
                              })
                        }
                      >
                        {setDocumentIcon(file.contentType)}
                      </Button>
                      {file.metadata.originalName}
                    </Tooltip>
                  ))}
                </Col>
              </Row>
              {doc.config_document.wording === "Modificatif à l'EDD" ? (
                <Form.Item
                  name={['rcp_documents', doc._id, 'amended_article_of_rc']}
                  label={t('missions.form.amended_article_of_rc')}
                >
                  <Input />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
        </>
      ))
    },
    {
      startWithDivider: {
        orientation: 'left',
        title: t('missions.dividers.lot_enjoyment_right')
      },
      noLabel: true,
      key: 'lot_enjoyment_right',
      input: (
        <Table
          tableLayout="auto"
          dataSource={finalizedInformations?.pcajp || []}
          columns={columns}
        />
      )
    },
    {
      name: ['final_document'],
      key: 'final_document',
      maxFilesCount: 100,
      input: 'File',
      documentRepositoryType: 'Finalisation de la mise en conformité',
      title: 'Document finalisé',
      labelCol: { span: 24 }
    },
    {
      name: ['document_pcs_ag_3_resolution'],
      key: 'document_pcs_ag_3_resolution',
      maxFilesCount: 100,
      input: 'File',
      documentRepositoryType: 'Finalisation de la mise en conformité',
      title: 'Document PCS - AG 3 Résolution',
      labelCol: { span: 24 }
    },
    {
      noLabel: true,
      valuesPropName: 'checked',
      name: ['validate_document'],
      key: 'validate_document',
      input: <Checkbox>{t(`missions.form.validate_document`)}</Checkbox>
    },
    {
      label: 'publication_quotation',
      key: 'publication_quotation',
      input: (
        <Button
          onClick={() =>
            Mission.navigateToCreateUpdateQuotation({
              quotations,
              navigate,
              mission,
              mission_type: 'PCS_PUBLICATION_QUOTE'
            })
          }
        >
          {t('missions.buttons.generate_quotation')}
        </Button>
      )
    }
  ];

  return { fields };
};
