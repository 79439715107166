import { BaseModel } from './BaseModel';

/**
 * Represents a PCS appointment monitoring.
 * @class
 */
export class PCSappointmentMonitoring extends BaseModel {
  static resourceName = 'pcs-appointment-monitoring';

  /**
   * Fetches all PCS appointment monitoring data.
   * @function
   * @async
   * @returns {Promise<Object[]>} A promise that resolves to an array of PCS appointment monitoring data.
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${PCSappointmentMonitoring.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Updates the PCS appointment monitoring data.
   * @function
   * @async
   * @param {Object} options - The options for updating the PCS appointment monitoring data.
   * @param {string} options.id - The ID of the PCS appointment monitoring data to update.
   * @param {Object} options.values - The values to update the PCS appointment monitoring data with.
   * @returns {Promise<Object>} A promise that resolves to the updated PCS appointment monitoring data.
   */
  static async updateResource({ id, values }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('PATCH', {
      url: `${PCSappointmentMonitoring.resourceName}/${id}`,
      body: formData
    });

    return data;
  }

  /**
   * Updates the PCS appointment monitoring data.
   * @function
   * @async
   * @param {Object} options - The options for updating the PCS appointment monitoring data.
   * @param {string} options.id - The ID of the PCS appointment monitoring data to update.
   * @param {string} options.pcs_id - The ID of the PCS appointment monitoring data to update.
   * @param {Object} options.values - The values to update the PCS appointment monitoring data with.
   * @param {string} options.type - The type of PCS appointment monitoring data to update.
   * @param {string} options.colunm - The column of PCS appointment monitoring data to update.
   * @returns {Promise<Object>} A promise that resolves to the updated PCS appointment monitoring data.
   */
  static updatePCS_X_date = async ({ id, pcs_id, values, type, column }) => {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    await this.dispatchAPI('PATCH', {
      url: `${PCSappointmentMonitoring.resourceName}/process-pcs-date/${id}?type=${type}&pcs_id=${pcs_id}&column=${column}`,
      body: formData
    });
  };

  /**
   * Creates a new PCS appointment monitoring data.
   * @function
   * @async
   * @param {Object} options - The options for creating a new PCS appointment monitoring data.
   * @param {string} options.id - The ID of the PCS appointment monitoring data to create.
   * @param {Object} options.values - The values to create the PCS appointment monitoring data with.
   * @param {string} options.type - The type of PCS appointment monitoring data to create.
   * @param {string} options.colunm - The column of PCS appointment monitoring data to create.
   * @returns {Promise<Object>} A promise that resolves to the created PCS appointment monitoring data.
   */
  static async createPCS_X_date({ id, values, type, column }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('POST', {
      url: `${PCSappointmentMonitoring.resourceName}/process-pcs-date/${id}?column=${column}&type=${type}`,
      body: formData
    });
    return data;
  }
}
