import { Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { actions_to_do } from '../../../../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../../utils/errorMessage';

/**
 * Custom hook for handling invoice collapse items.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {string} options.tradKey - The translation key.
 * @param {Object} options.mission - The mission object.
 * @returns {Array} - An array of collapse items.
 */
export const useInvoiceCollapseItems = ({
  tradKey,
  mission,
  setUnpaidInvoices
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [invoices, setInvoices] = useState({ PCS_1: [], PCS_2: [], PCS_3: [] });
  const { message } = useErrorMessage();

  const rowSelection = {
    getCheckboxProps: (record) => ({
      name: record.name
    })
  };

  const getInvoice = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoice?mission=${id}`
      });
      const missions_pcs1 = data.filter((el) => el.pcs_step === 'PCS_1');
      const missions_pcs2 = data.filter((el) => el.pcs_step === 'PCS_2');
      const missions_pcs3 = data.filter((el) => el.pcs_step === 'PCS_3');

      const getUnpaidInvoices = (pcsStep, status) =>
        data.filter(
          (el) =>
            el.pcs_step === pcsStep &&
            el.status === status &&
            ['ADVANCE_PAYMENT', 'BALANCE'].includes(el.type)
        );

      const pcs_1_unpaid = getUnpaidInvoices('PCS_1', 'UNPAID');
      const pcs_2_unpaid = getUnpaidInvoices('PCS_2', 'UNPAID');
      const pcs_3_unpaid = getUnpaidInvoices('PCS_3', 'UNPAID');

      setUnpaidInvoices({
        PCS_1: pcs_1_unpaid.length > 0,
        PCS_2: pcs_2_unpaid.length > 0,
        PCS_3: pcs_3_unpaid.length > 0
      });

      setInvoices({
        PCS_1: missions_pcs1,
        PCS_2: missions_pcs2,
        PCS_3: missions_pcs3
      });
    } catch (error) {
      message(error);
    }
  };

  const renderPaymentStatus = (status) => {
    if (status === 'UNPAID') {
      return (
        <Tag color={actions_to_do.UNPAID_INVOICE}>
          {t(`missions.tags.UNPAID_INVOICE`)}
        </Tag>
      );
    }
    if (status === 'PAID') {
      return (
        <Tag color={actions_to_do.PAID_INVOICE}>
          {t(`missions.tags.PAID_INVOICE`)}
        </Tag>
      );
    }
    return '';
  };

  useEffect(() => {
    Promise.all([getInvoice()]).catch((error) => {
      message(error);
    });
  }, []);

  const columns = [
    {
      title: t('invoices.form.reference'),
      key: 'reference',
      render: (record) => {
        if (!record || typeof record !== 'object') return '';
        const { reference, type, total_ttc } = record;
        const typeLabel = type ? t(`invoices.tags.${type}`) : '';
        const referenceText = reference || '';
        const totalTtcText = total_ttc ? `(${total_ttc} €)` : '';
        return `${typeLabel} - ${referenceText} - ${totalTtcText}`;
      },
      sorter: true
    },
    {
      title: t('missions.tags.UNVALIDATED_QUOTE'),
      key: ['status'],
      dataIndex: ['status'],
      render: (status) => renderPaymentStatus(status)
    }
  ];

  return [
    {
      key: '5',
      label: t(`${tradKey}.invoices_pcs_1`),
      children: (
        <Table
          rowSelection={{
            ...rowSelection
          }}
          showHeader={false}
          columns={columns}
          rowKey="_id"
          dataSource={invoices.PCS_1}
        />
      )
    },
    ...(['PCS_2', 'PCS_3'].includes(mission.type)
      ? [
          {
            key: '6',
            label: t(`${tradKey}.invoices_pcs_2`),
            children: (
              <Table
                rowSelection={{
                  ...rowSelection
                }}
                showHeader={false}
                columns={columns}
                rowKey="_id"
                dataSource={invoices.PCS_2}
              />
            )
          }
        ]
      : []),
    ...(['PCS_3'].includes(mission.type)
      ? [
          {
            key: '7',
            label: t(`${tradKey}.invoices_pcs_3`),
            children: (
              <Table
                rowSelection={{
                  ...rowSelection
                }}
                showHeader={false}
                columns={columns}
                rowKey="_id"
                dataSource={invoices.PCS_3}
              />
            )
          }
        ]
      : [])
  ];
};
