import { Row, Col, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { MergeDuplicatesMethods } from './methods';

/**
 * Renders the action buttons for merging and saving in the SPF Departmental Archive component.
 *
 * @hook
 * @param {Object} props - The component props.
 * @param {string} props.firstId - The ID of the first item to merge.
 * @param {string} props.secondId - The ID of the second item to merge.
 * @param {function} props.navigate - The function to navigate to a different route.
 * @param {object} props.form - The form object.
 * @param {function} props.setForceRefresh - The function to set the force refresh state.
 * @param {boolean} props.forceRefresh - The force refresh state.
 * @param {string} props.resource - The resource type.
 * @returns {Object} - The merge buttons and save buttons components.
 */
export const actionButtons = ({
  firstId,
  secondId,
  navigate,
  form,
  setForceRefresh,
  forceRefresh,
  resource
}) => {
  const { t } = useTranslation();
  const { removeDuplicate, handleSubmit } = MergeDuplicatesMethods();

  const mergeButtons = (
    <Row justify="center">
      <Col>
        <Button
          onClick={() =>
            removeDuplicate({
              idToDelete: secondId,
              idToKeep: firstId,
              navigate,
              setForceRefresh,
              forceRefresh,
              resource
            })
          }
        >
          {t('buttons.merge_keep_first')}
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() =>
            removeDuplicate({
              idToDelete: firstId,
              idToKeep: secondId,
              navigate,
              setForceRefresh,
              forceRefresh,
              resource
            })
          }
        >
          {t('buttons.merge_keep_second')}
        </Button>
      </Col>
      <Col>
        <Button onClick={() => navigate(`/configurations/${resource}`)}>
          {t('buttons.ignore')}
        </Button>
      </Col>
    </Row>
  );

  const saveButtons = (
    <Row gutter={[16, 16]} justify="end">
      <Col>
        <Button type="link" danger onClick={() => navigate(-1)}>
          {`${t('buttons.cancel')} `}
          <CloseOutlined />
        </Button>
      </Col>

      <Col>
        <Button
          type="primary"
          onClick={() =>
            handleSubmit({
              form,
              navigate,
              setForceRefresh,
              forceRefresh,
              resource
            })
          }
        >
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
      </Col>
    </Row>
  );

  return { mergeButtons, saveButtons };
};
