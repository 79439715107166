import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Tag, Flex, Button, Steps, Row, Col, Spin, Popconfirm } from 'antd';
import {
  ContentCustom,
  Exception,
  PageHeaderCustom
} from '../../../components';
import { Order } from '../../../models/Order';
import { orderTag } from '../../../utils/constants/tagColors';
import { useExtraButtons } from './useExtraButtons';
import { useStepsItems } from './useStepsItems';
import { GetEnums } from '../../../utils/getEnums';
import { useInformationsListContent } from './useInformationsListContent';
import { History } from '../../../components/History/History';
import { InformationsCard } from './InformationsCard';
import { DocumentCard } from './DocumentCard';
import { GroupOrdersCard } from './GroupOrdersCard';

/**
 * Component for displaying details of a specific order.
 * @component
 * @returns {JSX.Element} JSX element representing the ShowOrder component.
 */
export const ShowOrder = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [order, setOrder] = useState({});
  const extraButtons = useExtraButtons();
  const [enums, setEnums] = useState({});
  const stepsItems = useStepsItems({ enums, order });
  const { getEnums } = GetEnums();
  const informationsListContent = useInformationsListContent(order);
  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await Order.getResource({
        id
      });
      setOrder(data);
      setFiles(data?.document?.files);
      setIsLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      const data = await Order.getResource({
        id
      });
      setOrder(data);
      setFiles(data?.document?.files);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    (async () => {
      const orderEnums = await getEnums({ resource: 'orders' });
      const docEnums = await getEnums({ resource: 'documents' });
      setEnums({
        orderEnums,
        docEnums
      });
    })();
  }, []);

  if (!order) return <Exception />;

  return (
    <>
      <PageHeaderCustom
        title={
          <Flex justify="flex-start" align="center">
            {order && order.cheque_number
              ? t('orders.show.title.ORDERED', {
                  order: order.cheque_number || '-',
                  wording: order?.cerfa_document_type?.wording || '-'
                })
              : t('orders.show.title.TO_BE_ORDERED', {
                  wording: order?.cerfa_document_type?.wording || '-'
                })}
            {order.urgent && (
              <Tag
                color={orderTag.URGENT}
                style={{ height: 'fit-content', marginLeft: 16 }}
              >
                {t('orders.tags.URGENT')}
              </Tag>
            )}
          </Flex>
        }
        extra={
          <>
            {extraButtons.map(
              ({ type, label, icon, danger, onClick, popConfirm }) => {
                if (popConfirm) {
                  return (
                    <Popconfirm
                      title={popConfirm.title}
                      okText={popConfirm.okText}
                      okButtonProps={popConfirm.okButtonProps}
                      cancelText={popConfirm.cancelText}
                      onConfirm={() => popConfirm.onConfirm(id)}
                      icon={popConfirm.icon}
                    >
                      <Button type={type} danger={danger} onClick={onClick}>
                        {icon}
                        {label}
                      </Button>
                    </Popconfirm>
                  );
                }
                return (
                  <Button type={type} danger={danger} onClick={onClick}>
                    {icon}
                    {label}
                  </Button>
                );
              }
            )}
          </>
        }
      />
      <ContentCustom>
        {enums && enums.docEnums && (
          <Steps
            current={
              enums.docEnums.status.indexOf(order.document?.status) + 1 !==
                -1 && order.status !== enums.orderEnums.status[0]
                ? enums.docEnums.status.indexOf(order.document?.status) + 1
                : 0
            }
            items={stepsItems}
            style={{ marginBottom: 16 }}
          />
        )}
        {order && order._id && (
          <Row gutter={[16, 16]}>
            <Col sm={24} md={24} lg={14}>
              <InformationsCard
                informationsListContent={informationsListContent}
              />{' '}
            </Col>
            <Col sm={24} md={24} lg={10}>
              {enums &&
              enums.docEnums.status.indexOf(order.document?.status) !== -1 &&
              order.status === enums.orderEnums.status[0] ? null : (
                <DocumentCard
                  files={files}
                  isLoading={isLoading}
                  order={order}
                  setForceRefresh={setForceRefresh}
                />
              )}

              {order && order.history.length ? (
                <History
                  isLoading={isLoading}
                  historic={order?.history}
                  withLabels={false}
                />
              ) : null}
            </Col>
            <Col span={24}>
              <GroupOrdersCard
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
                order={order}
              />
            </Col>
          </Row>
        )}
        <Spin spinning={isLoading} tip={t('global.loading')} fullscreen />
      </ContentCustom>
    </>
  );
};
