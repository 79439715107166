import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EuroOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Building } from '@carbon/icons-react';
import { Badge, Collapse } from 'antd';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { useInvoiceCollapseItems } from './collapseItems/useInvoiceCollapseItems';
import { useCollectiveOwnershipDocumentsCollapseItems } from './collapseItems/useCollectiveOwnershipDocuments';

const tradKey = 'syndic-view.collective-ownerships.show';
const iconStyle = { marginRight: '1rem' };

/**
 * Represents a component that displays collapsible documents.
 * @component
 * @param {Object} badges The badges for the documents.
 * @returns {JSX.Element} The rendered DocumentsCollapse component.
 */
export const DocumentsCollapse = ({ badges }) => {
  const { dispatchAPI } = useAuthContext();
  const [mission, setMission] = useState({});
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [updaidInvoices, setUnpaidInvoices] = useState({
    PCS_1: true,
    PCS_2: true,
    PCS_3: true
  });
  const invoiceItems = useInvoiceCollapseItems({
    tradKey,
    mission,
    setUnpaidInvoices
  });
  const {
    collectiveOwnershipDocumentsItems,
    PCS_2_Documents,
    PCS_3_Documents,
    PHASE_1_DOCUMENTS
  } = useCollectiveOwnershipDocumentsCollapseItems({
    id,
    mission,
    updaidInvoices,
    tradKey
  });

  const getMission = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/mission/${id}?fields=type`
    });
    setMission(data);
  };

  useEffect(() => {
    Promise.all([getMission()]).catch((error) => {
      message(error);
    });
  }, []);

  const itemsCollapse = [
    {
      key: '1',
      label: (
        <>
          <EuroOutlined style={iconStyle} />
          {t(`${tradKey}.invoices`)}
          {badges.UNPAID_INVOICE > 0 && (
            <Badge
              count={badges.UNPAID_INVOICE}
              style={{ marginLeft: '1rem' }}
              color="red"
            />
          )}
          {badges.PAID_INVOICE > 0 && (
            <Badge
              count={badges.PAID_INVOICE}
              style={{ marginLeft: '1rem' }}
              color="green"
            />
          )}
        </>
      ),
      children: (
        <Collapse defaultActiveKey="1" bordered={false} items={invoiceItems} />
      )
    },
    {
      key: '2',
      label: (
        <>
          <Building style={iconStyle} />
          {t(`${tradKey}.collective_ownership_documents`)}
          {collectiveOwnershipDocumentsItems.filesCount > 0 && (
            <Badge
              count={collectiveOwnershipDocumentsItems.filesCount}
              style={{ marginLeft: '1rem' }}
              color="red"
            />
          )}
        </>
      ),
      children: (
        <Collapse
          defaultActiveKey="1"
          bordered={false}
          items={collectiveOwnershipDocumentsItems.items}
        />
      )
    },
    ...(!updaidInvoices.PCS_1
      ? [
          {
            key: '3',
            label: (
              <>
                <Badge count={1} color="grey" style={iconStyle} />
                {t(`${tradKey}.PHASE_1_documents`)}
                {PHASE_1_DOCUMENTS.filesCount > 0 && (
                  <Badge
                    count={PHASE_1_DOCUMENTS.filesCount}
                    style={{ marginLeft: '1rem' }}
                    color="red"
                  />
                )}
              </>
            ),
            children: (
              <Collapse
                defaultActiveKey="1"
                bordered={false}
                items={PHASE_1_DOCUMENTS.items}
              />
            )
          }
        ]
      : []),
    ...(!updaidInvoices.PCS_2 && ['PCS_2', 'PCS_3'].includes(mission.type)
      ? [
          {
            key: '4',
            label: (
              <>
                <Badge count={2} color="grey" style={iconStyle} />
                {t(`${tradKey}.PHASE_2_documents`)}
                {PCS_2_Documents.filesCount > 0 && (
                  <Badge
                    count={PCS_2_Documents.filesCount}
                    style={{ marginLeft: '1rem' }}
                    color="red"
                  />
                )}
              </>
            ),
            children: (
              <Collapse
                defaultActiveKey="1"
                bordered={false}
                items={PCS_2_Documents.items}
              />
            )
          }
        ]
      : []),
    ...(!updaidInvoices.PCS_2 && ['PCS_3'].includes(mission.type)
      ? [
          {
            key: '5',
            label: (
              <>
                <Badge count={3} color="grey" style={iconStyle} />
                {t(`${tradKey}.PHASE_3_documents`)}
                {PCS_3_Documents.filesCount > 0 && (
                  <Badge
                    count={PCS_3_Documents.filesCount}
                    style={{ marginLeft: '1rem' }}
                    color="red"
                  />
                )}
              </>
            ),
            children: (
              <Collapse
                defaultActiveKey="1"
                bordered={false}
                items={PCS_3_Documents.items}
              />
            )
          }
        ]
      : [])
  ];

  return <Collapse items={itemsCollapse} />;
};

DocumentsCollapse.propTypes = {
  badges: PropTypes.shape({
    UNPAID_INVOICE: PropTypes.number,
    PAID_INVOICE: PropTypes.number
  })
};

DocumentsCollapse.defaultProps = {
  badges: {}
};
