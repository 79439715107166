import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import {
  missionStatus,
  missionTag
} from '../../../../../utils/constants/tagColors';

/**
 * Custom hook that returns an array of columns for a table.
 * @hook
 * @param {Object} options - The options for the hook.
 * @returns {Array} An array of columns for the table.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('collective-ownerships.form.collective_ownership_address'),
      key: ['collective_ownership'],
      dataIndex: ['collective_ownership'],
      render: (record) => {
        const first_address = record?.first_address || {};
        const first_city = record?.first_city || {};
        const {
          street = '',
          additional = '',
          postal_code = ''
        } = first_address;
        const { name: city_name = '' } = first_city;
        return `${
          record?.name ? `${record.name} - ` : ''
        }${street} ${additional} ${postal_code}${
          city_name ? `, ${city_name}` : ''
        }`;
      }
    },
    {
      title: t('collective-ownerships.form.mission'),
      key: ['requested_mission_type'],
      dataIndex: ['requested_mission_type'],
      render: (mission) => {
        const record = mission === 'PCS_PRE_PRODUCTION' ? 'AUDIT' : mission;
        return (
          <Tag
            color={missionTag[record]}
            style={{
              display: 'block',
              marginBottom: '8px',
              textAlign: 'center'
            }}
          >
            {t(`missions.tags.${record}`)}
          </Tag>
        );
      }
    },
    {
      title: t('collective-ownerships.form.status'),
      key: ['status'],
      dataIndex: ['status'],
      render: (status) => (
        <Tag
          color={missionStatus[status]}
          style={{
            display: 'block',
            marginBottom: '8px',
            textAlign: 'center'
          }}
        >
          {status ? (
            <>
              {t(`missions.status.${status}`)}
              <br />
            </>
          ) : (
            ''
          )}
        </Tag>
      )
    },
    {
      title: t('collective-ownerships.form.last_update'),
      key: ['updated_at'],
      dataIndex: ['updated_at'],
      render: (date) => dayjs(date).format('DD/MM/YYYY HH:mm')
    }
  ];
};
