import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns the columns configuration based on the provided type.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {string} options.type - The type of columns to retrieve.
 * @returns {Array} - The columns configuration for the specified type.
 */
export const useColumns = ({ type }) => {
  const { t } = useTranslation();

  const cheques = [
    {
      title: t('accountant-exports.form.cheques_number'),
      dataIndex: 'cheques_number',
      key: 'cheques_number',
      render: (cheques_number) => cheques_number || '-'
    },
    {
      title: t('accountant-exports.form.date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => (date ? dayjs(date).format('DD-MM-YYYY') : '-')
    },
    {
      title: t('accountant-exports.form.conservation'),
      dataIndex: 'conservation',
      key: 'conservation',
      render: (conservation) => conservation || '-'
    },
    {
      title: t('accountant-exports.form.cheque_amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (amount ? `${amount} €` : '-')
    }
  ];

  const disbursements = [
    {
      title: t('accountant-exports.form.date'),
      key: 'date',
      render: (record) => {
        if (record.date === 'Total') return record.date;
        if (record.date) return dayjs(record.date).format('DD-MM-YYYY');
        return '-';
      }
    },
    {
      title: t('accountant-exports.form.syndic'),
      dataIndex: 'syndic',
      key: 'syndic',
      render: (syndic) => syndic || '-'
    },
    {
      title: t('missions.form.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: 'collective_ownership',
      render: (collective_ownership) =>
        `${collective_ownership?.code || ''} - ${
          collective_ownership?.first_address?.number || ''
        } ${collective_ownership?.first_address?.street || ''} ${
          collective_ownership?.first_address?.postal_code || ''
        } ${collective_ownership?.first_address?.city || ''} ${
          collective_ownership?.cadastral_reference
            ? `(${collective_ownership.cadastral_reference})`
            : ''
        }`
    },
    {
      title: t('accountant-exports.form.piece'),
      dataIndex: 'piece',
      key: 'piece',
      render: (piece) => piece || '-'
    },
    {
      title: t('accountant-exports.form.disbursements_amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (amount ? `${amount} €` : '-')
    },
    {
      title: t('accountant-exports.form.pointing'),
      dataIndex: 'pointing',
      key: 'pointing',
      render: (pointing) => pointing || '-'
    }
  ];

  const costs = [
    {
      title: t('accountant-exports.form.date'),
      key: 'date',
      render: (record) => {
        if (record.date === 'Total') return record.date;
        if (record.date) return dayjs(record.date).format('DD-MM-YYYY');
        return '-';
      }
    },
    {
      title: t('accountant-exports.form.syndic'),
      dataIndex: 'syndic',
      key: 'syndic',
      render: (syndic) => syndic || '-'
    },

    {
      title: t('accountant-exports.form.collective_ownership'),
      dataIndex: 'collective_ownership',
      key: 'collective_ownership',
      render: (collective_ownership) => collective_ownership || '-'
    },
    {
      title: t('accountant-exports.form.piece'),
      dataIndex: 'piece',
      key: 'piece',
      render: (piece) => piece || '-'
    },
    {
      title: t('accountant-exports.form.costs_amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (amount ? `${amount} €` : '-')
    },
    {
      title: t('accountant-exports.form.pointing'),
      dataIndex: 'pointing',
      key: 'pointing',
      render: (pointing) => pointing || '-'
    }
  ];

  const columns = {
    cheques,
    disbursements,
    costs
  };

  return columns[type];
};
