import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Row, Col } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  MailOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { deleteResource } from '../../../../../utils/deleteResource';
import {
  routes,
  subRoutes
} from '../../../../../utils/constants/routes/adminRoutes';

/**
 * Renders the buttons for editing and deleting a PCS pre-production.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - The flag indicating whether to force refresh the component.
 * @returns {JSX.Element} The rendered component.
 */
export const ShowButtons = ({
  setForceRefresh,
  forceRefresh,
  buttonsPermissions
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { disableEditButton, disableDeleteButton } = buttonsPermissions;

  return (
    <Row gutter={[10]}>
      <Col>
        {!disableEditButton && (
          <Link
            to={{
              pathname: `${routes.MISSIONS}${subRoutes.MISSIONS.PCS_PRE_PRODUCTIONS}/edit/${id}`
            }}
          >
            <Button type="primary">
              <EditOutlined />
              {`${t('buttons.edit')} `}
            </Button>
          </Link>
        )}
      </Col>
      <Col>
        <Button type="primary">
          <MailOutlined />
          {`${t('buttons.reminder')} `}
        </Button>
      </Col>
      <Col>
        {!disableDeleteButton && (
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() =>
              deleteResource({
                dispatchAPI,
                resourceName: 'pcs-pre-productions',
                id,
                message,
                setForceRefresh,
                forceRefresh,
                user
              })
            }
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              <DeleteOutlined />
              {`${t('buttons.delete')} `}
            </Button>
          </Popconfirm>
        )}
      </Col>
    </Row>
  );
};

ShowButtons.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    access: PropTypes.string
  }),
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  buttonsPermissions: PropTypes.shape({
    disableEditButton: PropTypes.bool,
    disableDeleteButton: PropTypes.bool
  })
};

ShowButtons.defaultProps = {
  user: {},
  buttonsPermissions: {
    disableEditButton: false,
    disableDeleteButton: false
  }
};
