import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Tag, Divider, Button, Typography, Row } from 'antd';
import {
  DownloadOutlined,
  EuroOutlined,
  EyeOutlined,
  MailOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { LicenseDraft } from '@carbon/icons-react';
import { invoiceTag } from '../../../../../utils/constants/tagColors';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { EditableTableMethods } from '../../../../../components/editableTableComponents/methods';

const { Text } = Typography;

/**
 * Returns an array of columns configuration for the invoice table.
 * @hook
 * @param {string} missionType - The mission type.
 * @param {Function} setEditingKey - The function to set the editing key.
 * @param {Object} form - The form object.
 * @returns {Array} The array of columns configuration.
 */
export const useColumns = (missionType, setEditingKey, form) => {
  const { t } = useTranslation();
  const { setIsDrawerOpen } = useMissionContext();
  const { editCell } = EditableTableMethods();

  const setDisbursementData = (record) => {
    if (record?.row_type === 'amount_committed') {
      return (
        <Button
          type="link"
          onClick={() =>
            setIsDrawerOpen((prev) => ({
              ...prev,
              disbursement: {
                open: true,
                type: missionType
              }
            }))
          }
        >
          <Text style={{ textDecoration: 'underline' }}>
            {record?.disbursement ? `${record?.disbursement} €` : '0 €'}
          </Text>
        </Button>
      );
    }
    return record?.disbursement ? `${record?.disbursement} €` : '0 €';
  };

  return [
    {
      title: (
        <Text className="break-column-title">
          {t('invoices.form.reference')}
        </Text>
      ),
      key: 'reference',
      dataIndex: 'reference',
      render: (reference) => reference || '-',
      sorter: (a, b) =>
        a.reference.toLowerCase().localeCompare(b.reference.toLowerCase())
    },
    {
      title: t('invoices.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (type ? t(`invoices.tags.${type}`) : ''),
      sorter: (a, b) => {
        const typeA = a.type ? t(`invoices.tags.${a.type}`) : '';
        const typeB = b.type ? t(`invoices.tags.${b.type}`) : '';
        return typeA.localeCompare(typeB);
      }
    },
    {
      title: t('invoices.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        status ? (
          <Tag color={invoiceTag[status]}>
            {t(`invoices.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          ''
        ),
      sorter: (a, b) => {
        const statusA = a.status ? t(`invoices.tags.${a.status}`) : '';
        const statusB = b.status ? t(`invoices.tags.${b.status}`) : '';
        return statusA.localeCompare(statusB);
      }
    },
    {
      title: (
        <Text className="break-column-title">
          {t('invoices.form.last_reminder')}
        </Text>
      ),
      key: 'last_reminder',
      dataIndex: 'last_reminder',
      render: (last_reminder) =>
        last_reminder ? dayjs(last_reminder).format('DD-MM-YYYY') : '-',
      sorter: (a, b) =>
        dayjs(a.last_reminder).unix() - dayjs(b.last_reminder).unix()
    },
    {
      title: (
        <Text className="break-column-title">
          {t('invoices.form.sending_date')}
        </Text>
      ),
      key: 'sending_date',
      dataIndex: 'sending_date',
      render: (sending_date) =>
        sending_date ? dayjs(sending_date).format('DD-MM-YYYY') : '-',
      sorter: (a, b) =>
        dayjs(a.sending_date).unix() - dayjs(b.sending_date).unix()
    },
    {
      title: (
        <Text className="break-column-title">
          {t('invoices.form.settlement_date')}
        </Text>
      ),
      key: 'settlement_date',
      dataIndex: 'settlement_date',
      render: (settlement_date) =>
        settlement_date ? dayjs(settlement_date).format('DD-MM-YYYY') : '-',
      sorter: (a, b) =>
        dayjs(a.settlement_date).unix() - dayjs(b.settlement_date).unix()
    },
    {
      title: (
        <Text className="break-column-title">
          {t('invoices.form.total_ht')}
        </Text>
      ),
      key: 'total_ht',
      dataIndex: 'total_ht',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            record?.reference === 'Montant engagé'
              ? editCell({ record, key: 'total_ht', setEditingKey, form })
              : null
          }
        >
          {record?.total_ht
            ? `${parseFloat(record.total_ht).toFixed(2)} €`
            : '0.00 €'}
        </Row>
      )
    },
    {
      title: t('invoices.form.tva'),
      key: 'tva',
      dataIndex: 'tva',
      render: (tva) => (tva ? `${parseFloat(tva).toFixed(2)} €` : '0.00 €'),
      sorter: (a, b) => a.tva - b.tva
    },
    {
      title: t('invoices.form.disbursement'),
      key: 'disbursement',
      render: (record) => setDisbursementData(record),
      sorter: (a, b) => a.disbursement - b.disbursement
    },
    {
      title: t('invoices.form.lawyer_fee_ht'),
      key: 'lawyer_fee_ht',
      render: (record) =>
        record?.lawyer_fee_ht ? `${record?.lawyer_fee_ht} €` : '0.00 €'
    },
    {
      title: (
        <Text className="break-column-title">
          {t('invoices.form.total_ttc')}
        </Text>
      ),

      key: 'total_ttc',
      dataIndex: 'total_ttc',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            record?.reference === 'Montant engagé'
              ? editCell({ record, key: 'total_ttc', setEditingKey, form })
              : null
          }
        >
          {record?.total_ttc
            ? `${parseFloat(record.total_ttc).toFixed(2)} €`
            : '0.00 €'}
        </Row>
      )
    },
    {
      title: t('invoices.form.paid'),
      key: 'paid',
      dataIndex: 'paid',
      render: (paid) => (paid ? `${parseFloat(paid).toFixed(2)} €` : '0.00 €'),
      sorter: (a, b) => a.paid - b.paid
    },
    {
      title: (
        <Text className="break-column-title">
          {t('invoices.form.left_to_pay')}
        </Text>
      ),
      key: 'left_to_pay',
      dataIndex: 'left_to_pay',
      render: (left_to_pay) =>
        left_to_pay ? `${parseFloat(left_to_pay).toFixed(2)} €` : '0.00 €',
      sorter: (a, b) => a.left_to_pay - b.left_to_pay
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) =>
        record?.status && (
          <>
            <Link
              to={{
                pathname: `/billing-and-accountings/invoices/show/${record._id}`
              }}
            >
              <EyeOutlined style={{ fontSize: 16 }} />
            </Link>
            <Divider type="vertical" />
            <DownloadOutlined style={{ fontSize: 16 }} />

            {record?.type !== 'CREDIT_NOTE' ? (
              <>
                <Divider type="vertical" />
                <Link
                  to="/billing-and-accountings/invoices/create"
                  state={{
                    type: 'CREDIT_NOTE',
                    mission: record?.mission,
                    syndic_id: record?.mission?.syndic?._id,
                    collective_ownership_id:
                      record?.mission?.collective_ownership?._id,
                    tva_percentage: record?.tva_percentage,
                    lawyer_fee_ht: record?.lawyer_fee_ht,
                    invoice: record
                  }}
                >
                  <LicenseDraft style={{ fontSize: 16, color: 'black' }} />
                </Link>
              </>
            ) : null}

            <Divider type="vertical" />
            <Link
              to="/billing-and-accountings/invoices/add-payments"
              state={[record]}
            >
              <EuroOutlined style={{ fontSize: 16 }} />
            </Link>

            <Divider type="vertical" />
            <MailOutlined style={{ fontSize: 16 }} />

            <Divider type="vertical" />
            <PrinterOutlined style={{ fontSize: 16 }} />
          </>
        )
    }
  ];
};
