import { Card, Row, Col, Button, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { StopOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { useInformationsListContent } from '../listContent/informations';
import { Mission } from '../../../../../models/Mission';
import { ParametersDrawer } from '../drawers/ParametersDrawer';
import { useMissionContext } from '../../../../../contexts/MissionContext';

const { Text } = Typography;

/**
 * Renders the InformationsCard component.
 *
 * @component
 * @returns {JSX.Element} The rendered InformationsCard component.
 */
export const InformationsCard = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    mission,
    forceRefresh,
    setForceRefresh,
    isDrawerOpen,
    setIsDrawerOpen
  } = useMissionContext();

  const informationsListContent = useInformationsListContent({
    data: mission,
    setForceRefresh,
    forceRefresh
  });

  return (
    <>
      <Card
        styles={{ title: { overflow: 'visible' } }}
        title={t('missions.cards.titles.informations')}
        extra={
          <Row>
            <Col>
              <Button
                type="link"
                onClick={() =>
                  Mission.blockMission({
                    id,
                    setForceRefresh,
                    forceRefresh,
                    value:
                      mission?.access === 'NOT_BLOCKED'
                        ? { access: 'BLOCKED' }
                        : { access: 'NOT_BLOCKED' }
                  })
                }
              >
                <StopOutlined />
                <Text>{t('missions.show.block_mission')}</Text>
              </Button>
            </Col>
            <Col>
              <Button
                type="link"
                onClick={() =>
                  setIsDrawerOpen((prev) => ({
                    ...prev,
                    parameters: true
                  }))
                }
              >
                <SettingOutlined />
                <Text>{t('missions.show.parameters')}</Text>
              </Button>
            </Col>
          </Row>
        }
      >
        <DescriptionList data={informationsListContent} translate vertical />
      </Card>
      {isDrawerOpen.parameters ? <ParametersDrawer /> : null}
    </>
  );
};
