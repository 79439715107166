import dayjs from 'dayjs';

/**
 * Returns the color code for the actual price based on the given record's start and end dates.
 * @param {Object} options - The options object.
 * @param {Array} options.resourceData - The array of price records.
 * @param {Function} options.setActualPriceColor - The setActualPriceColor function.
 * @returns {string} The color code for the actual price.
 */
export const getActualPriceColor = ({ resourceData, setActualPriceColor }) => {
  const today = dayjs();
  let activeObjectId = null;

  (resourceData || []).forEach((obj) => {
    const startDate = obj.start_date ? dayjs(obj.start_date) : null;
    const endDate = obj.end_date ? dayjs(obj.end_date) : null;

    if (
      startDate &&
      endDate &&
      today.isBetween(startDate, endDate, null, '[]')
    ) {
      activeObjectId = obj._id;
    }
  });

  if (!activeObjectId) {
    (resourceData || []).forEach((obj) => {
      const startDate = obj.start_date ? dayjs(obj.start_date) : null;

      if (startDate && !obj.end_date && startDate.isBefore(today)) {
        activeObjectId = obj._id;
      }
    });
  }

  const result = {};
  (resourceData || []).forEach((obj) => {
    result[obj._id] = obj._id === activeObjectId ? '#10B9A8' : '#DEE2E6';
  });

  setActualPriceColor(result);
};
