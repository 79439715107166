import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { AddIcon } from '../../../../utils/constants/customIcons';
import { ListResource } from '../../../../components';
import { useColumns } from './columns/assistantsColumns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Assistants component
 * @param {t} t - i18n function
 * @returns {JSX.Element} Assistants component
 */
export const Assistants = ({ t }) => {
  const { dispatchAPI, user } = useAuthContext();
  const editUserPermissions = ![
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER'
  ].includes(user.role);
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns({
    dispatchAPI,
    message,
    setForceRefresh,
    forceRefresh,
    editUserPermissions
  });
  return (
    <ListResource
      listKey="relation-manager-assistant"
      resourceName="relation-manager-assistant"
      extraQuery={`manager=${id}&populate=assistant`}
      columns={columns}
      forceRefresh={forceRefresh}
      customActionColumn
      resourceModelName="user"
      withPageHeader={false}
      withCreateButton={false}
      scroll={{ x: 'max-content' }}
      extraButtons={
        editUserPermissions && (
          <Link to="/users/create">
            <Button type="add">
              {t('buttons.create')}
              <AddIcon />
            </Button>
          </Link>
        )
      }
    />
  );
};

Assistants.propTypes = {
  enums: PropTypes.shape({}),
  t: PropTypes.func.isRequired
};

Assistants.defaultProps = {
  enums: {}
};
