import { updateHistory } from '../../../utils/updateHistory';

/**
 * Handles form submission for collective ownership.
 *
 * @async
 * @function
 *

 * @param {Object} options - The options for form submission.
 * @param {Object} options.values - The values from the form.
 * @param {string} options.purpose - The purpose of the form submission (create or edit).
 * @param {Function} options.dispatchAPI - The function to dispatch API calls.
 * @param {string} options.id - The ID of the collective ownership (only for edit purpose).
 * @param {Object} options.Message - The message component for displaying success or error messages.
 * @param {Function} options.t - The translation function.
 * @param {Function} options.navigate - The function to navigate to a different route.
 * @param {Object} options.form - The form component for validation.
 * @param {Object} options.permissions - The permissions for the collective ownership.
 * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
 */
export const onSubmitForm = async ({
  values,
  purpose,
  dispatchAPI,
  id,
  Message,
  t,
  navigate,
  form,
  permissions,
  missionId,
  user
}) => {
  try {
    const formData = new FormData();
    const actualFormValues = await form.validateFields();
    const newValues = { ...values, ...actualFormValues, permissions };

    newValues.plans = newValues?.plans?.filter(
      (plan) => plan?.notary_writer !== undefined
    );

    formData.append('values', JSON.stringify(newValues));

    const setApiCall = () => {
      switch (purpose) {
        case 'create':
          return { url: '/collective-ownerships', action: 'POST' };
        case 'edit':
          return { url: `/collective-ownerships/${id}`, action: 'PATCH' };
        default:
          return { url: '/collective-ownerships', action: 'POST' };
      }
    };

    await dispatchAPI(setApiCall().action, {
      url: setApiCall().url,
      body: formData
    });

    if (missionId) {
      const isAnyDocFilled = newValues.plans.some(
        (plan) => plan.notary_writer !== undefined
      );

      if (isAnyDocFilled) {
        const missionFormData = new FormData();
        missionFormData.append(
          'values',
          JSON.stringify({
            send_archivist_notary: true,
            steps: {
              principal: 'NOTARY_OFFICE_REQUEST'
            }
          })
        );
        await dispatchAPI('PATCH', {
          url: `/missions/${missionId}`,
          body: missionFormData
        });

        await updateHistory({
          dispatchAPI,
          id: missionId,
          resourceName: 'missions',
          values: {
            type: 'PATCH',
            steps: {
              principal: 'NOTARY_OFFICE_REQUEST'
            },
            description: 'NOTARY_OFFICE_REQUEST',
            date: new Date(),
            by: user._id
          }
        });
      } else {
        return Message.error(
          t('collective-ownerships.messages.error.notary_writer_not_filled')
        );
      }
    }

    Message.success(t(`collective-ownerships.messages.success.${purpose}`));
    return navigate(-1);
  } catch (e) {
    if (e.errorFields && e.errorFields.length) {
      return Message.error(t('collective-ownerships.messages.required_fields'));
    }
    return Message.error(
      t(`collective-ownerships.messages.error.${purpose}`, { error: e })
    );
  }
};
