import { useTranslation } from 'react-i18next';
import { ListNotaryConfigurations } from './ListNotaryConfigurations';

/**
 * Custom hook that returns an array of items for notary configurations.
 *
 * @hook
 * @returns {Object} An object containing the items array.
 */
export const useItems = () => {
  const { t } = useTranslation();
  const items = [
    {
      label: t('notary-configurations.tabs.editor-notaries'),
      key: '1',
      children: (
        <ListNotaryConfigurations
          resourceName="editor-notaries"
          resourceModelName="Editor-notary"
          populate="city,archivist_notary"
        />
      )
    },
    {
      label: t('notary-configurations.tabs.archivist-notaries'),
      key: '2',
      children: (
        <ListNotaryConfigurations
          resourceName="archivist-notaries"
          resourceModelName="Archivist-notary"
        />
      )
    }
  ];

  return { items };
};
