import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ListResource, PageHeaderCustom } from '../../../components';
import { useColumns } from './columns';
import { SearchsBar } from './SearchsBar';
import { CollectiveOwnership } from '../../../models/CollectiveOwnership';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { transformExtraQuery } from '../../../utils/filtersUtils';

export const ListPcsStock = () => {
  const { t } = useTranslation();
  const { syndics } = useGlobalContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [filters, setFilters] = useState({});
  const [extraQuery, setExtraQuery] = useState('');
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedSyndic, setSelectedSyndic] = useState('');
  const columns = useColumns({
    setForceRefresh,
    forceRefresh,
    setSelectedSyndic
  });

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const collectiveOwnershipsDatas = await CollectiveOwnership.getResources({
        populate: '',
        extraQuery: ''
      });
      setCollectiveOwnerships(collectiveOwnershipsDatas);
      setIsDataLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedSyndic) {
        const collectiveOwnershipsDatas =
          await CollectiveOwnership.getResources({
            populate: '',
            extraQuery: `syndic._id=${selectedSyndic}`
          });
        setCollectiveOwnerships(collectiveOwnershipsDatas);
      }
    })();
  }, [selectedSyndic]);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <>
      <PageHeaderCustom title={t('pcs_stocks.title')} />
      <ListResource
        resourceName="pcs-stocks"
        resourceModelName="Pcs-stock"
        withPageHeader={false}
        columns={columns}
        withCreateButton={false}
        extraQuery={extraQuery}
        deleteAction={false}
        editAction={false}
        scroll={{ x: 'max-content' }}
        searchBarExtraButtons={
          <SearchsBar
            setFilters={setFilters}
            isDataLoading={isDataLoading}
            setForceRefresh={setForceRefresh}
            syndics={syndics}
            setSelectedSyndic={setSelectedSyndic}
            collectiveOwnerships={collectiveOwnerships}
          />
        }
      />
    </>
  );
};
