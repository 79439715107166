import dayjs from 'dayjs';
/**
 * Returns a configuration object for resource retrieval.
 * @hook
 * @returns {Object} The configuration object.
 */
export const useConfig = ({ form }) => ({
  onGetResource: {
    setFields: (data) => {
      const pcajpObject = {};
      const rcpDocumentsObject = {};

      const updatedRcpDocument = (data.rcp_documents || []).map((doc) => ({
        ...doc,
        writting_date: doc.writting_date ? dayjs(doc.writting_date) : null,
        date: doc.date ? dayjs(doc.date) : null
      }));

      (data.pcajp || []).forEach((pcajp) => {
        pcajpObject[pcajp.private_usage_id] = pcajp;
      });

      updatedRcpDocument.forEach((doc) => {
        rcpDocumentsObject[doc._id] = doc;
      });

      const updatedData = {
        ...data,
        rcp_documents: rcpDocumentsObject,
        pcajp: pcajpObject
      };

      return updatedData;
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      form.resetFields();

      return data;
    }
  }
});
