import { Col, Divider, Form, Radio, Row, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { formatAddress } from '../../../../utils/formatingFunctions';
import { useGlobalContext } from '../../../../../../contexts/GlobalContext';

const layoutProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

/**
 * Renders the CollectivesOwnershipAndType component.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values for the form.
 * @param {Function} props.setButtonsActive - The function to set the buttons active.
 * @returns {JSX.Element} The rendered CollectivesOwnershipAndType component.
 */
export const CollectivesOwnershipStep = ({
  form,
  initialValues,
  setButtonsActive
}) => {
  const { dispatchAPI } = useAuthContext();
  const { currentSyndic } = useGlobalContext();
  const [missions, setMissions] = useState([]);
  const [selectedCollectiveOwnership, setSelectedCollectiveOwnership] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const fieldsToFetch = [
    'collective_ownership',
    'collective_ownership.name',
    'collective_ownership.first_address',
    'collective_ownership.first_city',
    'collective_ownership.first_city.name'
  ];
  const toPopulate = [
    'collective_ownership',
    'collective_ownership.first_city'
  ];

  const fieldsString = fieldsToFetch.join(',');
  const populateString = toPopulate.join(',');

  const getMissions = async () => {
    setIsLoading(true);
    const { data } = await dispatchAPI('GET', {
      url: `/missions?syndic=${currentSyndic}&type=PCS_2&steps.sub=FINALIZATION&populate=${populateString}&fields=${fieldsString}`
    });
    setIsLoading(false);
    setMissions(data);
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useEffect(() => {
    if (selectedCollectiveOwnership) {
      return setButtonsActive((prev) => ({
        ...prev,
        next: true,
        saveDraft: true
      }));
    }
    return setButtonsActive((prev) => ({
      ...prev,
      next: false,
      saveDraft: false
    }));
  }, [selectedCollectiveOwnership]);

  useEffect(() => {
    (async () => {
      await getMissions();
    })();
  }, []);

  const handleCollectiveOwnershipChange = (e) => {
    setSelectedCollectiveOwnership(e.target.value);
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="center">
          <Col span={12}>
            <Divider>
              {t('missions.form.select_affected_collective_ownership')}
            </Divider>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={8}>
            <Form.Item name={['mission']} {...layoutProps}>
              <Radio.Group
                optionFilterProp="children"
                defaultValue={null}
                onChange={handleCollectiveOwnershipChange}
              >
                <Spin spinning={isLoading}>
                  <Space direction="vertical">
                    {missions.map((mission) => (
                      <Radio value={mission._id} key={mission._id}>
                        {(() => {
                          const { collective_ownership } = mission;
                          const { name, first_address, first_city } =
                            collective_ownership;
                          return formatAddress(name, first_address, first_city);
                        })()}
                      </Radio>
                    ))}
                  </Space>
                </Spin>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CollectivesOwnershipStep.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  setButtonsActive: PropTypes.func.isRequired
};
