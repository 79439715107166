import { Card, Skeleton, Typography, Row, Col, Tag } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useCollectiveOwnershipInformationsListContent } from './listContent/collectiveOwnershipInformations';

const { Text } = Typography;

/**
 * Renders the CollectiveOwnershipInformations component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.pcsPreProduction - The PCS pre-production data.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @returns {JSX.Element} The rendered CollectiveOwnershipInformations component.
 */
export const CollectiveOwnershipInformations = ({
  pcsPreProduction,
  isLoading
}) => {
  const { t } = useTranslation();
  const informationsListContent =
    useCollectiveOwnershipInformationsListContent(pcsPreProduction);

  return (
    <Card
      title={t(
        'pcs-pre-productions.show.cards.titles.collective_ownership_informations'
      )}
      extra={
        pcsPreProduction?.collective_ownership?._id ? (
          <Link
            to={`/collective-ownerships/show/${pcsPreProduction?.collective_ownership?._id}`}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <Row gutter={[4, 4]}>
              <Col>
                <Text>{t('pcs-pre-productions.show.see')}</Text>
              </Col>
              <Col>
                <ArrowRightOutlined />
              </Col>
            </Row>
          </Link>
        ) : (
          <Tag style={{ color: 'red' }}>
            <Text>
              {t(
                'pcs-pre-productions.show.messages.collective_ownership_deleted'
              )}
            </Text>
          </Tag>
        )
      }
    >
      <Skeleton loading={isLoading} active>
        <DescriptionList data={informationsListContent} translate vertical />
      </Skeleton>
    </Card>
  );
};

CollectiveOwnershipInformations.propTypes = {
  pcsPreProduction: PropTypes.shape({
    collective_ownership: PropTypes.shape({
      _id: PropTypes.string
    })
  }),
  isLoading: PropTypes.bool
};

CollectiveOwnershipInformations.defaultProps = {
  pcsPreProduction: {},
  isLoading: false
};
