import { Button, Row, Select, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MessageCard } from './MessageCard';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { Message } from '../../models/Message';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMessageContext } from '../../contexts/MessageContext';

/**
 * Renders a component that displays messages.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.withCreateButton - Flag indicating if the create button should be displayed.
 * @returns {JSX.Element} The JSX element representing the ShowMessages component.
 */
export const ShowMessages = ({ withCreateButton }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { isCreateMessageCardOpen, setIsCreateMessageCardOpen } =
    useGlobalContext();
  const { recipients, messages, isFieldsLoading, setExtraQuery } =
    useMessageContext();

  return (
    <Row gutter={[16, 16]} justify="center">
      <Col span={!isCreateMessageCardOpen ? 16 : 20}>
        <Select
          allowClear
          loading={isFieldsLoading}
          placeholder={t('messages.show.recipients')}
          style={{ width: '100%' }}
          options={Message.recipientOptions({
            recipients,
            t,
            intern_note: !!(user?.role && user?.role?.includes('NOMADE')),
            user
          })}
          onChange={(value) => setExtraQuery(Message.extraQueryValue(value))}
        />
      </Col>
      {!isCreateMessageCardOpen && withCreateButton ? (
        <Col span={8}>
          <Button onClick={() => setIsCreateMessageCardOpen(true)}>
            {t('messages.buttons.new_message')}
          </Button>
        </Col>
      ) : null}
      {messages.map((message) => (
        <Col span={24} key={message._id}>
          <MessageCard message={message} cardId={message._id} />
        </Col>
      ))}
    </Row>
  );
};

ShowMessages.propTypes = {
  withCreateButton: PropTypes.bool
};

ShowMessages.defaultProps = {
  withCreateButton: true
};
