import { Select, Form, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { missionTag } from '../../../utils/constants/tagColors';

const { Option } = Select;

/**
 * Renders a form with two Select components for filtering syndics and enums.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.enums - The array of enums.
 * @param {boolean} props.isFiedlLoading - Indicates if the field is loading.
 * @returns {JSX.Element} - The rendered form.
 */
export const ExtraButtons = ({ setFilters, enums, isFiedlLoading }) => {
  const [form] = Form.useForm();
  const { syndics } = useGlobalContext();
  const { t } = useTranslation();

  /**
   * Filters the options based on the input value.
   * @function
   * @param {string} input - The input value.
   * @param {Object} option - The option object.
   * @returns {boolean} - Whether the option should be displayed or not.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} layout="inline">
      <Form.Item name={['syndics']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ type: null, status: null });
            setFilters((prev) => ({
              ...prev,
              syndic: values || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              syndic: ''
            }));
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFiedlLoading}
          placeholder={t('placeholder.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {syndic.society_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['type']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ syndics: null, status: null });
            setFilters((prev) => ({
              ...prev,
              type: values || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              type: ''
            }));
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFiedlLoading}
          placeholder={t('placeholder.search_mission_type')}
        >
          {(enums.types || []).map((type) => (
            <Option key={type} value={type}>
              <Tag color={missionTag[type]}>
                {t(`missions.tags.${type}`, {
                  defaultValue: type
                })}
              </Tag>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['status']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ syndics: null, type: null });
            setFilters((prev) => ({
              ...prev,
              status: values || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              status: ''
            }));
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFiedlLoading}
          placeholder={t('placeholder.search_status')}
        >
          {(enums.status || []).map((status) => (
            <Option key={status} value={status}>
              {t(`missions.tags.${status}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

ExtraButtons.propTypes = {
  setFilters: PropTypes.func,
  enums: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.arrayOf(PropTypes.string)
  }),
  isFiedlLoading: PropTypes.bool
};

ExtraButtons.defaultProps = {
  setFilters: () => {},
  enums: {},
  isFiedlLoading: true
};
