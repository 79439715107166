import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ExportModal } from '../ExportModal/ExportModal';

/**
 * ExportButton component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.fileName - The name of the exported file.
 * @param {string} props.dataName - The name of the data.
 * @param {string} props.url - The URL to fetch the data from.
 * @param {Array} props.headers - The headers for the CSV file.
 * @param {Function} props.formatter - The function to format the data before exporting.
 * @param {string} props.populate - The populate parameter for the API request.
 * @param {string} props.extraQuery - The extra query parameters for the API request.
 * @param {Object} props.selectedRowKeys - The selected row keys.
 * @param {string} props.buttonLabel - The label for the export button.
 * @returns {JSX.Element} The ExportButton component.
 */
export const ExportButton = ({
  fileName,
  dataName,
  url,
  headers,
  formatter,
  populate,
  extraQuery,
  selectedRowKeys,
  buttonLabel
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dataCSV, setDataCSV] = useState([]);
  const { message } = useErrorMessage();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${url}?${extraQuery ? `${extraQuery}&` : ''}${
          selectedRowKeys?.rowKeys?.length
            ? `${selectedRowKeys.key}=${selectedRowKeys.rowKeys}&`
            : ''
        }${populate ? `populate=${populate}` : ''}`
      });
      if (data.length) setDataCSV(formatter ? data.map(formatter) : data);
      else setDataCSV('Pas de donnée');
    } catch (e) {
      message(e);
      setDataCSV('Pas de donnée');
    }
  }, [url, formatter, dataName, headers, populate]);

  useEffect(() => {
    setDataCSV([]);
  }, [dataName, url, headers]);

  return (
    <>
      <ExportModal visible={visible} />
      <CSVLink
        style={{ color: 'inherit' }}
        asyncOnClick
        onClick={(e, done) => {
          if (!dataCSV.length) {
            setVisible(true);
            e.persist();
            e.preventDefault();
            fetchData().then(() => {
              setVisible(false);
              e.target.click();
              done(false);
              setDataCSV([]);
            });
          } else done();
        }}
        filename={fileName}
        data={dataCSV}
        headers={(headers || []).map(({ label, key }) => ({
          label: t(`${dataName}.form.${label}`),
          key: key || label
        }))}
      >
        <DownloadOutlined style={{ fontSize: '14px', marginRight: 8 }} />
        {buttonLabel || t(`buttons.export`)}
      </CSVLink>
    </>
  );
};

ExportButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formatter: PropTypes.func,
  extraQuery: PropTypes.string,
  populate: PropTypes.string,
  selectedRowKeys: PropTypes.shape({
    key: PropTypes.string,
    rowKeys: PropTypes.arrayOf(PropTypes.string)
  }),
  buttonLabel: PropTypes.string
};

ExportButton.defaultProps = {
  formatter: null,
  extraQuery: null,
  populate: null,
  selectedRowKeys: {},
  buttonLabel: null
};
