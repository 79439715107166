import { useState } from 'react';
import { Form } from 'antd';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/finalizationFields';
import { useConfig } from '../utils/finalizationCreateUpdateConfig';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Represents the FinalizationCard component.
 * @component
 * This component is responsible for rendering the finalization card for a mission.
 * It includes a form for creating or updating documents related to the mission.
 * @returns {JSX.Element} The FinalizationCard component.
 */
export const FinalizationCard = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState({});
  const { forceRefresh, isDrawerOpen, setForceRefresh, setIsDrawerOpen } =
    useMissionContext();
  const { fields } = useFields({
    setFileList,
    fileList
  });

  const config = useConfig({ form });

  return (
    <CreateUpdateContainer
      customFormInstance={form}
      config={config}
      fields={fields}
      purpose="edit"
      baseUrl="missions/finalization"
      resource="missions"
      withFilesManager={false}
      withCustomPageHeader
      setForceRefresh={setForceRefresh}
      forceRefresh={forceRefresh}
      setIsCreateUpdateOpen={setIsDrawerOpen}
      isCreateUpdateOpen={{ ...isDrawerOpen, finalization: false }}
      cancelCustomAction={() => {
        form.resetFields();
        setIsDrawerOpen((prev) => ({ ...prev, finalization: false }));
      }}
      customSubmitButtonStyle={{
        position: 'fixed',
        bottom: '20px',
        right: '40px'
      }}
      customLabelCol={{}}
      customWrapperCol={{}}
    />
  );
};
