import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Handles the deletion of a row in the charging grid.
 * @function
 * @param {Object} params - Function parameters
 * @param {string} params.key - The key of the row to be deleted
 * @param {Array} params.dataSource - The data source containing the rows
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Function} params.message - Function for displaying messages
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setIsFinalVersion - Function for setting final version status
 * @param {Object} params.resourceDatas - The resource object
 * @param {Function} params.setResourceDatas - Function for setting resource data
 * @returns {null}
 */

export const handleDeleteRow = async ({
  key,
  dataSource,
  chargingGrid,
  dispatchAPI,
  message,
  setChargingGrid,
  setIsFinalVersion,
  resourceDatas,
  setResourceDatas
}) => {
  const newData = [...dataSource];
  const index = newData.findIndex((item) => Number(key) === Number(item.key));
  const rowToDelete = newData[index];

  const formData = new FormData();
  try {
    formData.append('values', JSON.stringify(newData));

    formData.append('row_to_delete', JSON.stringify(rowToDelete));

    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${chargingGrid._id}?type=delete_data`,
      body: formData
    });

    updateChargingGrids({
      resourceDatas,
      chargingGrid: data,
      setResourceDatas
    });
    setChargingGrid(data);
  } catch (e) {
    message(e);
  }
  setIsFinalVersion(false);
};
