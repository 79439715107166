import { message as antdMessage } from 'antd';
import { t } from 'i18next';
import { BaseModel } from './BaseModel';

/**
 * Represents an mission.
 * @class
 */
export class Mission extends BaseModel {
  static resourceName = 'missions';

  /**
   * Array of possible status values for the PcsPreProduction class.
   * @type {string[]}
   */
  static statusSteps = {
    principal: {
      PCS_1: 0,
      PCS_2: 1,
      PCS_3: 2,
      FINISHED: 3
    },
    sub: {
      ADVANCE_PAYMENT: 0,
      BUILDING_FORM_ORDER: 1,
      BUILDING_FORM_DELIVERY: 2,
      ORDER_VERIFICATION: 3,
      DOCUMENTS_DELIVERY: 4,
      LAWYER_CONSULTATION: 5,
      PROJECT_IMPLEMENTATION: 1,
      INTERMEDIATE_PROJECT_SUBMISSION: 2,
      SYNDIC_VALIDATION: 3,
      FINAL_PROJECT_SUBMISSION: 4,
      FINALIZATION: 5,
      INVOICE_AND_DOCUMENT_RECOVERY: 0,
      MATRIX_FINALIZATION: 2,
      SEND_TO_NOTARY: 3,
      PUBLICATION: 4
    },
    principal_plans_research: {
      FILLED_RCP_INFORMATIONS: 0,
      NOTARY_OFFICE_REQUEST: 1,
      PLANS_ORDERS: 2,
      PLANS_DELIVERY: 3
    },
    principal_audit_one: {
      BUILDING_FORM_ORDER: 0,
      BUILDING_FORM_DELIVERY: 1
    },
    principal_audit_one_two: {
      INVOICE_DEPOSIT: 0,
      BUILDING_FORM_ORDER: 1,
      BUILDING_FORM_DELIVERY: 2,
      DOCUMENTS_ORDER: 3,
      DOCUMENTS_DELIVERY: 4,
      TRANSFORMATION_WORD: 5
    },
    principal_rcp_reserach: {
      BUILDING_FORM_ORDER: 0,
      BUILDING_FORM_DELIVERY: 1,
      DOCUMENTS_ORDER: 2,
      DOCUMENTS_DELIVERY: 3,
      TRANSFORMATION_WORD: 4
    }
  };

  /**
   * Returns the right mission steps based on the mission type.
   *
   * @param {string} missionType - The type of the mission.
   * @returns {Array|null} - The mission steps for the given mission type, or null if the mission type is not recognized.
   */
  static getRightMissionSteps(missionType) {
    switch (missionType) {
      case 'PCS_1':
        return Mission.statusSteps.principal;
      case 'PCS_2':
        return Mission.statusSteps.principal;
      case 'PCS_3':
        return Mission.statusSteps.principal;
      case 'PLANS_RESEARCH':
        return Mission.statusSteps.principal_plans_research;
      case 'AUDIT_1':
        return Mission.statusSteps.principal_audit_one;
      case 'AUDIT_1_2':
        return Mission.statusSteps.principal_audit_one_two;
      case 'RCP_RESEARCH':
        return Mission.statusSteps.principal_rcp_reserach;
      default:
        return null;
    }
  }

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Mission.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Retrieves resources for missions.
   * @function
   * @async
   * @param {Object} options - The options for retrieving resources.
   * @param {string} options.populate - The fields to populate in the resources.
   * @param {string} options.extraQuery - Additional query parameters for the request.
   * @returns {Promise<Array>} A promise that resolves to an array of resources.
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Mission.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Retrieves the count of documents based on the provided query parameters.
   * @function
   * @static
   * @async
   * @param {Object} [params={}] - The parameters for the request.
   * @param {string} [params.extraQuery=''] - Additional query parameters to append to the URL.
   * @returns {Promise<Object>} The data returned from the API, containing the document count.
   */
  static async getDocumentsCount({ extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Mission.resourceName}/count?${extraQuery}`
    });
    return data;
  }

  /**
   * Retrieves lawyer missions.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving lawyer missions.
   * @param {string} options.populate - The fields to populate in the response.
   * @param {string} options.extraQuery - Additional query parameters.
   * @returns {Promise<Array>} - A promise that resolves to an array of lawyer missions.
   */
  static async getLawyerMissions({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Mission.resourceName}/lawyer-missions?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Create a new resource with the given values.
   * @function
   * @async
   * @param {Object} options - The options for creating the resource.
   * @param {Object} options.values - The values for the new resource.
   * @returns {Promise<Object>} A promise that resolves to the created resource data.
   * @throws {Error} An error if the request fails.
   */
  static async createResource({ values }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('POST', {
      url: `${Mission.resourceName}/mission/create`,
      body: formData
    });
    return data;
  }

  /**
   * Retrieves plan requests by mission ID.
   *
   * @param {string} missionId - The ID of the mission.
   * @returns {Promise<any>} - A promise that resolves to the retrieved data.
   */
  static async getPlanRequestsByMissionId(missionId) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Mission.resourceName}/plan-requests/${missionId}`
    });
    return data;
  }

  /**
   * Retrieves plan requests that are under review.
   * @function
   * @static
   * @async
   * @returns {Promise<Object[]>} The data returned from the API, containing the list of plan requests under review.
   */
  static async getPlanRequestsByReview() {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Mission.resourceName}/plan-requests-review/`
    });
    return data;
  }

  /**
   * Patch a plan request for a mission.
   * @param {Object} options - The options for patching the plan request.
   * @param {string} options.id - The ID of the plan request.
   * @param {Object} options.values - The values to be patched.
   * @returns {Promise<void>} - A promise that resolves when the plan request is patched successfully.
   */
  static async patchPlanRequest({
    missionId,
    values,
    planRequestId,
    lastStep
  }) {
    const formData = new FormData();
    formData.append(
      'values',
      JSON.stringify({
        ...values,
        last_step: lastStep
      })
    );

    await this.dispatchAPI('PATCH', {
      url: `${Mission.resourceName}/plan-requests/${missionId}?planRequestId=${planRequestId}`,
      body: formData
    });
  }

  /**
   * Patch a resource by ID with new values.
   * @function
   * @async
   * @param {Object} options - The options for patching the resource.
   * @param {string} options.id - The ID of the resource to patch.
   * @param {Object} options.values - The new values to patch the resource with.
   * @param {function} [options.setIsSubmitting] - The function to set the submitting state.
   * @param {string} [options.populate=''] - The fields to populate in the patched resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the patch request.
   * @returns {Promise<Object>} A promise that resolves to the patched resource data.
   */
  static async patchResource({
    id,
    values,
    setIsSubmitting,
    message,
    messageContent,
    populate = '',
    extraQuery = '',
    setForceRefresh,
    setIsDrawerOpen,
    drawerKey
  }) {
    if (setIsSubmitting) setIsSubmitting(true);
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('PATCH', {
      url: `${Mission.resourceName}/${id}?populate=${populate}&${extraQuery}`,
      body: formData
    });
    if (setIsSubmitting) setIsSubmitting(false);
    if (message) message.success(messageContent);
    if (setForceRefresh) setForceRefresh((prev) => !prev);
    if (setIsDrawerOpen)
      setIsDrawerOpen((prev) => ({ ...prev, [drawerKey]: false }));
    return data;
  }

  /**
   * @function
   * @async
   * Blocks the mission with the given parameters.
   *
   * @param {Object} options - The options object.
   * @param {string} options.id - The ID of the collective ownership.
   * @param {Function} options.setForceRefresh - The setForceRefresh function.
   * @param {boolean} options.forceRefresh - The current value of forceRefresh.
   * @param {any} options.value - The value to be sent in the request body.
   * @returns {Promise<void>} - A promise that resolves when the collective ownership is successfully blocked.
   */
  static async blockMission({ id, setForceRefresh, forceRefresh, value }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(value));

    await this.dispatchAPI('PATCH', {
      url: `${Mission.resourceName}/${id}`,
      body: formData
    });

    setForceRefresh(!forceRefresh);
  }

  /**
   * Deletes a document associated with a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for deleting the document.
   * @param {Object} options.missionId - The mission id.
   * @param {Function} options.setForceRefresh - The function to set the force refresh state.
   * @param {boolean} options.forceRefresh - The current force refresh state.
   * @param {string} options.document_id - The ID of the document.
   * @returns {Promise<void>} - A promise that resolves when the document is deleted.
   */
  static async deleteDocument({
    mission_id,
    setForceRefresh,
    forceRefresh,
    document_id
  }) {
    await this.dispatchAPI('DELETE', {
      url: `missions/documents/${mission_id}?document_id=${document_id}`
    });
    setForceRefresh(!forceRefresh);
  }

  /**
   * Deletes multiple documents associated with a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for deleting multiple documents.
   * @param {Array} options.values - The values to be deleted.
   * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
   * @param {boolean} options.forceRefresh - The current value of the force refresh flag.
   * @param {string} options.id - The ID of the mission.
   * @returns {Promise<void>} A promise that resolves when the documents are deleted.
   */
  static async deleteManyDocument({
    values,
    setForceRefresh,
    forceRefresh,
    id
  }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    await this.dispatchAPI('PATCH', {
      url: `missions/documents/delete-many/${id}`,
      body: formData
    });
    setForceRefresh(!forceRefresh);
  }

  /**
   * Updates the parameters content of a mission.
   * @function
   * @async
   * @param {Object} options - The options for updating the parameters fields.
   * @param {any} options.value - The new value for the parameters fields.
   * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
   * @param {boolean} options.forceRefresh - The current value of the force refresh flag.
   * @param {string} options.id - The ID of the mission.
   * @param {string} options.resource - The resource URL for the mission.
   * @param {Function} options.setUpdateField - The function to set the update field flag.
   * @returns {Promise<void>} - A promise that resolves when the parameters fields are updated.
   */
  static async updateParametersContent({
    value,
    setForceRefresh,
    forceRefresh,
    id,
    resource,
    setUpdateField
  }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(value));

    await this.dispatchAPI('PATCH', {
      url: `${resource}/${id}`,
      body: formData
    });
    setUpdateField(false);
    setForceRefresh(!forceRefresh);
  }

  /**
   * Adds a building form to a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for adding the building form.
   * @param {function} options.setForceRefresh - The function to set the force refresh state.
   * @param {boolean} options.forceRefresh - The current force refresh state.
   * @param {Object} options.mission - The mission object.
   * @returns {Promise<void>} - A promise that resolves when the building form is added.
   */
  static async addBuildingForm({ setForceRefresh, forceRefresh, mission }) {
    const body = {
      config_document_ref: 'Cerfa-document',
      main_type: 'CERFA',
      status: 'EXPECTED',
      costs: 12,
      disbursement: 30,
      to_charge: 32,
      supply_by_syndic: false,
      mission_type: mission?.type || '',
      cadastral_reference: mission?.cadastral_reference || ''
    };
    const formData = new FormData();
    formData.append('values', JSON.stringify(body));

    await this.dispatchAPI('PATCH', {
      url: `missions/building-form/add/${mission._id}`,
      body: formData
    });

    setForceRefresh(!forceRefresh);
  }

  /**
   * Represents the steps of a mission.
   * @type {string[]}
   */
  static stepsObject = {
    PCS_1: {
      sub: [
        'ADVANCE_PAYMENT',
        'BUILDING_FORM_ORDER',
        'BUILDING_FORM_DELIVERY',
        'ORDER_VERIFICATION',
        'DOCUMENTS_DELIVERY',
        'LAWYER_CONSULTATION'
      ]
    },
    PCS_2: {
      sub: [
        'ADVANCE_PAYMENT',
        'PROJECT_IMPLEMENTATION',
        'INTERMEDIATE_PROJECT_SUBMISSION',
        'SYNDIC_VALIDATION',
        'FINAL_PROJECT_SUBMISSION',
        'FINALIZATION'
      ]
    },
    PCS_3: {
      principal: ['PCS_1', 'PCS_2', 'PCS_3', 'FINISHED'],
      sub: [
        'INVOICE_AND_DOCUMENT_RECOVERY',
        'BUILDING_FORM_ORDER',
        'MATRIX_FINALIZATION',
        'SEND_TO_NOTARY',
        'PUBLICATION'
      ]
    },
    AUDIT_1: {
      principal: [
        'INVOICE_DEPOSIT',
        'BUILDING_FORM_ORDER',
        'BUILDING_FORM_DELIVERY'
      ]
    },
    AUDIT_1_2: {
      principal: [
        'DOCUMENTS_ORDER',
        'DOCUMENTS_DELIVERY',
        'TRANSFORMATION_WORD'
      ]
    },
    RCP_RESEARCH: {
      principal: [
        'BUILDING_FORM_ORDER',
        'BUILDING_FORM_DELIVERY',
        'DOCUMENTS_ORDER',
        'DOCUMENTS_DELIVERY',
        'TRANSFORMATION_WORD'
      ]
    },
    PLANS_RESEARCH: {
      principal: [
        'FILLED_RCP_INFORMATIONS',
        'NOTARY_OFFICE_REQUEST',
        'PLANS_ORDERS',
        'PLANS_DELIVERY'
      ]
    }
  };

  /**
   * Updates the PCS status of a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for updating the PCS status.
   * @param {string} options.element - The element to update.
   * @param {string} options.id - The ID of the mission.
   * @param {function} options.setCurrent - The function to set the current state.
   * @param {function} options.setMission - The function to set the mission state.
   * @param {function} options.setForceRefresh - The function to set the force refresh state.
   * @param {string} options.stepType - The type of the step.
   * @param {Array} options.documents - The documents array.
   * @param {function} options.notification - The notification function.
   * @param {Object} options.mission - The mission object.
   * @returns {Promise<void>} - A promise that resolves when the PCS status is updated.
   */
  static updatePcsStatus = async ({
    element,
    id,
    setCurrent,
    setMission,
    setForceRefresh,
    stepType,
    documents,
    notification,
    mission,
    invoices
  }) => {
    let stepKey = {};

    switch (stepType) {
      case 'principal':
        setCurrent((prev) => ({ ...prev, principal: element }));
        stepKey = 'steps.principal';
        break;
      case 'sub':
        {
          const receivedBuildingForm = (documents || []).find(
            (document) =>
              document?.config_document?.wording === 'Fiche immeuble' &&
              document.status === 'RECEIVED'
          );

          const balanceInvoice = (invoices || []).find(
            (invoice) => invoice.type === 'BALANCE'
          );

          if (
            balanceInvoice &&
            balanceInvoice?.status !== 'PAID' &&
            mission?.syndic.wait_payment_for_send_PCS_to_lawyer
          ) {
            return notification.warning({
              message: t(
                'missions.messages.wait_payment_for_send_PCS_to_lawyer_title'
              ),
              description: t(
                'missions.messages.wait_payment_for_send_PCS_to_lawyer_content'
              ),
              placement: 'topRight'
            });
          }

          if (
            this.stepsObject[mission.type][stepType][element] ===
              'LAWYER_CONSULTATION' &&
            !receivedBuildingForm
          ) {
            return notification.warning({
              message: t('missions.messages.no_building_form_title'),
              description: t('missions.messages.no_building_form_content'),
              placement: 'topRight'
            });
          }
          setCurrent((prev) => ({ ...prev, sub: element }));
          stepKey = 'steps.sub';
        }
        break;
      default:
        break;
    }

    const formData = new FormData();
    const values = {
      [stepKey]: this.stepsObject[mission.type][stepType][element]
    };

    // This is a temporary fix to set the status for the lawyer to IN_PROGRESS
    if (
      this.stepsObject[mission.type][stepType][element] ===
      'FINAL_PROJECT_SUBMISSION'
    ) {
      values.status_for_lawyer = 'IN_PROGRESS';
    }
    //
    formData.append('values', JSON.stringify(values));

    await this.dispatchAPI('PATCH', {
      url: `${Mission.resourceName}/${id}`,
      body: formData
    });

    setMission((prev) => ({
      ...prev,
      [stepKey]: this.stepsObject[mission.type][stepType][element]
    }));
    setForceRefresh((prev) => !prev);
    return null;
  };

  /**
   * Fetches PCS mission statistics for the specified values.
   *
   * @param {Object} params - The parameters for the request.
   * @param {Object} params.values - The values containing the date for which statistics are to be retrieved.
   * @param {Date} params.values.date - The date for which the PCS statistics are required.
   * @returns {Promise<Object>} A promise that resolves to the PCS mission statistics.
   * @throws {Error} If there is an error during the request.
   *
   * @example
   * const values = { date: '2023-05-01T00:00:00.000Z' };
   * try {
   *   const stats = await getPcsStats({ values });
   *   console.log(stats);
   * } catch (error) {
   *   console.error(error);
   * }
   */
  static async getPcsStats({ values }) {
    try {
      const formData = new FormData();
      formData.append('values', JSON.stringify(values));

      const { data } = await this.dispatchAPI('POST', {
        url: `${Mission.resourceName}/pcs-stats`,
        body: formData
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  /**
   * Creates a mission for Phase Two of the PCS process.
   * @function
   * @async
   * @param {Object} options - The options for creating the mission.
   * @param {string} options.id - The ID of the mission.
   * @param {function} options.setIsLoading - The function to set the loading state.
   * @param {function} options.navigate - The navigation function.
   * @params {function} options.message - The message function.
   *
   * @returns {Promise<void>} - A promise that resolves when the mission is created.
   */
  static createMissionPcsPhaseTwo = async ({
    id,
    setIsLoading,
    navigate,
    message
  }) => {
    try {
      await this.dispatchAPI('PATCH', {
        url: `${Mission.resourceName}/pcs-phase-two/${id}`
      });

      setIsLoading(true);
      navigate(`/missions/missions-view/show/PCS/${id}/PCS_2`, {
        state: { refresh: true }
      });
    } catch (error) {
      message(error);
    }
  };

  /**
   * Generates the advance payment invoice for phase two of a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for generating the invoice.
   * @param {string} options.id - The ID of the mission.
   * @param {function} options.setForceRefresh - The function to trigger a force refresh.
   * @returns {Promise<void>} - A promise that resolves when the invoice is generated.
   */
  static generatePhaseTwoAdvancePaymentInvoice = async ({
    id,
    setForceRefresh
  }) => {
    await this.dispatchAPI('PATCH', {
      url: `${Mission.resourceName}/pcs-phase-two-generate-advance-payment-invoice/${id}`
    });

    setForceRefresh((prev) => !prev);
  };

  /**
   * Retrieves the finalized information for a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the finalized information.
   * @param {string} options.id - The ID of the mission.
   * @returns {Promise<Object>} A promise that resolves to the finalized information for the mission.
   */
  static getFinalizedInformations = async ({ id }) => {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Mission.resourceName}/finalization/${id}`
    });

    return data;
  };

  /**
   * Posts PCS stocks.
   *
   * @param {Object} options - The options object.
   * @param {Object} options.values - The values for the PCS stocks.
   * @returns {Promise<Object>} A promise that resolves to the data of the PCS stocks.
   */
  static postPcsStocks = async ({ values }) => {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));
    try {
      await this.dispatchAPI('POST', {
        url: `pcs-stocks`,
        body: formData
      });
    } catch (error) {
      return error;
    }
    return true;
  };

  /**
   * Navigates to create or update a quotation based on the provided parameters.
   * If a quotation of type 'PCS_PUBLICATION_QUOTE' exists, it navigates to the edit page of that quotation.
   * Otherwise, it navigates to the create page of a new quotation with the provided mission.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {Array} options.quotation - The object of quotation.
   * @param {function} options.navigate - The navigate function for navigation.
   * @param {Object} options.mission - The mission object.
   * @param {string} options.mission_type - The type of the mission
   * @returns {void}
   */
  static navigateToCreateUpdateQuotation = ({
    quotation,
    navigate,
    mission,
    mission_type
  }) => {
    if (quotation) {
      navigate(`/quotations/edit/${quotation._id}`);
    } else {
      navigate('/quotations/create', {
        state: { mission: { ...mission, mission_type } }
      });
    }
  };

  /**
   * Handles disbursements for a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for handling disbursements.
   * @param {Object} options.values - The values for the disbursements.
   * @param {string} options.id - The ID of the mission.
   * @param {Object} options.finalAmounts - The final amounts for the disbursements.
   * @param {function} options.setForceRefresh - The function to set the force refresh flag.
   * @param {function} options.message - The function to display an error message.
   * @param {function} options.setIsDrawerOpen - The function to set the drawer open state.
   * @param {string} options.successMessage - The success message to display.
   * @param {Object} options.isDrawerOpen - The drawer open state.
   * @returns {Promise<Object>} - A promise that resolves to the response data.
   */
  static handleDisbursements = async ({
    values,
    id,
    finalAmounts,
    setForceRefresh,
    message,
    setIsDrawerOpen,
    successMessage,
    isDrawerOpen
  }) => {
    const updatedValues = {
      ...values,
      final_amounts: finalAmounts
    };
    const formData = new FormData();
    formData.append('values', JSON.stringify(updatedValues));

    try {
      const { data } = await this.dispatchAPI('PATCH', {
        url: `${Mission.resourceName}/disbursements/${id}?mission_type=${isDrawerOpen.disbursement.type}`,
        body: formData
      });

      setForceRefresh((prev) => !prev);
      setIsDrawerOpen((prev) => ({
        ...prev,
        disbursement: {
          open: false,
          type: ''
        }
      }));
      antdMessage.success(successMessage);
      return data;
    } catch (error) {
      return message(error);
    }
  };

  /**
   * Handles the billing options switch.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {Object} options.item - The item object.
   * @param {boolean} options.e - The value of the switch.
   * @param {Object} options.form - The form object.
   * @param {Function} options.setBillingOptionsAmounts - The function to set billing options amounts.
   * @param {Function} options.setBillingOptionsColors - The function to set billing options colors.
   * @returns {void}
   */
  static handleBillingOptionsSwitch = ({
    item,
    e,
    form,
    setBillingOptionsAmounts,
    setBillingOptionsColors
  }) => {
    if (['compliance', 'adaptation', 'rcp_creation'].includes(item.name)) {
      form.setFieldsValue({
        billing_options: {
          compliance: item.name === 'compliance' ? e : false,
          adaptation: item.name === 'adaptation' ? e : false,
          rcp_creation: item.name === 'rcp_creation' ? e : false,
          charge_grid_creation: form.getFieldValue([
            'billing_options',
            'charge_grid_creation'
          ]),
          pcs_creation: form.getFieldValue(['billing_options', 'pcs_creation'])
        }
      });

      setBillingOptionsAmounts({
        total_ht: item.defaultValue || 0
      });

      setBillingOptionsColors((prev) => ({
        ...prev,
        compliance: item.name === 'compliance' && e ? 'yellow' : 'gray',
        adaptation: item.name === 'adaptation' && e ? 'yellow' : 'gray',
        rcp_creation: item.name === 'rcp_creation' && e ? 'yellow' : 'gray'
      }));
    } else {
      setBillingOptionsAmounts((prev) => ({
        total_ht: e
          ? prev.total_ht + (item.defaultValue || 0)
          : prev.total_ht - (item.defaultValue || 0)
      }));

      setBillingOptionsColors((prev) => ({
        ...prev,
        [item.name]: e ? 'yellow' : 'gray'
      }));
    }
  };

  /**
   * Handles the lawyer consultation checkbox.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {boolean} options.e - The value of the switch.
   * @param {string} options.fieldName - The name of the field.
   * @param {Function} options.setCheckedCheckboxCount - The function to set the checked checkbox count.
   * @param {Object} options.form - The form object.
   * @returns {void}
   */
  static handleLawyerConsultationCheckboxChange = ({
    e,
    fieldName,
    setCheckedCheckboxCount,
    form
  }) => {
    const isChecked = e.target.checked;

    setCheckedCheckboxCount((prev) => ({
      ...prev,
      count: isChecked ? prev.count + 1 : prev.count - 1
    }));

    if (
      [
        'need_to_bring_into_compliance',
        'legal_adaptation_recommended',
        'creation_of_rcp'
      ].includes(fieldName)
    ) {
      if (isChecked) {
        form.setFieldsValue({
          lawyer_consultation: {
            need_to_bring_into_compliance:
              fieldName === 'need_to_bring_into_compliance',
            legal_adaptation_recommended:
              fieldName === 'legal_adaptation_recommended',
            creation_of_rcp: fieldName === 'creation_of_rcp'
          }
        });
      }
    }
  };

  /**
   * Re-generates PCS One documents for a mission.
   * @function
   * @async
   * @param {Object} options - The options for re-generating PCS One documents.
   * @param {string} options.id - The ID of the mission.
   * @param {function} options.setIsLoading - The function to set the loading state.
   * @param {function} options.setForceRefresh - The function to force refresh the mission.
   * @returns {Promise<null|Error>} A promise that resolves to the data if successful, or an error if unsuccessful.
   */
  static async reGeneratePcsOneDocuments({
    id,
    setIsLoading,
    setForceRefresh
  }) {
    try {
      setIsLoading(true);
      const { data } = await this.dispatchAPI('PATCH', {
        url: `${Mission.resourceName}/re-generate-pcs-one-documents/${id}`
      });

      if (data) {
        setIsLoading(false);
        setForceRefresh((prev) => !prev);
        antdMessage.success(t(`missions.messages.${data}`));
        return data;
      }

      return null;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  }

  /**
   * Handles the form submission for reassigning missions.
   *
   * @function
   * @async
   * @param {Object} options - The options for handling the form submission.
   * @param {any} options.value - The value of the form.
   * @param {Array} options.selectedMissions - The selected missions to be reassigned.
   * @param {Function} options.message - The function to display error messages.
   * @param {Function} options.setForceRefresh - The function to trigger a force refresh.
   * @param {Function} options.setIsReassignModalOpen - The function to control the reassign modal visibility.
   * @param {Function} options.setSelectedRowKeys - The function to set the selected row keys.
   * @param {Function} options.setSelectedMissions - The function to set the selected missions.
   * @returns {Promise<void>} - A promise that resolves when the form submission is handled.
   */
  static async handleReassignFormSubmit({
    value,
    selectedMissions,
    message,
    setForceRefresh,
    setIsReassignModalOpen,
    setSelectedRowKeys,
    setSelectedMissions
  }) {
    try {
      await this.dispatchAPI('PATCH', {
        url: `/missions/reassign/${value.new_collective_ownership}`,
        body: selectedMissions
      });

      setIsReassignModalOpen(false);
      setSelectedRowKeys([]);
      setSelectedMissions([]);
      setForceRefresh((prev) => !prev);
      antdMessage.success(t('missions.messages.reassign_success'));
    } catch (e) {
      message(e);
    }
  }

  /**
   * Cancels a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for canceling the mission.
   * @param {string} options.id - The ID of the mission to be canceled.
   * @param {function} options.message - The function to display an error message.
   * @param {function} options.navigate - The function to navigate to a new page.
   * @param {Array} options.invoices - The array of invoices.
   * @param {Object} options.mission - The mission object.
   * @returns {Promise<void>} - A promise that resolves when the mission is canceled.
   */
  static async cancelMission({ id, message, navigate, invoices, mission }) {
    try {
      await this.dispatchAPI('PATCH', {
        url: `/missions/cancel-mission/${id}`
      });

      const filteredInvoices = invoices.filter(
        (el) => el.type !== 'CREDIT_NOTE'
      );

      navigate('/billing-and-accountings/invoices/create', {
        state: {
          type: 'CREDIT_NOTE',
          invoices: filteredInvoices.slice(1, -1),
          mission,
          syndic_id: mission.syndic._id,
          collective_ownership_id: mission.collective_ownership._id,
          is_mission_cancellation_form: true
        }
      });
    } catch (e) {
      message(e);
    }
  }

  /**
   * Confirms refunds for a mission.
   *
   * @function
   * @async
   * @param {Object} options - The options for confirming refunds.
   * @param {string} options.id - The ID of the mission.
   * @param {Function} options.message - The message function to display errors.
   * @returns {Promise<void>} - A promise that resolves when the refunds are confirmed.
   */
  static async confirmRefunds({
    id,
    message,
    setForceRefresh,
    setIsDrawerOpen
  }) {
    try {
      await this.dispatchAPI('PATCH', {
        url: `/missions/confirm-refunds/${id}`
      });

      setForceRefresh((prev) => !prev);
      setIsDrawerOpen((prev) => ({ ...prev, confirm_refunds: false }));
      antdMessage.success(t('missions.messages.mission_refunds_confirmed'));
    } catch (e) {
      message(e);
    }
  }

  /**
   * Sends a reminder to the provider.
   *
   * @function
   * @async
   * @param {string} plateform - The platform to send the reminder to.
   * @param {string} id - The ID of the provider.
   * @returns {Promise<void>} - A promise that resolves when the reminder is sent successfully.
   * @throws {Error} - If an error occurs while sending the reminder.
   */
  async providerReminder(plateform, id) {
    try {
      await this.dispatchAPI('GET', {
        url: `missions/reminder_provider/${id}/${plateform}`
      });
    } catch (e) {
      this.message(e);
    }
  }
}
