import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../components';
import { usePublicationListContent } from './listContent/publication';

/**
 * The `Publication` component is designed to display publication-related information for a syndic entity in a structured list format. It utilizes the `usePublicationListContent` hook to generate the content based on the syndic's data and presents it using the `DescriptionList` component.
 *
 * Props:
 * @prop {Object} [syndic={}] - An object containing publication information for a syndic entity. The structure of this object should align with the expected data format of the `usePublicationListContent` hook.
 *
 * The component first invokes the `usePublicationListContent` hook, passing in the `syndic` prop to generate an array of objects representing each piece of publication information to be displayed. This array is then provided as the `data` prop to the `DescriptionList` component, which renders the list of publication information.
 *
 * The `DescriptionList` component is a custom component designed to display data in a key-value pair format, where each pair is represented by a label (key) and its corresponding content (value). The `translate` prop indicates that the labels should be translated using internationalization, and the `vertical` prop specifies that the list should be displayed in a vertical orientation.
 *
 * Usage:
 * This component is intended for use within the detailed view of a syndic entity, where a section is dedicated to displaying the syndic's publication information, such as office usual name, society form, capital, and other legal details. It provides a clear and concise presentation of this information, enhancing the user's understanding of the syndic's publication status.
 */

export const Publication = ({ syndic }) => {
  const publicationListContent = usePublicationListContent(syndic);
  return <DescriptionList data={publicationListContent} translate vertical />;
};

Publication.propTypes = {
  syndic: PropTypes.shape({})
};

Publication.defaultProps = {
  syndic: {}
};
