import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../../utils/constants/servicesMenuLogos';
import { useHandleResize } from '../../../utils/handleResize';
import {
  routesCollectiveOwnership,
  routesPlansResearch,
  subRoutes,
  pathSearches
} from '../../../utils/constants/routes/servicesRoutes';
import { renderMenuItems } from '../renderMenuItems';

/**
 * ServicesNavMenu component renders the navigation menu for services.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setCollapsed - A function to set the collapsed state of the menu.
 * @returns {JSX.Element} The rendered ServicesNavMenu component.
 */
export const ServicesNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  let menuToSet;
  if (path.includes('/plans-research') || path.includes('/rcp-research')) {
    menuToSet = routesPlansResearch;
  }
  if (path.includes('/compliance-loi-elan')) {
    menuToSet = routesCollectiveOwnership;
  }

  const menuItems = renderMenuItems(
    menuToSet,
    subRoutes,
    t,
    navMenuLogos,
    NavLink,
    pathSearches,
    { color: 'black' },
    true
  );

  return (
    <Menu
      style={{
        padding: '12px 0',
        backgroundColor: 'transparent'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={menuItems}
    />
  );
};

ServicesNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
