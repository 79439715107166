import { FolderOpenFilled } from '@ant-design/icons';
import { ConfigProvider, Flex, Input, Upload, Tooltip } from 'antd';

const { Dragger } = Upload;

/**
 * Decides the display input type based on the provided parameters.
 *
 * @function
 * @param {boolean} isFileInput - Flag to check if the input is of type file.
 * @param {ReactElement} input - The Input component to be displayed.
 * @param {Object} draggerProps - Properties to pass to the Dragger component.
 * @param {boolean} disabled - Flag to check if the input is disabled.
 * @param {function} t - Translation function for internationalization support.
 * @returns {ReactElement} - Returns a Dragger component for file input, or the provided input element, or a disabled Input element based on the conditions.
 *   @param {boolean} iconOnly - Flag to check if the icon should be displayed only.
 */
export const inputDisplaySmall = (
  isFileInput,
  input,
  draggerProps,
  disabled,
  t,
  iconOnly
) => {
  if (isFileInput) {
    return (
      <ConfigProvider
        theme={{
          token: {
            padding: 0,
            fontSize: 6,
            colorPrimaryHover: 'gray'
          }
        }}
      >
        <Dragger {...draggerProps}>
          <Flex
            justify="space-evenly"
            align="center"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {iconOnly ? (
              <Tooltip
                title={t('files.create.action')}
                overlayStyle={{ fontSize: '10px' }}
              >
                <p
                  className="ant-upload-drag-icon"
                  style={{ margin: 2, marginRight: 0 }}
                >
                  <FolderOpenFilled style={{ color: 'gray' }} />
                </p>
              </Tooltip>
            ) : (
              <p
                className="ant-upload-drag-icon"
                style={{ margin: 2, marginRight: 8 }}
              >
                <FolderOpenFilled style={{ color: 'gray' }} />
              </p>
            )}
            {!iconOnly && (
              <p
                className="ant-upload-text"
                style={{ marginRight: 3, fontSize: 12 }}
              >
                {t('files.create.action')}
              </p>
            )}
          </Flex>
        </Dragger>
      </ConfigProvider>
    );
  }
  if (input) {
    return input;
  }
  return <Input disabled={disabled} />;
};
