import React from 'react';
import PropTypes from 'prop-types';
import { MenuOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

/**
 * A component that represents a draggable row in an editable table.
 *
 * @component
 * @param {Object} props - The properties for the DraggingRow component.
 * @param {ReactNode} props.children - The child elements of the DraggingRow component.
 * @returns {JSX.Element} The rendered DraggingRow component.
 */
export const DraggingRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props['data-row-key']
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999
        }
      : {})
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move'
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

DraggingRow.propTypes = {
  children: PropTypes.node,
  'data-row-key': PropTypes.string,
  style: PropTypes.shape({})
};

DraggingRow.defaultProps = {
  children: [],
  'data-row-key': '',
  style: {}
};
