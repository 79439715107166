import dayjs from 'dayjs';
import { MunicipalitiesMethods } from './methods';

/**
 * Returns a configuration object for creating, updating, and getting a resource.
 * @returns {Object} The configuration object.
 */

export const useConfig = () => {
  const { onCreateUpdateResourceBody } = MunicipalitiesMethods();

  return {
    onCreateResource: {
      setBody: (data) => onCreateUpdateResourceBody({ data })
    },

    onUpdateResource: {
      setBody: (data) => onCreateUpdateResourceBody({ data })
    },

    onGetResource: {
      setFields: (data) => {
        const newData = {
          ...data,
          services: data?.services?.map((service) => ({
            ...service,
            start_date: service.start_date && dayjs(service.start_date),
            end_date: service.end_date && dayjs(service.end_date)
          }))
        };
        return newData;
      }
    }
  };
};
