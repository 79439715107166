import { Table } from 'antd';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useManagerColumns } from '../columns/managersColumns';
import { ExpandedInvoicesTable } from './ExpandedInvoicesTable';

/**
 * ExpandedManagerTable component displays a table of managers using Ant Design's Table component.
 * It includes expandable rows that reveal a nested table of invoices for each manager.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Array} props.dataSource - The data to be displayed in the table, typically an array of manager objects.
 * @param {Function} setOpen - A function to open a related modal or drawer.
 * @param {string} comment - A comment or note to be displayed or used in the table.
 *
 *
 * @returns {JSX.Element} The rendered ExpandedManagerTable component.
 */
export const ExpandedManagerTable = ({ dataSource = [], setOpen, comment }) => {
  const columns = useManagerColumns(comment);

  const expandedRowRender = useCallback((record) => {
    const tableData = record?.invoices;
    return (
      <ExpandedInvoicesTable
        dataSource={tableData}
        setOpen={setOpen}
        comment={comment}
      />
    );
  }, []);

  return (
    <Table
      dataSource={dataSource.map((item, index) => ({
        ...item,
        key: item._id || index
      }))}
      columns={columns}
      expandable={{
        expandedRowRender,
        rowExpandable: (record) => record?.invoices?.length > 0
      }}
      pagination={false}
      showHeader={false}
    />
  );
};

ExpandedManagerTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  setOpen: PropTypes.func.isRequired,
  comment: PropTypes.string
};

ExpandedManagerTable.defaultProps = {
  dataSource: [],
  comment: ''
};
