import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { PlusCircleOutlined, ExperimentOutlined } from '@ant-design/icons';
import { PageHeaderCustom, ContentCustom } from '../../../components';
import DashboardActionButton from './components/DashboardActionButton';
import ConsultantInvoices from './components/ConsultantInvoices';
import LastMessages from './components/LastMessages';
import { MessageContextProvider } from '../../../contexts/MessageContext';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * AssistantDashboard component renders a dashboard interface for the assistant user role.
 * It displays buttons to create new missions, view waiting missions, and view consultant invoices.
 * Additionally, it shows the last messages related to the user.
 *
 * @component
 * @returns {JSX.Element} The rendered AssistantDashboard component.
 *
 * @example
 * return (
 *   <AssistantDashboard />
 * )
 */
// faire les raccourcis anomalies, à voir plus tard
export const AssistantDashboard = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const iconStyle = { fontSize: 35, color: '#FFD53F' };
  return (
    <>
      <PageHeaderCustom title={t('dashboard.title')} />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <DashboardActionButton
                  text="new_mission"
                  icon={<PlusCircleOutlined style={iconStyle} />}
                  link="/missions/pcs-pre-productions/create"
                  t={t}
                />
              </Col>
              <Col span={12}>
                <DashboardActionButton
                  text="waiting_missions"
                  icon={<ExperimentOutlined style={iconStyle} />}
                  link="/missions/pcs-pre-productions/"
                  t={t}
                />
              </Col>
              <Col span={12}>
                <ConsultantInvoices t={t} />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <MessageContextProvider id={user._id} model="User">
              <LastMessages t={t} />
            </MessageContextProvider>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
