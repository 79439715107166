import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns an array of columns for a table.
 * Each column object contains a title, key, dataIndex, and sorter.
 * The title and dataIndex values are translated using the useTranslation hook.
 *
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('predefined-messages.form.label'),
      key: 'label',
      dataIndex: 'label',
      sorter: (a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    },
    {
      title: t('predefined-messages.form.type'),
      key: 'type',
      dataIndex: 'type',
      sorter: (a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase())
    }
  ];
};
