import { Card, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useColumns } from '../../columns/planRequestColumns';
import { Mission } from '../../../../../../models/Mission';
import { Order } from '../../../../../../models/Order';
import { CollectiveOwnership } from '../../../../../../models/CollectiveOwnership';

/**
 * Renders a card component that displays plan requests for a mission.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.mission - The mission object.
 * @param {boolean} props.forceRefresh - Flag indicating if the component should force a refresh.
 * @param {function} props.setForceRefresh - Function to set the forceRefresh flag.
 * @param {boolean} [props.noEdit=false] - Flag indicating if editing is allowed.
 * @returns {JSX.Element} The rendered PlanRequestsCard component.
 */
export const PlanRequestsCard = ({
  mission,
  forceRefresh,
  setForceRefresh,
  lastStep
}) => {
  const { t } = useTranslation();
  const [planRequests, setPlanRequests] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [plan, setPlan] = useState({});
  const [expeditor, setExpeditor] = useState('');
  const columns = useColumns({ setPlan, setExpeditor });

  const getPlanRequests = async () => {
    if (!mission) return;
    setDataLoading(true);
    const { plan_requests } = await Mission.getPlanRequestsByMissionId(
      mission._id
    );
    setPlanRequests(plan_requests);
    setDataLoading(false);
  };

  const handlePlanChange = async () => {
    if (plan._id) {
      await Mission.patchPlanRequest({
        missionId: mission._id,
        values: plan,
        planRequestId: plan._id,
        lastStep
      });
      await getPlanRequests();

      if (plan.status === 'PLANS_AVAILABLE') {
        const concerned_plan =
          await CollectiveOwnership.getSpecificPlanFromCollectiveOwnership(
            plan.collective_ownership_plan,
            mission
          );
        const values = {
          name: 'Plan',
          type: 'TO_BE_ORDERED',
          mission: mission._id,
          expeditor,
          status: 'TO_BE_ORDERED',
          added_date: plan.date,
          ref_doc: `${concerned_plan.plan_volume}-${concerned_plan.plan_number}`,
          costs: 0,
          history: [
            {
              type: 'POST',
              date: new Date(),
              description: 'ORDER_CREATED'
            }
          ]
        };

        await Order.createOrder({ values });

        setForceRefresh(!forceRefresh);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getPlanRequests();
    })();
  }, [mission]);

  useEffect(() => {
    (async () => {
      await handlePlanChange();
    })();
  }, [plan]);

  return (
    <Card title={t('missions.cards.titles.plan_requests')}>
      <Table
        columns={columns}
        dataSource={planRequests}
        loading={dataLoading}
        pagination={false}
        rowKey={(record) => record._id}
        scroll={{ x: 'max-content' }}
      />
    </Card>
  );
};

PlanRequestsCard.propTypes = {
  mission: PropTypes.objectOf({}).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  lastStep: PropTypes.bool
};

PlanRequestsCard.defaultProps = {
  lastStep: false
};
