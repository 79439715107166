export const updateChargingGrids = ({
  resourceDatas,
  chargingGrid,
  setResourceDatas
}) => {
  const chargingGrids = resourceDatas.charging_grid.map((grid) => {
    if (grid.version === chargingGrid.version) {
      return chargingGrid;
    }
    return grid;
  });
  const newResourceDatas = {
    ...resourceDatas,
    charging_grid: chargingGrids
  };
  setResourceDatas(newResourceDatas);
};
