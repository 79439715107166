import { Form, Select, Switch, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Renders a form with search bars for filtering PCS appointment monitoring data.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.syndics - The array of syndics.
 * @param {Array} props.consultants - The array of consultants.
 * @param {Function} props.form - The form object.
 * @param {Function} props.setFilters - The function to set the filters.
 * @returns {JSX.Element} The rendered component.
 */
export const ExtraSearchBars = ({ syndics, consultants, form, setFilters }) => {
  const { t } = useTranslation();
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Form form={form} layout="inline" style={{ marginTop: 10 }}>
      <Form.Item name={['syndic']}>
        <Select
          style={{ width: 220 }}
          showSearch
          allowClear
          onChange={(value) => {
            setFilters((prev) => ({ ...prev, 'syndic._id': value }));
          }}
          filterOption={filterOption}
          placeholder={t('placeholder.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {syndic.society_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['consultant']}>
        <Select
          style={{ width: 220 }}
          showSearch
          allowClear
          filterOption={filterOption}
          onChange={(value) => {
            setFilters((prev) => ({ ...prev, 'syndic.consultant._id': value }));
          }}
          placeholder={t('placeholder.search_consultant')}
        >
          {(consultants || []).map((consultant) => (
            <Option key={consultant._id} value={consultant._id}>
              {`${consultant.last_name || ''} ${consultant.first_name || ''}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={['lawyer_consultation_received']}
        label={t(
          'pcs-appointment-monitoring.form.lawyer_consultation_received'
        )}
        valuePropName="checked"
      >
        <Switch
          onChange={(e) => {
            setFilters((prev) => ({
              ...prev,
              'mission.lawyer_consultation_received': e
            }));
          }}
        />
      </Form.Item>
      <Form.Item
        name={['first_mission_date']}
        label={t('pcs-appointment-monitoring.form.first_mission_date')}
      >
        <RangePicker
          style={{ width: 300 }}
          onChange={(values) => {
            if (values) {
              setFilters((prev) => ({
                ...prev,
                'mission.release_date>=': {
                  value: dayjs(values[0].$d).toISOString(),
                  no_operator: true
                },
                'mission.release_date<=': {
                  value: dayjs(values[1].$d).toISOString(),
                  no_operator: true
                }
              }));
            } else {
              setFilters((prev) => ({
                ...prev,
                'mission.release_date>=': '',
                'mission.release_date<=': ''
              }));
            }
          }}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
        />
      </Form.Item>
      <Form.Item
        name={['appointment_date']}
        label={t('pcs-appointment-monitoring.form.appointment_date')}
      >
        <RangePicker
          style={{ width: 300 }}
          onChange={(values) => {
            if (values) {
              setFilters((prev) => ({
                ...prev,
                appointment_date_start: {
                  value: dayjs(values[0].$d).toISOString()
                },
                appointment_date_end: {
                  value: dayjs(values[1].$d).toISOString()
                }
              }));
            } else {
              setFilters((prev) => ({
                ...prev,
                appointment_date_start: '',
                appointment_date_end: ''
              }));
            }
          }}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
        />
      </Form.Item>
      <Form.Item
        name={['no_activity_between_dates']}
        label={t('pcs-appointment-monitoring.form.no_activity_between_dates')}
      >
        <RangePicker
          style={{ width: 300 }}
          onChange={(values) => {
            if (values) {
              setFilters((prev) => ({
                ...prev,
                no_activity_between_dates_start: dayjs(
                  values[0].$d
                ).toISOString(),
                no_activity_between_dates_end: dayjs(values[1].$d).toISOString()
              }));
            } else {
              setFilters((prev) => ({
                ...prev,
                no_activity_between_dates_start: null,
                no_activity_between_dates_end: null
              }));
            }
          }}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
        />
      </Form.Item>
    </Form>
  );
};

ExtraSearchBars.propTypes = {
  syndics: PropTypes.arrayOf(PropTypes.shape({})),
  consultants: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  setFilters: PropTypes.func.isRequired
};

ExtraSearchBars.defaultProps = {
  syndics: [],
  consultants: []
};
