import { notification, message as antdMessage } from 'antd';
import dayjs from 'dayjs';
import { BaseModel } from './BaseModel';

/**
 * Represents an quotation.
 * @class
 */
export class Quotation extends BaseModel {
  static resourceName = 'quotations';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Quotation.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Quotation.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Duplicate a resource by its ID.
   *
   * @param {Object} options - The options for duplicating the resource.
   * @param {string} options.id - The ID of the resource to duplicate.
   * @param {function} options.navigate - The navigation function to redirect after duplication.
   * @param {function} options.t - The translation function for localization.
   * @returns {Promise<void>} - A promise that resolves when the duplication is successful.
   */
  static async duplicateResource({ id, navigate, t }) {
    const { data } = await this.dispatchAPI('PATCH', {
      url: `${Quotation.resourceName}/duplicate/${id}`
    });

    if (data) {
      antdMessage.success(t('quotations.messages.quotation_duplicate'));
      navigate(`/quotations/edit/${data._id}`, {
        state: { force_refresh: true }
      });
    }
  }

  /**
   * Archives multiple quotations.
   *
   * @function
   * @async
   * @param {Object} options - The options for archiving multiple quotations.
   * @param {Array} options.ids - The IDs of the quotations to be archived.
   * @param {Function} options.setForceRefresh - The function to trigger a force refresh after archiving.
   * @returns {Promise<void>} - A promise that resolves when the quotations are archived.
   */
  static async archiveMultipleQuotations({ ids, setForceRefresh }) {
    await this.dispatchAPI('PATCH', {
      url: `${
        Quotation.resourceName
      }/archive-multiple-quotations?objectIds=${JSON.stringify(ids)}`
    });

    setForceRefresh((prev) => !prev);
  }

  /**
   * Represents the status steps for a quotation.
   * @type {Object}
   * @property {Object} principal - The principal status steps.
   * @property {number} principal.TO_BE_COMPLETED - The "To be completed" status.
   * @property {number} principal.TO_SEND - The "To send" status.
   * @property {number} principal.WAITING_CUSTOMER - The "Waiting for customer" status.
   * @property {number} principal.PARTIAL_VALIDATION - The "Partial validation" status.
   * @property {number} principal.VALIDATED - The "Validated" status.
   * @property {number} principal.DENIED - The "Denied" status.
   * @property {number} principal.ARCHIVED - The "Archived" status.
   * @property {Object} sub - The sub status steps.
   * @property {number} sub.PARAMETERS - The "Parameters" status.
   * @property {number} sub.DETAILS - The "Details" status.
   * @property {number} sub.SUMMARY - The "Summary" status.
   * @property {number} sub.AMOUNTS - The "Amounts" status.
   * @property {number} sub.DOCUMENTS_REQUEST - The "Documents request" status.
   * @property {number} sub.VALIDATION - The "Validation" status.
   */
  static statusSteps = {
    principal: {
      TO_BE_COMPLETED: 0,
      TO_SEND: 1,
      WAITING_CUSTOMER: 2,
      PARTIAL_VALIDATION: 3,
      VALIDATED: 4,
      DENIED: 5,
      ARCHIVED: 6
    },
    sub: {
      PARAMETERS: 0,
      DETAILS: 1,
      SUMMARY: 2,
      AMOUNTS: 3,
      DOCUMENTS_REQUEST: 4,
      VALIDATION: 5
    }
  };

  /**
   * Represents the steps object for a quotation.
   * @type {Object}
   * @property {string[]} principal - The principal steps of the quotation.
   * @property {string[]} sub - The sub steps of the quotation.
   */
  static stepsObject = {
    principal: [
      'TO_BE_COMPLETED',
      'TO_SEND',
      'WAITING_CUSTOMER',
      'PARTIAL_VALIDATION',
      'VALIDATED',
      'DENIED',
      'ARCHIVED'
    ],
    sub: [
      'PARAMETERS',
      'DETAILS',
      'SUMMARY',
      'AMOUNTS',
      'DOCUMENTS_REQUEST',
      'VALIDATION'
    ]
  };

  /**
   * Updates the quotation status.
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {Form} options.form - The form object.
   * @param {string} options.element - The element value.
   * @param {string} options.id - The quotation ID.
   * @param {function} options.setCurrent - The setCurrent function.
   * @param {function} options.setForceRefresh - The setForceRefresh function.
   * @param {string} options.stepType - The step type.
   * @param {function} options.setFormData - The setFormData function.
   * @param {function} options.t - The translation function.
   * @returns {null} - Returns null.
   */
  static updateQuotationStatus = async ({
    form,
    element,
    id,
    setCurrent,
    setForceRefresh,
    stepType,
    setFormData,
    t
  }) => {
    let stepKey = {};
    let values = {};

    try {
      values = await form.validateFields();
    } catch (error) {
      if (error.errorFields.length) {
        return notification.warning({
          message: t('quotations.messages.required_fields_title'),
          description: t('quotations.messages.required_fields_description')
        });
      }
    }

    setFormData((prev) => ({ ...prev, ...values }));

    switch (stepType) {
      case 'principal':
        setCurrent((prev) => ({ ...prev, principal: element }));
        stepKey = 'status';
        break;
      case 'sub':
        setCurrent((prev) => ({ ...prev, sub: element }));
        stepKey = 'step';
        break;
      default:
        break;
    }

    const formData = new FormData();
    formData.append(
      'values',
      JSON.stringify({ [stepKey]: this.stepsObject[stepType][element] })
    );

    if (id) {
      await this.dispatchAPI('PATCH', {
        url: `${Quotation.resourceName}/${id}`,
        body: formData
      });

      setForceRefresh((prev) => !prev);
    } else {
      setCurrent((prev) => ({
        ...prev,
        [stepType]: element
      }));
    }

    return null;
  };

  /**
   * Sets the dynamic fields for the quotation.
   * @function
   * @param {Object} options - The options object.
   * @param {Function} options.setSyndicOptions - The function to set the syndic options.
   * @param {Array} options.syndics - The array of syndics.
   * @param {Object} options.syndic - The selected syndic.
   * @param {Function} options.setCollectiveOwnershipOptions - The function to set the collective ownership options.
   * @param {Array} options.collectiveOwnerships - The array of collective ownerships.
   * @returns {null} - Returns null.
   */
  static setDynamicFields = ({
    setSyndicOptions,
    syndics,
    syndic,
    setCollectiveOwnershipOptions,
    collectiveOwnerships
  }) => {
    setSyndicOptions(syndics);

    if (syndic) {
      const collectiveOwnershipFilteredBySyndic = collectiveOwnerships.filter(
        (item) => item.syndic._id === syndic._id
      );
      setCollectiveOwnershipOptions(collectiveOwnershipFilteredBySyndic);
    } else {
      setCollectiveOwnershipOptions(collectiveOwnerships);
    }
  };

  /**
   * Handles the validation values of the initial and updated objects.
   * @function
   * @param {Object} initialObj - The initial object.
   * @param {Object} updatedObj - The updated object.
   * @returns {Array} - An array containing the keys of the objects that need validation.
   */
  static handleValidationValues = (initialObj, updatedObj) => {
    const actId = [
      ...new Set([
        ...Object.keys(initialObj || {}),
        ...Object.keys(updatedObj || {})
      ])
    ];

    return actId.reduce((result, key) => {
      if ((updatedObj || {})[key] === true) {
        result.push(key);
      }
      return result;
    }, []);
  };

  /**
   * Checks if the actions in the provided data are unique based on their numbers.
   * Displays a warning message if duplicate actions are found.
   *
   * @function
   * @param {Object} data - The data containing the actions to be checked.
   * @param {Object} t - The translation object for localization.
   * @returns {boolean} - Returns true if all actions are unique, false otherwise.
   */
  static checkUniqueActions(data, t) {
    const numbersMap = {};
    let isUnique = true;

    Object.keys(data || {}).forEach((key) => {
      const obj = data[key];
      const number = obj.number;
      const action = obj.quotation_action?.action;

      if (number) {
        if (!numbersMap[number]) {
          numbersMap[number] = new Set();
        }
        numbersMap[number].add(action);
      }
    });

    Object.keys(data || {}).forEach((key) => {
      const obj = data[key];
      const number = obj.number;
      const action = obj.quotation_action?.action;

      if (
        number &&
        action &&
        ['SALE', 'EXCHANGE', 'SURVEYOR_QUOTATION'].includes(action)
      ) {
        if (numbersMap[number].size > 1) {
          antdMessage.warning(
            t('errors.message.UNIQUE_ACT', {
              action: t(`quotations.tags.${action}`)
            })
          );
          isUnique = false;
        }
      }
    });

    return isUnique;
  }

  /**
   * Creates a mission for Phase Three of the PCS process.
   * @function
   * @async
   * @param {Object} options - The options for creating the mission.
   * @param {string} options.mission - The object of the mission.
   * @param {function} options.setIsLoading - The function to set the loading state.
   * @param {function} options.navigate - The function to navigate to a new location.
   * @param {function} options.message - The function to display a message.
   * @returns {Promise<void>} - A promise that resolves when the mission is created.
   */
  static createMissionPcsPhaseThree = async ({
    mission,
    setIsLoading,
    navigate,
    message
  }) => {
    try {
      await this.dispatchAPI('PATCH', {
        url: `missions/pcs-phase-three/${mission._id}?mission_type=PCS_3`
      });

      setIsLoading(true);
      return navigate(`/missions/missions-view/show/PCS/${mission._id}/PCS_3`, {
        state: { refresh: true }
      });
    } catch (error) {
      return message(error);
    }
  };

  /**
   * Handles the form submission for creating or editing a quotation.
   *
   * @function
   * @async
   * @param {Object} options - The options for form submission.
   * @param {Object} options.values - The values from the form.
   * @param {Object} options.form - The form object.
   * @param {string} options.id - The ID of the quotation (for editing).
   * @param {string} options.purpose - The purpose of the form submission ('create' or 'edit').
   * @param {Object} options.current - The current state of the form.
   * @param {Function} options.setForceRefresh - The function to trigger a refresh of the data.
   * @param {Function} options.navigate - The function to navigate to a different page.
   * @param {Object} options.message - The message object for displaying success messages.
   * @param {Function} options.t - The translation function.
   * @param {string} options.type - The type of the quotation.
   * @param {Function} options.setIsLoading - The function to set the loading state.
   * @param {Object} options.filesList - The list of files.
   * @param {Object} options.state - The state object.
   * @returns {null} - Returns null.
   */
  static onSubmitForm = async ({
    values,
    form,
    id,
    purpose,
    current,
    setForceRefresh,
    navigate,
    message,
    t,
    type,
    setIsLoading,
    filesList,
    state
  }) => {
    let actualFormValues = {};
    setIsLoading(true);

    try {
      actualFormValues = await form.validateFields();
    } catch (error) {
      if (error.errorFields.length) {
        setIsLoading(false);
        return notification.warning({
          message: t('quotations.messages.required_fields_title'),
          description: t('quotations.messages.required_fields_description')
        });
      }
    }

    const validation = this.handleValidationValues(
      values.validation,
      actualFormValues.validation
    );

    const updatedValues = {
      ...values,
      ...actualFormValues,
      type,
      quotation_acts: Object.keys(values.quotation_acts || {}).reduce(
        (acc, key) => {
          acc[key] = {
            ...values.quotation_acts[key],
            ...(actualFormValues?.quotation_acts
              ? actualFormValues.quotation_acts[key]
              : {})
          };
          return acc;
        },
        {}
      ),
      validation,
      status: this.stepsObject.principal[current.principal],
      step: this.stepsObject.sub[current.sub + 1]
        ? this.stepsObject.sub[current.sub + 1]
        : 'VALIDATION'
    };

    const isUnique = this.checkUniqueActions(updatedValues.quotation_acts, t);

    if (isUnique === false) {
      setIsLoading(false);
      return false;
    }

    const formData = new FormData();
    formData.append('values', JSON.stringify(updatedValues));

    Object.keys(filesList || {}).forEach((key) => {
      filesList[key].forEach((file) => {
        formData.append(key, file);
      });
    });

    const setApiCall = () => {
      switch (purpose) {
        case 'create':
          return { url: '/quotations', action: 'POST' };
        case 'edit':
          return { url: `/quotations/${id}`, action: 'PATCH' };
        default:
          return { url: '/quotations', action: 'POST' };
      }
    };

    const { data } = await this.dispatchAPI(setApiCall().action, {
      url: setApiCall().url,
      body: formData
    });

    message.success(t('quotations.messages.QUOTATION_SAVED'));

    if (state?.launch_pcs3 && current.sub === 5) {
      await this.createMissionPcsPhaseThree({
        mission: state.mission,
        setIsLoading,
        navigate,
        message
      });

      return null;
    }

    if (
      updatedValues.mission &&
      this.stepsObject.sub[current.sub] === 'VALIDATION'
    ) {
      return navigate(
        `/missions/missions-view/show/PCS/${updatedValues.mission._id}/${updatedValues.mission.type}`,
        {
          state: { force_refresh: true }
        }
      );
    }

    if (purpose === 'create') {
      return navigate(`/quotations/edit/${data._id}`, {
        state: { force_refresh: true }
      });
    }

    setForceRefresh((prev) => !prev);
    setIsLoading(false);
    return null;
  };

  /**
   * Formats an array of data into a table format.
   *
   * @function
   * @param {Array} dataArray - The array of data to be formatted.
   * @returns {Object} - The formatted table data.
   */
  static formatTableDatas = (dataArray) =>
    dataArray.reduce((acc, data) => {
      acc[data._id] = data;
      acc[data._id].AG_date = data.AG_date ? dayjs(data.AG_date) : null;
      return acc;
    }, {});

  /**
   * Sets default field values for a quotation.
   *
   * @function
   * @param {Object} options - The options for setting default field values.
   * @param {Object} options.form - The form object.
   * @param {Object} options.mission - The mission object.
   * @param {Function} options.setFormData - The function to set form data.
   * @param {Function} options.setSyndic - The function to set syndic.
   * @param {Function} options.setCollectiveOwnership - The function to set collective ownership.
   * @returns {void}
   */
  static setDefaultFieldsValues = ({
    form,
    mission,
    setFormData,
    setSyndic,
    setCollectiveOwnership,
    setMissionType
  }) => {
    if (mission.type === 'PCS_2') setMissionType('PCS_PUBLICATION_QUOTE');
    setSyndic(mission?.syndic);
    setCollectiveOwnership(mission?.collective_ownership);
    setFormData((prev) => ({
      ...prev,
      syndic: mission?.syndic?._id || mission?.syndic,
      collective_ownership:
        mission?.collective_ownership?._id || mission?.collective_ownership,
      mission: mission?._id,
      mission_type: mission?.mission_type
    }));
    form.setFieldsValue({
      syndic: mission?.syndic?._id || mission?.syndic,
      collective_ownership:
        mission?.collective_ownership?._id || mission?.collective_ownership,
      mission_type: mission?.mission_type
    });
  };
}
