import { Route, Routes, useLocation } from 'react-router-dom';
import { ListCollectiveOwnerships } from './ListCollectiveOwnerships';
import { CreateUpdateCollectiveOwnership } from './CreateUpdateCollectiveOwnership/CreateUpdateCollectiveOwnership';
import { ShowCollectiveOwnership } from './ShowCollectiveOwnership/ShowCollectiveOwnership';
import { CollectiveOwnershipContextProvider } from '../../contexts/CollectiveOwnershipContext';
import { Exception } from '../../components';

export const CollectiveOwnershipRouter = () => {
  const location = useLocation();
  return (
    <CollectiveOwnershipContextProvider>
      <Routes>
        <Route
          path="/create"
          element={<CreateUpdateCollectiveOwnership purpose="create" />}
        />
        <Route
          path="/edit/:id"
          element={<CreateUpdateCollectiveOwnership purpose="edit" />}
        />
        <Route
          path="/show/:id"
          element={<ShowCollectiveOwnership key={location.key} />}
        />
        <Route index element={<ListCollectiveOwnerships />} />
        <Route path="*" element={<Exception />} />
      </Routes>
    </CollectiveOwnershipContextProvider>
  );
};
