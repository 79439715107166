import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFields } from './useFields';

/**
 * Renders the CollectivesOwnershipAndType component.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values for the form.
 * @param {Function} props.setButtonsActive - The function to set the buttons active.
 * @returns {JSX.Element} The rendered CollectivesOwnershipAndType component.
 */
export const CollectivesOwnershipAndType = ({
  form,
  initialValues,
  setButtonsActive
}) => {
  const { selectCollectiveOwnershipFields, actionsToDoFields } = useFields(
    setButtonsActive,
    initialValues,
    form
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={15}>{selectCollectiveOwnershipFields}</Col>
      <Col span={9}>{actionsToDoFields}</Col>
    </Row>
  );
};

CollectivesOwnershipAndType.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  setButtonsActive: PropTypes.func.isRequired
};
