import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useColumns } from '../columns/orderColumns';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { EditableTableMethods } from '../../../../../components/editableTableComponents/methods';
import { addColumns } from '../../../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../../../components/ContentCustom/ContentCustom';
import { EditableTable } from '../../../../../components/editableTableComponents/EditableTable';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { GetEnums } from '../../../../../utils/getEnums';

/**
 * Component for displaying a list of ordered documents.
 * @component
 * @returns {JSX.Element} The ListOrderedDocuments component.
 */
export const ListOrderedDocuments = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { getEnums } = GetEnums();
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [enums, setEnums] = useState({});
  const { getResourceData } = EditableTableMethods();
  const {
    setPagination,
    pagination,
    forceRefresh,
    setForceRefresh,
    currentSorter
  } = useMissionContext();
  const { orderedColumns } = useColumns(enums);
  const [mergedColumns, setMergedColumns] = useState(orderedColumns);

  /**
   * Checks if a record is being edited.
   * @function
   * @param {Object} record - The record being checked.
   * @param {string} rowKey - The key of the selected row.
   * @returns {boolean} True if the record is being edited, false otherwise.
   */
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  useEffect(() => {
    (async () => {
      const [orderDatas] = await Promise.all([
        getResourceData({
          resource: 'orders/mission',
          setPagination,
          pagination,
          query: `&populate=cerfa_document_type,concerned_SPF,mission,document,document.files,expeditor&status=ORDERED&mission=${id}`,
          currentSorter
        })
      ]);
      const enumsDatas = await getEnums({ resource: 'documents' });

      setEnums(enumsDatas);
      setDataSource(orderDatas.map((el, index) => ({ ...el, key: index })));
    })();
  }, [forceRefresh, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: orderedColumns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      message,
      setMergedColumns,
      resource: 'orders'
    });
  }, [editingKey, dataSource]);

  return (
    <ContentCustom>
      <EditableTable
        resource="orders"
        dataSource={dataSource}
        mergedColumns={mergedColumns}
        setSelectedRowKey={setSelectedRowKey}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        form={form}
        pagination={pagination}
        setPagination={setPagination}
        withoutAddLineButton
        scroll={{ x: 'max-content' }}
        withoutHeader
        rowSelection={null}
      />
    </ContentCustom>
  );
};
