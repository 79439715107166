import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns configuration for the activity monitoring table.
 * @hook
 * @returns {Array} The array of columns configuration.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('activity-monitorings.columns.date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => (date ? dayjs(date).format('DD-MM-YYYY') : ''),
      sorter: true
    },
    {
      title: t('activity-monitorings.columns.user'),
      dataIndex: 'user',
      key: 'user',
      render: (user) => `${user?.first_name} ${user?.last_name}`,
      sorter: true
    },
    {
      title: t('activity-monitorings.columns.syndic'),
      dataIndex: 'syndic',
      key: 'syndic',
      render: (syndic) =>
        syndic ? `${syndic?.society_name || ''} - (${syndic?.code || ''})` : '',
      sorter: true
    },
    {
      title: t('activity-monitorings.columns.manager'),
      dataIndex: 'manager',
      key: 'manager',
      render: (manager) =>
        manager ? `${manager?.first_name} ${manager?.last_name}` : '',
      sorter: true
    },
    {
      title: t('activity-monitorings.columns.action_type'),
      dataIndex: 'action_type',
      key: 'action_type',
      render: (action_type) =>
        action_type ? t(`activity-monitorings.actions.${action_type}`) : '',
      sorter: true
    },
    {
      title: t('activity-monitorings.columns.details'),
      dataIndex: 'details',
      key: 'details',
      render: (details) => details || '-'
    }
  ];
};
