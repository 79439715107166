import { Button, Card, Col, Flex, Row, Steps, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Building, Rocket } from '@carbon/icons-react';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { formatAddress } from '../../../../utils/formatingFunctions';
import { DocumentsCollapse } from './DocumentsCollapse';
import { MessageContextProvider } from '../../../../../../contexts/MessageContext';
import { Messages } from '../../../../../../components/Messages/Messages';
import Phase_1 from '../../../../../../assets/images/phases_step_1.svg';
import Phase_2 from '../../../../../../assets/images/phases_step_2.svg';
import Phase_3 from '../../../../../../assets/images/phases_step_3.svg';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { actions_to_do } from '../../../../../../utils/constants/tagColors';
import { LawyerConsultationCard } from '../../../../../missions/missions-view/ShowMission/components/LawyerConsultationCard';
import { PrivateEnjoymentModal } from './PrivateEnjoymentModal';

const tradKey = 'syndic-view.collective-ownerships.show';
const { Title } = Typography;
const iconStyle = { fontSize: '18px', marginRight: '1rem' };
const cardStyles = {
  borderRadius: '10px'
};

/**
 * Renders the MissionCollectiveOwnershipsShow component.
 * @component
 * @param {boolean} isCounsil - True if the user is a counsil
 * @returns {JSX.Element} The rendered MissionCollectiveOwnershipsShow component.
 */
export const MissionCollectiveOwnershipsShow = () => {
  const { id } = useParams();
  const location = useLocation();
  const { dispatchAPI, user } = useAuthContext();
  const [mission, setMission] = useState({});
  const { collective_ownership = {} } = mission;
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const { t } = useTranslation();
  const isCounsil = user?.role === 'users:SYNDIC_COUNCIL';
  const [actionsToDo, setActionsToDo] = useState({
    total: {
      UNPAID_INVOICE: 0,
      PAID_INVOICE: 0
    }
  });

  const { message } = useErrorMessage();

  const getMission = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/mission/${id}?populate=collective_ownership,collective_ownership.first_city`
      });
      const stepMap = {
        PCS_1: {
          ADVANCE_PAYMENT: 2,
          BUILDING_FORM_ORDER: 3,
          BUILDING_FORM_DELIVERY: 3,
          ORDER_VERIFICATION: 4,
          DOCUMENTS_DELIVERY: 4,
          LAWYER_CONSULTATION: 5
        },
        PCS_2: {
          ADVANCE_PAYMENT: 1,
          PROJECT_IMPLEMENTATION: 4,
          INTERMEDIATE_PROJECT_SUBMISSION: 5,
          SYNDIC_VALIDATION: 6,
          FINAL_PROJECT_SUBMISSION: 7,
          FINALIZATION: 8
        },
        PCS_3: {
          BUILDING_FORM_ORDER: 1,
          MATRIX_FINALIZATION: 2,
          SEND_TO_NOTARY: 3,
          PUBLICATION: 4
        }
      };

      const defaultCurrent = {
        PCS_1: 0,
        PCS_2: 1,
        PCS_3: 0
      };

      const type = data?.type;
      const subStep = data?.steps?.sub;
      const lawyerStep = data?.lawyer_step;

      let localCurrent = defaultCurrent[type] || 0;

      if (type && subStep) {
        localCurrent = stepMap[type][subStep] || current;

        if (type === 'PCS_1' && subStep === 'LAWYER_CONSULTATION') {
          if (lawyerStep === 'AG_DECISION') {
            localCurrent = 6;
          } else if (lawyerStep === 'FINISHED') {
            localCurrent = 8;
          }
        }
      }
      setCurrent(localCurrent);
      setMission(data);
    } catch (error) {
      message(error);
    }
  };

  const getInvoices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoice?mission=${id}&status=PAID,UNPAID&fields=status`
      });

      const totalunpaid = data.filter((el) => el.status === 'UNPAID');
      const totalpaid = data.filter((el) => el.status === 'PAID');

      if (data.length > 0) {
        setActionsToDo({
          total: {
            UNPAID_INVOICE: totalunpaid.length,
            PAID_INVOICE: totalpaid.length
          }
        });
      }
    } catch (error) {
      message(error);
    }
  };

  const getStepItems = () => {
    const stepsCountMap = {
      PCS_1: 9,
      PCS_2: 9,
      PCS_3: 5
    };

    const stepsCount = stepsCountMap[mission.type] || 0;

    const steps = Array.from({ length: stepsCount }, (_, idx) => ({
      title: t(`${tradKey}.steps.${mission.type}.${idx}`)
    }));

    return steps;
  };

  useEffect(() => {
    getMission();
    getInvoices();
  }, []);

  const getReplacedUrl = (index) => {
    const currentPath = location.pathname;
    const actionsToDoIndex = currentPath.indexOf(index);

    if (actionsToDoIndex !== -1) {
      const newPath = `${currentPath.slice(
        0,
        actionsToDoIndex
      )}/compliance?mission_id=${id}`;
      return newPath;
    }
    return `/compliance?mission_id=${id}`;
  };
  const launchMission2Url = getReplacedUrl('/collective-ownerships');

  const renderPhaseImage = () => {
    if (!mission || !mission.type) {
      return null;
    }

    switch (mission.type) {
      case 'PCS_1':
        return <img src={Phase_1} alt="Phase 1" />;
      case 'PCS_2':
        return <img src={Phase_2} alt="Phase 2" />;
      case 'PCS_3':
        return <img src={Phase_3} alt="Phase 3" />;
      default:
        return null;
    }
  };

  return (
    <Row gutter={[24, 24]} style={{ padding: '1rem' }}>
      <Col span={24}>
        <Card
          style={{
            backgroundColor: '#222222',
            borderRadius: '15px',
            color: 'white'
          }}
        >
          <Row style={{ alignItems: 'center' }}>
            <Col span={19}>
              <LeftOutlined onClick={() => navigate(-1)} style={iconStyle} />
              <Building style={iconStyle} />
              {formatAddress(
                collective_ownership.name,
                collective_ownership.first_address,
                collective_ownership.first_city
              )}
            </Col>
            <Col span={4}>{renderPhaseImage()}</Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          style={{
            backgroundColor: 'white',
            borderRadius: '15px'
          }}
        >
          <Steps
            progressDot
            current={current}
            items={getStepItems()}
            style={{ marginBottom: 24 }}
          />
          <Row gutter={[24, 24]}>
            <Col span={!isCounsil ? 14 : 24} gap={20}>
              <Flex vertical gap={5}>
                <Row gutter={[16, 16]}>
                  <Col
                    span={['PCS_2', 'PCS_3'].includes(mission.type) ? 18 : 24}
                  >
                    <Card
                      style={cardStyles}
                      title={
                        <Title level={5} className="yellow-underline">
                          {t(`${tradKey}.cards.titles.to_do_actions`)}
                        </Title>
                      }
                    >
                      {mission?.steps?.sub === 'LAWYER_CONSULTATION' &&
                        mission?.lawyer_step === 'FINISHED' && (
                          <Button
                            type="primary"
                            disabled={isCounsil}
                            style={{ borderRadius: '15px' }}
                            onClick={() => navigate(launchMission2Url)}
                          >
                            <Rocket />
                            {t('missions.buttons.launch_phase_two')}
                          </Button>
                        )}
                      {current === 8 && mission?.type === 'PCS_2' && (
                        <Button
                          type="primary"
                          disabled={isCounsil}
                          style={{ borderRadius: '15px' }}
                          onClick={() => navigate(launchMission2Url)}
                        >
                          <Rocket />
                          {t('missions.buttons.launch_phase_three')}
                        </Button>
                      )}
                      {actionsToDo.total.UNPAID_INVOICE > 0 && (
                        <Tag color={actions_to_do.UNPAID_INVOICE}>
                          {` ${t(`missions.tags.UNPAID_INVOICE`)} (${
                            actionsToDo.total.UNPAID_INVOICE
                          })`}
                        </Tag>
                      )}
                    </Card>
                  </Col>
                  {['PCS_2', 'PCS_3'].includes(mission.type) && (
                    <Col span={6}>
                      <Card
                        style={cardStyles}
                        title={
                          <Title level={5} className="yellow-underline">
                            {t(`${tradKey}.cards.titles.private_enjoyment`)}
                          </Title>
                        }
                      >
                        <PrivateEnjoymentModal isCounsil />
                      </Card>
                    </Col>
                  )}
                </Row>
                {mission?.type &&
                  !(current < 6 && mission.type === 'PCS_1') && (
                    <LawyerConsultationCard
                      customMission={mission}
                      fromSyndicRole
                    />
                  )}
                <Card
                  style={cardStyles}
                  title={
                    <Title level={5} className="yellow-underline">
                      {t(`${tradKey}.cards.titles.my_documents`)}
                    </Title>
                  }
                >
                  <DocumentsCollapse
                    badges={actionsToDo.total}
                    actionsToDo={actionsToDo}
                  />
                </Card>
              </Flex>
            </Col>
            {!isCounsil && (
              <Col span={10}>
                <MessageContextProvider id={id} model="Mission">
                  <Messages
                    missionType="PCS_1"
                    syndic={mission?.syndic}
                    collective_ownership={mission?.collective_ownership}
                    withCreateButton
                    titleClassName="yellow-underline bold-16"
                  />
                </MessageContextProvider>
              </Col>
            )}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
