import { Route, Routes } from 'react-router-dom';
import { ServicesRouterTemplate } from '../../../routers/ServicesRouterTemplate';
import { ServicesContextProvider } from '../../../contexts/ServicesContext';
import { otherRoutesSubRoutes as subRoutes } from '../../../utils/constants/routes/syndicManagerRoutes';
import { Exception } from '../../../components';
import { ComplianceElanRouter } from './ComplianceElanRouter/ComplianceElanRouter';
import { PlansResearchRouter } from './PlansResearchRouter/PlansResearchRouter';
import { RCPResearchRouter } from './RCPResearchRouter/RCPResearchRouter';

/**
 * @component
 * @returns {JSX.Element} The rendered ServicesRouter component.
 * ServicesRouter component handles the routing for the services section of the application.
 * It provides routes for compliance with the LOI ELAN and plans research.
 */
export const ServicesRouter = () => (
  <ServicesContextProvider>
    <Routes>
      <Route element={<ServicesRouterTemplate />}>
        <Route
          path={`${subRoutes.SERVICES.COMPLIANCE_LOI_ELAN}/*`}
          element={<ComplianceElanRouter />}
        />
        <Route
          path={`${subRoutes.SERVICES.PLANS_RESEARCH}/*`}
          element={<PlansResearchRouter />}
        />
        <Route
          path={`${subRoutes.SERVICES.RCP_RESEARCH}/*`}
          element={<RCPResearchRouter />}
        />
      </Route>
      <Route path="*" element={<Exception />} />
    </Routes>
  </ServicesContextProvider>
);
