import React, { useEffect, useRef } from 'react';
import { add, format } from 'date-fns';
import { Table } from 'antd';
import TodoItem from '../../../routes/customer-service-manager-view/event/SubComponents/TodoItem.jsx';

import {
  GenericEvent,
  CalendarBodyProps,
  EventsObject,
  ColumnNode
} from './types.tsx';
import { useTranslation } from 'react-i18next';
import { EventBlock } from './EventBlock.tsx';
import { getDayHoursEvents } from './utils/getDayHoursEvents.tsx';
import moment from 'moment';

const ALL_DAY_ROW = 0;

export const Calendar = <T extends GenericEvent>({
  weekDates,
  getDayEvents,
  onEventClick,
  dayRange,
  hourStart,
  subMode,
  hourEnd,
  noAllDayRow
}: CalendarBodyProps<T>) => {
  const { t } = useTranslation();
  const rowRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (rowRef.current) {
      rowRef.current?.scrollIntoView();
    }
  }, [rowRef]);

  const dayList = (dayRange: string | undefined) => {
    switch (dayRange) {
      case 'withSaturday':
        return [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ];
      case 'weekends':
        return [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ];
      default:
        return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    }
  };

  const dayColumns = dayList(dayRange).map((day, counter) => {
    const columnDate = add(new Date(weekDates.startDate), {
      days: 1 + counter
    });
    const isToday =
      format(columnDate, 'dd-MM-yy') === format(new Date(), 'dd-MM-yy');

    const formattedDayandMonth =
      t(`calendar.days.${format(columnDate, 'iii')}`) +
      ' ' +
      format(columnDate, 'dd');

    return {
      title: (
        <div className={isToday ? 'today-header' : ''}>
          {formattedDayandMonth}
        </div>
      ),
      dataIndex: day,
      key: day,
      width: 2,
      render: function (
        events: ColumnNode<T>,
        row: EventsObject<T>
      ): React.ReactNode | undefined {
        let cellStyle: React.CSSProperties = {
          backgroundColor: isToday ? 'var(--componentBackground)' : 'white'
        };

        if (events && events.length > 0 && events instanceof Array) {
          const eventsBlock = events.map(function (
            event,
            index: number
          ): React.ReactNode {
            if (
              subMode === 'custom' &&
              index === events.length - 1 &&
              event.allDay
            ) {
              return (
                <div style={{ height: '180px' }} key={event.eventId}>
                  <TodoItem item={event} />
                </div>
              );
            }
            if (format(event.startTime, 'P') === format(columnDate, 'P')) {
              return (
                  <EventBlock
                    key={event.eventId}
                    event={event}
                    index={index}
                    hour={row.hourObject}
                    events={events.length}
                    onEventClick={onEventClick}
                  />
              );
            }
          });

          return {
            props: {
              style: { position: 'relative', padding: '0', ...cellStyle }
            },
            children: eventsBlock || <div style={{ minHeight: '20px' }} />
          };
        }
        return {
          props: {
            style: cellStyle
          }
        };
      }
    };
  });
  const hourColumn = {
    title: t('calendar.hours.title'),
    dataIndex: 'hour',
    key: 'hour',
    width: 1,
    render: (hour: ColumnNode<T>, {}, id: number) => {
      return {
        props: {
          style: { width: '100%' }
        },
        children: <div>{hour}</div>
      };
    }
  };
  const tableColumns = [hourColumn, ...dayColumns];

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        dataSource={getDayHoursEvents(
          weekDates,
          getDayEvents,
          hourStart,
          hourEnd,
          noAllDayRow
        )}
        columns={tableColumns}
        pagination={false}
        bordered={true}
        showHeader={true}
        onRow={(_, rowIndex) => {
          return {};
        }}
        scroll={{
          y: 1000
        }}
      />
    </div>
  );
};

export default Calendar;
