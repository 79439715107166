import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Popconfirm, Row, Divider, Button } from 'antd';
import {
  CheckCircleOutlined,
  WarningOutlined,
  EyeOutlined,
  CloseCircleOutlined,
  MailOutlined
} from '@ant-design/icons';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { Collection } from '../../../models/Collection';
import { useAuthContext } from '../../../contexts/AuthContext';
import { sendMail } from './utils/sendMail';

/**
 * Returns an array of columns configuration for the cerfa documents table.
 * @hook
 * @param {Object} options - The options object.
 * @param {function} options.setEditingKey - The function to set the editing key.
 * @param {Object} options.form - The form object.
 * @param {function} options.setSelectType - The function to set the select type.
 * @param {function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The force refresh flag.
 * @param {string} options.collection_status - The status of the collection.
 * @returns {Array} - An array of column configuration objects.
 */
export const useColumns = ({
  setEditingKey,
  form,
  setSelectType,
  setForceRefresh,
  forceRefresh,
  collection_status
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { editCell } = EditableTableMethods();
  const validateAndDeletePermission = ![
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER'
  ].includes(user.role);

  return [
    {
      title: t('collections.form.validation_date'),
      key: 'validation_date',
      dataIndex: 'validation_date',
      render: (validation_date) =>
        validation_date ? dayjs(validation_date).format('DD-MM-YY') : '-',
      sorter: (a, b) =>
        dayjs(a.validation_date).unix() - dayjs(b.validation_date).unix()
    },
    {
      title: t('collections.form.syndic_code'),
      key: 'code',
      dataIndex: 'syndic',
      render: (syndic) => syndic?.code || '-',
      sorter: (a, b) => a.syndic.code - b.syndic.code
    },
    {
      title: t('collections.form.syndic_name'),
      key: 'syndic_name',
      dataIndex: 'syndic',
      render: (record) =>
        `${record?.society_name || ''} ${
          record?.code ? `(${record.code})` : ''
        }`,
      sorter: (a, b) =>
        a.syndic.society_name.localeCompare(b.syndic.society_name)
    },
    {
      title: t('collections.form.email'),
      key: 'email',
      dataIndex: 'syndic',
      render: (syndic) => syndic?.contact?.email || '-'
    },
    {
      title: t('collections.form.code_collective_ownership'),
      key: 'code_collective_ownership',
      dataIndex: 'code'
    },
    {
      title: t('collections.form.address'),
      key: 'address',
      dataIndex: 'first_address',
      render: (first_address) =>
        `${first_address?.number || ''} ${first_address?.street || ''} ${
          first_address?.postal_code || ''
        } ${first_address?.city || ''}`
    },
    {
      title: t('collections.form.collection_date'),
      key: 'collection_date',
      dataIndex: 'collection_date',
      editable: true,
      inputType: 'date-picker',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'collection_date', setEditingKey, form })
          }
        >
          {record?.collection_date
            ? dayjs(record?.collection_date).format('DD-MM-YY')
            : '-'}
        </Row>
      ),
      sorter: (a, b) =>
        dayjs(a.collection_date).unix() - dayjs(b.collection_date).unix()
    },
    {
      title: t('collections.form.mod'),
      key: 'mod',
      dataIndex: 'mod',
      editable: true,
      inputType: 'select',
      render: (_, record) => (
        <Row
          onClick={() => {
            setSelectType('mods');
            editCell({
              record,
              key: 'mod',
              setEditingKey,
              form
            });
          }}
        >
          {record?.mod ? t(`collections.tags.${record?.mod}`) : '-'}
        </Row>
      ),
      sorter: (a, b) => {
        const modA = a.mod ? t(`collections.tags.${a.mod}`) : '';
        const modB = b.mod ? t(`collections.tags.${b.mod}`) : '';

        return modA.localeCompare(modB);
      }
    },
    {
      title: t('collections.form.is_docs'),
      key: 'is_docs',
      dataIndex: 'is_docs',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'is_docs', setEditingKey, form })
          }
        >
          {record?.is_docs || '-'}
        </Row>
      )
    },
    {
      title: t('collections.form.grids'),
      key: 'grids',
      dataIndex: 'grids',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'grids', setEditingKey, form })
          }
        >
          {record?.grids || '-'}
        </Row>
      )
    },
    {
      title: t('collections.form.mail_reminder_date'),
      key: 'mail_reminder_date',
      dataIndex: 'mail_reminder_date',
      render: (_, record) =>
        record?.mail_reminder_date
          ? dayjs(record?.mail_reminder_date).format('DD-MM-YY')
          : '-',
      sorter: (a, b) =>
        dayjs(a.mail_reminder_date).unix() - dayjs(b.mail_reminder_date).unix()
    },
    {
      title: t('collections.form.phone_reminder_date'),
      key: 'phone_reminder_date',
      dataIndex: 'phone_reminder_date',
      editable: true,
      inputType: 'date-picker',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({
              record,
              key: 'phone_reminder_date',
              setEditingKey,
              form
            })
          }
        >
          {record?.phone_reminder_date
            ? dayjs(record?.phone_reminder_date).format('DD-MM-YY')
            : '-'}
        </Row>
      ),
      sorter: (a, b) =>
        dayjs(a.phone_reminder_date).unix() -
        dayjs(b.phone_reminder_date).unix()
    },
    {
      title: t('collections.form.collection_info'),
      key: 'collection_info',
      dataIndex: 'collection_info',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'collection_info', setEditingKey, form })
          }
        >
          {record?.collection_info || '-'}
        </Row>
      )
    },
    {
      title: t('collections.form.collection_sending_date'),
      key: 'collection_sending_date',
      dataIndex: 'collection_sending_date',
      render: (_, record) =>
        record?.collection_sending_date
          ? dayjs(record?.collection_sending_date).format('DD-MM-YY')
          : '-',
      sorter: (a, b) =>
        dayjs(a.collection_sending_date).unix() -
        dayjs(b.collection_sending_date).unix()
    },
    {
      key: 'action',
      fixed: 'right',
      width: 60,
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/collective-ownerships/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {collection_status === 'IN_PROGRESS' &&
          validateAndDeletePermission ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('popconfirm.titles.collection_validated')}
                okText={t('popconfirm.onOk.collection_validated')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('popconfirm.onCancel.collection_validated')}
                onConfirm={() =>
                  Collection.updateStatus({
                    values: [{ _id: record._id }],
                    extraQuery: 'status=VALIDATED',
                    setForceRefresh,
                    forceRefresh,
                    user
                  })
                }
                icon={<WarningOutlined />}
              >
                <CheckCircleOutlined style={{ color: 'green', fontSize: 16 }} />
              </Popconfirm>
              <Divider type="vertical" />
              <Popconfirm
                title={t('popconfirm.titles.collection_deleted')}
                okText={t('popconfirm.onOk.collection_deleted')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('popconfirm.onCancel.collection_deleted')}
                onConfirm={() =>
                  Collection.updateStatus({
                    values: [{ _id: record._id }],
                    extraQuery: 'status=DELETED',
                    setForceRefresh,
                    forceRefresh
                  })
                }
                icon={<WarningOutlined />}
              >
                <CloseCircleOutlined style={{ color: 'red', fontSize: 16 }} />
              </Popconfirm>
              <Button
                type="link"
                onClick={() => {
                  sendMail(record._id);
                }}
              >
                <MailOutlined />
              </Button>
            </>
          ) : null}
        </>
      )
    }
  ];
};
