import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { CollectiveOwnership } from '../../../../../models/CollectiveOwnership';
import { useCollectiveOwnershipContext } from '../../../../../contexts/CollectiveOwnershipContext';

/**
 * RemoveSelectionButton component.
 *
 * @component
 * @returns {JSX.Element} The RemoveSelectionButton component.
 */
export const RemoveSelectionButton = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { forceRefresh, setForceRefresh, selectedDocuments } =
    useCollectiveOwnershipContext();

  return (
    <Col span={24}>
      <Row justify="end">
        <Button
          type="link"
          onClick={() =>
            CollectiveOwnership.deleteManyDocument({
              id,
              values: selectedDocuments,
              setForceRefresh,
              forceRefresh
            })
          }
          danger
        >
          {t('missions.buttons.remove_selection')}
        </Button>
      </Row>
    </Col>
  );
};
