import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Row, Flex } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../../../components/ExportButton/ExportButton';
import { ImportButton } from '../../../components/importButton';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { AddIcon } from '../../../utils/constants/customIcons';
import { SearchResources } from '../../../components/SearchResources/SearchResources';
import { CustomDataTable } from './CustomDataTable';

/**
 * Custom list resource component for managing a list of resources.
 * @component
 * @param {object} props - Component props.
 * @param {string} props.resourceName - Name of the resource.
 * @param {string} [props.tradKey] - Translation key for the title.
 * @param {string} [props.dataToFetch] - Data to fetch for the resource.
 * @param {object[]} props.columns - Array of column definitions for the table.
 * @param {boolean} [props.customActionColumn=false] - Indicates whether custom action column is enabled.
 * @param {object[]} [props.headers] - Headers for export.
 * @param {string} [props.extraQuery] - Additional query parameters for data fetch.
 * @param {JSX.Element} [props.extraHeader] - Additional JSX element for the header.
 * @param {JSX.Element} [props.extraButtons] - Additional JSX element for extra buttons.
 * @param {string} [props.exportUrl] - URL for exporting data.
 * @param {string} [props.populate] - Populates fields for data fetch.
 * @param {boolean} [props.withCreateButton=true] - Indicates whether create button is enabled.
 * @param {boolean} [props.withUploadButton=true] - Indicates whether upload button is enabled.
 * @param {boolean} [props.withPageHeader=true] - Indicates whether page header is enabled.
 * @param {boolean} [props.withSearchBar=true] - Indicates whether search bar is enabled.
 * @param {boolean} [props.forceRefresh] - Indicates whether to force refresh the data.
 * @param {string} [props.resourceModelName] - Name of the resource model.
 * @param {(boolean|object)} [props.editAction=true] - Indicates whether edit action is enabled or object containing configuration.
 * @param {(boolean|object)} [props.showAction=true] - Indicates whether show action is enabled or object containing configuration.
 * @param {(boolean|object)} [props.duplicateAction=false] - Indicates whether duplicate action is enabled or object containing configuration.
 * @param {(boolean|object)} [props.printAction=false] - Indicates whether print action is enabled or object containing configuration.
 * @param {boolean} [props.deleteAction=true] - Indicates whether delete action is enabled.
 * @param {(boolean|object)} [props.onDoubleClickAction=true] - Indicates whether double click action is enabled or object containing configuration.
 * @param {object} [props.scroll] - Scroll configuration for the table.
 * @param {object} [props.expandable] - Expandable configuration for the table.
 * @param {string} [props.path] - Path for navigation.
 * @param {string} [props.rowKey='_id'] - Key for identifying rows.
 * @param {function} [props.formatter] - Function for formatting data.
 * @param {string} [props.customParams] - Custom parameters for navigation.
 * @param {function} [props.setResourceData] - Function for setting resource data.
 * @param {object} [props.rowSelection] - Row selection configuration for the table.
 * @param {JSX.Element} [props.searchBarExtraButtons] - Additional JSX element for search bar buttons.
 * @param {JSX.Element} [props.upperSearchKeyword] - Additional JSX element for upper search keyword.
 * @returns {JSX.Element} Custom list resource component.
 */
export const CustomListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  withPageHeader,
  withSearchBar,
  forceRefresh,
  resourceModelName,
  editAction,
  showAction,
  duplicateAction,
  printAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  formatter,
  customParams,
  setResourceData,
  rowSelection,
  searchBarExtraButtons,
  upperSearchKeyword
}) => {
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) {
      setSearchValue(keyword);
    } else {
      setSearchValue(null);
    }
  }, [keyword]);

  const menu = {
    items: [
      ...(headers
        ? [
            {
              key: 'export',
              label: (
                <ExportButton
                  dataName={resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                />
              )
            }
          ]
        : []),
      {
        key: 'import',
        label: <ImportButton resourceName={resourceModelName} />
      }
    ]
  };

  return (
    <>
      {withPageHeader && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
        />
      )}
      <ContentCustom>
        <Row justify={withSearchBar ? 'space-between' : 'end'} gutter={[8, 16]}>
          {withSearchBar && (
            <Flex vertical>
              <Flex>{upperSearchKeyword}</Flex>
              <Row gutter={[16, 16]}>
                <Col>
                  <SearchResources searchValue={searchValue} />
                </Col>
                {searchBarExtraButtons && <Col>{searchBarExtraButtons}</Col>}
                {!withCreateButton && headers && (
                  <Flex>
                    <Col>
                      <Dropdown menu={menu}>
                        <Button type="link">
                          <MenuOutlined
                            style={{ fontSize: 16, color: 'var(--textColor)' }}
                          />
                        </Button>
                      </Dropdown>
                    </Col>
                  </Flex>
                )}
              </Row>
            </Flex>
          )}

          {withCreateButton && (
            <Col>
              <Row align="middle">
                {extraButtons}
                <Link
                  to={
                    withCreateButton?.path ||
                    `${pathname}/create${customParams || ''}`
                  }
                >
                  <Button type="add">
                    {withCreateButton?.buttonText || `${t('buttons.create')}`}
                    &nbsp;
                    {withCreateButton?.buttonIcon || <AddIcon />}
                  </Button>
                </Link>
                {withUploadButton && (
                  <Dropdown menu={menu}>
                    <Button type="link">
                      <MenuOutlined
                        style={{ fontSize: 16, color: 'var(--textColor)' }}
                      />
                    </Button>
                  </Dropdown>
                )}
              </Row>
            </Col>
          )}
          {!withCreateButton && <Col>{extraButtons}</Col>}
        </Row>
        <Row gutter={[8, 16]}>{children}</Row>
        <CustomDataTable
          style={{ marginTop: 16 }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          duplicateAction={duplicateAction}
          printAction={printAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1000 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          setResourceData={setResourceData}
          rowSelection={rowSelection}
        />
      </ContentCustom>
    </>
  );
};

CustomListResource.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  customParams: PropTypes.string,
  setResourceData: PropTypes.func,
  rowSelection: PropTypes.shape({}),
  searchBarExtraButtons: PropTypes.shape({}),
  upperSearchKeyword: PropTypes.shape({})
};

CustomListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withSearchBar: true,
  withPageHeader: true,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  formatter: undefined,
  customParams: null,
  setResourceData: null,
  rowSelection: null,
  searchBarExtraButtons: false,
  upperSearchKeyword: null
};
