import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  syndicViewSubtitle,
  syndicViewTitle
} from '../../utils/styleConstants';

/**
 * @hook
 * Generates the title and subtitle for the factures section.
 * @param {string} mission - The mission parameter.
 * @returns {Object} An object containing the title and subtitle components.
 */
export const dashboardTitle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = (mission) => (
    <Row>
      <h1 style={syndicViewTitle}>
        {t('syndic-view.dashboard.title')} - {t(`missions.tags.${mission}`)}
      </h1>
    </Row>
  );

  const collectiveOwnershipTitle = (mission) => (
    <Row>
      <h1 style={syndicViewTitle}>
        {t('syndic-view.collective-ownerships.title')} -{' '}
        {t(`missions.tags.${mission}`)}
      </h1>
    </Row>
  );
  const actionsToDoTitle = (mission) => (
    <Row>
      <h1 style={syndicViewTitle}>
        {t('syndic-view.actions-to-do.title')} - {t(`missions.tags.${mission}`)}
      </h1>
    </Row>
  );

  const myDraftsTitle = (
    <Row>
      <h1 style={syndicViewTitle}>{t('syndic-view.drafts.title')} </h1>
    </Row>
  );

  const myDraftsSubtitle = (
    <p style={syndicViewSubtitle}>
      {t('syndic-view.drafts.subtitle1')}
      <br />
      {t('syndic-view.drafts.subtitle2')}
    </p>
  );

  const myPedndingValidationsTitle = (
    <Row>
      <h1 style={syndicViewTitle}>
        {t('syndic-view.pending_validation.title')}{' '}
      </h1>
    </Row>
  );

  const myPedndingValidationsSubtitle = (
    <p style={syndicViewSubtitle}>
      {t('syndic-view.pending_validation.subtitle1')}
      <br />
      {t('syndic-view.pending_validation.subtitle2')}
    </p>
  );

  const invoiceTitle = (mission) => (
    <Row>
      <h1 style={syndicViewTitle}>
        {t('syndic-factures.titlteCard.title')} -{' '}
        {t(`missions.tags.${mission}`)}
      </h1>
    </Row>
  );

  const invoiceSubtitle = (
    <p style={syndicViewSubtitle}>{t('syndic-factures.titlteCard.subtitle')}</p>
  );

  const auditTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.audit.title')}
      </h2>
    </Row>
  );

  const auditSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>
      {t('syndic-view.audit.subtitle')} <br />
      {t('syndic-view.audit.subtitle2')}
    </p>
  );
  const publicationTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.publication.title')}
      </h2>
    </Row>
  );

  const publicationSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>
      {t('syndic-view.publication.subtitle')}
    </p>
  );

  const complianceTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.compliance.title')}
      </h2>
    </Row>
  );

  const complianceSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>
      {t('syndic-view.compliance.subtitle')} <br />
      {t('syndic-view.compliance.subtitle2')}
    </p>
  );
  const clientWalletTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.client-wallet.title')}
      </h2>
    </Row>
  );

  const clientWalletSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>
      {t('syndic-view.client-wallet.subtitle')}
    </p>
  );

  const newMissionTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.new-mission.title')}
      </h2>
    </Row>
  );

  const newMissionSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>
      {t('syndic-view.new-mission.subtitle')}
    </p>
  );

  const manageWalletTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.manage-wallet.title')}
      </h2>
    </Row>
  );

  const manageWalletSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>
      {t('syndic-view.manage-wallet.subtitle')}
    </p>
  );

  const manageCollectiveOwnershipTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.delete-collective-ownerships.title')}
      </h2>
    </Row>
  );
  const manageCollectiveOwnershipSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>
      {t('syndic-view.delete-collective-ownerships.subtitle')}
    </p>
  );

  const manageTeamTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.manage-team.title')}
      </h2>
    </Row>
  );
  const manageTeamSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>
      {t('syndic-view.manage-team.subtitle')}
    </p>
  );

  const devisTitle = (
    <Row>
      <h2 style={{ ...syndicViewTitle, fontSize: '36px' }}>
        {t('syndic-view.devis.title')}
      </h2>
    </Row>
  );

  const devisSubtitle = (
    <p style={{ ...syndicViewSubtitle }}>{t('syndic-view.devis.subtitle')}</p>
  );

  const servicesCardStyle = { backgroundColor: '#222222', width: '97.5%' };

  const button = (
    <Button
      type="primary"
      onClick={() => {
        navigate('/client-wallet');
      }}
      style={{
        backgroundColor: '#ffd53f',
        borderRadius: '15px',
        color: '#222222',
        margin: '0px 10px'
      }}
    >
      <EditOutlined />
      {t('syndic-collective-ownerships.titlteCard.buttonText')}
    </Button>
  );

  return {
    title,
    button,
    collectiveOwnershipTitle,
    actionsToDoTitle,
    invoiceTitle,
    invoiceSubtitle,
    auditTitle,
    auditSubtitle,
    myDraftsTitle,
    myDraftsSubtitle,
    publicationTitle,
    publicationSubtitle,
    complianceTitle,
    complianceSubtitle,
    myPedndingValidationsTitle,
    myPedndingValidationsSubtitle,
    devisTitle,
    devisSubtitle,
    clientWalletTitle,
    clientWalletSubtitle,
    newMissionTitle,
    newMissionSubtitle,
    manageWalletTitle,
    manageWalletSubtitle,
    manageTeamTitle,
    manageTeamSubtitle,
    servicesCardStyle,
    manageCollectiveOwnershipTitle,
    manageCollectiveOwnershipSubtitle
  };
};
