import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useColumns } from './columns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { addColumns } from '../../../components/editableTableComponents/addColumns';
import { EditableTable } from '../../../components/editableTableComponents/EditableTable';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';

/**
 * Renders a list of requested documents.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of requested documents.
 * @returns {JSX.Element} The rendered component.
 */
export const ListRequestedDocuments = ({ type }) => {
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectOptions, setSelectOptions] = useState({ document_types: [] });
  const [selectType, setSelectType] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const { searchValue, setPagination, pagination, currentSorter } =
    useConfigurationContext();
  const { getResourceData } = EditableTableMethods();
  const columns = useColumns({
    setEditingKey,
    form,
    setSelectType,
    setForceRefresh,
    forceRefresh
  });
  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  useEffect(() => {
    (async () => {
      const [requestedDocumentsDatas, documentRepositoriesDatas] =
        await Promise.all([
          getResourceData({
            resource: 'requested-documents',
            query: `&populate=document_type&type=${type}`,
            searchValue,
            setPagination,
            pagination,
            currentSorter
          }),
          getResourceData({ resource: 'document-repositories' })
        ]);

      setDataSource(
        requestedDocumentsDatas.map((el, index) => ({ ...el, key: index }))
      );
      setSelectOptions({
        document_types: documentRepositoriesDatas
      });
    })();
  }, [forceRefresh, searchValue, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: mergedColumns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      selectOptions,
      selectType,
      resource: 'requested-documents'
    });
  }, [editingKey, dataSource]);

  return (
    <EditableTable
      resource="requested-documents"
      dataSource={dataSource}
      mergedColumns={mergedColumns}
      setSelectedRowKey={setSelectedRowKey}
      setForceRefresh={setForceRefresh}
      forceRefresh={forceRefresh}
      form={form}
      type={type}
      searchValue={searchValue}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};

ListRequestedDocuments.propTypes = {
  type: PropTypes.string.isRequired
};
