import { Avatar, Card, Col, Divider, List, Row, Typography } from 'antd';
import { ExclamationCircleFilled, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMessageContext } from '../../../contexts/MessageContext';
import { ContentCustom } from '../../../components';

const { Text } = Typography;

/**
 * Renders a list of messages.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ListMessages = () => {
  const { t } = useTranslation();
  const { messages } = useMessageContext();

  return (
    <ContentCustom>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Divider>{t('lawyers.home.pcs_1')}</Divider>
          <List
            itemLayout="horizontal"
            dataSource={messages
              .filter((message) => message.mission === 'PCS_1')
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))}
            split={false}
            renderItem={(item) => (
              <List.Item>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '5px'
                    // borderRight: `3px solid orange`,
                    // borderBottom: `3px solid orange`
                  }}
                  title={
                    <>
                      <Text strong>{item?.syndic?.society_name || '-'}</Text>
                      <Divider type="vertical" />
                      <Text>
                        {item.collective_ownership
                          ? `${item?.collective_ownership?.name}, ${item?.collective_ownership?.first_address?.street} ${item?.collective_ownership?.first_address?.postal_code} ${item?.collective_ownership?.first_city?.name}`
                          : '-'}
                      </Text>
                    </>
                  }
                  extra={
                    <ExclamationCircleFilled
                      style={{ color: 'orange' }}
                      size={24}
                    />
                  }
                >
                  <Row gutter={[16, 16]} align="middle">
                    <Col span={2}>
                      <Avatar
                        size="medium"
                        icon={item.author?.photo ? '' : <UserOutlined />}
                        src={item.author?.photo ? item.author.photo : ''}
                      >
                        {`${item.author?.first_name[0]}${item.author?.last_name[0]}`}
                      </Avatar>
                    </Col>
                    <Col span={5}>
                      <Text>
                        {item?.author
                          ? `${item.author?.first_name || ''} ${
                              item.author?.last_name || ''
                            }`
                          : '-'}
                      </Text>
                    </Col>
                    <Col span={8}>
                      <Text>{item.subject ? item.subject : '-'}</Text>
                    </Col>
                    <Col span={7}>
                      <Text>
                        {item.created_at
                          ? dayjs(item.created_at).format(
                              'Le DD-MM-YYYY à HH[h]mm'
                            )
                          : '-'}
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Col>
        <Col span={12}>
          <Divider>{t('lawyers.home.pcs_2')}</Divider>
          <List
            itemLayout="horizontal"
            dataSource={messages.filter(
              (message) => message.mission === 'PCS_2'
            )}
            renderItem={(item) => (
              <List.Item>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '5px'
                    // borderRight: `3px solid orange`,
                    // borderBottom: `3px solid orange`
                  }}
                  title={
                    <>
                      <Text strong>{item?.syndic?.society_name || '-'}</Text>
                      <Divider type="vertical" />
                      <Text>
                        {item.collective_ownership
                          ? `${item?.collective_ownership?.name}, ${item?.collective_ownership?.first_address?.street} ${item?.collective_ownership?.first_address?.postal_code} ${item?.collective_ownership?.first_city?.name}`
                          : '-'}
                      </Text>
                    </>
                  }
                  extra={
                    <ExclamationCircleFilled
                      style={{ color: 'orange' }}
                      size={24}
                    />
                  }
                >
                  <Row gutter={[16, 16]} align="middle">
                    <Col span={2}>
                      <Avatar
                        size="medium"
                        icon={item.author?.photo ? '' : <UserOutlined />}
                        src={item.author?.photo ? item.author.photo : ''}
                      >
                        {`${item.author?.first_name[0]}${item.author?.last_name[0]}`}
                      </Avatar>
                    </Col>
                    <Col span={5}>
                      <Text>
                        {item?.author
                          ? `${item.author?.first_name || ''} ${
                              item.author?.last_name || ''
                            }`
                          : '-'}
                      </Text>
                    </Col>
                    <Col span={8}>
                      <Text>{item.subject ? item.subject : '-'}</Text>
                    </Col>
                    <Col span={7}>
                      <Text>
                        {item.created_at
                          ? dayjs(item.created_at).format(
                              'Le DD-MM-YYYY à HH[h]mm'
                            )
                          : '-'}
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </ContentCustom>
  );
};
