import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { MissionKanbanColumn } from './MissionKanbanColumn';
import { Mission } from '../../../../models/Mission';
import { MissionKanbanExtraButtons } from './MissionKanbanExtraButtons';
import { ContentCustom } from '../../../../components';

/**
 * @component
 * MissionKanbanView component.
 * Renders a kanban view for missions.
 *
 * @returns {JSX.Element} The rendered MissionKanbanView component.
 */
export const MissionKanbanView = () => {
  const [syndic, setSyndic] = useState('');
  const [searchValue, setSearchValue] = useState(null);
  const [missionType, setMissionType] = useState('PCS_1');
  const { stepsObject } = Mission;
  const [missionSteps, setMissionSteps] = useState([]);

  useEffect(() => {
    setMissionSteps(
      stepsObject[missionType].sub || stepsObject[missionType].principal
    );
  }, [missionType]);

  return (
    <ContentCustom>
      <DndProvider backend={HTML5Backend}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <MissionKanbanExtraButtons
              setMissionType={setMissionType}
              setSyndic={setSyndic}
              setSearchValue={setSearchValue}
            />
          </Col>
          <Col span={24}>
            <Row className="kanban-mission-view" wrap={false}>
              {missionSteps.map((step) => (
                <MissionKanbanColumn
                  step={step}
                  searchValue={searchValue}
                  missionType={missionType}
                  missionSteps={missionSteps}
                  selectedSyndic={syndic}
                  isPCS={['PCS_1', 'PCS_2', 'PCS_3'].includes(missionType)}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </DndProvider>
    </ContentCustom>
  );
};
