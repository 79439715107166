import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useLocation } from 'react-router-dom';
import { WeeklyCalendar } from './WeeklyCalendar/WeeklyCalendar.tsx';
import { useAuthContext } from '../../contexts/AuthContext';
import { MonthAnnualCalendar } from './MonthAnnualCalendar/MonthAnnualCalendar.tsx';
import { Event } from '../../models/Event';

export const Calendar = ({
  modes,
  subMode,
  resourceName,
  extraQuery,
  weekCellRender,
  monthCellRender,
  view,
  annualCellRender,
  extraHeader,
  dayRange,
  customRoute,
  populate,
  forceRefresh,
  hourStart,
  hourEnd,
  ISOWeekName,
  noAllDayRow,
  initialMode,
  setExternalEvents
}) => {
  const { user } = useAuthContext();
  const [events, setEvents] = useState([]);
  const [mode, setMode] = useState(initialMode);
  const { pathname } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  useEffect(() => {
    if (setExternalEvents) setExternalEvents(setEvents);
  }, [events]);

  useEffect(() => {
    (async () => {
      await Event.getResources({
        searchValue,
        setEvents,
        resourceName,
        customRoute,
        extraQuery,
        populate,
        user
      });
    })();
  }, [forceRefresh, searchValue]);

  const displayMode = () => {
    switch (mode) {
      case 'week':
        return (
          <WeeklyCalendar
            modes={modes}
            mode="week"
            setMode={setMode}
            events={events}
            ISOWeekName={ISOWeekName}
            view={view}
            weekCellRender={weekCellRender}
            dayRange={dayRange}
            extraHeader={extraHeader}
            hourStart={hourStart}
            hourEnd={hourEnd}
            subMode={subMode}
            noAllDayRow={noAllDayRow}
          />
        );
      case 'year':
        return (
          <MonthAnnualCalendar
            mode="year"
            cellRender={annualCellRender}
            extraHeader={extraHeader}
            modes={modes}
            setMode={setMode}
            events={events}
            dayRange={dayRange}
          />
        );
      default:
        return (
          <MonthAnnualCalendar
            mode="month"
            cellRender={monthCellRender}
            extraHeader={extraHeader}
            modes={modes}
            setMode={setMode}
            events={events}
            dayRange={dayRange}
          />
        );
    }
  };

  return (
    <Card className="calendar-wrapper" bordered={false}>
      {displayMode()}
    </Card>
  );
};

Calendar.propTypes = {
  modes: PropTypes.arrayOf(PropTypes.string).isRequired,
  subMode: PropTypes.string,
  resourceName: PropTypes.string.isRequired,
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.func,
  view: PropTypes.string,
  monthCellRender: PropTypes.func,
  weekCellRender: PropTypes.func,
  annualCellRender: PropTypes.func,
  dayRange: PropTypes.string,
  customRoute: PropTypes.string,
  populate: PropTypes.string,
  forceRefresh: PropTypes.bool,
  hourStart: PropTypes.number,
  hourEnd: PropTypes.number,
  noAllDayRow: PropTypes.bool,
  initialMode: PropTypes.string.isRequired,
  ISOWeekName: PropTypes.bool,
  setExternalEvents: PropTypes.func
};

Calendar.defaultProps = {
  extraQuery: undefined,
  extraHeader: null,
  subMode: 'default',
  view: undefined,
  monthCellRender: null,
  weekCellRender: null,
  annualCellRender: null,
  dayRange: undefined,
  customRoute: undefined,
  populate: undefined,
  forceRefresh: undefined,
  hourStart: undefined,
  hourEnd: undefined,
  noAllDayRow: undefined,
  ISOWeekName: true,
  setExternalEvents: null
};
