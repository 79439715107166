import { updateHistory } from './updateHistory';

export const deleteResource = async ({
  dispatchAPI,
  resourceName,
  id,
  setForceRefresh,
  forceRefresh,
  message,
  navigate,
  user
}) => {
  try {
    await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });

    if (user) {
      updateHistory({
        dispatchAPI,
        id,
        resourceName,
        values: {
          date: new Date(),
          type: 'mission_delete',
          by: user._id
        },
        setForceRefresh,
        forceRefresh
      });
    }
    if (navigate) {
      navigate(-1);
    } else {
      setForceRefresh((prev) => !prev);
    }
  } catch (e) {
    message(e);
  }
};
