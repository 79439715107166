import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const formatDate = (date) => {
  if (date === 'Total') return date;
  if (date) return dayjs(date).format('DD/MM/YYYY');
  return '-';
};

/**
 * Returns a formatter object with a formattedData function.
 * The formattedData function takes in data and returns a new object with formatted properties.
 *
 * @returns {Object} The formatter object.
 * @property {Function} formattedData - The function that formats the data.
 * @param {Object} data - The data to be formatted.
 * @returns {Object} The formatted data object.
 */
export const useFormatter = () => {
  const { t } = useTranslation();

  const formattedData = (data) => ({
    ...data,
    date: data.date ? dayjs(data.date).format('DD/MM/YYYY') : '-',
    bank_journal: data.bank_journal || 'BN2',
    wording: data?.wording || data?.conservation || '-',
    piece: data?.piece || data?.cheques_number || '-',
    debit: data?.debit || data?.amount || '',
    credit: data?.credit || data?.to_bill || '',
    sending_date: formatDate(data.sending_date),
    mission_type: data.mission_type
      ? t(`missions.tags.${data.mission_type}`)
      : '-',
    invoice_type: data.invoice_type
      ? t(`invoices.tags.${data.invoice_type}`)
      : '-',
    payment: data.payment ? t(`invoices.tags.${data.payment}`) : '-',
    payment_date: formatDate(data.payment_date)
  });

  return { formattedData };
};
