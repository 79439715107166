import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * MergeDuplicatesMethods is a utility function that provides methods for handling form submission and removing duplicates.
 * @returns {Object} - An object containing the handleSubmit and removeDuplicate methods.
 */
export const MergeDuplicatesMethods = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Handles form submission by validating form fields, dispatching API requests, and navigating to a new page.
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {Object} options.form - The form object.
   * @param {Function} options.navigate - The navigate function for navigating to a new page.
   * @param {Function} options.setForceRefresh - The setForceRefresh function for setting the force refresh state.
   * @param {boolean} options.forceRefresh - The force refresh state.
   * @param {string} options.resource - The resource type.
   * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
   */

  const handleSubmit = async ({
    form,
    navigate,
    setForceRefresh,
    forceRefresh,
    resource
  }) => {
    const values = await form.validateFields();

    try {
      await Promise.all(
        Object.keys(values).map(async (key) => {
          const formData = new FormData();
          formData.append('values', JSON.stringify(values[key]));

          await dispatchAPI('PATCH', {
            url: `${resource}/${key}`,
            body: formData
          });
        })
      );

      setForceRefresh(!forceRefresh);
      navigate(`/configurations/${resource}`);
    } catch (error) {
      message(error);
    }
  };

  /**
   * Remove Duplicate
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {string} options.idToDelete - The ID of the departmental archive to delete.
   * @param {string} options.idToKeep - The ID of the departmental archive to keep.
   * @param {Function} options.dispatchAPI - The dispatchAPI function.
   * @param {Function} options.message - The message function.
   * @param {Function} options.navigate - The navigate function.
   * @param {Function} options.setForceRefresh - The setForceRefresh function.
   * @param {boolean} options.forceRefresh - The forceRefresh state.
   * @param {string} options.resource - The resource type.
   * @returns {Promise<void>} - A promise that resolves when the merge is complete.
   */
  const removeDuplicate = async ({
    idToDelete,
    idToKeep,
    navigate,
    setForceRefresh,
    forceRefresh,
    resource
  }) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/${resource}/${idToDelete}`
      });

      if (resource === 'spf-departmental-archives') {
        await dispatchAPI('PATCH', {
          url: `/municipalities/update_spf_and_departmental_archives/${idToKeep}/${idToDelete}`
        });
      }

      setForceRefresh(!forceRefresh);
      navigate(`/configurations/${resource}`);
    } catch (error) {
      message(error);
    }
  };

  /**
   * Returns the appropriate fields based on the configuration type.
   * @hook
   * @param {Object} options - The options object.
   * @param {string} options.resource - The configuration type.
   * @param {Array} options.spfDepartmentalArchiveFields - The SPF departmental archive fields.
   * @param {Array} options.municipalityFields - The municipality fields.
   * @returns {Array} - The appropriate fields based on the configuration type.
   */
  const useFields = ({
    resource,
    spfDepartmentalArchiveFields,
    municipalityFields
  }) => {
    switch (resource) {
      case 'spf-departmental-archives':
        return spfDepartmentalArchiveFields;
      case 'municipalities':
        return municipalityFields;
      default:
        return [];
    }
  };

  /**
   * Returns the appropriate columns based on the configuration type.
   * @hook
   * @param {Object} options - The options object.
   * @param {string} options.resource - The configuration type.
   * @param {Array} options.spfDepartmentalArchiveColumns - The columns for SPF departmental archives.
   * @param {Array} options.municipalityColumns - The columns for municipalities.
   * @returns {Array} - The appropriate columns based on the configuration type.
   */
  const useColumns = ({
    resource,
    spfDepartmentalArchiveColumns,
    municipalityColumns
  }) => {
    switch (resource) {
      case 'spf-departmental-archives':
        return spfDepartmentalArchiveColumns;
      case 'municipalities':
        return municipalityColumns;
      default:
        return [];
    }
  };

  /**
   * Retrieves the appropriate data source based on the configuration type.
   * @hook
   * @param {Object} options - The options object.
   * @param {string} options.resource - The configuration type.
   * @param {Array} options.duplicateSpfDepartmentalArchives - The duplicate SPF departmental archives.
   * @param {Array} options.duplicateMunicipalities - The duplicate municipalities.
   * @returns {Array} - The data source based on the configuration type.
   */
  const useDataSources = ({
    resource,
    duplicateSpfDepartmentalArchives,
    duplicateMunicipalities
  }) => {
    switch (resource) {
      case 'spf-departmental-archives':
        return duplicateSpfDepartmentalArchives;
      case 'municipalities':
        return duplicateMunicipalities;
      default:
        return [];
    }
  };

  return {
    handleSubmit,
    removeDuplicate,
    useFields,
    useColumns,
    useDataSources
  };
};
