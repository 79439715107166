import { Card, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import { DeliveryParcel } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../../../components';
import { useColumns } from '../columns/orderColumns';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { ListOrderedDocuments } from './ListOrderedDocuments';
import { setStepAndMissionId } from '../utils/setStepAndMissionId';

const { Text } = Typography;

/**
 * Renders the OrdersCard component.
 *
 * @component
 * @param {string} status - The status of the orders.
 * @returns {JSX.Element} The OrdersCard component.
 */
export const OrdersCard = ({ status }) => {
  const { t } = useTranslation();
  const { toBeOrderedColumns } = useColumns();
  const { forceRefresh, mission, setOrders, setIsDrawerOpen } =
    useMissionContext();

  return (
    <Card
      title={t(`missions.cards.titles.orders.${status}`)}
      styles={{ body: { padding: '8px 0' } }}
      extra={
        status === 'TO_BE_ORDERED' ? (
          <Button
            onClick={() =>
              setIsDrawerOpen((prev) => setStepAndMissionId({ prev, mission }))
            }
          >
            <DeliveryParcel />
            <Text>{t('missions.cards.documents.buttons.cerfa_order')}</Text>
          </Button>
        ) : null
      }
    >
      {status === 'TO_BE_ORDERED' && mission._id ? (
        <ListResource
          style={{ margin: '8px' }}
          scroll={{ x: 'max-content' }}
          resourceName="orders/mission"
          populate="cerfa_document_type,concerned_SPF,mission,mission.collective_ownership"
          extraQuery={`mission=${mission?._id}&status=${status}`}
          columns={toBeOrderedColumns}
          forceRefresh={forceRefresh}
          setResourceData={setOrders}
          customActionColumn
          resourceModelName="Order"
          withPageHeader={false}
          withCreateButton={false}
          withSearchBar={false}
        />
      ) : null}
      {status === 'ORDERED' ? <ListOrderedDocuments /> : null}
    </Card>
  );
};

OrdersCard.propTypes = {
  status: PropTypes.string.isRequired
};
