import dayjs from 'dayjs';

/**
 * Determines if a date should be disabled based on the given parameters.
 * @param {Object} options - The options object.
 * @param {Date} options.current - The current date to check.
 * @param {string} options.purpose - The purpose of the check ('create' or 'edit').
 * @param {Array} options.priceData - The price data to compare.
 * @param {Array} options.prices - The array of prices to compare against.
 * @param {Object} options.priceToEdit - The price object being edited (only applicable for 'edit' purpose).
 * @returns {boolean} - Returns true if the date should be disabled, false otherwise.
 */
export const disabledDate = ({
  current,
  purpose,
  priceData,
  prices,
  priceToEdit,
  localStartDate,
  localEndDate
}) => {
  let isDisabled = false;

  if (
    localStartDate &&
    dayjs(current).startOf('day').isBefore(dayjs(localStartDate).startOf('day'))
  ) {
    return true;
  }

  if (
    localEndDate &&
    dayjs(current).startOf('day').isAfter(dayjs(localEndDate).startOf('day'))
  ) {
    return true;
  }

  if (purpose === 'create') {
    prices
      .filter((el) => el.mission === priceData.mission)
      .filter(
        (el) =>
          el.type === priceData.type ||
          (el.type === undefined && priceData.type === null)
      )
      .forEach(({ start_date, end_date }) => {
        if (end_date) {
          if (
            dayjs(current) >= dayjs(start_date).startOf('day') &&
            dayjs(current) <= dayjs(end_date).endOf('day')
          ) {
            isDisabled = true;
          }
        } else if (
          dayjs(current).startOf('day') <= dayjs(start_date).startOf('day')
        ) {
          isDisabled = true;
        }
      });
  } else {
    prices
      .filter((el) => el.mission === priceData.mission)
      .filter((el) => el.type === priceData.type)
      .filter((el) => el._id !== priceToEdit._id)
      .forEach(({ start_date, end_date }) => {
        if (end_date) {
          if (
            dayjs(current) >= dayjs(start_date).startOf('day') &&
            dayjs(current) <= dayjs(end_date).endOf('day')
          ) {
            isDisabled = true;
          }
        } else if (
          dayjs(current).startOf('day') <= dayjs(start_date).startOf('day')
        ) {
          isDisabled = true;
        }
      });
  }

  return isDisabled;
};
