import { Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { GetEnums } from '../../utils/getEnums';
import { ListResourceExtraButtons } from './buttons/ListResourceExtraButtons';
import { useQuotationContext } from '../../contexts/QuotationContext';
import { Quotation } from '../../models/Quotation';

/**
 * Component for listing quotations.
 * @component
 * @returns {JSX.Element} The list of quotations.
 */
export const ListQuotations = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState('');
  const { setActiveKey } = useGlobalContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { getEnums } = GetEnums();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { forceRefresh, setForceRefresh } = useQuotationContext();

  const columns = useColumns({ enums });

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      const enumsDatas = await getEnums({ resource: 'quotations' });
      setEnums(enumsDatas);
      setIsFieldsLoading(false);
    })();
    setActiveKey('1');
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys) => {
      setSelectedRowKeys(rowKeys);
    }
  };

  return (
    <>
      <ListResource
        resourceName="quotations"
        populate="mission,syndic,collective_ownership"
        extraQuery={filters}
        columns={columns}
        forceRefresh={forceRefresh}
        resourceModelName="Quotation"
        customActionColumn
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        searchBarExtraButtons={
          <ListResourceExtraButtons
            setFilters={setFilters}
            enums={enums}
            isFieldsLoading={isFieldsLoading}
          />
        }
      />
      {selectedRowKeys?.length > 0 ? (
        <Row style={{ marginLeft: 20 }} gutter={[16, 16]}>
          <Col>
            <Button type="primary">{t('buttons.send')}</Button>
          </Col>
          <Col>
            <Button type="primary">{t('buttons.print')}</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() =>
                Quotation.archiveMultipleQuotations({
                  ids: selectedRowKeys,
                  setForceRefresh
                })
              }
            >
              {t('buttons.archive')}
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );
};
