import { useTranslation } from 'react-i18next';
import { Form, InputNumber } from 'antd';

/**
 * Custom hook that returns an array of columns for the disbursement and documents costs table.
 *
 * @hook
 * @returns {Array} - An array of columns for the disbursement and documents costs table.
 */
export const useDisbursementAndDocumentsCostsColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('missions.form.config_document'),
      key: 'config_document',
      dataIndex: 'config_document',
      render: (config_document) => config_document?.wording || '-'
    },
    {
      title: t('missions.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (_, record) => (
        <Form.Item
          name={['disbursement_and_documents_costs', record._id, 'costs']}
          style={{ margin: 0 }}
        >
          <InputNumber addonAfter="€" />
        </Form.Item>
      )
    },
    {
      title: t('missions.form.disbursement'),
      key: 'disbursement',
      dataIndex: 'disbursement',
      render: (_, record) => (
        <Form.Item
          name={[
            'disbursement_and_documents_costs',
            record._id,
            'disbursement'
          ]}
          style={{ margin: 0 }}
        >
          <InputNumber addonAfter="€" />
        </Form.Item>
      )
    },
    {
      title: t('missions.form.to_charge'),
      key: 'to_charge',
      dataIndex: 'to_charge',
      render: (_, record) => (
        <Form.Item
          name={['disbursement_and_documents_costs', record._id, 'to_charge']}
          style={{ margin: 0 }}
        >
          <InputNumber addonAfter="€" />
        </Form.Item>
      )
    }
  ];
};
