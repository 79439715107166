import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../../utils/generateFormItem/generateFormItem';
import { useAttachedProfessionalsFields } from '../fields/attachedProfessionals';
import { CustomActionButtons } from '../../../../components/CreateUpdateContainer/CustomActionButtons';

/**
 * The `AttachedProfessionalsForm` component is designed to collect and manage information regarding professionals (such as lawyers, consultants) attached to a specific entity or task. It dynamically generates form fields for selecting professionals and setting related options, utilizing a custom hook for field generation and another for fetching professionals' data.
 *
 * Props:
 * @prop {function} onFinish - Callback function to be called upon the completion of the form, typically used for final data processing or navigation to a different page.
 * @prop {function} onSubmit - Callback function to be called upon form submission, used for data validation, submission to an API, or transitioning to the next step in a multi-step form.
 * @prop {Object} [initialValues={}] - Initial values for the form fields, used to pre-populate the form when editing or updating existing attached professionals' information.
 * @prop {Object} form - Instance of the form provided by Ant Design's Form component, necessary for form control and managing field values.
 * @prop {boolean} isFieldsLoading - Indicates whether the professionals' data is being loaded, used to display a loading indicator within the select fields.
 * @prop {function} setCurrent - Function to set the current step in a multi-step form process, useful for forms divided into multiple sections or pages.
 * @prop {number} [current=0] - The current step within a multi-step form process, indicating the position of the `AttachedProfessionalsForm` within the overall form structure.
 * @prop {Object} professionals - An object containing arrays of professional entities categorized by their roles (e.g., lawyers, consultants), used to populate select field options.
 *
 * The component initializes by using the `useGenerateFormItem` hook to dynamically generate form fields based on the configurations provided by the `useAttachedProfessionalsFields` hook.
 * It employs a `useEffect` hook to listen for changes to `initialValues` and updates the form values accordingly, ensuring correct pre-population of the form when editing attached professionals' information.
 * The `CustomActionButtons` component is included at the bottom of the form to provide navigation and submission controls. It is configured with properties to display both previous and next buttons for step navigation.
 *
 * Usage:
 * This component is intended for use within forms that require a section for managing professionals attached to an entity or task, especially in scenarios where these professionals play a role in task assignments or project management.
 */

export const AttachedProfessionalsForm = ({
  onFinish,
  onSubmit,
  initialValues,
  form,
  isFieldsLoading,
  setCurrent,
  current,
  professionals
}) => {
  const generateFields = useGenerateFormItem();
  const { attachedProfessionalsFields } = useAttachedProfessionalsFields({
    isFieldsLoading,
    professionals
  });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <>
      {attachedProfessionalsFields.map((field) =>
        generateFields('syndics', field)
      )}
      <CustomActionButtons
        onFinish={onFinish}
        onSubmit={onSubmit}
        setCurrent={setCurrent}
        current={current}
        previousButton
        nextButton
      />
    </>
  );
};

AttachedProfessionalsForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  current: PropTypes.number,
  initialValues: PropTypes.shape({}),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  isFieldsLoading: PropTypes.bool.isRequired,
  professionals: PropTypes.shape({
    lawyers: PropTypes.arrayOf(PropTypes.shape({})),
    consultants: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

AttachedProfessionalsForm.defaultProps = {
  initialValues: {},
  professionals: {},
  current: 0
};
