/**
 * Class representing a Cerfa form
 *
 * @class
 */
export class Cerfa {
  constructor() {
    /**
     * @property {number} limit - The maximum number of lines per CERFA.
     */
    this.limit = 0;

    /**
     * @property {number} chequeLimit - The maximum number of lines per cheque.
     */
    this.chequeLimit = 0;

    /**
     * @property {Array} lines - The lines in the CERFA.
     */
    this.lines = [];

    /**
     * @property {Object|null} currentLine - Pointer to the last line added to the CERFA.
     */
    this.currentLine = null;
  }

  /**
   * Adds a line to the CERFA.
   * @method
   * @param {Object} order - The order to add.
   * @param {Object} cheque - The cheque associated with the order.
   * @returns {string|boolean} - Returns a status message or true if the line was added successfully.
   */
  addLine(order, cheque) {
    // Create a pointer to the line (for the first iteration)
    if (!this.currentLine) this.currentLine = order;

    // Check if the cheque limit has been reached -> Change CERFA and cheque
    if (this.isChequeLimit(cheque)) {
      return 'change cerfa and cheque';
    }

    // Check if the SPF has changed -> Change CERFA and cheque
    if (!this.isSameSPF(order)) {
      return 'change cerfa and cheque';
    }

    if (!this.isSameCerfa(order)) {
      return 'change cerfa';
    }

    // Check if the CERFA limit has been reached -> Change CERFA
    if (this.isCerfaLimit()) {
      return 'change cerfa';
    }

    // Add the line to the CERFA
    this.lines.push(order);
    // Create a pointer to the new line
    this.currentLine = order;
    return true;
  }

  /**
   * Checks if the CERFA limit has been reached.
   * @method
   * @returns {boolean} - True if the CERFA limit has been reached, false otherwise.
   */
  isCerfaLimit() {
    return this.lines.length >= this.limit;
  }

  /**
   * Checks if the cheque limit has been reached.
   * @method
   * @param {Object} cheque - The cheque to check.
   * @returns {boolean} - True if the cheque limit has been reached, false otherwise.
   */
  isChequeLimit(cheque) {
    return cheque.getLinesLength(this.constructor.name) >= this.chequeLimit;
  }

  /**
   * Checks if the order is from the same SPF.
   * @method
   * @param {Object} order - The order to check.
   * @returns {boolean} - True if the order is from the same SPF, false otherwise.
   */
  isSameSPF(order) {
    return this.currentLine.concerned_SPF._id === order.concerned_SPF._id;
  }

  isSameCerfa(order) {
    return this.currentLine.cerfa_number === order.cerfa_number;
  }
}
