import {
  Flex,
  Card,
  List,
  Tooltip,
  Button,
  Typography,
  Spin,
  notification
} from 'antd';
import {
  EyeOutlined,
  DownloadOutlined,
  HourglassTwoTone,
  LoadingOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { inputDisplay } from '../../../utils/generateFormItem/utils/inputDisplay';
import { iconColors } from '../../../utils/constants/colors';
import { Document } from '../../../models/Document';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * DocumentCard component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.order - The order object containing details about the order.
 * @param {boolean} props.isLoading - Flag indicating if the component is in a loading state.
 * @param {Array} props.files - List of files associated with the document card.
 * @param {Function} props.setForceRefresh - Function to trigger a force refresh.
 * @returns {JSX.Element} The rendered component.
 */
export const DocumentCard = ({ order, isLoading, files, setForceRefresh }) => {
  const [uploading, setUploading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { viewDocument, downloadDocument } = useDownloadDocument();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { user } = useAuthContext();

  /**
   * Dragger properties for uploading documents.
   * @function
   * @param {Object} documentId - The document object id.
   * @param {Object} mission - The mission object.
   * @returns {Object} Dragger properties.
   */
  const draggerProps = ({ documentId, mission }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      setUploading(true);
      try {
        await Document.uploadDocument({
          id: documentId,
          file: objectFile.file,
          extraQuery: `?resource=orders&missionId=${mission._id}&step=${
            mission?.steps.sub || mission?.steps.principal
          }&user=${user._id}`,
          setForceRefresh,
          t
        });
      } catch (e) {
        notification.error({
          message: t('orders.notifications.upload_document_error_title'),
          description: t(
            'orders.notifications.upload_document_error_description'
          )
        });
      }
      setUploading(false);
    },
    fileList: []
  });

  const handleView = async (file) => {
    setDisabled(true);
    await viewDocument(file);
    setDisabled(false);
  };

  const handleDownload = async (file) => {
    setDisabled(true);
    setUploading(true);
    await downloadDocument({
      _id: file._id,
      metadata: file.metadata,
      contentType: file.contentType
    });
    setDisabled(false);
    setUploading(false);
  };

  return (
    <Card
      title={t('orders.show.cards.titles.document')}
      style={{ marginBottom: '16px' }}
    >
      <Flex
        style={{
          marginBottom: 8,
          padding: 4,
          paddingLeft: 8,
          overflowY: 'auto'
        }}
        align="center"
      >
        {files.length > 0 ? (
          <List
            style={{ maxHeight: 100, width: '100%' }}
            loading={isLoading}
            itemLayout="horizontal"
            dataSource={files}
            renderItem={(file) => (
              <List.Item
                style={{ width: '100%' }}
                actions={[
                  <Tooltip title={t('orders.show.tooltips.view_document')}>
                    <Button
                      type="link"
                      disabled={disabled}
                      onClick={() => handleView(file)}
                    >
                      <EyeOutlined style={{ fontSize: 16 }} />
                    </Button>
                  </Tooltip>,
                  <Tooltip title={t('buttons.download')}>
                    <Button
                      type="link"
                      disabled={disabled}
                      onClick={() => handleDownload(file)}
                    >
                      <DownloadOutlined style={{ fontSize: 16 }} />
                    </Button>
                  </Tooltip>
                ]}
              >
                <Title level={5} style={{ margin: 0, marginLeft: 8 }}>
                  {order.cerfa_document_type?.wording || '-'}
                </Title>
              </List.Item>
            )}
          />
        ) : (
          <>
            <HourglassTwoTone twoToneColor={iconColors.folderOpen} />
            <Title level={5} style={{ margin: 0, marginLeft: 8 }}>
              {order.cerfa_document_type?.wording || '-'}
            </Title>
          </>
        )}
      </Flex>
      {uploading ? (
        <Flex
          justify="center"
          align="center"
          style={{ height: '100%', marginTop: 64 }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 32
                }}
                spin
              />
            }
            size="large"
          />
        </Flex>
      ) : (
        inputDisplay(
          true,
          null,
          {
            ...draggerProps({
              documentId: order.document._id,
              mission: order.mission
            })
          },
          false,
          t
        )
      )}
    </Card>
  );
};

DocumentCard.propTypes = {
  order: PropTypes.shape({
    cerfa_document_type: PropTypes.string,
    document: PropTypes.shape({
      _id: PropTypes.string
    }),
    mission: PropTypes.shape({})
  }),
  isLoading: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  setForceRefresh: PropTypes.func
};

DocumentCard.defaultProps = {
  order: null,
  isLoading: false,
  files: [],
  setForceRefresh: null
};
