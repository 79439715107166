/* eslint-disable no-unused-vars */
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * Renders additional buttons for invoices.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setForceRefresh - The function to force refresh.
 * @param {boolean} props.isShowActionsButtons - Indicates whether to show action buttons.
 * @param {Array} props.selectedInvoices - The selected invoices.
 * @returns {JSX.Element} - The rendered component.
 */
export const ExtraButtons = ({
  setForceRefresh,
  isShowActionsButtons,
  // eslint-disable-next-line no-unused-vars
  selectedInvoices
}) => {
  const { t } = useTranslation();

  return isShowActionsButtons ? (
    <Row gutter={[16, 16]}>
      <>
        <Col span={3.5}>
          <Button type="primary" icon={<DownloadOutlined />}>
            {t('invoices.buttons.download')}
          </Button>
        </Col>
        <Col span={3.5}>
          <Button
            type="primary"
            icon={<PrinterOutlined />}
            onClick={() => {
              setForceRefresh((prev) => !prev);
            }}
          >
            {t('invoices.buttons.print')}
          </Button>
        </Col>
      </>
    </Row>
  ) : null;
};

ExtraButtons.propTypes = {
  setForceRefresh: PropTypes.func.isRequired,
  isShowActionsButtons: PropTypes.bool.isRequired,
  selectedInvoices: PropTypes.arrayOf(PropTypes.element).isRequired
};
