import { Form, Select, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { handleReassignFormSubmit } from '../utils/handleReassignFormSubmit';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;

/**
 * ReassignModalForm component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.syndics - The list of syndics.
 * @param {Array} props.selectedCollectiveOwnerships - The list of selected collective ownerships.
 * @param {Function} props.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - The flag to force refresh.
 * @param {Function} props.setIsReassignModalOpen - The function to set the modal open flag.
 * @param {Function} props.setSelectedRowKeys - The function to set the selected row keys.
 * @param {Function} props.setSelectedCollectiveOwnerships - The function to set the selected collective ownerships.
 * @returns {JSX.Element} The ReassignModalForm component.
 */
export const ReassignModalForm = ({
  selectedCollectiveOwnerships,
  setForceRefresh,
  forceRefresh,
  setIsReassignModalOpen,
  setSelectedRowKeys,
  setSelectedCollectiveOwnerships,
  isFieldLoading,
  managers
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form
      form={form}
      onFinish={(value) =>
        handleReassignFormSubmit({
          value,
          dispatchAPI,
          message,
          selectedCollectiveOwnerships,
          setForceRefresh,
          forceRefresh,
          setIsReassignModalOpen,
          setSelectedRowKeys,
          setSelectedCollectiveOwnerships
        })
      }
    >
      <Form.Item name="new_manager">
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldLoading}
        >
          {(managers || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <Button
              type="primary"
              onClick={() => setIsReassignModalOpen(false)}
            >
              {t('buttons.cancel')}
            </Button>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

ReassignModalForm.propTypes = {
  setIsReassignModalOpen: PropTypes.func.isRequired,
  selectedCollectiveOwnerships: PropTypes.string.isRequired,
  managers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  setSelectedRowKeys: PropTypes.func.isRequired,
  setSelectedCollectiveOwnerships: PropTypes.func.isRequired,
  isFieldLoading: PropTypes.bool
};

ReassignModalForm.defaultProps = {
  forceRefresh: false,
  isFieldLoading: false
};
