import { useTranslation } from 'react-i18next';
import { Radio, Tooltip, DatePicker } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

/**
 * Custom hook that returns an array of columns for the plan request table.
 * @hook
 * @param {Object} options - Options for the hook.
 * @param {Function} options.setPlan - Function to set the plan.
 * @param {Function} options.setExpeditor - Function to set the expeditor.
 * @returns {Array} - Array of columns.
 */
export const useColumns = ({ setPlan, setExpeditor, setMission }) => {
  const { t } = useTranslation();

  return [
    {
      title: t('plan_requests_review.titles.syndic'),
      dataIndex: 'syndic',
      key: 'syndic',
      render: (syndic) => `${syndic?.society_name || '-'} (${syndic?.code})`,
      sorter: true
    },
    {
      title: t('plan_requests_review.titles.collective_ownership'),
      dataIndex: 'collective_ownership',
      key: 'collective_ownership',
      render: (collective_ownership) => {
        const collective = collective_ownership;
        return `${collective?.code ? `${collective?.code} -` : ''} ${
          collective?.first_address?.number || ''
        } ${collective?.first_address?.street || ''} ${
          collective?.first_address?.postal_code || ''
        } ${collective?.first_address?.city || ''} ${
          collective?.cadastral_reference
            ? `(${collective?.cadastral_reference})`
            : ''
        }`;
      },
      sorter: true
    },
    {
      title: t('plan_requests_review.titles.archivist_notary'),
      dataIndex: 'plan_request',
      key: 'archivist_notary',
      render: (plan_request) => {
        const editor_notary = plan_request?.editor_notary;
        const office_name = editor_notary?.archivist_notary?.office_name;
        const email = editor_notary?.archivist_notary?.email;

        return `${office_name || ''} - ${email || ''}`;
      },
      sorter: true
    },
    {
      title: t('plan_requests_review.titles.editor_notary'),
      dataIndex: 'plan_request',
      key: 'editor_notary',
      render: (plan_request) =>
        `${plan_request?.editor_notary?.first_name || ''} ${
          plan_request?.editor_notary?.last_name || ''
        }`,
      sorter: true
    },
    {
      title: t('plan_requests_review.titles.request_date'),
      dataIndex: 'plan_request',
      key: 'date',
      render: (plan_request) =>
        plan_request?.date
          ? dayjs(plan_request?.date).format('DD/MM/YYYY')
          : '',
      sorter: true
    },
    {
      title: t('plan_requests_review.titles.last_reminder'),
      key: 'last_reminder',
      editable: true,
      render: (record) => {
        const last_reminder = record?.plan_request?.last_reminder;

        return (
          <DatePicker
            value={last_reminder ? dayjs(last_reminder) : null}
            placeholder={t('plan_requests_review.placeholders.select_date')}
            onChange={(date) => {
              setMission((prev) => ({
                ...prev,
                _id: record._id,
                collective_ownership: record.collective_ownership,
                syndic: record.syndic
              }));
              setPlan((prev) => ({
                ...prev,
                _id: record.plan_request._id,
                collective_ownership_plan: record.plan_request.plan,
                date: record.plan_request.date,
                last_reminder: date
              }));
            }}
          />
        );
      }
    },
    {
      title: t('plan_requests_review.titles.status'),
      key: 'status',
      editable: true,
      render: (record) => (
        <Radio.Group
          value={record?.plan_request.status}
          onChange={(e) => {
            setMission((prev) => ({
              ...prev,
              _id: record._id,
              collective_ownership: record.collective_ownership,
              syndic: record.syndic
            }));
            setPlan({
              _id: record.plan_request._id,
              collective_ownership_plan: record.plan_request.plan,
              date: record.plan_request.date,
              status: e.target.value
            });
            setExpeditor(record?.editor_notary?.archivist_notary?._id || '');
          }}
          disabled={record.status === 'PLANS_AVAILABLE'}
        >
          <Radio value="NO_RESPONSE">
            {t('missions.cards.plan_requests.tags.NO_RESPONSE')}
          </Radio>
          <Tooltip title={t('missions.messages.disabled_rollback')} color="red">
            <Radio value="PLANS_AVAILABLE">
              {t('missions.cards.plan_requests.tags.PLANS_AVAILABLE')}
            </Radio>
          </Tooltip>
          <Radio value="PLANS_NOT_AVAILABLE">
            {t('missions.cards.plan_requests.tags.PLANS_NOT_AVAILABLE')}
          </Radio>
        </Radio.Group>
      )
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Tooltip
          placement="topRight"
          title={t('plan_requests_review.tooltip.remind_the_notary')}
        >
          <MailOutlined
            onClick={() => {
              setPlan({
                _id: record.plan_request._id,
                last_reminder: dayjs()
              });
            }}
          />
        </Tooltip>
      )
    }
  ];
};
