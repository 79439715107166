import { useEffect, useState } from 'react';
import { Badge, Button, Col, Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { setDocumentIcon } from '../../../../../../../components/setDocumentIcon';
import { useDownloadDocument } from '../../../../../../../utils/downloadDoc';
import { useAuthContext } from '../../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../../utils/errorMessage';
import ChargingGrid from '../../../../../../charging-grid/ChargingGrid';

/**
 * Custom hook for handling collapse items in the useCollectiveOwnershipDocuments function.
 * @hook
 * @param {Object} options - The options object.
 * @param {string} options.id - The ID of the mission.
 * @param {Object} options.mission - The mission object.
 * @returns {Array} - An array of collapse items.
 */
export const useCollectiveOwnershipDocumentsCollapseItems = ({
  id,
  mission,
  updaidInvoices,
  tradKey
}) => {
  const { viewDocument, downloadDocument } = useDownloadDocument();
  const { dispatchAPI } = useAuthContext();
  const [documentTypes, setDocumentTypes] = useState({
    PCS_1: [],
    PCS_2: [],
    PCS_3: [],
    COLLECTIVE_OWNERSHIP: [],
    PHASE_1: []
  });
  const [missionDocuments, setMissionDocuments] = useState([]);
  const [collectiveOwnership, setCollectiveOwnership] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const formData = new FormData();
  formData.append('values', JSON.stringify('test'));

  const sendMail = async () => {
    try {
      setLoading(true);
      await dispatchAPI('PATCH', {
        url: `/missions/${mission?._id}`,
        body: formData
      });

      setLoading(false);
    } catch (error) {
      message(error);
    }
  };

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: updaidInvoices[record?.mission_type],
      name: record.name
    })
  };

  const getCollectiveOwnership = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/mission/${id}?fields=collective_ownership,collective_ownership.charging_grid&populate=collective_ownership,collective_ownership.charging_grid,collective_ownership.charging_grid.data,collective_ownership.charging_grid.columns.column`
    });
    setCollectiveOwnership(data.collective_ownership);
  };

  const getMissionDocuments = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/documents/mission/${id}`
    });
    setMissionDocuments(data);
  };

  const getMissionDocumentRepository = async () => {
    const typeMap = {
      PCS_1: 'PCS_1',
      PCS_2: 'PCS_1,PCS_2',
      PCS_3: 'PCS_1,PCS_2,PCS_3'
    };
    const type = typeMap[mission.type] || '';
    const { data } = await dispatchAPI('GET', {
      url: `/document-repositories?missions_display=${type}&fields=wording,document_key,missions_display`
    });

    const collective_ownership_wording = [
      'Règlement de copropriété',
      'Fiches immeuble et fiches de lots',
      'Cadastre'
    ];

    const collective_ownership_documents = data.filter((el) =>
      collective_ownership_wording.includes(el.wording)
    );
    const phase_1_array = data.filter((el) =>
      ['Phase 1 - Audit'].includes(el.wording)
    );
    const missionPCS_2inDisplayArray = data.filter(
      (el) =>
        el.missions_display.includes('PCS_2') &&
        !collective_ownership_wording.includes(el.wording)
    );
    const missionPCS_1inDisplayArray = data.filter(
      (el) =>
        el.missions_display.includes('PCS_1') &&
        !collective_ownership_wording.includes(el.wording)
    );
    const missionPCS_3inDisplayArray = data.filter(
      (el) =>
        el.missions_display.includes('PCS_3') &&
        !collective_ownership_wording.includes(el.wording)
    );

    setDocumentTypes({
      PCS_1: missionPCS_1inDisplayArray,
      PCS_2: missionPCS_2inDisplayArray,
      PCS_3: missionPCS_3inDisplayArray,
      COLLECTIVE_OWNERSHIP: collective_ownership_documents,
      PHASE_1: phase_1_array
    });
  };

  useEffect(() => {
    if (!mission.type) {
      return;
    }
    setIsLoading(true);
    Promise.all([
      getCollectiveOwnership(),
      getMissionDocuments(),
      getMissionDocumentRepository()
    ])
      .catch((error) => {
        message(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [mission]);

  const columnsReglement = () => [
    {
      key: 'document_type',
      render: (record) =>
        updaidInvoices[record.mission_type] ? (
          <>
            <InfoCircleOutlined style={{ marginRight: '10px' }} />
            {t(`${tradKey}.step_invoice_payment_required`)}
          </>
        ) : (
          record?.config_document?.wording || record?.title || '-'
        )
    },
    {
      key: 'files',
      dataIndex: 'files',
      render: (files, record) =>
        updaidInvoices[record.mission_type]
          ? ''
          : files?.map((file) => (
              <Tooltip title={file.metadata.originalName}>
                <Button
                  type="link"
                  onClick={() =>
                    file.type === 'application/pdf'
                      ? viewDocument(file)
                      : downloadDocument({
                          _id: file._id,
                          metadata: file.metadata,
                          contentType: file.contentType
                        })
                  }
                >
                  {setDocumentIcon(file.contentType)}
                </Button>
              </Tooltip>
            ))
    },
    {
      key: '',
      render: (record) => {
        if (updaidInvoices[record.mission_type]) return '';
        if (record?.type === 'INTERMEDIATE_PROJECT') {
          return (
            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={() => sendMail()}
              loading={loading}
            >
              {t('buttons.version_validate')}
            </Button>
          );
        }
        return null;
      }
    }
  ];

  const createItemsFromdocumentTypes = (type) => {
    const items = documentTypes[type].map((documentType, index) => {
      const dataSource = missionDocuments.filter((el) => {
        const { config_document } = el;
        return (
          config_document?.document_type?.wording === documentType?.wording ||
          el?.document_key === documentType?.document_key
        );
      });

      const countDataSourceFiles = dataSource.reduce(
        (acc, el) => acc + el.files.length,
        0
      );

      return {
        key: `${documentType.wording}-${String(index)}`,
        label: (
          <>
            {documentType.wording || '-'}
            {countDataSourceFiles > 0 && (
              <Badge
                count={countDataSourceFiles}
                style={{ marginLeft: '1rem' }}
                color="red"
              />
            )}
          </>
        ),
        children: (
          <Table
            rowSelection={{
              ...rowSelection
            }}
            showHeader={false}
            columns={columnsReglement(dataSource)}
            rowKey="_id"
            dataSource={dataSource}
          />
        )
      };
    });
    const countItemsFiles = items.reduce(
      (acc, el) => acc + el.children.props.dataSource.length,
      0
    );
    return {
      items,
      filesCount: countItemsFiles
    };
  };
  const PCS_1_Documents = createItemsFromdocumentTypes('PCS_1');
  const PCS_2_Documents = createItemsFromdocumentTypes('PCS_2');
  const PCS_3_Documents = createItemsFromdocumentTypes('PCS_3');
  const COLLECTIVE_OWNERSHIP_Documents = createItemsFromdocumentTypes(
    'COLLECTIVE_OWNERSHIP'
  );

  const PHASE_1_DOCUMENTS = createItemsFromdocumentTypes('PHASE_1');

  const collectiveOwnershipDocumentsItems = {
    filesCount: COLLECTIVE_OWNERSHIP_Documents.filesCount,
    items: [
      {
        key: '6',
        label: 'Grilles de charges',
        children: (
          <Col>
            <ChargingGrid
              resourceDatas={collectiveOwnership}
              setResourceDatas={setCollectiveOwnership}
              isLoading={isLoading}
            />
          </Col>
        )
      },
      ...COLLECTIVE_OWNERSHIP_Documents.items
    ]
  };

  return {
    collectiveOwnershipDocumentsItems,
    PCS_1_Documents,
    PCS_2_Documents,
    PCS_3_Documents,
    PHASE_1_DOCUMENTS
  };
};
