import { ListPrices } from './ListPrices';

/**
 * Custom hook for generating items for a price system configuration.
 *
 * @param {Object} options - The options for generating items.
 * @param {Array} options.itemsTitle - The titles of the items.
 * @param {Object} options.enums - The enums for the price system.
 * @param {Function} options.t - The translation function.
 * @returns {Object} - The generated items.
 */

export const useItems = ({ itemsTitle, enums, t }) => {
  const items = itemsTitle.map((item, index) => ({
    label: t(`missions.tags.${item}`),
    key: `${index}`,
    children: <ListPrices missionType={item} enums={enums} />
  }));

  return { items };
};
