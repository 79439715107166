import { Cerfa } from './Cerfa';

/**
 * Class representing a Cerfa3233 form, which extends the base Cerfa class.
 *
 * @class
 * @extends Cerfa
 */
export class Cerfa3233 extends Cerfa {
  /**
   * Creates an instance of Cerfa3233.
   *
   * @constructor
   */
  constructor() {
    super();

    /**
     * @property {number} limit - The maximum number of lines per CERFA3233.
     * @default 7
     */
    this.limit = 7;

    /**
     * @property {Array} lines - The lines in the CERFA3233.
     */
    this.lines = [];

    /**
     * @property {Object|null} currentLine - Pointer to the last line added to the CERFA3233.
     */
    this.currentLine = null;

    /**
     * @property {number} chequeLimit - The maximum number of lines per cheque in CERFA3233.
     * @default 10
     */
    this.chequeLimit = 10;
  }
}
