import { Route, Routes, useLocation } from 'react-router-dom';
import { ListCollectiveOwnershipsForms } from './ListCollectiveOwnershipsForms';
import { Exception } from '../../../components';
import { CreateUpdateCollectiveOwnershipFormNoConnection } from './pcs-informations/CreateUpdateCollectiveOwnershipFormNoConnection';

/**
 * Component that defines the routes for the collective ownership forms.
 *
 * @component
 * @returns {React.Component} The component
 */
export const CollectiveOwnershipsFormsRouter = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route
        path="/create"
        element={
          <CreateUpdateCollectiveOwnershipFormNoConnection
            purpose="create"
            key={location.key}
          />
        }
      />
      <Route
        path="/edit/:id"
        element={
          <CreateUpdateCollectiveOwnershipFormNoConnection
            purpose="edit"
            key={location.key}
          />
        }
      />
      <Route
        path="/show/:id"
        element={
          <CreateUpdateCollectiveOwnershipFormNoConnection
            isEditable
            purpose="edit"
          />
        }
      />
      <Route index element={<ListCollectiveOwnershipsForms />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  );
};
