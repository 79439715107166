/**
 * Generates disabled hours, minutes, and time for a given start and end hour range.
 * @hook
 * @param {number} start - The start hour of the range (default: 6).
 * @param {number} end - The end hour of the range (default: 19).
 * @returns {Object} - An object containing disabledTime function.
 */
export const disableHours = (start = 6, end = 19) => {
  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 24; i += 1) {
      if (i < start || i > end) {
        hours.push(i);
      }
    }
    return hours;
  };

  const disabledMinutes = (selectedHour) =>
    selectedHour < start || selectedHour > end
      ? Array.from({ length: 60 }, (_, i) => i)
      : [];

  const disabledTime = (_, type) => {
    if (type === 'start') {
      return {
        disabledHours,
        disabledMinutes: () => []
      };
    }
    if (type === 'end') {
      return {
        disabledHours,
        disabledMinutes: (selectedHour) => disabledMinutes(selectedHour)
      };
    }
    return {};
  };

  return { disabledTime };
};
