import {
  FilePdfFilled,
  FileExcelFilled,
  FileWordFilled,
  PictureFilled
} from '@ant-design/icons';
/**
 * Returns the corresponding icon component based on the document type.
 *
 * @function
 * @param {string} type - The MIME type of the document.
 * @returns {JSX.Element} - The icon component.
 */
export const setDocumentIcon = (type) => {
  switch (type) {
    case 'application/pdf':
      return <FilePdfFilled />;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <FileWordFilled />;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FileExcelFilled />;
    case 'image/jpeg':
      return <PictureFilled />;
    default:
      return <FilePdfFilled />;
  }
};
