import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { Informations } from './Informations';
import { Missions } from './Missions';
import ChargingGrid from '../../../charging-grid/ChargingGrid';
import { Permissions } from '../../components/Permissions/Permissions';
import { Invoices } from './Invoices';
import { Documents } from './Documents';

/**
 * The `useItems` hook is designed to prepare content for tabs within a page or component related to a collective ownership entity.
 * It organizes the content into sections including informations, missions, documents, charging grid, permissions, and invoices,
 * each represented by a distinct tab. The hook dynamically generates the content for these tabs,
 * integrating various components and passing relevant props to each based on the collective ownership's data and loading state.
 *
 * Parameters:
 * @param {Object} [collectiveOwnership={}] - An object containing details about the collective ownership entity, passed as props to relevant child components.
 * @param {boolean} [isLoading=true] - A flag indicating whether the data related to the collective ownership is currently being fetched, used to control the display of loading placeholders.
 * @param {function} t - A translation function provided by `react-i18next` for internationalization, used to translate tab labels and other UI text.
 * @param {function} [setCollectiveOwnership] - A function for updating the state of the collective ownership entity, passed to components that may modify this data.
 * @param {Array} [columns] - An array of column configurations for tables within the tabs, passed to components that render tabular data.
 * @param {function} [setColumns] - A function for updating the columns configuration, passed to components that may modify column data.
 * @param {Object} [chargingGrid] - An object containing data for the charging grid tab, passed to the `ChargingGrid` component.
 * @param {function} [setChargingGrid] - A function for updating the charging grid data, passed to the `ChargingGrid` component.
 * @param {Array} [dataSource] - An array of data items for tables within the tabs, passed to components that render tabular data.
 * @param {function} [setDataSource] - A function for updating the data source for tabular data, passed to components that may modify this data.
 * @param {Object} [permissions] - An object containing permission settings for the collective ownership, passed to the `Permissions` component.
 * @param {function} [setPermissions] - A function for updating the permissions data, passed to the `Permissions` component.
 * @param {Array} [professionals] - An array of professional entities associated with the collective ownership, passed to components that require this data.
 * @param {string} id - The unique identifier of the collective ownership entity, used to fetch the entity's data and as a key for tab navigation.
 *
 * Returns:
 * @returns {Object} An object containing an `items` array, where each item corresponds to a tab's content. Each item includes:
 * - `label`: The translated label for the tab.
 * - `key`: A unique key for the tab, used for tab navigation and identification.
 * - `children`: The content to be displayed when the tab is active, which can be a component or a component wrapped in a loading skeleton, depending on the tab.
 *
 * Usage:
 * This hook is intended for use in a component that renders a tabbed interface for displaying and interacting with various aspects of a collective ownership entity.
 * It provides a structured and extensible way to organize and manage the content of each tab, allowing for a clean separation of concerns and easy maintenance.
 */

export const useItems = ({
  collectiveOwnership,
  isLoading,
  t,
  setCollectiveOwnership,
  columns,
  setColumns,
  chargingGrid,
  setChargingGrid,
  dataSource,
  setDataSource,
  permissions,
  setPermissions,
  professionals,
  id,
  enums,
  user
}) => {
  const [restricAccess, setRestrictAccess] = useState(true);

  const setAccess = () => {
    if (!collectiveOwnership?._id) return;
    const noAccess =
      collectiveOwnership?.access === 'BLOCKED' &&
      [
        'users:NOMADE_CUSTOMER_SERVICE_MANAGER',
        'users:NOMADE_ACCOUNT_MANAGER'
      ].includes(user.role);
    setRestrictAccess(noAccess);
  };

  useEffect(() => {
    setAccess();
  }, [collectiveOwnership, user.role]);

  const items = [
    {
      label: t('collective-ownerships.show.tabs.informations'),
      key: '1',
      children: (
        <Informations
          collectiveOwnership={collectiveOwnership}
          isLoading={isLoading}
          id={id}
        />
      )
    },
    ...(restricAccess
      ? []
      : [
          {
            label: t('collective-ownerships.show.tabs.missions'),
            key: '2',
            children: (
              <Missions
                enums={enums}
                collectiveOwnership={collectiveOwnership}
              />
            )
          },
          {
            label: t('collective-ownerships.show.tabs.documents'),
            key: '3',
            children: (
              <Documents
                collectiveOwnership={collectiveOwnership}
                setCollectiveOwnership={setCollectiveOwnership}
              />
            )
          },
          {
            label: t('collective-ownerships.show.tabs.charging_grid'),
            key: '4',
            children: (
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <ChargingGrid
                  resourceDatas={collectiveOwnership}
                  setResourceDatas={setCollectiveOwnership}
                  isLoading={isLoading}
                  columns={columns}
                  setColumns={setColumns}
                  chargingGrid={chargingGrid}
                  setChargingGrid={setChargingGrid}
                  dataSource={dataSource}
                  setDataSource={setDataSource}
                />
              </Skeleton>
            )
          },
          {
            label: t('collective-ownerships.show.tabs.permissions'),
            key: '5',
            disabled: ['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(
              user.role
            ),
            children: (
              <Permissions
                permissions={permissions}
                setPermissions={setPermissions}
                professionals={professionals}
                t={t}
                isFieldsLoading={isLoading}
                purpose="show"
              />
            )
          },
          {
            label: t('collective-ownerships.show.tabs.invoices'),
            key: '6',
            children: <Invoices id={id} />
          }
        ])
  ];

  return { items };
};

useItems.propTypes = {
  collectiveOwnership: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired
};

useItems.defaultProps = {
  collectiveOwnership: {},
  isLoading: true
};
