/**
 * Returns a configuration object for resource retrieval.
 * @hook
 * @param {Object} notification The notification object.
 * @param {Function} t The translation function.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({ notification, t }) => ({
  onUpdateResource: {
    setBody: (data) => {
      const allKeysAreFalse = Object.values(data?.accepted_measures).every(
        (value) => value === false
      );

      if (!data?.no_measure_accepted && allKeysAreFalse) {
        notification.warning({
          message: t('missions.messages.no_measure_accepted')
        });
        return null;
      }

      if (data?.no_measure_accepted) {
        notification.warning({
          message: t('missions.messages.no_measure_accepted_by_syndic')
        });
      }

      const updatedData = {
        ...data,
        lawyer_step: data?.no_measure_accepted ? 'AG_DECISION' : 'FINISHED'
      };

      return updatedData;
    }
  }
});
