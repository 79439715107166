import dayjs from 'dayjs';
import { NotaryDisbursement } from '../../../../../models/NotaryDisbursement';
import { DisbursementAndVariedCost } from '../../../../../models/DisbursementAndVariedCost';

export const DisbursementMethods = () => {
  /**
   * Adds a new line to the disbursement and varied costs table.
   * @function
   * @param {Object} params - The parameters object.
   * @param {Function} params.setDisbursementAndVariedCosts - The function to set the disbursement and varied costs data.
   * @param {Function} params.setDisbursementAndNotaryCosts - The function to set the disbursement and notary costs data.
   * @param {Array} params.disbursementAndVariedCostsDatas - The array of disbursement and varied costs data.
   * @param {Array} params.disbursementAndNotaryCostsDatas - The array of disbursement and notary costs data.
   * @param {string} params.type - The type of disbursement.
   * @param {Object} params.mission - The mission object.
   * @returns {void}
   */
  const addNewLineForDisbursementTable = async ({
    setDisbursementAndVariedCosts,
    setNotaryDisbursements,
    type,
    mission,
    form,
    isDrawerOpen
  }) => {
    switch (type) {
      case 'notary':
        {
          const data = await NotaryDisbursement.postResource({
            values: {
              mission: mission._id,
              cerfa_document_type: null,
              costs: null,
              disbursement: null,
              cheque_number: null,
              dest: null,
              to_bill: null
            }
          });

          const actualValues = await form.getFieldValue(
            'disbursement_and_notary_costs'
          );

          const updatedActualValues = Object.keys(actualValues || {}).map(
            (key) => ({
              ...actualValues[key],
              _id: key
            })
          );

          setNotaryDisbursements([...updatedActualValues, data]);
        }
        break;
      case 'varied':
        {
          const data = await DisbursementAndVariedCost.postResource({
            values: {
              mission: mission._id,
              cerfa_document_type: null,
              costs: null,
              disbursement: null,
              cheque_number: null,
              dest: null,
              to_bill: null,
              mission_type: isDrawerOpen?.disbursement?.type
            }
          });

          const actualValues = await form.getFieldsValue();

          const updatedActualValues = Object.keys(
            actualValues.disbursement_and_varied_costs || {}
          ).map((key) => ({
            ...actualValues[key],
            _id: key
          }));

          setDisbursementAndVariedCosts([...updatedActualValues, data]);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Deletes a line from the disbursement table.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {Object} options.record - The record to be deleted.
   * @param {Array} options.disbursementAndVariedCostsDatas - The array of disbursement and varied costs data.
   * @param {Function} options.setDisbursementAndVariedCosts - The function to set the updated disbursement and varied costs data.
   * @param {Function} options.setNotaryDisbursements - The function to set the updated disbursement and notary costs data.
   * @param {Array} options.notaryDisbursements - The array of disbursement and notary costs data.
   * @returns {void}
   */
  const deleteLineFromDisbursementTable = async ({
    record,
    setDisbursementAndVariedCosts,
    setNotaryDisbursements,
    type,
    form
  }) => {
    switch (type) {
      case 'notary':
        {
          await NotaryDisbursement.deleteResource({
            id: record._id
          });

          const actualValues = await form.getFieldValue(
            'disbursement_and_notary_costs'
          );

          const updatedActualValues = Object.keys(actualValues || {})
            .filter((key) => key !== record._id)
            .map((key) => ({
              ...actualValues[key],
              _id: key
            }));

          setNotaryDisbursements(updatedActualValues);
        }
        break;
      case 'varied':
        {
          await DisbursementAndVariedCost.deleteResource({
            id: record._id
          });

          const actualValues = await form.getFieldsValue();

          const updatedActualValues = Object.keys(
            actualValues.disbursement_and_varied_costs || {}
          )
            .filter((key) => key !== record._id)
            .map((key) => ({
              ...actualValues[key],
              _id: key
            }));

          setDisbursementAndVariedCosts(updatedActualValues);
        }
        break;
      default:
        break;
    }
  };

  /**
   * Calculates the final amount for disbursement and notary costs based on the provided form values.
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {Object} options.form - The form object.
   * @param {Function} options.setFinalAmounts - The function to set the final amounts.
   * @returns {Promise<void>} - A promise that resolves once the final amounts are calculated and set.
   */
  const handleFinalAmount = async ({ form, setFinalAmounts }) => {
    const values = await form.getFieldValue('disbursement_and_notary_costs');

    const disbursement = Object.keys(values || {}).reduce((acc, key, index) => {
      if (index === 0) return acc;
      if (values[key].disbursement) {
        return acc + values[key].disbursement;
      }
      return acc;
    }, 0);

    const difference =
      values[Object.keys(values)[0]].disbursement - disbursement;

    setFinalAmounts({ disbursement, difference });
  };

  /**
   * Sets default values for the form fields based on the provided data.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {Array} options.documents - The array of all documens.
   * @param {Object} options.form - The form object.
   * @param {Array} options.notaryDisbursements - The array of notary disbursements.
   * @param {Array} options.disbursementAndVariedCosts - The array of disbursement and varied costs.
   * @param {Function} options.setFinalAmounts - The function to set final amounts.
   * @returns {void}
   */
  const setDefaultValues = ({
    documents,
    form,
    notaryDisbursements,
    disbursementAndVariedCosts,
    setFinalAmounts
  }) => {
    form.resetFields();

    documents.map((document) =>
      form.setFieldsValue({
        disbursement_and_documents_costs: {
          [document._id]: {
            config_document: document.config_document?.wording,
            costs: document.costs,
            disbursement: document.disbursement,
            to_charge: document.to_charge
          }
        }
      })
    );

    if (notaryDisbursements.length) {
      if (notaryDisbursements.length === 1) {
        setFinalAmounts({
          disbursement: notaryDisbursements[0].disbursement,
          difference: 0
        });
      } else {
        const disbursement = notaryDisbursements.reduce((acc, curr, index) => {
          if (index === 0) return acc;
          if (curr.disbursement) {
            return acc + curr.disbursement;
          }
          return acc;
        }, 0);

        const difference = notaryDisbursements[0].disbursement - disbursement;

        setFinalAmounts({ disbursement, difference });
      }
    }

    notaryDisbursements.map((disbursement) =>
      form.setFieldsValue({
        disbursement_and_notary_costs: {
          [disbursement._id]: {
            document: disbursement.document,
            costs: disbursement.costs,
            disbursement: disbursement.disbursement,
            cheque_number: disbursement.cheque_number,
            dest: disbursement.dest,
            date: disbursement.date ? dayjs(disbursement.date) : null,
            to_bill: disbursement.to_bill
          }
        }
      })
    );

    disbursementAndVariedCosts.map((disbursement) =>
      form.setFieldsValue({
        disbursement_and_varied_costs: {
          [disbursement._id]: {
            document: disbursement.document,
            costs: disbursement.costs,
            disbursement: disbursement.disbursement,
            cheque_number: disbursement.cheque_number,
            dest: disbursement.dest,
            to_bill: disbursement.to_bill
          }
        }
      })
    );
  };

  return {
    addNewLineForDisbursementTable,
    deleteLineFromDisbursementTable,
    handleFinalAmount,
    setDefaultValues
  };
};
