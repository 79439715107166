import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatAddress } from '../../syndic-view/utils/formatingFunctions';
import DownloadAwnsersButton from './columns/DownloadAwnsersButton';

/**
 * Returns the columns for the collective ownerships forms list.
 * @hook
 * @returns {Array} - The columns.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('collective-ownership-forms.list.syndic'),
      key: 'syndic',
      dataIndex: 'syndic',
      render: (syndic) =>
        `${syndic?.code || '-'} (${syndic?.society_name || '-'})`,
      sorter: true
    },
    {
      title: t('collective-ownership-forms.list.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: 'collective_ownership',
      render: ({ name, first_address, first_city }) =>
        formatAddress(name, first_address, first_city),
      sorter: true
    },
    {
      title: t('collective-ownership-forms.list.batch_numbers'),
      key: 'batch_numbers',
      dataIndex: 'batch_numbers',
      render: (batch_numbers) => batch_numbers || '',
      sorter: true
    },
    {
      title: t('collective-ownership-forms.list.owners'),
      key: 'owners',
      dataIndex: 'owners',
      render: (owners) => owners || '',
      sorter: true
    },
    {
      title: t('collective-ownership-forms.list.send_date'),
      key: 'send_date',
      dataIndex: 'send_date',
      render: (send_date) => dayjs(send_date).format('DD-MM-YYYY'),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={`show/${record?._id}`}
            state={{
              syndic: record?.syndic,
              collectiveOwnership: record?.collective_ownership,
              mission: record?.mission
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={`edit/${record?._id}`}
            state={{
              syndic: record?.syndic,
              collectiveOwnership: record?.collective_ownership,
              mission: record?.mission
            }}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <DownloadAwnsersButton collectiveOwnershipForm={record} />
        </>
      )
    }
  ];
};
