import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for rendering an extra search button.
 * @component
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setFilter - The function to set the filter value.
 * @returns {JSX.Element} - The JSX element representing the extra search button.
 */
export const ExtraSearchButton = ({ missions, setFilter }) => {
  const { t } = useTranslation();

  const missionTypes = missions.map((value) => ({
    value,
    label: t(`missions.tags.${value}`)
  }));

  const onChange = (value) => {
    if (!value) {
      return setFilter('');
    }
    return setFilter(value);
  };

  return (
    <Select
      placeholder={t('syndic-collective-ownerships.list.select_mission_type')}
      onChange={(v) => onChange(v)}
      allowClear
      options={missionTypes}
    />
  );
};

ExtraSearchButton.propTypes = {
  setFilter: PropTypes.func.isRequired,
  missions: PropTypes.arrayOf(PropTypes.string)
};

ExtraSearchButton.defaultProps = {
  missions: []
};
