import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

const { TextArea } = Input;

export const commentFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      name: ['comment'],
      wrapperCol: '24',
      key: 'comment',
      rules: [{ required: true }],
      noLabel: true,
      input: (
        <TextArea
          rows={6}
          placeholder={t('invoices_reminders.placeholders.write_your_message')}
        />
      )
    }
  ];
  return fields;
};
