import { useTranslation } from 'react-i18next';

/**
 * Returns the assistant informations.
 * @param {Object} pcsPreProduction - The pcsPreProduction object
 * @returns {Object[]} - The assistant informations
 */
export const useAssistantInformations = (pcsPreProduction) => {
  const { t } = useTranslation();
  const assistant = pcsPreProduction?.assistant || {};
  return [
    {
      label: t('pcs-pre-productions.form.username'),
      span: 1,
      content: assistant
        ? `${assistant?.first_name || ''} ${
            assistant?.last_name ? assistant.last_name.toUpperCase() : ''
          }`
        : '-'
    },
    {
      label: t('pcs-pre-productions.form.email'),
      span: 1,
      content: assistant?.email || '-'
    },
    {
      label: t('pcs-pre-productions.form.phone_number_full'),
      span: 1,
      content: assistant?.phone_number?.number || '-'
    }
  ];
};
