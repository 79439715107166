import { useTranslation } from 'react-i18next';
import { Form, InputNumber, Input, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { DisbursementMethods } from '../utils/disbursementMethods';

/**
 * Custom hook that returns an array of columns for the disbursement and varied costs table.
 *
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Array} options.disbursementAndVariedCosts - The data for the disbursement and varied costs table.
 * @param {Function} options.setDisbursementAndVariedCosts - The function to update the data for the disbursement and varied costs table.
 * @returns {Array} - An array of columns for the disbursement and varied costs table.
 */
export const useDisbursementAndVariedCostsColumns = ({
  form,
  setDisbursementAndVariedCosts
}) => {
  const { t } = useTranslation();
  const { deleteLineFromDisbursementTable } = DisbursementMethods();

  return [
    {
      title: t('missions.form.document'),
      key: 'document',
      dataIndex: 'document',
      render: (_, record) => (
        <Form.Item
          name={['disbursement_and_varied_costs', record._id, 'document']}
          style={{ margin: 0 }}
        >
          <Input />
        </Form.Item>
      )
    },
    {
      title: t('missions.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (_, record) => (
        <Form.Item
          name={['disbursement_and_varied_costs', record._id, 'costs']}
          style={{ margin: 0 }}
        >
          <InputNumber addonAfter="€" />
        </Form.Item>
      )
    },
    {
      title: t('missions.form.disbursement'),
      key: 'disbursement',
      dataIndex: 'disbursement',
      render: (_, record) => (
        <Form.Item
          name={['disbursement_and_varied_costs', record._id, 'disbursement']}
          style={{ margin: 0 }}
        >
          <InputNumber addonAfter="€" />
        </Form.Item>
      )
    },
    {
      title: t('missions.form.cheque_number'),
      key: 'cheque_number',
      dataIndex: 'cheque_number',
      render: (_, record) => (
        <Form.Item
          name={['disbursement_and_varied_costs', record._id, 'cheque_number']}
          style={{ margin: 0 }}
        >
          <InputNumber addonAfter="€" />
        </Form.Item>
      )
    },
    {
      title: t('missions.form.dest'),
      key: 'dest',
      dataIndex: 'dest',
      render: (_, record) => (
        <Form.Item
          name={['disbursement_and_varied_costs', record._id, 'dest']}
          style={{ margin: 0 }}
        >
          <InputNumber addonAfter="€" />
        </Form.Item>
      )
    },
    {
      title: t('missions.form.to_bill'),
      key: 'to_bill',
      dataIndex: 'to_bill',
      render: (_, record) => (
        <Form.Item
          name={['disbursement_and_varied_costs', record._id, 'to_bill']}
          style={{ margin: 0 }}
        >
          <InputNumber addonAfter="€" />
        </Form.Item>
      )
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Button
          type="link"
          onClick={() =>
            deleteLineFromDisbursementTable({
              record,
              setDisbursementAndVariedCosts,
              type: 'varied',
              form
            })
          }
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Button>
      )
    }
  ];
};
