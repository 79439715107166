/**
 * Get roles from the API
 * @param {Object} props - props
 * @param {Function} props.dispatchAPI - The API dispatch function
 * @param {Function} props.setRoles - The roles setter function
 * @param {Function} props.message - The message function
 * @param {Boolean} props.forceRefresh - The force refresh boolean
 * @param {Function} props.setForceRefresh - The force refresh setter function
 * @returns {Promise} - The API response
 */
export const getRoles = async ({
  dispatchAPI,
  setRoles,
  message,
  forceRefresh,
  setForceRefresh
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/users/enums'
    });
    data.roles = data.roles.filter((role) =>
      ['NOMADE_', 'CONSULTANT'].some((val) => role.includes(val))
    );
    setRoles(data.roles);
    setForceRefresh(!forceRefresh);
  } catch (e) {
    message(e);
  }
};
