import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { KanbanView } from '../../../../components/Kanban/KanbanView';
import { KanBanCardPcsPreProduction } from './KanBanCardPcsPreProduction';
import { PcsPreProduction } from '../../../../models/PcsPreProduction';
import { ExtraHeader } from './ExtraHeader';

/**
 * Renders the PCS pre-production kanban view.
 * @component
 * @returns {JSX.Element} The rendered PCS pre-production kanban view.
 */
export const PcsPreProductionsKanbanView = () => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const { t } = useTranslation();
  const [pcsPreProductions, setPcsPreProductions] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);
  const [filters, setFilters] = useState('');
  const columns = PcsPreProduction.ALL_PHASES.map((item) => ({
    color: '#fff',
    title: t(`pcs-pre-productions.tabs.${item.toLowerCase()}`),
    display: true,
    status: item
  }));

  useEffect(() => {
    (async () => {
      const data = await PcsPreProduction.getResources({
        populate: 'history,syndic',
        extraQuery: filters
      });
      setPcsPreProductions(data);
    })();
  }, [forceRefresh, filters, searchValue]);

  return (
    <>
      <ExtraHeader
        setFilters={setFilters}
        keyword={keyword}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />
      <KanbanView
        resourceName="pcs-pre-productions"
        datas={pcsPreProductions}
        columnHeaders={columns}
        refresh={forceRefresh}
        setRefresh={setForceRefresh}
        defaultStatus="ASSISTANT"
      >
        <KanBanCardPcsPreProduction />
      </KanbanView>
    </>
  );
};
