import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { outOfNavRoutes } from '../../utils/constants/routes/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';
import useFields from './fields';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';

const UpdateProfile = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI, user, setUser } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { fields } = useFields();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/users/${user?._id}` });
      form.setFieldsValue({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      });
      setUser(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, [fetchUser]);

  const updateProfile = async (body) => {
    const formData = new FormData();
    formData.append('values', JSON.stringify(body));

    try {
      await dispatchAPI('PATCH', {
        url: `/users/${user?._id}`,
        body: formData
      });
      await fetchUser();
      navigate(outOfNavRoutes.PROFILE);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleSubmit = async (values) => {
    await updateProfile(values);
  };

  return (
    <>
      <PageHeaderCustom title={t('profile.title')} />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form form={form} onFinish={handleSubmit} {...formItemLayout}>
            {fields.map((field) => generateFields('users', field))}
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 8px' }}
                  type="link"
                  danger
                  onClick={() => navigate(-1)}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit">
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
};

export default UpdateProfile;
