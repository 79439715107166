import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGlobalContext } from '../../../contexts/GlobalContext';

const { Option } = Select;

/**
 * Renders a form with two Select components for filtering syndics and consultants.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.consultants - The array of consultants.
 * @param {boolean} props.isFiedlLoading - Indicates if the field is loading.
 * @returns {JSX.Element} - The rendered form.
 */
export const ExtraSearchBars = ({
  setFilters,
  consultants,
  isFiedlLoading
}) => {
  const [form] = Form.useForm();
  const { syndics, isDataLoading } = useGlobalContext();
  const { t } = useTranslation();

  /**
   * Filters the options based on the input value.
   * @function
   * @param {string} input - The input value.
   * @param {Object} option - The option object.
   * @returns {boolean} - Whether the option should be displayed or not.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} layout="inline">
      <Form.Item name={['syndics']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(syndic) => {
            form.setFieldsValue({ consultants: null });
            setFilters(syndic ? `&syndic._id=${syndic}` : '');
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isDataLoading}
          placeholder={t('placeholder.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {syndic.society_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['consultants']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            form.setFieldsValue({ syndics: null });
            setFilters(value ? `&syndic.consultant._id=${value}` : '');
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFiedlLoading}
          placeholder={t('placeholder.search_consultant')}
        >
          {(consultants || []).map((consultant) => (
            <Option key={consultant?._id} value={consultant?._id}>
              {`${consultant?.last_name || ''} ${consultant?.first_name || ''}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

ExtraSearchBars.propTypes = {
  enums: PropTypes.shape({}),
  setFilters: PropTypes.func,
  consultants: PropTypes.arrayOf(PropTypes.shape({})),
  isFiedlLoading: PropTypes.bool
};

ExtraSearchBars.defaultProps = {
  enums: {},
  setFilters: () => {},
  consultants: [],
  isFiedlLoading: true
};
