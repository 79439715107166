/**
 * Returns the action fields map.
 * @returns {Object} The action fields map.
 */
export const useTotalFields = () => {
  const actionFieldsMap = {
    PUBLICATION_PCS: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    ENJOYMENT_RIGHT_TO_VALIDATED: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    CHARGING_GRID: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    LOT_ENJOYMENT_RIGHT: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    LOT_CREATION_PCS: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    SURVEYOR_QUOTATION: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    LOT_CREATION: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    LOT_MERGE: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    LOT_DIVISION: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    LOT_REMOVAL: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    CONSISTENCY_DESTINATION_CHANGE: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    LOT_REMOVAL_PCS: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    OTHER: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ],
    SALE: [
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total.total_ttc',
        key: 'total_ttc'
      },
      {
        name: 'nomade_honorary_ht',
        label: 'quotation-act-amounts.form.total.nomade_honorary_ht',
        key: 'nomade_honorary_ht'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.total.notary_honorary_ttc',
        key: 'notary_honorary_ttc'
      }
    ]
  };

  return { actionFieldsMap };
};
