/**
 * Returns a configuration object for creating a resource.
 *
 * @hook
 * @param {Object} options - The options for the configuration.
 * @param {Object} options.fileList - The list of files to be uploaded.
 * @param {Object} options.customFormData - The custom form data.
 * @param {Object} options.form - The form instance.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({ fileList, customFormData, form }) => ({
  onCreateResource: {
    setBody: (data) => {
      Object.keys(fileList).forEach((key, index) => {
        if (key === 'other_documents') {
          fileList[key].forEach((file) => {
            customFormData.append(`other_documents`, file);
          });
        } else {
          fileList[key].forEach((file) => {
            customFormData.append(`documents_${index}`, file);
          });
        }
      });

      form.resetFields();

      return data;
    }
  }
});
