import { Row, Col, Button, Typography, Popconfirm } from 'antd';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MailOutlined, WarningOutlined } from '@ant-design/icons';
import { Policy, Scales, Renew } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { Mission } from '../../../../../models/Mission';

const { Text } = Typography;

/**
 * Renders the buttons for the first step of ending a mission.
 * @returns {JSX.Element} The rendered component.
 */
export const StepOneEndButtons = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { setIsDrawerOpen, setForceRefresh, mission } = useMissionContext();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <Button>
          <MailOutlined />
          <Text>{t('missions.buttons.re_send_consultation')}</Text>
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() =>
            Mission.navigateToCreateUpdateQuotation({
              quotation: null,
              navigate,
              mission,
              mission_type: 'PCS_SURVEYOR_QUOTE'
            })
          }
        >
          <Policy />
          <Text>{t('missions.buttons.surveyor_quote')}</Text>
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() =>
            setIsDrawerOpen((prev) => ({
              ...prev,
              lawyer_consultation: {
                open: true,
                lawyer_step: 'FINISHED'
              }
            }))
          }
        >
          <Scales />
          <Text>{t('missions.buttons.lawyer_response')}</Text>
        </Button>
      </Col>
      <Col>
        <Popconfirm
          title={t(
            'datatable.column.action.re_generated_pcs_one_document.title'
          )}
          description={t(
            'datatable.column.action.re_generated_pcs_one_document.description'
          )}
          okText={t('datatable.column.action.re_generated_pcs_one_document.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t(
            'datatable.column.action.re_generated_pcs_one_document.cancel'
          )}
          onConfirm={() =>
            Mission.reGeneratePcsOneDocuments({
              id,
              setIsLoading,
              setForceRefresh
            })
          }
          icon={<WarningOutlined />}
        >
          <Button isLoading={isLoading}>
            <Renew />
            <Text>
              {t('missions.buttons.re_generate_quote_and_ag_resolution')}
            </Text>
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
};
