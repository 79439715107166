export const uploadDocument = async ({
  filesList,
  dispatchAPI,
  syndic,
  setForceRefresh,
  message,
  type
}) => {
  const formData = new FormData();

  formData.append('documents', filesList);
  formData.append('type', type);

  try {
    await dispatchAPI('PATCH', {
      url: `/syndics/documents/${syndic._id}`,
      body: formData
    });
    setForceRefresh((prev) => !prev);
  } catch (e) {
    message(e);
  }
};
