import dayjs from 'dayjs';
import { Drawer, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePCSAppointmentFields } from '../fields/PCSAppointment';
import { useAgendaContext } from '../../../../contexts/AgendaContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { CreateUpdateContainer } from '../../../../components';
import { Event } from '../../../../models/Event';
import { useInternAppointmentFields } from '../fields/internAppointment';
import { GetEnums } from '../../../../utils/getEnums';
import { useUnavailabilityFields } from '../../../event/fields/unavailability';
import { useConfig } from '../../../event/utils/createUpdateConfig';
import { useModelForms } from './modelForms';

/**
 * Represents the DrawerEvent component.
 * @component
 * @returns {JSX.Element} The rendered DrawerEvent component.
 * @example
 * <DrawerEvent />
 */
export const DrawerEvent = () => {
  const { t } = useTranslation();
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    purpose,
    formToOpen,
    drawerTitle,
    event,
    customUser
  } = useAgendaContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [form] = Form.useForm();
  const { dispatchAPI, user } = useAuthContext();
  const { syndics, setForceRefresh, forceRefresh } = useGlobalContext();
  const [syndic, setSyndic] = useState(null);
  const [managers, setManagers] = useState([]);
  const [enums, setEnums] = useState({});
  const [isCreateUpdateOpen, setIsCreateUpdateOpen] = useState(true);
  const eventId = event?._id;
  const [csm_meetings, setCSMMeetings] = useState([]);
  const [missions, setMissions] = useState([]);
  const { fields: appointmentFields } = usePCSAppointmentFields({
    syndics,
    setSyndic,
    managers,
    csm_meetings,
    isFieldsLoading
  });
  const { fields: interAppointmentFields } = useInternAppointmentFields(form);
  const { fields: unavailabilityFields } = useUnavailabilityFields(
    syndics,
    setSyndic,
    managers,
    missions,
    csm_meetings,
    user,
    isFieldsLoading,
    enums
  );
  const modelForms = useModelForms(
    appointmentFields,
    interAppointmentFields,
    unavailabilityFields
  );

  const config = useConfig({
    user,
    eventType: formToOpen,
    eventId,
    userTarget: customUser || user,
    syndic,
    form,
    setSyndic
  });
  const { getEnums } = GetEnums();

  useEffect(() => {
    (async () => {
      if (syndic) {
        const data = await Event.getManagers(syndic);
        setManagers(data);
      }
      setForceRefresh(!forceRefresh);
    })();
  }, [syndic]);

  const getRelatedEvents = async (groupId) => {
    const { data: events } = await dispatchAPI('GET', {
      url: `/event?groupId=${groupId}&fields=time_slots`
    });
    return {
      startDate: dayjs(events[0].time_slots.start),
      endDate: dayjs(events[events.length - 1].time_slots.end)
    };
  };

  useEffect(() => {
    if (event?.groupId) {
      (async () => {
        const { startDate, endDate } = await getRelatedEvents(event.groupId);
        form.setFieldsValue({
          time_slots: [startDate, endDate]
        });
      })();
    }
  }, [event?._id]);

  useEffect(() => {
    (async () => {
      const missionsEnums = await getEnums({ resource: 'missions' });
      const eventsEnums = await getEnums({ resource: 'events' });
      setEnums(eventsEnums[0]);
      setMissions(missionsEnums);
      setIsFieldsLoading(false);
    })();
    (async () => {
      const { data } = await dispatchAPI('GET', {
        url: '/customer-service-manager-meetings'
      });
      setCSMMeetings(data);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (!isCreateUpdateOpen) {
      setForceRefresh(!forceRefresh);
      setIsDrawerOpen(false);
    }
  }, [isCreateUpdateOpen]);

  return (
    <Drawer
      title={t(drawerTitle)}
      width={380}
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <CreateUpdateContainer
        config={config}
        fields={modelForms[formToOpen]}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        customId={event?._id}
        populate="syndic,manager,csm_type"
        baseUrl="events"
        resource="events"
        customFormInstance={form}
        layout="vertical"
        withFilesManager={false}
        withCustomPageHeader
        withEnums={false}
        setIsCreateUpdateOpen={setIsCreateUpdateOpen}
        isCreateUpdateOpen={false}
        cancelCustomAction={() => setIsDrawerOpen(false)}
      />
    </Drawer>
  );
};
