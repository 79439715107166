import { Checkbox, Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Mission } from '../../../../../models/Mission';

/**
 * Custom hook that returns an array of fields for lawyer consultation.
 *
 * @hook
 * @returns {Object} An object containing the fields array.
 */
export const useFields = ({ setCheckedCheckboxCount, form }) => {
  const { t } = useTranslation();
  const [gridsNames, setGridsNames] = useState({
    pcs_and_charges_to_create: false,
    existing_pcs_in_rc: false
  });

  const fields = [
    {
      name: ['consultation_document'],
      key: 'consultation_document',
      documentRepositoryType: 'Phase 1 - Audit',
      title: 'Consultation avocat',
      maxFilesCount: 100,
      input: 'File',
      rules: [{ required: true }]
    },
    {
      noLabel: true,
      input: (
        <Form.Item name={['lawyer_consultation']}>
          <Form.Item
            key="need_to_bring_into_compliance"
            name={['lawyer_consultation', 'need_to_bring_into_compliance']}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) =>
                Mission.handleLawyerConsultationCheckboxChange({
                  e,
                  fieldName: 'need_to_bring_into_compliance',
                  setCheckedCheckboxCount,
                  form
                })
              }
            >
              {t(
                'missions.form.lawyer_consultation.need_to_bring_into_compliance'
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item
            key="right_of_private_enjoyment_to_be_integrated_into_rc"
            name={[
              'lawyer_consultation',
              'right_of_private_enjoyment_to_be_integrated_into_rc'
            ]}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) =>
                Mission.handleLawyerConsultationCheckboxChange({
                  e,
                  setCheckedCheckboxCount,
                  form
                })
              }
            >
              {t(
                'missions.form.lawyer_consultation.right_of_private_enjoyment_to_be_integrated_into_rc'
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item
            key="existing_pcs_in_rc"
            name={['lawyer_consultation', 'existing_pcs_in_rc']}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                setGridsNames((prev) => ({
                  ...prev,
                  existing_pcs_in_rc: e.target.checked
                }));
                Mission.handleLawyerConsultationCheckboxChange({
                  e,
                  setCheckedCheckboxCount,
                  form
                });
              }}
            >
              {t('missions.form.lawyer_consultation.existing_pcs_in_rc')}
            </Checkbox>
          </Form.Item>
          {gridsNames.existing_pcs_in_rc && (
            <Form.Item
              key="existing_pcs_in_rc_names"
              name={['lawyer_consultation', 'existing_pcs_in_rc_names']}
            >
              <Input
                placeholder={t('missions.form.lawyer_consultation.grids_names')}
              />
            </Form.Item>
          )}
          <Form.Item
            key="charging_grid_not_published"
            name={['lawyer_consultation', 'charging_grid_not_published']}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) =>
                Mission.handleLawyerConsultationCheckboxChange({
                  e,
                  setCheckedCheckboxCount,
                  form
                })
              }
            >
              {t(
                'missions.form.lawyer_consultation.charging_grid_not_published'
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item
            key="pcs_and_charges_to_create"
            name={['lawyer_consultation', 'pcs_and_charges_to_create']}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                setGridsNames((prev) => ({
                  ...prev,
                  pcs_and_charges_to_create: e.target.checked
                }));
                Mission.handleLawyerConsultationCheckboxChange({
                  e,
                  setCheckedCheckboxCount,
                  form
                });
              }}
            >
              {t('missions.form.lawyer_consultation.pcs_and_charges_to_create')}
            </Checkbox>
          </Form.Item>
          {gridsNames.pcs_and_charges_to_create && (
            <Form.Item
              key="pcs_and_charges_to_create_names"
              name={['lawyer_consultation', 'pcs_and_charges_to_create_names']}
            >
              <Input
                placeholder={t('missions.form.lawyer_consultation.grids_names')}
              />
            </Form.Item>
          )}
          <Form.Item
            key="legal_adaptation_recommended"
            name={['lawyer_consultation', 'legal_adaptation_recommended']}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) =>
                Mission.handleLawyerConsultationCheckboxChange({
                  e,
                  fieldName: 'legal_adaptation_recommended',
                  setCheckedCheckboxCount,
                  form
                })
              }
            >
              {t(
                'missions.form.lawyer_consultation.legal_adaptation_recommended'
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item
            key="transitional_lot_to_be_specified"
            name={['lawyer_consultation', 'transitional_lot_to_be_specified']}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) =>
                Mission.handleLawyerConsultationCheckboxChange({
                  e,
                  setCheckedCheckboxCount,
                  form
                })
              }
            >
              {t(
                'missions.form.lawyer_consultation.transitional_lot_to_be_specified'
              )}
            </Checkbox>
          </Form.Item>
          <Form.Item
            key="creation_of_rcp"
            name={['lawyer_consultation', 'creation_of_rcp']}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) =>
                Mission.handleLawyerConsultationCheckboxChange({
                  e,
                  fieldName: 'creation_of_rcp',
                  setCheckedCheckboxCount,
                  form
                })
              }
            >
              {t('missions.form.lawyer_consultation.creation_of_rcp')}
            </Checkbox>
          </Form.Item>
        </Form.Item>
      )
    }
  ];

  return { fields };
};
