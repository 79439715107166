import React, { useEffect, useState } from 'react';
import { Button, Flex, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { TemplateDocsDatatable } from './TemplateDocsDatatable';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import {
  routes,
  subRoutes
} from '../../../../utils/constants/routes/developerRoutes';

const { TabPane } = Tabs;

/**
 * `ListTemplateDocs` is a component that displays a list of template documents
 * categorized by use cases. It retrieves use cases from the server and displays
 * them in tabs, with each tab containing a datatable of documents for a particular
 * use case.
 *
 * It makes use of various hooks like `useTranslation` for internationalization,
 * `useNavigate` to navigate to other routes, and `useState` and `useEffect`
 * to manage the component's state and lifecycle respectively.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} type - The type of template document. Default value is an empty string.
 * @returns {React.Element} A JSX element containing a page header with a creation button and a set of tabs displaying documents categorized by use cases.
 *
 * @example
 *
 * return (
 *   <ListTemplateDocs />
 * );
 */
const ListTemplateDocs = ({ type }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [useCases, setUsesCases] = useState([]);
  const navigate = useNavigate();

  const getUseCases = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/template-documents/enums'
      });
      setUsesCases(
        data.configurations[type].map((config) => ({
          label: config.use_case,
          key: config.use_case,
          children: (
            <TemplateDocsDatatable
              useCaseParams={config.use_case}
              type={type}
            />
          )
        }))
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getUseCases();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t(`template-documents.${type}.title`)} />
      <ContentCustom>
        <Flex justify="flex-end" style={{ marginBottom: 16 }}>
          <Button
            onClick={() =>
              navigate(
                `${routes.CONFIGURATIONS}${subRoutes.CONFIGURATIONS[type]}/create`
              )
            }
          >
            <PlusOutlined />
            {t('buttons.create')}
          </Button>
        </Flex>
        <Tabs tabPosition="left" style={{ maxHeight: '100vh' }}>
          {useCases.map(({ label, key, children }) => (
            <TabPane
              key={key}
              tab={t(`template-documents.form.${label}`)}
              style={{ paddingLeft: 0 }}
            >
              {children}
            </TabPane>
          ))}
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default ListTemplateDocs;

ListTemplateDocs.propTypes = {
  type: PropTypes.string.isRequired
};
