import { Card, Collapse, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCollapseItems } from './useCollapseItems';

/**
 * ExpectedActionsCard Component
 *
 * This component renders a card containing a list of collapsible items, representing expected actions.
 * @component
 * @returns {JSX.Element} The rendered ExpectedActionsCard component.
 */
export const ExpectedActionsCard = () => {
  const { t } = useTranslation();
  const allCollapseItems = useCollapseItems();

  return (
    <Card
      style={{ width: 'fit-content' }}
      title={t('dashboard.production.cards.expected_actions.title')}
    >
      <Row style={{ width: 'fit-content' }} gutter={[16, 16]}>
        {allCollapseItems.map((collapseItem) => (
          <Col key={collapseItem.key} style={{ width: '100%' }}>
            <Collapse
              style={{ width: '100%' }}
              key={collapseItem.key}
              items={[{ ...collapseItem }]}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
};
