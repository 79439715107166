import React, { useEffect } from 'react';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { generateColumnTitle } from './generateColumnTitle';
import { handleDeleteRow } from './handleDeleteRow';
import { handleSave } from './handleSave';
import { handleEdit } from './handleEdit';
import { handleCancel } from './handleCancel';
import { editingHandleKeyPress } from './editingHandleKeyPress';

/**
 * EditableCell component for rendering editable table cells.
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.editing - Indicates if the cell is in edit mode
 * @param {string} props.dataIndex - The data index of the cell
 * @param {string} props.title - The title of the cell
 * @param {Object} props.record - The record object for the cell
 * @param {number} props.index - The index of the cell
 * @param {boolean} props.isTextColumn - Indicates if the column is a text column
 * @param {boolean} props.isDeletedColumn - Indicates if the column is marked for deletion
 * @param {Array} props.inputRefs - Array of input refs
 * @param {Array} props.inputTableRefs - Array of input table refs
 * @param {boolean} props.editTable - Indicates if the table is in edit mode
 * @param {number} props.subindex - The subindex of the cell
 * @param {Object} props.handleSaveAllTableButtonRef - The ref for the save all button
 * @param {Function} props.setEditTable - Function for setting the edit table state
 * @param {boolean} props.isDeletedRow - Indicates if the row is marked for deletion
 * @param {Array} props.dataSource - The data source array
 * @param {Array} props.dataColumns - The data columns array
 * @param {string} props.customUrl - The custom url for the table
 * @returns {JSX.Element} - Rendered component
 */

export const EditableCell = ({
  editing,
  dataIndex,
  record,
  index,
  children,
  isTextColumn,
  isDeletedColumn,
  inputRefs,
  inputTableRefs,
  editTable,
  subindex,
  handleSaveAllTableButtonRef,
  setEditTable,
  isDeletedRow,
  dataSource,
  dataColumns,
  ...restProps
}) => {
  useEffect(() => {
    const initialFocusRef = editTable
      ? inputTableRefs[1][0].current
      : inputRefs[1].current;
    if (initialFocusRef) {
      initialFocusRef.focus();
    }
  }, [inputRefs, inputTableRefs, editTable]);

  const shouldEdit =
    (editing && dataIndex !== 'Lot' && !isDeletedColumn) ||
    (editTable &&
      dataIndex !== 'Lot' &&
      !isDeletedColumn &&
      !isDeletedRow &&
      subindex !== 1);

  return (
    <td {...restProps}>
      {shouldEdit ? (
        <Form.Item
          name={editTable ? [`key${record.key}`, dataIndex] : dataIndex}
          style={{ margin: 0 }}
        >
          <Input
            type={isTextColumn ? 'text' : 'number'}
            ref={editTable ? inputTableRefs[index][subindex] : inputRefs[index]}
            onKeyDown={(e) =>
              editingHandleKeyPress({
                e,
                index,
                dataColumns,
                editTable,
                subindex,
                dataSource,
                handleSaveAllTableButtonRef,
                setEditTable,
                inputTableRefs,
                inputRefs
              })
            }
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/**
 * Adds columns to a table based on data provided.
 * @param {Object} props - Function props
 * @param {Array} props.data - Array of column data objects
 * @param {Array} props.dataSource - Array of data source objects
 * @param {Function} props.dispatchAPI - Function for dispatching API requests
 * @param {Object} props.chargingGrid - Charging grid object
 * @param {Function} props.message - Function for displaying messages
 * @param {Function} props.setColumns - Function for setting table columns
 * @param {Function} props.t - Function for translating text
 * @param {Function} props.isEditing - Function for checking if a cell is in edit mode
 * @param {number} props.key - The key for the table
 * @param {Function} props.setChargingGrid - Function for setting charging grid data
 * @param {Object} props.tableForm - Table form object
 * @param {Function} props.setIsColumnModalOpen - Function for setting column modal open state
 * @param {Function} props.setDuplicateColumn - Function for setting duplicate column
 * @param {boolean} props.isFinalVersion - Indicates if it is the final version
 * @param {Function} props.setIsFinalVersion - Function for setting final version state
 * @param {Function} props.setEditKey - Function for setting edit key
 */

export const addColumns = ({
  data,
  dataSource,
  dispatchAPI,
  chargingGrid,
  message,
  setColumns,
  t,
  isEditing,
  key,
  setChargingGrid,
  tableForm,
  setIsColumnModalOpen,
  setDuplicateColumn,
  isFinalVersion,
  setIsFinalVersion,
  setEditKey,
  resourceDatas,
  setResourceDatas,
  inputRefs,
  inputTableRefs,
  editTable,
  setEditTable,
  handleSaveAllTableButtonRef,
  columnsButtonsPermissions
}) => {
  const columnsData = [];
  const {
    disableSaveButton,
    disableEditButton,
    disableDeleteRowButton,
    titleButtonsPermissions
  } = columnsButtonsPermissions;

  const setColumnsColors = (record) => {
    if (record.new_column) {
      return 'column-color-added';
    }
    if (record.delete_column) {
      return 'column-color-deleted';
    }
    return null;
  };

  if (!editTable) {
    columnsData.push({
      fixed: 'left',
      width: 56,
      render: (record) =>
        isEditing(record, key) ? (
          <>
            <Button
              type="link"
              onClick={() =>
                handleCancel({
                  setChargingGrid,
                  dispatchAPI,
                  message,
                  chargingGrid,
                  setEditKey
                })
              }
            >
              <CloseOutlined />
            </Button>

            {!disableSaveButton && (
              <Button
                onClick={() =>
                  handleSave({
                    key: record.key,
                    dataSource,
                    chargingGrid,
                    dispatchAPI,
                    setChargingGrid,
                    message,
                    tableForm,
                    setIsFinalVersion,
                    setEditKey,
                    resourceDatas,
                    setResourceDatas
                  })
                }
              >
                <SaveOutlined />
              </Button>
            )}
          </>
        ) : (
          record.Lot.value !== 'Total' &&
          !record.delete_row &&
          !disableEditButton && (
            <>
              <Button
                type="link"
                onClick={() =>
                  handleEdit({
                    record,
                    tableForm,
                    setEditKey
                  })
                }
              >
                <EditOutlined />
              </Button>
              {!disableDeleteRowButton && record.Lot.value !== 'Voulu' && (
                <Button
                  type="link"
                  danger
                  onClick={() =>
                    handleDeleteRow({
                      key: record.key,
                      dataSource,
                      chargingGrid,
                      dispatchAPI,
                      message,
                      setChargingGrid,
                      setIsFinalVersion,
                      resourceDatas,
                      setResourceDatas
                    })
                  }
                >
                  <DeleteOutlined />
                </Button>
              )}
            </>
          )
        )
    });
  }
  data.forEach((columnObject, index) => {
    columnsData.push({
      name: columnObject.column.name,
      title: generateColumnTitle({
        columnObject,
        charging_grid: chargingGrid,
        dispatchAPI,
        message,
        t,
        setChargingGrid,
        setIsColumnModalOpen,
        setDuplicateColumn,
        setIsFinalVersion,
        resourceDatas,
        setResourceDatas,
        titleButtonsPermissions
      }),
      dataIndex: columnObject.column.name,
      key: columnObject.column.name,
      width: index === 0 ? 80 : null,
      editable: columnObject.column.editable,
      fixed: index === 0 ? 'left' : null,
      render: (text, record, subindex) => (
        <div className={setColumnsColors(columnObject)}>
          <EditableCell
            isTextColumn={columnObject.is_text_column}
            editing={isEditing(record, key)}
            editTable={editTable}
            dataIndex={columnObject.column.name}
            title={columnObject.column.name}
            record={record}
            isDeletedColumn={columnObject.delete_column}
            isDeletedRow={record.delete_row}
            index={index}
            inputRefs={inputRefs}
            inputTableRefs={inputTableRefs}
            subindex={subindex}
            handleSaveAllTableButtonRef={handleSaveAllTableButtonRef}
            setEditTable={setEditTable}
            dataSource={dataSource}
            dataColumns={data}
          >
            {text?.value !== text?.old_value && !isFinalVersion ? (
              <span>
                {text?.value} <del>{text?.old_value}</del>
              </span>
            ) : (
              text?.value
            )}
          </EditableCell>
        </div>
      )
    });
  });
  setColumns(columnsData);
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  record: PropTypes.shape({
    key: PropTypes.number
  }),
  index: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  isTextColumn: PropTypes.bool,
  isDeletedColumn: PropTypes.bool,
  inputRefs: PropTypes.arrayOf(
    PropTypes.shape({
      current: PropTypes.shape({
        focus: PropTypes.func
      })
    })
  ),
  editTable: PropTypes.bool,
  subindex: PropTypes.number,
  inputTableRefs: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        current: PropTypes.shape({
          focus: PropTypes.func
        })
      })
    )
  ),
  handleSaveAllTableButtonRef: PropTypes.shape({
    current: PropTypes.shape({
      click: PropTypes.func
    })
  }),
  setEditTable: PropTypes.func,
  isDeletedRow: PropTypes.bool,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      delete_row: PropTypes.bool,
      Lot: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    })
  ),
  dataColumns: PropTypes.arrayOf(
    PropTypes.shape({
      delete_column: PropTypes.bool,
      column: PropTypes.shape({
        name: PropTypes.string
      })
    })
  )
};

addColumns.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      editable: PropTypes.bool
    })
  ),
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number
    })
  ),
  dispatchAPI: PropTypes.func,
  chargingGrid: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    )
  }),
  message: PropTypes.func,
  setDataSource: PropTypes.func,
  setColumns: PropTypes.func,
  t: PropTypes.func,
  isEditing: PropTypes.func,
  handleDelete: PropTypes.func,
  key: PropTypes.number,
  resourceDatas: PropTypes.shape({
    charging_grid: PropTypes.shape({
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      )
    })
  }),
  setChargingGrid: PropTypes.func,
  setCount: PropTypes.func,
  id: PropTypes.string,
  setResourceDatas: PropTypes.func,
  tableForm: PropTypes.shape({
    setFieldsValue: PropTypes.func
  })
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  title: '',
  record: null,
  index: 0,
  disabled: false,
  children: null,
  isTextColumn: false,
  isDeletedColumn: false,
  inputRefs: [],
  editTable: false,
  subindex: 0,
  inputTableRefs: [],
  handleSaveAllTableButtonRef: null,
  setEditTable: () => {},
  isDeletedRow: false,
  dataSource: [],
  dataColumns: []
};
