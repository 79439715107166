import { Select, Input, Form, Checkbox } from 'antd';

const { Option } = Select;

/**
 * The `useContactFields` hook generates configurations for form fields related to contact information within a larger form structure.
 * It provides fields for civility, name, function, email, phone numbers, and address details.
 * The hook utilizes Ant Design's `Select` and `Input` components for dropdown and text input fields, respectively.
 *
 * Parameters:
 * @param {Object} params - The parameters object.
 * @param {Object} params.enums - An object containing arrays of enumeration values for certain fields like civility and function, used to populate dropdown options.
 * @param {boolean} params.isFieldsLoading - A boolean indicating whether the enumeration data is being loaded, which controls the display of a loading indicator within the dropdown fields.
 * @param {function} params.t - A translation function provided by `react-i18next` for internationalization, used to translate labels and dropdown options.
 *
 * Returns:
 * @returns {Object} An object containing an array of field configurations, where each configuration may include:
 * - `name`: An array representing the path to the value in the form state, used as the name for the form field.
 * - `input`: A React component configured as the input for the form field, which can be either a `Select` component with search functionality for dropdown fields or an `Input` component for text input fields.
 * - `rules`: An array of validation rules for the field, indicating, for example, if a field is required.
 *
 * The `Select` components are configured with `showSearch` to enable filtering through the options, and a custom `filterOption` function to control the search behavior. The `Input.Group` component is used for phone number fields to combine country code selection with the phone number input.
 *
 * Usage:
 * This hook is intended for use in form components where contact information needs to be collected or edited, such as in user profiles, customer management interfaces, or similar applications where detailed contact information is required.
 */

export const useContactFields = ({
  enums,
  isFieldsLoading,
  t,
  setIsDirectorManager,
  showCheckBox,
  setShowCheckBox,
  isDirectorManager
}) => {
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const contactFields = [
    {
      name: ['contact', 'civility'],
      key: 'civility',
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(enums.civility || []).map((item) => (
            <Option value={item} key={item}>
              {t(`syndics.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['contact', 'last_name'],
      key: 'last_name',
      rules: [{ required: true }]
    },
    {
      name: ['contact', 'first_name'],
      key: 'first_name',
      rules: [{ required: true }]
    },
    {
      name: ['contact', 'function'],
      key: 'function',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(value) => {
            if (value === 'DIRECTOR') setShowCheckBox(true);
            else setShowCheckBox(false);
          }}
          onSelect={(value) => {
            if (value === 'DIRECTOR') setShowCheckBox(true);
            else setShowCheckBox(false);
          }}
        >
          {(enums.function || []).map((item) => (
            <Option value={item} key={item}>
              {t(`syndics.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      noLabel: true,
      name: ['contact', 'is_director_manager'],
      input: (
        <Checkbox
          onChange={(e) => {
            setIsDirectorManager(e.target.checked);
          }}
          disabled={!showCheckBox}
          defaultChecked={isDirectorManager}
        >
          {t('syndics.form.is_director_manager')}
        </Checkbox>
      )
    },
    {
      name: ['contact', 'email'],
      key: 'email',
      rules: [{ required: true }]
    },
    {
      name: ['contact', 'phone_number'],
      key: 'phone_number',
      rules: [{ required: true }],
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['contact', 'phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option key="33" value="+33">
                +33
              </Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['contact', 'phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['contact', 'cell_phone_number'],
      key: 'cell_phone_number',
      rules: [{ required: true }],
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['contact', 'cell_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option key="33" value="+33">
                +33
              </Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['contact', 'cell_phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['contact', 'address', 'number'],
      key: 'number'
    },
    {
      name: ['contact', 'address', 'street'],
      key: 'street'
    },
    {
      name: ['contact', 'address', 'additional'],
      key: 'additional'
    },
    {
      name: ['contact', 'address', 'postal_code'],
      key: 'postal_code'
    },
    {
      name: ['contact', 'address', 'city'],
      key: 'city'
    }
  ];

  return { contactFields };
};
