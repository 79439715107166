import React, { useEffect } from 'react';
import { Input, Typography, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../../utils/generateFormItem/generateFormItem';
import { usePublicationFields } from '../fields/publication';
import { CustomActionButtons } from '../../../../components/CreateUpdateContainer/CustomActionButtons';

const { Group } = Input;
const { Title } = Typography;

/**
 * The `PublicationForm` component is designed for collecting and managing publication-related information within a form structure. It dynamically generates form fields for publication details and representative address information, utilizing a custom hook for field generation and another for fetching enumeration values for certain fields.
 *
 * Props:
 * @prop {function} onFinish - Callback function to be called upon the completion of the form, typically used for final data processing or navigation to a different page.
 * @prop {function} onSubmit - Callback function to be called upon form submission, used for data validation, submission to an API, or transitioning to the next step in a multi-step form.
 * @prop {function} t - A translation function provided by `react-i18next` for internationalization, used to translate labels and dropdown options.
 * @prop {Object} [initialValues={}] - Initial values for the form fields, used to pre-populate the form when editing or updating existing publication information.
 * @prop {Object} form - Instance of the form provided by Ant Design's Form component, necessary for form control and managing field values.
 * @prop {Object} [enums={}] - An object containing enumeration values for dropdown fields, used to populate dropdown options.
 * @prop {boolean} isFieldsLoading - Indicates whether the enumeration data or other related data is being loaded, used to display a loading indicator within the dropdown fields.
 * @prop {function} setCurrent - Function to set the current step in a multi-step form process, useful for forms divided into multiple sections or pages.
 * @prop {number} [current=0] - The current step within a multi-step form process, indicating the position of the `PublicationForm` within the overall form structure.
 *
 * The component uses the `useGenerateFormItem` hook to dynamically generate form fields based on the configurations provided by the `usePublicationFields` hook.
 * A `useEffect` hook is employed to listen for changes to `initialValues` and update the form values accordingly, ensuring correct pre-population of the form when editing publication and address details.
 * The `CustomActionButtons` component is included at the bottom of the form to provide navigation and submission controls. It is configured with properties to display a previous button for step navigation.
 *
 * Usage:
 * This component is intended for use within forms that require a section for collecting or editing publication-related information, such as in registration forms, profile editors, or similar applications where detailed publication information is essential.
 */

export const PublicationForm = ({
  onFinish,
  onSubmit,
  t,
  initialValues,
  form,
  enums,
  isFieldsLoading,
  setCurrent,
  current
}) => {
  const generateFields = useGenerateFormItem();
  const { publicationFields, addressFields } = usePublicationFields({
    enums,
    isFieldsLoading
  });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Group>
      {publicationFields.map((field) => generateFields('syndics', field))}
      <Divider>
        <Title level={5} className="underlined">
          {t('syndics.form.sub_titles.representative_address')}
        </Title>
      </Divider>

      {addressFields.map((field) => generateFields('syndics', field))}
      <CustomActionButtons
        onFinish={onFinish}
        onSubmit={onSubmit}
        setCurrent={setCurrent}
        current={current}
        previousButton
      />
    </Group>
  );
};

PublicationForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  current: PropTypes.number,
  initialValues: PropTypes.shape({}),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  enums: PropTypes.shape({}),
  isFieldsLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

PublicationForm.defaultProps = {
  initialValues: {},
  enums: {},
  current: 0
};
