import { Col, Popconfirm, Row, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Event } from '../../models/Event';
import TodoItem from '../../routes/customer-service-manager-view/event/SubComponents/TodoItem';

const { Text } = Typography;

const rowStyle = {
  cursor: 'pointer',
  justifyContent: 'space-between'
};

const textStyle = { color: 'white', fontSize: '15px' };

const tagStyle = {
  backgroundColor: '#ffaa7f',
  paddingRight: '4px',
  paddingLeft: '4px',
  marginTop: '2px',
  border: 'none',
  borderRadius: '8%',
  color: 'black'
};

const formatTime = (time: string) => moment(time).format('HH:mm').replace(':', 'h');

const handleDrawerOpen = (
  eventItem: any,
  setFormToOpen: any,
  setEvent: any,
  setPurpose: any,
  setDrawerTitle: any,
  setIsDrawerOpen: any
) => {
  setFormToOpen(eventItem.type);
  setEvent(eventItem);
  setPurpose('edit');
  setDrawerTitle(`events.updates.${eventItem.type.toLowerCase()}`);
  setIsDrawerOpen(true);
};

/**
 * Renders a weekly calendar cell with event details, depending on whether the event is an all-day event or not.
 * If the event is not all-day, it provides detailed event information with interaction options.
 * If the event is an all-day event, it renders a simple row with the event details.
 *
 * @param {Object} eventItem - The event object containing details about the event.
 * @param {boolean} allDay - Flag indicating whether the event is an all-day event.
 * @param {boolean} forceRefresh - A state value used to force a refresh of the component when updated.
 * @param {Function} setForceRefresh - A function to update the forceRefresh state value.
 * @param {Function} setIsDrawerOpen - A function to control the visibility of the event details drawer.
 * @param {Function} setEvent - A function to set the current event being interacted with.
 * @param {Function} setPurpose - A function to define the purpose of the drawer interaction (e.g., 'edit').
 * @param {Function} setFormToOpen - A function to determine which form should be opened in the drawer.
 * @param {Function} setDrawerTitle - A function to set the title of the event details drawer.
 *
 * @returns {JSX.Element} The JSX element rendering the event details within the calendar cell.
 */
export const weeklyCellRender = (
  eventItem: any,
  allDay: boolean,
  forceRefresh: boolean,
  setForceRefresh: any,
  setIsDrawerOpen: any,
  setEvent: any,
  setPurpose: any,
  setFormToOpen: any,
  setDrawerTitle: any,
) => {
  if (allDay === false) {
    return (
      <>
        <Row
          style={rowStyle}
          onClick={() =>
            handleDrawerOpen(
              eventItem,
              setFormToOpen,
              setEvent,
              setPurpose,
              setDrawerTitle,
              setIsDrawerOpen
            )
          }
        >
          <Col>{`${formatTime(eventItem.startTime)} - ${formatTime(
            eventItem.endTime
          )}`}</Col>
          <Col>
            {moment
              .utc(moment(eventItem.endTime).diff(moment(eventItem.startTime)))
              .format('HH:mm')
              .replace(':', 'h')}
          </Col>
          <Tooltip
            title={`${eventItem.user?.first_name || ''} ${
              eventItem.user?.last_name || ''
            }`}
          >
            <Tag style={tagStyle}>
              <Text strong style={{ color: 'black', fontSize: '12px' }}>
                {eventItem.user?.first_name?.[0]}
                {eventItem.user?.last_name?.[0]}
              </Text>
            </Tag>
          </Tooltip>
        </Row>
        <Row style={rowStyle}>
          <Col>
            <Text strong style={textStyle}>
              {eventItem?.title || eventItem?.syndic?.society_name}
            </Text>
          </Col>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col style={{ cursor: 'pointer', paddingRight: '5px' }}>
              <EditOutlined
                onClick={() => {
                  setPurpose('edit');
                  setFormToOpen(eventItem.type);
                  setDrawerTitle(
                    `events.updates.${eventItem.type.toLowerCase()}`
                  );
                  setEvent(eventItem);
                  setIsDrawerOpen(true);
                }}
              />
            </Col>
            <Col style={{ cursor: 'pointer' }}>
              <Popconfirm
                title="Etes-vous sûr de vouloir supprimer cet événement ?"
                onConfirm={async () => {
                  await Event.deleteResource(eventItem.id);
                  setForceRefresh(!forceRefresh);
                }}
                okText="Oui"
                cancelText="Non"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ width: 'auto', overflow: 'scroll' }}>
              {eventItem.userTarget && eventItem.type === 'EXTERN' ? (
                <>
                  <Text
                    style={textStyle}
                  >{`${eventItem.userTarget?.first_name} ${eventItem.userTarget?.last_name}`}</Text>
                  <br />
                </>
              ) : null}
              {eventItem.informations && (
                <div
                  style={{
                    width: '100%',
                    height: 50,
                    overflow: 'scroll',
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {eventItem.informations}
                </div>
              )}
            </Col>
            <Col>{eventItem.contacts}</Col>
          </Row>
        </Row>
      </>
    );
  }

  if (!eventItem?._id) {
    return;
  }
  return (
    <Row>
      <TodoItem item={eventItem} />
    </Row>
  );
};
