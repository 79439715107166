import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useErrorMessage } from '../utils/errorMessage';
import { useAuthContext } from './AuthContext';

export const ConfigurationContext = createContext();

/**
 * Provides configuration context for the application.
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The configuration provider component.
 */
export const ConfigurationProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [
    duplicateSpfDepartmentalArchives,
    setDuplicateSpfDepartmentalArchives
  ] = useState([]);
  const [duplicateMunicipalities, setDuplicateMunicipalities] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const { pathname } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });

  const getDuplicateSpfDepartmentalArchives = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/spf-departmental-archives/duplicate'
      });
      setDuplicateSpfDepartmentalArchives(data);
    } catch (e) {
      if (e) message(e);
    }
  };

  const getDuplicateMunicipalities = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/municipalities/duplicate'
      });
      setDuplicateMunicipalities(data);
    } catch (e) {
      if (e) message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getDuplicateSpfDepartmentalArchives();
      await getDuplicateMunicipalities();
    })();
  }, [forceRefresh]);

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  return (
    <ConfigurationContext.Provider
      value={{
        duplicateSpfDepartmentalArchives,
        setDuplicateSpfDepartmentalArchives,
        duplicateMunicipalities,
        setDuplicateMunicipalities,
        setForceRefresh,
        forceRefresh,
        searchValue,
        setPagination,
        pagination,
        currentSorter
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export const useConfigurationContext = () => {
  const context = useContext(ConfigurationContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
