/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
// : TODO - Remove eslint-disable
// : TODO - Huge refactoring needed (for later after getting the data from the backend)
import { useTranslation } from 'react-i18next';
import { Card, Col, DatePicker, Row, Select, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  ContentCustom,
  ListResource,
  PageHeaderCustom
} from '../../../components';
import { SearchBars } from './SearchBars';
import { User } from '../../../models/User';
import { GetEnums } from '../../../utils/getEnums';
import { useMonitoringProdColumns } from './columns/monitoringProdColumns';
import { useProcessDelayColumns } from './columns/processDelayColumns';
import { useCashColumns } from './columns/cashColumns';
import { useRecoverColumns } from './columns/recoverColumns';
import { useBrokenFoldersColumns } from './columns/brokenFoldersColumns';
import { useMissingDocummentsColumns } from './columns/missingDocuments';
import { transformExtraQuery } from '../../../utils/filtersUtils';

const { RangePicker } = DatePicker;
const { Text } = Typography;

/**
 * Renders the Statistics component.
 *
 * This component displays various statistics related to monitoring.
 * It includes search bars, cards with data, and lists of resources.
 * @component
 * @returns {JSX.Element} The Statistics component.
 */
export const Statistics = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({});
  const [extraQuery, setExtraQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [enums, setEnums] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { getEnums } = GetEnums();

  // all forcerefresh (for optimization purposes)
  const [forceRefresh, setForceRefresh] = useState(false);
  const [monitoringProdRefresh, setMonitoringProdRefresh] = useState(false);
  const [processDelayRefresh, setProcessDelayRefresh] = useState(false);
  const [recoverRefresh, setRecoverRefresh] = useState(false);
  const [cashRefresh, setCashRefresh] = useState(false);
  const [brokenFoldersRefresh, setBrokenFoldersRefresh] = useState(false);
  const [missingDocumentsRefresh, setMissingDocumentsRefresh] = useState(false);

  // columns
  const monitoringProdColumns = useMonitoringProdColumns();
  const processDelayColumns = useProcessDelayColumns();
  const recoverColumns = useRecoverColumns();
  const cashColumns = useCashColumns();
  const brokenFoldersColumns = useBrokenFoldersColumns();
  const missingDocumentsColumns = useMissingDocummentsColumns();

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const { roles } = await getEnums({ resource: 'users' });
      const usersData = await User.getResources({
        populate: '',
        extraQuery: selectedRole ? `role=${selectedRole}` : ''
      });
      setEnums(roles);
      setUsersList(usersData);
      setIsDataLoading(false);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    (async () => {
      if (selectedRole !== '') {
        const users = await User.getResources({
          populate: '',
          extraQuery: `role=${selectedRole}`
        });
        setUsersList(users);
      }
    })();
  }, [selectedRole]);

  useEffect(() => {
    const updatedExtraQuery = transformExtraQuery(filters);
    setExtraQuery(updatedExtraQuery);
  }, [filters]);

  /*
   * This useEffect is used to force refresh all the lists when the global forceRefresh state changes
   */
  useEffect(() => {
    setMonitoringProdRefresh(!monitoringProdRefresh);
    setProcessDelayRefresh(!processDelayRefresh);
    setRecoverRefresh(!recoverRefresh);
    setCashRefresh(!cashRefresh);
    setBrokenFoldersRefresh(!brokenFoldersRefresh);
    setMissingDocumentsRefresh(!missingDocumentsRefresh);
  }, [forceRefresh, extraQuery]);

  return (
    <>
      <PageHeaderCustom title={t('statistics.title')} />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <SearchBars
              filters={filters}
              setFilters={setFilters}
              setSelectedRole={setSelectedRole}
              usersList={usersList}
              enums={enums}
              isDataLoading={isDataLoading}
              setForceRefresh={setForceRefresh}
              setExtraQuery={setExtraQuery}
            />
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 16
          }}
        >
          <Col xs={24} sm={24} lg={12}>
            <Card
              title={t('statistics.cards.titles.pcs_mission')}
              bordered={false}
              extra={
                <DatePicker
                  style={{ width: 200 }}
                  onChange={(date) => {
                    console.log('Date:', date);
                  }}
                />
              }
            >
              <p>{t('statistics.content')}</p>
            </Card>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Card
              title={t('statistics.cards.titles.invoices_reminder')}
              bordered={false}
              extra={
                <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    console.log('Dates:', dates);
                  }}
                  format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                />
              }
            >
              <p>{t('statistics.content')}</p>
            </Card>
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 16
          }}
        >
          <Col span={24}>
            <Card
              title={t('statistics.cards.titles.prod_monitoring')}
              bordered={false}
              extra={
                <Space>
                  <Text>{t('statistics.cards.labels.creation_date')}:</Text>
                  <RangePicker
                    format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                    style={{ width: 300 }}
                    onChange={(dates) => {
                      if (!dates) {
                        setFilters((prev) => ({
                          ...prev,
                          start_date: '',
                          end_date: ''
                        }));
                        setMonitoringProdRefresh(!monitoringProdRefresh);
                        return;
                      }
                      setFilters((prev) => ({
                        ...prev,
                        start_date: dayjs(dates[0])
                          ? dayjs(dates[0]).format('YYYY-MM-DD')
                          : '',
                        end_date: dayjs(dates[1])
                          ? dayjs(dates[1]).format('YYYY-MM-DD')
                          : ''
                      }));
                      setMonitoringProdRefresh(!monitoringProdRefresh);
                    }}
                  />
                </Space>
              }
            >
              <ListResource
                resourceName="statistics/prod-monitoring"
                columns={monitoringProdColumns}
                extraQuery={extraQuery}
                forceRefresh={monitoringProdRefresh}
                withCreateButton={false}
                deleteAction={false}
                editAction={false}
                showAction={false}
                withUploadButton={false}
                withPageHeader={false}
                withSearchBar={false}
                scroll={{ x: 'max-content' }}
              />
            </Card>
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 16
          }}
        >
          <Col span={24}>
            <Card
              title={t('statistics.cards.titles.process_delay')}
              bordered={false}
              extra={
                <Space>
                  <Text>{t('statistics.cards.labels.creation_date')}:</Text>
                  <RangePicker
                    style={{ width: 300 }}
                    onChange={(dates) => {
                      if (!dates) {
                        setFilters((prev) => ({
                          ...prev,
                          start_date: '',
                          end_date: ''
                        }));
                        setMonitoringProdRefresh(!monitoringProdRefresh);
                        return;
                      }
                      setFilters((prev) => ({
                        ...prev,
                        start_date: dayjs(dates[0])
                          ? dayjs(dates[0]).format('YYYY-MM-DD')
                          : '',
                        end_date: dayjs(dates[1])
                          ? dayjs(dates[1]).format('YYYY-MM-DD')
                          : ''
                      }));
                      setProcessDelayRefresh(!processDelayRefresh);
                    }}
                    format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                  />
                </Space>
              }
            >
              <ListResource
                resourceName="statistics/process-delay"
                columns={processDelayColumns}
                extraQuery={filters}
                forceRefresh={processDelayRefresh}
                withCreateButton={false}
                withUploadButton={false}
                withPageHeader={false}
                withSearchBar={false}
                scroll={{ x: 'max-content' }}
              />
            </Card>
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 16
          }}
        >
          <Col span={24}>
            <Card
              title={t('statistics.cards.titles.recover')}
              bordered={false}
              extra={
                <Space>
                  <Text>{t('statistics.cards.labels.creation_date')}:</Text>
                  <RangePicker
                    style={{ width: 300 }}
                    onChange={(dates) => {
                      console.log('Date:', dates);
                    }}
                    format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                  />
                </Space>
              }
            >
              {/* <ListResource
                resourceName="statistics/recover"
                columns={recoverColumns}
                extraQuery={filters}
                forceRefresh={recoverRefresh}
                withCreateButton={false}
                withUploadButton={false}
                withPageHeader={false}
                withSearchBar={false}
                scroll={{ x: 'max-content' }}
              /> */}
            </Card>
          </Col>
        </Row>
        <Row
          gutter={[16, 16]}
          style={{
            marginBottom: 16
          }}
        >
          <Col span={24}>
            <Card
              title={t('statistics.cards.titles.cash')}
              bordered={false}
              extra={
                <Space>
                  <Text>{t('statistics.cards.labels.status')}:</Text>
                  <Select
                    style={{ width: 200 }}
                    onChange={(value) => {
                      console.log('Status:', value);
                    }}
                    showSearch
                    allowClear
                    loading={isDataLoading}
                    placeholder={t('statistics.cards.labels.status')}
                  >
                    {(enums || []).map((role) => (
                      <Select.Option key={role} value={role}>
                        {t(`users.tags.${role}`)}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
              }
            >
              {/* <ListResource
                resourceName="statistics/cash"
                columns={cashColumns}
                extraQuery={filters}
                forceRefresh={cashRefresh}
                withCreateButton={false}
                withUploadButton={false}
                withPageHeader={false}
                withSearchBar={false}
                scroll={{ x: 'max-content' }}
              /> */}
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              title={t('statistics.cards.titles.broken_folders')}
              bordered={false}
            >
              {/* <ListResource
                resourceName="statistics/broken-folders"
                columns={brokenFoldersColumns}
                extraQuery={filters}
                forceRefresh={brokenFoldersRefresh}
                withCreateButton={false}
                withUploadButton={false}
                withPageHeader={false}
                withSearchBar={false}
                scroll={{ x: 'max-content' }}
              /> */}
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title={t('statistics.cards.titles.missing_documents')}
              bordered={false}
            >
              {/* <ListResource
                resourceName="statistics/missing-documents"
                columns={missingDocumentsColumns}
                extraQuery={filters}
                forceRefresh={forceRefresh}
                withCreateButton={false}
                withUploadButton={false}
                withPageHeader={false}
                withSearchBar={false}
                scroll={{ x: 'max-content' }}
              /> */}
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
