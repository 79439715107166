import { useEffect } from 'react';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import charging_grids_1 from '../../../../../../assets/images/charging_grids_1_example.jpg';
import charging_grids_2 from '../../../../../../assets/images/charging_grids_2_example.png';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem/generateFormItem';
import { useDraggerField } from '../../../utils/DraggerField/useDraggerField';

const { Text, Title } = Typography;

const pTagsStyle = { margin: 0, padding: 0, lineHeight: '3rem' };

const imagesStyle = {
  maxWidth: '95%',
  height: 'auto'
};
/**
 * Renders the ChargingGridSubmission component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values for the form.
 * @param {Array} props.filesList - The list of files.
 * @param {Function} props.setFilesList - The function to set the list of files.
 * @param {Function} props.setFilesConfiguration - The function to set the files configuration.
 * @param {Object} props.filesConfiguration - The configuration for the files.
 * @param {Function} props.setButtonsActive - The function to set the buttons active.
 * @param {string} props.purpose - The purpose of the component.
 * @param {Function} props.deleteFile - The function to delete a file.
 * @returns {JSX.Element} The rendered ChargingGridSubmission component.
 */
export const ChargingGridSubmission = ({
  form,
  initialValues,
  filesList,
  setFilesList,
  setFilesConfiguration,
  filesConfiguration,
  setButtonsActive,
  purpose,
  deleteFile
}) => {
  const generateFields = useGenerateFormItem();
  const tradKeyForm = 'pcs-pre-productions.form';
  const fieldsLeft = useDraggerField({
    fileName: 'charging_grids',
    filesList,
    setFilesList,
    setFilesConfiguration,
    filesConfiguration,
    purpose,
    deleteFile
  });
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useEffect(() => {
    if (filesList.charging_grids.length > 0) {
      setButtonsActive((prev) => ({ ...prev, next: true }));
    } else {
      setButtonsActive((prev) => ({ ...prev, next: false }));
    }
  }, [filesList]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <Divider>
          <Title level={5}>
            {t(`${tradKeyForm}.submit_documents_divider_2`)}
          </Title>
        </Divider>
        <Flex vertical align="center">
          <Text style={pTagsStyle}>
            {t(`${tradKeyForm}.submit_charging_grids`)}
          </Text>
          <Text style={pTagsStyle}>{t(`${tradKeyForm}.more_info`)}</Text>
          {fieldsLeft.map((field) =>
            generateFields('pcs-pre-productions', field)
          )}
        </Flex>
      </Col>
      <Col span={14}>
        <Divider>
          <Title level={5}>
            {t(`${tradKeyForm}.charging_grids_example_1`)}
          </Title>
        </Divider>
        <img style={imagesStyle} src={charging_grids_1} alt="placeholder" />
        <Divider>
          <Title level={5}>
            {t(`${tradKeyForm}.charging_grids_example_2`)}
          </Title>
        </Divider>
        <img style={imagesStyle} src={charging_grids_2} alt="placeholder" />
      </Col>
    </Row>
  );
};

ChargingGridSubmission.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  filesList: PropTypes.shape({
    charging_grids: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  setFilesList: PropTypes.func.isRequired,
  setFilesConfiguration: PropTypes.func.isRequired,
  filesConfiguration: PropTypes.shape({}).isRequired,
  setButtonsActive: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired
};
