import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Space, Switch, Typography } from 'antd';
import { ProjectOutlined, TableOutlined } from '@ant-design/icons';
import { PageHeaderCustom } from '../../../components';
import { ListMissions } from './ListMissions';
import { MissionKanbanView } from './KanbanView/MissionKanbanView';

const { Text } = Typography;

/**
 * @component
 * Renders the Missions component.
 *
 * @returns {JSX.Element} The rendered Missions component.
 */
export const Missions = () => {
  const { t } = useTranslation();
  const [showView, setShowView] = useState('table');

  return (
    <>
      <PageHeaderCustom title={t('missions.title')} />
      <Card
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          background: 'none',
          border: 'none'
        }}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Space>
              <TableOutlined />
              <Text>{t('pcs-pre-productions.view.table')}</Text>
            </Space>
          </Col>
          <Col>
            <Switch
              onChange={(e) => {
                if (e) {
                  setShowView('kanban');
                } else {
                  setShowView('table');
                }
              }}
            />
          </Col>
          <Col>
            <Space>
              <ProjectOutlined />
              <Text>{t('pcs-pre-productions.view.kanban')}</Text>
            </Space>
          </Col>
        </Row>
      </Card>

      {showView === 'table' ? <ListMissions /> : <MissionKanbanView />}
    </>
  );
};
