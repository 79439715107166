import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PageHeaderCustom,
  ContentCustom,
  ListResource
} from '../../../components';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { GetEnums } from '../../../utils/getEnums';
import { usetableColumns } from './columns/tableColumns';
import { ExpandedManagerTable } from './components/ExpandedManagerTable';
import SearchBarsOptions from './components/SearchBarsOptions';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import AddCommentDrawer from './components/AddCommentDrawer';
import { User } from '../../../models/User';

/**
 * @component
 * @returns {JSX.Element} The rendered ListPaymentFollowUps component.
 *
 * @example
 * return (
 *   <ListPaymentFollowUps />
 * )
 */
export const ListPaymentFollowUps = () => {
  const { t } = useTranslation();
  const { syndics } = useGlobalContext();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [missionTypes, setMissionTypes] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedSyndic, setSelectedSyndic] = useState('');
  const [extraQuery, setExtraQuery] = useState('');
  const [consultants, setConsultants] = useState([]);
  const { getEnums } = GetEnums();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [openAddComment, setOpenAddComment] = useState(false);
  const tableColumns = usetableColumns(setOpenAddComment, setSelectedSyndic);

  const expandedRowRender = useCallback((record) => {
    const tableData = record?.managers;
    return (
      <ExpandedManagerTable
        dataSource={tableData}
        setOpen={setOpenAddComment}
        comment={
          record?.syndic.comment || t('invoices_reminders.columns.no_comment')
        }
      />
    );
  }, []);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const enumsData = await getEnums({ resource: 'invoices' });
      const enumsMissions = await getEnums({ resource: 'missions' });
      const consultantsData = await User.getResources({
        populate: '',
        extraQuery: 'role=users:CONSULTANT'
      });
      setInvoiceTypes(enumsData);
      setMissionTypes(enumsMissions);
      setConsultants(consultantsData);
      setIsDataLoading(false);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <>
      <AddCommentDrawer
        open={openAddComment}
        setOpen={setOpenAddComment}
        syndic={selectedSyndic}
        setForceRefresh={setForceRefresh}
      />
      <PageHeaderCustom
        title={t('invoices_reminders.titles.payment_follow_ups')}
      />
      <ContentCustom>
        <ListResource
          style={{ padding: '0' }}
          resourceName="invoices/generate-payment-follow-ups"
          resourceModelName="Syndic"
          columns={tableColumns}
          withCreateButton={false}
          withPageHeader={false}
          withSearchBar
          scroll={{ x: 'max-content' }}
          customActionColumn
          forceRefresh={forceRefresh}
          extraQuery={extraQuery}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => record?.managers?.length > 0
          }}
          searchBarExtraButtons={
            <SearchBarsOptions
              setFilters={setFilters}
              syndics={syndics}
              isDataLoading={isDataLoading}
              setForceRefresh={setForceRefresh}
              missions={missionTypes}
              invoiceTypes={invoiceTypes}
              consultants={consultants}
            />
          }
        />
      </ContentCustom>
    </>
  );
};
