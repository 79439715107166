import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { generatePDF } from '../../../../../../utils/generatePDF';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../../contexts/AuthContext';

/**
 * @component
 * DownloadEmptyCollectiveOwnershipFormsButton component.
 *
 * This component renders a button that, when clicked, triggers the download of a collective ownership form PDF.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.mission - The mission object containing the mission details.
 * @param {string} props.mission._id - The unique identifier of the mission.
 *
 * @returns {JSX.Element} The rendered button component.
 *
 * <DownloadEmptyCollectiveOwnershipFormsButton mission={mission} />
 */
const DownloadEmptyCollectiveOwnershipFormsButton = ({ mission }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();

  const downloadDocuments = async () => {
    await generatePDF({
      message,
      dispatchAPI,
      parameters: [
        {
          collection: 'Mission',
          documentId: mission._id
        }
      ],
      use_case: 'COLLECTIVE_OWNERSHIP_FORM',
      template_type: 'TEMPLATE_DOCS',
      setIsLoading,
      downloadName: t(`missions.form.collective_ownership_form`)
    });
  };

  return (
    <Button
      loading={isLoading}
      onClick={downloadDocuments}
      icon={<DownloadOutlined />}
      type="primary"
    >
      {t('missions.buttons.virgin_form')}
    </Button>
  );
};

export default DownloadEmptyCollectiveOwnershipFormsButton;

DownloadEmptyCollectiveOwnershipFormsButton.propTypes = {
  mission: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired
};
