import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table
} from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GetEnums } from '../../../../../../utils/getEnums';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/errorMessage';

const { Option } = Select;
const { TextArea } = Input;

/**
 * PrivateEnjoymentModal component.
 * @component
 * @returns {JSX.Element} The PrivateEnjoymentModal component.
 */
export const PrivateEnjoymentModal = ({ isCounsil }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { getEnums } = GetEnums();
  const { dispatchAPI } = useAuthContext();
  const [pcajpArrayEnums, setPcajpArrayEnums] = useState({});
  const [pcajpArrays, setPcajpArrays] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const { message } = useErrorMessage();

  const getMissionPCAJPArrays = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/mission/${id}?populate=pcajp_arrays&fields=pcajp_arrays`
      });
      setPcajpArrays(data.pcajp_arrays);
    } catch (error) {
      message(error);
    }
  };

  const getData = async () => {
    try {
      const enumsDatas = await getEnums({ resource: 'pcajp-arrays' });
      setPcajpArrayEnums(enumsDatas);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([getData(), getMissionPCAJPArrays()]);
      } catch (error) {
        message(error);
      }
    })();
  }, []);
  useEffect(() => {
    setDataSource(pcajpArrays[pcajpArrays.length - 1]?.data || []);
  }, [pcajpArrays]);

  const columns = [
    {
      title: t('missions.form.batch_numbers'),
      key: 'batch_numbers',
      dataIndex: ['batch_numbers', 'value'],
      sorter: true
    },
    {
      title: t('missions.form.annexes'),
      key: 'private_usages',
      dataIndex: 'private_usages',
      render: (private_usages) => (
        <Row>
          {private_usages.map((privateUsage, index) => (
            <Col span={24} key={privateUsage._id}>
              {index !== 0 && <Divider />}
              {Object.keys(privateUsage).map((key) =>
                privateUsage[key]?.value ? (
                  <Row key={key}>
                    {t(`missions.tags.${privateUsage[key]?.value}`)}
                  </Row>
                ) : (
                  ''
                )
              )}
            </Col>
          ))}
        </Row>
      ),
      width: 500
    },
    {
      title: t('missions.form.validation'),
      key: ['validation', 'value'],
      dataIndex: ['validation', 'value'],
      render: (validation, record) => {
        if (isCounsil) {
          return validation ? t(`missions.tags.${validation}`) : '';
        }
        return (
          <Form.Item name={[record._id, 'validation']}>
            <Select disabled={isCounsil}>
              {(pcajpArrayEnums?.validations || []).map((el) => (
                <Option key={el} value={el}>
                  {t(`missions.tags.${el}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
      sorter: true
    },
    {
      title: t('missions.form.remarks'),
      key: ['remarks', 'value'],
      dataIndex: ['remarks', 'value'],
      render: (remarks) =>
        !isCounsil ? (
          <Form.Item name={['remarks', 'values']}>
            <TextArea defaultValue={remarks} disabled={isCounsil} />
          </Form.Item>
        ) : (
          remarks || ''
        ),
      sorter: true
    }
  ];

  return (
    <>
      <Button
        type="primary"
        style={{ borderRadius: '10px' }}
        onClick={() => {
          setIsModalOpen(!isModalOpen);
        }}
      >
        Consulter le tableau
      </Button>
      <Modal
        title={t('missions.modals.titles.private_enjoyment')}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={1300}
        centered
        open={isModalOpen}
      >
        <Table dataSource={dataSource} columns={columns} scroll={{ y: 500 }} />
      </Modal>
    </>
  );
};

PrivateEnjoymentModal.propTypes = {
  isCounsil: PropTypes.bool
};

PrivateEnjoymentModal.defaultProps = {
  isCounsil: false
};
