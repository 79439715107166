import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Flex, Tag, Tooltip, Spin, notification } from 'antd';
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { documentTag, missionTag } from '../../utils/constants/tagColors';
import { inputDisplaySmall } from '../../utils/generateFormItem/utils/inputDisplaySmall';
import { useAuthContext } from '../../contexts/AuthContext';
import { Document } from '../../models/Document';

/**
 * Custom hook to manage the columns of a table in a user interface.
 * @hook
 * @param {Object} params - Parameters to configure the columns.
 * @param {boolean} params.showOrdersOrdered - Flag to display certain columns based on this value.
 * @param {Function} params.toggleUrgent - Function to toggle the urgency status of an order.
 * @param {Function} params.setForceRefresh - Function to force data refresh.
 *
 * @returns {Array<Object>} - Array of column configuration objects.
 */
export const useColumns = ({
  showOrdersOrdered,

  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [uploading, setUploading] = useState(false);
  const [uploadedRowId, setUploadedRowId] = useState('');

  const draggerProps = ({ id, mission }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      setUploading(true);
      try {
        setUploadedRowId(id);
        await Document.uploadDocument({
          id,
          file: objectFile.file,
          extraQuery: `?resource=orders&missionId=${mission._id}&step=${
            mission?.steps.sub || mission?.steps.principal
          }&user=${user._id}`,
          setForceRefresh,
          t
        });
      } catch (e) {
        notification.error({
          message: t('orders.notifications.upload_document_error_title'),
          description: t(
            'orders.notifications.upload_document_error_description'
          )
        });
      }
      setUploading(false);
    },
    fileList: []
  });

  return [
    {
      title: t('orders.form.cerfa_document_type'),
      key: 'cerfa_document_type',
      dataIndex: 'cerfa_document_type',
      render: (cerfa_document_type) =>
        cerfa_document_type ? cerfa_document_type.wording : '-',
      sorter: true
    },
    showOrdersOrdered && {
      key: 'urgent',
      dataIndex: 'urgent',
      render: (condition) =>
        condition ? (
          <Tooltip title={t('orders.form.urgent')}>
            <WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        ) : null
    },
    {
      title: t('orders.form.ref_doc'),
      key: 'ref_doc',
      dataIndex: 'ref_doc',
      render: (ref_doc) => ref_doc || '-'
    },
    showOrdersOrdered && {
      title: t('orders.form.file'),
      key: 'file',
      render: (record) => (
        <Flex vertical gap={8}>
          {uploading && uploadedRowId === record.document._id ? (
            <Flex justify="center">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 24
                    }}
                    spin
                  />
                }
                size="small"
              />
            </Flex>
          ) : (
            inputDisplaySmall(
              true,
              false,
              {
                ...draggerProps({
                  id: record.document._id,
                  mission: record.mission
                })
              },
              false,
              t,
              true
            )
          )}
          <Tag
            color={documentTag[record.document.status]}
            style={{ marginLeft: 8 }}
          >
            {t(`missions.tags.${record.document.status}`)}
          </Tag>
        </Flex>
      )
    },
    showOrdersOrdered && {
      hidden: true,
      title: t('orders.form.status'),
      key: 'status',
      dataIndex: ['document', 'status'],
      render: (status) => (
        <Tag color={documentTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      ),
      sorter: true
    },
    {
      title: t('orders.form.syndic'),
      key: 'syndic',
      dataIndex: ['mission', 'syndic'],
      render: (syndic) =>
        syndic ? `${syndic.society_name} (${syndic.code})` : '-',
      sorter: true
    },
    {
      title: t('orders.form.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: ['mission', 'collective_ownership'],
      render: (collective_ownership) =>
        collective_ownership
          ? [
              collective_ownership?.code,
              collective_ownership?.name,
              `${collective_ownership?.first_address?.street || '-'} ${
                collective_ownership?.first_address?.postal_code || '-'
              } ${collective_ownership?.first_city?.name || '-'}`
            ].join(' - ')
          : '-',
      sorter: true
    },
    !showOrdersOrdered
      ? {
          title: t('orders.form.mission'),
          key: 'mission',
          dataIndex: 'mission',
          render: ({ type }) => (
            <Tag color={missionTag[type]}>
              {t(`missions.tags.${type}`, {
                defaultValue: type
              })}
            </Tag>
          ),
          sorter: true
        }
      : {
          title: t('orders.form.request_date'),
          key: 'request_date',
          dataIndex: 'request_date',
          render: (request_date) =>
            request_date ? dayjs(request_date).format('DD-MM-YYYY') : '-',
          sorter: true
        },
    showOrdersOrdered && {
      title: t('orders.form.reception_date'),
      key: 'reception_date',
      dataIndex: 'reception_date',
      render: (reception_date) =>
        reception_date ? dayjs(reception_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    !showOrdersOrdered && {
      hidden: true,
      title: t('orders.form.added_date'),
      key: 'added_date',
      dataIndex: 'added_date',
      render: (added_date) =>
        added_date ? dayjs(added_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('orders.form.concerned_SPF'),
      key: 'concerned_SPF',
      dataIndex: 'concerned_SPF',
      render: (concerned_SPF) => (concerned_SPF ? concerned_SPF.name : '-'),
      sorter: true
    },
    showOrdersOrdered && {
      title: t('orders.form.cerfa_number'),
      key: 'cerfa_number',
      dataIndex: 'cerfa_number',
      render: (cerfa_number) => cerfa_number || '-',
      sorter: true
    },
    {
      title: t('orders.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (costs) => (costs ? `${costs} €` : '-'),
      sorter: true
    },
    showOrdersOrdered && {
      title: t('orders.form.cheque'),
      key: 'cheque_number',
      dataIndex: 'cheque_number',
      render: (cheque_number) => cheque_number || '-',
      sorter: true
    },
    showOrdersOrdered && {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Link
          to={{
            pathname: `export/${record?._id}`
          }}
        >
          <Tooltip title={t('orders.form.tooltips.export')}>
            <VerticalAlignBottomOutlined style={{ fontSize: 18 }} />
          </Tooltip>
        </Link>
      )
    }
  ];
};
