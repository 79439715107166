import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { CreateMessage } from './CreateMessage';
import { ShowMessages } from './ShowMessages';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { useMessageContext } from '../../contexts/MessageContext';

/**
 * Custom hook for managing items in the Messages component.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {string} options.missionType - The mission type.
 * @param {string} options.syndic - The syndic.
 * @param {string} options.collective_ownership - The collective ownership.
 * @param {string} options.cardTitle - The card title.
 * @param {boolean} options.withCreateButton - Flag indicating if the create button should be hidden.
 * @returns {Array} - The array of items.
 */
export const useItems = ({
  missionType,
  syndic,
  collective_ownership,
  cardTitle,
  withCreateButton,
  titleClassName
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const { isCreateMessageCardOpen } = useGlobalContext();
  const { activeCard } = useMessageContext();

  return [
    {
      key: '1',
      label: cardTitle ? (
        <Text className={titleClassName}>{cardTitle}</Text>
      ) : (
        <Text className={titleClassName}>{t('messages.title')}</Text>
      ),
      children: (
        <>
          {isCreateMessageCardOpen && !activeCard ? (
            <CreateMessage
              missionType={missionType}
              syndic={syndic}
              collective_ownership={collective_ownership}
            />
          ) : null}
          <ShowMessages withCreateButton={withCreateButton} />
        </>
      )
    }
  ];
};
