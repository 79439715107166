import { Collapse, Skeleton, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * @component
 * @description Component that renders the history of the application.
 * The history is a list of actions that have been performed by the user.
 * The history is displayed in a timeline.
 * @param {boolean} isLoading - Indicates whether the history is currently loading.
 * @param {Array} historic - The array of historic actions.
 * @returns {JSX.Element} A Card component with a Timeline component inside.
 */
export const CollapseMissionHistory = ({ isLoading, historic }) => {
  const { t } = useTranslation();
  const sortedHistoric = historic?.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const handleColor = (type) => {
    switch (type) {
      case 'PATCH':
        return 'orange';
      case 'POST':
        return 'blue';
      case 'DELETE':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <Collapse
      expandIconPosition="end"
      items={[
        {
          key: '1',
          label: t('missions.cards.history.title'),
          children: (
            <Skeleton loading={isLoading} active>
              <Timeline
                mode="left"
                items={(sortedHistoric || []).map((item) => ({
                  label: (
                    <>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 'semibold'
                        }}
                      >
                        {dayjs(item.date).format('DD-MM-YYYY')}
                      </Text>
                      <br />
                      <Text
                        style={{
                          fontSize: 12,
                          color: 'gray'
                        }}
                      >
                        {dayjs(item.date).format('HH[h]mm')}
                      </Text>
                    </>
                  ),
                  color: handleColor(item.type),
                  children: (
                    <>
                      {item.description ? (
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: 'semibold'
                          }}
                        >
                          {t(`historics.tags.${item.description}`, {
                            doc_type: item?.doc_type
                          })}
                        </Text>
                      ) : (
                        ''
                      )}
                      <br />
                      {item?.steps && (
                        <>
                          <Text
                            style={{
                              fontSize: 12,
                              color: 'gray'
                            }}
                          >
                            {t('historics.labels.phase')}{' '}
                            {t(`missions.steps.${item?.steps?.principal}`)}
                          </Text>
                          {item?.steps?.sub && ' - '}
                          {item?.steps?.sub && (
                            <Text
                              style={{
                                fontSize: 12,
                                color: 'gray'
                              }}
                            >
                              {t(`mission.steps.${item?.steps?.sub}`)}
                            </Text>
                          )}
                          <br />
                        </>
                      )}

                      <Text
                        style={{
                          fontSize: 12,
                          color: 'gray'
                        }}
                      >
                        {t('historics.labels.author')} {item.by?.first_name}{' '}
                        {item.by?.last_name}{' '}
                        {item.by?.role &&
                          `(${t(
                            `users.tags.${item.by?.role?.split(':')[1]}`
                          )})`}
                      </Text>
                    </>
                  )
                }))}
              />
            </Skeleton>
          )
        }
      ]}
    />
  );
};

CollapseMissionHistory.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  historic: PropTypes.arrayOf(PropTypes.shape({}))
};

CollapseMissionHistory.defaultProps = {
  historic: []
};
