import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { ExtraSearchBars } from './ExtraSearchBars';
import { User } from '../../../models/User';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Renders a list of pcs pre productions with custom actions and search functionality.
 * @component
 * @param {Object} props The props object containing the status of the pcs pre productions.
 * @param {string} props.status The status of the pcs pre productions.
 * @returns {JSX.Element} The rendered ListPcsPreProductions component.
 */
export const ListPcsPreProductions = ({ status }) => {
  const resourceName = 'pcs-pre-productions';
  const [isFiedlLoading, setIsFieldLoading] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const { user } = useAuthContext();
  const createPermissions = !['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(
    user.role
  );
  const [filters, setFilters] = useState('');
  const [consultants, setConsultants] = useState([]);
  const columns = useColumns({
    forceRefresh,
    setForceRefresh
  });

  useEffect(() => {
    (async () => {
      const consultantsData = await User.getResources({
        extraQuery: 'role=users:CONSULTANT'
      });
      setConsultants(consultantsData);
      setIsFieldLoading(false);
    })();
  }, []);

  return (
    <ListResource
      forceRefresh={forceRefresh}
      style={{ margin: '0' }}
      resourceName={resourceName}
      columns={columns}
      extraQuery={`status=${status || ''}&${filters}`}
      withCreateButton={createPermissions}
      resourceModelName="Pcs-pre-production"
      withPageHeader={false}
      customActionColumn
      exportTemplateUrl={`template-documents/template/${resourceName}`}
      sheetName={resourceName}
      searchBarExtraButtons={
        <ExtraSearchBars
          setFilters={setFilters}
          consultants={consultants}
          isFiedlLoading={isFiedlLoading}
        />
      }
    />
  );
};

ListPcsPreProductions.propTypes = {
  status: PropTypes.string
};

ListPcsPreProductions.defaultProps = {
  status: ''
};
