import dayjs from 'dayjs';
import { constants } from './constants';

/**
 * @name adjustTimeSlots
 * @description Function to adjust the time slots based on the selected date
 * @param {object} data - The data object containing the date and time slots
 * @return {object} - The adjusted data object
 */
const adjustTimeSlots = (data) => {
  const selectedDate = data.new_date
    ? dayjs(data.new_date.$d)
    : dayjs(data.date.$d);
  const startTime = data.new_time_slots
    ? dayjs(data.new_time_slots[0].$d)
    : dayjs(data.time_slots[0].$d);
  const endTime = data.new_time_slots
    ? dayjs(data.new_time_slots[1].$d)
    : dayjs(data.time_slots[1].$d);

  const adjustedStartTime = selectedDate
    .hour(startTime.hour())
    .minute(startTime.minute());
  const adjustedEndTime = selectedDate
    .hour(endTime.hour())
    .minute(endTime.minute());

  const time_slots = {
    start: adjustedStartTime,
    end: adjustedEndTime
  };

  return time_slots;
};

/**
 * Define the color of the event.
 * @function
 * @param {Object} event - The event.
 * @returns {String} The color of the event.
 */
const defineColor = (event) => {
  const dateNow = new Date();
  const endTime = adjustTimeSlots(event).end;
  const to_replan = event.to_replan ?? false;

  if (dateNow > endTime && !to_replan) {
    return constants.COLORS.GREEN;
  }

  if ((dateNow > endTime && to_replan) || to_replan) {
    return constants.COLORS.ORANGE;
  }

  return constants.COLORS.BLUE;
};

/**
 * Create an intern event.
 * @function
 * @param {Object} user - The user.
 * @param {Object} data - The data.
 * @param {String} eventId - The event ID.
 * @param {String} method - The method.
 * @param {String} userTarget - The user target.
 * @returns {Object} The intern event.
 */
const createInternEvent = (user, data, eventId, method, userTarget) => {
  const { title = constants.TRANSLATION.INTERN } = data;
  const newEvent = {
    title: title.trim() === '' ? constants.TRANSLATION.INTERN : title,
    user: user._id,
    userTarget: userTarget?._id,
    userTargetRole: userTarget?.role,
    type: constants.INTERN,
    date: dayjs(data.date.$d),
    time_slots: adjustTimeSlots(data),
    backgroundColor: defineColor(data)
  };

  if (method === 'create') {
    if (userTarget?._id === user._id) {
      delete newEvent.userTarget;
    }
  }

  if (eventId && method === 'update') {
    newEvent._id = eventId;
  }

  return newEvent;
};

/**
 * Create an unavailability event.
 * @function
 * @param {Object} user - The user.
 * @param {Object} data - The data.
 * @param {String} eventId - The event ID.
 * @param {String} method - The method.
 * @param {String} userTarget - The user target.
 * @returns {Object} The unavailability event.
 */
const createUnavailabilityEvent = (user, data, eventId, method, userTarget) => {
  const { title = constants.TRANSLATION.UNAVAILABILITY } = data;

  const time_slots = {
    start: dayjs(data.time_slots[0].$d),
    end: dayjs(data.time_slots[1].$d)
  };

  const newEvent = {
    title,
    user: user._id,
    userRole: user.role,
    userTarget: userTarget?._id,
    userTargetRole: userTarget?.role,
    type: constants.UNAVAILABILITY,
    time_slots,
    backgroundColor: constants.COLORS.GRAY
  };

  if (method === 'create') {
    if (userTarget?._id === user._id) {
      delete newEvent.userTarget;
    }
  }

  if (eventId && method === 'update') {
    newEvent._id = eventId;
  }

  return newEvent;
};

/**
 * Create an PCS appointment event.
 * @function
 * @param {Object} user - The user.
 * @param {Object} data - The data.
 * @param {String} eventId - The event ID.
 * @param {String} method - The method.
 * @param {String} userTarget - The user target.
 * @param {Object} syndic - The syndic.
 * @returns {Object} The PCS appointment event.
 */
const createPCSAppointmentEvent = (
  user,
  data,
  eventId,
  method,
  userTarget,
  syndic
) => {
  const { to_replan } = data;
  const title = data.title ? data.title : syndic.society_name;

  const newEvent = {
    title,
    user: user._id,
    userRole: user.role,
    userTarget: userTarget?._id,
    userTargetRole: userTarget?.role,
    type: constants.PCS_APPOINTMENT,
    date: data.new_date ? dayjs(data.new_date) : dayjs(data.date.$d),
    time_slots: data.new_time_slots
      ? adjustTimeSlots(data)
      : adjustTimeSlots(data),
    to_replan: to_replan ?? false,
    backgroundColor: defineColor(data)
  };

  if (method === 'create') {
    if (userTarget?._id === user._id) {
      delete newEvent.userTarget;
    }
  }

  if (eventId && method === 'update') {
    newEvent._id = eventId;
  }

  return newEvent;
};

export const useConfig = ({
  eventType,
  user,
  eventId,
  userTarget,
  syndic,
  manager,
  pcs_preproduction,
  form,
  setSyndic
}) => ({
  onCreateResource: {
    setBody: (data) => {
      switch (eventType) {
        case constants.INTERN:
          return {
            ...data,
            ...createInternEvent(user, data, eventId, 'create', userTarget)
          };
        case constants.UNAVAILABILITY:
          return {
            ...data,
            ...createUnavailabilityEvent(
              user,
              data,
              eventId,
              'create',
              userTarget
            )
          };
        case constants.PCS_APPOINTMENT:
          return {
            ...data,
            ...createPCSAppointmentEvent(
              user,
              data,
              eventId,
              'create',
              userTarget,
              syndic,
              manager,
              pcs_preproduction
            )
          };
        default:
          throw new Error('Event type not found');
      }
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      switch (eventType) {
        case constants.INTERN:
          return {
            ...data,
            ...createInternEvent(user, data, eventId, 'update')
          };
        case constants.UNAVAILABILITY:
          return {
            ...data,
            ...createUnavailabilityEvent(
              user,
              data,
              eventId,
              'update',
              userTarget
            )
          };
        case constants.PCS_APPOINTMENT:
          return {
            ...data,
            ...createPCSAppointmentEvent(user, data, eventId, 'update')
          };
        default:
          throw new Error('Event type not found');
      }
    }
  },
  onGetResource: {
    setFields: (data) => {
      let updatedData = {};
      switch (data.type) {
        case constants.PCS_APPOINTMENT:
          setSyndic(data.syndic);

          updatedData = {
            ...data,
            syndic: data.syndic._id,
            manager: data.manager._id,
            pcs_preproduction: data.pcs_preproduction._id,
            date: dayjs(data.date) || null,
            mission: data.pcs_preproduction,
            time_slots: [
              dayjs(data.time_slots.start),
              dayjs(data.time_slots.end)
            ]
          };

          break;
        case constants.INTERN:
          updatedData = {
            ...data,
            date: dayjs(data.date) || null,
            time_slots: [
              dayjs(data.time_slots.start),
              dayjs(data.time_slots.end)
            ],
            remote_connection_support: data.remote_connection_support
          };

          break;
        case constants.UNAVAILABILITY:
          updatedData = {
            ...data,
            date: dayjs(data.date, 'YYYY-MM-DD') || null
          };
          break;
        default:
          break;
      }
      form.setFieldsValue(updatedData);

      return updatedData;
    }
  }
});
