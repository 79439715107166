import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Row, Col, message as antdMessage } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  StopOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { deleteResource } from '../../../utils/deleteResource';
import { routes } from '../../../utils/constants/routes/adminRoutes';
import { blockUser } from './blockUser';

export const ShowButtons = ({ user, setForceRefresh, forceRefresh }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, user: userFromContext } = useAuthContext();
  const showBlockButton = ![
    'users:SYNDIC_MANAGER',
    'users:SYNDIC_ASSISTANT'
  ].includes(userFromContext?.role);

  const handleConfirm = async () => {
    await blockUser({
      dispatchAPI,
      id: user._id,
      setForceRefresh,
      forceRefresh,
      message,
      value:
        user?.access === 'NOT_BLOCKED'
          ? { access: 'BLOCKED' }
          : { access: 'NOT_BLOCKED' }
    });
    antdMessage.success(
      t(
        `datatable.column.action.user.${
          user?.access === 'NOT_BLOCKED' ? 'BLOCKED' : 'NOT_BLOCKED'
        }.success`,
        {
          user: `${user?.first_name} ${user?.last_name}`
        }
      )
    );
  };

  return (
    <Row gutter={[10]}>
      <Col>
        <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
          <Button type="primary">
            {`${t('buttons.edit')} `}
            <EditOutlined />
          </Button>
        </Link>
      </Col>
      <Col>
        <Popconfirm
          title={t(`datatable.column.action.user.${user?.access}.title`)}
          okText={t(`datatable.column.action.user.${user?.access}.ok`)}
          okButtonProps={{ type: 'danger' }}
          cancelText={t(`datatable.column.action.user.${user?.access}.cancel`)}
          onConfirm={handleConfirm}
          icon={<WarningOutlined />}
        >
          {showBlockButton &&
            (user?.access === 'NOT_BLOCKED' ? (
              <Button type="primary">
                <StopOutlined style={{ fontSize: 18 }} />
                {t('users.buttons.block_access')}
              </Button>
            ) : (
              <Button type="primary">
                <CheckCircleOutlined style={{ fontSize: 18 }} />
                {t('users.buttons.unblock_access')}
              </Button>
            ))}
        </Popconfirm>
      </Col>
      <Col>
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            deleteResource({
              dispatchAPI,
              resourceName: 'users',
              id,
              message,
              navigate
            })
          }
          icon={<WarningOutlined />}
        >
          <Button type="link" danger>
            {`${t('buttons.delete')} `}
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
};

ShowButtons.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    access: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired
};

ShowButtons.defaultProps = {
  user: {}
};
