import { Link } from 'react-router-dom';
import { Popconfirm, Divider } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { deleteResource } from '../../../utils/deleteResource';

/**
 * Returns an array of columns configuration for a price system table.
 *
 * @param {Object} options - The options object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {string} options.message - The message string.
 * @param {Function} options.setForceRefresh - The setForceRefresh function.
 * @param {boolean} options.forceRefresh - The forceRefresh flag.
 * @param {string} options.missionType - The mission string.
 * @param {Object} options.actualPriceColor - The actualPriceColor object.
 * @param {Object} options.t - The translation object.
 * @returns {Array} An array of column configuration objects.
 */

export const useColumns = ({
  dispatchAPI,
  message,
  setForceRefresh,
  forceRefresh,
  missionType,
  actualPriceColor,
  t
}) => [
  {
    title: t('price-systems.form.start_date'),
    key: 'start_date',
    dataIndex: 'start_date',
    render: (start_date) =>
      start_date && dayjs(start_date).format('DD/MM/YYYY'),
    sorter: true
  },
  {
    title: t('price-systems.form.end_date'),
    key: 'end_date',
    dataIndex: 'end_date',
    render: (end_date) =>
      end_date ? dayjs(end_date).format('DD/MM/YYYY') : '-',
    sorter: true
  },
  {
    title: t('price-systems.form.actual_price'),
    key: 'actual_price',
    render: (record) => (
      <div
        style={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: actualPriceColor[record._id]
        }}
      />
    )
  },
  {
    title: t('price-systems.form.wording'),
    key: 'wording',
    dataIndex: 'wording',
    sorter: true
  },
  {
    title:
      missionType === 'PCS_1'
        ? t('price-systems.form.amount_ht_without_documents')
        : t('price-systems.form.amount_ht'),
    key: 'amount_ht',
    render: (record) =>
      missionType === 'PCS_1'
        ? `${record.amount_ht_without_documents} €`
        : `${record.amount_ht} €`,
    sorter: true
  },
  ...(['RCP_RESEARCH', 'PLANS_RESEARCH'].includes(missionType)
    ? []
    : [
        {
          title: t('price-systems.form.lawyer_ht'),
          key: 'lawyer_ht',
          dataIndex: 'lawyer_ht',
          render: (lawyer_ht) => (lawyer_ht ? `${lawyer_ht} €` : '-'),
          sorter: true
        }
      ]),
  {
    title: t('price-systems.form.tva'),
    key: 'tva',
    dataIndex: 'tva',
    render: (tva) => (tva ? `${tva} %` : '-'),
    sorter: true
  },
  {
    key: 'action',
    align: 'right',
    fixed: 'right',
    render: (record) => (
      <>
        <Link
          to={{
            pathname: `/configurations/price-systems/edit/${record?._id}/${missionType}`
          }}
        >
          <EditOutlined style={{ fontSize: 18 }} />
        </Link>
        <Divider type="vertical" />
        {!record?.default && (
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() =>
              deleteResource({
                dispatchAPI,
                resourceName: 'price-systems',
                id: record._id,
                setForceRefresh,
                forceRefresh,
                message
              })
            }
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'var(--errorColor)', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        )}
      </>
    )
  }
];
