import { BaseModel } from './BaseModel';
import { updateHistory } from '../utils/updateHistory';
import { PcsPreProduction } from './PcsPreProduction';

/**
 * Represents an collection.
 * @class
 */
export class Collection extends BaseModel {
  static resourceName = 'collections';

  /**
   * Asynchronously updates the status of an entity within a collection. This method sends a PATCH request
   * to a specified URL constructed using the collection's resource name. The new status values are passed
   * in the request body. After the status update, it toggles a forceRefresh flag to trigger any necessary
   * UI updates or data refetching.
   *
   * @function
   * @async
   * @param {Object} params - The parameters for updating the status.
   * @param {Object} params.values - The new status values to be updated. This object is sent in the request body.
   * @param {Function} params.setForceRefresh - A function to toggle the forceRefresh flag.
   * @param {boolean} params.forceRefresh - The current state of the forceRefresh flag, used to determine its new state.
   * @param {string} params.extraQuery - Additional query parameters to be appended to the request URL.
   * @param {Function} params.setSelectedRowKeys - A function to set the selected row keys.
   * @param {Function} params.setSelectedCollectiveOwnerships - A function to set the selected collective ownerships.
   * @param {Function} params.setIsValidateDeleteModalOpen - A function to set the state of the validate delete modal.
   * @returns {Promise<Object>} A promise that resolves with the response data from the status update request.
   * @throws {Error} Throws an error if the request fails.
   */

  static async updateStatus({
    values,
    setForceRefresh,
    forceRefresh,
    extraQuery,
    setSelectedRowKeys,
    setSelectedCollectiveOwnerships,
    setIsValidateDeleteModalOpen,
    user
  }) {
    const { data } = await this.dispatchAPI('PATCH', {
      url: `${Collection.resourceName}/update-status?${extraQuery}`,
      body: values
    });

    const response = await this.dispatchAPI('GET', {
      url: `collective-ownerships/${values[0]._id}`
    });

    if (response.data) {
      const collective_ownership = response.data;
      updateHistory({
        dispatchAPI: this.dispatchAPI,
        id: collective_ownership._id,
        resourceName: PcsPreProduction.resourceName,
        values: {
          date: new Date(),
          description: 'collection_validation',
          by: user._id,
          step: 'COLLECTION_VALIDATION',
          pcs_preproduction_uuid: collective_ownership.pcs_preproduction_uuid
        }
      });
    }

    setForceRefresh(!forceRefresh);

    if (setSelectedRowKeys) setSelectedRowKeys([]);
    if (setSelectedCollectiveOwnerships) setSelectedCollectiveOwnerships([]);
    if (setIsValidateDeleteModalOpen)
      setIsValidateDeleteModalOpen({ open: false, type: '' });

    return data;
  }
}
