import React from 'react';
import { Row, Button, Col } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { generateCerfa } from '../../../../../utils/generateCerfa';
import { handleStockCerfaActions } from '../../../../../utils/changeOrdersStockCerfaStatus';

/**
 * ExtraButtons component that renders a set of buttons for performing actions on selected stock documents.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setForceRefresh - Function to toggle the state for forcing a refresh.
 * @param {boolean} props.isShowExtraButtons - Flag to determine whether to show the extra buttons.
 * @param {Array} props.selectedStockDoc - Array of selected cerfa stock documents to perform actions on.
 * @returns {JSX.Element} The rendered component.
 */
const ExtraButtons = ({
  setForceRefresh,
  isShowExtraButtons,
  selectedStockDoc,
  setIsCerfaOrderDrawerOpen,
  setCheques,
  selectedOrders
}) => {
  const { t } = useTranslation();

  const toggleCerfaOrderDrawer = () => {
    setIsCerfaOrderDrawerOpen((prev) => !prev);
    setCheques(generateCerfa(selectedOrders));
  };

  return (
    <Row gutter={[16, 16]}>
      {isShowExtraButtons && (
        <>
          <Col span={3.5}>
            <Button
              type="primary"
              onClick={() => {
                handleStockCerfaActions('TO_VALIDATE', selectedStockDoc);
                setForceRefresh((prev) => !prev);
              }}
            >
              {`${t('dashboard.cerfa_stock.buttons.mark_as')} "${t(
                'dashboard.cerfa_stock.tags.TO_VALIDATE'
              ).toLowerCase()}"`}
            </Button>
          </Col>
          <Col span={3.5}>
            <Button
              type="primary"
              onClick={() => {
                handleStockCerfaActions('TO_PROCESS', selectedStockDoc);
                setForceRefresh((prev) => !prev);
              }}
            >
              {`${t('dashboard.cerfa_stock.buttons.mark_as')} "${t(
                'dashboard.cerfa_stock.tags.TO_PROCESS'
              ).toLowerCase()}"`}
            </Button>
          </Col>
          <Col span={3.5}>
            <Button
              type="primary"
              icon={
                <PrinterOutlined style={{ color: 'white', fontSize: 16 }} />
              }
              onClick={() => {
                toggleCerfaOrderDrawer();
                setForceRefresh((prev) => !prev);
              }}
            >
              {t('dashboard.cerfa_stock.buttons.command_and_print')}
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};

ExtraButtons.propTypes = {
  setForceRefresh: PropTypes.func.isRequired,
  isShowExtraButtons: PropTypes.bool.isRequired,
  selectedStockDoc: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired
    })
  ).isRequired,
  setIsCerfaOrderDrawerOpen: PropTypes.func.isRequired,
  setCheques: PropTypes.func.isRequired,
  selectedOrders: PropTypes.arrayOf(PropTypes.shape({}))
};

ExtraButtons.defaultProps = {
  selectedOrders: []
};

export default ExtraButtons;
