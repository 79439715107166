import { PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  InputNumber,
  notification,
  Row,
  Select
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CollectiveOwnership } from '../../../../../models/CollectiveOwnership';
import { handlePlansOrderSubmit } from '../utils/plansResearchHandler';

const { Item } = Form;
const { Option } = Select;

/**
 * Renders a drawer component for ordering plans in a mission.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Indicates whether the drawer is open or not.
 * @param {function} props.setOpen - A function to set the open state of the drawer.
 * @param {function} props.dispatchAPI - A function to dispatch API actions.
 * @param {Object} props.mission - The mission object.
 * @param {function} props.setForceRefresh - A function to set the force refresh state.
 * @param {Object} props.user - The user object.
 * @param {boolean} props.forceRefresh - Indicates whether to force refresh the component.
 * @param {function} props.setIsLoading - A function to set the loading state.
 * @returns {JSX.Element} The rendered component.
 */
export const PlansOrderDrawer = ({
  open,
  setOpen,
  mission,
  setForceRefresh,
  forceRefresh,
  setIsLoading
}) => {
  const { t } = useTranslation();

  const [isPLansLoading, setIsPlansLoading] = useState(false);
  const [plans, setPlans] = useState();
  const missionId = mission._id;
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      if (mission.collective_ownership?._id) {
        const collective_ownership =
          mission.collective_ownership?._id || mission.collective_ownership;
        setIsPlansLoading(true);
        const data = await CollectiveOwnership.getPlansByRef({
          collective_ownership,
          missionId,
          status: 'TO_BE_ORDERED'
        });
        setPlans(data);
        setIsPlansLoading(false);
      }
    })();
    form.resetFields();
  }, [open]);

  return (
    <Drawer
      title={t('missions.drawers.plans_orders.title')}
      placement="right"
      closable
      onClose={() => setOpen(false)}
      open={open}
      width={500}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={async (values) => {
          await handlePlansOrderSubmit({
            values,
            missionId,
            setForceRefresh,
            forceRefresh,
            setIsLoading,
            setOpen,
            t
          });
          notification.success({
            message: t('missions.messages.order_saved'),
            description: t('missions.messages.order_plan_saved'),
            placement: 'topRight'
          });
          return true;
        }}
      >
        <Item
          style={{ width: '100%' }}
          name="plan"
          label={t('missions.drawers.plans_orders.form.select_plan')}
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select loading={isPLansLoading}>
            {(plans || []).map((plan) => {
              const realization_date = dayjs(plan.realization_date).format(
                'DD-MM-YYYY'
              );
              const transcription_date = dayjs(plan.transcription_date).format(
                'DD-MM-YYYY'
              );
              return (
                <Option
                  key={plan._id}
                  value={`${plan.plan_volume}-${plan.plan_number}`}
                >
                  {plan.notary_writer?.first_name}{' '}
                  {plan.notary_writer?.last_name} - {realization_date} -{' '}
                  {transcription_date}{' '}
                  {`vol. ${plan.plan_volume} n°${plan.plan_number}`}
                </Option>
              );
            })}
          </Select>
        </Item>
        <Item
          style={{ width: 200 }}
          name="costs"
          label={t('missions.drawers.plans_orders.form.costs')}
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="€" />
        </Item>
        <Item
          style={{ width: '100%' }}
          name="cheque_number"
          label={t('missions.drawers.plans_orders.form.cheque_number')}
          rules={[
            {
              required: true
            }
          ]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Item>
        <Item style={{ width: 200 }} name="urgent" valuePropName="checked">
          <Checkbox>{t('missions.drawers.plans_orders.form.urgent')}</Checkbox>
        </Item>
        <Row justify="end" gutter={[16, 16]}>
          <Col span={6}>
            <Button icon={<PrinterOutlined />} type="primary" htmlType="submit">
              {t('missions.buttons.print')}
            </Button>
          </Col>
          <Col span={5}>
            <Button onClick={() => setOpen(false)}>
              {t('missions.buttons.cancel')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

PlansOrderDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  mission: PropTypes.shape({
    _id: PropTypes.string,
    collective_ownership: PropTypes.string
  }),
  setForceRefresh: PropTypes.func,
  user: PropTypes.shape({
    _id: PropTypes.string
  }),
  forceRefresh: PropTypes.bool,
  setIsLoading: PropTypes.func
};

PlansOrderDrawer.defaultProps = {
  open: false,
  setOpen: () => {},
  mission: {},
  setForceRefresh: () => {},
  user: {},
  forceRefresh: false,
  setIsLoading: () => {}
};
