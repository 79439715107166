import { Col, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';

const { Text } = Typography;

/**
 * Renders the buttons for collective ownerships.
 * @component
 * @param {Object} importDoc - The import document.
 * @param {Array} dataSource - The data source for the buttons.
 * @returns {JSX.Element} - The rendered buttons.
 */
export const CollectiveOwnershipsButtons = ({ importDoc, dataSource }) => {
  const { downloadDocument } = useDownloadDocument();
  const { t } = useTranslation();

  return (
    <>
      <Col span={6}>
        <Button
          onClick={() =>
            downloadDocument({
              _id: importDoc.file._id,
              metadata: importDoc.file.metadata,
              contentType: importDoc.file.contentType
            })
          }
        >
          <DownloadOutlined />
          {t('buttons.download_file')}
        </Button>
      </Col>
      <Col span={6}>
        <Text>
          {t('pcs-pre-productions.show.valid_copro_number', {
            number: dataSource.filter((el) => el.status === 'VALIDATED').length,
            total: dataSource.length
          })}
        </Text>
      </Col>
    </>
  );
};

CollectiveOwnershipsButtons.propTypes = {
  importDoc: PropTypes.shape({
    file: PropTypes.shape({
      _id: PropTypes.string,
      metadata: PropTypes.shape({}),
      contentType: PropTypes.string
    }),
    fileKey: PropTypes.string
  }),
  dataSource: PropTypes.arrayOf(PropTypes.shape({}))
};

CollectiveOwnershipsButtons.defaultProps = {
  importDoc: {},
  dataSource: []
};
