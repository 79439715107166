import { LeftOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import RechercheRCP_INFO from '../../../assets/images/RechercheRCP_INFO.svg';
import RechercheRCP_1 from '../../../assets/images/Recherche de documentation RCP 1.svg';
import { rcpTitles } from './utils/rcpTitles';

/**
 * @component
 * Renders a list of collective ownerships for a syndic.
 * @returns {JSX.Element} The rendered component.
 */
export const RcpResearchInfo = () => {
  const { title, subtitle, title2, subtitle2, title3, subtitle3, cardDesign } =
    rcpTitles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem'
      }}
    >
      <Card style={cardDesign}>
        <Row gutter={16} align="middle">
          <Col span={1}>
            <LeftOutlined style={{ fontSize: '24px' }} />
          </Col>
          <Col span={18}>
            <Row style={{ marginBottom: '8px' }}>{title}</Row>
            <Row>{subtitle}</Row>
          </Col>
          <Col span={4}>
            <img src={RechercheRCP_INFO} alt="logo" />
          </Col>
        </Row>
      </Card>
      <Card style={cardDesign}>
        <Row gutter={16} align="middle">
          <Col span={4}>
            <img src={RechercheRCP_1} alt="logo" />
          </Col>
          <Col span={15}>
            <Row style={{ marginBottom: '8px' }}>{title2}</Row>
            <Row>{subtitle2}</Row>
          </Col>
        </Row>
      </Card>
      <Card style={cardDesign}>
        <Row gutter={16} align="middle">
          <Col span={19}>
            <Row style={{ marginBottom: '8px' }}>{title3}</Row>
            <Row>{subtitle3}</Row>
          </Col>
          <Col span={4}>
            <img src={RechercheRCP_1} alt="logo" />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
