import { Route, Routes } from 'react-router-dom';
import { PriceSystemRouter } from './price-system/PriceSystemRouter';
import { DocumentRepository } from './document-repository/DocumentRepository';
import { MunicipalityRouter } from './municipality/MunicipalityRouter';
import { SpfDepartmentalArchiveRouter } from './spf-departmental-archive/SpfDepartmentalArchiveRouter';
import { CerfaDocuments } from './cerfa-documents/CerfaDocuments';
import { VariousDocuments } from './various-documents/VariousDocuments';
import { RequestedDocuments } from './requested-documents/RequestedDocuments';
import { QuoteConfigurations } from './quote-configurations/QuoteConfigurations';
import { Reminders } from './reminders/Reminders';
import { Accountings } from './accountings/Accountings';
import { NotaryConfigurationRouter } from './notary-configurations/NotaryConfigurationRouter';
import { CustomerServiceManagerMeetings } from './customer-service-manager-meetings/CustomerServiceManagerMeetings';
import { TemplateDocRouter } from './template-docs/TemplateDocsRouter';
import { TemplateEmailRouter } from './template-emails/TemplateEmailRouter';
import { FaqTemplateRouter } from './Faq-templates/FaqTemplateRouter';
import { FaqTemplateRouterPublic } from './Faq-templates-public/FaqTemplateRouterPublic';
import { PredefinedMessageRouter } from './predefined-messages/PredefinedMessageRouter';
import { ListDuplicates } from './utils/mergeComponents/ListDuplicates';
import { MergeDuplicates } from './utils/mergeComponents/MergeDuplicates';
import { ConfigurationProvider } from '../../contexts/ConfigurationContext';
import { Exception } from '../../components';

/**
 * Renders the dynamic configuration router component.
 * @returns {JSX.Element} The dynamic configuration router component.
 */
export const DynamicConfigurationRouter = () => (
  <ConfigurationProvider>
    <Routes>
      <Route path="/price-systems/*" element={<PriceSystemRouter />} />
      <Route path="/municipalities/*" element={<MunicipalityRouter />} />
      <Route
        path="/spf-departmental-archives/*"
        element={<SpfDepartmentalArchiveRouter />}
      />
      <Route path="/document-repositories" element={<DocumentRepository />} />
      <Route path="/cerfa-documents" element={<CerfaDocuments />} />
      <Route path="/various-documents" element={<VariousDocuments />} />
      <Route path="/requested-documents" element={<RequestedDocuments />} />
      <Route path="/quote-configurations" element={<QuoteConfigurations />} />
      <Route path="/reminders" element={<Reminders />} />
      <Route path="/accountings" element={<Accountings />} />
      <Route
        path="/notary-configurations/*"
        element={<NotaryConfigurationRouter />}
      />
      <Route
        path="/customer-service-manager-meetings"
        element={<CustomerServiceManagerMeetings />}
      />
      <Route path="/template-documents/*" element={<TemplateDocRouter />} />
      <Route path="/template-emails/*" element={<TemplateEmailRouter />} />
      <Route path="/faq-templates/*" element={<FaqTemplateRouter />} />
      <Route
        path="/faq-templates-public/*"
        element={<FaqTemplateRouterPublic />}
      />
      <Route
        path="/predefined-messages/*"
        element={<PredefinedMessageRouter />}
      />
      <Route path="/duplicate/:resource" element={<ListDuplicates />} />
      <Route
        path="/merge/:firstId/:secondId/:resource"
        element={<MergeDuplicates />}
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ConfigurationProvider>
);
