import { useState, useEffect } from 'react';
import { Select, Input } from 'antd';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom hook that handles the fields and logic for the ComplianceElanRouter Audit Step 5.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {moment.Moment} options.selectedDate - The selected date.
 * @param {Object} options.form - The form object.
 * @param {Function} options.refreshAppointment - The function to refresh the appointment.
 * @returns {Object} - The fields object containing the form fields.
 */
export const useFields = ({
  selectedDate,
  form,
  refreshAppointment,
  appointmentInterval
}) => {
  const { dispatchAPI } = useAuthContext();
  const [availableTimes, setAvailableTimes] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [numberOfAgents, setNumberOfAgents] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();

  const getEvents = async () => {
    try {
      const seletedDateVersions = [
        selectedDate.subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
        selectedDate.format('YYYY-MM-DD HH:mm')
      ];
      const selectedDateQuery = seletedDateVersions.join(',');

      const { data } = await dispatchAPI('GET', {
        url: `/events/roles-and-target-roles?userTargetRole=users:NOMADE_CUSTOMER_SERVICE_MANAGER&userRole=users:NOMADE_CUSTOMER_SERVICE_MANAGER&date=${selectedDateQuery}&fields=time_slots,date`
      });
      setEvents(data);
    } catch (error) {
      message(error);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `users?role=users:NOMADE_CUSTOMER_SERVICE_MANAGER&fields=exclusive_user_id`
      });
      setNumberOfAgents(data.length);
    } catch (error) {
      message(error);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await Promise.all([getEvents(), getUsers()]);
      form.setFieldsValue({ appointment_start_time: null });
    } catch (error) {
      message.error(`Error fetching data: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate, refreshAppointment]);

  const generateAllTimes = () => {
    const allTimes = [];
    const interval = appointmentInterval || 15;

    for (let hour = 7; hour < 18; hour += 1) {
      for (let minute = 0; minute < 60; minute += interval) {
        const time = dayjs()
          .set('hour', hour)
          .set('minute', minute)
          .format('HH:mm');
        allTimes.push(time);
      }
    }
    return allTimes;
  };

  const getAvailableTimes = () => {
    const allTimes = generateAllTimes();
    const timeSlotMap = {};

    allTimes.forEach((time) => {
      timeSlotMap[time] = 0;
    });

    events.forEach((event) => {
      const start = dayjs(event.time_slots.start);
      const end = dayjs(event.time_slots.end);

      for (
        let current = start;
        current.isBefore(end);
        current = current.add(15, 'minute')
      ) {
        const timeKey = current.format('HH:mm');
        if (timeSlotMap[timeKey] !== undefined) {
          timeSlotMap[timeKey] += 1;
        }
      }
    });

    const localAvailableTimes = allTimes.filter(
      (time) => timeSlotMap[time] < numberOfAgents
    );

    setAvailableTimes(localAvailableTimes);
  };

  useEffect(() => {
    getAvailableTimes();
  }, [events, numberOfAgents]);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  return {
    fields: [
      {
        label: 'select_appointment',
        name: ['appointment_start_time'],
        key: 'appointment_start_time',
        rules: [{ required: true }],
        input: (
          <Select
            loading={isLoading}
            onChange={handleTimeChange}
            value={selectedTime}
          >
            {availableTimes.map((time) => {
              const formattedValue = dayjs(
                `${selectedDate.format('YYYY-MM-DD')} ${time}`,
                'YYYY-MM-DD HH:mm'
              ).format('YYYY-MM-DD HH:mm');
              return (
                <Option key={time} value={formattedValue}>
                  {time}
                </Option>
              );
            })}
          </Select>
        )
      },
      {
        label: 'contact_details',
        name: ['contact_details'],
        key: 'contact_details',
        rules: [{ required: true }],
        input: <TextArea />
      }
    ]
  };
};
