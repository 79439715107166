import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../utils/constants/routes/productionRoutes';

/**
 * Custom hook for generating buttons for the quote generation card.
 * @hook
 * @param {function} setIsDrawerOpen - A function to set the state of the drawer open status.
 * @returns {Array} - An array of objects representing the buttons for the quote generation card.
 */
export const useQuoteGenerationCardButtons = ({ setIsDrawerOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return [
    // Temporary implementation ( had to open drawer instead of generating document )
    {
      label: t('dashboard.production.cards.quote_generation.rc_research'),
      onClick: () => setIsDrawerOpen('RCP_RESEARCH')
    },
    {
      label: t('dashboard.production.cards.quote_generation.plan_research'),
      onClick: () => setIsDrawerOpen('PLANS_RESEARCH')
    },
    {
      label: t('dashboard.production.cards.quote_generation.publication'),
      onClick: () => navigate(`${routes.QUOTATIONS}/create`)
    },
    {
      label: t('dashboard.production.cards.quote_generation.surveyor')
    }
  ];
};
