import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { SendOutlined } from '@ant-design/icons';
import {
  Typography,
  Modal,
  Row,
  Col,
  Button,
  Divider,
  notification
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { handleSubmitEmail } from '../../utils/plansResearchHandler';

const { Text } = Typography;

/**
 * SendEmailModal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.mission - The mission object.
 * @param {Array} props.archivistNotaries - The array of archivist notaries.
 * @param {boolean} props.visible - The visibility state of the modal.
 * @param {function} props.setVisible - The function to set the visibility state of the modal.
 * @param {function} props.setIsLoading - The function to set the loading state.
 * @param {boolean} props.forceRefresh - The force refresh state.
 * @param {function} props.setForceRefresh - The function to set the force refresh state.
 * @returns {JSX.Element} The SendEmailModal component.
 */
export const SendEmailModal = ({
  mission,
  archivistNotaries,
  visible,
  setVisible,
  setIsLoading,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isSending, setIsSending] = useState(false);
  const [emailDescription, setEmailDescription] = useState([]);
  const [emailNotary, setEmailNotary] = useState([]);

  const { id } = useParams();

  const generateHtml = async (id_mission) => {
    try {
      const missionData = await dispatchAPI('GET', {
        url: `/missions/${id_mission}`
      });

      const collectiveOwnershipData = await dispatchAPI('GET', {
        url: `/collective-ownerships/${missionData.data.collective_ownership}?populate=plans.notary_writer,plans.notary_writer.archivist_notary`
      });

      const collectiveOwnershipID = missionData.data.collective_ownership;

      const parameters = [
        {
          collection: 'Collective-ownership',
          documentId: collectiveOwnershipID
        }
      ];

      const promises = collectiveOwnershipData?.data?.plans.map(
        async (element) => {
          const body = {
            parameters,
            use_case: 'PLANS_EMAIL_TO_STUDY_REQUESTING_PLANS',
            template_type: 'TEMPLATE_EMAILS',
            registerInDb: false,
            exportHtml: true,
            extraPlaceholders: {
              notary_writer_name: `${element?.notary_writer?.last_name} ${element?.notary_writer?.first_name}`,
              realization_date: element?.realization_date,
              transcription_date: element?.transcription_date,
              plan_volume: element?.plan_volume,
              plan_number: element?.plan_number
            },
            generatePreview: true
          };

          const response = await dispatchAPI('POST', {
            url: '/template-documents/generatePDF',
            body
          });

          return {
            emailDescription: response.data,
            emailNotary: element?.notary_writer?.archivist_notary?.email
          };
        }
      );

      const results = await Promise.all(promises);

      setEmailDescription(results.map((result) => result.emailDescription));
      setEmailNotary(results.map((result) => result.emailNotary));
    } catch (e) {
      message(e);
    }
  };

  const unDisplayBar = () => {
    setTimeout(() => {
      const statusBar = document.getElementsByClassName('jodit-status-bar');
      for (let i = 0; i < statusBar.length; i += 1) {
        statusBar[i].remove();
      }
    }, 5000);
  };

  useEffect(async () => {
    await generateHtml(id);
    await unDisplayBar();
  }, [id]);

  return (
    <Modal
      title={t('missions.modal.titles.send_email_to_notary')}
      open={visible}
      onCancel={() => setVisible(false)}
      centered
      footer={[
        <Button
          key="back"
          type="link"
          onClick={() => {
            setVisible(false);
          }}
        >
          {t('missions.buttons.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            setVisible(false);
          }}
        >
          {t('missions.buttons.remind_me_later')}
        </Button>,
        <Button
          key="link"
          type="primary"
          loading={isSending}
          icon={<SendOutlined />}
          onClick={() => {
            setIsSending(true);
            handleSubmitEmail({
              mission,
              dispatchAPI,
              user,
              setForceRefresh,
              forceRefresh,
              setIsLoading,
              setIsSending,
              setVisible,
              t,
              notification,
              emailNotary
            });
          }}
        >
          {t('missions.buttons.send')}
        </Button>
      ]}
    >
      <Row>
        {(archivistNotaries || []).map((notary, index) => (
          <Col span={24} key={notary._id}>
            <Col span={24}>
              <Text>
                {t('missions.modal.messages.notary_email_description_head')}
              </Text>
              <Text strong>{notary.office_name}</Text>
            </Col>
            <Col span={24}>
              <Divider>
                <Text>
                  {t('missions.modal.messages.email_preview')} {index + 1}
                </Text>
              </Divider>
            </Col>
            <Col
              span={24}
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '2px',
                padding: '10px',
                marginBottom: '10px'
              }}
            >
              <JoditEditor
                ref={null}
                placeholder=""
                config={{
                  readonly: true,
                  toolbar: false
                }}
                value={emailDescription[index] || ''}
                style={{ height: '300px', marginBottom: '20px', minWidth: 640 }}
              />
            </Col>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

SendEmailModal.propTypes = {
  archivistNotaries: PropTypes.arrayOf(PropTypes.shape({})),
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setIsLoading: PropTypes.func,
  mission: PropTypes.shape({
    _id: PropTypes.string,
    collective_ownership: PropTypes.shape({
      _id: PropTypes.string,
      plans: PropTypes.arrayOf(PropTypes.shape({}))
    })
  }),
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

SendEmailModal.defaultProps = {
  archivistNotaries: [],
  visible: false,
  setVisible: () => {},
  setIsLoading: () => {},
  mission: {},
  forceRefresh: false,
  setForceRefresh: () => {}
};
