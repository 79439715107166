import { UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Flex, Spin } from 'antd';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { DraggableCard } from './DraggableCard';

const StyledCol = styled.div`
  min-width: 15rem;
  height: 100vh;
  border-radius: 2px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-right: 1px solid white;
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--primaryColor);
  box-shadow: 0px 9px 16px -6px var(--opacity25);
  border-radius: 2px;
  width: 100%;
  text-align: left;
  padding: 12px 10px;
  min-height: 40px;
  z-index: 1;
`;

/**
 * Renders a column for a manager in the Kanban view of the client wallet.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.manager - The manager object.
 * @param {string} props.searchValue - The search value for filtering collective ownerships.
 * @returns {JSX.Element} The rendered component.
 */
export const ManagerColumn = ({ manager, searchValue }) => {
  const keywords = searchValue ? `/search/${searchValue}` : '';
  const { currentSyndic } = useGlobalContext();
  const [collectiveOwnership, setCollectiveOwnership] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [refresh, setRefresh] = useState(false);

  const fetchCollectiveOwnership = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/syndic-collective-ownerships/getManagersKanban${keywords}?syndic=${currentSyndic}&manager=${manager._id}&populate=first_city&fields=name,first_address,first_city.name,mission&sort=-updated_at`
      });
      setCollectiveOwnership(data);
    } catch (e) {
      message(e);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    await fetchCollectiveOwnership();
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [searchValue, refresh]);

  const updateManager = async (collective_ownership, newManager) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/syndic-collective-ownerships/updateManager`,
        body: {
          collective_ownership,
          newManager
        }
      });
      setRefresh((prev) => !prev);
    } catch (e) {
      message(e);
    }
  };

  const [, drop] = useDrop({
    accept: 'card',
    drop: async (item) => {
      try {
        await updateManager(item.collective_ownership._id, manager._id);
      } catch (e) {
        message(e);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <StyledCol ref={drop}>
      <Title>
        <Badge
          dot={false}
          color="transparent"
          text={
            <>
              <UserOutlined style={{ marginRight: '8px' }} />
              {`${manager.first_name} ${manager.last_name}`}
            </>
          }
          style={{ color: '#fff' }}
        />
      </Title>

      <Flex style={{ width: '100%', overflowY: 'auto' }} vertical gap={8}>
        {isLoading && <Spin />}
        {!isLoading &&
          collectiveOwnership.map((item) => (
            <DraggableCard
              manager={manager}
              key={item._id}
              {...item}
              forceRefresh={() => setRefresh((prev) => !prev)}
            />
          ))}
      </Flex>
    </StyledCol>
  );
};

ManagerColumn.propTypes = {
  manager: PropTypes.shape({
    _id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired,
  searchValue: PropTypes.string
};

ManagerColumn.defaultProps = {
  searchValue: ''
};
