import PropTypes from 'prop-types';
import { useState } from 'react';
import { Datatable, ContentCustom } from '../../../../components';
import { useColumns } from './columns';

/**
 * `TemplateDocsDatatable` is a component that renders a datatable for displaying
 * template documents. It leverages the `Datatable` component and sets up specific
 * columns and queries based on the `useCaseParams` prop.
 *
 * @component
 * @param {Object} props - The properties that define the component.
 * @param {string} props.useCaseParams - A string parameter used to construct a query to fetch data for the datatable.
 * @param {string} props.type - The type of template document.
 *
 * @returns {React.Element} A `Datatable` component populated with columns and data based on the `useCaseParams`.
 *
 * @example
 *
 * return (
 *   <TemplateDocsDatatable useCaseParams="someUseCaseParam" />
 * );
 */
export const TemplateDocsDatatable = ({ useCaseParams, type }) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns(forceRefresh, setForceRefresh, type);

  const types = {
    TEMPLATE_DOCS: 'template-documents',
    TEMPLATE_EMAILS: 'template-emails',
    FAQ_TEMPLATES: 'faq-templates'
  };

  return (
    <ContentCustom>
      <Datatable
        tableLayout="auto"
        columns={columns}
        resourceName="template-documents"
        showAction={{
          pathname: (record) =>
            `/configurations/${types[type]}/show/${record._id}`
        }}
        editAction={{
          pathname: (record) =>
            `/configurations/${types[type]}/edit/${record._id}`
        }}
        forceRefresh={forceRefresh}
        extraQuery={`use_case=${useCaseParams}&type=${type}&sort=order&direction=desc`}
        populate="author"
        scroll={{ x: 900 }}
        onDoubleClickAction={null}
      />
    </ContentCustom>
  );
};

TemplateDocsDatatable.propTypes = {
  useCaseParams: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
