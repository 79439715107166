/**
 * Adds columns to the table.
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.isEditing - Flag indicating if the table is in editing mode.
 * @param {Array} options.columnsData - The data for the columns.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setEditingKey - The function to set the editing key.
 * @param {string} options.editingKey - The editing key.
 * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - Flag indicating if the table should be force refreshed.
 * @param {Function} options.setMergedColumns - The function to set the merged columns.
 * @param {Array} options.selectOptions - The options for the select input.
 * @param {string} options.selectType - The type of the select input.
 * @param {string} options.resource - The resource name.
 * @param {string} options.customUrl - The custom URL.
 * @returns {void}
 */
export const addColumns = ({
  isEditing,
  columnsData,
  form,
  setEditingKey,
  editingKey,
  setForceRefresh,
  forceRefresh,
  setMergedColumns,
  selectOptions,
  selectType,
  resource,
  customUrl
}) => {
  const mergedColumns = columnsData.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        setEditingKey,
        editingKey,
        form,
        setForceRefresh,
        forceRefresh,
        selectOptions,
        selectType,
        resource,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(col.key, record.key),
        customUrl
      })
    };
  });

  setMergedColumns(mergedColumns);
};
