import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { missionTag } from '../../../utils/constants/tagColors';

/**
 * Hook for generating information list content for the ShowOrder component.
 * @hook
 * @param {object} order - The order object.
 * @returns {object[]} Array of objects representing information list content.
 */
export const useInformationsListContent = (order) => {
  const { t } = useTranslation();

  return [
    {
      label: 'orders.form.ref_doc',
      content: order.ref_doc || '-'
    },
    {
      label: 'orders.form.cerfa_document_type',
      span: 2,
      content: order.cerfa_document_type?.wording || '-'
    },
    {
      label: 'orders.show.cards.labels.concerned_SPF',
      content: order.concerned_SPF?.name || '-'
    },
    {
      label: 'orders.form.urgent',
      content: order.urgent ? 'Oui' : 'Non'
    },
    {
      label: 'orders.form.syndic',
      content: order.mission?.syndic?.society_name || '-'
    },
    {
      label: 'orders.form.collective_ownership',
      contentStyle: {
        textAlign: 'center'
      },
      content: `${order.mission?.collective_ownership?.code || '--'} - ${
        order.mission?.collective_ownership?.name || '--'
      } - 
      ${order.mission?.collective_ownership?.first_address?.street || '--'} ${
        order.mission?.collective_ownership?.first_address?.postal_code || '--'
      } ${order.mission?.collective_ownership?.first_city?.name || '--'}`
    },
    {
      label: 'orders.form.mission',
      content: (
        <Tag color={missionTag[order.mission?.type]}>
          {t(`missions.tags.${order.mission?.type}`, {
            defaultValue: order.mission?.type || '-'
          })}
        </Tag>
      )
    },
    {
      label: 'orders.form.request_date',
      content: order.request_date
        ? new Date(order.request_date).toLocaleDateString()
        : '-'
    },
    {
      label: 'orders.form.reception_date',
      content: order.reception_date
        ? new Date(order.reception_date).toLocaleDateString()
        : '-'
    },
    {
      label: 'orders.form.cheque',
      content: order.cheque_number || '-'
    },
    {
      label: 'orders.form.cerfa_number',
      content: order.cerfa_number || '-'
    },
    {
      label: 'orders.form.costs',
      content: `${order.costs ?? '0'} €`
    },
    {
      label: 'orders.show.cards.labels.disbursement',
      content: `${order.disbursement ?? '0'} €`
    },
    {
      label: 'orders.show.cards.labels.to_bill',
      content: `${order.to_bill ?? '0'} €`
    }
  ];
};
