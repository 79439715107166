import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  outOfNavRoutes,
  routes
} from '../utils/constants/routes/billingRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { UserRouter } from '../routes/users/UserRouter';
import { CollectiveOwnershipRouter } from '../routes/collective-ownership/CollectiveOwnershipRouter';
import { SyndicRouter } from '../routes/syndic/SyndicRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { MissionRouter } from '../routes/missions/MissionRouter';
import { BillingAndAccountingRouter } from '../routes/billing-and-accountings/BillingAndAccountingRouter';
import { DynamicConfigurationRouter } from '../routes/configuration/DynamicConfigurationRouter';
import { RequireAuth } from './RequireAuth';
import { EventRouter } from '../routes/event/EventRouter';
import { MonitoringRouter } from '../routes/monitoring/MonitoringRouter';
import { OrderRouter } from '../routes/orders/OrderRouter';
import { DashboardRouter } from '../routes/dashboard/DashboardRouter';
import { CreateUpdateCollectiveOwnershipFormNoConnection } from '../routes/monitoring/collective-ownerships-forms/pcs-informations/CreateUpdateCollectiveOwnershipFormNoConnection';

export const BillingRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={outOfNavRoutes.PCSFORM}
          element={
            <CreateUpdateCollectiveOwnershipFormNoConnection purpose="create" />
          }
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.MISSIONS}/*`} element={<MissionRouter />} />
          <Route
            path={`${routes.BILLING_AND_ACCOUNTING}/*`}
            element={<BillingAndAccountingRouter />}
          />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route path={`${routes.EVENTS}/*`} element={<EventRouter />} />
          <Route
            path={`${routes.MONITORING}/*`}
            element={<MonitoringRouter />}
          />
          <Route path={`${routes.SYNDICS}/*`} element={<SyndicRouter />} />
          <Route
            path={`${routes.COLLECTIVE_OWNERSHIPS}/*`}
            element={<CollectiveOwnershipRouter />}
          />
          <Route
            path={`${routes.CONFIGURATIONS}/*`}
            element={<DynamicConfigurationRouter />}
          />
          <Route path={`${routes.ORDERS}/*`} element={<OrderRouter />} />
          <Route path={`${routes.DASHBOARD}/*`} element={<DashboardRouter />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
