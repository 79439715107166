import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Button, Row } from 'antd';
import Search from 'antd/es/input/Search';
import { useTranslation } from 'react-i18next';
import { CreateManagerDrawer } from './CreateManagerDrager.jsx/CreateManagerDrawer';

/**
 * Renders the header component for the ManagersKanban view.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setSearchValue - The function to set the search value.
 * @param {Function} props.setRefresh - The function to set the refresh state.
 * @param {boolean} props.refresh - The refresh state.
 * @returns {JSX.Element} The rendered ManagersKanbanHeader component.
 */
export const ManagersKanbanHeader = ({
  setSearchValue,
  setRefresh,
  refresh
}) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onSearch = (value) => {
    if (value === '') {
      return setSearchValue(null);
    }
    return setSearchValue(value);
  };

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]}>
        <Search
          placeholder={t('placeholder.search')}
          onSearch={onSearch}
          style={{ width: 200 }}
        />
        <Button type="primary" onClick={() => setIsDrawerOpen(true)}>
          <PlusOutlined />
          {t('buttons.create-manager')}
        </Button>
      </Row>
      <CreateManagerDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </>
  );
};

ManagersKanbanHeader.propTypes = {
  setSearchValue: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired
};
