import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { missionTag } from '../../../../utils/constants/tagColors';
import { formatAddress } from '../../../syndic-view/utils/formatingFunctions';
import { dateFormat } from '../../../syndic-view/utils/dateFormat';

const tradKey = 'syndic-requests.form';

/**
 * Returns an array of column configurations for a table.
 * @param {string} missionTypeLabel - The mission type.
 * @returns {Array} An array of column configurations.
 */
export const useColumns = (missionTypeLabel) => {
  const { t } = useTranslation();

  const findDocumentAndCreationDate = (documents, fileKey) => {
    const document = documents.find((doc) => doc.fileKey === fileKey);
    return document ? dayjs(document.created_at).format(dateFormat) : '-';
  };

  const findSignedQuote = (devis_signed) => {
    if (!devis_signed.length) {
      return null;
    }
    let mostRecentQuote = devis_signed[0];
    mostRecentQuote = devis_signed.reduce((mostRecent, quote) => {
      if (dayjs(quote.signed_date).isAfter(dayjs(mostRecent.signed_date))) {
        return quote;
      }
      return mostRecent;
    }, mostRecentQuote);
    return dayjs(mostRecentQuote.signed_date).format(dateFormat) || '-';
  };

  const spreadInputArrayIfMissionLabelIs = (missionLabels, inputArray) =>
    missionLabels.includes(missionTypeLabel) ? inputArray : [];

  return [
    {
      title: t(`${tradKey}.syndic`),
      key: ['syndic'],
      dataIndex: ['syndic', 'society_name']
    },
    {
      title: t(`${tradKey}.collective_ownership`),
      key: ['collective_ownership'],
      dataIndex: ['collective_ownership'],
      render: ({ name, first_address, first_city }) =>
        formatAddress(name, first_address, first_city)
    },
    ...spreadInputArrayIfMissionLabelIs(
      ['PCS_2'],
      [
        {
          title: t(`${tradKey}.recieved_pv_ag`),
          key: ['recieved_pv_ag'],
          dataIndex: ['documents'],
          render: (record) => findDocumentAndCreationDate(record, 'pv_ag')
        }
      ]
    ),
    ...spreadInputArrayIfMissionLabelIs(
      ['PCS_2', 'PCS_3'],
      [
        {
          title: t(`${tradKey}.devis_signed`),
          key: ['devis_signed'],
          dataIndex: ['devis_signed'],
          render: (record) => findSignedQuote(record)
        }
      ]
    ),
    ...spreadInputArrayIfMissionLabelIs(
      ['OUTSIDE_PCS'],
      [
        {
          title: t('collective-ownerships.form.mission'),
          key: ['requested_mission_type'],
          dataIndex: ['requested_mission_type'],
          render: (mission) => (
            <Tag
              color={missionTag[mission]}
              style={{
                display: 'block',
                marginBottom: '8px',
                textAlign: 'center'
              }}
            >
              {t(`missions.tags.${mission}`)}
            </Tag>
          )
        }
      ]
    ),
    ...spreadInputArrayIfMissionLabelIs(
      ['PCS_2'],
      [
        {
          title: t(`${tradKey}.recieved_plans`),
          key: ['recieved_plans'],
          dataIndex: ['documents'],
          render: (record) => findDocumentAndCreationDate(record, 'plans')
        },
        {
          title: t(`${tradKey}.type_pcs_2`),
          key: ['type_pcs_2'],
          dataIndex: ['type_pcs_2'],
          render: (mission) => (
            <Tag
              color={missionTag[mission]}
              style={{
                display: 'block',
                marginBottom: '8px',
                textAlign: 'center'
              }}
            >
              {t(`missions.tags.${mission}`)}
            </Tag>
          )
        }
      ]
    ),
    {
      title: t(`${tradKey}.submittion_date`),
      key: ['updated_at'],
      dataIndex: ['updated_at'],
      render: (date) => dayjs(date).format(dateFormat)
    }
  ];
};
