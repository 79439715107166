import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

/**
 * The `useAttachedProfessionalsListContent` hook is designed to structure and prepare data for presentation in a list format, specifically focusing on professionals attached to a syndic entity. It includes information about a lawyer, consultant, and the assignment statuses related to PCS tasks.
 *
 * @param {Object} [data={}] - An object containing information about the attached professionals and their assignment statuses. The properties include lawyer, consultant, reassign_PCS_tasks_from_stock_to_this_lawyer, and reassign_PCS_tasks_for_other_lawyer.
 *
 * Returns:
 * An array of objects, where each object represents a piece of information to be displayed in the list. Each object includes:
 * - `label`: A string key for internationalization purposes, used to retrieve the localized text for the label of the information.
 * - `span`: A numerical value indicating the span or width of the item in the list layout, used for alignment and formatting in grid or table-like displays.
 * - `content`: The content to be displayed for the item. This can be a simple string for individual professionals or a React component for more complex data such as assignment statuses, which are displayed with icons indicating the status (check or close).
 *
 * The hook utilizes the `useTranslation` hook from `react-i18next` for label internationalization, ensuring that the list content is accessible and understandable in different languages. It also uses Ant Design's `Row`, `Col`, and icon components (`CheckCircleFilled`, `CloseCircleFilled`) to visually represent assignment statuses.
 *
 * Usage:
 * This hook is intended for use in components that display detailed information about a syndic entity, particularly focusing on the professionals attached to it and their task assignments. It provides a structured and visual way to present complex data sets in a list or table-like format.
 */

export const useAttachedProfessionalsListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    lawyer,
    reassign_PCS_tasks_from_stock_to_this_lawyer,
    reassign_PCS_tasks_for_other_lawyer,
    consultant
  } = data;

  const useIcon = (isValidate) => {
    if (isValidate) {
      return <CheckCircleFilled style={{ width: 24, color: 'yellow' }} />;
    }
    return <CloseCircleFilled style={{ width: 24, color: 'grey' }} />;
  };

  return [
    {
      label: 'syndics.form.lawyer',
      span: 1,
      content: lawyer
        ? `${lawyer?.last_name || ''} ${lawyer?.first_name || ''}`
        : '-'
    },
    {
      label: 'syndics.form.assignments',
      span: 2,
      content: (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {useIcon(reassign_PCS_tasks_from_stock_to_this_lawyer)}
            {t('syndics.form.reassign_PCS_tasks_from_stock_to_this_lawyer')}
          </Col>
          <Col span={24}>
            {useIcon(reassign_PCS_tasks_for_other_lawyer)}
            {t('syndics.form.reassign_PCS_tasks_for_other_lawyer')}
          </Col>
        </Row>
      )
    },
    {
      label: 'syndics.form.consultant',
      span: 1,
      content: consultant
        ? `${consultant?.last_name || ''} ${consultant?.first_name || ''}`
        : '-'
    }
  ];
};
