import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Divider, Tag, Tooltip, Button, Flex } from 'antd';
import {
  WarningOutlined,
  EyeOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { documentTag } from '../../../../../utils/constants/tagColors';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { setDocumentIcon } from '../../../../../components/setDocumentIcon';
import { Document } from '../../../../../models/Document';
import { inputDisplaySmall } from '../../../../../utils/generateFormItem/utils/inputDisplaySmall';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * Custom hook that returns the columns configuration for the "toBeOrdered" and "ordered" tables in the missions view.
 * @hook
 * @param {Object} enums - An object containing enums used in the component.
 * @returns {Object} - An object containing the columns configuration for the "toBeOrdered" and "ordered" tables.
 */
export const useColumns = (enums) => {
  const { t } = useTranslation();
  const { id: missionId } = useParams();
  const { user } = useAuthContext();
  const { setForceRefresh, mission } = useMissionContext();
  const { viewDocument, downloadDocument } = useDownloadDocument();

  const draggerProps = ({ id }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      await Document.uploadDocument({
        id,
        file: objectFile.file,
        extraQuery: `?resource=orders&missionId=${missionId}&step=${
          mission?.steps.sub || mission?.steps.principal
        }&user=${user._id}`,
        setForceRefresh,
        t,
        invoiceGeneration: mission.type === 'PLANS_RESEARCH'
      });
    },
    fileList: []
  });

  const toBeOrderedColumns = [
    {
      title: t('orders.form.cerfa_document_type'),
      key: 'cerfa_document_type',
      dataIndex: 'cerfa_document_type',
      render: (cerfa_document_type) => cerfa_document_type?.wording || '-',
      sorter: true
    },
    {
      title: t('orders.form.ref_doc'),
      key: 'ref_doc',
      dataIndex: 'ref_doc',
      render: (ref_doc) => ref_doc || '-',
      sorter: true
    },
    {
      title: t('orders.form.added_date'),
      key: 'added_date',
      dataIndex: 'added_date',
      render: (added_date) =>
        added_date ? dayjs(added_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('orders.form.concerned_SPF'),
      key: 'concerned_SPF',
      dataIndex: 'concerned_SPF',
      render: (concerned_SPF) => concerned_SPF?.name || '-',
      sorter: true
    },
    {
      title: t('orders.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (costs) =>
        costs ? `${parseFloat(costs).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: () => (
        <>
          <EyeOutlined style={{ fontSize: 16 }} />

          <Divider type="vertical" />
          <PrinterOutlined style={{ fontSize: 16 }} />
        </>
      )
    }
  ];

  const orderedColumns = [
    {
      key: 'urgent',
      dataIndex: 'urgent',
      render: (condition) =>
        condition ? (
          <Tooltip title={t('orders.form.urgent')}>
            <WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        ) : null
    },
    {
      title: t('orders.form.cerfa_document_type'),
      key: 'cerfa_document_type',
      dataIndex: 'cerfa_document_type',
      render: (cerfa_document_type) => cerfa_document_type?.wording || '-',
      sorter: true
    },

    {
      title: t('orders.form.ref_doc'),
      key: 'ref_doc',
      dataIndex: 'ref_doc',
      render: (ref_doc) => ref_doc || '-',
      sorter: true
    },
    {
      title: t('orders.form.file'),
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record?.document?._id }) },
          false,
          t,
          true
        )
    },
    {
      key: 'document',
      dataIndex: 'document',
      render: (document, record) => (
        <Flex align="center" gap={1}>
          {document?.status ? (
            <Tag
              color={documentTag[document.status]}
              style={{ width: 'fit-content' }}
            >
              {t(`missions.tags.${document.status}`, {
                defaultValue: document.status
              })}
            </Tag>
          ) : (
            '-'
          )}
          {(document?.files || []).map((file) => (
            <Tooltip
              title={`${file.metadata.originalName} / ${
                record.reception_date
                  ? dayjs(record.reception_date).format('DD-MM-YY')
                  : '-'
              }`}
            >
              <Button
                type="link"
                onClick={() =>
                  file.type === 'application/pdf'
                    ? viewDocument(file)
                    : downloadDocument({
                        _id: file._id,
                        metadata: file.metadata,
                        contentType: file.contentType
                      })
                }
              >
                {setDocumentIcon(file.contentType)}
              </Button>
            </Tooltip>
          ))}
        </Flex>
      )
    },
    {
      hidden: true,
      title: t('orders.form.status'),
      key: 'document',
      dataIndex: 'document',
      render: (document) =>
        document?.status ? (
          <Tag color={documentTag[document?.status]}>
            {t(`missions.tags.${document?.status}`, {
              defaultValue: document?.status
            })}
          </Tag>
        ) : (
          '-'
        ),
      sorter: true,
      filters: (enums?.status || []).map((r) => ({
        text: t(`missions.tags.${r}`),
        value: r
      }))
    },
    {
      hidden: true,
      title: t('orders.form.reception_date'),
      key: 'reception_date',
      dataIndex: 'reception_date',
      render: (reception_date) =>
        reception_date ? dayjs(reception_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },

    {
      title: t('orders.form.added_date'),
      key: 'added_date',
      dataIndex: 'added_date',
      render: (added_date) =>
        added_date ? dayjs(added_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },

    {
      title: t('orders.form.concerned_SPF'),
      key: 'concerned_SPF',
      render: (record) =>
        record.concerned_SPF?.name || record?.expeditor?.office_name || '-',
      sorter: true
    },
    {
      title: t('orders.form.cerfa_number'),
      key: 'cerfa_number',
      dataIndex: 'cerfa_number',
      render: (cerfa_number) => cerfa_number || '-',
      sorter: true
    },
    {
      title: t('orders.form.costs'),
      key: 'to_bill',
      dataIndex: 'to_bill',
      render: (to_bill) =>
        to_bill ? `${parseFloat(to_bill).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      title: t('orders.form.cheque'),
      key: 'cheque_number',
      dataIndex: 'cheque_number',
      render: (cheque_number) => cheque_number || '-',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: () => (
        <>
          <EyeOutlined style={{ fontSize: 16 }} />

          <Divider type="vertical" />
          <PrinterOutlined style={{ fontSize: 16 }} />
        </>
      )
    }
  ];

  return { toBeOrderedColumns, orderedColumns };
};
