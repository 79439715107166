/**
 * Custom hook that returns an array of objects representing syndic information list content.
 * @hook
 * @param {Object} data - The data object containing syndic information.
 * @returns {Array} An array of objects representing syndic information list content.
 */
export const useSyndicInformationsListContent = (data = {}) => {
  const syndic = data?.syndic || {};

  return [
    {
      label: 'pcs-pre-productions.form.society_name',
      span: 1,
      content: syndic?.society_name || ''
    },
    {
      label: 'pcs-pre-productions.form.code',
      span: 1,
      content: syndic?.code || ''
    },
    {
      label: 'pcs-pre-productions.form.consultant',
      span: 1,
      content: syndic?.consultant
        ? `${syndic?.consultant?.first_name || ''} ${
            syndic?.consultant?.last_name
              ? syndic.consultant.last_name.toUpperCase()
              : ''
          }`
        : '-'
    },
    {
      label: 'pcs-pre-productions.form.contact',
      span: 1,
      content: syndic?.contact
        ? `${syndic?.contact?.first_name || ''} ${
            syndic?.contact?.last_name
              ? syndic.contact.last_name.toUpperCase()
              : ''
          }`
        : '-'
    },
    {
      label: 'pcs-pre-productions.form.email',
      span: 1,
      content: syndic?.contact?.email || '-'
    },
    {
      label: 'pcs-pre-productions.form.phone_number',
      span: 1,
      content: syndic?.contact?.phone_number?.number || '-'
    },
    {
      label: 'pcs-pre-productions.form.cell_phone_number',
      span: 1,
      content: syndic?.contact?.cell_phone_number?.number || '-'
    }
  ];
};
