import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { useDraggerField } from '../../../utils/DraggerField/useDraggerField';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem/generateFormItem';

const pTagsStyle = { margin: 0, padding: 0, lineHeight: '3rem' };

/**
 * SubmitOtherElements component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values for the form.
 * @param {Array} props.filesList - The list of files.
 * @param {Function} props.setFilesList - The function to set the files list.
 * @param {Function} props.setFilesConfiguration - The function to set the files configuration.
 * @param {Object} props.filesConfiguration - The files configuration object.
 * @param {string} props.purpose - The purpose of the files.
 * @param {Function} props.deleteFile - The function to delete a file.
 * @param {Function} props.setButtonsActive - The function to set the active buttons.
 * @returns {JSX.Element} The SubmitOtherElements component.
 */
export const SubmitOtherElements = ({
  form,
  initialValues,
  filesList,
  setFilesList,
  setFilesConfiguration,
  filesConfiguration,
  purpose,
  deleteFile,
  setButtonsActive
}) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const draggerField = useDraggerField({
    fileName: 'plans',
    filesList,
    setFilesList,
    setFilesConfiguration,
    filesConfiguration,
    purpose,
    deleteFile,
    noLabelCentered: true
  });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useEffect(() => {
    setButtonsActive((prev) => ({ ...prev, next: true, back: true }));
  }, []);

  return (
    <Flex vertical align="center">
      <p style={pTagsStyle}>{t('missions.form.submit_pb_ag')}</p>
      <p style={pTagsStyle}>{t('pcs-pre-productions.form.more_info')}</p>
      <div style={{ width: '100%' }}>
        {draggerField.map((field) =>
          generateFields('pcs-pre-productions', field)
        )}
      </div>
    </Flex>
  );
};

SubmitOtherElements.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  filesList: PropTypes.shape({}).isRequired,
  setFilesList: PropTypes.func.isRequired,
  setFilesConfiguration: PropTypes.func.isRequired,
  filesConfiguration: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired,
  setButtonsActive: PropTypes.func.isRequired
};
