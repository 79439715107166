import { useParams, useNavigate } from 'react-router-dom';
import {
  EditOutlined,
  BlockOutlined,
  FileAddOutlined,
  BellOutlined,
  CloseCircleOutlined,
  MailOutlined
} from '@ant-design/icons';
import { Button, Row, Col, Typography } from 'antd';
import { Rocket, Events } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { Mission } from '../../../../../models/Mission';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { confirmRefundsButtons } from '../utils/confirmRefundsButtons';

const { Text } = Typography;

/**
 * Renders extra buttons for the PCS mission type.
 * @component
 * @returns {JSX.Element|null} The rendered extra buttons or null if the mission type is not supported.
 */
export const PcsExtraButtons = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { user } = useAuthContext();
  const {
    mission,
    setIsDrawerOpen,
    invoices,
    setForceRefresh,
    setAddDocumentCardDatas,
    setIsLoading,
    quotations
  } = useMissionContext();

  const phaseTwoAdvancePaymentInvoice = invoices.find(
    (invoice) =>
      invoice.type === 'ADVANCE_PAYMENT' && invoice.pcs_step === 'PCS_2'
  );

  const phaseThreeBalanceInvoice = invoices.find(
    (invoice) => invoice.type === 'BALANCE' && invoice.pcs_step === 'PCS_3'
  );

  const quotation = (quotations || []).find(
    (el) => el.mission_type === 'PCS_PUBLICATION_QUOTE'
  );

  switch (type) {
    case 'PCS_1':
      return (
        <>
          {confirmRefundsButtons({ mission, setIsDrawerOpen })}
          {mission?.lawyer_step === 'LAWYER_SEIZURE' &&
          user.role === 'users:LAWYER' ? (
            <Row gutter={[16, 16]}>
              <Col>
                <Button
                  onClick={() =>
                    setIsDrawerOpen((prev) => ({
                      ...prev,
                      lawyer_consultation: {
                        open: true,
                        lawyer_step: 'LAWYER_SEIZURE'
                      }
                    }))
                  }
                >
                  <EditOutlined />
                  {t('missions.buttons.lawyer_consultation')}
                </Button>
              </Col>
              {mission?.status !== 'BLOCKED' ? (
                <Col>
                  <Button
                    onClick={() =>
                      setIsDrawerOpen((prev) => ({
                        ...prev,
                        block_mission: true
                      }))
                    }
                  >
                    <BlockOutlined />
                    {t('missions.buttons.block_mission')}
                  </Button>
                </Col>
              ) : null}
            </Row>
          ) : null}
          {mission?.lawyer_step === 'AG_DECISION' &&
          [
            'users:NOMADE_PRODUCTION',
            'admins:ADMIN',
            'admins:SUPER-ADMIN'
          ].includes(user.role) ? (
            <Row>
              <Col>
                <Button
                  onClick={() =>
                    setIsDrawerOpen((prev) => ({ ...prev, ag_decision: true }))
                  }
                >
                  <Events />
                  {t('missions.buttons.ag_decision')}
                </Button>
              </Col>
            </Row>
          ) : null}
          {mission?.lawyer_step === 'FINISHED' &&
          [
            'users:NOMADE_PRODUCTION',
            'admins:ADMIN',
            'admins:SUPER-ADMIN'
          ].includes(user.role) ? (
            <Button
              onClick={() =>
                Mission.createMissionPcsPhaseTwo({
                  id,
                  setForceRefresh,
                  setIsLoading,
                  navigate,
                  message
                })
              }
            >
              <Rocket />
              {t('missions.buttons.launch_phase_two')}
            </Button>
          ) : null}
        </>
      );
    case 'PCS_2':
      return (
        <Row gutter={[16, 16]}>
          {confirmRefundsButtons({ mission, setIsDrawerOpen })}
          {mission.type === 'PCS_2' &&
            ['FINAL_PROJECT_SUBMISSION'].includes(mission?.steps?.sub) &&
            ['users:LAWYER'].includes(user.role) && (
              <Col>
                <Button
                  onClick={() =>
                    setIsDrawerOpen((prev) => ({
                      ...prev,
                      definitive_project: true
                    }))
                  }
                >
                  <FileAddOutlined />
                  <Text>
                    {t('missions.cards.documents.buttons.definitive_project', {
                      type: t(
                        `missions.cards.documents.buttons.${mission.phase_two_type}`
                      )
                    })}
                  </Text>
                </Button>
              </Col>
            )}
          {mission.type === 'PCS_2' &&
            ['INTERMEDIATE_PROJECT_SUBMISSION', 'SYNDIC_VALIDATION'].includes(
              mission?.steps?.sub
            ) &&
            user.role === 'users:LAWYER' && (
              <Col>
                <Button
                  onClick={() =>
                    setIsDrawerOpen((prev) => ({
                      ...prev,
                      intermediate_project: true
                    }))
                  }
                >
                  <FileAddOutlined />
                  <Text>
                    {t('missions.cards.documents.buttons.intermediate_project')}
                  </Text>
                </Button>
              </Col>
            )}
          {mission?.steps?.sub === 'FINALIZATION' &&
          [
            'users:NOMADE_PRODUCTION',
            'admins:ADMIN',
            'admins:SUPER-ADMIN'
          ].includes(user.role) ? (
            <>
              <Col>
                <Button
                  onClick={() =>
                    Mission.navigateToCreateUpdateQuotation({
                      quotation,
                      navigate,
                      mission,
                      mission_type: 'PCS_PUBLICATION_QUOTE'
                    })
                  }
                >
                  {t('missions.buttons.publication_quotation')}
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setAddDocumentCardDatas({
                      open: false,
                      type: ''
                    });
                    setIsDrawerOpen((prev) => ({
                      ...prev,
                      finalization: true
                    }));
                  }}
                >
                  {t('missions.buttons.finalization')}
                </Button>
              </Col>
            </>
          ) : null}
          {mission?.steps?.sub === 'PROJECT_IMPLEMENTATION' &&
          [
            'users:LAWYER',
            'users:NOMADE_PRODUCTION',
            'admins:ADMIN',
            'admins:SUPER-ADMIN'
          ].includes(user.role) ? (
            <Col>
              <Button
                onClick={() =>
                  setIsDrawerOpen((prev) => ({
                    ...prev,
                    documents_to_be_re_written: true
                  }))
                }
              >
                {t('missions.buttons.documents_to_be_re_written')}
              </Button>
            </Col>
          ) : null}
          {mission?.steps?.sub === 'ADVANCE_PAYMENT' &&
          !phaseTwoAdvancePaymentInvoice ? (
            <Col>
              <Button
                onClick={() =>
                  Mission.generatePhaseTwoAdvancePaymentInvoice({
                    id,
                    setForceRefresh
                  })
                }
              >
                <FileAddOutlined />
                {t('missions.buttons.generate_advance_payment_invoice')}
              </Button>
            </Col>
          ) : null}
          {mission?.steps?.sub === 'PROJECT_IMPLEMENTATION' ? (
            <>
              <Col>
                <Button onClick={() => Mission.providerReminder('word', id)}>
                  <BellOutlined />
                  {t('missions.buttons.provider_reminder_word')}
                </Button>
              </Col>
              <Col>
                <Button onClick={() => Mission.providerReminder('excel', id)}>
                  <BellOutlined />
                  {t('missions.buttons.provider_reminder_excel')}
                </Button>
              </Col>
            </>
          ) : null}
          <Col>
            <Button
              onClick={() =>
                setIsDrawerOpen((prev) => ({
                  ...prev,
                  pcajp_array: true
                }))
              }
            >
              {t('missions.buttons.pcajp_array')}
            </Button>
          </Col>
          {user.role === 'users:LAWYER' && (
            <Col>
              <Button
                onClick={() =>
                  setIsDrawerOpen((prev) => ({
                    ...prev,
                    block_mission: true
                  }))
                }
                style={{
                  backgroundColor: 'black',
                  color: 'white'
                }}
              >
                <CloseCircleOutlined />
                {t('missions.buttons.block_mission')}
              </Button>
            </Col>
          )}
          {quotation &&
          [
            'users:NOMADE_PRODUCTION',
            'admins:ADMIN',
            'admins:SUPER-ADMIN'
          ].includes(user.role) ? (
            <Col>
              <Button
                onClick={() =>
                  navigate(`/quotations/edit/${quotation._id}`, {
                    state: {
                      launch_pcs3: true,
                      mission
                    }
                  })
                }
              >
                <Rocket />
                {t('missions.buttons.launch_phase_three')}
              </Button>
            </Col>
          ) : null}
        </Row>
      );
    case 'PCS_3':
      return (
        <Row gutter={[16, 16]}>
          {confirmRefundsButtons({ mission, setIsDrawerOpen })}
          {mission?.steps?.sub === 'INVOICE_AND_DOCUMENT_RECOVERY' &&
          !phaseThreeBalanceInvoice ? (
            <Col>
              <Button
                onClick={() =>
                  navigate(`/billing-and-accountings/invoices/create`, {
                    state: {
                      type: 'BALANCE',
                      mission,
                      syndic_id: mission.syndic?._id,
                      collective_ownership_id:
                        mission.collective_ownership?._id,
                      total_ht: mission?.PCS_3.amount_committed_ht,
                      disbursement: quotation?.total_notary_ttc,
                      pcs_step: 'PCS_3',
                      notary_fee_ttc: mission?.PCS_3?.total_notary_ttc
                    }
                  })
                }
              >
                <FileAddOutlined />
                {t('missions.buttons.generate_balance_invoice')}
              </Button>
            </Col>
          ) : null}
          {['INVOICE_AND_DOCUMENT_RECOVERY', 'BUILDING_FORM_ORDER'].includes(
            mission?.steps?.sub
          ) ? (
            <Col>
              <Button
                onClick={() =>
                  setIsDrawerOpen((prev) => ({
                    ...prev,
                    requested_documents_to_syndic: true
                  }))
                }
              >
                <FileAddOutlined />
                <Text>{t('missions.buttons.requested_documents')}</Text>
              </Button>
            </Col>
          ) : null}
          {mission?.steps?.sub === 'MATRIX_FINALIZATION' ? (
            <Col>
              <Button
                onClick={() =>
                  setIsDrawerOpen((prev) => ({
                    ...prev,
                    matrix_finalization: true
                  }))
                }
              >
                {t('missions.buttons.matrix_finalization')}
              </Button>
            </Col>
          ) : null}
          {mission?.steps?.sub === 'SEND_TO_NOTARY' ? (
            <Col>
              <Button
                onClick={() =>
                  setIsDrawerOpen((prev) => ({
                    ...prev,
                    notary_transmission: true
                  }))
                }
              >
                <MailOutlined />
                {t('missions.buttons.send_to_notary')}
              </Button>
            </Col>
          ) : null}
        </Row>
      );
    default:
      return null;
  }
};
