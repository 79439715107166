import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Row, Tag } from 'antd';
import { useDrag } from 'react-dnd';
import { missionTag } from '../../../../utils/constants/tagColors';
import { formatAddress } from '../../utils/formatingFunctions';

/**
 * DraggableCard component represents a draggable card used in the manager's kanban view.
 *
 * @component
 * @param {string} _id - The unique identifier of the card.
 * @param {string} name - The name of the card.
 * @param {string} manager - The manager associated with the card.
 * @param {string} first_address - The first address of the card.
 * @param {string} first_city - The first city of the card.
 * @param {Array} missions - An array of missions associated with the card.
 * @param {function} forceRefresh - A function to force refresh the component.
 * @returns {JSX.Element} The rendered DraggableCard component.
 */
export const DraggableCard = ({
  _id,
  name,
  manager,
  first_address,
  first_city,
  missions,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'card',
      end: () => {
        setTimeout(() => {
          forceRefresh();
        }, 200);
      },
      item: { collective_ownership: { _id }, manager },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [_id]
  );

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={drag} style={{ opacity }}>
      <Card key={_id} style={{ width: '100%' }}>
        <Flex vertical>
          {missions.map((mission) => (
            <Row key={mission._id}>
              <Tag color={missionTag[mission.type]}>
                {t(`missions.tags.${mission.type}`)}
              </Tag>
            </Row>
          ))}
        </Flex>
        {formatAddress(name, first_address, first_city)}
      </Card>
    </div>
  );
};

DraggableCard.propTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  first_address: PropTypes.string.isRequired,
  first_city: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  missions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired
    })
  ).isRequired,
  manager: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired,
  forceRefresh: PropTypes.func.isRequired
};
