import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { AddIcon } from '../../../../utils/constants/customIcons';
import { ListResource } from '../../../../components';
import { useColumns } from './columns/userColumns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * The `ManagersAndAssistants` component is designed to display a list of users specifically filtered by roles to include only those with 'SYNDIC_MANAGER' and 'SYNDIC_ASSISTANT' roles, associated with a particular syndic. It provides a comprehensive view of managers and assistants, allowing for user interactions such as viewing details and creating new entries.
 *
 * Props:
 * @prop {Object} [enums={}] - An object containing enumeration values for dropdown fields and other selectable options within the table, used to populate filters and tag colors.
 * @prop {function} t - A translation function provided by `react-i18next` for internationalization, used to translate labels, buttons, and other UI text.
 *
 * The component uses React's `useState` hook to manage the `forceRefresh` state, which controls the re-fetching of user data to reflect updates. The `useColumns` hook is utilized to generate the column definitions for the table based on the `enums`, API dispatch function, and other parameters.
 *
 * Additionally, the component integrates with the `useAuthContext` for authorization context and `useErrorMessage` for handling error messages. The `useParams` hook from 'react-router-dom' is used to extract the syndic's ID from the URL parameters, which is then used to filter the list of users by their association with the syndic.
 *
 * Extra functionality includes a custom action button for creating new users, linked to the user creation route. This allows for easy addition of new managers and assistants as needed.
 *
 * Usage:
 * This component is used within the administrative interface of an application that manages syndics and their associated users. It provides a filtered view of users with manager and assistant roles, facilitating the management of personnel related to a specific syndic.
 */

export const ManagersAndAssistants = ({ enums, t, editPermissions }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns({
    enums,
    dispatchAPI,
    message,
    setForceRefresh,
    forceRefresh,
    editPermissions
  });
  return (
    <ListResource
      resourceName={`syndics/getManagersAndAssistants/${id}`}
      columns={columns}
      scroll={{ x: 'max-content' }}
      forceRefresh={forceRefresh}
      customActionColumn
      onDoubleClickAction={{ action: () => {} }}
      resourceModelName="User"
      withPageHeader={false}
      withCreateButton={false}
      extraButtons={
        editPermissions && (
          <Link to="/users/create" state={{ syndic: id }}>
            <Button type="add">
              {t('buttons.create')}
              <AddIcon />
            </Button>
          </Link>
        )
      }
    />
  );
};

ManagersAndAssistants.propTypes = {
  enums: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  editPermissions: PropTypes.bool
};

ManagersAndAssistants.defaultProps = {
  enums: {},
  editPermissions: true
};
