import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Tag,
  Checkbox,
  Row,
  Tooltip,
  Button,
  Popconfirm,
  Radio,
  Flex,
  Typography
} from 'antd';
import { WarningOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { EditableTableMethods } from '../../../../../components/editableTableComponents/methods';
import { missionTag } from '../../../../../utils/constants/tagColors';
import { Document } from '../../../../../models/Document';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { setDocumentIcon } from '../../../../../components/setDocumentIcon';
import { Mission } from '../../../../../models/Mission';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { inputDisplaySmall } from '../../../../../utils/generateFormItem/utils/inputDisplaySmall';

/**
 * Custom hook that returns columns configuration for a table.
 *
 * @hook
 * @param {Function} setEditingKey - Function to set the editing key.
 * @param {Object} form - Form object.
 * @returns {Object} - Object containing columns configuration.
 */
const { Text } = Typography;
export const useColumns = ({ setEditingKey, form }) => {
  const { t } = useTranslation();
  const { id: missionId } = useParams();
  const { editCell, handleCheckbox, handleRadio } = EditableTableMethods();
  const { viewDocument, downloadDocument } = useDownloadDocument();
  const { forceRefresh, setForceRefresh, mission } = useMissionContext();

  const draggerProps = ({ id }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      await Document.uploadDocument({
        id,
        file: objectFile.file,
        extraQuery: `?resource=orders&missionId=${missionId}&step=${
          mission?.steps.sub || mission?.steps.principal
        }&notPlan=${true}`,
        setForceRefresh,
        t
      });
    },
    fileList: []
  });

  const actCopyColumns = [
    {
      title: (
        <Text className="break-column-title">
          {t('missions.form.document_type')}
        </Text>
      ),
      key: 'document_type',
      dataIndex: 'config_document',
      render: (config_document) => config_document?.wording || '-',
      sorter: (a, b) =>
        a.config_document.wording.localeCompare(b.config_document.wording)
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      editable: true,
      inputType: 'date-picker',
      render: (_, record) => (
        <Row
          onClick={() => editCell({ record, key: 'date', setEditingKey, form })}
        >
          {record?.date ? dayjs(record?.date).format('DD-MM-YY') : '-'}
        </Row>
      ),
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix()
    },
    {
      title: t('missions.form.volume'),
      key: 'volume',
      dataIndex: 'volume',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'volume', setEditingKey, form })
          }
        >
          {record?.volume || '-'}
        </Row>
      ),
      sorter: (a, b) => a.volume.localeCompare(b.volume)
    },
    {
      title: t('missions.form.number'),
      key: 'number',
      dataIndex: 'number',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'number', setEditingKey, form })
          }
        >
          {record?.number || '-'}
        </Row>
      ),
      sorter: (a, b) => a.number.localeCompare(b.number)
    },

    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    {
      title: (
        <Text className="break-column-title">
          {t('missions.form.SAGE_code')}
        </Text>
      ),
      key: 'SAGE_code',
      dataIndex: 'SAGE_code',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'SAGE_code', setEditingKey, form })
          }
        >
          {record?.SAGE_code || '-'}
        </Row>
      ),
      sorter: (a, b) => a.SAGE_code.localeCompare(b.SAGE_code)
    },
    {
      title: (
        <Text className="break-column-title">
          {t('missions.form.supply_by_syndic')}
        </Text>
      ),
      key: 'supply_by_syndic',
      dataIndex: 'supply_by_syndic',
      render: (_, record) => (
        <Checkbox
          defaultChecked={record.supply_by_syndic}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'supply_by_syndic',
              resource: 'documents',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('missions.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'costs', setEditingKey, form })
          }
        >
          {record?.costs ? `${record.costs} €` : '-'}
        </Row>
      ),
      sorter: (a, b) => a.costs - b.costs
    },
    {
      title: t('missions.form.disbursement'),
      key: 'disbursement',
      dataIndex: 'disbursement',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'disbursement', setEditingKey, form })
          }
        >
          {record?.disbursement ? `${record.disbursement} €` : '-'}
        </Row>
      ),
      sorter: (a, b) => a.disbursement - b.disbursement
    },
    {
      title: t('missions.form.to_charge'),
      key: 'to_charge',
      dataIndex: 'to_charge',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'to_charge', setEditingKey, form })
          }
        >
          {record?.to_charge ? `${record.to_charge} €` : '-'}
        </Row>
      ),
      sorter: (a, b) => a.to_charge - b.to_charge
    },
    {
      title: t('missions.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'comment', setEditingKey, form })
          }
        >
          {record?.comment || '-'}
        </Row>
      ),
      sorter: (a, b) => a.comment.localeCompare(b.comment)
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status)
    },
    {
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record._id }) },
          false,
          t
        )
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            Mission.deleteDocument({
              mission_id: missionId,
              setForceRefresh,
              forceRefresh,
              document_id: record._id
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const otherColumns = [
    {
      title: t('missions.form.document_type'),
      key: 'document_type',
      dataIndex: 'config_document',
      render: (config_document) => config_document?.wording || '-'
    },
    {
      title: t('missions.form.cadastral_reference'),
      key: 'cadastral_reference',
      dataIndex: 'cadastral_reference'
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      editable: true,
      inputType: 'date-picker',
      render: (_, record) => (
        <Row
          onClick={() => editCell({ record, key: 'date', setEditingKey, form })}
        >
          {record?.date ? dayjs(record?.date).format('DD-MM-YY') : '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (costs) =>
        costs ? `${parseFloat(costs).toFixed(2)} €` : '0.00 €'
    },
    {
      title: t('missions.form.to_charge'),
      key: 'to_charge',
      dataIndex: 'to_charge',
      editable: true,
      inputType: 'number',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'to_charge', setEditingKey, form })
          }
        >
          {record?.to_charge
            ? `${parseFloat(record.to_charge).toFixed(2)} €`
            : '0.00 €'}
        </Row>
      )
    },
    {
      title: t('missions.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'comment', setEditingKey, form })
          }
        >
          {record?.comment || '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      )
    },
    {
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record._id }) },
          false,
          t,
          true
        )
    },
    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Flex vertical>
            <Tooltip title={file.metadata.originalName}>
              <Button
                type="link"
                onClick={() =>
                  file.type === 'application/pdf'
                    ? viewDocument(file)
                    : downloadDocument({
                        _id: file._id,
                        metadata: file.metadata,
                        contentType: file.contentType
                      })
                }
              >
                {setDocumentIcon(file.contentType)}
              </Button>
            </Tooltip>
          </Flex>
        ))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            Mission.deleteDocument({
              mission_id: missionId,
              setForceRefresh,
              forceRefresh,
              document_id: record._id
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const generalAssemblyColumns = [
    {
      title: t('missions.form.document_type'),
      key: 'document_type',
      dataIndex: 'config_document',
      render: (config_document) => config_document?.wording || '-'
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      editable: true,
      inputType: 'date-picker',
      render: (_, record) => (
        <Row
          onClick={() => editCell({ record, key: 'date', setEditingKey, form })}
        >
          {record?.date ? dayjs(record?.date).format('DD-MM-YY') : '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.supply_by_syndic'),
      key: 'supply_by_syndic',
      dataIndex: 'supply_by_syndic',
      render: (_, record) => (
        <Checkbox
          defaultChecked={record.supply_by_syndic}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'supply_by_syndic',
              resource: 'documents',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('missions.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'comment', setEditingKey, form })
          }
        >
          {record?.comment || '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      )
    },
    {
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record._id }) },
          false,
          t
        )
    },
    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            Mission.deleteDocument({
              mission_id: missionId,
              setForceRefresh,
              forceRefresh,
              document_id: record._id
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const exchangeDocumentsColumns = [
    {
      title: t('missions.form.document_type'),
      key: 'document_type',
      dataIndex: 'config_document',
      render: (config_document) => config_document?.wording || '-'
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      editable: true,
      inputType: 'date-picker',
      render: (_, record) => (
        <Row
          onClick={() => editCell({ record, key: 'date', setEditingKey, form })}
        >
          {record?.date ? dayjs(record?.date).format('DD-MM-YY') : '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'comment', setEditingKey, form })
          }
        >
          {record?.comment || '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      )
    },
    {
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record._id }) },
          false,
          t
        )
    },
    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            Mission.deleteDocument({
              mission_id: missionId,
              setForceRefresh,
              forceRefresh,
              document_id: record._id
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const intermediateProjectColumns = [
    {
      title: t('missions.form.document_title'),
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: t('missions.form.file'),
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => file.metadata.originalName).join(', ')
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (_, record) =>
        record?.date ? dayjs(record?.date).format('DD-MM-YY') : '-'
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      )
    },
    {
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record._id }) },
          false,
          t
        )
    },
    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            Mission.deleteDocument({
              mission_id: missionId,
              setForceRefresh,
              forceRefresh,
              document_id: record._id
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const definitiveProjectColumns = [
    {
      title: t('missions.form.document_type'),
      key: 'document_type',
      render: (record) =>
        record?.config_document?.wording || record?.title || '-'
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (_, record) =>
        record?.date ? dayjs(record?.date).format('DD-MM-YY') : '-'
    },
    {
      title: t('missions.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'comment', setEditingKey, form })
          }
        >
          {record?.comment || '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      )
    },
    {
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record._id }) },
          false,
          t
        )
    },
    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            Mission.deleteDocument({
              mission_id: missionId,
              setForceRefresh,
              forceRefresh,
              document_id: record._id
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const requestedDocumentSyndicColumns = [
    {
      title: t('missions.form.document_type'),
      key: 'document_type',
      dataIndex: 'title',
      render: (title) => title || '-'
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (_, record) =>
        record?.date ? dayjs(record?.date).format('DD-MM-YY') : '-'
    },
    {
      title: t('missions.form.supply_by_syndic'),
      key: 'supply_by_syndic',
      dataIndex: 'supply_by_syndic',
      render: (_, record) => (
        <Checkbox
          defaultChecked={record.supply_by_syndic}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'supply_by_syndic',
              resource: 'documents',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('missions.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'comment', setEditingKey, form })
          }
        >
          {record?.comment || '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.validation_nomade'),
      key: 'validation_nomade',
      dataIndex: 'validation_nomade',
      render: (_, record) => (
        <Radio.Group
          onChange={(e) =>
            handleRadio({
              value: e.target.value,
              record,
              type: 'validation_nomade',
              resource: 'documents',
              setForceRefresh,
              forceRefresh
            })
          }
          value={record.validation_nomade}
        >
          <Radio value="VALIDATED">{t('missions.form.validated')}</Radio>
          <Radio value="IGNORED">{t('missions.form.ignored')}</Radio>
        </Radio.Group>
      )
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      )
    },
    {
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record._id }) },
          false,
          t
        )
    },
    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            Mission.deleteDocument({
              mission_id: missionId,
              setForceRefresh,
              forceRefresh,
              document_id: record._id
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const auditColumns = [
    {
      title: t('missions.form.document_type'),
      key: 'document_type',
      dataIndex: 'title',
      render: (title) => title || '-'
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (_, record) =>
        record?.date ? dayjs(record?.date).format('DD-MM-YY') : '-'
    },
    {
      title: (
        <Text className="break-column-title">
          {t('missions.form.supply_by_syndic')}
        </Text>
      ),
      key: 'supply_by_syndic',
      dataIndex: 'supply_by_syndic',
      render: (_, record) => (
        <Checkbox
          defaultChecked={record.supply_by_syndic}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'supply_by_syndic',
              resource: 'documents',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('missions.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'comment', setEditingKey, form })
          }
        >
          {record?.comment || '-'}
        </Row>
      )
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      )
    },
    {
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          { ...draggerProps({ id: record._id }) },
          false,
          t
        )
    },
    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            Mission.deleteDocument({
              mission_id: missionId,
              setForceRefresh,
              forceRefresh,
              document_id: record._id
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  return {
    actCopyColumns,
    otherColumns,
    generalAssemblyColumns,
    exchangeDocumentsColumns,
    intermediateProjectColumns,
    definitiveProjectColumns,
    requestedDocumentSyndicColumns,
    auditColumns
  };
};
