import { useState, useEffect } from 'react';
import { Form, Col, Switch, Row, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { MenuOutlined } from '@ant-design/icons';
import { useColumns } from './columns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { GetEnums } from '../../../utils/getEnums';
import { addColumns } from '../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { EditableTable } from '../../../components/editableTableComponents/EditableTable';
import { useMissionContext } from '../../../contexts/MissionContext';
import { ValidateDeleteModal } from './ValidateDeleteModal';
import { ExtraButtons } from './ListCollectionsExtraButtons';
import { User } from '../../../models/User';
import { MultipleEditModeDocsGrillesModal } from './MultipleEditModeDocsGrillesModal';

const { Text } = Typography;

/**
 * Renders the ListCollections component.
 *
 * @component
 * @returns {JSX.Element} The ListCollections component.
 */
export const ListCollections = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectOptions, setSelectOptions] = useState({});
  const [selectType, setSelectType] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const { getResourceData } = EditableTableMethods();
  const { searchValue, setPagination, pagination, currentSorter } =
    useMissionContext();
  const [collection_status, setCollectionStatus] = useState('IN_PROGRESS');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filters, setFilters] = useState('');
  const [isFiedlLoading, setIsFieldLoading] = useState(true);
  const [consultants, setConsultants] = useState([]);
  const [selectedCollectiveOwnerships, setSelectedCollectiveOwnerships] =
    useState([]);
  const [isValidateDeleteModalOpen, setIsValidateDeleteModalOpen] =
    useState(false);
  const { getEnums } = GetEnums();
  const columns = useColumns({
    setEditingKey,
    form,
    setSelectType,
    setForceRefresh,
    forceRefresh,
    collection_status
  });
  const rowSelectPermissions = ![
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER'
  ].includes(user.role);

  useEffect(() => {
    (async () => {
      const consultantsDatas = await User.getResources({
        query: 'role=users:CONSULTANT'
      });
      setConsultants(consultantsDatas);
      setIsFieldLoading(false);
    })();
  }, []);

  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  const handleDataSource = async () => {
    const collectiveOwnershipsDatas = await getResourceData({
      resource: 'collective-ownerships',
      searchValue,
      setPagination,
      pagination,
      query: `&${filters}&populate=syndic,syndic.contact,syndic.consultant&status=${collection_status}`,
      currentSorter
    });

    const updatedCollectiveOwnerships = collectiveOwnershipsDatas.map(
      (collectiveOwnership, index) => ({
        ...collectiveOwnership,
        key: index
      })
    );

    return updatedCollectiveOwnerships;
  };

  useEffect(() => {
    (async () => {
      const [collectiveOwnershipsDatas, collectiveOwnershipsEnums] =
        await Promise.all([
          handleDataSource(),
          getEnums({ resource: 'collective-ownerships' })
        ]);

      setDataSource(collectiveOwnershipsDatas);
      setSelectOptions({
        mods: collectiveOwnershipsEnums.mods
      });
    })();
  }, [forceRefresh, searchValue, collection_status, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: columns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      selectOptions,
      selectType,
      resource: 'collective-ownerships'
    });
  }, [editingKey, dataSource, collection_status]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedCollectiveOwnerships(rowRecords);
    }
  };

  return (
    <>
      <PageHeaderCustom title={t('collections.title')} />
      <ContentCustom>
        <EditableTable
          resource="collective-ownerships"
          dataSource={dataSource}
          mergedColumns={mergedColumns}
          setSelectedRowKey={setSelectedRowKey}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          form={form}
          searchValue={searchValue}
          pagination={pagination}
          setPagination={setPagination}
          scroll={{ x: 2500 }}
          withoutAddLineButton
          rowSelection={
            rowSelectPermissions
              ? {
                  type: 'checkbox',
                  ...rowSelection
                }
              : null
          }
          extraHeader={
            <>
              <ExtraButtons
                setFilters={setFilters}
                consultants={consultants}
                isFiedlLoading={isFiedlLoading}
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
              />
              <Col>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Text>{t('buttons.in_progress')}</Text>
                  </Col>
                  <Col>
                    <Switch
                      onChange={() =>
                        setCollectionStatus((prev) =>
                          prev === 'IN_PROGRESS' ? 'VALIDATED' : 'IN_PROGRESS'
                        )
                      }
                    />
                  </Col>
                  <Col>
                    <Text>{t('buttons.terminated')}</Text>
                  </Col>
                  <Col>
                    <MenuOutlined />
                  </Col>
                </Row>
              </Col>
            </>
          }
        />
      </ContentCustom>
      {isValidateDeleteModalOpen.open ? (
        <ValidateDeleteModal
          setIsValidateDeleteModalOpen={setIsValidateDeleteModalOpen}
          isValidateDeleteModalOpen={isValidateDeleteModalOpen}
          selectedCollectiveOwnerships={selectedCollectiveOwnerships}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectedCollectiveOwnerships={setSelectedCollectiveOwnerships}
        />
      ) : null}
      {selectedRowKeys.length && collection_status === 'IN_PROGRESS' ? (
        <Row style={{ marginLeft: 20 }} gutter={[16, 16]}>
          <Col>
            <Button
              type="primary"
              onClick={() =>
                setIsValidateDeleteModalOpen({ open: true, type: 'VALIDATED' })
              }
            >
              {t('buttons.validate_collective_ownerships')}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() =>
                setIsValidateDeleteModalOpen({ open: true, type: 'DELETED' })
              }
            >
              {t('buttons.cancel_collective_ownerships')}
            </Button>
          </Col>
          <Col>
            <MultipleEditModeDocsGrillesModal
              selectedCollectiveOwnerships={selectedCollectiveOwnerships}
              setForceRefresh={setForceRefresh}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
};
