/**
 * Changes the form data and advances to the next step.
 * @function
 * @async
 *
 * @param {Object} options - The options object.
 * @param {Function} options.nextStep - The function to advance to the next step.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setCurrent - The function to set the current step.
 * @param {Object} options.Message - The Message object.
 * @param {Function} options.t - The translation function.
 * @param {Function} options.setFormData - The function to set the form data.
 * @returns {null|string} - Returns null if successful, or an error message string if validation fails.
 */
export const changeForm = async ({
  nextStep,
  form,
  setCurrent,
  Message,
  t,
  setFormData
}) => {
  try {
    const values = await form.validateFields();
    setFormData((prev) => ({ ...prev, ...values }));
    setCurrent(nextStep);
    return null;
  } catch (errorInfo) {
    return Message.error(t('collective-ownerships.messages.required_fields'));
  }
};
