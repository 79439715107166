import { Modal, Row, Col, Typography, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { Mission } from '../../../../../models/Mission';

const { Text } = Typography;

/**
 * ConfirmRefundsModal component.
 *
 * @component
 * @returns {JSX.Element} The ConfirmRefundsModal component.
 */
export const ConfirmRefundsModal = () => {
  const { isDrawerOpen, setIsDrawerOpen, setForceRefresh } =
    useMissionContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage;

  return (
    <Modal
      title={t('missions.modal.titles.confirm_refunds')}
      onCancel={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          confirm_refunds: false
        }))
      }
      open={isDrawerOpen.confirm_refunds}
      footer={null}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Text>{t('missions.modal.messages.confirm_refunds')}</Text>
        </Col>
      </Row>
      <Row justify="end">
        <Button
          style={{ margin: '0 8px' }}
          type="link"
          danger
          onClick={() => {
            setIsDrawerOpen((prev) => ({
              ...prev,
              confirm_refunds: false
            }));
          }}
        >
          {`${t('buttons.cancel')} `}
          <CloseOutlined />
        </Button>
        <Button
          type="add"
          onClick={() =>
            Mission.confirmRefunds({
              id,
              message,
              setForceRefresh,
              setIsDrawerOpen
            })
          }
        >
          {`${t('buttons.confirm')} `}
          <CheckOutlined />
        </Button>
      </Row>
    </Modal>
  );
};
