import { DatePicker, Select, Input, Form, InputNumber } from 'antd';

const { Option } = Select;

/**
 * Custom hook that returns an array of fields for the profile form.
 *
 * @hook
 * @returns {Object} An object containing the fields array.
 */
const useFields = () => {
  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [{ required: true }, { type: 'email' }],
      disabled: true
    },
    {
      name: ['phone_number'],
      label: 'phone_number',
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <InputNumber style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['date_of_birth'],
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      label: 'number',
      name: ['address', 'number']
    },
    {
      label: 'street',
      name: ['address', 'street']
    },
    {
      label: 'additional',
      name: ['address', 'additional']
    },
    {
      label: 'postal_code',
      name: ['address', 'postal_code']
    },
    {
      label: 'city',
      name: ['address', 'city']
    }
  ];

  return {
    fields
  };
};

export default useFields;
