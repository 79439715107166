import { Link } from 'react-router-dom';
import { Tag, Popconfirm, Divider } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  collectiveOwnershipStatus,
  missionTag
} from '../../../../../utils/constants/tagColors';
import { deleteResource } from '../../../../../utils/deleteResource';

/**
 * The `useColumns` hook generates column definitions for a table component that displays information about collective ownership entities.
 * It includes columns for manager details, code, name, address, missions associated with the ownership, status, and actions such as view, edit, and delete.
 *
 * Parameters:
 * @param {Object} params - The parameters object.
 * @param {Object} params.enums - An object containing enumeration values for certain fields like mission types and collective ownership statuses, used to populate filters and tag colors.
 * @param {function} params.dispatchAPI - Function used to dispatch API calls, typically for data fetching or updating.
 * @param {function} params.message - Function for displaying feedback messages to the user (e.g., success or error messages).
 * @param {function} params.setForceRefresh - Function to update a state variable that can trigger a re-fetch of table data.
 * @param {boolean} params.forceRefresh - A state variable that, when toggled, indicates the table data should be re-fetched.
 *
 * Returns:
 * An array of column definitions for use in an Ant Design `Table` component. Each column definition includes properties such as:
 * - `title`: The localized title of the column, serving as the header.
 * - `key`: A unique key for the column, used internally by the table for tracking and sorting purposes.
 * - `dataIndex`: The path to the data value for the column in the data source, used to fetch the appropriate value for each cell.
 * - `render`: A function that returns the React elements to be rendered in each cell of the column, such as formatted strings, tags for status and mission types, and action icons.
 * - `sorter`: A boolean or function indicating whether the column is sortable.
 * - `filters`: An array of filter options for the column, applicable to columns like `missions` and `status`, which may categorize data based on mission types or ownership status.
 * - `align`: Alignment of the column content (e.g., 'right').
 * - `fixed`: Indicates if the column should be fixed at the 'right' side of the table, preventing it from scrolling out of view horizontally.
 *
 * The hook leverages the `useTranslation` hook from `react-i18next` for localizing column headers and tag contents, ensuring that the table is accessible and understandable in different languages. It utilizes Ant Design's `Tag`, `Popconfirm`, `Link`, and icon components for rendering mission statuses, confirmation dialogs, navigation links, and action icons.
 *
 * Usage:
 * This hook is intended for use in components where a table displaying a list of collective ownership entities is required. It allows for easy integration of sorting, filtering, formatting, and actions within the table.
 */

export const useColumns = ({
  enums,
  dispatchAPI,
  message,
  setForceRefresh,
  forceRefresh,
  setActiveKey,
  editCollectiveOwnershipPermissions
}) => {
  const { t } = useTranslation();

  return [
    {
      title: t('collective-ownerships.form.manager'),
      dataIndex: 'manager',
      key: 'manager',
      render: (manager) =>
        manager
          ? `${manager?.last_name || ''} ${manager?.first_name || ''}`
          : '-',
      sorter: true
    },
    {
      title: t('collective-ownerships.form.code'),
      key: 'code',
      render: (record) => record.code || '-',
      sorter: (a, b) => (a.code ? a?.code.localeCompare(b?.code) : true)
    },
    {
      title: t('collective-ownerships.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name ? a?.name.localeCompare(b?.name) : true)
    },
    {
      title: t('collective-ownerships.form.first_address.street'),
      key: 'first_address',
      dataIndex: 'first_address',
      render: (first_address) =>
        first_address
          ? `${first_address?.number || ''} ${first_address?.street || ''} ${
              first_address?.postal_code || ''
            } ${first_address?.city || ''}`
          : '-',
      sorter: (a, b) =>
        a.number
          ? a?.first_address.number.localeCompare(b?.first_address.number)
          : true
    },
    {
      title: t('collective-ownerships.form.missions'),
      key: 'missions',
      dataIndex: 'missions',
      render: (missions) =>
        missions?.length
          ? missions.map((mission) => (
              <Tag color={missionTag[mission.type]}>
                {t(`missions.tags.${mission.type}`, {
                  defaultValue: mission.type
                })}
              </Tag>
            ))
          : '-',
      filters: enums?.type?.map((r) => ({
        text: t(`missions.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('collective-ownerships.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={collectiveOwnershipStatus[status]}>
            {t(`collective-ownerships.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.collective_ownership_status?.map((r) => ({
        text: t(`collective-ownerships.tags.${r}`),
        value: r
      }))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/collective-ownerships/show/${record?._id}`
            }}
            onClick={() => {
              setActiveKey('1');
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {editCollectiveOwnershipPermissions && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/collective-ownerships/edit/${record?._id}`
                }}
                onClick={() => {
                  setActiveKey('1');
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() =>
                  deleteResource({
                    dispatchAPI,
                    resourceName: 'collective-ownerships',
                    id: record._id,
                    setForceRefresh,
                    forceRefresh,
                    message
                  })
                }
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];
};
