import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import { CustomSearchResourcesBar } from '../../../components/ExtraSearchsBar/CustomSearchResourcesBar';
import { AddIcon } from '../../../utils/constants/customIcons';

/**
 * Renders a list of notary configurations.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource.
 * @param {string} props.resourceModelName - The name of the resource model.
 * @param {boolean} props.populate - Whether to populate the resource.
 * @returns {JSX.Element} The rendered component.
 */
export const ListNotaryConfigurations = ({
  resourceName,
  resourceModelName,
  populate
}) => {
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [filters, setFilters] = useState({});
  const [extraQuery, setExtraQuery] = useState('');
  const columns = useColumns({
    resourceName,
    forceRefresh,
    setForceRefresh
  });

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
  }, [filters]);

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between">
        <Col>
          <CustomSearchResourcesBar setFilters={setFilters} />
        </Col>
        <Col>
          <Link
            to={`/configurations/notary-configurations/create/${resourceName}`}
          >
            <Button type="add">
              {t('buttons.create')}
              <AddIcon />
            </Button>
          </Link>
        </Col>
      </Row>
      <ListResource
        resourceName={resourceName}
        columns={columns[resourceName]}
        extraQuery={extraQuery}
        resourceModelName={resourceModelName}
        populate={populate}
        customParams={`/${resourceName}`}
        withPageHeader={false}
        withSearchBar={false}
        withCreateButton={false}
        customActionColumn
        forceRefresh={forceRefresh}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
};

ListNotaryConfigurations.propTypes = {
  resourceName: PropTypes.string.isRequired,
  resourceModelName: PropTypes.string.isRequired,
  populate: PropTypes.string
};

ListNotaryConfigurations.defaultProps = {
  populate: ''
};
