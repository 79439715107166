/**
 * Generate a PDF document by sending a request to the specified API endpoint.
 *
 * @async
 * @function generatePDF
 * @param {Function} message - Function to display a message or handle an error.
 * @param {Function} dispatchAPI - Function to dispatch an API request.
 * @param {Object} parameters - Parameters to include in the API request body.
 * @param {string} use_case - Specific use case for generating the PDF.
 * @param {string} template_type - The type of template to use for generating the PDF.
 * @param {Function} setIsLoading - Function to set the loading state.
 * @param {boolean} [printPdf=true] - Whether to print the PDF or return the Blob object.
 * @param {string} [extraQuery] - Additional query parameters to include in the API request.
 * @param {string} [downloadName] - The name of the file to download.
 * @param {boolean} [exportHtml] - Whether to export the HTML content.
 * @param {boolean} [openInNewTab] - Whether to open the PDF in a new tab.
 * @param {boolean} [registerInDb] - Whether to register the PDF in the database.
 * @param {Object} [translationObject] - The object containing the translations.
 * @returns {Promise<Blob|void>} - Returns a Blob object if `printPdf` is false, otherwise void.
 * @throws Will throw an error if the API request fails.
 *
 * @example
 * generatePDF(
 *   (msg) => console.log(msg),
 *   async (method, options) => axios({ method, ...options }),
 *   { param1: 'value1', param2: 'value2' },
 *   'use_case_example',
 *   (isLoading) => console.log('Loading:', isLoading),
 *   true
 * ).then(blob => {
 *   if (blob) {
 *     // Handle the Blob object
 *   }
 * }).catch(error => {
 *   console.error(error);
 * });
 */
export const generatePDF = async ({
  message,
  dispatchAPI,
  parameters,
  use_case,
  template_type,
  setIsLoading,
  printPdf = true,
  extraQuery,
  downloadName,
  exportHtml,
  openInNewTab,
  registerInDb,
  translationObject
}) => {
  setIsLoading(true);

  try {
    const { data } = await dispatchAPI('POST', {
      url: `template-documents/generatePDF${
        extraQuery ? `?${extraQuery}` : ''
      }`,
      body: {
        parameters,
        use_case,
        template_type,
        registerInDb,
        exportHtml,
        returnPdfResponse: true,
        translationObject
      },
      responseType: 'arraybuffer' // Set the responseType to receive the response as an ArrayBuffer
    });
    setIsLoading(false);
    // Create a Blob object from the ArrayBuffer

    const blob = new Blob([data], { type: 'application/pdf' });

    if (printPdf) {
      // Create a URL from the Blob object
      const url = URL.createObjectURL(blob);

      // Use the URL to create a download link or display the PDF in the browser
      // Example: Create a download link
      const link = document.createElement('a');
      link.href = url;
      if (openInNewTab) {
        link.target = '_blank';
      } else {
        link.download = `${downloadName}.pdf`;
      }
      link.click();

      // Clean up the URL object
      return URL.revokeObjectURL(url);
    }
    return blob;
  } catch (e) {
    if (e.response) {
      const decoder = new TextDecoder('utf-8');
      const errorText = decoder.decode(e.response.data);
      try {
        const errorData = JSON.parse(errorText);
        if (errorData.description === 'PRICE_SYSTEM_NOT_FOUND') {
          return message('PRICE_SYSTEM_NOT_FOUND');
        }
      } catch (parseError) {
        message(errorText);
      }
    }
    if (e?.response?.status === 404) return message('TEMPLATE_NOT_FOUND');
    return message(e);
  }
};
