import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  syndicViewSubtitle,
  syndicViewTitle
} from '../../utils/styleConstants';

/**
 * Generates the title and subtitle for the factures component.
 * @returns {Object} An object containing the title and subtitle components.
 */
export const facturesTitle = () => {
  const { t } = useTranslation();

  const title = (
    <Row>
      <h1 style={syndicViewTitle}>{t('syndic-factures.titlteCard.title')}</h1>
    </Row>
  );

  const subtitle = (
    <p style={syndicViewSubtitle}>{t('syndic-factures.titlteCard.subtitle')}</p>
  );

  return { title, subtitle };
};
