import { Input } from 'antd';
import PropTypes from 'prop-types';
import { LotCreation } from './LotCreation';
import { LotDivision } from './LotDivision';
import { LotMerge } from './LotMerge';
import { SurveyorQuotation } from './SurveyorQuotation';
import { ChargingGrid } from './ChargingGrid';
import { ConsistencyDestinationChange } from './ConsistencyDestinationChange';
import { Sale } from './Sale';
import { LotRemoval } from './LotRemoval';
import { Other } from './Other';
import { PublicationPcs } from './PublicationPcs';
import { EnjoymentRightToValidated } from './EnjoymentRightToValidated';
import { LotEnjoymentRight } from './LotEnjoymentRight';
import { LotCreationPcs } from './LotCreationPcs';
import { LotRemovalPcs } from './LotRemovalPcs';
import { Exchange } from './Exchange';

/**
 * Renders the appropriate component based on the action selected in the form.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {string} props.recordId - The record ID.
 * @returns {JSX.Element|null} The rendered component or null.
 */
export const RenderQuotationActionDatas = ({ form, recordId }) => {
  const values = form.getFieldsValue();

  if (values?.actions) {
    switch (values?.actions[recordId]?.action) {
      case 'LOT_CREATION':
        return <LotCreation recordId={recordId} />;
      case 'LOT_DIVISION':
        return <LotDivision recordId={recordId} />;
      case 'LOT_MERGE':
        return <LotMerge recordId={recordId} />;
      case 'CHARGING_GRID':
        return <ChargingGrid recordId={recordId} />;
      case 'CONSISTENCY_DESTINATION_CHANGE':
        return <ConsistencyDestinationChange recordId={recordId} />;
      case 'SALE':
        return <Sale recordId={recordId} />;
      case 'LOT_REMOVAL':
        return <LotRemoval recordId={recordId} />;
      case 'EXCHANGE':
        return <Exchange recordId={recordId} />;
      case 'SURVEYOR_QUOTATION':
        return <SurveyorQuotation recordId={recordId} />;
      case 'OTHER':
        return <Other recordId={recordId} />;
      case 'PUBLICATION_PCS':
        return <PublicationPcs recordId={recordId} />;
      case 'ENJOYMENT_RIGHT_TO_VALIDATED':
        return <EnjoymentRightToValidated recordId={recordId} />;
      case 'LOT_ENJOYMENT_RIGHT':
        return <LotEnjoymentRight recordId={recordId} />;
      case 'LOT_CREATION_PCS':
        return <LotCreationPcs recordId={recordId} />;
      case 'LOT_REMOVAL_PCS':
        return <LotRemovalPcs recordId={recordId} />;
      default:
        return <Input />;
    }
  }

  return null;
};

RenderQuotationActionDatas.propTypes = {
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func
  }),
  recordId: PropTypes.string
};

RenderQuotationActionDatas.defaultProps = {
  form: {},
  recordId: ''
};
