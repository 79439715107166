import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { Steps, message as Message, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { ContentCustom } from '../../../components';
import { MainInformationsForm } from './forms/MainInformations';
import { ContactForm } from './forms/Contact';
import { AttachedProfessionalsForm } from './forms/AttachedProfessionals';
import { PublicationForm } from './forms/Publication';
import { changeForm } from '../utils/changeForm';
import { onFinishForm } from '../utils/onFinishForm';
import { onSubmitForm } from '../utils/onSubmitForm';
import { getEnums } from '../utils/getEnums';
import { getProfessionals } from '../utils/getProfessionals';
import { ManagerModal } from '../modals/ManagerModal';
import { useConfig } from '../utils/createUpdateConfig';

/**
 * The `CreateUpdateSyndic` component is designed to handle the creation or update of a syndic entity within a multi-step form interface.
 * It allows users to input and submit detailed information about a syndic, including main information, contact details, attached professionals, and publication information.
 *
 * Props:
 * @prop {string} purpose - Indicates the purpose of the form, either 'create' or 'update', to tailor the form functionality and API interactions accordingly.
 *
 * The component utilizes React's `useState` and `useEffect` hooks for state management and lifecycle operations, `useNavigate` and `useParams` from 'react-router-dom' for navigation and parameter extraction, and `useTranslation` for internationalization.
 * It integrates with the `useAuthContext` for authorization context and `useErrorMessage` for handling error messages. The form fields and their configurations are dynamically generated using custom hooks such as `useGenerateFormItem` and `useMainInfoFields`.
 *
 * Functionality:
 * - Initializes form fields and manages form state using Ant Design's `Form` component and a custom form generation utility.
 * - Fetches enumeration values and professionals' data needed for select fields and other form inputs.
 * - Manages the current step of the multi-step form process and handles navigation between steps.
 * - Submits form data upon completion, handling both creation and update operations based on the `purpose` prop.
 *
 * The component renders a `PageHeaderCustom` with the form title, a `Steps` component for step navigation, and the current form step's fields within a `CreateUpdateContainer`.
 *
 * Usage:
 * This component is used within the administrative interface of an application that manages syndic entities. It provides a structured and user-friendly interface for entering and updating detailed syndic information across multiple categories.
 */

export const CreateUpdateSyndic = ({ purpose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [current, setCurrent] = useState(0);
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [professionals, setProfessionals] = useState({});
  const [formData, setFormData] = useState({});
  const [managerModal, setManagerModal] = useState(false);
  const [isDirectorManager, setIsDirectorManager] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const config = useConfig({ setFormData });
  const editPermissions = !['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(
    user.role
  );

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums({ dispatchAPI, setEnums, message });
    await getProfessionals({
      dispatchAPI,
      message,
      setProfessionals
    });
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    if (!editPermissions) {
      navigate('/syndics');
    }
  }, [editPermissions, user]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const forms = [
    <MainInformationsForm
      onFinish={() =>
        onFinishForm({
          form,
          setFormData,
          setCurrent,
          current,
          Message,
          t
        })
      }
      onSubmit={() =>
        onSubmitForm({
          values: formData,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          form,
          setManagerModal,
          navigate,
          isDirectorManager,
          user
        })
      }
      initialValues={formData}
      form={form}
      enums={enums}
      isFieldsLoading={isFieldsLoading}
      setCurrent={setCurrent}
      current={current}
      t={t}
      key="main-informations"
    />,
    <ContactForm
      onFinish={() =>
        onFinishForm({
          form,
          setFormData,
          setCurrent,
          current,
          Message,
          t
        })
      }
      onSubmit={() =>
        onSubmitForm({
          values: formData,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          form,
          setManagerModal,
          navigate,
          isDirectorManager,
          user
        })
      }
      initialValues={formData}
      setIsDirectorManager={setIsDirectorManager}
      isDirectorManager={isDirectorManager}
      form={form}
      enums={enums}
      isFieldsLoading={isFieldsLoading}
      setCurrent={setCurrent}
      current={current}
      t={t}
      key="contact"
      showCheckBox={showCheckBox}
      setShowCheckBox={setShowCheckBox}
    />,
    <AttachedProfessionalsForm
      onFinish={() =>
        onFinishForm({
          form,
          setFormData,
          setCurrent,
          current,
          Message,
          t
        })
      }
      onSubmit={() =>
        onSubmitForm({
          values: formData,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          form,
          setManagerModal,
          navigate,
          isDirectorManager,
          user
        })
      }
      initialValues={formData}
      form={form}
      isFieldsLoading={isFieldsLoading}
      setCurrent={setCurrent}
      current={current}
      professionals={professionals}
      key="attached-professionals"
    />,
    <PublicationForm
      onFinish={() =>
        onFinishForm({
          form,
          setFormData,
          setCurrent,
          current,
          Message,
          t
        })
      }
      onSubmit={() =>
        onSubmitForm({
          values: formData,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          form,
          setManagerModal,
          navigate,
          isDirectorManager,
          user
        })
      }
      initialValues={formData}
      form={form}
      enums={enums}
      isFieldsLoading={isFieldsLoading}
      setCurrent={setCurrent}
      current={current}
      t={t}
      key="publication"
    />
  ];

  const steps = [
    {
      title: t('syndics.form.titles.main_informations'),
      icon: <CheckCircleOutlined />,
      key: 'main-informations'
    },
    {
      title: t('syndics.form.titles.contact'),
      icon: <CheckCircleOutlined />,
      key: 'contact'
    },
    {
      title: t('syndics.form.titles.attached_professionals'),
      icon: <CheckCircleOutlined />,
      key: 'attached-professionals'
    },
    {
      title: t('syndics.form.titles.publication'),
      icon: <CheckCircleOutlined />,
      key: 'publication'
    }
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title
  }));

  return (
    <>
      <PageHeaderCustom title={t(`syndics.form.title.${purpose}`)} />
      <ContentCustom>
        <Steps
          current={current}
          items={items}
          onChange={(e) =>
            changeForm({
              nextStep: e,
              form,
              setCurrent,
              Message,
              t,
              setFormData
            })
          }
          style={{ marginBottom: 24 }}
        />
        <CreateUpdateContainer
          customFormInstance={form}
          formExtra={forms[current]}
          isFieldsLoading={false}
          purpose={purpose}
          baseUrl="syndics"
          resource="syndics"
          config={config}
          withFilesManager={false}
          withEnums={false}
          withCustomButtons
          withCustomPageHeader
        />
        {managerModal ? <ManagerModal managerModal={managerModal} /> : null}
      </ContentCustom>
    </>
  );
};

CreateUpdateSyndic.propTypes = {
  purpose: PropTypes.string.isRequired
};
