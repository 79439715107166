import { useTranslation } from 'react-i18next';

/**
 * Hook for generating steps items for the ShowOrder component.
 * @hook
 * @param {object} enums - Enums object containing document and order enums.
 * @param {object} order - The order object.
 * @returns {object[]} Array of objects representing steps items.
 */
export const useStepsItems = ({ enums, order }) => {
  const { t } = useTranslation();

  return [
    enums &&
      enums.docEnums && {
        title:
          enums.docEnums.status.indexOf(order.document?.status) + 1 !== -1 &&
          order.status !== enums.orderEnums.status[0]
            ? t('orders.show.steps.ORDERED')
            : t('orders.show.steps.TO_BE_ORDERED')
      },
    {
      title: t('orders.show.document.EXPECTED')
    },
    {
      title: t('orders.show.document.LATE')
    },
    {
      title: t('orders.show.document.RECEIVED')
    }
  ];
};
