import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { outOfNavRoutes } from '../utils/constants/routes/adminRoutes';
import { ServicesLayout } from '../components/layouts/Services/ServicesLayout';

/**
 * Higher-order component that handles authentication and user role-based rendering of layouts.
 * @component
 * @returns {JSX.Element} Rendered layout component based on user role or redirects to login if not authenticated.
 */
export const ServicesRouterTemplate = () => {
  const location = useLocation();
  const { isValid } = useAuthContext();
  const { '*': urlToken } = useParams();

  const isPostRoute =
    urlToken?.includes('post-pwd') || urlToken?.includes('reset-pwd');

  if (!isValid && !isPostRoute) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  const renderLayout = () => <ServicesLayout />;

  return renderLayout();
};
