import dayjs from 'dayjs';

/**
 * Custom hook that returns a configuration object for updating a resource.
 *
 * @param {Object} options - The options for the configuration.
 * @param {string} options.id - The ID of the resource.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({ id }) => ({
  onGetResource: {
    setFields: (data) => {
      const newData = {
        [id]: {
          ...data,
          cerfa_3231_date: data?.cerfa_3231_date?.start_date && [
            dayjs(data?.cerfa_3231_date?.start_date),
            dayjs(data?.cerfa_3231_date?.end_date)
          ],
          cerfa_3236_date: data?.cerfa_3236_date?.start_date && [
            dayjs(data?.cerfa_3236_date?.start_date),
            dayjs(data?.cerfa_3236_date?.end_date)
          ],
          services: data?.services?.map((service) => ({
            ...service,
            start_date: service.start_date && dayjs(service.start_date),
            end_date: service.end_date && dayjs(service.end_date)
          }))
        }
      };
      return newData;
    }
  }
});
