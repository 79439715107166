import { useState } from 'react';
import { Drawer, notification, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/lawyerConsultationFields';
import { useConfig } from '../utils/lawyerConsultationCreateUpdateConfig';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * LawyerConsultationDrawer component.
 *
 * @component
 * @returns {JSX.Element} The LawyerConsultationDrawer component
 */
export const LawyerConsultationDrawer = () => {
  const [form] = Form.useForm();
  const {
    mission,
    forceRefresh,
    setForceRefresh,
    setIsDrawerOpen,
    isDrawerOpen,
    isLoading
  } = useMissionContext();
  const [checkedCheckboxCount, setCheckedCheckboxCount] = useState({
    count: 0,
    show_message: true
  });
  const { fields } = useFields({ setCheckedCheckboxCount, form });
  const { t } = useTranslation();

  const config = useConfig({
    mission,
    checkedCheckboxCount,
    setCheckedCheckboxCount,
    notification,
    t
  });

  return (
    <Drawer
      title={t('missions.drawers.titles.lawyer_consultation')}
      onClose={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          lawyer_consultation: { open: false, lawyer_step: null }
        }))
      }
      open={isDrawerOpen.lawyer_consultation.open}
    >
      <CreateUpdateContainer
        customFormInstance={form}
        fields={fields}
        config={config}
        isFieldsLoading={isLoading}
        purpose="edit"
        baseUrl="missions"
        resource="missions"
        populate="documents,documents.files"
        extraQuery={`?current_lawyer_step=${isDrawerOpen.lawyer_consultation.lawyer_step}`}
        withFilesManager={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{
          ...isDrawerOpen,
          lawyer_consultation: { open: false, lawyer_step: null }
        }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
