import { message as antdMessage } from 'antd';
import { BaseModel } from './BaseModel';
import { updateHistory } from '../utils/updateHistory';

/**
 * Represents a user.
 * @class
 */
export class PcsPreProduction extends BaseModel {
  static resourceName = 'pcs-pre-productions';

  /**
   * Array of possible status values for the PcsPreProduction class.
   * @type {string[]}
   */
  static arrStatus = [
    'ASSISTANT',
    'ACCOUNT_MANAGER',
    'PRODUCTION',
    'DELETED',
    'VALIDATED',
    'PENDING_VALIDATION'
  ];

  static ALL_PHASES = [
    'DRAFT',
    'ASSISTANT',
    'ACCOUNT_MANAGER',
    'PENDING_VALIDATION',
    'PRODUCTION',
    'DELETED',
    'VALIDATED'
  ];

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${PcsPreProduction.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${PcsPreProduction.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Patch a resource by ID with new values.
   * @function
   * @async
   * @param {Object} options - The options for patching the resource.
   * @param {string} options.id - The ID of the resource to patch.
   * @param {Object} options.values - The new values to patch the resource with.
   * @param {function} [options.setIsSubmitting] - The function to set the submitting state.
   * @param {string} [options.populate=''] - The fields to populate in the patched resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the patch request.
   * @returns {Promise<Object>} A promise that resolves to the patched resource data.
   */
  static async patchResource({
    id,
    values,
    setIsSubmitting,
    messageContent,
    populate = '',
    extraQuery = ''
  }) {
    if (setIsSubmitting) setIsSubmitting(true);
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('PATCH', {
      url: `${PcsPreProduction.resourceName}/${id}?populate=${populate}&${extraQuery}`,
      body: formData
    });
    if (setIsSubmitting) setIsSubmitting(false);
    if (antdMessage) antdMessage.success(messageContent);
    return data;
  }

  /**
   * Validates the PcsPreProduction with the given parameters.
   *
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {string} options.id - The ID of the PcsPreProduction.
   * @param {Object} options.values - The values to be validated.
   * @param {function} options.setIsValidateModalOpen - The function to set the validation modal state.
   * @param {function} options.setCurrent - The function to set the current state.
   * @param {string} [options.populate=''] - The populate string for the API request.
   * @param {string} [options.extraQuery=''] - The extra query string for the API request.
   * @returns {Promise<Object>} - The response data from the API request.
   */
  static async validatePcsPreProduction({
    id,
    values,
    setIsValidateModalOpen,
    setCurrent,
    populate = '',
    extraQuery = '',
    user,
    t,
    setForceRefresh,
    forceRefresh,
    message
  }) {
    try {
      const formData = new FormData();
      formData.append('values', JSON.stringify(values));

      const { data } = await this.dispatchAPI('PATCH', {
        url: `${PcsPreProduction.resourceName}/validate/pcs-pre-production/${id}?populate=${populate}&${extraQuery}`,
        body: formData
      });

      if (data) {
        setIsValidateModalOpen(false);
        setCurrent(4);
      }
      antdMessage.info(t('pcs-pre-productions.modals.validate_success'));

      updateHistory({
        dispatchAPI: this.dispatchAPI,
        id,
        resourceName: PcsPreProduction.resourceName,
        values: {
          date: new Date(),
          description: 'mission_completed',
          by: user._id,
          step: this.arrStatus[4]
        },
        setForceRefresh,
        forceRefresh
      });

      return data;
    } catch (error) {
      return message(error);
    }
  }

  /**
   * Updates the status of a PcsPreProduction element.
   * @param {Object} options - The options for updating the status.
   * @param {number} options.element - The element to update the status for.
   * @param {number} options.id - The ID of the PcsPreProduction element.
   * @param {function} options.setIsValidateModalOpen - The function to set the state of the validation modal.
   * @param {function} options.setCurrent - The function to set the current element.
   * @param {function} options.setPcsPreProduction - The function to set the state of the PcsPreProduction.
   * @returns {Promise<void>} - A promise that resolves when the status is updated.
   */
  static updateStatus = async ({
    element,
    id,
    setIsValidateModalOpen,
    setCurrent,
    setPcsPreProduction,
    setForceRefresh,
    forceRefresh,
    user
  }) => {
    if (element === 4) {
      setIsValidateModalOpen(true);
    } else {
      setCurrent(element);

      const formData = new FormData();
      formData.append(
        'values',
        JSON.stringify({ status: this.arrStatus[element] })
      );

      await this.dispatchAPI('PATCH', {
        url: `${PcsPreProduction.resourceName}/update-status/${id}`,
        body: formData
      });

      setPcsPreProduction((prev) => ({
        ...prev,
        status: this.arrStatus[element]
      }));

      updateHistory({
        dispatchAPI: this.dispatchAPI,
        id,
        resourceName: PcsPreProduction.resourceName,
        values: {
          date: new Date(),
          description: 'mission_validation',
          by: user._id,
          step: this.arrStatus[element - 1]
        },
        setForceRefresh,
        forceRefresh
      });
    }
  };

  /**
   * Deletes a file by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for deleting the file.
   * @param {string} options.id - The ID of the file to delete.
   * @param {function} options.setForceRefresh - The function to set the force refresh state.
   * @param {boolean} options.forceRefresh - The current force refresh state.
   * @param {string} options.fileId - The ID of the file to delete.
   * @returns {Promise<boolean>} A promise that resolves to `true` if the file is deleted successfully.
   */
  static async deleteFileById({
    resourceId,
    fileId,
    setForceRefresh,
    forceRefresh
  }) {
    await this.dispatchAPI('PATCH', {
      url: `${PcsPreProduction.resourceName}/${resourceId}/${fileId}`
    });

    setForceRefresh(!forceRefresh);
    return true;
  }

  /**
   * Deletes a PcsPreProduction record by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for deleting the record.
   * @param {number} options.id - The ID of the PcsPreProduction record to delete.
   * @param {function} options.setForceRefresh - The function to set the forceRefresh state.
   * @param {boolean} options.forceRefresh - The current value of the forceRefresh state.
   * @returns {Promise<boolean>} A promise that resolves to true if the deletion is successful.
   */
  static async deleteResource({ id, setForceRefresh, forceRefresh }) {
    await this.dispatchAPI('DELETE', {
      url: `${PcsPreProduction.resourceName}/${id}`
    });

    setForceRefresh(!forceRefresh);
    return true;
  }

  /**
   * Retrieves statistics from the API.
   *
   * This method sends a GET request to the API to fetch statistics related
   * to the resource specified by `PcsPreProduction.resourceName`.
   *
   * @returns {Promise<Object>} An object containing the statistical data.
   */
  static async getStats() {
    const { data } = await this.dispatchAPI('GET', {
      url: `${PcsPreProduction.resourceName}/stats`
    });
    return data;
  }

  /**
   * Retrieves PCS stock statistics from the API.
   *
   * This method sends a GET request to the API to fetch PCS stock data.
   *
   * @returns {Promise<Object>} An object containing the number of PCS stocks.
   */
  static async getPcsStocksStats() {
    const { data } = await this.dispatchAPI('GET', {
      url: `pcs-stocks`
    });
    return {
      pcs_stocks: data.length
    };
  }
}
