import {
  Select,
  Input,
  Row,
  Col,
  Typography,
  Switch,
  Form,
  InputNumber
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuotationContext } from '../../../contexts/QuotationContext';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

export const useFields = () => {
  const { t } = useTranslation();
  const {
    setSyndic,
    syndicOptions,
    collectiveOwnershipOptions,
    setCollectiveOwnership,
    setIsNewSyndicField,
    isNewSyndicField,
    isNewCollectiveOwnershipField,
    setIsNewCollectiveOwnershipField,
    municipalities,
    form,
    quoteConfiguration,
    isFieldsLoading,
    enums,
    setMissionType,
    missionType
  } = useQuotationContext();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      noLabel: true,
      key: 'is_existing_syndic',
      input: (
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Text>{t('quotations.form.existing_syndic')}</Text>
          </Col>
          <Col>
            <Form.Item
              name={['is_existing_syndic']}
              valuePropName="checked"
              style={{ marginBottom: '0' }}
            >
              <Switch
                onChange={(e) => {
                  setIsNewSyndicField(!isNewSyndicField);
                  if (e) {
                    form.setFieldsValue({
                      is_existing_collective_ownership: true
                    });
                    setIsNewCollectiveOwnershipField(true);
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Text>{t('quotations.form.new_syndic')}</Text>
          </Col>
        </Row>
      )
    },
    ...(!isNewSyndicField
      ? [
          {
            name: ['syndic'],
            key: 'syndic',
            rules: [{ required: true }],
            input: (
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                loading={isFieldsLoading}
                onChange={(e, option) => {
                  setSyndic(option?.syndic);
                  form.setFieldsValue({
                    collective_ownership: undefined
                  });
                }}
              >
                {syndicOptions.map((item) => (
                  <Option value={item._id} key={item._id} syndic={item}>
                    {item.society_name}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : [
          {
            label: 'syndic_code',
            name: ['syndic', 'code'],
            key: 'syndic.code',
            rules: [{ required: true }]
          },
          {
            label: 'syndic_society_name',
            name: ['syndic', 'society_name'],
            key: 'syndic.society_name',
            rules: [{ required: true }]
          },
          {
            label: 'syndic_address.street',
            name: ['syndic', 'representative_address', 'street'],
            key: 'street',
            rules: [{ required: true }]
          },
          {
            label: 'syndic_address.postal_code',
            name: ['syndic', 'representative_address', 'postal_code'],
            key: 'postal_code',
            rules: [{ required: true }]
          },
          {
            label: 'syndic_address.city',
            name: ['syndic', 'representative_address', 'city'],
            key: 'city',
            rules: [{ required: true }]
          }
        ]),
    {
      noLabel: true,
      key: 'is_existing_collective_ownership',
      input: (
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Text>{t('quotations.form.existing_collective_ownership')}</Text>
          </Col>
          <Col>
            <Form.Item
              name={['is_existing_collective_ownership']}
              valuePropName="checked"
              style={{ marginBottom: '0' }}
            >
              <Switch
                onChange={() =>
                  setIsNewCollectiveOwnershipField(
                    !isNewCollectiveOwnershipField
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Text>{t('quotations.form.new_collective_ownership')}</Text>
          </Col>
        </Row>
      )
    },
    ...(!isNewCollectiveOwnershipField
      ? [
          {
            name: ['collective_ownership'],
            key: 'collective_ownership',
            rules: [{ required: true }],
            input: (
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                loading={isFieldsLoading}
                onChange={(e, option) => {
                  setCollectiveOwnership(option?.collective_ownership);
                  form.setFieldsValue({
                    mission: undefined
                  });
                }}
              >
                {collectiveOwnershipOptions?.map((item) => (
                  <Option
                    value={item._id}
                    key={item._id}
                    collective_ownership={item}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : [
          {
            label: 'collective_ownership_cadastral_reference',
            name: ['collective_ownership', 'cadastral_reference'],
            rules: [{ required: true }]
          },
          {
            label: 'collective_ownership_capacity',
            name: ['collective_ownership', 'capacity'],
            input: <InputNumber addonAfter="m²" />
          },
          {
            label: 'collective_ownership_name',
            name: ['collective_ownership', 'name']
          },
          {
            label: 'collective_ownership_first_address.street',
            name: ['collective_ownership', 'first_address', 'street'],
            rules: [{ required: true }]
          },
          {
            label: 'collective_ownership_first_address.additional',
            name: ['collective_ownership', 'first_address', 'additional']
          },
          {
            label: 'collective_ownership_first_address.postal_code',
            name: ['collective_ownership', 'first_address', 'postal_code'],
            rules: [{ required: true }]
          },
          {
            label: 'collective_ownership_first_city',
            name: ['collective_ownership', 'first_city'],
            rules: [{ required: true }],
            input: (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                loading={isFieldsLoading}
              >
                {(municipalities || []).map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )
          }
        ]),
    {
      name: ['mission_type'],
      key: 'mission_type',
      rules: [{ required: true }],
      input: (
        <Select
          onChange={(el) => setMissionType(el)}
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {enums?.quotation?.type?.map((item) => (
            <Option value={item} key={item}>
              {t(`quotations.tags.mission.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    ...(missionType === 'PCS_PUBLICATION_QUOTE'
      ? [
          {
            label: 'lot_number_in_building',
            name: ['publication_pcs_quotation_configuration'],
            key: 'publication_pcs_quotation_configuration',
            rules: [{ required: true }],
            input: (
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                loading={isFieldsLoading}
              >
                {(quoteConfiguration || []).map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.number_of_lots}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['mission_description'],
      key: 'mission_description',
      input: <TextArea />
    }
  ];

  return { fields };
};
