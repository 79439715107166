import {
  DatePicker,
  Form,
  InputNumber,
  Select,
  Typography,
  List,
  Row,
  Col,
  Card
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Invoice } from '../../../../models/Invoice';

const { Option } = Select;
const { Text } = Typography;

/**
 * Custom hook that returns an array of fields based on the provided parameters.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {string} options.type - The type of invoice.
 * @param {boolean} options.isFieldsLoading - Indicates if the fields are loading.
 * @param {Array} options.syndics - The array of syndics.
 * @param {Array} options.collective_ownerships - The array of collective ownerships.
 * @param {Array} options.missions - The array of missions.
 * @param {Function} options.setSelectedSyndic - The function to set the selected syndic.
 * @param {Function} options.setSelectedCollectiveOwnership - The function to set the selected collective ownership.
 * @param {Function} options.setSelectedMission - The function to set the selected mission.
 * @param {string} options.selectedSyndic - The selected syndic.
 * @param {string} options.selectedCollectiveOwnership - The selected collective ownership.
 * @param {string} options.selectedMission - The selected mission.
 * @param {Function} options.setTotalTTC - The function to set the total TTC.
 * @param {Object} options.totalTTC - The total TTC object.
 * @param {Array} options.orders - The array of orders.
 * @param {boolean} options.is_mission_cancellation_form - Indicates if the form is for mission cancellation.
 * @param {Array} options.invoices - The array of invoices.
 * @param {Function} options.setSelectedInvoices - The function to set the selected invoices.
 * @param {Array} options.selectedInvoices - The selected invoices.
 * @param {Object} options.invoice - The invoice object.
 * @returns {Object} - The fields object.
 */
export const useFields = ({
  form,
  type,
  isFieldsLoading,
  syndics,
  collective_ownerships,
  missions,
  setSelectedSyndic,
  setSelectedCollectiveOwnership,
  setSelectedMission,
  selectedSyndic,
  selectedCollectiveOwnership,
  selectedMission,
  setTotalTTC,
  totalTTC,
  orders,
  is_mission_cancellation_form,
  invoices,
  setSelectedInvoices,
  selectedInvoices,
  invoice
}) => {
  const { t } = useTranslation();
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const mainFields = [
    {
      name: ['syndic'],
      key: 'syndic',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          disabled={type === 'CREDIT_NOTE'}
          onChange={(value) => {
            if (value) {
              setSelectedSyndic(value);
            } else {
              setSelectedSyndic('');
            }
          }}
        >
          {(syndics || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.society_name || '-'} - (${item.code || '-'})`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['collective_ownership'],
      key: 'collective_ownership',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(value) => {
            if (value) {
              setSelectedCollectiveOwnership(value);
            } else {
              setSelectedCollectiveOwnership('');
            }
          }}
          disabled={!selectedSyndic || type === 'CREDIT_NOTE'}
        >
          {(collective_ownerships || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.code || '-'} - ${item.first_address?.street || '-'} - ${
                item.first_city?.name || '-'
              } ${item.first_address?.postal_code || '-'}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['mission'],
      key: 'mission',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(value, option) => {
            if (value) {
              setSelectedMission(option.mission);
            } else {
              setSelectedMission('');
            }
          }}
          disabled={!selectedCollectiveOwnership || type === 'CREDIT_NOTE'}
        >
          {(missions || []).map((item) => (
            <Option value={item._id} key={item._id} mission={item}>
              {t(`missions.tags.${item.type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['sending_date'],
      key: 'sending_date',
      rules: [{ required: true }],
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    ...(type !== 'CREDIT_NOTE'
      ? [
          {
            name: ['tva_percentage'],
            key: 'tva_percentage',
            rules: [{ required: true }],
            input: (
              <Form.Item name={['tva_percentage']} key="tva_percentage">
                <InputNumber
                  addonAfter="%"
                  onChange={() =>
                    Invoice.handleCreateUpdateTotalTTC({
                      form,
                      type,
                      setTotalTTC
                    })
                  }
                />
              </Form.Item>
            )
          }
        ]
      : [])
  ];

  let fields = [...mainFields];

  switch (type) {
    case 'ASSETS':
      fields = [
        ...mainFields,
        {
          name: ['cancellation_fee'],
          key: 'cancellation_fee',
          input: (
            <Form.Item
              name={['cancellation_fee']}
              key="cancellation_fee"
              extra={t('invoices.form.total_ttc_extra', {
                amount: totalTTC.cancellation_fee
              })}
            >
              <InputNumber
                addonAfter="€"
                onChange={() =>
                  Invoice.handleCreateUpdateTotalTTC({
                    form,
                    type,
                    setTotalTTC
                  })
                }
              />
            </Form.Item>
          )
        },
        {
          name: ['lawyer_fee_ht'],
          key: 'lawyer_fee_ht',
          input: (
            <Form.Item
              name={['lawyer_fee_ht']}
              key="lawyer_fee_ht"
              extra={t('invoices.form.total_ttc_extra', {
                amount: totalTTC.lawyer_fee_ht
              })}
            >
              <InputNumber
                addonAfter="€"
                onChange={() =>
                  Invoice.handleCreateUpdateTotalTTC({
                    form,
                    type,
                    setTotalTTC
                  })
                }
              />
            </Form.Item>
          )
        }
      ];
      break;
    case 'ADVANCE_PAYMENT':
      break;
    case 'REGULE':
      fields = [
        ...mainFields,
        {
          name: ['total_ht'],
          key: 'total_ht',
          input: (
            <Form.Item
              name={['total_ht']}
              key="total_ht"
              extra={t('invoices.form.total_ttc_extra', {
                amount: totalTTC.total_ht
              })}
            >
              <InputNumber
                addonAfter="€"
                onChange={() =>
                  Invoice.handleCreateUpdateTotalTTC({
                    form,
                    type,
                    setTotalTTC
                  })
                }
              />
            </Form.Item>
          )
        },
        {
          name: ['disbursement'],
          key: 'disbursement',
          input: <InputNumber addonAfter="€" />
        }
      ];
      break;
    case 'BALANCE':
      fields = [
        ...mainFields,
        {
          name: ['total_ht'],
          key: 'total_ht',
          input: (
            <Form.Item
              name={['total_ht']}
              key="total_ht"
              extra={t('invoices.form.total_ttc_extra', {
                amount: totalTTC.total_ht
              })}
            >
              <InputNumber
                addonAfter="€"
                onChange={() =>
                  Invoice.handleCreateUpdateTotalTTC({
                    form,
                    type,
                    setTotalTTC
                  })
                }
              />
            </Form.Item>
          )
        },
        ...(selectedMission?.type === 'PLANS_RESEARCH'
          ? [
              {
                name: ['supply_TTC_due_to_plans'],
                key: 'supply_TTC_due_to_plans',
                input: <InputNumber addonAfter="€" />
              }
            ]
          : []),
        ...(selectedMission?.type === 'PCS_3'
          ? [
              {
                label: 'notary_disbursement',
                name: ['notary_fee_ttc'],
                key: ['notary_fee_ttc'],
                input: (
                  <InputNumber
                    addonAfter="€"
                    onChange={() =>
                      Invoice.handleCreateUpdateTotalTTC({
                        form,
                        type,
                        setTotalTTC
                      })
                    }
                  />
                )
              }
            ]
          : [])
      ];
      break;
    case 'CREDIT_NOTE':
      fields = [
        ...(is_mission_cancellation_form
          ? [
              {
                name: ['invoices'],
                key: 'invoices',
                rules: [{ required: true }],
                input: (
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={filterOption}
                    loading={isFieldsLoading}
                    mode="multiple"
                    onChange={(_, option) => {
                      if (option) {
                        setSelectedInvoices(option.map((item) => item.invoice));
                      } else {
                        setSelectedInvoices([]);
                      }
                    }}
                  >
                    {(invoices || []).map((item) => (
                      <Option value={item._id} key={item._id} invoice={item}>
                        {`${t(`invoices.tags.${item.type}`)} ${
                          item.reference || '-'
                        }`}
                      </Option>
                    ))}
                  </Select>
                )
              }
            ]
          : []),
        ...mainFields,
        ...(selectedInvoices.length
          ? [
              {
                noLabel: true,
                key: 'credit_notes',
                input: (
                  <List
                    dataSource={selectedInvoices}
                    renderItem={(item, index) => (
                      <List.Item key={item}>
                        <Card
                          style={{
                            width: 280,
                            padding: '0px',
                            overflow: 'hidden'
                          }}
                        >
                          <Row
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                            gutter={[16, 16]}
                          >
                            <Col span={24}>
                              <Form.Item
                                label={t('invoices.form.tva_percentage')}
                                name={[
                                  'credit_notes',
                                  item._id,
                                  'tva_percentage'
                                ]}
                                key="tva_percentage"
                              >
                                <InputNumber
                                  addonAfter="%"
                                  onChange={() =>
                                    Invoice.handleCreateUpdateTotalTTC({
                                      form,
                                      type,
                                      setTotalTTC,
                                      invoiceId: item._id
                                    })
                                  }
                                />
                              </Form.Item>
                            </Col>
                            {index === 0 ? (
                              <Col span={24}>
                                <Form.Item
                                  label={t('invoices.form.cancellation_fee')}
                                  name={[
                                    'credit_notes',
                                    item._id,
                                    'cancellation_fee'
                                  ]}
                                  key="cancellation_fee"
                                  extra={t('invoices.form.total_ttc_extra', {
                                    amount:
                                      totalTTC[item._id]?.cancellation_fee || 0
                                  })}
                                >
                                  <InputNumber
                                    addonAfter="€"
                                    onChange={() =>
                                      Invoice.handleCreateUpdateTotalTTC({
                                        form,
                                        type,
                                        setTotalTTC,
                                        invoiceId: item._id
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            <Col span={24}>
                              <Form.Item
                                label={t('invoices.form.total_ht')}
                                name={['credit_notes', item._id, 'total_ht']}
                                key="total_ht"
                                extra={t('invoices.form.total_ttc_extra', {
                                  amount: totalTTC[item._id]?.total_ht || 0
                                })}
                              >
                                <InputNumber
                                  addonAfter="€"
                                  onChange={() =>
                                    Invoice.handleCreateUpdateTotalTTC({
                                      form,
                                      type,
                                      setTotalTTC,
                                      invoiceId: item._id
                                    })
                                  }
                                />
                              </Form.Item>
                            </Col>
                            {item.disbursement || item.disbursement === 0 ? (
                              <Col span={24}>
                                <Form.Item
                                  label={t('invoices.form.disbursement')}
                                  name={[
                                    'credit_notes',
                                    item._id,
                                    'disbursement'
                                  ]}
                                  key="disbursement"
                                  extra={t('invoices.form.total_ttc_extra', {
                                    amount:
                                      totalTTC[item._id]?.disbursement || 0
                                  })}
                                >
                                  <InputNumber
                                    addonAfter="€"
                                    onChange={() =>
                                      Invoice.handleCreateUpdateTotalTTC({
                                        form,
                                        type,
                                        setTotalTTC,
                                        invoiceId: item._id
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            {item.lawyer_fee_ht || item.lawyer_fee_ht === 0 ? (
                              <Col span={24}>
                                <Form.Item
                                  label={t('invoices.form.lawyer_fee_ht')}
                                  name={[
                                    'credit_notes',
                                    item._id,
                                    'lawyer_fee_ht'
                                  ]}
                                  key="lawyer_fee_ht"
                                  extra={t('invoices.form.total_ttc_extra', {
                                    amount:
                                      totalTTC[item._id]?.lawyer_fee_ht || 0
                                  })}
                                >
                                  <InputNumber
                                    addonAfter="€"
                                    onChange={() =>
                                      Invoice.handleCreateUpdateTotalTTC({
                                        form,
                                        type,
                                        setTotalTTC,
                                        invoiceId: item._id
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            {item.notary_fee_ttc ||
                            item.notary_fee_ttc === 0 ? (
                              <Col span={24}>
                                <Form.Item
                                  label={t('invoices.form.notary_fee_ttc')}
                                  name={[
                                    'credit_notes',
                                    item._id,
                                    'notary_fee_ttc'
                                  ]}
                                  key="notary_fee_ttc"
                                >
                                  <InputNumber
                                    addonAfter="€"
                                    onChange={() =>
                                      Invoice.handleCreateUpdateTotalTTC({
                                        form,
                                        type,
                                        setTotalTTC,
                                        invoiceId: item._id
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            ) : null}
                            <Col span={24}>
                              <Form.Item
                                name={['credit_notes', item._id, 'total_ttc']}
                                key="total_ttc"
                              >
                                <Text type="secondary">
                                  {t('invoices.form.total_billed', {
                                    amount:
                                      totalTTC[item._id]?.total_billed_show || 0
                                  })}
                                </Text>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </List.Item>
                    )}
                  />
                )
              }
            ]
          : [
              {
                name: ['tva_percentage'],
                key: 'tva_percentage',
                input: (
                  <Form.Item name={['tva_percentage']} key="tva_percentage">
                    <InputNumber
                      addonAfter="%"
                      onChange={() =>
                        Invoice.handleCreateUpdateTotalTTC({
                          form,
                          type,
                          setTotalTTC
                        })
                      }
                    />
                  </Form.Item>
                )
              },
              {
                name: ['total_ht'],
                key: 'total_ht',
                input: (
                  <Form.Item
                    name={['total_ht']}
                    key="total_ht"
                    extra={t('invoices.form.total_ttc_extra', {
                      amount: totalTTC?.total_ht || 0
                    })}
                  >
                    <InputNumber
                      addonAfter="€"
                      onChange={() =>
                        Invoice.handleCreateUpdateTotalTTC({
                          form,
                          type,
                          setTotalTTC
                        })
                      }
                    />
                  </Form.Item>
                )
              },
              ...(invoice.disbursement || invoice.disbursement === 0
                ? [
                    {
                      name: ['disbursement'],
                      key: 'disbursement',
                      input: (
                        <Form.Item
                          name={['disbursement']}
                          key="disbursement"
                          extra={t('invoices.form.total_ttc_extra', {
                            amount: totalTTC?.disbursement || 0
                          })}
                        >
                          <InputNumber
                            addonAfter="€"
                            onChange={() =>
                              Invoice.handleCreateUpdateTotalTTC({
                                form,
                                type,
                                setTotalTTC
                              })
                            }
                          />
                        </Form.Item>
                      )
                    }
                  ]
                : []),
              ...(invoice.lawyer_fee_ht || invoice.lawyer_fee_ht === 0
                ? [
                    {
                      name: ['lawyer_fee_ht'],
                      key: 'lawyer_fee_ht',
                      input: (
                        <Form.Item
                          name={['lawyer_fee_ht']}
                          key="lawyer_fee_ht"
                          extra={t('invoices.form.total_ttc_extra', {
                            amount: totalTTC?.lawyer_fee_ht || 0
                          })}
                        >
                          <InputNumber
                            addonAfter="€"
                            onChange={() =>
                              Invoice.handleCreateUpdateTotalTTC({
                                form,
                                type,
                                setTotalTTC
                              })
                            }
                          />
                        </Form.Item>
                      )
                    }
                  ]
                : []),
              ...(invoice.notary_fee_ttc || invoice.notary_fee_ttc === 0
                ? [
                    {
                      name: ['notary_fee_ttc'],
                      key: 'notary_fee_ttc',
                      input: (
                        <Form.Item
                          name={['notary_fee_ttc']}
                          key="notary_fee_ttc"
                        >
                          <InputNumber
                            addonAfter="€"
                            onChange={() =>
                              Invoice.handleCreateUpdateTotalTTC({
                                form,
                                type,
                                setTotalTTC
                              })
                            }
                          />
                        </Form.Item>
                      )
                    }
                  ]
                : [])
            ])
      ];
      break;
    default:
      break;
  }
  fields = [
    ...fields,
    ...(orders || []).map((item) => ({
      translate: false,
      label: item?.cerfa_document_type?.wording,
      name: ['orders', item._id, 'to_bill'],
      key: item._id,
      input: (
        <InputNumber
          addonAfter="€"
          onChange={() =>
            Invoice.handleCreateUpdateTotalTTC({
              form,
              type,
              setTotalTTC
            })
          }
        />
      )
    })),
    ...(!is_mission_cancellation_form
      ? [
          {
            noLabel: true,
            key: 'total_ttc',
            input: (
              <Form.Item name={['total_ttc']} key="total_ttc">
                <Text type="secondary">
                  {t('invoices.form.total_billed', {
                    amount: totalTTC.total_billed_show
                  })}
                </Text>
              </Form.Item>
            )
          }
        ]
      : [])
  ];

  return { fields };
};
