import { useTranslation } from 'react-i18next';
import {
  ColorPicker,
  Checkbox,
  Tag,
  Popconfirm,
  Row,
  Flex,
  message as antdMessage
} from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { userRoles, missionTag } from '../../../utils/constants/tagColors';
import { deleteResource } from '../../../utils/deleteResource';
import { IconsArray } from '../../../components/editableTableComponents/IconsArray';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Returns an array of columns configuration for the document repository table.
 *
 * @param {Object} options - The options object.
 * @param {function} options.setEditingKey - The function to set the editing key.
 * @param {Object} options.form - The form object.
 * @param {function} options.setSelectType - The function to set the select type.
 * @param {function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The force refresh flag.
 * @returns {Array} An array of column configuration objects.
 */

export const useColumns = ({
  setEditingKey,
  form,
  setSelectType,
  setForceRefresh,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { editCell, updateColor, handleCheckbox } = EditableTableMethods();

  const missionsDisplayRender = (record) => {
    if (
      record?.missions_display?.length &&
      record?.missions_display?.length === 15
    ) {
      return t('document-repositories.form.all_missions');
    }

    if (record?.missions_display.length) {
      return (record?.missions_display || []).map((el) => (
        <Tag color={missionTag[el]} key={el}>
          {t(`missions.tags.${el}`)}
        </Tag>
      ));
    }

    return '-';
  };

  return [
    {
      title: t('document-repositories.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            record?.default
              ? null
              : editCell({ record, key: 'wording', setEditingKey, form })
          }
        >
          {record?.wording || '-'}
        </Row>
      )
    },
    {
      title: t('document-repositories.form.archive_name'),
      key: 'archive_name',
      dataIndex: 'archive_name',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            record?.default
              ? null
              : editCell({ record, key: 'archive_name', setEditingKey, form })
          }
        >
          {record?.archive_name || '-'}
        </Row>
      )
    },
    {
      title: t('document-repositories.form.icon'),
      key: 'icon',
      dataIndex: 'icon',
      editable: true,
      inputType: 'select',
      render: (_, record) => (
        <Row
          onClick={() => {
            if (record?.default) return;
            setSelectType('icons');
            editCell({
              record,
              key: 'icon',
              setEditingKey,
              form
            });
          }}
        >
          {record?.icon
            ? IconsArray.find((icon) => icon.name === record.icon)?.icon
            : '-'}
        </Row>
      )
    },
    {
      title: t('document-repositories.form.color'),
      key: 'color',
      dataIndex: 'color',
      render: (_, record) => (
        <ColorPicker
          disabled={record.default}
          size="small"
          defaultValue={record.color ? record.color : '#000000'}
          onChangeComplete={(value) =>
            updateColor({ record, value, resource: 'document-repositories' })
          }
        />
      )
    },
    {
      title: t('document-repositories.form.by_OS'),
      key: 'by_OS',
      dataIndex: 'by_OS',
      render: (_, record) => (
        <Checkbox
          defaultChecked={record.by_OS}
          disabled={record.default}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'by_OS',
              resource: 'document-repositories',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('document-repositories.form.visible_for'),
      key: 'visible_for',
      dataIndex: 'visible_for',
      editable: true,
      inputType: 'select',
      render: (_, record) => (
        <Row
          onClick={() => {
            setSelectType('roles');
            editCell({
              record,
              key: 'visible_for',
              setEditingKey,
              form
            });
          }}
        >
          {record?.visible_for?.length
            ? (record?.visible_for || []).map((el) => (
                <Tag color={userRoles[el.split(':')[1]]} key={el}>
                  {t(`users.tags.${el.split(':')[1]}`)}
                </Tag>
              ))
            : '-'}
        </Row>
      )
    },
    {
      title: t('document-repositories.form.missions_display'),
      key: 'missions_display',
      dataIndex: 'missions_display',
      editable: true,
      inputType: 'select',
      render: (_, record) => (
        <Flex
          vertical
          justify="space-around"
          gap={1}
          onClick={() => {
            setSelectType('missions');
            editCell({
              record,
              key: 'missions_display',
              setEditingKey,
              form
            });
          }}
        >
          {missionsDisplayRender(record)}
        </Flex>
      )
    },
    {
      title: t('document-repositories.form.fee'),
      key: 'fee',
      dataIndex: 'fee',
      render: (_, record) => (
        <Checkbox
          disabled={record.default}
          defaultChecked={record.fee}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'fee',
              resource: 'document-repositories',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('document-repositories.form.disbursements'),
      key: 'disbursements',
      dataIndex: 'disbursements',
      render: (_, record) => (
        <Checkbox
          disabled={record.default}
          defaultChecked={record.disbursements}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'disbursements',
              resource: 'document-repositories',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('document-repositories.form.act_copy'),
      key: 'act_copy',
      dataIndex: 'act_copy',
      render: (_, record) => (
        <Checkbox
          disabled={record.default}
          defaultChecked={record.act_copy}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'act_copy',
              resource: 'document-repositories',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      key: 'action',
      fixed: 'right',
      width: 60,
      render: (record) =>
        record.default ? null : (
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => {
              deleteResource({
                dispatchAPI,
                resourceName: 'document-repositories',
                id: record._id,
                setForceRefresh,
                forceRefresh,
                message
              });
              antdMessage.success({
                content: t('datatable.message.success.deleteline'),
                icon: <DeleteOutlined style={{ color: 'red' }} />
              });
              return true;
            }}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'var(--errorColor)', fontSize: 16 }}
              type="delete"
            />
          </Popconfirm>
        )
    }
  ];
};
