import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Row, Select } from 'antd';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getRoles } from '../utils/getRoles';
import { getUsers } from '../utils/getUsers';
import { useAgendaContext } from '../../../contexts/AgendaContext';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';
import SelectorItems from './SelectorItems';
import { SearchResources } from '../../../components/SearchResources/SearchResources';
import { DrawerEvent } from './DrawerEvent';
import { PageHeaderCustom } from '../../../components';
import { User } from '../../../models/User';

const { Option } = Select;

/**
 * Custom hook that returns an array of columns for the CustomAgendaHeader component.
 * @hook
 * @returns {Array} An array of columns for the CustomAgendaHeader component.
 */
export const CustomAgendaHeader = ({ setExtraQuery }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isRolesLoading, setIsRoleLoading] = useState(true);
  const [isUsersLoading, setIsUserLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const { syndics } = useGlobalContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const filterOptions = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    setPurpose,
    setFormToOpen,
    setOpenedDrawer,
    setDrawerTitle,
    setCustomUser
  } = useAgendaContext();

  useEffect(() => {
    (async () => {
      if (
        userId &&
        [
          'users:NOMADE_ACCOUNT_MANAGER',
          'users:NOMADE_CUSTOMER_SERVICE_MANAGER'
        ].includes(selectedRole)
      ) {
        const user = await User.getUser({ id: userId, action: setCustomUser });
        setCustomUser(user);
      } else {
        setCustomUser(null);
      }
    })();
  }, [userId]);

  const showDrawer = (formToOpen) => {
    setOpenedDrawer(formToOpen.name);
    setDrawerTitle(formToOpen.title);
    setFormToOpen(formToOpen.name);
    setIsDrawerOpen(true);
  };

  useEffect(() => {
    (async () => {
      await getRoles({
        dispatchAPI,
        setRoles,
        message,
        forceRefresh,
        setForceRefresh
      });
      setIsRoleLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setUsers([]);
      await getUsers({
        dispatchAPI,
        setUsers,
        role: selectedRole,
        message
      });
      setIsUserLoading(false);
    })();
  }, [selectedRole]);

  return (
    <>
      <PageHeaderCustom title="Agenda" />
      {isDrawerOpen ? <DrawerEvent /> : null}
      <Card
        style={{
          border: 'none'
        }}
      >
        <Card
          style={{
            backgroundColor: 'white',
            border: 'none'
          }}
        >
          <Button
            type="primary"
            style={{
              marginRight: 10
            }}
            onClick={() => {
              setExtraQuery('');
              setCustomUser(null);
              setForceRefresh(!forceRefresh);
            }}
          >
            {t('events.myagenda')}
          </Button>
          <SelectorItems
            placeholder={t('events.user_role')}
            data={roles}
            isNavigable={false}
            loading={isRolesLoading}
            itemName="role"
            customAction={(value) => {
              setSelectedRole(value);
              if (value === '') {
                setExtraQuery('');
                setUserId(null);
              } else {
                setExtraQuery(
                  `userTargetRole=${value}&populate=user,manager,syndic`
                );
              }
            }}
            forceRefresh={forceRefresh}
            setForceRefresh={setForceRefresh}
            onClear={() => {
              setExtraQuery('');
              setForceRefresh(!forceRefresh);
            }}
          />
          <Select
            style={{
              width: 250,
              marginRight: 10
            }}
            showSearch
            allowClear
            onClear={() => {
              setExtraQuery('');
              setForceRefresh(!forceRefresh);
            }}
            optionFilterProp="children"
            filterOption={(input, option) => filterOptions(input, option)}
            onSelect={(value) => {
              setUserId(value);
              setExtraQuery(`userTarget=${value}&populate=user,manager,syndic`);
              setForceRefresh(!forceRefresh);
            }}
            loading={isUsersLoading}
            placeholder={t('events.user')}
            onChange={() => {
              setForceRefresh(!forceRefresh);
            }}
          >
            {(users || []).map((item) => (
              <Option value={item._id} key={item._id}>
                {`${item.first_name} ${item.last_name}`}
              </Option>
            ))}
          </Select>
        </Card>
        <Card
          style={{
            backgroundColor: 'white',
            border: 'none'
          }}
        >
          <Row
            gutter={[16, 16]}
            style={{
              marginBottom: 10
            }}
          >
            <Col>
              <SearchResources />
            </Col>
            <Col>
              <Select
                style={{
                  width: 250,
                  marginRight: 10
                }}
                showSearch
                allowClear
                onClear={() => {
                  setExtraQuery('');
                  setForceRefresh(!forceRefresh);
                }}
                optionFilterProp="children"
                filterOption={(input, option) => filterOptions(input, option)}
                onSelect={(value) => {
                  setExtraQuery(`syndic=${value}&populate=user,manager,syndic`);
                  setForceRefresh(!forceRefresh);
                }}
                loading={isUsersLoading}
                placeholder={t('events.syndic')}
                onChange={() => {
                  setForceRefresh(!forceRefresh);
                }}
              >
                {(syndics || []).map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.society_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setPurpose('create');
                  showDrawer({
                    name: 'PCS_APPOINTMENT',
                    title: t('events.creates.extern')
                  });
                }}
              >
                {t('events.creates.extern')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setPurpose('create');
                  showDrawer({
                    name: 'INTERN',
                    title: t('events.creates.intern')
                  });
                }}
              >
                {t('events.creates.intern')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setPurpose('create');
                  showDrawer({
                    name: 'UNAVAILABILITY',
                    title: t('events.creates.unavailability')
                  });
                }}
              >
                {t('events.creates.unavailability')}
              </Button>
            </Col>
            <Col>
              <MenuOutlined
                style={{
                  fontSize: 20
                }}
              />
            </Col>
          </Row>
        </Card>
      </Card>
    </>
  );
};

CustomAgendaHeader.propTypes = {
  setExtraQuery: PropTypes.func.isRequired
};

CustomAgendaHeader.defaultProps = {};
