import { BaseModel } from './BaseModel';

/**
 * Represents an spf or departmental archive.
 * @class
 */
export class SpfDepartmentalArchive {
  static resourceName = 'spf-departmental-archives';

  /**
   * Get the resources.
   * @param {string} [populate=''] - The fields to populate.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources(populate = '') {
    const { data } = await BaseModel.dispatchAPI('GET', {
      url: `${SpfDepartmentalArchive.resourceName}?populate=${populate}`
    });
    return data;
  }
}
