import { Route, Routes } from 'react-router-dom';
import { ListPredefinedMessages } from './ListPredefinedMessages';
import { CreateUpdatePredefinedMessage } from './CreateUpdatePredefinedMessage';
import { ShowPredefinedMessage } from './ShowPredefinedMessage';
import { Exception } from '../../../components';

/**
 * Renders the router for predefined messages.
 * @returns {JSX.Element} The router component.
 */
export const PredefinedMessageRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdatePredefinedMessage purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdatePredefinedMessage purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowPredefinedMessage />} />
    <Route index element={<ListPredefinedMessages />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
