import { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ServicesCustomContent } from '../../services/ServicesCustomContent';
import { dashboardTitle } from '../../services/ComplianceElanRouter/dashboardTitles';
import mes_brouillons_1 from '../../../../assets/images/mes_brouillons_1.svg';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { ManagerColumn } from './ManagerColumn';
import { ManagersKanbanHeader } from './ManagersKanbanHeader';

/**
 * @component
 * Renders the ManagersKanban component.
 * This component displays a kanban board for managing managers' wallets.
 *
 * @returns {JSX.Element} The rendered ManagersKanban component.
 */
export const ManagersKanban = () => {
  const { dispatchAPI } = useAuthContext();
  const { currentSyndic } = useGlobalContext();
  const { manageWalletTitle, manageWalletSubtitle } = dashboardTitle();
  const [searchValue, setSearchValue] = useState('');
  const [managers, setManagers] = useState([]);
  const { message } = useErrorMessage();
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();

  const unsasinged = {
    first_name: t('syndic-view.managers-kanban.unassigned_first_name'),
    last_name: ' ',
    _id: t('syndic-view.managers-kanban.unassigned')
  };

  const fetchManagers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?syndic=${currentSyndic}&sort=-createdAt`
      });
      setManagers([unsasinged, ...data]);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchManagers();
    })();
  }, [refresh]);

  return (
    <ServicesCustomContent
      title={manageWalletTitle}
      subtitle={manageWalletSubtitle}
      imageOnRight={mes_brouillons_1}
      cardStyle={{ backgroundColor: '#222222' }}
      childrenWithAsHeader
    >
      <Flex vertical gap={20} style={{ width: '100%' }}>
        <ManagersKanbanHeader
          setSearchValue={setSearchValue}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        <DndProvider backend={HTML5Backend}>
          <Flex
            style={{
              width: '100%',
              borderRadius: '15px',
              backgroundColor: '#a7bad6',
              overflowX: 'auto'
            }}
          >
            {managers.map((manager) => (
              <ManagerColumn
                key={manager._id}
                manager={manager}
                searchValue={searchValue}
              />
            ))}
          </Flex>
        </DndProvider>
      </Flex>
    </ServicesCustomContent>
  );
};
