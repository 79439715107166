import { message } from 'antd';

/**
 * Update the history of a resource.
 * @function
 * @async
 * @param {Object} options - The options for updating the history.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {string} options.id - The ID of the resource.
 * @param {string} options.resourceName - The name of the resource.
 * @param {Object} options.values - The values to update the history with.
 * @param {function} [options.setForceRefresh] - The function to set the force refresh state.
 * @param {boolean} [options.forceRefresh] - The force refresh state.
 * @returns {Promise<void>} - A promise that resolves when the history is updated.
 */
export const updateHistory = async ({
  dispatchAPI,
  id,
  resourceName,
  values,
  setForceRefresh,
  forceRefresh
}) => {
  try {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    await dispatchAPI('PATCH', {
      url: `${resourceName}/patch-history/${id}`,
      body: formData
    });

    if (setForceRefresh) setForceRefresh(!forceRefresh);
  } catch (error) {
    message.error(error.message);
  }
};
