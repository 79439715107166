import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * A collection of methods related to SPF departmental archives.
 * @returns {Object} An object containing the `getSpfDepartmentalArchives` method.
 */
export const SpfDepartmentalArchivesMethods = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Retrieves SPF departmental archives.
   * @param {Object} options - The options for retrieving SPF departmental archives.
   * @param {string} options.spfDepartmentalArchive - The ID of the SPF departmental archive to exclude from the results.
   * @returns {Promise<Array<Object>>|null} A promise that resolves to an array of SPF departmental archives, or null if no data is available.
   */
  const getSpfDepartmentalArchives = async ({ spfDepartmentalArchive }) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `spf-departmental-archives?_id!=${spfDepartmentalArchive?._id}`
      });

      if (!data) {
        return null;
      }

      return data;
    } catch (error) {
      return message(error);
    }
  };

  return { getSpfDepartmentalArchives };
};
