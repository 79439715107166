import { Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/matrixFinalizationFields';
import { useConfig } from '../utils/matrixFinalizationCreateUpdateConfig';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Renders the MatrixFinalizationDrawer component.
 * @component
 * This component is responsible for displaying a drawer that allows users to edit the finalization matrix for a mission.
 * @returns {JSX.Element} The MatrixFinalizationDrawer component.
 */
export const MatrixFinalizationDrawer = () => {
  const {
    forceRefresh,
    setForceRefresh,
    isDrawerOpen,
    setIsDrawerOpen,
    isLoading
  } = useMissionContext();

  const [form] = Form.useForm();
  const { fields } = useFields({ form });
  const { t } = useTranslation();

  const config = useConfig();

  return (
    <Drawer
      title={t('missions.drawers.titles.matrix_finalization')}
      onClose={() =>
        setIsDrawerOpen((prev) => ({ ...prev, matrix_finalization: false }))
      }
      open={isDrawerOpen.matrix_finalization}
      width={600}
    >
      <CreateUpdateContainer
        customFormInstance={form}
        fields={fields}
        config={config}
        isFieldsLoading={isLoading}
        purpose="edit"
        baseUrl="missions"
        resource="missions"
        withFilesManager={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{ ...isDrawerOpen, matrix_finalization: false }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
