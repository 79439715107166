import { Select, Tag, Form, InputNumber, Checkbox, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;

/**
 * Custom hook that returns an array of fields for a user form.
 * @hook
 * @param {Object} options - The options object.
 * @param {Object} options.enums - The enums object.
 * @param {boolean} options.isFieldsLoading - Flag indicating if the fields are loading.
 * @param {Function} options.setRole - The function to set the role.
 * @param {Array} options.syndicOptions - The array of syndic options.
 * @param {Function} options.setSyndic - The function to set the syndic.
 * @param {string} options.role - The role of the user.
 * @param {Array} options.assistants - The array of assistants.
 * @param {Array} options.managers - The array of managers.
 * @param {Object} options.form - The form object.
 * @returns {Object} - The fields object.
 */
export const useFields = ({
  enums,
  isFieldsLoading,
  setRole,
  syndicOptions,
  setSyndic,
  role,
  assistants,
  managers,
  form
}) => {
  const { t } = useTranslation();
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['last_name'],
      key: 'last_name',
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      key: 'first_name',
      rules: [{ required: true }]
    },
    {
      name: ['role'],
      key: 'role',
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} onChange={(el) => setRole(el)}>
          {(enums?.roles || []).map((r) => (
            <Option key={r} value={r}>
              <Tag
                style={{
                  color: userRoles[r.split(':')[1]],
                  borderColor: userRoles[r.split(':')[1]]
                }}
              >
                {t(`users.tags.${r.split(':')[1]}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['email'],
      key: 'email',
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['phone_number'],
      key: 'phone_number',
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <InputNumber style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['address', 'number'],
      key: 'number'
    },
    {
      name: ['address', 'street'],
      key: 'street'
    },
    {
      name: ['address', 'additional'],
      key: 'additional'
    },
    {
      name: ['address', 'postal_code'],
      key: 'postal_code'
    },
    {
      name: ['address', 'city'],
      key: 'city'
    },
    ...(role === 'users:LAWYER'
      ? [
          {
            name: ['maximum_missions_number'],
            key: 'maximum_missions_number',
            rules: [{ required: true }],
            input: <InputNumber min={1} />
          }
        ]
      : []),
    ...(role === 'users:PRESTATION_WORD'
      ? [
          {
            noLabel: true,
            name: ['prestation_word'],
            key: 'prestation_word',
            input: <Checkbox>{t('users.form.prestation_word')}</Checkbox>
          }
        ]
      : []),

    ...([
      'users:SYNDIC_MANAGER',
      'users:SYNDIC_ASSISTANT',
      'users:SYNDIC_COUNCIL'
    ].includes(role)
      ? [
          {
            name: ['syndic'],
            key: 'syndic',
            rules: [{ required: true }],
            input: (
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={filterOption}
                loading={isFieldsLoading}
                onChange={(e, option) => {
                  setSyndic(option?.syndic);
                  form.setFieldsValue({
                    assistants: undefined,
                    managers: undefined
                  });
                }}
              >
                {syndicOptions.map((item) => (
                  <Option value={item._id} key={item._id} syndic={item}>
                    {item.society_name}
                  </Option>
                ))}
              </Select>
            )
          },
          ...(role === 'users:SYNDIC_ASSISTANT'
            ? [
                {
                  name: ['managers'],
                  key: 'managers',
                  rules: [{ required: true }],
                  input: (
                    <Select
                      mode="multiple"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={filterOption}
                      loading={isFieldsLoading}
                    >
                      {managers?.map((item) => (
                        <Option value={item._id} key={item._id}>
                          {`${item.last_name} ${item.first_name}`}
                        </Option>
                      ))}
                    </Select>
                  )
                }
              ]
            : []),
          ...(role === 'users:SYNDIC_MANAGER'
            ? [
                {
                  name: ['assistants'],
                  key: 'assistants',
                  input: (
                    <Select
                      mode="multiple"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={filterOption}
                      loading={isFieldsLoading}
                    >
                      {assistants.map((item) => (
                        <Option value={item._id} key={item._id}>
                          {`${item.last_name} ${item.first_name}`}
                        </Option>
                      ))}
                    </Select>
                  )
                }
              ]
            : [])
        ]
      : [])
  ];

  return { fields };
};
