import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { formatAddress } from '../../../../syndic-view/utils/formatingFunctions';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const tradKey = 'dashboard.production.cards.quote_generation.drawer.form';

/**
 * @hook
 * Custom hook for managing fields in the QuoteGenerationCard component.
 * @returns {Object} An object containing the fields.
 */
export const useFields = () => {
  const { syndics } = useGlobalContext();
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);
  const [selectedSyndic, setSelectedSyndic] = useState('');
  const { dispatchAPI } = useAuthContext();
  const [enums, setEnums] = useState({ MAIN_TYPES: [], SUB_TYPES: [] });
  const [selectedQuote, setSelecteQuote] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const formattedSyndics = syndics.map((syndic) => ({
    label: syndic.society_name,
    value: syndic._id
  }));

  const getCollectiveOwnership = async () => {
    if (!selectedSyndic) return;
    const fields = 'name,first_address,first_city.name';
    const populate = 'first_city';
    const { data } = await dispatchAPI('GET', {
      url: `/collective-ownership?syndic=${selectedSyndic}&populate=${populate}&fields=${fields}`
    });
    const dataToSet = data.map(({ _id, name, first_address, first_city }) => ({
      label: formatAddress(name, first_address, first_city),
      value: _id
    }));
    setCollectiveOwnerships(dataToSet);
  };

  const getTemplateEnums = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/template-documents/enums/QUOTE_TYPES'
    });
    const objToSet = {
      MAIN_TYPES: data.MAIN_TYPES.map((type) => ({
        label: t(`${tradKey}.quote_types.${type}`),
        value: type
      })),
      RCP_RESEARCH_SUBTYPES: data.RCP_RESEARCH_SUBTYPES.map((type) => ({
        label: t(`${tradKey}.quote_types.${type}`),
        value: type
      }))
    };
    setEnums(objToSet);
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        await Promise.all([getCollectiveOwnership(), getTemplateEnums()]);
      } catch (error) {
        message(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedSyndic]);

  const fields = [
    {
      name: ['quote'],
      rules: [{ required: true }],
      input: <Select options={enums.MAIN_TYPES} onChange={setSelecteQuote} />
    },
    ...(selectedQuote === 'RCP_RESEARCH'
      ? [
          {
            name: ['rcp_research_subtype'],
            rules: [{ required: true }],
            input: <Select options={enums.RCP_RESEARCH_SUBTYPES} />
          }
        ]
      : []),
    {
      name: ['syndic'],
      rules: [{ required: true }],
      input: <Select options={formattedSyndics} onChange={setSelectedSyndic} />
    },
    {
      name: ['collective_ownership'],
      rules: [{ required: true }],
      input: <Select options={collectiveOwnerships} loading={isLoading} />
    }
  ];

  return { fields };
};
