import dayjs from 'dayjs';
import { onUpdateResourceBody } from './onUpdateResourceBody';
import { onCreateResourceBody } from './onCreateResourceBody';

/**
 * Creates or updates a configuration.
 * @param {Object} options - The options for creating or updating a configuration.
 * @param {Array} options.prices - The array of prices.
 * @param {Object} options.priceToEdit - The price to edit.
 * @param {Function} options.setPriceData - The function to set the price data.
 * @param {Function} options.t - The translation function.
 * @returns {Object} - The configuration object.
 */
export const useConfig = ({ prices, priceToEdit, setPriceData, t }) => ({
  onCreateResource: {
    setBody: (data) => onCreateResourceBody({ prices, data, t })
  },

  onUpdateResource: {
    setBody: (data) => onUpdateResourceBody({ prices, data, priceToEdit, t })
  },

  onGetResource: {
    setFields: (data) => {
      setPriceData({ mission: data.mission, type: data.type });
      const newData = {
        ...data,
        start_date: data?.start_date && dayjs(data.start_date),
        end_date: data?.end_date && dayjs(data.end_date)
      };
      return newData;
    }
  }
});
