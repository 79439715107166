export const getEnums = async ({ dispatchAPI, setEnums, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/users/enums?selected_enums=create_update_user'
    });
    setEnums(data);

    const { data: collectiveOwnershipEnums } = await dispatchAPI('GET', {
      url: '/collective-ownerships/enums'
    });

    collectiveOwnershipEnums.collective_ownership_status =
      collectiveOwnershipEnums.status;
    delete collectiveOwnershipEnums.status;

    setEnums((prevState) => ({ ...prevState, ...collectiveOwnershipEnums }));
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
