import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { missionTag } from '../../../../../utils/constants/tagColors';
import { stepTitlesAudit } from '../../ComplianceElanRouter/Audit/CreateUpdateAudit';
import { stepTitlesCompliance } from '../../ComplianceElanRouter/Compliance/CreateUpdateCompliance';
import { stepTitlesPublication } from '../../ComplianceElanRouter/Publication/CreateUpdatePublication';
import { formatAddress } from '../../../utils/formatingFunctions';

/**
 * Custom hook that returns an array of columns for a table.
 * @hook
 * @param {Object} options - The options object.
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const selectStep = (mission, step) => {
    switch (mission) {
      case 'PCS_PRE_PRODUCTION':
        return stepTitlesAudit[step];
      case 'PCS_2':
        return stepTitlesCompliance[step];
      case 'PCS_3':
        return stepTitlesPublication[step];
      case 'PLANS_RESEARCH':
      case 'RCP_RESEARCH':
        return stepTitlesPublication[step];
      default:
        return {};
    }
  };

  return [
    {
      title: t('collective-ownerships.form.collective_ownership_address'),
      key: ['collective_ownership'],
      render: (mainData, rec) => {
        let data = mainData;
        if (rec.pcs_pre_production) data = rec.pcs_pre_production;
        const { collective_ownership, new_collective_ownership } = data;
        const customName = data.name;
        const record = collective_ownership || new_collective_ownership || {};
        const { name, first_address, first_city } = record;
        return formatAddress(customName || name, first_address, first_city);
      }
    },
    {
      title: t('collective-ownerships.form.mission'),
      key: ['requested_mission_type'],
      dataIndex: ['requested_mission_type'],
      render: (mission) => {
        const record = mission === 'PCS_PRE_PRODUCTION' ? 'AUDIT' : mission;
        return (
          <Tag
            color={missionTag[record]}
            style={{
              display: 'block',
              marginBottom: '8px',
              textAlign: 'center'
            }}
          >
            {t(`missions.tags.${record}`)}
          </Tag>
        );
      }
    },
    {
      title: t('collective-ownerships.form.phase'),
      key: ['draft_step'],
      render: (status) => {
        const mission = status?.requested_mission_type;
        const tradKey =
          status.requested_mission_type !== 'PCS_PRE_PRODUCTION'
            ? `missions.form`
            : `pcs-pre-productions.form`;
        const step = status?.draft_step;

        return `${t(`${tradKey}.${selectStep(mission, step)}`)}`;
      }
    },
    {
      title: t('collective-ownerships.form.last_update'),
      key: ['updated_at'],
      dataIndex: ['updated_at'],
      render: (date) => dayjs(date).format('DD/MM/YYYY HH:mm')
    }
  ];
};
