import { Tag, Row, Col, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { missionTag } from '../../../../../utils/constants/tagColors';
import { ListDocuments } from '../components/ListDocuments';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * Sets the collapse items for the mission view.
 *
 * @hook
 * @returns {Array} The array of collapse items.
 */
export const setCollapseItems = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { configDocuments, documents, documentType, missionTypeForDocuments } =
    useMissionContext();

  const documentRepositoriesDatas = missionTypeForDocuments
    ? configDocuments.document_repositories[missionTypeForDocuments]
    : configDocuments.document_repositories;

  const filterDocuments = (document_repository) =>
    documents.filter((document) => {
      const matchesKey = document?.config_document
        ? document?.config_document?.document_type?.document_key ===
          document_repository?.document_key
        : document?.document_key === document_repository?.document_key;

      const matchesRef =
        missionTypeForDocuments ||
        document?.config_document_ref === documentType;

      return matchesKey && matchesRef;
    });

  const documentRepositoriesDatasArray = Array.isArray(
    documentRepositoriesDatas
  )
    ? documentRepositoriesDatas
    : [];

  const filtredDocumentRepositoriesDatasArrayByRole =
    documentRepositoriesDatasArray.filter((el) =>
      (el.visible_for || []).includes(user.role)
    );

  const collapseItems = (filtredDocumentRepositoriesDatasArrayByRole || []).map(
    (document_repository, index) => {
      const filteredDocuments = filterDocuments(document_repository);
      const documentNumber = filteredDocuments.length;
      const isExpectedDocument = filteredDocuments.find(
        (document) => document.status === 'EXPECTED'
      );

      const columnType = () => {
        switch (document_repository.wording) {
          case 'Assemblée générale':
            return 'generalAssemblyColumns';
          case 'Échange de documents':
            return 'exchangeDocumentsColumns';
          case 'Projet de mise en conformité intermédiaire':
            return 'intermediateProjectColumns';
          case 'Projet de mise en conformité':
            return 'definitiveProjectColumns';
          case 'Finalisation de la mise en conformité':
            return 'definitiveProjectColumns';
          case 'Documents Syndic':
            return 'requestedDocumentSyndicColumns';
          case "Préparation du projet d'acte":
            return 'generalAssemblyColumns';
          case 'Phase 1 - Audit':
            return 'auditColumns';
          default:
            break;
        }
        if (document_repository.act_copy) {
          return 'actCopyColumns';
        }
        return 'otherColumns';
      };

      const searchByConfigDocumentRef = () => {
        switch (document_repository.wording) {
          case 'Projet de mise en conformité intermédiaire':
            return false;
          case 'Documents Syndic':
            return false;
          case 'Phase 1 - Audit':
            return false;
          case 'Finalisation de la mise en conformité':
            return false;
          default:
            return true;
        }
      };

      return {
        key: String(index),
        label: (
          <Row gutter={[16, 16]}>
            <Col>{document_repository.wording}</Col>
            <Col>
              <Badge count={documentNumber} />
            </Col>
          </Row>
        ),
        extra: isExpectedDocument ? (
          <Tag color={missionTag[isExpectedDocument.status]}>
            {t(`missions.tags.${isExpectedDocument.status}`)}
          </Tag>
        ) : null,
        children: (
          <ListDocuments
            search_by_document_ref={searchByConfigDocumentRef()}
            columns_type={columnType()}
            document_repository_key={document_repository.document_key}
          />
        )
      };
    }
  );

  return { collapseItems };
};
