import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Handles the saving of a record in the table.
 * @function
 * @param {Object} params - Function parameters
 * @param {string} params.key - The key of the record being edited
 * @param {Object[]} params.dataSource - The current data source for the table
 * @param {Object} params.chargingGrid - The charging grid data
 * @param {Function} params.dispatchAPI - Function for making API requests
 * @param {Function} params.setChargingGrid - Function for updating charging grid data
 * @param {Function} params.message - Function for displaying messages
 * @param {FormInstance} params.tableForm - The form instance for the table
 * @param {Function} params.setIsFinalVersion - Function for setting the final version flag
 * @param {Function} params.setEditKey - Function for setting the edit key
 * @param {Object} params.resourceDatas - The resource object
 * @param {Function} params.setResourceDatas - Function for setting resource data
 * @returns {void}
 */

export const handleSave = async ({
  key,
  dataSource,
  chargingGrid,
  dispatchAPI,
  setChargingGrid,
  message,
  tableForm,
  setIsFinalVersion,
  setEditKey,
  resourceDatas,
  setResourceDatas
}) => {
  try {
    const row = await tableForm.validateFields();
    const newData = [...dataSource];
    const index = newData.findIndex((item) => Number(key) === Number(item.key));

    const lot = newData[index] ? [newData[index]] : [];

    const newRow = {};
    Object.keys(row).forEach((objectKey) => {
      if (row[objectKey] !== undefined && row[objectKey] !== null) {
        if (row[objectKey].value) {
          newRow[objectKey] = Number.isNaN(parseInt(row[objectKey], 10))
            ? row[objectKey]
            : Number(row[objectKey]);
        } else {
          newRow[objectKey] = {
            value: Number.isNaN(parseInt(row[objectKey], 10))
              ? row[objectKey]
              : Number(row[objectKey]),
            old_value: lot.length ? lot[0][objectKey]?.old_value : null
          };
        }
      }
    });

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...newRow });

      const formData = new FormData();
      try {
        formData.append('values', JSON.stringify(newData));

        const { data } = await dispatchAPI('PATCH', {
          url: `/charging-grids/${chargingGrid._id}?type=edit_data`,
          body: formData
        });
        updateChargingGrids({
          resourceDatas,
          chargingGrid: data,
          setResourceDatas
        });
        setChargingGrid(data);
        setEditKey(undefined);
      } catch (e) {
        message(e);
      }
    } else {
      newData.push(row);
      const formData = new FormData();
      try {
        formData.append('values', JSON.stringify(newData));

        const data = await dispatchAPI('PATCH', {
          url: `/charging-grids/${chargingGrid._id}?type=add_row`,
          body: formData
        });
        updateChargingGrids({
          resourceDatas,
          chargingGrid: data,
          setResourceDatas
        });
        setChargingGrid(data);
        setEditKey(undefined);
      } catch (e) {
        message(e);
      }
    }
  } catch (errInfo) {
    message('Validate Failed:', errInfo);
  }
  setIsFinalVersion(false);
  return null;
};
