import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListTemplateDocs from '../utils/templateComponents/ListTemplateDocs';
import { DocEditor } from '../utils/templateComponents/DocEditor/DocEditor';
import { Exception } from '../../../components';
import { ShowTemplate } from '../utils/templateComponents/ShowTemplate';

export const FaqTemplateRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<DocEditor purpose="create" type="FAQ_TEMPLATES" />}
    />
    <Route
      path="/edit/:id"
      element={<DocEditor purpose="edit" type="FAQ_TEMPLATES" />}
    />
    <Route path="/show/:id" element={<ShowTemplate type="FAQ_TEMPLATES" />} />
    {/* ---------------------------------------------------------- */}
    <Route index element={<ListTemplateDocs type="FAQ_TEMPLATES" />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
