/* eslint-disable react/forbid-prop-types */
import { Button, Col, Row, message as antdMessage } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../../components';
import { useConfig } from './utils/createUpdateConfig';
import { GetEnums } from '../../../../utils/getEnums';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import copropriete_form_background from '../../../../assets/images/copropriete_form_background.jpg';

/**
 * Component for creating or updating a collective ownership form without connection.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the form.
 * @param {boolean} props.isEditable - Indicates if the form is editable.
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateCollectiveOwnershipFormNoConnection = ({
  purpose = '',
  isEditable = false
}) => {
  const [searchParams] = useSearchParams();
  const mission_id = searchParams.get('missionId');
  const [enums, setEnums] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { getEnums } = GetEnums();
  const { t } = useTranslation();
  const [missionId, setMissionId] = useState(mission_id);
  const [mission, setMission] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const navigate = useNavigate();

  const getMissionData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `collective-ownership-forms/mission/${missionId}`
      });
      setMission(data);
    } catch (error) {
      message(error);
    }
  };

  const getSelectOptions = useCallback(async () => {
    try {
      const enumsDatas = await getEnums({
        resource: 'collective-ownership-forms'
      });
      setEnums(enumsDatas);
    } catch (error) {
      message(error);
    }
  }, []);

  useEffect(() => {
    if (!missionId) return;
    setIsLoading(true);
    (async () => {
      try {
        await Promise.all([getMissionData(), getSelectOptions()]);
      } catch (error) {
        message(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getSelectOptions, missionId]);

  const config = useConfig({
    syndic: mission.syndic,
    collectiveOwnership: mission.collective_ownership,
    mission,
    setMissionId
  });
  const { fields } = useFields({ enums, isEditable, mission, purpose });

  return (
    <Row
      loading={isLoading}
      style={{
        backgroundImage:
          purpose === 'create' ? `url(${copropriete_form_background})` : null,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh'
      }}
    >
      <Col span={12} offset={6}>
        <CreateUpdateContainer
          config={config}
          style={{
            borderRadius: '10px',
            overflowY: 'auto',
            maxHeight: '95vh',
            padding: '1rem 4rem'
          }}
          fields={fields}
          purpose={purpose}
          loading={isLoading}
          baseUrl="collective-ownership-forms"
          resource="collective-ownership-forms"
          layout="vertical"
          withCustomButtons={isEditable}
          customActionButtons={
            isEditable && (
              <Button type="primary" onClick={() => navigate(-1)}>
                {t('buttons.back')}
              </Button>
            )
          }
          successMessage={() =>
            antdMessage.success(t('collective-ownership-forms.form_sent'))
          }
          withEnums={false}
          withFilesManager={false}
          withCustomPageHeader
          customLabelCol={{}}
          customWrapperCol={{}}
          disabledSubmit={
            isEditable || mission.co_owner_form_status === 'CLOSED'
          }
        />
      </Col>
    </Row>
  );
};

CreateUpdateCollectiveOwnershipFormNoConnection.propTypes = {
  purpose: Proptypes.string,
  isEditable: Proptypes.bool
};

CreateUpdateCollectiveOwnershipFormNoConnection.defaultProps = {
  purpose: '',
  isEditable: false
};
