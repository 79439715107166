import { Select, DatePicker, Form, Space, Button, Row, Col } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

/**
 * Renders a list of services in a form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.enums - The enums object containing cerfa types.
 * @param {boolean} props.isFieldsLoading - Indicates if the fields are loading.
 * @param {Array} props.spfDepartmentalArchives - The array of departmental archives.
 * @param {string} props.id - The ID of the data.
 * @returns {JSX.Element} The rendered component.
 */
export const ServicesFormList = ({
  enums,
  isFieldsLoading,
  spfDepartmentalArchives,
  id
}) => {
  const { t } = useTranslation();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form.List name={id ? [id, 'services'] : 'services'}>
      {(listFields, { add, remove }) => {
        if (listFields.length < 2) add();
        return (
          <>
            {listFields.map(({ key, name, ...restField }, index) => (
              <Row
                gutter={[24, 24]}
                key={key}
                style={{
                  marginBottom: '10px'
                }}
              >
                <Col
                  span={24}
                  className="add-service-field"
                  style={{ paddingRight: '30px' }}
                >
                  <Space style={{ display: 'flex' }} direction="vertical">
                    <Form.Item
                      {...restField}
                      name={[name, 'cerfa_type']}
                      label={t('municipalities.form.cerfa_type')}
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={filterOption}
                        loading={isFieldsLoading}
                      >
                        {(enums.cerfa_types || []).map((item) => (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'competent_authority']}
                      label={t('municipalities.form.competent_authority')}
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={filterOption}
                        loading={isFieldsLoading}
                      >
                        {(spfDepartmentalArchives || []).map((item) => (
                          <Option value={item._id} key={item._id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'start_date']}
                      label={t('municipalities.form.start_date')}
                    >
                      <DatePicker
                        format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'end_date']}
                      label={t('municipalities.form.end_date')}
                    >
                      <DatePicker
                        format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                      />
                    </Form.Item>
                    {index > 1 ? (
                      <CloseCircleOutlined
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          fontSize: '16px',
                          cursor: 'pointer'
                        }}
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </Space>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {t('municipalities.form.buttons.add_service')}
              </Button>
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

ServicesFormList.propTypes = {
  enums: PropTypes.shape({
    cerfa_types: PropTypes.arrayOf(PropTypes.string)
  }),
  isFieldsLoading: PropTypes.bool,
  spfDepartmentalArchives: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string
};

ServicesFormList.defaultProps = {
  enums: {},
  isFieldsLoading: false,
  spfDepartmentalArchives: [],
  id: null
};
