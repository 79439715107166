import { Col, Divider, Form, Row } from 'antd';
import { ServicesCustomContent } from '../../services/ServicesCustomContent';
import Missions_RCP_ELAN from '../../../../assets/images/Missions_RCP_ELAN.svg';
import { dashboardTitle } from '../../services/ComplianceElanRouter/dashboardTitles';
import { useFields } from './useFields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem/generateFormItem';
import { CreateUpdateContainer } from '../../../../components';
import { useConfig } from './useConfig';

/**
 * @component
 * TransferCollectiveOwnership component.
 * Renders a form for transferring collective ownership.
 *
 * @returns {JSX.Element} The TransferCollectiveOwnership component.
 */
export const TransferCollectiveOwnership = () => {
  const [form] = Form.useForm();
  const config = useConfig();
  const generateFields = useGenerateFormItem();
  const { manageCollectiveOwnershipTitle, manageCollectiveOwnershipSubtitle } =
    dashboardTitle();
  const { fieldsRight, fieldsLeft } = useFields();

  const forms = [
    <Row gutter={[24, 24]}>
      <Col span={12}>
        {fieldsLeft.map((field) => generateFields('syndic-requests', field))}
      </Col>
      <Col span={2} align="center">
        <Divider type="vertical" style={{ height: '100%' }} />
      </Col>
      <Col span={10}>
        {fieldsRight.map((field) => generateFields('syndic-requests', field))}
      </Col>
    </Row>
  ];

  return (
    <ServicesCustomContent
      title={manageCollectiveOwnershipTitle}
      subtitle={manageCollectiveOwnershipSubtitle}
      imageOnRight={Missions_RCP_ELAN}
      containerStyle={{ width: '100%', borderRadius: '15px' }}
      contentStyle={{ borderRadius: '15px' }}
    >
      <CreateUpdateContainer
        customFormInstance={form}
        formExtra={forms[0]}
        isFieldsLoading={false}
        purpose="create"
        baseUrl="syndic-requests"
        resource="syndic-requests"
        layout="vertical"
        config={config}
        withFilesManager={false}
        withEnums={false}
        withCustomPageHeader
        customLabelCol={{}}
        customWrapperCol={{}}
        customSubmitButtonStyle={{ marginTop: '20px' }}
      />
    </ServicesCustomContent>
  );
};
