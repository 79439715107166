import { Link } from 'react-router-dom';
import {
  Tag,
  Popconfirm,
  Divider,
  Typography,
  message as antdMessage
} from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  StopOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { userRoles, accessTag } from '../../utils/constants/tagColors';
import { deleteResource } from '../../utils/deleteResource';
import { blockUser } from './utils/blockUser';

const { Text } = Typography;

export const useColumns = ({
  enums,
  dispatchAPI,
  message,
  setForceRefresh,
  forceRefresh
}) => {
  const { t } = useTranslation();

  const handleConfirm = async (record) => {
    await blockUser({
      dispatchAPI,
      id: record._id,
      setForceRefresh,
      forceRefresh,
      message,
      value:
        record?.access === 'NOT_BLOCKED'
          ? { access: 'BLOCKED' }
          : { access: 'NOT_BLOCKED' }
    });
    antdMessage.success(
      t(
        `datatable.column.action.user.${
          record?.access === 'NOT_BLOCKED' ? 'BLOCKED' : 'NOT_BLOCKED'
        }.success`,
        {
          user: `${record?.first_name} ${record?.last_name}`
        }
      )
    );
  };

  return [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) =>
        a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase())
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) =>
        a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: ['role'],
      render: (role) =>
        role ? (
          <Tag
            style={{
              color: userRoles[role.split(':')[1]],
              borderColor: userRoles[role.split(':')[1]]
            }}
          >
            {t(`users.tags.${role.split(':')[1]}`, {
              defaultValue: role.split(':')[1]
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.roles?.map((r) => ({
        text: t(`users.tags.${r.split(':')[1]}`),
        value: r
      }))
    },
    {
      title: t('users.form.syndic'),
      key: 'syndic',
      dataIndex: 'syndic',
      render: (syndic) => (syndic ? syndic.society_name : '-')
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: (email) => (email ? <Text italic>{email}</Text> : '-')
    },
    {
      title: t('users.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) =>
        phone_number?.number
          ? `${phone_number.country_code} ${phone_number?.number}`
          : '-'
    },
    {
      title: t('users.form.access'),
      key: 'access',
      dataIndex: 'access',
      render: (access) =>
        access === 'BLOCKED' ? (
          <Tag color={accessTag[access]}>
            {t(`users.tags.${access}`, {
              defaultValue: access
            })}
          </Tag>
        ) : (
          '-'
        ),
      sorter: true,
      filters: enums?.access?.map((r) => ({
        text: t(`users.tags.${r}`),
        value: r
      }))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/users/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `/users/edit/${record?._id}`
            }}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title={t(`datatable.column.action.user.${record?.access}.title`)}
            okText={t(`datatable.column.action.user.${record?.access}.ok`)}
            okButtonProps={{ type: 'danger' }}
            cancelText={t(
              `datatable.column.action.user.${record?.access}.cancel`
            )}
            onConfirm={() => handleConfirm(record)}
            icon={<WarningOutlined />}
          >
            {record?.access === 'NOT_BLOCKED' ? (
              <StopOutlined style={{ fontSize: 18 }} />
            ) : (
              <CheckCircleOutlined style={{ fontSize: 18 }} />
            )}
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() =>
              deleteResource({
                dispatchAPI,
                resourceName: 'users',
                id: record._id,
                setForceRefresh,
                forceRefresh,
                message
              })
            }
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'var(--errorColor)', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        </>
      )
    }
  ];
};
