/**
 * Returns a configuration object for creating a resource.
 *
 * @hook
 * @param {Object} options - The options for the configuration.
 * @param {Object} options.fileList - The list of files to be uploaded.
 * @param {Object} options.customFormData - The custom form data.
 * @param {Object} options.form - The form instance.
 * @returns {Object} The configuration object.
 */
export const useConfig = () => ({
  onUpdateResource: {
    setBody: () => {
      const updatedData = { 'steps.sub': 'SEND_TO_NOTARY' };

      return updatedData;
    }
  }
});
