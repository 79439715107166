import { Route, Routes } from 'react-router-dom';
import { ServicesContextProvider } from '../../../contexts/ServicesContext';
import { Exception } from '../../../components';
import { otherRoutesSubRoutes as subRoutes } from '../../../utils/constants/routes/syndicManagerRoutes';
import { ClientWallet } from './ClientWallet';
import { NewMission } from './NewMission';
import { ManagersKanban } from './ManagersKanban/ManagersKanban';
import { ManageTeam } from './ManageTeam/ManageTeam';
import { TransferCollectiveOwnership } from './TransferCollectiveOwnership/TransferCollectiveOwnership';

/**
 * @component
 * Renders the router component for the client wallet.
 *
 * @returns {JSX.Element} The client wallet router component.
 */
export const ClientWalletRouter = () => (
  <ServicesContextProvider>
    <Routes>
      <Route
        path={`${subRoutes.CLIENT_WALLET.NEW_MISSION}/*`}
        element={<NewMission />}
      />
      <Route
        path={`${subRoutes.CLIENT_WALLET.MANAGE_WALLET}/*`}
        element={<ManagersKanban />}
      />
      <Route
        path={`${subRoutes.CLIENT_WALLET.MANAGE_TEAM}/*`}
        element={<ManageTeam />}
      />
      <Route
        path={`${subRoutes.CLIENT_WALLET.TRANSFER_COLLECTIVE_OWNERSHIP}/*`}
        element={<TransferCollectiveOwnership />}
      />
      <Route path="" element={<ClientWallet />} />

      <Route path="*" element={<Exception />} />
    </Routes>
  </ServicesContextProvider>
);
