import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Divider, Row, Form, Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList, CreateUpdateContainer } from '../../../../components';
import { useInvoiceInformationsListContent } from './invoiceInformations';
import { useFields } from './fields';
import { GetEnums } from '../../../../utils/getEnums';
import { Accounting } from '../../../../models/Accounting';
import { CustomActionButtons } from '../../../../components/CreateUpdateContainer/CustomActionButtons';
import { useColumns } from './paymentColumns';

/**
 * Component for displaying and adding payments to an invoice.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.invoice - The invoice object.
 * @param {number} props.invoiceNumber - The invoice number.
 * @returns {JSX.Element} - The rendered component.
 */
export const ShowAddPayments = ({ invoice, invoiceNumber }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [enums, setEnums] = useState({});
  const columns = useColumns();
  const [bankAccounts, setBankAccounts] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { fields } = useFields({
    enums,
    bankAccounts,
    isFieldsLoading,
    form,
    invoice
  });
  const { getEnums } = GetEnums();
  const invoiceInformationsListContent =
    useInvoiceInformationsListContent(invoice);

  useEffect(() => {
    (async () => {
      const [accountingDatas, enumsDatas] = await Promise.all([
        Accounting.getResources({ extraQuery: '&type=BANK_ACCOUNTS' }),
        getEnums({ resource: 'payments' })
      ]);
      setBankAccounts(accountingDatas);
      setEnums(enumsDatas);
      setIsFieldsLoading(false);
    })();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      payments: [
        {
          date: dayjs(),
          amount: invoice?.left_to_pay,
          type: 'TRANSFER'
        }
      ]
    });
  }, [invoice]);

  return (
    <Row>
      <Divider orientation="left">
        {t('invoices.divider.titles.invoice_informations')}
      </Divider>
      <DescriptionList
        data={invoiceInformationsListContent}
        translate
        vertical
      />
      {invoice.payments && invoice.payments.length > 0 ? (
        <>
          <Divider orientation="left">
            {t('invoices.divider.titles.payments_list')}
          </Divider>
          <Table
            tableLayout="auto"
            dataSource={invoice?.payments || []}
            columns={columns}
            scroll={{ x: '100%' }}
            style={{ width: '100%' }}
            pagination={false}
          />
        </>
      ) : null}
      <Divider orientation="left">
        {t('invoices.divider.titles.payment')}
      </Divider>
      <CreateUpdateContainer
        withCustomButtons={invoiceNumber > 1}
        customActionButtons={invoiceNumber > 1 ? <CustomActionButtons /> : null}
        customFormInstance={form}
        fields={fields}
        purpose="create"
        baseUrl={`payments/add-payments/${invoice._id}`}
        resource="payments"
        withFilesManager={false}
        withEnums={false}
        withCustomPageHeader
      />
    </Row>
  );
};

ShowAddPayments.propTypes = {
  invoice: PropTypes.shape({
    total_ttc: PropTypes.number,
    left_to_pay: PropTypes.number,
    payments: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string
  }).isRequired,
  invoiceNumber: PropTypes.number.isRequired
};
