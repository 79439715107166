import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Card, Row } from 'antd';
import { ReassignModal } from '../../../collective-ownership/modals/ReassignModal';
import { AddIcon } from '../../../../utils/constants/customIcons';
import { ListResource } from '../../../../components';
import { useColumns } from './columns/collectiveOwnershipColumns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useGlobalContext } from '../../../../contexts/GlobalContext';

/**
 * CollectiveOwnerships component
 * @param {Object} props - props
 * @returns {JSX.Element} CollectiveOwnerships component
 */
export const CollectiveOwnerships = ({ enums, t, isLoading }) => {
  const { dispatchAPI, user } = useAuthContext();
  const editUserPermissions = ![
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER'
  ].includes(user.role);
  const { syndics } = useGlobalContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCollectiveOwnerships, setSelectedCollectiveOwnerships] =
    useState([]);
  const columns = useColumns({
    enums,
    dispatchAPI,
    message,
    setIsReassignModalOpen,
    setSelectedCollectiveOwnerships,
    setForceRefresh,
    forceRefresh,
    editUserPermissions
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedCollectiveOwnerships(rowRecords);
    }
  };

  return (
    <Card
      style={{ marginTop: '10px' }}
      title={t('users.show.tabs.collective_ownerships')}
    >
      <ListResource
        resourceName="collective-ownerships"
        extraQuery={`manager._id=${id}`}
        populate="syndic"
        columns={columns}
        forceRefresh={forceRefresh}
        customActionColumn
        resourceModelName="Collective-ownership"
        withPageHeader={false}
        withCreateButton={false}
        isLoading={isLoading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        extraButtons={
          editUserPermissions && (
            <Link
              to="/collective-ownerships/create"
              state={{ syndic_manager: id }}
            >
              <Button type="add">
                {t('buttons.create')}
                <AddIcon />
              </Button>
            </Link>
          )
        }
      />
      {isReassignModalOpen ? (
        <ReassignModal
          syndics={syndics}
          setIsReassignModalOpen={setIsReassignModalOpen}
          isReassignModalOpen={isReassignModalOpen}
          selectedCollectiveOwnerships={selectedCollectiveOwnerships}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectedCollectiveOwnerships={setSelectedCollectiveOwnerships}
        />
      ) : null}
      {selectedRowKeys.length > 0 ? (
        <Row style={{ marginLeft: 20 }}>
          <Button type="primary" onClick={() => setIsReassignModalOpen(true)}>
            {t('buttons.reassign')}
          </Button>
        </Row>
      ) : null}
    </Card>
  );
};

CollectiveOwnerships.propTypes = {
  enums: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

CollectiveOwnerships.defaultProps = {
  enums: {},
  isLoading: true
};
