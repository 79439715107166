import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Divider, Input } from 'antd';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { formatAddress } from '../../utils/formatingFunctions';

const { Search } = Input;

/**
 * @hook
 * Custom hook that handles the fields for the TransferCollectiveOwnership component.
 * It retrieves collective ownership data from the API and sets the fields for the left and right sections.
 *
 * @returns {Object} An object containing the fields for the left and right sections.
 */
export const useFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);
  const { currentSyndic } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState('');
  const filteredItems = collectiveOwnerships.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [selectedValues, setSelectedValues] = useState([]);
  const handleCheckboxChange = (checkedValues) => {
    setSelectedValues(checkedValues);
  };

  const getCollectiveOwnerships = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `collective-ownership?syndic=${currentSyndic}`
    });
    const collectiveOwnershipsToSet = data.map(
      ({ name, first_address, first_city, ...item }) => ({
        label: formatAddress(name, first_address, first_city),
        value: item._id
      })
    );
    setCollectiveOwnerships(collectiveOwnershipsToSet);
  };

  useEffect(() => {
    (async () => {
      await getCollectiveOwnerships();
    })();
  }, []);

  const mergedItems = [
    ...filteredItems,
    ...collectiveOwnerships.filter(
      (item) =>
        selectedValues.includes(item.value) && !filteredItems.includes(item)
    )
  ];

  const fieldsLeft = [
    {
      startWithDivider: {
        title: t('syndic-requests.form.collective_ownerships_divider')
      },
      noLabel: true,
      name: [null],
      input: (
        <Search
          placeholder="Search..."
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: '1rem' }}
        />
      )
    },
    {
      noLabel: true,
      name: ['collective_ownerships'],
      input: (
        <Checkbox.Group
          value={selectedValues}
          onChange={handleCheckboxChange}
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            paddingRight: '8px',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {mergedItems.map((item) => (
            <>
              <Checkbox
                key={item.value}
                value={item.value}
                style={{ width: '100%', marginBottom: '8px' }}
              >
                {item.label}
              </Checkbox>
              <Divider style={{ margin: '0.5rem 0rem' }} />
            </>
          ))}
        </Checkbox.Group>
      )
    }
  ];

  const fieldsRight = [
    {
      startWithDivider: { title: t('syndic-requests.form.new_syndic_info') },
      name: ['new_syndic', 'name']
    },
    {
      name: ['new_syndic', 'city']
    },
    {
      name: ['new_syndic', 'additional']
    }
  ];

  return { fieldsRight, fieldsLeft };
};
