import { useTranslation } from 'react-i18next';
import { formatAddress } from '../../utils/formatingFunctions';

/**
 * @hook
 * Custom hook that returns an array of columns configuration for a table.
 *
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('syndic-factures.collective_ownership'),
      key: 'name',
      sorter: true,
      render: ({ name, first_address, first_city }) =>
        formatAddress(name, first_address, first_city)
    },
    {
      title: t('syndic-factures.gestionnaire'),
      dataIndex: ['manager'],
      render: (manager) =>
        `${manager?.first_name || ''} ${manager?.last_name || ''}`
    },
    {
      title: t('syndic-factures.nb_paid_invoices'),
      dataIndex: ['invoices_calculation', 'nb_paid_invoices'],
      render: (invoices) => (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#10b9a8',
            padding: '5px',
            color: 'white'
          }}
        >
          {invoices}
        </div>
      )
    },
    {
      title: t('syndic-factures.nb_unpaid_invoices'),
      dataIndex: ['invoices_calculation', 'nb_unpaid_invoices'],
      render: (invoices) => (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#cb2529',
            padding: '5px',
            color: 'white'
          }}
        >
          {invoices}
        </div>
      )
    },
    {
      title: t('syndic-factures.total_ttc'),
      dataIndex: ['invoices_calculation', 'total_ttc']
    },
    {
      title: t('syndic-factures.total_paid'),
      dataIndex: ['invoices_calculation', 'total_paid']
    },
    {
      title: t('syndic-factures.total_unpaid'),
      dataIndex: ['invoices_calculation', 'total_unpaid']
    }
  ];
};
