import { Route, Routes, useNavigate } from 'react-router-dom';
import { ServicesGenericCollectiveOwnershipList } from '../syndic-view/services/components/ServicesCollectiveOwnerships/ServicesGenericCollectiveOwnershipList';
import { MissionCollectiveOwnershipsShow } from '../syndic-view/services/ComplianceElanRouter/CollectiveOwnerships/ShowMissionCollectiveOwnership/MissionCollectiveOwnershipsShow';
import { Exception } from '../../components';
import { useColumns } from './useColumns';

const MISSIONS_PCS = ['PCS_1', 'PCS_2', 'PCS_3'];
const MISSIONS_NO_PCS = ['PLANS_RESEARCH', 'RCP_RESEARCH'];

/**
 * MissionsConseilSyndicRouter component.
 * This component handles the routing for the Conseil Syndic section of the application.
 * @component
 * @returns {JSX.Element} The rendered MissionsConseilSyndicRouter component.
 */
export const MissionsConseilSyndicRouter = () => {
  const navigate = useNavigate();
  const columns = useColumns(MISSIONS_PCS);

  return (
    <Routes>
      <Route
        path="/mission/show/:id"
        element={<MissionCollectiveOwnershipsShow isCounsil />}
      />
      <Route
        path="/"
        element={
          <ServicesGenericCollectiveOwnershipList
            tradKey="missions"
            missions={[...MISSIONS_PCS, ...MISSIONS_NO_PCS]}
            onDoubleClickAction={{
              action: (e) => {
                if (MISSIONS_PCS.includes(e.type)) {
                  navigate(`mission/show/${e._id}`);
                }
              }
            }}
            noHeaderButtons
            customColumns={columns}
          />
        }
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  );
};
