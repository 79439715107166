import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../../components';
import { ListMissions } from './ListMissions';
import { MissionViewRouter } from '../../missions/missions-view/MissionViewRouter';
import { MissionContextProvider } from '../../../contexts/MissionContext';

/**
 * MissionsRouter component
 * @returns {object} - The UI of the MissionsRouter component
 */
export const MissionsRouter = () => (
  <MissionContextProvider>
    <Routes>
      <Route path="/create" element={<Exception />} />
      <Route path="/edit/:id" element={<Exception />} />
      <Route path="/missions-view/*" element={<MissionViewRouter />} />
      <Route index element={<ListMissions />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </MissionContextProvider>
);
