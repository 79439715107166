import { useEffect, useState } from 'react';
import { Select, InputNumber, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { disabledDate } from './utils/disabledDate';

/**
 * Custom hook that returns an array of fields for a price system configuration form.
 *
 * @param {Object} options - The options for the hook.
 * @param {Object} options.enums - The enums object containing mission and type values.
 * @param {boolean} options.isFieldsLoading - Indicates whether the fields are loading.
 * @param {Function} options.t - The translation function.
 * @param {string} options.purpose - The purpose of the form.
 * @param {Array} options.prices - The prices array.
 * @param {string} options.priceToEdit - The price to edit.
 * @param {string} options.priceMission - The selected price mission.
 * @param {Function} options.setPriceMission - The function to set the price mission.
 * @param {string} options.priceType - The selected price type.
 * @param {Function} options.setPriceType - The function to set the price type.
 * @returns {Object} - The fields array.
 */

const { Option } = Select;

export const useFields = ({
  enums,
  isFieldsLoading,
  purpose,
  prices,
  priceToEdit,
  setPriceData,
  priceData,
  missionType
}) => {
  const [pickerDates, setPickerDates] = useState();
  const { t } = useTranslation();
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    if (purpose === 'edit') {
      setPickerDates({
        startDate: priceToEdit?.start_date,
        endDate: priceToEdit?.end_date
      });
    }
  }, [priceToEdit]);

  const datePicker = (isStartDatePicker) => {
    const localStartDate = !isStartDatePicker ? pickerDates?.startDate : null;
    const localEndDate = isStartDatePicker ? pickerDates?.endDate : null;

    return (
      <DatePicker
        disabled={!priceData.mission}
        value={
          isStartDatePicker ? pickerDates?.startDate : pickerDates?.endDate
        }
        disabledDate={(current) =>
          disabledDate({
            current,
            purpose,
            priceData,
            prices,
            priceToEdit,
            localStartDate,
            localEndDate
          })
        }
        onChange={(date) => {
          if (isStartDatePicker)
            setPickerDates((prev) => ({ ...prev, startDate: date }));
          else setPickerDates((prev) => ({ ...prev, endDate: date }));
        }}
        format="DD/MM/YYYY"
        cellRender={(current) => {
          const isDisabled = disabledDate({
            current,
            purpose,
            priceData,
            prices,
            priceToEdit,
            localStartDate,
            localEndDate
          });
          return isDisabled ? (
            <div style={{ color: 'red' }}>{current.date()}</div>
          ) : (
            current.date()
          );
        }}
      />
    );
  };

  const fields = [
    {
      name: ['mission_type'],
      key: 'mission_type',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(e) =>
            setPriceData((prev) => ({ ...prev, mission_type: e }))
          }
        >
          {(enums || []).map((item) => (
            <Option value={item} key={item}>
              {t(`missions.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['wording'],
      key: 'wording',
      rules: [{ required: true }]
    },
    {
      name: ['advance_payment'],
      key: 'advance_payment',
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    ...(missionType === 'PCS_1'
      ? [
          {
            name: ['amount_ht_without_documents'],
            key: 'amount_ht_without_documents',
            rules: [{ required: true }],
            input: <InputNumber addonAfter="€" />
          },
          {
            name: ['amount_ht_with_documents'],
            key: 'amount_ht_with_documents',
            rules: [{ required: true }],
            input: <InputNumber addonAfter="€" />
          }
        ]
      : [
          {
            name: ['amount_ht'],
            key: 'amount_ht',
            rules: [{ required: true }],
            input: <InputNumber addonAfter="€" />
          }
        ]),
    {
      name: ['lawyer_ht'],
      key: 'lawyer_ht',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['tva'],
      key: 'tva',
      rules: [{ required: true }],
      input: <InputNumber addonAfter="%" />
    },
    {
      name: ['start_date'],
      key: 'start_date',
      rules: [{ required: true }],
      input: datePicker(true)
    },
    {
      name: ['end_date'],
      key: 'end_date',
      input: datePicker()
    }
  ];

  return { fields };
};
