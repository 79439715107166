import { createContext, useContext, useState } from 'react';

export const InvoiceContext = createContext();

export const InvoiceContextProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  return (
    <InvoiceContext.Provider
      value={{
        formData,
        setFormData
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export const useInvoiceContext = () => {
  const context = useContext(InvoiceContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
