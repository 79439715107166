import { Card, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import { DeliveryParcel } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../../contexts/MissionContext';
import { ListResource } from '../../../../../../components';
import { useColumns } from './columns/plansOrdersColumns';
import { ListOrderedDocuments } from '../ListOrderedDocuments';

const { Text } = Typography;

/**
 * Renders the OrdersCard component.
 *
 * @component
 * @param {string} status - The status of the orders.
 * @returns {JSX.Element} The OrdersCard component.
 */
export const PlansOrdersCard = ({ status, setOpen }) => {
  const { t } = useTranslation();
  const { toBeOrderedColumns } = useColumns();
  const { forceRefresh, mission } = useMissionContext();

  return (
    <Card
      title={t(`missions.cards.titles.orders.${status}`)}
      extra={
        status === 'TO_BE_ORDERED' ? (
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            <DeliveryParcel />
            <Text>{t('missions.cards.documents.buttons.plan_order')}</Text>
          </Button>
        ) : null
      }
    >
      {status === 'TO_BE_ORDERED' ? (
        <ListResource
          scroll={{ x: 'max-content' }}
          resourceName="orders/mission"
          populate="cerfa_document_type,mission,expeditor"
          extraQuery={`mission=${mission?._id}&status=${status}`}
          columns={toBeOrderedColumns}
          forceRefresh={forceRefresh}
          customActionColumn
          resourceModelName="Order"
          withPageHeader={false}
          withCreateButton={false}
          withSearchBar={false}
        />
      ) : null}
      {status === 'ORDERED' ? <ListOrderedDocuments /> : null}
    </Card>
  );
};

PlansOrdersCard.propTypes = {
  status: PropTypes.string.isRequired,
  setOpen: PropTypes.func
};

PlansOrdersCard.defaultProps = {
  setOpen: () => {}
};
