import { Col, Statistic, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;

/**
 * StatisticLawyerDetail Component
 *
 * This component renders a single statistic for a lawyer's mission, with a styled title and value.
 * It conditionally applies styling based on the value of the statistic, particularly for unpaid missions.
 *
 * @component
 * @param {Object} props.item - The statistic data for the mission.
 * @param {string} props.item.title - The title key for the statistic.
 * @param {number} props.item.value - The value of the statistic.
 * @param {Function} props.t - The translation function from `react-i18next` used for localization.
 * @returns {JSX.Element} The rendered statistic detail component.
 *
 * @example
 * const item = { title: 'unpaid_missions', value: 0 };
 * const t = (key) => key; // Mock translation function
 * <StatisticLawyerDetail item={item} t={t} />
 */
const StatisticLawyerDetail = ({ item, t }) => {
  const isZero = item.value === 0 && item.title === 'unpaid_missions';

  return (
    <Col lg={7}>
      <Statistic
        title={
          <Title
            level={5}
            style={{
              margin: '0px',
              padding: '0px',
              fontSize: '14px'
            }}
          >
            {t(`users.show.cards.titles.${item.title}`)}
          </Title>
        }
        value={item.value}
        style={{
          textAlign: 'center',
          background: isZero ? '#FFF7F3' : 'white',
          border: `1px solid ${isZero ? '#E17A3B' : 'white'}`,
          borderRadius: '6px',
          padding: '10px',
          fontWeight: 'bold'
        }}
        valueStyle={{ color: isZero ? '#E17A3B' : 'black' }}
      />
    </Col>
  );
};

StatisticLawyerDetail.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};

export default StatisticLawyerDetail;
