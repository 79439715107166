import { Tag, Select } from 'antd';
import { userRoles, missionTag } from '../../utils/constants/tagColors';

const { Option } = Select;

/**
 * Returns the JSX component for the given selectType.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {string} options.selectType - The type of select.
 * @param {string|Object} options.element - The element for the option.
 * @param {Function} options.t - The translation function.
 * @returns {JSX.Element|null} The JSX component for the selectType.
 */
export const useOptions = ({ selectType, element, t }) => {
  switch (selectType) {
    case 'icons':
      return (
        <Option key={element.name} value={element.name}>
          {element.icon}
        </Option>
      );
    case 'roles':
      return (
        <Option key={element} value={element}>
          <Tag color={userRoles[element.split(':')[1]]}>
            {t(`users.tags.${element.split(':')[1]}`)}
          </Tag>
        </Option>
      );
    case 'missions':
      return (
        <Option key={element} value={element}>
          <Tag color={missionTag[element]}>{t(`missions.tags.${element}`)}</Tag>
        </Option>
      );
    case 'accounting_missions':
      return (
        <Option key={element} value={element}>
          <Tag color={missionTag[element]}>{t(`missions.tags.${element}`)}</Tag>
        </Option>
      );
    case 'pcs_phases':
      return (
        <Option key={element} value={element}>
          <Tag color={missionTag[element]}>{t(`missions.tags.${element}`)}</Tag>
        </Option>
      );
    case 'document_types':
      return (
        <Option
          key={element._id}
          value={element._id}
          searchValue={element?.wording}
        >
          <Tag color={element.color}>{element.wording}</Tag>
        </Option>
      );
    case 'cerfa_types':
      return (
        <Option key={element} value={element}>
          {element}
        </Option>
      );
    case 'mods':
      return (
        <Option key={element} value={element}>
          {t(`collections.tags.${element}`)}
        </Option>
      );
    default:
      return null;
  }
};
