import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

/**
 * A component that renders a form with various search filters for treasury publications.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.setFilters - Function to update the filters.
 * @param {Array} props.syndics - List of syndic objects for the syndic filter.
 * @param {boolean} props.isDataLoading - Flag to indicate if data is loading.
 * @param {Object} props.collective_ownerships - Collective-ownerships data for the collective_ownerships filter.
 *
 * @returns {JSX.Element} The rendered ExtraSearchBarOptions form component.
 */
const ExtraSearchBarOptions = ({
  setFilters,
  syndics,
  isDataLoading,
  collective_ownerships
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Form layout="inline" form={form}>
      <Form.Item name={['syndic']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              syndic: value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              syndic: ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('treasury_publications.placeholders.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {`${syndic?.society_name} (${syndic?.code})`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['collective_ownership']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              _id: value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              _id: ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t(
            'treasury_publications.placeholders.search_collective_ownership'
          )}
        >
          {(collective_ownerships || []).map((collective_ownership) => (
            <Option
              key={collective_ownership._id}
              value={collective_ownership._id}
            >
              {`${
                collective_ownership?.code
                  ? `${collective_ownership?.code} -`
                  : ''
              } ${collective_ownership?.first_address?.number || ''} ${
                collective_ownership?.first_address?.street || ''
              } ${collective_ownership?.first_address?.postal_code || ''} ${
                collective_ownership?.first_address?.city || ''
              } ${collective_ownership?.cadastral_reference || ''}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

ExtraSearchBarOptions.propTypes = {
  setFilters: PropTypes.func.isRequired,
  syndics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  collective_ownerships: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default ExtraSearchBarOptions;
