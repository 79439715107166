import {
  Row,
  Card,
  Col,
  Skeleton,
  Typography,
  Descriptions,
  Divider
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useInformationsListContent } from './listContent/informations';
import { usePlansListContent } from './listContent/plans';
import { Messages } from '../../../../components/Messages/Messages';
import { MessageContextProvider } from '../../../../contexts/MessageContext';

const { Text } = Typography;

/**
 * The `Informations` component is designed to display detailed information about a collective ownership entity, organized into several sections including main information, plans, and notes.
 * The component leverages Ant Design's `Row`, `Col`, `Card`, `Skeleton`, and `Typography` components for layout and formatting, and it utilizes custom hooks `useInformationsListContent` and `usePlansListContent` to structure the list content for the main information and plans sections, respectively.
 *
 * Props:
 * @prop {Object} [collectiveOwnership={}] - An object containing details about the collective ownership entity. This includes any relevant information that should be displayed in the main information and plans sections, as well as notes.
 * @prop {boolean} [isLoading=true] - A flag indicating whether the data for the collective ownership entity is currently loading. This is used to display a `Skeleton` loading animation while the data is being fetched.
 * @prop {function} t - A translation function provided by `react-i18next` for internationalization, used to translate titles and labels within the component.
 *
 * The component is structured into two main columns using `Row` and `Col` layout components from Ant Design. The first column contains cards for main information, plans, and notes, each wrapped in a `Skeleton` component to handle loading states. The second column contains placeholders for future development sections related to exchanges and pre-production exchange archives.
 *
 * Each card within the first column uses a `DescriptionList` component to display the structured list content generated by the custom hooks. The `DescriptionList` component is configured to use vertical layout and translation for its items.
 *
 * The notes section simply displays the `notes` property from the `collectiveOwnership` object, with a fallback to '-' if no notes are present.
 *
 * Usage:
 * This component is intended for use in pages or sections of an application where detailed information about a specific collective ownership entity needs to be displayed. It provides a comprehensive view of the entity's details, organized in a user-friendly format.
 */

export const Informations = ({ collectiveOwnership, isLoading, id }) => {
  const { t } = useTranslation();
  const informationsListContent =
    useInformationsListContent(collectiveOwnership);
  const plansListContent = usePlansListContent(collectiveOwnership);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card
              title={t(
                'collective-ownerships.show.cards.titles.main_informations'
              )}
            >
              <Skeleton loading={isLoading} active>
                <DescriptionList
                  data={informationsListContent}
                  translate
                  vertical
                />
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card title={t('collective-ownerships.show.cards.titles.plans')}>
              <Skeleton loading={isLoading} active>
                {collectiveOwnership?.plans ? (
                  collectiveOwnership?.plans.map((plan, index) => (
                    <Descriptions.Item key={plan._id}>
                      <Divider>Document {index + 1}</Divider>
                      <DescriptionList
                        data={usePlansListContent(plan)}
                        translate
                        vertical
                      />
                    </Descriptions.Item>
                  ))
                ) : (
                  <DescriptionList data={plansListContent} translate vertical />
                )}
              </Skeleton>
            </Card>
          </Col>
          <Col span={24}>
            <Card title={t('collective-ownerships.show.cards.titles.notes')}>
              <Skeleton loading={isLoading} active>
                <Text>{collectiveOwnership?.notes || '-'}</Text>
              </Skeleton>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Skeleton loading={isLoading} active>
              <MessageContextProvider id={id} model="Collective-ownership">
                <Messages withCreateButton />
              </MessageContextProvider>
            </Skeleton>
          </Col>
          <Col span={24}>
            <Card>
              <Skeleton loading={isLoading} active>
                <div>Archives échanges pré-prodcution ( En développement )</div>
              </Skeleton>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Informations.propTypes = {
  collectiveOwnership: PropTypes.shape({
    notes: PropTypes.string,
    plans: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  isLoading: PropTypes.bool,
  id: PropTypes.string.isRequired
};

Informations.defaultProps = {
  collectiveOwnership: {},
  isLoading: true
};
