import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * Renders a button component for PCAJP arrays.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setCurrentPcajpArray - The function to set the current PCAJP array.
 * @param {Function} props.setDataSource - The function to set the data source.
 * @param {Object} props.pcajpArray - The PCAJP array object.
 * @returns {JSX.Element} The rendered button component.
 */
export const PcajpArraysButtons = ({
  setCurrentPcajpArray,
  setDataSource,
  pcajpArray
}) => {
  const { t } = useTranslation();

  const handleButtonTitle = () => {
    if (pcajpArray.version === 0) {
      return t('missions.buttons.pcajp_before_intervention');
    }
    if (pcajpArray.is_validated) {
      return t('missions.buttons.pcajp_intervention_validated');
    }

    return t('missions.buttons.pcajp_intervention', {
      role: t(`missions.tags.${pcajpArray.role}`),
      version: pcajpArray.version
    });
  };

  return (
    <Button
      onClick={() => {
        setCurrentPcajpArray(pcajpArray);
        setDataSource(pcajpArray.data);
      }}
    >
      {handleButtonTitle()}
    </Button>
  );
};

PcajpArraysButtons.propTypes = {
  setCurrentPcajpArray: PropTypes.func.isRequired,
  setDataSource: PropTypes.func.isRequired,
  pcajpArray: PropTypes.shape({
    version: PropTypes.number,
    role: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    is_validated: PropTypes.bool
  })
};

PcajpArraysButtons.defaultProps = {
  pcajpArray: {}
};
