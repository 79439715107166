import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

/**
 * Renders a form with three Select components for filtering syndics, cerfa documents and concerned SPFs.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.cerfaDocuments- The array of cerfa documents.
 * @param {Array} props.concernedSPFs - The array of concerned SPFs.
 * @returns {JSX.Element} - The rendered form.
 */
export const SearchBars = ({
  setFilters,
  usersList,
  setSelectedRole,
  enums,
  isDataLoading,
  setForceRefresh
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  /**
   * Filters the options based on the input value.
   * @function
   * @param {string} input - The input value.
   * @param {Object} option - The option object.
   * @returns {boolean} - Whether the option should be displayed or not.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} layout="inline">
      <Form.Item name={['roles']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              role: value || ''
            }));
            setSelectedRole(value);
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              role: ''
            }));
            setSelectedRole('');
            setForceRefresh((prev) => !prev);
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.role')}
        >
          {(enums || []).map((role) => (
            <Option key={role} value={role}>
              {t(`users.tags.${role.split(':')[1]}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['users']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              consultant: value || '' //! refacto later
            }));
            setForceRefresh((prev) => !prev);
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              consultant: ''
            }));
            setForceRefresh((prev) => !prev);
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.user')}
        >
          {(usersList || []).map((user) => (
            <Option key={user._id} value={user._id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

SearchBars.propTypes = {
  setFilters: PropTypes.func,
  enums: PropTypes.shape({
    docEnums: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.string)
    })
  }),
  usersList: PropTypes.arrayOf(PropTypes.objectOf({})),
  isDataLoading: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  setSelectedRole: PropTypes.func
};

SearchBars.defaultProps = {
  setFilters: () => {},
  enums: {
    docEnums: {
      status: []
    }
  },
  usersList: [],
  isDataLoading: true,
  setForceRefresh: () => {},
  setSelectedRole: () => {}
};
