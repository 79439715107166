/**
 * Returns a configuration object for resource retrieval.
 * @hook
 * @returns {Object} The configuration object.
 */
export const useConfig = () => ({
  onUpdateResource: {
    setBody: (data) => {
      const setPhase2Type = () => {
        if (data.realized_modifications.compliance) {
          return 'COMPLIANCE';
        }
        if (data.realized_modifications.adaptation) {
          return 'ADAPTATION';
        }
        return null;
      };

      const updatedData = {
        ...data,
        phase_two_type: setPhase2Type(),
        'steps.sub': 'FINALIZATION',
        'show_messages.definitive_project_submitted': true
      };

      return updatedData;
    }
  }
});
