import PropTypes, { shape } from 'prop-types';
import { Button, Col, message as Message, Modal, Row, Spin } from 'antd';
import { CheckOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Renders a set of buttons for navigating through steps in a multi-step form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.steps - The array of steps in the form.
 * @param {number} props.current - The current step index.
 * @param {function} props.setCurrent - The function to update the current step index.
 * @param {object} props.form - The form object.
 * @param {function} props.setInitialValues - The function to set the initial form values.
 * @param {boolean} props.buttonsActive - The object indicating the active state of buttons.
 * @param {object} props.extraSubmitValues - The extra values to be included when submitting the form.
 * @param {object} props.extraSaveDraftValues - The extra values to be included when saving the form as a draft.
 * @returns {JSX.Element} The rendered StepsButtons component.
 */
export const StepsButtons = ({
  steps,
  current,
  setCurrent,
  form,
  setInitialValues,
  buttonsActive,
  extraSubmitValues,
  extraSaveDraftValues,
  onSubmitAction,
  isSubmitting,
  setIsSubmitting
}) => {
  const { t } = useTranslation();

  const prev = () => {
    setCurrent(current - 1);
  };

  const onNextStep = async () => {
    try {
      const values = await form.validateFields();
      setInitialValues((prv) => ({ ...prv, ...values }));
      setCurrent(current + 1);
      return null;
    } catch (errorInfo) {
      return Message.error(t('syndics.messages.required_fields'));
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const values = await form.validateFields();
      onSubmitAction(values);
      await setIsSubmitting(true);
      await setInitialValues((prv) => ({
        ...prv,
        ...values,
        ...extraSaveDraftValues
      }));
      return form.submit();
    } catch (error) {
      return Message.error(t('syndics.messages.required_fields'));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const values = await form.validateFields();
      onSubmitAction(values);
      await setInitialValues((prv) => ({
        ...prv,
        ...values,
        ...extraSubmitValues
      }));
      await setIsSubmitting(true);
      return form.submit();
    } catch (error) {
      return Message.error(t('syndics.messages.required_fields'));
    }
  };
  return (
    <>
      {current > 0 && (
        <Button
          disabled={!buttonsActive.back}
          style={{
            margin: '0 8px'
          }}
          onClick={() => prev()}
        >
          <LeftOutlined />
          {t('buttons.previous')}
        </Button>
      )}
      {current !== steps.length - 1 && (
        <Button
          type="add"
          disabled={!buttonsActive.saveDraft}
          onClick={handleSave}
        >
          {`${t('buttons.save_draft')} `}
        </Button>
      )}
      {current < steps.length - 1 && (
        <Button
          type="primary"
          disabled={!buttonsActive.next}
          onClick={async () => {
            await onNextStep();
          }}
        >
          {t('buttons.next_step')}
          <RightOutlined />
        </Button>
      )}
      {current === steps.length - 1 && (
        <Button
          disabled={!buttonsActive.submit}
          type="add"
          onClick={handleSubmit}
        >
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
      )}
      <Modal
        open={isSubmitting}
        centered
        footer={null}
        closable={false}
        bodyStyle={{
          textAlign: 'center',
          padding: '40px 20px',
          borderRadius: '8px'
        }}
      >
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col>
            <>
              <p>{t('global.saving')}</p>
              <Spin size="large" />
            </>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

StepsButtons.propTypes = {
  current: PropTypes.number,
  setCurrent: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(shape({})).isRequired,
  form: PropTypes.shape({
    validateFields: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  setInitialValues: PropTypes.func.isRequired,
  buttonsActive: PropTypes.shape({
    next: PropTypes.bool,
    back: PropTypes.bool,
    saveDraft: PropTypes.bool,
    submit: PropTypes.bool
  }),
  extraSubmitValues: PropTypes.shape({}),
  extraSaveDraftValues: PropTypes.shape({}),
  onSubmitAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setIsSubmitting: PropTypes.func
};

StepsButtons.defaultProps = {
  current: 0,
  buttonsActive: {
    next: true,
    back: true,
    saveDraft: true,
    submit: true
  },
  extraSubmitValues: {},
  extraSaveDraftValues: {},
  onSubmitAction: () => {},
  isSubmitting: false,
  setIsSubmitting: () => {}
};
