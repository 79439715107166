import { Form, Select, DatePicker, Tag, Dropdown, Button } from 'antd';
import { MenuOutlined, FilePdfFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { invoiceTag } from '../../../utils/constants/tagColors';
import { ExportButton } from '../../../components';
import { useFormatter } from '../../../utils/formatter';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  invoiceSendListHeaders,
  invoicePaidListHeaders,
  invoiceSendAccountantExportHeaders,
  invoicePaidAccountantExportHeaders
} from './headers';
import { generatePDF } from '../../../utils/generatePDF';
// import { ExportModal } from '../../../components/ExportModal/ExportModal';

const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * Renders the search bar component for invoices.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.collectiveOwnerships - The array of collective ownerships.
 * @param {Array} props.syndics - The array of syndics.
 * @param {Function} props.setSelectedSyndic - The function to set the selected syndic.
 * @param {boolean} props.isDataLoading - Indicates if the data is loading.
 * @param {Object} props.enums - The object containing enums.
 * @param {Function} props.setForceRefresh - The function to force refresh.
 * @param {string} props.extraQuery - The extra query.
 * @param {Array} props.selectedRowKeys - The selected row keys.
 * @returns {JSX.Element} The search bar component.
 */
export const SearchsBar = ({
  setFilters,
  collectiveOwnerships,
  syndics,
  setSelectedSyndic,
  isDataLoading,
  enums,
  setForceRefresh,
  extraQuery,
  selectedRowKeys
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { formattedData } = useFormatter();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const menu = {
    items: [
      {
        key: 'invoice_send_accountant_export',
        label: (
          <ExportButton
            dataName="accountant-exports"
            headers={invoiceSendAccountantExportHeaders}
            url="invoices/accountant-exports/invoice-send-accountant-exports"
            fileName="export.csv"
            extraQuery={extraQuery}
            formatter={formattedData}
            selectedRowKeys={{
              key: selectedRowKeys.key,
              rowKeys: selectedRowKeys.rowKeys.map((el) => el.mission)
            }}
            buttonLabel={t('buttons.accounting_export_invoice_send')}
          />
        )
      },
      {
        key: 'invoice_send_export_list',
        label: (
          <ExportButton
            dataName="accountant-exports"
            headers={invoiceSendListHeaders}
            url="invoices/accountant-exports/invoice-list"
            fileName="export.csv"
            extraQuery={extraQuery}
            formatter={formattedData}
            selectedRowKeys={{
              key: selectedRowKeys.key,
              rowKeys: selectedRowKeys.rowKeys.map((el) => el.mission)
            }}
            buttonLabel={t('buttons.accounting_export_invoice_send_list')}
          />
        )
      },
      {
        key: 'invoice_paid_accountant_export',
        label: (
          <ExportButton
            dataName="accountant-exports"
            headers={invoicePaidAccountantExportHeaders}
            url="invoices/accountant-exports/invoice-paid-accountant-exports"
            fileName="export.csv"
            extraQuery={`${extraQuery ? `${extraQuery}&` : ''}status=PAID`}
            formatter={formattedData}
            selectedRowKeys={{
              key: selectedRowKeys.key,
              rowKeys: selectedRowKeys.rowKeys.map((el) => el.mission)
            }}
            buttonLabel={t('buttons.accounting_export_invoice_paid')}
          />
        )
      },
      {
        key: 'invoice_paid_export_list',
        label: (
          <ExportButton
            dataName="accountant-exports"
            headers={invoicePaidListHeaders}
            url="invoices/accountant-exports/invoice-list"
            fileName="export.csv"
            extraQuery={`${extraQuery ? `${extraQuery}&` : ''}status=PAID`}
            formatter={formattedData}
            selectedRowKeys={{
              key: selectedRowKeys.key,
              rowKeys: selectedRowKeys.rowKeys.map((el) => el.mission)
            }}
            buttonLabel={t('buttons.accounting_export_invoice_paid_list')}
          />
        )
      },
      {
        key: 'export_disbursement_and_costs',
        label: (
          <>
            <FilePdfFilled
              style={{ fontSize: 16, color: 'var(--textColor)' }}
            />
            <Button
              type="link"
              onClick={() =>
                generatePDF({
                  message,
                  dispatchAPI,
                  use_case: 'ADVANCE_PAYMENT_INVOICE_PCS_1',
                  parameters: [
                    {
                      collection: 'Invoice',
                      documentId: '66e808bc9201255c0f0f319a'
                    }
                  ],
                  template_type: 'TEMPLATE_DOCS',
                  downloadName: 'export_disbursement_and_costs'
                })
              }
            >
              {t('buttons.export_pdf')}
            </Button>
          </>
        )
      }
    ]
  };

  return (
    <Form layout="inline" form={form}>
      <Form.Item name={['syndic']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              'syndic._id': value || ''
            }));
            setSelectedSyndic(value);
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              'syndic._id': ''
            }));
            setSelectedSyndic('');
            setForceRefresh((prev) => !prev);
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {`${syndic?.society_name} (${syndic?.code})`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['collective-ownership']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              'collective_ownership._id': value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              'collective_ownership._id': ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.search_collective_ownership')}
        >
          {(collectiveOwnerships || []).map((collective_ownership) => (
            <Option
              key={collective_ownership._id}
              value={collective_ownership._id}
            >
              {`${collective_ownership?.code} - ${
                collective_ownership?.name || ''
              } - ${collective_ownership?.first_address?.street} ${
                collective_ownership?.first_address?.postal_code
              }`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['status']}>
        <Select
          style={{ minWidth: 190 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              status: value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              status: ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.filter_status')}
        >
          {(enums.status || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={invoiceTag[status]}>
                {t(`invoices.tags.${status}`, {
                  defaultValue: status
                })}
              </Tag>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['invoice-type']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              invoiceType: value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              invoiceType: ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.filter_invoice_type')}
        >
          {(enums.types || []).map((invoice_type) => (
            <Option key={invoice_type} value={invoice_type}>
              <Tag color={invoiceTag[invoice_type]}>
                {t(`invoices.tags.${invoice_type}`, {
                  defaultValue: invoice_type
                })}
              </Tag>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['date']}>
        <RangePicker
          onChange={(dates) => {
            if (dates) {
              setFilters((prev) => ({
                ...prev,
                start_date: new Date(dates[0].$d),
                end_date: new Date(dates[1].$d)
              }));
            } else {
              setFilters((prev) => ({
                ...prev,
                start_date: '',
                end_date: ''
              }));
            }
          }}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          allowClear
          style={{ minWidth: 200 }}
        />
      </Form.Item>
      <Dropdown menu={menu}>
        <Button type="link">
          <MenuOutlined style={{ fontSize: 16, color: 'var(--textColor)' }} />
        </Button>
      </Dropdown>
    </Form>
  );
};

SearchsBar.propTypes = {
  setFilters: PropTypes.func.isRequired,
  collectiveOwnerships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedSyndic: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  syndics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enums: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  extraQuery: PropTypes.string,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string)
};

SearchsBar.defaultProps = {
  extraQuery: '',
  selectedRowKeys: []
};
