import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { dateFormat } from '../../syndic-view/utils/dateFormat';
import { formatAddress } from '../../syndic-view/utils/formatingFunctions';
import { missionTag } from '../../../utils/constants/tagColors';

const tradKey = 'syndic-requests.COLLECTIVE_OWNERSHIP_TRANSFER.form';
const tradKeyNoForm = 'syndic-requests.COLLECTIVE_OWNERSHIP_TRANSFER';

/**
 * @hook
 * Returns an array of column configurations for a table.
 * @param {string} missionTypeLabel - The mission type.
 * @returns {Array} An array of column configurations.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t(`${tradKey}.syndic`),
      key: ['syndic'],
      dataIndex: ['syndic', 'society_name']
    },
    {
      title: t(`${tradKey}.collective_ownership`),
      key: ['collective_ownership'],
      dataIndex: ['collective_ownership'],
      render: ({ name, first_address, first_city }) =>
        formatAddress(name, first_address, first_city)
    },
    {
      title: t(`${tradKey}.new_syndic_address`),
      key: ['new_syndic'],
      dataIndex: ['new_syndic'],
      render: ({ name, city, additional }) =>
        `${name || ''} ${city || ''} ${additional || ''}`
    },
    {
      title: t(`${tradKey}.status`),
      key: ['status'],
      dataIndex: ['status'],
      render: (status) => (
        <Tag color={missionTag[status]}>
          {t(`${tradKeyNoForm}.status.${status}`)}
        </Tag>
      )
    },
    {
      title: t(`${tradKey}.submittion_date`),
      key: ['updated_at'],
      dataIndex: ['updated_at'],
      render: (date) => dayjs(date).format(dateFormat)
    }
  ];
};
