import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  homeSyndicViewSubtitle,
  homeSyndicViewTitle
} from '../../utils/styleConstants';
import homeSubtitleImage from '../../../../assets/images/services_proposes 1.svg';

/**
 * @hook
 * Generates the title, subtitle, card style, and home subtitle image for the home syndic view.
 * @returns {Object} An object containing the title, subtitle, card style, and home subtitle image.
 */
export const homeSubtitle = () => {
  const { t } = useTranslation();

  const title = (
    <Row>
      <h1 style={homeSyndicViewTitle}>
        {t('syndic-home.subTitlteCard.title')}
      </h1>
    </Row>
  );

  const subtitle = (
    <>
      <p style={homeSyndicViewSubtitle}>
        {t('syndic-home.subTitlteCard.subtitle_1')}
      </p>
      <p style={homeSyndicViewSubtitle}>
        {t('syndic-home.subTitlteCard.subtitle_2')}
      </p>
      <p style={homeSyndicViewSubtitle}>
        {t('syndic-home.subTitlteCard.subtitle_3')}
      </p>
    </>
  );

  const cardStyle = {
    backgroundColor: '#ffffff'
  };

  return { title, subtitle, cardStyle, homeSubtitleImage };
};
