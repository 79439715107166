export const routes = {};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PASSWORD_CREATION: '/post-pwd',
  PASSWORD_RESET: '/reset-pwd',
  PCSFORM: '/pcs-form'
};

// url search parameters
export const pathSearches = {};
