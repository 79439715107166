import { Button, Row, Modal, Typography, Col } from 'antd';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PcsPreProduction } from '../../../../models/PcsPreProduction';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Text } = Typography;

/**
 * Renders a modal component for validating a PCS pre-production.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isValidateModalOpen - Flag indicating whether the validate modal is open.
 * @param {function} props.setIsValidateModalOpen - Function to set the state of the validate modal.
 * @param {function} props.setCurrent - Function to set the current state.
 * @returns {JSX.Element} The validate modal component.
 */
export const ValidateModal = ({
  isValidateModalOpen,
  setIsValidateModalOpen,
  setCurrent,
  setForceRefresh,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAuthContext();
  const { message } = useErrorMessage();

  return (
    <Modal
      title={t('pcs-pre-productions.show.modals.titles.validate')}
      open={isValidateModalOpen}
      onCancel={() => setIsValidateModalOpen(false)}
      footer={null}
    >
      <Row>
        <Col>
          <Text>
            {t('pcs-pre-productions.show.modals.validate_content_part_1')}
          </Text>
        </Col>
        <Col>
          <Text>
            {t('pcs-pre-productions.show.modals.validate_content_part_2')}
          </Text>
        </Col>
      </Row>
      <Row justify="end" gutter={[16, 16]}>
        <Col>
          <Button type="primary" onClick={() => setIsValidateModalOpen(false)}>
            {`${t('buttons.cancel')} `}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              PcsPreProduction.validatePcsPreProduction({
                id,
                values: { status: 'VALIDATED' },
                setIsValidateModalOpen,
                setCurrent,
                user,
                t,
                setForceRefresh,
                forceRefresh,
                message
              });
            }}
          >
            {`${t('buttons.register')} `}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

ValidateModal.propTypes = {
  isValidateModalOpen: PropTypes.bool.isRequired,
  setIsValidateModalOpen: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired
};
