import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag, Divider, Button } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { syndicStatus } from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Fonction qui renvoie un tableau d'objets décrivant les colonnes d'un tableau.
 *
 * @param {Object} options - Les options pour la génération des colonnes.
 * @param {Object} options.enums - Les énumérations utilisées pour certaines valeurs de colonnes.
 * @returns {Array} Un tableau d'objets représentant les colonnes du tableau.
 */

export const useColumns = ({
  enums,
  setIsDeleteModalOpen,
  setSelectedSyndic
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const editPermissions = !['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(
    user.role
  );

  return [
    {
      title: t('syndics.form.society_name'),
      key: 'society_name',
      dataIndex: 'society_name',
      sorter: true
    },
    {
      title: t('syndics.form.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('syndics.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={syndicStatus[status]}>
            {t(`syndics.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.syndic_status?.map((r) => ({
        text: t(`syndics.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('syndics.form.address.postal_code'),
      key: 'billing_address.postal_code',
      render: (record) => record.billing_address?.postal_code || '-',
      sorter: true
    },
    {
      title: t('syndics.form.address.city'),
      key: 'billing_address.city',
      render: (record) => record.billing_address?.city || '-',
      sorter: true
    },
    {
      title: t('syndics.form.principal_contact'),
      key: 'contact',
      render: (record) =>
        `${record.contact?.last_name || ''} ${
          record.contact?.first_name || ''
        }`,
      sorter: (a, b) => a.contact?.last_name.localeCompare(b.contact?.last_name)
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/syndics/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>

          {editPermissions && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/syndics/edit/${record?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
              <Divider type="vertical" />
              <Button
                type="link"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  setSelectedSyndic(record);
                }}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: 18 }}
                  type="delete"
                />
              </Button>
            </>
          )}
        </>
      )
    }
  ];
};
