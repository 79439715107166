import { useState } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './utils/useColumns';
import { ExtraSearchButton } from './utils/ExtraSearchButton';
import SyndicViewContentCustom from '../SyndicViewContentCustom';
import { collectiveOwnershipTitle } from './utils/collectiveOwnershipTitle';
import { syndicContentCustomStyle } from '../utils/styleConstants';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import homeSubtitleImage from '../../../assets/images/portefeuille_a_evolue_1.svg';

/**
 * @component
 * Renders a list of collective ownerships for a syndic.
 * @returns {JSX.Element} The rendered component.
 */
export const CollectiveOwnershipSyndicList = () => {
  const { currentSyndic } = useGlobalContext();
  const [filter, setFilter] = useState('');
  const columns = useColumns();
  const { title, subtitle } = collectiveOwnershipTitle();

  return (
    <SyndicViewContentCustom
      title={title}
      subtitle={subtitle}
      imageOnRight={homeSubtitleImage}
    >
      <ListResource
        ContentCustomStyle={syndicContentCustomStyle}
        withPageHeader={false}
        resourceName="syndic-collective-ownerships"
        extraQuery={`syndic=${currentSyndic}${
          filter && `&missionType=${filter}`
        }&fields=_id,name,syndic.society_name,first_address`}
        searchBarExtraButtons={<ExtraSearchButton setFilter={setFilter} />}
        populate="syndic,first_city"
        columns={columns}
        resourceModelName="Collective-ownership"
        customActionColumn
        withCreateButton={false}
      />
    </SyndicViewContentCustom>
  );
};
