import { useSearchParams } from 'react-router-dom';

/**
 * getFiltersFromURL retrieves filter values from the URL search parameters,
 * specifically for mission type, re-written document status, and finalization steps.
 *
 * @returns {object} An object containing the filters based on the URL parameters.
 */
export const getFiltersFromURL = () => {
  const [searchParams] = useSearchParams();
  const late_rewrite = searchParams.get('re_written_document_status');
  const finalization = searchParams.get('steps.sub');
  const missionType = searchParams.get('type');

  if (late_rewrite) {
    return {
      type: missionType,
      re_written_document_status: late_rewrite
    };
  }
  if (missionType) {
    return {
      type: missionType,
      'steps.sub': finalization
    };
  }
  return {};
};

/**
 * Retrieves the filter object for consultants based on query parameters from the URL.
 *
 * @returns {Object} The filter object containing the `syndic._id` field if the `syndic` query parameter is present; otherwise, an empty object.
 */
export const getConsultantFilter = () => {
  const [searchParams] = useSearchParams();
  const syndicId = searchParams.get('syndic');

  if (syndicId) {
    return {
      'syndic._id': syndicId
    };
  }
  return {};
};
