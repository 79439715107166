import { Collapse, Button, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../../../../components';
import { useColumns } from '../../columns/collectiveOwnershipFormsColumns';
import { useMissionContext } from '../../../../../../contexts/MissionContext';
import { EmailCollectiveOwnershipFormsButton } from './EmailCollectiveOwnershipFormsButton';
import DownloadEmptyCollectiveOwnershipFormsButton from './DownloadEmptyCollectiveOwnershipFormsButton';

/**
 * Renders a card component for displaying collective ownership forms.
 * @component
 * @returns {JSX.Element} The rendered card component.
 */
export const CollectiveOwnershipFormsCard = () => {
  const { mission } = useMissionContext();
  const { t } = useTranslation();
  const columns = useColumns();

  return (
    <Collapse
      expandIconPosition="end"
      items={[
        {
          key: '1',
          label: t('missions.cards.titles.collective_ownership_forms'),
          children: (
            <ListResource
              style={{ margin: 0 }}
              resourceName="collective-ownership-form"
              extraQuery={`collective_ownership=${mission?.collective_ownership?._id}`}
              columns={columns}
              resourceModelName="Collective-ownership-form"
              customActionColumn
              withPageHeader={false}
              withCreateButton={false}
              extraButtons={
                <Flex gap={5}>
                  <DownloadEmptyCollectiveOwnershipFormsButton
                    mission={mission}
                  />
                  <EmailCollectiveOwnershipFormsButton mission={mission} />
                  <Link to={`/pcs-form?missionId=${mission._id}`}>
                    <Button type="primary">
                      {`${t('buttons.create')}`}
                      <PlusOutlined />
                    </Button>
                  </Link>
                </Flex>
              }
            />
          )
        }
      ]}
    />
  );
};
