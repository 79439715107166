import { useCallback, useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { getEnums } from './utils/getEnums';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { setDynamicFields } from './utils/setDynamicFields';
import { useConfig } from './utils/createUpdateConfig';
import { User } from '../../models/User';

/**
 * Component for creating or updating a user.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the user creation or update.
 * @returns {JSX.Element} The create or update user component.
 */
export const CreateUpdateUser = ({ purpose }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { syndics, setForceRefresh, forceRefresh } = useGlobalContext();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [role, setRole] = useState('');
  const [syndicOptions, setSyndicOptions] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [managers, setManagers] = useState([]);
  const [syndic, setSyndic] = useState(null);
  const { manager_role, syndic: syndicId } = location.state || {};

  const { fields } = useFields({
    enums,
    isFieldsLoading,
    setRole,
    syndicOptions,
    setSyndic,
    role,
    assistants,
    managers,
    form
  });

  useEffect(() => {
    if (syndicId) {
      const selectedRole = form.getFieldValue('role');
      if (
        ['users:SYNDIC_MANAGER', 'users:SYNDIC_ASSISTANT'].includes(
          selectedRole
        )
      ) {
        form.setFieldValue('syndic', syndicId);
      }
    }
  }, [syndicId]);

  useEffect(() => {
    (async () => {
      if (manager_role) {
        setForceRefresh(!forceRefresh);
        await User.populateFields({
          form,
          setSyndic,
          manager_role,
          setRole
        });
      }
    })();
  }, [manager_role]);

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums({ dispatchAPI, setEnums, message });
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    setDynamicFields({
      role,
      setSyndicOptions,
      syndics,
      syndic,
      setAssistants,
      setManagers
    });
  }, [syndic, role]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const config = useConfig({ setRole, setSyndic });

  return (
    <CreateUpdateContainer
      customFormInstance={form}
      fields={fields}
      config={config}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      withFilesManager={false}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};
