import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Form } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { EditableTable } from '../../../components/editableTableComponents/EditableTable';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';
import { addColumns } from '../../../components/editableTableComponents/addColumns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useDynamicColumns } from './utils/processColumns';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import { ExtraSearchBars } from './ExtraSearchBars';
import { PCSappointmentMonitoring } from '../../../models/PCSAppointementMonitoring';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { User } from '../../../models/User';

/**
 * Renders a component for displaying PCS appointment monitoring data.
 * @component
 * @returns {JSX.Element} The JSX element representing the component.
 */
export const PcsappointmentMonitoring = () => {
  const { t } = useTranslation();
  const { syndics } = useGlobalContext();
  const [consultants, setConsultants] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const { searchValue, setPagination, pagination } = useConfigurationContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filters, setFilters] = useState({
    'mission.lawyer_consultation_received': false
  });
  const [extraQuery, setExtraQuery] = useState('');
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [form] = Form.useForm();

  const [mergedColumns, setMergedColumns] = useState(dynamicColumns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  // ! Don't use Promise.all here, it will cause a race condition
  useEffect(() => {
    (async () => {
      const { data } = await dispatchAPI('GET', {
        url: '/customer-service-manager-meetings'
      });
      const updatedData = {
        PCS_1: data.filter((meeting) => meeting.pcs_phase === 'PCS_1'),
        PCS_2: data.filter((meeting) => meeting.pcs_phase === 'PCS_2'),
        PCS_3: data.filter((meeting) => meeting.pcs_phase === 'PCS_3'),
        ALL: data.filter((meeting) => meeting.meeting_type === 'Autres')
      };
      const processedDynamicColumns = useDynamicColumns(updatedData, t);
      setDynamicColumns(processedDynamicColumns);
    })();
    (async () => {
      const consultantsData = await User.getResources({
        populate: '',
        extraQuery: `role=users:CONSULTANT`
      });
      setConsultants(consultantsData);
    })();
  }, []);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  useEffect(() => {
    (async () => {
      const data = await PCSappointmentMonitoring.getResources({
        populate: '',
        extraQuery
      });
      setDataSource(data);
    })();
  }, [forceRefresh, searchValue]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: dynamicColumns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      resource: 'pcs-appointment-monitoring'
    });
  }, [editingKey, dataSource]);

  return (
    <>
      <PageHeaderCustom title={t('pcs-appointment-monitoring.title')} />
      <ContentCustom>
        <EditableTable
          resource="pcs-appointment-monitoring"
          dataSource={dataSource}
          mergedColumns={mergedColumns}
          setSelectedRowKey={setSelectedRowKey}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          rowSelection={false}
          form={form}
          searchValue={searchValue}
          pagination={pagination}
          setPagination={setPagination}
          setDataSource={setDataSource}
          withoutAddLineButton
          scroll={{ x: 'max-content' }}
          extraHeader={
            <ExtraSearchBars
              syndics={syndics}
              consultants={consultants}
              form={form}
              setForceRefresh={setForceRefresh}
              setFilters={setFilters}
            />
          }
        />
      </ContentCustom>
    </>
  );
};
