import { EyeOutlined, EditOutlined, MailOutlined } from '@ant-design/icons';
import { Space, Badge, Row, Col, Typography, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const getTotalBilled = (managers, keyName) => {
  let total = 0;

  managers.forEach((manager) => {
    manager.invoices.forEach((invoice) => {
      total += invoice[keyName];
    });
  });
  return total;
};

export const usetableColumns = (setOpenAddComment, setSelectedSyndic) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const iconStyle = { fontSize: 18, color: 'black', cursor: 'pointer' };

  return [
    {
      key: 'indicator',
      render: () => <Badge status="error" />
    },
    {
      title: t('invoices_reminders.columns.syndic_and_manager'),
      key: 'syndic',
      render: (record) => (
        <Row>
          <Col span={24}>
            <Text>{`${record?.syndic.code} ${record?.syndic.society_name}`}</Text>
          </Col>
        </Row>
      ),
      sorter: true
    },
    {
      title: t('invoices_reminders.columns.phone_number'),
      key: 'phone_number',
      render: (record) => {
        const contact = record?.syndic.contact?.phone_number;
        if (contact?.country_code && contact?.number) {
          return `${contact.country_code} ${contact.number}`;
        }
        if (contact?.number) {
          return contact?.number;
        }
        return '-';
      },
      sorter: true
    },
    {
      title: t('invoices_reminders.columns.last_reminder'),
      key: 'last_reminder',
      render: (record) => {
        let current = record.managers[0].invoices[0]?.last_reminder;
        const managers = record?.managers;

        managers.forEach((manager) => {
          manager.invoices.forEach((invoice) => {
            if (invoice?.last_reminder > current) {
              current = invoice.last_reminder;
            }
          });
        });
        if (current) {
          return dayjs(current).format('DD-MM-YYYY');
        }
        return 'Aucune';
      },
      sorter: true
    },
    {
      title: t('invoices_reminders.columns.next_reminder'),
      key: 'next_reminder',
      render: (record) => {
        let current = record.managers[0].invoices[0]?.next_reminder;
        const managers = record?.managers;

        managers.forEach((manager) => {
          manager.invoices.forEach((invoice) => {
            if (invoice?.next_reminder === 'Aucune') {
              current = invoice?.next_reminder;
            }
            if (current !== 'Aucune' && invoice?.next_reminder > current) {
              current = invoice.next_reminder;
            }
          });
        });
        if (current !== 'Aucune') {
          return dayjs(current).format('DD-MM-YYYY');
        }
        return 'Aucune';
      },
      sorter: true
    },
    {
      title: t('invoices_reminders.columns.number_invoices'),
      key: 'nb_invoices',
      render: (record) => {
        let count = 0;

        record.managers.forEach((manager) => {
          count += manager.invoices.length;
        });
        return count;
      },
      sorter: true
    },
    {
      title: t('invoices_reminders.columns.total_billed'),
      key: 'total_billed',
      render: (record) => {
        const total = getTotalBilled(record?.managers || [], 'total_ttc');
        return total ? `${parseFloat(total).toFixed(2)} €` : '0 €';
      },
      sorter: true
    },
    {
      title: t('invoices_reminders.columns.paid'),
      key: 'total_paid',
      render: (record) => {
        const total = getTotalBilled(record?.managers || [], 'paid');
        return total ? `${parseFloat(total).toFixed(2)} €` : '0 €';
      },
      sorter: true
    },
    {
      title: t('invoices_reminders.columns.remainder_to_pay'),
      key: 'left_to_pay',
      render: (record) => {
        const total = getTotalBilled(record?.managers || [], 'left_to_pay');
        return total ? `${parseFloat(total).toFixed(2)} €` : '0 €';
      },
      sorter: true
    },
    {
      title: t('invoices_reminders.columns.comment'),
      key: 'comment',
      render: (record) => {
        const text = record?.syndic.comment
          ? record.syndic.comment
          : 'sans commentaire';

        return (
          <Tooltip placement="bottom" title={text}>
            <p>{text}</p>
          </Tooltip>
        );
      },
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/syndics/show/${record._id}`}>
            <EyeOutlined style={iconStyle} />
          </Link>
          <EditOutlined
            style={iconStyle}
            onClick={() => {
              setOpenAddComment(true);
              setSelectedSyndic(record);
            }}
          />
          <MailOutlined style={iconStyle} />
        </Space>
      )
    }
  ];
};
