import { Modal, message as antdMessage } from 'antd';

/**
 * Handles the file upload process when maxFilesCount is equal to 1.
 *
 * @function
 * @param {Array} fieldsFilesList - The list of files currently uploaded.
 * @param {function} setFieldsFileList - A setter function for the file list.
 * @param {File} fileItem - The file item to be uploaded.
 * @param {function} setFilesConfiguration - A setter function for the files configuration.
 * @param {string} name - The name of the field associated with the file.
 * @param {function} deleteFile - A function for deleting a file.
 * @param {function} resolve - The resolve function from the Promise.
 * @param {function} reject - The reject function from the Promise.
 * @param {function} setFilesToUpload - A function to set filesToUpload.
 * @param {Array} requiredFileType - An array of file extensions required.
 * @param {function} t - A function for translating message strings.
 * @param {string} documentRepositoryType - The type of the document repository.
 * @param {string} cerfaDocumentType - The type of the cerfa document.
 * @param {string} variousDocumentType - The type of the various document.
 * @param {string} requestedDocumentType - The type of the requested document.
 * @param {string} reWrittenDocumentType - The type of the rewritten document.
 * @param {string} title - The title of the file uploader component.
 * @returns {void}
 */
const handleMaxFilesCountEqualToOne = (
  fieldsFilesList,
  setFieldsFileList,
  fileItem,
  setFilesConfiguration,
  name,
  deleteFile,
  resolve,
  reject,
  setFilesToUpload,
  requiredFileType,
  t,
  documentRepositoryType,
  cerfaDocumentType,
  variousDocumentType,
  requestedDocumentType,
  reWrittenDocumentType,
  title
) => {
  const document = fieldsFilesList[name][0];
  const reader = new FileReader();

  reader.readAsDataURL(fileItem);
  reader.onload = async () => {
    const newFile = {
      file: fileItem,
      url: reader.result,
      fileKey: name
    };
    const fileExtension = newFile.file.name.split('.')[1];
    if (
      (requiredFileType || []).length &&
      !requiredFileType.includes(fileExtension)
    ) {
      antdMessage.error(
        t(
          'dashboard.production.cards.creation.modal.notifications.required_file_type_error',
          {
            formats: requiredFileType.join(', ')
          }
        )
      );
      return;
    }
    if (document?.file?._id) {
      await deleteFile(document.file._id);
    }
    setFieldsFileList((prevList) => {
      const updatedList = { ...prevList };
      const indexToReplace = 0;

      const fieldArray = updatedList[name];
      if (fieldArray) {
        fieldArray[indexToReplace] = newFile;
      }

      return updatedList;
    });
    setFilesToUpload((prevList) => {
      const existingFileIndex = prevList.findIndex(
        (file) => file.name === name
      );
      if (existingFileIndex !== -1) {
        return [
          ...prevList.slice(0, existingFileIndex),
          newFile,
          ...prevList.slice(existingFileIndex + 1)
        ];
      }
      return [...prevList, newFile];
    });
    setFilesConfiguration((prevList) => {
      const updatedList = prevList.filter(
        (file) => file.fileKey !== name.join('')
      );
      return [
        ...updatedList,
        {
          name: fileItem.name,
          fileKey: name.join(''),
          source: 'field',
          type: fileItem.type,
          documentType: {
            documentRepositoryType,
            cerfaDocumentType,
            variousDocumentType,
            requestedDocumentType
          },
          re_written_document_type: reWrittenDocumentType,
          title
        }
      ];
    });
    resolve(false);
  };
  reader.onerror = (error) => reject(error);
};

/**
 * Shows a warning modal when the max file count is reached.
 *
 * @function
 * @param {function} t - A function for translating message strings.
 * @returns {void}
 */
const handleMaxFilesCountSuperiorToOneAndReached = (t) => {
  Modal.warning({
    title: t('files.modal.maxCount_reached'),
    content: t('files.modal.maxCount_reached_content'),
    okText: t('files.modal.ok'),
    okType: 'warning'
  });
};

/**
 * Default case handler for the file upload process.
 *
 * @function
 * @param {Array} fileList - The list of files currently uploaded.
 * @param {function} setFieldsFileList - A setter function for the file list.
 * @param {File} fileItem - The file item to be uploaded.
 * @param {function} setFilesConfiguration - A setter function for the files configuration.
 * @param {string} name - The name of the field associated with the file.
 * @param {function} resolve - The resolve function from the Promise.
 * @param {function} reject - The reject function from the Promise.
 * @param {function} setFilesToUpload - A function to set filesToUpload.
 * @param {Array} requiredFileType - An array of file extensions required.
 * @param {function} t - A function for translating message strings.
 * @param {string} documentRepositoryType - The type of the document repository.
 * @param {string} cerfaDocumentType - The type of the cerfa document.
 * @param {string} variousDocumentType - The type of the various document.
 * @param {string} requestedDocumentType - The type of the requested document.
 * @param {string} reWrittenDocumentType - The type of the rewritten document.
 * @param {string} title - The title of the file uploader component.
 * @returns {void}
 */
const defaultCase = (
  fileList,
  setFieldsFileList,
  fileItem,
  setFilesConfiguration,
  name,
  resolve,
  reject,
  setFilesToUpload,
  requiredFileType,
  t,
  documentRepositoryType,
  cerfaDocumentType,
  variousDocumentType,
  requestedDocumentType,
  reWrittenDocumentType,
  title
) => {
  const reader = new FileReader();

  reader.readAsDataURL(fileItem);
  reader.onload = () => {
    const newFile = {
      file: fileItem,
      url: reader.result,
      fileKey: name
    };
    const fileExtension = newFile.file.name.split('.')[1];
    if (
      (requiredFileType || []).length &&
      !requiredFileType.includes(fileExtension)
    ) {
      antdMessage.error(
        t(
          'dashboard.production.cards.creation.modal.notifications.required_file_type_error',
          {
            formats: requiredFileType.join(', ')
          }
        )
      );
      return;
    }
    setFilesToUpload((prevList) => {
      const existingFileIndex = prevList.findIndex(
        (file) => file.file.name === fileItem.name
      );
      if (existingFileIndex !== -1) {
        return [
          ...prevList.slice(0, existingFileIndex),
          { ...newFile, name },
          ...prevList.slice(existingFileIndex + 1)
        ];
      }
      return [...prevList, newFile];
    });
    setFieldsFileList((prevList) => {
      const updatedList = { ...prevList };

      const fieldArray = updatedList[name];
      if (fieldArray) {
        fieldArray.push(newFile);
      }

      return updatedList;
    });
    setFilesConfiguration((prevList) => [
      ...prevList,
      {
        name: fileItem.name,
        fileKey: name.join(''),
        source: 'field',
        type: fileItem.type,
        documentType: {
          documentRepositoryType,
          cerfaDocumentType,
          variousDocumentType,
          requestedDocumentType
        },
        re_written_document_type: reWrittenDocumentType,
        title
      }
    ]);
    resolve(false);
  };
  reader.onerror = (error) => reject(error);
};

/**
 * Handles the upload process of a file.
 *
 * @function
 * @param {File} fileItem - The file item to be uploaded.
 * @param {Array} fieldsFilesList - The list of files currently uploaded.
 * @param {Array} filesConfiguration - The configuration of the files.
 * @param {function} setFieldsFileList - A setter function for the file list.
 * @param {function} setFilesConfiguration - A setter function for the files configuration.
 * @param {number} maxFilesCount - The maximum count of files that can be uploaded.
 * @param {function} deleteFile - A function for deleting a file.
 * @param {function} message - A function for displaying messages.
 * @param {function} t - A function for translating message strings.
 * @param {string} name - The name of the field associated with the file.
 * @param {function} setFilesToUpload - A function to set filesToUpload.
 * @param {Array} requiredFileType - An array of file extensions required.
 * @param {string} documentRepositoryType - The type of the document repository.
 * @param {string} cerfaDocumentType - The type of the cerfa document.
 * @param {string} variousDocumentType - The type of the various document.
 * @param {string} requestedDocumentType - The type of the requested document.
 * @param {string} reWrittenDocumentType - The type of the rewritten document.
 * @param {string} title - The title of the file uploader component.
 * @returns {Promise} A promise that resolves if the file is successfully prepared for upload, or rejects if an error occurs.
 */
export const beforeUpload = (
  fileItem,
  fieldsFilesList,
  filesConfiguration,
  setFieldsFileList,
  setFilesConfiguration,
  maxFilesCount,
  deleteFile,
  message,
  t,
  name,
  setFilesToUpload,
  requiredFileType,
  documentRepositoryType,
  cerfaDocumentType,
  variousDocumentType,
  requestedDocumentType,
  reWrittenDocumentType,
  title
) =>
  new Promise((resolve, reject) => {
    const isFileExist = filesConfiguration.some(
      (file) => file.name === fileItem.name
    );

    switch (true) {
      case isFileExist:
        message(t('existing_file_name'));
        resolve(false);
        break;
      case maxFilesCount === 1:
        handleMaxFilesCountEqualToOne(
          fieldsFilesList,
          setFieldsFileList,
          fileItem,
          setFilesConfiguration,
          name,
          deleteFile,
          resolve,
          reject,
          setFilesToUpload,
          requiredFileType,
          t,
          documentRepositoryType,
          cerfaDocumentType,
          variousDocumentType,
          requestedDocumentType,
          reWrittenDocumentType,
          title
        );
        break;
      case fieldsFilesList[name].length >= maxFilesCount:
        handleMaxFilesCountSuperiorToOneAndReached(t);
        break;
      default:
        defaultCase(
          fieldsFilesList,
          setFieldsFileList,
          fileItem,
          setFilesConfiguration,
          name,
          resolve,
          reject,
          setFilesToUpload,
          requiredFileType,
          t,
          documentRepositoryType,
          cerfaDocumentType,
          variousDocumentType,
          requestedDocumentType,
          reWrittenDocumentType,
          title
        );
    }
  });
