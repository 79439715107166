import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import {
  CheckCircleOutlined,
  HourglassOutlined,
  PauseCircleOutlined,
  SunOutlined
} from '@ant-design/icons';
import { EditableTableMethods } from '../../../../../components/editableTableComponents/methods';

/**
 * Returns an array of columns for the collective ownership table.
 * @hoook
 * @param {Object} options - The options for the columns.
 * @param {Function} options.setEditingKey - The function to set the editing key.
 * @param {Object} options.form - The form object.
 * @returns {Array} An array of columns.
 */
export const useColumns = ({ setEditingKey, form }) => {
  const { t } = useTranslation();
  const { editCell } = EditableTableMethods();

  const renderStatus = (status) => {
    switch (status) {
      case 'IN_PROGRESS':
        return <HourglassOutlined />;
      case 'VALIDATED':
        return <CheckCircleOutlined />;
      case 'ACTIVE':
        return <SunOutlined />;
      case 'INACTIVE':
        return <PauseCircleOutlined />;
      default:
        return null;
    }
  };

  return [
    {
      title: t('pcs-pre-productions.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('pcs-pre-productions.form.address.second_title'),
      key: 'address',
      render: (record) =>
        `${record?.first_address?.number || ''} ${
          record?.first_address?.street || ''
        } ${record?.first_address?.postal_code || ''} ${
          record?.first_address?.city || ''
        }`,
      sorter: true
    },
    {
      title: t('pcs-pre-productions.form.cadastral_reference'),
      key: 'cadastral_reference',
      dataIndex: 'cadastral_reference',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({
              record,
              key: 'cadastral_reference',
              setEditingKey,
              form
            })
          }
        >
          {record?.cadastral_reference || '-'}
        </Row>
      ),
      sorter: true
    },
    {
      title: t('pcs-pre-productions.form.code'),
      key: 'code',
      dataIndex: 'code',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({
              record,
              key: 'code',
              setEditingKey,
              form
            })
          }
        >
          {record?.code || '-'}
        </Row>
      ),
      sorter: true
    },
    {
      key: 'action',
      fixed: 'right',
      width: 60,
      render: (record) => renderStatus(record.status)
    }
  ];
};
