import { useEffect, useState } from 'react';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFieldsRight } from './useFields';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem/generateFormItem';
import { useDraggerField } from '../../../utils/DraggerField/useDraggerField';

const { Text, Title } = Typography;

const textStyle = { margin: 0, padding: 0, lineHeight: '3rem' };

/**
 * Component for submitting documents.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values for the form.
 * @param {Object} props.filesList - The list of files.
 * @param {Function} props.setFilesList - The function to update the files list.
 * @param {Function} props.setFilesConfiguration - The function to update the files configuration.
 * @param {Object} props.filesConfiguration - The configuration for the files.
 * @param {string} props.purpose - The purpose of the file.
 * @param {Function} props.deleteFile - The function to delete
 * @returns {JSX.Element} The RCPDocuments component.
 */
export const RCPDocuments = ({
  form,
  initialValues,
  filesList,
  setFilesList,
  setFilesConfiguration,
  filesConfiguration,
  purpose,
  deleteFile
}) => {
  const generateFields = useGenerateFormItem();
  const [noRCPDocumentChecked, setNoRCPDocumentChecked] = useState(false);
  useEffect(() => {
    if (initialValues?.cadastral_reference) {
      setNoRCPDocumentChecked(true);
      form.setFieldsValue({ no_rcp_document: true });
    }
  }, [initialValues]);

  const fieldsLeft = useDraggerField({
    fileName: 'rcp',
    filesList,
    setFilesList,
    setFilesConfiguration,
    filesConfiguration,
    purpose,
    deleteFile,
    disableDraggerField: noRCPDocumentChecked
  });
  const fieldsRight = useFieldsRight({
    filesList,
    noRCPDocumentChecked,
    setNoRCPDocumentChecked
  });

  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Divider>
          <Title level={5}>
            {t('pcs-pre-productions.form.submit_documents_divider')}
          </Title>
        </Divider>
        <Flex vertical align="center">
          <Text style={textStyle}>
            {t('pcs-pre-productions.form.submit_rcp')}
          </Text>
          <Text style={textStyle}>
            {t('pcs-pre-productions.form.more_info')}
          </Text>
          {fieldsLeft.map((field) =>
            generateFields('pcs-pre-productions', field)
          )}
        </Flex>
      </Col>
      <Col span={12}>
        <Divider>
          <Title level={5}>{t('pcs-pre-productions.form.no_document')}</Title>
        </Divider>
        <Text style={textStyle}>
          {t('pcs-pre-productions.form.for_validation')}
        </Text>
        {fieldsRight.map((field) =>
          generateFields('pcs-pre-productions', field)
        )}
      </Col>
    </Row>
  );
};

RCPDocuments.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  initialValues: PropTypes.shape({
    cadastral_reference: PropTypes.string
  }).isRequired,
  filesList: PropTypes.shape({}).isRequired,
  setFilesList: PropTypes.func.isRequired,
  setFilesConfiguration: PropTypes.func.isRequired,
  filesConfiguration: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired
};
