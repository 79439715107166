import { UploadOutlined } from '@ant-design/icons';
import { Building, Enterprise, User } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../../utils/constants/routes/productionRoutes';

/**
 * useCreationCardButtons Hook
 *
 * This hook generates an array of button configurations for the CreationCard component.
 * Each button configuration includes a label, an icon, and either a navigation path or an onClick handler.
 * @hook
 * @param {Object} params - The hook parameters.
 * @param {function} params.setIsOpenModal - The function to set the state of the modal's open status.
 * @returns {Array} An array of button configurations.
 */
export const useCreationCardButtons = ({ setIsOpenModal }) => {
  const { t } = useTranslation();

  return [
    {
      label: t('dashboard.production.cards.creation.syndic'),
      icon: <Enterprise className="anticon" />,
      path: `${routes.SYNDICS}/create`
    },
    {
      label: t('dashboard.production.cards.creation.manager'),
      icon: <User className="anticon" />,
      path: `${routes.USERS}/create`
    },
    {
      label: t('dashboard.production.cards.creation.collective_ownership'),
      icon: <Building className="anticon" />,
      path: `${routes.COLLECTIVE_OWNERSHIPS}/create`
    },
    {
      label: t(
        'dashboard.production.cards.creation.multiple_collective_ownerships'
      ),
      icon: <UploadOutlined className="anticon" />,
      onClick: () => {
        setIsOpenModal((prev) => !prev);
      }
    }
  ];
};
