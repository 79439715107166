import { Button, Col, Row, Select, Switch, Tooltip, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getChargingGrid } from '../utils/getChargingGrid';
import { createNewChargingGrid } from '../utils/createNewChargingGrid';
import { deleteChargingGrid } from '../utils/deleteChargingGrid';
import { handleSaveAllTable } from '../utils/handleSaveAllTable';
import { handleEditAllTable } from '../utils/handleEditAllTable';
import { useChargingGridPermissions } from '../utils/usePermissions';

const { Text } = Typography;
const { Option } = Select;

/**
 * ActionButtons component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Flag indicating if the component is in a loading state.
 * @param {function} props.setIsFinalVersion - Function to set the final version flag.
 * @param {Array} props.resourceDatas - Array of resource data.
 * @param {function} props.setChargingGrid - Function to set the charging grid.
 * @param {boolean} props.setIsVersionButton - Flag indicating if the version button is active.
 * @param {Object} props.chargingGrid - The charging grid object.
 * @param {boolean} props.isVersionButton - Flag indicating if the version button is active.
 * @param {function} props.dispatchAPI - Function to dispatch API actions.
 * @param {function} props.setResourceDatas - Function to set the resource data.
 * @param {string} props.message - The message string.
 * @param {Array} props.setSelectedRowKeys - Array of selected row keys.
 * @param {function} props.t - Function for translation.
 * @param {boolean} props.isFinalVersion - Flag indicating if it's the final version.
 * @param {function} props.setIsLotModalOpen - Function to set the lot modal open flag.
 * @param {function} props.setIsEditLotModalOpen - Function to set the edit lot modal open flag.
 * @param {function} props.setIsMergeLotModalOpen - Function to set the merge lot modal open flag.
 * @param {function} props.setIsDivisionLotModalOpen - Function to set the division lot modal open flag.
 * @param {function} props.setIsColumnModalOpen - Function to set the column modal open flag.
 * @param {function} props.setDuplicateColumn - Function to set the duplicate column.
 * @param {Array} props.selectedRowKeys - Array of selected row keys.
 * @param {function} props.setIsLabelModalOpen - Function to set the label modal open flag.
 * @param {Object} props.form - The form object.
 * @param {Array} props.dataSource - The data source array.
 * @param {boolean} props.setEditTable - Function to set the edit table flag.
 * @param {boolean} props.editTable - Flag indicating if the table is in edit mode.
 * @param {function} props.handleSaveAllTableButtonRef - Function to handle the save all table button ref.
 * @param {function} props.handleEditAllTableButtonRef - Function to handle the edit all table button ref.
 * @param {function} props.setSelectedRowKey - Function to set the selected row key.
 * @param {boolean} props.isLabelAndDate - Flag indicating if there is a label and date.
 * @returns {JSX.Element} The rendered ActionButtons component.
 */
export const ActionButtons = ({
  isLoading,
  setIsFinalVersion,
  resourceDatas,
  setChargingGrid,
  setIsVersionButton,
  chargingGrid,
  isVersionButton,
  dispatchAPI,
  setResourceDatas,
  message,
  setSelectedRowKeys,
  t,
  isFinalVersion,
  setIsLotModalOpen,
  setIsEditLotModalOpen,
  setIsMergeLotModalOpen,
  setIsDivisionLotModalOpen,
  setIsColumnModalOpen,
  setDuplicateColumn,
  selectedRowKeys,
  setIsLabelModalOpen,
  form,
  dataSource,
  setEditTable,
  editTable,
  handleSaveAllTableButtonRef,
  handleEditAllTableButtonRef,
  setSelectedRowKey,
  isLabelAndDate
}) => {
  const { actionButtonsPermissions } = useChargingGridPermissions();
  const {
    disableEditButton,
    disableAddColumnButton,
    disasbleAddLotButton,
    disableEditLotButton,
    disableNewVersionButton,
    disableFinalVersionSwitch,
    disableDeleteChargingGridButton
  } = actionButtonsPermissions;

  return (
    <Col xs={24} xxl={24}>
      <Row align="middle" justify="space-between" gutter={[12, 12]}>
        <Col>
          <Row gutter={[12, 12]} align="middle">
            <Col>
              <Row gutter={[6, 6]} align="middle">
                <Col>
                  <Text>{t('charging-grids.show.filter_label.version')}</Text>
                </Col>
                <Col>
                  <Select
                    loading={isLoading}
                    onChange={(_, option) => {
                      setIsFinalVersion(false);
                      getChargingGrid({
                        chargingGrid: option?.data,
                        resourceDatas,
                        setChargingGrid,
                        setIsVersionButton
                      });
                    }}
                    value={chargingGrid._id}
                    showSearch
                    allowClear
                    style={{ minWidth: 150 }}
                    filterOption={(input, option) =>
                      (option?.value ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {(resourceDatas.charging_grid || []).map((grid) => (
                      <Option key={grid._id} value={grid._id} data={grid}>
                        {`V${grid.version} ${moment(grid.date).format(
                          'DD/MM/YYYY'
                        )}`}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                {isVersionButton && !disableNewVersionButton ? (
                  <Button
                    disabled={!isLabelAndDate}
                    type="primary"
                    onClick={() => {
                      createNewChargingGrid({
                        dispatchAPI,
                        chargingGrid,
                        setResourceDatas,
                        resourceDatas,
                        setChargingGrid,
                        message,
                        setIsVersionButton,
                        setIsLabelModalOpen
                      });
                      setSelectedRowKeys([]);
                    }}
                    style={{ marginRight: 6 }}
                  >
                    <PlusOutlined />
                    {t('charging-grids.show.buttons.create_version')}
                  </Button>
                ) : null}
                {isVersionButton &&
                chargingGrid.version !== 1 &&
                !disableDeleteChargingGridButton ? (
                  <Tooltip
                    title={t('charging-grids.show.buttons.delete_version')}
                  >
                    <Button
                      disabled={!isLabelAndDate}
                      type="link"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        deleteChargingGrid({
                          chargingGrid,
                          dispatchAPI,
                          resourceDatas,
                          setChargingGrid,
                          setResourceDatas,
                          setIsVersionButton
                        })
                      }
                    />
                  </Tooltip>
                ) : null}
              </Row>
            </Col>
            {chargingGrid.version !== 1 && !disableFinalVersionSwitch ? (
              <Col>
                <Row align="middle">
                  {t('charging-grids.show.final_version')}
                  <Switch
                    disabled={!isLabelAndDate}
                    checked={isFinalVersion}
                    defaultChecked={isFinalVersion}
                    onChange={() => setIsFinalVersion(!isFinalVersion)}
                    style={{ marginLeft: 6 }}
                  />
                </Row>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Row gutter={[6, 6]}>
          {!editTable ? (
            <Col>
              {!disableEditButton && (
                <Button
                  disabled={!isLabelAndDate}
                  type="primary"
                  ref={handleEditAllTableButtonRef}
                  onClick={() =>
                    handleEditAllTable({
                      setEditTable,
                      editTable,
                      setSelectedRowKey,
                      dataSource,
                      form
                    })
                  }
                >
                  {t('charging-grids.show.buttons.edit_table')}
                </Button>
              )}
            </Col>
          ) : (
            <>
              <Col>
                <Button type="link" danger onClick={() => setEditTable(false)}>
                  {t('charging-grids.show.buttons.cancel')}
                </Button>
              </Col>

              <Col>
                <Button
                  disabled={!isLabelAndDate}
                  type="primary"
                  ref={handleSaveAllTableButtonRef}
                  onClick={() =>
                    handleSaveAllTable({
                      form,
                      dataSource,
                      dispatchAPI,
                      chargingGrid,
                      resourceDatas,
                      setResourceDatas,
                      setChargingGrid,
                      setEditTable,
                      message
                    })
                  }
                >
                  {t('charging-grids.show.buttons.save')}
                </Button>
              </Col>
            </>
          )}
          <Col>
            {!disableAddColumnButton && (
              <Button
                disabled={!isLabelAndDate}
                type="primary"
                onClick={() => {
                  setDuplicateColumn(null);
                  setIsColumnModalOpen(true);
                }}
              >
                {t('charging-grids.show.buttons.add_column')}
              </Button>
            )}
          </Col>
          <Col>
            {!disasbleAddLotButton && (
              <Button
                disabled={!isLabelAndDate}
                onClick={() => setIsLotModalOpen(true)}
                type="primary"
              >
                {t('charging-grids.show.buttons.add_lot')}
              </Button>
            )}
          </Col>
          <Col>
            {!disableEditLotButton && (
              <Button
                disabled={!isLabelAndDate}
                onClick={() => setIsEditLotModalOpen(true)}
                type="primary"
              >
                {t('charging-grids.show.buttons.edit_lot')}
              </Button>
            )}
          </Col>
          {selectedRowKeys.length > 1 ? (
            <Col>
              <Button
                disabled={!isLabelAndDate}
                onClick={() => setIsMergeLotModalOpen(true)}
                type="primary"
              >
                {t('charging-grids.show.buttons.merge')}
              </Button>
            </Col>
          ) : null}
          {selectedRowKeys.length === 1 ? (
            <Col>
              <Button
                disabled={!isLabelAndDate}
                onClick={() => setIsDivisionLotModalOpen(true)}
                type="primary"
              >
                {t('charging-grids.show.buttons.divide')}
              </Button>
            </Col>
          ) : null}
        </Row>
      </Row>
    </Col>
  );
};

ActionButtons.propTypes = {
  chargingGrid: PropTypes.shape({
    label: PropTypes.string,
    document_date: PropTypes.string,
    _id: PropTypes.string,
    version: PropTypes.number
  }),
  isFinalVersion: PropTypes.bool,
  dispatchAPI: PropTypes.func,
  message: PropTypes.func,
  resourceDatas: PropTypes.shape({
    _id: PropTypes.string,
    charging_grid: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        version: PropTypes.number,
        date: PropTypes.string
      })
    )
  }),
  setChargingGrid: PropTypes.func,
  setIsVersionButton: PropTypes.func,
  t: PropTypes.func,
  setIsLotModalOpen: PropTypes.func,
  setIsEditLotModalOpen: PropTypes.func,
  setIsMergeLotModalOpen: PropTypes.func,
  setIsDivisionLotModalOpen: PropTypes.func,
  setIsColumnModalOpen: PropTypes.func,
  setDuplicateColumn: PropTypes.func,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  setSelectedRowKeys: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsFinalVersion: PropTypes.func,
  setResourceDatas: PropTypes.func,
  isVersionButton: PropTypes.bool,
  setIsLabelModalOpen: PropTypes.func,
  form: PropTypes.shape({
    validateFields: PropTypes.func
  }),
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number
    })
  ),
  setEditTable: PropTypes.func,
  editTable: PropTypes.bool,
  handleSaveAllTableButtonRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }),
  handleEditAllTableButtonRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  }),
  setSelectedRowKey: PropTypes.func,
  isLabelAndDate: PropTypes.bool,
  disabledButtons: PropTypes.shape({
    disableEditButton: PropTypes.bool,
    disableAddColumnButton: PropTypes.bool,
    disasbleAddLotButton: PropTypes.bool,
    disableEditLotButton: PropTypes.bool
  })
};

ActionButtons.defaultProps = {
  chargingGrid: {
    label: '',
    document_date: ''
  },
  isFinalVersion: false,
  dispatchAPI: () => {},
  message: () => {},
  resourceDatas: {},
  setChargingGrid: () => {},
  setIsVersionButton: () => {},
  t: () => {},
  setIsLotModalOpen: () => {},
  setIsEditLotModalOpen: () => {},
  setIsMergeLotModalOpen: () => {},
  setIsDivisionLotModalOpen: () => {},
  setIsColumnModalOpen: () => {},
  setDuplicateColumn: () => {},
  selectedRowKeys: [],
  setSelectedRowKeys: () => {},
  isLoading: false,
  setIsFinalVersion: () => {},
  setResourceDatas: () => {},
  isVersionButton: false,
  setIsLabelModalOpen: () => {},
  form: {
    validateFields: () => {}
  },
  dataSource: [],
  setEditTable: () => {},
  editTable: false,
  handleSaveAllTableButtonRef: { current: null },
  handleEditAllTableButtonRef: { current: null },
  setSelectedRowKey: () => {},
  isLabelAndDate: false,
  disabledButtons: {
    disableEditButton: false,
    disableAddColumnButton: false,
    disasbleAddLotButton: false,
    disableEditLotButton: false
  }
};
