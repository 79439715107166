import { Button, Card, Flex } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
/**
 * This component renders a card with a title and a set of buttons.
 * Each button can have an onClick handler or navigate to a specified path.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the card.
 * @param {Array} props.cardButtons - An array of button configurations.
 *        Each configuration includes a label, optional icon, optional path to navigate to, and an optional onClick handler.
 * @returns {JSX.Element} The rendered card component with buttons.
 */
export const BaseCard = ({ title, cardButtons }) => {
  const navigate = useNavigate();

  return (
    <Card style={{ flexGrow: 1 }} title={title}>
      <Flex vertical justify="space-around">
        {cardButtons.map((cardButton) => (
          <Button
            key={cardButton.label}
            onClick={
              cardButton?.onClick
                ? cardButton.onClick
                : () => cardButton?.path && navigate(cardButton.path)
            }
            type="primary"
            style={{ marginBottom: 8 }}
          >
            {cardButton?.icon && cardButton.icon}
            {cardButton?.label && cardButton.label}
          </Button>
        ))}
      </Flex>
    </Card>
  );
};

BaseCard.propTypes = {
  title: PropTypes.string,
  cardButtons: PropTypes.arrayOf(PropTypes.shape({}))
};

BaseCard.defaultProps = {
  title: '',
  cardButtons: []
};
