import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom, ContentCustom } from '../../../components';
import { ExpectedActionsCard } from './cards/ExpectedActionsCard/ExpectedActionsCard';
import { CreationCard } from './cards/CreationCard/CreationCard';
import { QuoteGenerationCard } from './cards/QuoteGenerationCard/QuoteGenerationCard';
import { ActCopyResearchCard } from './cards/ActCopyResearchCard/ActCopyResearchCard';
import { PCSMissionsStatisticsCard } from './cards/PCSMissionsStatisticsCard/PCSMissionsStatisticsCard';

/**
 * ProductionDashboard Component
 *
 * This component represents the production dashboard layout, displaying various cards
 * related to production activities such as expected actions, creation, quote generation,
 * act copy research, and PCS missions statistics.
 * @component
 * @returns {JSX.Element} The ProductionDashboard component.
 */
export const ProductionDashboard = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderCustom title={t('dashboard.title')} />
      <ContentCustom>
        <Row gutter={[16, 16]} align="top">
          <Col style={{ width: '40%' }}>
            <ExpectedActionsCard />
          </Col>
          <Row gutter={[8, 8]} style={{ width: '60%' }}>
            <Col style={{ flexGrow: 1, width: '30%' }}>
              <CreationCard />
            </Col>
            <Col style={{ flexGrow: 1, width: '30%' }}>
              <QuoteGenerationCard />
            </Col>
            <Col style={{ width: '100%' }}>
              <ActCopyResearchCard />
            </Col>
            <Col style={{ width: '100%' }}>
              <PCSMissionsStatisticsCard />
            </Col>
          </Row>
        </Row>
      </ContentCustom>
    </>
  );
};
