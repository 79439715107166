import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { BaseModel } from './BaseModel';

/**
 * Represents an quotation actions.
 * @class
 */
export class QuotationAction extends BaseModel {
  static resourceName = 'quotation-actions';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${QuotationAction.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${QuotationAction.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Formats an array of actions into an object with action IDs as keys.
   * Each action object is modified to include a formatted AG_date property.
   *
   * @function
   * @param {Array} actionsArray - The array of actions to be formatted.
   * @returns {Object} - The formatted actions object with action IDs as keys.
   */
  static formatActions = (actionsArray) =>
    actionsArray.reduce((acc, action) => {
      acc[action._id] = action;
      acc[action._id].AG_date = action.AG_date ? dayjs(action.AG_date) : null;
      return acc;
    }, {});

  /**
   * Adds a quotation action.
   *
   * @function
   * @param {Object} options - The options for adding a quotation action.
   * @param {Function} options.setFormData - The function to set the form data.
   * @param {Function} options.setQuotationActions - The function to set the quotation actions.
   * @param {Object} options.message - The message object.
   * @param {Function} options.t - The translation function.
   * @returns {Promise<Object|null>} - A promise that resolves to the added quotation action data, or null if the purpose is 'edit'.
   */
  static addQuotationAction({ setFormData, setQuotationActions, message, t }) {
    const quotationActionBody = {
      _id: uuidv4(),
      action: null,
      AG_date: null,
      resolution_ref: null,
      description: null,
      other_ref: null,
      data: {
        description: null,
        created_lot_number: null,
        divide_lot_number: null,
        merge_lot_number: null,
        created_lot_ref: [],
        divide_lot_ref: [],
        merge_lot_ref: [],
        impacted_lot_number: null,
        modified_lot_number: null,
        give_lot_number: null,
        buyer: null,
        seller: null,
        amount: null,
        remove_lot_number: null,
        remove_lot_ref: []
      }
    };

    setQuotationActions((prev) => [...prev, quotationActionBody]);
    setFormData((prev) => ({
      ...prev,
      actions: { ...prev.actions, ...this.formatActions([quotationActionBody]) }
    }));

    message.success(t('quotation-actions.messages.QUOTATION_ACTION_ADDED'));

    return null;
  }

  /**
   * Removes a quotation action.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {string} options.purpose - The purpose of the action (e.g., 'edit').
   * @param {Function} options.setFormData - The function to update the form data.
   * @param {Function} options.setQuotationActions - The function to update the quotation actions.
   * @param {Object} options.message - The message object for displaying success messages.
   * @param {Function} options.t - The translation function.
   * @returns {null} - Returns null.
   */
  static removeQuotationAction({
    id,
    setFormData,
    setQuotationActions,
    message,
    t
  }) {
    setQuotationActions((prev) => prev.filter((el) => el._id !== id));
    setFormData((prev) => {
      const { [id]: removedKey, ...rest } = prev.actions || {};
      return {
        ...prev,
        actions: rest
      };
    });

    message.success(t('quotation-actions.messages.QUOTATION_ACTION_REMOVED'));

    return null;
  }

  /**
   * Duplicates a quotation action.
   *
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {string} options.id - The ID of the quotation action to duplicate.
   * @param {Function} options.setFormData - The function to update the form data.
   * @param {Function} options.setQuotationActions - The function to update the quotation actions.
   * @param {Object} options.message - The message object for displaying success messages.
   * @param {Function} options.t - The translation function.
   * @returns {Promise<Object|null>} - A promise that resolves to the duplicated quotation action data, or null if the purpose is 'edit'.
   */
  static async duplicateQuotationAction({
    id,
    form,
    setFormData,
    setQuotationActions,
    message,
    t
  }) {
    const quotationActionToDuplicate = form.getFieldValue(['actions', id]);

    if (!quotationActionToDuplicate) {
      return null;
    }
    const duplicatedQuotationAction = {
      ...quotationActionToDuplicate,
      _id: uuidv4()
    };

    setQuotationActions((prev) => [...prev, duplicatedQuotationAction]);
    await form.setFieldsValue({
      actions: { [duplicatedQuotationAction._id]: duplicatedQuotationAction }
    });
    setFormData((prev) => ({
      ...prev,
      actions: {
        ...prev.actions,
        ...this.formatActions([duplicatedQuotationAction])
      }
    }));

    message.success(
      t('quotation-actions.messages.QUOTATION_ACTION_DUPLICATED')
    );
    return duplicatedQuotationAction;
  }

  /**
   * Sets the value at the specified path in the given object.
   *
   * @function
   * @param {object} obj - The object to set the value in.
   * @param {Array<string>} path - The path to the property where the value should be set.
   * @param {*} value - The value to set.
   * @returns {void}
   */
  static setValueAtPath = (obj, path, value) => {
    let current = obj;
    for (let i = 0; i < path.length - 1; i += 1) {
      const segment = path[i];
      if (!current[segment]) {
        current[segment] = {};
      }
      current = current[segment];
    }
    current[path[path.length - 1]] = value;
  };

  /**
   * Handles the change event for an input number field.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {number} options.value - The new value of the input number field.
   * @param {string} options.path - The path of the field in the form.
   * @param {Object} options.form - The form instance.
   * @returns {void}
   */
  static handleInputNumberChange = ({ value, path, form }) => {
    const existingValues = form.getFieldValue(path) || [];
    const newValues = Array.from({ length: value }).map(
      (_, index) => existingValues[index] || ''
    );

    const newFormValues = {};
    this.setValueAtPath(newFormValues, path, newValues);
    form.setFieldsValue(newFormValues);
  };
}
