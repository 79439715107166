import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../../utils/handleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../../utils/constants/routes/productionRoutes';
import { renderMenuItems } from '../renderMenuItems';

export const ProductionNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  const menuItems = renderMenuItems(
    routes,
    subRoutes,
    t,
    navMenuLogos,
    NavLink,
    pathSearches
  );

  return (
    <Menu
      style={{
        padding: '12px 0'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={menuItems}
    />
  );
};

ProductionNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
