import { useState, useEffect, useCallback } from 'react';
import { Form, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { GetEnums } from '../../../utils/getEnums';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { setDynamicFields } from './utils/setDynamicFields';
import { useConfig } from './utils/createUpdateConfig';
import { useAuthContext } from '../../../contexts/AuthContext';
import { Municipality } from '../../../models/Municipality';

/**
 * Component for creating or updating PCS pre-production.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component.
 * @returns {JSX.Element} The JSX element representing the CreateUpdatePcsPreProduction component.
 */
export const CreateUpdatePcsPreProduction = ({ purpose }) => {
  const resourceName = 'pcs-pre-productions';
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { getEnums } = GetEnums();
  const { syndics } = useGlobalContext();
  const { user } = useAuthContext();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [syndicOptions, setSyndicOptions] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [managers, setManagers] = useState([]);
  const [syndic, setSyndic] = useState(null);
  const [status, setStatus] = useState(null);
  const [municipalities, setMunicipalities] = useState([]);
  const navigate = useNavigate();
  const [isMultipleCollectiveOwnerships, setIsMultipleCollectiveOwnerships] =
    useState(false);
  const { fields } = useFields({
    enums,
    isFieldsLoading,
    syndicOptions,
    setSyndic,
    syndic,
    assistants,
    managers,
    form,
    setIsMultipleCollectiveOwnerships,
    isMultipleCollectiveOwnerships,
    status,
    purpose,
    municipalities,
    resourceName
  });

  const editPermissions = !['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(
    user.role
  );

  useEffect(() => {
    if (!editPermissions) {
      navigate('/missions/pcs-pre-productions');
    }
  }, [editPermissions, user]);

  useEffect(() => {
    setDynamicFields({
      setSyndicOptions,
      syndics,
      syndic,
      setAssistants,
      setManagers
    });
  }, [syndic, syndics]);

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    const [municipalitiesDatas, enumsDatas] = await Promise.all([
      Municipality.getResources({}),
      getEnums({ resource: 'pcs-pre-productions' })
    ]);

    setMunicipalities(municipalitiesDatas);
    setEnums(enumsDatas);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const config = useConfig({
    setAssistants,
    setManagers,
    setIsMultipleCollectiveOwnerships,
    setStatus,
    user
  });

  const customErrorMessage = (e) => {
    const tradKey = 'pcs-pre-productions.messages';
    if (e.response.data.name === 'CustomError') {
      const type = e.response.data.description.type;
      const description = e.response.data.description;
      notification.warning({
        message: t(`${tradKey}.${type}_title`),
        description: t(`${tradKey}.${type}_description`, {
          municipalities: (description.municipalities || []).join(', '),
          missingColumns: (description.missingColumns || [])
            .map(
              (colInfo) =>
                `${t(`${tradKey}.line`)} ${colInfo.row}: ${colInfo.columns.join(
                  ', '
                )}`
            )
            .join('; ')
        })
      });
    } else {
      message(e);
    }
  };

  return (
    <CreateUpdateContainer
      customFormInstance={form}
      fields={fields}
      config={config}
      purpose={purpose}
      populate="syndic,syndic.assistants managers,documents,documents.files"
      baseUrl="pcs-pre-productions"
      resource={resourceName}
      withFilesManager={false}
      customErrorMessage={customErrorMessage}
    />
  );
};

CreateUpdatePcsPreProduction.propTypes = {
  purpose: PropTypes.string.isRequired
};
