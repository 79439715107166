/**
 * Custom hook that returns an array of fields for matrix finalization.
 *
 * @hook
 * @returns {Object} The fields object.
 */
export const useFields = () => {
  const fields = [
    {
      name: ['matrix_finalization'],
      key: 'matrix_finalization',
      maxFilesCount: 100,
      input: 'File',
      requestedDocumentType: 'Matrice publication PCS'
    }
  ];

  return { fields };
};
