import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row } from 'antd';
import { useDraggerProps } from '../../../../../../components/CreateUpdateContainer/FilesManager/utils/draggerProps';
import FilesManagerCustom from '../../../../../../components/CreateUpdateContainer/FilesManager/FilesManagerWithDisplay';

/**
 * SubmitPublicationDocuments component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values for the form.
 * @param {Array} props.draggerFilesList - The list of files for the dragger.
 * @param {Function} props.setDraggerFilesList - The function to set the dragger files list.
 * @param {Object} props.setFilesConfiguration - The function to set the files configuration.
 * @param {Object} props.filesConfiguration - The files configuration object.
 * @param {string} props.purpose - The purpose of the component.
 * @param {Function} props.setButtonsActive - The function to set the active buttons.
 * @returns {JSX.Element} The SubmitPublicationDocuments component.
 */
export const SubmitPublicationDocuments = ({
  form,
  initialValues,
  draggerFilesList,
  setDraggerFilesList,
  setFilesConfiguration,
  filesConfiguration,
  purpose,
  setButtonsActive
}) => {
  const { t } = useTranslation();
  const possibleDocuments = [
    'signed_quotes',
    'complete_and_official_rcp_document',
    'valid_syndic_contract',
    'latest_minutes_appointing_syndic',
    'certificate_of_no_appeal_from_last_ag',
    'condominium_summary_sheet',
    'minutes_approving_conformity_project',
    'certificate_of_no_contestation_from_ag',
    'building_reference_number',
    'syndic_non_bankruptcy_certificate',
    'published_or_pending_amendments_since_audit'
  ];

  useDraggerProps(draggerFilesList, setDraggerFilesList);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useEffect(() => {
    setButtonsActive((prev) => ({
      ...prev,
      next: true,
      back: false,
      saveDraft: true
    }));
  }, []);

  return (
    <Row align="center">
      <Col span={22}>
        <Divider>{t('missions.form.step_submit_other_elements')}</Divider>
      </Col>
      <Col span={24} style={{ backgroundColor: 'red' }}>
        <FilesManagerCustom
          filesList={draggerFilesList}
          setFilesList={setDraggerFilesList}
          filesKeys={possibleDocuments}
          filesConfiguration={filesConfiguration}
          setFilesConfiguration={setFilesConfiguration}
          purpose={purpose}
          notitle
          noBorder
        />
      </Col>
    </Row>
  );
};

SubmitPublicationDocuments.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  draggerFilesList: PropTypes.shape([]).isRequired,
  setDraggerFilesList: PropTypes.func.isRequired,
  setFilesConfiguration: PropTypes.func.isRequired,
  filesConfiguration: PropTypes.shape([]).isRequired,
  purpose: PropTypes.string.isRequired,
  setButtonsActive: PropTypes.func.isRequired
};
