/**
 * Returns the action fields map.
 * @returns {Object} The action fields map.
 */
export const useActionsFields = () => {
  const actionFieldsMap = {
    PUBLICATION_PCS: [
      {
        name: 'modified_lot_edd',
        label: 'quotation-act-amounts.form.modified_lot_edd',
        key: 'modified_lot_edd'
      }
    ],
    LOT_CREATION: [
      {
        name: 'modified_lot_edd',
        label: 'quotation-act-amounts.form.modified_lot_edd',
        key: 'modified_lot_edd'
      }
    ],
    ENJOYMENT_RIGHT_TO_VALIDATED: [
      {
        name: 'modified_lot_edd',
        label: 'quotation-act-amounts.form.modified_lot_edd',
        key: 'modified_lot_edd'
      }
    ],
    CHARGING_GRID: [
      {
        name: 'modified_lot_rc',
        label: 'quotation-act-amounts.form.modified_lot_rc',
        key: 'modified_lot_rc'
      }
    ],
    LOT_ENJOYMENT_RIGHT: [
      {
        name: 'modified_lot_edd',
        label: 'quotation-act-amounts.form.modified_lot_edd',
        key: 'modified_lot_edd'
      }
    ],
    LOT_DIVISION: [
      {
        name: 'modified_lot_edd',
        label: 'quotation-act-amounts.form.modified_lot_edd',
        key: 'modified_lot_edd'
      },
      {
        name: 'procurations',
        label: 'quotation-act-amounts.form.procurations',
        key: 'procurations'
      },
      {
        name: 'lot_sheet',
        label: 'quotation-act-amounts.form.lot_sheet',
        key: 'lot_sheet'
      }
    ],
    LOT_MERGE: [
      {
        name: 'modified_lot_edd',
        label: 'quotation-act-amounts.form.modified_lot_edd',
        key: 'modified_lot_edd'
      },
      {
        name: 'procurations',
        label: 'quotation-act-amounts.form.procurations',
        key: 'procurations'
      },
      {
        name: 'lot_sheet',
        label: 'quotation-act-amounts.form.lot_sheet',
        key: 'lot_sheet'
      }
    ],
    CONSISTENCY_DESTINATION_CHANGE: [
      {
        name: 'modified_lot_edd',
        label: 'quotation-act-amounts.form.modified_lot_edd',
        key: 'modified_lot_edd'
      },
      {
        name: 'lot_sheet',
        label: 'quotation-act-amounts.form.lot_sheet',
        key: 'lot_sheet'
      }
    ],
    LOT_REMOVAL: [
      {
        name: 'modified_lot_edd',
        label: 'quotation-act-amounts.form.modified_lot_edd',
        key: 'modified_lot_edd'
      },
      {
        name: 'procurations',
        label: 'quotation-act-amounts.form.procurations',
        key: 'procurations'
      },
      {
        name: 'lot_sheet',
        label: 'quotation-act-amounts.form.lot_sheet',
        key: 'lot_sheet'
      }
    ],
    SALE: [
      {
        name: 'sales',
        label: 'quotation-act-amounts.form.sales',
        key: 'sales'
      },
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.honorary_ttc',
        key: 'honorary_ttc',
        input: true,
        unit: '€'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      {
        name: 'tva',
        label: 'quotation-act-amounts.form.tva',
        key: 'tva'
      },
      {
        name: 'total_notary_ttc',
        label: 'quotation-act-amounts.form.total_notary_ttc',
        key: 'total_notary_ttc'
      }
    ],
    EXCHANGE: [
      {
        name: 'echanges',
        label: 'quotation-act-amounts.form.exchanges',
        key: 'echanges'
      },
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.honorary_ttc',
        key: 'honorary_ttc',
        input: true
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      {
        name: 'tva',
        label: 'quotation-act-amounts.form.tva',
        key: 'tva'
      },
      {
        name: 'total_notary_ttc',
        label: 'quotation-act-amounts.form.total_notary_ttc',
        key: 'total_notary_ttc'
      }
    ]
  };

  return { actionFieldsMap };
};
