import { useTranslation } from 'react-i18next';
import { Button, Tag, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { templateStatus } from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * A custom hook that generates an array of column objects to be used with a data table component.
 * Each column object contains properties like title, key, dataIndex, width, and render to define how data
 * should be displayed in each column of the table.
 *
 * @function
 * @param {boolean} forceRefresh - A boolean to trigger the refresh of the data table.
 * @param {Function} setForceRefresh - A function to update the forceRefresh state.
 * @returns {Array<Object>} An array of column objects for displaying data in a data table.
 * @param {string} type - The type of template document.
 * @example
 *
 * const [forceRefresh, setForceRefresh] = useState(false);
 * const columns = useColumns(forceRefresh, setForceRefresh);
 */
const { Text } = Typography;
export const useColumns = (forceRefresh, setForceRefresh, type) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const patchDefaultTemplate = async (record) => {
    const use_case = record.use_case;
    try {
      await dispatchAPI('PATCH', {
        url: `template-documents/patch-default/${record._id}/${use_case}`
      });
      setForceRefresh(!forceRefresh);
    } catch (error) {
      message(error);
    }
  };

  return [
    {
      title: t('template-documents.form.status'),
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      sorter: true,
      render: (status) =>
        status ? (
          <Tag color={templateStatus[status]}>
            {t(`template-documents.form.${status}`)}
          </Tag>
        ) : (
          '-'
        )
    },
    ...(type === 'FAQ_TEMPLATES'
      ? []
      : [
          {
            title: (
              <Text className="break-column-title">
                {t('template-documents.form.default_template')}
              </Text>
            ),
            key: 'default_template',
            width: '10%',
            sorter: true,
            render: (record) => (
              <Button onClick={() => patchDefaultTemplate(record)}>
                {record.default_template === true ? (
                  <CheckOutlined />
                ) : (
                  <CloseOutlined />
                )}
              </Button>
            )
          }
        ]),
    {
      title: t('template-documents.form.title'),
      key: 'title',
      dataIndex: 'title',
      width: '30%',
      sorter: (a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase())
    },
    ...(type === 'FAQ_TEMPLATES'
      ? [
          {
            title: t('template-documents.form.order'),
            key: 'order',
            dataIndex: 'order',
            width: '10%',
            sorter: (a, b) => a.order - b.order,
            defaultSortOrder: 'descend'
          }
        ]
      : []),

    {
      title: t('template-documents.form.author'),
      key: 'author',
      dataIndex: 'author',
      width: '30%',
      sorter: (a, b) => {
        const aName = a.author
          ? `${a.author.first_name || ''} ${a.author.last_name || ''}`
              .trim()
              .toLowerCase()
          : '';
        const bName = b.author
          ? `${b.author.first_name || ''} ${b.author.last_name || ''}`
              .trim()
              .toLowerCase()
          : '';
        return aName.localeCompare(bName);
      },
      render: (author) =>
        author
          ? `${author.first_name || ''} ${author.last_name || ''}`.trim()
          : ''
    }
  ];
};
