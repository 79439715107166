import { useEffect, useState } from 'react';
import { ListResource, PageHeaderCustom } from '../../../components';
import { useColumns } from './columns';
import { GetEnums } from '../../../utils/getEnums';
import { ExtraSearchBars } from './ExtraSearchsBar';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { CollectiveOwnership } from '../../../models/CollectiveOwnership';
import { ExtraTitleHeader } from './ExtraTitleHeader';
import { Mission } from '../../../models/Mission';

/**
 * Renders a list of missions.
 * @component
 * @returns {JSX.Element} The list of missions component.
 */
export const ListMissions = () => {
  const { user } = useAuthContext();
  const { syndics } = useGlobalContext();
  const { getEnums } = GetEnums();
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);
  const [selectedSyndic, setSelectedSyndic] = useState('');
  const [isFiedlLoading, setIsFieldLoading] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [missionsStatus, setMissionsStatus] = useState({
    IN_PROGRESS: 0,
    BLOCKED: 0,
    PENDING: 0
  });
  const [filters, setFilters] = useState({});
  const [extraQuery, setExtraQuery] = useState('');
  const [enums, setEnums] = useState({});
  const columns = useColumns({
    setForceRefresh,
    forceRefresh,
    enums
  });

  useEffect(() => {
    (async () => {
      const enumsDatas = await getEnums({ resource: 'missions' });
      const collectiveOwnershipsData = await CollectiveOwnership.getResources({
        populate: '',
        extraQuery: 'status=ACTIVE'
      });
      const missionsData = await Mission.getLawyerMissions({
        extraQuery: `lawyer=${user._id}`
      });
      const missionsStatusData = missionsData.reduce(
        (acc, mission) => {
          acc[mission.status_for_lawyer] += 1;
          return acc;
        },
        { IN_PROGRESS: 0, BLOCKED: 0, PENDING: 0 }
      );
      setMissionsStatus(missionsStatusData);
      setCollectiveOwnerships(collectiveOwnershipsData);
      setEnums(enumsDatas);
      setIsFieldLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedSyndic) {
        const collectiveOwnershipsData = await CollectiveOwnership.getResources(
          {
            populate: '',
            extraQuery: `syndic._id=${selectedSyndic}`
          }
        );
        setCollectiveOwnerships(collectiveOwnershipsData);
      }
    })();
  }, [selectedSyndic]);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <>
      <PageHeaderCustom
        title={<ExtraTitleHeader missionsStatus={missionsStatus} />}
      />
      <ListResource
        resourceName="missions/lawyer-missions"
        scroll={{ x: 'max-content' }}
        populate="syndic,manager,collective_ownership,lawyer"
        extraQuery={`lawyer=${user._id}&${extraQuery}`}
        columns={columns}
        resourceModelName="Mission"
        customActionColumn
        withCreateButton={false}
        withPageHeader={false}
        searchBarExtraButtons={
          <ExtraSearchBars
            setFilters={setFilters}
            collectiveOwnerships={collectiveOwnerships}
            syndics={syndics}
            setSelectedSyndic={setSelectedSyndic}
            isDataLoading={isFiedlLoading}
            enums={enums}
            setForceRefresh={setForceRefresh}
          />
        }
      />
    </>
  );
};
