import { useTranslation } from 'react-i18next';
import { Tag, Popconfirm, Row, Col, message as antdMessage } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { deleteResource } from '../../../utils/deleteResource';
import { IconsArray } from '../../../components/editableTableComponents/IconsArray';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Returns an array of columns configuration for the various documents table.
 * @hook
 * @param {Object} options - The options object.
 * @param {function} options.setEditingKey - The function to set the editing key.
 * @param {Object} options.form - The form object.
 * @param {function} options.setSelectType - The function to set the select type.
 * @param {function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The force refresh flag.
 * @param {Object} options.selectOptions - The options for the select input.
 * @returns {Array} - An array of column configuration objects.
 */
export const useColumns = ({
  setEditingKey,
  form,
  setSelectType,
  setForceRefresh,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { editCell } = EditableTableMethods();

  return [
    {
      title: t('various-documents.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'wording', setEditingKey, form })
          }
        >
          {record?.wording || '-'}
        </Row>
      ),
      sorter: (a, b) => (a.wording ? a.wording.localeCompare(b.wording) : true)
    },
    {
      title: t('various-documents.form.document_type'),
      key: 'document_type',
      dataIndex: 'document_type',
      editable: true,
      inputType: 'select',
      render: (_, record) => (
        <Row
          onClick={() => {
            setSelectType('document_types');
            editCell({
              record,
              key: 'document_type',
              setEditingKey,
              form
            });
          }}
        >
          {record?.document_type ? (
            <Tag
              color={record?.document_type?.color}
              key={record?.document_type?._id}
            >
              <Row gutter={[6]}>
                <Col>
                  {record?.document_type?.icon
                    ? IconsArray.find(
                        (icon) => icon.name === record?.document_type?.icon
                      )?.icon
                    : ''}
                </Col>
                <Col>{record?.document_type?.wording || ''}</Col>
              </Row>
            </Tag>
          ) : (
            '-'
          )}
        </Row>
      ),
      sorter: (a, b) =>
        a?.document_type?.wording
          ? a?.document_type?.wording.localeCompare(b?.document_type?.wording)
          : true
    },
    {
      key: 'action',
      fixed: 'right',
      width: 60,
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => {
            deleteResource({
              dispatchAPI,
              resourceName: 'various-documents',
              id: record._id,
              setForceRefresh,
              forceRefresh,
              message
            });
            antdMessage.success({
              content: t('datatable.message.success.deleteline'),
              icon: <DeleteOutlined style={{ color: 'red' }} />
            });
            return true;
          }}
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 16 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];
};
