import { Mission } from '../models/Mission';

/**
 * Updates the show mission drawer state and patches the mission resource.
 * @function
 * @param {Object} options - The options object.
 * @param {Object} options.missionDatas - The mission data object.
 * @param {Function} options.setIsDrawerOpen - The function to set the show mission drawer state.
 * @returns {void}
 */
export const updateShowMissionDrawer = ({ missionDatas, setIsDrawerOpen }) => {
  if (missionDatas?.open_drawer_automatically?.status) {
    setIsDrawerOpen((prev) => ({
      ...prev,
      [missionDatas.open_drawer_automatically.drawer]: true
    }));

    Mission.patchResource({
      id: missionDatas?._id,
      values: {
        open_drawer_automatically: { status: false, drawer: '' }
      }
    });
  }
};
