import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useColumns } from './columns';

/**
 * Renders an expanded cheque list component.
 *
 * @component
 * @param {Object[]} datasource - The data source for the table.
 * @returns {JSX.Element} The expanded cheque list component.
 */
export const ExpandedChequeList = ({ datasource = [] }) => {
  const columns = useColumns();

  return (
    <Table
      columns={columns}
      dataSource={datasource}
      pagination={false}
      scroll={{ x: 'max-content' }}
    />
  );
};

ExpandedChequeList.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape({}))
};

ExpandedChequeList.defaultProps = {
  datasource: []
};
