import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  homeSyndicViewTitle,
  rpcResearchTitle
} from '../../utils/styleConstants';

/**
 * @hook
 * Returns an object containing the title, subtitle, and card design for the RCP research component.
 * @returns {Object} An object containing the title, subtitle, and card design.
 */
export const rcpTitles = () => {
  const { t } = useTranslation();
  const title = (
    <Row>
      <h1 style={homeSyndicViewTitle}>
        {t('syndic-rcp-research.titlteCard.title')}
      </h1>
    </Row>
  );

  const subtitle = (
    <>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.titlteCard.subtitle_1')}
      </p>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.titlteCard.subtitle_2')}
      </p>
    </>
  );

  const title2 = (
    <Row>
      <h1 style={homeSyndicViewTitle}>
        {t('syndic-rcp-research.card1.title')}
      </h1>
    </Row>
  );

  const subtitle2 = (
    <>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card1.subtitle_1')}
      </p>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card1.subtitle_item_1')}
      </p>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card1.subtitle_item_2')}
      </p>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card1.subtitle_item_3')}
      </p>
    </>
  );

  const title3 = (
    <Row>
      <h1 style={homeSyndicViewTitle}>
        {t('syndic-rcp-research.card2.title')}
      </h1>
    </Row>
  );

  const subtitle3 = (
    <>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card2.subtitle_1')}
      </p>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card2.subtitle_item_1')}
      </p>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card2.subtitle_item_2')}
      </p>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card2.subtitle_item_3')}
      </p>
      <p style={rpcResearchTitle}>
        {t('syndic-rcp-research.card2.subtitle_item_4')}
      </p>
    </>
  );

  const cardDesign = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '97.5%',
    borderRadius: '15px',
    padding: '1rem 1rem',
    backgroundColor: '#ffffff'
  };
  return {
    title,
    subtitle,
    title2,
    subtitle2,
    title3,
    subtitle3,
    cardDesign
  };
};
