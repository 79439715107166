import { Skeleton, Form, Input, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { DescriptionList } from '../../../../../components';
import { PcsPreProduction } from '../../../../../models/PcsPreProduction';
import { useInformationsProvidedBySyndicListContent } from '../listContent/informationsProvidedBySyndic';

const { TextArea } = Input;

/**
 * Returns an array of items for displaying information provided by syndic.
 *
 * @hook
 * @param {Object} props - The component props.
 * @param {Object} props.pcsPreProduction - The PCS pre-production object.
 * @param {boolean} props.isLoading - Indicates whether the data is loading.
 * @param {Function} props.setPcsPreProduction - Function to set pcsPreProduction
 * @returns {Array} - The array of items.
 */
export const informationProvidedBySyndicItems = ({
  pcsPreProduction,
  isLoading,
  setPcsPreProduction
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { informations } =
    useInformationsProvidedBySyndicListContent(pcsPreProduction);

  useEffect(() => {
    form.setFieldsValue({
      personalized_message: pcsPreProduction?.personalized_message || null
    });
  }, [pcsPreProduction]);

  const handleFinish = async (values) => {
    await PcsPreProduction.patchResource({
      id,
      values,
      setIsSubmitting,
      messageContent: t(
        'pcs-pre-productions.show.messages.personalized_message'
      ),
      populate:
        'syndic,manager,assistant,collective_ownership,syndic.consultant contact,documents,documents.files,charging_grid,charging_grid.columns.column,history.by'
    });
    setPcsPreProduction((prev) => ({
      ...prev,
      personalized_message: values.personalized_message
    }));
  };

  const items = [
    {
      label: t('pcs-pre-productions.tabs.list'),
      key: '1',
      children: (
        <Skeleton loading={isLoading} active>
          <DescriptionList data={informations} translate vertical />
        </Skeleton>
      )
    },
    {
      label: t('pcs-pre-productions.tabs.personalized_message'),
      key: '2',
      children: (
        <Skeleton loading={isLoading} active>
          <Form form={form} onFinish={handleFinish}>
            <Form.Item name={['personalized_message']}>
              <TextArea />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {t('buttons.register')}
              </Button>
            </Form.Item>
          </Form>
        </Skeleton>
      )
    }
  ];
  return items;
};
