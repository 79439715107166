export const useConfig = ({ setRole, setSyndic }) => ({
  onGetResource: {
    setFields: (data) => {
      setRole(data.role);
      setSyndic(data.syndic);
      const newData = { ...data, syndic: data?.syndic?._id || null };
      return newData;
    }
  }
});
