import { message as antdMessage } from 'antd';
import { getChargingGrid } from './getChargingGrid';
import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Handles the addition of rows to the charging grid.
 * @function
 * @param {Object} params - Function parameters
 * @param {number} params.count - The count of rows
 * @param {Array} params.dataSource - The data source containing existing rows
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {Function} params.message - Function for displaying messages
 * @param {Function} params.t - Function for translating strings
 * @param {Object} params.resourceDatas - The resource object
 * @param {Function} params.setResourceDatas - Function for setting resource data
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Object} params.body - The request body containing row details
 * @param {Function} params.setIsVersionButton - Function for setting version button state
 * @param {Function} params.setIsLotModalOpen - Function for setting lot modal state
 * @param {Function} params.setIsFinalVersion - Function for setting final version state
 * @returns {null}
 */

export const handleAddRows = async ({
  count,
  dataSource,
  dispatchAPI,
  chargingGrid,
  message,
  t,
  resourceDatas,
  setChargingGrid,
  body,
  setIsVersionButton,
  setIsLotModalOpen,
  setIsFinalVersion,
  setResourceDatas
}) => {
  const values = [];
  for (let i = body.from; i < body.row_number + body.from; i += 1) {
    const lot = dataSource.filter((data) => data.Lot.value === i);
    const lotBis = dataSource.filter((data) => data.Lot.value === `${i} bis`);

    if (lot.length && !lot[0].delete_row) {
      if (!body.is_duplicate_lot) {
        return antdMessage.warning(
          t('charging-grids.show.messages.lot-exists', { lot: i }),
          5
        );
      }
    }
    if (lotBis.length && body.is_duplicate_lot) {
      return antdMessage.warning(
        t('charging-grids.show.messages.lot-exists', { lot: `${i} bis` }),
        5
      );
    }
  }
  for (let i = 0; i < body.row_number; i += 1) {
    let newRow = {};
    if (chargingGrid.version === 1) {
      newRow = { key: count + 1 + i, Lot: { value: body.from + i } };
    } else {
      newRow = {
        key: count + 1 + i,
        Lot: {
          value: body.is_duplicate_lot ? `${body.from + i} bis` : body.from + i
        },
        new_row: true
      };
    }
    values.push(newRow);
  }

  const formData = new FormData();
  try {
    formData.append('values', JSON.stringify(values));
    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${chargingGrid._id}?type=add_row`,
      body: formData
    });

    updateChargingGrids({
      resourceDatas,
      chargingGrid: data,
      setResourceDatas
    });
    setIsLotModalOpen(false);
    getChargingGrid({
      chargingGrid: data,
      resourceDatas,
      setChargingGrid,
      setIsVersionButton
    });
  } catch (e) {
    message(e);
  }
  setIsFinalVersion(false);
  return null;
};
