import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/documentsToBeReWrittenFields';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Renders a drawer component for documents to be rewritten.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const DocumentToBeReWrittenDrawer = () => {
  const {
    forceRefresh,
    setForceRefresh,
    setIsDrawerOpen,
    isDrawerOpen,
    isLoading
  } = useMissionContext();

  const { fields } = useFields();
  const { t } = useTranslation();

  return (
    <Drawer
      title={t('missions.drawers.titles.documents_to_be_re_written')}
      onClose={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          documents_to_be_re_written: false
        }))
      }
      open={isDrawerOpen.documents_to_be_re_written}
    >
      <CreateUpdateContainer
        fields={fields}
        isFieldsLoading={isLoading}
        purpose="edit"
        baseUrl="missions/add-documents-to-be-re-written"
        resource="missions"
        withFilesManager={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{
          ...isDrawerOpen,
          documents_to_be_re_written: false
        }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
