import { Order } from '../models/Order';

/**
 * handleStockCerfaActions updates the stock Cerfa status for a list of orders.
 *
 * @param {string} value - The new status value for the stock Cerfa.
 * @param {Array} orders - The list of orders to update.
 * @returns {void}
 */
export const handleStockCerfaActions = (value, orders) => {
  orders.forEach(async (stockCerfa) => {
    await Order.patchResource({
      id: stockCerfa._id,
      values: { stock_cerfa_status: value }
    });
  });
};
