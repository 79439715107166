/**
 * Handles the form submission for permission updates.
 *
 * @async
 * @function
 *
 * @param {Object} options - The options for form submission.
 * @param {Object} options.values - The form values.
 * @param {Object} options.permissions - The current permissions.
 * @param {Object} options.professionals - The available professionals.
 * @param {Function} options.setPermissions - The function to update permissions.
 * @param {Function} options.setOpenModal - The function to control modal visibility.
 * @param {boolean} options.updateDataBase - Flag indicating whether to update the database.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {Function} options.message - The function to display error messages.
 * @param {string} options.id - The ID of the collective ownership.
 * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
 */
export const onPermissionFormSubmit = async ({
  values,
  permissions,
  professionals,
  setPermissions,
  setOpenModal,
  updateDataBase,
  dispatchAPI,
  message,
  id
}) => {
  const newPermissions = { ...permissions };

  const fullPermissions = {
    lawyers: values.lawyers
      ? values.lawyers.map((lawyerId) =>
          professionals.lawyers.find((item) => item._id === lawyerId)
        )
      : [],
    notaries: values.notaries
      ? values.notaries.map((notaryId) =>
          professionals.notaries.find((item) => item._id === notaryId)
        )
      : [],
    surveyors: values.surveyors
      ? values.surveyors.map((surveyorId) =>
          professionals.surveyors.find((item) => item._id === surveyorId)
        )
      : [],
    others: values.others
      ? values.others.map((otherId) =>
          professionals.others.find((item) => item._id === otherId)
        )
      : []
  };

  Object.keys(fullPermissions).forEach((key) => {
    if (Array.isArray(fullPermissions[key])) {
      newPermissions[key].push(...fullPermissions[key]);
    }
  });

  if (updateDataBase) {
    const formData = new FormData();

    formData.append('values', JSON.stringify({ permissions: newPermissions }));

    try {
      await dispatchAPI('PATCH', {
        url: `/collective-ownerships/${id}`,
        body: formData
      });
      setPermissions(newPermissions);
      setOpenModal(false);
    } catch (e) {
      message(e);
    }
  } else {
    setPermissions(newPermissions);
    setOpenModal(false);
  }
};
