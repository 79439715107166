import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { handleStepDisabled } from '../utils/handleStepDisabled';
import { Mission } from '../../../../../models/Mission';

/**
 * Returns an array of principal items for the PCS steps.
 * @hook
 * @returns {Object[]} principalItems - The array of principal items.
 * @property {string} key - The key of the item.
 * @property {string} title - The title of the item.
 * @property {boolean} disabled - Indicates if the item is disabled.
 */
export const pcsSteps = () => {
  const { t } = useTranslation();
  const { stepsObject } = Mission;
  const { PCS_1, PCS_2, PCS_3 } = stepsObject;
  const { mission } = useMissionContext();

  const principalSteps = [
    {
      title: t('missions.steps.PCS_1'),
      icon: <CheckCircleOutlined />,
      key: 'PCS_1',
      disabled: true
    },
    {
      title: t('missions.steps.PCS_2'),
      icon: <CheckCircleOutlined />,
      key: 'PCS_2',
      disabled: true
    },
    {
      title: t('missions.steps.PCS_3'),
      icon: <CheckCircleOutlined />,
      key: 'PCS_3',
      disabled: true
    },
    {
      title: t('missions.steps.FINISHED'),
      icon: <CheckCircleOutlined />,
      key: 'FINISHED',
      disabled: true
    }
  ];

  const principalItems = principalSteps.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled
  }));

  const pcs1SubSteps = PCS_1.sub.map((step) => ({
    title: t(`missions.steps.${step}`),
    icon: <CheckCircleOutlined />,
    key: step,
    disabled: handleStepDisabled({ mission, step_type: 'sub' })[step]
  }));

  const pcs1SubItems = pcs1SubSteps.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled
  }));

  const pcs2SubSteps = PCS_2.sub.map((step) => ({
    title: t(`missions.steps.${step}`),
    icon: <CheckCircleOutlined />,
    key: step,
    disabled: true
  }));

  const pcs2SubItems = pcs2SubSteps.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled
  }));

  const pcs3SubSteps = PCS_3.sub.map((step) => ({
    title: t(`missions.steps.${step}`),
    icon: <CheckCircleOutlined />,
    key: step,
    disabled:
      step === 'MATRIX_FINALIZATION'
        ? handleStepDisabled({ mission, step_type: 'sub' }).MATRIX_FINALIZATION
        : true
  }));

  const pcs3SubItems = pcs3SubSteps.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled
  }));

  return { principalItems, pcs1SubItems, pcs2SubItems, pcs3SubItems };
};
