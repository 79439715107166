import { beforeUpload } from './beforeUpload';
import { itemRender } from './itemRender';
import { onPreview } from './onPreview';

/**
 * Generates the properties for a file uploader component.
 *
 * @function
 * @param {Array} fieldsFilesList - The list of files currently uploaded.
 * @param {function} setFieldsFileList - A setter function for the file list.
 * @param {string} purpose - The purpose of the createUpdateContainer.
 * @param {Array} filesConfiguration - The configuration of the files.
 * @param {function} deleteFile - A function for deleting a file.
 * @param {function} downloadFile - A function for downloading a file.
 * @param {function} showFile - A function for displaying a file.
 * @param {Array} name - An array representing the name of the field associated with the file.
 * @param {function} setFilesConfiguration - A setter function for the files configuration.
 * @param {function} message - A function for displaying messages.
 * @param {boolean} multipleFiles - A flag indicating whether multiple files can be uploaded.
 * @param {number} maxFilesCount - The maximum count of files that can be uploaded.
 * @param {function} t - A function for translating message strings.
 * @param {function} setFilesToUpload - A function to set filesToUpload.
 * @param {Array} requiredFileType - An array of file extensions required.
 * @param {string} documentRepositoryType - The type of the document repository.
 * @param {string} cerfaDocumentType - The type of the cerfa document.
 * @param {string} variousDocumentType - The type of the various document.
 * @param {string} requestedDocumentType - The type of the requested document.
 * @param {string} reWrittenDocumentType - The type of the rewritten document.
 * @param {string} title - The title of the file uploader component
 * @returns {Object} An object containing the properties for a file uploader component.
 */
export const useDraggerProps = (
  fieldsFilesList,
  setFieldsFileList,
  purpose,
  filesConfiguration,
  deleteFile,
  downloadFile,
  showFile,
  name,
  setFilesConfiguration,
  message,
  multipleFiles,
  maxFilesCount,
  t,
  setFilesToUpload,
  requiredFileType,
  documentRepositoryType,
  cerfaDocumentType,
  variousDocumentType,
  requestedDocumentType,
  reWrittenDocumentType,
  title
) => ({
  multiple: multipleFiles,
  maxCount: maxFilesCount,
  listType: 'picture-card',
  onPreview: (file) => onPreview(file, showFile),
  showUploadList: true,
  beforeUpload: async (file) => {
    await beforeUpload(
      file,
      fieldsFilesList,
      filesConfiguration,
      setFieldsFileList,
      setFilesConfiguration,
      maxFilesCount,
      deleteFile,
      message,
      t,
      name,
      setFilesToUpload,
      requiredFileType,
      documentRepositoryType,
      cerfaDocumentType,
      variousDocumentType,
      requestedDocumentType,
      reWrittenDocumentType,
      title
    );
  },
  itemRender: (_, file) =>
    itemRender(
      file,
      fieldsFilesList[name],
      downloadFile,
      name,
      filesConfiguration,
      setFilesConfiguration,
      setFieldsFileList,
      purpose,
      deleteFile,
      message,
      setFilesToUpload,
      t
    ),
  fileList: fieldsFilesList[name]
});
