import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';

/**
 * Custom content component for SyndicView.
 *
 * @component
 * @param {string} title - The title of the content.
 * @param {string} subtitle - The subtitle of the content.
 * @param {ReactNode} children - The content to be rendered inside the component.
 * @param {object} cardStyle - Custom styles for the Card component.
 * @param {object} containerStyle - Custom styles for the container div.
 * @param {string} imageOnRight - The URL of the image to be displayed on the right side.
 * @returns {JSX.Element} The rendered SyndicViewContentCustom component.
 */

const SyndicViewContentCustom = ({
  title,
  subtitle,
  children,
  cardStyle,
  containerStyle,
  imageOnRight,
  imageOnLeft,
  childrenStyle
}) => (
  <Content
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      minHeight: 'fit-content',
      ...containerStyle
    }}
  >
    <Card
      style={{
        width: '97.5%',
        backgroundColor: 'black',
        flexDirection: 'column',
        borderRadius: '15px',
        color: 'white',
        padding: '0rem 1rem',
        ...cardStyle
      }}
    >
      <Row gutter={16} align="middle">
        {imageOnLeft && (
          <Col xs={24} sm={12} md={12} lg={3}>
            <img
              style={{
                height: '15em',
                marginTop: '-60px',
                marginLeft: '-60px'
              }}
              src={imageOnLeft}
              alt="logo"
            />
          </Col>
        )}
        <Col xs={24} sm={12} md={12} lg={19}>
          <Row>{title}</Row>
          {subtitle}
        </Col>
        {imageOnRight && (
          <Col span={4}>
            <img style={{ height: '12em' }} src={imageOnRight} alt="logo" />
          </Col>
        )}
      </Row>
    </Card>
    <Content
      style={{
        width: '97.5%',
        backgroundColor: '#ffff',
        borderRadius: '15px',
        padding: '2rem',
        ...childrenStyle
      }}
    >
      {children}
    </Content>
  </Content>
);

export default SyndicViewContentCustom;

SyndicViewContentCustom.propTypes = {
  title: PropTypes.element,
  subtitle: PropTypes.element,
  children: PropTypes.node,
  cardStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
  imageOnRight: PropTypes.string,
  imageOnLeft: PropTypes.string,
  childrenStyle: PropTypes.shape({})
};

SyndicViewContentCustom.defaultProps = {
  title: null,
  subtitle: null,
  children: null,
  cardStyle: {},
  containerStyle: {},
  imageOnRight: null,
  imageOnLeft: null,
  childrenStyle: {}
};
