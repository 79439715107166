import { Card, Popover, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useManagerInformationsListContent } from './listContent/managerInformations';
import { useAssistantInformations } from './listContent/assistantInformations';

/**
 * Renders the ManagerInformations component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.pcsPreProduction - The PCS pre-production data.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @returns {JSX.Element} The rendered ManagerInformations component.
 */
export const ManagerInformations = ({ pcsPreProduction, isLoading }) => {
  const { t } = useTranslation();
  const informationsListContent =
    useManagerInformationsListContent(pcsPreProduction);
  const assistantInformations = useAssistantInformations(pcsPreProduction);

  const assistantDescriptionListContent = (
    <DescriptionList data={assistantInformations} vertical />
  );

  return (
    <Popover
      content={assistantDescriptionListContent}
      title={t('pcs-pre-productions.show.cards.titles.assistant_informations')}
    >
      <Card
        title={t('pcs-pre-productions.show.cards.titles.manager_informations')}
      >
        <Skeleton loading={isLoading} active>
          <DescriptionList data={informationsListContent} translate vertical />
        </Skeleton>
      </Card>
    </Popover>
  );
};

ManagerInformations.propTypes = {
  pcsPreProduction: PropTypes.shape({}),
  isLoading: PropTypes.bool
};

ManagerInformations.defaultProps = {
  pcsPreProduction: {},
  isLoading: false
};
