import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Typography } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { DeliveryParcel } from '@carbon/icons-react';
import { Mission } from '../../../../../models/Mission';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { setStepAndMissionId } from '../utils/setStepAndMissionId';
import { useAuthContext } from '../../../../../contexts/AuthContext';

const { Text } = Typography;

/**
 * Renders the document buttons component.
 * @component
 * @returns {JSX.Element} The document buttons component.
 */
export const DocumentButtons = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const {
    forceRefresh,
    setAddDocumentCardDatas,
    setForceRefresh,
    setIsDrawerOpen,
    isShowToBeOrderedCard,
    mission
  } = useMissionContext();

  return (
    <Row gutter={[16, 16]}>
      {mission.type === 'PCS_2' &&
        ['FINAL_PROJECT_SUBMISSION'].includes(mission?.steps?.sub) &&
        [
          'admins:SUPER-ADMIN',
          'admins:ADMIN',
          'users:NOMADE_PRODUCTION'
        ].includes(user.role) && (
          <Col>
            <Button
              onClick={() =>
                setIsDrawerOpen((prev) => ({
                  ...prev,
                  definitive_project: true
                }))
              }
            >
              <FileAddOutlined />
              <Text>
                {t('missions.cards.documents.buttons.definitive_project', {
                  type: t(
                    `missions.cards.documents.buttons.${mission.phase_two_type}`
                  )
                })}
              </Text>
            </Button>
          </Col>
        )}
      {mission.type === 'PCS_2' &&
        ['INTERMEDIATE_PROJECT_SUBMISSION', 'SYNDIC_VALIDATION'].includes(
          mission?.steps?.sub
        ) &&
        [
          'admins:SUPER-ADMIN',
          'admins:ADMIN',
          'users:NOMADE_PRODUCTION'
        ].includes(user.role) && (
          <Col>
            <Button
              onClick={() =>
                setIsDrawerOpen((prev) => ({
                  ...prev,
                  intermediate_project: true
                }))
              }
            >
              <FileAddOutlined />
              <Text>
                {t('missions.cards.documents.buttons.intermediate_project')}
              </Text>
            </Button>
          </Col>
        )}
      {isShowToBeOrderedCard && (
        <Col>
          {mission.type !== 'PLANS_RESEARCH' && (
            <Button
              onClick={() =>
                setIsDrawerOpen((prev) =>
                  setStepAndMissionId({ prev, mission })
                )
              }
            >
              <DeliveryParcel />
              <Text>{t('missions.cards.documents.buttons.cerfa_order')}</Text>
            </Button>
          )}
        </Col>
      )}
      {[
        'users:LAWYER',
        'users:NOMADE_PRODUCTION',
        'admins:ADMIN',
        'admins:SUPER-ADMIN'
      ].includes(user.role) && (
        <Col>
          <Button
            type="link"
            onClick={() => {
              setIsDrawerOpen((prev) => ({ ...prev, finalization: false }));
              setAddDocumentCardDatas({ open: true, type: 'Cerfa-document' });
            }}
          >
            <FileAddOutlined />
            <Text>
              {t('missions.cards.documents.buttons.add_cerfa_document')}
            </Text>
          </Button>
        </Col>
      )}
      <Col>
        <Button
          type="link"
          onClick={() => {
            setIsDrawerOpen((prev) => ({ ...prev, finalization: false }));
            setAddDocumentCardDatas({ open: true, type: 'Various-document' });
          }}
        >
          <FileAddOutlined />
          <Text>
            {t('missions.cards.documents.buttons.add_various_document')}
          </Text>
        </Button>
      </Col>
      <Col>
        <Button
          type="link"
          onClick={() =>
            Mission.addBuildingForm({
              setForceRefresh,
              forceRefresh,
              mission
            })
          }
        >
          <FileAddOutlined />
          <Text>{t('missions.cards.documents.buttons.add_building_form')}</Text>
        </Button>
      </Col>
    </Row>
  );
};
