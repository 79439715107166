import { useTranslation } from 'react-i18next';
import { Col, Image, Row } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { VerifyCodeComponent } from '../../../utils/VeritifcationCode/VerifyCodeComponent';
import devis_adaptation from '../../../../../../assets/images/devis_adaptation.png';
import devis_compliance from '../../../../../../assets/images/devis_compliance.png';
import devis_creation_rc from '../../../../../../assets/images/devis_creation_rc.png';
import surveyor_quotation_image from '../../../../../../assets/images/surveyor_quotation_image.png';

const imageHeight = 400;
const imageWidth = 300;

/**
 * SignDevis component is responsible for rendering the sign devis form and handling code validation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial form values.
 * @param {Function} props.setInitialValues - The function to set the initial form values.
 * @param {Function} props.setButtonsActive - The function to set the active buttons.
 * @returns {JSX.Element} The rendered SignDevis component.
 */
export const SignDevis = ({
  form,
  initialValues,
  setInitialValues,
  setButtonsActive
}) => {
  const [areCodesValidated, setAreCodesValidated] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const setValidCodes = () => {
    const allValidated = ['type_pcs_2', 'SURVEYOR_QUOTATION'].every((key) => {
      if (initialValues[key]) {
        return areCodesValidated[key];
      }
      return true;
    });

    if (allValidated) {
      setButtonsActive((prev) => ({
        ...prev,
        next: true,
        back: true
      }));
    } else {
      setButtonsActive((prev) => ({ ...prev, next: false, back: true }));
    }
  };

  useEffect(() => {
    setValidCodes();
  }, [areCodesValidated, initialValues]);

  const handleValidationChange = (field, isValid) => {
    setAreCodesValidated((prevState) => ({
      ...prevState,
      [field]: isValid
    }));
  };

  const generateNewPdfProps = {
    parameters: [
      {
        collection: 'Mission',
        documentId: initialValues.mission
      },
      {
        collection: 'Price-system',
        mission_type: initialValues.type_pcs_2
      }
    ],
    use_case: `QUOTE_${initialValues.type_pcs_2}`,
    extraQuery: 'needs_price_system=true',
    downloadName: t(`missions.tags.${initialValues.type_pcs_2}`)
  };

  const surveyorParameters = {
    parameters: [
      {
        collection: 'Mission',
        documentId: initialValues.mission
      },
      {
        collection: 'Price-system',
        mission_type: 'SURVEYOR_QUOTATION'
      }
    ],
    use_case: `SURVEYOR_QUOTATION`,
    extraQuery: 'needs_price_system=true',
    downloadName: t(`missions.tags.SURVEYOR_QUOTATION`)
  };

  const translateImages = {
    ADAPTATION: devis_adaptation,
    COMPLIANCE: devis_compliance,
    COLLECTIVE_OWNERSHIP_REGULATION_CREATION: devis_creation_rc
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={14}>
        <Row gutter={[16, 16]}>
          {initialValues?.type_pcs_2 && (
            <Col span={12}>
              <Image
                src={translateImages[initialValues?.type_pcs_2]}
                height={imageHeight}
                width={imageWidth}
              />
            </Col>
          )}
          {initialValues?.SURVEYOR_QUOTATION && (
            <Col span={12}>
              <Image
                src={surveyor_quotation_image}
                height={imageHeight}
                width={imageWidth}
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col span={10}>
        {initialValues?.type_pcs_2 && (
          <VerifyCodeComponent
            setInitialValues={setInitialValues}
            documentToSee="devis"
            initialValues={initialValues}
            codeToValidate={initialValues?.type_pcs_2}
            generateNewPdfProps={generateNewPdfProps}
            dividerTitle={t(
              `missions.form.${initialValues?.type_pcs_2.toLowerCase()}`
            )}
            setIsValidated={(isValid) =>
              handleValidationChange('type_pcs_2', isValid)
            }
          />
        )}
        {initialValues?.SURVEYOR_QUOTATION && (
          <VerifyCodeComponent
            setInitialValues={setInitialValues}
            initialValues={initialValues}
            codeToValidate="SURVEYOR_QUOTATION"
            documentToSee="devis"
            dividerTitle={t('missions.form.SURVEYOR_QUOTATION')}
            setIsValidated={(isValid) =>
              handleValidationChange('SURVEYOR_QUOTATION', isValid)
            }
            generateNewPdfProps={surveyorParameters}
          />
        )}
      </Col>
    </Row>
  );
};

SignDevis.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.shape({
    mission: PropTypes.string,
    SURVEYOR_QUOTATION: PropTypes.string,
    type_pcs_2: PropTypes.string
  }).isRequired,
  setInitialValues: PropTypes.func.isRequired,
  setButtonsActive: PropTypes.func.isRequired
};
