import dayjs from 'dayjs';

/**
 * Returns a configuration object for creating, updating, and getting a resource.
 * @returns {Object} The configuration object.
 */

export const setDataResources = (data) => {
  const newData = {
    ...data,
    cerfa_3231_date: data?.cerfa_3231_date?.length && {
      start_date: dayjs(data.cerfa_3231_date[0]),
      end_date: dayjs(data.cerfa_3231_date[1])
    },
    cerfa_3236_date: data?.cerfa_3236_date?.length && {
      start_date: dayjs(data.cerfa_3236_date[0]),
      end_date: dayjs(data.cerfa_3236_date[1])
    }
  };

  return newData;
};

export const useConfig = () => ({
  onCreateResource: {
    setBody: (data) => setDataResources(data)
  },
  onUpdateResource: {
    setBody: (data) => setDataResources(data)
  },
  onGetResource: {
    setFields: (data) => {
      const newData = {
        ...data,
        cerfa_3231_date: data?.cerfa_3231_date?.start_date && [
          dayjs(data?.cerfa_3231_date?.start_date),
          dayjs(data?.cerfa_3231_date?.end_date)
        ],
        cerfa_3236_date: data?.cerfa_3236_date?.start_date && [
          dayjs(data?.cerfa_3236_date?.start_date),
          dayjs(data?.cerfa_3236_date?.end_date)
        ]
      };
      return newData;
    }
  }
});
