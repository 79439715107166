import { Card, Row, Col, Input, Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuotationContext } from '../../../contexts/QuotationContext';
import { RenderActAmountDataCard } from '../quotation-act-amount-inputs/RenderActAmountDataCard';

const { Title } = Typography;

/**
 * Renders the AmountForm component.
 *
 * @component
 * @returns {JSX.Element} The rendered AmountForm component.
 */
export const AmountForm = () => {
  const { t } = useTranslation();
  const { quotationActAmounts } = useQuotationContext();

  return (
    <Row gutter={[16, 16]}>
      {(quotationActAmounts || []).map((actAmount) => (
        <Col span={6} key={actAmount._id}>
          <Card>
            <Row gutter={[16, 16]} justify="center">
              <Title level={4}>
                {t('quotations.cards.titles.act_number', {
                  act_number: actAmount?.number
                })}
              </Title>
            </Row>
            <Form.Item
              label={t('quotation-act-amounts.form.title')}
              name={['quotation_act_amounts', actAmount._id, 'title']}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('quotation-act-amounts.form.sub_title')}
              name={['quotation_act_amounts', actAmount._id, 'sub_title']}
            >
              <Input.TextArea />
            </Form.Item>

            <RenderActAmountDataCard actAmount={actAmount} />
          </Card>
        </Col>
      ))}
    </Row>
  );
};
