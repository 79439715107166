import {
  Form,
  Input,
  Typography,
  Row,
  Col,
  DatePicker,
  Upload,
  Divider,
  Button
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

/**
 * Custom hook that defines the fields for the addDocumentCard component.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setFileList - The function to set the file list.
 * @param {Object} options.fileList - The current file list.
 * @returns {Object} - The fields object.
 */
export const useFields = ({ setFileList, fileList }) => {
  const { t } = useTranslation();

  const draggerProps = ({ name }) => ({
    multiple: true,
    onRemove: () => {
      const updatedFileList = { ...fileList };
      delete updatedFileList[name];
      setFileList(updatedFileList);
    },
    beforeUpload: (file) => {
      setFileList((prev) => {
        const updatedList = { ...prev };
        if (updatedList[name]) {
          updatedList[name] = [...updatedList[name], file];
        } else {
          updatedList[name] = [file];
        }
        return updatedList;
      });

      return false;
    },
    fileList: fileList[name] || []
  });

  const fields = [
    {
      noLabel: true,
      name: ['documents'],
      input: (
        <>
          <Divider orientation="left">
            <Title level={5}>
              {t('missions.dividers.new_project_version')}
            </Title>
          </Divider>
          <Form.List name="documents" initialValue={[{}]}>
            {(listFields, { add }) => (
              <>
                {listFields.map(({ key, name, ...restField }) => (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item>
                        <Dragger {...draggerProps({ name })}>
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined
                              style={{ color: 'var(--textColor)' }}
                            />
                          </p>
                          <p className="ant-upload-text">
                            {t('files.create.action')}
                          </p>
                        </Dragger>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Form.Item {...restField} name={[name, 'title']}>
                            <Input
                              placeholder={t(
                                'missions.form.personalized_title'
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item {...restField} name={[name, 'date']}>
                            <DatePicker
                              format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item {...restField} name={[name, 'comment']}>
                            <TextArea
                              placeholder={t('missions.form.comment')}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
                <Col span={24}>
                  <Button type="primary" onClick={() => add()}>
                    {t('missions.buttons.add_another_version')}
                  </Button>
                </Col>
              </>
            )}
          </Form.List>
          <Divider orientation="left">
            <Title level={5}>{t('missions.dividers.other_documents')}</Title>
          </Divider>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Dragger {...draggerProps({ name: 'other_documents' })}>
                <p className="ant-upload-drag-icon">
                  <UploadOutlined style={{ color: 'var(--textColor)' }} />
                </p>
                <p className="ant-upload-text">{t('files.create.action')}</p>
              </Dragger>
            </Col>
            <Col span={24}>
              <Form.Item name={['other_documents', 'personalized_title']}>
                <Input placeholder={t('missions.form.personalized_title')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name={['other_documents', 'date']}>
                <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name={['other_documents', 'comment']}>
                <TextArea placeholder={t('missions.form.comment')} />
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    }
  ];

  return { fields };
};
