import { useState } from 'react';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, Flex, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const tradKeyF = 'pcs-pre-productions.form';
const buttonStyle = {
  backgroundColor: '#ffd53f',
  borderColor: 'transparent',
  borderRadius: '15px',
  color: '#222222',
  margin: '0px 10px'
};

/**
 * Custom hook that provides fields for different steps in the compliance elan audit process.
 * @hook
 * @param {function} setCurrent - A function to update the current step of the audit process.
 * @param {function} setInitialValues - A function to set the initial values for the form fields.
 * @param {string} codeToValidate - The code to validate.
 * @returns {object} - An object containing fields for different steps of the audit process.
 */
export const useSignCodeFields = ({
  setCurrent,
  setInitialValues,
  codeToValidate
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentCode, setCurrentCode] = useState('');
  const [checked, setChecked] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const searchParams = new URLSearchParams(location.search);
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);

  const createVerificationCode = async (type) => {
    setIsLoading(true);
    const { data } = await dispatchAPI('POST', {
      url: `verification-codes?url_code=${searchParams.get('id')}&type=${type}`
    });
    setIsLoading(false);
    if (data.status_code === 201) setCurrent(1);
  };

  const verifyCode = async (type) => {
    try {
      const { data } = await dispatchAPI('PATCH', {
        url: `verification-codes?code=${currentCode}&url_code=${searchParams.get(
          'id'
        )}&type=${type}`
      });
      if (data) {
        setInitialValues((prev) => ({
          ...prev,
          devis_signed: [
            ...(prev.devis_signed || []),
            { type, signed_date: Date.now() }
          ]
        }));
        setCurrent(2);
      }
    } catch (error) {
      message(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const fieldsAcceptSendCode = (
    <Flex gap={15} vertical>
      <Checkbox required onChange={(e) => onChange(e)}>
        {t(`${tradKeyF}.accept_conditions`)}
      </Checkbox>
      <Button
        type="primary"
        disabled={!checked || isLoading}
        loading={isLoading}
        onClick={() => createVerificationCode(codeToValidate)}
        style={buttonStyle}
      >
        <EditOutlined />
        {t('syndic-view.audit.sign_contract')}
      </Button>
    </Flex>
  );

  const fieldValidateCode = (
    <>
      {t('pcs-pre-productions.form.email_sent_24h')}
      <div>
        <Input
          onChange={(e) => setCurrentCode(e.target.value)}
          value={currentCode}
          onKeyDown={handleKeyDown}
          placeholder={t(`${tradKeyF}.code_here`)}
        />
      </div>
      <Button
        type="primary"
        onClick={() => verifyCode(codeToValidate)}
        style={buttonStyle}
      >
        <CheckOutlined />
        {t(`${tradKeyF}.verify_code`)}
      </Button>
    </>
  );

  const fieldsCodeIsValidated = (
    <>
      <b>{t('pcs-pre-productions.form.successfully_signed')}</b>
      <>{t('pcs-pre-productions.form.successfully_signed_next_step')}</>
    </>
  );

  return { fieldsAcceptSendCode, fieldValidateCode, fieldsCodeIsValidated };
};
