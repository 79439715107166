import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../components';

/**
 * InformationsCard component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.informationsListContent - The list of information items to be displayed.
 * @returns {JSX.Element} The rendered component.
 */
export const InformationsCard = ({ informationsListContent }) => {
  const { t } = useTranslation();
  return (
    <Card
      title={t('orders.show.cards.titles.infos')}
      style={{ minWidth: '70%' }}
    >
      <DescriptionList translate data={informationsListContent} />
    </Card>
  );
};

InformationsCard.propTypes = {
  informationsListContent: PropTypes.arrayOf(PropTypes.shape({}))
};

InformationsCard.defaultProps = {
  informationsListContent: []
};
