import { ListResource } from '../../../../../components';
import { useColumns } from '../columns/noteColumns';

/**
 * Renders a list of notes.
 * @component
 * @returns {JSX.Element} The rendered list of notes.
 */
export const ListNotes = () => {
  const columns = useColumns();

  return (
    <ListResource
      withCreateButton={false}
      withSearchBar={false}
      withPageHeader={false}
      populate="documents,documents.files"
      resourceName="notes"
      columns={columns}
      customActionColumn
      resourceModelName="Note"
      scroll={{ x: 'max-content' }}
    />
  );
};
