import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { ExtraButtons } from './ExtraButtons';

const { Text, Title } = Typography;

/**
 * List missions component
 * @returns {JSX.Element} List missions component
 * @constructor ListMissions
 * @component ListMissions
 * @example <ListMissions />
 */
export const ListMissions = () => {
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [extraQuery, setExtraQuery] = useState(
    're_written_document_status=IN_PROGRESS'
  );
  const [isChecked, setIsChecked] = useState(false);
  const columns = useColumns({ setForceRefresh, isChecked });

  return (
    <>
      <Card
        style={{
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '20px',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      >
        <Typography>
          <Title
            level={2}
            style={{
              color: 'white'
            }}
          >
            {t('prestation_word.missions.title')}
          </Title>
          <Text
            style={{
              color: 'white'
            }}
          >
            {t('prestation_word.missions.description')}
          </Text>
        </Typography>
      </Card>
      <ListResource
        resourceName="missions/prestation-word-documents"
        columns={columns}
        customActionColumn
        extraQuery={extraQuery}
        resourceModelName="Mission"
        withPageHeader={false}
        withCreateButton={false}
        forceRefresh={forceRefresh}
        extraButtons={
          <ExtraButtons
            isChecked={isChecked}
            setExtraQuery={setExtraQuery}
            setIsChecked={setIsChecked}
            setForceRefresh={setForceRefresh}
          />
        }
      />
    </>
  );
};
