import { Route, Routes, useLocation } from 'react-router-dom';
import { ListSyndic } from './ListSyndic';
import { CreateUpdateSyndic } from './CreateUpdateSyndic/CreateUpdateSyndic';
import { ShowSyndic } from './ShowSyndic/ShowSyndic';
import { Exception } from '../../components';

export const SyndicRouter = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route path="/create" element={<CreateUpdateSyndic purpose="create" />} />
      <Route path="/edit/:id" element={<CreateUpdateSyndic purpose="edit" />} />
      <Route path="/show/:id" element={<ShowSyndic key={location.key} />} />
      <Route index element={<ListSyndic />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  );
};
