/**
 * Returns the action fields map.
 * @returns {Object} The action fields map.
 */
export const useNotaryHonoraryFields = () => {
  const actionFieldsMap = {
    PUBLICATION_PCS: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary.honorary_ttc',
        key: 'honorary_ttc'
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.notary_honorary.extra',
        key: 'extra'
      }
    ],
    ENJOYMENT_RIGHT_TO_VALIDATED: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary.honorary_ttc',
        key: 'honorary_ttc'
      },
      {
        name: 'right_of_use_to_be_ratified_ttc',
        label:
          'quotation-act-amounts.form.notary_honorary.right_of_use_to_be_ratified_ttc',
        key: 'right_of_use_to_be_ratified_ttc'
      },
      {
        name: 'enjoyment_rigth_ttc',
        label: 'quotation-act-amounts.form.notary_honorary.enjoyment_rigth_ttc',
        key: 'enjoyment_rigth_ttc'
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.notary_honorary.extra',
        key: 'extra'
      }
    ],
    CHARGING_GRID: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary.honorary_ttc',
        key: 'honorary_ttc'
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.notary_honorary.extra',
        key: 'extra'
      }
    ],
    LOT_ENJOYMENT_RIGHT: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary.honorary_ttc',
        key: 'honorary_ttc'
      },
      {
        name: 'right_of_use_to_be_ratified_ttc',
        label:
          'quotation-act-amounts.form.notary_honorary.right_of_use_to_be_ratified_ttc',
        key: 'right_of_use_to_be_ratified_ttc'
      },
      {
        name: 'enjoyment_rigth_ttc',
        label: 'quotation-act-amounts.form.notary_honorary.enjoyment_rigth_ttc',
        key: 'enjoyment_rigth_ttc'
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.notary_honorary.extra',
        key: 'extra'
      }
    ],
    LOT_CREATION_PCS: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary.honorary_ttc',
        key: 'honorary_ttc'
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.notary_honorary.extra',
        key: 'extra'
      }
    ],
    LOT_REMOVAL_PCS: [
      {
        name: 'honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary.honorary_ttc',
        key: 'honorary_ttc'
      },
      {
        name: 'extra',
        label: 'quotation-act-amounts.form.notary_honorary.extra',
        key: 'extra'
      }
    ]
  };

  return { actionFieldsMap };
};
