import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes } from '../utils/constants/routes/syndicCouncilRoutes';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { RequireAuth } from './RequireAuth';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Login } from '../routes/login';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { useGlobalContext } from '../contexts/GlobalContext';
import { LoadingAndRefreshModal } from '../components/LoadingAndRefreshModal';
import { CreateUpdateCollectiveOwnershipForm } from '../routes/monitoring/collective-ownerships-forms/pcs-informations/CreateUpdateCollectiveOwnershipForm';
import { Exception } from '../components';
import { MissionsConseilSyndicRouter } from '../routes/conseil-syndic/MissionsConseilSyndicRouter';

/**
 * @component
 * Renders the Syndic council Router component.
 * This component handles the routing for the Syndic council section of the application.
 *
 * @returns {JSX.Element} The Syndic Manager Router component.
 */
export const SyndicCouncilRouter = () => {
  const { isValid } = useAuthContext();
  const { currentSyndic } = useGlobalContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  if (!currentSyndic) {
    return <LoadingAndRefreshModal />;
  }
  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={outOfNavRoutes.PCSFORM}
          element={<CreateUpdateCollectiveOwnershipForm purpose="create" />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route path="/*" element={<MissionsConseilSyndicRouter />} />
          <Route path="*" element={<Exception />} />
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
