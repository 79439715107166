import { Table, Button, message, Form, DatePicker, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from '../columns/quotationActionsColumns';
import { useQuotationContext } from '../../../contexts/QuotationContext';
import { QuotationAction } from '../../../models/QuotationAction';

/**
 * Renders the DetailsForm component.
 *
 * @component
 * @returns {JSX.Element} The rendered DetailsForm component.
 */
export const DetailsForm = () => {
  const { quotationActions, setQuotationActions, setFormData } =
    useQuotationContext();
  const { t } = useTranslation();
  const columns = useColumns();

  return (
    <>
      <Row justify="center">
        <Col>
          <Form.Item
            rules={[{ required: true }]}
            label={t('quotations.form.quotation_date')}
            name={['date']}
          >
            <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
          </Form.Item>
        </Col>
      </Row>
      <Table
        style={{ overflow: 'auto' }}
        dataSource={quotationActions}
        columns={columns}
        scroll={{ x: 'max-content' }}
      />
      <Button
        style={{ marginTop: '8px' }}
        onClick={() =>
          QuotationAction.addQuotationAction({
            setFormData,
            setQuotationActions,
            message,
            t
          })
        }
      >
        {t('quotations.buttons.add_action')}
      </Button>
    </>
  );
};
