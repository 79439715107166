import { Route, Routes } from 'react-router-dom';
import { ListSpfDepartmentalArchives } from './ListSpfDepartmentalArchives';
import { CreateUpdateSpfDepartmentalArchive } from './CreateUpdateSpfDepartmentalArchive';
import { Exception } from '../../../components';

export const SpfDepartmentalArchiveRouter = () => (
  <Routes>
    <Route path="/" element={<ListSpfDepartmentalArchives />} />
    <Route
      path="/create"
      element={<CreateUpdateSpfDepartmentalArchive purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateSpfDepartmentalArchive purpose="edit" />}
    />

    <Route path="*" element={<Exception />} />
  </Routes>
);
