import { Mission } from '../../../models/Mission';
import { setMissionSteps } from './setMissionSteps';

/**
 * Creates a mission from collective ownership.
 * @param {Object} options - The options for creating the mission.
 * @param {string} options.missisonType - The type of the mission.
 * @param {Object} options.collectiveOwnership - The collective ownership object.
 * @param {function} options.navigate - The navigate function.
 * @param {object} options.notification - The notification object.
 * @param {function} options.t - The translation function.
 * @param {function} options.message - The message function.
 * @returns {Promise<void>} The promise.
 */
export const createMission = async ({
  missisonType,
  collectiveOwnership,
  navigate,
  notification,
  t,
  message
}) => {
  const basicValues = {
    type: missisonType,
    collective_ownership: collectiveOwnership._id,
    syndic: collectiveOwnership.syndic?._id,
    manager: collectiveOwnership.manager._id,
    assistant: collectiveOwnership.syndic?.assistants[0],
    cadastral_reference: collectiveOwnership.cadastral_reference,
    steps: setMissionSteps(missisonType),
    release_date: Date.now()
  };

  if (missisonType === 'AUDIT_1_2') {
    basicValues.transformation_word = false;
  }

  try {
    const data = await Mission.createResource({ values: { ...basicValues } });

    if (!data.steps.principal) {
      notification.error({
        message: t('errors.message.title'),
        description: t('missions.messages.error')
      });
      return;
    }

    navigate(
      `/missions/missions-view/show/${missisonType.toLowerCase()}/${data._id}/${
        data?.type
      }`
    );
  } catch (error) {
    message(error);
  }
};
