/**
 * Handles the mission type for documents.
 *
 * @function
 * @param {string} el - The mission type.
 * @param {string} type - The document type.
 * @param {string} lawyer_step - The lawyer step.
 * @returns {string} - The corresponding document type.
 */
export const handleMissionTypeForDocuments = (el, type, lawyer_step) => {
  switch (type) {
    case 'PCS_1':
      if (['AG_DECISION', 'FINISHED'].includes(lawyer_step)) {
        switch (el) {
          case '1':
            return 'PCS_1';
          default:
            return 'OTHER';
        }
      } else return null;
    case 'PCS_2': {
      switch (el) {
        case '1':
          return 'PCS_2';
        case '2':
          return 'PCS_1';
        default:
          return null;
      }
    }
    case 'PCS_3':
      switch (el) {
        case '1':
          return 'PCS_3';
        case '2':
          return 'PCS_2';
        case '3':
          return 'PCS_1';
        default:
          return null;
      }
    default:
      return null;
  }
};
