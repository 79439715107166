import { Badge, Space } from 'antd';
import { EyeOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

export const useManagerColumns = (comment) => {
  const iconStyle = { fontSize: 18, color: 'black', cursor: 'pointer' };

  return [
    {
      key: 'indicator',
      render: () => <Badge status="error" />
    },
    {
      key: 'manager_name',
      render: (record) => {
        const first_name = record?.manager.first_name;
        const last_name = record?.manager.last_name;

        return `${last_name} ${first_name}`;
      }
    },
    {
      key: 'manager_phone_number',
      render: (record) => {
        const phone_number = record?.manager.phone_number;
        if (phone_number?.country_code && phone_number?.number) {
          return `${phone_number.country_code} ${phone_number.number}`;
        }
        if (phone_number?.number) {
          return phone_number?.number;
        }
        return '-';
      }
    },
    {
      key: 'last_reminder',
      render: (record) => {
        if (!record.invoices.length) {
          return 'Aucune';
        }
        let current = record.invoices[0]?.last_reminder;

        record.invoices.forEach((invoice) => {
          if (invoice?.last_reminder > current) {
            current = invoice.last_reminder;
          }
        });
        if (current) {
          return dayjs(current).format('DD-MM-YYYY');
        }
        return 'Aucune';
      }
    },
    {
      key: 'next_reminder',
      render: (record) => {
        if (!record.invoices.length) {
          return 'Aucune';
        }
        let current = record.invoices[0]?.next_reminder;

        record.invoices.forEach((invoice) => {
          if (invoice?.next_reminder === 'Aucune') {
            current = invoice?.next_reminder;
          }
          if (current !== 'Aucune' && invoice?.next_reminder > current) {
            current = invoice.next_reminder;
          }
        });
        if (current !== 'Aucune') {
          return dayjs(current).format('DD-MM-YYYY');
        }
        return 'Aucune';
      }
    },
    {
      key: 'nb_invoices',
      render: (record) => record.invoices.length
    },
    {
      key: 'total_billed',
      render: (record) => {
        let total = 0;

        record.invoices.forEach((invoice) => {
          total += invoice.total_ttc;
        });
        return total ? `${parseFloat(total).toFixed(2)} €` : '0 €';
      }
    },
    {
      key: 'total_paid',
      render: (record) => {
        let total = 0;

        record.invoices.forEach((invoice) => {
          total += invoice.paid;
        });
        return total ? `${parseFloat(total).toFixed(2)} €` : '0 €';
      }
    },
    {
      key: 'total_left_to_pay',
      render: (record) => {
        let total = 0;

        record.invoices.forEach((invoice) => {
          total += invoice.left_to_pay;
        });
        return total ? `${parseFloat(total).toFixed(2)} €` : '0 €';
      }
    },
    {
      key: 'comment',
      render: () => comment
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/users/show/${record._id}`}>
            <EyeOutlined style={iconStyle} />
          </Link>
          <Link to={`/users/edit/${record._id}`}>
            <UserOutlined style={iconStyle} />
          </Link>
          <MailOutlined style={iconStyle} />
        </Space>
      )
    }
  ];
};
