import {
  BellOutlined,
  EuroCircleOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

/**
 * Renders additional buttons for invoices.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setForceRefresh - The function to force refresh.
 * @param {boolean} props.isShowActionsButtons - Indicates whether to show action buttons.
 * @param {Array} props.selectedInvoices - The selected invoices.
 * @returns {JSX.Element} - The rendered component.
 */
export const ExtraButtons = ({
  setForceRefresh,
  isShowActionsButtons,
  selectedInvoices
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Row gutter={[16, 16]}>
      {isShowActionsButtons && (
        <>
          <Col span={3.5}>
            <Button
              type="primary"
              icon={<EuroCircleOutlined />}
              onClick={() => {
                navigate('/billing-and-accountings/invoices/add-payments', {
                  state: [...selectedInvoices]
                });
                setForceRefresh((prev) => !prev);
              }}
            >
              {t('invoices.buttons.add_payment')}
            </Button>
          </Col>
          <Col span={3.5}>
            <Button
              type="primary"
              icon={<BellOutlined />}
              onClick={() => {
                // : Add logic of invoice creation here
                setForceRefresh((prev) => !prev);
              }}
            >
              {t('invoices.buttons.remind_payment')}
            </Button>
          </Col>
          <Col span={3.5}>
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              onClick={() => {
                // : Add logic of invoice creation here
                setForceRefresh((prev) => !prev);
              }}
            >
              {t('invoices.buttons.print')}
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
};

ExtraButtons.propTypes = {
  setForceRefresh: PropTypes.func.isRequired,
  isShowActionsButtons: PropTypes.bool.isRequired,
  selectedInvoices: PropTypes.arrayOf(PropTypes.element).isRequired
};
