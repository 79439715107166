import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../../components/ContentCustom/ContentCustom';
import { useColumns as useSpfDepartmentalArchiveColumns } from '../../spf-departmental-archive/columns';
import { useColumns as useMunicipalityColumns } from '../../municipality/columns';
import { GetEnums } from '../../../../utils/getEnums';
import { useConfigurationContext } from '../../../../contexts/ConfigurationContext';
import { MergeDuplicatesMethods } from './methods';

/**
 * Renders a list of duplicates based on the configuration type.
 * @returns {JSX.Element} The rendered list of duplicates.
 */
export const ListDuplicates = () => {
  const { t } = useTranslation();
  const { resource } = useParams();
  const { getEnums } = GetEnums();
  const { useColumns, useDataSources } = MergeDuplicatesMethods();
  const { duplicateSpfDepartmentalArchives, duplicateMunicipalities } =
    useConfigurationContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [enums, setEnums] = useState({});
  const spfDepartmentalArchiveColumns = useSpfDepartmentalArchiveColumns({
    forceRefresh,
    setForceRefresh,
    enums,
    list_duplicates: true
  });
  const municipalityColumns = useMunicipalityColumns({
    forceRefresh,
    setForceRefresh,
    enums,
    list_duplicates: true
  });

  useEffect(() => {
    (async () => {
      const enumsDatas = await getEnums({ resource });
      setEnums(enumsDatas);
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t(`${resource}.duplicate_title`)} />
      <ContentCustom>
        <Table
          columns={useColumns({
            resource,
            spfDepartmentalArchiveColumns,
            municipalityColumns
          })}
          dataSource={useDataSources({
            resource,
            duplicateSpfDepartmentalArchives,
            duplicateMunicipalities
          })}
        />
      </ContentCustom>
    </>
  );
};
