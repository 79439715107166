/**
 * Sets the step and mission ID based on the given mission.
 * @function
 * @param {Object} options - The options object.
 * @param {Array} options.missions - The missions array.
 * @returns {Array} - The step and mission ID objects within an array.
 */
export const multipleSetStepAndMissionId = ({ prev, missions }) =>
  missions.map((mission) => {
    if (mission.type.includes('PCS')) {
      switch (mission.steps.sub) {
        case 'BUILDING_FORM_ORDER':
          return {
            ...prev,
            missionId: mission._id,
            step: mission.type === 'PCS_1' ? 'BUILDING_FORM_DELIVERY' : null
          };
        case 'ORDER_VERIFICATION':
          return {
            ...prev,
            missionId: mission._id,
            step: 'DOCUMENTS_DELIVERY'
          };
        default:
          return {
            ...prev,
            missionId: null,
            step: null
          };
      }
    } else {
      switch (mission.steps.principal) {
        case 'BUILDING_FORM_ORDER':
          return {
            ...prev,
            missionId: mission._id,
            step: 'BUILDING_FORM_DELIVERY'
          };
        case 'ORDER_VERIFICATION':
          return {
            ...prev,
            missionId: mission._id,
            step: 'DOCUMENTS_DELIVERY'
          };
        case 'DOCUMENTS_ORDER':
          return {
            missionId: mission._id,
            step: 'DOCUMENTS_DELIVERY'
          };
        default:
          return { missionId: null, step: null };
      }
    }
  });
