import React, { useState, useEffect } from 'react';
import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns an array of fields for lawyer consultation.
 *
 * @hook
 * @returns {Object} An object containing the fields array.
 */
export const useFields = ({ form }) => {
  const { t } = useTranslation();
  const [checkedKey, setCheckedKey] = useState(null);

  const handleCheckboxChange = (key) => {
    setCheckedKey((prevCheckedKey) => (prevCheckedKey === key ? null : key));
  };

  useEffect(() => {
    form.setFieldsValue({
      accepted_measures: {
        collective_ownership_regulation_creation:
          checkedKey === 'collective_ownership_regulation_creation',
        compliance: checkedKey === 'compliance',
        adaptation: checkedKey === 'adaptation'
      },
      no_measure_accepted: checkedKey === 'no_measure_accepted'
    });
  }, [checkedKey]);

  const fields = [
    {
      label: 'accepted_measures',
      input: (
        <Form.Item name={['accepted_measures']} rules={[{ required: true }]}>
          <Form.Item
            key="collective_ownership_regulation_creation"
            name={[
              'accepted_measures',
              'collective_ownership_regulation_creation'
            ]}
            valuePropName="checked"
          >
            <Checkbox
              checked={
                checkedKey === 'collective_ownership_regulation_creation'
              }
              onChange={() =>
                handleCheckboxChange('collective_ownership_regulation_creation')
              }
            >
              {t(`missions.form.collective_ownership_regulation_creation`)}
            </Checkbox>
          </Form.Item>

          <Form.Item
            key="compliance"
            name={['accepted_measures', 'compliance']}
            valuePropName="checked"
          >
            <Checkbox
              checked={checkedKey === 'compliance'}
              onChange={() => handleCheckboxChange('compliance')}
            >
              {t(`missions.form.compliance`)}
            </Checkbox>
          </Form.Item>

          <Form.Item
            key="adaptation"
            name={['accepted_measures', 'adaptation']}
            valuePropName="checked"
          >
            <Checkbox
              checked={checkedKey === 'adaptation'}
              onChange={() => handleCheckboxChange('adaptation')}
            >
              {t(`missions.form.adaptation`)}
            </Checkbox>
          </Form.Item>
        </Form.Item>
      )
    },
    {
      noLabel: true,
      input: (
        <Form.Item name={['no_measure_accepted']} rules={[{ required: true }]}>
          <Checkbox
            checked={checkedKey === 'no_measure_accepted'}
            onChange={() => handleCheckboxChange('no_measure_accepted')}
          >
            {t(`missions.form.no_measure_accepted`)}
          </Checkbox>
        </Form.Item>
      )
    },
    {
      name: ['ag_pv_signed'],
      key: 'ag_pv_signed',
      maxFilesCount: 100,
      input: 'File',
      requestedDocumentType: "PV d'AG"
    },
    {
      name: ['signed_quotation'],
      key: 'signed_quotation',
      maxFilesCount: 100,
      input: 'File',
      requestedDocumentType: 'Devis'
    }
  ];

  return { fields };
};
