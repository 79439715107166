import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { PCSappointmentMonitoring } from '../../../../models/PCSAppointementMonitoring';

export const useDynamicColumns = (data, t) => [
  {
    title: t('pcs-appointment-monitoring.form.syndic'),
    onHeaderCell: () => ({
      className: 'pcs-header',
      style: { borderLeft: '0.5px solid var(--primaryColor)' }
    }),
    key: 'syndic',
    dataIndex: 'syndic',
    render: (syndic) => syndic?.society_name || '',
    onCell: () => ({
      style: {
        borderRight: '0.5px solid var(--borderColor)',
        borderLeft: '0.5px solid var(--borderColor)'
      }
    })
  },
  {
    title: t('pcs-appointment-monitoring.form.manager'),
    onHeaderCell: () => ({
      className: 'pcs-header'
    }),
    key: 'manager',
    dataIndex: 'manager',
    render: (manager) => manager?.last_name || '',
    onCell: () => ({
      style: {
        borderRight: '0.5px solid var(--borderColor)'
      }
    })
  },
  {
    title: t('pcs-appointment-monitoring.form.PCS_1'),
    onHeaderCell: () => ({
      className: 'pcs-header',
      style: { backgroundColor: 'var(--lightBlue)' }
    }),
    children: data?.PCS_1.map((meeting, index) => ({
      title: meeting.meeting_type,
      onHeaderCell: () => ({
        className: 'pcs-header',
        style: { backgroundColor: 'var(--lightBlue)' }
      }),
      key: index,

      render: (record) => {
        const PCS_1 = record?.PCS_1;
        const defaultValue = PCS_1.length
          ? PCS_1?.filter((el) => el?.column === meeting._id)[0]?.delay
          : null;
        return (
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            placeholder="JJ-MM-AAAA --:--"
            defaultValue={defaultValue ? dayjs(defaultValue) : null}
            format="DD-MM-YYYY HH:mm"
            onChange={async (value) => {
              if (defaultValue) {
                await PCSappointmentMonitoring.updatePCS_X_date({
                  id: record._id,
                  pcs_id: defaultValue._id,
                  values: { delay: value },
                  type: 'PCS_1',
                  column: meeting._id
                });
              } else {
                await PCSappointmentMonitoring.createPCS_X_date({
                  id: record._id,
                  values: { delay: value },
                  type: 'PCS_1',
                  column: meeting._id
                });
              }
            }}
          />
        );
      },
      onCell: () => ({
        style: {
          backgroundColor: 'var(--lightBlue)',
          padding: '5px',
          borderRight: '0.5px solid var(--borderColor)'
        }
      })
    }))
  },

  {
    title: t('pcs-appointment-monitoring.form.PCS_2'),
    onHeaderCell: () => ({
      className: 'pcs-header',
      style: { backgroundColor: 'var(--lightTurquoise)' }
    }),
    children: data?.PCS_2?.map((meeting, index) => ({
      title: meeting.meeting_type,
      onHeaderCell: () => ({
        className: 'pcs-header',
        style: { backgroundColor: 'var(--lightTurquoise)' }
      }),
      key: index,
      render: (record) => {
        const PCS_2 = record.PCS_2 || [];
        const defaultValue =
          PCS_2?.filter((el) => el?.column === meeting._id)[0]?.delay || null;
        return (
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            placeholder="JJ-MM-AAAA --:--"
            defaultValue={defaultValue ? dayjs(defaultValue) : null}
            format="DD-MM-YYYY HH:mm"
            onChange={async (value) => {
              if (defaultValue) {
                await PCSappointmentMonitoring.updatePCS_X_date({
                  id: record._id,
                  pcs_id: defaultValue._id,
                  values: { delay: value },
                  type: 'PCS_2',
                  column: meeting._id
                });
              } else {
                await PCSappointmentMonitoring.createPCS_X_date({
                  id: record._id,
                  values: { delay: value },
                  type: 'PCS_2',
                  column: meeting._id
                });
              }
            }}
          />
        );
      },
      onCell: () => ({
        style: {
          backgroundColor: 'var(--lightTurquoise)',
          padding: '5px',
          borderRight: '0.5px solid var(--borderColor)'
        }
      })
    }))
  },
  {
    title: t('pcs-appointment-monitoring.form.PCS_3'),
    onHeaderCell: () => ({
      className: 'pcs-header',
      style: { backgroundColor: 'var(--lightGreen)' }
    }),
    children: data?.PCS_3?.map((meeting, index) => ({
      title: meeting.meeting_type,
      onHeaderCell: () => ({
        className: 'pcs-header',
        style: { backgroundColor: 'var(--lightGreen)' }
      }),
      key: index,
      render: (record) => {
        const PCS_3 = record.PCS_3 || [];
        const defaultValue =
          PCS_3?.filter((el) => el?.column === meeting._id)[0]?.delay || null;
        return (
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            placeholder="JJ-MM-AAAA --:--"
            defaultValue={defaultValue ? dayjs(defaultValue) : null}
            format="DD-MM-YYYY HH:mm"
            onChange={async (value) => {
              if (defaultValue) {
                await PCSappointmentMonitoring.updatePCS_X_date({
                  id: record._id,
                  pcs_id: defaultValue._id,
                  values: { delay: value },
                  type: 'PCS_3',
                  column: meeting._id
                });
              } else {
                await PCSappointmentMonitoring.createPCS_X_date({
                  id: record._id,
                  values: { delay: value },
                  type: 'PCS_3',
                  column: meeting._id
                });
              }
            }}
          />
        );
      },
      onCell: () => ({
        style: {
          backgroundColor: 'var(--lightGreen)',
          padding: '5px',
          borderRight: '0.5px solid var(--borderColor)'
        }
      })
    }))
  },
  ...(data.ALL.length > 0
    ? [
        {
          title: t('pcs-appointment-monitoring.form.others'),
          onHeaderCell: () => ({
            className: 'pcs-header',
            style: { backgroundColor: 'var(--gray300)' }
          }),
          key: 'others',
          render: (record) => {
            const others = record?.others;
            return (
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                placeholder="JJ-MM-AAAA --:--"
                defaultValue={others ? dayjs(others) : null}
                onChange={async (value) => {
                  await PCSappointmentMonitoring.updateResource({
                    id: record._id,
                    values: { others: value }
                  });
                }}
              />
            );
          },
          onCell: () => ({
            style: {
              backgroundColor: 'var(--gray300)',
              padding: '5px',
              borderRight: '0.5px solid var(--borderColor)'
            }
          })
        }
      ]
    : [])
];
