import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Returns an array of columns for the collective ownership forms table.
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { setIsDrawerOpen, setCollectiveOwnershipForm } = useMissionContext();

  return [
    {
      title: t('missions.form.batch_numbers'),
      key: 'batch_numbers',
      dataIndex: 'batch_numbers',
      render: (batch_numbers) => batch_numbers || '-'
    },
    {
      title: t('missions.form.owners'),
      key: 'owners',
      dataIndex: 'owners',
      render: (owners) => owners || '-'
    },
    {
      title: t('missions.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: (email) => email || '-',
      hidden: true
    },
    {
      title: t('missions.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) =>
        phone_number?.number
          ? `${phone_number.country_code} ${phone_number?.number.replace(
              /(\d{2})(?=\d)/g,
              '$1 '
            )}`
          : '-',
      sorter: true,
      hidden: true
    },
    {
      title: t('missions.form.send_date'),
      key: 'send_date',
      dataIndex: 'send_date',
      render: (send_date) =>
        send_date ? dayjs(send_date).format('DD-MM-YYYY') : '-'
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setCollectiveOwnershipForm(record);
              setIsDrawerOpen((prev) => ({
                ...prev,
                collective_ownership_form: true
              }));
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Button>
          <Link
            to={`/monitoring/collective-ownership-forms/edit/${record._id}`}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
        </>
      )
    }
  ];
};
