import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Steps, Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../../components';
import { PcsPreProduction } from '../../../../models/PcsPreProduction';
import { SyndicInformations } from './SyndicInformations';
import { ManagerInformations } from './ManagerInformations';
import { CollectiveOwnershipInformations } from './CollectiveOwnershipInformations';
import { InformationsProvidedBySyndic } from './InformationsProvidedBySyndic';
import { Documents } from './Documents';
import { ListCollectiveOwnerships } from './ListCollectiveOwnerships';
import { Remarks } from './Remarks';
import { ValidateModal } from './ValidateModal';
import { Messages } from '../../../../components/Messages/Messages';
import { ShowButtons } from './extraButtons/ShowButtons';
import { MessageContextProvider } from '../../../../contexts/MessageContext';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { MissionContextProvider } from '../../../../contexts/MissionContext';
import MissionMonitoring from './MissionMonitoring/MissionMonitoring';

/**
 * Component for displaying the details of a PCS pre-production.
 * @component
 * @returns {JSX.Element} The JSX element representing the component.
 */
export const ShowPcsPreProduction = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useAuthContext();
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pcsPreProduction, setPcsPreProduction] = useState({});
  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const isCustomerService =
    user?.role === 'users:NOMADE_CUSTOMER_SERVICE_MANAGER';

  const steps = [
    {
      title: t('pcs-pre-productions.tabs.assistant'),
      icon: <CheckCircleOutlined />,
      key: 'assistant'
    },
    {
      title: t('pcs-pre-productions.tabs.account_manager'),
      icon: <CheckCircleOutlined />,
      key: 'account_manager',
      diabled: user.role !== 'users:NOMADE_ASSISTANT'
    },
    {
      title: t('pcs-pre-productions.tabs.production'),
      icon: <CheckCircleOutlined />,
      key: 'production',
      disabled: user.role !== 'users:NOMADE_ACCOUNT_MANAGER'
    },
    {
      title: t('pcs-pre-productions.tabs.deleted'),
      icon: <CheckCircleOutlined />,
      key: 'deleted',
      disabled: true
    },
    {
      title: t('pcs-pre-productions.tabs.validated'),
      icon: <CheckCircleOutlined />,
      key: 'validated',
      disabled:
        ![
          'users:NOMADE_PRODUCTION',
          'admins:ADMIN',
          'admins:SUPER-ADMIN'
        ].includes(user.role) || pcsPreProduction?.status === 'DELETED'
    }
  ];

  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled || index < current
  }));

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const PcsPreProductionData = await PcsPreProduction.getResource({
        id,
        populate:
          'syndic,manager,assistant,collective_ownership,collective_ownership.first_city,syndic.consultant contact,documents,documents.files,charging_grid,charging_grid.columns.column,history.by'
      });
      setPcsPreProduction(PcsPreProductionData);
      setCurrent(
        PcsPreProduction.arrStatus.indexOf(PcsPreProductionData.status)
      );
      setIsLoading(false);
    })();
  }, [forceRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={
          pcsPreProduction && Object.keys(pcsPreProduction).length
            ? t(`pcs-pre-productions.show.title`, {
                syndic_name: pcsPreProduction?.syndic?.society_name,
                syndic_code: pcsPreProduction?.syndic?.code
              })
            : null
        }
        extra={
          pcsPreProduction?.status !== 'DELETED' ? (
            <ShowButtons
              setForceRefresh={setForceRefresh}
              forceRefresh={forceRefresh}
              buttonsPermissions={{
                disableEditButton: isCustomerService,
                disableDeleteButton: isCustomerService
              }}
            />
          ) : null
        }
      />
      <ContentCustom>
        <Steps
          current={current}
          items={items}
          onChange={(e) =>
            PcsPreProduction.updateStatus({
              element: e,
              id,
              setIsValidateModalOpen,
              setCurrent,
              setPcsPreProduction,
              setForceRefresh,
              forceRefresh,
              user
            })
          }
          style={{ marginBottom: 24 }}
        />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={14}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <SyndicInformations
                  pcsPreProduction={pcsPreProduction}
                  isLoading={isLoading}
                />
              </Col>
              <Col span={24}>
                <ManagerInformations
                  pcsPreProduction={pcsPreProduction}
                  isLoading={isLoading}
                />
              </Col>
              <Col span={24}>
                {pcsPreProduction?.collective_ownerships?.length ? (
                  <MissionContextProvider>
                    <ListCollectiveOwnerships
                      pcsPreProduction={pcsPreProduction}
                      isLoading={isLoading}
                      setForceRefresh={setForceRefresh}
                      forceRefresh={forceRefresh}
                    />
                  </MissionContextProvider>
                ) : (
                  <CollectiveOwnershipInformations
                    pcsPreProduction={pcsPreProduction}
                    isLoading={isLoading}
                  />
                )}
              </Col>
              <Col span={24}>
                <Remarks
                  pcsPreProduction={pcsPreProduction}
                  isLoading={isLoading}
                />
              </Col>
              <Col span={24}>
                <MissionMonitoring
                  ressource={pcsPreProduction}
                  forceRefresh={forceRefresh}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} lg={10}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <InformationsProvidedBySyndic
                  pcsPreProduction={pcsPreProduction}
                  isLoading={isLoading}
                  setPcsPreProduction={setPcsPreProduction}
                />
              </Col>
              <Col span={24}>
                <Documents
                  pcsPreProduction={pcsPreProduction}
                  setPcsPreProduction={setPcsPreProduction}
                  isLoading={isLoading}
                  setForceRefresh={setForceRefresh}
                  forceRefresh={forceRefresh}
                />
              </Col>
              <Col span={24}>
                <MessageContextProvider id={id} model="Pcs-pre-production">
                  <Messages
                    withCreateButton
                    syndic={pcsPreProduction?.syndic?._id}
                    collective_ownership={
                      pcsPreProduction?.collective_ownership?._id
                    }
                  />
                </MessageContextProvider>
              </Col>
            </Row>
          </Col>
        </Row>
        {isValidateModalOpen ? (
          <ValidateModal
            isValidateModalOpen={isValidateModalOpen}
            setIsValidateModalOpen={setIsValidateModalOpen}
            setCurrent={setCurrent}
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
          />
        ) : null}
      </ContentCustom>
    </>
  );
};
