import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Select
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Task } from '../../../../models/Task';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useGlobalContext } from '../../../../contexts/GlobalContext';

const { Option } = Select;

const TodoItem = ({ item }) => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { syndics, setForceRefresh } = useGlobalContext();
  const [taskName, setTaskName] = useState('');
  const [date, setDate] = useState(item?.startTime);
  const [syndic, setSyndic] = useState('');
  const [done, setDone] = useState(false);

  const handleAddTask = async () => {
    await Task.createResource({
      values: {
        name: taskName,
        done,
        user: user._id,
        date,
        syndic
      }
    });
    setForceRefresh((prev) => !prev);
  };

  const getDateValue = () => {
    if (item.startTime) {
      return dayjs(item.startTime);
    }
    if (item.date) {
      return dayjs(item?.date);
    }
    return null;
  };

  return (
    <Col>
      <Row
        style={{
          display: 'flex',
          padding: '6px',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#E9ECEF'
        }}
        gutter={[4, 4]}
      >
        <Col span={24}>
          <Input
            placeholder={t('tasks.fields.title')}
            defaultValue={item?.name}
            onChange={(e) => setTaskName(e.target.value)}
            onPressEnter={(e) => {
              if (item?._id) {
                Task.updateResource({
                  id: item._id,
                  values: { name: e.target.value }
                });
                message.success(t('tasks.messages.update'));
                setForceRefresh((prev) => !prev);
              } else {
                setTaskName(e.target.value);
              }
            }}
          />
        </Col>
        <Col span={24}>
          <Select
            style={{ width: '100%' }}
            placeholder={t('tasks.fields.syndic')}
            defaultValue={
              item?.syndic
                ? `${item?.syndic?.society_name} - (${item?.syndic?.code})`
                : null
            }
            onChange={async (value) => {
              if (item._id) {
                await Task.updateResource({
                  id: item._id,
                  values: { syndic: value }
                });
                setForceRefresh((prev) => !prev);
              } else {
                setSyndic(value);
              }
            }}
          >
            {(syndics || []).map((syndicData) => (
              <Option key={syndicData._id} value={syndicData._id}>
                {`${syndicData.society_name} - (${syndicData.code})`}
              </Option>
            ))}
          </Select>
        </Col>
        <Col
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ width: 'fit-content' }}>
            <DatePicker
              style={{ width: 125 }}
              format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
              placeholder="Date"
              defaultValue={getDateValue()}
              onChange={async (value) => {
                if (item._id) {
                  await Task.updateResource({
                    id: item._id,
                    values: { date: value }
                  });
                  setForceRefresh((prev) => !prev);
                } else {
                  setDate(value);
                }
              }}
            />
            <Checkbox
              style={{ marginLeft: '8px' }}
              defaultChecked={item?.done || done}
              onChange={async (value) => {
                if (item._id) {
                  await Task.updateResource({
                    id: item._id,
                    values: { done: value.target.checked }
                  });
                  setForceRefresh((prev) => !prev);
                } else {
                  setDone(value.target.checked);
                }
                message.success(t('tasks.messages.update'));
              }}
            >
              OK
            </Checkbox>
          </div>
          <Popconfirm
            title={t('tasks.messages.delete_confirm')}
            okText="Oui"
            okButtonProps={{ type: 'danger' }}
            cancelText="Non"
            onConfirm={async () => {
              if (item._id) {
                await Task.deleteResource(item._id);
                setForceRefresh((prev) => !prev);
                message.success({
                  content: t('tasks.messages.delete'),
                  icon: <DeleteOutlined style={{ color: 'red' }} />
                });
                setForceRefresh((prev) => !prev);
              }
            }}
          >
            <DeleteOutlined style={{ color: 'black' }} />
          </Popconfirm>
        </Col>
      </Row>
      {!item?._id && (
        <Row
          style={{
            marginTop: '5px',
            justifyContent: 'center',
            marginBottom: '21px'
          }}
        >
          <Button
            onClick={async () => {
              await handleAddTask();
              setForceRefresh((prev) => !prev);
            }}
            type="primary"
          >
            {t('tasks.buttons.add_task')}
          </Button>
        </Row>
      )}
    </Col>
  );
};

TodoItem.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    startTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    done: PropTypes.bool,
    syndic: PropTypes.shape({
      _id: PropTypes.string,
      society_name: PropTypes.string,
      code: PropTypes.string
    })
  }).isRequired
};

export default TodoItem;
