import { Building, Dashboard } from '@carbon/icons-react';
import {
  BookOutlined,
  EditOutlined,
  EuroCircleOutlined,
  HourglassOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';

const iconStyle = {
  color: 'black'
};

const navMenuLogos = {
  COLLECTIVE_OWNERSHIPS: <Building className="anticon" style={iconStyle} />,
  DASHBOARD: <Dashboard className="anticon" style={iconStyle} />,
  ACTIONS_TO_DO: <EditOutlined className="anticon" style={iconStyle} />,
  PENDING_VALIDATION: (
    <HourglassOutlined className="anticon" style={iconStyle} />
  ),
  DRAFTS: <BookOutlined className="anticon" style={iconStyle} />,
  INVOICES: <EuroCircleOutlined className="anticon" style={iconStyle} />,
  AUDIT: <PlusCircleOutlined className="anticon" style={iconStyle} />,
  COMPLIANCE: <PlusCircleOutlined className="anticon" style={iconStyle} />,
  PUBLICATION: <PlusCircleOutlined className="anticon" style={iconStyle} />,
  DEVIS: <PlusCircleOutlined className="anticon" style={iconStyle} />
};

export default navMenuLogos;
