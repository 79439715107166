import React, { useEffect } from 'react';
import { Input, Typography, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../../utils/generateFormItem/generateFormItem';
import { useMainInfoFields } from '../fields/mainInformations';
import { CustomActionButtons } from '../../../../components/CreateUpdateContainer/CustomActionButtons';

const { Group } = Input;
const { Title } = Typography;

/**
 * The `MainInformationsForm` component is designed to collect and manage main information within a form structure.
 * It dynamically generates form fields for main information, first address, and second address sections using custom hooks and utilities.
 * The component supports dynamic loading states and leverages the Ant Design `Typography` and `Input.Group` components for layout and grouping.
 *
 * Props:
 * @prop {function} onFinish - Callback function to be called upon completion of the form.
 * @prop {function} onSubmit - Callback function to be called upon submission of the form.
 * @prop {Object} [initialValues={}] - Initial values for the form fields.
 * @prop {Object} form - Instance of the form provided by Ant Design's Form component, required for form control and value setting.
 * @prop {Object} [enums={}] - Enumeration values used for select fields within the form, allowing for dropdown selections.
 * @prop {boolean} isFieldsLoading - Indicates whether the form fields are currently loading, used to display loading indicators or disable fields temporarily.
 * @prop {function} setCurrent - Function to set the current step in a multi-step form process.
 * @prop {number} [current=0] - The current step in a multi-step form process.
 * @prop {function} setSyndic - Function to update the syndic state, typically used in a parent component to manage form state.
 * @prop {function} setManager - Function to update the manager state, similar to `setSyndic`, for managing form state.
 * @prop {Array} [managerOptions=[]] - Options for the manager select field, provided as an array of objects.
 * @prop {Array} [syndicOptions=[]] - Options for the syndic select field, similar to `managerOptions`.
 * @prop {function} t - Translation function used for internationalization, to dynamically translate labels, titles, and other text.
 *
 * The component utilizes the `useGenerateFormItem` hook to create form fields based on configurations provided by the `useMainInfoFields` hook.
 * It listens for changes to `initialValues` and updates the form values accordingly using the `useEffect` hook.
 * The `CustomActionButtons` component is used to render action buttons at the bottom of the form, providing navigation and submission functionality.
 *
 * Usage:
 * This component is intended for use within larger forms or form steps where main information about a collective ownership or similar entity needs to be captured.
 * It provides a structured and dynamic way to collect information such as addresses and contact details, with support for internationalization and custom actions.
 */

export const MainInformationsForm = ({
  onFinish,
  onSubmit,
  initialValues,
  form,
  enums,
  isFieldsLoading,
  setCurrent,
  current,
  setSyndic,
  setManager,
  managerOptions,
  syndicOptions,
  t,
  setPermissions
}) => {
  const generateFields = useGenerateFormItem();
  const { informationsFields, firstAddressFields, secondAddressFields } =
    useMainInfoFields({
      enums,
      isFieldsLoading,
      setSyndic,
      setManager,
      managerOptions,
      syndicOptions,
      form,
      t,
      setPermissions
    });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Group>
      {informationsFields.map((field) =>
        generateFields('collective-ownerships', field)
      )}
      <Divider>
        <Title level={5} className="underlined">
          {t('collective-ownerships.form.sub_titles.first_address')}
        </Title>
      </Divider>

      {firstAddressFields.map((field) =>
        generateFields('collective-ownerships', field)
      )}
      <Divider>
        <Title level={5} className="underlined">
          {t('collective-ownerships.form.sub_titles.second_address')}
        </Title>
      </Divider>

      {secondAddressFields.map((field) =>
        generateFields('collective-ownerships', field)
      )}
      <CustomActionButtons
        onFinish={onFinish}
        onSubmit={onSubmit}
        setCurrent={setCurrent}
        current={current}
        nextButton
      />
    </Group>
  );
};

MainInformationsForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  current: PropTypes.number,
  initialValues: PropTypes.shape({}),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  enums: PropTypes.shape({}),
  isFieldsLoading: PropTypes.bool.isRequired,
  setSyndic: PropTypes.func.isRequired,
  setManager: PropTypes.func.isRequired,
  managerOptions: PropTypes.arrayOf(PropTypes.shape({})),
  syndicOptions: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func.isRequired,
  setPermissions: PropTypes.func
};

MainInformationsForm.defaultProps = {
  initialValues: {},
  enums: {},
  current: 0,
  managerOptions: [],
  syndicOptions: [],
  setPermissions: () => {}
};
