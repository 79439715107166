import { DownloadOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { missionTag } from '../../../../utils/constants/tagColors';
import { Datatable } from '../../../../components';
import { dateFormat } from '../../utils/dateFormat';

/**
 * Custom hook that returns the expandable columns configuration for the syndic view of invoices.
 *
 * @param {Array} selectedIncoives - The selected invoices.
 * @param {Function} setSelectedInvoices - The function to set the selected invoices.
 * @returns {Object} - The expandable columns configuration.
 */
export const useExpandableColumns = (selectedIncoives, setSelectedInvoices) => {
  const { t } = useTranslation();

  const rowSelection = {
    selectedIncoives,
    onChange: (rowId, rowData) => {
      const desiredData = rowData.map((record) => ({
        invoiceId: record._id,
        collective_ownership_Id: record.collective_ownership_Id
      }));
      const collective_ownership_Id = rowData[0].collective_ownership_Id;
      setSelectedInvoices({
        ...selectedIncoives,
        [collective_ownership_Id]: desiredData
      });
    }
  };

  const expandableColumns = [
    {
      title: t('syndic-factures.mission'),
      key: 'mission_type',
      dataIndex: 'missionType',
      render: (type) => (
        <Tag color={missionTag[type]} key={type}>
          {t(`missions.tags.${type}`)}
        </Tag>
      )
    },
    {
      title: t('syndic-factures.invoice_type'),
      dataIndex: 'type',
      render: (type) => t(`invoices.tags.${type}`)
    },
    {
      title: t('syndic-factures.status'),
      dataIndex: 'status',
      render: (status) => (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: status === 'PAID' ? '#10b9a8' : '#cb2529',
            padding: '5px',
            color: 'white'
          }}
        >
          {t(`invoices.tags.${status}`)}
        </div>
      )
    },
    {
      title: t('syndic-factures.sending_date'),
      dataIndex: 'sending_date',
      render: (sending_date) =>
        sending_date ? dayjs(sending_date).format(dateFormat) : '-'
    },
    {
      title: t('syndic-factures.payment_date'),
      dataIndex: ['payments'],
      render: (payments) => {
        if (payments && payments.length > 0) {
          const sortedPayments = payments.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          );
          const earliestDate = sortedPayments[0].date;
          return dayjs(earliestDate).format(dateFormat);
        }
        return '-';
      }
    },
    {
      title: t('syndic-factures.total_ttc'),
      dataIndex: 'total_ttc'
    },
    {
      title: t('syndic-factures.paid'),
      dataIndex: 'paid'
    },
    {
      title: t('syndic-factures.total_unpaid'),
      dataIndex: 'left_to_pay'
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: () => <DownloadOutlined style={{ fontSize: '18px' }} />
    }
  ];

  return {
    expandedRowRender: (record) => (
      <Datatable
        style={{ margin: 0, padding: 0 }}
        resourceName="syndic-collective-ownerships/expandable"
        extraQuery={`collective_ownership=${record._id}`}
        columns={[...expandableColumns]}
        customActionColumn
        onDoubleClickAction={false}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
      />
    ),
    rowExpandable: (record) =>
      record.invoices_calculation.nb_paid_invoices > 0 ||
      record.invoices_calculation.nb_unpaid_invoices > 0
  };
};
