import { Card, DatePicker, Flex, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { useState } from 'react';
import { chartsColors } from '../../../../../utils/constants/colors';
import { PCSMissionChart } from './PCSMissionChart';

/**
 * PCSMissionsStatisticsCard Component
 *
 * This component displays statistics for PCS missions over a specified period.
 * @component
 * @returns {JSX.Element} The PCSMissionsStatisticsCard component.
 */
export const PCSMissionsStatisticsCard = () => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState(dayjs());
  const [legend, setLegend] = useState({});

  const renderLegend = (item) => (
    <ul
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        listStyleType: 'none',
        fontStyle: 'italic'
      }}
    >
      {Object.keys(item).map((key) => {
        if (key === 'name') return null;
        return (
          <Space key={key} direction="horizontal">
            <div
              style={{
                width: 15,
                height: 15,
                borderRadius: '50%',
                backgroundColor: chartsColors.MISSIONS[key]
              }}
            />
            <li key={`item-${key}`} style={{ width: 'max-content' }}>
              {t(`dashboard.production.cards.pcs_missions_statistics.${key}`)}
            </li>
          </Space>
        );
      })}
    </ul>
  );

  const handlePeriodChange = (value) => {
    setPeriod(value);
  };

  return (
    <Card
      style={{ width: '100%' }}
      title={t('dashboard.production.cards.pcs_missions_statistics.title')}
    >
      <Flex justify="flex-end">
        <DatePicker
          onChange={handlePeriodChange}
          defaultValue={period}
          format="MM-YYYY"
          picker="month"
        />
      </Flex>
      <Flex style={{ width: '100%', paddingBottom: 24, paddingTop: 24 }}>
        <PCSMissionChart date={period} setLegend={setLegend} />
        <PCSMissionChart date={period.subtract(1, 'month')} />
      </Flex>
      {legend && legend.name ? renderLegend(legend) : null}
    </Card>
  );
};
