/**
 * Returns a configuration object for handling resource retrieval.
 * @hook
 * @returns {Object} The configuration object.
 */
export const useConfig = () => ({
  onGetResource: {
    setFields: (data) => {
      const desiredDocuments = (data?.desired_documents.documents || []).reduce(
        (obj, id) => {
          const updatedObject = obj;
          updatedObject[id] = true;
          return updatedObject;
        },
        {}
      );

      const updatedData = {
        ...data,
        desired_documents: {
          other_documents: data?.desired_documents.other_documents || [],
          ...desiredDocuments
        }
      };

      return updatedData;
    }
  }
});
