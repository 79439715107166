import { useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useColumns } from '../columns/invoiceColumns';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { EditableTableMethods } from '../../../../../components/editableTableComponents/methods';
import { addColumns } from '../../../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../../../components/ContentCustom/ContentCustom';
import { EditableTable } from '../../../../../components/editableTableComponents/EditableTable';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Renders a list of invoices for a mission.
 *
 * @component
 * @param {Object} extraQuery - Additional query parameters for fetching invoices.
 * @param {string} missionType - The type of mission.
 * @returns {JSX.Element} The rendered component.
 */
export const ListInvoices = ({ extraQuery, missionType }) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const { getResourceData } = EditableTableMethods();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    setPagination,
    pagination,
    forceRefresh,
    setForceRefresh,
    setSelectedDocuments
  } = useMissionContext();
  const columns = useColumns(missionType, setEditingKey, form);

  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  const handleDataSource = async () => {
    const invoicesDatas = await getResourceData({
      resource: 'invoices',
      setPagination,
      pagination,
      query: extraQuery
    });

    const updatedDocuments = invoicesDatas.map(
      (collectiveOwnership, index) => ({
        ...collectiveOwnership,
        key: index
      })
    );
    setDataSource(updatedDocuments);
  };

  useEffect(() => {
    (async () => {
      await handleDataSource();
    })();
  }, [forceRefresh]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: columns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      resource: 'invoices',
      customUrl: `missions/update-mission-costs/${id}/${missionType}`
    });
  }, [editingKey, dataSource]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedDocuments(rowRecords);
    }
  };

  return (
    <ContentCustom>
      <EditableTable
        scroll={{ x: 'max-content' }}
        resource="invoices"
        dataSource={dataSource}
        mergedColumns={mergedColumns}
        setSelectedRowKey={setSelectedRowKey}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        form={form}
        pagination={pagination}
        setPagination={setPagination}
        withoutAddLineButton
        withoutHeader
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
      />
    </ContentCustom>
  );
};

ListInvoices.propTypes = {
  extraQuery: PropTypes.shape({}),
  missionType: PropTypes.string
};

ListInvoices.defaultProps = {
  extraQuery: null,
  missionType: null
};
