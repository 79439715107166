import { createContext, useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Quotation } from '../models/Quotation';
import { Mission } from '../models/Mission';
import { Municipality } from '../models/Municipality';
import { RequestedDocument } from '../models/RequestedDocument';
import { QuoteConfiguration } from '../models/QuoteConfiguration';
import { GetEnums } from '../utils/getEnums';
import { useGlobalContext } from './GlobalContext';

export const QuotationContext = createContext();

export const QuotationContextProvider = ({ children }) => {
  const { syndics, collectiveOwnerships } = useGlobalContext();
  const [form] = Form.useForm();
  const { getEnums } = GetEnums();
  const { pathname } = useLocation();
  const location = useLocation();
  const { state } = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });
  const [current, setCurrent] = useState({ principal: 0, sub: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [quotation, setQuotation] = useState({});
  const [quoteConfiguration, setQuoteConfiguration] = useState([]);
  const [syndic, setSyndic] = useState(null);
  const [syndicOptions, setSyndicOptions] = useState([]);
  const [collectiveOwnership, setCollectiveOwnership] = useState(null);
  const [collectiveOwnershipOptions, setCollectiveOwnershipOptions] = useState(
    []
  );
  const [missions, setMissions] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [quotationActions, setQuotationActions] = useState([]);
  const [quotationActs, setQuotationActs] = useState([]);
  const [quotationActAmounts, setQuotationActAmounts] = useState([]);
  const [isNewSyndicField, setIsNewSyndicField] = useState(false);
  const [isNewCollectiveOwnershipField, setIsNewCollectiveOwnershipField] =
    useState(false);
  const [actionTypes, setActionTypes] = useState({});
  const [enums, setEnums] = useState();
  const [formData, setFormData] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [missionType, setMissionType] = useState(null);
  const [filesList, setFilesList] = useState({});
  const [requestedDocuments, setRequestedDocuments] = useState([]);

  useEffect(() => {
    Quotation.setDynamicFields({
      setSyndicOptions,
      syndics,
      syndic,
      setCollectiveOwnershipOptions,
      collectiveOwnerships
    });
  }, [syndic, syndics, collectiveOwnership, collectiveOwnerships]);

  const getQuotationDatas = async ({ id }) => {
    setIsFieldsLoading(true);
    const [
      missionsDatas,
      municipalityDatas,
      quotationActionEnumsDatas,
      quotationEnumsDatas,
      RequestedDocumentsDatas
    ] = await Promise.all([
      Mission.getResources({}),
      Municipality.getResources({}),
      getEnums({ resource: 'quotation-actions' }),
      getEnums({ resource: 'quotations' }),
      RequestedDocument.getResources({ extraQuery: 'type=PCS_PUBLICATION' })
    ]);

    const quoteConfigurationDatas = await QuoteConfiguration.getResources({
      type: 'PUBLICATION_PCS'
    });
    setQuoteConfiguration(quoteConfigurationDatas);

    if (id) {
      const quotationDatas = await Quotation.getResource({
        id,
        populate:
          'mission,mission.pcajp_arrays,actions,acts,acts.quotation_action,syndic,act_amounts,act_amounts.quotation_acts,act_amounts.quotation_acts.quotation_action'
      });

      setQuotation(quotationDatas);
      setCurrent({
        principal: Quotation.statusSteps.principal[quotationDatas.status],
        sub: Quotation.statusSteps.sub[quotationDatas.step]
      });

      const desiredDocuments = (
        quotationDatas?.desired_documents.documents || []
      ).reduce((obj, key) => {
        const updatedObject = obj;
        updatedObject[key] = true;
        return updatedObject;
      }, {});

      setFormData({
        ...quotationDatas,
        desired_documents: {
          other_documents:
            quotationDatas?.desired_documents.other_documents || [],
          ...desiredDocuments
        },
        actions: Quotation.formatTableDatas(quotationDatas.actions),
        quotation_acts: Quotation.formatTableDatas(quotationDatas.acts),
        quotation_act_amounts: Quotation.formatTableDatas(
          quotationDatas.act_amounts
        )
      });

      setQuotationActions(quotationDatas?.actions || []);
      setQuotationActs(quotationDatas?.acts || []);
      setQuotationActAmounts(quotationDatas?.act_amounts || []);
      setRequestedDocuments(RequestedDocumentsDatas);
    }

    setEnums({
      quotation_action: quotationActionEnumsDatas,
      quotation: quotationEnumsDatas
    });
    setMunicipalities(municipalityDatas);
    setMissions(missionsDatas);

    if (state?.mission) {
      Quotation.setDefaultFieldsValues({
        form,
        mission: state.mission,
        setFormData,
        setSyndic,
        setCollectiveOwnership,
        setMissionType
      });
    }

    setIsFieldsLoading(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  return (
    <QuotationContext.Provider
      value={{
        getQuotationDatas,
        searchValue,
        setPagination,
        pagination,
        current,
        setCurrent,
        isLoading,
        setIsLoading,
        forceRefresh,
        setForceRefresh,
        setQuotation,
        quotation,
        syndic,
        setSyndic,
        syndicOptions,
        setSyndicOptions,
        collectiveOwnership,
        setCollectiveOwnership,
        collectiveOwnershipOptions,
        setCollectiveOwnershipOptions,
        missions,
        setMissions,
        isNewSyndicField,
        setIsNewSyndicField,
        isNewCollectiveOwnershipField,
        setIsNewCollectiveOwnershipField,
        municipalities,
        quotationActions,
        setQuotationActions,
        enums,
        setFormData,
        formData,
        setActionTypes,
        actionTypes,
        form,
        quotationActs,
        setQuotationActs,
        quotationActAmounts,
        setQuotationActAmounts,
        quoteConfiguration,
        isFieldsLoading,
        setMissionType,
        missionType,
        setFilesList,
        filesList,
        requestedDocuments
      }}
    >
      {children}
    </QuotationContext.Provider>
  );
};

export const useQuotationContext = () => {
  const context = useContext(QuotationContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};

QuotationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
