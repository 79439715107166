import { useLocation } from 'react-router-dom';

export const renderMenuItems = (
  routes,
  subRoutes,
  t,
  navMenuLogos,
  NavLink,
  pathSearches,
  textStyle,
  continuePath
) =>
  Object.entries(routes).map(([menuItem, p]) => {
    if (subRoutes[menuItem]) {
      return {
        key: p,
        icon: navMenuLogos[menuItem],
        label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
        children: Object.entries(subRoutes[menuItem]).map(
          ([subMenuItem, subPath]) => ({
            key: subPath,
            disabled: subPath === '',
            label: (
              <NavLink
                to={`${p}${subPath}${
                  pathSearches[subMenuItem] ? pathSearches[subMenuItem] : ''
                }`}
              >
                <span style={textStyle}>
                  {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                </span>
              </NavLink>
            )
          })
        )
      };
    }

    if (continuePath) {
      const location = useLocation().pathname;
      const setPath = () => {
        const paths = [
          '/services/compliance-loi-elan',
          '/services/plans-research',
          '/services/rcp-research'
        ];
        return paths.find((path) => location.includes(path)) || '';
      };
      const prefix = setPath();

      return {
        key: p,
        disabled: p === '',
        icon: navMenuLogos[menuItem],
        label: (
          <NavLink
            to={`${prefix}/${p}${
              pathSearches[menuItem] ? pathSearches[menuItem] : ''
            }`}
          >
            <span style={textStyle}>
              {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
            </span>
          </NavLink>
        )
      };
    }

    return {
      key: p,
      disabled: p === '',
      icon: navMenuLogos[menuItem],
      label: (
        <NavLink
          to={`${p}${pathSearches[menuItem] ? pathSearches[menuItem] : ''}`}
        >
          <span style={textStyle}>
            {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
          </span>
        </NavLink>
      )
    };
  });
