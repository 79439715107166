/**
 * Generates the title for the delete syndic modal based on the provided parameters.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {Array} options.collectiveOwnerships - The array of collective ownerships.
 * @param {Object} options.selectedSyndic - The selected syndic object.
 * @param {Function} options.t - The translation function.
 * @returns {string|null} The generated title for the delete syndic modal.
 */
export const handleDeleteSyndicModalTitle = ({
  collectiveOwnerships,
  selectedSyndic,
  t
}) => {
  let title = null;

  if (collectiveOwnerships.length) {
    title = t('syndics.modals.titles.reassign_collective_ownerships');
  }

  if (selectedSyndic?.managers?.length && !collectiveOwnerships.length) {
    title = t('syndics.modals.titles.reassign_managers');
  }

  if (!selectedSyndic?.managers?.length && !collectiveOwnerships.length) {
    title = t('syndics.modals.titles.remove_syndic');
  }

  return title;
};
