/**
 * Sets the step and mission ID based on the given mission.
 * @function
 * @param {Object} options - The options object.
 * @param {Object} options.mission - The mission object.
 * @returns {Object} - The step and mission ID object.
 */
export const setStepAndMissionId = ({ prev, mission }) => {
  if (mission.type.includes('PCS')) {
    switch (mission.steps.sub) {
      case 'BUILDING_FORM_ORDER':
        return {
          ...prev,
          cerfa_order: {
            open: true,
            missionId: mission._id,
            step: mission.type === 'PCS_1' ? 'BUILDING_FORM_DELIVERY' : ''
          }
        };
      case 'ORDER_VERIFICATION':
        return {
          ...prev,
          cerfa_order: {
            open: true,
            missionId: mission._id,
            step: 'DOCUMENTS_DELIVERY'
          }
        };
      default:
        return {
          ...prev,
          cerfa_order: { open: true, missionId: '', step: '' }
        };
    }
  } else {
    switch (mission.steps.principal) {
      case 'BUILDING_FORM_ORDER':
        return {
          ...prev,
          cerfa_order: {
            open: true,
            missionId: mission._id,
            step: 'BUILDING_FORM_DELIVERY'
          }
        };
      case 'ORDER_VERIFICATION':
        return {
          ...prev,
          cerfa_order: {
            open: true,
            missionId: mission._id,
            step: 'DOCUMENTS_DELIVERY'
          }
        };
      case 'DOCUMENTS_ORDER':
        return {
          ...prev,
          cerfa_order: {
            open: true,
            missionId: mission._id,
            step: 'DOCUMENTS_DELIVERY'
          }
        };
      default:
        return {
          ...prev,
          cerfa_order: {
            open: true,
            missionId: '',
            step: ''
          }
        };
    }
  }
};
