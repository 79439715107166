import { Row, Card, Col, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../components';
import { useInformationsListContent } from './listContent/informations';
import { useNotesListContent } from './listContent/notes';
import { useAttachedProfessionalsListContent } from './listContent/attachedProfessionals';
import { useContactListContent } from './listContent/contact';
import { History } from '../../../../components/History/History';
import { CardStatistics } from './CardStatistics/CardStatistics';

/**
 * The `Informations` component is designed to display a comprehensive overview of various pieces of information related to a syndic entity, including main information, notes, attached professionals, contact details, and more. It presents this information in a structured layout using Ant Design's `Row`, `Col`, and `Card` components.
 *
 * Props:
 * @prop {Object} [syndic={}] - An object containing detailed information about a syndic entity. This data is passed to custom hooks for generating the content of each list.
 * @prop {boolean} [isLoading=true] - A boolean flag indicating whether the syndic data is currently being loaded. When `true`, a loading skeleton is displayed instead of the actual content.
 * @prop {function} t - A translation function provided by `react-i18next` for internationalization, used to translate titles and other UI text.
 *
 * The component utilizes several custom hooks (`useInformationsListContent`, `useNotesListContent`, `useAttachedProfessionalsListContent`, `useContactListContent`) to generate the content for each information section. These hooks structure the data into a format suitable for display in the `DescriptionList` component, which is a custom component for rendering lists of labeled data.
 *
 * The UI is divided into multiple sections, each contained within a `Card`. The `Skeleton` component from Ant Design is used to provide a placeholder loading state while the data is being fetched. Each section is given a title corresponding to the type of information it displays, which is internationalized using the `t` function.
 *
 * Usage:
 * This component is intended for use in a detailed view page where a complete overview of syndic information needs to be presented. It offers a clear and organized display of various data points associated with a syndic, enhancing the user's ability to comprehend and analyze the information.
 */

export const Informations = ({ syndic, isLoading, t }) => {
  const informationsListContent = useInformationsListContent(syndic);
  const notesListContent = useNotesListContent(syndic);
  const attachedProfessionalsListContent =
    useAttachedProfessionalsListContent(syndic);
  const contactListContent = useContactListContent(syndic);
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card>
          <Skeleton loading={isLoading} active>
            <CardStatistics syndicId={syndic?._id} />
          </Skeleton>
        </Card>
      </Col>

      <Col sm={24} xs={24} md={24} lg={13}>
        <Card
          title={t('syndics.show.cards.titles.main_informations')}
          style={{ overflow: 'hidden' }}
        >
          <Skeleton loading={isLoading} active>
            <DescriptionList
              data={informationsListContent}
              translate
              vertical
            />
          </Skeleton>
        </Card>
      </Col>

      <Col sm={24} xs={24} md={24} lg={11}>
        <Card title={t('syndics.show.cards.titles.notes')}>
          <Skeleton loading={isLoading} active>
            <DescriptionList data={notesListContent} translate vertical />
          </Skeleton>
        </Card>
      </Col>

      <Col sm={24} xs={24} md={24} lg={13}>
        <Card title={t('syndics.show.cards.titles.attached_professionals')}>
          <Skeleton loading={isLoading} active>
            <DescriptionList
              data={attachedProfessionalsListContent}
              translate
              vertical
            />
          </Skeleton>
        </Card>
      </Col>

      <Col sm={24} xs={24} md={24} lg={11}>
        <History isLoading={isLoading} historic={syndic.historic} />
      </Col>

      <Col sm={24} xs={24} md={24} lg={13}>
        <Card title={t('syndics.show.cards.titles.principal_contact')}>
          <Skeleton loading={isLoading} active>
            <DescriptionList data={contactListContent} translate vertical />
          </Skeleton>
        </Card>
      </Col>
    </Row>
  );
};

Informations.propTypes = {
  syndic: PropTypes.shape({
    _id: PropTypes.string,
    historic: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired
};

Informations.defaultProps = {
  syndic: {},
  isLoading: true
};
