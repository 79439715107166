import { Modal, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Collection } from '../../../models/Collection';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * ValidateDeleteModal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isValidateDeleteModalOpen - Flag indicating if the reassign modal is open.
 * @param {function} props.setIsValidateDeleteModalOpen - Function to set the state of the reassign modal.
 * @param {Array} props.selectedCollectiveOwnerships - Array of selected collective ownerships.
 * @param {Array} props.syndics - Array of syndics.
 * @param {function} props.setForceRefresh - Function to set the state of force refresh.
 * @param {boolean} props.forceRefresh - Flag indicating if a force refresh is needed.
 * @param {function} props.setSelectedRowKeys - Function to set the state of selected row keys.
 * @param {function} props.setSelectedCollectiveOwnerships - Function to set the state of selected collective ownerships.
 * @returns {JSX.Element} The ValidateDeleteModal component.
 */

export const ValidateDeleteModal = ({
  isValidateDeleteModalOpen,
  setIsValidateDeleteModalOpen,
  selectedCollectiveOwnerships,
  setForceRefresh,
  forceRefresh,
  setSelectedRowKeys,
  setSelectedCollectiveOwnerships
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  return (
    <Modal
      title={t(
        `pcs-pre-productions.modals.titles.collective_ownerships_${isValidateDeleteModalOpen.type}`
      )}
      open={isValidateDeleteModalOpen}
      onOk={() => {
        setIsValidateDeleteModalOpen({ open: false, type: '' });
        setSelectedCollectiveOwnerships([]);
        setSelectedRowKeys([]);
      }}
      onCancel={() => {
        setIsValidateDeleteModalOpen({ open: false, type: '' });
        setSelectedCollectiveOwnerships([]);
        setSelectedRowKeys([]);
      }}
      footer={null}
    >
      <Row gutter={[16, 16]} justify="end">
        <Col>
          <Button
            onClick={() => {
              setIsValidateDeleteModalOpen({ open: false, type: '' });
              setSelectedCollectiveOwnerships([]);
              setSelectedRowKeys([]);
            }}
          >
            {t('buttons.no')}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() =>
              Collection.updateStatus({
                values: selectedCollectiveOwnerships,
                extraQuery: `status=${isValidateDeleteModalOpen.type}`,
                setForceRefresh,
                forceRefresh,
                setSelectedRowKeys,
                setSelectedCollectiveOwnerships,
                setIsValidateDeleteModalOpen,
                user
              })
            }
          >
            {t('buttons.yes')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

ValidateDeleteModal.propTypes = {
  isValidateDeleteModalOpen: PropTypes.bool.isRequired,
  setIsValidateDeleteModalOpen: PropTypes.func.isRequired,
  selectedCollectiveOwnerships: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  setSelectedRowKeys: PropTypes.func.isRequired,
  setSelectedCollectiveOwnerships: PropTypes.func.isRequired
};

ValidateDeleteModal.defaultProps = {
  forceRefresh: false
};
