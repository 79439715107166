import dayjs from 'dayjs';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';

/**
 * Custom hook that returns an object with configuration options for resource operations.
 * @hook
 * @param {string} status - The status of the resource.
 * @param {string} requested_mission_type - The requested mission type of the resource.
 * @returns {Object} - An object with configuration options for resource operations.
 */
export const useConfig = (status, requested_mission_type) => {
  const { currentSyndic } = useGlobalContext();
  return {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        add_collective_ownership: !!data.new_collective_ownership,
        new_collective_ownership: {
          ...data.new_collective_ownership,
          AG_date: dayjs(data.AG_date)
        }
      })
    },
    onCreateResource: {
      setBody: (values) => ({
        ...values,
        status,
        requested_mission_type,
        syndic: currentSyndic,
        new_collective_ownership: values.add_collective_ownership
          ? values.new_collective_ownership
          : null
      })
    },
    onUpdateResource: {
      setBody: (values) => ({
        ...values,
        status,
        requested_mission_type,
        syndic: currentSyndic,
        new_collective_ownership: values.add_collective_ownership
          ? values.new_collective_ownership
          : null
      })
    }
  };
};
