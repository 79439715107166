import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PageHeaderCustom,
  ContentCustom,
  ListResource
} from '../../../../../components';
import { useStockCerfaColumns } from './columns';
import { GetEnums } from '../../../../../utils/getEnums';
import { transformExtraQuery } from '../../../../../utils/filtersUtils';
import AdditionalSearchBar from './AdditionalSearchBar';
import ExtraButtons from './ExtraButtons';
import { CerfaOrderDrawer } from '../../../../orders/CerfaOrderDrawer';

/**
 * ListStockCerfa is a React component that displays a list of stock cerfa records
 * in a table format with various customization options. It allows for filtering,
 * selection, and batch actions on the records. The component uses Ant Design's
 * Table and related components to manage and display the data.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered component.
 */
const ListStockCerfa = () => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedStockDoc, setSelectedStockDoc] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isShowExtraButtons, setIsShowExtraButtons] = useState(false);
  const [enums, setEnums] = useState([]);
  const [filters, setFilters] = useState({
    status: 'TO_BE_ORDERED',
    'mission.type': 'PCS_1'
  });
  const [extraQuery, setExtraQuery] = useState(transformExtraQuery(filters));
  const { getEnums } = GetEnums();
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useStockCerfaColumns(setForceRefresh);
  const [isCerfaOrderDrawerOpen, setIsCerfaOrderDrawerOpen] = useState(false);
  const [cheques, setCheques] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedStockDoc(rowRecords);
    }
  };

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setIsShowExtraButtons(true);
    } else {
      setIsShowExtraButtons(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const enumsData = await getEnums({ resource: 'orders' });
      setEnums(enumsData.stock_cerfa_status);
      setIsDataLoading(false);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <>
      <PageHeaderCustom title={t('dashboard.cerfa_stock.titles.cerfa_stock')} />
      <ContentCustom>
        <ListResource
          style={{ padding: 0 }}
          resourceName="orders"
          resourceModelName="Order"
          columns={columns}
          forceRefresh={forceRefresh}
          withCreateButton={false}
          withUploadButton
          customActionColumn
          withPageHeader={false}
          withSearchBar
          scroll={{ x: 'max-content' }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          extraQuery={extraQuery}
          searchBarExtraButtons={
            <AdditionalSearchBar
              setFilters={setFilters}
              enums={enums}
              isDataLoading={isDataLoading}
            />
          }
        />
        <ExtraButtons
          setForceRefresh={setForceRefresh}
          isShowExtraButtons={isShowExtraButtons}
          selectedStockDoc={selectedStockDoc}
          setIsCerfaOrderDrawerOpen={setIsCerfaOrderDrawerOpen}
          setCheques={setCheques}
          selectedOrders={selectedStockDoc}
        />
      </ContentCustom>
      <CerfaOrderDrawer
        cheques={cheques}
        isCerfaOrderDrawerOpen={isCerfaOrderDrawerOpen}
        setIsCerfaOrderDrawerOpen={setIsCerfaOrderDrawerOpen}
        setForceRefresh={setForceRefresh}
        orders={selectedStockDoc}
      />
    </>
  );
};

export default ListStockCerfa;
