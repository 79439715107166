import propTypes from 'prop-types';
import { CollapseMissionHistory } from '../../../../../components/History/CollapseHistoryMission';

/**
 * Renders a card component for mission monitoring.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.mission - The mission object.
 * @param {boolean} props.isLoading - Indicates if the data is currently loading.
 * @returns {JSX.Element} The rendered MissionMonitoringCard component.
 */
export const MissionMonitoringCard = ({ mission, isLoading }) => (
  <CollapseMissionHistory
    isLoading={isLoading}
    historic={mission?.history}
    missionMode
    title="missions.cards.history.title"
  />
);

MissionMonitoringCard.propTypes = {
  mission: propTypes.objectOf({}).isRequired,
  isLoading: propTypes.bool.isRequired
};
