import React from 'react';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns an array of fields for documents to be rewritten.
 *
 * @hook
 * @returns {Object} An object containing the fields array.
 */
export const useFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      noLabel: true,
      name: ['no_documents_to_be_re_written'],
      key: 'no_documents_to_be_re_written',
      valuePropName: 'checked',
      input: (
        <Checkbox>{t('missions.form.no_documents_to_be_re_written')}</Checkbox>
      )
    },
    {
      label: 'documents_to_be_re_written',
      name: ['documents_to_be_re_written.documents'],
      key: 'documents_to_be_re_written',
      maxFilesCount: 100,
      input: 'File',
      requestedDocumentType: 'Documents à retaper',
      reWrittenDocumentType: 'word'
    },
    {
      label: 'charging_grid_to_be_re_written',
      name: ['charging_grid_to_be_re_written.documents'],
      key: 'charging_grid_to_be_re_written',
      maxFilesCount: 100,
      input: 'File',
      requestedDocumentType: 'Documents à retaper',
      reWrittenDocumentType: 'excel'
    }
  ];

  return { fields };
};
