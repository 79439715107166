import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ServicesCustomContent } from '../../services/ServicesCustomContent';
import Missions_RCP_ELAN from '../../../../assets/images/Missions_RCP_ELAN.svg';
import { dashboardTitle } from '../../services/ComplianceElanRouter/dashboardTitles';
import { ListResource } from '../../../../components';
import { useColumns } from './useColumns';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { CreateManagerDrawer } from '../ManagersKanban/CreateManagerDrager.jsx/CreateManagerDrawer';

/**
 * @component
 * Represents the ManageTeam component.
 * This component is responsible for managing the team of managers in the application.
 * It displays a list of managers and provides functionality to create new managers.
 *
 * @returns {JSX.Element} The ManageTeam component.
 */
export const ManageTeam = () => {
  const { manageTeamTitle, manageTeamSubtitle } = dashboardTitle();
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { currentSyndic } = useGlobalContext();
  const { columns } = useColumns({
    setForceRefresh,
    forceRefresh
  });

  const createManagerButton = (
    <Button type="primary" onClick={() => setIsDrawerOpen(true)}>
      <PlusOutlined />
      {t('buttons.create-manager')}
    </Button>
  );

  return (
    <ServicesCustomContent
      title={manageTeamTitle}
      subtitle={manageTeamSubtitle}
      imageOnRight={Missions_RCP_ELAN}
      cardStyle={{ backgroundColor: '#222222' }}
      containerStyle={{ width: '100%' }}
    >
      <Row style={{ width: '100%' }}>
        <ListResource
          withPageHeader={false}
          extraQuery={`syndic=${currentSyndic}&role=users:SYNDIC_MANAGER,users:SYNDIC_ASSISTANT&fields=_id,last_name,first_name,role,email,phone_number`}
          resourceName="syndic-collective-ownerships/syndicUsers"
          resourceModelName="User"
          customActionColumn
          withCreateButton={false}
          columns={columns}
          forceRefresh={forceRefresh}
          extraButtons={createManagerButton}
        />
      </Row>
      <CreateManagerDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </ServicesCustomContent>
  );
};
