import { Route, Routes } from 'react-router-dom';
import { InvoiceRouter } from './invoices/InvoiceRouter';
import { Exception } from '../../components';
import { ListPaymentFollowUps } from './payment-follow-ups/ListPaymentFollowUps';
import { ListTreasuryPublications } from './treasury-publications/ListTreasuryPublications';
import { AccountantExports } from './accountant-exports/AccountantExports';

/**
 * Renders the Billing and Accounting Router component.
 * @returns {JSX.Element} The rendered Billing and Accounting Router component.
 */
export const BillingAndAccountingRouter = () => (
  <Routes>
    <Route path="/invoices/*" element={<InvoiceRouter />} />
    <Route path="/invoices-reminders/*" element={<ListPaymentFollowUps />} />;
    <Route
      path="/treasury-publications/*"
      element={<ListTreasuryPublications />}
    />
    ;
    <Route path="/invoices-reminders/*" element={<ListPaymentFollowUps />} />
    <Route path="/accountant-exports" element={<AccountantExports />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
