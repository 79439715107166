import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Row, Col } from 'antd';
import { QuotationAction } from '../../../models/QuotationAction';
import { useQuotationContext } from '../../../contexts/QuotationContext';

/**
 * Component for creating a lot with pieces in a quotation action.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.recordId - The ID of the record.
 * @returns {JSX.Element} The rendered component.
 */
export const LotCreationPcs = ({ recordId }) => {
  const { t } = useTranslation();
  const { form } = useQuotationContext();

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('quotation-actions.form.created_lot_number')}
              name={['actions', recordId, 'data', 'created_lot_number']}
              wrapperCol={{ span: 10 }}
            >
              <InputNumber
                onChange={(value) =>
                  QuotationAction.handleInputNumberChange({
                    value,
                    path: ['actions', recordId, 'data', 'created_lot_ref'],
                    form
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.List name={['actions', recordId, 'data', 'created_lot_ref']}>
              {(listFields) => (
                <>
                  {listFields.map(({ key, name, ...restField }) => (
                    <Form.Item
                      {...restField}
                      key={key}
                      name={[name]}
                      wrapperCol={{ span: 10 }}
                    >
                      <Input />
                    </Form.Item>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Form.Item name={['actions', recordId, 'data', 'observation']}>
          <Input
            placeholder={t('placeholder.observation')}
            style={{ width: '350px' }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

LotCreationPcs.propTypes = {
  recordId: PropTypes.string.isRequired
};
