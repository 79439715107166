import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ContentCustom,
  ListResource,
  PageHeaderCustom
} from '../../../components';
import { useColumns } from './columns';
import { SearchsBar } from './SearchsBar';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { CollectiveOwnership } from '../../../models/CollectiveOwnership';
import { GetEnums } from '../../../utils/getEnums';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import { ExtraButtons } from './ExtraButtons';
import { ExpandedInvoiceTable } from './expandableList/ExpandedInvoiceTable';
import { getConsultantFilter } from '../../../utils/updateFilter';

/**
 * Renders a list of invoices.
 * This component fetches the list of invoices and renders them in a table.
 * It also provides a search bar to filter the invoices by different parameters.
 *
 * @component
 * @returns {JSX.Element} The list of invoices component.
 */
export const ListInvoices = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const { state } = useLocation();
  const { syndics } = useGlobalContext();
  const [filters, setFilters] = useState(getConsultantFilter());
  const [enums, setEnums] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedSyndic, setSelectedSyndic] = useState('');
  const [extraQuery, setExtraQuery] = useState(transformExtraQuery(filters));
  const [selectedRowKeys, setSelectedRowKeys] = useState({
    key: 'key',
    rowKeys: []
  });
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isShowActionsButtons, setIsShowActionsButtons] = useState(false);
  const { getEnums } = GetEnums();

  const rowSelection = {
    selectedRowKeys: selectedRowKeys.rowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys({ key: 'key', rowKeys });
      const table_data = rowRecords.map((record) => record.table_data).flat();
      const invoices = table_data.filter((invoice) => invoice._id);
      setSelectedInvoices(invoices);
    }
  };

  const expandedRowRender = useCallback((record) => {
    const tableData = record?.table_data;
    return <ExpandedInvoiceTable datasource={tableData} />;
  }, []);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const data = await CollectiveOwnership.getResources({
        populate: '',
        extraQuery: ''
      });
      const enumsData = await getEnums({ resource: 'invoices' });
      setEnums(enumsData);
      setCollectiveOwnerships(data);
      setIsDataLoading(false);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setIsShowActionsButtons(true);
    } else {
      setIsShowActionsButtons(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    (async () => {
      if (selectedSyndic !== '' && selectedSyndic !== undefined) {
        const data = await CollectiveOwnership.getResources({
          populate: '',
          extraQuery: `syndic._id=${selectedSyndic}`
        });
        setCollectiveOwnerships(data);
      }
    })();
  }, [selectedSyndic]);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <>
      <PageHeaderCustom title={t('invoices.titles.invoices')} />
      <ContentCustom>
        <ListResource
          style={{ margin: '0' }}
          resourceName="invoices"
          resourceModelName="Invoice"
          columns={columns}
          forceRefresh={forceRefresh}
          extraQuery={`${
            state?.missionId ? `mission=${state.missionId}` : extraQuery
          }&showMission=${false}`}
          withCreateButton={false}
          withUploadButton
          customActionColumn
          withPageHeader={false}
          withSearchBar
          scroll={{ x: 'max-content' }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => record?.table_data?.length > 0
          }}
          searchBarExtraButtons={
            <SearchsBar
              setFilters={setFilters}
              collectiveOwnerships={collectiveOwnerships}
              syndics={syndics}
              setSelectedSyndic={setSelectedSyndic}
              isDataLoading={isDataLoading}
              setForceRefresh={setForceRefresh}
              enums={enums}
              extraQuery={extraQuery}
              selectedRowKeys={selectedRowKeys}
            />
          }
        />
        <ExtraButtons
          setForceRefresh={setForceRefresh}
          isShowActionsButtons={isShowActionsButtons}
          selectedInvoices={selectedInvoices}
        />
      </ContentCustom>
    </>
  );
};
