import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Divider, Checkbox, Tag, Upload, Tooltip, Button } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  PrinterOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { EditableTableMethods } from '../../../../../../../components/editableTableComponents/methods';
import { useDownloadDocument } from '../../../../../../../utils/downloadDoc';
import { useMissionContext } from '../../../../../../../contexts/MissionContext';
import { setDocumentIcon } from '../../../../../../../components/setDocumentIcon';
import { documentTag } from '../../../../../../../utils/constants/tagColors';

const { Dragger } = Upload;

/**
 * Custom hook that returns the columns configuration for the "toBeOrdered" and "ordered" tables in the missions view.
 * @hook
 * @param {Object} enums - An object containing enums used in the component.
 * @returns {Object} - An object containing the columns configuration for the "toBeOrdered" and "ordered" tables.
 */
export const useColumns = (enums) => {
  const { t } = useTranslation();
  const { id: missionId } = useParams();
  const { forceRefresh, setForceRefresh, mission } = useMissionContext();
  const { handleCheckbox } = EditableTableMethods();
  const { viewDocument, downloadDocument } = useDownloadDocument();

  const draggerProps = ({ id }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      await Document.uploadDocument({
        id,
        file: objectFile.file,
        extraQuery: `?resource=orders&missionId=${missionId}&step=${mission?.steps.sub}`,
        setForceRefresh,
        t
      });
    },
    fileList: []
  });

  const toBeOrderedColumns = [
    {
      title: t('orders.form.cerfa_document_type'),
      key: 'cerfa_document_type',
      dataIndex: 'cerfa_document_type',
      render: (cerfa_document_type) => cerfa_document_type?.wording || '-',
      sorter: true
    },
    {
      title: t('orders.form.ref_doc'),
      key: 'ref_doc',
      dataIndex: 'ref_doc',
      render: (ref_doc) => ref_doc || '-',
      sorter: true
    },
    {
      title: t('orders.form.added_date'),
      key: 'added_date',
      dataIndex: 'added_date',
      render: (added_date) =>
        added_date ? dayjs(added_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('orders.form.expeditor'),
      key: 'expeditor',
      dataIndex: 'expeditor',
      render: (expeditor) => expeditor?.office_name || '-',
      sorter: true
    },
    {
      title: t('orders.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (costs) => (costs ? `${costs} €` : '0 €'),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: () => (
        <>
          <EyeOutlined style={{ fontSize: 16 }} />

          <Divider type="vertical" />
          <PrinterOutlined style={{ fontSize: 16 }} />
        </>
      )
    }
  ];

  const orderedColumns = [
    {
      title: t('orders.form.cerfa_document_type'),
      key: 'cerfa_document_type',
      dataIndex: 'cerfa_document_type',
      render: (cerfa_document_type) => cerfa_document_type?.wording || '-',
      sorter: true
    },
    {
      title: t('orders.form.urgent'),
      key: 'urgent',
      dataIndex: 'urgent',
      render: (_, record) => (
        <Checkbox
          defaultChecked={record.urgent}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'urgent',
              resource: 'orders',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('orders.form.ref_doc'),
      key: 'ref_doc',
      dataIndex: 'ref_doc',
      render: (ref_doc) => ref_doc || '-',
      sorter: true
    },
    {
      title: t('orders.form.file'),
      key: 'file',
      render: (record) => (
        <Dragger {...draggerProps({ id: record.document._id })}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      key: 'files',
      dataIndex: 'document',
      render: (document) =>
        (document?.files || []).map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        )),

      sorter: true
    },
    {
      title: t('orders.form.status'),
      key: 'document',
      dataIndex: 'document',
      render: (document) =>
        document?.status ? (
          <Tag color={documentTag[document?.status]}>
            {t(`missions.tags.${document?.status}`, {
              defaultValue: document?.status
            })}
          </Tag>
        ) : (
          '-'
        ),
      sorter: true,
      filters: (enums?.status || []).map((r) => ({
        text: t(`missions.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('orders.form.added_date'),
      key: 'added_date',
      dataIndex: 'added_date',
      render: (added_date) =>
        added_date ? dayjs(added_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('orders.form.reception_date'),
      key: 'reception_date',
      dataIndex: 'reception_date',
      render: (reception_date) =>
        reception_date ? dayjs(reception_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('orders.form.expeditor'),
      key: 'expeditor',
      dataIndex: 'expeditor',
      render: (expeditor) => expeditor?.office_name || '-',
      sorter: true
    },
    {
      title: t('orders.form.cerfa_number'),
      key: 'cerfa_number',
      dataIndex: 'cerfa_number',
      render: (cerfa_number) => cerfa_number || '-',
      sorter: true
    },
    {
      title: t('orders.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (costs) => (costs ? `${costs} €` : '-'),
      sorter: true
    },
    {
      title: t('orders.form.cheque'),
      key: 'cheque_number',
      dataIndex: 'cheque_number',
      render: (cheque_number) => cheque_number || '-',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      //! TODO: Implement the edit, download, print and delete functionalities
      render: () => (
        <>
          <EyeOutlined style={{ fontSize: 16 }} />
          <Divider type="vertical" />
          <EditOutlined style={{ fontSize: 16 }} />
          <Divider type="vertical" />
          <DownloadOutlined style={{ fontSize: 16 }} />
          <Divider type="vertical" />
          <PrinterOutlined style={{ fontSize: 16 }} />
          <Divider type="vertical" />
          <DeleteOutlined style={{ fontSize: 16 }} />
        </>
      )
    }
  ];

  return { toBeOrderedColumns, orderedColumns };
};
