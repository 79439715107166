import { useTranslation } from 'react-i18next';
import { Flex, Switch, Typography } from 'antd';
import { Hourglass, SendAlt } from '@carbon/icons-react';
import PropTypes from 'prop-types';
import { adminTheme } from '../../utils/constants/colors';

/**
 * SwitchBar component for toggling between showing pending and ongoing orders.
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.showOrdersOrdered - Indicates whether to show ordered orders.
 * @param {Function} props.toggleShowOrders - Function to toggle the showOrdersOrdered state.
 *
 * @returns {JSX.Element} The rendered SwitchBar component.
 */
export const SwitchBar = ({ showOrdersOrdered, toggleShowOrders }) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <Flex align="center" style={{ minWidth: '50%', marginBottom: 16 }}>
      <Flex align="center">
        <Hourglass color={!showOrdersOrdered && adminTheme.secondaryColor} />
        <Text>{t('orders.labels.pending')}</Text>
      </Flex>
      <Flex align="center">
        <Switch
          style={{ marginLeft: 4, marginRight: 4 }}
          checked={showOrdersOrdered}
          onChange={toggleShowOrders}
        />
        <SendAlt color={showOrdersOrdered && adminTheme.secondaryColor} />
      </Flex>
      <Text>{t('orders.labels.ongoing')}</Text>
    </Flex>
  );
};

SwitchBar.propTypes = {
  showOrdersOrdered: PropTypes.bool,
  toggleShowOrders: PropTypes.func
};

SwitchBar.defaultProps = {
  showOrdersOrdered: false,
  toggleShowOrders: null
};
