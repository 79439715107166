import { DatePicker, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

/**
 * Custom hook that returns an object containing the fields for the intern appointment form.
 * @hook
 * @returns {Object} The fields object.
 */
export const useInternAppointmentFields = () => {
  const { t } = useTranslation();
  const fields = [
    {
      name: ['title'],
      key: 'title',
      input: (
        <Input style={{ width: 200 }} placeholder={t('events.form.title')} />
      )
    },
    {
      name: ['date'],
      key: 'date',
      rules: [{ required: true }, { type: String }],
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      name: ['time_slots'],
      key: 'time_slots',
      rules: [{ required: true }, { type: Object }],
      input: (
        <RangePicker
          showTime={{ format: 'HH:mm' }}
          picker="time"
          onOk={(value) => value}
        />
      )
    },
    {
      name: ['remote_connection_support'],
      key: 'remote_connection_support'
    }
  ];

  return { fields };
};
