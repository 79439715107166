import { Cheque } from '../routes/orders/models/Cheque';

/**
 * Generates CERFA documents and groups orders into cheques.
 * The orders are first sorted by `concerned_SPF._id`, then by `cerfa_number` within each group.
 * @function
 * @param {Array} orders - The array of order objects to be processed. Each order must have the properties `concerned_SPF._id` and `cerfa_number`.
 * @returns {Array} The array of cheques with sorted and grouped orders.
 */
export const generateCerfa = (orders) => {
  /**
   * Sorts and groups an array of objects by the properties `concerned_SPF._id` and `cerfa_number`.
   * The objects are first sorted by `concerned_SPF._id`, then by `cerfa_number` within each group.
   *
   * @param {Array} array - The array of objects to be sorted and grouped. Each object must have the properties `concerned_SPF._id` and `cerfa_number`.
   * @param {Object} array[].concerned_SPF - The SPF object containing the identifier.
   * @param {string} array[].concerned_SPF._id - The identifier of the SPF object used for sorting.
   * @param {string} array[].cerfa_number - The Cerfa number used for secondary sorting.
   * @returns {Array} The array sorted and grouped by `concerned_SPF._id` and then by `cerfa_number`.
   */
  const sortAndGroup = (array) =>
    array.sort((a, b) => {
      if (a.concerned_SPF._id === b.concerned_SPF._id) {
        return a.cerfa_number.localeCompare(b.cerfa_number);
      }
      return a.concerned_SPF._id.localeCompare(b.concerned_SPF._id);
    });

  const sortedOrders = sortAndGroup(orders);

  let currentCheque = null;
  const cheques = [];

  sortedOrders.forEach((order, index) => {
    // On crée un nouveau chèque et un nouveau CERFA pour la première ligne
    if (!currentCheque) {
      currentCheque = new Cheque();
      currentCheque.currentCerfa = Cheque.getNewCerfa(order.cerfa_number);
    }
    // On tente d'ajouter la ligne au CERFA
    const resultAddLine = currentCheque.currentCerfa.addLine(
      order,
      currentCheque
    );
    // Si la ligne n'a pas pu être ajoutée, on analyse les différents cas
    if (resultAddLine !== true) {
      switch (resultAddLine) {
        case 'change cerfa':
          // On sauvegarde le CERFA courant dans le chèque
          currentCheque.cerfas.push(currentCheque.currentCerfa);
          // On en crée un nouveau et on change de CERFA
          currentCheque.currentCerfa = Cheque.getNewCerfa(order.cerfa_number);
          // On met la ligne qu'on voulait mettre au début dans le nouveau CERFA
          currentCheque.currentCerfa.addLine(order, currentCheque);
          break;
        case 'change cerfa and cheque':
          // On sauvegarde le CERFA courant dans le chèque précédent
          currentCheque.cerfas.push(currentCheque.currentCerfa);
          // On sauvegarde le chèque précédent
          cheques.push(currentCheque);
          // On crée un nouveau chèque
          currentCheque = new Cheque();
          // Dans ce nouveau chèque, on crée un nouveau CERFA
          currentCheque.currentCerfa = Cheque.getNewCerfa(order.cerfa_number);
          // On met la ligne qu'on voulait mettre au début dans le nouveau CERFA
          currentCheque.currentCerfa.addLine(order, currentCheque);
          break;
        default:
          break;
      }
    }
    if (sortedOrders.length - 1 === index) {
      // Si on arrive à la dernière ligne
      // On sauvegarde le CERFA courant dans le chèque actuel
      currentCheque.cerfas.push(currentCheque.currentCerfa);
      // On sauvegarde le chèque actuel
      cheques.push(currentCheque);
    }
  });
  return cheques;
};
