import { Tabs } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useItems } from './useItems';

/**
 * Renders the NotaryConfigurations component.
 * @component
 * @returns {JSX.Element} The rendered NotaryConfigurations component.
 */
export const NotaryConfigurations = () => {
  const { items } = useItems();

  return (
    <>
      <PageHeaderCustom title="Configuration - Notaires" />
      <ContentCustom>
        <Tabs tabPosition="left" items={items} />
      </ContentCustom>
    </>
  );
};
