import { Modal, Row, Col, Typography, Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { Mission } from '../../../../../models/Mission';

const { Text } = Typography;

/**
 * Renders a modal component for canceling a mission.
 *
 * @component
 * @returns {JSX.Element} The cancel mission modal component.
 */
export const CancelMissionModal = () => {
  const { isDrawerOpen, setIsDrawerOpen, invoices, mission } =
    useMissionContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { message } = useErrorMessage;

  return (
    <Modal
      title={t('missions.modal.titles.cancel_mission')}
      onCancel={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          cancel_mission: false
        }))
      }
      open={isDrawerOpen.cancel_mission}
      footer={null}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Text>{t('missions.modal.messages.cancel_mission')}</Text>
        </Col>
      </Row>
      <Row justify="end">
        <Button
          style={{ margin: '0 8px' }}
          type="link"
          danger
          onClick={() => {
            setIsDrawerOpen((prev) => ({
              ...prev,
              cancel_mission: false
            }));
          }}
        >
          {`${t('buttons.cancel')} `}
          <CloseOutlined />
        </Button>
        <Button
          type="add"
          onClick={() =>
            Mission.cancelMission({ id, message, navigate, invoices, mission })
          }
        >
          {`${t('buttons.confirm')} `}
          <CheckOutlined />
        </Button>
      </Row>
    </Modal>
  );
};
