import { message as antdMessage } from 'antd';
import { handleSave } from './handleSave';

export const updateSelectedRowKey = ({
  keyChange,
  selectedRowKey,
  dataSource,
  setSelectedRowKey
}) => {
  const newSelectedRowKey = selectedRowKey + keyChange;
  if (newSelectedRowKey >= 0 && newSelectedRowKey < dataSource.length) {
    setSelectedRowKey(newSelectedRowKey);
  }
};

export const generalHandleKeyPress =
  ({
    selectedRowKey,
    setRowEditing,
    setEditKey,
    setSelectedRowKey,
    rowEditing,
    dataSource,
    chargingGrid,
    dispatchAPI,
    setChargingGrid,
    message,
    form,
    setIsFinalVersion,
    resourceDatas,
    setResourceDatas,
    handleEditAllTableButtonRef,
    t
  }) =>
  (e) => {
    if (e.key === 'Escape') {
      if (selectedRowKey) {
        setRowEditing(false);
        setEditKey(undefined);
        setSelectedRowKey(null);
      }
      return;
    }

    const isArrowKey = e.key === 'ArrowUp' || e.key === 'ArrowDown';
    const isRowSelected = selectedRowKey !== null;

    if (rowEditing && isRowSelected) {
      if (e.key === 'Enter' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        setRowEditing(false);
        setEditKey(undefined);
        handleSave({
          key: dataSource[selectedRowKey].key,
          dataSource,
          chargingGrid,
          dispatchAPI,
          setChargingGrid,
          message,
          tableForm: form,
          setIsFinalVersion,
          setEditKey,
          resourceDatas,
          setResourceDatas
        });

        let newSelectedRowKey = selectedRowKey;

        if (e.key === 'ArrowUp' && selectedRowKey > 0) {
          newSelectedRowKey = selectedRowKey - 1;
        } else if (
          e.key === 'ArrowDown' &&
          dataSource.length - selectedRowKey > 1
        ) {
          newSelectedRowKey = selectedRowKey + 1;
        }

        setSelectedRowKey(newSelectedRowKey);
      }
    } else if (!rowEditing && isRowSelected) {
      if (isArrowKey) {
        updateSelectedRowKey({
          keyChange: e.key === 'ArrowUp' ? -1 : 1,
          selectedRowKey,
          dataSource,
          setSelectedRowKey
        });
      }
      if (e.key === 'Enter' && selectedRowKey !== null) {
        if (dataSource[selectedRowKey].Lot.value === 'Total') {
          antdMessage.warning(
            t('charging-grids.show.messages.edit_total_not_allowed')
          );
          return;
        }

        setEditKey(dataSource[selectedRowKey].key);

        const transformedRecord = {};

        Object.keys(dataSource[selectedRowKey]).forEach((key) => {
          const value = dataSource[selectedRowKey][key];

          if (typeof value === 'object' && 'value' in value) {
            transformedRecord[key] = value.value;
          } else {
            transformedRecord[key] = value;
          }
        });

        setRowEditing(!rowEditing);
        form.setFieldsValue({ ...transformedRecord });
      }
    }

    if (e.key === 'e' && selectedRowKey === null) {
      e.preventDefault();
      handleEditAllTableButtonRef.current?.click();
    }
  };
