/**
 * Fetches enums data from the server and sets it using the provided setEnums function.
 * @async
 * @function
 *
 * @param {Object} options - The options object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function for making API requests.
 * @param {Function} options.setEnums - The function to set the fetched enums data.
 * @param {Function} options.message - The function to display error messages.
 * @returns {Promise<void>} - A promise that resolves when the enums data is fetched and set successfully.
 */
export const getEnums = async ({ dispatchAPI, setEnums, message }) => {
  try {
    const { data: collectiveData } = await dispatchAPI('GET', {
      url: '/collective-ownerships/enums'
    });

    const { data: missionData } = await dispatchAPI('GET', {
      url: '/missions/enums'
    });

    const { data: municipalitiesData } = await dispatchAPI('GET', {
      url: '/municipalities'
    });

    const cities = municipalitiesData.map((city) => city);

    setEnums({
      ...collectiveData,
      ...missionData,
      collectivestatus: collectiveData.status,
      cities
    });
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
