import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

/**
 * Custom hook that returns an object containing the fields configuration.
 *
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setEditorState - The function to set the editor state.
 * @returns {Object} - The fields configuration object.
 */
export const useFields = ({ setEditorState, editorState }) => {
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image'],
      ['clean']
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };

  const fields = [
    {
      name: ['label'],
      key: 'label',
      rules: [{ required: true }]
    },
    {
      name: ['type'],
      key: 'type'
    },
    {
      name: ['message'],
      input: (
        <ReactQuill
          theme="snow"
          modules={modules}
          value={editorState}
          onChange={(value) => setEditorState(value)}
        />
      )
    }
  ];

  return { fields };
};
