import PropTypes from 'prop-types';
import { ListResource } from '../../../components';
import { useColumns } from './useColumns';

/**
 * Renders a list of syndic requests.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.requested_mission_type - The requested mission types.
 * @returns {JSX.Element} The rendered component.
 */
export const ListSpecificSyndicRequest = ({ missionType }) => {
  const fieldsToFetch = [
    'type',
    '_id',
    'name',
    'updated_at',
    'status',
    'syndic',
    'collective_ownership',
    'new_syndic'
  ];

  const columns = useColumns(missionType);

  const buildStringFields = (fields) => fields.join(',');
  const fieldsString = buildStringFields(fieldsToFetch);

  const MISSIONS_POPULATE =
    'syndic,mission,collective_ownership,collective_ownership.first_city,new_collective_ownership.first_city,documents.files';

  const MISSIONS_QUERY = `status=PENDING_VALIDATION&requested_mission_type=${missionType}&fields=${fieldsString}&sort=-updated_at`;

  return (
    <ListResource
      tradKey={`syndic-requests.${missionType}`}
      resourceName="syndic-request"
      extraQuery={MISSIONS_QUERY}
      populate={MISSIONS_POPULATE}
      onDoubleClickAction={{ action: () => {} }}
      columns={columns}
      customActionColumn
      withCreateButton={false}
    />
  );
};

ListSpecificSyndicRequest.propTypes = {
  missionType: PropTypes.string
};

ListSpecificSyndicRequest.defaultProps = {
  missionType: 'COLLECTIVE_OWNERSHIP_TRANSFER'
};
