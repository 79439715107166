import { getChargingGrid } from './getChargingGrid';

/**
 * Creates a new charging grid.
 * @async
 * @function
 * @param {Object} params - Function parameters
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {Function} params.setResourceDatas - Function for setting collective ownership data
 * @param {Object} params.resourceDatas - The collective ownership object
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.message - Function for displaying messages
 * @param {Function} params.setIsVersionButton - Function for setting version button state
 * @throws {Error} Throws an error if the API request fails
 * @returns {Promise<void>} - A promise that resolves after the new charging grid is created
 */

export const createNewChargingGrid = async ({
  dispatchAPI,
  chargingGrid,
  setResourceDatas,
  resourceDatas,
  setChargingGrid,
  message,
  setIsVersionButton,
  setIsLabelModalOpen
}) => {
  try {
    const { data } = await dispatchAPI('POST', {
      url: `/charging-grids?charging_grid=${chargingGrid._id}&collective_ownership=${resourceDatas._id}`
    });

    setResourceDatas(data.collective_ownership);
    getChargingGrid({
      chargingGrid: data.charging_grid,
      resourceDatas: data.collective_ownership,
      setChargingGrid,
      setIsVersionButton
    });
    setIsLabelModalOpen(true);
  } catch (e) {
    message(e);
  }
};
