import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import {
  ContentCustom,
  ListResource,
  PageHeaderCustom
} from '../../../components';
import { useColumns } from './columns';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { CollectiveOwnership } from '../../../models/CollectiveOwnership';
import { GetEnums } from '../../../utils/getEnums';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import { SearchsBar } from './utils/ExtraSearchsBar';
import { ExtraButtons } from './ExtraButtons';
import { ExpandedInvoiceTable } from './expandableList/ExpandedInvoiceTable';

/**
 * Renders a list of invoices that are in phase one.
 * This component fetches the list of invoices and renders them in a table.
 * It also provides a search bar to filter the invoices by different parameters.
 *
 * @component
 * @returns {JSX.Element} The list of invoices component.
 */
export const ListPhaseOneInvoices = () => {
  const { t } = useTranslation();
  const { mainColumns, syndicColumns } = useColumns();
  const { syndics } = useGlobalContext();
  const [filters, setFilters] = useState({});
  const [enums, setEnums] = useState([]);
  const [view, setView] = useState('main');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedSyndic, setSelectedSyndic] = useState('');
  const [extraQuery, setExtraQuery] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isShowActionsButtons, setIsShowActionsButtons] = useState(false);
  const { getEnums } = GetEnums();

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedInvoices(rowRecords);
    }
  };

  const expandedRowRender = useCallback((record) => {
    const tableData = record?.invoices;
    return <ExpandedInvoiceTable datasource={tableData} />;
  }, []);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const data = await CollectiveOwnership.getResources({
        populate: '',
        extraQuery: ''
      });
      const enumsData = await getEnums({ resource: 'invoices' });
      setEnums(enumsData);
      setCollectiveOwnerships(data);
      setIsDataLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setIsShowActionsButtons(true);
    } else {
      setIsShowActionsButtons(false);
    }
  }, [selectedRowKeys]);

  useEffect(() => {
    (async () => {
      const data = await CollectiveOwnership.getResources({
        populate: '',
        extraQuery: selectedSyndic ? `syndic._id=${selectedSyndic}` : ''
      });
      setCollectiveOwnerships(data);
    })();
  }, [selectedSyndic]);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <>
      <PageHeaderCustom title={t('invoices.titles.invoice_step_one')} />
      <ContentCustom>
        {view === 'main' ? (
          <>
            <ListResource
              resourceName="invoices/phase-one"
              resourceModelName="Invoice"
              columns={mainColumns}
              forceRefresh={forceRefresh}
              extraQuery={extraQuery}
              withCreateButton={false}
              withUploadButton
              customActionColumn
              withPageHeader={false}
              withSearchBar
              scroll={{ x: 'max-content' }}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection
              }}
              searchBarExtraButtons={
                <SearchsBar
                  setFilters={setFilters}
                  collectiveOwnerships={collectiveOwnerships}
                  syndics={syndics}
                  setSelectedSyndic={setSelectedSyndic}
                  isDataLoading={isDataLoading}
                  setForceRefresh={setForceRefresh}
                  enums={enums}
                  view={view}
                  setView={setView}
                />
              }
            />
            <ExtraButtons
              setForceRefresh={setForceRefresh}
              isShowActionsButtons={isShowActionsButtons}
              selectedInvoices={selectedInvoices}
            />
          </>
        ) : (
          <ListResource
            resourceName="invoices/by-syndic"
            resourceModelName="Invoice"
            columns={syndicColumns}
            forceRefresh={forceRefresh}
            extraQuery={extraQuery}
            withCreateButton={false}
            withUploadButton
            customActionColumn
            withPageHeader={false}
            withSearchBar
            scroll={{ x: 'max-content' }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            expandable={{
              expandedRowRender,
              rowExpandable: (record) => record?.invoices?.length > 0
            }}
            searchBarExtraButtons={
              <SearchsBar
                setFilters={setFilters}
                collectiveOwnerships={collectiveOwnerships}
                syndics={syndics}
                setSelectedSyndic={setSelectedSyndic}
                isDataLoading={isDataLoading}
                setForceRefresh={setForceRefresh}
                enums={enums}
                setView={setView}
              />
            }
          />
        )}
      </ContentCustom>
    </>
  );
};
