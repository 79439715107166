import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Returns an object containing methods related to quote configurations.
 * @hook
 * @returns {Object} An object containing the `getLatestQuoteAmounts` method.
 */
export const QuoteConfigurationsMethods = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Retrieves the latest quote amounts.
   * @function
   * @async
   * @returns {Object|null} The latest quote amounts, or `null` if no data is available.
   */
  const getLatestQuoteAmounts = async ({ type }) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `quote-configurations?type=${type}&limit=1&sort=-created_at`
      });

      if (!data.length) {
        return null;
      }

      return data[0];
    } catch (e) {
      return message(e);
    }
  };

  return { getLatestQuoteAmounts };
};
