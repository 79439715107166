import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, InputNumber, Typography, Divider } from 'antd';

const { Text } = Typography;

/**
 * Renders a form for recording exchange information.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.recordId - The ID of the record.
 * @returns {JSX.Element} - The rendered component.
 */
export const Exchange = ({ recordId }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={8}>
            <Row justify="center">
              <Col>
                <Text>{t('quotation-actions.form.first_owner')}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="center">
              <Col>
                <Text>
                  {t('quotation-actions.form.compensation_paid_from_1_to_2')}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="center">
              <Col>
                <Text>{t('quotation-actions.form.second_owner')}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={8}>
            <Row justify="start">
              <Col span={12}>
                <Form.Item
                  label={t('quotation-actions.form.lot_number')}
                  name={[
                    'actions',
                    recordId,
                    'data',
                    'first_owner',
                    'lot_number'
                  ]}
                  wrapperCol={{ span: 12 }}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('quotation-actions.form.identity')}
                  name={[
                    'actions',
                    recordId,
                    'data',
                    'first_owner',
                    'identity'
                  ]}
                  wrapperCol={{ span: 12 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="center">
              <Col spane={24}>
                <Form.Item
                  name={[
                    'actions',
                    recordId,
                    'data',
                    'compensation_paid_from_1_to_2'
                  ]}
                  wrapperCol={{ span: 12 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify="end">
              <Col span={12}>
                <Form.Item
                  label={t('quotation-actions.form.lot_number')}
                  name={[
                    'actions',
                    recordId,
                    'data',
                    'second_owner',
                    'lot_number'
                  ]}
                  wrapperCol={{ span: 12 }}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('quotation-actions.form.identity')}
                  name={[
                    'actions',
                    recordId,
                    'data',
                    'second_owner',
                    'identity'
                  ]}
                  wrapperCol={{ span: 12 }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Exchange.propTypes = {
  recordId: PropTypes.string.isRequired
};
