/* eslint-disable no-unused-vars */
import { Select, DatePicker, Form, Divider, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

/**
 * Renders a list of plan form fields for creating or updating collective ownership.
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isFieldsLoading - Indicates whether the fields are loading.
 * @param {Array} props.professionals - The array of professionals.
 * @returns {JSX.Element} The rendered component.
 */
export const PlanFormList = ({ isFieldsLoading, professionals }) => {
  /**
   * Filters the options of a Select component based on the input value.
   * @param {string} input - The input value to filter.
   * @param {Object} option - The option object.
   * @returns {boolean} True if the option matches the filter, false otherwise.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const { t } = useTranslation();

  return (
    <Form.List name="plans">
      {(listFields, { add }) => {
        if (listFields.length < 2) add();
        return (
          <>
            {listFields.map(({ name, key }, index) => (
              <>
                <Divider>Document {index + 1}</Divider>
                <Form.Item
                  name={[name, 'notary_writer']}
                  label={t('collective-ownerships.form.notary_writer')}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    loading={isFieldsLoading}
                  >
                    {(professionals.editorNotaies || []).map((item) => (
                      <Option value={item._id} key={item._id}>
                        {`${item.last_name || ''} ${item.first_name || ''}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={[name, 'realization_date']}
                  label={t('collective-ownerships.form.realization_date')}
                >
                  <DatePicker
                    format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                  />
                </Form.Item>
                <Form.Item
                  name={[name, 'transcription_date']}
                  label={t('collective-ownerships.form.transcription_date')}
                >
                  <DatePicker
                    format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                  />
                </Form.Item>
                <Form.Item
                  name={[name, 'plan_volume']}
                  label={t('collective-ownerships.form.plan_volume')}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={[name, 'plan_number']}
                  label={t('collective-ownerships.form.plan_number')}
                >
                  <Input />
                </Form.Item>
              </>
            ))}
          </>
        );
      }}
    </Form.List>
  );
};

PlanFormList.propTypes = {
  isFieldsLoading: PropTypes.bool.isRequired,
  professionals: PropTypes.shape({
    editorNotaies: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};
