import { Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../components';
import { useSyndicInformationsListContent } from './listContent/syndicInformations';

/**
 * Renders the SyndicInformations component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.pcsPreProduction - The PCS pre-production data.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @returns {JSX.Element} The rendered SyndicInformations component.
 */
export const SyndicInformations = ({ pcsPreProduction, isLoading }) => {
  const { t } = useTranslation();
  const informationsListContent =
    useSyndicInformationsListContent(pcsPreProduction);

  return (
    <Card
      title={t('pcs-pre-productions.show.cards.titles.syndic_informations')}
    >
      <Skeleton loading={isLoading} active>
        <DescriptionList data={informationsListContent} translate vertical />
      </Skeleton>
    </Card>
  );
};

SyndicInformations.propTypes = {
  pcsPreProduction: PropTypes.shape({}),
  isLoading: PropTypes.bool
};

SyndicInformations.defaultProps = {
  pcsPreProduction: {},
  isLoading: false
};
