import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { templateStatus } from '../../../../utils/constants/tagColors';

/**
 * The `listContent` function generates an array of content objects to display detailed
 * information about a template document. It accepts a data object that contains details
 * about a template document and returns an array of objects with detailed information
 * to be displayed in a description list.
 *
 * Each object in the returned array contains:
 * - `label`: The translation key for the label of the detail
 * - `span`: The span value to control the width of the detail
 * - `content`: The content to be displayed as the detail
 *
 * @function
 * @param {string} type - The type of template document. Default value is an empty string.
 * @param {Object} data - The data object containing details about the template document. Default value is an empty object.
 * @param {string} data.title - The title of the template document.
 * @param {string} data.status - The status of the template document.
 * @param {Object} data.author - The author of the template document.
 * @param {string} data.author.first_name - The first name of the author.
 * @param {string} data.author.last_name - The last name of the author.
 * @param {boolean} data.default_template - A flag indicating if it's a default template.
 * @returns {Array<Object>} An array of objects containing details about the template document to be displayed in a description list.
 *
 * @example
 *
 * const templateData = {
 *   title: 'Sample Title',
 *   status: 'active',
 *   author: { first_name: 'John', last_name: 'Doe' },
 *   default_template: true
 * };
 *
 * const contentList = listContent(templateData);
 */
export const listContent = (type, data = {}) => {
  const { t } = useTranslation();
  const { title, status, author, default_template } = data;

  return [
    {
      label: 'template-documents.show.template-title',
      span: 3,
      content: title || t('errors.form.not_specified')
    },
    {
      label: 'template-documents.show.status',
      span: 3,
      content:
        (status && (
          <Tag color={templateStatus[status]}>
            {t(`template-documents.show.${status}`)}
          </Tag>
        )) ||
        t('errors.form.not_specified')
    },
    {
      label: 'template-documents.show.author',
      span: 3,
      content:
        (author && `${author.first_name} ${author.last_name}`) ||
        t('errors.form.not_specified')
    },
    ...(type === 'FAQ_TEMPLATES'
      ? []
      : [
          {
            label: 'template-documents.show.default_template',
            span: 3,
            content:
              default_template === true ? (
                <CheckCircleOutlined style={{ color: 'green' }} />
              ) : (
                <CloseCircleOutlined style={{ color: 'red' }} />
              )
          }
        ])
  ];
};
