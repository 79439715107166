import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

/**
 * Returns an array of columns for the wordings table.
 * @hook
 * @returns {Array} An array of columns for the wordings table.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  const wordingsColumns = [
    {
      title: t('invoices.form.label'),
      dataIndex: 'wording',
      key: 'wording'
    },
    {
      title: t('invoices.form.total_ht'),
      dataIndex: 'total',
      key: 'total'
    },
    {
      title: t('invoices.form.tva'),
      dataIndex: 'tva',
      key: 'tva'
    },
    {
      title: t('invoices.form.disbursement'),
      dataIndex: 'disbursement',
      key: 'disbursement'
    },
    {
      title: t('invoices.form.total'),
      dataIndex: 'total_ttc',
      key: 'total_ttc'
    }
  ];

  const paymentsColumns = [
    {
      title: t('payments.form.date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => (date ? dayjs(date).format('DD-MM-YYYY') : '-')
    },
    {
      title: t('payments.form.amount'),
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: t('payments.form.bank_account'),
      dataIndex: 'bank_account',
      key: 'bank_account'
    },
    {
      title: t('payments.form.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => t(`payments.tags.${type}`)
    },
    {
      title: t('payments.form.comment'),
      dataIndex: 'comment',
      key: 'comment'
    }
  ];

  return { wordingsColumns, paymentsColumns };
};
