import React from 'react';
import { Col, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CreateUpdateContainer } from '../../../../components';
import { commentFields } from './fields';
import { useHandleResize } from '../../../../utils/handleResize';

/**
 * AddCommentDrawer component displays a drawer allowing users to add or view comments for a syndic.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines if the drawer is open or closed.
 * @param {function} props.setOpen - Function to control the visibility of the drawer.
 * @param {Object} props.syndic - The syndic object containing the comment data.
 * @param {function} props.setForceRefresh - Function to control the rerender the component.
 * @param {string} [props.syndic.comment] - The comment associated with the syndic, if any.
 *
 * @returns {JSX.Element} The rendered AddCommentDrawer component.
 */
const AddCommentDrawer = ({ open, setOpen, syndic, setForceRefresh }) => {
  const { t } = useTranslation();
  const fields = commentFields();
  const comment = syndic?.comment || '';
  const comments = comment?.split('***') || [];

  const onClose = () => {
    setOpen(false);
  };
  const { width } = useHandleResize();

  return (
    <Drawer
      title={t('invoices_reminders.titles.add_a_comment')}
      onClose={onClose}
      open={open}
      width={width < 1200 ? '60%' : '40%'}
    >
      <Col gutter={16}>
        {comment ? (
          comments.map((com) => <p key={`${com}`}>{com}</p>)
        ) : (
          <p>Aucun commentaire</p>
        )}
      </Col>
      <CreateUpdateContainer
        fields={fields}
        purpose="edit"
        baseUrl="syndics"
        resource="syndics"
        withFilesManager={false}
        withCustomPageHeader
        withEnums={false}
        customActionColumn
        customId={syndic._id}
        style={{ padding: '10px', margin: 0 }}
        customLabelCol
        setForceRefresh={setForceRefresh}
        submitLabel="invoices_reminders.buttons.save"
      />
    </Drawer>
  );
};

AddCommentDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  syndic: PropTypes.shape({
    _id: PropTypes.string,
    comment: PropTypes.string
  }).isRequired
};

export default AddCommentDrawer;
