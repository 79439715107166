/**
 * Custom hook to extract content from a record object.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Object} options.record - The record object.
 * @returns {Object} - An object containing extracted content.
 */
export const useContent = ({ record }) => {
  const syndicName = `${record?.syndic?.society_name || ''} ${
    record?.syndic?.code ? `(${record.syndic.code})` : ''
  }`;

  const managerName = `${record?.manager?.last_name || ''} ${
    record?.manager?.first_name || ''
  }`;

  const collectiveOwnership = `${record?.collective_ownership?.code || ''} - ${
    record?.collective_ownership?.first_address?.number || ''
  } ${record?.collective_ownership?.first_address?.street || ''} ${
    record?.collective_ownership?.first_address?.postal_code || ''
  } ${record?.collective_ownership?.first_address?.city || ''} ${
    record?.collective_ownership?.cadastral_reference
      ? `(${record.collective_ownership.cadastral_reference})`
      : ''
  }`;

  return {
    syndicName,
    managerName,
    collectiveOwnership
  };
};
