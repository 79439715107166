import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../../components';
import { ConfigurationProvider } from '../../../contexts/ConfigurationContext';
import { PcsappointmentMonitoring } from './PcsAppointmentMonitoring';

/**
 * Renders the PCS appointment monitoring component.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const PcsappointmentRouter = () => (
  <ConfigurationProvider>
    <Routes>
      <Route index element={<PcsappointmentMonitoring />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ConfigurationProvider>
);
