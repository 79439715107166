export const routes = {
  HOME: '/',
  EVENTS: '/events',
  PCS_APPOINTMENTS_MONITORING: '/pcs-appointments-monitoring',
  MISSIONS: '/missions',
  BILLING_AND_ACCOUNTING: '/billing-and-accountings',
  SYNDICS: '/syndics',
  COLLECTIVE_OWNERSHIPS: '/collective-ownerships',
  CONFIGURATIONS: '/configurations'
};

export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PCSFORM: '/pcs-form',
  PASSWORD_CREATION: '/post-pwd',
  PASSWORD_RESET: '/reset-pwd',
  SHOW_SYNDIC: '/syndics/show',
  SHOW_COLLECTIVE_OWNERSHIP: '/collective-ownerships/show'
};

export const subRoutes = {
  MISSIONS: {
    PCS_PRE_PRODUCTIONS: '/pcs-pre-productions',
    MISSIONS_VIEW: '/missions-view',
    COLLECTIONS: '/collections'
  },
  BILLING_AND_ACCOUNTING: {
    INVOICES: '/invoices',
    INVOICES_REMINDERS: '/invoices-reminders',
    ACCOUNTANT_EXPORTS: '/accountant-exports',
    TREASURY_PUBLICATIONS: '/treasury-publications'
  }
};

export const pathSearches = {};
