import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columns';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { addColumns } from '../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { EditableTable } from '../../../components/editableTableComponents/EditableTable';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';

/**
 * Renders the VariousDocuments component.
 *
 * @component
 * @returns {JSX.Element} The VariousDocuments component.
 */
export const VariousDocuments = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectOptions, setSelectOptions] = useState({
    document_types: []
  });
  const [selectType, setSelectType] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const { getResourceData } = EditableTableMethods();
  const { searchValue, setPagination, pagination, currentSorter } =
    useConfigurationContext();
  const columns = useColumns({
    setEditingKey,
    form,
    setSelectType,
    setForceRefresh,
    forceRefresh,
    selectOptions
  });
  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  useEffect(() => {
    (async () => {
      const [variousDocumentsDatas, documentRepositoriesDatas] =
        await Promise.all([
          getResourceData({
            resource: 'various-documents',
            searchValue,
            setPagination,
            pagination,
            query: '&populate=document_type',
            currentSorter
          }),
          getResourceData({ resource: 'document-repositories' })
        ]);

      setDataSource(
        variousDocumentsDatas.map((el, index) => ({ ...el, key: index }))
      );
      setSelectOptions({
        document_types: documentRepositoriesDatas
      });
    })();
  }, [forceRefresh, searchValue, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: columns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      setMergedColumns,
      selectOptions,
      selectType,
      resource: 'various-documents'
    });
  }, [editingKey, dataSource]);

  return (
    <>
      <PageHeaderCustom title={t('various-documents.title')} />
      <ContentCustom>
        <EditableTable
          resource="various-documents"
          dataSource={dataSource}
          mergedColumns={mergedColumns}
          setSelectedRowKey={setSelectedRowKey}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          form={form}
          searchValue={searchValue}
          pagination={pagination}
          setPagination={setPagination}
        />
      </ContentCustom>
    </>
  );
};
