import { Form, Select, Switch, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { invoiceTag } from '../../../../utils/constants/tagColors';

const { Option } = Select;

/**
 * Renders the search bar component for the invoices list.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.collectiveOwnerships - The array of collective ownerships.
 * @param {Array} props.syndics - The array of syndics.
 * @param {Function} props.setSelectedSyndic - The function to set the selected syndic.
 * @param {boolean} props.isDataLoading - Indicates if the data is loading.
 * @param {Object} props.enums - The object containing enums.
 * @param {Function} props.setForceRefresh - The function to force refresh.
 * @returns {JSX.Element} The search bar component.
 */
export const SearchsBar = ({
  setFilters,
  collectiveOwnerships,
  syndics,
  setSelectedSyndic,
  isDataLoading,
  enums,
  setForceRefresh,
  setView
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Form layout="inline" form={form}>
      <Form.Item name={['syndic']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              'syndic._id': value || ''
            }));
            setSelectedSyndic(value);
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              'syndic._id': ''
            }));
            setSelectedSyndic('');
            setForceRefresh((prev) => !prev);
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {`${syndic?.society_name} (${syndic?.code})`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['collective-ownership']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              'collective_ownership._id': value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              'collective_ownership._id': ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.search_collective_ownership')}
        >
          {(collectiveOwnerships || []).map((collective_ownership) => (
            <Option
              key={collective_ownership._id}
              value={collective_ownership._id}
            >
              {`${collective_ownership?.code} - ${
                collective_ownership?.name || ''
              } - ${collective_ownership?.first_address?.street} ${
                collective_ownership?.first_address?.postal_code
              }`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['status']}>
        <Select
          style={{ minWidth: 190 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              status: value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              status: ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('placeholder.filter_status')}
        >
          {(enums.status || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={invoiceTag[status]}>
                {t(`invoices.tags.${status}`, {
                  defaultValue: status
                })}
              </Tag>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={['switch_filter_by_syndic']}
        label={t('invoices.labels.filter_by_syndic')}
        valuePropName="checked"
      >
        <Switch
          onChange={(value) => {
            if (value) {
              setView('syndic');
              setForceRefresh((prev) => !prev);
            } else {
              setView('main');
              setForceRefresh((prev) => !prev);
            }
          }}
        />
      </Form.Item>
    </Form>
  );
};

SearchsBar.propTypes = {
  setFilters: PropTypes.func.isRequired,
  collectiveOwnerships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedSyndic: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  syndics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enums: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired
};
