import { EyeOutlined, EditOutlined, BankOutlined } from '@ant-design/icons';
import { Badge, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { missionTag, invoiceTag } from '../../../../utils/constants/tagColors';
import TableTitle from '../components/TableTitle';

export const useInvoicesColumns = (setOpen, comment) => {
  const { t } = useTranslation();
  const iconStyle = { fontSize: 18, color: 'black', cursor: 'pointer' };

  return [
    {
      key: 'indicator',
      render: () => <Badge status="error" />
    },
    {
      title: (
        <TableTitle title={t('invoices_reminders.invoices.invoice_number')} />
      ),
      key: 'invoice_number',
      render: (record) => (record.reference ? record.reference : '-')
    },
    {
      title: <TableTitle title={t('invoices_reminders.invoices.type')} />,
      key: 'type',
      render: (record) => {
        const type = record.type;
        return type ? t(`invoices_reminders.tags.${record?.type}`) : '-';
      },
      sorter: true
    },
    {
      title: (
        <TableTitle
          title={t('invoices_reminders.invoices.collective_ownership')}
        />
      ),
      key: 'collective_ownership',
      render: (record) => {
        const collective = record?.collectiveOwnershipDetails;
        return `${collective?.code || ''} - ${
          collective?.first_address?.number || ''
        } ${collective?.first_address?.street || ''} ${
          collective?.first_address?.postal_code || ''
        } ${collective?.first_address?.city || ''} ${
          collective?.cadastral_reference
            ? `(${collective?.cadastral_reference})`
            : ''
        }`;
      },
      sorter: true
    },
    {
      title: <TableTitle title={t('invoices_reminders.invoices.mission')} />,
      key: 'mission',
      render: (record) => {
        const mission_type = record?.missionDetails.type;
        return mission_type ? (
          <Tag color={missionTag[mission_type]}>
            {t(`missions.tags.${mission_type}`, {
              defaultValue: mission_type
            })}
          </Tag>
        ) : (
          '-'
        );
      },
      sorter: true
    },
    {
      title: (
        <TableTitle title={t('invoices_reminders.columns.last_reminder')} />
      ),
      key: 'last_reminder',
      dataIndex: 'last_reminder',
      render: (record) => (record ? dayjs(record).format('DD-MM-YYYY') : '-'),
      sorter: true
    },
    {
      title: (
        <TableTitle title={t('invoices_reminders.columns.next_reminder')} />
      ),
      key: 'next_reminder',
      dataIndex: 'next_reminder',
      render: (record) => {
        if (record === 'Aucune') {
          return record;
        }
        if (record) {
          return dayjs(record).format('DD-MM-YYYY');
        }
        return '-';
      },
      sorter: true
    },
    {
      title: <TableTitle title={t('invoices_reminders.invoices.send_date')} />,
      key: 'sending_date',
      render: (record) => {
        const sending_date = record?.sending_date;

        if (sending_date) {
          return dayjs(sending_date).format('DD-MM-YYYY');
        }
        return '-';
      },
      sorter: true
    },
    {
      title: <TableTitle title={t('invoices_reminders.invoices.status')} />,
      key: 'status',
      render: (record) => {
        const invoice_status = record?.status;
        return invoice_status ? (
          <Tag color={invoiceTag[invoice_status]}>
            {t(`invoices_reminders.invoices.${invoice_status}`, {
              defaultValue: invoice_status
            })}
          </Tag>
        ) : (
          '-'
        );
      },
      sorter: true
    },
    {
      title: <TableTitle title={t('invoices_reminders.invoices.total')} />,
      key: 'total',
      render: (record) =>
        record.total_ttc
          ? `${parseFloat(record.total_ttc).toFixed(2)} €`
          : '0 €',
      sorter: true
    },
    {
      title: <TableTitle title={t('invoices_reminders.columns.paid')} />,
      key: 'paid',
      render: (record) =>
        record.paid ? `${parseFloat(record.paid).toFixed(2)} €` : '0 €',
      sorter: true
    },
    {
      title: (
        <TableTitle title={t('invoices_reminders.columns.remainder_to_pay')} />
      ),
      key: 'remainder_to_pay',
      render: (record) =>
        record.left_to_pay
          ? `${parseFloat(record.left_to_pay).toFixed(2)} €`
          : '0 €',
      sorter: true
    },
    {
      title: <TableTitle title={t('invoices_reminders.columns.comment')} />,
      key: 'comment',
      render: () => comment,
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/billing-and-accountings/invoices/show/${record._id}`}>
            <EyeOutlined style={iconStyle} />
          </Link>
          <EditOutlined style={iconStyle} onClick={() => setOpen(true)} />
          <BankOutlined style={iconStyle} />
        </Space>
      )
    }
  ];
};
