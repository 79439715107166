export const routes = {
  HOME: '/',
  SYNDIC_COLLECTIVE_OWNERSHIPS: '/collective-ownerships',
  CLIENT_WALLET: '/client-wallet',
  SYNDIC_INVOICES: '/invoices',
  DRAFTS: '/drafts',
  PENDING_VALIDATION: '/pending-validation',
  CONTACT: '/contact',
  FAQ: '/faq'
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  PASSWORD_CREATION: '/post-pwd',
  PASSWORD_RESET: '/reset-pwd',
  PCSFORM: '/pcs-form',
  USERS: '/users'
};

export const otherRoutes = {
  SERVICES: '/services'
};

export const otherRoutesSubRoutes = {
  SERVICES: {
    COMPLIANCE_LOI_ELAN: 'compliance-loi-elan',
    // PUBLICATION_LOI_ELAN: 'publication-loi-elan',
    RCP_RESEARCH: 'rcp-research',
    PLANS_RESEARCH: 'plans-research'
  },
  CLIENT_WALLET: {
    NEW_MISSION: 'new-mission',
    MANAGE_WALLET: 'manage-wallet',
    MANAGE_TEAM: 'manage-team',
    TRANSFER_COLLECTIVE_OWNERSHIP: 'transfer-collective-ownership'
  }
};

// url search parameters
export const pathSearches = {};
