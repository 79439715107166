import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { Statistics } from './statistics/Statistics';
import { ActivityMonitoringList } from './activity-monitoring/ActivityMonitoringList';
import { CreateActivityMonitoring } from './activity-monitoring/CreateUpdateActivityMonitoring/createActivityMonitoring';
import { ListPcsStock } from './pcs-stock/ListPcsStock';
import { CollectiveOwnershipsFormsRouter } from './collective-ownerships-forms/CollectiveOwnershipFormRouter';
import { subRoutes } from '../../utils/constants/routes/productionRoutes';
import { SyndicRequestRouter } from './SyndicRequests/SyndicRequestRouter';
import { CollectiveOwnershipTransferRouter } from './CollectiveOwnershipTransfer/SyndicRequestRouter';
import { ListPcsPhase3 } from './pcs-phase-3/ListPcsPhase3';

/**
 * Renders the monitoring router component.
 * @returns {JSX.Element} The monitoring router component.
 */
export const MonitoringRouter = () => (
  <Routes>
    <Route
      path="/collective-ownership-forms/*"
      element={<CollectiveOwnershipsFormsRouter />}
    />
    <Route
      path={`${subRoutes.MONITORING.SYNDIC_REQUESTS}/*`}
      element={<SyndicRequestRouter />}
    />
    <Route
      path={`${subRoutes.MONITORING.COLLECTIVE_OWNERSHIPS_TRANSFER}/*`}
      element={<CollectiveOwnershipTransferRouter />}
    />
    <Route path="/statistics" element={<Statistics />} />
    <Route path="/activity-monitoring" element={<ActivityMonitoringList />} />
    <Route
      path="/activity-monitoring/create"
      element={<CreateActivityMonitoring />}
    />
    <Route path="pcs-stock" element={<ListPcsStock />} />
    <Route path="pcs-phase-3" element={<ListPcsPhase3 />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
