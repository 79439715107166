import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  CreateUpdateContainer,
  PageHeaderCustom
} from '../../../../components';
import { useFields } from './fields';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { GetEnums } from '../../../../utils/getEnums';
import { User } from '../../../../models/User';

/**
 * Component for creating activity monitoring.
 * ! Doit être supprimé plus tard (only for test)
 * @component
 * @returns {JSX.Element} The create activity monitoring component.
 */
export const CreateActivityMonitoring = () => {
  const { t } = useTranslation();
  const { syndics } = useGlobalContext();
  const [selectedSyndic, setSelectedSyndic] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [managers, setManagers] = useState([]);
  const [users, setUsers] = useState([]);
  const [actionTypes, setActionTypes] = useState([]);
  const { getEnums } = GetEnums();
  const fields = useFields({
    users,
    syndics,
    managers,
    actionTypes,
    setSelectedSyndic,
    isLoading
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const usersData = await User.getResources({
        populate: '',
        extraQuery: ''
      });
      const managersData = await User.getResources({
        populate: '',
        extraQuery: 'role=USERS:SYNDIC_MANAGER'
      });
      const enums = await getEnums({ resource: 'activity-monitorings' });
      setUsers(usersData);
      setManagers(managersData);
      setActionTypes(enums);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (selectedSyndic) {
      setManagers(selectedSyndic.managers);
    }
  }, [selectedSyndic]);

  return (
    <>
      <PageHeaderCustom title={t('activity-monitorings.create')} />
      <CreateUpdateContainer
        ressource="activity-monitorings"
        purpose="create"
        resource="activity-monitorings"
        baseUrl="activity-monitorings"
        withEnums={false}
        fields={fields}
        withCustomPageHeader
        withFilesManager={false}
      />
    </>
  );
};
