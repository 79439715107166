import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Divider, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import SyndicViewContentCustom from '../SyndicViewContentCustom';
import portefeuille_a_evolue_1 from '../../../assets/images/portefeuille_a_evolue_1.svg';
import add_copro from '../../../assets/images/add_copro.svg';
import delete_copro from '../../../assets/images/delete_copro.svg';
import edit_team from '../../../assets/images/edit_team.svg';
import config_wallet from '../../../assets/images/config_wallet.svg';
import { dashboardTitle } from '../services/ComplianceElanRouter/dashboardTitles';
import { otherRoutesSubRoutes as subRoutes } from '../../../utils/constants/routes/syndicManagerRoutes';

const { Text } = Typography;

/**
 * @component
 * Renders the ClientWallet component.
 * This component displays a list of cards representing different actions related to client wallet management.
 *
 * @returns {JSX.Element} The rendered ClientWallet component.
 */
export const ClientWallet = () => {
  const { clientWalletTitle, clientWalletSubtitle } = dashboardTitle();
  const { t } = useTranslation();

  const cards = [
    {
      index: 1,
      title: t('syndic-view.manage-wallet.new_mission'),
      image: add_copro,
      route: subRoutes.CLIENT_WALLET.NEW_MISSION
    },
    {
      index: 2,
      title: t('syndic-view.manage-wallet.transfer_collective_ownership'),
      image: delete_copro,
      route: subRoutes.CLIENT_WALLET.TRANSFER_COLLECTIVE_OWNERSHIP
    },
    {
      index: 3,
      title: t('syndic-view.manage-wallet.manage_wallet'),
      image: config_wallet,
      route: subRoutes.CLIENT_WALLET.MANAGE_WALLET
    },
    {
      index: 4,
      title: t('syndic-view.manage-wallet.manage_team'),
      image: edit_team,
      route: subRoutes.CLIENT_WALLET.MANAGE_TEAM
    }
  ];

  return (
    <SyndicViewContentCustom
      title={clientWalletTitle}
      subtitle={clientWalletSubtitle}
      imageOnRight={portefeuille_a_evolue_1}
      cardStyle={{ backgroundColor: '#222222' }}
    >
      <Card
        style={{
          backgroundColor: 'white',
          borderRadius: '15px',
          color: 'white',
          padding: '0rem 1rem'
        }}
      >
        <Row justify="space-around" align="middle">
          {cards.map((item, index) => (
            <React.Fragment key={item.index}>
              <Row
                justify="space-around"
                align="middle"
                style={{ width: '15rem', height: '15rem', textAlign: 'center' }}
              >
                <Col span={24}>
                  <Text style={{ color: 'var(--gray700)' }}>{item.title}</Text>
                </Col>
                <Col span={24}>
                  <Link to={item.route}>
                    <img src={item.image} alt="logo" />
                  </Link>
                </Col>
              </Row>
              {index < cards.length - 1 && (
                <Divider type="vertical" style={{ height: '10em' }} />
              )}
            </React.Fragment>
          ))}
        </Row>
      </Card>
    </SyndicViewContentCustom>
  );
};
