import { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { PcajpArraysForm } from '../forms/PcajpArraysForm';
import { PcajpArraysButtons } from '../buttons/PcajpArraysButtons';

/**
 * Modal component for displaying PCAJP arrays.
 * @component
 * @returns {JSX.Element} The PCAJP array modal component.
 */
export const PcajpArraysModal = () => {
  const { isDrawerOpen, setIsDrawerOpen, pcajpArrays } = useMissionContext();
  const [dataSource, setDataSource] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPcajpArray, setCurrentPcajpArray] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setDataSource(pcajpArrays[pcajpArrays.length - 1]?.data || []);
    setIsSubmitting(false);
  }, [pcajpArrays]);

  return (
    <Modal
      title={t('missions.modals.titles.pcajp_array')}
      onCancel={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          pcajp_array: false
        }))
      }
      open={isDrawerOpen.pcajp_array}
      footer={null}
      width={1500}
      forceRender
      centered
      bodyStyle={{
        padding: '20px',
        maxHeight: '80vh',
        overflowY: 'auto'
      }}
    >
      <Row gutter={[16, 16]}>
        {pcajpArrays.map((pcajpArray) => (
          <Col>
            <PcajpArraysButtons
              setCurrentPcajpArray={setCurrentPcajpArray}
              setDataSource={setDataSource}
              pcajpArray={pcajpArray}
            />
          </Col>
        ))}
        <Col span={24}>
          <PcajpArraysForm
            currentPcajpArray={currentPcajpArray}
            dataSource={dataSource}
            setCurrentPcajpArray={setCurrentPcajpArray}
            pcajpArrays={pcajpArrays}
            setIsSubmitting={setIsSubmitting}
            isSubmitting={isSubmitting}
          />
        </Col>
      </Row>
    </Modal>
  );
};
