import { Card, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { documentTabs } from './tabs/documentTabs';
import { useCollectiveOwnershipContext } from '../../../../contexts/CollectiveOwnershipContext';
/**
 * Renders a document card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.collectiveOwnership - The collective ownership entity data.
 * @param {Function} props.setCollectiveOwnership - The collective ownership entity data setter function.
 * @returns {JSX.Element} The rendered DocumentCard component.
 */
export const Documents = ({ collectiveOwnership, setCollectiveOwnership }) => {
  const { setItemType } = useCollectiveOwnershipContext();
  const { items } = documentTabs({
    collectiveOwnership,
    setCollectiveOwnership
  });

  const handleItemType = (el) => {
    if (el === '1') {
      setItemType({ type: 'missionDisplay', value: 'PCS_1' });
    } else if (el === '2') {
      setItemType({ type: 'documentType', value: 'Cerfa-document' });
    } else {
      setItemType(null);
    }
  };

  return (
    <Card>
      <Tabs
        defaultActiveKey="1"
        items={items}
        destroyInactiveTabPane
        onChange={(el) => handleItemType(el)}
      />
    </Card>
  );
};

Documents.propTypes = {
  collectiveOwnership: PropTypes.shape({}).isRequired,
  setCollectiveOwnership: PropTypes.func.isRequired
};
