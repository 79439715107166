import { Route, Routes } from 'react-router-dom';
import { ComplianceElanCollectiveOwnershipList } from './CollectiveOwnershipList';
import { Exception } from '../../../../../components';
import { MissionCollectiveOwnershipsShow } from './ShowMissionCollectiveOwnership/MissionCollectiveOwnershipsShow';

/**
 * @component
 * Router component for managing compliance elan collective ownerships.
 * @returns {JSX.Element} The rendered router component.
 */
export const ComplianceElanCollectiveOwnershipRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<MissionCollectiveOwnershipsShow />} />
    <Route path="/" element={<ComplianceElanCollectiveOwnershipList />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
