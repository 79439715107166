import { Select, DatePicker, InputNumber } from 'antd';

const { Option } = Select;

/**
 * The `usePublicationFields` hook generates configurations for form fields related to the publication details of an entity, such as office names, society forms, capital, and representative information. It utilizes Ant Design's `Select`, `DatePicker`, and `InputNumber` components for various input types.
 *
 * Parameters:
 * @param {Object} params - The parameters object.
 * @param {Object} params.enums - An object containing arrays of enumeration values for certain fields like status, used to populate dropdown options.
 * @param {boolean} params.isFieldsLoading - Indicates whether the enumeration data is being loaded, which controls the display of a loading indicator within the dropdown fields.
 *
 * Returns:
 * @returns {Object} An object containing two arrays of field configurations: `publicationFields` for general publication details and `addressFields` for address details. Each field configuration may include:
 * - `name`: An array representing the path to the value in the form state, used as the name for the form field.
 * - `input`: A React component configured as the input for the form field, which can be a `Select` component for dropdown fields, `DatePicker` for date inputs, or `InputNumber` for numeric inputs.
 *
 * The `Select` components are configured with `showSearch` to enable filtering through the options, and a custom `filterOption` function to control the search behavior. The `DatePicker` components are used for date inputs with a specified format.
 *
 * Usage:
 * This hook is intended for use in form components where publication details of an entity need to be collected or edited, such as in registration forms, profile editors, or similar applications where detailed publication information is required.
 */

export const usePublicationFields = ({ enums, isFieldsLoading }) => {
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const publicationFields = [
    {
      name: ['office_usual_name'],
      key: 'office_usual_name'
    },
    {
      name: ['society_form'],
      key: 'society_form'
    },
    {
      name: ['capital'],
      key: 'capital',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['siret_number'],
      key: 'siret_number'
    },
    {
      name: ['rcs_city'],
      key: 'rcs_city'
    },
    {
      name: ['status'],
      key: 'status',
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(enums.civility || []).map((item) => (
            <Option value={item} key={item}>
              {item}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['representative_name'],
      key: 'representative_name'
    },
    {
      name: ['representative_function'],
      key: 'representative_function'
    },
    {
      name: ['procuration_representative'],
      key: 'procuration_representative'
    },
    {
      name: ['city_of_birth'],
      key: 'city_of_birth'
    },
    {
      name: ['birth_date'],
      key: 'birth_date',
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      name: ['professional_card_mention'],
      key: 'professional_card_mention'
    },
    {
      name: ['professional_card_number'],
      key: 'professional_card_number'
    },
    {
      name: ['professional_card_acquisition_date'],
      key: 'professional_card_acquisition_date',
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      name: ['card_deliver_by'],
      key: 'card_deliver_by'
    }
  ];

  const addressFields = [
    {
      name: ['representative_address', 'number'],
      key: 'number'
    },
    {
      name: ['representative_address', 'street'],
      key: 'street'
    },
    {
      name: ['representative_address', 'additional'],
      key: 'additional'
    },
    {
      name: ['representative_address', 'postal_code'],
      key: 'postal_code'
    },
    {
      name: ['representative_address', 'city'],
      key: 'city'
    }
  ];

  return { publicationFields, addressFields };
};
