import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { setDocumentIcon } from '../../../../../components/setDocumentIcon';

/**
 * Returns an array of columns for the notes.
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { downloadDocument, viewDocument } = useDownloadDocument();

  return [
    {
      title: t('notes.form.title'),
      key: 'title',
      dataIndex: 'title',
      render: (title) => title || '-'
    },
    {
      title: t('notes.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (type ? t(`notes.form.${type}`) : '-')
    },
    {
      title: t('notes.form.comment'),
      key: 'comment',
      dataIndex: 'comment'
    },
    {
      title: t('notes.form.documents_list'),
      key: 'documents_list',
      dataIndex: 'documents',
      render: (documents) =>
        (documents?.files || []).map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    }
  ];
};
