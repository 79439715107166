import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { ShowEvents } from './ShowEvents';

/**
 * EventRouter component
 * @returns {object} - The UI of the EventRouter component
 */
export const EventRouter = () => (
  <Routes>
    <Route path="/create" element={<Exception />} />
    <Route path="/edit/:id" element={<Exception />} />
    <Route path="/show/:id" element={<Exception />} />
    <Route index element={<ShowEvents />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
