import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Renders a button component for creating an invoice.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.step - The current step of the mission.
 * @param {string} props.invoiceType - The type of the invoice.
 * @returns {JSX.Element} The create invoice button component.
 */
export const CreateInvoiceButton = ({ step, invoiceType }) => {
  const navigate = useNavigate();
  const { mission, allOrders } = useMissionContext();
  const { t } = useTranslation();

  return (
    <Col span={24}>
      <Row justify="end">
        <Button
          onClick={() =>
            navigate(`/billing-and-accountings/invoices/create`, {
              state: {
                type: invoiceType,
                mission,
                syndic_id: mission.syndic?._id,
                collective_ownership_id: mission.collective_ownership?._id,
                pcs_step: step,
                tva_percentage: 20,
                disbursement: step
                  ? mission[step].left_to_pay
                  : mission.left_to_pay,
                orders: allOrders.filter((el) => el.is_order_invoiced === false)
              }
            })
          }
        >
          {t(`missions.buttons.create_${invoiceType}_invoice`)}
        </Button>
      </Row>
    </Col>
  );
};

CreateInvoiceButton.propTypes = {
  step: PropTypes.string,
  invoiceType: PropTypes.string.isRequired
};

CreateInvoiceButton.defaultProps = {
  step: null
};
