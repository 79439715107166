import dayjs from 'dayjs';
import { message } from 'antd';
import { Order } from '../../../../../models/Order';

/**
 * Handles the submission of an email for a mission.
 *
 * @param {Object} options - The options for handling the email submission.
 * @param {Object} options.mission - The mission object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {Object} options.user - The user object.
 * @param {Function} options.setForceRefresh - The setForceRefresh function.
 * @param {boolean} options.forceRefresh - The forceRefresh flag.
 * @param {Function} options.setIsLoading - The setIsLoading function.
 * @param {Function} options.setIsSending - The setIsSending function.
 * @param {Function} options.setVisible - The setVisible function.
 * @param {Function} options.t - The translation function.
 * @returns {Promise<void>} - A promise that resolves when the email submission is complete.
 */
export const handleSubmitEmail = async ({
  mission,
  dispatchAPI,
  setForceRefresh,
  forceRefresh,
  setIsLoading,
  setIsSending,
  setVisible,
  t,
  notification,
  emailNotary
}) => {
  try {
    const formData = new FormData();
    const plan_requests = mission?.collective_ownership?.plans
      ?.map((plan) => ({
        editor_notary: plan.notary_writer,
        plan: plan._id,
        date: dayjs(),
        status: 'NO_RESPONSE'
      }))
      .filter((plan) => plan.editor_notary !== undefined);
    formData.append(
      'values',
      JSON.stringify({
        send_archivist_notary: false,
        plan_requests: [...plan_requests]
      })
    );
    await dispatchAPI('PATCH', {
      url: `/missions/${mission._id}`,
      body: formData
    });

    notification.success({
      message: t('missions.messages.send_notary_success'),
      placement: 'topRight',
      description: t('missions.messages.send_notary_success_content')
    });

    await dispatchAPI('POST', {
      url: `/missions/notary-email/${mission?._id}`,
      body: {
        receiver: emailNotary
      }
    });

    setIsLoading(true);
    setForceRefresh(!forceRefresh);
    setIsSending(false);
    setVisible(false);
  } catch (error) {
    message.error(t('missions.messages.error'));
  }
};

/**
 * Handles the submission of plans order.
 *
 * @function
 * @param {Object} options - The options for handling plans order submission.
 * @param {Object} options.values - The values for the plans order.
 * @param {string} options.missionId - The ID of the mission.
 * @param {Function} options.setNoInvoiceCreated - The function to set the no invoice created value.
 * @param {Function} options.dispatchAPI - The function to dispatch API calls.
 * @param {Function} options.setForceRefresh - The function to set the force refresh value.
 * @param {boolean} options.forceRefresh - The force refresh value.
 * @param {Function} options.setIsLoading - The function to set the loading state.
 * @param {Function} options.setOpen - The function to set the open state.
 * @param {Object} options.user - The user object.
 * @param {Function} options.t - The translation function.
 * @returns {Promise<void>} - A promise that resolves when the plans order is handled successfully.
 * @throws {Error} - If an error occurs while handling the plans order.
 */
export const handlePlansOrderSubmit = async ({
  values,
  missionId,
  setForceRefresh,
  setIsLoading,
  setOpen,
  t
}) => {
  try {
    //* Update order
    await Order.takePlanOrder({
      refDoc: values.plan,
      missionId,
      values: {
        order: { ...values, status: 'ORDERED' },
        mission: {
          steps: {
            principal: 'PLANS_DELIVERY'
          }
        }
      }
    });

    setIsLoading(true);
    setForceRefresh((prev) => !prev);
    setOpen(false);
  } catch (error) {
    message.error(t('missions.messages.error'));
  }
};
