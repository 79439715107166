import { useEffect, useState } from 'react';
import { Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHandleResize } from '../../../../../utils/handleResize';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/intermediateProjectFields';
import { useConfig } from '../utils/intermediateProjectCreateUpdateConfig';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * IntermediateProjectDrawer component.
 * @component
 * This component represents a drawer that displays an intermediate project for a mission.
 * It allows the user to create or update the intermediate project documents.
 * @returns {JSX.Element} The IntermediateProjectDrawer component.
 */
export const IntermediateProjectDrawer = () => {
  const {
    mission,
    forceRefresh,
    setForceRefresh,
    isDrawerOpen,
    setIsDrawerOpen,
    isLoading
  } = useMissionContext();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState({});
  const { fields } = useFields({ setFileList, fileList });
  const [resetFields, setResetFields] = useState(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const customFormData = new FormData();

  const config = useConfig({
    fileList,
    customFormData,
    form
  });
  const { width } = useHandleResize();

  useEffect(() => {
    form.resetFields();
  }, [resetFields]);

  return (
    <Drawer
      title={t('missions.drawers.titles.intermediate_project')}
      onClose={() =>
        setIsDrawerOpen((prev) => ({ ...prev, intermediate_project: false }))
      }
      open={isDrawerOpen.intermediate_project}
      width={width < 900 ? '80%' : '40%'}
    >
      <CreateUpdateContainer
        customFormInstance={form}
        customFormData={customFormData}
        fields={fields}
        config={config}
        isFieldsLoading={isLoading}
        purpose="create"
        baseUrl={`missions/add-intermediate-project-documents?missionId=${mission._id}`}
        resource="missions"
        withFilesManager={false}
        customErrorMessage={(e) => {
          setResetFields(!resetFields);
          message(e);
        }}
        withEnums={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{ ...isDrawerOpen, intermediate_project: false }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
