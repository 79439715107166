import dayjs from 'dayjs';
import { Drawer, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePCSAppointmentFields } from '../fields/PCSAppointment';
import { useUnavailabilityFields } from '../fields/unavailability';
import { useInternAppointmentFields } from '../fields/internAppointment';
import { useAgendaContext } from '../../../contexts/AgendaContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useConfig } from '../utils/createUpdateConfig';
import { GetEnums } from '../../../utils/getEnums';
import { CreateUpdateContainer } from '../../../components';
import { Event } from '../../../models/Event';
import { useHandleResize } from '../../../utils/handleResize';

/**
 * Custom hook that returns an object containing the fields for the DrawerEvent component.
 * @hook
 * @param {Object} params - The parameters object.
 * @param {Array} params.syndics - The array of syndics.
 * @param {Function} params.setSyndic - The function to set the syndic.
 * @param {Array} params.managers - The array of managers.
 * @param {Array} params.preprods - The array of preproductions.
 * @param {Object} params.user - The user object.
 * @param {boolean} params.isFieldsLoading - The flag indicating whether the fields are loading.
 * @param {Object} params.enums - The object containing the enums.
 * @returns {Object} The fields object.
 */
const modelForms = {
  PCS_APPOINTMENT: (
    syndics,
    setSyndic,
    managers,
    preprods,
    user,
    isFieldsLoading
  ) =>
    usePCSAppointmentFields({
      syndics,
      setSyndic,
      managers,
      preprods,
      isFieldsLoading
    }),
  INTERN: (form) => useInternAppointmentFields(form),
  UNAVAILABILITY: (
    syndics,
    setSyndic,
    managers,
    preprods,
    user,
    isFieldsLoading,
    enums
  ) =>
    useUnavailabilityFields({
      enums: enums.unvailability_type
    })
};

/**
 * Represents the DrawerEvent component.
 * @component
 * @returns {JSX.Element} The rendered DrawerEvent component.
 * @example
 * <DrawerEvent />
 */
export const DrawerEvent = () => {
  const { t } = useTranslation();
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    purpose,
    formToOpen,
    drawerTitle,
    event,
    customUser
  } = useAgendaContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [form] = Form.useForm();
  const { dispatchAPI, user } = useAuthContext();
  const { syndics, setForceRefresh, forceRefresh } = useGlobalContext();
  const [syndic, setSyndic] = useState({});
  const [managers, setManagers] = useState([]);
  const [enums, setEnums] = useState({});
  const [isCreateUpdateOpen, setIsCreateUpdateOpen] = useState(true);
  const eventId = event?._id;
  const [preprods, setPreprods] = useState([]);
  const config = useConfig({
    user,
    eventType: formToOpen,
    eventId,
    userTarget: customUser || user,
    syndic,
    setSyndic,
    form
  });
  const { getEnums } = GetEnums();
  const { width } = useHandleResize();

  useEffect(() => {
    (async () => {
      if (Object.keys(syndic).length) {
        const data = await Event.getManagers(syndic._id);
        setManagers(data);
      }
      setForceRefresh(!forceRefresh);
    })();
  }, [syndic]);

  const getRelatedEvents = async (groupId) => {
    const { data: events } = await dispatchAPI('GET', {
      url: `/event?groupId=${groupId}&fields=time_slots`
    });

    return {
      startDate: dayjs(events[0].time_slots.start),
      endDate: dayjs(events[events.length - 1].time_slots.end)
    };
  };

  useEffect(() => {
    if (event?.groupId) {
      (async () => {
        const { startDate, endDate } = await getRelatedEvents(event.groupId);
        form.setFieldsValue({
          time_slots: [startDate, endDate]
        });
      })();
    }
  }, [event?._id]);

  useEffect(() => {
    (async () => {
      const eventsEnums = await getEnums({ resource: 'events' });
      setEnums(eventsEnums[0]);
      setIsFieldsLoading(false);
    })();
    (async () => {
      if (!syndic?._id) return;
      const extraQuery = syndic?._id
        ? `syndic._id=${syndic._id}&status=PRODUCTION`
        : '';
      const { data } = await dispatchAPI('GET', {
        url: `/pcs-pre-productions?${extraQuery}`
      });
      setPreprods(data);
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (!isCreateUpdateOpen) {
      setForceRefresh(!forceRefresh);
      setIsDrawerOpen(false);
    }
  }, [isCreateUpdateOpen]);

  return (
    <Drawer
      title={t(drawerTitle)}
      width={width < 1000 ? '80%' : '40%'}
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <CreateUpdateContainer
        config={config}
        fields={
          modelForms[formToOpen](
            syndics,
            setSyndic,
            managers,
            preprods,
            customUser,
            isFieldsLoading,
            enums
          ).fields
        }
        loadingFields={isFieldsLoading}
        purpose={purpose}
        customId={event?._id}
        populate="syndic,manager,csm_type,pcs_preproduction"
        baseUrl="events"
        resource="events"
        customFormInstance={form}
        layout="vertical"
        withFilesManager={false}
        withCustomPageHeader
        withEnums={false}
        setIsCreateUpdateOpen={setIsCreateUpdateOpen}
        isCreateUpdateOpen={false}
        cancelCustomAction={() => setIsDrawerOpen(false)}
      />
    </Drawer>
  );
};
