import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

/**
 * Renders a form with two Select components for filtering syndics and consultants.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.consultants - The array of consultants.
 * @param {boolean} props.isFiedlLoading - Indicates if the field is loading.
 * @returns {JSX.Element} - The rendered form.
 */
export const ExtraButtons = ({
  setFilters,
  isFiedlLoading,
  enums,
  forceRefresh,
  setForceRefresh
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  /**
   * Filters the options based on the input value.
   * @function
   * @param {string} input - The input value.
   * @param {Object} option - The option object.
   * @returns {boolean} - Whether the option should be displayed or not.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} layout="inline">
      <Form.Item name={['status']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ consultants: null });
            setFilters(values ? `status=${values}` : '');
            setForceRefresh(!forceRefresh);
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFiedlLoading}
          placeholder={t('placeholder.search_by_status')}
        >
          {(enums || []).map((status) => (
            <Option key={status} value={status}>
              {t(`pcs-pre-productions.tags.${status}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

ExtraButtons.propTypes = {
  enums: PropTypes.shape({}),
  setFilters: PropTypes.func,
  isFiedlLoading: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

ExtraButtons.defaultProps = {
  enums: {},
  setFilters: () => {},
  isFiedlLoading: true,
  forceRefresh: false,
  setForceRefresh: () => {}
};
