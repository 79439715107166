import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Typography,
  Button,
  Tooltip,
  Divider,
  Popconfirm,
  Flex
} from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  WarningOutlined,
  DeleteOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { setDocumentIcon } from '../../../components/setDocumentIcon';
import { PcsPreProduction } from '../../../models/PcsPreProduction';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Text } = Typography;

/**
 * Custom hook that returns an array of columns for a table.
 * @hook
 * @param {Object} props The props object.
 * @param {boolean} props.forceRefresh The force refresh state.
 * @param {function} props.setForceRefresh The function to set the force refresh state.
 * @returns {Array} The array of columns.
 */
export const useColumns = ({ forceRefresh, setForceRefresh }) => {
  const { t } = useTranslation();
  const { viewDocument, downloadDocument } = useDownloadDocument();
  const { user } = useAuthContext();
  const isCustomerService =
    user?.role === 'users:NOMADE_CUSTOMER_SERVICE_MANAGER';

  return [
    {
      key: 'remarks',
      render: (record) =>
        record?.remarks && (
          <Tooltip title={record.remarks}>
            <SearchOutlined />
          </Tooltip>
        ),
      sorter: true
    },
    {
      title: t('pcs-pre-productions.form.consultant'),
      key: 'consultant',
      render: (record) => {
        const consultant = record?.syndic?.consultant;
        if (consultant) {
          const lastInitial = consultant.last_name
            ? consultant.last_name.charAt(0)
            : '';
          const firstInitial = consultant.first_name
            ? consultant.first_name.charAt(0)
            : '';
          return `${lastInitial}.${firstInitial}`;
        }
        return '-';
      },

      sorter: true
    },
    {
      title: t('pcs-pre-productions.form.syndic'),
      key: 'syndic',
      render: (record) => (
        <Row>
          <Col span={24}>
            <Text>
              {`${record?.syndic?.society_name || '-'}`}
              <br />
              {`${record?.syndic?.code ? `(${record?.syndic?.code})` : ''}`}
              <br />
              {`${record?.manager?.last_name || ''}`}
              {`${record?.manager?.first_name || ''}`}
            </Text>
          </Col>
          <Col span={24}>
            <Text>{record?.manager?.email || ''}</Text>
          </Col>
        </Row>
      ),
      sorter: true
    },
    {
      title: (
        <Text className="break-column-title">
          {t('pcs-pre-productions.form.collective_ownerships_number')}
        </Text>
      ),
      key: 'collective_ownerships_number',
      dataIndex: 'collective_ownerships',
      render: (collective_ownerships) => collective_ownerships?.length || 1
    },
    {
      title: (
        <Text className="break-column-title">
          {t('pcs-pre-productions.form.address.title')}
        </Text>
      ),
      key: 'collective_ownership_address',
      render: (record) =>
        record?.collective_ownerships?.length ? (
          '-'
        ) : (
          <Row>
            <Col span={24}>
              <Text>{`${record?.code || ''} - ${record?.name || ''}`}</Text>
            </Col>
            <Col span={24}>
              <Text>{record?.address?.street || ''}</Text>
            </Col>
            <Col span={24}>
              <Text>{record?.address?.postal_code || ''}</Text>
            </Col>
            <Col span={24}>
              <Text>{record?.address?.city || ''}</Text>
            </Col>
          </Row>
        )
    },
    {
      title: t('pcs-pre-productions.form.documents'),
      key: 'documents',
      dataIndex: 'documents',
      render: (documents) =>
        documents.map((doc) =>
          (doc.files || []).map((file) => (
            <Flex vertical align="left">
              <Tooltip title={file.metadata.originalName} key={file._id}>
                <Button
                  type="link"
                  onClick={() =>
                    file.type === 'application/pdf'
                      ? viewDocument(doc.file)
                      : downloadDocument({
                          _id: file._id,
                          metadata: file.metadata,
                          contentType: file.contentType
                        })
                  }
                >
                  {setDocumentIcon(file.contentType)}
                </Button>
              </Tooltip>
            </Flex>
          ))
        )
    },
    {
      title: (
        <Text className="break-column-title">
          {t('pcs-pre-productions.form.created_at')}
        </Text>
      ),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) => dayjs(created_at).format('DD-MM-YY'),
      sorter: true
    },
    {
      title: (
        <Text className="break-column-title">
          {t('pcs-pre-productions.form.last_validation')}
        </Text>
      ),
      key: 'last_validation',
      dataIndex: 'last_validation',
      sorter: true,
      render: (last_validation) => dayjs(last_validation).format('DD-MM-YY')
    },
    {
      title: t('pcs-pre-productions.form.reminder'),
      key: 'reminder',
      dataIndex: 'reminder',
      render: () => 'in progress',
      sorter: true
    },
    {
      title: (
        <Text className="break-column-title">
          {t('pcs-pre-productions.form.meeting_date')}
        </Text>
      ),
      key: 'meeting_date',
      dataIndex: 'meeting_date',
      render: () => 'in progress',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          {record?.status !== 'DRAFT' ? (
            <Link
              to={{
                pathname: `show/${record?._id}`
              }}
            >
              <EyeOutlined style={{ fontSize: 18 }} />
            </Link>
          ) : null}
          {record?.status !== 'DELETED' && !isCustomerService ? (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `edit/${record?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() =>
                  PcsPreProduction.deleteResource({
                    id: record?._id,
                    setForceRefresh,
                    forceRefresh
                  })
                }
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            </>
          ) : null}
        </>
      )
    }
  ];
};
