import moment, { weekdays } from 'moment';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useAgendaContext } from '../../../contexts/AgendaContext';

function getDates(startDate: Date, endDate: Date) {
  const dates = [];
  let currentDate = startDate;
  const addDays = function (current: Date, days: number) {
    const date = new Date(current);
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate < endDate) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
}

export const createEvents = (
  rawEvents: any[],
  weekCellRender: (
    event: object,
    allDay: boolean,
    forceRefresh: boolean,
    setForceRefresh: any,
    setIsModalOpen: any,
    setEvent: any,
    setPurpose: any,
    setFormToOpen: any,
    setDrawerTitle: any,
    isEventOwner: boolean
  ) => JSX.Element,
  view: string,
  subMode: string,
  weekPeriod: any
) => {
  const events: any[] = [];
  const { forceRefresh, setForceRefresh } = useGlobalContext();
  const {
    setIsDrawerOpen,
    setEvent,
    setPurpose,
    setFormToOpen,
    setDrawerTitle,
    getDrawerToOpen,
    isEventOwner
  } = useAgendaContext();
  if (subMode === 'custom') {
    const dates = getDates(weekPeriod.startDate, weekPeriod.endDate)

    dates.map((date, index) => {
      if(index !== 0 && index !== dates.length - 1)
      rawEvents.push({
        name: 'Tâche',
        date: date,
        done: false,
        emptyTask : true
      });
    })
  }
  const generateKey = (item: any) => `${item.date}-${item.emptyTask}`;
  const filteredEvents = Array.from(new Map(rawEvents.map(item => [generateKey(item), item])).values())
  
  filteredEvents?.map(async (event) => {
    if (event.time_slots) {
      const startTime = moment(event.time_slots.start).toDate();
      const endTime = moment(event.time_slots.end).toDate();
      const formattedEvent = {
        ...event,
        id: event._id,
        startTime,
        endTime
      };

      events.push({
        startTime,
        endTime,
        backgroundColor:
          view === 'syndic-unavailable' ? 'grey' : event.backgroundColor,
        title: weekCellRender(
          formattedEvent,
          false,
          forceRefresh,
          setForceRefresh,
          setIsDrawerOpen,
          setEvent,
          setPurpose,
          setFormToOpen,
          setDrawerTitle,
          isEventOwner
        ),
        id: event._id
      });
    } else {
      const startTime = moment(event.date).startOf('day').toDate();
      const endTime = moment(event.date).endOf('day').toDate();
      
      events.push({
        allDay: true,
        startTime,
        endTime,
        title: weekCellRender(
          event,
          true,
          forceRefresh,
          setForceRefresh,
          setIsDrawerOpen,
          setEvent,
          setPurpose,
          setFormToOpen,
          setDrawerTitle,
          isEventOwner
        ),
        key: event._id
      });
    }
  });

  return events;
};
