import dayjs from 'dayjs';
import Proptypes from 'prop-types';
import { Form, Input, InputNumber, DatePicker, Typography } from 'antd';
import { DisbursementMethods } from './disbursementMethods';

const { Text } = Typography;

/**
 * Renders the notary disbursement data based on the input type and record.
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.dataIndex - The index of the data.
 * @param {Object} props.record - The record object.
 * @param {string} props.inputType - The type of input.
 * @param {Function} props.setFinalAmounts - The function to set the final amounts.
 * @param {Object} props.form - The form object.
 * @returns {JSX.Element|string} - The rendered notary disbursement data.
 */
export const RenderNotaryDisbursementDatas = ({
  dataIndex,
  record,
  inputType,
  setFinalAmounts,
  form
}) => {
  const { handleFinalAmount } = DisbursementMethods();

  if (record?.default) {
    switch (inputType) {
      case 'input':
        return <Text>{record[dataIndex]}</Text>;
      case 'inputNumber':
        return <Text>{`${record[dataIndex]} €`}</Text>;
      case 'date':
        return <Text>{dayjs(record[dataIndex]).format('DD-MM-YYYY')}</Text>;
      default:
        return <Text>{record[dataIndex]}</Text>;
    }
  } else {
    switch (inputType) {
      case 'input':
        return (
          <Form.Item
            name={['disbursement_and_notary_costs', record._id, dataIndex]}
            style={{ margin: 0 }}
          >
            <Input />
          </Form.Item>
        );
      case 'inputNumber':
        return (
          <Form.Item
            name={['disbursement_and_notary_costs', record._id, dataIndex]}
            style={{ margin: 0 }}
          >
            <InputNumber
              addonAfter="€"
              onChange={() => {
                if (dataIndex === 'disbursement') {
                  handleFinalAmount({ form, setFinalAmounts });
                }
              }}
            />
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item
            name={['disbursement_and_notary_costs', record._id, dataIndex]}
            style={{ margin: 0 }}
          >
            <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            name={['disbursement_and_notary_costs', record._id, dataIndex]}
            style={{ margin: 0 }}
          >
            <Input />
          </Form.Item>
        );
    }
  }
};

RenderNotaryDisbursementDatas.propTypes = {
  dataIndex: Proptypes.string.isRequired,
  record: Proptypes.shape({ _id: Proptypes.string, default: Proptypes.bool })
    .isRequired,
  inputType: Proptypes.string.isRequired,
  setFinalAmounts: Proptypes.func.isRequired,
  form: Proptypes.shape({}).isRequired
};
