import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import {
  Button,
  List,
  Popconfirm,
  Typography,
  message as antdMessage
} from 'antd';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { deleteDocument } from '../../../utils/deleteDocument';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../contexts/AuthContext';

const { Text } = Typography;

/**
 * Renders a single item in the documents list.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.item - The document item.
 * @param {Object} props.syndic - The syndic object.
 * @param {Function} props.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - The flag to force refresh.
 * @param {boolean} props.editPermissions - The flag to indicate if edit permissions are enabled.
 * @returns {JSX.Element} The rendered component.
 */
export const DocumentsListItem = ({
  item,
  syndic,
  setForceRefresh,
  forceRefresh,
  editPermissions
}) => {
  const { downloadDocument, viewDocument } = useDownloadDocument();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const documentContainingFile = syndic.documents.find((doc) =>
    doc.files.some((file) => file._id === item._id)
  );

  return (
    <List.Item
      actions={[
        <>
          <Button type="link" onClick={() => viewDocument(item)}>
            <EyeOutlined />
          </Button>
          <Button
            type="link"
            onClick={() =>
              downloadDocument({
                _id: item._id,
                metadata: item.metadata,
                contentType: item.contentType
              })
            }
          >
            <DownloadOutlined />
          </Button>
          {editPermissions && (
            <Popconfirm
              title={t('syndics.show.modals.delete.title')}
              okText={t('syndics.show.modals.delete.ok')}
              cancelText={t('syndics.show.modals.delete.cancel')}
              onConfirm={() => {
                deleteDocument({
                  dispatchAPI,
                  syndic,
                  setForceRefresh,
                  forceRefresh,
                  message,
                  file_id: item._id,
                  document_id: documentContainingFile
                    ? documentContainingFile._id
                    : null
                });
                antdMessage.success({
                  content: t('datatable.message.success.deleteline'),
                  icon: <DeleteOutlined style={{ color: 'red' }} />
                });
                return true;
              }}
            >
              <Button type="link">
                <DeleteOutlined style={{ color: 'red' }} />
              </Button>
            </Popconfirm>
          )}
        </>
      ]}
    >
      <Text>{item?.metadata?.originalName}</Text>
    </List.Item>
  );
};

DocumentsListItem.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    metadata: PropTypes.shape({
      originalName: PropTypes.string
    }),
    contentType: PropTypes.string
  }).isRequired,
  syndic: PropTypes.shape({
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        files: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string
          })
        )
      })
    )
  }).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  editPermissions: PropTypes.bool
};

DocumentsListItem.defaultProps = {
  editPermissions: true
};
