/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Typography, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { PredefinedMessage } from '../../../models/PredefinedMessage';

const { Text } = Typography;

/**
 * Component for displaying predefined message details.
 * @component
 *
 * @returns {JSX.Element} The rendered component.
 */
export const ShowPredefinedMessage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [predefinedMessage, setPredefinedMessage] = useState({});

  useEffect(() => {
    (async () => {
      const predefinedMessageData = await PredefinedMessage.getResource(id);
      setPredefinedMessage(predefinedMessageData);
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={t('predefined-messages.show.title')} />
      <ContentCustom>
        <Card title={predefinedMessage?.label}>
          <Skeleton loading={isLoading}>
            <Text>
              <div
                dangerouslySetInnerHTML={{ __html: predefinedMessage?.content }}
              />
            </Text>
          </Skeleton>
        </Card>
      </ContentCustom>
    </>
  );
};
