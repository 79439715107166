import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  invoiceTag,
  missionTag
} from '../../../../../utils/constants/tagColors';

/**
 * The `useColumns` hook is designed to generate column definitions for a table component that displays mission-related information.
 * Each column is configured to display specific attributes of a mission, such as priority, steps, release date, and last update,
 * along with action icons for further interaction. The hook utilizes the `useTranslation` hook for internationalization,
 * ensuring that column titles and certain content are localized.
 *
 * Returns:
 * An array of column definitions for use in an Ant Design `Table` component. Each column definition includes properties such as:
 * - `title`: The localized title of the column, serving as the header.
 * - `key`: A unique key for the column, used internally by the table for tracking and sorting purposes.
 * - `dataIndex`: The path to the data value for the column in the data source, used to fetch the appropriate value for each cell.
 * - `render`: A function that returns the React elements to be rendered in each cell of the column. This can include formatting of data, rendering of icons, or wrapping content in interactive components like `Link`.
 * - `sorter`: A boolean or function indicating whether the column is sortable. If true, the table will automatically handle sorting based on this column.
 * - `align`: Alignment of the column content (e.g., 'right').
 * - `fixed`: Indicates if the column should be fixed at the 'right' side of the table, preventing it from scrolling out of view horizontally.
 *
 * Specific columns include:
 * - A priority column that uses an `ExclamationCircleFilled` icon to indicate high-priority missions.
 * - Principal and sub-step columns that display the current step of the mission as a colored `Tag`, with the color and text determined by the mission's status.
 * - Release date and last update columns that format date strings using `dayjs` for readability.
 * - An action column that provides a link to a detailed view of the mission, using an `EyeOutlined` icon wrapped in a `Link` component for navigation.
 *
 * Usage:
 * This hook is intended for use in components where a table displaying a list of missions is required. It allows for easy integration of sorting, formatting, and linking functionalities within the table.
 */

export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.form.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('invoices.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (type ? t(`invoices.tags.${type}`) : ''),
      sorter: true
    },
    {
      title: t('invoices.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        status ? (
          <Tag color={invoiceTag[status]}>{t(`invoices.tags.${status}`)}</Tag>
        ) : (
          ''
        ),
      sorter: true
    },
    {
      title: t('invoices.form.mission'),
      key: 'mission',
      render: (record) =>
        record?.status ? (
          <Tag color={missionTag[record?.mission?.type]}>
            {t(`missions.tags.${record?.mission?.type}`)}
          </Tag>
        ) : (
          ''
        ),
      sorter: true
    },
    {
      title: t('invoices.form.last_reminder'),
      key: 'last_reminder',
      dataIndex: 'last_reminder',
      render: (last_reminder) =>
        last_reminder
          ? dayjs(last_reminder).format('DD-MM-YYYY à HH[h]mm')
          : 'Jamais',
      sorter: true
    },
    {
      title: t('invoices.form.sending_date'),
      key: 'sending_date',
      dataIndex: 'sending_date',
      render: (sending_date) =>
        sending_date ? dayjs(sending_date).format('DD-MM-YYYY à HH[h]mm') : '-',
      sorter: true
    },
    {
      title: t('invoices.form.settlement_date'),
      key: 'settlement_date',
      dataIndex: 'settlement_date',
      render: (settlement_date) =>
        settlement_date
          ? dayjs(settlement_date).format('DD-MM-YYYY à HH[h]mm')
          : '-',
      sorter: true
    },
    {
      title: t('invoices.form.cancellation_fee'),
      key: 'cancellation_fee',
      dataIndex: 'cancellation_fee',
      render: (cancellation_fee) =>
        cancellation_fee
          ? `${parseFloat(cancellation_fee).toFixed(2)} €`
          : '0.00 €',
      sorter: true
    },
    {
      title: t('invoices.form.total_ht'),
      key: 'total_ht',
      dataIndex: 'total_ht',
      render: (total_ht) =>
        total_ht ? `${parseFloat(total_ht).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      title: t('invoices.form.tva'),
      key: 'tva',
      dataIndex: 'tva',
      render: (tva) => (tva ? `${parseFloat(tva).toFixed(2)} €` : '0.00 €'),
      sorter: true
    },
    {
      title: t('invoices.form.disbursement'),
      key: 'disbursement',
      dataIndex: 'disbursement',
      render: (disbursement) =>
        disbursement ? `${parseFloat(disbursement).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      title: t('invoices.form.total'),
      key: 'total_ttc',
      dataIndex: 'total_ttc',
      render: (total_ttc) =>
        total_ttc ? `${parseFloat(total_ttc).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      title: t('invoices.form.paid'),
      key: 'paid',
      dataIndex: 'paid',
      render: (paid) => (paid ? `${parseFloat(paid).toFixed(2)} €` : '0.00 €'),
      sorter: true
    },
    {
      title: t('invoices.form.left_to_pay'),
      key: 'left_to_pay',
      dataIndex: 'left_to_pay',
      render: (left_to_pay) =>
        left_to_pay ? `${parseFloat(left_to_pay).toFixed(2)} €` : '0.00 €',
      sorter: true
    }
  ];
};
