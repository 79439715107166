import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, notification, Row, Spin, Steps } from 'antd';
import { BellOutlined, MenuOutlined } from '@ant-design/icons';
import { useMissionContext } from '../../../../contexts/MissionContext';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { showTitle } from './utils/showTitle';
import { AlertCard } from './components/AlertCard';
import { DocumentCard } from './components/DocumentCard';
import { MessageContextProvider } from '../../../../contexts/MessageContext';
import { Messages } from '../../../../components/Messages/Messages';
import { InformationsCard } from './components/InformationsCard';
import { InvoiceCard } from './components/InvoiceCard';
import { AddDocumentCard } from './components/AddDocumentCard';
import { CerfaOrderDrawer } from './drawers/CerfaOrderDrawer';
import { MissionMonitoringCard } from './components/MissionMonitoringCard';
import { OrdersCard } from './components/OrdersCard';
import { auditOneTwoSteps } from './steps/auditOneTwoSteps';
import { useMenu } from './utils/plansMenu';
import { Mission } from '../../../../models/Mission';
import { DisbursementModal } from './modals/DisbursementModal';
import { AlertDrawer } from './drawers/AlertDrawer';
import { NoteDrawer } from './drawers/NoteDrawer';
import { BlockMissionCreditNote } from './modals/BlockMissionCreditNoteModal';
import { CancelMissionModal } from './modals/CancelMissionModal';
import { confirmRefundsButtons } from './utils/confirmRefundsButtons';
import { ConfirmRefundsModal } from './modals/ConfirmRefundsModal';

/**
 * Renders the ShowAuditOneTwoMission component.
 *
 * @component
 * @returns {JSX.Element} The rendered ShowAuditOneTwoMission component.
 */
export const ShowAuditOneTwoMission = () => {
  const { id, type } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const {
    getMissionDatas,
    current,
    mission,
    forceRefresh,
    addDocumentCardDatas,
    isDrawerOpen,
    isOrderCardUp,
    isShowToBeOrderedCard,
    setIsDrawerOpen,
    setCurrent
  } = useMissionContext();

  const { principalItems } = auditOneTwoSteps({
    step: current.principal
  });

  const items = {
    AUDIT_1_2: { principal: principalItems }
  };

  const menu = {
    items: useMenu({ mission })
  };

  useEffect(() => {
    (async () => {
      await getMissionDatas({
        id,
        type,
        message: notification,
        t
      });
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (mission.type) {
      if (current.principal === 0 && !mission.transformation_word_ok) {
        setIsDrawerOpen((prev) => ({
          ...prev,
          parameters: true
        }));
      }
      setIsLoading(false);
    }
  }, [mission]);

  return (
    <>
      <PageHeaderCustom title={showTitle({ record: mission })} />
      <Row justify="end" gutter={[5, 5]} style={{ marginRight: 15 }}>
        {confirmRefundsButtons({ mission, setIsDrawerOpen })}
        {mission?.steps?.principal === 'TRANSFORMATION_WORD' ? (
          <Col>
            <Button type="primary" icon={<BellOutlined />}>
              {t('missions.buttons.provider_reminder')}
            </Button>
          </Col>
        ) : null}
        <Col>
          <Dropdown menu={menu}>
            <Button type="link">{t('missions.buttons.more_actions')}</Button>
          </Dropdown>
        </Col>
        <Col>
          <Button type="link" icon={<MenuOutlined />} />
        </Col>
      </Row>
      <ContentCustom>
        <Steps
          className="main-steps"
          current={current.principal}
          items={items[type].principal}
          style={{ marginBottom: 24 }}
          size="small"
          onChange={async (value) => {
            setIsLoading(true);
            setCurrent((prev) => ({
              ...prev,
              principal: value
            }));
            await Mission.patchResource({
              id,
              values: {
                steps: {
                  principal: principalItems[value].key
                    .toUpperCase()
                    .replace(' ', '_')
                }
              }
            });
            setIsLoading(false);
          }}
        />
        <Row gutter={[16, 16]}>
          <AlertCard />
          <Col span={addDocumentCardDatas.open ? 14 : 24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                {isShowToBeOrderedCard ? (
                  <OrdersCard status="TO_BE_ORDERED" />
                ) : null}
                {isOrderCardUp && current.principal > 1 ? (
                  <OrdersCard status="ORDERED" />
                ) : null}
              </Col>
              <Col span={24}>
                <MissionMonitoringCard
                  mission={mission}
                  isLoading={isLoading}
                />
              </Col>
              <Col span={addDocumentCardDatas.open ? 24 : 16}>
                <Row>
                  <Col span={24}>
                    <DocumentCard />
                  </Col>
                </Row>
              </Col>
              <Col span={addDocumentCardDatas.open ? 24 : 8}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <MessageContextProvider id={id} model="Mission">
                      <Messages missionType="AUDIT_1_2" withCreateButton />
                    </MessageContextProvider>
                  </Col>
                  {mission?.pcs_pre_production ? (
                    <Col span={24}>
                      <MessageContextProvider
                        id={mission?.pcs_pre_production}
                        model="Pcs-pre-production"
                      >
                        <Messages
                          cardTitle={t(
                            'missions.cards.titles.pcs_pre_production_messages'
                          )}
                          withoutDefaultActiveKey
                          withCreateButton={false}
                        />
                      </MessageContextProvider>
                    </Col>
                  ) : null}
                  <Col span={24}>
                    <InformationsCard />
                  </Col>
                </Row>
              </Col>
              {Object.keys(mission).length ? (
                <Col span={24}>
                  <InvoiceCard type={mission.type} />
                </Col>
              ) : null}
              {current.principal > 1 && !isOrderCardUp ? (
                <Col span={24}>
                  <OrdersCard status="ORDERED" />
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col
            className="add-cerfa-docs-card"
            span={10}
            style={{ display: addDocumentCardDatas.open ? 'block' : 'none' }}
          >
            <AddDocumentCard />
          </Col>
        </Row>
      </ContentCustom>
      <Spin
        spinning={isLoading}
        fullscreen
        tip={t('missions.messages.loading')}
      />
      {isDrawerOpen?.cerfa_order?.open ? <CerfaOrderDrawer /> : null}
      {isDrawerOpen.disbursement ? <DisbursementModal /> : null}
      {isDrawerOpen.alert ? <AlertDrawer /> : null}
      {isDrawerOpen.note ? <NoteDrawer /> : null}
      {isDrawerOpen.block_mission_credit_note ? (
        <BlockMissionCreditNote />
      ) : null}
      {isDrawerOpen.cancel_mission ? <CancelMissionModal /> : null}
      {isDrawerOpen.confirm_refunds ? <ConfirmRefundsModal /> : null}
    </>
  );
};
