import { useTranslation } from 'react-i18next';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Divider,
  InputNumber,
  Button,
  Tooltip
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { inputDisplaySmall } from '../../../../../utils/generateFormItem/utils/inputDisplaySmall';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { PcajpArray } from '../../../../../models/PcajpArray';
import { Document } from '../../../../../models/Document';
import { setDocumentIcon } from '../../../../../components/setDocumentIcon';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';

const { Option } = Select;
const { TextArea } = Input;

/**
 * Custom hook that returns an array of columns for the missions view table.
 * @hook
 * @param {Object} props - Component props
 * @param {Object} props.showPrincipalLotField - Object containing the state of the principal lot field.
 * @param {Function} props.setShowPrincipalLotField - Function to set the state of the principal lot field.
 * @param {Function} props.setIsSubmitting - Function to set the state of the isSubmitting variable.
 * @returns {Array} Array of column objects.
 */
export const useColumns = ({
  showPrincipalLotField,
  setShowPrincipalLotField,
  setIsSubmitting
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { pcajpArrayEnums, setForceRefresh } = useMissionContext();
  const { viewDocument, downloadDocument } = useDownloadDocument();

  const draggerProps = ({ id }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      await Document.uploadDocument({
        id,
        file: objectFile.file,
        setForceRefresh,
        t
      });
    },
    fileList: []
  });

  const setEditingColor = (isEdited) =>
    isEdited
      ? { border: '2px solid blue', backgroundColor: 'rgb(232,237,255)' }
      : {};

  return [
    {
      title: t('missions.form.batch_numbers'),
      key: 'batch_numbers',
      dataIndex: 'batch_numbers',
      render: (_, record) => (
        <Form.Item name={[record._id, 'batch_numbers']}>
          <Input
            disabled={
              ![
                'users:LAWYER',
                'users:NOMADE_PRODUCTION',
                'admins:ADMIN',
                'admins:SUPER-ADMIN'
              ].includes(user.role)
            }
            style={setEditingColor(record?.batch_numbers?.is_edited)}
          />
        </Form.Item>
      ),
      sorter: true
    },
    {
      title: t('missions.form.private_usages'),
      key: 'private_usages',
      dataIndex: 'private_usages',
      width: 500,
      render: (_, record) => (
        <Row>
          {record.private_usages.map((privateUsage, index) => (
            <Col span={24} key={privateUsage._id}>
              {index !== 0 && <Divider />}
              <Form.Item
                name={[
                  record._id,
                  'private_usages',
                  privateUsage._id,
                  'private_usage'
                ]}
              >
                <Select
                  disabled={
                    ![
                      'users:LAWYER',
                      'users:NOMADE_PRODUCTION',
                      'admins:ADMIN',
                      'admins:SUPER-ADMIN'
                    ].includes(user.role)
                  }
                  style={setEditingColor(
                    privateUsage?.private_usage?.is_edited
                  )}
                >
                  {(pcajpArrayEnums?.private_usages || []).map((el) => (
                    <Option key={el} value={el}>
                      {t(`missions.tags.${el}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={[
                  record._id,
                  'private_usages',
                  privateUsage._id,
                  'enjoyment_right'
                ]}
              >
                <Select
                  disabled={
                    ![
                      'users:LAWYER',
                      'users:NOMADE_PRODUCTION',
                      'admins:ADMIN',
                      'admins:SUPER-ADMIN'
                    ].includes(user.role)
                  }
                  style={setEditingColor(
                    privateUsage?.enjoyment_right?.is_edited
                  )}
                >
                  {(pcajpArrayEnums?.enjoyment_rights || []).map((el) => (
                    <Option key={el} value={el}>
                      {t(`missions.tags.${el}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={[
                  record._id,
                  'private_usages',
                  privateUsage._id,
                  'to_validate'
                ]}
              >
                <Select
                  disabled={
                    ![
                      'users:LAWYER',
                      'users:NOMADE_PRODUCTION',
                      'admins:ADMIN',
                      'admins:SUPER-ADMIN'
                    ].includes(user.role)
                  }
                  style={setEditingColor(privateUsage?.to_validate?.is_edited)}
                  onChange={(el) =>
                    setShowPrincipalLotField((prev) => ({
                      ...prev,
                      [privateUsage._id]: el === 'LOT_ENJOYMENT_RIGHT'
                    }))
                  }
                >
                  {(pcajpArrayEnums?.to_validates || []).map((el) => (
                    <Option key={el} value={el}>
                      {t(`missions.tags.${el}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {showPrincipalLotField[privateUsage._id] ? (
                <Form.Item
                  label={t('missions.form.principal_lot_enjoyment_right')}
                  name={[
                    record._id,
                    'private_usages',
                    privateUsage._id,
                    'principal_lot_enjoyment_right'
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              ) : null}
            </Col>
          ))}
          <Button
            type="link"
            onClick={() =>
              PcajpArray.addPrivateUsage({
                id: record._id,
                setForceRefresh,
                setIsSubmitting
              })
            }
          >
            <PlusOutlined />
          </Button>
        </Row>
      ),
      sorter: true
    },
    {
      title: t('missions.form.documents'),
      key: 'file',
      render: (record) => (
        <Row>
          <Col span={24}>
            {inputDisplaySmall(
              true,
              null,
              { ...draggerProps({ id: record.document._id }) },
              false,
              t
            )}
          </Col>
          <Col>
            {(record?.document?.files || []).map((file) => (
              <Tooltip title={file.metadata.originalName}>
                <Button
                  type="link"
                  onClick={() =>
                    file.type === 'application/pdf'
                      ? viewDocument(file)
                      : downloadDocument({
                          _id: file._id,
                          metadata: file.metadata,
                          contentType: file.contentType
                        })
                  }
                >
                  {setDocumentIcon(file.contentType)}
                </Button>
              </Tooltip>
            ))}
            ,
          </Col>
        </Row>
      )
    },
    {
      title: t('missions.form.validation'),
      key: 'validation',
      dataIndex: 'validation',
      render: (_, record) => (
        <Form.Item name={[record._id, 'validation']}>
          <Select
            disabled={
              ![
                'users:SYNDIC_MANAGER',
                'users:SYNDIC_ASSISTANT',
                'users:NOMADE_PRODUCTION',
                'admins:ADMIN',
                'admins:SUPER-ADMIN'
              ].includes(user.role)
            }
            style={setEditingColor(record?.validation?.is_edited)}
          >
            {(pcajpArrayEnums?.validations || []).map((el) => (
              <Option key={el} value={el}>
                {t(`missions.tags.${el}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
      sorter: true
    },
    {
      title: t('missions.form.remarks'),
      key: 'remarks',
      dataIndex: 'remarks',
      render: (_, record) => (
        <Form.Item name={[record._id, 'remarks']}>
          <TextArea
            disabled={
              ![
                'users:SYNDIC_MANAGER',
                'users:SYNDIC_ASSISTANT',
                'users:NOMADE_PRODUCTION',
                'admins:ADMIN',
                'admins:SUPER-ADMIN'
              ].includes(user.role)
            }
            style={setEditingColor(record?.remarks?.is_edited)}
          />
        </Form.Item>
      ),
      sorter: true
    }
  ];
};
