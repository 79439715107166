import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Flex, Form, Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import Launch_mission from '../../../../../assets/images/launch_mission.png';
import SyndicViewContentCustom from '../../../SyndicViewContentCustom';
import { dashboardTitle } from '../dashboardTitles';
import { RCPDocuments } from './Step3/RCPDocuments';
import { CollectiveOwnershipForm } from './Step1/CollectiveOwnershipForm';
import { CreateUpdateContainer } from '../../../../../components';
import { ChargingGridSubmission } from './Step4/ChargingGridSubmission';
import { ContractSignature } from './Step2/ContractSignature';
import { useConfig } from './utils/useConfig';
import { useDraggerFunctions } from '../../utils/DraggerField/draggerFunctions';
import { useEventConflictMessage } from '../../../utils/EventConflictMessage';
import { AppointmentForm } from '../../utils/AppointmentForm/AppointmentForm';
import { StepsButtons } from '../../utils/useStepsButtons';

const tradKeyForm = 'pcs-pre-productions.form';
const resource = 'syndic-requests';
export const stepTitlesAudit = [
  'collective_ownership_fields',
  'contract_signature',
  'submit_documents',
  'submit_charging_grid',
  'appointment'
];

/**
 * Component for creating or updating an audit.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the audit.
 * @returns {JSX.Element} The CreateUpdateAudit component.
 */
export const CreateUpdateAudit = ({ purpose }) => {
  const toPopulate = [
    'pcs_pre_production.syndic',
    'pcs_pre_production.syndic.assistants managers',
    'pcs_pre_production.documents',
    'pcs_pre_production.documents.files'
  ];
  const populateString = toPopulate.toString(',');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const [filesList, setFilesList] = useState({ rcp: [], charging_grids: [] });
  const [filesConfiguration, setFilesConfiguration] = useState([]);
  const { deleteFile } = useDraggerFunctions({ resource });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const customFormData = new FormData();
  const [current, setCurrent] = useState(0);
  const config = useConfig({
    setInitialValues,
    customFormData,
    setFilesList,
    setFilesConfiguration,
    filesList,
    filesConfiguration,
    initialValues,
    setCurrent
  });
  const [refreshAppointment, setRefreshAppointment] = useState(false);
  const { eventConflictMessage } = useEventConflictMessage({
    setRefreshAppointment
  });
  const [buttonsActive, setButtonsActive] = useState({
    next: true,
    back: true,
    saveDraft: true,
    submit: true
  });
  const { auditTitle, auditSubtitle, servicesCardStyle } = dashboardTitle();

  useEffect(() => {
    if (current === 0) {
      setButtonsActive((prev) => ({ ...prev, next: true }));
    }
    if (current === 2) {
      setButtonsActive((prev) => ({
        ...prev,
        next: true,
        saveDraft: true,
        back: false
      }));
    }
    if (current === 3) {
      setButtonsActive((prev) => ({ ...prev, back: true }));
    }
  }, [current]);

  const steps = stepTitlesAudit.map((titleKey) => ({
    title: t(`${tradKeyForm}.${titleKey}`)
  }));

  const forms = [
    <CollectiveOwnershipForm initialValues={initialValues} form={form} />,
    <ContractSignature
      initialValues={initialValues}
      form={form}
      setButtonsActive={setButtonsActive}
      setInitialValues={setInitialValues}
    />,
    <RCPDocuments
      initialValues={initialValues}
      form={form}
      filesList={filesList}
      setFilesList={setFilesList}
      setFilesConfiguration={setFilesConfiguration}
      deleteFile={deleteFile}
      filesConfiguration={filesConfiguration}
      setButtonsActive={setButtonsActive}
      purpose={purpose}
    />,
    <ChargingGridSubmission
      initialValues={initialValues}
      form={form}
      filesList={filesList}
      setFilesList={setFilesList}
      setFilesConfiguration={setFilesConfiguration}
      deleteFile={deleteFile}
      filesConfiguration={filesConfiguration}
      setButtonsActive={setButtonsActive}
      purpose={purpose}
    />,
    <AppointmentForm
      form={form}
      initialValues={initialValues}
      setInitialValues={setInitialValues}
      refreshAppointment={refreshAppointment}
      firstAvailableDate={dayjs().add(4, 'month').startOf('day')}
      appointmentInterval={30}
    />
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title
  }));

  return (
    <SyndicViewContentCustom
      title={auditTitle}
      subtitle={auditSubtitle}
      imageOnRight={Launch_mission}
      cardStyle={servicesCardStyle}
      childrenStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Steps
        style={{
          width: '97.5%'
        }}
        current={current}
        items={items}
      />
      <div style={{ width: '100%' }}>
        <CreateUpdateContainer
          customFormInstance={form}
          fields={[]}
          customErrorMessage={(e) => {
            eventConflictMessage(e);
            setIsSubmitting(false);
          }}
          populate={populateString}
          formExtra={forms[current]}
          isFieldsLoading={false}
          purpose={purpose}
          baseUrl={resource}
          resource={resource}
          config={config}
          extraQuery="?syndicview=true"
          customFormData={customFormData}
          customUpdateFormData={customFormData}
          withFilesManager={false}
          withCustomPageHeader
          withCustomButtons
          withEnums={false}
        />
      </div>
      <Flex justify="flex-end" gap="small">
        <StepsButtons
          steps={steps}
          current={current}
          setCurrent={setCurrent}
          form={form}
          setInitialValues={setInitialValues}
          buttonsActive={buttonsActive}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          extraSaveDraftValues={{
            is_syndic_draft: true,
            draft_step: current,
            status: 'DRAFT'
          }}
          extraSubmitValues={{
            status: 'ACCOUNT_MANAGER',
            draft_step: current,
            is_syndic_draft: true,
            to_validate_from_production: true
          }}
        />
      </Flex>
    </SyndicViewContentCustom>
  );
};

CreateUpdateAudit.propTypes = {
  purpose: PropTypes.string
};

CreateUpdateAudit.defaultProps = {
  purpose: 'create'
};
