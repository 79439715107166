import { message as antdMessage } from 'antd';

/**
 * Handles the error message for duplicate email.
 * @param {Object} options - The options object.
 * @param {Error} options.e - The error object.
 * @param {string} options.message - The error message.
 * @param {Function} options.t - The translation function.
 * @returns {void}
 */
export const duplicateEmailErrorMessage = ({ e, message, t }) => {
  if (
    e?.response?.data?.message &&
    e.response.data.message.includes('E11000')
  ) {
    antdMessage.warning(t('errors.message.E11000'));
  } else if (e?.response?.data?.errors) {
    let regexpErrorFound = false;
    Object.keys(e.response.data.errors).forEach((key) => {
      if (e.response.data.errors[key]?.kind === 'REGEXP') {
        message(`REGEXP.${key}`);
        regexpErrorFound = true;
      }
      if (!regexpErrorFound) {
        message(e);
      }
    });
  } else {
    message(e);
  }
};
