/**
 * Retrieves professionals from the server and categorizes them based on their role.
 *
 * @async
 * @function
 *
 * @param {Object} options - The options for retrieving professionals.
 * @param {Function} options.dispatchAPI - The function for making API requests.
 * @param {Function} options.message - The function for displaying error messages.
 * @param {Function} options.setProfessionals - The function for setting the retrieved professionals.
 * @returns {Promise<void>} - A promise that resolves when the professionals are retrieved and categorized.
 */
export const getProfessionals = async ({
  dispatchAPI,
  message,
  setProfessionals
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/users'
    });

    const { data: editorsNotaries } = await dispatchAPI('GET', {
      url: '/editor-notaries'
    });

    const professionals = {
      managers: [],
      notaries: [],
      editorNotaies: editorsNotaries,
      lawyers: [],
      surveyors: [],
      others: []
    };

    data.forEach((user) => {
      switch (user.role) {
        case 'users:SYNDIC_MANAGER':
          professionals.managers.push(user);
          break;
        case 'users:NOTARY':
          professionals.notaries.push(user);
          break;
        case 'users:LAWYER':
          professionals.lawyers.push(user);
          break;
        case 'users:SURVEYOR':
          professionals.surveyors.push(user);
          break;
        default:
          professionals.others.push(user);
      }
    });

    setProfessionals(professionals);
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
