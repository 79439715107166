import { Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../components';
import { useInformationsListContent } from './listContent/informationsColumns';

/**
 * Informations component
 * @param {Object} props - props
 * @returns {JSX.Element} Informations component
 */
export const Informations = ({ user, isLoading, t }) => {
  const informationsListContent = useInformationsListContent(user);
  return (
    <Card
      style={{ marginRight: '10px' }}
      title={t('users.show.cards.titles.informations')}
    >
      <Skeleton loading={isLoading} active>
        <DescriptionList data={informationsListContent} translate vertical />
      </Skeleton>
    </Card>
  );
};

Informations.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  assistantEnums: PropTypes.shape({})
};

Informations.defaultProps = {
  user: {},
  isLoading: true,
  assistantEnums: {}
};
