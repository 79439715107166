import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row } from 'antd';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem/generateFormItem';
import { useFields } from './useFields';

/**
 * Renders the publication note step component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values for the form fields.
 * @param {Function} props.setButtonsActive - The function to set the active buttons.
 * @returns {JSX.Element} The publication note step component.
 */
export const PublicationNoteStep = ({
  form,
  initialValues,
  setButtonsActive
}) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();

  const { fields } = useFields();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useEffect(() => {
    setButtonsActive((prev) => ({ ...prev, next: true, back: true }));
  }, []);

  return (
    <Row justify="center">
      <Col span={12}>
        <Divider>{t('missions.form.note_divider')}</Divider>
      </Col>
      <Col span={24} offset={16}>
        {fields.map((field) => generateFields('missions', field))}
      </Col>
    </Row>
  );
};

PublicationNoteStep.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  setButtonsActive: PropTypes.func.isRequired
};
