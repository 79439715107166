import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col } from 'antd';

/**
 * Component for rendering the ChargingGrid.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.recordId - The record ID.
 * @returns {JSX.Element} The ChargingGrid component.
 */
export const ChargingGrid = ({ recordId }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={8}>
        <Form.Item
          label={t('quotation-actions.form.impacted_lot_number')}
          name={['actions', recordId, 'data', 'impacted_lot_number']}
          wrapperCol={{ span: 10 }}
        >
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name={['actions', recordId, 'data', 'observation']}>
          <Input
            placeholder={t('placeholder.observation')}
            style={{ width: '350px' }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

ChargingGrid.propTypes = {
  recordId: PropTypes.string.isRequired
};
