/**
 * Returns a configuration object for resource retrieval.
 * @hook
 * @returns {Object} The configuration object.
 */
export const useConfig = () => ({
  onUpdateResource: {
    setBody: (data) => {
      const updatedData = {
        ...data,
        'steps.sub': 'PUBLICATION'
      };

      return updatedData;
    }
  }
});
