import { useState } from 'react';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/addDocumentCardFields';
import { useConfig } from '../utils/documentCreateUpdateConfig';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Component for adding a document card.
 *
 * @component
 * @returns {JSX.Element} The add document card component.
 */
export const AddDocumentCard = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState({});
  const {
    forceRefresh,
    addDocumentCardDatas,
    setAddDocumentCardDatas,
    setForceRefresh,
    mission
  } = useMissionContext();
  const { fields } = useFields({
    setFileList,
    fileList
  });
  const customFormData = new FormData();

  const config = useConfig({
    fileList,
    customFormData,
    addDocumentCardDatas,
    form,
    mission
  });

  return (
    <CreateUpdateContainer
      customWrapperCol="20"
      customFormInstance={form}
      config={config}
      fields={fields}
      purpose="create"
      baseUrl={`documents/many_documents/${id}?type=${addDocumentCardDatas.type}`}
      resource="documents"
      withFilesManager={false}
      withEnums={false}
      withCustomPageHeader
      customFormData={customFormData}
      setForceRefresh={setForceRefresh}
      forceRefresh={forceRefresh}
      setIsCreateUpdateOpen={setAddDocumentCardDatas}
      isCreateUpdateOpen={{ open: false, type: '' }}
      cancelCustomAction={() => {
        form.resetFields();
        setAddDocumentCardDatas({ open: false, type: '' });
      }}
      customSubmitButtonStyle={{
        position: 'fixed',
        bottom: '20px',
        right: '60px'
      }}
    />
  );
};
