import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MergeForm } from './MergeForm';
import { SpfDepartmentalArchivesMethods } from '../utils/methods';

/**
 * MergeModal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.spfDepartmentalArchive - The SPF departmental archive object.
 * @param {Function} props.setSpfDepartmentalArchive - The function to set the SPF departmental archive.
 * @returns {JSX.Element} The MergeModal component.
 */
export const MergeModal = ({
  spfDepartmentalArchive,
  setSpfDepartmentalArchive
}) => {
  const { t } = useTranslation();
  const { getSpfDepartmentalArchives } = SpfDepartmentalArchivesMethods();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [spfDepartmentalArchives, setSpfDepartmentalArchives] = useState([]);
  const [selectedSpfDepartmentalArchive, setSelectedSpfDepartmentalArchive] =
    useState('');

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    const spfDepartmentalArchiveDatas = await getSpfDepartmentalArchives({
      spfDepartmentalArchive
    });
    setSpfDepartmentalArchives(spfDepartmentalArchiveDatas);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <Modal
      title={t('spf-departmental-archives.modals.titles.merge')}
      open={Object.keys(spfDepartmentalArchive).length}
      onOk={() => setSpfDepartmentalArchive({})}
      onCancel={() => setSpfDepartmentalArchive({})}
      footer={null}
    >
      <MergeForm
        spfDepartmentalArchive={spfDepartmentalArchive}
        selectedSpfDepartmentalArchive={selectedSpfDepartmentalArchive}
        isFieldsLoading={isFieldsLoading}
        setSelectedSpfDepartmentalArchive={setSelectedSpfDepartmentalArchive}
        spfDepartmentalArchives={spfDepartmentalArchives}
        setSpfDepartmentalArchive={setSpfDepartmentalArchive}
      />
    </Modal>
  );
};

MergeModal.propTypes = {
  spfDepartmentalArchive: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  setSpfDepartmentalArchive: PropTypes.func.isRequired
};
