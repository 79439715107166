/**
 * Prepares form data with files for submission.
 *
 * @function
 * @param {Array} files - Array of files from form fields.
 * @param {Array} draggerFilesList - Array of files from drag and drop interface.
 * @param {FormData} formData - FormData object that will be submitted.
 * @param {Array} filesConfiguration - Configuration array for files.
 * @param {string} purpose - Purpose of the createUpdateContainer.
 * @param {FormData} customFormData - Custom FormData object.
 * @returns {void}
 */
export const handleFormDataWithFiles = (
  files,
  draggerFilesList,
  formData,
  filesConfiguration,
  purpose,
  customFormData
) => {
  let filteredByConfiguration;
  if (purpose === 'edit') {
    const filteredDraggerFilesList = draggerFilesList.filter(
      (file) => !file.fileKey
    );
    filteredByConfiguration = filesConfiguration.filter(
      (fileConfig) =>
        filteredDraggerFilesList.some(
          (file) => fileConfig.name === file.name
        ) || fileConfig.source === 'field'
    );
  }

  if (draggerFilesList.length) {
    draggerFilesList.forEach((file) => {
      if (customFormData) {
        customFormData.append(file.fileKey, file.file);
      } else {
        formData.append(file.fileKey, file.file);
      }
    });
  }

  if (files.length) {
    files.forEach((file) => {
      if (customFormData) {
        customFormData.append(file.fileKey, file.file);
      } else {
        formData.append(file.fileKey, file.file);
      }
    });
  }

  if (draggerFilesList.length || files.length) {
    const configuration =
      purpose === 'create' ? filesConfiguration : filteredByConfiguration;
    if (customFormData) {
      customFormData.append(
        'filesConfiguration',
        JSON.stringify(configuration)
      );
    } else {
      formData.append('filesConfiguration', JSON.stringify(configuration));
    }
  }
};
