/**
 * Handles the form submission for reassigning collective ownerships.
 *
 * @param {Object} options - The options for the form submission.
 * @param {string} options.value - The new syndic value.
 * @param {function} options.dispatchAPI - The dispatchAPI function.
 * @param {Array} options.selectedCollectiveOwnerships - The selected collective ownerships.
 * @param {function} options.message - The message function.
 * @param {function} options.setForceRefresh - The setForceRefresh function.
 * @param {boolean} options.forceRefresh - The forceRefresh value.
 * @param {function} options.setIsReassignModalOpen - The setIsReassignModalOpen function.
 * @param {function} options.setSelectedRowKeys - The setSelectedRowKeys function.
 * @param {function} options.setSelectedCollectiveOwnerships - The setSelectedCollectiveOwnerships function.
 * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
 */
export const handleReassignFormSubmit = async ({
  value,
  dispatchAPI,
  selectedCollectiveOwnerships,
  message,
  setForceRefresh,
  forceRefresh,
  setIsReassignModalOpen,
  setSelectedRowKeys,
  setSelectedCollectiveOwnerships
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `/collective-ownerships/reassign/${value.new_manager}`,
      body: selectedCollectiveOwnerships
    });

    setIsReassignModalOpen(false);
    setSelectedRowKeys([]);
    setSelectedCollectiveOwnerships([]);
    setForceRefresh(!forceRefresh);
  } catch (e) {
    message(e);
  }
};
