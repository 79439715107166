import { Collapse } from 'antd';
import { PropTypes } from 'prop-types';
import { ProfessionalsTable } from './ProfessionalsTable';

/**
 * `UsePermissionsItems` component.
 *
 * Renders a series of collapsible panels each containing a `ProfessionalsTable`.
 * These tables are used to display and manage permissions for different professional
 * roles such as lawyers, notaries, surveyors, and others.
 *
 * Props:
 * @prop {function} t - Translation function from react-i18next for internationalization.
 * @prop {Object} permissions - An object containing arrays of permission data for each role.
 * @prop {function} setPermissions - Function to update the permissions state.
 * @prop {Object} professionals - An object containing professional data.
 * @prop {boolean} isFieldsLoading - Indicates if the professional data is currently being loaded.
 *
 * @returns A `Collapse` component from Ant Design containing `ProfessionalsTable` for
 *          each category of professionals.
 *
 * Usage:
 * <UsePermissionsItems
 *   t={translationFunction}
 *   permissions={permissionsData}
 *   setPermissions={updatePermissionsFunction}
 *   professionals={professionalData}
 *   isFieldsLoading={loadingState}
 * />
 *
 * Each panel in the `Collapse` displays a `ProfessionalsTable` for a specific category
 * of professionals. The `ProfessionalsTable` component is responsible for rendering
 * and managing the permissions associated with each professional role.
 */

export const UsePermissionsItems = ({
  t,
  permissions,
  setPermissions,
  professionals,
  isFieldsLoading,
  purpose
}) => {
  const items = [
    {
      key: '1',
      label: t('collective-ownerships.form.collapse.titles.lawyers'),
      children: (
        <ProfessionalsTable
          dataSource={permissions.lawyers}
          setPermissions={setPermissions}
          permissions={permissions}
          professionals={professionals}
          isFieldsLoading={isFieldsLoading}
          t={t}
          purpose={purpose}
        />
      )
    },
    {
      key: '2',
      label: t('collective-ownerships.form.collapse.titles.notaries'),
      children: (
        <ProfessionalsTable
          dataSource={permissions.notaries}
          setPermissions={setPermissions}
          permissions={permissions}
          professionals={professionals}
          isFieldsLoading={isFieldsLoading}
          t={t}
          purpose={purpose}
        />
      )
    },
    {
      key: '3',
      label: t('collective-ownerships.form.collapse.titles.surveyors'),
      children: (
        <ProfessionalsTable
          dataSource={permissions.surveyors}
          setPermissions={setPermissions}
          permissions={permissions}
          professionals={professionals}
          isFieldsLoading={isFieldsLoading}
          t={t}
          purpose={purpose}
        />
      )
    },
    {
      key: '4',
      label: t('collective-ownerships.form.collapse.titles.others'),
      children: (
        <ProfessionalsTable
          dataSource={permissions.others}
          setPermissions={setPermissions}
          permissions={permissions}
          professionals={professionals}
          isFieldsLoading={isFieldsLoading}
          t={t}
          purpose={purpose}
        />
      )
    }
  ];
  return <Collapse items={items} />;
};

UsePermissionsItems.propTypes = {
  t: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    lawyers: PropTypes.arrayOf(PropTypes.shape({})),
    notaries: PropTypes.arrayOf(PropTypes.shape({})),
    surveyors: PropTypes.arrayOf(PropTypes.shape({})),
    others: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  setPermissions: PropTypes.func.isRequired,
  professionals: PropTypes.shape({}),
  isFieldsLoading: PropTypes.bool.isRequired,
  purpose: PropTypes.string.isRequired
};

UsePermissionsItems.defaultProps = {
  permissions: {},
  professionals: {}
};
