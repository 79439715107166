import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useGlobalContext } from '../../../../contexts/GlobalContext';

/**
 * Custom hook for rendering an extra search button.
 * @component
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setFilter - The function to set the filter value.
 * @returns {JSX.Element} - The JSX element representing the extra search button.
 */
export const ManagersSearchButton = ({ setFilter }) => {
  const [gestionnaires, setGestionnaires] = useState([]);
  const { currentSyndic } = useGlobalContext();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const getGestionnaires = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/syndic/${currentSyndic}?populate=managers&fields=managers,managers._id,managers.first_name,managers.last_name`
      });
      setGestionnaires(
        data.managers.map((gestionnaire) => ({
          label: `${gestionnaire.first_name} ${gestionnaire.last_name}`,
          value: gestionnaire._id
        }))
      );
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getGestionnaires();
  }, []);

  const onChange = (value) => {
    if (!value) {
      return setFilter('');
    }
    return setFilter(value);
  };

  return (
    <Select
      placeholder={t('syndic-factures.select_gestionnaire')}
      onChange={(v) => onChange(v)}
      allowClear
      options={gestionnaires}
    />
  );
};

ManagersSearchButton.propTypes = {
  setFilter: PropTypes.func.isRequired
};
