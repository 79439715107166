import { useGenerateFormItem } from '../../../utils/generateFormItem/generateFormItem';
import { useFields } from '../fields/parametersFields';

/**
 * Renders the ParametersForm component.
 *
 * @component
 * @returns {JSX.Element} The rendered ParametersForm component.
 */
export const ParametersForm = () => {
  const generateFields = useGenerateFormItem();
  const { fields } = useFields();
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 }
  };

  return fields.map((field) =>
    generateFields(
      'quotations',
      {
        ...field,
        ...formItemLayout
      },
      'centered-form-item'
    )
  );
};
