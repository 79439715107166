import {
  Checkbox,
  Select,
  Form,
  Input,
  Switch,
  Typography,
  Row,
  Col,
  DatePicker,
  Tooltip
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { informationsProvidedBySyndicInputs } from './utils/informationsProvidedBySyndicInputs';
import { collectiveOwnershipsDragger } from './utils/collectiveOwnershipsDragger';
import { ExportXlsxTemplateButton } from '../../../components/ExportXlsxTemplateButton/ExportXlsxTemplateButton';

const { Option } = Select;
const { Text } = Typography;

/**
 * Custom hook that returns an object containing the fields for a form.
 *
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Object[]} options.enums - The enums object.
 * @param {boolean} options.isFieldsLoading - Indicates if the fields are loading.
 * @param {Object[]} options.syndicOptions - The syndic options.
 * @param {Object[]} options.managers - The managers options.
 * @param {Object[]} options.assistants - The assistants options.
 * @param {Function} options.setSyndic - The function to set the syndic value.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setIsMultipleCollectiveOwnerships - The function to set the isMultipleCollectiveOwnerships value.
 * @param {boolean} options.isMultipleCollectiveOwnerships - Indicates if there are multiple collective ownerships.
 * @param {string} options.status - The status value.
 * @param {string} options.purpose - The purpose value.
 * @param {Object[]} options.municipalities - The municipalities options.
 * @returns {Object} - The fields object.
 */
export const useFields = ({
  enums,
  isFieldsLoading,
  syndicOptions,
  managers,
  assistants,
  setSyndic,
  syndic,
  form,
  setIsMultipleCollectiveOwnerships,
  isMultipleCollectiveOwnerships,
  status,
  purpose,
  municipalities,
  resourceName
}) => {
  const { t } = useTranslation();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['syndic'],
      key: 'syndic',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(e, option) => {
            setSyndic(option?.syndic);
            form.setFieldsValue({
              assistants: undefined,
              managers: undefined
            });
          }}
        >
          {syndicOptions.map((item) => (
            <Option value={item._id} key={item._id} syndic={item}>
              {item.society_name}
            </Option>
          ))}
        </Select>
      )
    },

    {
      name: ['manager'],
      key: 'manager',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {managers?.map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.last_name} ${item.first_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['assistant'],
      key: 'assistant',
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {assistants.map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.last_name} ${item.first_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      key: 'status',
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums?.status || []).map((r) => (
            <Option key={r} value={r}>
              {t(`pcs-pre-productions.tags.${r}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['informations_to_be_provided_by_syndic'],
      key: 'informations_to_be_provided_by_syndic',
      input: (
        <Input.Group>
          {informationsProvidedBySyndicInputs.map((input) => (
            <Form.Item
              key={input.label}
              name={['informations_to_be_provided_by_syndic', input.label]}
              valuePropName={input.type === 'checkbox' ? 'checked' : 'value'}
            >
              {input.type === 'checkbox' ? (
                <Checkbox>
                  {t(`pcs-pre-productions.form.${input.label}`)}
                </Checkbox>
              ) : (
                <Input
                  placeholder={t(`pcs-pre-productions.form.${input.label}`)}
                />
              )}
            </Form.Item>
          ))}
        </Input.Group>
      )
    },
    {
      name: ['remarks'],
      key: 'remarks',
      input: <Input.TextArea />
    },
    {
      label: t('switch'),
      key: 'is_multiple_collective_ownerships',
      valueProppName: 'checked',
      input: (
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Text>
              {t('pcs-pre-productions.form.one_collective_ownership')}
            </Text>
          </Col>
          <Col>
            <Form.Item
              name={['is_multiple_collective_ownerships']}
              valuePropName="checked"
            >
              <Switch
                onChange={() =>
                  setIsMultipleCollectiveOwnerships(
                    !isMultipleCollectiveOwnerships
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Text>
              {t('pcs-pre-productions.form.multiple_collective_ownerships')}
            </Text>
          </Col>
        </Row>
      )
    },
    ...(!isMultipleCollectiveOwnerships
      ? [
          {
            name: ['cadastral_reference'],
            rules: [{ required: true }],
            key: 'cadastral_reference'
          },
          {
            name: ['code'],
            key: 'code'
          },
          {
            name: ['invoice_code'],
            rules: [{ required: syndic?.force_code_OS && true }],
            key: 'invoice_code'
          },
          {
            name: ['name'],
            key: 'name'
          },
          {
            name: ['address', 'street'],
            rules: [{ required: true }],
            key: 'street'
          },
          {
            name: ['address', 'additional'],
            key: 'additional'
          },
          {
            name: ['address', 'postal_code'],
            rules: [{ required: true }],
            key: 'postal_code'
          },
          {
            name: ['first_city'],
            rules: [{ required: true }],
            key: 'city',
            input: (
              <Select loading={isFieldsLoading}>
                {(municipalities || []).map((municipality) => (
                  <Option key={municipality._id} value={municipality._id}>
                    {municipality?.name}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['AG_date'],
            key: 'AG_date',
            input: (
              <DatePicker
                format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                placeholder={t('placeholder.select_date')}
              />
            )
          }
        ]
      : [
          {
            name: ['download_import_template'],
            input: (
              <ExportXlsxTemplateButton
                dataName={resourceName}
                url={`template-documents/template/${resourceName}`}
                sheetName={resourceName}
              />
            )
          },
          ...collectiveOwnershipsDragger({ status, purpose })
        ]),
    {
      name: ['contracts'],
      key: 'contracts',
      maxFilesCount: 100,
      input: 'File'
    },
    {
      name: ['documents'],
      key: 'documents',
      maxFilesCount: 100,
      input: 'File'
    },
    ...(isMultipleCollectiveOwnerships
      ? [
          {
            name: ['informations'],
            key: 'informations',
            input: (
              <Tooltip
                title={t('pcs-pre-productions.tooltips.document_rcp_dragger')}
              >
                <InfoCircleOutlined />
              </Tooltip>
            )
          }
        ]
      : []),
    {
      name: ['rcp'],
      key: 'rcp',
      maxFilesCount: 100,
      input: 'File'
    },
    ...(isMultipleCollectiveOwnerships
      ? [
          {
            name: ['informations'],
            key: 'informations',
            input: (
              <Tooltip
                title={t('pcs-pre-productions.tooltips.document_rcp_dragger')}
              >
                <InfoCircleOutlined />
              </Tooltip>
            )
          }
        ]
      : []),
    {
      name: ['charging_grids'],
      key: 'charging_grids',
      maxFilesCount: 100,
      input: 'File'
    }
  ];

  return { fields };
};
