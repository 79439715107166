import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Modal,
  Space,
  Form,
  message,
  Spin,
  Button,
  List,
  Typography,
  Flex
} from 'antd';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useCreationCardButtons } from './useCreationCardButtons';
import { BaseCard } from '../BaseCard/BaseCard';
import { CreateUpdateMultipleCollectiveOwnerships } from './CreateUpdateMultipleCollectiveOwnerships';
import {
  routes,
  subRoutes
} from '../../../../../utils/constants/routes/productionRoutes';

/**
 * CreationCard Component
 *
 * This component renders a card with buttons for creating collective ownerships.
 * It includes modals for handling form submission and displaying information about missing municipalities.
 * @component
 * @returns {JSX.Element} The rendered CreationCard component.
 */
export const CreationCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);
  const [errorType, setErrorType] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const creationCardButtons = useCreationCardButtons({ setIsOpenModal });

  const handleResourceData = ({
    success,
    type,
    municipalities: missingMunicipalities
  }) => {
    setIsLoading(false);
    if (success) {
      setIsOpenModal(false);
      setIsOpenModalInfo(false);
      message.success(
        t('collective-ownerships.form.collective_ownerships_import_success')
      );
      navigate(`${routes.COLLECTIVE_OWNERSHIPS}`);
    } else {
      setMunicipalities(missingMunicipalities);
      setErrorType(type);
      setIsOpenModalInfo(true);
    }
  };

  const submitForm = () => {
    setIsLoading(true);
    form.submit();
  };

  return (
    <>
      <Modal
        style={{
          minWidth: 800
        }}
        title={t('dashboard.production.cards.creation.modal.title')}
        open={isOpenModal}
        onOk={submitForm}
        onCancel={() => setIsOpenModal(false)}
        okText={t('buttons.register')}
        okButtonProps={{ loading, icon: <CheckOutlined /> }}
      >
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined spin />}
          size="large"
        >
          <Modal
            title={t(
              `collective-ownerships.form.collective_ownerships_import_${errorType}.title`
            )}
            open={isOpenModalInfo}
            onOk={() => setIsOpenModalInfo(false)}
            onCancel={() => setIsOpenModalInfo(false)}
            footer={[
              <Space direction="horizontal">
                <Button onClick={() => setIsOpenModalInfo(false)}>
                  {t('buttons.back')}
                </Button>
                <Link
                  to={`${routes.CONFIGURATIONS}${subRoutes.CONFIGURATIONS.MUNICIPALITIES}`}
                >
                  {' '}
                  <Button
                    type="primary"
                    onClick={() => setIsOpenModalInfo(false)}
                  >
                    {t(
                      'dashboard.production.cards.creation.modal_info.redirect_configurations'
                    )}
                  </Button>
                </Link>
              </Space>
            ]}
          >
            {t(
              `collective-ownerships.form.collective_ownerships_import_${errorType}.description`
            )}
            <Flex style={{ width: '100%', overflowY: 'auto' }}>
              <List
                style={{ maxHeight: 300, width: '100%' }}
                dataSource={municipalities}
                renderItem={(municipality) => (
                  <List.Item>
                    <Typography.Text italic>{municipality}</Typography.Text>
                  </List.Item>
                )}
              />
            </Flex>
          </Modal>
          <CreateUpdateMultipleCollectiveOwnerships
            form={form}
            handleResourceData={handleResourceData}
          />
        </Spin>
      </Modal>
      <BaseCard
        title={t('dashboard.production.cards.creation.title')}
        cardButtons={creationCardButtons}
      />
    </>
  );
};
