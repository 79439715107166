import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Text } = Typography;

/**
 * Custom React hook that generates column definitions for a table displaying PCS Phase 3 data.
 * This hook leverages the `useTranslation` hook for localized text and defines a set of columns
 * with custom renderers, sorting capabilities, and conditional styling.
 *
 * @returns {Array<Object>} An array of column definitions for an Ant Design table. Each column object
 * contains properties such as `title`, `key`, `render`, and `sorter`.
 */
export const usePcsPhase3Columns = () => {
  const { t } = useTranslation();

  const displayDate = (date, defaultText) => {
    const background = date ? '#10B9A8' : '#CB2529';

    return (
      <div style={{ background, color: 'white' }}>
        {date ? `Oui - ${dayjs(date).format('DD-MM-YYYY')}` : defaultText}
      </div>
    );
  };
  return [
    {
      title: t('pcs_phase_3.titles.syndic'),
      key: 'syndic',
      render: (record) => {
        const syndic = record?.syndic;

        return syndic ? (
          <Text>{`${syndic.society_name} (${syndic.code})`}</Text>
        ) : (
          '-'
        );
      },
      sorter: true
    },
    {
      title: t('pcs_phase_3.titles.collective_ownership'),
      key: 'collective_ownership',
      render: (record) => {
        const collective = record?.collective_ownership;

        return `${collective?.code ? `${collective?.code} -` : ''} ${
          collective?.first_address?.number || ''
        } ${collective?.first_address?.street || ''} ${
          collective?.first_address?.postal_code || ''
        } ${collective?.first_address?.city || ''} ${
          collective?.cadastral_reference
            ? `(${collective?.cadastral_reference})`
            : ''
        }`;
      }
    },
    {
      title: t('pcs_phase_3.titles.nomade_payment'),
      key: 'nomade_payment',
      render: (record) => displayDate(record.nomade_payment_date, 'Non'),
      sorter: true
    },
    {
      title: t('pcs_phase_3.titles.building_sheet'),
      key: 'building_sheet',
      render: (record) => displayDate(record.building_sheet_date, 'NC'),
      sorter: true
    },
    {
      title: t('pcs_phase_3.titles.documents'),
      key: 'documents',
      render: (record) => displayDate(record.documents_date, 'Non'),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => {
        const type = ['PCS_1', 'PCS_2', 'PCS_3'].includes(record?.type)
          ? 'PCS'
          : record?.type?.toLowerCase();
        return (
          <Space size="middle">
            <Link
              to={`/missions/missions-view/show/${type}/${record._id}/${record.type}`}
            >
              <EyeOutlined style={{ fontSize: 16, color: 'black' }} />
            </Link>
          </Space>
        );
      },
      sorter: true
    }
  ];
};
