import { Table, Alert, Row, Col } from 'antd';
import { AlertOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useColumns } from '../columns/summaryColumns';
import { useQuotationContext } from '../../../contexts/QuotationContext';

/**
 * Renders the SummaryForm component.
 *
 * @component
 * @returns {JSX.Element} The rendered SummaryForm component.
 */
export const SummaryForm = () => {
  const { quotationActs } = useQuotationContext();
  const columns = useColumns();
  const { t } = useTranslation();

  const lotMergeAction = quotationActs.find(
    (act) => act.quotation_action.action === 'LOT_MERGE'
  );

  const lotSaleAction = quotationActs.find(
    (act) => act.quotation_action.action === 'SALE'
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Table
          dataSource={quotationActs}
          columns={columns}
          bordered
          pagination={false}
        />
      </Col>
      {lotMergeAction ? (
        <Col span={24}>
          <Alert
            showIcon
            message={t('quotations.messages.owner_is_owner_of_created_lots')}
            type="error"
            icon={<AlertOutlined fontSize={24} />}
          />
        </Col>
      ) : null}
      {lotSaleAction ? (
        <Col span={24}>
          <Alert
            showIcon
            message={t(
              'quotations.messages.check_water_closet_right_of_enjoyment'
            )}
            type="warning"
            icon={<WarningOutlined fontSize={24} />}
          />
        </Col>
      ) : null}
    </Row>
  );
};
