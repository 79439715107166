import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns for the broken folders statistics list
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useBrokenFoldersColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('statistics.columns.consultant'),
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: true
    },
    {
      title: t('statistics.columns.broken_folders.total_broke_folders'),
      dataIndex: 'total_broke_folders',
      key: 'total_broke_folders',
      sorter: true
    },
    {
      title: t(
        'statistics.columns.broken_folders.not_found_collective_ownerships'
      ),
      dataIndex: 'not_found_collective_ownerships',
      key: 'not_found_collective_ownerships',
      sorter: true
    },
    {
      title: t(
        'statistics.columns.broken_folders.total_collective_ownerships_overflow'
      ),
      dataIndex: 'total_collective_ownerships_overflow',
      key: 'total_collective_ownerships_overflow',
      sorter: true
    }
  ];
};
