/**
 * Asynchronously retrieves a list of professionals (lawyers and consultants) from the API and categorizes them based on their roles.
 * In case of an API error, it uses the provided message function to display the error status.
 *
 * @param {Object} options - The options for fetching professionals.
 * @param {Function} options.dispatchAPI - The function used to dispatch API requests. It accepts a request method and options.
 * @param {Function} options.message - Function to display error messages, typically used for error handling. It accepts an error message or status code.
 * @param {Function} options.setProfessionals - Function to update the state with the fetched professionals, categorized by their roles.
 * @async
 * @returns {Promise<void>} A promise that resolves when the professionals have been successfully fetched and categorized, or when an error has been caught and handled.
 */

export const getProfessionals = async ({
  dispatchAPI,
  message,
  setProfessionals
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/users?role=users:LAWYER,users:CONSULTANT'
    });

    const professionals = {};

    data.forEach((user) => {
      switch (user.role) {
        case 'users:LAWYER':
          professionals.lawyers = [...(professionals.lawyers || []), user];
          break;
        case 'users:CONSULTANT':
          professionals.consultants = [
            ...(professionals.consultants || []),
            user
          ];
          break;
        default:
          break;
      }
    });

    setProfessionals(professionals);
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
