/**
 * Retrieves collective ownership data from the server.
 * @param {Object} options - The options for retrieving collective ownership.
 * @param {Function} options.dispatchAPI - The function for dispatching API requests.
 * @param {string} options.id - The ID of the collective ownership.
 * @param {Function} options.setCollectiveOwnership - The function for setting the collective ownership data.
 * @param {Function} options.setPermissions - The function for setting the permissions data.
 * @param {Function} options.message - The function for displaying error messages.
 * @returns {Promise<void>} - A promise that resolves when the collective ownership data is retrieved.
 */
export const getCollectiveOwnership = async ({
  dispatchAPI,
  id,
  setCollectiveOwnership,
  setPermissions,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/collective-ownerships/${id}?populate=charging_grid,charging_grid.columns.column,syndic,manager,notary_writer,first_city`
    });

    setCollectiveOwnership(data);
    setPermissions(data?.permissions);
  } catch (e) {
    message(e);
  }
};
