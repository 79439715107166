import { useTranslation } from 'react-i18next';
import { ListAccountantExports } from './ListAccountantExports';

/**
 * Custom hook for retrieving items for accountant exports.
 * @hook
 * @returns {Object} The items object containing key, label, and children properties.
 */
export const useItems = () => {
  const { t } = useTranslation();

  const items = [
    {
      key: '1',
      label: t('accountant-exports.items.titles.cheques'),
      children: <ListAccountantExports type="cheques" />
    },
    {
      key: '2',
      label: t('accountant-exports.items.titles.disbursements'),
      children: <ListAccountantExports type="disbursements" />
    },
    {
      key: '3',
      label: t('accountant-exports.items.titles.costs'),
      children: <ListAccountantExports type="costs" />
    }
  ];

  return { items };
};
