import {
  Checkbox,
  Select,
  DatePicker,
  Switch,
  InputNumber,
  Row,
  Form,
  Typography,
  Col,
  Tag,
  Radio,
  Divider
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { Mission } from '../../../../../models/Mission';

const { Option } = Select;
const { Text } = Typography;

/**
 * Custom hook that returns an array of fields for a form.
 *
 * @hook
 * @returns {Object} - The fields array.
 */
export const useFields = ({ form }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    setSyndicInformation,
    professionals,
    syndicInformations,
    setBillingOptionsColors,
    billingOptionsColors,
    setBillingOptionsAmounts,
    mission,
    pcs2PriceSystem
  } = useMissionContext();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const optionRadio = [
    { key: 'YES', value: 'YES', label: t('radio-options.yes') },
    { key: 'NO', value: 'NO', label: t('radio-options.no') }
  ];

  const billingOptions = [
    {
      name: 'compliance',
      key: 'compliance',
      label: t('missions.form.billing_options.compliance'),
      defaultValue: pcs2PriceSystem?.compliance
    },
    {
      name: 'adaptation',
      key: 'adaptation',
      label: t('missions.form.billing_options.adaptation'),
      defaultValue: pcs2PriceSystem?.adaptation
    },
    {
      name: 'rcp_creation',
      key: 'rcp_creation',
      label: t('missions.form.billing_options.rcp_creation'),
      defaultValue: pcs2PriceSystem?.rcp_creation
    },
    {
      name: 'charge_grid_creation',
      key: 'charge_grid_creation',
      label: t('missions.form.billing_options.charge_grid_creation')
    },
    {
      name: 'pcs_creation',
      key: 'pcs_creation',
      label: t('missions.form.billing_options.pcs_creation')
    }
  ];

  const fields = [
    {
      name: ['OS_code'],
      key: 'OS_code'
    },
    {
      name: ['AG_date'],
      key: 'AG_date',
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      noLabel: true,
      name: ['priority'],
      key: 'priority',
      input: <Checkbox>{t('missions.form.priority')}</Checkbox>,
      valuePropName: 'checked'
    },
    ...(mission?.type.includes('PCS')
      ? [
          {
            name: ['lawyer'],
            key: 'lawyer',
            input: (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                loading={isLoading}
              >
                {(professionals?.lawyers || []).map((item) => (
                  <Option value={item._id} key={item._id}>
                    {`${item.last_name || ''} ${item.first_name || ''}`}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['notary'],
            key: 'notary',
            input: (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                loading={isLoading}
              >
                {(professionals?.notaries || []).map((item) => (
                  <Option value={item._id} key={item._id}>
                    {`${item.last_name || ''} ${item.first_name || ''}`}
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      name: ['use_syndic_information_for_billing'],
      key: 'use_syndic_information_for_billing',
      input: <Switch onChange={(e) => setSyndicInformation(e)} />,
      valuePropName: 'checked'
    },
    ...(!syndicInformations
      ? [
          {
            name: ['billing_email'],
            key: 'billing_email'
          },
          {
            name: ['billing_address'],
            key: 'billing_address'
          }
        ]
      : []),
    ...(mission?.type === 'AUDIT_1_2'
      ? [
          {
            noLabel: true,
            name: ['transformation_word'],
            key: 'transformation_word',
            input: (
              <Checkbox>{t('missions.form.transformation_word')}</Checkbox>
            ),
            valuePropName: 'checked'
          }
        ]
      : []),
    ...(mission?.type === 'PCS_2'
      ? [
          {
            name: ['co_owner_form_status'],
            key: 'co_owner_form_status',
            input: (
              <Radio.Group name="co_owner_form_status">
                <Radio value="OPENED">
                  {t('missions.form.opened_awnsers')}
                </Radio>
                <Radio value="CLOSED">
                  {t('missions.form.closed_awnsers')}
                </Radio>
              </Radio.Group>
            )
          },
          {
            noLabel: true,
            input: (
              <Row gutter={[16, 16]}>
                <Divider>{t('missions.dividers.billing_options')}</Divider>
                {billingOptions.map((item) => (
                  <Col span={24} key={item.key}>
                    <Row justify="space-around" align="middle">
                      <Col>
                        <Form.Item
                          noStyle
                          name={['billing_options', item.name]}
                          valuePropName="checked"
                        >
                          <Switch
                            disabled={mission.steps.sub !== 'ADVANCE_PAYMENT'}
                            onChange={(e) =>
                              Mission.handleBillingOptionsSwitch({
                                item,
                                e,
                                form,
                                setBillingOptionsAmounts,
                                setBillingOptionsColors
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Text>{item.label}</Text>
                      </Col>
                      <Col>
                        <Tag color={billingOptionsColors[item.name]}>{`${
                          item.defaultValue || 0
                        } €`}</Tag>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            )
          },
          {
            name: ['total_ht'],
            key: 'total_ht',
            input: (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item name="total_ht">
                    <InputNumber disabled addonAfter="€" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>{t('missions.form.send_to_lawyer_after_paid')}</Text>
                </Col>
              </Row>
            )
          }
        ]
      : []),
    ...(mission?.type === 'PCS_3'
      ? [
          {
            name: ['is_notary_paid'],
            key: 'is_notary_paid',
            input: (
              <Radio.Group options={optionRadio} name="co_owner_form_status" />
            )
          }
        ]
      : [])
  ];

  return { fields };
};
