import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Space, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Invoice } from '../../../../models/Invoice';
import { adminTheme } from '../../../../utils/constants/colors';

/**
 * ConsultantInvoices component displays a list of consultants with their respective number of invoices.
 * It fetches the data on mount and allows navigation to specific consultant invoices.
 *
 * @param {object} props - The component props.
 * @param {function} props.t - The translation function for localized text.
 * @returns {JSX.Element} The rendered ConsultantInvoices component.
 */
const ConsultantInvoices = ({ t }) => {
  const [consultants, setConsultants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const mapItem = (item) => (
    <Row justify="center">
      <Button
        onClick={() =>
          item.syndic &&
          navigate(
            `/billing-and-accountings/invoices?syndic=${item.syndic._id}`
          )
        }
        type="primary"
        style={{ width: '100%', marginBottom: 8 }}
      >
        <Space direction="horizontal">
          <span>{`${item.consultant.first_name} ${item.consultant.last_name}`}</span>
          {item && item.invoices.length && (
            <span style={{ color: adminTheme.secondaryColor }}>
              {item.invoices.length}
            </span>
          )}
        </Space>
      </Button>
    </Row>
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await Invoice.getResourcesByConsultant({
        populate: '',
        extraQuery: ''
      });
      setConsultants(data);
      setIsLoading(false);
    })();
  }, []);

  return (
    <Card
      title={t('dashboard.assistant.cards.invoices_less_than_three_months')}
    >
      <Spin spinning={isLoading}>{(consultants || []).map(mapItem)}</Spin>
    </Card>
  );
};

ConsultantInvoices.propTypes = {
  t: PropTypes.func.isRequired
};

export default ConsultantInvoices;
