import { Row, Col, Input, Form, Checkbox, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuotationContext } from '../../../contexts/QuotationContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Dragger } = Upload;

/**
 * Custom hook to manage fields and file operations for document requests in quotations.
 *
 * @hook
 * @returns {Object} An object containing the fields configuration.
 *
 * @example
 * const { fields } = useFields();
 *
 * @typedef {Object} Field
 * @property {boolean} noLabel - Indicates if the field should have no label.
 * @property {string} key - The key for the field.
 * @property {JSX.Element} input - The input element for the field.
 *
 * @typedef {Object} DraggerProps
 * @property {boolean} multiple - Indicates if multiple files can be uploaded.
 * @property {Function} onRemove - Function to handle file removal.
 * @property {Function} beforeUpload - Function to handle actions before file upload.
 * @property {Array} fileList - List of files to be uploaded.
 *
 * @function deleteFile
 * @async
 * @param {string} fileID - The ID of the file to be deleted.
 * @returns {Promise<void>} - A promise that resolves when the file is deleted.
 *
 * @function draggerProps
 * @param {Object} params - Parameters for the dragger properties.
 * @param {string} params.name - The name of the file list.
 * @returns {DraggerProps} - The properties for the dragger component.
 */
export const useFields = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();
  const { filesList, setFilesList, requestedDocuments } = useQuotationContext();

  const deleteFile = async (fileID) => {
    try {
      await dispatchAPI('PATCH', {
        url: `quotations/${id}/${fileID}`
      });
    } catch (e) {
      message(e);
    }
  };

  const draggerProps = ({ name }) => ({
    multiple: true,
    onRemove: async (file) => {
      const updatedFileList = { ...filesList };
      if (id) {
        const index = updatedFileList[name].findIndex(
          (fileItem) => fileItem?.file?.uuid === file?.uuid
        );

        if (index !== -1) {
          await deleteFile(updatedFileList[name][index]._id);
          updatedFileList[name].splice(index, 1);
          setFilesList(updatedFileList);
        }
      }
    },
    beforeUpload: (file) => {
      setFilesList((prev) => {
        const updatedList = { ...prev };
        if (updatedList[name]) {
          updatedList[name] = [...updatedList[name], file];
        } else {
          updatedList[name] = [file];
        }
        return updatedList;
      });

      return false;
    },
    fileList: (filesList[name] || []).map((file) => file.file || file)
  });

  const fields = [
    {
      noLabel: true,
      key: 'desired_documents',
      input: (
        <Row gutter={[16, 16]}>
          <Col>
            {(requestedDocuments || []).map((requestedDocument) => (
              <Form.Item
                key={requestedDocument._id}
                name={['desired_documents', requestedDocument._id]}
                valuePropName="checked"
              >
                <Checkbox>{requestedDocument.wording}</Checkbox>
              </Form.Item>
            ))}
          </Col>
          <Col>
            <Form.Item
              label={t('quotations.form.desired_documents.other_documents')}
              name={['desired_documents', 'other_documents']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )
    },
    {
      noLabel: true,
      input: (
        <Dragger {...draggerProps({ name: 'documents' })}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return { fields };
};
