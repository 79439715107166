import { useEffect, useState } from 'react';
import { Card } from 'antd';
import { Calendar } from '../../../components/Calendar/Calendar';
import { monthCellRender } from '../../../components/Calendar/CellRenders.tsx';
import { CustomAgendaHeader } from './SubComponents/CustomAgendaHeader';
import { Event } from '../../../models/Event';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { weeklyCellRender } from '../../../components/Calendar/WeeklyCellRenders.tsx';

/**
 * ShowEvents component
 * @component ShowEvents
 * @return {object} The UI of the ShowEvents component
 * @example return <ShowEvents />
 */
export const ShowEvents = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { forceRefresh, setForceRefresh } = useGlobalContext();

  const [extraQuery, setExtraQuery] = useState('isTasks=true');

  useEffect(() => {
    setForceRefresh(!forceRefresh);
  }, [extraQuery]);

  const handleDeleteEvent = async ({ id }) => {
    try {
      await Event.deleteResource(id, dispatchAPI);
      setForceRefresh(!forceRefresh);
    } catch (error) {
      message(error);
    }
  };

  return (
    <Card>
      <CustomAgendaHeader setExtraQuery={setExtraQuery} />
      <Calendar
        modes={['week', 'month']}
        subMode="custom"
        resourceName="events"
        extraQuery={extraQuery}
        weekCellRender={weeklyCellRender}
        monthCellRender={monthCellRender}
        dayRange="9"
        forceRefresh={forceRefresh}
        deleteEvent={handleDeleteEvent}
        hourStart={6}
        hourEnd={21}
        ISOWeekName={false}
        initialMode="week"
      />
    </Card>
  );
};
