import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReassignModalForm } from './ReassignModalForm';
import { getManagersForReassign } from '../utils/getManagersForReassign';

/**
 * ReassignModal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isReassignModalOpen - Flag indicating if the reassign modal is open.
 * @param {function} props.setIsReassignModalOpen - Function to set the state of the reassign modal.
 * @param {Array} props.selectedCollectiveOwnerships - Array of selected collective ownerships.
 * @param {Array} props.syndics - Array of syndics.
 * @param {function} props.setForceRefresh - Function to set the state of force refresh.
 * @param {boolean} props.forceRefresh - Flag indicating if a force refresh is needed.
 * @param {function} props.setSelectedRowKeys - Function to set the state of selected row keys.
 * @param {function} props.setSelectedCollectiveOwnerships - Function to set the state of selected collective ownerships.
 * @returns {JSX.Element} The ReassignModal component.
 */

export const ReassignModal = ({
  isReassignModalOpen,
  setIsReassignModalOpen,
  selectedCollectiveOwnerships,
  syndics,
  setForceRefresh,
  forceRefresh,
  setSelectedRowKeys,
  setSelectedCollectiveOwnerships
}) => {
  const { t } = useTranslation();
  const [managers, setManagers] = useState([]);
  const [isFieldLoading, setIsFieldLoading] = useState(true);

  useEffect(() => {
    getManagersForReassign({
      syndics,
      selectedCollectiveOwnerships,
      setIsFieldLoading,
      setManagers
    });
  }, []);

  return (
    <Modal
      title={t(
        `collective-ownerships.modals.titles.reassign_collective_ownership${
          selectedCollectiveOwnerships.length > 1 ? 's' : ''
        }`
      )}
      open={isReassignModalOpen}
      onOk={() => setIsReassignModalOpen(false)}
      onCancel={() => setIsReassignModalOpen(false)}
      footer={null}
    >
      <ReassignModalForm
        selectedCollectiveOwnerships={selectedCollectiveOwnerships}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        setIsReassignModalOpen={setIsReassignModalOpen}
        setSelectedRowKeys={setSelectedRowKeys}
        setSelectedCollectiveOwnerships={setSelectedCollectiveOwnerships}
        isFieldLoading={isFieldLoading}
        managers={managers}
      />
    </Modal>
  );
};

ReassignModal.propTypes = {
  isReassignModalOpen: PropTypes.bool.isRequired,
  setIsReassignModalOpen: PropTypes.func.isRequired,
  selectedCollectiveOwnerships: PropTypes.string.isRequired,
  syndics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  setSelectedRowKeys: PropTypes.func.isRequired,
  setSelectedCollectiveOwnerships: PropTypes.func.isRequired
};

ReassignModal.defaultProps = {
  forceRefresh: false
};
