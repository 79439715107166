import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { getEnums } from './utils/getEnums';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { DeleteModal } from './modals/DeleteModal';
import { ExtraButtons } from './ExtraButtons';
import { useGlobalContext } from '../../contexts/GlobalContext';

/**
 * Composant de liste pour les syndics.
 * Affiche une liste de syndics avec des fonctionnalités de tri, de filtrage et de recherche.
 *
 * @returns {JSX.Element} Le composant de liste des syndics.
 */
export const ListSyndic = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { setActiveKey } = useGlobalContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [enums, setEnums] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedSyndic, setSelectedSyndic] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [syndics, setSyndics] = useState([]);
  const [extraQuery, setExtraQuery] = useState('');
  const columns = useColumns({
    enums,
    setIsDeleteModalOpen,
    setSelectedSyndic
  });
  setActiveKey('1');
  const createPermissions = !['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(
    user.role
  );

  useEffect(() => {
    (async () => {
      await getEnums({ dispatchAPI, setEnums, message });
    })();
  }, [getEnums]);

  return (
    <>
      <ListResource
        resourceName="syndics"
        columns={columns}
        extraQuery={extraQuery}
        resourceModelName="Syndic"
        populate="contact,managers"
        customActionColumn
        setResourceData={setSyndics}
        withCreateButton={createPermissions}
        forceRefresh={forceRefresh}
        extraButtons={<ExtraButtons setExtraQuery={setExtraQuery} />}
      />
      {isDeleteModalOpen ? (
        <DeleteModal
          syndics={syndics}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDeleteModalOpen={isDeleteModalOpen}
          selectedSyndic={selectedSyndic}
          dispatchAPI={dispatchAPI}
          message={message}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          t={t}
        />
      ) : null}
    </>
  );
};
