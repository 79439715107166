import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import contract_consultation from '../../../../../../assets/images/contract_consultation.png';
import { VerifyCodeComponent } from '../../../utils/VeritifcationCode/VerifyCodeComponent';

/**
 * Renders the form for collective ownership.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.initialValues - The initial values for the form fields.
 * @param {Object} props.form - The form instance.
 * @returns {JSX.Element} The rendered component.
 */
export const ContractSignature = ({
  initialValues,
  form,
  setButtonsActive,
  setInitialValues
}) => {
  const [isCodeValidated, setIsCodeValidated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const setUniqueIdAndNavigate = () => {
    const uniqueId = uuidv4();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('id', uniqueId);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(newUrl, { replace: true });
  };

  const setContractSigned = () => {
    if (isCodeValidated) {
      return setInitialValues((prev) => ({ ...prev, contract_signed: true }));
    }
    if (
      !form.getFieldValue('contract') ||
      !initialValues?.contract ||
      !initialValues?.contract_signed
    ) {
      return setInitialValues((prev) => ({ ...prev, contract_signed: true }));
    }
    return null;
  };

  useEffect(() => {
    setUniqueIdAndNavigate(location, navigate);
    setButtonsActive((prev) => ({ ...prev, next: false }));
  }, []);

  useEffect(() => {
    if (isCodeValidated) {
      setContractSigned();
      setButtonsActive((prev) => ({ ...prev, next: true, back: false }));
    }
  }, [isCodeValidated]);

  return (
    <Row>
      <Col span={8}>
        <img src={contract_consultation} alt="placeholder" />
      </Col>
      <Col span={16}>
        <VerifyCodeComponent
          setInitialValues={setInitialValues}
          initialValues={initialValues}
          codeToValidate="contract"
          setIsValidated={setIsCodeValidated}
          documentToSee="contract"
        />
      </Col>
    </Row>
  );
};
ContractSignature.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func
  }).isRequired,
  initialValues: PropTypes.shape({
    contract: PropTypes.string,
    contract_signed: PropTypes.bool
  }),
  setButtonsActive: PropTypes.func.isRequired,
  setInitialValues: PropTypes.func.isRequired
};

ContractSignature.defaultProps = {
  initialValues: {}
};
