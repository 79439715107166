import { BaseModel } from './BaseModel';

/**
 * Represents an disbursement and varied costs.
 * @class
 */
export class DisbursementAndVariedCost extends BaseModel {
  static resourceName = 'disbursement-and-varied-costs';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${DisbursementAndVariedCost.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${DisbursementAndVariedCost.resourceName}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Posts a resource to the server.
   * @function
   * @async
   * @param {Object} options - The options for the request.
   * @param {Object} options.values - The values to be sent in the request body.
   * @returns {Promise<Object>} A promise that resolves to the response data.
   */
  static async postResource({ values }) {
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));

    const { data } = await this.dispatchAPI('POST', {
      url: `${DisbursementAndVariedCost.resourceName}`,
      body: formData
    });
    return data;
  }

  /**
   * Deletes a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for deleting the resource.
   * @param {string} options.id - The ID of the resource to delete.
   * @returns {Promise<void>} A promise that resolves when the resource is deleted.
   */
  static async deleteResource({ id }) {
    await this.dispatchAPI('DELETE', {
      url: `${DisbursementAndVariedCost.resourceName}/${id}`
    });
  }
}
