import { Button, Drawer, Flex, Form, message as Message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuoteGenerationCardButtons } from './useQuoteGenerationCardButtons';
import { BaseCard } from '../BaseCard/BaseCard';
import { useFields } from './useFields';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem/generateFormItem';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { generatePDF } from '../../../../../utils/generatePDF';

const tradKey = 'dashboard.production.cards.quote_generation.drawer';
/**
 * @component
 * QuoteGenerationCard Component
 *
 * This component renders a card titled "Quote Generation" with associated buttons for actions.
 * @returns {JSX.Element} The QuoteGenerationCard component.
 */
export const QuoteGenerationCard = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const generateFormItem = useGenerateFormItem();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const quoteGenerationCardButtons = useQuoteGenerationCardButtons({
    setIsDrawerOpen
  });
  const { fields } = useFields();

  const onFinishForm = async (quoteType) => {
    try {
      setIsLoading(true);
      await generatePDF({
        message,
        dispatchAPI,
        parameters: [
          {
            collection: 'Collective-ownership',
            documentId: quoteType.collective_ownership
          },
          {
            collection: 'Price-system',
            mission_type: quoteType.rcp_research_subtype || quoteType.quote
          }
        ],
        use_case: `QUOTE_${quoteType.rcp_research_subtype || quoteType.quote}`,
        template_type: 'TEMPLATE_DOCS',
        setIsLoading,
        extraQuery: 'needs_price_system=true',
        downloadName: t(
          `missions.tags.${quoteType.rcp_research_subtype || quoteType.quote}`
        )
      });
      setIsLoading(false);
    } catch (error) {
      message(error);
    }
  };

  const onValidate = async (e) => {
    e.preventDefault();
    try {
      await form.validateFields();
      return form.submit();
    } catch (error) {
      return Message.error(t('syndics.messages.required_fields'));
    }
  };

  return (
    <>
      <BaseCard
        title={t('dashboard.production.cards.quote_generation.title')}
        cardButtons={quoteGenerationCardButtons}
      />
      <Drawer
        title={t(`${tradKey}.title`)}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Form form={form} layout="vertical" onFinish={onFinishForm}>
          {fields.map((field) => generateFormItem(tradKey, field))}
          <Flex justify="flex-end">
            <Button onClick={() => setIsDrawerOpen(false)}>
              {t(`buttons.cancel`)}
            </Button>
            <Button loading={isLoading} type="add" onClick={onValidate}>
              {t(`buttons.generate`)}
            </Button>
          </Flex>
        </Form>
      </Drawer>
    </>
  );
};
