import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const { Search } = Input;

/**
 * Renders a search bar component for syndics.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @returns {JSX.Element} The rendered component.
 */
export const CustomSearchResourcesBar = ({ setFilters }) => {
  const { t } = useTranslation();

  return (
    <Search
      style={{ width: 200, marginBottom: 0 }}
      allowClear
      placeholder={t('placeholder.search')}
      onSearch={(value) => {
        if (value) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            keywords: value.toString()
          }));
        } else {
          setFilters((prevFilters) => ({
            ...prevFilters,
            keywords: ''
          }));
        }
      }}
    />
  );
};

CustomSearchResourcesBar.propTypes = {
  setFilters: PropTypes.func
};

CustomSearchResourcesBar.defaultProps = {
  setFilters: () => {}
};
