import { Button } from 'antd';
import { useState } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './utils/useColumns';
import SyndicViewContentCustom from '../SyndicViewContentCustom';
import { facturesTitle } from './utils/facturesTitle';
import { syndicContentCustomStyle } from '../utils/styleConstants';
import MissionConfondues from '../../../assets/images/missions_confondues.svg';
import { useExpandableColumns } from './utils/useExpandableColumns';
import { ManagersSearchButton } from './utils/ManagersSearchButton';
import { useGlobalContext } from '../../../contexts/GlobalContext';

/**
 * @component
 * Renders a list of collective ownerships for a syndic.
 * @returns {JSX.Element} The rendered component.
 */
export const FacturesSyndicList = () => {
  const { currentSyndic } = useGlobalContext();
  const [filter, setFilter] = useState('');
  const { title, subtitle } = facturesTitle();
  const columns = useColumns();
  const [selectedIncoives, setSelectedInvoices] = useState({});
  const [selectedMissionsIds, setSelectedMissionsIds] = useState([]);
  const expandableTable = useExpandableColumns(
    selectedIncoives,
    setSelectedInvoices
  );

  const rowSelection = {
    selectedMissionsIds,
    onChange: (rowKeys) => {
      setSelectedMissionsIds(rowKeys);
    }
  };

  return (
    <SyndicViewContentCustom
      title={title}
      subtitle={subtitle}
      imageOnRight={MissionConfondues}
      cardStyle={{ backgroundColor: '#222222' }}
    >
      <ListResource
        ContentCustomStyle={syndicContentCustomStyle}
        withPageHeader={false}
        tradKey="syndic-factures"
        resourceName="syndic-collective-ownerships"
        onDoubleClickAction={false}
        extraUrlPath="/invoices"
        extraQuery={`syndic=${currentSyndic}&manager=${filter}&fields=_id,name,first_address,manager.first_name,manager.last_name`}
        populate="manager,first_city"
        searchBarExtraButtons={<ManagersSearchButton setFilter={setFilter} />}
        columns={columns}
        resourceModelName="Collective-ownership"
        customActionColumn
        withCreateButton={false}
        expandable={expandableTable}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: '2rem'
        }}
      >
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            borderRadius: '15px',
            color: '#ffffff'
          }}
        >
          Download selected
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            borderRadius: '15px',
            color: '#ffffff'
          }}
        >
          Download all
        </Button>
      </div>
    </SyndicViewContentCustom>
  );
};
