import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Row, Select } from 'antd';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { useAgendaContext } from '../../../../contexts/AgendaContext';
import { PageHeaderCustom } from '../../../../components';
import { DrawerEvent } from './DrawerEvent';
import { SearchResources } from '../../../../components/SearchResources/SearchResources';

const { Option } = Select;

/**
 * Custom hook that returns an array of columns for the CustomAgendaHeader component.
 * @hook
 * @returns {Array} An array of columns for the CustomAgendaHeader component.
 */
export const CustomAgendaHeader = ({ setExtraQuery }) => {
  const { t } = useTranslation();

  const { syndics, isDataLoading } = useGlobalContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const filterOptions = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    setPurpose,
    setFormToOpen,
    setOpenedDrawer,
    setDrawerTitle
  } = useAgendaContext();

  const showDrawer = (formToOpen) => {
    setOpenedDrawer(formToOpen.name);
    setDrawerTitle(formToOpen.title);
    setFormToOpen(formToOpen.name);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <PageHeaderCustom title="Agenda" />
      {isDrawerOpen ? <DrawerEvent /> : null}
      <Card
        style={{
          border: 'none'
        }}
      >
        <Card
          style={{
            backgroundColor: 'white',
            border: 'none'
          }}
        >
          <Row
            gutter={[16, 16]}
            style={{
              marginBottom: 10
            }}
          >
            <Col>
              <SearchResources />
            </Col>
            <Col>
              <Select
                style={{
                  width: 250,
                  marginLeft: 20
                }}
                showSearch
                allowClear
                onClear={() => {
                  setExtraQuery('isTasks=true');
                  setForceRefresh(!forceRefresh);
                }}
                optionFilterProp="children"
                filterOption={(input, option) => filterOptions(input, option)}
                onSelect={(value) => {
                  setExtraQuery(
                    `isTasks=true&syndic=${value}&populate=user,manager,syndic`
                  );
                  setForceRefresh(!forceRefresh);
                }}
                loading={isDataLoading}
                placeholder={t('events.syndic')}
                onChange={() => {
                  setForceRefresh(!forceRefresh);
                }}
              >
                {(syndics || []).map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.society_name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setPurpose('create');
                  showDrawer({
                    name: 'PCS_APPOINTMENT',
                    title: t('events.creates.extern')
                  });
                }}
              >
                {t('events.creates.extern')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setPurpose('create');
                  showDrawer({
                    name: 'INTERN',
                    title: t('events.creates.intern')
                  });
                }}
              >
                {t('events.creates.intern')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setPurpose('create');
                  showDrawer({
                    name: 'UNAVAILABILITY',
                    title: t('events.creates.unavailability')
                  });
                }}
              >
                {t('events.creates.unavailability')}
              </Button>
            </Col>
            <Col>
              <MenuOutlined
                style={{
                  fontSize: 20
                }}
              />
            </Col>
          </Row>
        </Card>
      </Card>
    </>
  );
};

CustomAgendaHeader.propTypes = {
  setExtraQuery: PropTypes.func.isRequired
};

CustomAgendaHeader.defaultProps = {};
