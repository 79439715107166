import { useEffect } from 'react';
import { Collapse, Row, Col, Badge, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ChargingGrid from '../../../../charging-grid/ChargingGrid';
import { setCollapseItems } from '../utils/setCollapseItems';
import { RemoveSelectionButton } from '../buttons/RemoveSelectionButton';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { ListNotes } from '../components/ListNotes';

/**
 * Returns an object containing the tabs items for the document view.
 *
 * @hook
 * @returns {Object} - The tabs items object.
 */
export const documentTabs = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    mission,
    collectiveOwnership,
    setCollectiveOwnership,
    documents,
    notes
  } = useMissionContext();
  const { collapseItems } = setCollapseItems();
  const { viewDocument, downloadDocument } = useDownloadDocument();

  useEffect(() => {
    setCollectiveOwnership(mission.collective_ownership);
  }, [mission]);

  const cerfaDocumentNumber = documents.filter(
    (document) =>
      document?.main_type === 'CERFA' && document.status === 'EXPECTED'
  ).length;

  const variousDocumentNumber = documents.filter(
    (document) =>
      document?.main_type === 'VARIOUS' && document.status === 'EXPECTED'
  ).length;

  const chargingGridDocument = documents.find(
    (document) => document?.fileKey === 'charging_grids'
  );

  const pcs1TabsItems = [
    {
      key: '1',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.PCS_1')}</Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '2',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.cerfa_documents')}</Col>
          <Col>
            <Badge count={cerfaDocumentNumber} />
          </Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '3',
      label: t('missions.cards.documents.tabs.charging_grid'),
      children: (
        <Row gutter={[16, 16]}>
          <Col>
            <ChargingGrid
              resourceDatas={collectiveOwnership}
              setResourceDatas={setCollectiveOwnership}
              isLoading={isLoading}
            />
          </Col>
          {chargingGridDocument ? (
            <Col>
              <Button
                onClick={() =>
                  chargingGridDocument.files[0].type === 'application/pdf'
                    ? viewDocument(chargingGridDocument.files[0])
                    : downloadDocument({
                        _id: chargingGridDocument.files[0]._id,
                        metadata: chargingGridDocument.files[0].metadata,
                        contentType: chargingGridDocument.files[0].contentType
                      })
                }
              >
                {t('missions.buttons.download_charging_grid')}
              </Button>
            </Col>
          ) : null}
        </Row>
      )
    },
    {
      key: '4',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.various_documents')}</Col>
          <Col>
            <Badge count={variousDocumentNumber} />
          </Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    ...(notes.length
      ? [
          {
            key: '5',
            label: (
              <Row gutter={[16, 16]}>
                <Col>{t('missions.cards.documents.tabs.notes')}</Col>
              </Row>
            ),
            children: (
              <Row>
                <ListNotes />
              </Row>
            )
          }
        ]
      : [])
  ];

  const pcs3TabsItems = [
    {
      key: '1',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.PCS_3')}</Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '2',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.PCS_2')}</Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '3',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.PCS_1')}</Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '4',
      label: t('missions.cards.documents.tabs.charging_grid'),
      children: (
        <Row gutter={[16, 16]}>
          <Col>
            <ChargingGrid
              resourceDatas={collectiveOwnership}
              setResourceDatas={setCollectiveOwnership}
              isLoading={isLoading}
            />
          </Col>
          {chargingGridDocument ? (
            <Col>
              <Button
                onClick={() =>
                  chargingGridDocument.files[0].type === 'application/pdf'
                    ? viewDocument(chargingGridDocument.files[0])
                    : downloadDocument({
                        _id: chargingGridDocument.files[0]._id,
                        metadata: chargingGridDocument.files[0].metadata,
                        contentType: chargingGridDocument.files[0].contentType
                      })
                }
              >
                {t('missions.buttons.download_charging_grid')}
              </Button>
            </Col>
          ) : null}
        </Row>
      )
    },
    ...(notes.length
      ? [
          {
            key: '5',
            label: (
              <Row gutter={[16, 16]}>
                <Col>{t('missions.cards.documents.tabs.notes')}</Col>
              </Row>
            ),
            children: (
              <Row>
                <ListNotes />
              </Row>
            )
          }
        ]
      : [])
  ];

  const pcs2TabsItems = [
    {
      key: '1',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.PCS_2')}</Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '2',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.PCS_1')}</Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '3',
      label: t('missions.cards.documents.tabs.charging_grid'),
      children: (
        <Row gutter={[16, 16]}>
          <Col>
            <ChargingGrid
              resourceDatas={collectiveOwnership}
              setResourceDatas={setCollectiveOwnership}
              isLoading={isLoading}
            />
          </Col>
          {chargingGridDocument ? (
            <Col>
              <Button
                onClick={() =>
                  chargingGridDocument.files[0].type === 'application/pdf'
                    ? viewDocument(chargingGridDocument.files[0])
                    : downloadDocument({
                        _id: chargingGridDocument.files[0]._id,
                        metadata: chargingGridDocument.files[0].metadata,
                        contentType: chargingGridDocument.files[0].contentType
                      })
                }
              >
                {t('missions.buttons.download_charging_grid')}
              </Button>
            </Col>
          ) : null}
        </Row>
      )
    },
    ...(notes.length
      ? [
          {
            key: '4',
            label: (
              <Row gutter={[16, 16]}>
                <Col>{t('missions.cards.documents.tabs.notes')}</Col>
              </Row>
            ),
            children: (
              <Row>
                <ListNotes />
              </Row>
            )
          }
        ]
      : [])
  ];

  const tabsItems = [
    {
      key: '1',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.cerfa_documents')}</Col>
          <Col>
            <Badge count={cerfaDocumentNumber} />
          </Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    ...(mission.type !== 'PLANS_RESEARCH'
      ? [
          {
            key: '2',
            label: t('missions.cards.documents.tabs.charging_grid'),
            children: (
              <Row gutter={[16, 16]}>
                <Col>
                  <ChargingGrid
                    resourceDatas={collectiveOwnership}
                    setResourceDatas={setCollectiveOwnership}
                    isLoading={isLoading}
                  />
                </Col>
                {chargingGridDocument ? (
                  <Col>
                    <Button
                      onClick={() =>
                        chargingGridDocument.files[0].type === 'application/pdf'
                          ? viewDocument(chargingGridDocument.files[0])
                          : downloadDocument({
                              _id: chargingGridDocument.files[0]._id,
                              metadata: chargingGridDocument.files[0].metadata,
                              contentType:
                                chargingGridDocument.files[0].contentType
                            })
                      }
                    >
                      {t('missions.buttons.download_charging_grid')}
                    </Button>
                  </Col>
                ) : null}
              </Row>
            )
          }
        ]
      : []),
    {
      key: '3',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.various_documents')}</Col>
          <Col>
            <Badge count={variousDocumentNumber} />
          </Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    ...(notes.length
      ? [
          {
            key: '4',
            label: (
              <Row gutter={[16, 16]}>
                <Col>{t('missions.cards.documents.tabs.notes')}</Col>
              </Row>
            ),
            children: (
              <Row>
                <ListNotes />
              </Row>
            )
          }
        ]
      : [])
  ];

  return { tabsItems, pcs1TabsItems, pcs2TabsItems, pcs3TabsItems };
};
