import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Handles the merging of lots.
 * @function
 * @async
 * @param {Object} options - The options object.
 * @param {Object} options.body - The body object.
 * @param {Array} options.dataSource - The data source array.
 * @param {Function} options.antdMessage - The antdMessage function.
 * @param {Function} options.t - The translation function.
 * @param {Array} options.selectedRowKeys - The selected row keys array.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {Object} options.chargingGrid - The charging grid object.
 * @param {Function} options.setChargingGrid - The setChargingGrid function.
 * @param {Array} options.resourceDatas - The resource datas array.
 * @param {Function} options.setResourceDatas - The setResourceDatas function.
 * @param {Function} options.setIsMergeLotModalOpen - The setIsMergeLotModalOpen function.
 * @param {Function} options.message - The message function.
 * @returns {void}
 */
export const handleMergeLots = async ({
  body,
  dataSource,
  antdMessage,
  t,
  selectedRowKeys,
  dispatchAPI,
  chargingGrid,
  setChargingGrid,
  resourceDatas,
  setResourceDatas,
  setIsMergeLotModalOpen,
  message,
  setSelectedRowKeys
}) => {
  let hasError = false;

  dataSource.forEach((lot) => {
    if (body.lot === lot.Lot.value && !lot.delete_row) {
      if (!body.is_duplicate_lot) {
        antdMessage.warning(
          t('charging-grids.show.messages.lot-exists', {
            lot: lot.Lot.value
          }),
          5
        );
        hasError = true;
      }
    }

    if (body.is_duplicate_lot) {
      if (
        lot.Lot.value !== 'Voulu' &&
        lot.Lot.value !== 'Total' &&
        typeof lot.Lot.value === 'string'
      ) {
        const lotBis = parseInt(lot.Lot.value.replace('bis', '').trim(), 10);

        if (lotBis === body.lot) {
          antdMessage.warning(
            t('charging-grids.show.messages.lot-exists', {
              lot: lot.Lot.value
            }),
            5
          );
          hasError = true;
        }
      }
    }
  });

  if (hasError) {
    return;
  }

  try {
    const formData = new FormData();

    const newBody = { ...body };

    if (body.is_duplicate_lot) {
      newBody.lot = `${body.lot} bis`;
    }

    formData.append('values', JSON.stringify(newBody));
    formData.append('row_keys', JSON.stringify(selectedRowKeys));

    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${chargingGrid._id}?type=merge_data`,
      body: formData
    });

    updateChargingGrids({
      resourceDatas,
      chargingGrid: data,
      setResourceDatas
    });
    setChargingGrid(data);
    setSelectedRowKeys([]);
    setIsMergeLotModalOpen(false);
  } catch (error) {
    message(error);
  }
};
