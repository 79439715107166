import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * @component
 * @description The `DescriptionList` component renders a list of key-value pairs in a descriptive format.
 * It utilizes Ant Design's `Descriptions` component to display the list, with support for
 * vertical or horizontal layout. The component also leverages the `useTranslation` hook
 * for internationalization, ensuring that labels are localized.
 * @param {string} title The title of the description list.
 * @param {Array} data The data to display in the description list.
 * @param {boolean} skipEmpty Flag to skip empty content in the list.
 * @param {boolean} translate Flag to enable translation of labels.
 * @param {boolean} vertical Flag to display the list vertically.
 * @returns {JSX.Element} The description list component.
 */
export const DescriptionList = ({
  title,
  data,
  skipEmpty,
  translate,
  vertical
}) => {
  const { t } = useTranslation();

  const label = (item) => {
    if (item.noLabel) {
      return null;
    }
    return translate ? t(`${item.label}`) : item.label;
  };

  return (
    <div>
      <Descriptions title={title} layout={vertical ? 'vertical' : 'horizontal'}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty) && (
              <Descriptions.Item
                label={label(item)}
                span={item.span || 1}
                key={item.label}
              >
                <Typography style={{ fontWeight: 700 }}>
                  {item.content || '-'}
                </Typography>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool,
  vertical: PropTypes.bool
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false,
  vertical: false
};
