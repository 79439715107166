import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const tradKey = 'collections.form';

/**
 * Represents a modal component for multiple edit mode of documents and grids.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.selectedCollectiveOwnerships - The selected collective ownerships.
 * @param {Function} props.setForceRefresh - The function to set the force refresh state.
 * @returns {JSX.Element} The JSX element representing the MultipleEditModeDocsGrillesModal.
 */
export const MultipleEditModeDocsGrillesModal = ({
  selectedCollectiveOwnerships,
  setForceRefresh
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modOptions, setModOptions] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const getMods = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/collective-ownerships/enums?select=mods'
    });
    const options = data.map((mod) => ({
      value: mod,
      label: t(`collections.tags.${mod}`)
    }));
    setModOptions(options);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const ids = selectedCollectiveOwnerships.map((item) => item._id);
      await dispatchAPI('PATCH', {
        url: '/collective-ownerships/update-many',
        body: { ids, ...values }
      });
      setIsSubmitting(false);
      setForceRefresh((prev) => !prev);
      setIsModalOpen(false);
    } catch (e) {
      message(e);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getMods();
  }, []);

  return (
    <>
      <Button type="primary" onClick={setIsModalOpen}>
        {t(`${tradKey}.edit_docs_grilles`)}
      </Button>
      <Modal
        open={isModalOpen}
        footer={[]}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form layout="horizontal" form={form} onFinish={handleSubmit}>
          <Form.Item name="mod" label={t(`${tradKey}.mod`)}>
            <Select
              options={modOptions}
              placeholder={t(`${tradKey}.mod`)}
              allowClear
            />
          </Form.Item>
          <Form.Item name="is_docs" label={t(`${tradKey}.is_docs`)}>
            <Input placeholder={t(`${tradKey}.is_docs`)} />
          </Form.Item>
          <Form.Item name="grids" label={t(`${tradKey}.grids`)}>
            <Input placeholder={t(`${tradKey}.grids`)} />
          </Form.Item>

          <Form.Item>
            <Row justify="center">
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                <CheckOutlined />
                {`${t('buttons.save')} `}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

MultipleEditModeDocsGrillesModal.propTypes = {
  selectedCollectiveOwnerships: PropTypes.shape([]).isRequired,
  setForceRefresh: PropTypes.func.isRequired
};
