import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getManagersForReassign } from '../utils/getManagersForReassign';
import { getSelectedSyndicCollectiveOwnerships } from '../utils/getSelectedSyndicCollectiveOwnerships';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { HandleDeleteSyndicModalContent } from '../utils/HandleDeleteSyndicModalContent';
import { handleDeleteSyndicModalTitle } from '../utils/handleDeleteSyndicModalTitle';

/**
 * DeleteModal component for deleting a syndic.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isDeleteModalOpen - Flag indicating if the delete modal is open.
 * @param {function} props.setIsDeleteModalOpen - Function to set the delete modal open flag.
 * @param {string} props.selectedSyndic - The ID of the selected syndic.
 * @param {Array} props.syndics - The array of syndics.
 * @param {function} props.setForceRefresh - Function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - Flag indicating if a force refresh is needed.
 * @returns {JSX.Element} The DeleteModal component.
 */
export const DeleteModal = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  selectedSyndic,
  syndics,
  setForceRefresh,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isFieldLoading, setIsFieldLoading] = useState(true);
  const [managers, setManagers] = useState([]);
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);

  useEffect(() => {
    (async () => {
      getManagersForReassign({
        syndics,
        selectedSyndic,
        setIsFieldLoading,
        setManagers
      });
      await getSelectedSyndicCollectiveOwnerships({
        dispatchAPI,
        message,
        selectedSyndic,
        setCollectiveOwnerships
      });
    })();
  }, []);

  return (
    <Modal
      title={handleDeleteSyndicModalTitle({
        collectiveOwnerships,
        selectedSyndic,
        t
      })}
      visible={isDeleteModalOpen}
      onOk={() => setIsDeleteModalOpen(false)}
      onCancel={() => setIsDeleteModalOpen(false)}
      footer={null}
    >
      <HandleDeleteSyndicModalContent
        collectiveOwnerships={collectiveOwnerships}
        forceRefresh={forceRefresh}
        isFieldLoading={isFieldLoading}
        managers={managers}
        selectedSyndic={selectedSyndic}
        setForceRefresh={setForceRefresh}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        t={t}
        disptchAPI={dispatchAPI}
        message={message}
      />
    </Modal>
  );
};

DeleteModal.propTypes = {
  isDeleteModalOpen: PropTypes.bool.isRequired,
  setIsDeleteModalOpen: PropTypes.func.isRequired,
  selectedSyndic: PropTypes.string.isRequired,
  syndics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool
};

DeleteModal.defaultProps = {
  forceRefresh: false
};
