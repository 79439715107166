import { useEffect, useState } from 'react';
import { DatePicker, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useGlobalContext } from '../../../../../../contexts/GlobalContext';

const { Option } = Select;

/**
 * @hook
 * Custom hook that provides fields for the ComplianceElanRouter Audit Step 1.
 * It fetches data for managers and municipalities, and returns an array of fields.
 *
 * @returns {Object} An object containing the fields array.
 */
export const useCollectiveOwnershipSyndicFields = () => {
  const { dispatchAPI } = useAuthContext();
  const { currentSyndic } = useGlobalContext();
  const [managers, setManagers] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const { t } = useTranslation();

  const getManagers = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `/syndic/${currentSyndic}?populate=managers&fields=managers,managers._id,managers.first_name,managers.last_name`
    });
    setManagers(data.managers);
  };
  const getMunicipalities = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/municipalities?deleted=false&fields=name,postal_code'
    });
    setMunicipalities(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([getManagers(), getMunicipalities()]);
      setIsLoading(false);
    };

    fetchData();
  }, [currentSyndic]);

  const fields = [
    {
      startWithDivider: {
        title: t('pcs-pre-productions.form.collective_ownership_fields')
      },
      label: 'code_syndic_view',
      name: ['code'],
      key: 'code'
    },
    {
      name: ['name'],
      key: 'name'
    },
    {
      label: 'address.address',
      name: ['address', 'street'],
      rules: [{ required: true }],
      key: 'street'
    },
    {
      name: ['address', 'additional'],
      key: 'additional'
    },
    {
      name: ['address', 'postal_code'],
      rules: [{ required: true }],
      key: 'postal_code'
    },
    {
      name: ['first_city'],
      rules: [{ required: true }],
      key: 'city',
      input: (
        <Select loading={isLoading}>
          {municipalities.map((municipality) => (
            <Option key={municipality._id} value={municipality._id}>
              {`${municipality?.name}, ${municipality?.postal_code}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['AG_date'],
      key: 'AG_date',
      input: (
        <DatePicker
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          placeholder={t('placeholder.select_date')}
        />
      )
    },
    {
      startWithDivider: {
        title: t('pcs-pre-productions.form.manager_fields')
      },
      name: ['manager'],
      key: 'manager',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          loading={isLoading}
          optionFilterProp="children"
        >
          {managers?.map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.last_name} ${item.first_name}`}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return { fields };
};
