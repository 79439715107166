import { useEffect, useState } from 'react';
import { Switch, Form, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Custom hook that returns an array of fields for a form.
 *
 * @hook
 * @param {object} options - The options for the hook.
 * @param {object} options.form - The form instance.
 * @returns {object} - The fields array.
 */
export const useFields = ({ form }) => {
  const { t } = useTranslation();
  const [selectedSwitch, setSelectedSwitch] = useState(null);

  useEffect(() => {
    if (selectedSwitch === 'compliance') {
      form.setFieldsValue({
        realized_modifications: {
          compliance: true,
          adaptation: false
        }
      });
    } else if (selectedSwitch === 'adaptation') {
      form.setFieldsValue({
        realized_modifications: {
          compliance: false,
          adaptation: true
        }
      });
    } else {
      form.setFieldsValue({
        realized_modifications: {
          compliance: false,
          adaptation: false
        }
      });
    }
  }, [selectedSwitch, form]);

  const handleSwitchChange = (checked, type) => {
    setSelectedSwitch(checked ? type : null);
  };

  const fields = [
    {
      noLabel: true,
      input: (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col>
                <Form.Item
                  noStyle
                  name={['realized_modifications', 'compliance']}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) =>
                      handleSwitchChange(checked, 'compliance')
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Text>{t('missions.form.compliance')}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col>
                <Form.Item
                  noStyle
                  name={['realized_modifications', 'adaptation']}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) =>
                      handleSwitchChange(checked, 'adaptation')
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Text>{t('missions.form.adaptation')}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    },
    {
      name: ['compliance_project_in_pdf'],
      key: 'compliance_project_in_pdf',
      maxFilesCount: 100,
      input: 'File',
      requestedDocumentType: 'Mise en conformité en Word',
      rules: [{ required: true }]
    },
    {
      name: ['compliance_project_in_word'],
      key: 'compliance_project_in_word',
      maxFilesCount: 100,
      input: 'File',
      requestedDocumentType: 'Mise en conformité en PDF',
      rules: [{ required: true }]
    },
    {
      name: ['lawyer_invoice_in_pdf'],
      key: 'lawyer_invoice_in_pdf',
      maxFilesCount: 100,
      input: 'File',
      requestedDocumentType: 'Facture avocat'
    }
  ];

  return { fields };
};
