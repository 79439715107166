/**
 * Retrieves prices from the server based on the provided mission.
 * @param {Object} options - The options object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function used to make API requests.
 * @param {Function} options.message - The message function used to display error messages.
 * @param {string} options.missionType - The missionType for which prices should be retrieved.
 * @param {Function} options.setPrices - The function used to set the retrieved prices.
 * @param {Function} options.setPriceToEdit - The function used to set the price to be edited.
 * @param {string} options.id - The ID of the price to be edited.
 * @returns {Promise<void>} - A promise that resolves when the prices are retrieved and set.
 */
export const getPrices = async ({
  dispatchAPI,
  message,
  missionType,
  setPrices,
  setPriceToEdit,
  id
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `price-system?mission=${missionType}`
    });
    setPrices(data);
    setPriceToEdit(data.filter((el) => el._id === id)[0]);
  } catch (e) {
    message(e);
  }
};
