import { Form, Select, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * Renders a form for merging SPF departmental archives.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.spfDepartmentalArchive - The SPF departmental archive object.
 * @param {string} props.selectedSpfDepartmentalArchive - The selected SPF departmental archive ID.
 * @param {boolean} props.isFieldsLoading - Indicates if the fields are loading.
 * @param {Function} props.setSelectedSpfDepartmentalArchive - Callback function to set the selected SPF departmental archive.
 * @param {Array} props.spfDepartmentalArchives - The array of SPF departmental archives.
 * @param {Function} props.setSpfDepartmentalArchive - Callback function to set the merge modal open state.
 * @returns {JSX.Element} The JSX element representing the merge form.
 */
export const MergeForm = ({
  spfDepartmentalArchive,
  selectedSpfDepartmentalArchive,
  isFieldsLoading,
  setSelectedSpfDepartmentalArchive,
  spfDepartmentalArchives,
  setSpfDepartmentalArchive
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = () => {
    navigate(
      `/configurations/merge/${spfDepartmentalArchive._id}/${selectedSpfDepartmentalArchive}/spf-departmental-archives`
    );
  };

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form>
      <Form.Item>
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
          onChange={(value) => setSelectedSpfDepartmentalArchive(value)}
        >
          {(spfDepartmentalArchives || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Row gutter={[16, 16]} justify="end">
        <Col>
          <Button type="primary" onClick={() => setSpfDepartmentalArchive({})}>
            {t('buttons.cancel')}
          </Button>
        </Col>

        <Col>
          <Button type="primary" onClick={onFinish}>
            {t('buttons.save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

MergeForm.propTypes = {
  setSpfDepartmentalArchive: PropTypes.func.isRequired,
  spfDepartmentalArchive: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  spfDepartmentalArchives: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string
    })
  ).isRequired,
  isFieldsLoading: PropTypes.bool.isRequired,
  selectedSpfDepartmentalArchive: PropTypes.string.isRequired,
  setSelectedSpfDepartmentalArchive: PropTypes.func.isRequired
};
