// Main nav subMenuItems
export const routesCollectiveOwnership = {
  DASHBOARD: 'dashboard',
  COLLECTIVE_OWNERSHIPS: 'collective-ownerships',
  ACTIONS_TO_DO: 'actions-to-do',
  DRAFTS: 'drafts',
  PENDING_VALIDATION: 'pending-validation',
  INVOICES: 'invoices',
  AUDIT: 'audit',
  COMPLIANCE: 'compliance',
  PUBLICATION: 'publication'
};

export const routesPlansResearch = {
  DASHBOARD: 'dashboard',
  COLLECTIVE_OWNERSHIPS: 'collective-ownerships',
  ACTIONS_TO_DO: 'actions-to-do',
  DRAFTS: 'drafts',
  PENDING_VALIDATION: 'pending-validation',
  INVOICES: 'invoices',
  DEVIS: 'devis'
};

export const subRoutes = {};

export const pathSearches = {};
