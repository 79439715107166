/**
 * Custom hook that generates the list content for remarks.
 *
 * @param {Object} data - The data object containing remarks.
 * @returns {Array} - An array containing the list content for remarks.
 */
export const useRemarksListContent = (data = {}) => {
  const { remarks } = data;

  return [
    {
      label: 'pcs-pre-productions.form.remarks',
      span: 1,
      content: remarks || '-',
      noLabel: true
    }
  ];
};
