import { useTranslation } from 'react-i18next';
import { Radio, Tooltip } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

/**
 * Custom hook that returns an array of columns for the plan request table.
 * @hook
 * @param {Object} options - Options for the hook.
 * @param {Function} options.setPlan - Function to set the plan.
 * @param {Function} options.setExpeditor - Function to set the expeditor.
 * @returns {Array} - Array of columns.
 */
export const useColumns = ({ setPlan, setExpeditor }) => {
  const { t } = useTranslation();

  return [
    {
      title: t('missions.cards.plan_requests.archivist_notary'),
      dataIndex: 'editor_notary',
      key: 'archivist_notary',
      render: (editor_notary) =>
        `${editor_notary?.archivist_notary?.office_name} - ${editor_notary?.archivist_notary?.email}`
    },
    {
      title: t('missions.cards.plan_requests.editor_notary'),
      dataIndex: 'editor_notary',
      key: 'editor_notary',
      render: (editor_notary) =>
        `${editor_notary?.first_name} ${editor_notary?.last_name}`
    },
    {
      title: t('missions.cards.plan_requests.date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => dayjs(date).format('DD/MM/YYYY')
    },
    {
      title: t('missions.cards.plan_requests.last_reminder'),
      dataIndex: 'last_reminder',
      key: 'last_reminder',
      render: (last_reminder) =>
        last_reminder ? dayjs(last_reminder).format('DD/MM/YYYY') : '-'
    },
    {
      title: t('missions.cards.plan_requests.status'),
      key: 'status',
      editable: true,
      render: (record) => (
        <Radio.Group
          value={record.status}
          onChange={(e) => {
            setPlan({
              _id: record._id,
              collective_ownership_plan: record.plan,
              date: record.date,
              status: e.target.value
            });
            setExpeditor(record?.editor_notary?.archivist_notary?._id || '');
          }}
          disabled={record.status === 'PLANS_AVAILABLE'}
        >
          <Radio value="NO_RESPONSE">
            {t('missions.cards.plan_requests.tags.NO_RESPONSE')}
          </Radio>
          <Tooltip title={t('missions.messages.disabled_rollback')} color="red">
            <Radio value="PLANS_AVAILABLE">
              {t('missions.cards.plan_requests.tags.PLANS_AVAILABLE')}
            </Radio>
          </Tooltip>
          <Radio value="PLANS_NOT_AVAILABLE">
            {t('missions.cards.plan_requests.tags.PLANS_NOT_AVAILABLE')}
          </Radio>
        </Radio.Group>
      )
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <MailOutlined
          onClick={() => {
            setPlan({
              _id: record._id,
              last_reminder: dayjs()
            });
          }}
        />
      )
    }
  ];
};
