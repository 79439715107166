/**
 * Returns a configuration object for creating a resource.
 *
 * @hook
 * @param {Object} options - The options for the configuration.
 * @param {Object} options.fileList - The list of files to be uploaded.
 * @param {Object} options.customFormData - The custom form data.
 * @param {Object} options.addDocumentCardDatas - The additional document card data.
 * @param {Object} options.form - The form instance.
 * @param {Object} options.mission - The mission object.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({
  fileList,
  customFormData,
  addDocumentCardDatas,
  form,
  mission
}) => ({
  onCreateResource: {
    setBody: (data) => {
      Object.keys(fileList).forEach((key, index) => {
        fileList[key].forEach((file) => {
          customFormData.append(`documents_${index}`, file);
        });
      });

      const updatedData = {
        ...data,
        config_document_ref: addDocumentCardDatas.type,
        mission_type: mission.type || ''
      };

      form.resetFields();

      return updatedData;
    }
  }
});
