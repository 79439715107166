import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useConfig } from './utils/createUpdateConfig';

/**
 * Component for creating or updating a predefined message.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component.
 * @returns {JSX.Element} The JSX element representing the CreateUpdatePredefinedMessage component.
 */
export const CreateUpdatePredefinedMessage = ({ purpose }) => {
  const [editorState, setEditorState] = useState('');
  const { fields } = useFields({ setEditorState, editorState });

  const config = useConfig({ editorState, setEditorState });

  return (
    <CreateUpdateContainer
      fields={fields}
      config={config}
      purpose={purpose}
      baseUrl="predefined-messages"
      resource="predefined-messages"
      withFilesManager={false}
      withEnums={false}
    />
  );
};

CreateUpdatePredefinedMessage.propTypes = {
  purpose: PropTypes.string.isRequired
};
