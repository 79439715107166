import { Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

/**
 * Custom hook that returns an array of fields for the alert form.
 * @hook
 * @returns {Object} An object containing the fields array.
 */
export const useFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      noLabel: true,
      key: 'content',
      dataIndex: 'content',
      input: (
        <Form.Item name={['content']}>
          <TextArea placeholder={t('placeholder.write_text_here')} />
        </Form.Item>
      )
    }
  ];

  return { fields };
};
