import dayjs from 'dayjs';

/**
 * The `usePlansListContent` hook is designed to structure and prepare plan-related data for presentation in a list or detailed view format.
 * It processes information about plans, such as notary writer details, realization date, transcription date, and plan identifiers,
 * formatting them for display and using conditional rendering to handle missing data.
 *
 * @param {Object} [data={}] - An object containing plan-related information. The object's properties may include notary writer details,
 * realization date, transcription date, plan volume, and plan number associated with a specific plan or document.
 *
 * Returns:
 * An array of objects where each object represents a piece of information to be displayed in the list. Each object includes:
 * - `label`: A string key used to identify the label of the information. This label is typically used for displaying the name of the field in a list or table.
 * - `span`: A numerical value indicating the span or width of the item in the list layout, used for alignment and formatting in grid or table-like displays.
 * - `content`: The content to be displayed for the item. This can be a simple string, a formatted string combining multiple pieces of data, or a formatted date string.
 * Conditional rendering is used to display a placeholder '-' for missing or undefined data.
 *
 * The hook uses the `dayjs` library to format date fields (`realization_date` and `transcription_date`) into a human-readable format ('DD-MM-YYYY').
 * This ensures that dates are presented consistently and are easily interpretable by users.
 *
 * Usage:
 * This hook is intended for use in components that display detailed information about plans or documents, such as a profile page, detail view, or information panel.
 * It simplifies the process of organizing and displaying complex data sets by structuring the data into a consistent format suitable for list or table-like presentations,
 * especially in contexts related to real estate, legal documents, or project planning.
 */

export const usePlansListContent = (data = {}) => {
  const {
    notary_writer,
    realization_date,
    transcription_date,
    plan_volume,
    plan_number
  } = data;

  return [
    {
      label: 'collective-ownerships.form.notary_writer',
      span: 3,
      content: notary_writer
        ? `${notary_writer?.last_name || ''} ${notary_writer?.first_name || ''}`
        : '-'
    },
    {
      label: 'collective-ownerships.form.realization_date',
      span: 1,
      content: realization_date
        ? dayjs(realization_date).format('DD-MM-YYYY')
        : '-'
    },
    {
      label: 'collective-ownerships.form.transcription_date',
      span: 2,
      content: transcription_date
        ? dayjs(transcription_date).format('DD-MM-YYYY')
        : '-'
    },
    {
      label: 'collective-ownerships.form.plan_volume',
      span: 1,
      content: plan_volume || '-'
    },
    {
      label: 'collective-ownerships.form.plan_number',
      span: 1,
      content: plan_number || '-'
    }
  ];
};
