import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tag, Checkbox, Row, Tooltip, Button, Popconfirm } from 'antd';
import { WarningOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { EditableTableMethods } from '../../../../../components/editableTableComponents/methods';
import { missionTag } from '../../../../../utils/constants/tagColors';
import { Document } from '../../../../../models/Document';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { setDocumentIcon } from '../../../../../components/setDocumentIcon';
import { CollectiveOwnership } from '../../../../../models/CollectiveOwnership';
import { useCollectiveOwnershipContext } from '../../../../../contexts/CollectiveOwnershipContext';
import { inputDisplaySmall } from '../../../../../utils/generateFormItem/utils/inputDisplaySmall';

/**
 * Custom hook that returns columns configuration for a table.
 *
 * @hook
 * @param {Function} setEditingKey - Function to set the editing key.
 * @param {Object} form - Form object.
 * @returns {Object} - Object containing columns configuration.
 */
export const useColumns = ({
  setEditingKey,
  form,
  noDragger,
  noDeleteButton
}) => {
  const { t } = useTranslation();
  const { id: collectiveOwnershipId } = useParams();
  const { editCell, handleCheckbox } = EditableTableMethods();
  const { viewDocument, downloadDocument } = useDownloadDocument();
  const { forceRefresh, setForceRefresh } = useCollectiveOwnershipContext();

  const draggerProps = ({ id }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      await Document.uploadDocument({
        id,
        file: objectFile.file,
        setForceRefresh,
        t
      });
    },
    fileList: []
  });

  const columns = [
    {
      title: t('missions.form.document_type'),
      key: 'document_type',
      dataIndex: 'config_document',
      render: (config_document) => config_document?.wording || '-',
      sorter: true
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      editable: true,
      inputType: 'date-picker',
      render: (_, record) => (
        <Row
          onClick={() => editCell({ record, key: 'date', setEditingKey, form })}
        >
          {record?.date ? dayjs(record?.date).format('DD-MM-YYYY') : '-'}
        </Row>
      ),
      sorter: true
    },
    {
      title: t('missions.form.supply_by_syndic'),
      key: 'supply_by_syndic',
      dataIndex: 'supply_by_syndic',
      render: (_, record) => (
        <Checkbox
          defaultChecked={record.supply_by_syndic}
          onChange={(e) =>
            handleCheckbox({
              value: e.target.checked,
              record,
              type: 'supply_by_syndic',
              resource: 'documents',
              setForceRefresh,
              forceRefresh
            })
          }
        />
      )
    },
    {
      title: t('missions.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'comment', setEditingKey, form })
          }
        >
          {record?.comment || '-'}
        </Row>
      ),
      sorter: true
    },
    {
      title: t('missions.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={missionTag[status]}>{t(`missions.tags.${status}`)}</Tag>
      ),
      sorter: true
    },
    ...(noDragger
      ? []
      : [
          {
            key: 'file',
            render: (record) =>
              inputDisplaySmall(
                true,
                null,
                { ...draggerProps({ id: record._id }) },
                false,
                t
              )
          }
        ]),
    {
      key: 'files',
      dataIndex: 'files',
      render: (files) =>
        files?.map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        )),

      sorter: true
    },
    ...(noDeleteButton
      ? []
      : [
          {
            key: 'action',
            align: 'right',
            fixed: 'right',
            render: (record) => (
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() =>
                  CollectiveOwnership.deleteDocument({
                    collective_ownership_id: collectiveOwnershipId,
                    setForceRefresh,
                    forceRefresh,
                    document_id: record._id
                  })
                }
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            )
          }
        ])
  ];

  return {
    columns
  };
};
