import { useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { useItems } from './useItems';

/**
 * Component for adding payments to invoices.
 * @component
 * @returns {JSX.Element} The rendered AddPayments component.
 */
export const AddPayments = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;
  const { invoices } = state;

  const { items } = useItems({
    invoices: invoices || state
  });

  return (
    <>
      <PageHeaderCustom title={t('invoices.titles.payments')} />
      <ContentCustom>
        <Tabs tabPosition="left" items={items} />
      </ContentCustom>
    </>
  );
};
