import dayjs from 'dayjs';
import { Quotation } from '../../../models/Quotation';
import { createFileFromJSON } from '../../../components/CreateUpdateContainer/utils/createFileFromJSON';

/**
 * Returns a configuration object for handling resource retrieval.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setMissionType - The function to set the mission type.
 * @param {Function} options.setFilesList - The function to set the files list.
 * @param {Function} options.dispatchAPI - The function to dispatch an API request.
 * @param {Function} options.message - The function to display a message.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({
  setMissionType,
  setFilesList,
  dispatchAPI,
  message
}) => ({
  onGetResource: {
    setFields: async (data) => {
      setMissionType(data?.mission_type);
      const validation = (data?.validation || []).reduce((obj, key) => {
        const updatedObject = obj;
        updatedObject[key] = true;
        return updatedObject;
      }, {});

      const desiredDocuments = (data?.desired_documents.documents || []).reduce(
        (obj, key) => {
          const updatedObject = obj;
          updatedObject[key] = true;
          return updatedObject;
        },
        {}
      );

      if (data?.documents.length) {
        const filePromise = (data.documents || []).map((document) =>
          createFileFromJSON(document.files[0], dispatchAPI, message)
        );

        const results = await Promise.all(filePromise);
        setFilesList({ documents: results.map((file) => file) });
      }

      const updatedData = {
        ...data,
        mission: data.mission?._id,
        validation,
        actions: Quotation.formatTableDatas(data?.actions || []),
        quotation_acts: Quotation.formatTableDatas(data?.acts || []),
        quotation_act_amounts: Quotation.formatTableDatas(
          data?.act_amounts || []
        ),
        desired_documents: {
          other_documents: data?.desired_documents.other_documents || [],
          ...desiredDocuments
        },
        date: data.date ? dayjs(data.date) : null
      };

      return updatedData;
    }
  }
});
