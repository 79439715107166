import { Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Copy } from '@carbon/icons-react';
import { PlusOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Quotation } from '../../../models/Quotation';
import { useQuotationContext } from '../../../contexts/QuotationContext';

/**
 * Renders additional buttons for creating or updating a quotation.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the quotation (e.g., 'create' or 'edit').
 * @returns {JSX.Element} The rendered component.
 */
export const CreateUpdateExtraButtons = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { formData } = useQuotationContext();

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <Button
          onClick={() => {
            Quotation.duplicateResource({ id, navigate, t });
          }}
        >
          <Copy />
          {t('quotations.buttons.duplicate_quotation')}
        </Button>
      </Col>
      {purpose === 'edit' ? (
        <Col>
          <Button
            onClick={() =>
              navigate(`/quotations/create`, {
                state: { mission: formData.mission }
              })
            }
          >
            <PlusOutlined />
            {t(
              'quotations.buttons.create_other_quotation_with_same_collective_ownership'
            )}
          </Button>
        </Col>
      ) : null}
    </Row>
  );
};

CreateUpdateExtraButtons.propTypes = {
  purpose: PropTypes.string.isRequired
};
