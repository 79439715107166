import { Divider, Row, Card, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { CreateInvoiceButton } from '../buttons/CreateInvoiceButton';
import { ListInvoices } from './ListInvoices';

/**
 * Renders the InvoiceCard component.
 *
 * @component
 * @returns {JSX.Element} The rendered InvoiceCard component.
 */
export const InvoiceCard = ({ type }) => {
  const { t } = useTranslation();

  const { mission, noInvoiceCreated, invoices } = useMissionContext();

  const pcs3BalanceInvoices = invoices.filter(
    (el) => el.pcs_step === 'PCS_3' && el.type === 'BALANCE'
  );

  const pcs2AdvancePaymentInvoices = invoices.find(
    (el) => el.pcs_step === 'PCS_2' && el.type === 'ADVANCE_PAYMENT'
  );

  const pcs1BalanceInvoices = invoices.filter(
    (el) => el.pcs_step === 'PCS_1' && el.type === 'BALANCE'
  );

  const otherMissionBalanceInvoices = invoices.filter(
    (el) => !el.pcs_step && el.type === 'BALANCE'
  );

  const filterInvoices = (pcs_step, invoiceType) => {
    if (pcs_step) {
      return invoices.filter(
        (el) => el.pcs_step === pcs_step && el.type === invoiceType
      );
    }
    return invoices.filter((el) => !el.pcs_step && el.type === invoiceType);
  };

  return (
    <Card
      title={t('missions.cards.titles.invoices')}
      styles={{ body: { padding: '8px' } }}
    >
      <Row gutter={[16, 16]}>
        {type.includes('PCS') && Number(type.slice(-1)) >= 1 ? (
          <Divider>{t(`missions.tags.PCS_1`)}</Divider>
        ) : null}
        {filterInvoices(null, 'BALANCE').length &&
        otherMissionBalanceInvoices.every((el) => el.status === 'PAID') &&
        mission.left_to_pay > 0 ? (
          <CreateInvoiceButton invoiceType="BALANCE" />
        ) : null}
        {(mission?.steps?.sub === 'LAWYER_CONSULTATION' ||
          filterInvoices('PCS_1', 'BALANCE').length) &&
        (pcs1BalanceInvoices.length === 0 ||
          pcs1BalanceInvoices.every((el) => el.status === 'PAID')) &&
        mission.PCS_1.left_to_pay > 0 ? (
          <CreateInvoiceButton step="PCS_1" invoiceType="BALANCE" />
        ) : null}
        <Col span={24} style={{ overflowX: 'auto' }}>
          <ListInvoices
            extraQuery={`&mission=${
              mission._id
            }&showMission=${true}&${noInvoiceCreated}${
              type.includes('PCS') ? '&pcs_step=PCS_1' : ''
            }`}
            missionType={type.includes('PCS') ? 'PCS_1' : type}
          />
        </Col>

        {type.includes('PCS') && Number(type.slice(-1)) >= 2 ? (
          <>
            <Divider>{t(`missions.tags.PCS_2`)}</Divider>
            {filterInvoices('PCS_2', 'ADVANCE_PAYMENT')[0] &&
            pcs2AdvancePaymentInvoices.status === 'PAID' &&
            mission.PCS_2.left_to_pay > 0 ? (
              <CreateInvoiceButton step="PCS_2" invoiceType="BALANCE" />
            ) : null}
            <Col span={24} style={{ overflowX: 'auto' }}>
              <ListInvoices
                extraQuery={`&mission=${mission._id}&showMission=${true}${
                  invoices.filter((el) => el.pcs_step === 'PCS_2').length
                    ? ''
                    : `&noInvoiceCreated=${true}`
                }&pcs_step=PCS_2`}
                missionType="PCS_2"
              />
            </Col>
          </>
        ) : null}

        {type.includes('PCS') && Number(type.slice(-1)) >= 3 ? (
          <>
            <Divider>{t(`missions.tags.PCS_3`)}</Divider>
            {filterInvoices('PCS_3', 'BALANCE').length &&
            pcs3BalanceInvoices.every((el) => el.status === 'PAID') &&
            mission.PCS_3.left_to_pay > 0 ? (
              <CreateInvoiceButton step="PCS_3" invoiceType="REGULE" />
            ) : null}
            <Col span={24} style={{ overflowX: 'auto' }}>
              <ListInvoices
                extraQuery={`&mission=${mission._id}&showMission=${true}${
                  invoices.filter((el) => el.pcs_step === 'PCS_3').length
                    ? ''
                    : `&noInvoiceCreated=${true}`
                }&pcs_step=PCS_3`}
                missionType="PCS_3"
              />
            </Col>
          </>
        ) : null}
      </Row>
    </Card>
  );
};

InvoiceCard.propTypes = {
  type: PropTypes.string
};

InvoiceCard.defaultProps = {
  type: ''
};
