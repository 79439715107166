import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, InputNumber } from 'antd';

/**
 * Renders a form for recording sale information.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.recordId - The ID of the record.
 * @returns {JSX.Element} - The rendered component.
 */
export const Sale = ({ recordId }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t('quotation-actions.form.give_lot_number')}
              name={['actions', recordId, 'data', 'give_lot_number']}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('quotation-actions.form.buyer')}
              name={['actions', recordId, 'data', 'buyer']}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('quotation-actions.form.seller')}
              name={['actions', recordId, 'data', 'seller']}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('quotation-actions.form.amount')}
              name={['actions', recordId, 'data', 'amount']}
              wrapperCol={{ span: 12 }}
            >
              <InputNumber addonAfter="€" />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Form.Item name={['actions', recordId, 'data', 'observation']}>
          <Input
            placeholder={t('placeholder.observation')}
            style={{ width: '350px' }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

Sale.propTypes = {
  recordId: PropTypes.string.isRequired
};
