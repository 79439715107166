import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useColumns } from '../columns/documentColumns';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { EditableTableMethods } from '../../../../../components/editableTableComponents/methods';
import { addColumns } from '../../../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../../../components/ContentCustom/ContentCustom';
import { EditableTable } from '../../../../../components/editableTableComponents/EditableTable';
import { useCollectiveOwnershipContext } from '../../../../../contexts/CollectiveOwnershipContext';

/**
 * Renders a list of documents for a mission.
 * @component
 * @param {Object} props - The component props.
 * @param {Boolean} props.search_by_document_ref - The document reference to search by.
 * @param {string} props.document_repository_key - The key of the document repository.
 * @returns {JSX.Element} The rendered ListDocuments component.
 */
export const ListDocuments = ({
  search_by_document_ref,
  document_repository_key
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const { getResourceData } = EditableTableMethods();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    setPagination,
    pagination,
    forceRefresh,
    setForceRefresh,
    setSelectedDocuments,
    itemType,
    currentSorter
  } = useCollectiveOwnershipContext();
  const noDraggerPermission = [
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER'
  ].includes(user.role);

  const { columns } = useColumns({
    setEditingKey,
    form,
    noDragger: noDraggerPermission,
    noDeleteButton: noDraggerPermission
  });

  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  const handleDataSource = async () => {
    const documentsDatas = await getResourceData({
      resource: `documents/collective-ownership/${id}`,
      setPagination,
      pagination,
      query: `&${
        search_by_document_ref
          ? 'config_document.document_type.document_key='
          : 'document_key='
      }${document_repository_key}${
        itemType?.type === 'documentType'
          ? `&config_document_ref=${itemType.value}`
          : ''
      }`,
      currentSorter
    });

    const updatedDocuments = documentsDatas.map(
      (collectiveOwnership, index) => ({
        ...collectiveOwnership,
        key: index
      })
    );
    setDataSource(updatedDocuments);
  };

  useEffect(() => {
    (async () => {
      await handleDataSource();
    })();
  }, [forceRefresh, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: columns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      resource: 'documents'
    });
  }, [editingKey, dataSource]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedDocuments(rowRecords);
    }
  };

  return (
    <ContentCustom>
      <EditableTable
        scroll={{ x: 'max-content' }}
        resource="documents"
        dataSource={dataSource}
        mergedColumns={mergedColumns}
        setSelectedRowKey={setSelectedRowKey}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        form={form}
        pagination={pagination}
        setPagination={setPagination}
        withoutAddLineButton
        withoutHeader
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
      />
    </ContentCustom>
  );
};

ListDocuments.propTypes = {
  search_by_document_ref: PropTypes.bool,
  document_repository_key: PropTypes.string.isRequired
};

ListDocuments.defaultProps = {
  search_by_document_ref: false
};
