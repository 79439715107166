export const syndicViewTitle = {
  fontWeight: 'bold',
  fontSize: '26px',
  margin: '0px'
};

export const syndicViewSubtitle = {
  fontSize: '20px'
};
export const homeSyndicViewTitle = {
  fontWeight: 'bold',
  fontSize: '26px',
  color: '#222222'
};

export const homeSyndicViewSubtitle = {
  fontSize: '20px',
  color: '#222222',
  margin: '0px'
};

export const rpcResearchTitle = {
  fontSize: '20px',
  color: '#6C757D',
  margin: '0px'
};

export const syndicContentCustomStyle = {
  borderRadius: '15px'
};
