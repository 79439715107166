import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Edits multiple rows in a charging grid.
 * @async
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.body - The request body containing information about the rows to be edited
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Object} params.chargingGrid - The charging grid object to be edited
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setIsEditLotModalOpen - Function for setting the edit lot modal open state
 * @param {Function} params.message - Function for displaying messages
 * @param {Array} params.selectedRowKeys - Array of selected row keys to be edited
 * @param {Function} params.setIsFinalVersion - Function for setting the final version state
 * @throws {Error} Throws an error if the API request fails
 * @returns {Promise<void>} - A promise that resolves after the rows are edited
 */

export const editMultipleRows = async ({
  body,
  dispatchAPI,
  chargingGrid,
  setChargingGrid,
  setIsEditLotModalOpen,
  message,
  selectedRowKeys,
  setIsFinalVersion,
  setSelectedRowKeys,
  resourceDatas,
  setResourceDatas,
  dataSource
}) => {
  try {
    let newBody = { ...body };

    if (body?.range?.range_start && body?.range?.range_end) {
      const start = body.range.range_start;
      const end = body.range.range_end;

      const selectedLots = dataSource.filter(
        (data) =>
          data.Lot.value !== 'Voulu' &&
          data.Lot.value !== 'Total' &&
          !data.delete_row &&
          data.key >= start &&
          data.key <= end
      );
      newBody = {
        ...body,
        lots: selectedLots.map((lot) => lot.key)
      };
    }

    if (selectedRowKeys.length) {
      newBody = { ...body, lots: selectedRowKeys };
    }

    const formData = new FormData();

    formData.append(
      'values',
      JSON.stringify({
        ...newBody
      })
    );

    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${chargingGrid._id}?type=edit_multiple_rows`,
      body: formData
    });

    updateChargingGrids({
      resourceDatas,
      chargingGrid: data,
      setResourceDatas
    });
    setChargingGrid(data);
    setIsEditLotModalOpen(false);
    setSelectedRowKeys([]);
  } catch (e) {
    message(e);
  }
  setIsFinalVersion(false);
};
