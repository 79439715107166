/**
 * Get users by role
 * @param {Object} props - props
 * @param {Function} props.dispatchAPI - The API dispatch function
 * @param {Function} props.setUsers - The users setter function
 * @param {String} props.role - The role string
 * @param {Function} props.message - The message function
 * @returns {Promise} - The API response
 */
export const getUsers = async ({ dispatchAPI, setUsers, role, message }) => {
  try {
    if (!role) {
      setUsers([]);
      return;
    }
    const { data } = await dispatchAPI('GET', {
      url: `/users?role=${role}`
    });
    setUsers(data);
  } catch (e) {
    message(e);
  }
};
