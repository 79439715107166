import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Flex, Row, Typography } from 'antd';
import { useDraggerField } from '../../../utils/DraggerField/useDraggerField';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem/generateFormItem';

const { Text } = Typography;
const lineHeight = { lineHeight: '3rem' };

/**
 * SubmitPvAg component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values for the form.
 * @param {Array} props.filesList - The list of files.
 * @param {Function} props.setFilesList - The function to set the list of files.
 * @param {Function} props.setFilesConfiguration - The function to set the files configuration.
 * @param {Object} props.filesConfiguration - The files configuration.
 * @param {string} props.purpose - The purpose of the component.
 * @param {Function} props.deleteFile - The function to delete a file.
 * @param {Function} props.setButtonsActive - The function to set the active buttons.
 * @returns {JSX.Element} The SubmitPvAg component.
 */
export const SubmitPvAg = ({
  form,
  initialValues,
  filesList,
  setFilesList,
  setFilesConfiguration,
  filesConfiguration,
  purpose,
  deleteFile,
  setButtonsActive
}) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const draggerField = useDraggerField({
    fileName: 'pv_ag',
    filesList,
    setFilesList,
    setFilesConfiguration,
    filesConfiguration,
    purpose,
    deleteFile,
    noLabelCentered: true
  });

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useEffect(() => {
    setButtonsActive((prev) => ({
      ...prev,
      daveDraft: true,
      next: true,
      back: false
    }));
  }, []);

  return (
    <Row align="center">
      <Flex vertical align="center">
        <Text style={lineHeight}>{t('missions.form.submit_pb_ag')}</Text>
        <Text style={lineHeight}>
          {t('pcs-pre-productions.form.more_info')}
        </Text>
      </Flex>
      <Col span={24}>
        {draggerField.map((field) =>
          generateFields('pcs-pre-productions', field)
        )}
      </Col>
    </Row>
  );
};

SubmitPvAg.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  filesList: PropTypes.shape({}).isRequired,
  setFilesList: PropTypes.func.isRequired,
  setFilesConfiguration: PropTypes.func.isRequired,
  filesConfiguration: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired,
  setButtonsActive: PropTypes.func.isRequired
};
