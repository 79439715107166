import PropTypes from 'prop-types';
import { Informations } from './Informations';
import { ManagersAndAssistants } from './ManagersAndAssistants';
import { CollectiveOwnerships } from './CollectiveOwnerships';
import { Publication } from './Publication';
import { Documents } from './Documents';

/**
 * The `useItems` hook is designed to aggregate various components related to a syndic entity into a structured array suitable for rendering in a tabbed interface or similar component. Each item in the array corresponds to a tab or section containing specific information or functionality related to the syndic.
 *
 * Parameters:
 * @param {Object} params - The parameters object.
 * @param {Object} [params.syndic={}] - An object containing detailed information about a syndic entity, which is passed down to child components.
 * @param {boolean} [params.isLoading=true] - A boolean flag indicating whether the syndic data is currently being loaded. This is used to control loading states in child components.
 * @param {function} params.t - A translation function provided by `react-i18next` for internationalization, used to translate tab labels and other UI text.
 * @param {Object} [params.enums={}] - An object containing enumeration values for dropdown fields and other selectable options within child components, used to populate filters and tag colors.
 * @param {function} params.setForceRefresh - A function to toggle a state that forces the re-fetching of data in child components to reflect updates.
 * @param {boolean} params.forceRefresh - A boolean state that, when toggled, triggers a re-fetching of data in child components.
 *
 * Returns:
 * An object containing a single property `items`, which is an array of objects where each object represents a tab or section. Each object includes:
 * - `label`: A string representing the title of the tab, translated using the provided `t` function.
 * - `key`: A unique identifier for the tab.
 * - `children`: The component to be rendered when the tab is selected, configured with the appropriate props.
 *
 * The hook organizes the tabs into 'Informations', 'Managers and Assistants', 'Collective Ownerships', 'Documents', and 'Publication', each containing relevant components like `Informations`, `ManagersAndAssistants`, `CollectiveOwnerships`, `Documents`, and `Publication` respectively.
 *
 * Usage:
 * This hook is intended for use in a parent component that renders a tabbed interface or a similar component for displaying detailed information about a syndic. It centralizes the configuration of tabs and their content, simplifying the parent component's structure and enhancing maintainability.
 */

export const useItems = ({
  syndic,
  isLoading,
  t,
  enums,
  setForceRefresh,
  forceRefresh,
  editPermissions
}) => {
  const items = [
    {
      label: t('syndics.show.tabs.informations'),
      key: '1',
      children: <Informations syndic={syndic} isLoading={isLoading} t={t} />
    },
    {
      label: t('syndics.show.tabs.managers_and_assistants'),
      key: '2',
      children: (
        <ManagersAndAssistants
          enums={enums}
          t={t}
          editPermissions={editPermissions}
        />
      )
    },
    {
      label: t('syndics.show.tabs.collective_ownerships'),
      key: '3',
      children: (
        <CollectiveOwnerships
          enums={enums}
          t={t}
          editPermissions={editPermissions}
        />
      )
    },
    {
      label: t('syndics.show.tabs.documents'),
      key: '4',
      children: (
        <Documents
          syndic={syndic}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          isLoading={isLoading}
          editPermissions={editPermissions}
        />
      )
    },
    {
      label: t('syndics.show.tabs.publication'),
      key: '5',
      children: <Publication syndic={syndic} />
    }
  ];

  return { items };
};

useItems.propTypes = {
  syndic: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired
};

useItems.defaultProps = {
  syndic: {},
  isLoading: true
};
