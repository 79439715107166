import { Route, Routes } from 'react-router-dom';
import { ListOrders } from './ListOrders';
import { Exception } from '../../components';
import { ShowOrder } from './ShowOrder/ShowOrder';

/**
 * Router component for managing order-related routes.
 * @component
 * @returns {JSX.Element} The rendered routes for the orders.
 */
export const OrderRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowOrder />} />
    <Route index element={<ListOrders />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
