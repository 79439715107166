/**
 * Sets dynamic fields for the mission form.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {Function} options.setSyndicOptions - The function to set syndic options.
 * @param {Array} options.syndics - The array of syndics.
 * @param {Object} options.syndic - The syndic object.
 * @param {Function} options.setAssistants - The function to set assistants.
 * @param {Function} options.setManagers - The function to set managers.
 * @returns {void}
 */
export const setDynamicFields = ({
  setSyndicOptions,
  syndics,
  syndic,
  setAssistants,
  setManagers
}) => {
  setSyndicOptions(syndics);

  if (syndic && syndic.assistants) {
    setAssistants(syndic.assistants);
  }

  if (syndic && syndic.managers) {
    setManagers(syndic.managers);
  }
};
