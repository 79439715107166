import { Col, Image, Row } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { VerifyCodeComponent } from '../../../utils/VeritifcationCode/VerifyCodeComponent';

/**
 * SignPublicationDevis component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial values object.
 * @param {Function} props.setInitialValues - The function to set the initial values.
 * @param {Function} props.setButtonsActive - The function to set the active buttons.
 * @returns {JSX.Element} The SignPublicationDevis component.
 */
export const SignPublicationDevis = ({
  form,
  initialValues,
  setInitialValues,
  setButtonsActive
}) => {
  const [isCodeValidated, setIsCodeValidated] = useState(false);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const setValidCodes = () => {
    if (isCodeValidated) {
      setButtonsActive((prev) => ({
        ...prev,
        next: true,
        back: false
      }));
    } else {
      setButtonsActive((prev) => ({
        ...prev,
        next: false,
        back: true,
        saveDraft: true
      }));
    }
  };

  useEffect(() => {
    setValidCodes();
  }, [isCodeValidated, initialValues]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={7}>
        <Image src="https://assets-us-01.kc-usercontent.com/fa776f1a-4d27-4a6b-ae1c-2ce928f9647d/0b068045-9b65-4ddf-aec1-b2a917fc5869/contract%20cropped.png" />
      </Col>
      <Col span={17}>
        <VerifyCodeComponent
          setInitialValues={setInitialValues}
          initialValues={initialValues}
          codeToValidate="publication"
          setIsValidated={setIsCodeValidated}
          documentToSee="devis"
        />
      </Col>
    </Row>
  );
};

SignPublicationDevis.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  setInitialValues: PropTypes.func.isRequired,
  setButtonsActive: PropTypes.func.isRequired
};
