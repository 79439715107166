/**
 * Retrieves the charging grid data.
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {Object} params.resourceDatas - The resource object
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setIsVersionButton - Function for setting version button state
 * @returns {void}
 */

export const getChargingGrid = ({
  chargingGrid,
  resourceDatas,
  setChargingGrid,
  setIsVersionButton
}) => {
  if (chargingGrid === undefined) {
    if (resourceDatas.charging_grid) {
      const latestChargingGrid = resourceDatas.charging_grid.reduce(
        (latest, current) => {
          if (!latest || current.version_date > latest.version_date) {
            return current;
          }
          return latest;
        },
        null
      );
      if (latestChargingGrid) {
        setChargingGrid(latestChargingGrid);

        const versions = resourceDatas.charging_grid.map(
          (item) => item.version
        );

        const maxVersionInArray = Math.max(...versions);

        if (maxVersionInArray > latestChargingGrid.version) {
          setIsVersionButton(false);
        } else {
          setIsVersionButton(true);
        }
      }
    }
  } else {
    setChargingGrid(chargingGrid);

    const versions = resourceDatas.charging_grid.map((item) => item.version);

    const maxVersionInArray = Math.max(...versions);

    if (maxVersionInArray > chargingGrid.version) {
      setIsVersionButton(false);
    } else {
      setIsVersionButton(true);
    }
  }
};
