import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Tag, Divider, Button, Tooltip } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { Copy } from '@carbon/icons-react';
import dayjs from 'dayjs';
import { quotationTag } from '../../utils/constants/tagColors';
import { Quotation } from '../../models/Quotation';
import { useQuotationContext } from '../../contexts/QuotationContext';

export const useColumns = ({ enums }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setForceRefresh } = useQuotationContext();

  return [
    {
      title: t('quotations.form.reference'),
      key: 'reference',
      dataIndex: 'reference',
      render: (reference) => reference || '-',
      sorter: true
    },
    {
      title: t('quotations.form.syndic'),
      key: 'syndic',
      dataIndex: 'syndic',
      render: (syndic) =>
        `${syndic?.society_name || ''} ${
          syndic?.code ? `(${syndic.code})` : ''
        }`,
      sorter: true
    },
    {
      title: t('quotations.form.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: 'collective_ownership',
      render: (collective_ownership) =>
        `${collective_ownership?.code || ''} - ${
          collective_ownership?.first_address?.number || ''
        } ${collective_ownership?.first_address?.street || ''} ${
          collective_ownership?.first_address?.postal_code || ''
        } ${collective_ownership?.first_address?.city || ''} ${
          collective_ownership?.cadastral_reference
            ? `(${collective_ownership?.cadastral_reference})`
            : ''
        }`,
      sorter: true
    },
    {
      title: t('quotations.form.mission_type'),
      key: 'mission_type',
      dataIndex: 'mission_type',
      render: (mission_type) =>
        mission_type ? t(`quotations.tags.${mission_type}`) : '-'
    },
    {
      title: t('quotations.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={quotationTag[status]}>
          {t(`quotations.steps.${status}`, {
            defaultValue: status
          })}
        </Tag>
      ),
      sorter: true,
      filters: enums?.status?.map((r) => ({
        text: t(`users.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('quotations.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => (date ? dayjs(date).format('DD-MM-YYYY') : '-'),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/quotations/edit/${record?._id}`
            }}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>

          <Divider type="vertical" />
          <Tooltip title={t('quotations.tooltips.validate_quotation')}>
            <Button
              type="link"
              onClick={() => {
                Quotation.patchResource({
                  id: record?._id,
                  values: { status: 'VALIDATED' },
                  setForceRefresh
                });
              }}
            >
              <CheckOutlined style={{ color: 'green' }} />
            </Button>
          </Tooltip>

          <Divider type="vertical" />
          <Tooltip title={t('quotations.tooltips.duplicate_quotation')}>
            <Button
              type="link"
              onClick={() => {
                Quotation.duplicateResource({ id: record?._id, navigate, t });
              }}
            >
              <Copy />
            </Button>
          </Tooltip>
        </>
      )
    }
  ];
};
