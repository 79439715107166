import { BaseModel } from './BaseModel';

/**
 * Represents an archivist notary.
 * @class
 */
/**
 * Represents an Archivist Notary.
 */
export class ArchivistNotary {
  static resourceName = 'archivist-notaries';

  /**
   * Get the resources.
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources(populate = '', extraQuery = '') {
    const query = `${populate ? `populate=${populate}` : ''}${
      populate && extraQuery ? '&' : ''
    }${extraQuery}`;
    const { data } = await BaseModel.dispatchAPI('GET', {
      url: `${ArchivistNotary.resourceName}?${query}`
    });
    return data;
  }

  /**
   * Get a specific resource by ID.
   * @param {string} id - The ID of the resource.
   * @param {string} [populate=''] - The fields to populate.
   * @returns {Promise<Object>} The resource.
   * @static
   */
  static async getResource(id, populate = '') {
    const { data } = await BaseModel.dispatchAPI('GET', {
      url: `${ArchivistNotary.resourceName}/${id}?populate=${populate}`
    });
    return data;
  }

  /**
   * Get an Archivist Notary by Editor Notary ID.
   * @param {string} editorNotaryId - The ID of the Editor Notary.
   * @returns {Promise<Object>} The Archivist Notary.
   * @static
   */
  static async getArchivistNotaryFromEditorNotary(editorNotaryId) {
    const { data } = await BaseModel.dispatchAPI('GET', {
      url: `${ArchivistNotary.resourceName}/editor-notary/${editorNotaryId}`
    });
    return data;
  }
}
