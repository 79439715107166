import { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Upload, Col, Card, List } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { uploadDocument } from '../../utils/uploadDocument';
import { DocumentsListItem } from './DocumentsUtils/DocumentsListItem';

const { Dragger } = Upload;

/**
 * The `Documents` component is designed to manage documents related to a syndic entity, specifically handling the upload, viewing, downloading, and deletion of documents such as KBIS and status certificates. It provides an interface for users to interact with the document management functionalities.
 *
 * Props:
 * @prop {Object} syndic - An object representing the syndic entity, including an `_id` and a `documents` array that contains document objects with file details.
 * @prop {function} setForceRefresh - A function to toggle a state that forces the re-fetching of documents to reflect updates.
 * @prop {boolean} [forceRefresh=false] - A boolean state that, when toggled, triggers a re-fetching of documents.
 * @prop {boolean} [isLoading=false] - A boolean flag indicating whether the documents are currently being loaded. This is used to control loading states in the UI.
 * @returns {JSX.Element} A React component that displays a document management interface with sections for uploading and managing documents.
 * The component uses React's `useState` hook to manage the state of the list of files prepared for upload. The `useTranslation` hook is utilized for internationalization, rendering UI text in the user's preferred language. It integrates with the `useAuthContext` for authorization context and `useErrorMessage` for handling error messages.
 *
 * The document management functionalities are supported by utility functions including `useDownloadDocument` for viewing and downloading documents, `deleteDocument` for document deletion, and `uploadDocument` for uploading new documents.
 *
 * The UI is divided into two main sections, each containing an `Upload.Dragger` component for file upload and a `List` component for displaying existing documents. The upload and list sections are differentiated by document types (KBIS and status certificates). Actions available for each document include viewing, downloading, and deleting.
 *
 * Usage:
 * This component is used within the administrative interface of an application that manages syndic entities and their associated documents. It provides a comprehensive solution for document management, including uploading new documents and managing existing ones.
 */
export const Documents = ({
  syndic,
  setForceRefresh,
  forceRefresh,
  isLoading,
  editPermissions
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [filesList, setFilesList] = useState([]);

  const draggerProps = (type) => ({
    onRemove: () => {
      setFilesList(null);
    },
    beforeUpload: (file) => {
      setFilesList(file);

      return false;
    },
    onChange: async () => {
      await uploadDocument({
        filesList,
        dispatchAPI,
        syndic,
        setForceRefresh,
        message,
        type
      });
    },
    fileList: []
  });

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title={t('syndics.show.cards.titles.kbis')}>
              <Col span={24}>
                {editPermissions && (
                  <Dragger {...draggerProps('KBIS')}>
                    <p className="ant-upload-drag-icon">
                      <FolderOpenOutlined
                        style={{ color: 'var(--textColor)' }}
                      />
                    </p>
                    <p className="ant-upload-text">
                      {t('files.create.action')}
                    </p>
                  </Dragger>
                )}
              </Col>
              <Col span={24}>
                <List
                  loading={isLoading}
                  dataSource={
                    syndic.documents?.find((el) => el.sub_type === 'KBIS')
                      ?.files || []
                  }
                  renderItem={(item) => (
                    <DocumentsListItem
                      item={item}
                      syndic={syndic}
                      setForceRefresh={setForceRefresh}
                      forceRefresh={forceRefresh}
                      editPermissions={editPermissions}
                    />
                  )}
                />
              </Col>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title={t('syndics.show.cards.titles.status')}>
              <Col span={24}>
                {editPermissions && (
                  <Dragger {...draggerProps('STATUS')}>
                    <p className="ant-upload-drag-icon">
                      <FolderOpenOutlined
                        style={{ color: 'var(--textColor)' }}
                      />
                    </p>
                    <p className="ant-upload-text">
                      {t('files.create.action')}
                    </p>
                  </Dragger>
                )}
              </Col>
              <Col span={24}>
                <List
                  loading={isLoading}
                  dataSource={
                    syndic.documents.find((el) => el.sub_type === 'STATUS')
                      ?.files || []
                  }
                  renderItem={(item) => (
                    <DocumentsListItem
                      item={item}
                      syndic={syndic}
                      setForceRefresh={setForceRefresh}
                      forceRefresh={forceRefresh}
                    />
                  )}
                />
              </Col>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Documents.propTypes = {
  syndic: PropTypes.shape({
    _id: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.shape({
          _id: PropTypes.string,
          metadata: PropTypes.shape({
            originalName: PropTypes.string
          }),
          contentType: PropTypes.string
        })
      })
    )
  }),
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  isLoading: PropTypes.bool,
  editPermissions: PropTypes.bool
};

Documents.defaultProps = {
  syndic: {},
  forceRefresh: false,
  isLoading: false,
  editPermissions: true
};
