import { Button, Col, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../contexts/GlobalContext';
import { useAuthContext } from '../contexts/AuthContext';

/**
 * Represents a modal component that displays a loading state and provides a refresh button.
 * @component
 * @returns {JSX.Element} The rendered LoadingAndRefreshModal component.
 */
export const LoadingAndRefreshModal = () => {
  const { currentSyndic, setRefreshSyndic } = useGlobalContext();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  return (
    <Modal
      open
      centered
      footer={null}
      closable={false}
      bodyStyle={{
        textAlign: 'center',
        padding: '40px 20px',
        borderRadius: '8px'
      }}
    >
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col>
          {currentSyndic === null ? (
            <>
              <p>{t('global.loading')}</p>
              <Spin size="large" />
            </>
          ) : (
            <>
              <p style={{ marginBottom: '20px' }}>
                {t('global.syndic_not_found')}
              </p>
              <Button
                size="large"
                onClick={() => setRefreshSyndic((e) => !e)}
                style={{
                  backgroundColor: '#222222',
                  borderRadius: '15px',
                  color: '#ffffff',
                  width: '100%'
                }}
              >
                {t('global.reload_button')}
              </Button>
              <Button
                size="large"
                onClick={() => dispatchAPI('LOGOUT')}
                style={{
                  backgroundColor: '#222222',
                  borderRadius: '15px',
                  color: '#ffffff',
                  width: '100%'
                }}
              >
                {t('header.menu.user.logout')}
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};
