import { Button, Flex } from 'antd';
import { LeftOutlined, RightOutlined, CheckOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

export const CustomActionButtons = ({
  onFinish,
  onSubmit,
  setCurrent,
  current,
  nextButton,
  previousButton
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex justify="flex-end" gap="small" style={{ marginTop: '15px' }}>
      <Button type="link" danger onClick={() => navigate(-1)}>
        {t('buttons.cancel')}
      </Button>
      <Button type="primary" onClick={onSubmit}>
        <CheckOutlined />
        {t('buttons.register')}
      </Button>
      {previousButton ? (
        <Button type="primary" onClick={() => setCurrent(current - 1)}>
          <LeftOutlined />
          {t('buttons.previous')}
        </Button>
      ) : null}
      {nextButton ? (
        <Button type="primary" onClick={onFinish}>
          {t('buttons.next')}
          <RightOutlined />
        </Button>
      ) : null}
    </Flex>
  );
};

CustomActionButtons.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  setCurrent: PropTypes.func,
  current: PropTypes.number,
  nextButton: PropTypes.bool,
  previousButton: PropTypes.bool
};

CustomActionButtons.defaultProps = {
  onSubmit: () => {},
  setCurrent: () => {},
  current: 0,
  nextButton: false,
  previousButton: false
};
