import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useColumns } from './columns';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { EditableTableMethods } from '../../../../components/editableTableComponents/methods';
import { addColumns } from '../../../../components/editableTableComponents/addColumns';
import { EditableTable } from '../../../../components/editableTableComponents/EditableTable';
import { useConfigurationContext } from '../../../../contexts/ConfigurationContext';

/**
 * Renders a list of quote configurations.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of quote configuration.
 * @returns {JSX.Element} The rendered component.
 */
export const ListQuoteConfigurations = ({ type }) => {
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectType, setSelectType] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const { getResourceData } = EditableTableMethods();
  const { searchValue, setPagination, pagination, currentSorter } =
    useConfigurationContext();
  const columns = useColumns({
    setEditingKey,
    form,
    setSelectType,
    setForceRefresh,
    forceRefresh
  });
  const [mergedColumns, setMergedColumns] = useState(columns[type]);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  useEffect(() => {
    (async () => {
      const [quoteConfigurationDatas] = await Promise.all([
        getResourceData({
          resource: 'quote-configurations',
          searchValue,
          setPagination,
          pagination,
          query: `&type=${type}`,
          currentSorter
        })
      ]);

      setDataSource(
        quoteConfigurationDatas.map((el, index) => ({ ...el, key: index }))
      );
    })();
  }, [forceRefresh, searchValue, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: columns[type],
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      selectType,
      resource: 'quote-configurations'
    });
  }, [editingKey, dataSource]);

  return (
    <EditableTable
      resource="quote-configurations"
      dataSource={dataSource}
      mergedColumns={mergedColumns}
      setSelectedRowKey={setSelectedRowKey}
      setForceRefresh={setForceRefresh}
      forceRefresh={forceRefresh}
      form={form}
      type={type}
      searchValue={searchValue}
      pagination={pagination}
      setPagination={setPagination}
      scroll={{ x: 'max-content' }}
    />
  );
};

ListQuoteConfigurations.propTypes = {
  type: PropTypes.string.isRequired
};
