import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { RenderNotaryDisbursementDatas } from '../utils/RenderNotaryDisbursementDatas';
import { DisbursementMethods } from '../utils/disbursementMethods';

/**
 * Custom hook that returns an array of columns for the disbursement and notary costs table.
 *
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Array} options.disbursementAndNotaryCostsDatas - The data for the disbursement and varied costs table.
 * @param {Function} options.setDisbursementAndNotaryCostsDatas - The function to update the data for the disbursement and varied costs table.
 * @returns {Array} - An array of columns for the disbursement and varied costs table.
 */
export const useDisbursementAndNotaryCostsColumns = ({
  setNotaryDisbursements,
  setFinalAmounts,
  form
}) => {
  const { t } = useTranslation();
  const { deleteLineFromDisbursementTable } = DisbursementMethods();

  return [
    {
      title: t('missions.form.document'),
      key: 'document',
      dataIndex: 'document',
      render: (_, record) => (
        <RenderNotaryDisbursementDatas
          dataIndex="document"
          record={record}
          inputType="input"
          setFinalAmounts={setFinalAmounts}
          form={form}
        />
      )
    },
    {
      title: t('missions.form.costs'),
      key: 'costs',
      dataIndex: 'costs',
      render: (_, record) => (
        <RenderNotaryDisbursementDatas
          dataIndex="costs"
          record={record}
          inputType="inputNumber"
          setFinalAmounts={setFinalAmounts}
          form={form}
        />
      )
    },
    {
      title: t('missions.form.disbursement'),
      key: 'disbursement',
      dataIndex: 'disbursement',
      render: (_, record) => (
        <RenderNotaryDisbursementDatas
          dataIndex="disbursement"
          record={record}
          inputType="inputNumber"
          setFinalAmounts={setFinalAmounts}
          form={form}
        />
      )
    },
    {
      title: t('missions.form.cheque_number'),
      key: 'cheque_number',
      dataIndex: 'cheque_number',
      render: (_, record) => (
        <RenderNotaryDisbursementDatas
          dataIndex="cheque_number"
          record={record}
          inputType="input"
          setFinalAmounts={setFinalAmounts}
          form={form}
        />
      )
    },
    {
      title: t('missions.form.recipient'),
      key: 'recipient',
      dataIndex: 'recipient',
      render: (_, record) => (
        <RenderNotaryDisbursementDatas
          dataIndex="recipient"
          record={record}
          inputType="input"
          setFinalAmounts={setFinalAmounts}
          form={form}
        />
      )
    },
    {
      title: t('missions.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (_, record) => (
        <RenderNotaryDisbursementDatas
          dataIndex="date"
          record={record}
          inputType="date"
          setFinalAmounts={setFinalAmounts}
          form={form}
        />
      )
    },
    {
      title: t('missions.form.to_bill'),
      key: 'to_bill',
      dataIndex: 'to_bill',
      render: (_, record) => (
        <RenderNotaryDisbursementDatas
          dataIndex="to_bill"
          record={record}
          inputType="input"
          setFinalAmounts={setFinalAmounts}
          form={form}
        />
      )
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) =>
        record.default ? null : (
          <Button
            type="link"
            onClick={() =>
              deleteLineFromDisbursementTable({
                record,
                setNotaryDisbursements,
                type: 'notary',
                form
              })
            }
          >
            <DeleteOutlined
              style={{ color: 'var(--errorColor)', fontSize: 18 }}
              type="delete"
            />
          </Button>
        )
    }
  ];
};
