import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../components';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { Quotation } from '../../models/Quotation';
import { quotationSteps } from './steps/quotationSteps';
import { useQuotationContext } from '../../contexts/QuotationContext';
import { useForms } from './useForms';
import { useConfig } from './utils/createUpdateConfig';
import { CustomActionButtons } from './buttons/CustomActionButtons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateUpdateExtraButtons } from './buttons/CreateUpdateExtraButtons';

export const CreateUpdateQuotations = ({ purpose }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { forms } = useForms({ purpose });
  const [disabledStep, setDisabledStep] = useState(false);
  const {
    getQuotationDatas,
    forceRefresh,
    setForceRefresh,
    current,
    setCurrent,
    setFormData,
    form,
    isLoading,
    setMissionType,
    missionType,
    setFilesList
  } = useQuotationContext();
  const { principalItems, subItems } = quotationSteps({ disabledStep });

  const items = {
    principal: principalItems,
    sub: subItems
  };

  const formLayout = [
    'horizontal',
    'horizontal',
    'horizontal',
    'vertical',
    'horizontal'
  ];

  useEffect(() => {
    (async () => {
      if (purpose === 'create') setCurrent({ principal: 0, sub: 0 });
      await getQuotationDatas({ id });
      if (state?.launch_pcs3) {
        setDisabledStep(true);
        setCurrent((prev) => ({ ...prev, sub: 5 }));
      }
    })();
  }, [forceRefresh, state?.force_refresh, state?.mission]);

  const config = useConfig({
    setMissionType,
    setFilesList,
    dispatchAPI,
    message
  });

  return (
    <>
      <PageHeaderCustom
        title={t('quotations.create_update_title', {
          type: t(`quotations.tags.${missionType || 'DEFAULT'}`)
        })}
        extra={<CreateUpdateExtraButtons purpose={purpose} />}
      />
      <ContentCustom isLoading={isLoading}>
        <Steps
          className="main-steps"
          current={current.principal}
          items={items.principal}
          style={{ marginBottom: 24 }}
          size="default"
          onChange={(e) =>
            Quotation.updateQuotationStatus({
              form,
              element: e,
              id,
              setCurrent,
              setForceRefresh,
              stepType: 'principal',
              setFormData,
              t
            })
          }
        />
        <Steps
          current={current.sub}
          items={items.sub}
          style={{ marginBottom: 24 }}
          size="small"
          onChange={(e) =>
            Quotation.updateQuotationStatus({
              form,
              element: e,
              id,
              setCurrent,
              setForceRefresh,
              stepType: 'sub',
              setFormData,
              t
            })
          }
        />
        <CreateUpdateContainer
          customFormInstance={form}
          formExtra={forms[current.sub]}
          isFieldsLoading={false}
          purpose={purpose}
          baseUrl="quotations"
          resource="quotations"
          populate="mission,actions,acts,act_amounts,documents,documents.files"
          layout={formLayout[current.sub]}
          config={config}
          withFilesManager={false}
          withEnums={false}
          withCustomButtons
          withCustomPageHeader
          customLabelCol={{}}
          forceRefresh={forceRefresh}
          customActionButtons={<CustomActionButtons purpose={purpose} />}
        />
      </ContentCustom>
    </>
  );
};

CreateUpdateQuotations.propTypes = {
  purpose: PropTypes.string.isRequired
};
