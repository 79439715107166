import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Steps,
  Row,
  Col,
  notification,
  Skeleton,
  Dropdown,
  Button
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PageHeaderCustom } from '../../../../components/PageHeader/PageHeader';
import { ContentCustom } from '../../../../components';
import { Messages } from '../../../../components/Messages/Messages';
import { MessageContextProvider } from '../../../../contexts/MessageContext';
import { pcsSteps } from './steps/pcsSteps';
import { showTitle } from './utils/showTitle';
import { AlertCard } from './components/AlertCard';
import { DocumentCard } from './components/DocumentCard';
import { AddDocumentCard } from './components/AddDocumentCard';
import { InformationsCard } from './components/InformationsCard';
import { InvoiceCard } from './components/InvoiceCard';
import { OrdersCard } from './components/OrdersCard';
import { useMissionContext } from '../../../../contexts/MissionContext';
import { CerfaOrderDrawer } from './drawers/CerfaOrderDrawer';
import { LawyerConsultationDrawer } from './drawers/LawyerConsultationDrawer';
import { LawyerConsultationCard } from './components/LawyerConsultationCard';
import { AgDecisionDrawer } from './drawers/AgDecisionDrawer';
import { PcsExtraButtons } from './buttons/PcsExtraButtons';
import { StepOneEndButtons } from './buttons/StepOneEndButtons';
import { BlockMissionDrawer } from './drawers/BlockMissionDrawer';
import { DisbursementModal } from './modals/DisbursementModal';
import { CollapseMissionHistory } from '../../../../components/History/CollapseHistoryMission';
import { Mission } from '../../../../models/Mission';
import { PcajpArraysModal } from './modals/PcajpArraysModal';
import { DocumentToBeReWrittenDrawer } from './drawers/DocumentsToBeReWrittenDrawer';
import { IntermediateProjectDrawer } from './drawers/IntermediateProjectDrawer';
import { DefinitiveProjectDrawer } from './drawers/DefinitiveProjectDrawer';
import { FinalizationCard } from './components/FinalizationCard';
import { CollectiveOwnershipFormsCard } from './components/CollectiveOwnershipFormsCard/CollectiveOwnershipFormsCard';
import { CollectiveOwnershipFormDrawer } from './drawers/CollectiveOwnershipFormDrawer';
import { RequestedDocumentsToSyndicDrawer } from './drawers/RequestedDocumentsToSyndicDrawer';
import { MatrixFinalizationDrawer } from './drawers/MatrixFinalizationDrawer';
import { NotaryTransmissionDrawer } from './drawers/NotaryTransmissionDrawer';
import { AlertDrawer } from './drawers/AlertDrawer';
import { useMenu } from './utils/plansMenu';
import { NoteDrawer } from './drawers/NoteDrawer';
import { BlockMissionCreditNote } from './modals/BlockMissionCreditNoteModal';
import { CancelMissionModal } from './modals/CancelMissionModal';
import { ConfirmRefundsModal } from './modals/ConfirmRefundsModal';

/**
 * Renders the ShowPcsMission component.
 *
 * @component
 * @returns {JSX.Element} The rendered ShowPcsMission component.
 */
export const ShowPcsMission = () => {
  const { id, type } = useParams();
  const { t } = useTranslation();
  const { principalItems, pcs1SubItems, pcs2SubItems, pcs3SubItems } =
    pcsSteps();
  const { state } = useLocation();

  const {
    getMissionDatas,
    current,
    mission,
    forceRefresh,
    addDocumentCardDatas,
    isOrderCardUp,
    isShowToBeOrderedCard,
    isDrawerOpen,
    isLoading,
    setMission,
    setCurrent,
    setForceRefresh,
    documents,
    invoices
  } = useMissionContext();

  const items = {
    PCS_1: { principal: principalItems, sub: pcs1SubItems },
    PCS_2: { principal: principalItems, sub: pcs2SubItems },
    PCS_3: { principal: principalItems, sub: pcs3SubItems }
  };

  useEffect(() => {
    (async () => {
      await getMissionDatas({
        id,
        type,
        message: notification,
        t
      });
    })();
  }, [forceRefresh, state?.refresh]);

  const menu = {
    items: useMenu({ mission })
  };

  return (
    <>
      <PageHeaderCustom
        title={showTitle({ record: mission })}
        extra={
          <Row>
            <Col>
              <PcsExtraButtons />
            </Col>
            <Col>
              <Dropdown menu={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        }
      />
      <ContentCustom>
        <Steps
          className="main-steps"
          current={current.principal}
          items={items[type].principal}
          style={{ marginBottom: 24 }}
        />
        <Row gutter={[16, 16]}>
          {items[type].sub.length ? (
            <Col span={24}>
              <Steps
                current={current.sub}
                items={items[type].sub}
                style={{ marginBottom: 24 }}
                onChange={(e) =>
                  Mission.updatePcsStatus({
                    element: e,
                    id,
                    setCurrent,
                    setMission,
                    setForceRefresh,
                    stepType: 'sub',
                    documents,
                    notification,
                    mission,
                    invoices
                  })
                }
              />
            </Col>
          ) : null}
          <Skeleton loading={isLoading} active>
            <AlertCard />
          </Skeleton>
          <Col
            span={
              addDocumentCardDatas.open || isDrawerOpen.finalization ? 14 : 24
            }
          >
            <Row gutter={[16, 16]}>
              <Skeleton loading={isLoading} active>
                {isShowToBeOrderedCard ? (
                  <Col span={24}>
                    <OrdersCard status="TO_BE_ORDERED" />
                  </Col>
                ) : null}
                {isOrderCardUp && current.sub >= 1 ? (
                  <Col span={24}>
                    <OrdersCard status="ORDERED" />
                  </Col>
                ) : null}
              </Skeleton>
              <Skeleton loading={isLoading} active>
                <Col span={24}>
                  <DocumentCard />
                </Col>
              </Skeleton>
              <Skeleton loading={isLoading} active>
                {Object.keys(mission).length ? (
                  <Col span={24}>
                    <InvoiceCard type={mission.type} />
                  </Col>
                ) : null}
              </Skeleton>
              <Skeleton loading={isLoading} active>
                {current.sub > 1 && !isOrderCardUp ? (
                  <Col span={24}>
                    <OrdersCard status="ORDERED" />
                  </Col>
                ) : null}
              </Skeleton>

              <Col span={16}>
                <Row gutter={[16, 16]}>
                  <Skeleton loading={isLoading} active>
                    <Col span={24}>
                      <CollapseMissionHistory
                        isLoading={isLoading}
                        historic={mission.history}
                      />
                    </Col>
                  </Skeleton>

                  <Skeleton loading={isLoading} active>
                    {mission?.lawyer_step === 'FINISHED' &&
                    mission?.type === 'PCS_1' ? (
                      <Col span={24}>
                        <StepOneEndButtons />
                      </Col>
                    ) : null}
                  </Skeleton>

                  <Skeleton loading={isLoading} active>
                    {['AG_DECISION', 'LAWYER_SEIZURE'].includes(
                      mission?.lawyer_step
                    ) || mission.type === 'PCS_2' ? (
                      <Col span={24}>
                        <LawyerConsultationCard />
                      </Col>
                    ) : null}
                  </Skeleton>

                  <Skeleton loading={isLoading} active>
                    <Col span={24}>
                      <CollectiveOwnershipFormsCard />
                    </Col>
                  </Skeleton>
                </Row>
              </Col>

              <Col span={8}>
                <Row gutter={[16, 16]}>
                  <Skeleton loading={isLoading} active>
                    <Col span={24}>
                      <MessageContextProvider id={id} model="Mission">
                        <Messages
                          missionType="PCS_1"
                          syndic={mission?.syndic}
                          collective_ownership={mission?.collective_ownership}
                          withCreateButton
                        />
                      </MessageContextProvider>
                    </Col>
                  </Skeleton>
                  <Skeleton loading={isLoading} active>
                    <Col span={24}>
                      {mission?.pcs_pre_production ? (
                        <MessageContextProvider
                          id={mission?.pcs_pre_production}
                          model="Pcs-pre-production"
                          showAllMessages
                        >
                          <Messages
                            cardTitle={t(
                              'missions.cards.titles.pcs_pre_production_messages'
                            )}
                            withoutDefaultActiveKey
                            withCreateButton={false}
                          />
                        </MessageContextProvider>
                      ) : null}
                    </Col>
                  </Skeleton>
                  <Skeleton loading={isLoading} active>
                    <Col span={24}>
                      <InformationsCard />
                    </Col>
                  </Skeleton>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            className="add-cerfa-docs-card"
            span={10}
            style={{
              display: addDocumentCardDatas.open ? 'block' : 'none'
            }}
          >
            <AddDocumentCard />
          </Col>
          {isDrawerOpen?.finalization ? (
            <Col span={10} className="add-cerfa-docs-card">
              <FinalizationCard />
            </Col>
          ) : null}
        </Row>
      </ContentCustom>
      {isDrawerOpen?.cerfa_order.open ? <CerfaOrderDrawer /> : null}
      {isDrawerOpen.lawyer_consultation.open ? (
        <LawyerConsultationDrawer />
      ) : null}
      {isDrawerOpen.ag_decision ? <AgDecisionDrawer /> : null}
      {isDrawerOpen.block_mission ? <BlockMissionDrawer /> : null}
      {isDrawerOpen.disbursement ? <DisbursementModal /> : null}
      {isDrawerOpen.pcajp_array ? <PcajpArraysModal /> : null}
      {isDrawerOpen.documents_to_be_re_written ? (
        <DocumentToBeReWrittenDrawer />
      ) : null}
      {isDrawerOpen?.intermediate_project ? (
        <IntermediateProjectDrawer />
      ) : null}
      {isDrawerOpen?.definitive_project ? <DefinitiveProjectDrawer /> : null}
      {isDrawerOpen?.collective_ownership_form ? (
        <CollectiveOwnershipFormDrawer />
      ) : null}
      {isDrawerOpen?.requested_documents_to_syndic ? (
        <RequestedDocumentsToSyndicDrawer />
      ) : null}
      {isDrawerOpen?.matrix_finalization ? <MatrixFinalizationDrawer /> : null}
      {isDrawerOpen?.notary_transmission ? <NotaryTransmissionDrawer /> : null}
      {isDrawerOpen?.alert ? <AlertDrawer /> : null}
      {isDrawerOpen.note ? <NoteDrawer /> : null}
      {isDrawerOpen.block_mission_credit_note ? (
        <BlockMissionCreditNote />
      ) : null}
      {isDrawerOpen.cancel_mission ? <CancelMissionModal /> : null}
      {isDrawerOpen.confirm_refunds ? <ConfirmRefundsModal /> : null}
    </>
  );
};
