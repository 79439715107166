import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Popconfirm, Row, Col, message as antdMessage } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { deleteResource } from '../../../utils/deleteResource';
import { routes } from '../../../utils/constants/routes/adminRoutes';

export const ShowButtons = ({ permissions }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { editButton, deleteButton } = permissions;

  return (
    <Row gutter={[10]}>
      <Col>
        <Button
          type="primary"
          onClick={() =>
            antdMessage.warning('Fonctionnalité pas encore disponible')
          }
        >
          {t('syndics.buttons.mission_monitoring_export')}
        </Button>
      </Col>
      <Col>
        {editButton && (
          <Link to={{ pathname: `${routes.SYNDICS}/edit/${id}` }}>
            <Button type="primary">
              {`${t('buttons.edit')} `}
              <EditOutlined />
            </Button>
          </Link>
        )}
      </Col>
      <Col>
        {deleteButton && (
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() =>
              deleteResource({
                dispatchAPI,
                resourceName: 'syndics',
                id,
                message,
                navigate
              })
            }
            icon={<WarningOutlined />}
          >
            <Button type="link" danger>
              {`${t('buttons.delete')} `}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )}
      </Col>
    </Row>
  );
};

ShowButtons.propTypes = {
  permissions: PropTypes.shape({
    editButton: PropTypes.bool,
    deleteButton: PropTypes.bool
  })
};

ShowButtons.defaultProps = {
  permissions: {
    editButton: false,
    deleteButton: false
  }
};
