import { Form, Select, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { handleDeleteFormSubmit } from '../utils/handleDeleteFormSubmit';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Option } = Select;

/**
 * DeleteForm component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.selectedSyndic - The selected syndic.
 * @param {function} props.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - The force refresh flag.
 * @param {function} props.setIsDeleteModalOpen - The function to set the delete modal open flag.
 * @param {Array} props.syndics - The list of syndics.
 * @returns {JSX.Element} The DeleteForm component.
 */
export const DeleteForm = ({
  selectedSyndic,
  setForceRefresh,
  forceRefresh,
  setIsDeleteModalOpen,
  isFieldLoading,
  managers
}) => {
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form
      form={form}
      onFinish={(value) =>
        handleDeleteFormSubmit({
          value,
          dispatchAPI,
          message,
          selectedSyndic,
          setForceRefresh,
          forceRefresh,
          setIsDeleteModalOpen
        })
      }
    >
      <Form.Item name="new_syndic">
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldLoading}
        >
          {(managers || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Row gutter={[16, 16]} justify="end">
          <Col>
            <Button type="primary" onClick={() => setIsDeleteModalOpen(false)}>
              {t('buttons.cancel')}
            </Button>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit">
              {t('buttons.save')}
            </Button>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item>
        <Row gutter={[16, 16]} justify="end">
          <Button
            onClick={() =>
              handleDeleteFormSubmit({
                dispatchAPI,
                message,
                selectedSyndic,
                setForceRefresh,
                forceRefresh,
                setIsDeleteModalOpen
              })
            }
          >
            {t('syndics.modals.buttons.no_reassign')}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

DeleteForm.propTypes = {
  setIsDeleteModalOpen: PropTypes.func.isRequired,
  selectedSyndic: PropTypes.string.isRequired,
  managers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool,
  isFieldLoading: PropTypes.bool
};

DeleteForm.defaultProps = {
  forceRefresh: false,
  isFieldLoading: false
};
