import { Button, Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { dashboardTitle } from '../services/ComplianceElanRouter/dashboardTitles';
import mes_brouillons_1 from '../../../assets/images/mes_brouillons_1.svg';
import { ServicesCustomContent } from '../services/ServicesCustomContent';
import Mission_recherche_plan from '../../../assets/images/illustrationMission_recherche_plan.svg';
import Mission_recherche_rcp from '../../../assets/images/recherche_rcp.svg';
import Mission_services_complementaires from '../../../assets/images/services_complementaires.svg';
import Missions_RCP_ELAN from '../../../assets/images/Missions_RCP_ELAN.svg';

/**
 * @component
 * Renders the NewMission component.
 *
 * @returns {JSX.Element} The NewMission component.
 */
export const NewMission = () => {
  const { newMissionTitle, newMissionSubtitle } = dashboardTitle();
  const { t } = useTranslation();
  const cardStyle = {
    title: {
      overflow: 'visible',
      whiteSpace: 'normal'
    }
  };

  const cards = [
    {
      title: t('syndic-view.new-mission.rcp_elan'),
      image: Missions_RCP_ELAN,
      route: '/services/compliance-loi-elan/audit',
      button: t('syndic-view.new-mission.audit'),
      index: 1
    },

    {
      title: t('syndic-view.new-mission.document_rcp'),
      image: Mission_recherche_rcp,
      route: '/services/rcp-research/devis',
      button: t('syndic-view.new-mission.recieve_quote'),
      index: 2
    },
    {
      title: t('syndic-view.new-mission.recherche_plan'),
      image: Mission_recherche_plan,
      index: 3,
      route: '/services/plans-research/devis',
      button: t('syndic-view.new-mission.recieve_quote')
    },
    {
      title: t('syndic-view.new-mission.recherche_fiche'),
      image: Mission_services_complementaires,
      route: '',
      button: t('syndic-view.new-mission.recieve_quote'),
      index: 4
    },
    {
      title: t('syndic-view.new-mission.other_requests'),
      image: Mission_services_complementaires,
      route: '',
      button: t('syndic-view.new-mission.make_request'),
      index: 5
    }
  ];

  return (
    <ServicesCustomContent
      title={newMissionTitle}
      subtitle={newMissionSubtitle}
      imageOnRight={mes_brouillons_1}
      cardStyle={{ backgroundColor: '#222222' }}
      containerStyle={{ width: '100%' }}
    >
      <div className="subscription-tab">
        <Row justify="space-between" gutter={[16, 16]}>
          {cards.map((item) => (
            <Col key={item.index} className="subscription-wrapper">
              <Card
                title={item.title}
                styles={cardStyle}
                key={item.index}
                style={{
                  backgroundColor: '#ffff',
                  border: `1px solid ${item.color}`,
                  width: '15rem',
                  height: '20rem',
                  borderRadius: '15px'
                }}
              >
                <img src={item.image} alt="logo" />
                <Link to={item.route}>
                  <Button
                    className="new-mission-button"
                    type="primary"
                    style={{
                      backgroundColor: '#222222',
                      borderRadius: '15px',
                      color: '#ffffff',
                      width: '100%'
                    }}
                  >
                    {item.button}
                  </Button>
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </ServicesCustomContent>
  );
};
