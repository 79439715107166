import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { GetEnums } from '../../../utils/getEnums';
import { addColumns } from '../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { EditableTable } from '../../../components/editableTableComponents/EditableTable';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';

/**
 * Renders the CerfaDocuments component.
 *
 * @component
 * @returns {JSX.Element} The CerfaDocuments component.
 */
export const CerfaDocuments = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectOptions, setSelectOptions] = useState({});
  const [selectType, setSelectType] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const { getResourceData } = EditableTableMethods();
  const { searchValue, setPagination, pagination, currentSorter } =
    useConfigurationContext();
  const { getEnums } = GetEnums();
  const columns = useColumns({
    setEditingKey,
    form,
    setSelectType,
    setForceRefresh,
    forceRefresh
  });
  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  useEffect(() => {
    (async () => {
      const [
        cerfaDocumentsDatas,
        documentRepositoriesDatas,
        cerfaDocumentsEnums
      ] = await Promise.all([
        getResourceData({
          resource: 'cerfa-documents',
          searchValue,
          setPagination,
          pagination,
          query: '&populate=document_type',
          currentSorter
        }),
        getResourceData({ resource: 'document-repositories' }),
        getEnums({ resource: 'cerfa-documents' })
      ]);

      setDataSource(
        cerfaDocumentsDatas.map((el, index) => ({ ...el, key: index }))
      );
      setSelectOptions({
        cerfa_types: cerfaDocumentsEnums.cerfa_types,
        document_types: documentRepositoriesDatas
      });
    })();
  }, [forceRefresh, searchValue, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: mergedColumns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      setMergedColumns,
      selectOptions,
      selectType,
      resource: 'cerfa-documents'
    });
  }, [editingKey, dataSource]);

  return (
    <>
      <PageHeaderCustom title={t('cerfa-documents.title')} />
      <ContentCustom>
        <EditableTable
          resource="cerfa-documents"
          dataSource={dataSource}
          mergedColumns={mergedColumns}
          setSelectedRowKey={setSelectedRowKey}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          form={form}
          searchValue={searchValue}
          pagination={pagination}
          setPagination={setPagination}
        />
      </ContentCustom>
    </>
  );
};
