import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';

/**
 * @hook
 * Custom hook that returns an array of fields for the right side of the form in the ComplianceElanRouter Audit Step 3.
 * Each field object contains the name, noLabel, valuePropName, and input properties.
 * The input property is a Checkbox component with a label translated using the useTranslation hook.
 *
 * @param {Object} options - The options object.
 * @param {Array} options.filesList - The list of files.
 * @param {boolean} options.noRCPDocumentChecked - The flag indicating if no RCP document is checked.
 * @param {function} options.setNoRCPDocumentChecked - The function to set the flag indicating if no RCP document is checked.
 * @returns {Array} - The array of field objects.
 */
export const useFieldsRight = ({
  filesList,
  noRCPDocumentChecked,
  setNoRCPDocumentChecked
}) => {
  const { t } = useTranslation();

  return [
    {
      name: ['no_rcp_document'],
      noLabel: true,
      valuePropName: 'checked',
      input: (
        <Checkbox
          disabled={filesList?.rcp?.length > 0}
          value={noRCPDocumentChecked}
          onChange={(e) => setNoRCPDocumentChecked(e.target.checked)}
        >
          {t('pcs-pre-productions.form.no_RCP_document')}
        </Checkbox>
      )
    },
    ...(noRCPDocumentChecked
      ? [
          {
            name: ['cadastral_reference'],
            rules: [{ required: true }],
            key: 'cadastral_reference'
          }
        ]
      : [])
  ];
};
