import {
  Alert,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { setOptions } from './setOptions';
import logo_prestation_word from '../../../../assets/images/logo_prestation_word.svg';

const { Option } = Select;
const { Text } = Typography;

/**
 * Custom hook that returns an object containing the fields for a form.
 *
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Object[]} options.enums - The enums object.
 * @param {boolean} options.isEditable - Indicates if the fields are editable.
 * @param {Object} options.mission - The mission object.
 * @returns {Object} - The fields object.
 */
export const useFields = ({ enums, isEditable, mission, purpose }) => {
  const { t } = useTranslation();
  const strongSpan = (text) => <Text strong>{text}</Text>;

  const HeaderInfo = (
    <>
      <Row justify="center">
        {purpose === 'create' ? (
          <Image
            style={{ margin: '1rem 0' }}
            src={logo_prestation_word}
            preview={false}
          />
        ) : null}
      </Row>
      <Divider>{t('collective-ownership-forms.form.title')}</Divider>
      <Col span={24}>
        <Text>
          <Text>
            {t('collective-ownership-forms.form.syndicName')} :{' '}
            {mission.syndic?.society_name
              ? strongSpan(mission.syndic?.society_name)
              : '-'}
          </Text>
        </Text>
      </Col>
      <Col span={24}>
        <Text>
          {t('collective-ownership-forms.form.collectiveOwnershipAdress')} :{' '}
          {mission.collective_ownership?.name
            ? strongSpan(mission.collective_ownership?.name)
            : '-'}
        </Text>
      </Col>
      {mission.co_owner_form_status === 'CLOSED' ? (
        <Col>
          <Alert
            showIcon
            message={t('collective-ownership-forms.messages.closed_form')}
            type="warning"
          />
        </Col>
      ) : null}
    </>
  );

  const fields = [
    {
      name: [null],
      noLabel: true,
      input: HeaderInfo
    },
    {
      name: ['owners'],
      key: 'owners',
      rules: [{ required: true }, { type: 'string' }],
      disabled: isEditable || mission?.co_owner_form_status === 'CLOSED'
    },
    {
      name: ['physical_person'],
      key: 'physical_person',
      valueProppName: 'checked',
      disabled: isEditable || mission?.co_owner_form_status === 'CLOSED',
      input: (
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Form.Item name="physical_person" valuePropName="checked">
              <Switch disabled={isEditable} />
            </Form.Item>
          </Col>
        </Row>
      )
    },
    {
      name: ['phone_number'],
      key: 'phone_number',
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select
              style={{ width: 70 }}
              disabled={
                isEditable || mission?.co_owner_form_status === 'CLOSED'
              }
            >
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <InputNumber
              style={{ width: 'calc(100% - 70px)' }}
              disabled={
                isEditable || mission?.co_owner_form_status === 'CLOSED'
              }
            />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['email'],
      key: 'email',
      rules: [{ required: true }, { type: 'string' }],
      disabled: isEditable || mission?.co_owner_form_status === 'CLOSED'
    },
    {
      name: ['adress_if_different'],
      key: 'adress_if_different',
      disabled: isEditable || mission?.co_owner_form_status === 'CLOSED'
    },
    {
      name: ['batch_numbers'],
      key: 'batch_numbers',
      input: (
        <Row gutter={[4, 4]}>
          <Col>
            <Input
              disabled={
                isEditable || mission?.co_owner_form_status === 'CLOSED'
              }
            />
          </Col>
          <Col>
            <Tooltip
              title={t('collective-ownership-forms.messages.separate_lot')}
            >
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          </Col>
        </Row>
      )
    },
    {
      name: ['private_usages'],
      key: 'private_usages',
      disabled: isEditable,
      input: (
        <Checkbox.Group
          options={setOptions({ enums, t })}
          disabled={isEditable || mission?.co_owner_form_status === 'CLOSED'}
          style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
        />
      )
    },
    {
      name: ['other_annexes'],
      key: 'other_annexes',
      disabled: isEditable || mission?.co_owner_form_status === 'CLOSED'
    }
  ];
  return { fields };
};
