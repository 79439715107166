import { Row, Col } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns the list content for the informations provided by the syndic.
 * @hook
 * @param {Object} data - The data object containing the information to be provided by the syndic.
 * @returns {Object} - The list content for the informations provided by the syndic.
 */
export const useInformationsProvidedBySyndicListContent = (data = {}) => {
  const { t } = useTranslation();
  const informations_to_be_provided_by_syndic =
    data?.informations_to_be_provided_by_syndic || {};

  /**
   * Returns the icon component based on the validation status.
   * @param {boolean} isValidate - The validation status.
   * @returns {JSX.Element} - The icon component.
   */
  const useIcon = (isValidate) => {
    if (isValidate) {
      return <CheckCircleFilled style={{ width: 24, color: 'yellow' }} />;
    }
    return <CloseCircleFilled style={{ width: 24, color: 'grey' }} />;
  };

  const informations = [
    {
      noLabel: true,
      span: 3,
      content: (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {useIcon(
              informations_to_be_provided_by_syndic?.contract_completed_and_signed
            )}
            {t('pcs-pre-productions.form.contract_completed_and_signed')}
          </Col>
          <Col span={24}>
            {useIcon(
              informations_to_be_provided_by_syndic?.manager_contact_details
            )}
            {t('pcs-pre-productions.form.manager_contact_details')}
          </Col>
          <Col span={24}>
            {useIcon(informations_to_be_provided_by_syndic?.invoice_code)}
            {t('pcs-pre-productions.form.invoice_code')}
          </Col>
          <Col span={24}>
            {useIcon(
              informations_to_be_provided_by_syndic?.rcp_document_in_their_possession
            )}
            {t('pcs-pre-productions.form.rcp_document_in_their_possession')}
          </Col>
          <Col span={24}>
            {useIcon(
              informations_to_be_provided_by_syndic?.charging_grid_used_by_the_accountant
            )}
            {t('pcs-pre-productions.form.charging_grid_used_by_the_accountant')}
          </Col>
        </Row>
      )
    }
  ];

  return { informations };
};
