import { useEffect, useState } from 'react';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';
import { getEnums } from './utils/getEnums';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

export const ListUsers = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns({
    enums,
    dispatchAPI,
    message,
    setForceRefresh,
    forceRefresh
  });

  useEffect(() => {
    (async () => {
      await getEnums({ dispatchAPI, setEnums, message });
    })();
  }, []);

  return (
    <ListResource
      resourceName="users"
      columns={columns}
      headers={headers}
      forceRefresh={forceRefresh}
      customActionColumn
      resourceModelName="User"
      scroll={{ x: 'max-content' }}
    />
  );
};
