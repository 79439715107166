import { Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Get the items for the mission monitoring.
 * @function
 * @param {Object} props - The props.
 * @param {Object[]} props.history - The history of the mission.
 * @returns {Object[]} - The items for the mission monitoring.
 */
export const useItems = ({ history }) => {
  const { t } = useTranslation();

  return [
    {
      key: '1',
      label: (
        <Text strong>
          {t('pcs-pre-productions.show.cards.titles.mission-monitoring')}
        </Text>
      ),
      children: (
        <Timeline
          mode="left"
          items={(history || []).map((item) => ({
            label: (
              <>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'semibold'
                  }}
                >
                  {dayjs(item.date).format('DD-MM-YYYY')}
                </Text>
                <br />
                <Text
                  style={{
                    fontSize: 12,
                    color: 'gray'
                  }}
                >
                  {dayjs(item.date).format('HH:mm').replace(':', 'h')}
                </Text>
              </>
            ),
            color: 'orange',
            children: (
              <>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'semibold'
                  }}
                >
                  {t(`monitoring.${item.type}`)} {item.step && ' - '}{' '}
                  {item.step && t(`pcs-pre-productions.tags.${item.step}`)}
                </Text>
                <br />
                <Text
                  style={{
                    fontSize: 12,
                    color: 'gray'
                  }}
                >
                  {`${item.by?.first_name ? item.by.first_name : ''} ${
                    item.by?.last_name ? item.by.last_name : ''
                  } - `}
                  {item.by?.role
                    ? t(`users.tags.${item.by.role.split(':')[1]}`)
                    : ''}
                </Text>
              </>
            )
          }))}
        />
      )
    }
  ];
};
