import { Modal, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { BlockMissionCreditNoteButtons } from '../buttons/BlockMissionCreditNoteButtons';

const { Text } = Typography;

/**
 * BlockMissionCreditNote component.
 *
 * @component
 * @returns {JSX.Element} The BlockMissionCreditNote component
 */
export const BlockMissionCreditNote = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useMissionContext();
  const { t } = useTranslation();

  return (
    <Modal
      title={t('missions.modal.titles.block_mission_credit_note')}
      onCancel={() =>
        setIsDrawerOpen((prev) => ({
          ...prev,
          block_mission_credit_note: {
            open: false,
            type: ''
          }
        }))
      }
      open={isDrawerOpen.block_mission_credit_note}
      footer={null}
      width={1000}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Text>
            {t('missions.modal.messages.block_mission_credit_note_first')}
          </Text>
        </Col>
        <Col span={24}>
          <Text>
            {t('missions.modal.messages.block_mission_credit_note_second')}
          </Text>
        </Col>
        <Col span={24}>
          <BlockMissionCreditNoteButtons />
        </Col>
      </Row>
    </Modal>
  );
};
