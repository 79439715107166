import { Badge, Button, Card, Flex, Spin, Typography } from 'antd';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { DownOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { formatAddress } from '../../../syndic-view/utils/formatingFunctions';

const { Text } = Typography;

const StyledCol = styled.div`
  height: 600px;
  border-radius: 2px;
  min-width: 300px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-right: 1px solid white;
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--primaryColor);
  color: white;
  box-shadow: 0px 9px 16px -6px var(--opacity25);
  border-radius: 2px;
  width: 100%;
  text-align: left;
  padding: 12px 10px;
  min-height: 40px;
  z-index: 1;
`;

/**
 * MissionKanbanColumn component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.step - The step of the mission.
 * @param {string} props.missionType - The type of the mission.
 * @param {boolean} props.isPCS - Indicates if the mission is PCS.
 * @param {Array} props.missionSteps - The steps of the mission.
 * @param {string} props.selectedSyndic - The selected syndic.
 * @param {string} props.searchValue - The search value.
 * @returns {JSX.Element} The MissionKanbanColumn component.
 */
export const MissionKanbanColumn = ({
  step,
  missionType,
  isPCS,
  missionSteps,
  selectedSyndic,
  searchValue
}) => {
  const [missions, setMissions] = useState([]);
  const [totalMissions, setTotalMissions] = useState(0);
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [skip, setSkip] = useState(0);
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);

  const getMissions = async (fromSkip) => {
    setIsLoading(true);
    try {
      const populateString = [
        'syndic',
        'manager',
        'collective_ownership',
        'collective_ownership.first_city'
      ];
      const fieldsString = [
        'type',
        'steps',
        'updated_at',
        'collective_ownership.name',
        'collective_ownership.first_address',
        'collective_ownership.first_city.name',
        'manager.first_name',
        'manager.last_name',
        'syndic.society_name'
      ];

      const missionTypeString = isPCS
        ? `steps.sub=${step}`
        : `steps.principal=${step}`;

      const syndicString = selectedSyndic ? `&syndic=${selectedSyndic}` : '';
      const keywords = searchValue ? `/search/${searchValue}` : '';
      const { data, headers } = await dispatchAPI('GET', {
        url: `/mission${keywords}?type=${missionType}${syndicString}&${missionTypeString}&populate=${populateString}&fields=${fieldsString}&skip=${skip}&limit=10`
      });
      if (fromSkip) {
        setMissions((prevMissions) => [...prevMissions, ...data]);
      } else {
        setMissions(data || []);
      }
      const total = parseInt(headers['x-total-count'], 10);
      setTotalMissions(total);
      setIsLoading(false);
    } catch (e) {
      message(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getMissions();
    })();
  }, [missionSteps, searchValue, selectedSyndic]);

  useEffect(() => {
    if (skip === 0) return;
    (async () => {
      await getMissions(skip);
    })();
  }, [skip]);

  const [, drop] = useDrop({
    accept: 'card',
    drop: async () => {},
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const handleLoadMore = () => {
    setSkip((prevSkip) => prevSkip + 10);
  };

  return (
    <Spin spinning={isLoading}>
      <StyledCol ref={drop}>
        <Title>
          <Badge
            dot={false}
            color="transparent"
            text={t(`missions.steps.${step}`)}
            style={{ color: 'var(--clientColor)' }}
          />
        </Title>
        <Flex
          style={{ width: '100%', overflowY: 'auto', height: '100vh' }}
          gap={10}
          vertical
        >
          {missions &&
            missions.map(
              (
                {
                  _id,
                  type,
                  collective_ownership,
                  manager,
                  syndic,
                  updated_at
                },
                idx
              ) => (
                <React.Fragment key={_id}>
                  <Card
                    key={_id}
                    style={{ width: '100%' }}
                    className="kanban-pcs-card"
                  >
                    <Flex vertical gap={8}>
                      <Text style={{ color: 'var(--addColor)' }}>
                        <UserOutlined style={{ paddingRight: '15px' }} />
                        {`${manager?.first_name || ''} ${
                          manager?.last_name || ''
                        }`}
                      </Text>
                      <Text strong level={5}>
                        {syndic.society_name}
                      </Text>
                      <Text level={5}>
                        {formatAddress(
                          collective_ownership?.name,
                          collective_ownership?.first_address,
                          collective_ownership?.first_city
                        )}
                      </Text>
                      <Flex justify="space-between">
                        <Text style={{ color: 'var(--gray600)' }}>
                          {`Mise a jour le ${dayjs(updated_at).format(
                            'DD/MM/YYYY'
                          )} `}
                        </Text>
                        <Link
                          to={{
                            pathname: `/missions/missions-view/show/${
                              type.includes('PCS') ? 'PCS' : type.toLowerCase()
                            }/${_id}/${type}`
                          }}
                        >
                          <EyeOutlined
                            style={{ fontSize: 18, color: '#495057' }}
                          />
                        </Link>
                      </Flex>
                    </Flex>
                  </Card>
                  {idx === missions.length - 1 &&
                    missions.length < totalMissions && (
                      <Button
                        style={{ width: '100%' }}
                        onClick={handleLoadMore}
                      >
                        {t('buttons.load_more')}
                        <DownOutlined />
                      </Button>
                    )}
                </React.Fragment>
              )
            )}
        </Flex>
      </StyledCol>
    </Spin>
  );
};

MissionKanbanColumn.propTypes = {
  step: PropTypes.string.isRequired,
  isPCS: PropTypes.bool.isRequired,
  missionType: PropTypes.string,
  missionSteps: PropTypes.shape([]).isRequired,
  selectedSyndic: PropTypes.string,
  searchValue: PropTypes.string
};

MissionKanbanColumn.defaultProps = {
  missionType: 'PCS_1',
  selectedSyndic: '',
  searchValue: ''
};
