import { notification } from 'antd';
import { BaseModel } from './BaseModel';

/**
 * Represents an document.
 * @class
 */
export class Document extends BaseModel {
  static resourceName = 'documents';

  /**
   * Uploads a document.
   * @function
   * @async
   * @param {Object} options - The options for uploading the document.
   * @param {string} options.id - The ID of the document.
   * @param {File} options.file - The file to be uploaded.
   * @param {function} options.setForceRefresh - The function to set the force refresh flag.
   * @param {string} [options.extraQuery=''] - The extra query to be added to the URL.
   * @returns {Promise<void>} - A promise that resolves when the document is uploaded.
   */
  static async uploadDocument({
    id,
    file,
    setForceRefresh,
    extraQuery = '',
    t,
    invoiceGeneration = false
  }) {
    const formData = new FormData();

    formData.append('documents', file);

    await this.dispatchAPI('PATCH', {
      url: `documents/upload/${id}${extraQuery}`,
      body: formData
    });

    notification.success({
      message: t('orders.notifications.upload_document_success_title'),
      description: t('orders.notifications.upload_document_success_description')
    });

    if (invoiceGeneration) {
      notification.success({
        message: t('missions.messages.invoice_generated'),
        description: t('missions.messages.invoice_generated_content'),
        placement: 'topRight'
      });
    }
    setForceRefresh((prev) => !prev);
  }

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Document.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }
}
