import { ServicesCustomContentWithDivider } from '../ServicesCustomContentWithDivider';
import { dashboardTitle } from '../ComplianceElanRouter/dashboardTitles';

/**
 * @component
 * Renders a list of collective ownerships for a syndic.
 * @returns {JSX.Element} The rendered component.
 */
export const DashBoard = () => {
  const { title, button } = dashboardTitle();
  const mission = 'PLANS_RESEARCH';

  return (
    <ServicesCustomContentWithDivider
      title={title(mission)}
      buttonOnRight={button}
      dashboardResource={mission}
      withDivider
      cardStyle={{ backgroundColor: '#222222' }}
    />
  );
};
