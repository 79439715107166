import { Route, Routes, Outlet } from 'react-router-dom';
import { PcsPreProductionRouter } from './pcs-pre-productions/PcsPreProductionRouter';
import { MissionViewRouter } from './missions-view/MissionViewRouter';
import { ListCollections } from './collections/ListCollections';
import { Exception } from '../../components';
import { MissionContextProvider } from '../../contexts/MissionContext';

// This component applies the context to the "show" routes
const MissionContextWrapper = () => (
  <MissionContextProvider>
    <Outlet />
  </MissionContextProvider>
);

/**
 * Renders the mission router component.
 * @returns {JSX.Element} The mission router component.
 */
export const MissionRouter = () => (
  <Routes>
    <Route path="/pcs-pre-productions/*" element={<PcsPreProductionRouter />} />
    <Route path="/missions-view/*" element={<MissionViewRouter />} />

    <Route element={<MissionContextWrapper />}>
      <Route path="/collections" element={<ListCollections />} />
    </Route>

    <Route path="*" element={<Exception />} />
  </Routes>
);
