import { Route, Routes } from 'react-router-dom';
import { NotaryConfigurations } from './NotaryConfigurations';
import { CreateUpdateNotaryConfiguration } from './CreateUpdateNotaryConfiguration';

export const NotaryConfigurationRouter = () => (
  <Routes>
    <Route path="/" element={<NotaryConfigurations />} />
    <Route
      path="/create/:resource"
      element={<CreateUpdateNotaryConfiguration purpose="create" />}
    />
    <Route
      path="/edit/:id/:resource"
      element={<CreateUpdateNotaryConfiguration purpose="edit" />}
    />
  </Routes>
);
