import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

/**
 * TableTitle component displays a styled title text.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The text to be displayed as the title.
 *
 * @returns {JSX.Element} The rendered TableTitle component.
 */
const TableTitle = ({ title }) => {
  const { Text } = Typography;
  return (
    <Text
      style={{ fontWeight: 'normal', fontSize: '14px', lineHeight: '17px' }}
    >
      {title}
    </Text>
  );
};

TableTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default TableTitle;
