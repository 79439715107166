import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Tooltip, Button, Form, Input } from 'antd';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { setDocumentIcon } from '../../../../../components/setDocumentIcon';

const { Text } = Typography;
const { TextArea } = Input;

/**
 * Custom hook that returns an array of columns for the lot enjoyment right table.
 * @hook
 * @returns {Array} Array of columns
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { viewDocument, downloadDocument } = useDownloadDocument();

  return [
    {
      title: t('missions.form.batch_numbers'),
      key: 'principal_lot_enjoyment_right',
      dataIndex: 'principal_lot_enjoyment_right',
      width: '10%',
      render: (principal_lot_enjoyment_right) =>
        principal_lot_enjoyment_right?.value || '-'
    },
    {
      title: t('missions.form.private_usages'),
      key: 'private_usages',
      width: '80%',
      render: (_, record) => (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text>
                {t('missions.form.private_usages_column_datas', {
                  private_usage: t(
                    `missions.tags.${record.private_usage?.value}`
                  ),
                  enjoyment_right: t(
                    `missions.tags.${record.enjoyment_right?.value}`
                  ),
                  to_validate: t(`missions.tags.${record.to_validate?.value}`)
                })}
              </Text>
            </Col>
          </Row>
          <Form.Item
            name={['pcajp', record.private_usage_id, 'lot_description']}
          >
            <TextArea />
          </Form.Item>
        </>
      )
    },
    {
      title: t('missions.form.document'),
      key: 'document',
      dataIndex: 'document',
      width: '10%',
      render: (document) =>
        (document?.files || []).map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    }
  ];
};
