import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Custom hook for handling event conflict messages.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setRefreshAppointment - The function to refresh the appointment.
 * @returns {Object} - An object containing the eventConflictMessage function.
 */
export const useEventConflictMessage = ({ setRefreshAppointment }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const eventConflictMessage = (e) => {
    if (
      e?.response?.data?.message &&
      e.response.data.message.includes('EVENT_CONFLICT')
    ) {
      antdMessage.warning(t('errors.message.EVENT_CONFLICT'));
      setRefreshAppointment((prev) => !prev);
    } else {
      message(e);
    }
  };

  return { eventConflictMessage };
};
