import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSignCodeFields } from './useVerifyCodeFields';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { generatePDF } from '../../../../../utils/generatePDF';

const buttonSeeStyle = {
  backgroundColor: '#222222',
  borderRadius: '15px',
  color: '#ffffff'
};

/**
 * VerifyCodeComponent is a React component that handles the verification of a code.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.codeToValidate - The code to validate.
 * @param {function} props.setInitialValues - A function to set the initial values.
 * @param {Object} props.initialValues - The initial values.
 * @param {string} props.dividerTitle - The title for the divider.
 * @param {function} props.setIsValidated - A function to set the validation status.
 * @param {string} props.documentToSee - The document to see.
 * @returns {JSX.Element} The VerifyCodeComponent JSX element.
 */
export const VerifyCodeComponent = ({
  codeToValidate,
  setInitialValues,
  initialValues,
  dividerTitle,
  setIsValidated,
  documentToSee,
  generateNewPdfProps
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [current, setCurrent] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const { fieldsAcceptSendCode, fieldValidateCode, fieldsCodeIsValidated } =
    useSignCodeFields({
      setCurrent,
      setInitialValues,
      codeToValidate,
      initialValues
    });

  useEffect(() => {
    if (current === 2) {
      setIsValidated(true);
    } else {
      setIsValidated(false);
    }
  }, [current]);

  const downloadFile = async () => {
    setIsDownloading(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: `syndic-requests/generate-document`,
        body: { values: initialValues },
        responseType: 'arraybuffer'
      });

      const blob = new Blob([data], { type: 'application/pdf' });

      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      const fileName = documentToSee;
      link.target = fileName;
      link.click();

      antdMessage.success(t('children.documents.modal.success'));
      return URL.revokeObjectURL(url);
    } catch (e) {
      return message(e);
    } finally {
      setIsDownloading(false);
    }
  };

  const generateNewPdf = async () => {
    setIsDownloading(true);
    try {
      await generatePDF({
        message,
        dispatchAPI,
        template_type: 'TEMPLATE_DOCS',
        setIsLoading: setIsDownloading,
        openInNewTab: true,
        ...generateNewPdfProps
      });
    } catch (error) {
      message(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Flex vertical gap={15} style={{ width: '100%', alignItems: 'center' }}>
      {dividerTitle ? (
        <Divider>{dividerTitle}</Divider>
      ) : (
        <div style={{ marginBottom: '16px' }} />
      )}
      <Button
        loading={isDownloading}
        onClick={generateNewPdfProps ? generateNewPdf : downloadFile}
        type="primary"
        style={buttonSeeStyle}
      >
        <EyeOutlined />
        {documentToSee === 'devis' ? t('missions.form.see_devis') : ''}
        {documentToSee === 'contract'
          ? t('pcs-pre-productions.form.see_contract')
          : ''}
      </Button>
      {current === 0 && fieldsAcceptSendCode}
      {current === 1 && fieldValidateCode}
      {current === 2 && fieldsCodeIsValidated}
    </Flex>
  );
};

VerifyCodeComponent.propTypes = {
  codeToValidate: PropTypes.string.isRequired,
  setInitialValues: PropTypes.func.isRequired,
  dividerTitle: PropTypes.string,
  setIsValidated: PropTypes.func,
  documentToSee: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  generateNewPdfProps: PropTypes.shape({})
};

VerifyCodeComponent.defaultProps = {
  dividerTitle: '',
  setIsValidated: () => {},
  generateNewPdfProps: null
};
