import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import { useItems } from './useItems';

/**
 * Represents the Messages component.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.missionType - The mission type.
 * @param {string} props.syndic - The syndic.
 * @param {string} props.collective_ownership - The collective ownership.
 * @param {string} props.cardTitle - The card title.
 * @param {boolean} props.withoutDefaultActiveKey - Flag indicating if the default active key should be set.
 * @param {boolean} props.withCreateButton - Flag indicating if the create button should be hidden.
 * @returns {JSX.Element} The Messages component.
 */
export const Messages = ({
  missionType,
  syndic,
  collective_ownership,
  cardTitle,
  withoutDefaultActiveKey,
  withCreateButton,
  titleClassName
}) => {
  const items = useItems({
    missionType,
    syndic,
    collective_ownership,
    cardTitle,
    withCreateButton,
    titleClassName
  });

  return (
    <Collapse
      style={{ overflow: 'auto' }}
      items={items}
      defaultActiveKey={withoutDefaultActiveKey ? null : ['1']}
      expandIconPosition="end"
      titleClassName
    />
  );
};

Messages.propTypes = {
  missionType: PropTypes.string,
  syndic: PropTypes.string,
  collective_ownership: PropTypes.string,
  cardTitle: PropTypes.string,
  withoutDefaultActiveKey: PropTypes.bool,
  withCreateButton: PropTypes.bool,
  titleClassName: PropTypes.string
};

Messages.defaultProps = {
  missionType: null,
  syndic: null,
  collective_ownership: null,
  cardTitle: null,
  withoutDefaultActiveKey: false,
  withCreateButton: false,
  titleClassName: null
};
