import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from './fields';
import { useConfig } from './utils/CreateUpdateConfig';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { useMessageContext } from '../../contexts/MessageContext';

/**
 * Renders a component for creating a message.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.missionType - The mission type.
 * @param {string} props.syndic - The syndic.
 * @param {string} props.collective_ownership - The collective ownership.
 * @returns {JSX.Element} The JSX element representing the create message component.
 */
export const CreateMessage = ({
  missionType,
  syndic,
  collective_ownership
}) => {
  const { t } = useTranslation();
  const { setIsCreateMessageCardOpen, setMessageDateWhoReceiveResponse } =
    useGlobalContext();
  const {
    enums,
    recipients,
    isFieldsLoading,
    form,
    predefinedMessages,
    setActiveCard,
    model,
    id
  } = useMessageContext();

  const [editorState, setEditorState] = useState(null);
  const { fields } = useFields({
    isFieldsLoading,
    enums,
    recipients,
    form,
    setEditorState,
    editorState,
    predefinedMessages,
    missionType
  });

  const config = useConfig({
    editorState,
    recipients,
    form,
    missionType,
    syndic,
    collective_ownership
  });

  return (
    <Card
      title={t('messages.form.title')}
      extra={
        <Button
          type="text"
          onClick={() => {
            setIsCreateMessageCardOpen(false);
            setMessageDateWhoReceiveResponse(null);
            setActiveCard(null);
          }}
          icon={<CloseOutlined />}
          size="small"
          style={{ border: 'none', boxShadow: 'none' }}
        />
      }
    >
      <CreateUpdateContainer
        config={config}
        customFormInstance={form}
        fields={fields}
        isFieldsLoading={isFieldsLoading}
        purpose="create"
        baseUrl="messages"
        resource="messages"
        extraQuery={`/${id}/${model}`}
        layout="vertical"
        withFilesManager={false}
        withCustomPageHeader
        customWrapperCol
        setIsCreateUpdateOpen={setIsCreateMessageCardOpen}
        isCreateUpdateOpen={false}
        cancelCustomAction={() => {
          setIsCreateMessageCardOpen(false);
          setMessageDateWhoReceiveResponse(null);
          form.resetFields();
          setActiveCard(null);
        }}
        submitLabel={t('buttons.send')}
        successMessage={t('messages.success')}
      />
    </Card>
  );
};

CreateMessage.propTypes = {
  missionType: PropTypes.string,
  syndic: PropTypes.shape({}),
  collective_ownership: PropTypes.shape({})
};

CreateMessage.defaultProps = {
  missionType: null,
  syndic: null,
  collective_ownership: null
};
