import { useTranslation } from 'react-i18next';
import { Space, Tag, Typography } from 'antd';
import { EyeOutlined, CheckOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { stockCerfaTag } from '../../../../../utils/constants/tagColors';
import { Order } from '../../../../../models/Order';

const { Text } = Typography;

/**
 * A custom hook that generates and returns the columns configuration for an Ant Design Table component
 * used to display stock cerfa records. The columns include fields such as document reference, syndic,
 * collective ownership, status, stock date, concerned SPF, and fee. The hook also includes an action
 * column with options to change the status of a record or view detailed information.
 *
 * @param {Function} setForceRefresh - A function to trigger a refresh of the data in the parent component.
 *
 * @returns {Array<Object>} An array of column configuration objects for the Ant Design Table component.
 */
export const useStockCerfaColumns = (setForceRefresh) => {
  const { t } = useTranslation();
  const translation = 'dashboard.cerfa_stock.titles';

  const handleChangeStatus = async (record) => {
    await Order.patchResource({
      id: record._id,
      values: { stock_cerfa_status: 'TO_PROCESS' }
    });
    setForceRefresh((prev) => !prev);
  };
  return [
    {
      title: t(`${translation}.document_reference`),
      key: 'reference',
      render: (record) => (record?.ref_doc ? record.ref_doc : '-')
    },
    {
      title: t(`${translation}.syndic`),
      key: 'syndic',
      render: (record) => {
        const syndic = record?.mission?.syndic;

        return syndic ? (
          <Text>{`${syndic.society_name} (${syndic.code})`}</Text>
        ) : (
          '-'
        );
      },
      sorter: true
    },
    {
      title: t(`${translation}.collective_ownership`),
      key: 'collective_ownership',
      render: (record) => {
        const collective = record?.mission?.collective_ownership;

        return `${collective?.code ? `${collective?.code} -` : ''} ${
          collective?.first_address?.number || ''
        } ${collective?.first_address?.street || ''} ${
          collective?.first_address?.postal_code || ''
        } ${collective?.first_address?.city || ''} ${
          collective?.cadastral_reference
            ? `(${collective?.cadastral_reference})`
            : ''
        }`;
      },
      sorter: true
    },
    {
      title: t(`${translation}.status`),
      key: 'status',
      render: (record) => {
        const stock_cerfa_status = record?.stock_cerfa_status;

        return stock_cerfa_status ? (
          <Tag color={stockCerfaTag[stock_cerfa_status]}>
            {t(`dashboard.cerfa_stock.tags.${stock_cerfa_status}`, {
              defaultValue: stock_cerfa_status
            })}
          </Tag>
        ) : (
          '-'
        );
      },
      sorter: true
    },
    {
      title: t(`${translation}.stock_date`),
      key: 'stock_date',
      render: (record) => {
        const date = record?.stock_date || record?.added_date;

        return date ? dayjs(date).format('DD-MM-YYYY') : '-';
      },
      sorter: true
    },
    {
      title: t(`${translation}.concerned_SPF`),
      key: 'concerned_SPF',
      render: (record) => {
        const value = record?.concerned_SPF?.name;

        return value || '-';
      },
      sorter: true
    },
    {
      title: t(`${translation}.fee`),
      key: 'fee',
      render: (record) => {
        const fee = record?.costs;

        return fee ? `${parseFloat(fee).toFixed(2)} €` : '0.00 €';
      },
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => {
        const mission = record?.mission;

        return (
          <Space size="middle">
            {record?.stock_cerfa_status === 'TO_VALIDATE' ? (
              <CheckOutlined
                style={{ fontSize: 16, color: 'black' }}
                onClick={() => handleChangeStatus(record)}
              />
            ) : (
              ''
            )}
            <Link
              to={`${
                mission
                  ? `/missions/missions-view/show/${
                      mission.type.includes('PCS')
                        ? 'PCS'
                        : mission.type.toLowerCase()
                    }/${mission?._id}/${mission?.type}`
                  : '/dashboard/stock-cerfa'
              }`}
            >
              <EyeOutlined style={{ fontSize: 16, color: 'black' }} />
            </Link>
          </Space>
        );
      }
    }
  ];
};
