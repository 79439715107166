import {
  BellOutlined,
  DownloadOutlined,
  EuroCircleOutlined,
  FileSyncOutlined,
  PrinterOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Button, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const { Text } = Typography;

/**
 * Renders the header buttons for invoices.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const HeaderButtons = ({ invoice }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Row
      gutter={[16, 16]}
      style={{
        justifyContent: 'end',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 0
      }}
    >
      <Space direction="horizontal">
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            color: '#ffffff'
          }}
          onClick={() => {
            navigate('/billing-and-accountings/invoices/add-payments', {
              state: { invoices: [invoice] }
            });
          }}
          icon={<EuroCircleOutlined />}
        >
          <Text style={{ color: 'white' }}>
            {t('invoices.buttons.add_payment')}
          </Text>
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            color: '#ffffff'
          }}
          icon={<BellOutlined />}
        >
          <Text style={{ color: 'white' }}>
            {t('invoices.buttons.remind_payment')}
          </Text>
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            color: '#ffffff'
          }}
          icon={<DownloadOutlined />}
        >
          <Text style={{ color: 'white' }}>
            {t('invoices.buttons.nomade_invoice')}
          </Text>
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            color: '#ffffff'
          }}
          icon={<DownloadOutlined />}
        >
          <Text style={{ color: 'white' }}>
            {t('invoices.buttons.syndic_invoice')}
          </Text>
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            color: '#ffffff'
          }}
          icon={<SendOutlined />}
        >
          <Text style={{ color: 'white' }}>{t('invoices.buttons.send')}</Text>
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            color: '#ffffff'
          }}
          icon={<PrinterOutlined />}
        >
          <Text style={{ color: 'white' }}>{t('invoices.buttons.print')}</Text>
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: '#222222',
            color: '#ffffff'
          }}
          icon={<FileSyncOutlined />}
        >
          <Text style={{ color: 'white' }}>{t('invoices.buttons.assets')}</Text>
        </Button>
      </Space>
    </Row>
  );
};

HeaderButtons.propTypes = {
  invoice: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
