import { Select } from 'antd';

const { Option } = Select;

/**
 * `useFields` is a custom React hook designed to generate configurations for form fields, specifically for selecting professionals such as lawyers, notaries, surveyors, and others in a collective ownership context. Each field configuration includes properties for the field name, label, input component, and other relevant settings.
 *
 * Parameters:
 * @param {Object} params - The parameters object.
 * @param {boolean} params.isFieldsLoading - Indicates if the professional data is currently being loaded, used to display a loading state in the Select inputs.
 * @param {Object} params.professionals - An object containing arrays of professional entities. Each key (e.g., 'lawyers', 'notaries') maps to an array of objects representing individual professionals.
 * @param {function} params.t - Translation function used for internationalization, allowing for dynamic translation of labels and other UI text.
 *
 * Returns:
 * @returns {Object} - An object containing an array of field configurations. Each configuration is an object that includes:
 * - `name`: An array representing the path to the value in the form state.
 * - `label`: The translated label for the field, used as the field's visible name.
 * - `translate`: A boolean indicating whether the label should be translated. This is included for flexibility but is not used in the current implementation.
 * - `input`: A React component for the input field. In this case, it's an Ant Design `Select` component configured for multiple selections and equipped with a search feature. The options for the `Select` component are dynamically generated based on the `professionals` parameter.
 *
 * Each `Select` component is configured with:
 * - `showSearch`: Enables a search input within the select dropdown to filter options.
 * - `mode`: Set to "multiple" allowing multiple selections.
 * - `optionFilterProp`: Specifies which prop value to filter on when searching.
 * - `filterOption`: A function that defines how options are filtered based on the search input.
 * - `loading`: Indicates if the select options are currently loading, based on `isFieldsLoading`.
 *
 * The options within each `Select` component are generated by mapping over the respective arrays in the `professionals` object, creating an `Option` for each professional with their full name as the display value and their `_id` as the value.
 *
 * Usage:
 * This hook is intended for use in form components where professionals need to be selected, particularly in scenarios involving collective ownerships. It simplifies the creation of form fields by abstracting the repetitive aspects of configuring `Select` inputs for multiple categories of professionals.
 */

export const useFields = ({ isFieldsLoading, professionals, t }) => {
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['lawyers'],
      label: t('collective-ownerships.form.collapse.modal.lawyers'),
      translate: false,
      input: (
        <Select
          showSearch
          mode="multiple"
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(professionals.lawyers || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.last_name || ''} ${item.first_name || ''}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['notaries'],
      label: t('collective-ownerships.form.collapse.modal.notaries'),
      translate: false,
      input: (
        <Select
          showSearch
          mode="multiple"
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(professionals.notaries || []).map((item) => (
            <Option value={item._id} key={item._id} manager={item}>
              {`${item.last_name || ''} ${item.first_name || ''}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['surveyors'],
      label: t('collective-ownerships.form.collapse.modal.surveyors'),
      translate: false,
      input: (
        <Select
          showSearch
          mode="multiple"
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(professionals.surveyors || []).map((item) => (
            <Option value={item._id} key={item._id} manager={item}>
              {`${item.last_name || ''} ${item.first_name || ''}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['others'],
      label: t('collective-ownerships.form.collapse.modal.others'),
      translate: false,
      input: (
        <Select
          showSearch
          mode="multiple"
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(professionals.others || []).map((item) => (
            <Option value={item._id} key={item._id} manager={item}>
              {`${item.last_name || ''} ${item.first_name || ''}`}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return { fields };
};
