import { message } from 'antd';
import { BaseModel } from './BaseModel';

/**
 * Represents an event.
 * @class
 */
export class Event extends BaseModel {
  static resourceName = 'events';

  /**
   * Get events.
   * @function
   * @param {Object} props - The component props.
   * @param {String} props.searchValue - The search value.
   * @param {Function} props.setEvents - The function to set the events.
   * @param {String} props.resourceName - The resource name.
   * @param {String} props.customRoute - The custom route.
   * @param {String} props.extraQuery - The extra query.
   * @param {String} props.populate - The populate.
   * @param {Object} props.user - The user.
   * @returns {Promise<Object>} The events.
   * @throws {Error} The error.
   */
  static async getResources({
    searchValue,
    setEvents,
    resourceName,
    customRoute,
    extraQuery,
    populate,
    user
  }) {
    try {
      const searchURL = searchValue ? `/search/${searchValue}` : null;
      let events;

      const customQuery =
        extraQuery ||
        `user=${user._id}&userTargetRole=${user.role}&populate=user,manager,syndic`;

      const { data } = await this.dispatchAPI('GET', {
        url: `${resourceName}${searchURL || ''}${
          customRoute ? `/${customRoute}` : ''
        }${customQuery ? `?${customQuery}` : ''}${
          populate ? `${customQuery ? `&` : `?populate=${populate}`}` : ''
        }`
      });

      let internalEvents;
      if (user.role === 'users:NOMADE_PRODUCTION' && !extraQuery) {
        internalEvents = await this.dispatchAPI('GET', {
          url: `/events?type=INTERN&populate=user,manager,syndic`
        });
        events = [...data, ...internalEvents.data].filter(
          (event, index, self) =>
            index === self.findIndex((e) => e._id === event._id)
        );
      } else {
        events = data;
      }

      setEvents(events);
    } catch (e) {
      message(e);
    }
  }

  /**
   * Get an event.
   * @function
   * @param {Object} props - The component props.
   * @param {String} props.id - The event ID.
   * @returns {Promise<Object>} The event.
   */
  static async getResource({ id }) {
    try {
      const { data } = await this.dispatchAPI('GET', {
        url: `/events/${id}?populate=user,manager,syndic`
      });

      return data;
    } catch (e) {
      message.error(e.message);
    }
    return null;
  }

  /**
   * Get managers.
   * @function
   * @param {String} syndic - The syndic ID.
   * @returns {Promise<Object>} The managers.
   * @throws {Error} The error.
   
   */
  static async getManagers(syndic) {
    try {
      const { data } = await this.dispatchAPI('GET', {
        url: `/syndics/${syndic}?populate=managers`
      });

      return data.managers;
    } catch (e) {
      message.error(e.message);
    }
    return null;
  }

  /**
   * Get enums for events.
   * @function
   * @param {Object} props - The component props.
   * @param {Function} props.dispatchAPI - The function to dispatch an API call.
   * @param {Function} props.setEnums - The function to set the enums.
   * @param {Object} props.message - The message object.
   * @returns {Promise<Object>} The enums.
   */
  static async getEventTypes() {
    try {
      const response = await this.dispatchAPI('GET', {
        url: '/events/enums'
      });
      return response.data;
    } catch (e) {
      message.error(e.message);
    }
    return null;
  }

  /**
   * Delete an event.
   * @function
   * @param {string} id - The event ID.
   * @returns {Promise<void>} The result.
   * @throws {Error} The error.
   */
  static async deleteResource(id) {
    try {
      await this.dispatchAPI('DELETE', { url: `/events/${id}` });
    } catch (e) {
      message.error(e.message);
    }
  }

  static async populateFields({ form, setSyndic }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `syndics?sort=-created_at&limit=1`
    });

    setTimeout(() => {
      form.setFieldsValue({ syndic: data[0]._id });
      setSyndic(data[0]._id);
    }, 300);
  }
}
