import PropTypes from 'prop-types';
import { Button, Col, message as Message, Modal, Row, Spin } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Renders the submit and save buttons component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.buttonsActive - The active buttons object.
 * @param {Function} props.onSaveAction - The save action function.
 * @param {Function} props.onSubmitAction - The submit action function.
 * @param {string} props.buttonTradKey - The button translation key.
 * @returns {JSX.Element} The submit and save buttons component.
 */
export const SubmitAndSaveButtons = ({
  form,
  buttonsActive,
  onSaveAction,
  onSubmitAction,
  buttonTradKey,
  isSubmitting,
  setIsSubmitting
}) => {
  const { t } = useTranslation();

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const values = await form.validateFields();
      await onSaveAction(values);
      await setIsSubmitting(true);
      return form.submit();
    } catch (error) {
      return Message.error(t('syndics.messages.required_fields'));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const values = await form.validateFields();
      await onSubmitAction(values);
      await setIsSubmitting(true);
      return form.submit();
    } catch (error) {
      return Message.error(t('syndics.messages.required_fields'));
    }
  };
  return (
    <>
      <Button
        type="add"
        disabled={!buttonsActive.saveDraft}
        onClick={handleSave}
      >
        {`${t('buttons.save_draft')} `}
      </Button>
      <Button
        disabled={!buttonsActive.submit}
        type="add"
        onClick={handleSubmit}
      >
        {t(`buttons.${buttonTradKey || 'save'}`)}
        <SendOutlined />
      </Button>
      <Modal
        open={isSubmitting}
        centered
        footer={null}
        closable={false}
        bodyStyle={{
          textAlign: 'center',
          padding: '40px 20px',
          borderRadius: '8px'
        }}
      >
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col>
            <>
              <p>{t('global.saving')}</p>
              <Spin size="large" />
            </>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

SubmitAndSaveButtons.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  onSaveAction: PropTypes.func,
  buttonsActive: PropTypes.shape({
    saveDraft: PropTypes.bool,
    submit: PropTypes.bool
  }),
  onSubmitAction: PropTypes.func,
  buttonTradKey: PropTypes.string,
  isSubmitting: PropTypes.bool,
  setIsSubmitting: PropTypes.func
};

SubmitAndSaveButtons.defaultProps = {
  buttonsActive: {
    next: true,
    back: true,
    saveDraft: true,
    submit: true
  },
  onSubmitAction: () => {},
  onSaveAction: () => {},
  buttonTradKey: 'save',
  isSubmitting: false,
  setIsSubmitting: () => {}
};
