import { useState } from 'react';
import dayjs from 'dayjs';
import { Form, Select, DatePicker, Dropdown, Button } from 'antd';
import { FilePdfFilled, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { ExportButton } from '../../../components';
import { headers } from './headers';
import { useFormatter } from '../../../utils/formatter';
import { generateDocumentsOld } from '../../../utils/generateDocumentOld';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ExportModal } from '../../../components/ExportModal/ExportModal';

const { Option } = Select;
const { MonthPicker } = DatePicker;

/**
 * Renders extra buttons for filtering and selecting options.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Function} props.setForceRefresh - The function to force refresh.
 * @param {string} props.type - The type of buttons to render.
 * @param {string} props.extraQuery - The extra query to add to the request.
 * @param {Object} props.selectedRowKeys - The selected row keys.
 * @param {Object} props.resourceData - The resource data.
 * @returns {JSX.Element} - The rendered component.
 */
export const ExtraButtons = ({
  setFilters,
  setForceRefresh,
  type,
  extraQuery,
  selectedRowKeys,
  resourceData
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter();
  const [visible, setVisible] = useState(false);
  const { syndics, collectiveOwnerships, isDataLoading } = useGlobalContext();
  const [form] = Form.useForm();
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const menu = {
    items: [
      ...(type === 'cheques'
        ? [
            {
              key: 'export_cheques',
              label: (
                <ExportButton
                  dataName="accountant-exports"
                  headers={headers}
                  url={`invoices/accountant-exports/${type}`}
                  fileName="export.csv"
                  extraQuery={`${
                    extraQuery ? `${extraQuery}&` : ''
                  }csv_export=true`}
                  formatter={formattedData}
                  selectedRowKeys={selectedRowKeys}
                  buttonLabel={t('buttons.accounting_export')}
                />
              )
            }
          ]
        : [
            {
              key: 'export_disbursement_and_costs',
              label: (
                <>
                  <FilePdfFilled
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                  <Button
                    type="link"
                    onClick={() =>
                      generateDocumentsOld({
                        dispatchAPI,
                        message,
                        documentType:
                          type === 'disbursements'
                            ? 'DISBURSEMENTS_EXPORT'
                            : 'COSTS_EXPORT',
                        values: resourceData,
                        setModalVisible: setVisible
                      })
                    }
                  >
                    {t('buttons.export_pdf')}
                  </Button>
                </>
              )
            }
          ])
    ]
  };

  return (
    <>
      <ExportModal visible={visible} />
      <Form layout="inline" form={form}>
        <Form.Item name={['date']}>
          <MonthPicker
            format="MM/YYYY"
            onChange={(date) => {
              if (date) {
                setFilters((prev) => ({
                  ...prev,
                  'date>=': {
                    value: dayjs(date).startOf('month').toISOString(),
                    no_operator: true
                  },
                  'date<=': {
                    value: dayjs(date).endOf('month').toISOString(),
                    no_operator: true
                  }
                }));
              } else {
                setFilters((prev) => ({
                  ...prev,
                  'date>=': '',
                  'date<=': ''
                }));
              }
            }}
            allowClear
            style={{ minWidth: 200 }}
          />
        </Form.Item>
        {type === 'cheques' ? (
          <>
            <Form.Item name={['syndic']}>
              <Select
                style={{ minWidth: 200 }}
                onChange={(value) => {
                  setFilters((prev) => ({
                    ...prev,
                    'syndic._id': value || ''
                  }));
                }}
                onClear={() => {
                  setFilters((prev) => ({
                    ...prev,
                    'syndic._id': ''
                  }));
                  setForceRefresh((prev) => !prev);
                }}
                loading={isDataLoading}
                showSearch
                allowClear
                filterOption={filterOption}
                placeholder={t('placeholder.search_syndic')}
              >
                {(syndics || []).map((syndic) => (
                  <Option key={syndic._id} value={syndic._id}>
                    {`${syndic?.society_name} (${syndic?.code})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['collective-ownership']}>
              <Select
                style={{ minWidth: 200 }}
                onChange={(value) => {
                  setFilters((prev) => ({
                    ...prev,
                    'collective_ownership._id': value || ''
                  }));
                }}
                onClear={() => {
                  setFilters((prev) => ({
                    ...prev,
                    'collective_ownership._id': ''
                  }));
                }}
                loading={isDataLoading}
                showSearch
                allowClear
                filterOption={filterOption}
                placeholder={t('placeholder.search_collective_ownership')}
              >
                {(collectiveOwnerships || []).map((collective_ownership) => (
                  <Option
                    key={collective_ownership._id}
                    value={collective_ownership._id}
                  >
                    {`${collective_ownership?.code || ''} - ${
                      collective_ownership?.name || ''
                    } - ${collective_ownership?.first_address?.street || ''} ${
                      collective_ownership?.first_address?.postal_code || ''
                    }`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : null}
        <Dropdown menu={menu}>
          <Button type="link">
            <MenuOutlined style={{ fontSize: 16, color: 'var(--textColor)' }} />
          </Button>
        </Dropdown>
      </Form>
    </>
  );
};

ExtraButtons.propTypes = {
  setFilters: PropTypes.func.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  extraQuery: PropTypes.string,
  selectedRowKeys: PropTypes.shape({
    key: PropTypes.string,
    rowKeys: PropTypes.arrayOf(PropTypes.string)
  }),
  resourceData: PropTypes.shape({})
};

ExtraButtons.defaultProps = {
  extraQuery: null,
  selectedRowKeys: {},
  resourceData: {}
};
