import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Col, notification, Row, Spin, Steps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useMissionContext } from '../../../../contexts/MissionContext';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { showTitle } from './utils/showTitle';
import { AlertCard } from './components/AlertCard';
import { DocumentCard } from './components/DocumentCard';
import { MessageContextProvider } from '../../../../contexts/MessageContext';
import { Messages } from '../../../../components/Messages/Messages';
import { InformationsCard } from './components/InformationsCard';
import { InvoiceCard } from './components/InvoiceCard';
import { AddDocumentCard } from './components/AddDocumentCard';
import { CerfaOrderDrawer } from './drawers/CerfaOrderDrawer';
import { MissionMonitoringCard } from './components/MissionMonitoringCard';
import { auditOneSteps } from './steps/auditOneSteps';
import { OrdersCard } from './components/OrdersCard';
import { DisbursementModal } from './modals/DisbursementModal';
import { AlertDrawer } from './drawers/AlertDrawer';
import { NoteDrawer } from './drawers/NoteDrawer';
import { BlockMissionCreditNote } from './modals/BlockMissionCreditNoteModal';
import { CancelMissionModal } from './modals/CancelMissionModal';
import { confirmRefundsButtons } from './utils/confirmRefundsButtons';
import { ConfirmRefundsModal } from './modals/ConfirmRefundsModal';

/**
 * Renders the ShowAuditOneMission component.
 *
 * @component
 * @returns {JSX.Element} The rendered ShowAuditOneMission component.
 */
export const ShowAuditOneMission = () => {
  const { id, type } = useParams();
  const { t } = useTranslation();
  const { principalItems } = auditOneSteps();
  const [isLoading, setIsLoading] = useState(true);
  const {
    getMissionDatas,
    current,
    mission,
    forceRefresh,
    addDocumentCardDatas,
    isDrawerOpen,
    isOrderCardUp,
    isShowToBeOrderedCard,
    setIsDrawerOpen
  } = useMissionContext();

  const items = {
    AUDIT_1: { principal: principalItems }
  };

  useEffect(() => {
    (async () => {
      await getMissionDatas({
        id,
        type,
        message: notification,
        t
      });
    })();
  }, [forceRefresh]);

  useEffect(() => {
    if (mission.type) {
      setIsLoading(false);
    }
  }, [mission]);

  return (
    <>
      <PageHeaderCustom title={showTitle({ record: mission })} />
      <Row justify="end" gutter={[5, 5]} style={{ marginRight: 15 }}>
        {confirmRefundsButtons({ mission, setIsDrawerOpen })}
        <Col>
          <Button type="link" icon={<MenuOutlined />} />
        </Col>
      </Row>
      <ContentCustom>
        <Steps
          current={current.principal}
          items={items[type].principal}
          style={{ marginBottom: 24 }}
        />
        <Row gutter={[16, 16]}>
          <AlertCard />
          <Col span={addDocumentCardDatas.open ? 14 : 24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                {isShowToBeOrderedCard ? (
                  <OrdersCard status="TO_BE_ORDERED" />
                ) : null}
                {isOrderCardUp ? <OrdersCard status="ORDERED" /> : null}
              </Col>
              <Col span={24}>
                <MissionMonitoringCard
                  mission={mission}
                  isLoading={isLoading}
                />
              </Col>
              <Col span={addDocumentCardDatas.open ? 24 : 16}>
                <Row>
                  <Col span={24}>
                    <DocumentCard />
                  </Col>
                </Row>
              </Col>
              <Col span={addDocumentCardDatas.open ? 24 : 8}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <MessageContextProvider id={id} model="Mission">
                      <Messages missionType="AUDIT_1" withCreateButton />
                    </MessageContextProvider>
                  </Col>
                  {mission?.pcs_pre_production ? (
                    <Col span={24}>
                      <MessageContextProvider
                        id={mission?.pcs_pre_production}
                        model="Pcs-pre-production"
                      >
                        <Messages
                          cardTitle={t(
                            'missions.cards.titles.pcs_pre_production_messages'
                          )}
                          withoutDefaultActiveKey
                          withCreateButton={false}
                        />
                      </MessageContextProvider>
                    </Col>
                  ) : null}
                  <Col span={24}>
                    <InformationsCard />
                  </Col>
                </Row>
              </Col>
              {Object.keys(mission).length ? (
                <Col span={24}>
                  <InvoiceCard type={mission.type} />
                </Col>
              ) : null}
              {current.principal > 1 && !isOrderCardUp ? (
                <Col span={24}>
                  <OrdersCard status="ORDERED" />
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col
            className="add-cerfa-docs-card"
            span={10}
            style={{ display: addDocumentCardDatas.open ? 'block' : 'none' }}
          >
            <AddDocumentCard />
          </Col>
        </Row>
      </ContentCustom>
      <Spin
        spinning={isLoading}
        fullscreen
        tip={t('missions.messages.loading')}
      />
      {isDrawerOpen?.cerfa_order?.open ? <CerfaOrderDrawer /> : null}
      {isDrawerOpen.disbursement ? <DisbursementModal /> : null}
      {isDrawerOpen.alert ? <AlertDrawer /> : null}
      {isDrawerOpen.note ? <NoteDrawer /> : null}
      {isDrawerOpen.block_mission_credit_note ? (
        <BlockMissionCreditNote />
      ) : null}
      {isDrawerOpen.cancel_mission ? <CancelMissionModal /> : null}
      {isDrawerOpen.confirm_refunds ? <ConfirmRefundsModal /> : null}
    </>
  );
};
