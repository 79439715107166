import {
  Select,
  Form,
  Input,
  Typography,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Button
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { handlePaymentChange } from '../utils/handlePaymentChange';

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

/**
 * Custom hook that returns an array of fields for adding payments in invoices.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Object[]} options.enums - The enums object containing payment types.
 * @param {Object[]} options.bankAccounts - The array of bank accounts.
 * @param {boolean} options.isFieldsLoading - Flag indicating if the fields are loading.
 * @returns {Object} - The fields array.
 */
export const useFields = ({
  enums,
  bankAccounts,
  isFieldsLoading,
  form,
  invoice
}) => {
  const { t } = useTranslation();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      noLabel: true,
      name: ['payments'],
      input: (
        <Form.List name="payments">
          {(listFields, { add, remove }) => {
            if (listFields.length < 1) add();
            return (
              <>
                {listFields.map(({ key, name, ...restField }, index) => (
                  <Row gutter={[16, 16]} key={key}>
                    <Col span={24}>
                      <Row gutter={[16, 16]} justify="space_between">
                        <Col span={16}>
                          <Title level={5}>
                            {t('payments.form.title', { index: index + 1 })}
                          </Title>
                        </Col>
                        {index > 0 ? (
                          <Col span={2}>
                            <Button type="link" onClick={() => remove(name)}>
                              <DeleteOutlined />
                            </Button>
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'date']}
                        label={t('payments.form.date')}
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'total_ht']}
                        label={t('payments.form.total_ht')}
                      >
                        <InputNumber
                          addonAfter="€"
                          onChange={(element) =>
                            handlePaymentChange({
                              invoice,
                              form,
                              index: name,
                              element,
                              fieldName: 'total_ht'
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'tva']}
                        label={t('payments.form.tva')}
                      >
                        <InputNumber addonAfter="€" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'disbursement']}
                        label={t('payments.form.disbursement')}
                      >
                        <InputNumber
                          addonAfter="€"
                          onChange={(element) =>
                            handlePaymentChange({
                              invoice,
                              form,
                              index: name,
                              element,
                              fieldName: 'disbursement'
                            })
                          }
                          disabled={
                            !invoice?.disbursement ||
                            invoice?.disbursement === 0
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'lawyer_fee_ht']}
                        label={t('payments.form.lawyer_fee_ht')}
                      >
                        <InputNumber
                          addonAfter="€"
                          onChange={(element) =>
                            handlePaymentChange({
                              invoice,
                              form,
                              index: name,
                              element,
                              fieldName: 'lawyer_fee_ht'
                            })
                          }
                          disabled={
                            !invoice?.lawyer_fee_ht ||
                            invoice?.lawyer_fee_ht === 0
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'amount']}
                        label={t('payments.form.amount')}
                        rules={[{ required: true }]}
                      >
                        <InputNumber addonAfter="€" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'bank_account']}
                        label={t('payments.form.bank_account')}
                        rules={[{ required: true }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          loading={isFieldsLoading}
                          filterOption={filterOption}
                        >
                          {(bankAccounts || []).map((el) => (
                            <Option key={el._id} value={el.bank_account}>
                              {`${el.account || ''} - ${el.bank_account || ''}`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'type']}
                        label={t('payments.form.type')}
                        rules={[{ required: true }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          loading={isFieldsLoading}
                          filterOption={filterOption}
                        >
                          {(enums?.types || []).map((type) => (
                            <Option key={type} value={type}>
                              {t(`payments.tags.${type}`)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'comment']}
                        label={t('payments.form.comment')}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Col span={24}>
                  <Button type="primary" onClick={() => add()}>
                    {t('payments.buttons.add_payment')}
                  </Button>
                </Col>
              </>
            );
          }}
        </Form.List>
      )
    }
  ];

  return { fields };
};
