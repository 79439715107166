import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { useItems } from './useItems';

/**
 * Renders the RequestedDocuments component.
 *
 * @component
 * @returns {JSX.Element} The rendered RequestedDocuments component.
 */
export const RequestedDocuments = () => {
  const { t } = useTranslation();
  const { items } = useItems({});

  return (
    <>
      <PageHeaderCustom title={t('requested-documents.title')} />
      <ContentCustom>
        <Tabs items={items} />
      </ContentCustom>
    </>
  );
};
