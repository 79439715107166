import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Custom hook that returns a configuration object for creating a resource.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {string} options.editorState - The editor state.
 * @param {Object} options.form - The form object.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({
  editorState,
  form,
  missionType,
  syndic,
  collective_ownership
}) => {
  const { messageDateWhoReceiveResponse } = useGlobalContext();
  const { user } = useAuthContext();

  return {
    onCreateResource: {
      setBody: (body) => {
        const updatedBody = {
          mission: missionType,
          syndic: syndic || null,
          collective_ownership: collective_ownership || null,
          ...body,
          author: user._id,
          recipients: body.recipients.filter((r) => r !== 'nomade_intern_note'),
          intern_message: !!body.recipients.includes('nomade_intern_note'),
          content: editorState,
          message_date_who_receive_response: messageDateWhoReceiveResponse
        };
        form.resetFields();
        return updatedBody;
      }
    }
  };
};
