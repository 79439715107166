import { Divider, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  DownloadOutlined,
  EuroOutlined,
  EyeOutlined,
  MailOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { LicenseDraft } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import { invoiceTag } from '../../../../utils/constants/tagColors';

/**
 * Returns an array of columns configuration for the invoices expandable list.
 *
 * @hook
 * @returns {Array} The array of columns configuration.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.form.reference'),
      dataIndex: 'reference',
      key: 'reference',
      render: (reference) => reference || '-',
      sorter: true
    },
    {
      title: t('invoices.form.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => (type ? t(`invoices.tags.${type}`) : ''),
      sorter: true
    },
    {
      title: t('invoices.form.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status ? (
          <Tag color={invoiceTag[status]}>
            {t(`invoices.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          ''
        ),
      sorter: true
    },
    {
      title: t('invoices.form.last_reminder'),
      dataIndex: 'last_reminder',
      key: 'last_reminder',
      render: (last_reminder) =>
        last_reminder ? dayjs(last_reminder).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('invoices.form.sending_date'),
      dataIndex: 'sending_date',
      key: 'sending_date',
      render: (sending_date) =>
        sending_date ? dayjs(sending_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('invoices.form.settlement_date'),
      dataIndex: 'settlement_date',
      key: 'settlement_date',
      render: (settlement_date) =>
        settlement_date ? dayjs(settlement_date).format('DD-MM-YYYY') : '-',
      sorter: true
    },
    {
      title: t('invoices.form.total_ht'),
      dataIndex: 'total_ht',
      key: 'total_ht',
      render: (total_ht) =>
        total_ht ? `${parseFloat(total_ht).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      title: t('invoices.form.tva'),
      dataIndex: 'tva',
      key: 'tva',
      render: (tva) => (tva ? `${parseFloat(tva).toFixed(2)} €` : '0.00 €'),
      sorter: true
    },
    {
      title: t('invoices.form.disbursement'),
      dataIndex: 'disbursement',
      key: 'disbursement',
      render: (disbursement) =>
        disbursement ? `${parseFloat(disbursement).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      title: t('invoices.form.total_ttc'),
      dataIndex: 'total_ttc',
      key: 'total_ttc',
      render: (total_ttc) =>
        total_ttc ? `${parseFloat(total_ttc).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      title: t('invoices.form.paid'),
      dataIndex: 'paid',
      key: 'paid',
      render: (paid) => (paid ? `${parseFloat(paid).toFixed(2)} €` : '0.00 €'),
      sorter: true
    },
    {
      title: t('invoices.form.left_to_pay'),
      dataIndex: 'left_to_pay',
      key: 'left_to_pay',
      render: (left_to_pay) =>
        left_to_pay ? `${parseFloat(left_to_pay).toFixed(2)} €` : '0.00 €',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) =>
        record?.status && (
          <>
            <Link
              to={{
                pathname: `/billing-and-accountings/invoices/show/${record._id}`
              }}
            >
              <EyeOutlined style={{ fontSize: 16 }} />
            </Link>

            <Divider type="vertical" />
            <DownloadOutlined style={{ fontSize: 16 }} />

            {record?.type !== 'CREDIT_NOTE' ? (
              <>
                <Divider type="vertical" />
                <Link
                  to="/billing-and-accountings/invoices/create"
                  state={{
                    type: 'CREDIT_NOTE',
                    mission: record?.mission,
                    syndic_id: record?.mission?.syndic?._id,
                    collective_ownership_id:
                      record?.mission?.collective_ownership?._id,
                    tva_percentage: record?.tva_percentage,
                    lawyer_fee_ht: record?.lawyer_fee_ht,
                    invoice: record
                  }}
                >
                  <LicenseDraft style={{ fontSize: 16, color: 'black' }} />
                </Link>
              </>
            ) : null}

            <Divider type="vertical" />
            <Link
              to="/billing-and-accountings/invoices/add-payments"
              state={[record]}
            >
              <EuroOutlined style={{ fontSize: 16 }} />
            </Link>

            <Divider type="vertical" />
            <MailOutlined style={{ fontSize: 16 }} />

            <Divider type="vertical" />
            <PrinterOutlined style={{ fontSize: 16 }} />
          </>
        )
    }
  ];
};
