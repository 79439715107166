import { BaseModel } from './BaseModel';

/**
 * Represents notes.
 * @class
 */
export class Note extends BaseModel {
  static resourceName = 'notes';

  /**
   * Retrieves a resource by its ID.
   *
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource.
   * @param {string} options.id - The ID of the resource to retrieve.
   * @param {string} [options.populate=''] - The fields to populate in the retrieved resource.
   * @param {string} [options.extraQuery=''] - Additional query parameters for the request.
   * @returns {Promise<Object>} A promise that resolves to the retrieved resource.
   */
  static async getResource({ id, populate = '', extraQuery = '' }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Note.resourceName}/${id}?populate=${populate}&${extraQuery}`
    });
    return data;
  }

  /**
   * Get the resources.
   * @function
   * @async
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @param {string} [projection=''] - The projection.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({
    populate = '',
    extraQuery = '',
    projection = ''
  }) {
    const { data } = await this.dispatchAPI('GET', {
      url: `${Note.resourceName}?populate=${populate}&${extraQuery}&fields=${projection}`
    });
    return data;
  }

  /**
   * Deletes a resource by its ID.
   *
   * @param {Object} options - The options for deleting the resource.
   * @param {string} options.id - The ID of the resource to delete.
   * @param {function} options.setForceRefresh - A function to trigger a force refresh after deleting the resource.
   * @returns {Promise<Object>} A promise that resolves to the deleted resource data.
   */
  static async deleteResource({ id, setForceRefresh }) {
    const { data } = await this.dispatchAPI('DELETE', {
      url: `${Note.resourceName}/${id}`
    });

    if (setForceRefresh) setForceRefresh((prev) => !prev);

    return data;
  }
}
