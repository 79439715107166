import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Handles the addition of label and date to the charging grid.
 * @function
 * @param {Object} params - Function parameters
 * @param {Object} params.body - The data body containing label and date
 * @param {Function} params.dispatchAPI - Function for dispatching API requests
 * @param {Object} params.chargingGrid - The charging grid object
 * @param {Function} params.setChargingGrid - Function for setting charging grid data
 * @param {Function} params.setIsLabelModalOpen - Function for setting label modal state
 * @param {Function} params.message - Function for displaying messages
 * @returns {void}
 */

export const handleAddLabelAndDate = async ({
  body,
  dispatchAPI,
  chargingGrid,
  setChargingGrid,
  setIsLabelModalOpen,
  message,
  resourceDatas,
  setResourceDatas
}) => {
  try {
    const formData = new FormData();

    formData.append(
      'values',
      JSON.stringify({
        ...body
      })
    );

    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${chargingGrid._id}?type=add_label_and_date`,
      body: formData
    });
    updateChargingGrids({
      resourceDatas,
      chargingGrid: data,
      setResourceDatas
    });
    setChargingGrid(data);
    setIsLabelModalOpen(false);
  } catch (e) {
    message(e);
  }
};
