import { Mission } from '../models/Mission';

/**
 * Updates the show mission notifications based on the provided mission data.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {Object} options.missionDatas - The mission data object.
 * @param {Function} options.t - The translation function.
 * @param {string} options.message - The message to be sent.
 * @param {Object} options.user - The user object.
 * @returns {void}
 */
export const updateShowMissionNotifications = ({
  missionDatas,
  t,
  message,
  user
}) => {
  if (
    ['admins:SUPER-ADMIN', 'admins:ADMIN', 'users:NOMADE_PRODUCTION'].includes(
      user?.role
    )
  ) {
    if (missionDatas.show_messages.advance_payment) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            advance_payment: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.advance_payment_paid_title'),
          description: t('missions.messages.advance_payment_paid_content'),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.cerfa_order) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: { ...missionDatas.show_messages, cerfa_order: false }
        },
        message,
        messageContent: {
          message: t('missions.messages.cerfa_order_title'),
          description: t('missions.messages.cerfa_order_content'),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas?.show_messages?.order_waiting?.show) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            order_waiting: { show: false, document_wordings: [] }
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.order_waiting_title'),
          description: t('missions.messages.order_waiting_content', {
            wordings: missionDatas.show_messages.order_waiting.document_wordings
              .map((wording) => wording)
              .join(', ')
          }),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.balance) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            balance: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.balance_paid_title'),
          description: t('missions.messages.balance_paid_content'),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.random_lawyer_transmission?.status) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            random_lawyer_transmission: {
              status: false,
              first_name: '',
              last_name: ''
            }
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.random_lawyer_transmission_title'),
          description: t(
            'missions.messages.random_lawyer_transmission_content',
            {
              first_name:
                missionDatas?.show_messages?.random_lawyer_transmission
                  ?.first_name,
              last_name:
                missionDatas?.show_messages?.random_lawyer_transmission
                  ?.last_name
            }
          ),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.default_lawyer_transmission) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            default_lawyer_transmission: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.default_lawyer_transmission_title'),
          description: t(
            'missions.messages.default_lawyer_transmission_content'
          ),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.pcs_stock) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            pcs_stock: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.pcs_stock_title'),
          description: t('missions.messages.pcs_stock_content'),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.AG_decision) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            AG_decision: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.AG_decision_title'),
          description: t('missions.messages.AG_decision_content'),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.add_documents_to_be_re_written) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            add_documents_to_be_re_written: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.add_documents_to_be_re_written_title'),
          description: t(
            'missions.messages.add_documents_to_be_re_written_content'
          ),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas?.show_messages?.add_intermediate_project?.production) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            add_intermediate_project: {
              ...missionDatas.show_messages.add_intermediate_project,
              production: false
            }
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.add_intermediate_project_title'),
          description: t('missions.messages.add_intermediate_project_content'),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.definitive_project_submitted) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            definitive_project_submitted: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.definitive_project_submitted_title'),
          description: t(
            'missions.messages.definitive_project_submitted_content'
          ),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.balance_invoice_pcs_3_generated) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            balance_invoice_pcs_3_generated: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.balance_invoice_pcs_3_generated_title'),
          description: t(
            'missions.messages.balance_invoice_pcs_3_generated_content'
          ),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas.show_messages.balance_invoice_pcs_3_paid) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            balance_invoice_pcs_3_paid: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.balance_invoice_pcs_3_paid_title'),
          description: t(
            'missions.messages.balance_invoice_pcs_3_paid_content'
          ),
          placement: 'topRight'
        }
      });
    }
  }

  if (['users:LAWYER'].includes(user.role)) {
    if (missionDatas.show_messages.received_documents_re_written) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            received_documents_re_written: false
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.received_documents_re_written_title'),
          description: t(
            'missions.messages.received_documents_re_written_content'
          ),
          placement: 'topRight'
        }
      });
    }

    if (missionDatas?.show_messages?.add_intermediate_project?.lawyer) {
      Mission.patchResource({
        id: missionDatas?._id,
        values: {
          show_messages: {
            ...missionDatas.show_messages,
            add_intermediate_project: {
              ...missionDatas.show_messages.add_intermediate_project,
              lawyer: false
            }
          }
        },
        message,
        messageContent: {
          message: t('missions.messages.add_intermediate_project_title'),
          description: t('missions.messages.add_intermediate_project_content'),
          placement: 'topRight'
        }
      });
    }
  }
};
