import { Input } from 'antd';

const { TextArea } = Input;

/**
 * Custom hook that returns an array of fields for the block mission.
 *
 * @hook
 * @returns {Object} An object containing the fields array.
 */
export const useFields = () => {
  const fields = [
    {
      key: 'block_mission_comment',
      name: ['block_mission_comment'],
      input: <TextArea />
    }
  ];

  return { fields };
};
