import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  outOfNavRoutes,
  routes,
  otherRoutes
} from '../utils/constants/routes/syndicManagerRoutes';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { RequireAuth } from './RequireAuth';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { CollectiveOwnershipSyndicList } from '../routes/syndic-view/collective-ownership-syndic/CollectiveOwnershipSyndicList';
import { FacturesSyndicList } from '../routes/syndic-view/factures-syndic/FacturesSyndicList';
import { HomeSyndic } from '../routes/syndic-view/home-syndic/HomeSyndic';
import { RcpResearchInfo } from '../routes/syndic-view/rcp-research/RcpResearchInfo';
import { ServicesRouter } from '../routes/syndic-view/services/ServicesRouter';
import { useGlobalContext } from '../contexts/GlobalContext';
import { LoadingAndRefreshModal } from '../components/LoadingAndRefreshModal';
import { ServicesDraftsList } from '../routes/syndic-view/services/components/ServicesDrafts/ServicesDraftsList';
import { ServicesPendingValidationList } from '../routes/syndic-view/services/components/ServicesPendingValidation/ServicesPendingValidationList';
import { ClientWalletRouter } from '../routes/syndic-view/client-wallet/ClientWalletRouter';
import { CreateUpdateCollectiveOwnershipForm } from '../routes/monitoring/collective-ownerships-forms/pcs-informations/CreateUpdateCollectiveOwnershipForm';
import { ContactSectionSyndic } from '../routes/syndic-view/contact-section/ContactSectionSyndic';
import { UserRouter } from '../routes/users/UserRouter';
import { FaqSection } from '../routes/syndic-view/faq-section/FaqSection';

/**
 * @component
 * Renders the Syndic Manager Router component.
 * This component handles the routing for the Syndic Manager section of the application.
 *
 * @returns {JSX.Element} The Syndic Manager Router component.
 */
export const SyndicManagerRouter = () => {
  const { isValid } = useAuthContext();
  const { currentSyndic } = useGlobalContext();
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  if (!currentSyndic) {
    return <LoadingAndRefreshModal />;
  }
  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route
          path={outOfNavRoutes.PCSFORM}
          element={<CreateUpdateCollectiveOwnershipForm purpose="create" />}
        />

        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.USERS}/*`}
            element={
              <UserRouter
                restricAccess={{
                  userList: true
                }}
              />
            }
          />
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route
            path={`${otherRoutes.SERVICES}/*`}
            element={<ServicesRouter />}
          />
          <Route
            path={routes.SYNDIC_INVOICES}
            element={<FacturesSyndicList />}
          />
          <Route
            path={`${routes.CLIENT_WALLET}/*`}
            element={<ClientWalletRouter />}
          />
          <Route
            path={`${routes.SYNDIC_COLLECTIVE_OWNERSHIPS}/*`}
            element={<CollectiveOwnershipSyndicList />}
          />
          <Route path="rcp-research" element={<RcpResearchInfo />} />
          <Route
            path={routes.DRAFTS}
            element={
              <ServicesDraftsList
                requested_mission_type={[
                  'PCS_2',
                  'PCS_3',
                  'PLANS_RESEARCH',
                  'RCP_RESEARCH',
                  'PCS_PRE_PRODUCTION'
                ]}
              />
            }
          />
          <Route
            path={routes.PENDING_VALIDATION}
            element={
              <ServicesPendingValidationList
                requested_mission_type={[
                  'PCS_2',
                  'PCS_3',
                  'PLANS_RESEARCH',
                  'RCP_RESEARCH',
                  'PCS_PRE_PRODUCTION'
                ]}
              />
            }
          />
          <Route path="*" element={<Exception />} />
          <Route path={routes.CONTACT} element={<ContactSectionSyndic />} />
          <Route path={routes.FAQ} element={<FaqSection />} />
          <Route path={routes.HOME} element={<HomeSyndic />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
