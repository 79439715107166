import dayjs from 'dayjs';

/**
 * Custom hook that returns a configuration object for handling resource updates.
 * @hook
 *
 * @param {Object} options - The options for the configuration.
 * @param {Function} options.setPermissions - The function to set the permissions.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({ setPermissions }) => ({
  onGetResource: {
    setFields: (data) => {
      setPermissions(data?.permissions);
      const newData = {
        ...data,
        plans: data?.plans?.map((plan) => ({
          ...plan,
          realization_date:
            plan.realization_date && dayjs(plan.realization_date),
          transcription_date:
            plan.transcription_date && dayjs(plan.transcription_date)
        })),
        realization_date:
          data?.realization_date && dayjs(data.realization_date),
        transcription_date:
          data?.transcription_date && dayjs(data.transcription_date)
      };
      return newData;
    }
  }
});
