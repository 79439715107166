import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  homeSyndicViewSubtitle,
  homeSyndicViewTitle
} from '../../utils/styleConstants';
import homeTitleImage from '../../../../assets/images/Accueil 1.svg';

/**
 * Generates the title, subtitle, card style, and home title image for the home syndic view.
 * @returns {Object} An object containing the title, subtitle, card style, and home title image.
 */
export const homeTitle = () => {
  const { t } = useTranslation();

  const title = (
    <Row>
      <h1 style={homeSyndicViewTitle}>{t('syndic-home.titlteCard.title')}</h1>
    </Row>
  );

  const subtitle = (
    <p style={homeSyndicViewSubtitle}>{t('syndic-home.titlteCard.subtitle')}</p>
  );

  const cardStyle = {
    backgroundColor: '#ffffff',
    width: '97.5%'
  };

  return { title, subtitle, cardStyle, homeTitleImage };
};
