import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag, Divider, Button, Popconfirm } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  ForkOutlined,
  WarningOutlined,
  StopOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { accessTag, missionTag } from '../../utils/constants/tagColors';
import { blockCollectiveOwnership } from './utils/blockCollectiveOwnership';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * The `useColumns` hook is designed to generate column definitions for a table component,
 * focusing on displaying various attributes of collective ownership entities and their associated data, such as syndic details,
 * manager information, collective ownership descriptions, missions associated with the ownership, and the last manager connection status.
 * The hook incorporates internationalization for column titles and mission tags, enhancing usability across different locales.
 *
 * @hook
 * @param {Object} params - The parameters object.
 * @param {Object} [params.enums] - An object containing enumeration values, which might include types or categories used within the missions column to filter or categorize missions.
 * @param {Function} [params.setIsReassignModalOpen] - A function to set the state of the reassign modal.
 * @param {Function} [params.setSelectedCollectiveOwnerships] - A function to set the state of the selected collective ownerships.
 * @param {Function} [params.dispatchAPI] - A function to dispatch an API request.
 * @param {Function} [params.setForceRefresh] - A function to set the state of the force refresh.
 * @param {boolean} [params.forceRefresh] - The current value of the force refresh.
 * @param {Function} [params.message] - A function to display a message.
 *
 * Returns:
 * An array of column definitions for use in an Ant Design `Table` component. Each column definition includes properties such as:
 * - `title`: The localized title of the column, serving as the header.
 * - `key`: A unique key for the column, used internally by the table for tracking and sorting purposes.
 * - `dataIndex`: The path to the data value for the column in the data source, used to fetch the appropriate value for each cell.
 * - `render`: A function that returns the React elements to be rendered in each cell of the column. This can include formatted strings or React components like `Tag` for visually encoding data.
 * - `sorter`: A boolean or function indicating whether the column is sortable.
 * - `filters`: An array of filter options for the column, applicable to columns like `missions`, which may categorize data based on mission types or other enumerable attributes.
 *
 * The hook leverages the `useTranslation` hook from `react-i18next` to localize column headers and mission tags, ensuring that the table is accessible and understandable in different languages.
 * It utilizes Ant Design's `Tag` component to visually represent mission types with color coding, based on predefined status colors mapped in `accessTag`.
 *
 * Usage:
 * This hook is intended for use in components where a table displaying a list of collective ownership entities is required. It allows for easy integration of sorting, filtering, formatting, and internationalization within the table.
 */

export const useColumns = ({
  enums,
  setIsReassignModalOpen,
  setSelectedCollectiveOwnerships,
  setForceRefresh,
  forceRefresh,
  message
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const permissions = {
    edit: !['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(user.role),
    block: ![
      'users:NOMADE_CUSTOMER_SERVICE_MANAGER',
      'users:NOMADE_ASSISTANT'
    ].includes(user.role)
  };

  return [
    {
      title: t('collective-ownerships.form.syndic'),
      key: 'syndic',
      dataIndex: 'syndic',
      render: (syndic) =>
        `${syndic?.society_name || ''} ${
          syndic?.code ? `(${syndic.code})` : ''
        }`,
      sorter: true
    },
    {
      title: t('collective-ownerships.form.manager'),
      key: 'manager',
      dataIndex: 'manager',
      render: (manager) =>
        `${manager?.last_name || ''} ${manager?.first_name || ''}`,
      sorter: true
    },
    {
      title: t('collective-ownerships.form.collective_ownership'),
      key: 'collective_ownership',
      render: (record) =>
        `${record?.code || ''} - ${record?.first_address?.number || ''} ${
          record?.first_address?.street || ''
        } ${record?.first_address?.postal_code || ''} ${
          record?.first_address?.city || ''
        } ${
          record?.cadastral_reference ? `(${record?.cadastral_reference})` : ''
        }`,
      sorter: (a, b) => {
        const getFirstRelevantPart = (record) => {
          const combined = `${record?.code || ''} ${
            record?.first_address?.number || ''
          } ${record?.first_address?.street || ''} ${
            record?.first_address?.postal_code || ''
          } ${record?.first_address?.city || ''} ${
            record?.cadastral_reference || ''
          }`.trim();

          const cleaned = combined.replace(/[-\s]/g, '').normalize('NFKD');

          // Find the first sequence of digits or the first letter
          const match = cleaned.match(/^(\d+)|([A-Za-z])/);
          if (match) {
            return match[1] !== undefined
              ? parseInt(match[1], 10)
              : match[2].toUpperCase();
          }
          return ''; // Default if no relevant part is found
        };

        const partA = getFirstRelevantPart(a);
        const partB = getFirstRelevantPart(b);

        // Handle cases where either part is undefined or null
        if (partA == null && partB == null) return 0;
        if (partA == null) return 1;
        if (partB == null) return -1;

        // Compare numbers if both are numeric
        if (typeof partA === 'number' && typeof partB === 'number') {
          return partA - partB;
        }
        if (typeof partA === 'number') return -1; // Numbers come before letters
        if (typeof partB === 'number') return 1; // Numbers come before letters

        // If both parts are strings, compare them lexicographically
        if (partA < partB) return -1;
        if (partA > partB) return 1;

        return 0;
      }
    },
    {
      title: t('collective-ownerships.form.missions'),
      key: 'missions',
      dataIndex: 'missions',
      render: (missions) =>
        missions?.length
          ? missions.map((mission) => (
              <Tag color={missionTag[mission?.type]}>
                {t(`missions.tags.${mission?.type}`, {
                  defaultValue: mission?.type
                })}
              </Tag>
            ))
          : '-',
      filters: enums?.type?.map((r) => ({
        text: t(`missions.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('collective-ownerships.form.last_manager_connection'),
      key: 'last_connection',
      render: (record) =>
        record?.manager?.last_connection
          ? dayjs(record?.manager?.last_connection).format('DD-MM-YYYY à HH:mm')
          : t('collective-ownerships.tags.NEVER'),

      sorter: (a, b) => {
        const getDateOrNever = (record) => {
          if (!record?.manager?.last_connection) {
            return 'NEVER';
          }
          return new Date(record.manager.last_connection);
        };

        const dateA = getDateOrNever(a);
        const dateB = getDateOrNever(b);

        // Handle sorting for "NEVER" (assume "NEVER" should come last, modify if needed)
        if (dateA === 'NEVER' && dateB === 'NEVER') return 0;
        if (dateA === 'NEVER') return 1; // "NEVER" comes last, change to -1 if it should come first
        if (dateB === 'NEVER') return -1; // "NEVER" comes last, change to 1 if it should come first

        return dateA - dateB; // Sort by date if both are valid dates
      }
    },
    ...(permissions.block
      ? [
          {
            title: t('users.form.access'),
            key: 'access',
            dataIndex: 'access',
            render: (access) =>
              access === 'BLOCKED' ? (
                <Tag color={accessTag[access]}>
                  {t(`users.tags.${access}`, {
                    defaultValue: access
                  })}
                </Tag>
              ) : (
                '-'
              ),
            sorter: true,
            filters: enums?.access?.map((r) => ({
              text: t(`users.tags.${r}`),
              value: r
            }))
          }
        ]
      : []),
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/collective-ownerships/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>

          <>
            {permissions.edit && (
              <>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: `/collective-ownerships/edit/${record?._id}`
                  }}
                >
                  <EditOutlined style={{ fontSize: 18 }} />
                </Link>
              </>
            )}
            {permissions.block && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title={t(
                    `datatable.column.action.collective-ownership.${record?.access}.title`
                  )}
                  okText={t(
                    `datatable.column.action.collective-ownership.${record?.access}.ok`
                  )}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t(
                    `datatable.column.action.collective-ownership.${record?.access}.cancel`
                  )}
                  onConfirm={() =>
                    blockCollectiveOwnership({
                      dispatchAPI,
                      id: record._id,
                      setForceRefresh,
                      forceRefresh,
                      message,
                      value:
                        record?.access === 'NOT_BLOCKED'
                          ? { access: 'BLOCKED' }
                          : { access: 'NOT_BLOCKED' }
                    })
                  }
                  icon={<WarningOutlined />}
                >
                  {record?.access === 'NOT_BLOCKED' ? (
                    <StopOutlined style={{ fontSize: 18 }} />
                  ) : (
                    <CheckCircleOutlined style={{ fontSize: 18 }} />
                  )}
                </Popconfirm>
              </>
            )}
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                setIsReassignModalOpen(true);
                setSelectedCollectiveOwnerships([record]);
              }}
            >
              <ForkOutlined />
            </Button>
          </>
        </>
      )
    }
  ];
};
