import React from 'react';
import { Form, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { stockCerfaTag } from '../../../../../utils/constants/tagColors';

const { Option } = Select;

/**
 * AdditionalSearchBar component renders a search bar with a status filter dropdown.
 * It allows users to filter data by status.
 *
 * @component
 * @param {function} props.setFilters - Function to update the filters applied.
 * @param {boolean} props.isDataLoading - Indicates if the data is currently loading.
 * @param {Object} props.enums - Enumeration object containing the available statuses.
 * @param {Array<string>} props.enums.status - Array of status strings to display in the dropdown.
 * @returns {JSX.Element} The rendered search bar component.
 */
const AdditionalSearchBar = ({ setFilters, isDataLoading, enums }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Form layout="inline" form={form}>
      <Form.Item name={['status']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              stock_cerfa_status: value || ''
            }));
          }}
          onClear={() => {
            setFilters((prev) => ({
              ...prev,
              stock_cerfa_status: ''
            }));
          }}
          showSearch
          allowClear
          loading={isDataLoading}
          filterOption={filterOption}
          placeholder={t('dashboard.cerfa_stock.placeholders.filter_by_status')}
        >
          {(enums || []).map((status) => (
            <Option key={status} value={status}>
              <Tag color={stockCerfaTag[status]}>
                {t(`dashboard.cerfa_stock.tags.${status}`, {
                  defaultValue: status
                })}
              </Tag>
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

AdditionalSearchBar.propTypes = {
  setFilters: PropTypes.func.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  enums: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};
export default AdditionalSearchBar;
