import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm } from 'antd';
import { deletePermission } from '../../utils/deletePermission';

/**
 * `useColumns` is a custom React hook that generates column definitions for use in a table component,
 * particularly within the context of collective ownership permissions management.
 * Each column definition includes configuration for displaying, sorting data, and rendering custom action buttons with localized labels.
 *
 * Parameters:
 * @param {Object} params - The parameters object.
 * @param {function} params.setPermissions - Function to update the permissions state in the parent component.
 * @param {Object} params.permissions - The current state of permissions, used for operations such as deletion.
 * @param {function} params.dispatchAPI - Function used to dispatch API calls, typically for data fetching or updating.
 * @param {function} params.message - Function for displaying feedback messages to the user (e.g., success or error messages).
 * @param {string} params.id - Identifier for the current context or item, used in operations such as deleting permissions.
 * @param {string} params.purpose - A string indicating the purpose of the table, which can influence actions within the columns (e.g., whether to update the database on delete).
 *
 * Returns:
 * An array of column definitions for use in an Ant Design `Table` component. Each column definition includes properties such as:
 * - `title`: The localized title of the column, used as the header.
 * - `key`: A unique key for the column, used internally by the table for tracking.
 * - `dataIndex`: The path to the data value for the column in the data source.
 * - `sorter`: A boolean indicating whether the column is sortable.
 * - `align`: Alignment of the column content.
 * - `fixed`: Whether the column should be fixed at the 'right' side of the table.
 * - `render`: A function that returns the React elements to be rendered in the column. This typically includes action icons and interactive elements like `Link`, `Popconfirm`, and Ant Design icons for actions such as viewing details and deleting entries with a confirmation dialog.
 *
 * Usage:
 * This hook is intended to be used in components where a table displaying a list of permissions is required. It allows for easy integration of view and delete functionalities with added confirmation steps for destructive actions.
 */

export const useColumns = ({
  setPermissions,
  permissions,
  dispatchAPI,
  message,
  id,
  purpose
}) => {
  const { t } = useTranslation();

  return [
    {
      title: t('collective-ownerships.form.collapse.columns.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('collective-ownerships.form.collapse.columns.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/users/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>

          <>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() =>
                deletePermission({
                  record,
                  setPermissions,
                  permissions,
                  updateDataBase: purpose === 'show',
                  dispatchAPI,
                  message,
                  id
                })
              }
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: 18 }}
                type="delete"
              />
            </Popconfirm>
          </>
        </>
      )
    }
  ];
};
