import { useTranslation } from 'react-i18next';
import { Checkbox, DatePicker, Input, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * Custom hook that returns an object containing the fields for the PCS appointment form.
 * @hook
 * @param {Object} params - The parameters object.
 * @param {Array} params.syndics - The array of syndics.
 * @param {Function} params.setSyndic - The function to set the syndic.
 * @param {Array} params.managers - The array of managers.
 * @param {Array} params.preprods - The array of preproductions.
 * @param {Object} params.user - The user object.
 * @param {boolean} params.isFieldsLoading - The flag indicating whether the fields are loading.
 * @param {Object} params.enums - The object containing the enums.
 * @returns {Object} The fields object.
 */
export const usePCSAppointmentFields = ({
  syndics,
  setSyndic,
  managers,
  csm_meetings
}) => {
  const { t } = useTranslation();
  const [showReplan, setShowReplan] = useState(false);

  const fields = [
    {
      name: ['syndic'],
      rules: [{ required: true }, { type: 'string' }],
      input: (
        <Select
          style={{
            width: 200,
            marginRight: 10
          }}
          showSearch
          allowClear
          optionFilterProp="children"
          onSelect={(value) => {
            setSyndic(value);
          }}
          placeholder={t('events.form.syndic')}
        >
          {(syndics || []).map((item) => (
            <Option value={item._id} key={item._id} syndic={item}>
              {`${item.code} ${item.society_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['manager'],
      rules: [{ required: true }, { type: 'string' }],
      input: (
        <Select
          style={{
            width: 200,
            marginRight: 10
          }}
          showSearch
          allowClear
          optionFilterProp="children"
          placeholder={t('events.form.manager')}
        >
          {(managers || []).map((item) => (
            <Option value={item._id} key={item._id} manager={item}>
              {`${item.first_name || '-'} ${item.last_name || '-'}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['csm_type'],
      rules: [{ required: true }, { type: 'string' }],
      input: (
        <Select
          style={{
            width: 200,
            marginRight: 10
          }}
          showSearch
          allowClear
          optionFilterProp="children"
          placeholder={t('events.form.csm_type')}
        >
          {(csm_meetings || []).map((item) => (
            <Option value={item._id} key={item._id} syndic={item}>
              {`${t(`missions.tags.${item.pcs_phase}`)} - ${item.meeting_type}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['title'],
      input: (
        <Input style={{ width: 200 }} placeholder={t('events.form.title')} />
      )
    },
    {
      name: ['date'],
      key: 'date',
      rules: [{ required: true }, { type: Date }],
      input: <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      name: ['time_slots'],
      key: 'time_slots',
      rules: [{ required: true }, { type: Object }],
      input: (
        <RangePicker
          showTime={{
            format: 'HH:mm'
          }}
          picker="time"
          onOk={(value) => value}
          // disabledDate={disabledDateTime}
          // disabledTime={disabledRangeTime}
          disabled={false}
        />
      )
    },
    {
      name: ['collective_ownership_numbers'],
      key: 'collective_ownership_numbers',
      required: true,
      input: (
        <InputNumber
          style={{ width: 200 }}
          min={1}
          max={10}
          defaultValue={1}
          onChange={(value) => value}
        />
      )
    },
    {
      name: ['contacts'],
      key: 'contacts',
      rules: [{ required: true }, { type: 'string' }],
      input: (
        <Input style={{ width: 200 }} placeholder={t('events.form.contacts')} />
      )
    },
    {
      name: ['remote_connection_support'],
      key: 'remote_connection_support',
      input: (
        <Input
          style={{ width: 200 }}
          placeholder={t('events.form.remote_connection_support')}
        />
      )
    },
    {
      name: ['informations'],
      key: 'informations',
      input: (
        <Input.TextArea
          style={{ width: 300, height: 100 }}
          placeholder={t('events.form.informations')}
        />
      )
    },
    {
      name: ['to_replan'],
      key: 'to_replan',
      valuePropName: 'checked',
      noLabel: true,
      input: (
        <Checkbox
          onChange={(value) => {
            setShowReplan(value.target.checked);
            return value.target.checked;
          }}
        >
          {t('events.form.to_replan')}
        </Checkbox>
      )
    },
    ...(showReplan
      ? [
          {
            name: ['new_date'],
            key: 'new_date',
            rules: [{ required: true }, { type: Date }],
            input: (
              <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
            )
          },
          {
            name: ['new_time_slots'],
            key: 'new_time_slots',
            rules: [{ required: true }, { type: Object }],
            input: (
              <RangePicker
                showTime={{
                  format: 'HH:mm',
                  defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                }}
                picker="time"
                onOk={(value) => value}
                // disabledDate={disabledDateTime}
                // disabledTime={disabledRangeTime}
                disabled={false}
              />
            )
          }
        ]
      : [])
  ];

  return { fields };
};
