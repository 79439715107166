import PropTypes from 'prop-types';
import { LeftOutlined } from '@ant-design/icons';
import { Card, Col, Row, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';

/**
 * Custom content component for services.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the custom content.
 * @param {string} props.subtitle - The subtitle of the custom content.
 * @param {Object} props.cardDesign - The design configuration for the card.
 * @param {string} props.imageOnRight - The URL of the image to be displayed on the right side.
 * @param {Object} props.containerStyle - The additional styles for the container.
 * @param {ReactNode} props.children - The content to be rendered inside the component.
 * @param {boolean} props.childrenWithAsHeader - Determines if the children should be treated as a header.
 * @returns {JSX.Element} The rendered ServicesCustomContent component.
 */

const { Content } = Layout;
export const ServicesCustomContent = ({
  title,
  subtitle,
  cardDesign,
  imageOnRight,
  containerStyle,
  children,
  contentStyle
}) => {
  const headerWidth = '97.5%';
  const iconStyle = { fontSize: '24px' };
  const navigate = useNavigate();
  const rowStyle = { marginBottom: '8px' };
  return (
    <Content
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        minHeight: 'fit-content',
        ...containerStyle
      }}
    >
      <Card
        style={{
          backgroundColor: '#222222',
          borderRadius: '15px',
          color: '#F4F4F4',
          width: headerWidth,
          ...cardDesign
        }}
      >
        <Row gutter={16} align="middle">
          <Col span={1}>
            <LeftOutlined onClick={() => navigate(-1)} style={iconStyle} />
          </Col>
          <Col span={18}>
            <Row style={rowStyle}>{title}</Row>
            <Row>{subtitle}</Row>
          </Col>
          <Col span={4}>
            {imageOnRight && <img src={imageOnRight} alt="logo" />}
          </Col>
        </Row>
      </Card>
      <Content
        style={{
          width: headerWidth,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '15px',
          backgroundColor: '#ffff',
          padding: '2rem',
          ...contentStyle
        }}
      >
        {children}
      </Content>
    </Content>
  );
};

ServicesCustomContent.propTypes = {
  title: PropTypes.shape({}),
  subtitle: PropTypes.shape({}),
  cardDesign: PropTypes.shape({}),
  imageOnRight: PropTypes.string,
  containerStyle: PropTypes.shape({}),
  contentStyle: PropTypes.shape({})
};

ServicesCustomContent.defaultProps = {
  title: {},
  subtitle: {},
  cardDesign: {},
  imageOnRight: '',
  containerStyle: {},
  contentStyle: {}
};
