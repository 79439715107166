import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns for the cash statistics list
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useCashColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('statistics.columns.consultant'),
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: true
    },
    {
      title: t('statistics.columns.cash.total_collective_ownership'),
      dataIndex: 'total_collective_ownership',
      key: 'total_collective_ownership',
      sorter: true
    },
    {
      title: t('statistics.columns.cash.total_delay'),
      dataIndex: 'total_delay',
      key: 'total_delay',
      sorter: true
    },
    {
      title: t('statistics.columns.cash.percentage_delay'),
      dataIndex: 'percentage_delay',
      key: 'percentage_delay',
      sorter: true
    },
    {
      title: t('statistics.columns.cash.advance_to_be_collected'),
      dataIndex: 'advance_to_be_collected',
      key: 'advance_to_be_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.cash.percentage_advance_collected'),
      dataIndex: 'percentage_advance_collected',
      key: 'percentage_advance_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.cash.balance_collected'),
      dataIndex: 'balance_collected',
      key: 'balance_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.cash.balance_to_be_collected'),
      dataIndex: 'balance_to_be_collected',
      key: 'balance_to_be_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.cash.percentage_balance_collected'),
      dataIndex: 'percentage_balance_collected',
      key: 'percentage_balance_collected',
      sorter: true
    }
  ];
};
