import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { CustomListResource } from '../SubComponents/CustomListResource';
import { useColumns } from './columns';
import { Order } from '../../../models/Order';

/**
 * GroupOrdersCard component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.order - The order object containing details about the current order.
 * @param {Function} props.forceRefresh - Variable to trigger a force refresh.
 * @param {Function} props.setForceRefresh - Function to trigger a force refresh.
 * @returns {JSX.Element} The rendered component.
 */
export const GroupOrdersCard = ({ order, forceRefresh, setForceRefresh }) => {
  const { t } = useTranslation();

  /**
   * Toggle the urgent status of an order.
   * @async
   * @function
   * @param {boolean} urgent - The urgent status.
   * @param {string} orderId - The ID of the order.
   * @returns {void}
   */
  const toggleUrgent = async (urgent, orderId) => {
    await Order.patchResource({ id: orderId, values: { urgent } });
  };

  const columns = useColumns({ toggleUrgent, setForceRefresh });

  return (
    <Card title={t('orders.show.cards.titles.group_orders')}>
      <CustomListResource
        forceRefresh={forceRefresh}
        withPageHeader={false}
        withCreateButton={false}
        withSearchBar={false}
        resourceName="orders"
        resourceModelName="Order"
        columns={columns}
        scroll={{ x: 'max-content' }}
        extraQuery={`cheque_number=${order?.cheque_number}&exclude_order=${order._id}`}
        printAction
        showAction
        editAction={false}
        path="/orders"
      />
    </Card>
  );
};

GroupOrdersCard.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string,
    cheque_number: PropTypes.string
  }),
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

GroupOrdersCard.defaultProps = {
  order: null,
  forceRefresh: false,
  setForceRefresh: null
};
