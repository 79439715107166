import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columns';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { addColumns } from '../../../components/editableTableComponents/addColumns';
import { ContentCustom } from '../../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../../components/PageHeader/PageHeader';
import { EditableTable } from '../../../components/editableTableComponents/EditableTable';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';

/**
 * Renders the Reminders component.
 *
 * @component
 * @returns {JSX.Element} The Reminders component.
 */
export const Reminders = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectType, setSelectType] = useState(null);
  const { getResourceData } = EditableTableMethods();
  const { searchValue, setPagination, pagination, currentSorter } =
    useConfigurationContext();
  const columns = useColumns({
    setEditingKey,
    form,
    setForceRefresh,
    forceRefresh,
    setSelectType
  });
  const [mergedColumns, setMergedColumns] = useState(columns);
  const isEditing = (record, rowKey) =>
    record === editingKey && rowKey === selectedRowKey;

  useEffect(() => {
    (async () => {
      const [reminderDatas] = await Promise.all([
        getResourceData({
          resource: 'reminders',
          searchValue,
          setPagination,
          pagination,
          currentSorter
        })
      ]);

      setDataSource(reminderDatas.map((el, index) => ({ ...el, key: index })));
    })();
  }, [forceRefresh, searchValue, currentSorter]);

  useEffect(() => {
    addColumns({
      isEditing,
      columnsData: columns,
      form,
      setEditingKey,
      editingKey,
      selectedRowKey,
      setForceRefresh,
      forceRefresh,
      dispatchAPI,
      message,
      selectType,
      setMergedColumns,
      resource: 'reminders'
    });
  }, [editingKey, dataSource]);

  return (
    <>
      <PageHeaderCustom title={t('reminders.title')} />
      <ContentCustom>
        <EditableTable
          resource="reminders"
          dataSource={dataSource}
          mergedColumns={mergedColumns}
          setSelectedRowKey={setSelectedRowKey}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          form={form}
          searchValue={searchValue}
          pagination={pagination}
          setPagination={setPagination}
        />
      </ContentCustom>
    </>
  );
};
