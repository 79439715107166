import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const sendMail = async (id) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  try {
    await dispatchAPI('GET', {
      url: `/collections/generatePDF/${id}/`
    });
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
