import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Table } from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useColumns } from './pcsProgressColumns';

const tradKey = 'syndics.show.cards.statistics.PCS_missions_progress_modal';

/**
 * ModalPCSProgressTable component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Flag indicating whether the modal is open or not.
 * @param {function} props.setIsOpen - Function to set the open state of the modal.
 * @param {string} props.syndicId - The ID of the syndic.
 * @returns {JSX.Element} The ModalPCSProgressTable component.
 */
export const ModalPCSProgressTable = ({ isOpen, setIsOpen, syndicId }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const { message } = useErrorMessage();
  const columns = useColumns(tradKey);

  const getDataSource = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/syndics/statistics-table/${syndicId}`
      });

      setDataSource([data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message(error);
    }
  };

  useEffect(() => {
    if (!syndicId) return;
    getDataSource();
  }, [syndicId]);

  return (
    <Modal
      onCancel={() => {
        setIsOpen(false);
      }}
      onOk={() => {
        setIsOpen(false);
      }}
      footer={null}
      title={t(`${tradKey}.title`)}
      open={isOpen}
      width="fit-content"
    >
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </Modal>
  );
};

export default ModalPCSProgressTable;

ModalPCSProgressTable.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  syndicId: PropTypes.string.isRequired
};
