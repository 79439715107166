import React, { useEffect, useState } from 'react';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Municipality } from '../../../models/Municipality';

const { Text } = Typography;

/**
 * MunicipalitiesModal is a React component that renders a modal displaying a list of municipalities
 * associated with a specific record. The modal can be opened or closed based on the `open` prop,
 * and it retrieves the municipalities data upon mounting.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.record - The record object containing details for the modal.
 * @param {string} props.record._id - The ID of the record used to fetch municipalities.
 * @param {string} props.record.name - The name of the record to be displayed in the modal title.
 * @param {boolean} props.open - A boolean that controls whether the modal is open or closed.
 * @param {Function} props.setOpen - A function to set the state of the modal's open/closed status.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const MunicipalitiesModal = ({ record, open, setOpen }) => {
  const { t } = useTranslation();
  const [municipalities, setMunicipalities] = useState([]);
  const { _id } = record;

  useEffect(() => {
    (async () => {
      const data = await Municipality.getResources({
        populate: '',
        extraQuery: `SPF_3233=${_id}`
      });
      setMunicipalities(data);
    })();
  }, []);

  return (
    <Modal
      title={`${record?.name} - ${t(
        'spf-departmental-archives.modals.titles.municipalities'
      )}`}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={480}
      closable
      footer={null}
      closeIcon={<CloseOutlined style={{ color: 'black', fontSize: 18 }} />}
    >
      {(municipalities || []).map((municipality, index) => (
        <Text key={municipality._id}>{`${municipality.name}${
          index === municipalities.length - 1 ? `.` : `, `
        }`}</Text>
      ))}
      {(!municipalities.length || !municipalities) && (
        <Text>
          {t('spf-departmental-archives.modals.titles.no_municipalities')}
        </Text>
      )}
    </Modal>
  );
};

MunicipalitiesModal.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default MunicipalitiesModal;
