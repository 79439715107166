import { Divider, Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTotalFields } from './fields/total';

const { Text } = Typography;

/**
 * Renders the total information for a quotation action.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.actAmount - The quotation action object.
 * @returns {JSX.Element|null} The rendered total information or null if the action is not supported.
 */
export const Total = ({ actAmount }) => {
  const { t } = useTranslation();
  const { actionFieldsMap } = useTotalFields();

  const actions = [
    ...new Set(
      actAmount.quotation_acts.map((act) => act.quotation_action.action)
    )
  ];

  const fieldsToRender = [
    ...new Map(
      actions
        .flatMap((action) => actionFieldsMap[action] || [])
        .map((field) => [field.name, field])
    ).values()
  ];

  return (
    <>
      <Divider>{t('quotations.dividers.total')}</Divider>
      <Row gutter={[16, 16]} justify="center">
        {fieldsToRender.map((field) => (
          <Col span={24} key={field.key}>
            <Text>
              {t(field.label, {
                amount: actAmount?.total?.[field.name] || 0
              })}
            </Text>
          </Col>
        ))}
      </Row>
    </>
  );
};

Total.propTypes = {
  actAmount: PropTypes.shape({
    quotation_acts: PropTypes.arrayOf(
      PropTypes.shape({
        quotation_action: PropTypes.shape({
          action: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired,
    total: PropTypes.shape({
      total_ttc: PropTypes.number,
      nomade_honorary_ht: PropTypes.number,
      notary_honorary_ttc: PropTypes.number
    })
  }).isRequired
};
