export const blockUser = async ({
  dispatchAPI,
  message,
  id,
  setForceRefresh,
  forceRefresh,
  value
}) => {
  try {
    const formData = new FormData();

    formData.append('values', JSON.stringify(value));

    await dispatchAPI('PATCH', {
      url: `/users/${id}`,
      body: formData
    });

    setForceRefresh(!forceRefresh);
  } catch (e) {
    message(e);
  }
};
