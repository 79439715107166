import { useTranslation } from 'react-i18next';
import {
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  message,
  Row,
  Col,
  Tooltip
} from 'antd';
import { Copy } from '@carbon/icons-react';
import { DeleteOutlined } from '@ant-design/icons';
import { useQuotationContext } from '../../../contexts/QuotationContext';
import { RenderQuotationActionDatas } from '../quotation-action-inputs/RenderQuotationActionDatas';
import { QuotationAction } from '../../../models/QuotationAction';
import { ActionMethods } from '../utils/actionMethods';

const { Option } = Select;

/**
 * Custom hook that returns an array of columns for the quotation actions table.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {string} options.purpose - The purpose of the quotation actions.
 * @returns {Array} An array of columns for the quotation actions table.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const {
    enums,
    setActionTypes,
    form,
    setQuotationActions,
    setFormData,
    isFieldsLoading,
    missionType
  } = useQuotationContext();
  const { setActionDynamicFields } = ActionMethods();

  return [
    {
      title: t('quotation-actions.form.action'),
      key: 'action',
      dataIndex: 'action',
      render: (_, record) => (
        <Form.Item name={['actions', record._id, 'action']}>
          <Select
            style={{ width: '250px' }}
            loading={isFieldsLoading}
            onChange={(e) => {
              setActionDynamicFields({ action: e, recordId: record._id });
              setActionTypes({ [record._id]: e });
            }}
          >
            {(enums?.quotation_action?.action_types[missionType] || []).map(
              (el) => (
                <Option key={el} value={el}>
                  {t(`quotation-actions.tags.${el}`)}
                </Option>
              )
            )}
          </Select>
        </Form.Item>
      ),
      sorter: true
    },
    {
      title: t('quotation-actions.form.AG_date'),
      key: 'AG_date',
      dataIndex: 'AG_date',
      render: (_, record) => (
        <Form.Item name={['actions', record._id, 'AG_date']}>
          <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
        </Form.Item>
      )
    },
    {
      title: t('quotation-actions.form.resolution_ref'),
      key: 'resolution_ref',
      dataIndex: 'resolution_ref',
      render: (_, record) => (
        <Form.Item name={['actions', record._id, 'resolution_ref']}>
          <Input style={{ width: '100px' }} />
        </Form.Item>
      )
    },
    {
      title: t('quotation-actions.form.description'),
      key: 'description',
      dataIndex: 'description',
      render: (_, record) => (
        <Form.Item name={['actions', record._id, 'description']}>
          <Input style={{ width: '150px' }} />
        </Form.Item>
      )
    },
    {
      title: t('quotation-actions.form.other_ref'),
      key: 'other_ref',
      dataIndex: 'other_ref',
      render: (_, record) => (
        <Form.Item name={['actions', record._id, 'other_ref']}>
          <Input style={{ width: '80px' }} />
        </Form.Item>
      )
    },
    {
      title: t('quotation-actions.form.data'),
      key: 'data',
      dataIndex: 'data',
      render: (_, record) => (
        <RenderQuotationActionDatas form={form} recordId={record._id} />
      )
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col span={12}>
            <Button
              type="link"
              onClick={() =>
                QuotationAction.duplicateQuotationAction({
                  id: record._id,
                  form,
                  setQuotationActions,
                  setFormData,
                  message,
                  t
                })
              }
            >
              <Tooltip title={t('quotation-actions.tooltips.copy')}>
                <Copy />
              </Tooltip>
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="link"
              onClick={() =>
                QuotationAction.removeQuotationAction({
                  id: record._id,
                  setQuotationActions,
                  setFormData,
                  message,
                  t
                })
              }
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: 18 }}
                type="delete"
              />
            </Button>
          </Col>
        </Row>
      )
    }
  ];
};
