import { useTranslation } from 'react-i18next';
import { Popconfirm, Row } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { EditableTableMethods } from '../../../components/editableTableComponents/methods';
import { deleteResource } from '../../../utils/deleteResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Returns an array of columns configuration for the customer service manager meetings table.
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setEditingKey - The function to set the editing key.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setSelectType - The function to set the select type.
 * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The force refresh flag.
 * @returns {Array} - The array of columns configuration.
 */
export const useColumns = ({
  setEditingKey,
  form,
  setSelectType,
  setForceRefresh,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { editCell } = EditableTableMethods();

  return [
    { key: 'sort' },
    {
      title: t('customer-service-manager-meetings.form.meeting_type'),
      key: 'meeting_type',
      dataIndex: 'meeting_type',
      editable: true,
      inputType: 'text',
      render: (_, record) => (
        <Row
          onClick={() =>
            editCell({ record, key: 'meeting_type', setEditingKey, form })
          }
        >
          {record?.meeting_type || '-'}
        </Row>
      ),

      sorter: (a, b) => {
        const valA = a.meeting_type ? a.meeting_type.toLowerCase() : '-';
        const valB = b.meeting_type ? b.meeting_type.toLowerCase() : '-';

        if (valA === '-' && valB !== '-') return 1;
        if (valA !== '-' && valB === '-') return -1;
        if (valA === '-' && valB === '-') return 0;

        return valA.localeCompare(valB);
      }
    },
    {
      title: t('customer-service-manager-meetings.form.pcs_phase'),
      key: 'pcs_phase',
      dataIndex: 'pcs_phase',
      editable: true,
      inputType: 'select',
      render: (_, record) => (
        <Row
          onClick={() => {
            setSelectType('pcs_phases');
            editCell({
              record,
              key: 'pcs_phase',
              setEditingKey,
              form
            });
          }}
        >
          {record?.pcs_phase
            ? t(`customer-service-manager-meetings.tags.${record?.pcs_phase}`)
            : '-'}
        </Row>
      )
    },
    {
      title: t(
        'customer-service-manager-meetings.form.time_interval_between_mission_and_meeting'
      ),
      key: 'time_interval_between_mission_and_meeting',
      dataIndex: 'time_interval_between_mission_and_meeting',
      editable: true,
      inputType: 'number-unit',
      render: (_, record) => (
        <Row
          onClick={() => {
            setSelectType('time_interval_between_mission_and_meeting');
            editCell({
              record,
              key: 'time_interval_between_mission_and_meeting',
              setEditingKey,
              form
            });
          }}
        >
          {`${
            record?.time_interval_between_mission_and_meeting?.number || '-'
          } ${
            record?.time_interval_between_mission_and_meeting?.unit
              ? t(
                  `units.${record?.time_interval_between_mission_and_meeting?.unit}`
                )
              : ''
          } `}
        </Row>
      )
    },
    {
      key: 'action',
      fixed: 'right',
      width: 60,
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            deleteResource({
              dispatchAPI,
              resourceName: 'customer-service-manager-meetings',
              id: record._id,
              setForceRefresh,
              forceRefresh,
              message
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 16 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];
};
