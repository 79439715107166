import { Select, InputNumber, Checkbox, DatePicker, Input } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns an array of fields for configuration.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.enums - The enums object containing the available options for select inputs.
 * @param {boolean} options.isFieldsLoading - A flag indicating whether the fields are currently loading.
 * @param {string} options.id - The ID of the data.
 * @param {boolean} options.isDuplicateForm - A flag indicating whether the form is a duplicate.
 * @returns {Object} - The fields array.
 */

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const useFields = ({ enums, isFieldsLoading, id, isDuplicateForm }) => {
  const { t } = useTranslation();

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      label: 'type',
      name: id ? [id, 'type'] : ['type'],
      key: 'type',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(enums.types || []).map((item) => (
            <Option value={item} key={item}>
              {t(`spf-departmental-archives.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'name',
      name: id ? [id, 'name'] : ['name'],
      key: 'name',
      rules: [{ required: true }],
      input: <Input status={isDuplicateForm ? 'error' : ''} />
    },
    {
      label: 'email',
      name: id ? [id, 'email'] : ['email'],
      key: 'email',
      rules: [{ required: true }, { type: 'email' }],
      input: <Input status={isDuplicateForm ? 'error' : ''} />
    },
    {
      label: 'address.street',
      name: id ? [id, 'address', 'street'] : ['address', 'street'],
      key: 'address.street'
    },
    {
      label: 'address.additional',
      name: id ? [id, 'address', 'additional'] : ['address', 'additional'],
      key: 'address.additional'
    },
    {
      label: 'address.postal_code',
      name: id ? [id, 'address', 'postal_code'] : ['address', 'postal_code'],
      key: 'address.postal_code',
      input: <Input status={isDuplicateForm ? 'error' : ''} />
    },
    {
      label: 'address.city',
      name: id ? [id, 'address', 'city'] : ['address', 'city'],
      key: 'address.city',
      input: <Input status={isDuplicateForm ? 'error' : ''} />
    },
    {
      label: 'send_type',
      name: id ? [id, 'send_type'] : ['send_type'],
      key: 'send_type',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(enums.send_types || []).map((item) => (
            <Option value={item} key={item}>
              {t(`spf-departmental-archives.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'duplicate_printing',
      name: id ? [id, 'duplicate_printing'] : ['duplicate_printing'],
      key: 'duplicate_printing',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      label: 'cheque_printing',
      name: id ? [id, 'cheque_printing'] : ['cheque_printing'],
      key: 'cheque_printing',
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      label: 'order_method',
      name: id ? [id, 'order_method'] : ['order_method'],
      key: 'order_method',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(enums.order_methods || []).map((item) => (
            <Option value={item} key={item}>
              {t(`spf-departmental-archives.tags.${item}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'cerfa_3231_date',
      name: id ? [id, 'cerfa_3231_date'] : ['cerfa_3231_date'],
      key: 'cerfa_3231_date',
      input: <RangePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      label: 'cerfa_3236_date',
      name: id ? [id, 'cerfa_3236_date'] : ['cerfa_3236_date'],
      key: 'cerfa_3236_date',
      input: <RangePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
    },
    {
      label: 'building_record_rate',
      name: id ? [id, 'building_record_rate'] : ['building_record_rate'],
      key: 'building_record_rate',
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: 'building_record_send',
      name: id ? [id, 'building_record_send'] : ['building_record_send'],
      key: 'building_record_send',
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: 'general_prices_rate',
      name: id ? [id, 'general_prices_rate'] : ['general_prices_rate'],
      key: 'general_prices_rate',
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: 'general_prices_send',
      name: id ? [id, 'general_prices_send'] : ['general_prices_send'],
      key: 'general_prices_send',
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: 'rectification_certificate_rate',
      name: id
        ? [id, 'rectification_certificate_rate']
        : ['rectification_certificate_rate'],
      key: 'rectification_certificate_rate',
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: 'rectification_certificate_send',
      name: id
        ? [id, 'rectification_certificate_send']
        : ['rectification_certificate_send'],
      key: 'rectification_certificate_send',
      rules: [{ required: true }],
      input: <InputNumber addonAfter="€" />
    },
    {
      label: 'alert_message',
      name: id ? [id, 'alert_message'] : ['alert_message'],
      key: 'alert_message',
      input: <TextArea />
    },
    {
      label: 'comment',
      name: id ? [id, 'comment'] : ['comment'],
      key: 'comment',
      input: <TextArea />
    }
  ];

  return { fields };
};
