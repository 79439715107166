export const setDynamicFields = ({
  role,
  setSyndicOptions,
  syndics,
  syndic,
  setAssistants,
  setManagers
}) => {
  if (
    [
      'users:SYNDIC_MANAGER',
      'users:SYNDIC_ASSISTANT',
      'users:SYNDIC_COUNCIL'
    ].includes(role)
  ) {
    setSyndicOptions(syndics);
  }

  if (syndic && syndic.assistants) {
    setAssistants(syndic.assistants);
  }

  if (syndic && syndic.managers) {
    setManagers(syndic.managers);
  }
};
