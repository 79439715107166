import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Mission } from '../../../../../models/Mission';
import { useMissionContext } from '../../../../../contexts/MissionContext';

const { Text } = Typography;

/**
 * Component for displaying an existing capacity alert message.
 * @component
 * @returns {JSX.Element} The JSX element representing the existing capacity alert message.
 */
export const ExistingCapacityAlertMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mission, setForceRefresh } = useMissionContext();

  return (
    <Row gutter={[16, 16]} justify="space-between" align="middle">
      <Col>
        <Text>{t('missions.messages.capactiy_not_set')}</Text>
      </Col>
      <Col>
        <Row gutter={[16, 16]}>
          <Col>
            <Button
              onClick={() =>
                navigate(
                  `/collective-ownerships/edit/${mission.collective_ownership._id}`
                )
              }
            >
              {t('buttons.inform')}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() =>
                Mission.patchResource({
                  id: mission._id,
                  values: { ignore_capacity_alert: true },
                  setForceRefresh
                })
              }
            >
              {t('buttons.ignore')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
