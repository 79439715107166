import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Checkbox, Divider, Flex, Form, Radio, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import { useGlobalContext } from '../../../../../../contexts/GlobalContext';
import { formatAddress } from '../../../../utils/formatingFunctions';
import { useErrorMessage } from '../../../../../../utils/errorMessage';

const layoutProps = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

/**
 * @hook
 * Custom hook that provides fields for the ComplianceElanRouter Audit Step 1.
 * It fetches data for managers and municipalities, and returns an array of fields.
 * @param {Function} setButtonsActive - A function to set the buttons active.
 * @param {Object} form - The form object.
 * @returns {Object} An object containing the fields array.
 */
export const useFields = (setButtonsActive, form) => {
  const { dispatchAPI } = useAuthContext();
  const { currentSyndic } = useGlobalContext();
  const location = useLocation();
  const [missions, setMissions] = useState([]);
  const [missionTypes, setMissionTypes] = useState({
    mainTypes: [],
    extraType: []
  });
  const [isLoading, setIsLoading] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [selectedCollectiveOwnership, setSelectedCollectiveOwnership] =
    useState(null);
  const { message } = useErrorMessage();
  const searchParams = new URLSearchParams(location.search);

  const { t } = useTranslation();

  const getMissions = async () => {
    setIsLoading(true);
    const { data } = await dispatchAPI('GET', {
      url: `/missions?syndic=${currentSyndic}&type=PCS_1&lawyer_step=FINISHED&populate=collective_ownership&fields=collective_ownership,lawyer_step`
    });
    setIsLoading(false);
    setMissions(data);
  };

  const getMissionTypes = async () => {
    const { data } = await dispatchAPI('GET', {
      url: '/missions/enums'
    });
    setMissionTypes({
      mainTypes: data.phase_two_types || [],
      extraType: data.phase_two_types_bonus || []
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([getMissions(), getMissionTypes()]);
    } catch (error) {
      message('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentSyndic]);

  useEffect(() => {
    const fields = form.getFieldsValue();
    setButtonsActive((prev) => ({ ...prev, next: false }));
    const { mission, type_pcs_2, SURVEYOR_QUOTATION } = fields;

    const validMission = mission;
    const validType = type_pcs_2 || SURVEYOR_QUOTATION;

    if (validMission && validType) {
      setButtonsActive((prev) => ({ ...prev, next: true, saveDraft: true }));
    }
  }, [selectedRadio, isCheckboxChecked, selectedCollectiveOwnership]);

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };
  const handleCollectiveOwnershipChange = (e) => {
    setSelectedCollectiveOwnership(e.target.value);
  };

  const actionsToDoFields = (
    <Flex vertical gap={20}>
      <Divider>{t('missions.form.select_actions_to_do')}</Divider>
      <Form.Item name={['type_pcs_2']} {...layoutProps}>
        <Radio.Group
          optionFilterProp="children"
          value={selectedRadio}
          onChange={handleRadioChange}
        >
          <Space direction="vertical">
            {missionTypes.mainTypes.map((type) => (
              <Radio value={type} key={type}>
                {t(`missions.form.${type.toLowerCase()}`)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      {missionTypes.extraType.map((type) => (
        <Form.Item
          name={[type]}
          label=" "
          {...layoutProps}
          valuePropName="checked"
        >
          <Checkbox checked={isCheckboxChecked} onChange={handleCheckboxChange}>
            {t(`missions.form.${type}`)}
          </Checkbox>
        </Form.Item>
      ))}
    </Flex>
  );

  const selectCollectiveOwnershipFields = (
    <Flex vertical gap={20}>
      <Divider>
        {t('missions.form.select_affected_collective_ownership')}
      </Divider>
      <Form.Item name={['mission']} {...layoutProps}>
        <Radio.Group
          optionFilterProp="children"
          defaultValue={searchParams.get('mission_id')}
          onChange={handleCollectiveOwnershipChange}
        >
          <Spin spinning={isLoading}>
            <Space direction="vertical">
              {missions.map((mission) => (
                <Radio value={mission._id} key={mission._id}>
                  {(() => {
                    const { collective_ownership } = mission;
                    const { name, first_address, first_city } =
                      collective_ownership;
                    return formatAddress(name, first_address, first_city);
                  })()}
                </Radio>
              ))}
            </Space>
          </Spin>
        </Radio.Group>
      </Form.Item>
    </Flex>
  );
  return { selectCollectiveOwnershipFields, actionsToDoFields };
};
