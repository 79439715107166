import { Modal, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * ExportModal component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.visible - Determines if the modal is visible.
 * @returns {JSX.Element} The rendered ExportModal component.
 */
export const ExportModal = ({ visible }) => {
  const { t } = useTranslation();

  return (
    <Modal
      closable={false}
      footer={false}
      open={visible}
      maskClosable={false}
      bodyStyle={{ textAlign: 'center' }}
    >
      <Spin spinning size="large" style={{ margin: 16 }} />
      <br />
      <Typography.Text>{t('success.message.preparing_file')}</Typography.Text>
    </Modal>
  );
};

ExportModal.propTypes = {
  visible: PropTypes.bool.isRequired
};
