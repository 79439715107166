import { useCallback, useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useConfig } from './utils/createUpdateConfig';
import { GetEnums } from '../../../utils/getEnums';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';

/**
 * Component for creating or updating SPF Departmental Archive.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component.
 * @returns {JSX.Element} JSX element representing the CreateUpdateSpfDepartmentalArchive component.
 */

export const CreateUpdateSpfDepartmentalArchive = ({ purpose }) => {
  const [form] = Form.useForm();
  const { getEnums } = GetEnums();
  const [enums, setEnums] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { forceRefresh, setForceRefresh } = useConfigurationContext();
  const { fields } = useFields({
    enums,
    isFieldsLoading,
    purpose
  });

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    const enumsDatas = await getEnums({
      resource: 'spf-departmental-archives'
    });
    setEnums(enumsDatas);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const config = useConfig();

  return (
    <CreateUpdateContainer
      customFormInstance={form}
      fields={fields}
      config={config}
      purpose={purpose}
      baseUrl="spf-departmental-archives"
      resource="spf-departmental-archives"
      extraQuery="?type=SPF"
      withFilesManager={false}
      withEnums={false}
      setForceRefresh={setForceRefresh}
      forceRefresh={forceRefresh}
    />
  );
};

CreateUpdateSpfDepartmentalArchive.propTypes = {
  purpose: PropTypes.string.isRequired
};
