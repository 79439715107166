import PropTypes from 'prop-types';
import { useColumns } from './useColumns';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';
import { ListResource } from '../../../../../components';
import { syndicContentCustomStyle } from '../../../utils/styleConstants';
import logoPendingValidation from '../../../../../assets/images/bienvenue_dans_votre_outil_de_gestion.svg';
import { ServicesCustomContent } from '../../ServicesCustomContent';
import { dashboardTitle } from '../../ComplianceElanRouter/dashboardTitles';

/**
 * Renders a list of services pending validation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.requested_mission_type - The requested mission types.
 * @param {boolean} props.with_PCS_PRE_PRODUCTION - Indicates if PCS Pre production is enabled.
 * @param {string} props.missionsButtonTradKey - The translation key for the missions button.
 * @returns {JSX.Element} The rendered component.
 */
export const ServicesPendingValidationList = ({ requested_mission_type }) => {
  const missionsString = requested_mission_type.join(',');
  const { currentSyndic } = useGlobalContext();
  const columns = useColumns();
  const { myPedndingValidationsTitle, myPedndingValidationsSubtitle } =
    dashboardTitle();
  const fieldsToFetch = [
    'type',
    '_id',
    'name',
    'updated_at',
    'status',
    'collective_ownership.first_address',
    'collective_ownership.first_city.name',
    'collective_ownership.name',
    'requested_mission_type',
    'collective_ownership.name',
    'requested_mission_type',
    'new_collective_ownership'
  ];
  const buildStringFields = (fields) => fields.join(',');
  const fieldsString = buildStringFields(fieldsToFetch);

  const MISSIONS_POPULATE =
    'mission,collective_ownership,collective_ownership.first_city,new_collective_ownership.first_city';
  const MISSIONS_QUERY = `syndic=${currentSyndic}&status=PENDING_VALIDATION,VALIDATED&requested_mission_type=${missionsString}&fields=${fieldsString}&sort=-updated_at`;

  return (
    <ServicesCustomContent
      title={myPedndingValidationsTitle}
      subtitle={myPedndingValidationsSubtitle}
      imageOnRight={logoPendingValidation}
      cardStyle={{ backgroundColor: '#222222' }}
    >
      <div style={{ width: '100%' }}>
        <ListResource
          ContentCustomStyle={syndicContentCustomStyle}
          withPageHeader={false}
          resourceName="syndic-requests"
          extraQuery={MISSIONS_QUERY}
          populate={MISSIONS_POPULATE}
          onDoubleClickAction={{ action: () => {} }}
          columns={columns}
          customActionColumn
          withCreateButton={false}
        />
      </div>
    </ServicesCustomContent>
  );
};

ServicesPendingValidationList.propTypes = {
  requested_mission_type: PropTypes.arrayOf(PropTypes.string)
};

ServicesPendingValidationList.defaultProps = {
  requested_mission_type: []
};
