import { Avatar, Card, Col, Divider, List, Row, Typography, Badge } from 'antd';
import React from 'react';
import { ExclamationCircleFilled, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useMessageContext } from '../../../../contexts/MessageContext';

const { Text } = Typography;

/**
 * LastMessages component displays a list of the most recent messages
 * received, sorted by creation date.
 *
 * @param {object} props - The component props.
 * @param {function} props.t - The translation function for localized text.
 * @returns {JSX.Element} The rendered LastMessages component.
 */
const LastMessages = ({ t }) => {
  const { messages } = useMessageContext();

  return (
    <Card
      title={
        <Text
          style={{ display: 'grid', placeItems: 'center', fontSize: '1rem' }}
        >
          {t('dashboard.assistant.cards.last_messages_received')}
        </Text>
      }
      extra={
        <Badge
          count={messages?.length || 0}
          color={messages?.length ? 'orange' : '#CED4DA'}
          showZero
        />
      }
    >
      <List
        itemLayout="horizontal"
        dataSource={messages.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )}
        split={false}
        renderItem={(item) => (
          <List.Item style={{ paddingTop: '0px' }}>
            <Card
              style={{
                width: '100%',
                borderRadius: '5px',
                background: 'white',
                borderBottom: 'none',
                paddingBottom: 0
              }}
              title={
                <>
                  <Text style={{ fontWeight: 'normal' }}>
                    {item?.syndic?.society_name || '-'}
                  </Text>
                  <Divider type="vertical" />
                  <Text strong>
                    {item.collective_ownership
                      ? `${
                          item?.collective_ownership?.name
                            ? `${item?.collective_ownership?.name}, `
                            : ''
                        } ${
                          item?.collective_ownership?.first_address?.street ||
                          ''
                        } ${
                          item?.collective_ownership?.first_address
                            ?.postal_code || ''
                        } ${item?.collective_ownership?.first_city?.name || ''}`
                      : '-'}
                  </Text>
                </>
              }
              extra={
                <ExclamationCircleFilled
                  style={{ color: 'orange' }}
                  size={24}
                />
              }
            >
              <Row gutter={[16, 16]} align="middle">
                <Col
                  span={12}
                  style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                >
                  <Avatar
                    style={{ borderColor: 'blue', borderWidth: '2px' }}
                    size="medium"
                    icon={item.author?.photo ? '' : <UserOutlined />}
                    src={item.author?.photo ? item.author.photo : ''}
                  >
                    {`${item.author?.first_name[0]}${item.author?.last_name[0]}`}
                  </Avatar>

                  <Text strong>
                    {item?.author
                      ? `${item.author?.first_name || ''} ${
                          item.author?.last_name || ''
                        }`
                      : '-'}
                  </Text>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1.5rem',
                    justifyContent: 'end'
                  }}
                >
                  <Text strong>{item.subject ? item.subject : '-'}</Text>

                  <Text>
                    {item.created_at
                      ? dayjs(item.created_at).format('Le DD-MM-YYYY à HH[h]mm')
                      : '-'}
                  </Text>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
      />
    </Card>
  );
};

LastMessages.propTypes = {
  t: PropTypes.func.isRequired
};

export default LastMessages;
