import { useTranslation } from 'react-i18next';
import { message as antdMessage } from 'antd';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * A collection of utility methods for working with municipalities.
 * @hook
 * @returns {Object} An object containing the `getSpfDepartmentalArchives` method.
 */
export const MunicipalitiesMethods = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Retrieves the SPF departmental archives.
   * @function
   * @async
   * @param {Object} options - The options for retrieving the SPF departmental archives.
   * @param {Function} options.setSpfDepartmentalArchives - The function to set the SPF departmental archives.
   * @returns {Promise<Array>} A promise that resolves to an array of SPF departmental archives.
   */
  const getSpfDepartmentalArchives = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/spf-departmental-archives?deleted=${false}`
      });

      if (!data) {
        return null;
      }

      return data;
    } catch (error) {
      return message(error);
    }
  };

  /**
   * Checks for date conflicts in the given services array.
   *
   * @function
   * @param {Array} services - The array of services to check for conflicts.
   * @returns {boolean} - Returns true if there are date conflicts, false otherwise.
   */
  const checkDateConflicts = (services) => {
    const groupedServices = {};
    let returnWarningMessage = false;

    services.forEach((service) => {
      const { competent_authority, cerfa_type, start_date, end_date } = service;
      const key = `${competent_authority}-${cerfa_type}`;

      if (!groupedServices[key]) {
        groupedServices[key] = [];
      }

      groupedServices[key].push({
        start_date,
        end_date,
        cerfa_type
      });
    });

    Object.keys(groupedServices).some((key) => {
      const group = groupedServices[key];

      for (let i = 0; i < group.length; i += 1) {
        for (let j = i + 1; j < group.length; j += 1) {
          const serviceA = group[i];
          const serviceB = group[j];

          if (
            serviceA.start_date &&
            serviceB.end_date &&
            serviceA.start_date <= serviceB.end_date &&
            serviceA.end_date &&
            serviceB.start_date &&
            serviceA.end_date >= serviceB.start_date
          ) {
            returnWarningMessage = true;
            antdMessage.warning(
              t('municipalities.messages.services_date_conflict', {
                cerfa_type: serviceA.cerfa_type,
                service_A_start_date: dayjs(serviceA.start_date).format(
                  'DD-MM-YYYY'
                ),
                service_A_end_date: dayjs(serviceA.end_date).format(
                  'DD-MM-YYYY'
                ),
                service_B_start_date: dayjs(serviceB.start_date).format(
                  'DD-MM-YYYY'
                ),
                service_B_end_date: dayjs(serviceB.end_date).format(
                  'DD-MM-YYYY'
                )
              })
            );
            return true;
          }
        }
      }
      return false;
    });

    return returnWarningMessage;
  };

  /**
   * Creates or updates a resource body.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {Object} options.data - The data object.
   * @returns {Object|null} The updated data object or null if there is a missing key.
   */
  const onCreateUpdateResourceBody = ({ data }) => {
    const services = data?.services;
    const updatedData = { ...data };

    let returnWarningMessage = false;

    const cerfaTypeToDefaultDate = {
      3231: dayjs('12-31-1955'),
      3236: dayjs('01-01-1956')
    };

    const serviceWithSameSpfAndNoDate = {};

    returnWarningMessage = checkDateConflicts(services, returnWarningMessage);

    if (!returnWarningMessage) {
      services.some((service, index) => {
        const { cerfa_type, start_date, end_date, competent_authority } =
          service;

        if (!end_date || !start_date) {
          if (
            serviceWithSameSpfAndNoDate[`${competent_authority}-${cerfa_type}`]
          ) {
            returnWarningMessage = true;
            antdMessage.warning(
              t('municipalities.messages.services_missing_date', {
                competent_authority,
                cerfa_type
              })
            );
            return true;
          }
          serviceWithSameSpfAndNoDate[
            `${competent_authority}-${cerfa_type}`
          ] = true;
        }

        if (!end_date && !start_date) {
          const defaultDate = cerfaTypeToDefaultDate[cerfa_type];

          if (defaultDate) {
            updatedData.services[index] = {
              ...updatedData.services[index],
              ...(cerfa_type === '3231' ? { end_date: defaultDate } : {}),
              ...(cerfa_type === '3236' ? { start_date: defaultDate } : {})
            };
          }
        }

        return false;
      });
    }

    if (returnWarningMessage) {
      return null;
    }

    return updatedData;
  };

  return { getSpfDepartmentalArchives, onCreateUpdateResourceBody };
};
