import { createContext, useContext, useState } from 'react';

export const ServicesContext = createContext();

/**
 * @description ServicesContextProvider component
 * @param {Object} props - The props object.
 * @param {Object} props.children - The children object.
 * @returns {Object} - The JSX object.
 */
export const ServicesContextProvider = ({ children }) => {
  const [currentService, setCurrentService] = useState('');

  return (
    <ServicesContext.Provider value={{ currentService, setCurrentService }}>
      {children}
    </ServicesContext.Provider>
  );
};

export const useServicesContext = () => {
  const context = useContext(ServicesContext);
  if (context === undefined) {
    throw new Error(
      'useServicesContext must be used within a ServicesContextProvider'
    );
  }
  return context;
};
