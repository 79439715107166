/**
 * Retrieves the details of a specific syndic identified by its ID and updates the syndic state within the application.
 * The function also manages the loading state during data retrieval and displays a message in case of an error.
 *
 * @param {Object} options - The options for fetching the syndic data.
 * @param {Function} options.dispatchAPI - The function used to make API calls. It accepts a request type and request options as parameters.
 * @param {string} options.id - The ID of the syndic to be retrieved. This is used to make the API call.
 * @param {Function} options.setSyndic - Function to update the syndic state in the application with the retrieved data. It accepts an object as a parameter.
 * @param {Function} options.message - Function to display messages, typically used for error handling. It accepts an error message or status code as parameters.
 * @returns {Promise<void>} A promise that resolves when the syndic data has been successfully retrieved and set, or when an error has been caught and handled.
 */
export const getSyndic = async ({ dispatchAPI, id, setSyndic, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/syndics/${id}?populate=lawyer,consultant,contact,documents,documents.files,history.author`
    });

    setSyndic(data);
  } catch (e) {
    message(e);
  }
};
