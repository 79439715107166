import { useTranslation } from 'react-i18next';
import { Row, Col, Badge, Typography } from 'antd';
import { ShowAddPayments } from './ShowAddPayments';
import { AllInfos } from './AllInfos';
import { useInvoiceContext } from '../../../../contexts/InvoiceContext';

const { Text } = Typography;

/**
 * Custom hook to generate items for invoices.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Array} options.invoices - The array of invoices.
 * @returns {Object} - The generated items.
 */
export const useItems = ({ invoices }) => {
  const { t } = useTranslation();
  const items = [];
  const { formData } = useInvoiceContext();

  if ((invoices || []).length > 1) {
    items.push({
      label: t('invoices.titles.all'),
      key: 'all',
      children: <AllInfos invoices={invoices} />
    });
  }

  (invoices || []).forEach((invoice, index) => {
    items.push({
      label: (
        <Row gutter={[16, 16]}>
          <Col>
            <Text>{invoice?.reference}</Text>
          </Col>
          {Object.keys(formData).length ? (
            <Col>
              <Badge color="green" />
            </Col>
          ) : null}
        </Row>
      ),
      key: `${index}`,
      children: (
        <ShowAddPayments invoice={invoice} invoiceNumber={invoices.length} />
      )
    });
  });

  return { items };
};
