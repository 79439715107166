import PropTypes from 'prop-types';
import { Skeleton, Row, Col, Typography, Collapse } from 'antd';
import { HourglassOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { useConsultationListContent } from '../listContent/consultation';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { useAuthContext } from '../../../../../contexts/AuthContext';

const { Title } = Typography;

/**
 * Component for displaying a lawyer consultation card.
 * @component
 * @param {Object} customMission The mission object.
 * @param {boolean} customIsLoading The loading state.
 * @param {boolean} fromSyndicRole The role of the user.
 * @returns {JSX.Element} The lawyer consultation card component.
 */
export const LawyerConsultationCard = ({
  customMission,
  customIsLoading,
  fromSyndicRole
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  let mission;
  let isLoading;
  if (!customMission) {
    const { isLoading: contextIsLoading, mission: contextMission } =
      useMissionContext();
    mission = contextMission;
    isLoading = contextIsLoading;
  } else {
    mission = customMission;
    isLoading = customIsLoading;
  }
  const consultationListContent = useConsultationListContent(mission);

  return (
    <Collapse
      defaultActiveKey={mission?.type === 'PCS_1' ? ['1'] : null}
      expandIconPosition="end"
      bordered={!fromSyndicRole}
      items={[
        {
          key: '1',
          label: fromSyndicRole ? (
            <Title level={5} className="yellow-underline">
              {t('missions.cards.titles.lawyer_consultation')}
            </Title>
          ) : (
            t('missions.cards.titles.lawyer_consultation')
          ),
          children: (
            <>
              {mission?.lawyer_step === 'AG_DECISION' ||
              mission?.type === 'PCS_2' ? (
                <Skeleton loading={isLoading} active>
                  <DescriptionList
                    data={consultationListContent}
                    translate
                    vertical
                  />
                </Skeleton>
              ) : null}
              {mission?.lawyer_step === 'LAWYER_SEIZURE' ? (
                <Row justify="center" align="middle" gutter={[16, 16]}>
                  <Col>
                    <HourglassOutlined />
                  </Col>
                  <Col>
                    <Title level={5}>
                      {t(
                        `missions.show.lawyer_consultation.${
                          user.role.split(':')[0] === 'LAWYER'
                            ? 'lawyer'
                            : 'production'
                        }`
                      )}
                    </Title>
                  </Col>
                </Row>
              ) : null}
            </>
          )
        }
      ]}
    />
  );
};

LawyerConsultationCard.propTypes = {
  customMission: PropTypes.shape({
    lawyer_step: PropTypes.string,
    type: PropTypes.string
  }),
  customIsLoading: PropTypes.bool,
  fromSyndicRole: PropTypes.bool
};

LawyerConsultationCard.defaultProps = {
  customMission: null,
  customIsLoading: false,
  fromSyndicRole: false
};
