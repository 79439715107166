import { Route, Routes } from 'react-router-dom';
import { ServicesContextProvider } from '../../../../contexts/ServicesContext';
import { routesCollectiveOwnership } from '../../../../utils/constants/routes/servicesRoutes';
import { Exception } from '../../../../components';
import { DashBoard } from './DashBoard';
import { ServicesActionsToDoList } from '../components/ServicesActionsToDo/ServicesActionsToDoList';
import { ServicesInvoicesList } from '../components/ServicesInvoices/ServicesInvoicesList';
import { CreateUpdateAudit } from './Audit/CreateUpdateAudit';
import { ServicesDraftsList } from '../components/ServicesDrafts/ServicesDraftsList';
import { ServicesPendingValidationList } from '../components/ServicesPendingValidation/ServicesPendingValidationList';
import { CreateUpdateCompliance } from './Compliance/CreateUpdateCompliance';
import { CreateUpdatePublication } from './Publication/CreateUpdatePublication';
import { ComplianceElanCollectiveOwnershipRouter } from './CollectiveOwnerships/ComplianceElanCollectiveOwnershipRouter';

const tradKey = 'COMPLIANCE_LOI_ELAN';
/**
 * Renders the ComplianceElanRouter component.
 * This component provides routing for various services related to Compliance Elan.
 *
 * @returns {JSX.Element} The rendered ComplianceElanRouter component.
 */
export const ComplianceElanRouter = () => (
  <ServicesContextProvider>
    <Routes>
      <Route
        path={routesCollectiveOwnership.DASHBOARD}
        element={<DashBoard />}
      />
      <Route
        path={`${routesCollectiveOwnership.COLLECTIVE_OWNERSHIPS}/*`}
        element={<ComplianceElanCollectiveOwnershipRouter />}
      />
      <Route
        path={routesCollectiveOwnership.ACTIONS_TO_DO}
        element={
          <ServicesActionsToDoList
            tradKey={tradKey}
            missions={['PCS_1', 'PCS_2', 'PCS_3']}
          />
        }
      />
      <Route
        path={routesCollectiveOwnership.DRAFTS}
        element={
          <ServicesDraftsList
            requested_mission_type={['PCS_2', 'PCS_3', 'PCS_PRE_PRODUCTION']}
            with_PCS_PRE_PRODUCTION
          />
        }
      />
      <Route
        path={routesCollectiveOwnership.PENDING_VALIDATION}
        element={
          <ServicesPendingValidationList
            tradKey={tradKey}
            requested_mission_type={[
              'PCS_1',
              'PCS_2',
              'PCS_3',
              'PCS_PRE_PRODUCTION'
            ]}
            with_PCS_PRE_PRODUCTION
          />
        }
      />
      <Route
        path={routesCollectiveOwnership.INVOICES}
        element={
          <ServicesInvoicesList
            tradKey={tradKey}
            missions={['PCS_1', 'PCS_2', 'PCS_3']}
          />
        }
      />
      <Route
        path={`${routesCollectiveOwnership.AUDIT}/edit/:id`}
        element={<CreateUpdateAudit tradKey={tradKey} purpose="edit" />}
      />
      <Route
        path={routesCollectiveOwnership.AUDIT}
        element={<CreateUpdateAudit tradKey={tradKey} purpose="create" />}
      />
      <Route
        path={`${routesCollectiveOwnership.COMPLIANCE}/edit/:id`}
        element={<CreateUpdateCompliance tradKey={tradKey} purpose="edit" />}
      />
      <Route
        path={routesCollectiveOwnership.COMPLIANCE}
        element={<CreateUpdateCompliance tradKey={tradKey} purpose="create" />}
      />
      <Route
        path={`${routesCollectiveOwnership.PUBLICATION}/edit/:id`}
        element={<CreateUpdatePublication tradKey={tradKey} purpose="edit" />}
      />
      <Route
        path={routesCollectiveOwnership.PUBLICATION}
        element={<CreateUpdatePublication tradKey={tradKey} purpose="create" />}
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ServicesContextProvider>
);
