/**
 * Retrieves managers for reassignment based on selected collective ownerships.
 *
 * @function
 *
 * @param {Object} options - The options object.
 * @param {Array} options.syndics - The array of syndics.
 * @param {Array} options.selectedCollectiveOwnerships - The array of selected collective ownerships.
 * @param {Function} options.setIsFieldLoading - The function to set the field loading state.
 * @param {Function} options.setManagers - The function to set the managers.
 * @returns {void} - The return is void.
 */
export const getManagersForReassign = ({
  syndics,
  selectedCollectiveOwnerships,
  setIsFieldLoading,
  setManagers
}) => {
  setIsFieldLoading(true);
  const otherSyndics = syndics.filter(
    (item) =>
      !selectedCollectiveOwnerships.some(
        (ownership) => ownership.syndic?._id === item._id
      )
  );

  const managersInfo = otherSyndics.flatMap((syndic) =>
    syndic.managers?.map((manager) => ({
      ...manager,
      name: `${manager.last_name} ${manager.first_name} - ${syndic.society_name}`
    }))
  );

  setManagers(managersInfo);
  setIsFieldLoading(false);
};
