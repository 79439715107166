import {
  UserOutlined,
  BranchesOutlined,
  SettingOutlined,
  FileTextOutlined,
  CalendarOutlined,
  ReconciliationOutlined,
  CalculatorOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import {
  Building,
  Enterprise,
  DataEnrichment,
  DeliveryParcel,
  Policy,
  Dashboard
} from '@carbon/icons-react';

const navMenuLogos = {
  PCS_APPOINTMENTS_MONITORING: <ScheduleOutlined />,
  BILLS_PHASE_ONE: <CalculatorOutlined />,
  USERS: <UserOutlined />,
  MISSIONS: <DataEnrichment className="anticon" />,
  MONITORING: <ReconciliationOutlined />,
  EVENTS: <CalendarOutlined />,
  SYNDICS: <Enterprise className="anticon" />,
  BILLING_AND_ACCOUNTING: <FileTextOutlined />,
  COLLECTIVE_OWNERSHIPS: <Building className="anticon" />,
  WORKFLOWS: <BranchesOutlined />,
  CONFIGURATIONS: <SettingOutlined />,
  TEMPLATE_DOCS: <FileTextOutlined />,
  CONTACT: '',
  FAQ: '',
  ORDERS: <DeliveryParcel className="anticon" />,
  QUOTATIONS: <Policy className="anticon" />,
  DASHBOARD: <Dashboard className="anticon" />
};

export default navMenuLogos;
