import { Link } from 'react-router-dom';
import { Tag, Popconfirm, Divider } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { userRoles } from '../../../../../utils/constants/tagColors';
import { deleteResource } from '../../../../../utils/deleteResource';

/**
 * The `useColumns` hook generates column definitions for a table component that displays information about users.
 * It includes columns for last name, first name, role, email, phone number, assistant, manager, and actions such as view, edit, and delete.
 *
 * Parameters:
 * @param {Object} params - The parameters object.
 * @param {Object} params.enums - An object containing enumeration values for certain fields like roles, used to populate filters and tag colors.
 * @param {function} params.dispatchAPI - Function used to dispatch API calls, typically for data fetching or updating.
 * @param {function} params.message - Function for displaying feedback messages to the user (e.g., success or error messages).
 * @param {function} params.setForceRefresh - Function to update a state variable that can trigger a re-fetch of table data.
 * @param {boolean} params.forceRefresh - A state variable that, when toggled, indicates the table data should be re-fetched.
 *
 * Returns:
 * An array of column definitions for use in an Ant Design `Table` component. Each column definition includes properties such as:
 * - `title`: The localized title of the column, serving as the header.
 * - `key`: A unique key for the column, used internally by the table for tracking and sorting purposes.
 * - `dataIndex`: The path to the data value for the column in the data source, used to fetch the appropriate value for each cell.
 * - `render`: A function that returns the React elements to be rendered in each cell of the column, such as formatted strings, tags for roles, and action icons.
 * - `sorter`: A boolean or function indicating whether the column is sortable.
 * - `filters`: An array of filter options for the column, applicable to columns like `role`, which may categorize data based on user roles.
 * - `align`: Alignment of the column content (e.g., 'right').
 * - `fixed`: Indicates if the column should be fixed at the 'right' side of the table, preventing it from scrolling out of view horizontally.
 *
 * The hook leverages the `useTranslation` hook from `react-i18next` for localizing column headers and tag contents, ensuring that the table is accessible and understandable in different languages. It utilizes Ant Design's `Tag`, `Popconfirm`, `Link`, and icon components for rendering role tags, confirmation dialogs, navigation links, and action icons.
 *
 * Usage:
 * This hook is intended for use in components where a table displaying a list of users is required. It allows for easy integration of sorting, filtering, formatting, and actions within the table.
 */

export const useColumns = ({
  enums,
  dispatchAPI,
  message,
  setForceRefresh,
  forceRefresh,
  editRolesPermissions
}) => {
  const { t } = useTranslation();

  return [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.function'),
      key: 'role',
      dataIndex: ['role'],
      render: (role) =>
        role ? (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`, {
              defaultValue: role.split(':')[1]
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.roles?.map((r) => ({
        text: t(`users.tags.${r.split(':')[1]}`),
        value: r
      }))
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('users.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) => phone_number?.number,
      sorter: true
    },
    {
      title: t('users.form.assistants'),
      key: 'assistants',
      dataIndex: 'assistants',
      render: (assistants) =>
        assistants?.length
          ? assistants.map(
              (assistant, index) =>
                `${assistant?.last_name || ''} ${assistant?.first_name || ''}${
                  index < assistants.length - 1 ? ', ' : ''
                }`
            )
          : '-',
      sorter: true
    },
    {
      title: t('users.form.managers'),
      key: 'managers',
      dataIndex: 'managers',
      render: (managers) =>
        managers?.length
          ? managers.map(
              (manager, index) =>
                `${manager?.last_name || ''} ${manager?.first_name || ''}${
                  index < managers.length - 1 ? ', ' : ''
                }`
            )
          : '-',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/users/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {editRolesPermissions && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/users/edit/${record?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() =>
                  deleteResource({
                    dispatchAPI,
                    resourceName: 'users',
                    id: record._id,
                    setForceRefresh,
                    forceRefresh,
                    message
                  })
                }
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];
};
