import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFields } from './useFields';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem/generateFormItem';

const tradKey = 'pcs-pre-productions';
const { Text } = Typography;

/**
 * AppointmentForm component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {Object} props.initialValues - The initial form values.
 * @param {Function} props.refreshAppointment - The function to refresh the appointment.
 * @param {Date} props.firstAvailableDate - The first available date for the appointment.
 * @returns {JSX.Element} The rendered AppointmentForm component.
 */
export const AppointmentForm = ({
  form,
  initialValues,
  refreshAppointment,
  firstAvailableDate,
  appointmentInterval
}) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(firstAvailableDate);
  const { fields } = useFields({
    selectedDate,
    form,
    refreshAppointment,
    appointmentInterval
  });

  const disabledDate = (current) => current && current < firstAvailableDate;

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Flex vertical>
      <Flex vertical gap={15} align="center">
        <Text>{t('events.first_appointment_description')}</Text>
        <Row align="center">
          <Text>{t('events.date')}</Text>
          <DatePicker
            format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
            value={selectedDate.isValid() ? selectedDate : null}
            placeholder={t('placeholder.select_appointment')}
            required
            onChange={handleDateChange}
            disabledDate={disabledDate}
            defaultValue={
              firstAvailableDate.isValid() ? firstAvailableDate : null
            }
          />
        </Row>
      </Flex>
      {fields.map((field) => generateFields(tradKey, field))}
    </Flex>
  );
};

AppointmentForm.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    submit: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  refreshAppointment: PropTypes.bool.isRequired,
  firstAvailableDate: PropTypes.instanceOf(dayjs).isRequired,
  extraSubmitValues: PropTypes.shape({}),
  appointmentInterval: PropTypes.number
};

AppointmentForm.defaultProps = {
  extraSubmitValues: {},
  appointmentInterval: null
};
