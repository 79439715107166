import { useParams } from 'react-router-dom';
import { Card, Tabs } from 'antd';
import { DocumentButtons } from '../buttons/DocumentButtons';
import { documentTabs } from '../tabs/documentTabs';
import { useMissionContext } from '../../../../../contexts/MissionContext';
import { handleDocumentType } from '../utils/handleDocumentType';
import { handleMissionTypeForDocuments } from '../utils/handleMissionTypeForDocuments';
import { setTabsItems } from '../utils/setTabsItems';

/**
 * Renders a document card component.
 *
 * @component
 * @returns {JSX.Element} The rendered DocumentCard component.
 */
export const DocumentCard = () => {
  const { type } = useParams();
  const { setDocumentType, setMissionTypeForDocuments, mission } =
    useMissionContext();
  const { tabsItems, pcs1TabsItems, pcs2TabsItems, pcs3TabsItems } =
    documentTabs();

  return (
    <Card extra={<DocumentButtons />}>
      <Tabs
        defaultActiveKey="1"
        items={setTabsItems({
          type,
          lawyerStep: mission.lawyer_step,
          pcs1TabsItems,
          pcs2TabsItems,
          pcs3TabsItems,
          tabsItems
        })}
        destroyInactiveTabPane
        onChange={(el) => {
          if (['PCS_1', 'PCS_2', 'PCS_3'].includes(type)) {
            setMissionTypeForDocuments(
              handleMissionTypeForDocuments(el, type, mission.lawyer_step)
            );
          }
          setDocumentType(handleDocumentType(el));
        }}
      />
    </Card>
  );
};
