export const getEnums = async ({ dispatchAPI, setEnums, message }) => {
  try {
    const { data: syndicEnums } = await dispatchAPI('GET', {
      url: '/syndics/enums'
    });

    syndicEnums.syndic_status = syndicEnums.status;
    delete syndicEnums.status;

    setEnums((prevState) => ({ ...prevState, ...syndicEnums }));

    const { data: contactsEnums } = await dispatchAPI('GET', {
      url: '/contacts/enums'
    });
    setEnums((prevState) => ({ ...prevState, ...contactsEnums }));

    const { data: collectiveOwnershipEnums } = await dispatchAPI('GET', {
      url: '/collective-ownerships/enums'
    });

    collectiveOwnershipEnums.collective_ownership_status =
      collectiveOwnershipEnums.status;
    delete collectiveOwnershipEnums.status;

    setEnums((prevState) => ({ ...prevState, ...collectiveOwnershipEnums }));
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
