import React, { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek } from 'date-fns';

import Calendar from './CalendarBody.tsx';
import { CalendarHeader } from './CalendarHeader.tsx';
import { GenericEvent, CalendarContainerProps } from './types.tsx';
import { createEvents } from './createEvents.tsx';
import { daysToWeekObject } from './utils/daysToWeekObject.tsx';

export const WeeklyCalendar = <T extends GenericEvent>({
  events,
  onEventClick,
  onSelectDate,
  dayRange = 'no-weekend',
  hourStart = 0,
  hourEnd = 26,
  value,
  ISOWeekName = true,
  modes,
  mode,
  setMode,
  subMode,
  weekCellRender,
  extraHeader,
  noAllDayRow,
  view
}: CalendarContainerProps<T>) => {
  const [startWeek, setStartWeek] = useState(startOfWeek(value || new Date()));
  const weekPeriod = {
    startDate: startWeek,
    endDate: endOfWeek(startWeek),
    ISOWeekName
  };

  useEffect(() => {
    if (value && startOfWeek(value).getTime() !== startWeek.getTime()) {
      setStartWeek(value);
    }
  }, [value]);

  useEffect(() => {
    onSelectDate && onSelectDate(startWeek);
  }, [startWeek]);

  const createEventsFromRawData = createEvents(
    events,
    weekCellRender,
    view,
    subMode,
    weekPeriod
  );

  const weekObject = daysToWeekObject(createEventsFromRawData, startWeek);

  return (
    <>
      <CalendarHeader
        startWeek={startWeek}
        setStartWeek={setStartWeek}
        ISOWeekName={ISOWeekName}
        modes={modes}
        mode={mode}
        setMode={setMode}
        extraHeader={extraHeader}
      />
      <Calendar
        weekDates={weekPeriod}
        subMode={subMode}
        getDayEvents={weekObject}
        onEventClick={onEventClick}
        dayRange={dayRange}
        hourStart={hourStart}
        hourEnd={hourEnd}
        noAllDayRow={noAllDayRow}
      />
    </>
  );
};
