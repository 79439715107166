import { InputNumber } from 'antd';

export const useFields = () => {
  const traditionalFields = [
    {
      name: ['RC_or_EDD_update'],
      key: 'RC_or_EDD_update',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['EDD_update'],
      key: 'EDD_update',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['charging_grid'],
      key: 'charging_grid',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['digital_archiving'],
      key: 'digital_archiving',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['publication_requisition'],
      key: 'publication_requisition',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['published_act_package'],
      key: 'published_act_package',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['proxy_fee'],
      key: 'proxy_fee',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['lot_sheet_cost'],
      key: 'lot_sheet_cost',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['fixed_mortgage_tax'],
      key: 'fixed_mortgage_tax',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['publication_contribution'],
      key: 'publication_contribution',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['formality_status'],
      key: 'formality_status',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['fee_nomade_ttc'],
      key: 'fee_nomade_ttc',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['obtaining_all_necessary_documents'],
      key: 'obtaining_all_necessary_documents',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['right_of_use_to_be_ratified_ttc_notary'],
      key: 'right_of_use_to_be_ratified_ttc_notary',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['right_of_use_to_be_ratified_ttc_nomade'],
      key: 'right_of_use_to_be_ratified_ttc_nomade',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['fee_ttc_notary_after_MEC_publication'],
      key: 'fee_ttc_notary_after_MEC_publication',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['fee_ttc_nomade_after_MEC_publication'],
      key: 'fee_ttc_nomade_after_MEC_publication',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['honorary_nomade_sale_ttc'],
      key: 'honorary_nomade_sale_ttc',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['lot_right_of_use_to_be_ratified_notary'],
      key: 'lot_right_of_use_to_be_ratified_notary',
      input: <InputNumber addonAfter="€" />
    }
  ];

  const amountFields = [
    {
      name: ['notary_honorary_ttc_for_pcs_publication'],
      key: 'notary_honorary_ttc_for_pcs_publication',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['nomade_honorary_ttc_for_pcs_publication'],
      key: 'nomade_honorary_ttc_for_pcs_publication',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['enjoyment_rigth_notary_ttc'],
      key: 'enjoyment_rigth_notary_ttc',
      input: <InputNumber addonAfter="€" />
    },
    {
      name: ['enjoyment_rigth_nomade_ttc'],
      key: 'enjoyment_rigth_nomade_ttc',
      input: <InputNumber addonAfter="€" />
    }
  ];

  return { traditionalFields, amountFields };
};
