import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, Space } from 'antd';
import {
  MailOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../../contexts/AuthContext';

/**
 * Renders a button component that allows the user to send collective ownership forms via email.
 *
 * @component
 * @param {Object} mission - The mission object.
 * @returns {JSX.Element} The EmailCollectiveOwnershipFormsButton component.
 */
export const EmailCollectiveOwnershipFormsButton = ({ mission }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { message } = useErrorMessage();

  const handleSend = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const emailArray = values.emails;
      await dispatchAPI('POST', {
        url: `collective-ownership-forms/send-email-qr-code?mission=${mission._id}`,
        body: { recipients: emailArray }
      });
      setLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      message(error);
    }
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} type="primary">
        {`${t('buttons.send_by_email')}`}
        <MailOutlined />
      </Button>
      <Modal
        title={
          <>
            {t('buttons.send_by_email')}
            <MailOutlined style={{ marginLeft: '10px' }} />
          </>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            {t('buttons.cancel')}
          </Button>,
          <Button key="send" type="primary" onClick={handleSend}>
            {t('buttons.send')} <MailOutlined />
          </Button>
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          wrapperCol={{}}
          name="email_list_form"
        >
          <Form.List name="emails" initialValue={['']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={name}
                      label={idx === 0 && t('login.username')}
                      fieldKey={fieldKey}
                      rules={[
                        {
                          required: true,
                          type: 'email'
                        }
                      ]}
                    >
                      <Input placeholder={t('login.username')} />
                    </Form.Item>
                    {fields.length > 1 && (
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    )}
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    loading={loading}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('buttons.add_line')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

EmailCollectiveOwnershipFormsButton.propTypes = {
  mission: PropTypes.shape({
    _id: PropTypes.string
  })
};

EmailCollectiveOwnershipFormsButton.defaultProps = {
  mission: {}
};
