import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useItems } from './useItems';
import { ContentCustom, PageHeaderCustom } from '../../../components';

/**
 * Renders the AccountantExports component.
 * This component displays the accountant exports and their corresponding tabs.
 *
 * @component
 * @returns {JSX.Element} The rendered AccountantExports component.
 */
export const AccountantExports = () => {
  const { items } = useItems({});
  const { t } = useTranslation();

  return (
    <>
      <PageHeaderCustom title={t('accountant-exports.title')} />
      <ContentCustom>
        <Tabs tabPosition="left" items={items} />
      </ContentCustom>
    </>
  );
};
