import { Row, Col } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating the list content for the collective ownership form.
 *
 * @hook
 * @param {Object} data - The data object containing the form data.
 * @returns {Array} - An array of objects representing the list content.
 */
export const useCollectiveOwnershipFormListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    owners,
    physical_person,
    phone_number,
    email,
    adress_if_different,
    batch_numbers,
    private_usages
  } = data;

  const useIcon = (isValidate) => {
    if (isValidate) {
      return <CheckCircleFilled style={{ width: 24, color: 'yellow' }} />;
    }
    return <CloseCircleFilled style={{ width: 24, color: 'grey' }} />;
  };

  return [
    {
      label: 'missions.form.owners_drawer',
      span: 3,
      content: owners || '-'
    },
    {
      label: 'missions.form.physical_person',
      span: 3,
      content: (
        <Row gutter={[16, 16]}>
          <Col span={24}>{useIcon(physical_person)}</Col>
        </Row>
      )
    },
    {
      label: 'missions.form.phone_number',
      span: 3,
      content: phone_number?.number
        ? `${phone_number.country_code} ${phone_number?.number.replace(
            /(\d{2})(?=\d)/g,
            '$1 '
          )}`
        : '-'
    },
    {
      label: 'missions.form.email',
      span: 3,
      content: email || '-'
    },
    {
      label: 'missions.form.adress_if_different',
      span: 3,
      content: adress_if_different || '-'
    },
    {
      label: 'missions.form.batch_numbers_drawer',
      span: 3,
      content: batch_numbers || '-'
    },
    {
      label: 'missions.form.private_usages_drawer',
      span: 3,
      content: (private_usages || []).map((usage) => (
        <Row gutter={[16, 16]} key={usage}>
          <Col key={usage}>{useIcon(true)}</Col>
          {t(`missions.tags.${usage}`)}
        </Row>
      ))
    }
  ];
};
