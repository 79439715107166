import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../../components';
import { ListSyndicRequests } from './ListSyndicRequests';

/**
 * Component that defines the routes for the Syndic Request.
 *
 * @component
 * @returns {React.Component} The component
 */
export const SyndicRequestRouter = () => (
  <Routes>
    <Route index element={<ListSyndicRequests />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
