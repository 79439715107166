import { Empty, Flex, Typography } from 'antd';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { chartsColors } from '../../../../../utils/constants/colors';
import { Mission } from '../../../../../models/Mission';

/**
 * PCSMissionChart Component
 *
 * This component displays a bar chart of PCS mission statistics for a given date.
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.style - Additional styles for the component.
 * @param {Object} props.date - The date for which the statistics are displayed.
 * @param {Function} props.setLegend - Callback function to set the legend data.
 * @returns {JSX.Element} The PCSMissionChart component.
 */
export const PCSMissionChart = ({ style, date, setLegend }) => {
  const { t } = useTranslation();
  const [pcsStats, setPcsStats] = useState([]);
  const [formattedPeriod, setFormattedPeriod] = useState('');

  const formatPcsStats = (data) =>
    data.map((pcsStat) => {
      const formattedPcsStat = pcsStat;
      formattedPcsStat.name = t(
        `dashboard.production.cards.pcs_missions_statistics.${pcsStat.name}`
      );
      return formattedPcsStat;
    });

  const getPcsStats = async (period) => {
    const data = await Mission.getPcsStats({
      values: {
        date: period.toDate()
      }
    });
    setPcsStats(formatPcsStats(data));
    if (setLegend) setLegend(data[0]);
  };

  useEffect(() => {
    getPcsStats(date);
    setFormattedPeriod(
      `${t(
        `calendar.months.${date
          .toDate()
          .toLocaleString('en', { month: 'long' })}`
      )} ${date.year()}`
    );
  }, [date]);

  return (
    <Flex
      style={{ width: '50%', ...style }}
      justify="center"
      align="center"
      vertical
    >
      {pcsStats.length ? (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={pcsStats}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              formatter={(value, name, item) => [
                value,
                t(`dashboard.production.cards.pcs_missions_statistics.${name}`),
                { ...item }
              ]}
            />
            <Bar
              dataKey="IN_PROGRESS"
              fill={chartsColors.MISSIONS.IN_PROGRESS}
            />
            <Bar dataKey="FINISHED" fill={chartsColors.MISSIONS.FINISHED} />
            <Bar dataKey="CANCELLED" fill={chartsColors.MISSIONS.CANCELLED} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Empty />
      )}
      <Typography.Title style={{ margin: 0 }} level={5}>
        {formattedPeriod}
      </Typography.Title>
    </Flex>
  );
};

PCSMissionChart.propTypes = {
  style: PropTypes.shape({}),
  date: PropTypes.instanceOf(dayjs),
  setLegend: PropTypes.func
};

PCSMissionChart.defaultProps = {
  style: {},
  date: null,
  setLegend: null
};
