import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { useHandleResize } from '../../../utils/handleResize';
import { ServicesNavMenu } from './ServicesNavMenu';
import ServiceCustomSelector from '../../ServiceCustomSelector';

const { Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  height: 100vh;
  z-index: 10;
  box-shadow: 1px 0 4px var(--borderColor);
  overflow-x: hidden;
  position: fixed;
  left: 0;
`;

/**
 * Represents the layout component for services.
 *
 * @component
 * @returns {JSX.Element} The rendered ServicesLayout component.
 */
export const ServicesLayout = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    if (location.search === '') {
      document
        .getElementById('mainContent')
        .scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [location]);

  return (
    <StyledLayout as={Layout}>
      <StyledSider
        as={Sider}
        width={256}
        style={{
          backgroundColor: 'transparent'
        }}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <ServiceCustomSelector />
        <ServicesNavMenu setCollapsed={setCollapsed} width={width} />
      </StyledSider>
      <Layout>
        <Outlet />
      </Layout>
    </StyledLayout>
  );
};
