import { Link } from 'react-router-dom';
import { Popconfirm, Divider, Button } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  VerticalAlignMiddleOutlined,
  AuditOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { deleteResource } from '../../../utils/deleteResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Generates columns configuration for the SPF Departmental Archive table.
 *
 * @param {Object} options - Options for generating columns.
 * @param {Function} options.setForceRefresh - Function to trigger a force refresh of the table.
 * @param {boolean} options.forceRefresh - Flag indicating whether to force refresh the table.
 * @param {Object} options.enums - Enumerations for filtering options.
 * @param {Object} options.setSpfDepartmentalArchive - Function to set the SPF Departmental Archive.
 * @returns {Array} - Columns configuration for the SPF Departmental Archive table.
 */

export const useColumns = ({
  setForceRefresh,
  forceRefresh,
  enums,
  setSpfDepartmentalArchive,
  list_duplicates,
  setOpen,
  setRecord
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  return [
    {
      title: t('spf-departmental-archives.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => t(`spf-departmental-archives.tags.${type}`),
      sorter: true,
      filters: enums?.type?.map((r) => ({
        text: t(`spf-departmental-archives.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('spf-departmental-archives.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('spf-departmental-archives.form.postal_code'),
      key: 'address.postal_code',
      dataIndex: 'address',
      render: (address) => address?.postal_code || '',
      sorter: true
    },
    {
      title: t('spf-departmental-archives.form.city'),
      key: 'address.city',
      dataIndex: 'address',
      render: (address) => address?.city || '',
      sorter: true
    },
    {
      title: t('spf-departmental-archives.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) =>
        list_duplicates ? (
          <Link
            to={{
              pathname: `/configurations/merge/${record._id}/${record.duplicate_id}/spf-departmental-archives`,
              search: 'duplicate'
            }}
          >
            <VerticalAlignMiddleOutlined style={{ fontSize: 18 }} />
          </Link>
        ) : (
          <>
            <Link
              to={{
                pathname: `/configurations/spf-departmental-archives/edit/${record?._id}`
              }}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </Link>
            <Divider type="vertical" />

            <Button
              type="link"
              onClick={() => {
                setRecord(record);
                setOpen(true);
              }}
            >
              <AuditOutlined style={{ fontSize: 18 }} />
            </Button>

            <Button
              type="link"
              onClick={() => setSpfDepartmentalArchive(record)}
            >
              <VerticalAlignMiddleOutlined style={{ fontSize: 18 }} />
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() =>
                deleteResource({
                  dispatchAPI,
                  resourceName: 'spf-departmental-archives',
                  id: record._id,
                  setForceRefresh,
                  forceRefresh,
                  message
                })
              }
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: 18 }}
                type="delete"
              />
            </Popconfirm>
          </>
        )
    }
  ];
};
