import { Popconfirm } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { deleteResource } from '../../../utils/deleteResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Custom hook that returns the columns configuration for the notary configurations table.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} options.forceRefresh - The flag indicating whether to force refresh the table.
 * @param {string} options.resourceName - The name of the resource.
 * @returns {Object} - The columns configuration for the notary configurations table.
 */
export const useColumns = ({ setForceRefresh, forceRefresh, resourceName }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const actionButtons = [
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 100,
      render: (record) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() =>
            deleteResource({
              dispatchAPI,
              resourceName,
              id: record._id,
              setForceRefresh,
              forceRefresh,
              message
            })
          }
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--errorColor)', fontSize: 18 }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  const editorNotaries = [
    {
      title: t('editor-notaries.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) =>
        a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase())
    },
    {
      title: t('editor-notaries.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) =>
        a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
    },
    {
      title: t('editor-notaries.form.city'),
      key: 'city',
      dataIndex: 'city',
      sorter: (a, b) => a.city.toLowerCase().localeCompare(b.city.toLowerCase())
    },
    {
      title: t('editor-notaries.form.archivist_notary'),
      key: 'archivist_notary',
      dataIndex: 'archivist_notary',
      render: (notary) => notary?.office_name || '-',
      sorter: (a, b) =>
        a.office_name?.toLowerCase().localeCompare(b.office_name?.toLowerCase())
    },
    ...actionButtons
  ];

  const archivistNotaries = [
    {
      title: t('archivist-notaries.form.office_name'),
      key: 'office_name',
      dataIndex: 'office_name',
      sorter: (a, b) =>
        a.office_name?.toLowerCase().localeCompare(b.office_name?.toLowerCase())
    },
    {
      title: t('archivist-notaries.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    {
      title: t('archivist-notaries.form.phone_number'),
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (phone_number) =>
        phone_number?.number ? `0${phone_number.number}` : '-',
      sorter: true
    },
    {
      title: t('archivist-notaries.form.full_address'),
      key: 'address',
      dataIndex: 'address',
      render: (address) =>
        `${address?.street || ''} ${address?.city || ''} ${
          address?.postal_code
        }`,
      sorter: (a, b) =>
        a.full_address
          ?.toLowerCase()
          .localeCompare(b.full_address?.toLowerCase())
    },
    ...actionButtons
  ];

  return {
    'editor-notaries': editorNotaries,
    'archivist-notaries': archivistNotaries
  };
};
