import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col } from 'antd';

/**
 * SurveyorQuotation component.
 * Renders a form for surveyor quotation inputs.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.recordId - The ID of the record.
 * @returns {JSX.Element} The rendered SurveyorQuotation component.
 */
export const SurveyorQuotation = ({ recordId }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form.Item name={['actions', recordId, 'data', 'description']}>
          <Input
            placeholder={t('placeholder.description')}
            style={{ width: '350px' }}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name={['actions', recordId, 'data', 'observation']}>
          <Input
            placeholder={t('placeholder.observation')}
            style={{ width: '350px' }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

SurveyorQuotation.propTypes = {
  recordId: PropTypes.string.isRequired
};
