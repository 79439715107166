import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { getEnums } from './utils/getEnums';
import { useItems } from './useItems';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Represents the PriceSystem component.
 * @component
 * @example
 * return (
 *   <PriceSystem />
 * )
 */

export const PriceSystem = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [itemsTitle, setItemsTitle] = useState([]);
  const [enums, setEnums] = useState([]);

  useEffect(() => {
    (async () => {
      await getEnums({ dispatchAPI, message, setItemsTitle, setEnums });
    })();
  }, []);

  const { items } = useItems({
    itemsTitle,
    enums,
    t
  });

  return (
    <>
      <PageHeaderCustom title={t('price-systems.title')} />
      <ContentCustom>
        <Tabs tabPosition="left" items={items} />
      </ContentCustom>
    </>
  );
};
