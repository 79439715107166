import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Mission } from '../models/Mission';
import { Invoice } from '../models/Invoice';
import { Quotation } from '../models/Quotation';
import { DocumentRepository } from '../models/DocumentRepository';
import { CerfaDocument } from '../models/CerfaDocument';
import { VariousDocument } from '../models/VariousDocument';
import { CollectiveOwnership } from '../models/CollectiveOwnership';
import { User } from '../models/User';
import { Order } from '../models/Order';
import { updateShowMissionNotifications } from '../utils/updateShowMissionNotifications';
import { updateShowMissionDrawer } from '../utils/updateShowMissionDrawer';
import { GetEnums } from '../utils/getEnums';
import { useAuthContext } from './AuthContext';
import { RequestedDocument } from '../models/RequestedDocument';
import { NotaryDisbursement } from '../models/NotaryDisbursement';
import { DisbursementAndVariedCost } from '../models/DisbursementAndVariedCost';
import { PriceSystem } from '../models/PriceSystem';
import { Alert } from '../models/Alert';
import { Note } from '../models/Note';

export const MissionContext = createContext();

/**
 * Provides the MissionContext to its children components.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The MissionContextProvider component.
 */
export const MissionContextProvider = ({ children }) => {
  const { pathname } = useLocation();
  const location = useLocation();
  const { getEnums } = GetEnums();
  const { user } = useAuthContext();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const [searchValue, setSearchValue] = useState(keyword);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total, range) => `${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });
  const [current, setCurrent] = useState({ principal: 0, sub: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [mission, setMission] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [configDocuments, setConfigDocuments] = useState({
    document_repositories: [],
    cerfa_documents: [],
    various_documents: []
  });
  const [professionals, setProfessionals] = useState({});
  const [documents, setDocuments] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [documentType, setDocumentType] = useState('Cerfa-document');
  const [collectiveOwnership, setCollectiveOwnership] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [pcajpArrays, setPcajpArrays] = useState([]);
  const [pcajpArrayEnums, setPcajpArrayEnums] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState({
    parameters: false,
    ag_decision: false,
    block_mission: false,
    block_mission_credit_note: false,
    lawyer_consultation: { open: false, pcs_step: null },
    disbursement: { open: false, type: '' },
    cerfa_order: {
      open: false,
      missionId: null,
      step: null
    },
    pcajp_array: false,
    documents_to_be_re_written: false,
    intermediate_project: false,
    definitive_project: false,
    finalization: false,
    collective_ownership_form: false,
    requested_document_to_syndic: false,
    matrix_finalization: false,
    notary_transmission: false,
    alert: false,
    note: false,
    cancel_mission: false,
    confirm_refunds: false
  });
  const [syndicInformations, setSyndicInformation] = useState(true);
  const [orders, setOrders] = useState([]);
  const [noInvoiceCreated, setNoInvoiceCreated] = useState(
    'noInvoiceCreated=true'
  );
  const [isOrderCardUp, setIsOrderCardUp] = useState(false);
  const [isShowToBeOrderedCard, setIsShowToBeOrderedCard] = useState(false);
  const [addDocumentCardDatas, setAddDocumentCardDatas] = useState({
    open: false,
    type: ''
  });
  const [billingOptionsColors, setBillingOptionsColors] = useState({});
  const [billingOptionsAmounts, setBillingOptionsAmounts] = useState({
    total_ht: 0
  });
  const [missionTypeForDocuments, setMissionTypeForDocuments] = useState(null);
  const [finalizedInformations, setFinalizedInformations] = useState({});
  const [collectiveOwnershipForm, setCollectiveOwnershipForm] = useState({});
  const [quotations, setQuotations] = useState([]);
  const [requestedDocuments, setRequestedDocuments] = useState([]);
  const [notaryDisbursements, setNotaryDisbursements] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [notes, setNotes] = useState([]);
  const [disbursementAndVariedCosts, setDisbursementAndVariedCosts] = useState(
    []
  );
  const [pcs2PriceSystem, setPcs2PriceSystem] = useState({
    adaptation: 0,
    compliance: 0,
    collective_ownership_regulation_creation: 0
  });

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  /**
   * Retrieves mission data and invoice data for a given mission ID.
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {string} options.id - The ID of the mission.
   * @param {string} options.type - The type of mission.
   * @param {Function} options.setMission - The function to set the mission data.
   * @param {Function} options.setInvoices - The function to set the invoice data.
   * @param {Function} options.setCurrent - The function to set the current status.
   * @param {Function} options.setIsLoading - The function to set the loading state.
   * @param {Function} options.setConfigDocuments - The function to set the document repositories.
   * @param {Function} options.setDocuments - The function to set the documents.
   * @param {Function} options.setProfessionals - The function to set the professionals.
   * @param {Function} options.message - The message function.
   * @param {Object} options.t - The translation object.
   * @returns {Promise<void>} - A promise that resolves when the mission and invoice data is retrieved.
   */
  const getMissionDatas = async ({ id, type, message, t }) => {
    const missionDisplayQuery = () => {
      switch (type) {
        case 'PCS_2':
          return 'PCS_1,PCS_2';
        default:
          return type;
      }
    };

    const [
      missionDatas,
      documentRepositoriesDatas,
      cerfaDocumentsDatas,
      variousDocumentsDatas,
      usersDatas,
      ordersDatas,
      quotationDatas,
      disbursementAndVariedCostsDatas,
      alertDatas,
      noteDatas
    ] = await Promise.all([
      Mission.getResource({
        id,
        populate:
          'syndic,lawyer,notary,manager,collective_ownership,documents,documents.config_document config_document.document_type,collective_ownership.charging_grid charging_grid.columns.column,history.by,pcajp_arrays,pcajp_arrays.data.document,pcajp_arrays.data.document.files'
      }),
      DocumentRepository.getResources({
        extraQuery: `missions_display=${missionDisplayQuery()}`
      }),
      CerfaDocument.getResources({
        extraQuery: `document_type.missions_display=${missionDisplayQuery()}`
      }),
      VariousDocument.getResources({
        extraQuery: `document_type.missions_display=${missionDisplayQuery()}`
      }),
      User.getResources({
        extraQuery: `role=users:LAWYER,users:NOTARY,users:PRESTATION_WORD`
      }),
      Order.getResources({
        extraQuery: `mission=${id}`,
        populate: 'document,cerfa_document_type,expeditor'
      }),
      Quotation.getResources({
        extraQuery: `mission=${id}`
      }),
      DisbursementAndVariedCost.getResources({
        extraQuery: `mission=${id}`
      }),
      Alert.getResources({
        extraQuery: `mission=${id}`
      }),
      Note.getResources({
        extraQuery: `mission=${id}`,
        projection: '_id'
      })
    ]);

    updateShowMissionNotifications({ missionDatas, t, message, user });
    updateShowMissionDrawer({ missionDatas, setIsDrawerOpen });

    const invoicesDatas = await Invoice.getResources({
      extraQuery: `mission=${id}&showMission=${true}`
    });

    if (invoicesDatas.length > 0) {
      setNoInvoiceCreated('');
    }

    // NEED TO REFACTO
    if (missionDatas.collective_ownership) {
      const [collectiveOwnershipDatas] = await Promise.all([
        CollectiveOwnership.getResource({
          id: missionDatas.collective_ownership._id,
          populate:
            'documents,documents.config_document config_document.document_type'
        })
      ]);

      const showOrderCardUp = ordersDatas?.some(
        (obj) =>
          obj?.status === 'ORDERED' && obj?.document?.status === 'EXPECTED'
      );

      const showToBeOrderedCard = ordersDatas?.some(
        (obj) => obj?.status === 'TO_BE_ORDERED'
      );

      setIsShowToBeOrderedCard(showToBeOrderedCard);
      setIsOrderCardUp(showOrderCardUp);
      const mergedDocuments = [
        ...missionDatas.documents,
        ...collectiveOwnershipDatas.documents
      ];

      const uniqueDocuments = Array.from(
        new Map(
          mergedDocuments.map((doc) => [doc._id.toString(), doc])
        ).values()
      );

      setDocuments(uniqueDocuments);
    }
    if (type === 'PCS_1') {
      if (['AG_DECISION', 'FINISHED'].includes(missionDatas.lawyer_step)) {
        setMissionTypeForDocuments('PCS_1');
        const pcs1AuditDocuments = documentRepositoriesDatas.filter(
          (doc) => doc.wording === 'Phase 1 - Audit'
        );

        const pcs1Documents = documentRepositoriesDatas.filter(
          (doc) => doc.wording !== 'Phase 1 - Audit'
        );

        setConfigDocuments({
          document_repositories: {
            PCS_1: pcs1AuditDocuments,
            OTHER: pcs1Documents
          },
          cerfa_documents: cerfaDocumentsDatas,
          various_documents: variousDocumentsDatas
        });
      } else {
        const pcs1Documents = documentRepositoriesDatas.filter(
          (doc) => doc.wording !== 'Phase 1 - Audit'
        );

        setConfigDocuments({
          document_repositories: pcs1Documents,
          cerfa_documents: cerfaDocumentsDatas,
          various_documents: variousDocumentsDatas
        });
      }
    } else if (type === 'PCS_2') {
      const [priceSystem, finalizedInformationsDatas] = await Promise.all([
        PriceSystem.getResources({
          extraQuery: `mission=ADAPTATION,COMPLIANCE,REGULATION_CREATION&deleted=false&start_date<=${dayjs()
            .endOf('day')
            .toISOString()}&end_date>=${dayjs().startOf('day').toISOString()}`
        }),
        Mission.getFinalizedInformations({
          id
        })
      ]);

      setFinalizedInformations(finalizedInformationsDatas);
      setPcs2PriceSystem({
        adaptation: priceSystem.find((obj) => obj.mission === 'ADAPTATION')
          ?.amount_ht,
        compliance: priceSystem.find((obj) => obj.mission === 'COMPLIANCE')
          ?.amount_ht,
        collective_ownership_regulation_creation: priceSystem.find(
          (obj) => obj.mission === 'REGULATION_CREATION'
        )?.amount_ht
      });

      const pcs1Documents = documentRepositoriesDatas.filter((doc) =>
        doc.missions_display.includes('PCS_1')
      );

      const pcs2Documents = documentRepositoriesDatas.filter((doc) =>
        doc.missions_display.includes('PCS_2')
      );

      setConfigDocuments({
        document_repositories: { PCS_1: pcs1Documents, PCS_2: pcs2Documents },
        cerfa_documents: cerfaDocumentsDatas,
        various_documents: variousDocumentsDatas
      });
      setMissionTypeForDocuments('PCS_2');
      const enumsDatas = await getEnums({ resource: 'pcajp-arrays' });
      setPcajpArrayEnums(enumsDatas);
      setPcajpArrays(
        (missionDatas.pcajp_arrays || []).filter((obj) =>
          obj.visible_to_role.includes(
            'NOMADE_PRODUCTION' || user.role.split(':')[1]
          )
        )
      );
    } else if (type === 'PCS_3') {
      const [requestedDocumentDatas, notaryDisbursementDatas] =
        await Promise.all([
          RequestedDocument.getResources({
            extraQuery: 'type=PCS_PUBLICATION'
          }),
          NotaryDisbursement.getResources({
            extraQuery: `mission=${id}`
          })
        ]);

      if (requestedDocumentDatas) setRequestedDocuments(requestedDocumentDatas);
      if (notaryDisbursementDatas)
        setNotaryDisbursements(notaryDisbursementDatas);

      const pcs1Documents = documentRepositoriesDatas.filter((doc) =>
        doc.missions_display.includes('PCS_1')
      );

      const pcs2Documents = documentRepositoriesDatas.filter((doc) =>
        doc.missions_display.includes('PCS_2')
      );

      const pcs3Documents = documentRepositoriesDatas.filter((doc) =>
        doc.missions_display.includes('PCS_3')
      );

      setConfigDocuments({
        document_repositories: {
          PCS_1: pcs1Documents,
          PCS_2: pcs2Documents,
          PCS_3: pcs3Documents
        },
        cerfa_documents: cerfaDocumentsDatas,
        various_documents: variousDocumentsDatas
      });

      setMissionTypeForDocuments('PCS_3');
    } else {
      setConfigDocuments({
        document_repositories: documentRepositoriesDatas,
        cerfa_documents: cerfaDocumentsDatas,
        various_documents: variousDocumentsDatas
      });
    }
    setMission(missionDatas);
    setInvoices(invoicesDatas);
    setAllOrders(ordersDatas);
    setDisbursementAndVariedCosts(disbursementAndVariedCostsDatas);
    setCurrent({
      principal: Mission.getRightMissionSteps(missionDatas.type)[
        missionDatas.steps.principal
      ],
      sub: missionDatas?.steps?.sub
        ? Mission.statusSteps.sub[missionDatas.steps.sub]
        : null
    });
    setQuotations(quotationDatas);
    setProfessionals(User.handleProfessionalsDatas({ data: usersDatas }));
    setNotes(noteDatas);
    setAlerts(alertDatas);
    setIsLoading(false);
  };

  return (
    <MissionContext.Provider
      value={{
        searchValue,
        setPagination,
        pagination,
        getMissionDatas,
        current,
        setCurrent,
        isLoading,
        setIsLoading,
        mission,
        setMission,
        invoices,
        setInvoices,
        configDocuments,
        setConfigDocuments,
        professionals,
        setProfessionals,
        documents,
        setDocuments,
        forceRefresh,
        setForceRefresh,
        addDocumentCardDatas,
        setAddDocumentCardDatas,
        setDocumentType,
        documentType,
        collectiveOwnership,
        setCollectiveOwnership,
        selectedDocuments,
        setSelectedDocuments,
        syndicInformations,
        setSyndicInformation,
        orders,
        setOrders,
        isOrderCardUp,
        isShowToBeOrderedCard,
        isDrawerOpen,
        setIsDrawerOpen,
        allOrders,
        noInvoiceCreated,
        setNoInvoiceCreated,
        billingOptionsColors,
        setBillingOptionsColors,
        billingOptionsAmounts,
        setBillingOptionsAmounts,
        missionTypeForDocuments,
        setMissionTypeForDocuments,
        pcajpArrays,
        pcajpArrayEnums,
        finalizedInformations,
        setCollectiveOwnershipForm,
        collectiveOwnershipForm,
        quotations,
        requestedDocuments,
        notaryDisbursements,
        setNotaryDisbursements,
        disbursementAndVariedCosts,
        setDisbursementAndVariedCosts,
        pcs2PriceSystem,
        alerts,
        notes
      }}
    >
      {children}
    </MissionContext.Provider>
  );
};

export const useMissionContext = () => {
  const context = useContext(MissionContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};

MissionContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
