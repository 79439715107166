import { useState, useEffect } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { GetEnums } from '../../../utils/getEnums';
import { MergeModal } from './MergeComponents/MergeModal';
import { useConfigurationContext } from '../../../contexts/ConfigurationContext';
import { ExtraButtons } from '../utils/ExtraButtons';
import MunicipalitiesModal from './MunicipalitiesModal';

/**
 * Component for listing SPF departmental archives.
 *
 * @component
 * @returns {JSX.Element} JSX element representing the list of SPF departmental archives.
 */

export const ListSpfDepartmentalArchives = () => {
  const { duplicateSpfDepartmentalArchives } = useConfigurationContext();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { getEnums } = GetEnums();
  const [enums, setEnums] = useState({});
  const [spfDepartmentalArchive, setSpfDepartmentalArchive] = useState({});
  const [spfDepartmentalArchiveFilters, setSpfDepartmentalArchiveFilters] =
    useState([]);
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState({});
  const columns = useColumns({
    forceRefresh,
    setForceRefresh,
    setSpfDepartmentalArchive,
    enums,
    setOpen,
    setRecord
  });

  useEffect(() => {
    (async () => {
      const enumsDatas = await getEnums({
        resource: 'spf-departmental-archives'
      });
      setEnums(enumsDatas);
    })();
  }, []);

  return (
    <>
      {open && (
        <MunicipalitiesModal record={record} open={open} setOpen={setOpen} />
      )}
      <ListResource
        resourceName="spf-departmental-archives"
        columns={columns}
        resourceModelName="Spf-departmental-archive"
        customActionColumn
        extraQuery={`type=${spfDepartmentalArchiveFilters.join(',')}`}
        searchBarExtraButtons={
          <ExtraButtons
            duplicatesArray={duplicateSpfDepartmentalArchives}
            resource="spf-departmental-archives"
            enums={enums}
            setFilters={setSpfDepartmentalArchiveFilters}
          />
        }
      />
      {Object.keys(spfDepartmentalArchive).length ? (
        <MergeModal
          spfDepartmentalArchive={spfDepartmentalArchive}
          setSpfDepartmentalArchive={setSpfDepartmentalArchive}
        />
      ) : null}
    </>
  );
};
