import { useGenerateFormItem } from '../../../utils/generateFormItem/generateFormItem';
import { useFields } from '../fields/documentRequestFields';

/**
 * Renders the DocumentRequestForm component.
 *
 * @component
 * @returns {JSX.Element} The rendered DocumentRequestForm component.
 */
export const DocumentRequestForm = () => {
  const generateFields = useGenerateFormItem();
  const { fields } = useFields();

  return fields.map((field) => generateFields('quotations', field));
};
