import { Divider, Row, Col, Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CalculMethods } from '../utils/calculMethods';
import { useNotaryHonoraryFields } from './fields/notaryHonorary';

/**
 * Renders the notary honorary section based on the quotation action.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.actAmount - The quotation action object.
 * @returns {JSX.Element|null} The rendered notary honorary section or null if the action is not supported.
 */
export const NotaryHonorary = ({ actAmount }) => {
  const { t } = useTranslation();
  const { handleAmountsUpdate } = CalculMethods();
  const { actionFieldsMap } = useNotaryHonoraryFields();

  const actions = [
    ...new Set(
      (actAmount.quotation_acts || []).map((act) => act.quotation_action.action)
    )
  ];

  const fieldsToRender = [
    ...new Map(
      actions
        .flatMap((action) => actionFieldsMap[action] || [])
        .map((field) => [field.name, field])
    ).values()
  ];
  return (
    <>
      <Divider>{t('quotations.dividers.notary_honorary')}</Divider>
      <Row gutter={[16, 16]} justify="center">
        {fieldsToRender.map((field) => (
          <Col span={24} key={field.key}>
            <Form.Item
              label={t(field.label)}
              name={[
                'quotation_act_amounts',
                actAmount._id,
                'notary_honorary',
                field.name
              ]}
            >
              <InputNumber
                addonAfter="€"
                onChange={() => handleAmountsUpdate({ actAmount })}
              />
            </Form.Item>
          </Col>
        ))}
      </Row>
    </>
  );
};

NotaryHonorary.propTypes = {
  actAmount: PropTypes.shape({
    quotation_acts: PropTypes.arrayOf(
      PropTypes.shape({
        quotation_action: PropTypes.shape({
          action: PropTypes.string.isRequired
        }).isRequired
      })
    ),
    _id: PropTypes.string
  }).isRequired
};
