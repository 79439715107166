import { useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { GetEnums } from '../../../utils/getEnums';
import { ExtraButtons } from './ExtraButtons';
import { ReassignModal } from './modals/ReassignModal';
import { transformExtraQuery } from '../../../utils/filtersUtils';
import { getFiltersFromURL } from '../../../utils/updateFilter';

/**
 * Renders a list of missions.
 * @component
 * @returns {JSX.Element} The list of missions component.
 */
export const ListMissions = () => {
  const { t } = useTranslation();
  const { getEnums } = GetEnums();
  const [isFiedlLoading, setIsFieldLoading] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [selectedMissions, setSelectedMissions] = useState([]);
  const [filters, setFilters] = useState(getFiltersFromURL());
  const [extraQuery, setExtraQuery] = useState(transformExtraQuery(filters));
  const [enums, setEnums] = useState({});
  const columns = useColumns({
    setSelectedMissions,
    setForceRefresh,
    forceRefresh,
    enums,
    setIsReassignModalOpen
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedMissions(rowRecords);
    }
  };

  useEffect(() => {
    (async () => {
      const enumsDatas = await getEnums({ resource: 'missions' });
      setEnums(enumsDatas);
      setIsFieldLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <>
      <ListResource
        resourceName="missions"
        scroll={{ x: 'max-content' }}
        populate="syndic,manager,collective_ownership"
        extraQuery={extraQuery}
        columns={columns}
        withPageHeader={false}
        forceRefresh={forceRefresh}
        resourceModelName="Mission"
        customActionColumn
        withCreateButton={false}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        searchBarExtraButtons={
          <ExtraButtons
            setFilters={setFilters}
            enums={enums}
            isFiedlLoading={isFiedlLoading}
          />
        }
      />
      {isReassignModalOpen ? (
        <ReassignModal
          setIsReassignModalOpen={setIsReassignModalOpen}
          isReassignModalOpen={isReassignModalOpen}
          selectedMissions={selectedMissions}
          setForceRefresh={setForceRefresh}
          forceRefresh={forceRefresh}
          setSelectedRowKeys={setSelectedRowKeys}
          setSelectedMissions={setSelectedMissions}
        />
      ) : null}
      {selectedRowKeys.length > 0 ? (
        <Row style={{ marginLeft: 20 }}>
          <Button type="primary" onClick={() => setIsReassignModalOpen(true)}>
            {t('buttons.reassign')}
          </Button>
        </Row>
      ) : null}
    </>
  );
};
