import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { Mission } from '../../../../../models/Mission';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * RemoveSelectionButton component.
 *
 * @component
 * @returns {JSX.Element} The RemoveSelectionButton component.
 */
export const RemoveSelectionButton = () => {
  const { t } = useTranslation();
  const { mission, forceRefresh, setForceRefresh, selectedDocuments } =
    useMissionContext();

  return (
    <Col span={24}>
      <Row justify="end">
        <Button
          style={{ marginTop: '10px' }}
          type="link"
          onClick={() =>
            Mission.deleteManyDocument({
              id: mission._id,
              values: selectedDocuments,
              setForceRefresh,
              forceRefresh
            })
          }
          danger
        >
          {t('missions.buttons.remove_selection')}
        </Button>
      </Row>
    </Col>
  );
};
