import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { arrayMove } from '@dnd-kit/sortable';
import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * Provides methods for handling editable table functionality.
 *
 * @hook
 * @returns {Object} - An object containing the editable table methods.
 */

export const EditableTableMethods = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  /**
   * Edits a cell in the document repository.
   *
   * @function
   * @param {Object} options - The options for editing the cell.
   * @param {Object} options.record - The record containing the cell data.
   * @param {string} options.key - The key of the cell to be edited.
   * @param {Object} options.form - The form object used for editing.
   * @param {Function} options.setEditingKey - The function to set the editing key.
   * @returns {void} - This function return nothing.
   */
  const editCell = ({ record, key, form, setEditingKey }) => {
    form.setFieldsValue({
      ...record,
      document_type: record?.document_type?._id,
      application_period: [
        dayjs(record?.application_period?.start),
        dayjs(record?.application_period?.end)
      ]
    });

    setEditingKey(key);
  };

  /**
   * Retrieves resource data from the server.
   * @function
   * @async
   * @param {Object} options - The options for retrieving the resource data.
   * @param {string} options.resource - The resource to retrieve data from.
   * @param {string} options.query - The query string to append to the URL (optional).
   * @param {string} options.searchValue - The search value to append to the URL (optional).
   * @param {Object} options.pagination - The pagination object.
   * @param {Function} options.setPagination - The function to set the pagination object.
   * @returns {Promise<Object|null>} - A promise that resolves to the retrieved data or null if an error occurs.
   */
  const getResourceData = async ({
    resource,
    query,
    searchValue,
    pagination,
    setPagination,
    currentSorter
  }) => {
    try {
      const searchURL = searchValue ? `/search/${searchValue}` : null;

      const { data, headers } = await dispatchAPI('GET', {
        url: `/${resource}${searchURL || ''}?sort=${currentSorter || 'key'}${
          query || ''
        }`
      });

      if (data) {
        if (setPagination) {
          setPagination({
            ...pagination,
            total: parseInt(headers?.['x-Total-Count'] || 0, 10)
          });
        }

        return data;
      }

      return null;
    } catch (e) {
      return message(e);
    }
  };

  /**
   * Handles the addition of a new line in the document repository.
   *
   * @function
   * @async
   * @param {Object} options - The options for adding a new line.
   * @param {Function} options.setForceRefresh - The function for setting the force refresh flag.
   * @param {boolean} options.forceRefresh - The flag indicating whether to force a refresh.
   * @returns {Promise<void>} - A promise that resolves when the line is added successfully.
   */
  const handleAddLine = async ({
    setForceRefresh,
    forceRefresh,
    resource,
    type
  }) => {
    const body = {
      key: dayjs(),
      type: type || undefined
    };

    if (resource === 'document-repositories') {
      const documentKey = uuidv4();
      body.document_key = documentKey;
    }

    const formData = new FormData();
    formData.append('values', JSON.stringify(body));

    try {
      await dispatchAPI('POST', {
        url: resource,
        body: formData
      });
      antdMessage.success(t('datatable.message.success.addline'));
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  /**
   * Handles the checkbox change event.
   *
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {boolean} options.value - The new value of the checkbox.
   * @param {Object} options.record - The record object.
   * @param {string} options.type - The type of checkbox.
   * @param {string} options.resource - The resource to be updated.
   * @param {Function} options.setForceRefresh - The function to set the force refresh flag.
   * @param {boolean} options.forceRefresh - The current value of the force refresh flag.
   * @returns {Promise<void>} - A promise that resolves when the checkbox change is handled.
   */
  const handleCheckbox = async ({
    value,
    record,
    type,
    resource,
    setForceRefresh,
    forceRefresh
  }) => {
    const newBody = {
      [type]: value
    };

    const formData = new FormData();
    formData.append('values', JSON.stringify(newBody));

    try {
      await dispatchAPI('PATCH', {
        url: `/${resource}/${record._id}`,
        body: formData
      });

      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  /**
   * Handles the radio button selection in the editable table.
   *
   * @function
   * @async
   * @param {Object} options - The options for handling the radio button selection.
   * @param {string} options.value - The selected value.
   * @param {Object} options.record - The record object.
   * @param {string} options.type - The type of the radio button.
   * @param {string} options.resource - The resource name.
   * @param {function} options.setForceRefresh - The function to set the force refresh flag.
   * @param {boolean} options.forceRefresh - The current value of the force refresh flag.
   * @returns {Promise<void>} - A promise that resolves when the radio button selection is handled.
   */
  const handleRadio = async ({
    value,
    record,
    type,
    resource,
    setForceRefresh,
    forceRefresh
  }) => {
    const newBody = {
      [type]: value
    };

    const formData = new FormData();
    formData.append('values', JSON.stringify(newBody));

    try {
      await dispatchAPI('PATCH', {
        url: `/${resource}/${record._id}`,
        body: formData
      });

      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  /**
   * Saves the cell data to the server.
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {function} options.setEditingKey - The function to set the editing key.
   * @param {Object} options.record - The record object.
   * @param {function} options.setForceRefresh - The function to set the force refresh flag.
   * @param {boolean} options.forceRefresh - The current value of the force refresh flag.
   * @param {Object} options.form - The form object.
   * @param {function} options.dispatchAPI - The function to dispatch API requests.
   * @param {function} options.message - The function to display messages.
   * @param {string} options.inputType - The input type.
   * @param {string} options.selectType - The select type.
   * @param {string} options.resource - The resource URL.
   * @param {React.Ref} options.selectedValuesRef - The ref for the selected values.
   * @param {string} options.customUrl - The custom URL for the
   * @returns {Promise<void>} - A promise that resolves when the cell data is saved.
   */
  const saveCell = async ({
    setEditingKey,
    record,
    setForceRefresh,
    forceRefresh,
    form,
    inputType,
    selectType,
    resource,
    allMissions,
    selectedValuesRef,
    customUrl
  }) => {
    const values = await form.validateFields();

    try {
      const key = Object.keys(values)[0];

      if (values[key] === undefined) {
        values[key] = null;
      }

      if (values.application_period) {
        values.application_period = {
          start: values.application_period[0],
          end: values.application_period[1]
        };
      }

      if (
        selectedValuesRef.current &&
        ['roles', 'missions', 'cerfa_types'].includes(selectType)
      ) {
        values[key] = selectedValuesRef.current;
      }

      const formData = new FormData();
      formData.append('values', JSON.stringify(values));

      const { data } = await dispatchAPI('PATCH', {
        url: customUrl || `/${resource}/${record._id}`,
        body: formData
      });

      if (typeof data === 'string') {
        antdMessage.success(t(`success.message.${data}`));
      }
    } catch (e) {
      message(e);
    }

    if (
      inputType !== 'select' ||
      allMissions ||
      ['icons', 'document_types', 'accounting_missions', 'pcs_phases'].includes(
        selectType
      )
    ) {
      form.resetFields();
      setEditingKey('');
      setForceRefresh(!forceRefresh);
    }
  };

  /**
   * Updates the color of a record in the document repository.
   * @function
   * @async
   * @param {Object} options - The options for updating the color.
   * @param {Object} options.record - The record to update.
   * @param {Object} options.value - The new color value.
   * @param {Function} options.dispatchAPI - The function for dispatching API requests.
   * @param {Function} options.message - The function for displaying error messages.
   * @returns {Promise<void>} - A promise that resolves when the color is updated.
   */
  const updateColor = async ({ record, value, resource }) => {
    const rgbToHex = (r, g, b) => {
      const hexR = Math.round(r).toString(16).padStart(2, '0');
      const hexG = Math.round(g).toString(16).padStart(2, '0');
      const hexB = Math.round(b).toString(16).padStart(2, '0');
      return `#${hexR}${hexG}${hexB}`;
    };

    const hexColor = rgbToHex(
      value.metaColor.r,
      value.metaColor.g,
      value.metaColor.b
    );

    const newBody = {
      ...record,
      color: hexColor
    };

    const formData = new FormData();
    formData.append('values', JSON.stringify(newBody));

    try {
      await dispatchAPI('PATCH', {
        url: `/${resource}/${record._id}`,
        body: formData
      });
    } catch (e) {
      message(e);
    }
  };

  /**
   * Handles the drag and drop functionality for the editable table.
   * @function
   * @async
   * @param {Object} options - The options for the drag and drop operation.
   * @param {Object} options.active - The active draggable item.
   * @param {Object} options.over - The item being dragged over.
   * @param {Array} options.dataSource - The data source array.
   * @param {Function} options.setDataSource - The function to update the data source.
   * @param {string} options.resource - The resource URL.
   * @returns {Promise<void>} - A promise that resolves when the drag and drop operation is complete.
   */
  const onDragEnd = async ({
    active,
    over,
    dataSource,
    setDataSource,
    resource
  }) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((i) => i.key === active.id);
      const overIndex = dataSource.findIndex((i) => i.key === over?.id);
      const arrayMoveDatas = arrayMove(dataSource, activeIndex, overIndex);

      try {
        const newDataSource = await Promise.all(
          arrayMoveDatas.map(async (item, index) => {
            const updatedData = {
              ...item,
              key: index
            };

            const formData = new FormData();
            formData.append('values', JSON.stringify(updatedData));

            await dispatchAPI('PATCH', {
              url: `${resource}/${updatedData._id}`,
              body: formData
            });

            return updatedData;
          })
        );

        if (newDataSource) {
          setDataSource(newDataSource);
        }
      } catch (e) {
        message(e);
      }
    }
  };

  return {
    editCell,
    getResourceData,
    handleAddLine,
    handleCheckbox,
    handleRadio,
    saveCell,
    updateColor,
    onDragEnd
  };
};
