import { useEffect, useState, useCallback } from 'react';
import { Form, Row, Col } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { GetEnums } from '../../../../utils/getEnums';
import { MunicipalitiesMethods } from '../../municipality/utils/methods';
import { UseCreateUpdate } from './UseCreateUpdate';
import { actionButtons } from './actionButtons';
import { useConfigurationContext } from '../../../../contexts/ConfigurationContext';

/**
 * MergeDuplicates component.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const MergeDuplicates = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { getEnums } = GetEnums();
  const isDuplicateForm = location.search.includes('duplicate');
  const { firstId, secondId, resource } = useParams();
  const { getSpfDepartmentalArchives } = MunicipalitiesMethods();
  const [spfDepartmentalArchives, setSpfDepartmentalArchives] = useState([]);
  const [enums, setEnums] = useState({});
  const { forceRefresh, setForceRefresh } = useConfigurationContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const { mergeButtons, saveButtons } = actionButtons({
    firstId,
    secondId,
    navigate,
    form,
    setForceRefresh,
    forceRefresh,
    resource
  });

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);

    const [spfDepartmentalArchiveDatas, enumsDatas] = await Promise.all([
      getSpfDepartmentalArchives(),
      getEnums({ resource })
    ]);

    setSpfDepartmentalArchives(spfDepartmentalArchiveDatas);
    setEnums(enumsDatas);

    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <>
      <PageHeaderCustom title="Communes - Gestion des doublons" />
      <ContentCustom>
        {mergeButtons}
        <Row>
          {[firstId, secondId].map((id) => (
            <Col lg={12} md={24} sm={24} xs={24}>
              <UseCreateUpdate
                form={form}
                id={id}
                isFieldsLoading={isFieldsLoading}
                enums={enums}
                isDuplicateForm={isDuplicateForm}
                resource={resource}
                spfDepartmentalArchives={spfDepartmentalArchives}
              />
            </Col>
          ))}
        </Row>
        <Row justify="center">{saveButtons}</Row>
      </ContentCustom>
    </>
  );
};
