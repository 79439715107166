import { createGlobalStyle } from 'styled-components';
import '../SCSS/admin/index.scss';

export const AdminTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #222222;
    --addColor: #10B9A8;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #F4F4F4;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  var(--secondaryColor);
    --secondaryColor: #FFD53F;
    --secondaryLight: #F5F0DE;
    --disabledColor: #bfbfbf;
    --bodyBackground: var(--componentBackground);
    --textColor: var(--primaryColor);
    --textColorSecondary: var(--bodyBackground);
    --itemActiveBackground: #F5F0DE;
    --itemHoverBackground: #F5F0DE;
    --borderColor: #d9d9d9;
    --contrastBackground: var(--clientColor);
    --errorColor: #CB2529;
    --errorColorLight: #FCE4E7;
    --addColorLight: #C8FEF9;
    --gray100:#F8F9FA;
    --gray200:#E9ECEF;
    --gray300:#DEE2E6;
    --gray400:#CED4DA;
    --gray500:#ADB5BD;
    --gray600:#6C757D;
    --gray700: #495057;
    --gray800: #343A40;
    --opacitySVG: 1;
    --opacity80: rgba(255, 255, 255, 0.80);
    --opacity25: rgba(0, 0, 0, 0.25);
    --lightBlue: #E8EDFF;
    --lightTurquoise:#E2FCFF;
    --lightGreen:#C8FEF9;
    --primaryColorA04
    --primaryColorA07
    --primaryDark
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;
