import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * Custom hook that returns an array of menu items for plans.
 * @hook
 * @returns {Array} - An array of menu items.
 */
export const useMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mission, setIsDrawerOpen } = useMissionContext();

  return [
    {
      key: 'submit-note',
      label: (
        <Button
          type="link"
          onClick={() => setIsDrawerOpen((prev) => ({ ...prev, note: true }))}
        >
          {t('missions.buttons.submit_note')}
        </Button>
      )
    },
    {
      key: 'create-alert',
      label: (
        <Button
          type="link"
          onClick={() => setIsDrawerOpen((prev) => ({ ...prev, alert: true }))}
        >
          {t('missions.buttons.create_alert')}
        </Button>
      )
    },
    {
      key: 'launch-another-mission',
      label: (
        <Button
          type="link"
          onClick={() =>
            navigate(
              `/collective-ownerships/show/${mission.collective_ownership._id}`,
              { state: { showMissionTab: true } }
            )
          }
        >
          {t('missions.buttons.launch_another_mission')}
        </Button>
      )
    },
    ...(mission.status !== 'CANCELED' && !mission?.is_refunds_confirmed
      ? [
          {
            key: 'cancel-mission',
            label: (
              <Button
                type="link"
                onClick={() =>
                  setIsDrawerOpen((prev) => ({ ...prev, cancel_mission: true }))
                }
              >
                {t('missions.buttons.cancel_mission')}
              </Button>
            )
          }
        ]
      : [])
  ];
};
