import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Table, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { addColumns } from './utils/addColumns';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { getChargingGrid } from './utils/getChargingGrid';
import { EditLotModal } from './EditLotModal';
import { AddLabelAndDateModal } from './AddLabelAndDateModal';
import { AddLotModal } from './AddLotModal';
import { MergeLotModal } from './MergeLotModal';
import { DivisionLotModal } from './DivisionLotModal';
import { changeVersion } from './utils/changeVersion';
import { AddColumnModal } from './AddColumnModal';
import { setRowsData } from './utils/setRowsData';
import { ExportButtons } from './buttons/ExportButtons';
import { ActionButtons } from './buttons/ActionButtons';
import { handleDoubleClick } from './utils/handleDoubleClick';
import { generalHandleKeyPress } from './utils/generalHandleKeyPress';
import { setRowsColors } from './utils/setRowsColors';
import { getTemplate } from './utils/getTemplate';
import { useChargingGridPermissions } from './utils/usePermissions';

/**
 * ChargingGrid Component
 *
 * This component displays a table view of charging grid data with various functionalities
 * such as editing, adding, merging, and dividing lots.
 *
 * @component
 * @param {object} props - The props for the component.
 * @param {object} props.resourceDatas - The collective ownership data.
 * @param {function} props.setResourceDatas - Function to set collective ownership data.
 * @param {boolean} [props.isLoading=false] - Flag indicating if data is loading.
 * @returns {JSX.Element} React component
 */

const ChargingGrid = ({ resourceDatas, setResourceDatas, isLoading }) => {
  const handleSaveAllTableButtonRef = useRef(null);
  const handleEditAllTableButtonRef = useRef(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [chargingGrid, setChargingGrid] = useState({});
  const [count, setCount] = useState(0);
  const [isLotModalOpen, setIsLotModalOpen] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);
  const [isVersionButton, setIsVersionButton] = useState(false);
  const [isFinalVersion, setIsFinalVersion] = useState(false);
  const [isEditLotModalOpen, setIsEditLotModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [isMergeLotModalOpen, setIsMergeLotModalOpen] = useState(false);
  const [isDivisionLotModalOpen, setIsDivisionLotModalOpen] = useState(false);
  const [isColumnModalOpen, setIsColumnModalOpen] = useState(false);
  const [duplicateColumn, setDuplicateColumn] = useState(null);
  const [template, setTemplate] = useState(null);
  const [editKey, setEditKey] = useState(undefined);
  const [rowEditing, setRowEditing] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [isEditLabel, setIsEditLabel] = useState(false);
  const [isModalsOpen, setIsModalsOpen] = useState(true);
  const isLabelAndDate = chargingGrid.label && chargingGrid.document_date;
  const {
    onDoubleClickPermissions,
    columnsButtonsPermissions,
    disabledRowSelection,
    disableActionColumn
  } = useChargingGridPermissions();

  useEffect(() => {
    const isOpen =
      isLotModalOpen ||
      isLabelModalOpen ||
      isEditLotModalOpen ||
      isMergeLotModalOpen ||
      isDivisionLotModalOpen ||
      isColumnModalOpen;
    setIsModalsOpen(isOpen);
  }, [
    isLotModalOpen,
    isLabelModalOpen,
    isEditLotModalOpen,
    isMergeLotModalOpen,
    isDivisionLotModalOpen,
    isColumnModalOpen
  ]);

  useEffect(() => {
    if (!editTable && !isModalsOpen) {
      const handleKeyPress = generalHandleKeyPress({
        selectedRowKey,
        setRowEditing,
        setEditKey,
        setSelectedRowKey,
        rowEditing,
        dataSource,
        chargingGrid,
        dispatchAPI,
        setChargingGrid,
        message,
        form,
        setIsFinalVersion,
        resourceDatas,
        setResourceDatas,
        handleEditAllTableButtonRef,
        t,
        isModalsOpen
      });
      document.addEventListener('keydown', handleKeyPress);
      return () => document.removeEventListener('keydown', handleKeyPress);
    }
    return null;
  }, [selectedRowKey, rowEditing, isModalsOpen]);

  const isEditing = (record, key) => Number(record.key) === Number(key);

  useEffect(() => {
    if (Object.keys(chargingGrid).length) {
      const rowData = setRowsData({ chargingGrid, setCount, setDataSource });
      const inputRefs = chargingGrid.columns.map(() => React.createRef());
      const inputTableRefs = chargingGrid.columns.map(() =>
        rowData.map(() => React.createRef())
      );

      addColumns({
        data: chargingGrid.columns,
        dataSource: rowData,
        dispatchAPI,
        chargingGrid,
        message,
        setColumns,
        t,
        isEditing,
        key: editKey,
        setChargingGrid,
        tableForm: form,
        setIsColumnModalOpen,
        setDuplicateColumn,
        isFinalVersion,
        setIsFinalVersion,
        setEditKey,
        setResourceDatas,
        resourceDatas,
        inputRefs,
        inputTableRefs,
        editTable,
        setEditTable,
        handleSaveAllTableButtonRef,
        columnsButtonsPermissions
      });
    }
  }, [chargingGrid, editKey, editTable]);

  useEffect(() => {
    getChargingGrid({
      resourceDatas,
      setChargingGrid,
      setIsVersionButton
    });
    getTemplate({ dispatchAPI, setTemplate, message });
  }, []);

  useEffect(() => {
    if (chargingGrid._id) {
      changeVersion({
        dispatchAPI,
        chargingGrid,
        isFinalVersion,
        setChargingGrid,
        message
      });
    }
  }, [isFinalVersion]);

  const onSelectChange = (_, selectedRow) => {
    const rowKeys = selectedRow.map((row) => row.key);
    setSelectedRowKeys(rowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (record, selectedRow) => {
      onSelectChange(record, selectedRow);
    },
    getCheckboxProps: (record) => ({
      disabled:
        record.delete_row ||
        record.Lot.value === 'Total' ||
        record.Lot.value === 'Voulu'
    })
  };

  return (
    <>
      <Row style={{ marginBottom: 12 }}>
        {chargingGrid && (
          <ExportButtons
            chargingGrid={chargingGrid}
            columns={columns}
            isFinalVersion={isFinalVersion}
            dispatchAPI={dispatchAPI}
            message={message}
            resourceDatas={resourceDatas}
            setChargingGrid={setChargingGrid}
            setIsVersionButton={setIsVersionButton}
            dataSource={dataSource}
            template={template}
            t={t}
            setIsLabelModalOpen={setIsLabelModalOpen}
            setIsEditLabel={setIsEditLabel}
            isLabelAndDate={isLabelAndDate}
          />
        )}
        {Object.keys(resourceDatas).length ? (
          <ActionButtons
            isLoading={isLoading}
            setIsFinalVersion={setIsFinalVersion}
            resourceDatas={resourceDatas}
            setChargingGrid={setChargingGrid}
            setIsVersionButton={setIsVersionButton}
            chargingGrid={chargingGrid}
            isVersionButton={isVersionButton}
            dispatchAPI={dispatchAPI}
            setResourceDatas={setResourceDatas}
            message={message}
            setSelectedRowKeys={setSelectedRowKeys}
            t={t}
            isFinalVersion={isFinalVersion}
            setIsLotModalOpen={setIsLotModalOpen}
            setIsEditLotModalOpen={setIsEditLotModalOpen}
            setIsMergeLotModalOpen={setIsMergeLotModalOpen}
            setIsDivisionLotModalOpen={setIsDivisionLotModalOpen}
            setIsColumnModalOpen={setIsColumnModalOpen}
            setDuplicateColumn={setDuplicateColumn}
            selectedRowKeys={selectedRowKeys}
            setIsLabelModalOpen={setIsLabelModalOpen}
            form={form}
            dataSource={dataSource}
            setEditTable={setEditTable}
            editTable={editTable}
            handleSaveAllTableButtonRef={handleSaveAllTableButtonRef}
            handleEditAllTableButtonRef={handleEditAllTableButtonRef}
            setSelectedRowKey={setSelectedRowKey}
            isLabelAndDate={isLabelAndDate}
          />
        ) : null}
      </Row>
      <Form form={form}>
        <Table
          className="grid-table"
          bordered={false}
          dataSource={dataSource}
          columns={disableActionColumn ? columns.slice(1) : columns}
          style={{ overflowY: 'visible' }}
          rowClassName={(record) =>
            setRowsColors({ record, dataSource, selectedRowKey })
          }
          pagination={false}
          rowSelection={!disabledRowSelection && rowSelection}
          size="small"
          scroll={{ x: 1000 }}
          onRow={(record, key) => ({
            onClick: () => {
              setSelectedRowKey(key);
            },
            onDoubleClick: onDoubleClickPermissions
              ? () => {}
              : () => {
                  handleDoubleClick({
                    rowEditing,
                    dataSource,
                    selectedRowKey,
                    t,
                    setEditKey,
                    setRowEditing,
                    form,
                    record
                  });
                }
          })}
        />
      </Form>
      {isColumnModalOpen && (
        <AddColumnModal
          isColumnModalOpen={isColumnModalOpen}
          setIsColumnModalOpen={setIsColumnModalOpen}
          chargingGrid={chargingGrid}
          t={t}
          dispatchAPI={dispatchAPI}
          message={message}
          setChargingGrid={setChargingGrid}
          id={id}
          setResourceDatas={setResourceDatas}
          duplicateColumn={duplicateColumn}
          setDuplicateColumn={setDuplicateColumn}
          setIsFinalVersion={setIsFinalVersion}
        />
      )}
      {isMergeLotModalOpen && dataSource.length && (
        <MergeLotModal
          isMergeLotModalOpen={isMergeLotModalOpen}
          setIsMergeLotModalOpen={setIsMergeLotModalOpen}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          dataSource={dataSource}
          t={t}
          dispatchAPI={dispatchAPI}
          chargingGrid={chargingGrid}
          message={message}
          setChargingGrid={setChargingGrid}
          resourceDatas={resourceDatas}
          setResourceDatas={setResourceDatas}
        />
      )}
      {isDivisionLotModalOpen && (
        <DivisionLotModal
          isDivisionLotModalOpen={isDivisionLotModalOpen}
          setIsDivisionLotModalOpen={setIsDivisionLotModalOpen}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          dataSource={dataSource}
          t={t}
          dispatchAPI={dispatchAPI}
          chargingGrid={chargingGrid}
          message={message}
          setChargingGrid={setChargingGrid}
          resourceDatas={resourceDatas}
          setResourceDatas={setResourceDatas}
        />
      )}
      {isLotModalOpen && chargingGrid.data && (
        <AddLotModal
          isLotModalOpen={isLotModalOpen}
          setIsLotModalOpen={setIsLotModalOpen}
          chargingGrid={chargingGrid}
          t={t}
          count={count}
          dataSource={dataSource}
          dispatchAPI={dispatchAPI}
          message={message}
          resourceDatas={resourceDatas}
          setChargingGrid={setChargingGrid}
          tableForm={form}
          setIsVersionButton={setIsVersionButton}
          setIsFinalVersion={setIsFinalVersion}
          setResourceDatas={setResourceDatas}
        />
      )}
      <AddLabelAndDateModal
        isLabelModalOpen={isLabelModalOpen}
        dispatchAPI={dispatchAPI}
        chargingGrid={chargingGrid}
        setChargingGrid={setChargingGrid}
        setIsLabelModalOpen={setIsLabelModalOpen}
        message={message}
        t={t}
        resourceDatas={resourceDatas}
        setResourceDatas={setResourceDatas}
        isEditLabel={isEditLabel}
      />
      {isEditLotModalOpen && (
        <EditLotModal
          isEditLotModalOpen={isEditLotModalOpen}
          setIsEditLotModalOpen={setIsEditLotModalOpen}
          isLoading={isLoading}
          chargingGrid={chargingGrid}
          dataSource={dataSource}
          setChargingGrid={setChargingGrid}
          dispatchAPI={dispatchAPI}
          message={message}
          t={t}
          selectedRowKeys={selectedRowKeys}
          setIsFinalVersion={setIsFinalVersion}
          setSelectedRowKeys={setSelectedRowKeys}
          resourceDatas={resourceDatas}
          setResourceDatas={setResourceDatas}
        />
      )}
    </>
  );
};

export default ChargingGrid;

ChargingGrid.propTypes = {
  resourceDatas: PropTypes.shape({
    _id: PropTypes.string,
    charging_grid: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        version: PropTypes.number,
        date: PropTypes.string
      })
    )
  }).isRequired,
  setResourceDatas: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

ChargingGrid.defaultProps = {
  isLoading: false
};
