import { createContext, useContext, useState } from 'react';
import { useAuthContext } from './AuthContext';

export const AgendaContext = createContext();

/**
 * @description AgendaContextProvider component
 * @param {Object} props - The props object.
 * @param {Object} props.children - The children object.
 * @returns {Object} - The JSX object.
 */
export const AgendaContextProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openedDrawer, setOpenedDrawer] = useState('');
  const [event, setEvent] = useState(null);
  const [purpose, setPurpose] = useState('');
  const [formToOpen, setFormToOpen] = useState('');
  const [drawerTitle, setDrawerTitle] = useState('');
  const [keywords, setKeywords] = useState('');
  const { user } = useAuthContext();
  const [customUser, setCustomUser] = useState(null);

  const isEventOwner = () => event?.user === user._id;

  return (
    <AgendaContext.Provider
      value={{
        isDrawerOpen,
        setIsDrawerOpen,
        event,
        setEvent,
        purpose,
        setPurpose,
        formToOpen,
        setFormToOpen,
        openedDrawer,
        setOpenedDrawer,
        drawerTitle,
        setDrawerTitle,
        isEventOwner,
        customUser,
        setCustomUser,
        keywords,
        setKeywords
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};

export const useAgendaContext = () => {
  const context = useContext(AgendaContext);
  if (context === undefined) {
    throw new Error(
      'useAgendaContext must be used within a AgendaContextProvider'
    );
  }
  return context;
};
