import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row, Card, Skeleton } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { getUser } from '../utils/getUser';
import { getEnums } from '../utils/getEnums';
import { ShowButtons } from '../utils/ShowButtons';
import { CollectiveOwnerships } from './Items/CollectiveOwnerships';
import { Informations } from './Items/Informations';
import { CollectiveOwnership } from '../../../models/CollectiveOwnership';
import StatisticLawyerDetails from './Items/StatisticLawyerDetails';

import { Assistants } from './Items/Assistants';

/**
 * Component for displaying user details.
 * @component
 *
 * @returns {JSX.Element} The rendered component.
 */
export const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user: userFromContext } = useAuthContext();
  const { role } = userFromContext;
  const editUserPermissions = ![
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER'
  ].includes(role);
  const showCollectiveOwnershipCard = ![
    'users:SYNDIC_MANAGER',
    'users:SYNDIC_ASSISTANT'
  ].includes(role);

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [collectiveOwnerShipsEnums, setEnums] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);
  const [linkedOnCollectiveOwnership, setLinkedOnCollectiveOwnership] =
    useState(false);

  useEffect(() => {
    (async () => {
      await getUser({ setIsLoading, dispatchAPI, id, setUser, message });
      await getEnums({ dispatchAPI, setEnums, message });
      const collectiveOwnership = await CollectiveOwnership.getResources({
        populate: 'syndic',
        extraQuery: `manager._id=${id}`
      });
      setLinkedOnCollectiveOwnership(collectiveOwnership.length > 0);
    })();
  }, [forceRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={`${user?.last_name || ''} ${user?.first_name || ''}`}
        extra={
          editUserPermissions && (
            <ShowButtons
              user={user}
              setForceRefresh={setForceRefresh}
              forceRefresh={forceRefresh}
            />
          )
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Informations user={user} isLoading={isLoading} t={t} />
          </Col>
          {user.role === 'users:LAWYER' && (
            <Col xs={24} lg={12}>
              <Card>
                <StatisticLawyerDetails />
              </Card>
            </Col>
          )}
          {user.role === 'users:SYNDIC_MANAGER' && (
            <Col xs={24} lg={12}>
              <Card title={t('users.form.assistants')}>
                <Skeleton loading={isLoading} active>
                  <Assistants t={t} />
                </Skeleton>
              </Card>
            </Col>
          )}
        </Row>
        {linkedOnCollectiveOwnership && showCollectiveOwnershipCard
          ? !(
              <CollectiveOwnerships
                enums={collectiveOwnerShipsEnums}
                isLoading={isLoading}
                t={t}
              />
            )
          : null}
      </ContentCustom>
    </>
  );
};
