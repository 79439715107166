/**
 * Returns a configuration object for creating a resource.
 * @returns {Object} The configuration object.
 */
export const useConfig = ({ state, totalTTC, orders }) => ({
  onCreateResource: {
    setBody: (data) => {
      const updatedCreditNotes = data.credit_notes || {};

      if (Object.keys(updatedCreditNotes).length) {
        Object.keys(updatedCreditNotes).forEach((key) => {
          updatedCreditNotes[key].total_ttc = totalTTC[key].total_billed;
        });
      }

      const updatedData = {
        ...data,
        type: state.type,
        total_ht: data.type === 'CREDIT_NOTE' ? -data.total_ht : data.total_ht,
        pcs_step: state?.pcs_step || state?.invoice?.pcs_step || null,
        invoice: state?.invoice?._id || null,
        total_ttc: totalTTC.total_billed,
        credit_notes: updatedCreditNotes,
        orders: (orders || []).map((el) => {
          if (data.orders[el._id]) {
            return {
              ...el,
              to_bill: data.orders[el._id].to_bill
            };
          }
          return el;
        })
      };

      if (updatedData.supply_TTC_due_to_plans) {
        updatedData.total_ht += updatedData.supply_TTC_due_to_plans;
        delete updatedData.supply_TTC_due_to_plans;
      }

      if (state.pcs_step === 'PCS_3') {
        updatedData.wordings = [
          {
            wording: 'Honoraires',
            reduction: 0,
            fee: 0,
            tva: data.tva_percentage,
            disbursement: 0,
            total: updatedData.total_ttc
          },
          {
            wording: 'Débours notaire TTC',
            reduction: 0,
            fee: 0,
            tva: data.tva_percentage,
            disbursement: data.disbursement || 0,
            total: data.disbursement || 0
          }
        ];
      }

      return updatedData;
    }
  }
});
