import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns for the missing documents statistics list
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useMonitoringProdColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('statistics.columns.consultant'),
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: true
    },
    {
      title: t('statistics.columns.prod_monitoring.total_syndic'),
      dataIndex: 'total_syndic',
      key: 'total_syndic',
      sorter: true
    },
    {
      title: t('statistics.columns.prod_monitoring.total_collective_ownership'),
      dataIndex: 'total_collective_ownership',
      key: 'total_collective_ownership',
      sorter: true
    },
    {
      title: t('statistics.columns.prod_monitoring.total_processed_folders'),
      dataIndex: 'total_processed_folders',
      key: 'total_processed_folders',
      sorter: true
    },
    {
      title: t('statistics.columns.prod_monitoring.total_folders_to_process'),
      dataIndex: 'total_folders_to_process',
      key: 'total_folders_to_process',
      sorter: true
    }
  ];
};
