import { Route, Routes } from 'react-router-dom';
import { AddPayments } from './add-payments/AddPayments';
import { Exception } from '../../../components';
import { ListInvoices } from './ListInvoices';
import { ShowInvoice } from './ShowInvoice/ShowInvoice';
import { CreateInvoice } from './CreateInvoice/CreateInvoice';
import { InvoiceContextProvider } from '../../../contexts/InvoiceContext';

/**
 * Renders the router component for invoices.
 * @returns {JSX.Element} The router component.
 */
export const InvoiceRouter = () => (
  <InvoiceContextProvider>
    <Routes>
      <Route path="/add-payments" element={<AddPayments />} />
      <Route path="/" element={<ListInvoices />} />
      <Route path="/show/:id" element={<ShowInvoice />} />
      <Route path="/create" element={<CreateInvoice />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </InvoiceContextProvider>
);
