import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { invoiceTag, missionTag } from '../../../../utils/constants/tagColors';

/**
 * Custom hook that returns an array of objects containing invoice information.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Object} options.invoice - The invoice object.
 * @returns {Array} An array of objects containing invoice information.
 */
export const useInvoiceInformationsListContent = ({ invoice }) => {
  const { t } = useTranslation();
  const {
    reference,
    type,
    sending_date,
    cancellation_fee,
    tva_percentage,
    tva,
    total_ttc,
    paid,
    left_to_pay,
    status,
    mission
  } = invoice;
  const { syndic = null, collective_ownership = null } = mission || {};

  return [
    {
      label: 'invoices.form.reference',
      span: 1,
      content: reference || ''
    },
    {
      label: 'invoices.form.type',
      span: 1,
      content: type ? (
        <Tag color={invoiceTag[type]}>
          {t(`invoices.tags.${type}`, {
            defaultValue: type
          })}
        </Tag>
      ) : (
        'N/A'
      )
    },
    {
      label: 'invoices.form.status',
      span: 1,
      content: status ? (
        <Tag color={invoiceTag[status]}>
          {t(`invoices.tags.${status}`, {
            defaultValue: status
          })}
        </Tag>
      ) : (
        'N/A'
      )
    },
    {
      label: 'invoices.form.sending_date',
      span: 1,
      content: sending_date ? dayjs(sending_date).format('DD-MM-YYYY') : '-'
    },
    {
      label: 'invoices.form.syndic',
      span: 1,
      content: syndic
        ? `${syndic?.society_name || '-'} ${
            syndic?.code ? `(${syndic?.code})` : ''
          }`
        : '-'
    },
    {
      label: 'invoices.form.collective_ownership',
      span: 1,
      content: `${collective_ownership?.code || ''} - ${
        collective_ownership?.first_address?.number || ''
      } ${collective_ownership?.first_address?.street || ''} ${
        collective_ownership?.first_address?.postal_code || ''
      } ${collective_ownership?.first_address?.city || ''} ${
        collective_ownership?.cadastral_reference
          ? `(${collective_ownership?.cadastral_reference})`
          : ''
      }`
    },
    {
      label: 'invoices.form.mission',
      span: 1,
      content: mission?.type ? (
        <Tag color={missionTag[mission?.type]}>
          {t(`missions.tags.${mission?.type}`, {
            defaultValue: mission?.type
          })}
        </Tag>
      ) : (
        ''
      )
    },
    {
      label: 'invoices.form.billing_address',
      span: 1,
      content: mission?.syndic?.billing_address
        ? `${mission?.syndic?.billing_address?.number || ''} ${
            mission?.syndic?.billing_address?.street || ''
          } ${mission?.syndic?.billing_address?.postal_code || ''} ${
            mission?.syndic?.billing_address?.city || ''
          }`
        : '-'
    },
    {
      label: 'invoices.form.cancellation_fee',
      span: 1,
      content: cancellation_fee
        ? `${parseFloat(cancellation_fee).toFixed(2)} €`
        : '0.00 €'
    },
    {
      label: 'invoices.form.tva',
      span: 1,
      content: tva_percentage
        ? `${tva_percentage} %`
        : `${Math.round((tva / total_ttc) * 100)} %`
    },
    {
      label: 'invoices.form.total_ttc',
      span: 1,
      content: total_ttc ? `${parseFloat(total_ttc).toFixed(2)} €` : '0.00 €'
    },
    {
      label: 'invoices.form.paid',
      span: 1,
      content: paid ? `${parseFloat(paid).toFixed(2)} €` : '0.00 €'
    },
    {
      label: 'invoices.form.left_to_pay',
      span: 1,
      content: left_to_pay
        ? `${parseFloat(left_to_pay).toFixed(2)} €`
        : '0.00 €'
    }
  ];
};
