import { useState } from 'react';
import PropTypes from 'prop-types';
import { ListResource } from '../../../../components';
import { useColumns } from './columns/invoicesColumns';

/**
 * @component
 * @description Renders a list of invoices for a collective ownership entity.
 * @returns {JSX.Element} The invoices list component.
 */
export const Invoices = ({ id }) => {
  const columns = useColumns({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedInvoices(rowRecords.filter((invoice) => invoice?.status));
    }
  };

  return (
    <ListResource
      resourceName={`invoices/collective-ownership/${id}`}
      columns={columns}
      resourceModelName="Invoice"
      customActionColumn
      withPageHeader={false}
      withCreateButton={false}
      withSearchBar={false}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection
      }}
    />
  );
};

Invoices.propTypes = {
  id: PropTypes.string.isRequired
};
