import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, message as antdMessage } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import FilesManager from './FilesManager/FilesManager';
import { handleFormDataWithFiles } from './utils/handleFormDataWithFiles';
import { handleFileActionsOnFetch } from './utils/handleFileActionsOnFetch';
import { checkDraggerTypes } from './utils/checkDraggerTypes';
import { duplicateEmailErrorMessage } from '../../utils/duplicateEmailErrorMessage';
import { useDownloadDocument } from '../../utils/downloadDoc';

/**
 * `CreateUpdateContainer` is a container for creating and updating resources, which includes form controls and various handlers for creating and updating resources.
 * @component
 * @prop {Object} props - The component [
 *] @prop {string} purpose - Defines whether the purpose of the form is to 'edit' or 'create'.
 * @prop {Array} fields - An array of field objects for the form.
 * @prop {string} resource - The name of the resource being created or edited.
 * @prop {boolean} [loadingFields=false] - A flag to determine whether the form fields are being loaded.
 * @prop {string} baseUrl - Base URL for the API endpoints.
 * @prop {Object} [config={}] - A configuration object that provides handlers for getting, creating, and updating resources.
 * @prop {ReactElement} [formExtra=null] - Any extra form elements to be added.
 * @prop {string} [tradKey=null] - Key used for translation.
 * @prop {string} [submitLabel=null] - Label for the form submission button.
 * @prop {Function} [customSubmit=null] - A custom function to handle form submission.
 * @prop {boolean} [isParentLoading=false] - A flag to determine if the parent component is being loaded.
 * @prop {boolean} [withFilesManager=true] - A flag to determine whether to use a file manager in the form.
 * @prop {boolean} [withEnums=true] - A flag to determine whether to use enums in the form.
 * @prop {Object} [customFormInstance=null] - A custom form instance to be used.
 * @prop {boolean} [withCustomButtons=false] - A flag to determine whether to use custom buttons in the form.
 * @prop {boolean} [withCustomPageHeader=false] - A flag to determine whether to use a custom page header.
 * @prop {string} [customId=null] - A custom ID for the resource.
 * @prop {Function} [setForceRefresh=null] - A function to set the force refresh flag.
 * @prop {boolean} [forceRefresh=false] - A flag to determine whether to force refresh the form.
 * @prop {string} [successMessage=null] - A success message to display after form submission.
 * @prop {string} [layout='horizontal'] - The form layout (e.g., "horizontal" or "vertical").
 * @prop {Object} [customLabelCol] - The custom label column configuration.
 * @prop {Object} [customWrapperCol] - The custom wrapper column configuration.
 * @prop {Function} [cancelCustomAction] - The function to cancel the custom action.
 * @prop {Function} [setIsCreateUpdateOpen] - The function to set the create/update open state.
 * @prop {boolean} [isCreateUpdateOpen] - The create/update open state.
 * @prop {string} [populate] - The populate query parameter.
 * @prop {string} [extraQuery] - The extra query parameter.
 * @prop {Object} [customSubmitButtonStyle] - The custom style for the submit button.
 * @prop {Object} [customFormData=null] - A custom form data object to be used.
 * @prop {Object} [customUpdateFormData] - The custom update form data.
 * @prop {Function} [setResourceData] - The function to set the resource data.
 * @prop {boolean} [disabledSubmit] - Indicates if the submit button is disabled.
 * @prop {Function} [customErrorMessage] - The custom error message function.
 * @prop {ReactNode} [customActionButtons] - The custom action buttons.
 * @prop {boolean} [withoutHandleFileActionsOnFetch] - Indicates if file actions should be handled on fetch.
 * @prop {React.Ref} ref - The ref for the component.
 * @returns {ReactNode} The rendered CreateUpdateContainer component.
 */
export const CreateUpdateContainer = forwardRef(
  (
    {
      style,
      className,
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      withFilesManager,
      withEnums,
      customFormInstance,
      withCustomButtons,
      withCustomPageHeader,
      customId,
      setForceRefresh,
      forceRefresh,
      successMessage,
      layout,
      customLabelCol,
      customWrapperCol,
      cancelCustomAction,
      setIsCreateUpdateOpen,
      isCreateUpdateOpen,
      populate,
      extraQuery,
      customSubmitButtonStyle,
      customFormData,
      customUpdateFormData,
      setResourceData,
      disabledSubmit = false,
      customErrorMessage,
      customActionButtons,
      withoutHandleFileActionsOnFetch,
      customNavigate
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [draggerFilesList, setDraggerFilesList] = useState([]);
    const [fieldsFilesList, setFieldsFileList] = useState([]);
    const [filesConfiguration, setFilesConfiguration] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [draggerFilesKeys, setDraggerFilesKeys] = useState([]);
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;
    const { downloadDocument, viewDocument } = useDownloadDocument();

    const getResourceFilesKeys = async () => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${resource}/enums`
        });
        setDraggerFilesKeys(
          data.fileKeys
            .filter((enumItem) => enumItem.source === 'dragger')
            .map((enumItem) => enumItem.key)
        );
        const transformedObject = {};

        data.fileKeys.forEach((item) => {
          if (item.source === 'field') {
            transformedObject[item.key] = [];
          }
        });
        setFieldsFileList(transformedObject);
        return setIsLoading(false);
      } catch (e) {
        return message(e);
      }
    };

    const updateResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = customUpdateFormData || new FormData();

      if (!customFormData) {
        handleFormDataWithFiles(
          files,
          draggerFilesList,
          formData,
          filesConfiguration,
          purpose,
          customFormData
        );
      }

      const values =
        onUpdateResource && onUpdateResource.setBody
          ? onUpdateResource.setBody(body)
          : body;

      if (values) {
        if (customFormData) {
          customFormData.append(
            'values',
            JSON.stringify({
              ...values
            })
          );
        } else {
          formData.append(
            'values',
            JSON.stringify({
              ...values
            })
          );
        }

        try {
          await dispatchAPI('PATCH', {
            url: `${baseUrl}/${customId || id}${extraQuery}`,
            body: formData
          });
          if (setForceRefresh) setForceRefresh(!forceRefresh);
          if (successMessage) antdMessage.success(successMessage);
          if (setIsCreateUpdateOpen) setIsCreateUpdateOpen(isCreateUpdateOpen);
          if (customNavigate) customNavigate();
          if (!setIsCreateUpdateOpen && !customNavigate) navigate(-1);

          setIsSubmitting(false);
        } catch (e) {
          if (customErrorMessage) {
            customErrorMessage(e);
          } else {
            duplicateEmailErrorMessage({ e, message, t });
          }
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
      }
    };

    const createResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = customFormData || new FormData();

      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose,
        customFormData
      );

      const values =
        onCreateResource && onCreateResource.setBody
          ? onCreateResource.setBody(body)
          : body;

      if (values) {
        if (customFormData) {
          customFormData.append(
            'values',
            JSON.stringify({
              ...values
            })
          );
        } else {
          formData.append(
            'values',
            JSON.stringify({
              ...values
            })
          );
        }

        try {
          const { data } = await dispatchAPI('POST', {
            url: `${baseUrl}${extraQuery}`,
            body: formData
          });
          if (setForceRefresh) setForceRefresh(!forceRefresh);
          if (setResourceData) {
            setResourceData(data);
            setIsSubmitting(false);
            return;
          }
          if (successMessage) antdMessage.success(successMessage);
          if (setIsCreateUpdateOpen) setIsCreateUpdateOpen(isCreateUpdateOpen);
          if (customNavigate) customNavigate();
          if (!setIsCreateUpdateOpen && !customNavigate) navigate(-1);

          setIsSubmitting(false);
        } catch (e) {
          if (customErrorMessage) {
            customErrorMessage(e);
          } else {
            duplicateEmailErrorMessage({ e, message, t });
          }
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
      }
    };

    const deleteFile = async (fileID) => {
      try {
        await dispatchAPI('PATCH', {
          url: `${resource}/${customId || id}/${fileID}`
        });
      } catch (e) {
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${customId || id}?populate=${populate}`
        });
        if (data?.documents?.length && !withoutHandleFileActionsOnFetch) {
          handleFileActionsOnFetch(
            data,
            setFieldsFileList,
            setDraggerFilesList,
            setFilesConfiguration,
            dispatchAPI,
            message
          );
        }

        (customFormInstance || form).setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl, customId, forceRefresh]);

    useEffect(() => {
      if (!loadingFields) {
        setIsLoading(true);
        (async () => {
          if (purpose === 'edit' && (customId || id)) {
            await getResource();
          }
          if (withEnums) {
            await getResourceFilesKeys();
          }
          setIsLoading(false);
        })();
      }
    }, [getResource]);

    const handleSubmit = async (values) => {
      const extractedFileKeys = filesToUpload.map((fileObject) => ({
        file: fileObject.file,
        fileKey: fileObject.fileKey[0]
      }));

      const boolean = checkDraggerTypes(draggerFilesList, filesConfiguration);

      switch (true) {
        case !boolean:
          return message(t('missing_types'));
        case customSubmit:
          return customSubmit(values, extractedFileKeys);
        case purpose === 'edit':
          await updateResource(values, extractedFileKeys);
          return true;
        default:
          await createResource(values, extractedFileKeys);
      }

      return true;
    };

    const generateFieldsMemoized = useCallback(
      useGenerateFormItem(
        fieldsFilesList,
        setFieldsFileList,
        filesConfiguration,
        setFilesConfiguration,
        purpose,
        deleteFile,
        setFilesToUpload,
        downloadDocument,
        viewDocument
      ),
      [fieldsFilesList, filesConfiguration]
    );

    return (
      <>
        {!withCustomPageHeader && (
          <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        )}
        <ContentCustom className={className} style={style}>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              labelCol={customLabelCol || formItemLayout.labelCol}
              wrapperCol={customWrapperCol || formItemLayout.wrapperCol}
              onFinish={handleSubmit}
              form={customFormInstance || form}
              layout={layout || 'horizontal'}
            >
              {fields &&
                fields.map((field) =>
                  generateFieldsMemoized(tradKey || resource, field)
                )}
              {formExtra}
              {withFilesManager && (
                <FilesManager
                  filesList={draggerFilesList}
                  setFilesList={setDraggerFilesList}
                  filesKeys={draggerFilesKeys}
                  filesConfiguration={filesConfiguration}
                  setFilesConfiguration={setFilesConfiguration}
                  purpose={purpose}
                  deleteFile={deleteFile}
                />
              )}
              {!withCustomButtons ? (
                <Form.Item {...tailFormItemLayout}>
                  <Row justify="end" style={customSubmitButtonStyle || {}}>
                    <Button
                      style={{ margin: '0 8px' }}
                      type="link"
                      danger
                      onClick={() => {
                        if (cancelCustomAction) {
                          cancelCustomAction();
                        } else {
                          navigate(-1);
                        }
                      }}
                    >
                      {`${t('buttons.cancel')} `}
                      <CloseOutlined />
                    </Button>
                    <Button
                      type="add"
                      htmlType="submit"
                      loading={isSubmitting}
                      disabled={disabledSubmit}
                    >
                      {`${t(submitLabel || 'buttons.save')} `}
                      <CheckOutlined />
                    </Button>
                  </Row>
                </Form.Item>
              ) : null}
              {customActionButtons && customActionButtons}
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  className: PropTypes.string,
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  withFilesManager: PropTypes.bool,
  withEnums: PropTypes.bool,
  customFormInstance: PropTypes.shape({}),
  withCustomButtons: PropTypes.bool,
  withCustomPageHeader: PropTypes.bool,
  customId: PropTypes.string,
  setForceRefresh: PropTypes.func,
  forceRefresh: PropTypes.bool,
  successMessage: PropTypes.string,
  layout: PropTypes.string,
  customLabelCol: PropTypes.shape({}),
  customWrapperCol: PropTypes.shape({}),
  cancelCustomAction: PropTypes.func,
  setIsCreateUpdateOpen: PropTypes.func,
  populate: PropTypes.string,
  extraQuery: PropTypes.string,
  customSubmitButtonStyle: PropTypes.shape({}),
  customFormData: PropTypes.shape({
    append: PropTypes.func
  }),
  customUpdateFormData: PropTypes.shape({
    append: PropTypes.func
  }),
  isCreateUpdateOpen: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ]),
  setResourceData: PropTypes.func,
  disabledSubmit: PropTypes.bool,
  customErrorMessage: PropTypes.func,
  customActionButtons: PropTypes.element,
  withoutHandleFileActionsOnFetch: PropTypes.bool,
  style: PropTypes.shape({}),
  customNavigate: PropTypes.func
};

CreateUpdateContainer.defaultProps = {
  className: '',
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  withFilesManager: true,
  withEnums: true,
  customFormInstance: null,
  withCustomButtons: false,
  withCustomPageHeader: false,
  customId: null,
  setForceRefresh: null,
  forceRefresh: false,
  successMessage: null,
  layout: 'horizontal',
  customLabelCol: null,
  customWrapperCol: null,
  cancelCustomAction: null,
  setIsCreateUpdateOpen: null,
  populate: '',
  extraQuery: '',
  customSubmitButtonStyle: null,
  customFormData: null,
  customUpdateFormData: null,
  isCreateUpdateOpen: false,
  setResourceData: null,
  disabledSubmit: false,
  customErrorMessage: null,
  customActionButtons: null,
  withoutHandleFileActionsOnFetch: false,
  style: undefined,
  customNavigate: null
};
