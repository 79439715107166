import { Form, Input, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';

const { Option } = Select;

/**
 * @hook
 * Custom hook for managing form fields in the CreateManagerDrager component.
 *
 * @param {Object} options - The options for the hook.
 * @param {boolean} options.isAssitant - Flag indicating whether the user is an assistant.
 * @param {Function} options.refresh - The refresh function.
 * @returns {Object} - The form fields.
 */
export const useFields = ({ isAssitant, refresh }) => {
  const [managers, setManagers] = useState([]);
  const { currentSyndic } = useGlobalContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getManagers = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/syndic/${currentSyndic}?populate=managers&fields=managers,managers._id,managers.last_name,managers.first_name`
      });
      setManagers(data?.managers || []);
    } catch (e) {
      message(e);
    } finally {
      setIsFieldsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getManagers();
    })();
  }, [refresh]);

  const fields = [
    {
      name: ['last_name'],
      key: 'last_name',
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      key: 'first_name',
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      key: 'email',
      rules: [{ required: true }, { type: 'email' }]
    },
    {
      name: ['phone_number'],
      key: 'phone_number',
      rules: [{ required: true }],
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <InputNumber style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['address', 'number'],
      key: 'number'
    },
    {
      name: ['address', 'street'],
      key: 'street'
    },
    {
      name: ['address', 'additional'],
      key: 'additional'
    },
    {
      name: ['address', 'postal_code'],
      key: 'postal_code'
    },
    {
      name: ['address', 'city'],
      key: 'city'
    }
  ];

  if (isAssitant) {
    fields.push({
      name: ['managers'],
      key: 'managers',
      rules: [{ required: true }],
      input: (
        <Select allowClear loading={isFieldsLoading}>
          {managers?.map((item) => (
            <Option value={item._id} key={item._id}>
              {`${item.last_name} ${item.first_name}`}
            </Option>
          ))}
        </Select>
      )
    });
  }

  return { fields };
};
