import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag, Divider, Button, Popconfirm } from 'antd';
import {
  EyeOutlined,
  ForkOutlined,
  WarningOutlined,
  StopOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { accessTag, missionTag } from '../../../utils/constants/tagColors';
import { Mission } from '../../../models/Mission';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Custom hook that returns an array of columns for the missions view table.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Function} options.setIsReassignModalOpen - The function to set the state of the reassign modal.
 * @param {Function} options.setSelectedMissions - The function to set the selected missions.
 * @param {Function} options.setForceRefresh - The function to set the force refresh state.
 * @param {boolean} options.forceRefresh - The force refresh state.
 * @param {Object} options.enums - The enums object.
 * @returns {Array} - An array of columns for the missions view table.
 */
export const useColumns = ({
  setIsReassignModalOpen,
  setSelectedMissions,
  setForceRefresh,
  forceRefresh,
  enums
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const blockPermissions = !['users:NOMADE_CUSTOMER_SERVICE_MANAGER'].includes(
    user.role
  );
  return [
    {
      title: t('missions.form.syndic'),
      key: 'syndic.society_name',
      dataIndex: 'syndic',
      render: (record) =>
        `${record?.society_name || ''} ${
          record?.code ? `(${record.code})` : ''
        }`,
      sorter: (a, b) =>
        a.syndic?.society_name
          ?.toLowerCase()
          .localeCompare(b.syndic?.society_name.toLowerCase())
    },
    {
      title: t('missions.form.manager'),
      key: 'manager',
      dataIndex: 'manager',
      render: (record) =>
        `${record?.last_name || ''} ${record?.first_name || ''}`,
      sorter: true
    },
    {
      title: t('missions.form.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: 'collective_ownership',
      render: (collective_ownership) =>
        `${collective_ownership?.code || ''} - ${
          collective_ownership?.first_address?.number || ''
        } ${collective_ownership?.first_address?.street || ''} ${
          collective_ownership?.first_address?.postal_code || ''
        } ${collective_ownership?.first_address?.city || ''} ${
          collective_ownership?.cadastral_reference
            ? `(${collective_ownership.cadastral_reference})`
            : ''
        }`,
      sorter: true
    },
    {
      title: t('missions.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) => (
        <Tag color={missionTag[type]}>
          {t(`missions.tags.${type}`, {
            defaultValue: type
          })}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('missions.form.last_manager_connection'),
      key: 'last_manager_connection',
      render: (record) =>
        `${
          dayjs(record?.manager?.last_connection).format(
            'DD-MM-YYYY à HH[h]mm'
          ) || 'Jamais'
        }`,
      sorter: true
    },
    {
      title: t('missions.form.access'),
      key: 'access',
      dataIndex: 'access',
      render: (access) =>
        access === 'BLOCKED' ? (
          <Tag color={accessTag[access]}>
            {t(`users.tags.${access}`, {
              defaultValue: access
            })}
          </Tag>
        ) : (
          '-'
        ),
      filters: enums?.access?.map((r) => ({
        text: t(`users.tags.${r}`),
        value: r
      }))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/missions/missions-view/show/${
                record.type.includes('PCS') ? 'PCS' : record.type.toLowerCase()
              }/${record?._id}/${record.type}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <>
            {blockPermissions && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title={t(
                    `datatable.column.action.mission.${record?.access}.title`
                  )}
                  okText={t(
                    `datatable.column.action.mission.${record?.access}.ok`
                  )}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t(
                    `datatable.column.action.mission.${record?.access}.cancel`
                  )}
                  onConfirm={() =>
                    Mission.blockMission({
                      id: record._id,
                      setForceRefresh,
                      forceRefresh,
                      value:
                        record?.access === 'NOT_BLOCKED'
                          ? {
                              access: 'BLOCKED',
                              status: 'BLOCKED',
                              status_for_lawyer: 'BLOCKED'
                            }
                          : {
                              access: 'NOT_BLOCKED',
                              status: 'IN_PROGRESS',
                              status_for_lawyer: 'IN_PROGRESS'
                            }
                    })
                  }
                  icon={<WarningOutlined />}
                >
                  {record?.access === 'NOT_BLOCKED' ? (
                    <StopOutlined style={{ fontSize: 18 }} />
                  ) : (
                    <CheckCircleOutlined style={{ fontSize: 18 }} />
                  )}
                </Popconfirm>
              </>
            )}
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                setIsReassignModalOpen(true);
                setSelectedMissions([record]);
              }}
            >
              <ForkOutlined />
            </Button>
          </>
        </>
      )
    }
  ];
};
