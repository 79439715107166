import { useQuotationContext } from '../../../contexts/QuotationContext';
import { Quotation } from '../../../models/Quotation';

export const CalculMethods = () => {
  const { form, setQuotationActAmounts, setFormData } = useQuotationContext();

  const calculateTTCAndTVA = (totalHT) => {
    // Temporraire le temps de trouver d'ou je prend la valeur de la tva
    const tauxTVA = 0.2;
    const totalTTC = totalHT * (1 + tauxTVA);
    const montantTVA = totalTTC - totalHT;

    return {
      total_ttc: totalTTC,
      tva: montantTVA
    };
  };

  const getFixedInheritancesTotals = (fixInheritanceBody) => {
    const totalHT = Object.keys(fixInheritanceBody)
      .filter((key) => !['total_ht', 'tva', 'total_ttc'].includes(key))
      .reduce((acc, key) => acc + fixInheritanceBody[key], 0);

    const { tva, total_ttc } = calculateTTCAndTVA(totalHT);

    return {
      total_ht: totalHT,
      tva,
      total_ttc
    };
  };

  const setTotalHT = (body) => {
    let totalHT = 0;
    if (body.fix_inheritances) {
      totalHT += getFixedInheritancesTotals(body.fix_inheritances).total_ttc;
    }

    if (body.rights_calculation) {
      const provisionNomadeHT =
        (body.rights_calculation.provision / 100 + 1) *
        body.rights_calculation.total_notary_ttc;

      totalHT = provisionNomadeHT + body.rights_calculation.nomade_honorary_ttc;
    }

    if (body.nomade_honorary) {
      if (body.nomade_honorary.provision) {
        totalHT +=
          body.nomade_honorary.total_ttc + body.nomade_honorary.honorary_ttc;
      } else {
        Object.values(body.nomade_honorary).forEach((value) => {
          totalHT += value;
        });
      }
    }

    if (body.notary_honorary) {
      Object.values(body.notary_honorary).forEach((value) => {
        totalHT += value;
      });
    }

    return totalHT;
  };

  const handleAmountsUpdate = ({ actAmount, actions }) => {
    const values = form.getFieldValue(['quotation_act_amounts', actAmount._id]);

    // Update fix_inheritances if present
    if (values.fix_inheritances) {
      Object.assign(
        values.fix_inheritances,
        getFixedInheritancesTotals(values.fix_inheritances)
      );
    }

    // Update rights_calculation if present
    if (values.rights_calculation) {
      const rightCalculationTotalNotaryTTC =
        values.fix_inheritances.total_ttc +
        values.rights_calculation.fixed_mortgage_tax +
        values.rights_calculation.publication_contribution +
        values.rights_calculation.formality_status;

      const provisionNomadeHT =
        (values.rights_calculation.provision * rightCalculationTotalNotaryTTC) /
          100 +
        (values.rights_calculation.nomade_honorary_extra || 0);

      Object.assign(values.rights_calculation, {
        total_notary_ttc: rightCalculationTotalNotaryTTC,
        provision_nomade_ht: provisionNomadeHT
      });
    }

    // Update nomade_honorary if present and actions are SALE or EXCHANGE
    if (
      values.nomade_honorary &&
      actions &&
      actions.includes('SALE', 'EXCHANGE')
    ) {
      const nomadeHonoraryTotalTTC =
        (1 + values.nomade_honorary.provision / 100) *
        values.actions.total_notary_ttc;

      const totalTTC =
        nomadeHonoraryTotalTTC +
        (values?.nomade_honorary.honorary_ttc || 0) +
        (values?.nomade_honorary.extra || 0);

      Object.assign(values.nomade_honorary, {
        total_ttc: nomadeHonoraryTotalTTC
      });

      Object.assign(values.total, {
        total_ttc: totalTTC
      });
    }

    const totalHT = setTotalHT(values);
    const nomadeHonoraryHT = totalHT;
    const notaryHonoraryTTC =
      values?.rights_calculation?.total_notary_ttc ||
      values?.fix_inheritances?.notary_honorary_ttc ||
      values?.notary_honorary?.honorary_ttc ||
      0;

    const quotationActBody = {
      fix_inheritances: values?.fix_inheritances || {},
      rights_calculation: values?.rights_calculation || {},
      nomade_honorary: values?.nomade_honorary || {},
      total: {
        total_ttc: !values.is_sale_or_exchange
          ? totalHT * 1.2
          : values.total.total_ttc,
        nomade_honorary_ht: !values.is_sale_or_exchange
          ? nomadeHonoraryHT.toFixed(2)
          : values.total.nomade_honorary_ht,
        notary_honorary_ttc: !values.is_sale_or_exchange
          ? notaryHonoraryTTC
          : values.total.notary_honorary_ttc
      }
    };

    setQuotationActAmounts((prev) =>
      prev.map((prevAct) =>
        prevAct._id === actAmount._id
          ? { ...prevAct, ...quotationActBody }
          : prevAct
      )
    );
    setFormData((prev) => ({
      ...prev,
      quotation_act_amounts: {
        ...prev.quotation_act_amounts,
        ...Quotation.formatTableDatas([{ ...values, ...quotationActBody }])
      }
    }));
  };
  return {
    handleAmountsUpdate
  };
};
