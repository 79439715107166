/**
 * Set the color of the rows according to the status of the record
 * @param {Object} record - The record
 * @returns {string} - The color of the row
 */
export const setRowsColors = ({ record }) => {
  if (record.status === 'ACTIVE') {
    return 'row-color-active';
  }
  return null;
};
