import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * Returns an object containing principalItems and subItems arrays.
 * @hook
 * @returns {Object} An object containing principalItems and subItems arrays.
 */
export const quotationSteps = ({ disabledStep }) => {
  const { t } = useTranslation();

  const principalSteps = [
    {
      title: t('quotations.steps.TO_BE_COMPLETED'),
      icon: <CheckCircleOutlined />,
      key: 'TO_BE_COMPLETED',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.TO_SEND'),
      icon: <CheckCircleOutlined />,
      key: 'TO_SEND',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.WAITING_CUSTOMER'),
      icon: <CheckCircleOutlined />,
      key: 'WAITING_CUSTOMER',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.PARTIAL_VALIDATION'),
      icon: <CheckCircleOutlined />,
      key: 'PARTIAL_VALIDATION',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.VALIDATED'),
      icon: <CheckCircleOutlined />,
      key: 'VALIDATED',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.DENIED'),
      icon: <CheckCircleOutlined />,
      key: 'DENIED',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.ARCHIVED'),
      icon: <CheckCircleOutlined />,
      key: 'ARCHIVED',
      disabled: disabledStep
    }
  ];

  const principalItems = principalSteps.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled
  }));

  const subSteps = [
    {
      title: t('quotations.steps.PARAMETERS'),
      icon: <CheckCircleOutlined />,
      key: 'PARAMETERS',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.DETAILS'),
      icon: <CheckCircleOutlined />,
      key: 'DETAILS',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.SUMMARY'),
      icon: <CheckCircleOutlined />,
      key: 'SUMMARY',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.AMOUNTS'),
      icon: <CheckCircleOutlined />,
      key: 'AMOUNTS',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.DOCUMENTS_REQUEST'),
      icon: <CheckCircleOutlined />,
      key: 'DOCUMENTS_REQUEST',
      disabled: disabledStep
    },
    {
      title: t('quotations.steps.VALIDATION'),
      icon: <CheckCircleOutlined />,
      key: 'VALIDATION',
      disabled: disabledStep
    }
  ];

  const subItems = subSteps.map((item) => ({
    key: item.title,
    title: item.title,
    disabled: item.disabled
  }));

  return { principalItems, subItems };
};
