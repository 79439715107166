import { Select, Input } from 'antd';
import { ServicesFormList } from './utils/ServicesFormList';

const { Option } = Select;

/**
 * Custom hook that returns an array of fields for the municipality configuration form.
 *
 * @hook
 * @param {Object} options - The options object.
 * @param {Object[]} options.enums - The enums object.
 * @param {boolean} options.isFieldsLoading - Indicates whether the fields are loading.
 * @param {Object[]} options.spfDepartmentalArchives - The SPF departmental archives.
 * @param {string} options.id - The ID of the data.
 * @param {boolean} options.isDuplicateForm - Indicates whether the form is a duplicate.
 * @returns {Object} - The fields object.
 */
export const useFields = ({
  enums,
  isFieldsLoading,
  spfDepartmentalArchives,
  id,
  isDuplicateForm
}) => {
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      label: 'name',
      name: id ? [id, 'name'] : ['name'],
      key: 'name',
      rules: [{ required: true }],
      input: <Input status={isDuplicateForm ? 'error' : ''} />
    },
    {
      label: 'postal_code',
      name: id ? [id, 'postal_code'] : ['postal_code'],
      key: 'postal_code',
      rules: [{ required: true }],
      input: <Input status={isDuplicateForm ? 'error' : ''} />
    },
    {
      label: 'cutting',
      name: id ? [id, 'cutting'] : ['cutting'],
      key: 'cutting'
    },
    {
      label: 'SPF_3233',
      name: id ? [id, 'SPF_3233'] : ['SPF_3233'],
      key: 'SPF_3233',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={filterOption}
          loading={isFieldsLoading}
        >
          {(spfDepartmentalArchives || []).map((item) => (
            <Option value={item._id} key={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'services',
      key: 'services',
      input: (
        <ServicesFormList
          enums={enums}
          isFieldsLoading={isFieldsLoading}
          spfDepartmentalArchives={spfDepartmentalArchives}
          id={id}
        />
      )
    }
  ];

  return { fields };
};
