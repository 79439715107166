import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

/**
 * Renders a form with two Select components for filtering syndics and collective ownerships.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - The function to set the filters.
 * @param {Array} props.syndics - The syndics options.
 * @param {Array} props.collectiveOwnerships - The collective ownerships options.
 * @param {boolean} props.isFiedlLoading - Indicates if the field is loading.
 * @returns {JSX.Element} - The rendered form.
 */
export const ExtraSearchBars = ({
  setFilters,
  syndics,
  collectiveOwnerships,
  isFiedlLoading
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  /**
   * Filters the options based on the input value.
   * @function
   * @param {string} input - The input value.
   * @param {Object} option - The option object.
   * @returns {boolean} - Whether the option should be displayed or not.
   */
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Form form={form} layout="inline">
      <Form.Item name={['syndics']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ consultants: null });
            setFilters(values ? `&syndic._id=${values}` : '');
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFiedlLoading}
          placeholder={t('placeholder.search_syndic')}
        >
          {(syndics || []).map((syndic) => (
            <Option key={syndic._id} value={syndic._id}>
              {syndic.society_name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={['collectiveOwnerships']}>
        <Select
          style={{ minWidth: 200 }}
          onChange={(values) => {
            form.setFieldsValue({ syndics: null });
            setFilters(values ? `&collective_ownership._id=${values}` : '');
          }}
          showSearch
          allowClear
          filterOption={filterOption}
          loading={isFiedlLoading}
          placeholder={t('placeholder.search_collective_ownership')}
        >
          {(collectiveOwnerships || []).map((collective_ownership) => (
            <Option
              key={collective_ownership?._id}
              value={collective_ownership?._id}
            >
              {`${collective_ownership?.code || ''} ${
                collective_ownership?.name || ''
              }`}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

ExtraSearchBars.propTypes = {
  enums: PropTypes.shape({}),
  setFilters: PropTypes.func,
  isFiedlLoading: PropTypes.bool,
  syndics: PropTypes.arrayOf(PropTypes.shape({})),
  collectiveOwnerships: PropTypes.arrayOf(PropTypes.shape({}))
};

ExtraSearchBars.defaultProps = {
  enums: {},
  setFilters: () => {},
  isFiedlLoading: true,
  syndics: [],
  collectiveOwnerships: []
};
