import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Row, Col } from 'antd';

/**
 * ConsistencyDestinationChange component.
 * Renders a form for modifying destination change data in a quotation action.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.recordId - The ID of the quotation action record.
 * @returns {JSX.Element} The rendered ConsistencyDestinationChange component.
 */
export const ConsistencyDestinationChange = ({ recordId }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label={t('quotation-actions.form.category')}
              name={['actions', recordId, 'data', 'category']}
              wrapperCol={{ span: 12 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('quotation-actions.form.modified_lot_number')}
              name={['actions', recordId, 'data', 'modified_lot_number']}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Form.Item name={['actions', recordId, 'data', 'observation']}>
          <Input
            placeholder={t('placeholder.observation')}
            style={{ width: '350px' }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

ConsistencyDestinationChange.propTypes = {
  recordId: PropTypes.string.isRequired
};
