import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

/**
 * Returns an array of columns configuration for the quotations summary table.
 * @hook
 * @returns {Array} The array of columns configuration.
 */
export const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('quotation-acts.form.act_number'),
      key: 'number',
      dataIndex: 'number',
      width: '10%',
      render: (_, record) => (
        <Form.Item
          name={['quotation_acts', record._id, 'number']}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
      ),
      sorter: true
    },
    {
      title: t('quotation-acts.form.documents'),
      width: '40%',
      children: [
        {
          title: t('quotation-acts.form.surveyor'),
          children: [
            {
              title: t('quotation-acts.form.ref'),
              key: 'surveyor.ref',
              dataIndex: ['surveyor', 'ref']
            },
            {
              title: t('quotation-acts.form.modification'),
              key: 'surveyor.modification',
              dataIndex: ['surveyor', 'modification']
            }
          ]
        },
        {
          title: t('quotation-acts.form.ag'),
          children: [
            {
              title: t('quotation-acts.form.ref'),
              key: 'ag.ref',
              dataIndex: ['ag', 'ref']
            },
            {
              title: t('quotation-acts.form.modification'),
              key: 'ag.modification',
              dataIndex: ['ag', 'modification']
            }
          ]
        }
      ]
    },
    {
      title: t('quotation-acts.form.encryption'),
      width: '20%',
      children: [
        {
          title: t('quotation-acts.form.lot_rc'),
          key: 'lot_rc',
          dataIndex: 'lot_rc'
        },
        {
          title: t('quotation-acts.form.lot_edd'),
          key: 'lot_edd',
          dataIndex: 'lot_edd'
        },
        {
          title: t('quotation-acts.form.procs'),
          key: 'procs',
          dataIndex: 'procs'
        },
        {
          title: t('quotation-acts.form.lot_sheet'),
          children: [
            {
              title: t('quotation-acts.form.digit'),
              key: 'digit',
              dataIndex: ['lot_sheet', 'digit']
            },
            {
              title: t('quotation-acts.form.number'),
              key: 'number',
              dataIndex: ['lot_sheet', 'number']
            }
          ]
        }
      ]
    },
    {
      title: t('quotation-acts.form.observation'),
      key: 'observation',
      dataIndex: 'observation',
      width: '30%'
    }
  ];
};
