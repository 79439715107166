import { useEffect, useState } from 'react';
import { Row, Typography } from 'antd';
import { AlertFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SyndicViewContentCustom from '../SyndicViewContentCustom';
import { homeTitle } from './utils/homeTitle';
import { ContentCustom } from '../../../components';
import { homeSubtitle } from './utils/homeSubtitle';
import HomeCards from './HomeCards';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useAuthContext } from '../../../contexts/AuthContext';

const { Text } = Typography;
const rowStyle = {
  margin: '8px 24px 8px',
  padding: 16,
  backgroundColor: '#fce4e7',
  color: '#cb2529',
  borderRadius: '15px'
};

/**
 * @component
 * Renders a list of collective ownerships for a syndic.
 * @returns {JSX.Element} The rendered component.
 */
export const HomeSyndic = () => {
  const { title, subtitle, cardStyle, homeTitleImage } = homeTitle();
  const { currentSyndic } = useGlobalContext();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [missingManagerAlert, setMissingManagerAlert] = useState(false);
  const {
    title: subTITLE,
    subtitle: subSUBTITTLE,
    cardStyle: subCARDSTYLE,
    homeSubtitleImage
  } = homeSubtitle();

  const fetchMissingManagerAlert = async () => {
    const managerQuery = JSON.stringify({
      $or: [{ manager: { $exists: false } }, { manager: null }]
    });

    const { data } = await dispatchAPI('GET', {
      url: `/collective-ownership?syndic=${currentSyndic}&filter=${managerQuery}&fields=manager`
    });

    setMissingManagerAlert(data.length > 0);
  };

  useEffect(() => {
    (async () => {
      await fetchMissingManagerAlert();
    })();
  }, []);

  return (
    <SyndicViewContentCustom
      cardStyle={cardStyle}
      title={title}
      subtitle={subtitle}
      imageOnRight={homeTitleImage}
    >
      {missingManagerAlert && (
        <Link to="/client-wallet/manage-wallet">
          <Row style={rowStyle}>
            <AlertFilled style={{ marginRight: '8px' }} />
            <Text style={{ color: '#cb2529' }} strong>
              {t('syndic-home.manager_alert')}
            </Text>
          </Row>
        </Link>
      )}
      <ContentCustom
        style={{
          backgroundColor: 'var( --secondaryColor)',
          borderRadius: '15px',
          imageOnLeft: true
        }}
      >
        <SyndicViewContentCustom
          title={subTITLE}
          subtitle={subSUBTITTLE}
          cardStyle={subCARDSTYLE}
          imageOnLeft={homeSubtitleImage}
        >
          <HomeCards />
        </SyndicViewContentCustom>
      </ContentCustom>
    </SyndicViewContentCustom>
  );
};
