import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { ExportSheet } from 'react-xlsx-sheet';
import * as XLSX from 'xlsx';

const ExportXLSXButton = ({ exportsheet, t }) => (
  <Button icon={<DownloadOutlined />} onClick={exportsheet}>
    {t(`buttons.export_excel`)}
  </Button>
);

ExportXLSXButton.propTypes = {
  exportsheet: PropTypes.func,
  t: PropTypes.func.isRequired
};

ExportXLSXButton.defaultProps = {
  exportsheet: () => {}
};

export const ExportExcel = ({
  exportDatas,
  customFormatter,
  columns,
  chargingGrid
}) => {
  const [exports, setExports] = useState([]);
  const { t } = useTranslation();

  const formatter = (keys, datas) => {
    const result = [];

    const header = keys.map((key) => key.name).filter(Boolean);

    result.push(header);

    if (datas)
      datas.forEach((data) => {
        const rowData = header.map((key) => {
          const value = data;
          return value[key]?.value || null;
        });
        result.push(rowData);
      });

    return result;
  };

  useEffect(() => {
    if (customFormatter) setExports(customFormatter());
    else setExports(formatter(columns, exportDatas));
  }, [exportDatas, columns]);

  return (
    <ExportSheet
      dataType="Array-of-Arrays"
      fileName={`${chargingGrid.label}_V${chargingGrid.version}`}
      dataSource={exports}
      isDOMElement={false}
      xlsx={XLSX}
    >
      <ExportXLSXButton t={t} />
    </ExportSheet>
  );
};

ExportExcel.propTypes = {
  exportDatas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFormatter: PropTypes.func,
  chargingGrid: PropTypes.shape({
    label: PropTypes.string,
    version: PropTypes.number
  }).isRequired
};

ExportExcel.defaultProps = {
  customFormatter: null
};
