import { Calendar } from 'antd';
import { monthAnnualHeader } from './monthAnnualHeader';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import { useAgendaContext } from '../../../contexts/AgendaContext';

interface MonthAnnualCalendarProps {
  cellRender?:
    | ((
        date: moment.Moment,
        events: Array<any>,
        setIsDrawerOpen: (value: boolean) => void,
        setEvent: (value: any) => void,
        setPurpose: (value: string) => void,
        setFormToOpen: (value: string) => void,
        setDrawerTitle: (value: string) => void
      ) => React.ReactNode)
    | null;
  extraHeader?:
    | ((
        value: moment.Moment,
        onChange: (value: moment.Moment) => void
      ) => React.ReactNode)
    | null;
  mode: CalendarMode | undefined;
  setMode?: ((mode: string) => void) | null;
  modes: Array<string>;
  events: Array<{ [key: string]: any }>;
  dayRange: string;
}

export const MonthAnnualCalendar: React.FC<MonthAnnualCalendarProps> = ({
  cellRender,
  extraHeader,
  mode,
  modes,
  setMode,
  events,
  dayRange
}) => {
  const {
    setIsDrawerOpen,
    setEvent,
    setPurpose,
    setFormToOpen,
    setDrawerTitle
  } = useAgendaContext();

  const handleClassName = () => {
    switch (dayRange) {
      case 'withSaturday':
        return 'saturday-calendar';
      case 'weekends':
        return 'weekends-calendar';
      default:
        return 'only-weekdays-calendar';
    }
  };

  return (
    <Calendar
      dateCellRender={(date: any) =>
        cellRender &&
        cellRender(
          date,
          events,
          setIsDrawerOpen,
          setEvent,
          setPurpose,
          setFormToOpen,
          setDrawerTitle
        )
      }
      monthCellRender={(date: any) =>
        cellRender &&
        cellRender(
          date,
          events,
          setIsDrawerOpen,
          setEvent,
          setPurpose,
          setFormToOpen,
          setDrawerTitle
        )
      }
      headerRender={({ value, onChange }) =>
        monthAnnualHeader(
          { value, onChange },
          extraHeader,
          modes,
          mode,
          setMode
        )
      }
      mode={mode}
      className={handleClassName()}
    />
  );
};
