import { Button, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

/**
 * Renders extra buttons for configuration lists.
 * @param {Object} props - The component props.
 * @param {Array} props.duplicatesArray - The array of duplicate items.
 * @param {string} props.resource - The resource name.
 * @param {Object} props.enums - The enums object.
 * @returns {JSX.Element|null} The rendered extra buttons or null if there are no duplicate duplicate items.
 */
export const ExtraButtons = ({
  duplicatesArray,
  resource,
  enums,
  setFilters
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const spfDepartmentalarchiveFilter =
    resource === 'spf-departmental-archives' ? (
      <Select
        style={{ minWidth: 200 }}
        mode="multiple"
        onChange={(values) => setFilters(values)}
        placeholder={t('spf-departmental-archives.place_holders.filter')}
      >
        {(enums?.types || []).map((type) => (
          <Option key={type} value={type}>
            {t(`spf-departmental-archives.tags.${type}`)}
          </Option>
        ))}
      </Select>
    ) : null;

  const duplicateButton = duplicatesArray.length ? (
    <Button
      style={{ color: 'red' }}
      onClick={() => navigate(`/configurations/duplicate/${resource}`)}
    >
      <CopyOutlined />
      {t('buttons.manage_duplicate', {
        count: duplicatesArray.length
      })}
    </Button>
  ) : null;

  return [spfDepartmentalarchiveFilter, duplicateButton];
};

ExtraButtons.propTypes = {
  duplicatesArray: PropTypes.arrayOf(PropTypes.shape({})),
  resource: PropTypes.string.isRequired,
  enums: PropTypes.shape({}),
  setFilters: PropTypes.func
};

ExtraButtons.defaultProps = {
  enums: {},
  setFilters: () => {},
  duplicatesArray: []
};
