import { message } from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CreateUpdateContainer } from '../../../../../components';
import { useFields } from './useFields';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';
import { getEnums } from '../../../../collective-ownership/utils/getEnums';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * CreateUpdateMultipleCollectiveOwnerships Component
 *
 * This component handles the creation and updating of multiple collective ownerships.
 * It manages form state, loads necessary enums, and handles syndic and manager options.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form instance used for managing form state.
 * @param {function} props.handleResourceData - The function to handle resource data.
 *
 * @returns {JSX.Element} The rendered CreateUpdateMultipleCollectiveOwnerships component.
 */
export const CreateUpdateMultipleCollectiveOwnerships = ({
  form,
  handleResourceData
}) => {
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [selectedSyndic, setSelectedSyndic] = useState(null);
  const { syndics } = useGlobalContext();
  const [syndicOptions, setSyndicOptions] = useState(syndics);
  const [managers, setManagers] = useState([]);
  const [enums, setEnums] = useState({});
  const { dispatchAPI } = useAuthContext();
  const { fields } = useFields({
    isFieldsLoading,
    setSelectedSyndic,
    form,
    syndicOptions,
    managers,
    enums
  });

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getEnums({ dispatchAPI, setEnums, message });
      setIsFieldsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setSyndicOptions(syndics);
    if (selectedSyndic && selectedSyndic.managers) {
      setManagers(selectedSyndic.managers);
    }
  }, [selectedSyndic, syndics]);

  return (
    <CreateUpdateContainer
      customFormInstance={form}
      fields={fields}
      purpose="create"
      baseUrl="collective-ownerships"
      resource="collective-ownerships"
      withCustomPageHeader
      withFilesManager={false}
      withCustomButtons
      setResourceData={handleResourceData}
    />
  );
};

CreateUpdateMultipleCollectiveOwnerships.propTypes = {
  form: PropTypes.shape({}),
  handleResourceData: PropTypes.func
};

CreateUpdateMultipleCollectiveOwnerships.defaultProps = {
  form: {},
  handleResourceData: null
};
