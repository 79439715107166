import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns for the recover statistics list
 * @hook
 * @returns {Array} An array of column objects.
 */
export const useRecoverColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('statistics.columns.consultant'),
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.syndic'),
      dataIndex: 'syndic',
      key: 'syndic',
      render: (syndic) => (syndic ? `Total(${syndic})` : '-'),
      sorter: true
    },
    {
      title: t('statistics.columns.recover.total_collective_ownership'),
      dataIndex: 'total_collective_ownership',
      key: 'total_collective_ownership',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.collective_ownership'),
      dataIndex: 'collective_ownership',
      key: 'collective_ownership',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.sales_canal'),
      dataIndex: 'sales_canal',
      key: 'sales_canal',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.creation_date'),
      dataIndex: 'creation_date',
      key: 'creation_date',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.advance_payment_collected'),
      dataIndex: 'advance_payment_collected',
      key: 'advance_payment_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.advance_to_be_collected'),
      dataIndex: 'advance_to_be_collected',
      key: 'advance_to_be_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.percentage_advance_collected'),
      dataIndex: 'percentage_advance_collected',
      key: 'percentage_advance_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.balance_collected'),
      dataIndex: 'balance_collected',
      key: 'balance_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.balance_to_be_collected'),
      dataIndex: 'balance_to_be_collected',
      key: 'balance_to_be_collected',
      sorter: true
    },
    {
      title: t('statistics.columns.recover.percentage_balance_collected'),
      dataIndex: 'percentage_balance_collected',
      key: 'percentage_balance_collected',
      sorter: true
    }
  ];
};
