import { useTranslation } from 'react-i18next';
import { ListAccountings } from './ListAccountings';

/**
 * Custom hook for retrieving accounting items.
 * @hook
 * @returns {Object} The quote configuration items.
 * @property {Array} items - The array of accounting items.
 */
export const useItems = () => {
  const { t } = useTranslation();
  const items = [
    {
      label: t('accountings.tabs.bank_accounts'),
      key: '1',
      children: <ListAccountings type="BANK_ACCOUNTS" />
    },
    {
      label: t('accountings.tabs.missions'),
      key: '2',
      children: <ListAccountings type="MISSIONS" />
    }
  ];

  return { items };
};
