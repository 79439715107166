export const userRoles = {
  GUEST: '#43CEDE',
  USER: '#6C757D',
  'SUPER-USER': 'geekblue',
  SYNDIC_MANAGER: '#8c07b8',
  ADMIN: '#CB2529',
  'SUPER-ADMIN': 'magenta',
  DEVELOPER: '#DDD6EC',
  NOMADE: '#F9CD31',
  NOTARY: '#1239C4',
  SURVEYOR: '#5E8536',
  LAWYER: '#9D2783'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};

export const templateStatus = {
  DRAFT: '#FADB14',
  VALIDATED: '#2DE180'
};

export const syndicStatus = {
  ACTIVE: 'green',
  INACTIVE: 'red'
};

export const collectiveOwnershipStatus = {
  ACTIVE: 'green',
  INACTIVE: 'red'
};

export const accessTag = {
  BLOCKED: 'red'
};

export const missionTag = {
  AUDIT_1_2: '#2596be',
  AUDIT_1: '#ac8dd4',
  CHARGING_GRID_DIAGNOSTIC: 'geekblue',
  SURVEYOR_STUDY: 'red',
  PCS_1: '#f99432',
  PCS_2: '#f9bd32',
  PCS_3: 'cyan',
  OUTLAWED_PUBLICATION_ELAN: 'orange',
  COLLECTIVE_OWNERSHIP_ACT_RESEARCH: 'gold',
  ACT_COPY_RESEARCH: 'lime',
  PLANS_RESEARCH: '#1945e3 ',
  RCP_DOC_AND_WORD_TRANSFORMATION_RESEARCH: 'yellow',
  RCP_RESEARCH: '#06154b',
  BATCH_SHEET_PERSON_SHEET_RESEARCH: 'orange',
  MORTGAGE_SHEET_RESEARCH: 'cyan',
  EXPECTED: 'blue',
  RECEIVED: 'green',
  AUDIT: '#72c6d9',
  ADAPTATION: '#f9bd32',
  COMPLIANCE: '#c47925',
  COLLECTIVE_OWNERSHIP_REGULATION_CREATION: '#F98651'
};

export const missionStatus = {
  IN_PROGRESS: 'green',
  FINISHED: 'blue',
  CANCELLED: 'orange',
  BLOCKED: 'red',
  PENDING: 'gray',
  PENDING_VALIDATION: 'blue',
  VALIDATED: 'green'
};

export const invoiceTag = {
  PAID: 'green',
  UNPAID: 'red',
  ADVANCE_PAYMENT: 'blue',
  REGULE: 'orange'
};

export const orderTag = {
  RECEIVED: 'green',
  ORDERED: 'blue',
  URGENT: 'red'
};

export const documentTag = {
  EXPECTED: 'blue',
  LATE: 'red',
  RECEIVED: 'green'
};

export const statusTag = {
  IN_PROGRESS: 'yellow',
  FINISHED: 'grey',
  CANCELLED: 'red'
};

export const actions_to_do = {
  UNPAID_INVOICE: 'red',
  PAID_INVOICE: 'green'
};

export const quotationTag = {
  TO_BE_COMPLETED: '#2596be',
  TO_SEND: '#ac8dd4',
  WAITING_CUSTOMER: 'blue',
  IN_PROGRESS: 'orange',
  PARTIAL_VALIDATION: 'cyan',
  VALIDATED: 'green',
  DENIED: 'red',
  ARCHIVED: 'grey'
};

export const stockCerfaTag = {
  TO_VALIDATE: 'blue',
  TO_PROCESS: 'magenta'
};
