import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomActionButtons } from '../../../../components/CreateUpdateContainer/CustomActionButtons';
import { PlanFormList } from '../fields/plans';

/**
 * The `PlansForm` component is designed for managing and submitting plans as part of a larger form structure.
 * It leverages dynamic field generation to create a form section dedicated to plans, using custom hooks for field definitions and utilities for form item generation.
 * The component is suitable for applications involving project management, collective ownerships, or any context where plans need to be detailed and submitted.
 *
 * Props:
 * @prop {function} onFinish - Callback function triggered upon the completion of the form, typically used for final data processing or navigation to a different page.
 * @prop {function} onSubmit - Callback function triggered upon form submission, used for data validation, submission to an API, or transitioning to the next step in a multi-step form.
 * @prop {Object} [initialValues={}] - Initial values for the form fields, used to pre-populate the form when editing or updating existing plans.
 * @prop {Object} form - Instance of the form provided by Ant Design's Form component, necessary for form control and managing field values.
 * @prop {boolean} isFieldsLoading - Indicates whether the data required for the form fields, such as options for select fields, is currently loading.
 * @prop {function} setCurrent - Function to set the current step in a multi-step form process, useful for forms divided into multiple sections or pages.
 * @prop {number} [current=0] - The current step within a multi-step form process, indicating the position of the `PlansForm` within the overall form structure.
 * @prop {Object} [professionals={}] - An object containing professional entities or personnel data, used to populate select fields or other form components that require professional information.
 *
 * The component uses the `useGenerateFormItem` hook to dynamically generate form fields based on the configurations provided by the `usePlansFields` hook.
 * A `useEffect` hook is employed to listen for changes to `initialValues` and update the form values accordingly, ensuring correct pre-population of the form when editing plans.
 * The `CustomActionButtons` component is included at the bottom of the form, providing navigation and submission controls. It is configured with properties to display both previous and next buttons for step navigation.
 *
 * Usage:
 * This component is intended for use within forms that require a dedicated section for detailing and submitting plans, particularly in scenarios that involve project planning, collective ownership management, or similar applications where detailed plans are a critical component of the form submission process.
 */

export const PlansForm = ({
  onFinish,
  onSubmit,
  initialValues,
  form,
  isFieldsLoading,
  setCurrent,
  current,
  professionals
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <>
      <PlanFormList
        professionals={professionals}
        isFieldsLoading={isFieldsLoading}
      />
      <CustomActionButtons
        onFinish={onFinish}
        onSubmit={onSubmit}
        setCurrent={setCurrent}
        current={current}
        previousButton
        nextButton
      />
    </>
  );
};

PlansForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  current: PropTypes.number,
  initialValues: PropTypes.shape({}),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  enums: PropTypes.shape({}),
  isFieldsLoading: PropTypes.bool.isRequired,
  professionals: PropTypes.shape({})
};

PlansForm.defaultProps = {
  initialValues: {},
  enums: {},
  current: 0,
  professionals: {}
};
