export const changeForm = async ({
  nextStep,
  form,
  setCurrent,
  Message,
  t,
  setFormData
}) => {
  try {
    const values = await form.validateFields();
    setFormData((prev) => ({ ...prev, ...values }));
    setCurrent(nextStep);
    return null;
  } catch (errorInfo) {
    return Message.error(t('syndics.messages.required_fields'));
  }
};
