/**
 * Set options for the select component
 * @param {Object} enums - The enums object
 * @param {Object} t - The translation function
 * @returns {Object[]} - The array of options for the select component
 */
export const setOptions = ({ enums, t }) => [
  {
    label: t('collective-ownership-forms.form.tags.GARDEN'),
    value: enums.GARDEN || 'GARDEN'
  },
  {
    label: t('collective-ownership-forms.form.tags.BALCONY'),
    value: enums.BALCONY || 'BALCONY'
  },
  {
    label: t('collective-ownership-forms.form.tags.TERRACE'),
    value: enums.TERRACE || 'TERRACE'
  },
  {
    label: t('collective-ownership-forms.form.tags.ATTIC'),
    value: enums.ATTIC || 'ATTIC'
  },
  {
    label: t('collective-ownership-forms.form.tags.COURTYARD'),
    value: enums.COURTYARD || 'COURTYARD'
  },
  {
    label: t('collective-ownership-forms.form.tags.PARKING'),
    value: enums.PARKING || 'PARKING'
  },
  {
    label: t('collective-ownership-forms.form.tags.WC'),
    value: enums.WC || 'WC'
  },
  {
    label: t('collective-ownership-forms.form.tags.CORRIDOR'),
    value: enums.CORRIDOR || 'CORRIDOR'
  },
  {
    label: t('collective-ownership-forms.form.tags.LOGGIA'),
    value: enums.LOGGIA || 'LOGGIA'
  }
];
