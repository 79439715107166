import { useRef } from 'react';
import {
  Input,
  Select,
  Space,
  Button,
  Divider,
  InputNumber,
  DatePicker,
  Form,
  Row,
  Col,
  message
} from 'antd';
import { EditableTableMethods } from './methods';
import { useOptions } from './useOptions';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * Renders a selection component based on the input type.
 *
 * @param {Object} props - The component props.
 * @param {string} props.inputType - The type of input ('select' or 'text').
 * @param {React.Ref} props.selectRef - The ref for the Select component.
 * @param {string} props.selectType - The type of select ('icons' or 'roles').
 * @param {function} props.setEditingKey - The function to set the editing key.
 * @param {function} props.setForceRefresh - The function to set the force refresh flag.
 * @param {boolean} props.forceRefresh - The flag to force refresh the component.
 * @param {object} props.record - The record object.
 * @param {object} props.form - The form object.
 * @param {object} props.selectOptions - The options for the select component.
 * @param {React.Ref} props.inputRef - The ref for the Input component.
 * @param {function} props.t - The translation function.
 * @param {string} props.resource - The resource name.
 * @param {string} props.customUrl - The custom URL.
 * @returns {React.ReactNode} The rendered selection component.
 */
export const setEditInput = ({
  inputType,
  selectRef,
  selectType,
  setEditingKey,
  setForceRefresh,
  forceRefresh,
  record,
  form,
  selectOptions,
  inputRef,
  resource,
  t,
  customUrl
}) => {
  const { saveCell } = EditableTableMethods();
  const selectedValuesRef = useRef([]);

  const filterOption = (input, option) => {
    const selectChildren = String(option?.searchValue || option?.value);
    return selectChildren.toLowerCase().includes(input.toLowerCase());
  };

  const handleSave = async () => {
    if (
      selectType === 'cerfa_types' &&
      selectedValuesRef.current.length > 1 &&
      selectedValuesRef.current.includes('3233')
    ) {
      message.error(
        t('cerfa-documents.messages.cannot_select_CERFA_3233_with_other_types')
      );
      return;
    }
    await saveCell({
      setEditingKey,
      record,
      setForceRefresh,
      forceRefresh,
      form,
      inputType,
      selectType,
      resource,
      selectedValuesRef,
      customUrl
    });
  };

  switch (inputType) {
    case 'select':
      return (
        <Select
          ref={selectRef}
          allowClear
          mode={
            ['roles', 'missions', 'cerfa_types'].includes(selectType)
              ? 'multiple'
              : ''
          }
          showSearch
          filterOption={filterOption}
          dropdownRender={(menu) =>
            selectType === 'missions' ? (
              <>
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    onClick={() => {
                      selectedValuesRef.current = selectOptions[selectType];
                    }}
                  >
                    {t('document-repositories.form.all_missions')}
                  </Button>
                </Space>
                <Divider />
                {menu}
              </>
            ) : (
              menu
            )
          }
          onChange={(el) => {
            if (['roles', 'missions', 'cerfa_types'].includes(selectType)) {
              selectedValuesRef.current = el;
            } else {
              handleSave();
              selectRef.current.focus();
            }
          }}
          onBlur={() => {
            if (['roles', 'missions', 'cerfa_types'].includes(selectType)) {
              setTimeout(() => {
                handleSave();
              }, 300);
            }
            setTimeout(() => {
              setEditingKey('');
              setForceRefresh(!forceRefresh);
            }, 1000);
          }}
        >
          {(selectOptions[selectType] || []).map((element) =>
            useOptions({ selectType, element, t })
          )}
        </Select>
      );
    case 'text':
      return (
        <Input ref={inputRef} onBlur={handleSave} onPressEnter={handleSave} />
      );
    case 'number':
      return (
        <InputNumber
          ref={inputRef}
          onBlur={handleSave}
          onPressEnter={handleSave}
        />
      );
    case 'range-picker':
      return (
        <RangePicker
          ref={inputRef}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          onOpenChange={(open) => {
            if (!open) {
              handleSave();
            }
          }}
        />
      );
    case 'date-picker':
      return (
        <DatePicker
          ref={inputRef}
          format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']}
          onChange={handleSave}
        />
      );
    case 'number-unit':
      return (
        <Row gutter={[16, 0]}>
          <Col>
            <Input.Group>
              <Form.Item name={[selectType, 'number']}>
                <InputNumber ref={inputRef} />
              </Form.Item>
              <Form.Item name={[selectType, 'unit']}>
                <Select defaultValue="DAYS">
                  <Option value="DAYS">{t('units.DAYS')}</Option>
                  <Option value="WEEKS">{t('units.WEEKS')}</Option>
                  <Option value="MONTHS">{t('units.MONTHS')}</Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Col>

          <Col>
            <Button onClick={handleSave}>{t('buttons.ok')}</Button>
          </Col>
        </Row>
      );
    default:
      return <Input ref={inputRef} onPressEnter={handleSave} />;
  }
};
