import { Table } from 'antd';
import PropTypes from 'prop-types';
import { useInvoicesColumns } from '../columns/invoicesColumns';

/**
 * A table component to display a list of invoices.
 *
 * @param {Object[]} dataSource - The array of invoice data to be displayed in the table.
 * @param {Function} setOpen - A function to open a related modal or drawer.
 * @param {string} comment - A comment or note to be displayed or used in the table.
 *
 * @returns {JSX.Element} The rendered table component.
 */
export const ExpandedInvoicesTable = ({
  dataSource = [],
  setOpen,
  comment
}) => {
  const columns = useInvoicesColumns(setOpen, comment);

  return <Table dataSource={dataSource} columns={columns} pagination={false} />;
};

ExpandedInvoicesTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  setOpen: PropTypes.func.isRequired,
  comment: PropTypes.string
};

ExpandedInvoicesTable.defaultProps = {
  dataSource: [],
  comment: ''
};
