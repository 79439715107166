import { useState } from 'react';
import PropTypes from 'prop-types';
import { useColumns } from './useColumns';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';
import { dashboardTitle } from '../../ComplianceElanRouter/dashboardTitles';
import { ServicesCustomContentWithDivider } from '../../ServicesCustomContentWithDivider';
import { syndicContentCustomStyle } from '../../../utils/styleConstants';
import { ListResource } from '../../../../../components';
import { ExtraSearchButton } from '../ServicesActionsToDo/ExtraSearchButton';

/**
 * Renders a list of generic collective ownerships with customizable columns and filters.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.missions - The list of missions.
 * @param {Array} props.customColumns - The custom columns to display.
 * @param {ReactNode} props.additionalHeader - Additional header content.
 * @param {string} props.tradKey - The translation key.
 * @returns {JSX.Element} The rendered component.
 */
export const ServicesGenericCollectiveOwnershipList = ({
  missions,
  customColumns,
  additionalHeader,
  tradKey,
  onDoubleClickAction,
  noHeaderButtons
}) => {
  const { currentSyndic } = useGlobalContext();
  const columns = useColumns();
  const [filter, setFilter] = useState([]);
  const { collectiveOwnershipTitle, button } = dashboardTitle();
  const fieldsToFetch = ['type', '_id', 'name'];
  const buildStringFields = (fields) => fields.join(',');
  const fieldsString = buildStringFields(fieldsToFetch);
  const missionsString = buildStringFields(missions);

  return (
    <ServicesCustomContentWithDivider
      title={collectiveOwnershipTitle(tradKey || missionsString)}
      buttonOnRight={button}
      cardStyle={{ backgroundColor: '#222222' }}
      noHeaderButtons={noHeaderButtons}
    >
      {additionalHeader}
      <div style={{ width: '100%' }}>
        <ListResource
          ContentCustomStyle={syndicContentCustomStyle}
          withPageHeader={false}
          resourceName="syndic-collective-ownerships"
          extraUrlPath="/forMissions"
          extraQuery={`syndic=${currentSyndic}&type=${
            filter.length > 0 ? filter : missionsString
          }&fields=${fieldsString}`}
          populate="collective_ownership.manager,syndic"
          searchBarExtraButtons={
            <ExtraSearchButton setFilter={setFilter} missions={missions} />
          }
          columns={customColumns || columns}
          resourceModelName="Collective-ownership"
          customActionColumn
          withCreateButton={false}
          onDoubleClickAction={onDoubleClickAction}
        />
      </div>
    </ServicesCustomContentWithDivider>
  );
};

ServicesGenericCollectiveOwnershipList.propTypes = {
  missions: PropTypes.string.isRequired,
  customColumns: PropTypes.shape([]),
  additionalHeader: PropTypes.element,
  tradKey: PropTypes.string,
  onDoubleClickAction: PropTypes.shape({}),
  noHeaderButtons: PropTypes.bool
};

ServicesGenericCollectiveOwnershipList.defaultProps = {
  customColumns: null,
  additionalHeader: null,
  tradKey: null,
  onDoubleClickAction: null,
  noHeaderButtons: false
};
