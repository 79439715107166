import { Collapse, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import ChargingGrid from '../../../../charging-grid/ChargingGrid';
import { setCollapseItems } from '../../../utils/setCollapseItems';
import { RemoveSelectionButton } from '../buttons/RemoveSelectionButton';
import { useCollectiveOwnershipContext } from '../../../../../contexts/CollectiveOwnershipContext';

/**
 * Returns an object containing the tabs items for the document view.
 *
 * @hook
 * @returns {Object} - The tabs items object.
 */
export const documentTabs = ({
  collectiveOwnership,
  setCollectiveOwnership
}) => {
  const { t } = useTranslation();
  const { isLoading } = useCollectiveOwnershipContext();
  const { collapseItems } = setCollapseItems();

  const items = [
    {
      key: '1',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.PCS_1')}</Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '2',
      label: (
        <Row gutter={[16, 16]}>
          <Col>{t('missions.cards.documents.tabs.cerfa_documents')}</Col>
        </Row>
      ),
      children: (
        <Row>
          <Col span={24}>
            <Collapse items={collapseItems} />
          </Col>
          <RemoveSelectionButton />
        </Row>
      )
    },
    {
      key: '3',
      label: t('missions.cards.documents.tabs.charging_grid'),
      children: (
        <ChargingGrid
          resourceDatas={collectiveOwnership}
          setResourceDatas={setCollectiveOwnership}
          isLoading={isLoading}
        />
      )
    }
  ];

  return { items };
};
