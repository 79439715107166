import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Row, Col } from 'antd';
import { QuotationAction } from '../../../models/QuotationAction';
import { useQuotationContext } from '../../../contexts/QuotationContext';

/**
 * Component for merging lots in a quotation action.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.recordId - The ID of the record.
 * @returns {JSX.Element} The rendered component.
 */
export const LotMerge = ({ recordId }) => {
  const { t } = useTranslation();
  const { form } = useQuotationContext();

  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label={t('quotation-actions.form.merge_lot_number')}
          name={['actions', recordId, 'data', 'merge_lot_number']}
        >
          <InputNumber
            onChange={(value) =>
              QuotationAction.handleInputNumberChange({
                value,
                path: ['actions', recordId, 'data', 'merge_lot_ref'],
                form
              })
            }
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.List name={['actions', recordId, 'data', 'merge_lot_ref']}>
          {(listFields) => (
            <>
              {listFields.map(({ key, name, ...restField }) => (
                <Row gutter={[16, 16]} key={key}>
                  <Col span={12}>
                    <Form.Item
                      label={t('quotation-actions.form.sub_merge_lot_number')}
                      {...restField}
                      key={key}
                      name={[name, 'sub_merge_lot_number']}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('quotation-actions.form.sub_merge_lot_merged')}
                      {...restField}
                      key={key}
                      name={[name, 'sub_merge_lot_merged']}
                    >
                      <InputNumber
                        onChange={(value) =>
                          QuotationAction.handleInputNumberChange({
                            value,
                            path: [
                              'actions',
                              recordId,
                              'data',
                              'merge_lot_ref',
                              name,
                              'sub_merge_lot_removed'
                            ],
                            form
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.List name={[name, 'sub_merge_lot_removed']}>
                      {(subListFields) => (
                        <>
                          {subListFields.map(
                            ({
                              key: subKey,
                              name: subName,
                              ...subRestField
                            }) => (
                              <Form.Item
                                label={t(
                                  'quotation-actions.form.sub_merge_lot_removed'
                                )}
                                {...subRestField}
                                key={subKey}
                                name={subName}
                              >
                                <Input />
                              </Form.Item>
                            )
                          )}
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </Col>
      <Col span={24}>
        <Form.Item name={['actions', recordId, 'data', 'observation']}>
          <Input
            placeholder={t('placeholder.observation')}
            style={{ width: '350px' }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

LotMerge.propTypes = {
  recordId: PropTypes.string.isRequired
};
