import { ParametersForm } from './forms/ParametersForm';
import { DetailsForm } from './forms/DetailsForm';
import { SummaryForm } from './forms/SummaryForm';
import { AmountForm } from './forms/AmountForm';
import { DocumentRequestForm } from './forms/DocumentRequest';
import { ValidationForm } from './forms/ValidationForm';

/**
 * Custom hook for managing forms based on the provided purpose.
 *
 * @hook
 * @returns {Object} - An object containing the forms.
 */
export const useForms = () => {
  const forms = [
    <ParametersForm key="parameters" />,
    <DetailsForm key="details" />,
    <SummaryForm key="summary" />,
    <AmountForm key="amount" />,
    <DocumentRequestForm key="document-request" />,
    <ValidationForm key="validation" />
  ];

  return { forms };
};
