import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem/generateFormItem';
import { useCollectiveOwnershipSyndicFields } from './useCollectiveOwnershipSyndicFields';

/**
 * Renders the form for collective ownership.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.initialValues - The initial values for the form fields.
 * @param {Object} props.form - The form instance.
 * @returns {JSX.Element} The rendered component.
 */
export const CollectiveOwnershipForm = ({ initialValues, form }) => {
  const generateFields = useGenerateFormItem();
  const { fields } = useCollectiveOwnershipSyndicFields();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return fields.map((field) => generateFields('pcs-pre-productions', field));
};

CollectiveOwnershipForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  initialValues: PropTypes.shape({})
};

CollectiveOwnershipForm.defaultProps = {
  initialValues: {}
};
