import { useTranslation } from 'react-i18next';
import { ListPcsPreProductions } from './ListPcsPreProductions';

/**
 * Custom hook that returns an array of items for pcs pre productions.
 * Each item contains a label, key, and children component.
 * @hook
 * @returns {Object} The items array.
 */
export const useItems = () => {
  const { t } = useTranslation();
  const items = [
    {
      label: t('pcs-pre-productions.tabs.draft'),
      key: '1',
      children: <ListPcsPreProductions status="DRAFT" />,
      status: 'DRAFT'
    },
    {
      label: t('pcs-pre-productions.tabs.assistant'),
      key: '2',
      children: <ListPcsPreProductions status="ASSISTANT" />,
      status: 'ASSISTANT'
    },
    {
      label: t('pcs-pre-productions.tabs.account_manager'),
      key: '3',
      children: <ListPcsPreProductions status="ACCOUNT_MANAGER" />,
      status: 'ACCOUNT_MANAGER'
    },
    {
      label: t('pcs-pre-productions.tabs.production'),
      key: '4',
      children: <ListPcsPreProductions status="PRODUCTION" />,
      status: 'PRODUCTION'
    },
    {
      label: t('pcs-pre-productions.tabs.all'),
      key: '7',
      children: <ListPcsPreProductions />,
      status: 'ALL'
    }
  ];

  return { items };
};
