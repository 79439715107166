import { Drawer, Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useFields } from '../fields/agDecisionFields';
import { useConfig } from '../utils/agDecisionCreateUpdateConfig';
import { useMissionContext } from '../../../../../contexts/MissionContext';

/**
 * AgDecisionDrawer component.
 *
 * @component
 * @returns {JSX.Element} The AgDecisionDrawer component
 */
export const AgDecisionDrawer = () => {
  const {
    forceRefresh,
    setForceRefresh,
    isDrawerOpen,
    setIsDrawerOpen,
    isLoading
  } = useMissionContext();
  const [form] = Form.useForm();
  const { fields } = useFields({ form });
  const { t } = useTranslation();

  const config = useConfig({ notification, t });

  return (
    <Drawer
      title={t('missions.drawers.titles.ag_decision')}
      onClose={() =>
        setIsDrawerOpen((prev) => ({ ...prev, ag_decision: false }))
      }
      open={isDrawerOpen.ag_decision}
    >
      <CreateUpdateContainer
        customFormInstance={form}
        fields={fields}
        config={config}
        isFieldsLoading={isLoading}
        populate="documents,documents.files"
        purpose="edit"
        baseUrl="missions"
        resource="missions"
        withFilesManager={false}
        withCustomPageHeader
        layout="vertical"
        setIsCreateUpdateOpen={setIsDrawerOpen}
        isCreateUpdateOpen={{ ...isDrawerOpen, ag_decision: false }}
        setForceRefresh={setForceRefresh}
        forceRefresh={forceRefresh}
        customLabelCol={{}}
        customWrapperCol={{}}
      />
    </Drawer>
  );
};
