import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Custom hook for handling permissions related to the charging grid.
 * @returns {Object} An object containing various permissions related to the charging grid.
 */
export const useChargingGridPermissions = () => {
  const { user } = useAuthContext();

  const noPermissions = [
    'users:NOMADE_CUSTOMER_SERVICE_MANAGER',
    'users:LAWYER',
    'users:SYNDIC_MANAGER',
    'users:SYNDIC_ASSISTANT'
  ].includes(user.role);

  const syndicExcludedPermissions = [
    'users:SYNDIC_MANAGER',
    'users:SYNDIC_ASSISTANT'
  ].includes(user.role);

  const exportButtonsPermissions = {
    isDisabled: noPermissions,
    noExportButton: noPermissions
  };
  const actionButtonsPermissions = {
    disableEditButton: noPermissions,
    disableAddColumnButton: noPermissions,
    disasbleAddLotButton: noPermissions,
    disableEditLotButton: noPermissions,
    disableNewVersionButton: noPermissions,
    disableFinalVersionSwitch: noPermissions,
    disableDeleteChargingGridButton: noPermissions
  };
  const onDoubleClickPermissions = noPermissions;
  const disabledRowSelection = noPermissions;
  const disableActionColumn = syndicExcludedPermissions;

  const columnsButtonsPermissions = {
    disableSaveButton: noPermissions,
    disableEditButton: noPermissions,
    disableDeleteRowButton: noPermissions,
    titleButtonsPermissions: {
      disableDeleteButton: noPermissions,
      disableDuplicateButton: noPermissions
    }
  };

  return {
    exportButtonsPermissions,
    actionButtonsPermissions,
    onDoubleClickPermissions,
    columnsButtonsPermissions,
    disabledRowSelection,
    disableActionColumn
  };
};
