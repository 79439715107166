/**
 * Sets multiple filters by merging the provided filter object with the existing filters.
 *
 * @param {Object} filterToAdd - The filter object to add.
 * @param {Function} setFilters - The function to set the filters.
 * @returns {void}
 */
export const setMulitpleFilters = (filterToAdd, setFilters) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    ...filterToAdd
  }));
};

/**
 * Generates a query string based on the provided filters object.
 *
 * @param {Object} filters - The filters object containing key-value pairs.
 * @returns {string} - The generated query string.
 * @example
 * // Returns 'key1=value1&key2=value2'
 * setExtraQuery({ key1: 'value1', key2: 'value2' });
 */
export const transformExtraQuery = (filters) => {
  let query = '';
  const orArray = [];

  Object.keys(filters).forEach((key) => {
    if (key === '$or') {
      const orConditions = filters.$or;

      orConditions.forEach((condition) => {
        Object.keys(condition).forEach((subKey) => {
          const filter = condition[subKey];
          orArray.push(
            `{"${subKey}":${filter.no_operator ? '' : '='}"${filter.value}"}`
          );
        });
      });
    } else if (filters[key] !== undefined && filters[key] !== '') {
      query += `${key}${filters[key]?.no_operator ? '' : '='}${
        filters[key]?.value || filters[key]
      }&`;
    }
  });

  if (orArray.length > 0) {
    query += `filter={"$or":[${orArray.join(',')}]}&`;
  }

  return query.slice(0, -1);
};
