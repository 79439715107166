import { Cerfa } from './Cerfa';

/**
 * Class representing a Cerfa3236 form, which extends the base Cerfa class.
 *
 * @class
 * @extends Cerfa
 */
export class Cerfa3236 extends Cerfa {
  /**
   * Creates an instance of Cerfa3236.
   *
   * @constructor
   */
  constructor() {
    super();

    /**
     * @property {number} limit - The maximum number of lines per CERFA3236.
     * @default 7
     */
    this.limit = 7;

    /**
     * @property {Array} lines - The lines in the CERFA3236.
     */
    this.lines = [];

    /**
     * @property {Object|null} currentLine - Pointer to the last line added to the CERFA3236.
     */
    this.currentLine = null;

    /**
     * @property {number} chequeLimit - The maximum number of lines per cheque in CERFA3236.
     * @default 30
     */
    this.chequeLimit = 30;
  }
}
