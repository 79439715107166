import { useCallback, useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { getEnums } from './utils/getEnums';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useConfig } from './utils/createUpdateConfig';
import { getPrices } from './utils/getPrices';

/**
 * Component for creating or updating a price system.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the price system (e.g., "create" or "update").
 * @returns {JSX.Element} The JSX element representing the create/update price system component.
 */

export const CreateUpdatePriceSystem = ({ purpose }) => {
  const [form] = Form.useForm();
  const { missionType, id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [enums, setEnums] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [prices, setPrices] = useState([]);
  const [priceToEdit, setPriceToEdit] = useState({});
  const [priceData, setPriceData] = useState({ mission: null, type: null });
  const { fields } = useFields({
    enums,
    isFieldsLoading,
    purpose,
    prices,
    priceToEdit,
    setPriceData,
    priceData,
    missionType
  });

  const setDefaultValues = () => {
    setPriceData((prev) => ({ ...prev, mission: missionType }));
    form.setFieldsValue({
      mission: missionType,
      lawyer_ht: missionType === 'PCS_1' ? 150 : undefined
    });
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums({ dispatchAPI, setEnums, message });
    await getPrices({
      dispatchAPI,
      message,
      missionType,
      setPrices,
      setPriceToEdit,
      id
    });
    setDefaultValues();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const config = useConfig({
    prices,
    priceToEdit,
    setPriceData,
    t
  });

  return (
    <CreateUpdateContainer
      customFormInstance={form}
      fields={fields}
      config={config}
      purpose={purpose}
      baseUrl="price-systems"
      resource="price-systems"
      withFilesManager={false}
      withEnums={false}
    />
  );
};

CreateUpdatePriceSystem.propTypes = {
  purpose: PropTypes.string.isRequired
};
