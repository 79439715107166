/**
 * Returns the action fields map.
 * @returns {Object} The action fields map.
 */
export const useFixInheritancesFields = () => {
  const actionFieldsMap = {
    CHARGING_GRID: [
      {
        name: 'charging_grid',
        label: 'quotation-act-amounts.form.charging_grid',
        key: 'charging_grid'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      { name: 'tva', label: 'quotation-act-amounts.form.tva', key: 'tva' },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total_ttc',
        key: 'total_ttc'
      }
    ],
    LOT_CREATION_PCS: [
      {
        name: 'EDD_update',
        label: 'quotation-act-amounts.form.EDD_update',
        key: 'EDD_update'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      { name: 'tva', label: 'quotation-act-amounts.form.tva', key: 'tva' },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total_ttc',
        key: 'total_ttc'
      }
    ],
    LOT_CREATION: [
      {
        name: 'RC_or_EDD_update',
        label: 'quotation-act-amounts.form.RC_or_EDD_update',
        key: 'RC_or_EDD_update'
      },
      {
        name: 'EDD_update',
        label: 'quotation-act-amounts.form.EDD_update',
        key: 'EDD_update'
      },
      {
        name: 'digital_archiving',
        label: 'quotation-act-amounts.form.digital_archiving',
        input: 'number',
        unit: '€',
        key: 'digital_archiving'
      },
      {
        name: 'obtaining_all_necessary_documents',
        label: 'quotation-act-amounts.form.obtaining_all_necessary_documents',
        input: 'number',
        unit: '€',
        key: 'obtaining_all_necessary_documents'
      },
      {
        name: 'publication_requisition',
        label: 'quotation-act-amounts.form.publication_requisition',
        key: 'publication_requisition'
      },
      {
        name: 'published_act_package',
        label: 'quotation-act-amounts.form.published_act_package',
        key: 'published_act_package'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_ttc'
      },
      {
        name: 'notary_honorary_extra',
        label: 'quotation-act-amounts.form.notary_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_extra'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      {
        name: 'tva',
        label: 'quotation-act-amounts.form.tva',
        key: 'tva'
      },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total_ttc',
        key: 'total_ttc'
      }
    ],
    LOT_MERGE: [
      {
        name: 'RC_or_EDD_update',
        label: 'quotation-act-amounts.form.RC_or_EDD_update',
        key: 'RC_or_EDD_update'
      },
      {
        name: 'EDD_update',
        label: 'quotation-act-amounts.form.EDD_update',
        key: 'EDD_update'
      },
      {
        name: 'digital_archiving',
        label: 'quotation-act-amounts.form.digital_archiving',
        input: 'number',
        unit: '€',
        key: 'digital_archiving'
      },
      {
        name: 'obtaining_all_necessary_documents',
        label: 'quotation-act-amounts.form.obtaining_all_necessary_documents',
        input: 'number',
        unit: '€',
        key: 'obtaining_all_necessary_documents'
      },
      {
        name: 'publication_requisition',
        label: 'quotation-act-amounts.form.publication_requisition',
        key: 'publication_requisition'
      },
      {
        name: 'published_act_package',
        label: 'quotation-act-amounts.form.published_act_package',
        key: 'published_act_package'
      },
      {
        name: 'proxy_fee',
        label: 'quotation-act-amounts.form.proxy_fee',
        key: 'proxy_fee'
      },
      {
        name: 'lot_sheet_cost',
        label: 'quotation-act-amounts.form.lot_sheet_cost',
        key: 'lot_sheet_cost'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_ttc'
      },
      {
        name: 'notary_honorary_extra',
        label: 'quotation-act-amounts.form.notary_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_extra'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      {
        name: 'tva',
        label: 'quotation-act-amounts.form.tva',
        key: 'tva'
      },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total_ttc',
        key: 'total_ttc'
      }
    ],
    LOT_DIVISION: [
      {
        name: 'RC_or_EDD_update',
        label: 'quotation-act-amounts.form.RC_or_EDD_update',
        key: 'RC_or_EDD_update'
      },
      {
        name: 'EDD_update',
        label: 'quotation-act-amounts.form.EDD_update',
        key: 'EDD_update'
      },
      {
        name: 'digital_archiving',
        label: 'quotation-act-amounts.form.digital_archiving',
        input: 'number',
        unit: '€',
        key: 'digital_archiving'
      },
      {
        name: 'obtaining_all_necessary_documents',
        label: 'quotation-act-amounts.form.obtaining_all_necessary_documents',
        input: 'number',
        unit: '€',
        key: 'obtaining_all_necessary_documents'
      },
      {
        name: 'publication_requisition',
        label: 'quotation-act-amounts.form.publication_requisition',
        key: 'publication_requisition'
      },
      {
        name: 'published_act_package',
        label: 'quotation-act-amounts.form.published_act_package',
        key: 'published_act_package'
      },
      {
        name: 'proxy_fee',
        label: 'quotation-act-amounts.form.proxy_fee',
        key: 'proxy_fee'
      },
      {
        name: 'lot_sheet_cost',
        label: 'quotation-act-amounts.form.lot_sheet_cost',
        key: 'lot_sheet_cost'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_ttc'
      },
      {
        name: 'notary_honorary_extra',
        label: 'quotation-act-amounts.form.notary_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_extra'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      {
        name: 'tva',
        label: 'quotation-act-amounts.form.tva',
        key: 'tva'
      },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total_ttc',
        key: 'total_ttc'
      }
    ],
    LOT_REMOVAL: [
      {
        name: 'RC_or_EDD_update',
        label: 'quotation-act-amounts.form.RC_or_EDD_update',
        key: 'RC_or_EDD_update'
      },
      {
        name: 'EDD_update',
        label: 'quotation-act-amounts.form.EDD_update',
        key: 'EDD_update'
      },
      {
        name: 'digital_archiving',
        label: 'quotation-act-amounts.form.digital_archiving',
        input: 'number',
        unit: '€',
        key: 'digital_archiving'
      },
      {
        name: 'obtaining_all_necessary_documents',
        label: 'quotation-act-amounts.form.obtaining_all_necessary_documents',
        input: 'number',
        unit: '€',
        key: 'obtaining_all_necessary_documents'
      },
      {
        name: 'publication_requisition',
        label: 'quotation-act-amounts.form.publication_requisition',
        key: 'publication_requisition'
      },
      {
        name: 'published_act_package',
        label: 'quotation-act-amounts.form.published_act_package',
        key: 'published_act_package'
      },
      {
        name: 'proxy_fee',
        label: 'quotation-act-amounts.form.proxy_fee',
        key: 'proxy_fee'
      },
      {
        name: 'lot_sheet_cost',
        label: 'quotation-act-amounts.form.lot_sheet_cost',
        key: 'lot_sheet_cost'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_ttc'
      },
      {
        name: 'notary_honorary_extra',
        label: 'quotation-act-amounts.form.notary_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_extra'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      {
        name: 'tva',
        label: 'quotation-act-amounts.form.tva',
        key: 'tva'
      },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total_ttc',
        key: 'total_ttc'
      }
    ],
    CONSISTENCY_DESTINATION_CHANGE: [
      {
        name: 'RC_or_EDD_update',
        label: 'quotation-act-amounts.form.RC_or_EDD_update',
        key: 'RC_or_EDD_update'
      },
      {
        name: 'EDD_update',
        label: 'quotation-act-amounts.form.EDD_update',
        key: 'EDD_update'
      },
      {
        name: 'digital_archiving',
        label: 'quotation-act-amounts.form.digital_archiving',
        input: 'number',
        unit: '€',
        key: 'digital_archiving'
      },
      {
        name: 'obtaining_all_necessary_documents',
        label: 'quotation-act-amounts.form.obtaining_all_necessary_documents',
        input: 'number',
        unit: '€',
        key: 'obtaining_all_necessary_documents'
      },
      {
        name: 'publication_requisition',
        label: 'quotation-act-amounts.form.publication_requisition',
        key: 'publication_requisition'
      },
      {
        name: 'published_act_package',
        label: 'quotation-act-amounts.form.published_act_package',
        key: 'published_act_package'
      },
      {
        name: 'proxy_fee',
        label: 'quotation-act-amounts.form.proxy_fee',
        key: 'proxy_fee'
      },
      {
        name: 'lot_sheet_cost',
        label: 'quotation-act-amounts.form.lot_sheet_cost',
        key: 'lot_sheet_cost'
      },
      {
        name: 'notary_honorary_ttc',
        label: 'quotation-act-amounts.form.notary_honorary_ttc',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_ttc'
      },
      {
        name: 'notary_honorary_extra',
        label: 'quotation-act-amounts.form.notary_honorary_extra',
        input: 'number',
        unit: '€',
        key: 'notary_honorary_extra'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      {
        name: 'tva',
        label: 'quotation-act-amounts.form.tva',
        key: 'tva'
      },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total_ttc',
        key: 'total_ttc'
      }
    ],
    LOT_REMOVAL_PCS: [
      {
        name: 'EDD_update',
        label: 'quotation-act-amounts.form.EDD_update',
        key: 'EDD_update'
      },
      {
        name: 'total_ht',
        label: 'quotation-act-amounts.form.total_ht',
        key: 'total_ht'
      },
      { name: 'tva', label: 'quotation-act-amounts.form.tva', key: 'tva' },
      {
        name: 'total_ttc',
        label: 'quotation-act-amounts.form.total_ttc',
        key: 'total_ttc'
      }
    ]
  };
  return { actionFieldsMap };
};
