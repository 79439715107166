import { BaseModel } from './BaseModel';

/**
 * Represents a message.
 * @class
 */
export class Message {
  static resourceName = 'messages';

  /**
   * Get the resources.
   * @function
   * @async
   * @param {Object} options - The options object.
   * @param {string} options.id - The ID from the model.
   * @param {string} options.model - The model to get the resources from.
   * @param {string} [populate=''] - The fields to populate.
   * @param {string} [extraQuery=''] - The extra query.
   * @returns {Promise<Object[]>} The resources.
   * @static
   */
  static async getResources({ id, model, populate = '', extraQuery = '' }) {
    const { data } = await BaseModel.dispatchAPI('GET', {
      url: `${Message.resourceName}/${id}/${model}?populate=${populate}${extraQuery}`
    });
    return data;
  }

  /**
   * Retrieves the recipients for a given collective ownership ID.
   * @function
   * @async
   * @static
   * @param {string} id - The ID of the collective ownership.
   * @param {string} model - The model from to get recipient.
   * @returns {Promise<Array>} - A promise that resolves to an array of recipients.
   */
  static async getRecipients(id = '', model = '') {
    const { data } = await BaseModel.dispatchAPI('GET', {
      url: `${Message.resourceName}/recipients/${id}/${model}`
    });

    return data.filter((recipient) => recipient?.first_name);
  }

  /**
   * Handles the selection of a value in the form.
   *
   * @function
   * @static
   * @param {Object} options - The options object.
   * @param {string} options.value - The selected value.
   * @param {Array} options.selectedValues - The currently selected values.
   * @param {function} options.setSelectedValues - The function to set the selected values.
   * @param {object} options.form - The form object.
   * @returns {void}
   */
  static handleSelect = ({
    value,
    selectedValues,
    setSelectedValues,
    form
  }) => {
    if (value === 'nomade_intern_note' && selectedValues.length) {
      form.setFieldsValue({
        recipients: [value]
      });
      setSelectedValues([value]);
    } else if (selectedValues.includes('nomade_intern_note')) {
      form.setFieldsValue({
        recipients: [
          value,
          ...selectedValues.filter((el) => el !== 'nomade_intern_note')
        ]
      });
      setSelectedValues([
        value,
        ...selectedValues.filter((el) => el !== 'nomade_intern_note')
      ]);
    } else {
      setSelectedValues((prev) => [...prev, value]);
    }
  };

  /**
   * Sets the field values for a message.
   *
   * @function
   * @static
   * @param {Object} options - The options for setting the field values.
   * @param {Form} options.form - The form object.
   * @param {Message} options.message - The message object.
   * @param {Function} options.setMessageDateWhoReceiveResponse - The function to set the message date.
   * @param {Function} options.setSelectedRecipientsValues - The function to set the selected recipients.
   * @param {Function} options.setIsCreateMessageCardOpen - The function to set the create message card open state.
   * @returns {void}
   */
  static setFieldsValues = ({
    form,
    message,
    setMessageDateWhoReceiveResponse,
    setSelectedRecipientsValues,
    setIsCreateMessageCardOpen
  }) => {
    setMessageDateWhoReceiveResponse(message.created_at);
    setSelectedRecipientsValues(
      message.intern_message ? ['nomade_intern_note'] : [message.author._id]
    );
    setIsCreateMessageCardOpen(true);
    form.setFieldsValue({
      mission: message.mission,
      recipients: message.intern_message
        ? ['nomade_intern_note']
        : [message.author._id],
      subject: message?.subject || ''
    });
  };

  /**
   * Returns the extra query value based on the selected value.
   *
   * @function
   * @static
   * @param {string} value - The selected value.
   * @returns {string} The extra query value.
   */
  static extraQueryValue = (value) => {
    if (value) {
      return value === 'nomade_intern_note'
        ? `&intern_message=${true}`
        : `&recipient=${value}`;
    }

    return '';
  };

  /**
   * Generates an array of recipient options based on the provided parameters.
   *
   * @function
   * @param {Object} options - The options object.
   * @param {Array} options.recipients - The array of recipients.
   * @param {Function} options.t - The translation function.
   * @param {boolean} options.intern_note - Indicates whether an intern note option should be included.
   * @param {Object} options.user - The user object.
   * @returns {Array} An array of recipient options.
   */
  static recipientOptions = ({ recipients, t, intern_note, user }) => {
    const recipientOptions = (recipients || [])
      .filter((el) => el?._id !== user?._id)
      .map((r) => ({
        label: `${t(`messages.roles.${r?.role?.split(':')[1]}`)} - ${
          r?.first_name || ''
        } ${r?.last_name || ''}`,
        value: r?._id,
        searchValue: `${r?.first_name || ''} ${r?.last_name || ''}`
      }));

    if (intern_note && user?.role?.includes('NOMADE')) {
      recipientOptions.unshift({
        label: t('messages.form.nomade_intern_note'),
        value: 'nomade_intern_note',
        searchValue: t('messages.form.nomade_intern_note')
      });
    }

    return recipientOptions;
  };
}
