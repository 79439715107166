import { Col, Popconfirm, Row, Tag, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { routes } from '../../utils/constants/routes/adminRoutes';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Event } from '../../models/Event';
import { Key } from 'react';
const { Text } = Typography;

const Cell = styled.div`
  list-style: none;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const rowStyle = {
  cursor: 'pointer',
  justifyContent: 'space-between'
};

const iconStyle = {
  cursor: 'pointer'
};

const tagStyle = {
  backgroundColor: '#ffaa7f',
  paddingRight: '4px',
  paddingLeft: '4px',
  marginTop: '2px',
  border: 'none',
  borderRadius: '8%',
  color: 'black'
};

const textStyle = { color: 'white', fontSize: '15px' };

const formatTime = (time: string) =>
  moment(time).format('HH:mm').replace(':', 'h');

const handleDrawerOpen = (
  eventItem: any,
  setFormToOpen: any,
  setEvent: any,
  setPurpose: any,
  setDrawerTitle: any,
  setIsDrawerOpen: any
) => {
  setFormToOpen(eventItem.type);
  setEvent(eventItem);
  setPurpose('edit');
  setDrawerTitle(`events.updates.${eventItem.type.toLowerCase()}`);
  setIsDrawerOpen(true);
};

/**
 * Renders the cell for an unavailable event.
 *
 * @param eventItem - The event item object.
 * @param allDay - Indicates if the event is an all-day event.
 * @param setIsDrawerOpen - Function to set the state of the drawer open status.
 * @param setEvent - Function to set the event state.
 * @param setPurpose - Function to set the purpose state.
 * @param setFormToOpen - Function to set the form to open state.
 * @param setDrawerTitle - Function to set the drawer title state.
 * @returns The JSX element representing the cell for an unavailable event.
 */
export const unavailableCellRender = (
  eventItem: {
    id: string;
    date: Date;
    startTime?: string;
    endTime?: string;
    type: string;
  },
  allDay: boolean,
  setIsDrawerOpen: any,
  setEvent: any,
  setPurpose: any,
  setFormToOpen: any,
  setDrawerTitle: any
) => {
  if (allDay === false) {
    return (
      <div style={{ backgroundColor: 'grey' }}>
        <Row
          style={{
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
          onClick={() => {
            setFormToOpen(eventItem.type);
            setEvent(eventItem);
            setPurpose('edit');
            setDrawerTitle(`events.updates.${eventItem.type.toLowerCase()}`);
            setIsDrawerOpen(true);
          }}
        >
          <Col>{`${moment(eventItem.startTime)
            .format('HH:mm')
            .replace(':', 'h')} - ${moment(eventItem.endTime)
            .format('HH:mm')
            .replace(':', 'h')}`}</Col>
          <Col>
            {moment
              .utc(moment(eventItem.endTime).diff(moment(eventItem.startTime)))
              .format('HH:mm')
              .replace(':', 'h')}
          </Col>
        </Row>
        <b style={{ fontSize: '16px' }}>Indisponibilité</b>
      </div>
    );
  }
  return (
    <Row
      style={{
        justifyContent: 'space-between',
        cursor: 'pointer'
      }}
      onClick={() => {
        setFormToOpen(eventItem.type);
        setEvent(eventItem);
        setPurpose('edit');
        setDrawerTitle(`events.updates.${eventItem.type.toLowerCase()}`);
        setIsDrawerOpen(true);
      }}
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>{moment(eventItem.date).format('DD-MM-YYYY')}</Col>
      </Row>
    </Row>
  );
};

/**
 * Renders the cell for a week in the calendar.
 *
 * @param eventItem - The event item to render.
 * @param allDay - Indicates if the event is an all-day event.
 * @param forceRefresh - Indicates if the calendar should be force refreshed.
 * @param setForceRefresh - Callback function to set the force refresh state.
 * @param setIsDrawerOpen - Callback function to set the drawer open state.
 * @param setEvent - Callback function to set the selected event.
 * @param setPurpose - Callback function to set the purpose of the form.
 * @param setFormToOpen - Callback function to set the form to open.
 * @param setDrawerTitle - Callback function to set the title of the drawer.
 * @param isEventOwner - Indicates if the current user is the owner of the event.
 * @returns The JSX element representing the cell for the week.
 */
export const weekCellRender = (
  eventItem: any,
  allDay: boolean,
  forceRefresh: boolean,
  setForceRefresh: any,
  setIsDrawerOpen: any,
  setEvent: any,
  setPurpose: any,
  setFormToOpen: any,
  setDrawerTitle: any,
  isEventOwner: boolean
) => {
  if (allDay === false) {
    return (
      <>
        <Row
          style={rowStyle}
          onClick={() =>
            handleDrawerOpen(
              eventItem,
              setFormToOpen,
              setEvent,
              setPurpose,
              setDrawerTitle,
              setIsDrawerOpen
            )
          }
        >
          <Col>{`${formatTime(eventItem.startTime)} - ${formatTime(
            eventItem.endTime
          )}`}</Col>
          <Col>
            {moment
              .utc(moment(eventItem.endTime).diff(moment(eventItem.startTime)))
              .format('HH:mm')
              .replace(':', 'h')}
          </Col>
          <Tooltip
            title={`${eventItem.user?.first_name || ''} ${
              eventItem.user?.last_name || ''
            }`}
          >
            <Tag style={tagStyle}>
              <Text strong style={{ color: 'black', fontSize: '12px' }}>
                {eventItem.user?.first_name?.[0]}
                {eventItem.user?.last_name?.[0]}
              </Text>
            </Tag>
          </Tooltip>
        </Row>
        <Row style={rowStyle}>
          <Col>
            <Text strong style={textStyle}>
              {eventItem?.title || eventItem?.syndic?.society_name}
            </Text>
          </Col>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col style={{ cursor: 'pointer', paddingRight: '5px' }}>
              <EditOutlined
                onClick={() => {
                  setPurpose('edit');
                  setFormToOpen(eventItem.type);
                  setDrawerTitle(
                    `events.updates.${eventItem.type.toLowerCase()}`
                  );
                  setEvent(eventItem);
                  setIsDrawerOpen(true);
                }}
              />
            </Col>
            <Col style={{ cursor: 'pointer' }}>
              <Popconfirm
                title="Etes-vous sûr de vouloir supprimer cet événement ?"
                onConfirm={async () => {
                  await Event.deleteResource(eventItem.id);
                  setForceRefresh(!forceRefresh);
                }}
                okText="Oui"
                cancelText="Non"
              >
                <DeleteOutlined />
              </Popconfirm>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col style={{ width: 'auto', overflow: 'scroll' }}>
              {eventItem.userTarget && eventItem.type === 'EXTERN' ? (
                <>
                  <Text
                    style={textStyle}
                  >{`${eventItem.userTarget?.first_name} ${eventItem.userTarget?.last_name}`}</Text>
                  <br />
                </>
              ) : null}
              {eventItem.informations && (
                <div
                  style={{
                    width: '100%',
                    height: 50,
                    overflow: 'scroll',
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  {eventItem.informations}
                </div>
              )}
            </Col>
            <Col>{eventItem.contacts}</Col>
          </Row>
        </Row>
      </>
    );
  }

  return (
    <Row
      style={{
        justifyContent: 'space-between',
        cursor: 'pointer'
      }}
      onClick={() => {
        setFormToOpen(eventItem.type);
        setEvent(eventItem);
        setPurpose('edit');
        setDrawerTitle(`events.updates.${eventItem.type.toLowerCase()}`);
        setIsDrawerOpen(true);
      }}
    >
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>{moment(eventItem.date).format('DD-MM-YYYY')}</Col>
      </Row>
    </Row>
  );
};

/**
 * Renders the events for a specific month cell in the calendar.
 *
 * @param value - The moment object representing the date of the month cell.
 * @param events - An array of events to be displayed.
 * @param setIsDrawerOpen - A function to set the state of the drawer open status.
 * @param setEvent - A function to set the selected event.
 * @param setPurpose - A function to set the purpose of the event.
 * @param setFormToOpen - A function to set the form to open in the drawer.
 * @param setDrawerTitle - A function to set the title of the drawer.
 * @returns An array of JSX elements representing the events to be displayed in the month cell.
 */
export const monthCellRender = (
  value: moment.Moment,
  events: Array<any>,
  setIsDrawerOpen: any,
  setEvent: any,
  setPurpose: any,
  setFormToOpen: any,
  setDrawerTitle: any
) => {
  const date = value;

  const eventsToDisplay = (events || []).filter(
    (event: { date: moment.MomentInput }) =>
      date.day() !== 0 &&
      date.format('YYYY-MM-DD') >= moment(event.date).format('YYYY-MM-DD') &&
      date.format('YYYY-MM-DD') <= moment(event.date).format('YYYY-MM-DD') &&
      moment(date).isoWeekday() !== 7 &&
      moment(date).isoWeekday() !== 6
  );

  return eventsToDisplay.map(
    (event: {
      _id: Key | null | undefined;
      backgroundColor: string | (string & {}) | undefined;
      time_slots: { start: string; end: string };
      title: any;
      type: any;
    }) => (
      <li key={event._id}>
        <Tag
          style={{
            backgroundColor: event.backgroundColor
              ? event.backgroundColor
              : '#1890ff',
            border: 'none',
            borderRadius: '2px',
            color: 'white'
          }}
          onClick={() => {
            setFormToOpen(event.type);
            setEvent(event);
            setPurpose('edit');
            setDrawerTitle(`events.updates.${event.type.toLowerCase()}`);
            setIsDrawerOpen(true);
          }}
        >
          <Row
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Col>{`${moment(event.time_slots.start)
              .format('HH:mm')
              .replace(':', 'h')} - ${moment(event.time_slots.end)
              .format('HH:mm')
              .replace(':', 'h')} ${event.title}`}</Col>
          </Row>
        </Tag>
      </li>
    )
  );
};

export const unavailableMonthCellRender = (
  value: moment.Moment,
  events: Array<any>
) => {
  const date = value;
  const eventsToDisplay = (events || []).filter(
    (event) =>
      date.day() !== 0 &&
      date.format('YYYY-MM-DD') >= moment(event.date).format('YYYY-MM-DD') &&
      date.format('YYYY-MM-DD') <= moment(event.date).format('YYYY-MM-DD') &&
      moment(date).isoWeekday() !== 7 &&
      moment(date).isoWeekday() !== 6
  );

  return eventsToDisplay.map((event) => (
    <li key={event._id}>
      <Tag
        style={{
          backgroundColor: 'grey',
          border: 'none',
          borderRadius: '2px',
          color: 'white'
        }}
      >
        <Row
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Col>{`${moment(event.time_slots.start)
            .format('HH:mm')
            .replace(':', 'h')} - ${moment(event.time_slots.end)
            .format('HH:mm')
            .replace(':', 'h')}`}</Col>
        </Row>
      </Tag>
    </li>
  ));
};

/**
 * Renders the content for an annual cell in the calendar.
 *
 * @param value - The moment object representing the date of the cell.
 * @param events - An array of events to be displayed in the cell.
 * @returns The JSX element representing the content of the cell.
 */
export const annualCellRender = (value: moment.Moment, events: Array<any>) => {
  const month = moment(value).month();
  return (
    <Cell>
      {(events || [])
        .filter(
          (event: { date: moment.MomentInput }) =>
            month >= moment(event.date).month() &&
            month <= moment(event.date).month()
        )
        .sort(
          (a: { date: moment.MomentInput }, b: { date: moment.MomentInput }) =>
            moment(b.date).day() - moment(a.date).day()
        )
        .map(
          (event: {
            _id: Key | null | undefined;
            time_slots: { start: moment.MomentInput };
          }) => (
            <li key={event._id}>
              <Link to={`${routes.EVENTS}/show/${event._id}`}>
                {moment(event.time_slots.start).format('DD-MM-YYYY')}
              </Link>
            </li>
          )
        )}
    </Cell>
  );
};
