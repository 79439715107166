import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Switch
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Invoice } from '../../../../models/Invoice';

/**
 * Renders a form component for adding payments to invoices.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.invoices - The list of invoices.
 * @returns {JSX.Element} The rendered component.
 */
export const AllInfos = ({ invoices }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    if (values.mark_all_as_paid) {
      const invoicesIds = (invoices || []).map((invoice) => invoice._id);
      await Invoice.payAllInvoices({ invoices: invoicesIds });
      message.success(t('invoices.messages.success.pay_all_invoices'));
    }
    navigate(-1);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{
        overflow: 'auto',
        height: 'calc(100vh - 200px)',
        padding: '24px',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            name="settlement_date"
            label={t('invoices.form.settlement_date')}
            initialValue={dayjs()}
          >
            <DatePicker format={['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY']} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="mark_all_as_paid"
            label={t('invoices.form.mark_all_as_paid')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item name="comments" label={t('invoices.form.comments')}>
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            {t('invoices.form.save')}
          </Button>
          <Button style={{ marginLeft: 8 }}>{t('invoices.form.cancel')}</Button>
        </Col>
      </Row>
    </Form>
  );
};

AllInfos.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
