import { Button, Col } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Renders the confirm refunds button based on the mission status.
 *
 * @function
 * @param {Object} props - The component props.
 * @param {Object} props.mission - The mission object.
 * @param {Function} props.setIsDrawerOpen - The function to set the drawer open state.
 * @returns {JSX.Element|null} The confirm refunds button component or null.
 */
export const confirmRefundsButtons = ({ mission, setIsDrawerOpen }) => {
  const { t } = useTranslation();

  if (mission?.status === 'CANCELED' && !mission?.is_refunds_confirmed) {
    return (
      <Col>
        <Button
          onClick={() =>
            setIsDrawerOpen((prev) => ({
              ...prev,
              confirm_refunds: true
            }))
          }
        >
          {t('missions.buttons.confirm_refunds')}
        </Button>
      </Col>
    );
  }

  return null;
};
