import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { ExpandedChequeList } from './expandableList/ExpandedChequeList';
import { ExtraButtons } from './ExtraButtons';
import { transformExtraQuery } from '../../../utils/filtersUtils';

/**
 * Renders a list of accountant exports.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of accountant exports.
 * @returns {JSX.Element} The rendered component.
 */
export const ListAccountantExports = ({ type }) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [filters, setFilters] = useState({});
  const [extraQuery, setExtraQuery] = useState('');
  const [resourceData, setResourceData] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState({
    key: 'key',
    rowKeys: []
  });
  const columns = useColumns({ type });

  const rowSelection = {
    selectedRowKeys: selectedRowKeys.rowKeys,
    onChange: (rowKeys) => {
      setSelectedRowKeys({ key: 'key', rowKeys });
    }
  };

  const expandedRowRender = useCallback((record) => {
    const tableData = record?.expandable_list;
    return <ExpandedChequeList datasource={tableData} />;
  }, []);

  useEffect(() => {
    if (filters) {
      const updatedExtraQuery = transformExtraQuery(filters);
      setExtraQuery(updatedExtraQuery);
    }
    setForceRefresh((prev) => !prev);
  }, [filters]);

  return (
    <ListResource
      style={{ margin: '0' }}
      resourceName={`invoices/accountant-exports/${type}`}
      resourceModelName="Invoice"
      setResourceData={setResourceData}
      extraQuery={extraQuery}
      columns={columns}
      withCreateButton={false}
      withUploadButton
      customActionColumn
      withPageHeader={false}
      withSearchBar={false}
      forceRefresh={forceRefresh}
      scroll={{ x: 'max-content' }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection
      }}
      expandable={{
        expandedRowRender,
        rowExpandable: (record) => record?.expandable_list?.length > 0
      }}
      extraButtons={
        <ExtraButtons
          setFilters={setFilters}
          filters={filters}
          setForceRefresh={setForceRefresh}
          type={type}
          extraQuery={extraQuery}
          selectedRowKeys={selectedRowKeys}
          resourceData={resourceData}
        />
      }
    />
  );
};

ListAccountantExports.propTypes = {
  type: PropTypes.string.isRequired
};
