import { useTranslation } from 'react-i18next';

/**
 * Returns an array of columns for the syndic card statistics.
 * @hook
 *
 * @param {string} tradKey - The translation key.
 * @returns {Array} An array of column objects.
 */
export const useColumns = (tradKey) => {
  const { t } = useTranslation();

  return [
    {
      title: t(`${tradKey}.syndic`),
      key: 'syndic',
      render: (record) =>
        `${record.society_name || ''} ${record.code ? `(${record.code})` : ''}`
    },
    {
      title: t(`${tradKey}.PCS_1`),
      children: [
        {
          title: t(`${tradKey}.entrusted`),
          key: 'PCS_1',
          dataIndex: ['PCS_1_status', 'TOTAL']
        },
        {
          title: t(`${tradKey}.treated`),
          key: 'PCS_1',
          dataIndex: ['PCS_1_status', 'FINISHED']
        },
        {
          title: t(`${tradKey}.COMPLIANCE`),
          key: 'COMPLIANCE',
          dataIndex: ['PCS_1_status', 'COMPLIANCE']
        }
      ]
    },
    {
      title: t(`${tradKey}.PCS_2`),
      key: 'PCS_2',
      children: [
        {
          title: t(`${tradKey}.entrusted`),
          key: 'PCS_2',
          dataIndex: ['PCS_2_status', 'TOTAL']
        },
        {
          title: t(`${tradKey}.treated`),
          key: 'PCS_2',
          dataIndex: ['PCS_2_status', 'FINISHED']
        }
      ]
    },
    {
      title: t(`${tradKey}.PCS_3`),
      key: 'PCS_3',
      children: [
        {
          title: t(`${tradKey}.entrusted`),
          key: 'PCS_2',
          dataIndex: ['PCS_3_status', 'TOTAL']
        },
        {
          title: t(`${tradKey}.treated`),
          key: 'PCS_3',
          dataIndex: ['PCS_3_status', 'FINISHED']
        }
      ]
    }
  ];
};
