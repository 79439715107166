import { Cerfa3231 } from './Cerfa3231';
import { Cerfa3233 } from './Cerfa3233';
import { Cerfa3236 } from './Cerfa3236';

/**
 * Class representing a Cheque.
 *
 * @class
 */
export class Cheque {
  /**
   * Creates an instance of Cheque.
   *
   * @constructor
   */
  constructor() {
    /**
     * @property {Array} cerfas - The Cerfa forms associated with the cheque.
     */
    this.cerfas = [];

    /**
     * @property {Object|null} currentCerfa - Pointer to the current Cerfa form.
     */
    this.currentCerfa = null;

    /**
     * @property {string} chequeNumber - The cheque number.
     */
    this.chequeNumber = '';
  }

  /**
   * Static method to get a new Cerfa instance based on the Cerfa code.
   *
   * @static
   * @method
   * @param {string} cerfa_code - The Cerfa code.
   * @returns {Cerfa} - A new instance of Cerfa based on the Cerfa code.
   */
  static getNewCerfa(cerfa_code) {
    switch (cerfa_code) {
      case '3231':
        return new Cerfa3231();
      case '3233':
        return new Cerfa3233();
      case '3236':
        return new Cerfa3236();
      default:
        return new Cerfa3231();
    }
  }

  /**
   * Getter method to calculate the total number of lines across all Cerfa forms associated with the cheque.
   *
   * @method
   * @param {string} cerfaType - The type of CERFA (Cerfa3231, Cerfa3233, Cerfa3236)
   * @returns {number} - The total number of lines.
   */
  getLinesLength(cerfaType) {
    let lines = 0;

    // On récupère les CERFA de même nature que celui qu'on traite
    const cerfas = this.cerfas.filter(
      (cerfa) => cerfa.constructor.name === cerfaType
    );

    // Si aucun CERFA n'est sauvegardé (état de début)
    // On compte seulement le nombre de lignes du CERFA qui est en train d'être traité
    if (!cerfas.length) {
      lines += this.currentCerfa.lines.length;
      return lines;
    }

    // Si on a au moins un CERFA de sauvegardé
    // On compte le nombre de lignes des CERFA sauvegardés
    // + le nb de lignes du CERFA qui est en train d'être traité
    cerfas.forEach((cerfa) => {
      lines += cerfa.lines.length;
    });
    lines += this.currentCerfa.lines.length;

    return lines;
  }
}
