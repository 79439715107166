import { useEffect, useState } from 'react';
import { Checkbox, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCollectiveOwnershipSyndicFields } from '../../ComplianceElanRouter/Audit/Step1/useCollectiveOwnershipSyndicFields';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';
import { formatAddress } from '../../../utils/formatingFunctions';

/**
 * Custom hook that provides fields for the DevisCreateUpdate component.
 * @hook
 * @param {Object} options - The options for the hook.
 * @param {string} options.requested_mission_type - The requested mission type.
 * @param {Object} options.form - The form object.
 * @returns {Object} - The fields object.
 */
export const useFields = ({ requested_mission_type, form }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { currentSyndic } = useGlobalContext();
  const { message } = useErrorMessage();
  const [collectiveOwnerships, setCollectiveOwnerships] = useState([]);
  const [new_collective_ownership, setNewCollectiveOwnership] = useState(false);
  const { fields: collective_ownership_fields } =
    useCollectiveOwnershipSyndicFields();

  const fetchCollectiveOwnership = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `collective-ownership?syndic=${currentSyndic}&fields=name,first_address,first_city&populate=first_city`
      });
      const collectiveOwnershipsToSet = data.map(
        ({ _id, name, first_address, first_city }) => ({
          label: formatAddress(name, first_address, first_city),
          value: _id
        })
      );
      setCollectiveOwnerships(collectiveOwnershipsToSet);
    } catch (error) {
      message('error', error.message);
    }
  };

  useEffect(() => {
    if (form.getFieldValue('add_collective_ownership')) {
      setNewCollectiveOwnership(true);
    }
  }, [form.getFieldValue('add_collective_ownership')]);

  useEffect(() => {
    if (new_collective_ownership) {
      form.setFieldsValue({
        collective_ownership: null
      });
    }
  }, [new_collective_ownership, form]);

  useEffect(() => {
    (async () => {
      await fetchCollectiveOwnership();
    })();
  }, []);

  const fields = [
    {
      startWithDivider: {
        title: t('syndic-requests.form.requested_mission_type')
      },
      noLabel: true,
      name: ['requested_mission_type'],
      key: 'requested_mission_type',
      input: (
        <Select
          disabled
          defaultValue={requested_mission_type}
          value={requested_mission_type}
          options={[
            {
              label: t(`missions.tags.${requested_mission_type}`),
              value: requested_mission_type
            }
          ]}
        />
      )
    },
    {
      startWithDivider: {
        title: t('syndic-requests.form.collective_ownership')
      },
      name: ['collective_ownership'],
      key: 'collective_ownership',
      input: (
        <Select
          options={collectiveOwnerships}
          disabled={new_collective_ownership}
        />
      )
    },
    {
      startWithDivider: {
        title: t('syndic-requests.form.add_collective_ownership_divider')
      },
      name: ['add_collective_ownership'],
      valuePropName: 'checked',
      input: (
        <Checkbox
          onChange={(e) => {
            setNewCollectiveOwnership(e.target.checked);
          }}
        />
      )
    }
  ];

  if (new_collective_ownership) {
    const newCollectiveOwnershipFields = collective_ownership_fields.map(
      (field) => {
        if (field?.name && field.name.length > 0) {
          return {
            ...field,
            label: field.label || field.name.join('.'),
            name: ['new_collective_ownership', ...field.name]
          };
        }
        return field;
      }
    );

    fields.push(...newCollectiveOwnershipFields);
  }

  return { fields };
};
