import Dragger from 'antd/es/upload/Dragger';
import { UploadOutlined } from '@ant-design/icons';
import { message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { itemRender } from '../../../../../utils/generateFormItem/utils/draggerProps/itemRender';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { onPreview } from '../../../../../utils/generateFormItem/utils/draggerProps/onPreview';

/**
 * Custom hook for handling a dragger field.
 *
 * @param {Object} options - The options for the dragger field.
 * @param {string} options.fileName - The name of the file.
 * @param {Object} options.filesList - The list of files.
 * @param {function} options.setFilesList - The function to set the files list.
 * @param {Object} options.filesConfiguration - The configuration for the files.
 * @param {function} options.setFilesConfiguration - The function to set the files configuration.
 * @param {string} options.purpose - The purpose of the dragger field.
 * @param {function} options.deleteFile - The function to delete a file.
 * @param {boolean} options.disableDraggerField - Whether to disable the dragger field.
 * @param {boolean} [options.noLabelCentered=false] - Whether the label should be centered.
 * @param {Object} [options.extraConfig={}] - Additional configuration for the dragger field.
 * @returns {Array} An array containing the dragger field object.
 */
export const useDraggerField = ({
  fileName,
  filesList,
  setFilesList,
  filesConfiguration,
  setFilesConfiguration,
  purpose,
  deleteFile,
  disableDraggerField,
  noLabelCentered = false,
  extraConfig = {}
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { downloadDocument, viewDocument } = useDownloadDocument();

  const draggerProps = ({ name }) => ({
    multiple: true,
    listType: 'picture-card',
    onPreview: (file) => onPreview(file, viewDocument),
    showUploadList: true,
    onRemove: () => {
      const updatedFileList = { ...filesList };
      delete updatedFileList[name];
      setFilesList(updatedFileList);
    },
    itemRender: (_, file) =>
      itemRender(
        file,
        filesList[name],
        downloadDocument,
        name,
        filesConfiguration,
        setFilesConfiguration,
        setFilesList,
        purpose,
        deleteFile,
        message,
        () => {},
        t
      ),
    beforeUpload: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const newFile = {
            file,
            url: reader.result,
            fileKey: name
          };

          setFilesList((prevList) => {
            const updatedList = { ...prevList };

            const fieldArray = updatedList[name] || [];
            fieldArray.push(newFile);
            updatedList[name] = fieldArray;

            return updatedList;
          });

          setFilesConfiguration((prevList) => [
            ...prevList,
            {
              name: file.name,
              fileKey: name,
              source: 'field',
              type: file.type
            }
          ]);

          resolve(true);
        };

        reader.onerror = (error) => {
          antdMessage.error(
            t(
              'dashboard.production.cards.creation.modal.notifications.file_read_error'
            )
          );
          reject(error);
        };

        reader.readAsDataURL(file);
      }),
    fileList: filesList[name] || []
  });

  const draggerConfig = extraConfig;
  if (noLabelCentered) {
    draggerConfig.label = ' ';
    draggerConfig.translate = false;
  }

  return [
    {
      name: [fileName],
      key: fileName,
      ...draggerConfig,
      maxFilesCount: 100,
      input: (
        <Dragger
          disabled={disableDraggerField}
          {...draggerProps({ name: fileName })}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];
};
