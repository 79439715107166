import { useState, useEffect } from 'react';
import { Button, Row, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { PrinterOutlined, BellOutlined } from '@ant-design/icons';
import { DocumentPdf } from '@carbon/icons-react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useColumns } from './columns';
import { ExtraSearchBars } from './ExtraSearchBar';
import { CerfaDocument } from '../../models/CerfaDocument';
import { SpfDepartmentalArchive } from '../../models/SpfDepartmentalArchive';
import { CustomListResource } from './SubComponents/CustomListResource';
import { SwitchBar } from './SwitchBar';
import { headers } from './headers';
import { GetEnums } from '../../utils/getEnums';
import { CerfaOrderDrawer } from './CerfaOrderDrawer';
import { Order } from '../../models/Order';
import { generateCerfa } from '../../utils/generateCerfa';

/**
 * Component to display and manage a list of orders.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const ListOrders = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [enums, setEnums] = useState({});
  const [filters, setFilters] = useState({
    status: location?.state?.status ? location.state.status : 'TO_BE_ORDERED',
    options: {
      'cerfa_document_type.wording': location?.state?.wording
        ? location.state.wording
        : '',
      'document.status': location?.state?.['document.status']
        ? location?.state?.['document.status']
        : ''
    }
  });
  const [cerfaDocuments, setCerfaDocuments] = useState([]);
  const [concernedSPFs, setConcernedSPFs] = useState([]);
  const [showOrdersOrdered, setShowOrdersOrdered] = useState(
    location?.state?.status === 'ORDERED'
  );
  const [isCerfaOrderDrawerOpen, setIsCerfaOrderDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cheques, setCheques] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys, rowRecords) => {
      setSelectedRowKeys(rowKeys);
      setSelectedOrders(rowRecords);
    }
  };

  const toggleShowOrders = () => {
    setShowOrdersOrdered((prev) => !prev);
    setFilters((allFilters) => ({
      ...allFilters,
      status: enums.orderEnums.status[showOrdersOrdered ? 0 : 1],
      options: {
        ...allFilters.options,
        'document.status': !showOrdersOrdered && 'EXPECTED'
      }
    }));
    setSelectedRowKeys([]);
    setSelectedOrders([]);
  };

  const toggleCerfaOrderDrawer = () => {
    setIsCerfaOrderDrawerOpen((prev) => !prev);
    setCheques(generateCerfa(selectedOrders));
  };

  const toggleUrgent = async (urgent, orderId) => {
    await Order.patchResource({ id: orderId, values: { urgent } });
    await Order.updateUrgentLate();
    setForceRefresh((prev) => !prev);
  };

  const columns = useColumns({
    dispatchAPI,
    message,
    setForceRefresh,
    forceRefresh,
    showOrdersOrdered,
    toggleUrgent
  });

  const { getEnums } = GetEnums();

  const transformFilter = (filterToTransform) => {
    let newFilter = '';
    Object.keys(filterToTransform).forEach((key) => {
      if (key === 'status') {
        newFilter += `&${key}=${filterToTransform[key]}`;
      } else if (key === 'options') {
        Object.keys(filterToTransform[key]).forEach((optionsKey) => {
          if (filterToTransform[key][optionsKey]) {
            newFilter += `&${optionsKey}=${filterToTransform[key][optionsKey]}`;
          }
        });
      }
    });
    return newFilter;
  };

  useEffect(() => {
    window.history.replaceState({}, '');
    (async () => {
      setIsLoading(true);
      const orderEnums = await getEnums({ resource: 'orders' });
      const docEnums = await getEnums({ resource: 'documents' });
      setEnums({
        orderEnums,
        docEnums
      });

      const dataCerfa = await CerfaDocument.getResources({
        populate: '',
        extraQuery: ''
      });
      setCerfaDocuments(dataCerfa);

      const dataSPF = await SpfDepartmentalArchive.getResources();
      setConcernedSPFs(dataSPF);

      setIsLoading(false);
    })();

    return () => {
      location.state = null;
    };
  }, []);

  return (
    <>
      <CustomListResource
        resourceName="orders"
        columns={columns}
        resourceModelName="Order"
        forceRefresh={forceRefresh}
        withCreateButton={false}
        headers={headers}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection
        }}
        extraQuery={transformFilter(filters)}
        searchBarExtraButtons={
          <ExtraSearchBars
            setFilters={setFilters}
            cerfaDocuments={cerfaDocuments}
            concernedSPFs={concernedSPFs}
            enums={enums}
            showOrdersOrdered={showOrdersOrdered}
            location={location}
          />
        }
        upperSearchKeyword={
          <SwitchBar
            showOrdersOrdered={showOrdersOrdered}
            toggleShowOrders={toggleShowOrders}
          />
        }
        printAction
        showAction
        deleteAction={showOrdersOrdered}
        editAction={false}
        scroll={{ x: 'max-content' }}
      />
      {selectedRowKeys.length > 0 &&
      filters.status === enums.orderEnums.status[0] ? (
        <Row style={{ marginLeft: 20 }}>
          <Button onClick={toggleCerfaOrderDrawer} type="primary">
            <PrinterOutlined />
            {t('buttons.order_and_print')}
          </Button>
        </Row>
      ) : null}
      {selectedRowKeys.length > 0 &&
      filters.status === enums.orderEnums.status[1] ? (
        <Row style={{ marginLeft: 20 }}>
          <Space>
            <Button onClick={() => {}} type="primary">
              <BellOutlined />
              {t('buttons.reminder')}
            </Button>
            <Button onClick={() => {}} type="primary">
              <DocumentPdf className="anticon" />
              {t('buttons.export')}
            </Button>
            <Button onClick={() => {}} type="primary">
              <PrinterOutlined />
              {t('buttons.print')}
            </Button>
          </Space>
        </Row>
      ) : null}
      <CerfaOrderDrawer
        cheques={cheques}
        isCerfaOrderDrawerOpen={isCerfaOrderDrawerOpen}
        setIsCerfaOrderDrawerOpen={setIsCerfaOrderDrawerOpen}
        setForceRefresh={setForceRefresh}
      />
      <Spin spinning={isLoading} tip={t('global.loading')} fullscreen />
    </>
  );
};
