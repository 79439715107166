/**
 * Handles the change in payment for an invoice.
 *
 * @function
 * @param {Object} options - The options for handling payment change.
 * @param {Object} options.invoice - The invoice object.
 * @param {Object} options.form - The form object.
 * @param {number} options.index - The index of the payment item.
 * @param {number} options.element - The new payment value.
 * @param {string} options.fieldName - The field name being updated.
 * @returns {void}
 */
export const handlePaymentChange = ({
  invoice,
  form,
  index,
  element,
  fieldName
}) => {
  const percentage = (element / invoice[fieldName]) * 100;

  const keyToUpdatedValues = ['total_ht', 'disbursement', 'lawyer_fee_ht'];

  const filteredValues = keyToUpdatedValues.filter((key) => key !== fieldName);

  const updatedValues = {};

  filteredValues.forEach((key) => {
    updatedValues[key] = parseFloat(
      ((invoice[key] || 0) * (percentage / 100)).toFixed(2)
    );
  });

  const updatedValuesTotal = Object.values(updatedValues).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const amount = parseFloat(
    (
      (element + updatedValuesTotal) *
      (1 + invoice.tva_percentage / 100)
    ).toFixed(2)
  );

  form.setFieldsValue({
    payments: form.getFieldValue('payments').map((item, idx) =>
      idx === index
        ? {
            ...item,
            ...updatedValues,
            tva: parseFloat((amount - element - updatedValuesTotal).toFixed(2)),
            amount
          }
        : item
    )
  });
};
