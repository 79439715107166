import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { collectiveOwnershipStatus } from '../../../../../utils/constants/tagColors';

/**
 * The `useInformationsListContent` hook is designed to structure and prepare data related to collective ownerships for presentation in a list or detailed view format.
 * It processes various pieces of information about a collective ownership, such as syndic, manager, status, and addresses, and formats them for display,
 * using conditional rendering to handle missing data and incorporating internationalization for labels and status tags.
 *
 * @param {Object} [data={}] - An object containing information about a collective ownership entity. The object's properties include syndic, manager, status, cadastral reference, volumetric lot, and various identifiers and addresses associated with the collective ownership.
 *
 * Returns:
 * An array of objects where each object represents a piece of information to be displayed in the list. Each object includes:
 * - `label`: A string key for internationalization purposes, used to retrieve the localized text for the label of the information.
 * - `span`: A numerical value indicating the span or width of the item in the list layout, used for alignment and formatting in grid or table-like displays.
 * - `content`: The content to be displayed for the item. This can be a simple string, a formatted string combining multiple pieces of data, or a React component (such as `Tag` for status). Conditional rendering is used to display a placeholder '-' for missing or undefined data.
 *
 * The hook utilizes the `useTranslation` hook from `react-i18next` for label internationalization, ensuring that the list content is accessible and understandable in different languages.
 * It also uses Ant Design's `Tag` component to visually represent the status of the collective ownership with color coding, based on predefined status colors mapped in `collectiveOwnershipStatus`.
 *
 * Usage:
 * This hook is intended for use in components that display detailed information about a collective ownership entity, such as a profile page, detail view, or information panel.
 * It simplifies the process of organizing and displaying complex data sets by structuring the data into a consistent format suitable for list or table-like presentations.
 */

export const useInformationsListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    syndic,
    manager,
    status,
    cadastral_reference,
    volumetric_lot,
    manager_code,
    code,
    name,
    first_address,
    first_city,
    second_address,
    registration_number,
    cadastral_place_or_route,
    capacity,
    head_office,
    siren_number
  } = data;

  return [
    {
      label: 'collective-ownerships.form.syndic',
      span: 1,
      content: syndic
        ? `${syndic?.code || ''} ${syndic?.society_name || ''}`
        : '-'
    },
    {
      label: 'collective-ownerships.form.manager',
      span: 1,
      content: manager
        ? `${manager?.last_name || ''} ${manager?.first_name || ''}`
        : '-'
    },
    {
      label: 'collective-ownerships.form.status',
      span: 1,
      content:
        (status && (
          <Tag color={collectiveOwnershipStatus[status]}>
            {t(`collective-ownerships.tags.${status}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'collective-ownerships.form.cadastral_reference',
      span: 1,
      content: cadastral_reference || '-'
    },
    {
      label: 'collective-ownerships.form.volumetric_lot',
      span: 1,
      content: volumetric_lot || '-'
    },
    {
      label: 'collective-ownerships.form.manager_code',
      span: 1,
      content: manager_code || '-'
    },
    {
      label: 'collective-ownerships.form.code',
      span: 1,
      content: code || '-'
    },
    {
      label: 'collective-ownerships.form.name',
      span: 2,
      content: name || '-'
    },
    {
      label: 'collective-ownerships.form.first_address.title',
      span: 1,
      content:
        first_address && Object.keys(first_address).length
          ? `${first_address?.number || ''} ${first_address?.street || ''} ${
              first_address?.postal_code || ''
            } ${first_city?.name || ''}`
          : '-'
    },
    {
      label: 'collective-ownerships.form.second_address.title',
      span: 2,
      content:
        second_address && Object.keys(second_address).length
          ? `${second_address?.number || ''} ${second_address?.street || ''} ${
              second_address?.postal_code || ''
            } ${second_address?.city || ''}`
          : '-'
    },
    {
      label: 'collective-ownerships.form.registration_number',
      span: 1,
      content: registration_number || '-'
    },
    {
      label: 'collective-ownerships.form.cadastral_place_or_route',
      span: 1,
      content: cadastral_place_or_route || '-'
    },
    {
      label: 'collective-ownerships.form.capacity',
      span: 1,
      content: capacity || '-'
    },
    {
      label: 'collective-ownerships.form.head_office',
      span: 1,
      content: head_office || '-'
    },
    {
      label: 'collective-ownerships.form.siren_number',
      span: 1,
      content: siren_number || '-'
    }
  ];
};
