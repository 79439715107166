export const headers = [
  {
    label: 'date',
    key: 'date'
  },
  {
    label: 'bank_journal',
    key: 'bank_journal'
  },
  {
    label: 'bank_account',
    key: 'bank_account'
  },
  {
    label: 'wording',
    key: 'wording'
  },
  {
    label: 'piece',
    key: 'piece'
  },
  {
    label: 'debit',
    key: 'debit'
  },
  {
    label: 'credit',
    key: 'credit'
  }
];
