import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, Button, Form, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

import { parametersContent } from '../utils/parametersContent';

const { Text } = Typography;

/**
 * Custom hook that generates an array of objects containing information for a list content.
 * @param {Object} data - The data object containing the necessary information.
 * @returns {Array} - An array of objects containing label, span, and content properties.
 */
export const useInformationsListContent = ({ data }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { priority, lawyer, type, co_owner_form_status, notary } = data;
  const [updateField, setUpdateField] = useState(false);
  const { AGDateContent, osCodeContent, useIcon } = parametersContent({
    setUpdateField,
    updateField,
    mission: data,
    form
  });

  return [
    {
      label: 'missions.form.AG_date',
      span: 1,
      content: (
        <Row>
          <Col>{AGDateContent(updateField)}</Col>
          <Col>
            <Button type="link" onClick={() => setUpdateField('AG_code')}>
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      )
    },
    {
      label: 'missions.form.OS_code',
      span: 1,
      content: (
        <Row>
          <Col>{osCodeContent(updateField)}</Col>
          <Col>
            <Button type="link" onClick={() => setUpdateField('OS_code')}>
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      )
    },
    {
      label: 'missions.form.priority',
      span: 1,
      content: useIcon(priority)
    },
    ...(type?.includes('PCS')
      ? [
          {
            label: 'missions.form.lawyer',
            span: 1,
            content: lawyer ? (
              <Link to={`/users/show/${lawyer._id}`}>
                <Text style={{ textDecoration: 'underline' }}>{`${
                  lawyer.first_name || ''
                } ${
                  lawyer.last_name ? lawyer.last_name.toUpperCase() : ''
                }`}</Text>
              </Link>
            ) : (
              '-'
            )
          }
        ]
      : []),
    ...(type === 'PCS_2'
      ? [
          {
            label: 'missions.form.co_owner_form_status',
            span: 1,
            content: (
              <Tag
                color={co_owner_form_status === 'BLOCKED' ? 'grey' : 'green'}
              >
                {t(`missions.tags.${co_owner_form_status}`)}
              </Tag>
            )
          }
        ]
      : []),
    ...(type === 'PCS_3'
      ? [
          {
            label: 'missions.form.notary',
            span: 1,
            content: notary ? (
              <Link to={`/users/show/${notary._id}`}>
                <Text style={{ textDecoration: 'underline' }}>{`${
                  notary.first_name || ''
                } ${
                  notary.last_name ? notary.last_name.toUpperCase() : ''
                }`}</Text>
              </Link>
            ) : (
              '-'
            )
          }
        ]
      : [])
  ];
};
