import { updateChargingGrids } from './updateCollectiveOwnership';

/**
 * Handles the division of a lot.
 * @function
 * @async
 * @param {Object} options - The options object.
 * @param {Object} options.body - The body object.
 * @param {Array} options.dataSource - The data source array.
 * @param {Function} options.antdMessage - The antdMessage function.
 * @param {Function} options.t - The translation function.
 * @param {Function} options.setSelectedRowKeys - The setSelectedRowKeys function.
 * @param {Array} options.selectedRowKeys - The selectedRowKeys array.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {Object} options.chargingGrid - The chargingGrid object.
 * @param {Function} options.setChargingGrid - The setChargingGrid function.
 * @param {Array} options.resourceDatas - The resourceDatas array.
 * @param {Function} options.setResourceDatas - The setResourceDatas function.
 * @param {string} options.lotNumber - The lot number.
 * @param {Function} options.message - The message function.
 * @param {Function} options.setIsDivisionLotModalOpen - The setIsDivisionLotModalOpen function.
 * @returns {void}
 */
export const handleDivideLot = async ({
  body,
  dataSource,
  antdMessage,
  t,
  setSelectedRowKeys,
  selectedRowKeys,
  dispatchAPI,
  chargingGrid,
  setChargingGrid,
  resourceDatas,
  setResourceDatas,
  lotNumber,
  message,
  setIsDivisionLotModalOpen
}) => {
  let hasError = false;

  dataSource.forEach((lot) => {
    if (body.lots.includes(lot.Lot.value) && !lot.delete_row) {
      if (
        body[body.lots.indexOf(lot.Lot.value)] &&
        !body[body.lots.indexOf(lot.Lot.value)].is_duplicate_lot
      ) {
        antdMessage.warning(
          t('charging-grids.show.messages.lot-exists', {
            lot: lot.Lot.value
          }),
          5
        );

        hasError = true;
      }
    }

    if (
      lot.Lot.value !== 'Voulu' &&
      lot.Lot.value !== 'Total' &&
      typeof lot.Lot.value === 'string'
    ) {
      const lotBis = parseInt(lot.Lot.value.replace('bis', '').trim(), 10);

      if (
        body.lots.includes(lotBis) &&
        body[body.lots.indexOf(lotBis)] &&
        body[body.lots.indexOf(lotBis)].is_duplicate_lot
      ) {
        antdMessage.warning(
          t('charging-grids.show.messages.lot-exists', {
            lot: lot.Lot.value
          }),
          5
        );

        hasError = true;
      }
    }
  });

  if (hasError) {
    return;
  }
  try {
    const formData = new FormData();

    const newBody = { ...body };

    body.lots.forEach((lot) => {
      if (body[body.lots.indexOf(lot)].is_duplicate_lot) {
        newBody.lots[body.lots.indexOf(lot)] = `${lot} bis`;
      }
    });

    formData.append('values', JSON.stringify(newBody));
    formData.append('row_keys', JSON.stringify(selectedRowKeys));

    const { data } = await dispatchAPI('PATCH', {
      url: `/charging-grids/${chargingGrid._id}?type=divide_data&lot_number=${lotNumber}`,
      body: formData
    });

    updateChargingGrids({
      resourceDatas,
      chargingGrid: data,
      setResourceDatas
    });
    setChargingGrid(data);
    setIsDivisionLotModalOpen(false);
    setSelectedRowKeys([]);
  } catch (error) {
    message(error);
  }
};
