/**
 * Sets the tabs items based on the provided type.
 *
 * @function
 * @param {Object} options - The options object.
 * @param {string} options.type - The type of the tabs items.
 * @param {string} options.lawyerStep - The lawyer step.
 * @param {Array} options.pcs1TabsItems - The tabs items for type 'PCS_1'.
 * @param {Array} options.pcs2TabsItems - The tabs items for type 'PCS_2'.
 * @param {Array} options.pcs3TabsItems - The tabs items for
 * @param {Array} options.tabsItems - The default tabs items.
 * @returns {Array} - The tabs items based on the provided type.
 */
export const setTabsItems = ({
  type,
  lawyerStep,
  pcs1TabsItems,
  pcs2TabsItems,
  pcs3TabsItems,
  tabsItems
}) => {
  switch (type) {
    case 'PCS_1': {
      if (['AG_DECISION', 'FINISHED'].includes(lawyerStep)) {
        return pcs1TabsItems;
      }
      return tabsItems;
    }
    case 'PCS_2':
      return pcs2TabsItems;
    case 'PCS_3':
      return pcs3TabsItems;
    default:
      return tabsItems;
  }
};
