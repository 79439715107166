import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Row, Col, message } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { inputDisplaySmall } from '../../../utils/generateFormItem/utils/inputDisplaySmall';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { setDocumentIcon } from '../../../components/setDocumentIcon';
import { Document } from '../../../models/Document';
import { Mission } from '../../../models/Mission';

/**
 * Use columns hook
 * @hook
 * @returns {Array} Columns
 */
export const useColumns = ({ setForceRefresh, isChecked }) => {
  const { t } = useTranslation();
  const { viewDocument, downloadDocument } = useDownloadDocument();

  const draggerProps = ({ id, collective_ownership }) => ({
    beforeUpload: () => false,
    onChange: async (objectFile) => {
      await Document.uploadDocument({
        id,
        file: objectFile.file,
        setForceRefresh,
        extraQuery: `?collective_ownership=${collective_ownership}`,
        t
      });
    },
    fileList: []
  });

  return [
    {
      title: t('prestation_word.missions.form.syndic'),
      key: 'syndic',
      dataIndex: 'syndic',
      sorter: true,
      render: (syndic) => (syndic ? `${syndic?.society_name}` : '-')
    },
    {
      title: t('prestation_word.missions.form.collective_ownership'),
      key: 'collective_ownership',
      dataIndex: 'collective_ownership',
      sorter: true,
      render: (collective_ownership) =>
        collective_ownership
          ? `${collective_ownership?.name || ''} 
             ${collective_ownership?.first_adress}`
          : '-'
    },
    {
      title: t('prestation_word.missions.form.reception_date'),
      key: 'reception_date',
      sorter: true,
      render: (record) => {
        const files = record?.document_to_re_written?.files;

        if (files?.length) {
          return dayjs(files[files.length - 1].updloadDate).format(
            'DD-MM-YYYY'
          );
        }
        return '-';
      }
    },
    {
      title: t('prestation_word.missions.form.document_to_re_written'),
      key: 'document_to_re_written',
      dataIndex: 'document_to_re_written',
      render: (document_to_re_written) =>
        (document_to_re_written?.files || []).map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    {
      title: (
        <Row gutter={[16, 16]}>
          <Col spane={18}>
            {t('prestation_word.missions.form.re_written_document')}
          </Col>
          <Col>
            <Tooltip
              title={t(
                'prestation_word.missions.tooltips.re_written_documents'
              )}
            >
              <InfoCircleOutlined style={{ fontSize: 18, color: '#B0B0B0' }} />
            </Tooltip>
          </Col>
        </Row>
      ),
      key: 'file',
      render: (record) =>
        inputDisplaySmall(
          true,
          null,
          {
            ...draggerProps({
              id: record.re_written_document._id,
              collective_ownership: record?.collective_ownership?._id
            })
          },
          false,
          t
        )
    },
    {
      key: 'files',
      dataIndex: 're_written_document',
      render: (document) =>
        (document?.files || []).map((file) => (
          <Tooltip title={file.metadata.originalName}>
            <Button
              type="link"
              onClick={() =>
                file.type === 'application/pdf'
                  ? viewDocument(file)
                  : downloadDocument({
                      _id: file._id,
                      metadata: file.metadata,
                      contentType: file.contentType
                    })
              }
            >
              {setDocumentIcon(file.contentType)}
            </Button>
          </Tooltip>
        ))
    },
    ...(isChecked
      ? [
          {
            title: t('prestation_word.missions.form.sending_date'),
            key: 'sending_date',
            sorter: true,
            render: (record) => {
              const files = record?.re_written_document?.files;

              if (files?.length) {
                return dayjs(files[files.length - 1].updloadDate).format(
                  'DD-MM-YYYY'
                );
              }
              return '-';
            }
          }
        ]
      : [
          {
            key: 'validate',
            render: (record) => (
              <Tooltip title={t('prestation_word.missions.tooltips.validate')}>
                <Button
                  type="link"
                  onClick={() =>
                    Mission.patchResource({
                      id: record._id,
                      values: {
                        re_written_document_status: 'DONE',
                        'steps.sub': 'INTERMEDIATE_PROJECT_SUBMISSION'
                      },
                      setForceRefresh,
                      message,
                      messageContent: t(
                        'prestation_word.missions.messages.validate'
                      )
                    })
                  }
                >
                  <CheckCircleFilled style={{ fontSize: 18, color: 'green' }} />
                </Button>
              </Tooltip>
            )
          }
        ])
  ];
};
