import { Route, Routes } from 'react-router-dom';
import { ServicesContextProvider } from '../../../../contexts/ServicesContext';
import { routesPlansResearch } from '../../../../utils/constants/routes/servicesRoutes';
import { Exception } from '../../../../components';
import { DashBoard } from './Dashboard';
import { ServicesGenericCollectiveOwnershipList } from '../components/ServicesCollectiveOwnerships/ServicesGenericCollectiveOwnershipList';
import { ServicesActionsToDoList } from '../components/ServicesActionsToDo/ServicesActionsToDoList';
import { ServicesInvoicesList } from '../components/ServicesInvoices/ServicesInvoicesList';
import { CreateUpdateDevis } from '../utils/DevisCreateUpdate/CreateUpdateDevis';
import { ServicesDraftsList } from '../components/ServicesDrafts/ServicesDraftsList';
import { ServicesPendingValidationList } from '../components/ServicesPendingValidation/ServicesPendingValidationList';

const resourceName = 'RCP_RESEARCH';
/**
 * @component
 * Renders the router for the Plans Research section.
 * @returns {JSX.Element} The PlansResearchRouter component.
 */
export const RCPResearchRouter = () => (
  <ServicesContextProvider>
    <Routes>
      <Route path={routesPlansResearch.DASHBOARD} element={<DashBoard />} />
      <Route
        path={routesPlansResearch.COLLECTIVE_OWNERSHIPS}
        element={
          <ServicesGenericCollectiveOwnershipList
            tradKey={resourceName}
            missions={[resourceName]}
          />
        }
      />
      <Route
        path={routesPlansResearch.ACTIONS_TO_DO}
        element={<ServicesActionsToDoList missions={[resourceName]} />}
      />
      <Route
        path={routesPlansResearch.INVOICES}
        element={<ServicesInvoicesList missions={resourceName} />}
      />
      <Route
        path={routesPlansResearch.DEVIS}
        element={
          <CreateUpdateDevis
            requested_mission_type={resourceName}
            purpose="create"
          />
        }
      />
      <Route
        path={`${routesPlansResearch.DEVIS}/edit/:id`}
        element={
          <CreateUpdateDevis
            requested_mission_type={resourceName}
            purpose="edit"
          />
        }
      />
      <Route
        path={routesPlansResearch.DRAFTS}
        element={<ServicesDraftsList requested_mission_type={[resourceName]} />}
      />
      <Route
        path={routesPlansResearch.PENDING_VALIDATION}
        element={
          <ServicesPendingValidationList
            requested_mission_type={[resourceName]}
          />
        }
      />
      <Route path="*" element={<Exception />} />
    </Routes>
  </ServicesContextProvider>
);
