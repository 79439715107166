import { useTranslation } from 'react-i18next';
import { CreateUpdateQuoteConfiguration } from './CreateUpdateQuoteConfiguration';
import { ListQuoteConfigurations } from './ListQuoteConfigurations';
import { PublicationPcsItem } from './PublicationPcsItem';

/**
 * Custom hook for retrieving quote configuration items.
 * @hook
 * @returns {Object} The quote configuration items.
 * @property {Array} items - The array of quote configuration items.
 */
export const useItems = () => {
  const { t } = useTranslation();
  const items = [
    {
      label: t('quote-configurations.tabs.missions'),
      key: '3',
      children: <ListQuoteConfigurations type="MISSIONS" />
    },
    {
      label: t('quote-configurations.tabs.traditional_publication'),
      key: '1',
      children: (
        <CreateUpdateQuoteConfiguration type="TRADITIONAL_PUBLICATION" />
      )
    },
    {
      label: t('quote-configurations.tabs.publication_pcs'),
      key: '2',
      children: <PublicationPcsItem />
    },
    {
      label: t('quote-configurations.tabs.phrases'),
      key: '4',
      children: <ListQuoteConfigurations type="PHRASES" />
    }
  ];

  return { items };
};
