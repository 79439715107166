import { Divider, Row, Col, Typography, InputNumber, Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CalculMethods } from '../utils/calculMethods';
import { useRightsCalculationFields } from './fields/rightsCalculation';

const { Text } = Typography;

/**
 * Renders the Rights calculation component based on the provided actions.
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.actAmount - The action object.
 * @returns {JSX.Element|null} The rendered Nomade Honorary component or null if the action is not supported.
 */
export const RightsCalculation = ({ actAmount }) => {
  const { t } = useTranslation();
  const { handleAmountsUpdate } = CalculMethods();
  const { actionFieldsMap } = useRightsCalculationFields();

  const actions = [
    ...new Set(
      actAmount.quotation_acts.map((act) => act.quotation_action.action)
    )
  ];

  const fieldsToRender = [
    ...new Map(
      actions
        .flatMap((action) => actionFieldsMap[action] || [])
        .map((field) => [field.name, field])
    ).values()
  ];

  return (
    <>
      <Divider>{t('quotations.dividers.rights_calculation')}</Divider>
      <Row gutter={[16, 16]} justify="center">
        {fieldsToRender.map((field) => (
          <Col span={24} key={field.key}>
            {field.input ? (
              <Form.Item
                label={t(field.label)}
                name={[
                  'quotation_act_amounts',
                  actAmount._id,
                  'rights_calculation',
                  field.name
                ]}
              >
                <InputNumber
                  addonAfter={field.unit}
                  onChange={() => handleAmountsUpdate({ actAmount })}
                />
              </Form.Item>
            ) : (
              <Text>
                {t(field.label, {
                  amount: actAmount?.rights_calculation?.[field.name] || 0
                })}
              </Text>
            )}
          </Col>
        ))}
      </Row>
    </>
  );
};

RightsCalculation.propTypes = {
  actAmount: PropTypes.shape({
    quotation_acts: PropTypes.arrayOf(
      PropTypes.shape({
        quotation_action: PropTypes.shape({
          action: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired,
    rights_calculation: PropTypes.shape({
      charging_grid: PropTypes.number,
      total_ht: PropTypes.number,
      tva: PropTypes.number,
      total_ttc: PropTypes.number
    }),
    _id: PropTypes.string
  }).isRequired
};
