import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components';
import { useColumns } from './useColumns/useColumns';

const rawSelectOptions = [
  { label: 'PCS_2', value: ['PCS_2'] },
  {
    label: 'PCS_3',
    value: ['PCS_3']
  },

  {
    label: 'OUTSIDE_PCS',
    value: [
      'PLANS_RESEARCH',
      'RCP_RESEARCH',
      'RCP_DOC_AND_WORD_TRANSFORMATION_RESEARCH',
      'BATCH_SHEET_PERSON_SHEET_RESEARCH',
      'MORTGAGE_SHEET_RESEARCH',
      'COLLECTIVE_OWNERSHIP_ACT_RESEARCH',
      'ACT_COPY_RESEARCH',
      'OUTLAWED_PUBLICATION_ELAN'
    ]
  }
];

/**
 * Renders a list of syndic requests.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.requested_mission_type - The requested mission types.
 * @returns {JSX.Element} The rendered component.
 */
export const ListSyndicRequests = () => {
  const [searchParams] = useSearchParams();
  const requestedMissionTypeLabel = searchParams.get('requested_mission_type');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fieldsToFetch = [
    'type',
    '_id',
    'name',
    'updated_at',
    'status',
    'syndic',
    'devis_signed',
    'collective_ownership.first_address',
    'collective_ownership.first_city.name',
    'collective_ownership.name',
    'requested_mission_type',
    'collective_ownership.name',
    'requested_mission_type',
    'new_collective_ownership',
    'documents',
    'type_pcs_2'
  ];

  const selectedOption =
    rawSelectOptions.find(
      (option) => option.label === requestedMissionTypeLabel
    ) || rawSelectOptions[0];

  useEffect(() => {
    if (
      !requestedMissionTypeLabel ||
      selectedOption.label !== requestedMissionTypeLabel
    ) {
      navigate({
        search: `?requested_mission_type=${selectedOption.label}`,
        replace: true
      });
    }
  }, [requestedMissionTypeLabel, selectedOption, navigate]);

  const requested_mission_type = selectedOption ? selectedOption.value : [];

  const columns = useColumns(requestedMissionTypeLabel);

  const selectOption = rawSelectOptions.map((type) => ({
    label: t(`missions.tags.${type.label}`),
    value: type.label
  }));

  const buildStringFields = (fields) => fields.join(',');
  const fieldsString = buildStringFields(fieldsToFetch);

  const MISSIONS_POPULATE =
    'syndic,mission,collective_ownership,collective_ownership.first_city,new_collective_ownership.first_city,documents.files';

  const MISSIONS_QUERY = `status=PENDING_VALIDATION&requested_mission_type=${requested_mission_type.join(
    ','
  )}&fields=${fieldsString}&sort=-updated_at`;

  const onChange = (e) => {
    const { value } = e.target;
    if (!value) {
      navigate({ search: '' });
    } else {
      navigate({ search: `?requested_mission_type=${value}` });
    }
  };

  return (
    <ListResource
      tradKey={`syndic-requests.${requestedMissionTypeLabel}`}
      resourceName="syndic-request"
      extraQuery={MISSIONS_QUERY}
      populate={MISSIONS_POPULATE}
      onDoubleClickAction={{ action: () => {} }}
      columns={columns}
      customActionColumn
      withCreateButton={false}
      extraButtons={
        <Radio.Group
          onChange={onChange}
          options={selectOption}
          optionType="button"
          buttonStyle="solid"
          value={requestedMissionTypeLabel}
        />
      }
    />
  );
};
